<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a href="#" id="btnListSrch" class="btn btn-primary btn-row2" @click.prevent="getCorpCostList">
                    <span>
                        <i class="glyphicon glyphicon-search"></i> 조회
                    </span>
                </a>
                <a href="#" id="btnListInst" class="btn btn-default" @click.prevent="addCorpCost">
                    <span>
                        <i class="glyphicon glyphicon-plus"></i> 추가
                    </span>
                </a>
                <a href="#" id="btnListInst" class="btn btn-default" @click.prevent="deleteRecords">
                    <span>
                        <i class="glyphicon glyphicon-remove"></i> 삭제
                    </span>
                </a>
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-3">
                                        <label for="selTrgtYY" class="control-label" style="text-align:center">
                                            <span>년도</span>
                                        </label>
                                        <select id="selTrgtYY" class="form-control" v-model="GeneralCorpCost.TargetYear" @change="getCorpCostList">
                                            <option v-for="year in TargetYearList" v-bind:value="year">{{year}}</option>
                                        </select>
                                    </div>
                                    <div class="cols col-3">
                                        <label for="txtCompanyNm" class="control-label">
                                            <span>비용구분</span>
                                        </label>
                                        <select id="costDivCd" class="form-control" v-model="GeneralCorpCost.CostDivCd" @change="getCorpCostList">
                                            <option value="">전체</option>
                                            <option v-for="costCd in CostDivList" v-bind:value="costCd.Code">{{costCd.Name}}</option>
                                        </select>
                                    </div>
                                </section>
                            </section>
                        </fieldset>
                    </form>
                </div>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">일반회사비용 관리</p>
                </div>
                <div class="table-responsive">
                    <DxDataGrid id="grid-container"
                                :data-source="CorpCostList"
                                :columns="DefaultColumns"
                                :hoverStateEnabled="true"
                                :row-alternation-enabled="true"
                                @row-click="showDetail"
                                @cell-prepared="onCellPrepared"
                                @selection-changed="onSelectionChanged"
                                :selected-row-keys="selectedItemKeys"
                                :ref="CostDetail"
                                style="max-height: 470px;">
                        <DxScrolling mode="virtual" useNative="false" />
                        <DxSelection mode="multiple" />
                    </DxDataGrid>
                </div>
            </div>
        </div>
        <AC1091E ref="AC1091E"></AC1091E>
    </div>
</template>
<script>
    import ViewTitle from '@/components/ViewTitle'
    import { DxDataGrid, DxColumn, DxEditing, DxSelection, DxScrolling, DxLookup, DxTotalItem } from 'devextreme-vue/data-grid'
    import DataSource from 'devextreme/data/data_source';
    import ArrayStore from 'devextreme/data/array_store';

    import AC1091E from '@/components/ac/popup/AC1091E';

    export default {
        name: 'AC1090R',
        components: {
            ViewTitle,
            AC1091E,
            DxDataGrid,
            DxColumn,
            DxEditing,
            DxSelection,
            DxScrolling,
            DxLookup,
            DxTotalItem
        },
        props: {
            templateData: {
                type: Object,
                default: () => { }
            }
        },
        data() {
            return {
                editOnKeyPress: true,
                enterKeyDirection: 'row',
                enterKeyAction: 'startEdit',
                CostDetail: 'dataGrid',
                DefaultColumns: [
                    { caption: "월", dataField: "AcctMonth", alignment: 'center' },
                    { caption: "비용구분", dataField: "CostDivNm", alignment: 'center' }
                ],
                // 그리드 검색 조건
                GeneralCorpCost: {
                    // 년도
                    TargetYear: this.moment(new Date()).year(),
                    // 비용구분코드
                    CostDivCd: ''
                },
                // 대상년도 리스트
                TargetYearList: new Array(),
                // 일반회사비용 그리드
                CorpCostList: new Array(),
                // 일반회사비용 추가 파라미터
                CorpCostDetail: {
                    Arg: '',
                    AcctYear: '',
                    AcctMonth: '',
                    CostDivCd: '',
                    CostAmt: '',
                    MemoCont: '',
                    EmpNo: ''
                },
                InputCorpCost: new Array(),
                DeleteCorpCost: new Array(),
                selectedItems: {
                    AcctYear: '',
                    AcctMonth: '',
                    CostDivCd: ''
                },
                selectedItemKeys: new Array(),
                dataSource: new DataSource({
                    store: new ArrayStore({
                        data: this.CorpCostList,
                        key: ['AcctYear', 'AcctMonth', 'CostDivCd']
                    }),
                })
            }
        },
        created() {

            this.getCorpCostList()
        },
        beforeMount() {

            // 비용구분 공통코드
            this.CostDivList = $.scriptBase.getCommonCode('CODE000', '0512000', '', '')

            var startYear = this.moment(new Date()).add(1, 'year').year()
            var endYear = this.moment(new Date(2013, 1, 1, 0, 0, 0)).year()

            // 대상년도 목록 값 설정
            for (var i = startYear; i >= endYear; i--) {

                this.TargetYearList.push(i)
            }
        },
        methods: {

            // datagrid header 가운데 정렬, column 중앙 정렬
            onCellPrepared(e) {

                if (e.rowType == 'header') {
                    e.cellElement.style['textAlign'] = 'center'
                }
                if (e.rowType == 'data') {
                    e.cellElement.style['verticalAlign'] = 'middle'
                }
            },

            // 일반회사비용 관리 목록 조회
            getCorpCostList: function () {

                var parent = this
                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/GetGeneralCorpCost'.toRealServiceUrl()
                var obj = new Object()

                parent.resetColumns()

                obj.jsonString = JSON.stringify(this.GeneralCorpCost)

                var successCallback = function (data) {

                    var jsonObj = JSON.parse(data.d)
                    parent.CorpCostList = jsonObj.Table
                    parent.DataCount = jsonObj.Table.length

                    var columnsCount = parent.DataCount > 0 ? Object.keys(jsonObj.Table[0]).length : 0

                    for (var i = 0; i < columnsCount - 3; i++) {

                        var columnName = Object.keys(jsonObj.Table[0])[3 + i]
                        var newColumn = new Array()

                        if (i < columnsCount - 4) {
                            var captionName = (parent.GeneralCorpCost.TargetYear - i) + '년 비용'
                            newColumn = { caption: captionName, dataField: columnName, alignment: 'right', format:"#,##0.##" }
                        } else {
                            newColumn = { caption: columnName, dataField: columnName, alignment: 'left', width: "40%" }
                        }

                        parent.DefaultColumns.push(newColumn)
                    }

                    parent.selectedItems = []
                    parent.selectedItemKeys = []
                }
                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            resetColumns: function () {
                var cnt = this.DefaultColumns.length

                for (var i = 2; i < cnt; i++) {
                    this.DefaultColumns.splice(2, 1)
                }
            },
            // 신규
            addCorpCost: function () {

                var parent = this
                var isNew = true
                var obj = this.CorpCostList
                var successCallback = function () {
                    parent.getCorpCostList()
                }
                this.$refs.AC1091E.open(successCallback, isNew, obj)

            },
            // 상세 조회 수정
            showDetail(e) {

                var parent = this
                var isNew = false

                if (!e.data.CostAmt1) {
                    alert('추가 버튼을 통해 비용을 입력해 주십시오.')
                    return
                }

                e.data.AcctYear = this.GeneralCorpCost.TargetYear
                e.data.EmpNo = this.$store.getters.getEmpId

                var callbackFunction = function () {
                    parent.getCorpCostList()
                }                
                this.$refs.AC1091E.open(callbackFunction, isNew, e.data)
            },        
            // 선택한 로우 인덱스
            onSelectionChanged(e) {

                this.selectedItemKeys = e.selectedRowKeys
            },
            // 일반회사비용 선택 로우 삭제
            deleteRecords: function () {

                var parent = this

                const dataGrid = this.$refs[this.CostDetail].instance

                $(dataGrid.getSelectedRowsData()).each(function (i, o) {

                    if (o.AcctYear && o.AcctMonth && o.CostDivCd) {

                        parent.CorpCostDetail.Arg = 'D'
                        parent.CorpCostDetail.AcctYear = parent.GeneralCorpCost.TargetYear
                        parent.CorpCostDetail.AcctMonth = o.AcctMonth
                        parent.CorpCostDetail.CostDivCd = o.CostDivCd
                        parent.CorpCostDetail.CostAmt = null
                        parent.CorpCostDetail.MemoCont = null
                        parent.CorpCostDetail.EmpNo = null

                        var selectedCostDetail = Object.assign({}, parent.CorpCostDetail)

                        parent.DeleteCorpCost.push(selectedCostDetail)
                    }
                })

                if (parent.selectedItemKeys.length == 0) {
                    alert('삭제할 항목을 선택해주세요.')
                    return
                }

                parent.selectedItemKeys.forEach((key) => {
                    parent.dataSource.store().remove(key)
                })

                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/DeleteGeneralCorpCost'
                url = url.toRealServiceUrl()

                var obj = new Object()
                obj.jsonString = JSON.stringify(parent.DeleteCorpCost)

                var ajaxSuccessHandler = function (data) {

                    alert("삭제되었습니다.")
                    parent.CorpCostDetail = []
                    parent.DeleteCorpCost = []
                    parent.getCorpCostList()
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
            }
        }
    }
</script>