<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>

        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a v-if="this.$parent.CanSearch" href="#" id="btnListShow" class="btn btn-primary btn-row2" @click.prevent="fn_ShowData">
                    <span>
                        <i class="glyphicon glyphicon-search"></i>조회
                    </span>
                </a>
                <a v-if="this.$parent.CanSave" href="#" id="btnListSave" class="btn btn-default" @click.prevent="fn_SaveData">
                    <span>
                        <i class="glyphicon glyphicon-save"></i>저장
                    </span>
                </a>
            </div>
            <div class="sch-box">
                <div class="sch-form">
                    <!--조건 선언부-->
                    <form class="form-horizontal" role="form">
                        <!--조건 선언부-->
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="sch3-1" class="control-label"><span>시스템</span></label>
                                        <select class="form-control" id="selSystId" name="selSystId">
                                            <option v-for="item in SystemIdList" v-bind:value="item.Code">{{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="sch3-1" class="control-label"><span>프로그램 분류</span></label>
                                        <select class="form-control" id="selProgrCls" name="selProgrCls" v-on:change="fn_ShowProg">
                                            <option value="">== 프로그램 분류 ==</option>
                                            <option v-for="item in ProgrClsList" v-bind:value="item.Code">{{item.Name}}</option>
                                        </select>
                                    </div>
                                </section>
                            </section>
                        </fieldset>
                        </form>
                    
                </div>
            </div>
                <div class="grid-wrap">
                    <div class="sub-title">
                        <p class="sub-title-txt">권한 등록</p>
                    </div>
                    <div>
                        <!--리스트 영역-->
                        <article class="row tbl-option-wrap">
                            <div class="col-xs-12 col-sm-8 tbl-option-left-wrap">
                                <div class="col-xs-12 col-sm-6 pad-l0">
                                    <h4 class="add-tlt">
                                        사용자 그룹/사용자 (<span id="spTotCnt0">{{UserAuthListTotalCount}}</span>건)
                                    </h4>
                                    <div class="table-responsive-always tbl-6-width table-responsive-height">
                                        <table class="table tbl-list table-hover table-bordered" id="tb0">
                                            <caption>기본 테이블</caption>
                                            <colgroup>
                                                <col />
                                                <col />
                                                <col />
                                                <col />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th class="text-center">선택</th>
                                                    <th class="text-center">구분</th>
                                                    <th class="text-center">사용자</th>
                                                    <th class="text-center">직급</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="UserAuthList === null">
                                                    <td colspan="4" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                </tr>
                                                <tr v-else v-for="item in UserAuthList">
                                                    <td class="text-center">
                                                        <label for="cb0" class="hidden">선택</label>
                                                        <input name="cb0" type="radio"
                                                               v-on:change="targetUserGroupChangeEvent"
                                                               v-bind:checked="SelectTargetUserGroupId === item.UserGrpId"
                                                               :corpcd="item.CorpCd"
                                                               :usersystid="item.UserSystId"
                                                               :usergrpid="item.UserGrpId"
                                                               :usergrpyn="item.UserGrpYN" />
                                                    </td>
                                                    <td class="text-center">{{item.UserGrpGb}}</td>
                                                    <td class="text-center">{{item.UserGrpName}}</td>
                                                    <td class="text-center">{{item.JobGradeNm}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 pad-r0">
                                    <h4 class="add-tlt">
                                        적용권한 (<span id="spTotCnt1">{{UserAuthListTotalCount1}}</span>건)
                                    </h4>
                                    <div class="tbl-6-col">
                                        <div class="table-responsive-always table-responsive-height">
                                            <table class="table tbl-list table-hover table-bordered" id="tb1">
                                                <caption>기본 테이블</caption>
                                                <colgroup>
                                                    <col />
                                                    <col />
                                                    <col />
                                                    <col />
                                                    <col />
                                                    <col />
                                                    <col />
                                                    <col />
                                                    <col />
                                                    <col />
                                                    <col />
                                                    <col />
                                                    <col />
                                                </colgroup>
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">
                                                            <label for="cbAll1" class="hidden">전체선택</label>
                                                            <input name="cbAll1" type="checkbox" @click="checkedAllEvent($event.target.checked, 'cb1')" />
                                                        </th>
                                                        <th class="text-center">구분</th>
                                                        <th class="text-center">프로그램</th>
                                                        <th class="text-center"><a href="#" id="aAuth1YN">조회</a></th>
                                                        <th class="text-center"><a href="#" id="aAuth2YN">신규</a></th>
                                                        <th class="text-center"><a href="#" id="aAuth3YN">저장</a></th>
                                                        <th class="text-center"><a href="#" id="aAuth4YN">삭제</a></th>
                                                        <th class="text-center"><a href="#" id="aAuth5YN">출력</a></th>
                                                        <th class="text-center"><a href="#" id="aAuth6YN">승인</a></th>
                                                        <th class="text-center"><a href="#" id="aAuth7YN">기타2</a></th>
                                                        <th class="text-center"><a href="#" id="aAuth8YN">기타3</a></th>
                                                        <th class="text-center"><a href="#" id="aAuth9YN">기타4</a></th>
                                                        <th class="text-center"><a href="#" id="aAuth10YN">기타5</a></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr name="nodata-row" v-if="UserAuthList1 === null">
                                                        <td colspan="13" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                    </tr>
                                                    <tr name="data-row" v-else v-for="item in UserAuthList1">
                                                        <td class="text-center">
                                                            <label for="cb1" class="hidden">선택</label>
                                                            <input name="cb1" type="checkbox"
                                                                   :corpcd="item.CorpCd"
                                                                   :progrsystid="item.ProgrSystId"
                                                                   :progrgrpid="item.ProgrGrpId"
                                                                   :usersystid="item.UserSystId"
                                                                   :usergrpid="item.UserGrpId"
                                                                   :usergrpyn="item.UserGrpYN"
                                                                   :proggrpyn="item.ProgGrpYN" />
                                                        </td>
                                                        <td class="text-center">{{item.ProgGrpGb}}</td>
                                                        <td class="text-center">{{item.ProgrGrpName}}</td>
                                                        <td class="text-center">
                                                            <label for="cbAuth1YN" class="hidden">선택</label>
                                                            <input name="cbAuth1YN" type="checkbox" v-bind:checked="item.Auth1YN === 'Y'" />
                                                        </td>
                                                        <td class="text-center">
                                                            <label for="cbAuth2YN" class="hidden">선택</label>
                                                            <input name="cbAuth2YN" type="checkbox" v-bind:checked="item.Auth2YN === 'Y'" />
                                                        </td>
                                                        <td class="text-center">
                                                            <label for="cbAuth3YN" class="hidden">선택</label>
                                                            <input name="cbAuth3YN" type="checkbox" v-bind:checked="item.Auth3YN === 'Y'" />
                                                        </td>
                                                        <td class="text-center">
                                                            <label for="cbAuth4YN" class="hidden">선택</label>
                                                            <input name="cbAuth4YN" type="checkbox" v-bind:checked="item.Auth4YN === 'Y'" />
                                                        </td>
                                                        <td class="text-center">
                                                            <label for="cbAuth5YN" class="hidden">선택</label>
                                                            <input name="cbAuth5YN" type="checkbox" v-bind:checked="item.Auth5YN === 'Y'" />
                                                        </td>
                                                        <td class="text-center">
                                                            <label for="cbAuth6YN" class="hidden">선택</label>
                                                            <input name="cbAuth6YN" type="checkbox" v-bind:checked="item.Auth6YN === 'Y'" />
                                                        </td>
                                                        <td class="text-center">
                                                            <label for="cbAuth7YN" class="hidden">선택</label>
                                                            <input name="cbAuth7YN" type="checkbox" v-bind:checked="item.Auth7YN === 'Y'" />
                                                        </td>
                                                        <td class="text-center">
                                                            <label for="cbAuth8YN" class="hidden">선택</label>
                                                            <input name="cbAuth8YN" type="checkbox" v-bind:checked="item.Auth8YN === 'Y'" />
                                                        </td>
                                                        <td class="text-center">
                                                            <label for="cbAuth9YN" class="hidden">선택</label>
                                                            <input name="cbAuth9YN" type="checkbox" v-bind:checked="item.Auth9YN === 'Y'" />
                                                        </td>
                                                        <td class="text-center">
                                                            <label for="cbAuth10YN" class="hidden">선택</label>
                                                            <input name="cbAuth10YN" type="checkbox" v-bind:checked="item.Auth10YN === 'Y'" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <!-- 버튼영역-->
                                <div v-if="this.$parent.CanSave" class="tbl-option-btn-wrap">
                                    <a id="btnInstData" href="#" class="btn btn-default" @click.prevent="fn_InstData">
                                        <span class="glyphicon glyphicon-chevron-left" title=""></span>
                                    </a>
                                    <a id="btnRmveData" href="#" class="btn btn-default" @click.prevent="fn_RmveData">
                                        <span class="glyphicon glyphicon-chevron-right" title=""></span>
                                    </a>
                                </div>
                                <!-- //버튼영역 -->
                            </div>
                            <div class="col-xs-12 col-sm-4 tbl-option-right-wrap">
                                <h4 class="add-tlt">
                                    프로그램 그룹/프로그램 (<span id="spTotCnt2">{{UserAuthListTotalCount2}}</span>건)
                                </h4>
                                <div class="table-responsive-always table-responsive-height">
                                    <table class="table tbl-list table-hover table-bordered" id="tb2">
                                        <caption>기본 테이블</caption>
                                        <colgroup>
                                            <col />
                                            <col />
                                            <col />
                                            <col />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th class="text-center">
                                                    <label for="cbAll2" class="hidden">전체선택</label>
                                                    <input name="cbAll2" type="checkbox" @click="checkedAllEvent($event.target.checked, 'cb2')">
                                                </th>
                                                <th class="text-center">구분</th>
                                                <th class="text-center">분류</th>
                                                <th class="text-center">프로그램</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="UserAuthList2 === null">
                                                <td colspan="4" class="text-center no-data">조회된 정보가 없습니다.</td>
                                            </tr>
                                            <tr v-else v-for="item in UserAuthList2">
                                                <td class="text-center">
                                                    <label for="cb2" class="hidden">선택</label>
                                                    <input name="cb2" type="checkbox"
                                                           :corpcd="item.CorpCd"
                                                           :progrsystid="item.ProgrSystId"
                                                           :progrgrpid="item.ProgrGrpId"
                                                           :proggrpyn="item.ProgGrpYN"
                                                           :proggrpgb="item.ProgGrpGb"
                                                           :progrclsname="item.ProgrClsName"
                                                           :progrgrpname="item.ProgrGrpName"
                                                           :progrtypename="item.ProgrTypeName" />
                                                </td>
                                                <td class="text-center">{{item.ProgGrpGb}}</td>
                                                <td class="text-center">{{item.ProgrClsName}}</td>
                                                <td class="text-center">{{item.ProgrGrpName}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle'
    export default {
        name: 'ZZ1050R',
        components: {
            ViewTitle
        },
        data() {
            return {
                SystemIdList: null,
                ProgrClsList: null,
                UserAuthList: null,
                UserAuthList1: null, 
                UserAuthList2: null,
                UserAuthListTotalCount: 0,
                UserAuthListTotalCount1: 0,
                UserAuthListTotalCount2: 0,
                SelectTargetUserGroupId: 0
            }
        },
        beforeMount() {

            // 공통코드 조회
            this.SystemIdList = $.scriptBase.getCommonCode('CODE090', this.$parent.CompanyCode, '', '')
            this.ProgrClsList = $.scriptBase.getCommonCode('CODE000', '0901000', '', '')
        },
        mounted() {

            if (this.$parent.CanSearch) {

                this.fn_ShowData()
            }
        },
        methods: {
            checkedAllEvent: function (isChecked, targetElementName) {

                document.getElementsByName(targetElementName).forEach(function (item) {

                    item.checked = isChecked
                })

                if (targetElementName === 'cb1') {

                    document.getElementsByName('cbAll1').forEach(function (item) {

                        item.checked = isChecked
                    })
                }
                else if (targetElementName === 'cb2') {

                    document.getElementsByName('cbAll2').forEach(function (item) {

                        item.checked = isChecked
                    })
                }
            },
            targetUserGroupChangeEvent(e) {

                var target = e.target;

                this.SelectTargetUserGroupId = target.getAttribute('usergrpid')
                this.fn_ShowChld()
            },
            fn_ShowProg: function () {

                var target = document.querySelectorAll('input[name="cb0"]:checked')

                if (target.length > 0) {

                    var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/GetUserAuth'
                    url = url.toRealServiceUrl()
                    var obj = new Object()
                    obj.prm = new Array()
                    obj.prm.push('S3')
                    obj.prm.push(this.$parent.EmpId)
                    obj.prm.push(target[0].getAttribute('corpcd'))
                    obj.prm.push(target[0].getAttribute('usersystid'))
                    obj.prm.push(target[0].getAttribute('usergrpid'))
                    obj.prm.push($('#selProgrCls').val())
                    obj.prm.push('')

                    var parent = this
                    var successCallback = function (data) {
                        
                        var table = JSON.parse(data.d).Table

                        if (table.length > 0) {

                            parent.UserAuthList2 = table
                            parent.UserAuthListTotalCount2 = table.length
                        }
                        else {

                            parent.UserAuthList2 = null
                            parent.UserAuthListTotalCount2 = 0
                        }

                        parent.checkedAllEvent(false, 'cb2')
                    }

                    $.scriptBase.executeAjaxAsync(url, obj, successCallback);
                }
            },
            fn_ShowChld: function () {

                var target = document.querySelectorAll('input[name="cb0"]:checked')

                if (target.length > 0) {

                    var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/GetUserAuth'
                    url = url.toRealServiceUrl()

                    var obj = new Object();
                    obj.prm = new Array();
                    obj.prm.push('S2');
                    obj.prm.push(this.$parent.EmpId);
                    obj.prm.push(target[0].getAttribute('corpcd'))
                    obj.prm.push(target[0].getAttribute('usersystid'))
                    obj.prm.push(target[0].getAttribute('usergrpid'))
                    obj.prm.push($('#selProgrCls').val());
                    obj.prm.push('');

                    var parent = this
                    var successCallback = function (data) {
                        
                        var table = JSON.parse(data.d).Table;
                        var table1 = JSON.parse(data.d).Table1;

                        if (table.length > 0) {

                            parent.UserAuthList1 = table
                            parent.UserAuthListTotalCount1 = table.length
                        }
                        else {

                            parent.UserAuthList1 = null
                            parent.UserAuthListTotalCount1 = 0
                        }

                        if (table1.length > 0) {

                            parent.UserAuthList2 = table1
                            parent.UserAuthListTotalCount2 = table1.length
                        }
                        else {

                            parent.UserAuthList2 = null
                            parent.UserAuthListTotalCount2 = 0
                        }

                        parent.checkedAllEvent(false, 'cb1')
                        parent.checkedAllEvent(false, 'cb2')
                    }

                    $.scriptBase.executeAjaxAsync(url, obj, successCallback);
                }
            },
            fn_ShowData: function () {

                var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/GetUserAuth'
                url = url.toRealServiceUrl()

                var obj = new Object();
                obj.prm = new Array();
                obj.prm.push('S1');
                obj.prm.push(this.$parent.EmpId);
                obj.prm.push(this.$parent.CompanyCode);
                obj.prm.push($('#selSystId').val());
                obj.prm.push('');
                obj.prm.push('');
                obj.prm.push($('#selProgrCls').val());

                var parent = this
                var successCallback = function (data) {

                    var table = JSON.parse(data.d).Table
                    var table1 = JSON.parse(data.d).Table1
                    var table2 = JSON.parse(data.d).Table2

                    if (table.length > 0) {

                        parent.UserAuthList = table
                        parent.SelectTargetUserGroupId = table[0].UserGrpId
                        parent.UserAuthListTotalCount = table.length
                    }
                    else {

                        parent.UserAuthList = null
                        parent.SelectTargetUserGroupId = 0
                        parent.UserAuthListTotalCount = 0
                    }

                    if (table1.length > 0) {

                        parent.UserAuthList1 = table1
                        parent.UserAuthListTotalCount1 = table1.length
                    }
                    else {

                        parent.UserAuthList1 = null
                        parent.UserAuthListTotalCount1 = 0
                    }

                    if (table.length > 0) {

                        parent.UserAuthList2 = table2
                        parent.UserAuthListTotalCount2 = table2.length
                    }
                    else {

                        parent.UserAuthList2 = null
                        parent.UserAuthListTotalCount2 = 0
                    }

                    parent.checkedAllEvent(false, 'cb1')
                    parent.checkedAllEvent(false, 'cb2')
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            fn_InstData: function () {

                if (document.querySelectorAll('input[name="cb0"]:checked').length === 0) {

                    alert('대상 사용자 그룹/사용자를 선택하세요.')
                    return
                }

                if (document.querySelectorAll('input[name="cb2"]:checked').length === 0) {

                    alert('프로그램 그룹/프로그램을 선택하세요.')
                    return
                }

                var parent = this

                if (this.UserAuthList1 == null) {

                    this.UserAuthList1 = new Array();
                }

                document.querySelectorAll('input[name="cb2"]:checked').forEach(function (item) {
                    
                    var obj = new Object();
                    obj.CorpCd = item.getAttribute('corpcd')
                    obj.ProgrSystId = item.getAttribute('progrsystid')
                    obj.ProgrGrpId = item.getAttribute('progrgrpid')
                    obj.UserSystId = ''
                    obj.UserGrpId = ''
                    obj.UserGrpYN = ''
                    obj.ProgGrpYN = item.getAttribute('proggrpyn')
                    obj.Auth1YN = 'N'
                    obj.Auth2YN = 'N'
                    obj.Auth3YN = 'N'
                    obj.Auth4YN = 'N'
                    obj.Auth5YN = 'N'
                    obj.Auth6YN = 'N'
                    obj.Auth7YN = 'N'
                    obj.Auth8YN = 'N'
                    obj.Auth9YN = 'N'
                    obj.Auth10YN = 'N'
                    obj.ProgrGrpName = item.getAttribute('progrgrpname')
                    obj.ProgGrpGb = item.getAttribute('proggrpgb')
                    obj.ProgrClsName = item.getAttribute('progrclsname')
                    obj.ProgrTypeName = item.getAttribute('progrtypename')

                    // 선택한 프로그램 그룹 또는 프로그램 적용권한 목록에 추가
                    parent.UserAuthList1.push(obj)

                    var removeProgramGroupId = item.getAttribute('progrgrpid')
                    var removeItemIndex = parent.UserAuthList2.findIndex(function (item) {
                        return item.ProgrGrpId === removeProgramGroupId
                    })

                    // 적용권한 목록에 추가된 항목 프로그램 그룹 또는 프로그램 목록에서 삭제
                    if (removeItemIndex > -1) {

                        parent.UserAuthList2.splice(removeItemIndex, 1)
                    }

                    // 목록 카운터 재조정
                    parent.UserAuthListTotalCount1 = parent.UserAuthListTotalCount1 + 1
                    parent.UserAuthListTotalCount2 = parent.UserAuthListTotalCount2 - 1
                })

                this.checkedAllEvent(false, 'cb2')
            },
            fn_SaveData: function () {

                var parent = this
                var targetDocumentList = document.querySelectorAll('#tb1 tbody tr[name=data-row]')

                if (targetDocumentList.length <= 0) {

                    alert('저장할 적용권한이 없습니다.')
                    return
                }

                var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/ManipulateUserAuth'
                url = url.toRealServiceUrl()
                var obj = new Object();
                obj.prm = new Array();

                targetDocumentList.forEach(function (item) {

                    var tempArray = new Array();
                    tempArray.push("I");
                    tempArray.push(parent.$parent.EmpId);
                    tempArray.push(item.querySelector('input[name=cb1]').getAttribute("corpcd"));
                    tempArray.push(item.querySelector('input[name=cb1]').getAttribute("progrsystid"));
                    tempArray.push(item.querySelector('input[name=cb1]').getAttribute("progrgrpid"));
                    tempArray.push(document.querySelector('input[name="cb0"]:checked').getAttribute("usersystid"));
                    tempArray.push(document.querySelector('input[name="cb0"]:checked').getAttribute("usergrpid"));
                    tempArray.push(document.querySelector('input[name="cb0"]:checked').getAttribute("usergrpyn"));
                    tempArray.push(item.querySelector('input[name="cb1"').getAttribute("proggrpyn"));
                    tempArray.push(item.querySelector('input[name=cbAuth1YN]').checked ? "Y" : "N");
                    tempArray.push(item.querySelector('input[name=cbAuth2YN]').checked ? "Y" : "N");
                    tempArray.push(item.querySelector('input[name=cbAuth3YN]').checked ? "Y" : "N");
                    tempArray.push(item.querySelector('input[name=cbAuth4YN]').checked ? "Y" : "N");
                    tempArray.push(item.querySelector('input[name=cbAuth5YN]').checked ? "Y" : "N");
                    tempArray.push(item.querySelector('input[name=cbAuth6YN]').checked ? "Y" : "N");
                    tempArray.push(item.querySelector('input[name=cbAuth7YN]').checked ? "Y" : "N");
                    tempArray.push(item.querySelector('input[name=cbAuth8YN]').checked ? "Y" : "N");
                    tempArray.push(item.querySelector('input[name=cbAuth9YN]').checked ? "Y" : "N");
                    tempArray.push(item.querySelector('input[name=cbAuth10YN]').checked ? "Y" : "N");
                    obj.prm.push(tempArray);
                })

                var successCallback = function (data) {

                    if (data.d > 0) {

                        alert('저장 되었습니다.')

                        parent.fn_ShowChld();
                    }
                    else {

                        alert("저장 할 수 없습니다.");
                    }
                }
                $.scriptBase.executeAjax(url, obj, successCallback);
            },
            fn_RmveData: function () {

                if (document.querySelectorAll('input[name="cb0"]:checked').length === 0) {

                    alert('대상 사용자 그룹/사용자를 선택하세요.')
                    return
                }

                if (document.querySelectorAll('input[name="cb1"]:checked').length === 0) {

                    alert('삭제 할 적용권한을 선택하세요.')
                    return
                }

                var parent = this
                var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/ManipulateUserAuth'
                url = url.toRealServiceUrl()
                var obj = new Object();
                obj.prm = new Array();

                document.querySelectorAll('input[name="cb1"]:checked').forEach(function (item) {

                    var targetUserGroupId = item.getAttribute('usergrpid')
                    
                    if (targetUserGroupId != '') {

                        var tempArray = new Array();
                        tempArray.push("D");
                        tempArray.push(parent.$parent.EmpId);
                        tempArray.push(item.getAttribute("corpcd"));
                        tempArray.push(item.getAttribute("progrsystid"));
                        tempArray.push(item.getAttribute("progrgrpid"));
                        tempArray.push(item.getAttribute("usersystid"));
                        tempArray.push(item.getAttribute("usergrpid"));

                        for (var i = 0; i < 12; i++) tempArray.push('');

                        obj.prm.push(tempArray);
                    }
                })
                
                if (obj.prm.length > 0) {

                    var successCallback = function (data) {

                        if (data.d > 0) {
                            
                            parent.fn_ShowChld();
                            parent.checkedAllEvent(false, 'cb1')
                        }
                        else {

                            alert("삭제 할 수 없습니다.");
                        }
                    }

                    $.scriptBase.executeAjaxAsync(url, obj, successCallback);
                }
                else {
                    
                    parent.fn_ShowChld();
                    parent.checkedAllEvent(false, 'cb1')
                }
            }
        }
    }
</script>

<style scoped>
</style>