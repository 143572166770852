<template>
    <!--계획상세등록-->
    <div class="modal fade" id="PM1006E_Modal" ref="PM1006E_Modal" tabindex="-1" role="dialog" aria-labelledby="PM1006E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" @click.Prevent="close">
                        <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                    </button>
                    <h3 class="modal-title" v-if="PageActionType == 'I'">프로젝트 인력 운용 계획 등록</h3>
                    <h3 class="modal-title" v-if="PageActionType == 'V'">프로젝트 인력 운용 계획 상세</h3>
                </div>
                <div class="modal-body">
                    <section class="add-wrap" style="margin-top: -15px">
                        <form>
                            <fieldset style="margin-bottom: -30px;">
                                <legend>..</legend>
                                <section class="cols-wrap" v-show="PageActionType == 'I'">
                                    <div class="cols col-1">
                                        <label for="txtCompanyNm" class="control-label">
                                            <span>프로젝트명</span>
                                        </label>
                                        <div class="cols-btns-cont pad-r150">
                                            <input type="text" id="txtProjectNm" class="form-control" readonly="readonly" v-model="MMPlanMaster.ProjectNm" />
                                        </div>
                                        <div class="cols-btns">
                                            <a href="#" id="btnListPop1" class="btn btn-default" @click.prevent="searchProject">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i> 검색
                                                </span>
                                            </a>
                                            <a href="#" id="btnSrchInit" class="btn btn-default"
                                               @click.prevent="function(){
                                                    MMPlanMaster.ProjectCd = null
                                                    MMPlanMaster.ProjectNo = null
                                                    MMPlanMaster.ProjectNm = null
                                                    MMPlanMaster.CompanyCd = null
                                               }">
                                                <span>
                                                    <i class="glyphicon glyphicon-repeat"></i> 초기화
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </section>
                                <section class="cols-wrap" v-show="PageActionType == 'V'" style="margin-bottom: 3px;">
                                    <div class="cols col-1">
                                        <label for="txtCompanyNm2" class="control-label">
                                            <span>프로젝트명</span>
                                        </label>
                                        <input type="text" class="form-control" id="txtCompanyNm2" v-model="MMPlanMaster.ProjectNm" disabled />
                                    </div>
                                </section>
                                <section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2" v-if="PageActionType == 'V' && gridDisable == true">
                                            <label for="txtProjectManpowerStartDate" class="control-label">
                                                <span><i class="glyphicon"></i>프로젝트 수행기간</span>
                                            </label>
                                            <div class="col-2 input-group date form_date">
                                                <DxDateBox id="txtProjectManpowerStartDate" :disabled="true" v-model="ProjectPeriod.ProjectExeStartDt" class="form-control txt" type="date" display-format="yyyy-MM" dateSerializationFormat="yyyyMMdd" />
                                            </div>
                                            <span class="tail"><span>~</span></span>
                                            <div class="col-2 input-group date form_date">
                                                <DxDateBox id="txtProjectManpowerEndDate" :disabled="true" v-model="ProjectPeriod.ProjectExeEndDt" class="form-control txt" type="date" display-format="yyyy-MM" dateSerializationFormat="yyyyMMdd" />
                                            </div>
                                        </div>
                                        <div class="cols col-2" v-if="PageActionType == 'V' && gridDisable == true">
                                            <div style="margin: 5px -10px 3px 0px" class="fr">
                                                <label for="txtProjectManpower" class="control-label" style="margin-right: 50px">
                                                    <span><i class="glyphicon"></i>투입기간수정</span>
                                                </label>
                                                <a href="#" id="btnChldInst" class="btn btn-default" title="추가" @click.prevent="changeStartDt">
                                                    <span class="glyphicon"></span> 시작일
                                                </a>
                                                <button type="button" id="btnItemRmve" class="btn btn-default" @click.Prevent="changeEndDt">
                                                    <span class="glyphicon"></span> 종료일
                                                </button>
                                            </div>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtProjectManpowerStartDate" class="control-label">
                                                <span><i class="glyphicon"></i>운용계획 수행기간</span>
                                            </label>
                                            <div class="col-2 input-group date form_date">
                                                <DxDateBox id="txtProjectManpowerStartDate" :disabled="true" v-model="ProjectPeriod.ProjectMMStartDate" class="form-control txt" type="date" display-format="yyyy-MM" dateSerializationFormat="yyyyMMdd" />
                                            </div>
                                            <span class="tail"><span>~</span></span>
                                            <div class="col-2 input-group date form_date">
                                                <DxDateBox id="txtProjectManpowerEndDate" :disabled="true" v-model="ProjectPeriod.ProjectMMEndDate" class="form-control txt" type="date" display-format="yyyy-MM" dateSerializationFormat="yyyyMMdd" />
                                            </div>
                                        </div>
                                        <div class="cols col-2">
                                            <div style="margin: 5px -10px 3px 0px" class="fr">
                                                <label for="txtProjectManpower" class="control-label" style="margin-right: 50px">
                                                    <span><i class="glyphicon"></i>투입인력수정</span>
                                                </label>
                                                <a href="#" id="btnChldInst" class="btn btn-default" title="추가" @click.prevent="addProjectMMPlan">
                                                    <span class="glyphicon glyphicon-plus"></span> 추가
                                                </a>
                                                <button type="button" id="btnItemRmve" class="btn btn-default" @click.Prevent="deleteProjectMMPlan">
                                                    <span class="glyphicon glyphicon-minus"></span> 삭제
                                                </button>
                                            </div>
                                        </div>
                                    </section>
                                </section>
                                <section>
                                    <div class="grid-wrap">
                                        <DxDataGrid key-expr="InputSeqNo"
                                                    :data-source="MMPlanDetailList"
                                                    :columns="DefaultColumns"
                                                    :show-borders="true"
                                                    :show-row-lines="true"
                                                    @saving="changedCellValue"
                                                    @selection-changed="onSelectionChanged"
                                                    @focused-cell-changing="onFocusedCellChanging"
                                                    @editor-preparing="onEditorPreparing"
                                                    :selected-row-keys="selectedItemKeys"
                                                    :ref="ProjectMM"
                                                    :disabled="gridDisable"
                                                    style="max-height: 350px;">
                                            <DxScrolling mode="standard" useNative="false" />
                                            <DxKeyboardNavigation :edit-on-key-press="true"
                                                                  :enter-key-action="'startEdit'"
                                                                  :enter-key-direction="'row'" />
                                            <DxEditing :allow-updating="true"
                                                       start-edit-action="dblClick"
                                                       :new-row-position="'last'"
                                                       mode="cell" />
                                            <DxSelection mode="multiple"
                                                         :show-check-boxes-mode="'always'" />
                                            <DxPaging :enabled="false" />
                                        </DxDataGrid>
                                    </div>
                                </section>
                            </fieldset>
                        </form>
                    </section>
                </div>
                <div class="modal-footer">
                    <div class="btn-align">
                        <div class="btn-group">
                            <button type="button" id="btnItemSave" class="btn btn-primary" v-bind:disabled="DisableSave" @click.prevent="saveProjectMMPlan">
                                <i class="glyphicon glyphicon-save"></i> 저장
                            </button>
                        </div>
                        <div class="btn-group">
                            <button type="button" class="btn btn-danger" @click.Prevent="close">
                                <i class="glyphicon glyphicon-remove"></i> 취소
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <CP1021P ref="CP1021P"></CP1021P>
        <CP1060P ref="CP1060P"></CP1060P>
    </div>
</template>

<script>
    import CP1021P from '@/components/libs/popup/CP1021P';
    import CP1060P from '@/components/libs/popup/CP1060P';

    import { DxDateBox, DxCalendarOptions } from 'devextreme-vue/date-box';
    import DxDataGrid, { DxEditing, DxSelection, DxScrolling, DxKeyboardNavigation, DxPaging, } from 'devextreme-vue/data-grid';

    export default {
        name: 'PM1006E',
        components: {
            DxDateBox, DxCalendarOptions, DxDataGrid, DxEditing, DxSelection, DxScrolling, DxKeyboardNavigation, DxPaging, CP1021P, CP1060P
        },
        data() {
            return {
                CallbackFunction: null,
                ProjectMM: 'dataGrid',
                // 상세 입력 모드 - 신규(I)/수정(V)
                PageActionType: 'I',
                // 디폴트 컬럼 (프로젝트 수행기간에 따라 컬럼을 동적으로 생성)
                DefaultColumns: [
                    {
                        caption: "성명", dataField: "InputEmpNm", width: "120", alignment: 'center'
                    },
                    {
                        caption: "역할", dataField: "Role", width: "120", alignment: 'center', showEditorAlways: true,
                        lookup: {
                            dataSource: $.scriptBase.getCommonCode('CODE000', '0709000', '', ''),
                            valueExpr: "Code",
                            displayExpr: "Name"
                        }
                    },
                    {
                        caption: "등급", dataField: "Rating", width: "120", alignment: 'center', showEditorAlways: true,
                        lookup: {
                            dataSource: $.scriptBase.getCommonCode('CODE000', '0313000', '', ''),
                            valueExpr: "Code",
                            displayExpr: "Name"
                        }
                    },
                    {
                        caption: "주요업무", dataField: "MainJob", width: "200"
                    }
                ],
                // 동적으로 생성되는 월 컬럼
                DateColumnList: new Array(),
                // 인력운용계획 존재여부 조회 조건
                MMPlanSelectTerm: {
                    Arg: 'N',
                    ProjectCd: null,
                    RoleSeq: 0,
                    TargetYear: null,
                    StatusCd: null
                },
                // 프로젝트 수행기간, 인력운용계획 수행기간
                ProjectPeriod: {
                    ProjectMMStartDate: null,
                    ProjectMMEndDate: null,
                    ProjectExeStartDt: null,
                    ProjectExeEndDt: null
                },
                // 프로젝트 인력운용 마스터 객체
                MMPlanMaster: {
                    CompanyCd: null,
                    ProjectCd: null,
                    ProjectNo: null,
                    ProjectNm: null,
                    Rating: null,
                    Role: null,
                    MainJob: null,
                    RoleSeq: 0,
                    InputEmpNo: null,
                    Confirmation: null,
                    EmpNo: null,
                    StatusCd: null,
                    TargetYear: null
                },
                // 프로젝트 인력운용 디테일 목록
                MMPlanDetailList: new Array(),
                // 체크박스 선택된 아이템 목록
                selectedItemKeys: new Array(),
                // 인력운용 삭제 객체
                DeletePlanTerm: new Array(),
                // 데이터 수정 가능 여부
                gridDisable: false,
                // 저장 가능 여부
                DisableSave: true,
            }
        },
        methods: {
            open(callbackFunction, selectedObj) {

                this.CallbackFunction = callbackFunction;

                // 상세 조회
                if (selectedObj) {

                    this.PageActionType = 'V';
                    this.MMPlanSelectTerm.ProjectCd = selectedObj.ProjectCd;
                    this.MMPlanSelectTerm.RoleSeq = selectedObj.RoleSeq;

                    this.getManpowerList();
                    // 신규 생성
                } else {

                    this.PageActionType = 'I';
                    this.DisableSave = true;
                }

                $("#PM1006E_Modal").modal("show");
            },
            close() {

                this.CallbackFunction();
                this.reset();

                $("#PM1006E_Modal").modal("hide");
            },
            reset() {

                Object.assign(this.$data, this.$options.data());
            },
            // 컬럼 세팅 전 리셋
            resetColumns() {

                let cnt = this.DefaultColumns.length;

                for (var i = 4; i < cnt; i++) {
                    this.DefaultColumns.splice(4, 1);
                }
            },
            // 그리드 에디터 세팅
            onEditorPreparing(e) {

                // 셀렉트 박스 placeholder
                if ((e.dataField === "Role" || e.dataField === "Rating") && e.parentType === "dataRow") {
                    e.editorOptions.placeholder = '==선택==';
                }
            },
            // 셀 이동 표시
            onFocusedCellChanging(e) {

                e.isHighlighted = true;
            },
            // 체크박스 선택한 키 저장
            onSelectionChanged(e) {

                this.selectedItemKeys = e.selectedRowKeys;
            },
            // 프로젝트 검색
            searchProject() {

                let parent = this;

                var successCallback = function (result, year) {

                    parent.MMPlanSelectTerm.ProjectCd = result.ProjectCd;
                    parent.MMPlanSelectTerm.TargetYear = year;

                    parent.ProjectPeriod.ProjectMMStartDate = result.ExeStartYMD;
                    parent.ProjectPeriod.ProjectMMEndDate = result.ExeEndYMD;
                    parent.ProjectPeriod.ProjectExeStartDt = result.ExeStartYMD;
                    parent.ProjectPeriod.ProjectExeEndDt = result.ExeEndYMD;

                    // 프로젝트 선택 시 조회
                    parent.getManpowerList(result);
                }

                this.$refs.CP1060P.open(successCallback, null, null, true);
            },
            // 해당 프로젝트의 기존 인력운용 데이터 존재여부 조회
            getManpowerList(result) {

                var parent = this;

                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/GetNewManpower'.toRealServiceUrl();
                var obj = new Object();

                obj.jsonString = JSON.stringify(this.MMPlanSelectTerm);

                // 기존 데이터 있을 경우 디테일까지 조회
                var successCallback = function (data) {

                    var jsonObj = JSON.parse(data.d);

                    if (jsonObj.Table.length > 0) {

                        parent.MMPlanMaster = jsonObj.Table[0];

                        if (jsonObj.Table1.length > 0) {

                            // 운용계획 마스터 테이블 수행기간
                            parent.ProjectPeriod.ProjectMMStartDate = jsonObj.Table1[0].ConStartYMD;
                            parent.ProjectPeriod.ProjectMMEndDate = jsonObj.Table1[0].ConEndYMD;
                            // 프로젝트 테이블 수행기간
                            parent.ProjectPeriod.ProjectExeStartDt = jsonObj.Table1[0].ExeStartYMD;
                            parent.ProjectPeriod.ProjectExeEndDt = jsonObj.Table1[0].ExeEndYMD;
                        }

                        parent.PageActionType = 'V';
                        parent.checkExeDt();
                        parent.selectProjectMMPlan();
                    } else {

                        parent.MMPlanMaster.CompanyCd = result.CompanyCd;
                        parent.MMPlanMaster.ProjectCd = result.ProjectCd;
                        parent.MMPlanMaster.ProjectNo = result.ProjectNo;
                        parent.MMPlanMaster.ProjectNm = result.ProjectNm;
                        parent.MMPlanMaster.RoleSeq = 0;

                        parent.createProjectMMPlan();
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 월별투입인력 실제 조회
            selectProjectMMPlan() {

                let parent = this;
                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/GetProjectMMPlanList'.toRealServiceUrl();
                var obj = new Object();

                obj.jsonString = JSON.stringify(this.MMPlanMaster);

                var ajaxSuccessHandler = function (data) {

                    parent.resetColumns();

                    var jsonObj = JSON.parse(data.d);
                    let planCount = jsonObj.Table.length;

                    if (planCount > 0) {

                        parent.MMPlanDetailList = jsonObj.Table;
                        parent.DisableSave = false;

                        let columnsCount = (planCount > 0) ? Object.keys(jsonObj.Table[0]).length : 0;

                        for (let i = 0; i < columnsCount - 12; i++) {

                            let columnName = Object.keys(jsonObj.Table[0])[12 + i];
                            let newColumn = { 'caption': columnName, 'dataField': columnName, width: '120', alignment: 'right', editorOptions: { max: 1, min: 0 }, dataType: 'number' };
                            parent.DefaultColumns.push(newColumn);
                            parent.DateColumnList.push(columnName);
                        }
                    } else {

                        parent.MMPlanDetailList = new Array();
                        parent.DisableSave = true;
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler);
            },
            // 프로젝트와 인력운용계획의 수행기간 비교 후, 알림 메시지 변경
            checkExeDt() {

                if (this.ProjectPeriod.ProjectMMStartDate !== this.ProjectPeriod.ProjectExeStartDt && this.ProjectPeriod.ProjectMMEndDate !== this.ProjectPeriod.ProjectExeEndDt) {

                    this.gridDisable = true;
                    alert("투입기간수정 버튼을 통해 시작일과 종료일을 수정해주세요.");

                } else if (this.ProjectPeriod.ProjectMMStartDate !== this.ProjectPeriod.ProjectExeStartDt) {

                    this.gridDisable = true;
                    alert("투입기간수정 버튼을 통해 시작일을 수정해주세요.");

                } else if (this.ProjectPeriod.ProjectMMEndDate !== this.ProjectPeriod.ProjectExeEndDt) {

                    this.gridDisable = true;
                    alert("투입기간수정 버튼을 통해 종료일을 수정해주세요.");

                } else {

                    this.gridDisable = false;
                }
            },
            // 수행기간 시작일 수정
            changeStartDt() {

                if (this.ProjectPeriod.ProjectMMStartDate != this.ProjectPeriod.ProjectExeStartDt) {

                    if (confirm('수행기간 시작일을 수정하시겠습니까?')) {

                        let parent = this;
                        // 수행기간 추가 url
                        let url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/InsertSchedule'.toRealServiceUrl();
                        let DateUpdateTerm = new Object();

                        DateUpdateTerm.CompanyCd = this.MMPlanMaster.CompanyCd;
                        DateUpdateTerm.ProjectCd = this.MMPlanMaster.ProjectCd;
                        DateUpdateTerm.ProjectNo = this.MMPlanMaster.ProjectNo;
                        DateUpdateTerm.RoleSeq = this.MMPlanMaster.RoleSeq;

                        // 운용계획의 수행기간 시작일이 프로젝트 수행기간 시작일보다 이후일 때
                        if (this.ProjectPeriod.ProjectMMStartDate > this.ProjectPeriod.ProjectExeStartDt) {

                            DateUpdateTerm.StartYM = this.ProjectPeriod.ProjectExeStartDt;

                            if (this.ProjectPeriod.ProjectMMStartDate.length == 8) {

                                let formatNum = new Date(this.ProjectPeriod.ProjectMMStartDate.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3'));
                                let tempDate = new Date(formatNum.setMonth(formatNum.getMonth() - 1));
                                let yyyy = tempDate.getFullYear();
                                let mm = tempDate.getMonth() + 1;
                                let dd = 28;
                                DateUpdateTerm.EndYM = `${yyyy}${mm >= 10 ? mm : '0' + mm}${dd >= 10 ? dd : '0' + dd}`;
                            }
                        } else {

                            // 수행기간 삭제 url
                            url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/DeleteSchedule'.toRealServiceUrl();

                            DateUpdateTerm.StartEnd = 'S';
                            DateUpdateTerm.ExeYM = this.ProjectPeriod.ProjectExeStartDt;
                        }

                        let PlanUpdateTerm = new Array();
                        let DataCount = parent.MMPlanDetailList.length;
                        parent.ProjectPeriod.ProjectMMStartDate = parent.ProjectPeriod.ProjectExeStartDt;

                        for (var i = 0; i < DataCount; i++) {

                            parent.MMPlanMaster.Rating = parent.MMPlanDetailList[i].Rating;
                            parent.MMPlanMaster.Role = parent.MMPlanDetailList[i].Role;
                            parent.MMPlanMaster.MainJob = parent.MMPlanDetailList[i].MainJob;
                            parent.MMPlanMaster.InputEmpNo = parent.MMPlanDetailList[i].InputEmpNo;
                            parent.MMPlanMaster.EmpNo = parent.$store.getters.getEmpId;
                            parent.MMPlanMaster.ConStartYMD = parent.ProjectPeriod.ProjectMMStartDate;
                            parent.MMPlanMaster.ConEndYMD = parent.ProjectPeriod.ProjectMMEndDate;

                            let mmPlan = Object.assign({}, parent.MMPlanMaster);
                            PlanUpdateTerm.push(mmPlan);
                        }

                        let obj = new Object();
                        obj.jsonString = JSON.stringify(DateUpdateTerm);
                        obj.jsonString2 = JSON.stringify(PlanUpdateTerm);

                        var ajaxSuccessHandler = function (data) {

                            if (data.d > 0) {

                                alert("저장되었습니다.");
                                parent.checkExeDt();
                                parent.selectProjectMMPlan();
                            } else {

                                alert('수행기간 수정에 실패했습니다. 잠시 후 다시 시도해 주십시오.');
                                return;
                            }
                        }

                        $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler);
                    }
                } else {

                    alert('수행기간 시작일이 일치합니다.');
                }
            },
            // 수행기간 종료일 수정
            changeEndDt() {

                if (this.ProjectPeriod.ProjectMMEndDate != this.ProjectPeriod.ProjectExeEndDt) {

                    if (confirm('수행기간 종료일을 수정하시겠습니까?')) {

                        let parent = this;
                        // 수행기간 추가 url
                        let url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/InsertSchedule'.toRealServiceUrl();
                        let DateUpdateTerm = new Object();

                        DateUpdateTerm.CompanyCd = this.MMPlanMaster.CompanyCd;
                        DateUpdateTerm.ProjectCd = this.MMPlanMaster.ProjectCd;
                        DateUpdateTerm.ProjectNo = this.MMPlanMaster.ProjectNo;
                        DateUpdateTerm.RoleSeq = this.MMPlanMaster.RoleSeq;

                        if (this.ProjectPeriod.ProjectMMEndDate < this.ProjectPeriod.ProjectExeEndDt) {

                            DateUpdateTerm.EndYM = this.ProjectPeriod.ProjectExeEndDt;

                            if (this.ProjectPeriod.ProjectMMEndDate.length == 8) {

                                var formatNum = new Date(this.ProjectPeriod.ProjectMMEndDate.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3'));
                                var tempDate = new Date(formatNum.setMonth(formatNum.getMonth() + 1));
                                var yyyy = tempDate.getFullYear();
                                var mm = tempDate.getMonth() + 1;
                                var dd = 1;
                                DateUpdateTerm.StartYM = `${yyyy}${mm >= 10 ? mm : '0' + mm}${dd >= 10 ? dd : '0' + dd}`;
                            }
                        } else {

                            // 수행기간 삭제 url
                            url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/DeleteSchedule'.toRealServiceUrl();

                            DateUpdateTerm.StartEnd = 'E';
                            DateUpdateTerm.ExeYM = this.ProjectPeriod.ProjectExeEndDt;
                        }

                        let PlanUpdateTerm = new Array();
                        let DataCount = parent.MMPlanDetailList.length;
                        parent.ProjectPeriod.ProjectMMEndDate = parent.ProjectPeriod.ProjectExeEndDt;

                        for (var i = 0; i < DataCount; i++) {

                            parent.MMPlanMaster.Rating = parent.MMPlanDetailList[i].Rating;
                            parent.MMPlanMaster.Role = parent.MMPlanDetailList[i].Role;
                            parent.MMPlanMaster.MainJob = parent.MMPlanDetailList[i].MainJob;
                            parent.MMPlanMaster.InputEmpNo = parent.MMPlanDetailList[i].InputEmpNo;
                            parent.MMPlanMaster.EmpNo = parent.$store.getters.getEmpId;
                            parent.MMPlanMaster.ConStartYMD = parent.ProjectPeriod.ProjectMMStartDate;
                            parent.MMPlanMaster.ConEndYMD = parent.ProjectPeriod.ProjectMMEndDate;

                            let mmPlan = Object.assign({}, parent.MMPlanMaster);
                            PlanUpdateTerm.push(mmPlan);
                        }

                        let obj = new Object();
                        obj.jsonString = JSON.stringify(DateUpdateTerm);
                        obj.jsonString2 = JSON.stringify(PlanUpdateTerm);

                        var ajaxSuccessHandler = function (data) {

                            if (data.d > 0) {

                                alert("저장되었습니다.");
                                parent.checkExeDt();
                                parent.selectProjectMMPlan();
                            } else {

                                alert('수행기간 수정에 실패했습니다. 잠시 후 다시 시도해 주십시오.');
                                return;
                            }
                        }

                        $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler);
                    }
                } else {

                    alert('수행기간 종료일이 일치합니다.');
                }
            },
            // 월별 투입인력 새 그리드 생성
            createProjectMMPlan() {

                let parent = this;
                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/SelectBlankMMPlanList'.toRealServiceUrl();
                var obj = new Object();
                obj.jsonString = JSON.stringify(this.ProjectPeriod);

                var ajaxSuccessHandler = function (data) {

                    parent.resetColumns();

                    let jsonObj = JSON.parse(data.d);

                    if (jsonObj.Table.length > 0) {

                        parent.MMPlanDetailList = jsonObj.Table;

                        let columnsCount = Object.keys(jsonObj.Table[0]).length;

                        for (let i = 0; i < columnsCount; i++) {

                            let columnName = Object.keys(jsonObj.Table[0])[i];
                            let newColumn = { 'caption': columnName, 'dataField': columnName, width: '120', alignment: 'right', editorOptions: { max: 1, min: 0 }, dataType: 'number' };
                            parent.DefaultColumns.push(newColumn);
                            parent.DateColumnList.push(columnName);
                        }

                        parent.MMPlanDetailList[0].InputSeqNo = '0';
                        parent.DisableSave = false;
                    } else {

                        parent.MMPlanDetailList = new Array();
                        parent.DisableSave = true;
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler);
            },
            // 새로운 인력 추가
            addProjectMMPlan() {

                this.$refs[this.ProjectMM].instance.addRow();
                this.$refs[this.ProjectMM].instance.saveEditData();
            },
            // 직원 이름 입력 메소드 동작 & 새로운 인력 추가 시 셀 포커스
            changedCellValue(e) {

                let parent = this;
                let changedData = e.changes[0];

                if (changedData && changedData.type == 'update') {

                    // 인력명 필드가 아닌 셀 수정 시
                    if (changedData.data['InputEmpNm'] == undefined) {

                        return;
                        // 인력명 수정 시
                    } else {

                        let inputEmpNm = { InputEmpNm: changedData.data.InputEmpNm.replace(/ /g, '') };
                        let beforeName = parent.MMPlanDetailList.find((item) => item.InputSeqNo == changedData.key).InputEmpNm;

                        // 수정 전 인력명과 같을 때
                        if (inputEmpNm.InputEmpNm == beforeName) {

                            return;
                        }

                        this.searchEmpNm(inputEmpNm, changedData);
                    }
                    // 새로운 로우 추가 시
                } else if (changedData && changedData.type == 'insert') {

                    let newRowIndex = parent.MMPlanDetailList.length;
                    parent.$refs[parent.ProjectMM].instance.editCell(newRowIndex, 'InputEmpNm');
                }
            },
            // 인력명으로 검색
            searchEmpNm(inputEmpNm, changedData) {

                let parent = this;

                let url = '/Mini.Service.IBS.HM.Service/HumanMgt.svc/ajax/SearchInputEmpNm'.toRealServiceUrl();
                let obj = new Object();
                obj.jsonString = JSON.stringify(inputEmpNm);

                var successCallback = function (data) {

                    let jsonObj = JSON.parse(data.d);
                    let Count = jsonObj.Table[0]['Counting'];
                    let table = jsonObj.Table1[0];

                    // 검색된 직원이 여러 명일 때
                    if (Count > 1) {

                        alert("동명이인이 존재합니다. 검색을 통해 선택해 주십시오.");
                        parent.searchManager(inputEmpNm.InputEmpNm, changedData);

                        // 검색된 직원이 없을 때
                    } else if (Count == 0) {

                        parent.searchManager(inputEmpNm.InputEmpNm, changedData);
                    } else {

                        // 검색된 직원이 이미 입력된 인력인지 체크
                        let check = parent.MMPlanDetailList.some((item) => item.InputEmpNo == table.EmpNo);

                        // 중복이 아닐 경우
                        if (check == false) {

                            // 저장된 인력명을 변경할 경우, 변경 전 데이터 삭제를 위한 세팅
                            let beforeEmp = parent.MMPlanDetailList.find((item) => item.InputSeqNo == changedData.key);

                            if (beforeEmp != undefined) {

                                let deleteMMPlan = {
                                    CompanyCd: beforeEmp.CompanyCd,
                                    ProjectCd: beforeEmp.ProjectCd,
                                    ProjectNo: beforeEmp.ProjectNo,
                                    RoleSeq: beforeEmp.RoleSeq,
                                    InputEmpNo: beforeEmp.InputEmpNo,
                                    InputSeqNo: beforeEmp.InputSeqNo
                                };

                                parent.DeletePlanTerm.push(deleteMMPlan);
                            }

                            // DB에서 조회한 데이터를 수정한 입력일 때
                            if (typeof changedData.key == 'number') {

                                parent.MMPlanDetailList.forEach((item) => {
                                    if (item.InputSeqNo == changedData.key) {
                                        item.InputEmpNo = table.EmpNo;
                                        item.InputEmpNm = table.EmpNm;
                                    }
                                })
                                // 새로운 입력일 때
                            } else {

                                parent.MMPlanDetailList.forEach((item) => {
                                    if (item.InputEmpNm == inputEmpNm.InputEmpNm) {
                                        item.InputEmpNo = table.EmpNo;
                                    }
                                })
                            }
                            // 중복일 경우
                        } else {

                            alert('이미 입력된 인력입니다.');
                        }
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 인력검색 팝업
            searchManager(inputEmpNm, changedData) {

                let parent = this;

                var successCallback = function (result) {

                    let InputEmpInfo = new Object();

                    InputEmpInfo.EmpNo = result.EmpNo;
                    InputEmpInfo.EmpNm = result.EmpNm;

                    // 검색된 직원이 이미 입력된 인력인지 체크
                    let check = parent.MMPlanDetailList.some((item) => item.InputEmpNo == InputEmpInfo.EmpNo);

                    // 중복이 아닐 경우 그리드에 직원 정보 입력
                    if (check == false) {

                        // 저장된 인력명을 변경할 경우, 변경 전 데이터 삭제를 위한 세팅
                        let beforeEmp = parent.MMPlanDetailList.find((item) => item.InputSeqNo == changedData.key);

                        if (beforeEmp != undefined) {

                            let deleteMMPlan = {
                                CompanyCd: beforeEmp.CompanyCd,
                                ProjectCd: beforeEmp.ProjectCd,
                                ProjectNo: beforeEmp.ProjectNo,
                                RoleSeq: beforeEmp.RoleSeq,
                                InputEmpNo: beforeEmp.InputEmpNo,
                                InputSeqNo: beforeEmp.InputSeqNo
                            };

                            parent.DeletePlanTerm.push(deleteMMPlan);
                        }

                        // DB에서 조회한 데이터를 수정한 입력일 때
                        if (typeof changedData.key == 'number') {

                            parent.MMPlanDetailList.forEach((item) => {
                                if (item.InputSeqNo == changedData.key) {
                                    item.InputEmpNo = InputEmpInfo.EmpNo;
                                    item.InputEmpNm = InputEmpInfo.EmpNm;
                                }
                            })
                            // 새로운 입력일 때
                        } else {

                            parent.MMPlanDetailList.forEach((item) => {
                                if (item.InputEmpNm == inputEmpNm) {
                                    item.InputEmpNo = InputEmpInfo.EmpNo;
                                    item.InputEmpNm = InputEmpInfo.EmpNm;
                                }
                            })
                        }

                        // 그리드 데이터 재조회
                        parent.$refs[parent.ProjectMM].instance.refresh();
                        // 중복일 경우 입력한 성명 리셋
                    } else {

                        alert('이미 입력된 인력입니다.');
                    }
                }
                this.$refs.CP1021P.open(successCallback, null, inputEmpNm);
            },
            // 인력 개별 삭제
            deleteProjectMMPlan() {

                let parent = this;

                if (this.selectedItemKeys.length == 0) {

                    alert('삭제할 인력을 선택해주세요.');
                    return;
                }

                // 삭제할 항목 세팅
                let selectedRowsList = new Array();
                selectedRowsList = this.$refs[this.ProjectMM].instance.getSelectedRowsData();

                selectedRowsList.forEach((item) => {

                    let selectedItem = new Object();
                    
                    if (typeof item.InputSeqNo == 'number') {

                        selectedItem.CompanyCd = item.CompanyCd;
                        selectedItem.ProjectCd = item.ProjectCd;
                        selectedItem.ProjectNo = item.ProjectNo;
                        selectedItem.RoleSeq = item.RoleSeq;
                        selectedItem.InputSeqNo = item.InputSeqNo;
                        selectedItem.InputEmpNo = item.InputEmpNo;

                        parent.DeletePlanTerm.push(selectedItem);
                    }
                })

                // 삭제할 항목 그리드에서 삭제
                this.selectedItemKeys.forEach((element) => this.MMPlanDetailList = this.MMPlanDetailList.filter((item) => item.InputSeqNo != element));
            },
            // 운용인력계획 저장
            saveProjectMMPlan() {

                if (confirm('변경사항을 저장하시겠습니까?')) {

                    let parent = this;

                    // 그리드에서 삭제한 데이터, 저장 시 DB에서도 삭제
                    // DB에 이미 저장되어있는 객체만 남기기
                    this.DeletePlanTerm = this.DeletePlanTerm.filter(item => typeof item.InputSeqNo == 'number');

                    if (this.DeletePlanTerm.length > 0) {

                        let url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/DeleteProjectMMPlan'.toRealServiceUrl();
                        let obj = new Object();
                        obj.jsonString = JSON.stringify(this.DeletePlanTerm);

                        var ajaxSuccessHandler = function (data) {

                            if (data.d > 0) {

                                parent.selectedItemKeys = new Array();
                                parent.DeletePlanTerm = new Array();
                            } else {

                                alert('변경사항 저장에 실패했습니다. 잠시 후 다시 시도해 주십시오.');
                                return;
                            }
                        }

                        $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler);
                    }

                    // 전체 그리드 저장
                    let url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/UpdateProjectMMPlan'.toRealServiceUrl();

                    let wrongNmIndex = this.MMPlanDetailList.findIndex((item) => item.InputEmpNo == undefined || item.InputEmpNm == undefined || item.InputEmpNm.length < 2);

                    if (wrongNmIndex != -1) {

                        alert('인력명을 정확히 입력해 주십시오.');
                        this.$refs[this.ProjectMM].instance.editCell(wrongNmIndex, 1);
                        return;
                    }

                    let PlanCount = parent.MMPlanDetailList.length;
                    let MonthCount = parent.DateColumnList.length;

                    let MMPlanMasterUpdateTerm = new Array();
                    let MMPlanDetailUpdateTerm = new Array();

                    if (PlanCount == 0) {

                        alert('해당 프로젝트의 인력운용계획이 삭제되었습니다.');
                        parent.close();
                        return;
                    }

                    for (let i = 0; i < PlanCount; i++) {

                        // 마스터 저장 객체 세팅
                        if (parent.MMPlanDetailList[i].InputEmpNo.length == 8) {

                            parent.MMPlanMaster.Rating = parent.MMPlanDetailList[i].Rating ? parent.MMPlanDetailList[i].Rating : null;
                            parent.MMPlanMaster.Role = parent.MMPlanDetailList[i].Role ? parent.MMPlanDetailList[i].Role : null;
                            parent.MMPlanMaster.MainJob = parent.MMPlanDetailList[i].MainJob ? parent.MMPlanDetailList[i].MainJob : null;
                            parent.MMPlanMaster.InputEmpNo = parent.MMPlanDetailList[i].InputEmpNo;
                            parent.MMPlanMaster.EmpNo = parent.$store.getters.getEmpId;
                            parent.MMPlanMaster.Confirmation = '0736020';
                            parent.MMPlanMaster.ConStartYMD = this.moment(this.ProjectPeriod.ProjectMMStartDate).format('YYYYMMDD');
                            parent.MMPlanMaster.ConEndYMD = this.moment(this.ProjectPeriod.ProjectMMEndDate).format('YYYYMMDD');

                            let mmPlan = Object.assign({}, parent.MMPlanMaster);
                            MMPlanMasterUpdateTerm.push(mmPlan);

                            // 디테일 저장 객체 세팅
                            for (let k = 0; k < MonthCount; k++) {

                                // 월 컬럼명
                                let DateMM = parent.DateColumnList[k];
                                let MMPlanDetail = new Object();

                                MMPlanDetail.CompanyCd = parent.MMPlanMaster.CompanyCd;
                                MMPlanDetail.ProjectCd = parent.MMPlanMaster.ProjectCd;
                                MMPlanDetail.ProjectNo = parent.MMPlanMaster.ProjectNo;
                                MMPlanDetail.InputSeqNo = (typeof parent.MMPlanDetailList[i].InputSeqNo == 'string') ? null : parent.MMPlanDetailList[i].InputSeqNo;
                                MMPlanDetail.InputEmpNo = parent.MMPlanDetailList[i].InputEmpNo;
                                MMPlanDetail.InputMonth = (parent.DateColumnList[k]).slice(6, 8);
                                MMPlanDetail.InputYear = (parent.DateColumnList[k]).slice(0, 4);
                                MMPlanDetail.InputMM = parent.MMPlanDetailList[i][DateMM] ? parent.MMPlanDetailList[i][DateMM] : null;
                                MMPlanDetail.RoleSeq = parent.MMPlanMaster.RoleSeq;
                                MMPlanDetail.Arg = 'Up';
                                MMPlanDetail.EmpNo = this.$store.getters.getEmpId;

                                let projectInputManpowerSch = Object.assign({}, MMPlanDetail);

                                MMPlanDetailUpdateTerm.push(projectInputManpowerSch);
                            }
                        }
                    }

                    let obj = new Object();
                    obj.jsonString = JSON.stringify(MMPlanMasterUpdateTerm);
                    obj.jsonString2 = JSON.stringify(MMPlanDetailUpdateTerm);

                    var ajaxSuccessHandler = function (data) {

                        if (data.d > 0) {

                            alert("저장되었습니다.");
                            parent.close();
                        } else {

                            alert('변경사항 저장에 실패했습니다. 잠시 후 다시 시도해 주십시오.');
                            return;
                        }
                    }

                    $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler);
                }
            },
        }
    }
</script>
