<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <article class="sch-wrap">
                    <form role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="selTrgtYY" class="control-label">
                                            <span>대상년도</span>
                                        </label>
                                        <select id="selTrgtYY" class="form-control"
                                                v-model="ListSelectTerm.TargetYear">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="year in TargetYearList"
                                                    v-bind:value="year"
                                                    v-bind:selected="moment(new Date()).year() === year">
                                                {{year}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="selAprvDvsCd" class="control-label">
                                            <span>프로젝트</span>
                                        </label>
                                        <div class="cols-btns-cont pad-r150">
                                            <input type="text" id="txtProjectNm" class="form-control" readonly="readonly" v-model="ListSelectTerm.ProjectName" />
                                            <input type="hidden" id="hidProjectCd" v-model="ListSelectTerm.ProjectName" />
                                        </div>
                                        <div class="cols-btns">
                                            <a v-show="this.$parent.CanSearch" href="#" id="btnShowProject" class="btn btn-default"
                                               @click.prevent="searchProject(false)">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i> 검색
                                                </span>
                                            </a>
                                            <a v-show="this.$parent.CanSearch" href="#" id="btnSrchInit" class="btn btn-default"
                                               @click.prevent="function(e){
                                                    ListSelectTerm.ProjectName = null
                                                    ListSelectTerm.ProjectCode = null
                                               }">
                                                <span>
                                                    <i class="glyphicon glyphicon-repeat"></i> 초기화
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="selAprvDvsCd" class="control-label">
                                            <span>승인구분</span>
                                        </label>
                                        <select class="form-control" id="selAprvDvsCd" name="selAprvDvsCd" v-model="ListSelectTerm.ApprovalCategoryCode">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in AprvDvsCodeList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                </section>
                            </section>
                            <section class="sch-btn">
                                <a v-show="this.$parent.CanSearch" href="#" id="btnSearchData" class="btn btn-primary btn-row2"
                                   @click.prevent="getAdditionalExpenseBudgetApply">
                                    <span>
                                        <i class="glyphicon glyphicon-search"></i> 조회
                                    </span>
                                </a>
                            </section>
                        </fieldset>
                    </form>
                </article>
                <article class="view-btns-wrap ">
                    <div class="btn-group-justified">
                        <a v-show="this.$parent.CanNew" href="#" id="btnInstData" class="btn btn-default" @click.prevent="addAdditionalExpenseBudgetApply">
                            <span>
                                <i class="glyphicon glyphicon-plus"></i>신청
                            </span>
                        </a>
                    </div>
                </article>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">경비예산 추가신청</p>
                </div>
                <div>
                    <!--리스트 영역-->
                    <section class="tbl-contents">
                        <div class="alert alert-info total">
                            <p>
                                <i class="glyphicon glyphicon-folder-close"></i>
                                전체: <span id="spTotCnt">{{ListTotalCount}}</span>건
                            </p>
                        </div>
                        <div class="table-responsive">
                            <table class="table table tbl-list tbl-center table-hover table-bordered" id="tb0">
                                <caption>테이블</caption>
                                <colgroup>
                                    <col class="table-colwid15" />
                                    <col class="table-colwid10" />
                                    <col class="table-colwid10" />
                                    <col class="table-colwid10" />
                                    <col class="table-colwid20" />
                                    <col class="table-colwid10" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col">프로젝트명</th>
                                        <th scope="col">담당PM</th>
                                        <th scope="col">기본예산총액</th>
                                        <th scope="col">추가예산신청일</th>
                                        <th scope="col">추가예산금액</th>
                                        <th scope="col">승인구분</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="AdditionalExpenseBudgetApplyList === null">
                                        <td colspan="6" class="text-center no-data">조회된 정보가 없습니다.</td>
                                    </tr>
                                    <tr v-else v-for="item in AdditionalExpenseBudgetApplyList" style="cursor:pointer;"
                                        @click="getAdditionalExpenseBudgetApplyDetail(item)">
                                        <td>{{item.ProjectNm}}</td>
                                        <td>{{item.PMname}}</td>
                                        <td>{{item.BasePrice}}</td>
                                        <td>{{item.RegtDate}}</td>
                                        <td>{{item.AddPrice}}</td>
                                        <td>{{item.AprvNm}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="text-center">
                            <ul ref="ulpager" class="pagination"></ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <div class="modal fade" ref="AC1061E_Modal" tabindex="-1" role="dialog" aria-labelledby="AC1061E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">
                            <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                        </button>
                        <h3 class="modal-title">추가 경비예산 신청</h3>
                    </div>
                    <div class="modal-body">
                        <section class="add-wrap">
                            <form>
                                <fieldset>
                                    <legend>..</legend>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtProjectNm" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>프로젝트명
                                                </span>
                                            </label>
                                            <div class="cols-btns-cont">
                                                <input type="text" ref="txtProjectNm2" class="form-control txt" readonly="readonly" v-model="BasicAdditionalExpenseBudgetApplyDetail.ProjectNm" />
                                                <input type="hidden" id="hidProjectCd2" v-model="BasicAdditionalExpenseBudgetApplyDetail.ProjectCd" />
                                                <input type="hidden" id="hidCompanyCd2" v-model="BasicAdditionalExpenseBudgetApplyDetail.CompanyCd" />
                                            </div>
                                            <div class="cols-btns">
                                                <a href="#" id="btnSearchProject2" class="btn btn-default" 
                                                   v-show="PageActionType === 'I'"
                                                   @click.prevent="searchProject(true)">
                                                    <span>
                                                        <i class="glyphicon glyphicon-search"></i> 검색
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                        <section class="cols-wrap">
                                            <div class="cols col-2">
                                                <label for="selTrgtYY2" class="control-label">
                                                    <span>대상년도</span>
                                                </label>
                                                <select id="selTrgtYY2" class="form-control" disabled="disabled"
                                                        v-model="BasicAdditionalExpenseBudgetApplyDetail.TrgtYY">
                                                    <option value="">== 전체 ==</option>
                                                    <option v-for="year in TargetYearList"
                                                            v-bind:value="year"
                                                            v-bind:selected="moment(new Date()).year() === year">
                                                        {{year}}
                                                    </option>
                                                </select>
                                            </div>
                                        </section>
                                        <section class="cols-wrap">
                                            <div class="cols col-2">
                                                <label for="txtWorkPrice2" class="control-label">
                                                    <span>기본예산총액</span>
                                                </label>
                                                <input type="text" id="txtDefaultPrice2" class="form-control text-right" readonly="readonly" v-model="BasicAdditionalExpenseBudgetApplyDetail.DefaultPrice"/>
                                            </div>
                                            <div class="cols col-2">
                                                <label for="txtWorkPrice2" class="control-label">
                                                    <span>경비사용총액</span>
                                                </label>
                                                <input type="text" id="txtUsedPrice2" class="form-control text-right" readonly="readonly" v-model="BasicAdditionalExpenseBudgetApplyDetail.Tot"/>
                                            </div>
                                        </section>
                                        <section class="cols-wrap">
                                            <div class="cols">
                                                <label for="txtEtcTxt2" class="control-label">
                                                    <span>특이사항</span>
                                                </label>
                                                <textarea id="txtEtc2" class="form-control" style="height:100px; resize:none" v-model="BasicAdditionalExpenseBudgetApplyDetail.Remark"></textarea>
                                            </div>
                                        </section>
                                        <div class="cols-btns">
                                            <section class="btn-align">
                                                <a href="#" id="btnrmveChld2" class="btn btn-default"
                                                   v-show="PageActionType === 'I' || BasicAdditionalExpenseBudgetApplyDetail.ApprovalCd === '0117010'"
                                                   @click.prevent="removeAdditionalExpenseBudgetApplyDetail">
                                                    <span>
                                                        <i class="glyphicon glyphicon-minus"></i>삭제
                                                    </span>
                                                </a>
                                                <a href="#" ref="btninstChld2" class="btn btn-default"
                                                   v-show="PageActionType === 'I' || BasicAdditionalExpenseBudgetApplyDetail.ApprovalCd === '0117010'"
                                                   @click.prevent="addAdditionalExpenseBudgetApplyItem">
                                                    <span>
                                                        <i class="glyphicon glyphicon-plus"></i> 추가
                                                    </span>
                                                </a>
                                            </section>
                                        </div>
                                    </section>
                                    <section class="tbl-contents">
                                        <div class="table-responsive">
                                            <table class="table table tbl-list tbl-center table-hover table-bordered" id="tb_sub1">
                                                <caption>테이블</caption>
                                                <colgroup>
                                                    <col class="chk" />
                                                    <col width="10%" />
                                                    <col class="table-colwid5" />
                                                    <col width="15%" />
                                                    <col width="50%" />
                                                </colgroup>
                                                <thead>
                                                    <tr>
                                                        <th scope="col">선택</th>
                                                        <th scope="col">년월</th>
                                                        <th scope="col">계정</th>
                                                        <th scope="col">신청금액</th>
                                                        <th scope="col">신청사유</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-if="AdditionalExpenseBudgetApplyItemList === null">
                                                        <td colspan="5" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                    </tr>
                                                    <tr v-else v-for="object in AdditionalExpenseBudgetApplyItemList" v-show="!object.IsRemove">
                                                        <td class="chk">
                                                            <label>
                                                                <input type="checkbox" name="cbItem2" v-model="object.IsSelected"
                                                                       @click="{
                                                                            object.IsSelected = $event.target.checked
                                                                       }"/>
                                                            </label>
                                                        </td>
                                                        <td class="text-center">
                                                            <input type="text" name="txtRegtDate2" id="txtRegtDate2" class="form-control sel" maxlength="6" placeholder="YYYYMM"
                                                                   v-bind:value="object.TrgtYYMM"
                                                                   @input="{
                                                                        object.TrgtYYMM = $event.target.value.toNumber()
                                                                        $event.target.value = $event.target.value.toNumber()
                                                                   }"/>
                                                        </td>
                                                        <td class="text-center">
                                                            <select class="form-control sel" id="selAcntCd2" name="selAcntCd2" v-model="object.BudgetAccntCd">
                                                                <option value="">== 선택 ==</option>
                                                                <option v-for="item in AccountCodeList" v-bind:value="item.Code">
                                                                    {{item.Name}}
                                                                </option>
                                                            </select>
                                                        </td>
                                                        <td class="text-center">
                                                            <input type="text" style="text-align:right" name="txtAmt" class="form-control"
                                                                   v-bind:value="object.BudgetAmt"
                                                                   @input="{
                                                                        object.BudgetAmt = $event.target.value.toPriceString()
                                                                        $event.target.value = $event.target.value.toPriceString()
                                                                   }"/>
                                                        </td>
                                                        <td class="text-center">
                                                            <input type="text" name="txtRemark" class="form-control" v-model="object.Remark" />
                                                            <input type="hidden" name="hidKey" id="hidKey" v-model="object.ItemSeqNo" />
                                                        </td>
                                                    </tr>
                                                    <tr v-show="AdditionalExpenseBudgetApplyItemList != null" id="sumrow">
                                                        <td class="text-center" colspan="4">
                                                            추가예산총액 : <span id="txtAddTotPrice2">{{AdditionalExpenseBudgetTotalPrice}}</span>
                                                        </td>
                                                        <td class="text-center">
                                                            기본예산 + 추가예산 합계금액 : <span id="txtTotPrice2">{{ExpenseBudgetTotalPrice}}</span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                    <div class="cols col-2">
                                        <label for="txtProjectNm" class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>담당이사
                                            </span>
                                        </label>
                                        <div class="cols-btns-cont">
                                            <input type="text" ref="txtPMnm2" class="form-control txt" readonly="readonly" v-model="BasicAdditionalExpenseBudgetApplyDetail.PMname"/>
                                            <input type="hidden" id="hidPMno2" v-model="BasicAdditionalExpenseBudgetApplyDetail.PMno"/>
                                        </div>
                                        <div class="cols-btns">
                                            <a href="#" id="btnSearchPeople2" class="btn btn-default" 
                                               v-show="PageActionType === 'I' || BasicAdditionalExpenseBudgetApplyDetail.ApprovalCd === '0117010'"
                                               @click.prevent="searchManager">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i> 검색
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="cols col-2">
                                        <section class="btn-align">
                                            <label class="control-label">
                                                결제선 :
                                                <span v-show="Number(AdditionalExpenseBudgetTotalPrice.toNumber()) < 3000000">담당이사(CFO참조)</span>
                                                <span v-show="Number(AdditionalExpenseBudgetTotalPrice.toNumber()) >= 3000000 && Number(AdditionalExpenseBudgetTotalPrice.toNumber()) < 5000000">담당이사 -> CFO</span>
                                                <span v-show="Number(AdditionalExpenseBudgetTotalPrice.toNumber()) >= 5000000">담당이사 -> CFO -> CEO</span>
                                                &nbsp;&nbsp;

                                            </label>
                                            <a href="#" id="btnAprvReq2" class="btn btn-primary"
                                               v-show="this.$parent.CanSearch && BasicAdditionalExpenseBudgetApplyDetail.ApprovalCd === '0117010'"
                                               @click.prevent="saveAdditionalExpenseBudgetApply(true)">
                                                <span>
                                                    <i class="glyphicon glyphicon-save"></i>승인요청
                                                </span>
                                            </a>
                                            <a href="#" id="btnItemSave2" class="btn btn-warning"
                                               v-show="this.$parent.CanSearch && BasicAdditionalExpenseBudgetApplyDetail.ApprovalCd === '0117010'"
                                               @click.prevent="saveAdditionalExpenseBudgetApply(false)">
                                                <span>
                                                    <i class="glyphicon glyphicon-edit"></i>저장
                                                </span>
                                            </a>
                                            <a href="#" class="btn btn-danger" data-dismiss="modal">
                                                <span>
                                                    <i class="glyphicon glyphicon-remove"></i>취소
                                                </span>
                                            </a>
                                        </section>
                                    </div>
                                </fieldset>
                            </form>
                        </section>
                        <label class="control-label">
                            <span>
                                ※ 예산 총액에 따른 결재 단계 <br /> &nbsp;
                                <span id="1r">300만원 미만 : 담당이사(CFO참조) </span><br />&nbsp;
                                <span id="2r">300만원 이상 ~ 500만원 미만 : 담당이사 -> CFO </span><br /> &nbsp;
                                <span id="3r">500만원 이상 : 담당이사 -> CFO -> CEO</span>
                            </span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <CP1020P ref="CP1020P"></CP1020P>
        <CP1060P ref="CP1060P"></CP1060P>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle'
    import CP1020P from '@/components/libs/popup/CP1020P'
    import CP1060P from '@/components/libs/popup/CP1060P'
    export default {
        name: 'AC1060R',
        components: {
            ViewTitle, CP1020P, CP1060P
        },
        data() {
            return {
                AprvDvsCodeList: null,                          // 승인구분 공통코드 목록
                AccountCodeList: null,                          // 계정 공통코드 목록
                AdditionalExpenseBudgetApplyList: null,         // 경비예산 추가신청 목록
                AdditionalExpenseBudgetApplyItemList: null,     // 경비예산 추가신청 아이템 목록
                ListTotalCount: 0,                              // 경비예산 추가신청 목록 전체 건수
                AdditionalExpenseBudgetTotalPrice: '0',         // 추가예산총액
                ExpenseBudgetTotalPrice: '0',                   // 총 예산(기본 + 추가)
                // 경비예산 추가신청 목록 조회 조건 객체
                ListSelectTerm: {
                    Arg: null,
                    UserId: this.$parent.EmpId,
                    CompanyCode: this.$parent.CompanyCode,
                    TargetYear: this.moment(new Date()).year(),
                    ProjectName: null,
                    ProjectCode: null,
                    ApprovalCategoryCode: '',
                    TopSize: 10,
                    CurrentPage: 1
                },
                // 기본경비예산 추가신청 상세 객체
                BasicAdditionalExpenseBudgetApplyDetail: {
                    Arg: null,
                    UserId: this.$parent.EmpId,
                    CorpCd: this.$parent.CompanyCode,
                    CompanyCd: null,
                    ProjectCd: null,
                    ProjectNm: null,
                    TrgtYY: null,
                    DefaultPrice: null,
                    Remark: null,
                    ApprovalCd: '0117010',
                    PMno: null,
                    PMname: null,
                    SeqNo: null,
                    Tot: null
                },
                // 경비예산 추가신청 아이템 객체
                AdditionalExpenseBudgetApplyItem: {
                    IsNew: false,
                    IsRemove: false,
                    IsSelected: false,
                    Arg: null,
                    UserId: this.$parent.EmpId,
                    CorpCd: this.$parent.CompanyCode,
                    CompanyCd: null,
                    ProjectCd: null,
                    TrgtYY: null,
                    SeqNo: null,
                    ItemSeqNo: null,
                    TrgtYYMM: null,
                    BudgetAccntCd: null,
                    BudgetAmt: null,
                    Remark: null
                },
                TargetYearList: new Array(),                    // 대상년도 목록
                PageActionType: null                            // 화면 액션값 설정 (I:신규, V: 상세보기)
            }
        },
        beforeMount() {

            // 공통코드 조회
            this.AprvDvsCodeList = $.scriptBase.getCommonCode('CODE000', '0117000', '', '')
            this.AccountCodeList = $.scriptBase.getCommonCode('CODE100', '0001', '', '')

            var startYear = this.moment(new Date()).add(1, 'year').year()
            var endYear = this.moment(new Date(2010, 1, 1, 0, 0, 0)).year()

            // 대상년도 목록 값 설정
            for (var i = startYear; i >= endYear; i--) {

                this.TargetYearList.push(i)
            }
        },
        mounted() {

            if (this.$parent.CanSearch) {

                this.getAdditionalExpenseBudgetApply()
            }
        },
        watch: {
            AdditionalExpenseBudgetApplyItemList: {
                deep: true,
                handler: function (newData) {

                    var parent = this

                    // 추가예산 총액 초기화
                    this.AdditionalExpenseBudgetTotalPrice = '0'

                    if (newData != null) {

                        // 추가예산 총액 계산
                        newData.forEach(function (object) {

                            // 삭제건은 제외
                            if (!object.IsRemove) {

                                parent.AdditionalExpenseBudgetTotalPrice = (Number(parent.AdditionalExpenseBudgetTotalPrice.toNumber())
                                    + Number(object.BudgetAmt.toNumber())).toPriceNumber()
                            }
                        })

                        var defaultPrice = Number(this.BasicAdditionalExpenseBudgetApplyDetail.DefaultPrice.toNumber())
                        var additionalExpenseBudgetTotalPrice = Number(this.AdditionalExpenseBudgetTotalPrice.toNumber())

                        // 기본예산 + 추가예산 총합 계산
                        this.ExpenseBudgetTotalPrice = (defaultPrice + additionalExpenseBudgetTotalPrice).toPriceNumber()
                    }
                }
            }
        },
        methods: {
            // 담당이사 조회
            searchManager() {

                var parent = this
                var successCallback = function (result) {

                    //parent.ExpenseBudgetApplyDetail.ChrgEmpNm = result.EmpNm
                    //parent.ExpenseBudgetApplyDetail.ChrgEmpNo = result.EmpNo
                }

                this.$refs.CP1020P.showManPowerPopup(successCallback)
            },
            // 프로젝트 검색
            // isDetail: 상세팝업에서 검색인지 여부
            searchProject: function (isDetail) {

                var parent = this
                var successCallback = function (result) {

                    if (isDetail) {

                        parent.BasicAdditionalExpenseBudgetApplyDetail.ProjectNm = result.ProjectNm
                        parent.BasicAdditionalExpenseBudgetApplyDetail.ProjectCd = result.ProjectCd
                        parent.BasicAdditionalExpenseBudgetApplyDetail.CompanyCd = result.CompanyCd

                        // 프로젝트 경비예산신청 상세조회
                        parent.getProjectExpenseBudgetApplyDetail(result.CompanyCd, result.ProjectCd, result.ContractYMD.substring(0, 4))
                    }
                    else {

                        parent.ListSelectTerm.ProjectCode = result.ProjectCd

                        // 프로젝트 선택 시 목록 조회 실행
                        if (parent.$parent.CanSearch) {

                            parent.getAdditionalExpenseBudgetApply()
                        }
                    }
                }

                this.$refs.CP1060P.showProjectPopup(successCallback)
            },
            // 페이지 변경 이벤트
            // pageIndex: 선택한 페이지 번호
            pageIndexChangeEvent: function (pageIndex) {

                this.ListSelectTerm.CurrentPage = pageIndex
                this.getAdditionalExpenseBudgetApply()
            },
            // 경비예산추가신청 조회
            getAdditionalExpenseBudgetApply: function () {

                var parent = this
                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/GetAdditionalExpenseBudgetApply'.toRealServiceUrl()
                var obj = new Object()

                this.ListSelectTerm.Arg = 'L'

                obj.jsonString = JSON.stringify(this.ListSelectTerm)

                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d)
                    var table = jsonData.Table
                    var table1 = jsonData.Table1

                    // 목록
                    if (table.length > 0) {

                        parent.AdditionalExpenseBudgetApplyList = table
                    }
                    else {

                        parent.AdditionalExpenseBudgetApplyList = null
                    }

                    // 목록 전체 카운터
                    if (table1.length > 0) {

                        parent.ListTotalCount = table1[0].TotCnt

                        // pager 설정
                        $(parent.$refs.ulpager).pager({
                            pager: parent.$refs.ulpager,
                            totalItemCount: parent.ListTotalCount,
                            recordPerPage: parent.ListSelectTerm.TopSize,
                            currentPage: parent.ListSelectTerm.CurrentPage,
                            pageIndexChanged: parent.pageIndexChangeEvent
                        })
                    }
                    else {

                        parent.ListTotalCount = 0
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            // 경비예산추가신청 상세 조회
            getAdditionalExpenseBudgetApplyDetail: function (targetRow) {

                this.PageActionType = 'V'

                var parent = this
                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/GetAdditionalExpenseBudgetApplyDetail'.toRealServiceUrl()
                var obj = new Object()

                obj.jsonString = JSON.stringify({
                    'Arg': 'R',
                    'CorpCd': targetRow.CorpCd,
                    'CompanyCd': targetRow.CompanyCd,
                    'ProjectCd': targetRow.ProjectCd,
                    'TargetYY': targetRow.TrgtYY,
                    'SeqNo': targetRow.SeqNo
                })

                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d)
                    var table = jsonData.Table
                    var table1 = jsonData.Table1
                    var table2 = jsonData.Table2
                    
                    if (table.length > 0) {
                        
                        Object.assign(parent.BasicAdditionalExpenseBudgetApplyDetail, table[0])

                        parent.BasicAdditionalExpenseBudgetApplyDetail.UserId = parent.$parent.EmpId,
                        parent.BasicAdditionalExpenseBudgetApplyDetail.DefaultPrice = parent.BasicAdditionalExpenseBudgetApplyDetail.DefaultPrice.toPriceNumber()
                        parent.BasicAdditionalExpenseBudgetApplyDetail.Tot = table2[0].tot.toPriceNumber()
                        parent.BasicAdditionalExpenseBudgetApplyDetail.BudgetType = '0115020'
                    }
                    else {

                        parent.AdditionalExpenseBudgetApplyList = null
                    }

                    if (table1.length > 0) {

                        // 금액 포맷 변경
                        table1.forEach(function (object) {

                            object.IsNew = false
                            object.IsRemove = false
                            object.IsSelected = false
                            object.UserId = parent.$parent.EmpId,
                            object.BudgetAmt = object.BudgetAmt.toPriceNumber()
                        })

                        parent.AdditionalExpenseBudgetApplyItemList = table1
                    }
                    else {

                        parent.AdditionalExpenseBudgetApplyItemList = null
                    }

                    $(parent.$refs.AC1061E_Modal).modal('show')
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            // 프로젝트 경비예산신청 상세 조회
            getProjectExpenseBudgetApplyDetail: function (companyCd, projectCd, targetYY) {

                var parent = this
                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/GetAdditionalExpenseBudgetApplyDetail'.toRealServiceUrl()
                var obj = new Object()

                obj.jsonString = JSON.stringify({
                    'Arg': 'P',
                    'CorpCd': this.$parent.CompanyCode,
                    'CompanyCd': companyCd,
                    'ProjectCd': projectCd,
                    'TargetYY': targetYY,
                    'SeqNo': null
                })

                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d)
                    var table = jsonData.Table
                    var table1 = jsonData.Table1

                    if (table.length > 0) {

                        parent.BasicAdditionalExpenseBudgetApplyDetail.TrgtYY = table[0].TrgtYY
                        parent.BasicAdditionalExpenseBudgetApplyDetail.DefaultPrice = table[0].DefaultPrice.toPriceNumber()
                        parent.BasicAdditionalExpenseBudgetApplyDetail.Tot = table1[0].tot.toPriceNumber()
                        parent.BasicAdditionalExpenseBudgetApplyDetail.PMno = table[0].PMno
                        parent.BasicAdditionalExpenseBudgetApplyDetail.PMname = table[0].PMname
                    }
                    else {

                        parent.BasicAdditionalExpenseBudgetApplyDetail.TrgtYY = ''
                        parent.BasicAdditionalExpenseBudgetApplyDetail.DefaultPrice = null
                        parent.BasicAdditionalExpenseBudgetApplyDetail.Tot = null
                        parent.BasicAdditionalExpenseBudgetApplyDetail.PMno = null
                        parent.BasicAdditionalExpenseBudgetApplyDetail.PMname = null

                        parent.BasicAdditionalExpenseBudgetApplyDetail.ProjectNm = null
                        parent.BasicAdditionalExpenseBudgetApplyDetail.ProjectCd = null
                        parent.BasicAdditionalExpenseBudgetApplyDetail.CompanyCd = null

                        alert('작성된 기본예산편성정보가 없습니다. 기본예산을 먼저 편성해주세요')
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            // 경비예산추가신청 신규 등록
            addAdditionalExpenseBudgetApply: function () {

                this.PageActionType = 'I'

                // 기본 경비예산추가신청 상세 객체 초기화
                this.BasicAdditionalExpenseBudgetApplyDetail = {
                    Arg: null,
                    UserId: this.$parent.EmpId,
                    CorpCd: this.$parent.CompanyCode,
                    CompanyCd: null,
                    ProjectCd: null,
                    ProjectNm: null,
                    TrgtYY: null,
                    DefaultPrice: null,
                    Remark: null,
                    ApprovalCd: '0117010',
                    PMno: null,
                    PMname: null,
                    SeqNo: null,
                    Tot: null,
                    BudgetType: '0115020'
                }

                // 경비예산추가신청 상세 목록 객체 초기화
                this.AdditionalExpenseBudgetApplyItemList = null

                $(this.$refs.AC1061E_Modal).modal('show')
            },
            // 경비예산추가신청 저장
            saveAdditionalExpenseBudgetApply: function (isApproval) {

                if (this.BasicAdditionalExpenseBudgetApplyDetail.ProjectCd === null
                    || this.BasicAdditionalExpenseBudgetApplyDetail.ProjectCd === '') {

                    alert('프로젝트를 선택해주세요')
                    this.$refs.txtProjectNm2.focus()
                }
                else if (this.BasicAdditionalExpenseBudgetApplyDetail.PMno === null
                    || this.BasicAdditionalExpenseBudgetApplyDetail.PMno === '') {

                    alert('프로젝트 담당이사를 선택해주세요')
                    this.$refs.txtPMnm2.focus()
                }
                else if (this.AdditionalExpenseBudgetApplyItemList === null
                    || this.AdditionalExpenseBudgetApplyItemList.length === 0) {

                    alert('신청하실 경비내역을 추가해주세요.')
                    this.$refs.btninstChld2.focus()
                }
                else {

                    var isValidate = true

                    // 추가예산 목록 유효성 검사
                    for (var i = 0; i < this.AdditionalExpenseBudgetApplyItemList.length; i++) {

                        var object = this.AdditionalExpenseBudgetApplyItemList[i]

                        if (object.TrgtYYMM === null || object.TrgtYYMM === '') {

                            alert('사용년월을 입력해주세요')
                            isValidate = false
                            break
                        }
                        else if (object.BudgetAccntCd === null || object.BudgetAccntCd === '') {

                            alert('계정과목을 선택해주세요')
                            isValidate = false
                            break
                        }
                        else if (object.BudgetAmt === null || object.BudgetAmt === '' || object.BudgetAmt === '0') {

                            alert('신청금액을 입력해주세요')
                            isValidate = false
                            break
                        }
                        else if (object.Remark === null || object.Remark === '') {

                            alert('신청사유 입력해주세요')
                            isValidate = false
                            break
                        }
                        else {

                            var existCount = 0

                            // 같은 년월에 같은 계정과목이 존재하는지 체크
                            for (var j = 0; j < this.AdditionalExpenseBudgetApplyItemList.length; j++) {

                                var tempObject = this.AdditionalExpenseBudgetApplyItemList[j]

                                if (object.TrgtYYMM === tempObject.TrgtYYMM
                                    && object.BudgetAccntCd === tempObject.BudgetAccntCd) {

                                    existCount = existCount + 1
                                }
                            }

                            if (existCount > 1) {

                                alert('같은 년/월에 같은 계정값은 입력하실 수 없습니다.')
                                isValidate = false
                                break
                            }
                        }
                    }
                    
                    if (isValidate) {

                        var parent = this
                        var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/SaveAdditionalExpenseBudgetApply'.toRealServiceUrl()
                        var obj = new Object()
                        var tempList = new Array()
                        
                        this.BasicAdditionalExpenseBudgetApplyDetail.Arg = 'I'

                        // 추가예산 총액 설정
                        this.BasicAdditionalExpenseBudgetApplyDetail.BudgetTotAmt = this.AdditionalExpenseBudgetTotalPrice

                        this.BasicAdditionalExpenseBudgetApplyDetail.ApprovalCd = isApproval ? '0117020' : '0117010'

                        obj.jsonString = JSON.stringify(this.BasicAdditionalExpenseBudgetApplyDetail)
                        
                        // 삭제되지 않은 추가예산건만 저장
                        this.AdditionalExpenseBudgetApplyItemList.forEach(function (object) {

                            if (!object.IsRemove) {

                                object.Arg = 'II'
                                tempList.push(object)
                            }
                        })

                        obj.jsonAdditionalExpenseBudgetListString = JSON.stringify(tempList)
                        
                        // 서비스호출 성공 콜백 함수
                        var successCallback = function (data) {

                            if (data.d > 0) {

                                if (parent.PageActionType === 'I') {

                                    alert('등록 되었습니다.')
                                    $(parent.$refs.AC1061E_Modal).modal('hide')

                                    // 목록 재조회
                                    parent.getAdditionalExpenseBudgetApply()
                                }
                                else {

                                    alert('저장 되었습니다.')

                                    // 목록 재조회
                                    parent.getAdditionalExpenseBudgetApply()

                                    // 상세 재조회
                                    parent.getAdditionalExpenseBudgetApplyDetail(parent.BasicAdditionalExpenseBudgetApplyDetail)
                                }
                            }
                        }

                        $.scriptBase.executeAjaxAsync(url, obj, successCallback)
                    }
                }
            },
            // 경비예산추가신청 아이템 목록 등록
            addAdditionalExpenseBudgetApplyItem: function () {

                var projectName = this.BasicAdditionalExpenseBudgetApplyDetail.ProjectNm

                if (projectName != null && projectName != '') {

                    // 목록 객체가 null이면 초기화
                    if (this.AdditionalExpenseBudgetApplyItemList === null
                        || this.AdditionalExpenseBudgetApplyItemList === '') {

                        this.AdditionalExpenseBudgetApplyItemList = new Array()
                    }

                    var tempObject = Object.assign({}, this.AdditionalExpenseBudgetApplyItem)

                    tempObject.BudgetAccntCd = ''
                    tempObject.IsNew = true
                    tempObject.BudgetAmt = '0'
                    tempObject.CompanyCd = this.BasicAdditionalExpenseBudgetApplyDetail.CompanyCd
                    tempObject.ProjectCd = this.BasicAdditionalExpenseBudgetApplyDetail.ProjectCd
                    tempObject.TrgtYY = this.BasicAdditionalExpenseBudgetApplyDetail.TrgtYY

                    this.AdditionalExpenseBudgetApplyItemList.push(tempObject)
                }
                else {

                    alert('프로젝트를 선택하여 주세요.')
                }
            },
            // 경비예산추가신청 상세 목록 삭제
            removeAdditionalExpenseBudgetApplyDetail: function () {

                var parent = this

                if (this.AdditionalExpenseBudgetApplyItemList === null
                    || this.AdditionalExpenseBudgetApplyItemList === '') {

                    alert('삭제할 항목을 선택하세요.')
                }
                else {

                    if (confirm('삭제 하시겠습니까?')) {

                        var removeList = new Array()

                        this.AdditionalExpenseBudgetApplyItemList.forEach(function (object) {

                            object.IsRemove = object.IsSelected

                            // DB에서 삭제할 목록 설정 (기존 데이터 삭제건)
                            if (object.IsRemove && !object.IsNew) {

                                object.Arg = 'DI'
                                removeList.push(object)
                            }
                        })
                        
                        var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/RemoveAdditionalExpenseBudgetApply'.toRealServiceUrl()
                        var obj = new Object()

                        obj.jsonString = JSON.stringify(removeList)

                        var successCallback = function (data) {

                            if (data.d > 0) {

                                alert('삭제 되었습니다.')

                                // 상세 재조회
                                parent.getAdditionalExpenseBudgetApplyDetail(parent.BasicAdditionalExpenseBudgetApplyDetail)
                            }
                        }

                        $.scriptBase.executeAjaxAsync(url, obj, successCallback)
                    }
                }
            }
        }
    }
</script>