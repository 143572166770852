<template>
    <!--영업 이력 추가 상세-->
    <div class="modal fade" id="BZ1002E_Modal" ref="BZ1002E_Modal" tabindex="-1" role="dialog" aria-labelledby="BZ1002E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">
                        <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                    </button>
                    <h3 class="modal-title">업체 영업이력</h3>
                </div>
                <div class="modal-body">
                    <section class="add-wrap">
                        <form>
                            <fieldset>
                                <legend></legend>
                                <section class="cols-wrap">
                                    <div class="cols col-12">
                                        <label for="txtProjectNm5" class="control-label">
                                            <span>업체영업명</span>
                                        </label>
                                        <input type="text" id="txtBizNm" class="form-control txt" readonly="readonly" v-model="BusinessHistoryDetail.CompanyNm" />
                                        <input type="hidden" id="hidCompanyCd" v-model="BusinessHistoryDetail.CompanyCd" />
                                        <input type="hidden" id="hidHistory" v-model="BusinessHistoryDetail.BizNo" />
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols">
                                        <label for="txtHistoryNm" class="control-label">
                                            <span>영업이력명</span>
                                        </label>
                                        <input type="text" id="txtHistoryNm" class="form-control txt" v-model="BusinessHistoryDetail.HistoryNm" />
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtBizDate" class="control-label">
                                            <span>영업일</span>
                                        </label>
                                        <DxDateBox id="txtBizDate" v-model="BusinessHistoryDetail.BizDate" type="date" display-format="yyyy-MM-dd"></DxDateBox>
                                        <!--<div class="input-group date form_date">
                                            <input type="text" id="txtBizDate" class="form-control" maxlength="10" v-model:value="BusinessHistoryDetail.BizDate" />
                                            <span class="input-group-addon">
                                                <span class="glyphicon glyphicon-calendar"></span>
                                            </span>
                                        </div>-->
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <label for="txtHistoryDesc" class="control-label"><span>영업내용</span></label>
                                    <div class="board-add-write" >
                                        <DxHtmlEditor height="280px" value-type="html" v-model="BusinessHistoryDetail.HistoryDesc">
                                            <DxMediaResizing :enabled="true" />
                                            <DxToolbar :multiline="isMultiLine">
                                                <DxItem format-name="undo" />
                                                <DxItem format-name="redo" />
                                                <DxItem format-name="separator" />
                                                <DxItem :format-values="sizeValues"
                                                        format-name="size" />
                                                <DxItem format-name="separator" />
                                                <DxItem format-name="bold" />
                                                <DxItem format-name="italic" />
                                                <DxItem format-name="strike" />
                                                <DxItem format-name="underline" />
                                                <DxItem format-name="separator" />
                                                <DxItem format-name="orderedList" />
                                                <DxItem format-name="bulletList" />
                                                <DxItem format-name="separator" />
                                                <DxItem format-name="color" />
                                                <DxItem format-name="background" />
                                                <DxItem format-name="separator" />
                                                <DxItem format-name="link" />
                                                <DxItem format-name="separator" />
                                                <DxItem format-name="clear" />
                                                <DxItem format-name="codeBlock" />
                                                <DxItem format-name="blockquote" />
                                            </DxToolbar>
                                        </DxHtmlEditor>
                                        <!--<textarea ref="txtHistoryDesc" rows="10" class="form-control" style="width:100%; height:10%;" v-model="BusinessHistoryDetail.HistoryDesc"> </textarea>-->
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtDueDate" class="control-label">
                                            <span>시작 예정일</span>
                                        </label>

                                        <DxDateBox  id="txtDueDate" v-model="BusinessHistoryDetail.DueDate" type="date" display-format="yyyy-MM-dd"></DxDateBox>

                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtMonths" class="control-label">
                                            <span>개월</span>
                                        </label>
                                            <input id="txtMonths" class="form-control txt" type="number" v-model.number="BusinessHistoryDetail.Months" />

                                    </div>
                                </section>
                                <section class="cols-wrap">

                                    <div class="cols col-2">
                                        <label for="txtEstimatedPrice" class="control-label">
                                            <span>예상 가격</span>
                                        </label>
                                            <Currency-input id="txtEstimatedPrice" class="form-control txt" v-model="BusinessHistoryDetail.EstimatedPrice"></Currency-input>

                                    </div>

                                </section>
                                <section class="cols-wrap">
                                    <div class="btn-wrap border-bottom">
                                        <div class="fr">
                                            <a href="#" id="btnChldInst5" class="btn btn-default" title="추가" @click.prevent="addAttachFile">
                                                <span class="glyphicon glyphicon-plus"></span> 추가
                                            </a>
                                            <a href="#" id="btnChldRmve5" class="btn btn-default" title="삭제" @click.prevent="removeAttachFile">
                                                <span class="glyphicon glyphicon-minus"></span> 삭제
                                            </a>
                                        </div>
                                    </div>
                                </section>
                                <section class="tbl-contents" style="overflow-y: auto; height: 100px;">
                                    <div class="table-responsive">
                                        <form id="form5" action="" name="form5" method="post" enctype="multipart/form-data">
                                            <table class="table tbl-list table-hover table-bordered" id="tb5">
                                                <colgroup>
                                                    <col class="chk" />
                                                    <col />
                                                </colgroup>
                                                <thead>
                                                    <tr>
                                                        <th class="chk table-colwid5">선택</th>
                                                        <th scope="col" class="text-center">첨부파일</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-if="BusinessHistoryFileList === null">
                                                        <td colspan="2" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                    </tr>
                                                    <tr v-else v-for="businessHistoryFile in BusinessHistoryFileList" v-show="businessHistoryFile.IsShow">
                                                        <td class="chk">
                                                            <label>
                                                                <input v-model="businessHistoryFile.IsChecked" type="checkbox" name="cbItem2"
                                                                       @click="function(e){
                                                                                businessHistoryFile.IsRemove = e.target.checked
                                                                           }" />
                                                            </label>
                                                        </td>
                                                        <td v-show="businessHistoryFile.IsNew" class="text-center">
                                                            <input type="file" name="ctlAttachFile"
                                                                   @change="function(e){
                                                                            businessHistoryFile.Files = e.target.files
                                                                       }" />
                                                        </td>
                                                        <td v-show="!businessHistoryFile.IsNew" class="text-left">
                                                            <a href="#" @click.prevent="fileHandler.download(businessHistoryFile.GUID)">{{businessHistoryFile.FileName}}</a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                    </div>
                                </section>
                            </fieldset>
                        </form>
                    </section>
                    <section class="btn-align">
                        <a v-show="this.$parent.$parent.CanSave" href="#" id="btnItemSave5" class="btn btn-primary" @click.prevent="saveBusinessHistory">
                            <span>
                                <i class="glyphicon glyphicon-save"></i> 저장
                            </span>
                        </a>
                        <a v-show="this.$parent.$parent.CanDelete" href="#" id="btnItemRmve5" class="btn btn-warning" @click.prevent="removeAntecedents">
                            <span>
                                <i class="glyphicon glyphicon-edit"></i> 삭제
                            </span>
                        </a>
                        <a href="#" class="btn btn-danger" data-dismiss="modal">
                            <span>
                                <i class="glyphicon glyphicon-remove"></i> 취소
                            </span>
                        </a>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'
    import {
        DxDateBox
    } from 'devextreme-vue'
    import {
        DxHtmlEditor,
        DxToolbar,
        DxMediaResizing,
        DxItem
    } from 'devextreme-vue/html-editor'

    export default {
        name: 'BZ1002E',
        components: {
            DxDateBox, DxHtmlEditor, DxToolbar, DxMediaResizing, DxItem
        },
        data() {
            return {
                CallbackFunction: null,
                BusinessHistoryFileList: null,  // 영업관리 이력 상세 파일 목록
                // 영업관리 이력 상세객체
                BusinessHistoryDetail: {
                    CorpCd: null,       // 회사코드
                    CompanyCd: null,    // 업체코드
                    CompanyNm: null,    // 업체이름
                    BizNo: null,        // 업체영업명 hidHistory
                    BizDate: moment(new Date()).format('YYYY-MM-DD'),      // 영업일 날짜
                    BizNm: null,        // 업체영업명 이름
                    SeqNo: null,        // 업체이력 순서
                    HistoryNm: null,    // 영업이력명
                    HistoryDesc: null,  // 영업내용
                    EstimatedPrice: null, 
                    DueDate: null,
                    Months: null,
                    RegtDate: null,
                    EmpNm: null         // 등록자
                },  
                // 영업관리 이력 파일상세객체
                BusinessHistoryFileDetail: {
                    IsNew: false,
                    IsRemove: false, 
                    IsShow: true,
                    IsChecked: false,
                    Files: null,
                    CorpCd: null,
                    CompanyCd: null,
                    BizNo: null,
                    SeqNo: null,
                    FileSeq: null,
                    FileName: null,
                    GUID: null
                },
                sizeValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'],
                isMultiLine: true
            }
        },
        beforbeforeMount() {
            
        },
        methods: {
            open: function (callbackFunction, bizObj, selectedObj, fileListObj) {
                this.CallbackFunction = callbackFunction

                if (selectedObj) {
                    this.BusinessHistoryDetail = Object.assign({}, selectedObj)
                }
                else {
                    this.reset()
                    this.BusinessHistoryDetail.CompanyNm = bizObj.CompanyNm,   // 업체 영업 이름
                    this.BusinessHistoryDetail.CompanyCd = bizObj.CompanyCd,   // 업체 영업 코드
                    this.BusinessHistoryDetail.BizNo = bizObj.BizNo           // 부모 hidden 번호
                }

                this.BusinessHistoryDetail.Arg = 'PI'
                this.BusinessHistoryDetail.UserId = this.$parent.$parent.EmpId
                this.BusinessHistoryDetail.CorpCd = this.$parent.$parent.CompanyCode

                this.BusinessHistoryFileList = fileListObj

                $("#BZ1002E_Modal").modal("show")
            },
            close: function () {
                $("#BZ1002E_Modal").modal("hide")
            },
            reset: function () {
                Object.assign(this.$data, this.$options.data())
            },
            // 업체 영업 이력 저장
            saveBusinessHistory: function () {

                var that = this

                var successUploadFileCallback = function (result) {

                    that.doSaveBusinessHistory(result)
                }

                if (this.BusinessHistoryDetail.HistoryNm === '' || this.BusinessHistoryDetail.HistoryNm === null) {

                    alert('영업이력명을 입력하세요.')
                    $(this.$refs.txtHistoryNm).focus()
                    return
                }
                if (this.BusinessHistoryDetail.BizDate === '' || this.BusinessHistoryDetail.BizDate === null) {

                    alert('영업일을 입력하세요')
                    $(this.$refs.txtBizDate).focus()
                    return
                }
                if (this.BusinessHistoryDetail.HistoryDesc === '' || this.BusinessHistoryDetail.HistoryDesc === null) {

                    alert('영업내용을 입력하세요.')
                    $(this.$refs.txtHistoryDesc).focus()
                    return
                }

                if (this.BusinessHistoryFileList != null && this.BusinessHistoryFileList != '') {

                    // 신규 파일이 존재하는지 체크
                    var isExistNewFile = this.BusinessHistoryFileList.some(function (file) {
                        return file.IsNew
                    })

                    var newFileList = new Array()

                    if (isExistNewFile) {

                        var isValidateFiles = true

                        for (var i = 0; i < this.BusinessHistoryFileList.length; i++) {

                            var file = this.BusinessHistoryFileList[i]

                            if (file.IsNew && !file.IsRemove) {

                                if (file.Files != null) {

                                    for (var j = 0; j < file.Files.length; j++) {

                                        newFileList.push(file.Files[j])
                                    }
                                }
                                else {

                                    alert('첨부파일을 선택하세요.')

                                    isValidateFiles = false
                                    break
                                }
                            }
                        }

                        if (isValidateFiles) {

                            this.fileHandler.upload(newFileList, successUploadFileCallback)
                        }
                    }
                }
                else {

                    that.doSaveBusinessHistory(null)
                }
            },
            // 영업이력 삭제 버튼 클릭 이벤트 핸들러
            removeAntecedents: function () {

                if (this.BusinessHistoryDetail === null && this.PageActionType === 'I') {

                    alert('삭제할 정보가 존재 하지 않습니다.')
                    return
                }

                if (confirm('삭제하시겠습니까?')) {

                    var that = this

                    var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/RemoveTraderEstimate'
                    url = url.toRealServiceUrl()
                    var obj = new Object()

                    // 삭제 시 필요한 객체 추가
                    this.BusinessHistoryDetail.Arg = 'PD'
                    this.BusinessHistoryDetail.UserId = that.$parent.$parent.EmpId
                    this.BusinessHistoryDetail.CompanyCode = that.$parent.$parent.CompanyCode
                    this.BusinessHistoryDetail.CorpCd = that.$parent.$parent.CompanyCode

                    obj.jsonString = JSON.stringify(this.BusinessHistoryDetail)


                    // 서비스호출 성공 콜백 함수
                    var successCallback = function (data) {

                        $(that.$refs.BZ1002E_Modal).modal('hide')
                        that.$parent.getBusiness()
                    }

                    $.scriptBase.executeAjaxAsync(url, obj, successCallback)

                }
            },
            // 업체 영업 이력 저장 실제 실행
            doSaveBusinessHistory: function (uploadFiles) {

                var that = this

                var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/SaveTraderEstimate'
                url = url.toRealServiceUrl()
                var obj = new Object()
                var attachFileList = new Array()

                obj.jsonString = JSON.stringify(this.BusinessHistoryDetail)

                if (uploadFiles != null) {
                    uploadFiles.files.forEach(function (object) {

                        attachFileList.push({
                            'Arg': 'CI',
                            'UserId': that.$parent.$parent.EmpId,
                            'CorpCd': that.$parent.$parent.CompanyCode,
                            'CompanyCd': that.BusinessHistoryDetail.CompanyCd,
                            'BizNo': that.BusinessHistoryDetail.BizNo,
                            'GUID': object.nFileName,
                            'FileName': object.oFileName,
                        })
                    })

                }

                obj.jsonFileString = JSON.stringify(attachFileList)

                // 서비스호출 성공 콜백 함수
                var successCallback = function (data) {

                    if (that.PageActionType === 'I') {

                        alert('등록 되었습니다.')
                        $(that.$refs.BZ1002E_Modal).modal('hide')
                    }
                    else {

                        alert('저장 되었습니다.')
                        $(that.$refs.BZ1002E_Modal).modal('hide')

                    }
                    // 등록 후 그리드 재 조회
                    that.$parent.getBusiness()
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            // 첨부파일 추가
            addAttachFile: function () {

                if (this.BusinessHistoryFileList === null || this.BusinessHistoryFileList === '') {

                    this.BusinessHistoryFileList = new Array()
                }

                // 신규파일 추가
                this.BusinessHistoryFileList.push({
                    IsNew: true,
                    IsRemove: false,
                    IsShow: true,
                    IsChecked: false,
                    Files: null,
                    CorpCd: null,
                    CompanyCd: null,
                    BizNo: null,
                    SeqNo: null,
                    FileSeq: null,
                    FileName: null,
                    GUID: null
                })
            },
            // 첨부파일 삭제
            removeAttachFile: function () {

                var that = this
                var removeFileList = new Array()

                // 삭제할 파일이 존재하는지 체크
                var isExist = this.BusinessHistoryFileList.some(function (object) {

                    return object.IsChecked
                })

                if (isExist) {

                    if (confirm('삭제 하시겠습니까?')) {

                        // 삭제할 파일들만 별도의 리스트 객체에 담기
                        this.BusinessHistoryFileList.forEach(function (object) {

                            // 선택한 파일 삭제 플래그 변경
                            object.IsRemove = object.IsChecked

                            // 기존파일은 DB에서 삭제하기위해 별도로 모음
                            if (!object.IsNew && object.IsChecked === true) {

                                object.Arg = 'CD'
                                object.UserId = that.$parent.$parent.EmpId
                                removeFileList.push(object)
                            }
                        })

                        var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/RemoveBusinessHistoryFile'
                        url = url.toRealServiceUrl()
                        var obj = new Object()

                        obj.jsonFilrString = JSON.stringify(removeFileList)

                        // 서비스호출 성공 콜백 함수
                        var successCallback = function (data) {

                            $(that.$refs.BZ1002E_Modal).modal('hide')
                            that.$parent.getBusiness()
                        }

                        $.scriptBase.executeAjaxAsync(url, obj, successCallback)

                    }
                    else {
                        alert('삭제할 파일이 없습니다.')
                    }
                }
                else {

                    alert('삭제할 파일이 없습니다.')
                }
            }

        }
    }

</script>

<style scoped>
</style>