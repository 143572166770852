<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <div class="btn-wrap">
                    <a v-show="this.$parent.CanSearch" href="#" id="btnListSrch" class="btn btn-primary btn-row2" @click.prevent="showCollectMoney">
                        <span>
                            <i class="glyphicon glyphicon-search"></i> 조회
                        </span>
                    </a>
                    <a v-show="this.$parent.CanNew" href="#" id="btnListInst" class="btn btn-default" @click.prevent="btnAddClicked">
                        <span>
                            <i class="glyphicon glyphicon-plus"></i> 추가
                        </span>
                    </a>
                </div>
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="selDateType" class="control-label">
                                            <span>발행예정일</span>
                                        </label>
                                        <div class="col-xs-3">
                                            <select class="form-control" id="selDateType" @change="dateTypeChangeEvent">
                                                <option value="1">오늘</option>
                                                <option value="2">일주일</option>
                                                <option value="3">한달</option>
                                                <option value="4">일년</option>
                                                <option value="5" selected="selected">이번달</option>
                                            </select>
                                        </div>
                                        <div class="col-xs-9">
                                            <label for="txtStartDate" class="sr-only">
                                                <span>발행예정일</span>
                                            </label>

                                            <div class="col-2 input-group date">
                                                <DxDateBox display-format="yyyy-MM-dd"
                                                           pickerType="calendar"
                                                           v-model="CollectMoneySelectTerm.StartDate"
                                                           @value-changed="showCollectMoney"
                                                           style="border-radius:0;">
                                                </DxDateBox>
                                            </div>
                                            <div class="tail"><span>~</span></div>
                                            <label for="txtEndDate" class="sr-only"><span>발행예정일</span></label>
                                            <div class="col-2 input-group date">
                                                <DxDateBox display-format="yyyy-MM-dd"
                                                           pickerType="calendar"
                                                           v-model="CollectMoneySelectTerm.EndDate"
                                                           @value-changed="showCollectMoney"
                                                           style="border-radius:0;">
                                                </DxDateBox>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtCompanyNm" class="control-label">
                                            <span>프로젝트</span>
                                        </label>
                                        <div class="cols-btns-cont pad-r150">
                                            <input type="text" id="txtProjectNm" class="form-control txt" readonly="readonly" v-model="CollectMoneySelectTerm.ProjectName" @change="showCollectMoney" />
                                            <input type="hidden" id="hidProjectCd" v-model="CollectMoneySelectTerm.ProjectCode" />
                                            <input type="hidden" id="hidCompanyNm" v-model="CollectMoneySelectTerm.CompanyName" />
                                            <input type="hidden" id="hidCompanyCd" v-model="CollectMoneySelectTerm.CompanyCode" />
                                        </div>
                                        <div class="cols-btns">
                                            <a href="#" id="btnListPop1" class="btn btn-default" @click.prevent="searchProjectCode">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i> 검색
                                                </span>
                                            </a>
                                            <a href="#" id="btnSrchInit" class="btn btn-default" @click.prevent="clearProjectCode">
                                                <span><i class="glyphicon glyphicon-repeat"></i> 초기화</span>
                                            </a>
                                        </div>
                                    </div>
                                </section>
                            </section>

                        </fieldset>
                    </form>
                </div>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">수금계획관리</p>
                </div>
                <div>
                    <!--리스트 영역-->
                    <section class="tbl-contents">
                        <div class="alert alert-info total">
                            <p><i class="glyphicon glyphicon-folder-close"></i> <span>전체: <span id="spTotCnt">{{TotalCount}}</span>건</span></p>
                        </div>
                        <div class="table-responsive">
                            <DxDataGrid id="grid-container"
                                        :data-source="CollectMoneyList"
                                        :hoverStateEnabled="true"
                                        :show-borders="false"
                                        @cell-prepared="onCellPrepared"
                                        @row-click="rowClickEvent"
                                        style="max-height: 500px; vertical-align: top;">
                                <DxScrolling mode="standard" useNative="false" />
                                <DxPaging :enabled="false" />
                                <DxColumn data-field="BillDueStartYMD" caption="발행예정일" width="10%" alignment="center" />
                                <DxColumn data-field="PayInDueStartYMD" caption="입금예정일" width="10%" alignment="center" />
                                <DxColumn data-field="ProjectNm" caption="프로젝트" width="30%" alignment="left" />
                                <DxColumn data-field="CompanyNm" caption="거래처" width="15%" alignment="left" />
                                <DxColumn data-field="CollectDvsName" caption="수금유형" width="7%" alignment="center" />
                                <DxColumn data-field="Price" caption="금액" width="9%" alignment="right" />
                                <DxColumn data-field="VAT" caption="부가세" width="9%" alignment="right" />
                                <DxColumn data-field="BillYMDUseYN" caption="세금계산서 발행여부" width="10%" alignment="center" />
                            </DxDataGrid>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <CP1060P ref="CP1060P"></CP1060P>
        <PM1021E ref="PM1021E"></PM1021E>
    </div>
</template>

<style>
    .radio-control {
        float: left;
        padding-bottom: 20px;
    }
</style>

<script>
    import ViewTitle from '@/components/ViewTitle';
    import CP1060P from '@/components/libs/popup/CP1060P';
    import PM1021E from '@/components/pm/popup/PM1021E';
    import { DxDataGrid, DxColumn, DxPaging, DxScrolling, } from 'devextreme-vue/data-grid';
    import DxDateBox, { DxCalendarOptions } from 'devextreme-vue/date-box';

    export default {
        name: 'PM1020R',
        components: {
            ViewTitle,
            CP1060P,
            PM1021E,
            DxDataGrid,
            DxColumn,
            DxPaging,
            DxScrolling,
            DxDateBox,
            DxCalendarOptions,
        },
        data() {
            return {
                // 수금계획관리 목록
                CollectMoneyList: new Array(),
                // 조회조건 데이터 객체
                CollectMoneySelectTerm: {
                    Arg: 'L',
                    UserId: this.$parent.EmpId,
                    CorpCd: this.$parent.CompanyCode,
                    ProjectCode: '',
                    ProjectName: '',
                    ProjectPrice: 0,
                    ProjectLeftPrc: 0,
                    ManagerNm: '',
                    CompanyName: '',
                    CompanyCode: '',
                    StartDate: this.moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD'),
                    EndDate: this.moment(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)).format('YYYY-MM-DD'),
                },
                // 수금계획관리 총 개수
                TotalCount: 0,
            }
        },
        mounted() {

            // 초기조회
            if (this.$parent.CanSearch) {

                this.showCollectMoney(false);
            }
        },
        methods: {
            // Datagrid header 가운데 정렬, column 중앙 정렬
            onCellPrepared(e) {

                if (e.rowType == 'header') {

                    e.cellElement.style['textAlign'] = 'center';
                }
                if (e.rowType == 'data') {

                    e.cellElement.style['verticalAlign'] = 'middle';
                }
            },
            // 발행예정일 날짜 타입 변경 이벤트
            dateTypeChangeEvent(event) {

                var dateType = Number(event.target.value);

                switch (dateType) {
                    case 1: {

                        this.CollectMoneySelectTerm.StartDate = this.moment(new Date()).format('YYYY-MM-DD');
                        this.CollectMoneySelectTerm.EndDate = this.moment(new Date()).format('YYYY-MM-DD');
                        break;
                    }
                    case 2: {

                        this.CollectMoneySelectTerm.StartDate = this.moment(new Date()).add(-7, 'days').format('YYYY-MM-DD');
                        this.CollectMoneySelectTerm.EndDate = this.moment(new Date()).format('YYYY-MM-DD');
                        break;
                    }
                    case 3: {

                        this.CollectMoneySelectTerm.StartDate = this.moment(new Date()).add(-1, 'months').format('YYYY-MM-DD');
                        this.CollectMoneySelectTerm.EndDate = this.moment(new Date()).format('YYYY-MM-DD');
                        break;
                    }
                    case 4: {

                        this.CollectMoneySelectTerm.StartDate = this.moment(new Date()).add(-1, 'years').format('YYYY-MM-DD');
                        this.CollectMoneySelectTerm.EndDate = this.moment(new Date()).format('YYYY-MM-DD');
                        break;
                    }
                    case 5: {

                        this.CollectMoneySelectTerm.StartDate = this.moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD');
                        this.CollectMoneySelectTerm.EndDate = this.moment(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)).format('YYYY-MM-DD');
                        break;
                    }
                    default:
                }
            },
            // 프로젝트 검색 메서드
            searchProjectCode() {

                var parent = this;

                var successCallback = function (result) {

                    // 결과값을 추출 후 돔객체에 바인딩
                    parent.CollectMoneySelectTerm.ProjectName = result.ProjectNm;
                    parent.CollectMoneySelectTerm.ProjectCode = result.ProjectCd;
                    parent.CollectMoneySelectTerm.CompanyName = result.CompanyNm;
                    parent.CollectMoneySelectTerm.CompanyCode = result.CompanyCd;
                    parent.CollectMoneySelectTerm.ProjectPrice = result.Price;
                    parent.CollectMoneySelectTerm.ManagerNm = result.ManagerNm;

                    parent.showCollectMoney();
                }

                this.$refs.CP1060P.showProjectPopup(successCallback);
            },
            // 프로젝트 초기화 메서드
            clearProjectCode() {

                this.CollectMoneySelectTerm.ProjectCode = '';
                this.CollectMoneySelectTerm.ProjectName = '';
                this.CollectMoneySelectTerm.CompanyName = '';
                this.CollectMoneySelectTerm.CompanyCode = '';
                this.CollectMoneySelectTerm.ManagerNm = '';

                this.showCollectMoney();
            },
            // 조회 메서드
            showCollectMoney() {

                var parent = this;

                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/GetCollectMoney'.toRealServiceUrl();
                var obj = new Object();
                obj.jsonString = JSON.stringify(this.CollectMoneySelectTerm);

                // ajax 성공 시 실행되는 핸들러 정의
                var ajaxSuccessHandler = function (data) {

                    var table = JSON.parse(data.d).Table;
                    var table1 = JSON.parse(data.d).Table1;

                    parent.CollectMoneyList = (table.length > 0) ? table : new Array();
                    parent.TotalCount = (table1 != null && table1.length > 0) ? table1[0].TotCnt : 0;
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler);
            },
            // 로우 클릭, 상세 조회
            rowClickEvent(e) {

                var parent = this;
                var data = e.data;

                var successCallback = function () {

                    parent.showCollectMoney();
                }
                this.$refs.PM1021E.open(successCallback, data);
            },
            // 추가 버튼 클릭, 신규 등록
            btnAddClicked() {

                var parent = this;

                var successCallback = function () {

                    parent.showCollectMoney();
                }
                this.$refs.PM1021E.open(successCallback);
            },
        }
    }
</script>