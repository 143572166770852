<template>
    <!--업체 영업 등록 상세-->
    <div class="modal fade" id="BZ1001E_Modal" ref="BZ1001E_Modal" tabindex="-1" role="dialog" aria-labelledby="BZ1001E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">
                        <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                    </button>
                    <h3 class="modal-title">업체 영업 등록</h3>
                </div>
                <div class="modal-body">
                    <section class="add-wrap">
                        <form>
                            <fieldset>
                                <legend></legend>
                                <section class="cols-wrap">
                                    <div class="cols col-12">
                                        <label for="txtTraderNm2" class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>업체명
                                            </span>
                                        </label>
                                        <div class="cols-btns-cont">
                                            <input type="text" id="txtTraderNm2" class="form-control txt" readonly="readonly" v-model="BusinessDetail.CompanyNm" />
                                            <input type="hidden" id="hidTraderCd2" class="form-control txt" v-model="BusinessDetail.CompanyCd" />
                                        </div>
                                        <div class="cols-btns">
                                            <a href="#" id="btnItemPop2" class="btn btn-default" @click.prevent="searchCompanyCode">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i> 검색
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-12">
                                        <label for="txtTraderBizNm2" class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>영업명
                                            </span>
                                        </label>
                                        <input type="text" id="txtTraderBizNm2" ref="txtTraderBizNm2" class="form-control txt" v-model="BusinessDetail.BizNm" />
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtPersonNm2" class="control-label">
                                            <span><i class="glyphicon glyphicon-asterisk"></i>담당자</span>
                                        </label>
                                        <div class="cols-btns-cont">
                                            <input type="text" id="txtPersonNm2" class="form-control txt" readonly="readonly" v-model="BusinessDetail.BizEmpNm" />
                                            <input type="hidden" id="hidPersonCd2" class="form-control txt" v-model="BusinessDetail.BizEmpNo" />
                                        </div>
                                        <div class="cols-btns">
                                            <a href="#" id="btnPerson" class="btn btn-default" @click.prevent="searchManager">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i> 검색
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="selStatusCd" class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>영업상태
                                            </span>
                                        </label>
                                        <select class="form-control" id="selStatusCd" name="selStatusCd"
                                                v-model="BusinessDetail.DvsCd">
                                            <option v-for="item in ProgressList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>

                                </section>
                                <section class="btn-align">
                                    <div v-show="this.$parent.$parent.CanSave" class="btn-group">
                                        <button type="button" id="btnItemSave" class="btn btn-primary" @click.prevent="saveData">
                                            <i class="glyphicon glyphicon-save"></i> 저장
                                        </button>
                                    </div>
                                    <div v-show="this.$parent.$parent.CanSave" class="btn-group">
                                        <button type="button" id="btnItemRmve" class="btn btn-warning" @click.Prevent="removeData">
                                            <span class="glyphicon glyphicon-edit"></span> 삭제
                                        </button>
                                    </div>
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-danger" data-dismiss="modal">
                                            <i class="glyphicon glyphicon-remove"></i> 취소
                                        </button>
                                    </div>
                                </section>
                            </fieldset>
                        </form>
                    </section>
                </div>
            </div>
        </div>
        <CP1020P ref="CP1020P"></CP1020P>
        <CP1030P ref="CP1030P"></CP1030P>
    </div>
</template>

<script>
    import CP1020P from '@/components/libs/popup/CP1020P'
    import CP1030P from '@/components/libs/popup/CP1030P'

   
    export default {
        name: 'BZ1001E',
        components: {
            CP1030P, CP1020P
        },
        data() {
            return {
                CallbackFunction: null     // Caller Function
                , ProgressList: null             // 진행상황 공통코드 리스트
                // 영업관리 상세 데이터 객체
                , BusinessDetail: {      
                    CorpCd: null,       // 회사코드
                    CompanyNm: null,    // 업체이름
                    CompanyCd: null,    // 업체코드
                    BizNo: null,        // hidden 번호
                    BizNm: null,        // 영업명
                    BizEmpNm: null,     // 영업 담당자 명
                    BizEmpNo: null,     // 영업 담당자 사번
                    DvsCdName: null,    // 영업 상태 이름
                    DvsCd: '0723020'    // 영업 상태 코드
                }
            }
        },
        beforeMount() {
            // 공통코드 조회
            this.ProgressList = $.scriptBase.getCommonCode('CODE000', '0723000', '', '') // 진행상황
        },
        methods: {
            open: function (callbackFunction, selectedObj) {

                this.CallbackFunction = callbackFunction

                if (selectedObj) {
                    this.BusinessDetail = selectedObj
                    this.BusinessDetail.Arg = 'I'
                }
                else {
                    this.reset()
                    this.BusinessDetail.Arg = 'I'
                }

                this.BusinessDetail.UserId = this.$parent.$parent.EmpId
                this.BusinessDetail.CorpCd = this.$parent.$parent.CompanyCode
                    

                $("#BZ1001E_Modal").modal("show")
            }, 
            close: function () {
                $("#BZ1001E_Modal").modal("hide")
                //this.CallbackFunction()
            },
            reset() {
                var progressList = this.ProgressList
                Object.assign(this.$data, this.$options.data())
                this.ProgressList = progressList
            },
            //업체 영업 저장 메서드
            saveData: function () {
                var parent = this

                if (this.BusinessDetail.CompanyNm === '' || this.BusinessDetail.CompanyNm === null) {

                    alert('업체를 입력하세요.')
                    $(txtTraderNm2).focus()
                    return
                }
                if (this.BusinessDetail.BizNm === '' || this.BusinessDetail.BizNm === null) {

                    alert('영업명을 입력하세요.')
                    $(txtTraderBizNm2).focus()
                    return
                }

                var parent = this

                var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/SaveTraderBusiness'
                url = url.toRealServiceUrl()
                var obj = new Object()

                obj.jsonString = JSON.stringify(this.BusinessDetail)

                // 서비스호출 성공 콜백 함수
                var successCallback = function (data) {

                    if (data.d > 0) {

                        if (parent.BusinessDetail.Arg = 'I') {

                            alert('등록 되었습니다.')
                            parent.close()
                        }
                        else {

                            alert('저장 되었습니다.')
                            parent.close()
                        }
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            // 영업관리 삭제 버튼 클릭 이벤트 핸들러
            removeData: function () {

                if (this.BusinessDetail === null && this.PageActionType === 'I') {

                    alert('삭제할 정보가 존재 하지 않습니다.')
                    return
                }

                if (confirm('삭제시 영업에관련된 모든 데이터가 삭제됩니다. \n삭제하시겠습니까?')) {

                    var parent = this

                    var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/RemoveTraderBusiness'
                    url = url.toRealServiceUrl()
                    var obj = new Object()

                    // 삭제 시 필요한 객체 추가
                    this.BusinessDetail.Arg = 'D'
                    this.BusinessDetail.UserId = this.$parent.$parent.EmpId
                    this.BusinessDetail.CompanyCode = this.$parent.$parent.CompanyCode

                    obj.jsonString = JSON.stringify(this.BusinessDetail)


                    // 서비스호출 성공 콜백 함수
                    var successCallback = function (data) {

                        if (data.d > 0) {

                            alert('삭제 되었습니다.')

                            $(parent.$refs.BZ1001E_Modal).modal('hide')
                            parent.getBusiness()
                        }
                        else {

                            alert('삭제에 실패하였습니다. 잠시 후 다시 시도해 주세요.')
                        }
                    }

                    $.scriptBase.executeAjaxAsync(url, obj, successCallback)

                }
            },
            // 담당자 검색
            searchManager: function () {

                var parent = this

                var successCallback = function (result) {

                    parent.BusinessDetail.BizEmpNm = result.EmpNm
                    parent.BusinessDetail.BizEmpNo = result.EmpNo
                }

                this.$refs.CP1020P.open(successCallback)
            },
            // 업체명 검색 메서드
            searchCompanyCode: function (index) {
                var parent = this

                var successCallback = function (result) {

                    // 결과값을 추출 후 돔객체에 바인딩
                    parent.BusinessDetail.CompanyNm = result.CompanyNm
                    parent.BusinessDetail.CompanyCd = result.CompanyCd
                }

                // 업체 검색 팝업 오픈
                this.$refs.CP1030P.open(successCallback)
            }
        }
    }
</script>

<style scoped>

</style>