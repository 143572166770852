<template>
    <div>
        <DxDataGrid ref="grdList" 
                    :data-source="projectMMList" 
                    :show-borders="true" 
                    :show-row-lines="true"
                    @cell-prepared="onCellPrepared"">
            <DxColumn caption="프로젝트명" data-field="ProjectNm" alignment="left" width="30%" />
            <DxColumn caption="1월" data-field="Jan" data-type="number" alignment="center" :customize-text="customizeText"/>
            <DxColumn caption="2월" data-field="Feb" data-type="number" alignment="center" :customize-text="customizeText"/>
            <DxColumn caption="3월" data-field="Mar" data-type="number" alignment="center" :customize-text="customizeText"/>
            <DxColumn caption="4월" data-field="Apr" data-type="number" alignment="center" :customize-text="customizeText"/>
            <DxColumn caption="5월" data-field="May" data-type="number" alignment="center" :customize-text="customizeText"/>
            <DxColumn caption="6월" data-field="Jun" data-type="number" alignment="center" :customize-text="customizeText"/>
            <DxColumn caption="7월" data-field="Jul" data-type="number" alignment="center" :customize-text="customizeText"/>
            <DxColumn caption="8월" data-field="Aug" data-type="number" alignment="center" :customize-text="customizeText"/>
            <DxColumn caption="9월" data-field="Sep" data-type="number" alignment="center" :customize-text="customizeText"/>
            <DxColumn caption="10월" data-field="Oct" data-type="number" alignment="center" :customize-text="customizeText"/>
            <DxColumn caption="11월" data-field="Nov" data-type="number" alignment="center" :customize-text="customizeText"/>
            <DxColumn caption="12월" data-field="Dec" data-type="number" alignment="center" :customize-text="customizeText"/>
        </DxDataGrid>
    </div>
</template>

<script>
    // Dev extreme
    import { DxDataGrid, DxColumn, DxPaging, DxSummary, DxTotalItem, DxValueFormat } from 'devextreme-vue/data-grid'
    import { DxButton } from 'devextreme-vue'

    export default {
        name: 'RT2010R_Detail'
        , components: {
            DxDataGrid
            , DxColumn
            , DxPaging
            , DxSummary
            , DxTotalItem
            , DxValueFormat
			, DxButton
        }
        , props: {
			templateData: {
                type: Object,
                default: () => { }
            }
        }
        , data() {
            return {
				Condition: {
					CorpCd: this.$parent.$parent.CompanyCode
					, ContractNm: ''
					, CompanyNm: ''
					, CompanyCd: ''
					, TargetYear: ''
                }
                , projectMMList: new Array()
            };
        }
        , created() {
			this.getEmpProjectMMDetail()
		}
        , methods: {
            //#region init
            //#endregion

            //#region event
            onCellPrepared(e) { // datagrid header 가운데 정렬, column 중앙 정렬
                if (e.rowType == 'header') {
                    e.cellElement.style['textAlign'] = 'center'
                }
                if (e.rowType == 'data') {
                    e.cellElement.style['verticalAlign'] = 'middle'
                }
            }
            , customizeText(options) {
                if (options.value == null) {

                    return 0
                }
                else {
                    return options.valueText;
                }
            }

            //#endregion

            //#region service
            , getEmpProjectMMDetail: function () {

                var that = this

                var url = '/Mini.Service.IBS.RT.Service/AccountingReport.svc/ajax/GetEmpProjectMMList_Report'.toRealServiceUrl()
                var obj = new Object()

                var obj = new Object()
                obj.year = this.templateData.data.TargetYear
                obj.empNo = this.templateData.data.EmpNo

                var successCallback = function (data) {

                    if (data.d != '{}') {
                        var jsonData = JSON.parse(data.d)
                        that.projectMMList = jsonData.Table
                    }
                   
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            }
            //#endregion
        }
    }
</script>
