<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a v-show="this.$parent.CanSearch" href="#" id="btnListSrch" class="btn btn-primary btn-row2" @click.prevent="fn_ShowData(false)">
                    <span>
                        <i class="glyphicon glyphicon-search"></i>조회
                    </span>
                </a>
                <a v-show="this.$parent.CanNew" href="#" id="btnListInst" class="btn btn-default" @click.prevent="fn_InstData">
                    <span>
                        <i class="glyphicon glyphicon-plus"></i>추가
                    </span>
                </a>
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="selDateType" class="control-label"><span>매입일</span></label>
                                        <div class="col-xs-3">
                                            <select class="form-control" id="selDateType" @change="dateTypeChangeEvent">
                                                <option value="1">오늘</option>
                                                <option value="2">일주일</option>
                                                <option value="3">한달</option>
                                                <option value="4">일년</option>
                                                <option value="5" selected="selected">이번달</option>
                                            </select>
                                        </div>
                                        <div class="col-xs-9">
                                            <label for="txtStartDate" class="sr-only"><span>매입일</span></label>
                                            <div class="col-2 input-group date">
                                                <DxDateBox display-format="yyyy-MM-dd"
                                                           pickerType="calendar"
                                                           v-model="PurTaxInvoiceSelectTerm.StartDate"
                                                           @value-changed="fn_ShowData"
                                                           style="border-radius:0;" />
                                            </div>
                                            <div class="tail"><span>~</span></div>
                                            <label for="txtEndDate" class="sr-only"><span>매입일</span></label>
                                            <div class="col-2 input-group date">
                                                <DxDateBox display-format="yyyy-MM-dd"
                                                           pickerType="calendar"
                                                           v-model="PurTaxInvoiceSelectTerm.EndDate"
                                                           @value-changed="fn_ShowData"
                                                           style="border-radius:0;" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtCompanyNm" class="control-label"><span>프로젝트</span></label>
                                        <div class="cols-btns-cont pad-r150">
                                            <input type="text" id="txtProjectNm" class="form-control txt" readonly="readonly" v-model="PurTaxInvoiceSelectTerm.ProjectNm" @change="fn_ShowData" />
                                            <input type="hidden" id="hidProjectCd" v-model="PurTaxInvoiceSelectTerm.ProjectCd" />
                                            <input type="hidden" id="hidCompanyNm" v-model="PurTaxInvoiceSelectTerm.CompanyNm" />
                                            <input type="hidden" id="hidCompanyCd" v-model="PurTaxInvoiceSelectTerm.CompanyCd" />
                                        </div>
                                        <div class="cols-btns">
                                            <a href="#" id="btnListPop1" class="btn btn-default" @click.prevent="searchProjectCode">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i> 검색
                                                </span>
                                            </a>
                                            <a href="#" id="btnSrchInit" class="btn btn-default"
                                               @click.prevent="function(){
                                                     PurTaxInvoiceSelectTerm.ProjectCd = ''
                                                     PurTaxInvoiceSelectTerm.ProjectNm = ''
                                                     PurTaxInvoiceSelectTerm.CompanyNm = ''
                                                     PurTaxInvoiceSelectTerm.CompanyCd = ''
                                                     PurTaxInvoiceSelectTerm.AccSrcDvsCd = ''
                                                     fn_ShowData()
                                                }">
                                                <span>
                                                    <i class="glyphicon glyphicon-repeat"></i> 초기화
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-3">
                                        <label for="txtInOut" class="control-label">
                                            <span>매입명</span>
                                        </label>
                                        <input type="text" id="txtInOutNm" class="form-control txt" v-model="PurTaxInvoiceSelectTerm.PurchaseNm" />
                                    </div>
                                    <div class="cols col-3">
                                        <label for="selInOutConfirm" class="control-label">
                                            <span>수취여부</span>
                                        </label>
                                        <select class="form-control" id="selInOutConfirm" v-model="PurTaxInvoiceSelectTerm.IsConfirm" @change="fn_ShowData">
                                            <option value="">== 전체 ==</option>
                                            <option value="Y">Y</option>
                                            <option value="N">N</option>
                                        </select>
                                    </div>
                                    <div class="cols col-3">
                                        <label for="selAccSrcDvsCd" class="control-label">
                                            <span>매입출처</span>
                                        </label>
                                        <select class="form-control" id="selAccSrcDvsCd" name="selAccSrcDvsCd" v-model="PurTaxInvoiceSelectTerm.AccSrcDvsCd" @change="selectAccSrcDvsCd">
                                            <option value="">== 전체 ==</option>
                                            <option value="Y">프로젝트 매입</option>
                                            <option value="N">프로젝트 없음</option>
                                        </select>
                                    </div>
                                </section>
                            </section>
                        </fieldset>
                    </form>
                </div>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">매입세금계산서관리</p>
                </div>
                <div>
                    <!--리스트 영역-->
                    <section class="tbl-contents">
                        <div class="alert alert-info total">
                            <p>
                                <span class="glyphicon glyphicon-folder-close"></span>
                                전체: <span id="spTotCnt">{{PurTaxInvoiceListTotalCount}}</span>건
                            </p>
                        </div>
                        <div class="table-responsive">
                            <DxDataGrid ref="PurTaxInvoiceList"
                                        :data-source="PurTaxInvoiceList"
                                        :hoverStateEnabled="true"
                                        :allow-column-resizing="true"
                                        :column-resizing-mode="'widget'"
                                        :column-auto-width="true"
                                        :row-alternation-enabled="true"
                                        :show-borders="false"
                                        @cell-prepared="onCellPrepared"
                                        @row-click="selectPurTaxInvoice"
                                        style="max-height: 500px; min-width: 1540px; vertical-align: top;">
                                <DxScrolling mode="standard" useNative="false" />
                                <DxColumnFixing :enabled="true" />
                                <DxColumn caption="매입출처" data-field="ProjectYN" alignment="center" />
                                <DxColumn caption="매입구분" data-field="SrcDvsNm" alignment="center" />
                                <DxColumn caption="프로젝트" data-field="ProjectNm" />
                                <DxColumn caption="매입명" data-field="PurchaseNm" />
                                <DxColumn caption="매입액" data-field="PurAmt" format="fixedPoint" alignment="right" />
                                <DxColumn caption="부가세" data-field="VAT" format="fixedPoint" alignment="right" />
                                <DxColumn caption="계" data-field="SumAmt" format="fixedPoint" alignment="right" />
                                <DxColumn caption="매입일" data-field="PurchaseYMD" alignment="center" />
                                <DxColumn caption="수취여부" data-field="InvoiceRecvYN" alignment="center" />
                                <DxPaging :enabled="false" />
                            </DxDataGrid>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <div class="modal fade" id="AC1011E_Modal" tabindex="-1" role="dialog" aria-labelledby="AC1011E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" @click.Prevent="close">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                        <h3 v-if="PageActionType == 'I'" class="modal-title">매입세금계산서관리 등록</h3>
                        <h3 v-else class="modal-title">매입세금계산서관리 수정</h3>
                    </div>
                    <div class="modal-body">
                        <section class="add-wrap">
                            <form>
                                <fieldset>
                                    <legend>...</legend>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtInOut2" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>매입명
                                                </span>
                                            </label>
                                            <input type="text" id="txtInOutNm2" class="form-control txt"
                                                   v-bind:disabled="PageActionType != 'I'"
                                                   v-model="PurTaxInvoiceDetail.PurchaseNm" />
                                        </div>
                                        <div class="cols col-2">
                                            <label for="txtOrderCorpNm1" class="control-label">
                                                <span><i class="glyphicon glyphicon-asterisk"></i>매입처명</span>
                                            </label>
                                            <div class="cols-btns-cont">
                                                <input type="text" id="txtCompanyNm" class="form-control txt" disabled="disabled" v-model="PurTaxInvoiceDetail.PurCompNm" />
                                                <input type="hidden" id="hideCompanyCd" v-model="PurTaxInvoiceDetail.PurCompCd" />
                                            </div>
                                            <div class="cols-btns">
                                                <a href="#" v-show="!ReadOnly" id="btnPerson" class="btn btn-default" @click.prevent="searchCompany()">
                                                    <span>
                                                        <i class="glyphicon glyphicon-search"></i> 검색
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="selInOutDvsCd2" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>매입구분
                                                </span>
                                            </label>
                                            <select class="form-control" v-bind:disabled="PageActionType != 'I'" v-model="PurTaxInvoiceDetail.SrcDvsCd">
                                                <option value="">== 선택 ==</option>
                                                <option value="0511030" v-show="PageActionType != 'I'">보증보험</option>
                                                <option v-for="item in AccSrcDvsCdList" v-bind:value="item.Code">
                                                    {{item.Name}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="selProjectYn" class="control-label">
                                                <span>매입출처</span>
                                            </label>
                                            <select class="form-control" v-model="PurTaxInvoiceDetail.ProjectYN" v-bind:disabled="true">
                                                <option value="프로젝트 없음">프로젝트 없음</option>
                                                <option value="프로젝트 매입">프로젝트 매입</option>
                                            </select>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtPrice2" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>매입액
                                                </span>
                                            </label>
                                            <input type="text" id="txtPrice2" class="form-control text-right"
                                                   v-bind:disabled="PageActionType != 'I'"
                                                   v-bind:value="PurTaxInvoiceDetail.PurAmt"
                                                   @input="function(e){
                                                        PurTaxInvoiceDetail.PurAmt = e.target.value.toPriceString()
                                                        e.target.value = e.target.value.toPriceString()
                                                   }" />
                                        </div>
                                        <div class="cols col-2">
                                            <label for="selVatIncludeYn" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>부가세구분
                                                </span>
                                            </label>
                                            <select class="form-control" id="selVatIncludeYn" ref="selVatIncludeYn" name="selVatIncludeYn"
                                                    v-model="PurTaxInvoiceDetail.IncludeVatYN"
                                                    v-bind:disabled="PageActionType != 'I'"
                                                    @change="selectBoxVatChangedEvent">
                                                <option value="">== 선택 ==</option>
                                                <option value="Y">포함</option>
                                                <option value="N">별도</option>
                                                <option value="Z">영세율</option>
                                                <option value="F">면세</option>
                                            </select>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtVAT2" class="control-label">
                                                <span>부가세</span>
                                            </label>
                                            <input type="text" id="txtVAT2" class="form-control text-right"
                                                   v-bind:disabled="true"
                                                   v-bind:value="PurTaxInvoiceDetail.VAT"
                                                   @input="function(e){
                                                        PurTaxInvoiceDetail.VAT = e.target.value.toPriceString()
                                                        e.target.value = e.target.value.toPriceString()
                                                   }" />
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtInOutYMD2" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>매입일
                                                </span>
                                            </label>
                                            <div class="col-1 input-group date">
                                                <DxDateBox display-format="yyyy-MM-dd"
                                                           pickerType="calendar"
                                                           v-model="PurTaxInvoiceDetail.PurchaseYMD"
                                                           :input-attr="{ 'aria-lable' : 'Disabled' }"
                                                           :disabled="ReadOnly && PurTaxInvoiceDetail.ProjectYN == '프로젝트 매입'"
                                                           style="border-radius:0;" />
                                            </div>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="selConfirm2" class="control-label">
                                                <span><i class="glyphicon glyphicon-asterisk"></i>수취여부</span>
                                            </label>
                                            <select class="form-control" v-model="PurTaxInvoiceDetail.InvoiceRecvYN" v-bind:disabled="PurTaxInvoiceDetail.InvoiceRecvYN == 'Y'">
                                                <option value="Y">Y</option>
                                                <option value="N">N</option>
                                            </select>
                                        </div>
                                    </section>
                                    <section class="cols-wrap" id="divCheckend">
                                        <div class="cols">
                                            <label for="txtBackReason2" class="control-label">
                                                <span>비고</span>
                                            </label>
                                            <textarea class="form-control txt" v-model="PurTaxInvoiceDetail.ReasonCont" />
                                        </div>
                                    </section>
                                </fieldset>
                            </form>
                        </section>
                    </div>
                    <div class="modal-footer">
                        <section class="btn-align">
                            <label v-show="this.PurTaxInvoiceDetail.InOutCompleteYN == 'Y'" class="control-label" id="label_InfoLabel"> 출금이 진행되었습니다. 수정, 삭제가 불가능합니다.</label>
                            <div class="btn-group" v-show="this.PurTaxInvoiceDetail.InOutCompleteYN != 'Y'">
                                <button type="button" id="btnItemSave" class="btn btn-primary" @click="fn_SaveData">
                                    <span class="glyphicon glyphicon-save"></span> 저장
                                </button>
                            </div>
                            <div class="btn-group" v-show="PageActionType != 'I' && this.PurTaxInvoiceDetail.InOutCompleteYN != 'Y'">
                                <button type="button" id="btnItemRmve" class="btn btn-warning" @click="fn_RemoveData">
                                    <span class="glyphicon glyphicon-edit"></span> 삭제
                                </button>
                            </div>
                            <div class="btn-group">
                                <button type="button" class="btn btn-danger" @click.Prevent="close">
                                    <span class="glyphicon glyphicon-remove"></span> 취소
                                </button>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
        <CP1030P ref="CP1030P"></CP1030P>
        <CP1060P ref="CP1060P"></CP1060P>
        <AC1013E ref="AC1013E"></AC1013E>
    </div>
</template>
<script>
    import { DxDataGrid, DxColumn, DxButton, DxScrolling, DxColumnFixing, DxPaging } from 'devextreme-vue/data-grid';
    import DxDateBox, { DxCalendarOptions } from 'devextreme-vue/date-box';
    import ViewTitle from '@/components/ViewTitle';
    import CP1030P from '@/components/libs/popup/CP1030P';
    import CP1060P from '@/components/libs/popup/CP1060P';
    import AC1013E from '@/components/ac/popup/AC1013E';
    export default {
        name: 'AC1010R',
        components: {
            ViewTitle, CP1030P, CP1060P, AC1013E, DxDataGrid, DxColumn, DxButton, DxScrolling, DxColumnFixing, DxPaging, DxDateBox, DxCalendarOptions,
        },
        data() {
            return {
                AccSrcDvsCdList: null,
                PurTaxInvoiceList: null,
                // 매입세금계산서 행 상세 객체
                PurTaxInvoiceDetail: {
                    PurchaseSeqNo: 0,
                    UserId: '',
                    CorpCd: '',
                    CompanyCd: '',
                    ProjectCd: '',
                    SeqNo: 0,
                    ItemNo: 0,
                    SrcDvsCd: '',
                    ProjectYN: '',
                    PurCompCd: '',
                    PurCompNm: '',
                    PurchaseNm: '',
                    IncludeVatYN: '',          // 부과세 포함, 미포함
                    PurAmt: 0,
                    VAT: 0,                 // 부과세
                    PurchaseYMD: '',
                    InvoiceRecvYN: '',
                    ReasonCont: ''
                },
                // 조회조건 객체
                PurTaxInvoiceSelectTerm: {
                    Arg: 'L',
                    CompanyCd: '',
                    ProjectCd: '',
                    StartDate: this.moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD'),
                    EndDate: this.moment(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)).format('YYYY-MM-DD'),
                    PurchaseNm: '',
                    AccSrcDvsCd: '',
                    IsConfirm: '',
                },
                PurTaxInvoiceListTotalCount: 0,
                PageActionType: 'V',
                ReadOnly: false,
                ProjectYN: false,
                CanSave: true,
            }
        },
        beforeMount() {

            let AccSrcDvsCd = $.scriptBase.getCommonCode('CODE000', '0511000', '', '');
            this.AccSrcDvsCdList = AccSrcDvsCd.filter((value) => value.Etc1 == 'Y');
        },
        mounted() {

            if (this.$parent.CanSearch) {

                this.fn_ShowData();
            }
        },
        watch: {

            PurTaxInvoiceDetail: {
                deep: true,
                handler(newData) {

                    if (newData.IncludeVatYn === 'N') {

                        this.autoCalculateVat();
                    }
                }
            }
        },
        methods: {
            // datagrid header 가운데 정렬, column 중앙 정렬
            onCellPrepared(e) {

                if (e.rowType == 'header') {
                    e.cellElement.style['textAlign'] = 'center';
                }
                if (e.rowType == 'data') {
                    e.cellElement.style['verticalAlign'] = 'middle';
                }
            },
            close() {

                $("#AC1011E_Modal").modal("hide");
                this.fn_ShowData();
            },
            // 매입일 날짜 타입 변경 이벤트
            dateTypeChangeEvent(event) {

                let dateType = Number(event.target.value);

                switch (dateType) {

                    case 1: {

                        this.PurTaxInvoiceSelectTerm.StartDate = this.moment(new Date()).format('YYYY-MM-DD');
                        this.PurTaxInvoiceSelectTerm.EndDate = this.moment(new Date()).format('YYYY-MM-DD');
                        break;

                    } case 2: {

                        this.PurTaxInvoiceSelectTerm.StartDate = this.moment(new Date()).add(-7, 'days').format('YYYY-MM-DD');
                        this.PurTaxInvoiceSelectTerm.EndDate = this.moment(new Date()).format('YYYY-MM-DD');
                        break;

                    } case 3: {

                        this.PurTaxInvoiceSelectTerm.StartDate = this.moment(new Date()).add(-1, 'months').format('YYYY-MM-DD');
                        this.PurTaxInvoiceSelectTerm.EndDate = this.moment(new Date()).format('YYYY-MM-DD');
                        break;

                    } case 4: {

                        this.PurTaxInvoiceSelectTerm.StartDate = this.moment(new Date()).add(-1, 'years').format('YYYY-MM-DD');
                        this.PurTaxInvoiceSelectTerm.EndDate = this.moment(new Date()).format('YYYY-MM-DD');
                        break;
                    }
                    case 5: {

                        this.PurTaxInvoiceSelectTerm.StartDate = this.moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD');
                        this.PurTaxInvoiceSelectTerm.EndDate = this.moment(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)).format('YYYY-MM-DD');
                        break;
                    }
                }
            },
            // 프로젝트 검색 메서드
            searchProjectCode() {

                var parent = this;

                var successCallback = function (result) {

                    // 결과값을 추출 후 돔객체에 바인딩
                    parent.PurTaxInvoiceSelectTerm.ProjectCd = result.ProjectCd;
                    parent.PurTaxInvoiceSelectTerm.ProjectNm = result.ProjectNm;
                    parent.PurTaxInvoiceSelectTerm.CompanyCd = result.CompanyCd;

                    // 프로젝트 선택 여부에 따라 매입출처 세팅
                    if (result.ProjectCd == '') {

                        parent.PurTaxInvoiceSelectTerm.AccSrcDvsCd = '';
                    } else {

                        parent.PurTaxInvoiceSelectTerm.AccSrcDvsCd = (result.ProjectCd == '999999') ? 'N' : 'Y';
                    }

                    parent.fn_ShowData();
                }
                this.$refs.CP1060P.open(successCallback);
            },
            // 매입출처 선택 이벤트
            selectAccSrcDvsCd() {

                if (this.PurTaxInvoiceSelectTerm.AccSrcDvsCd == 'N') {

                    this.PurTaxInvoiceSelectTerm.CompanyCd = '999999';
                    this.PurTaxInvoiceSelectTerm.ProjectNm = '프로젝트 외 매입';
                    this.PurTaxInvoiceSelectTerm.ProjectCd = '999999';
                }

                if (this.PurTaxInvoiceSelectTerm.AccSrcDvsCd != 'N' && this.PurTaxInvoiceSelectTerm.ProjectCd == '999999') {

                    this.PurTaxInvoiceSelectTerm.CompanyCd = '';
                    this.PurTaxInvoiceSelectTerm.ProjectNm = '';
                    this.PurTaxInvoiceSelectTerm.ProjectCd = '';
                }

                this.fn_ShowData();
            },
            // 데이터 조회
            fn_ShowData() {

                var parent = this;
                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/GetPurTaxInvoiceList'.toRealServiceUrl();
                var obj = new Object();
                obj.jsonString = JSON.stringify(this.PurTaxInvoiceSelectTerm);

                // 서비스 호출 성공 콜백 함수
                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d);
                    var table = jsonData.Table;
                    var table1 = jsonData.Table1;

                    // 매입세금계산서 목록
                    parent.PurTaxInvoiceList = (table.length > 0) ? table : new Array();
                    // 매입세금계산서 전체 건수
                    parent.PurTaxInvoiceListTotalCount = (table1.length > 0) ? table1[0].TotCnt : 0;
                }
                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 목록 선택 이벤트
            selectPurTaxInvoice(e) {

                let row = (e.data) ? e.data : e;

                // 화면 액션값 설정 (I:신규, V: 상세보기)
                this.PageActionType = 'V';

                // 선택한 행 데이터 복사
                this.PurTaxInvoiceDetail = Object.assign({}, row);

                if (this.PurTaxInvoiceDetail.InOutCompleteYN == 'Y') {

                    this.ReadOnly = true;
                } else {

                    this.ReadOnly = false;
                }

                // Arg, 로그인 사용자 아이디, 로그인 사용자 이름 값 추가
                this.PurTaxInvoiceDetail.Arg = 'I';
                this.PurTaxInvoiceDetail.UserId = this.$parent.EmpId;

                // 금액 필드 값 포맷 변경
                this.PurTaxInvoiceDetail.PurAmt = (this.PurTaxInvoiceDetail.PurAmt != null) ? this.PurTaxInvoiceDetail.PurAmt.toPriceNumber() : null;
                this.PurTaxInvoiceDetail.VAT = (this.PurTaxInvoiceDetail.VAT != null) ? this.PurTaxInvoiceDetail.VAT.toPriceNumber() : null;

                this.PurTaxInvoiceDetail.ReasonCont = (this.PurTaxInvoiceDetail.ReasonCont != null) ? this.PurTaxInvoiceDetail.ReasonCont : '';

                // 상세팝업 오픈
                $('#AC1011E_Modal').modal('show');
            },
            // 데이터 추가
            fn_InstData() {

                // 화면 액션값 설정 (I:신규, V: 상세보기)
                this.PageActionType = 'I';
                this.ReadOnly = false;

                this.PurTaxInvoiceDetail = {
                    Arg: 'I',
                    UserId: this.$parent.EmpId,
                    PurchaseSeqNo: 0,
                    CorpCd: '0001',
                    CompanyCd: '999999',
                    ProjectCd: '999999',
                    SeqNo: 1,
                    ItemNo: 1,
                    SrcDvsCd: '',
                    ProjectYN: '프로젝트 없음',
                    PurCompNm: '',
                    PurCompCd: '',
                    PurchaseNm: '',
                    IncludeVatYN: '',
                    PurAmt: 0,
                    VAT: 0,
                    PurchaseYMD: '',
                    InvoiceRecvYN: 'N',
                    ReasonCont: ''
                };

                $('#AC1011E_Modal').modal('show');
            },
            // 거래처 검색
            searchCompany() {

                var parent = this;

                var successCallback = function (result) {
                    parent.PurTaxInvoiceDetail.PurCompNm = result.CompanyNm;
                    parent.PurTaxInvoiceDetail.PurCompCd = result.CompanyCd;
                }
                this.$refs.CP1030P.open(successCallback);
            },
            // 부가세구분 선택 이벤트
            selectBoxVatChangedEvent(event) {
                // 포함
                if (event.target.value === 'N') {

                    // 부가세 자동계산
                    this.autoCalculateVat();
                    this.PurTaxInvoiceDetail.IncludeVatYN = 'N';
                    // 별도
                } else if (event.target.value === 'Y') {

                    this.PurTaxInvoiceDetail.VAT = '0';
                    this.PurTaxInvoiceDetail.IncludeVatYN = 'Y';
                    // 영세율
                } else if (event.target.value === 'Z') {

                    this.PurTaxInvoiceDetail.VAT = '0';
                    this.PurTaxInvoiceDetail.IncludeVatYN = 'Z';
                    // 면세
                } else if (event.target.value === 'F') {

                    this.PurTaxInvoiceDetail.VAT = '0';
                    this.PurTaxInvoiceDetail.IncludeVatYN = 'F';
                }
            },
            // 부가세 자동계산
            autoCalculateVat() {

                if (this.PurTaxInvoiceDetail.PurAmt != null && this.PurTaxInvoiceDetail.PurAmt != '') {

                    let vatCal = Number(this.PurTaxInvoiceDetail.PurAmt.toNumber()) * 0.1;
                    this.PurTaxInvoiceDetail.VAT = Math.round(vatCal).toPriceNumber();
                } else {

                    this.PurTaxInvoiceDetail.VAT = null;
                }
            },
            // 데이터 저장
            fn_SaveData() {

                if (this.PurTaxInvoiceDetail.PurchaseNm === null || this.PurTaxInvoiceDetail.PurchaseNm === '') {

                    alert('매입명을 입력해주세요.');
                    document.getElementById('txtInOutNm2').focus();
                    return;
                }

                if (this.PurTaxInvoiceDetail.PurCompCd === null || this.PurTaxInvoiceDetail.PurCompCd === '') {

                    alert('매입처명을 입력해주세요.');
                    this.searchCompany();
                    return;
                }

                if (this.PurTaxInvoiceDetail.SrcDvsCd === null || this.PurTaxInvoiceDetail.SrcDvsCd === '') {

                    alert('매입구분을 선택해주세요.');
                    document.getElementById('selInOutDvsCd2').focus();
                    return;
                }

                if (this.PurTaxInvoiceDetail.PurAmt === null || this.PurTaxInvoiceDetail.PurAmt === '' || this.PurTaxInvoiceDetail.PurAmt === '0') {

                    alert('매입액을 입력해주세요.');
                    document.getElementById('txtPrice2').focus();
                    return;
                }

                if (this.PurTaxInvoiceDetail.IncludeVatYN === null || this.PurTaxInvoiceDetail.IncludeVatYN === '') {

                    alert('부가세구분을 선택해주세요.');
                    document.getElementById('selVatIncludeYn').focus();
                    return;
                }

                if (this.PurTaxInvoiceDetail.PurchaseYMD === null || this.PurTaxInvoiceDetail.PurchaseYMD === '') {

                    alert('매입일을 입력해주세요.');
                    document.getElementById('txtInOutYMD2').focus();
                    return;
                }

                var parent = this;
                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/UpsertPurTaxInvoice'.toRealServiceUrl();
                var obj = new Object();

                this.PurTaxInvoiceDetail.PurAmt = Number(this.PurTaxInvoiceDetail.PurAmt.replace(/[^0-9\.-]+/g, ''));
                this.PurTaxInvoiceDetail.PurchaseYMD = this.moment(this.PurTaxInvoiceDetail.PurchaseYMD).format('YYYYMMDD');

                obj.jsonString = JSON.stringify(this.PurTaxInvoiceDetail);

                // 서비스호출 성공 콜백 함수
                var successCallback = function (data) {

                    if (data.d > 0) {

                        if (parent.PageActionType === 'I') {

                            alert('등록되었습니다.');
                            $('#AC1011E_Modal').modal('hide');
                            parent.fn_ShowData();
                        } else {

                            alert('저장되었습니다.');
                            parent.fn_ShowData();
                        }
                    }
                }
                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 데이터 삭제
            fn_RemoveData() {

                if (confirm('매입세금계산서를 삭제하시겠습니까?')) {

                    var parent = this;
                    var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/UpsertPurTaxInvoice'.toRealServiceUrl();

                    this.PurTaxInvoiceDetail.Arg = 'D';
                    this.PurTaxInvoiceDetail.UserId = this.$parent.EmpId;

                    var obj = new Object();
                    obj.jsonString = JSON.stringify(this.PurTaxInvoiceDetail);

                    var successCallback = function (data) {

                        if (data.d > 0) {

                            alert('삭제되었습니다.');
                            $('#AC1011E_Modal').modal('hide');
                            parent.fn_ShowData();
                        }
                    }
                    $.scriptBase.executeAjaxAsync(url, obj, successCallback);
                }
            },
        }
    }
</script>