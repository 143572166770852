<template>
    <div class="modal fade" ref="CP1060P_Modal" id="CP1060P_Modal" tabindex="-1" role="dialog" aria-labelledby="defaultModal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" @click.Prevent="close">
                        <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                    </button>
                    <h3 class="modal-title">프로젝트 검색</h3>
                </div>
                <div class="modal-body">
                    <!-- 검색영역 -->
                    <section class="sch-wrap">
                        <form role="form">
                            <fieldset>
                                <legend>검색폼</legend>
                                <section class="sch-cont">
                                    <section class="cols-wrap">
                                        <div class="cols col-3">
                                            <label for="selTrgtYY" class="control-label"><span>년도</span></label>
                                            <select class="form-control" ref="CP1060P_selSection" name="CP1060P_selSection" v-model="TargetYear" @change="btnSearchClick">
                                                <option v-for="year in YearsList" v-bind:value="year">{{year}}</option>
                                            </select>
                                        </div>
                                        <div class="cols col-3">
                                            <label for="selCP1060P_StatusCd" class="control-label"><span>진행상태</span></label>
                                            <select class="form-control" ref="selCP1060P_StatusCd" name="selCP1060P_StatusCd" v-model="ProgressStatusCd" @change="btnSearchClick">
                                                <option value="">== 전체 ==</option>
                                                <option v-for="item in ProgressStatusCdList" v-bind:value="item.Code">{{item.Name}}</option>
                                            </select>
                                        </div>
                                        <div class="cols col-3">
                                            <label for="selCP1060P_PrjetSection" class="control-label"><span>프로젝트구분</span></label>
                                            <select class="form-control" ref="selCP1060P_PrjetSection" name="selCP1060P_PrjetSection" v-model="ProjectDvsCd" @change="btnSearchClick">
                                                <option value="">== 전체 ==</option>
                                                <option v-for="item in ProjectDvCdList" v-bind:value="item.Code">{{item.Name}}</option>
                                            </select>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtCP1060P_CompanyNm" class="control-label"><span>거래처</span></label>
                                            <div class="cols-btns-cont">
                                                <input type="text" ref="txtCP1060P_CompanyNm" class="form-control txt" readonly="readonly" v-model="CompanyDetail.CompanyNm" />
                                                <input type="hidden" ref="hidCP1060P_CompanyCd" v-model="CompanyDetail.CompanyCd" />
                                            </div>
                                            <div class="cols-btns">
                                                <button v-show="IsSelectCompany" type="button" id="btnCP1060P_ComPop" class="btn btn-default" @click.prevent="searchCompanyCode">
                                                    <span>
                                                        <i class="glyphicon glyphicon-search"></i> 검색
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="txtProjectNm" class="control-label"><span>프로젝트명</span></label>
                                            <div class="cols-btns-cont pad-r80">
                                                <input type="text" ref="txtCP1060P_ProjectNm" class="form-control txt" v-model="ProjectNmTxt" @keypress.enter.prevent="keypressEvent" />
                                            </div>
                                            <div class="cols-btns">
                                                <button type="button" class="btn btn-default" @click.prevent="clearSearchParams">
                                                    <span><i class="glyphicon glyphicon-repeat"></i> 초기화</span>
                                                </button>
                                            </div>
                                        </div>
                                    </section>
                                </section>

                                <section class="sch-btn">
                                    <a href="#" id="CP1060P_btnSearch" class="btn btn-primary" @click.prevent="btnSearchClick">
                                        <span><i class="glyphicon glyphicon-search"></i> 검색</span>
                                    </a>
                                </section>
                            </fieldset>
                        </form>
                    </section>
                    <!-- 테이블 -->
                    <section class="tbl-contents">
                        <div class="total alert" style="margin-bottom: 0;">
                            <p><i class="glyphicon glyphicon-folder-close"></i><span>전체: <span id="CP1060P_total">{{TotalCount}}</span>건</span></p>
                        </div>
                        <div class="table-responsive">
                            <table class="table tbl-list tbl-center table-hover table-bordered">
                                <caption>테이블</caption>
                                <colgroup>
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col">프로젝트명</th>
                                        <th scope="col">거래처명</th>
                                        <th scope="col">프로젝트 구분</th>
                                        <th scope="col">계약일자</th>
                                        <th scope="col">진행상태</th>
                                        <th scope="col">계약시작일자</th>
                                        <th scope="col">계약종료일자</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="ProjectList.length == 0">
                                        <td colspan="7">조회된 정보가 없습니다.</td>
                                    </tr>
                                    <tr v-else v-for="item in ProjectList" style="cursor:pointer;" v-on:click="rowClickEvent(item)">
                                        <td style="text-align:left;">{{item.ProjectNm}}</td>
                                        <td>{{item.CompanyNm}}</td>
                                        <td>{{item.ProjectTypeNm}}</td>
                                        <td>{{item.ContractYMD}}</td>
                                        <td>{{item.StatusName}}</td>
                                        <td>{{item.ConStartYMD}}</td>
                                        <td>{{item.ConEndYMD}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                    <!-- 페이징 -->
                    <div class="text-center">
                        <ul ref="CP1060P_pager" class="pagination"></ul>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default btn-sm" @click.Prevent="close"><span class="glyphicon glyphicon-remove"></span>취소(닫기)</button>
                </div>
            </div>
        </div>
        <CP1030P ref="CP1030P"></CP1030P>
    </div>
</template>

<script>
    import CP1030P from '@/components/libs/popup/CP1030P';
    export default {
        name: 'CP1060P',
        components: {
            CP1030P
        },
        data() {
            return {
                // 업체 선택 결과를 넘겨줄 콜백함수
                CallbackFunction: null,
                // 년도 목록
                YearsList: new Array(),
                // 진행상태 목록
                ProgressStatusCdList: new Array(),
                // 프로젝트 구분 목록
                ProjectDvCdList: new Array(),
                // 사용자 사번
                EmpId: this.$store.getters.getEmpId,
                CompanyCode: this.$store.getters.getCompanyCode,
                // 거래처 정보
                CompanyDetail: {
                    // 업체 이름
                    CompanyNm: '',
                    // 업체 코드
                    CompanyCd: ''
                },
                // 프로젝트명 검색어
                ProjectNmTxt: '',
                // 년도
                TargetYear: '',
                // 진행상태 코드
                ProgressStatusCd: '',
                // 프로젝트 구분 코드
                ProjectDvsCd: '',
                TopSize: 10,
                CurrentPage: 1,
                // 프로젝트 검색 팝업 내 거래처 검색 가능 여부
                IsSelectCompany: true,
                TotalCount: 0,
                // 조회된 인력 리스트
                ProjectList: new Array(),
            }
        },
        beforeMount() {
            // 년도 조회
            var startYear = this.moment(new Date()).add(1, 'year').year();
            var endYear = this.moment(new Date(2013, 1, 1, 0, 0, 0)).year();

            // 대상년도 목록 값 설정
            for (var i = startYear; i >= endYear; i--) {

                this.YearsList.push(i);
            }

            // 진행상태 코드
            this.ProgressStatusCdList = $.scriptBase.getCommonCode('CODE000', '0701000', '', '');

            // 프로젝트 구분 코드
            this.ProjectDvCdList = $.scriptBase.getCommonCode('CODE000', '0732000', '', '');
        },
        watch: {
            // 조회조건 - 거래처 코드 변경
            'CompanyDetail.CompanyCd'(newData) {
                this.btnSearchClick();
            },
        },
        methods: {
            // 프로젝트 검색 팝업 오픈
            // companyCode: 업체코드
            // companyName: 업체명
            // isSelectCompany: 프로젝트 검색 팝업 내 거래처 검색 가능 여부
            open(callbackFunction, companyCode, companyName, isSelectCompany) {

                this.CallbackFunction = callbackFunction;
                this.CurrentPage = 1;
                this.TotalCount = 0;
                this.ProjectList = new Array();
                this.IsSelectCompany = (isSelectCompany === undefined || isSelectCompany === null) ? true : isSelectCompany;

                // 년도 초기화
                this.TargetYear = this.moment(new Date()).year();
                // 진행상태 초기화
                this.ProgressStatusCd = '0701030';
                // 프로젝트 구분 초기화
                this.ProjectDvsCd = '';
                // 거래처 이름 초기화
                this.CompanyDetail.CompanyNm = (companyName === undefined || companyName === null) ? '' : companyName;
                // 거래처 아이디 초기화
                this.CompanyDetail.CompanyCd = (companyCode === undefined || companyCode === null) ? '' : companyCode;
                // 프로젝트명 초기화
                this.ProjectNmTxt = '';

                // 페이징 세팅
                $(this.$refs.CP1060P_pager).pager({
                    pager: this.$refs.CP1060P_pager,
                    totalItemCount: this.TotalCount,
                    recordPerPage: this.TopSize,
                    currentPage: this.CurrentPage,
                    pageIndexChanged: this.pageChangeEvent,
                })

                this.ShowData();
                $(this.$refs.CP1060P_Modal).modal('show');
            },
            close() {

                $(this.$refs.CP1060P_Modal).modal("hide");
            },
            // 프로젝트 검색 팝업 오픈
            // companyCode: 업체코드
            // companyName: 업체명
            // isSelectCompany: 프로젝트 검색 팝업 내 거래처 검색 가능 여부
            showProjectPopup(callbackFunction, companyCode, companyName, isSelectCompany) {

                this.CallbackFunction = callbackFunction;
                this.CurrentPage = 1;
                this.TotalCount = 0;
                this.ProjectList = new Array();
                this.IsSelectCompany = (isSelectCompany === undefined || isSelectCompany === null) ? true : isSelectCompany;

                // 년도 초기화
                this.TargetYear = this.moment(new Date()).year();
                // 진행상태 초기화
                this.ProgressStatusCd = '0701030';
                // 프로젝트 구분 초기화
                this.ProjectDvsCd = '';
                // 거래처 이름 초기화
                this.CompanyDetail.CompanyNm = (companyName === undefined || companyName === null) ? '' : companyName;
                // 거래처 아이디 초기화
                this.CompanyDetail.CompanyCd = (companyCode === undefined || companyCode === null) ? '' : companyCode;
                // 프로젝트명 초기화
                this.ProjectNmTxt = '';

                // 페이징 세팅
                $(this.$refs.CP1060P_pager).pager({
                    pager: this.$refs.CP1060P_pager,
                    totalItemCount: this.TotalCount,
                    recordPerPage: this.TopSize,
                    currentPage: this.CurrentPage,
                    pageIndexChanged: this.pageChangeEvent,
                });

                this.ShowData();
                $(this.$refs.CP1060P_Modal).modal('show');
            },
            // 검색 버튼 클릭 이벤트 핸들러
            btnSearchClick() {

                this.CurrentPage = 1;
                this.ShowData();
            },
            // 업체 검색 메서드
            searchCompanyCode() {

                var parent = this;
                var successCallback = function (result) {

                    // 결과값을 추출 후 돔객체에 바인딩
                    parent.CompanyDetail.CompanyNm = result.CompanyNm;
                    parent.CompanyDetail.CompanyCd = result.CompanyCd;
                }

                this.$refs.CP1030P.open(successCallback);
            },
            // 검색 조건 초기화 메서드
            clearSearchParams() {

                this.CurrentPage = 1;

                // 년도 초기화
                this.TargetYear = this.moment(new Date()).year();
                // 진행상태 초기화
                this.ProgressStatusCd = '0701030';
                // 프로젝트 구분 초기화
                this.ProjectDvsCd = '';
                // 거래처 초기화
                this.CompanyDetail.CompanyNm = '';
                this.CompanyDetail.CompanyCd = '';
                // 프로젝트명 초기화
                this.ProjectNmTxt = '';

                this.ShowData();
            },
            // 프로젝트 검색 메서드
            ShowData() {

                var parent = this;

                var url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/GetProjectList'.toRealServiceUrl();
                var obj = new Object();
                obj.prm = new Array();
                obj.prm.push("L");
                obj.prm.push(this.EmpId);
                obj.prm.push(this.CompanyCode);
                // 거래처 회사 코드
                obj.prm.push(this.CompanyDetail.CompanyCd);
                obj.prm.push("");
                // 프로젝트명
                obj.prm.push(this.ProjectNmTxt);
                // 년도
                obj.prm.push(this.TargetYear);
                // 진행상태
                obj.prm.push(this.ProgressStatusCd);
                // 프로젝트 구분
                obj.prm.push(this.ProjectDvsCd);
                obj.prm.push(this.TopSize);
                obj.prm.push(this.CurrentPage);

                // ajax 성공 시 실행되는 핸들러 정의
                var ajaxSuccessHandler = function (data) {

                    var table = JSON.parse(data.d).Table;
                    var table1 = JSON.parse(data.d).Table1;

                    parent.ProjectList = (table.length > 0) ? table : new Array();
                    parent.TotalCount = (table1.length > 0) ? table1[0].TotCnt : 0;

                    // 페이징 세팅
                    $(parent.$refs.CP1060P_pager).pager({
                        pager: parent.$refs.CP1060P_pager,
                        totalItemCount: parent.TotalCount,
                        recordPerPage: parent.TopSize,
                        currentPage: parent.CurrentPage,
                        pageIndexChanged: parent.pageChangeEvent,
                    });
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler);
            },
            // 페이지 변경 이벤트 핸들러
            pageChangeEvent(page) {

                this.CurrentPage = page;
                this.ShowData();
            },
            // 테이블Row 클릭 이벤트 핸들러
            rowClickEvent(item, year) {

                // 호출한 뷰로 선택한 인력정보 데이터 전달
                year = this.TargetYear;
                this.CallbackFunction(item, year);
                this.close();
            },
            // 검색어 필드에서 엔터키 입력 시 조회되도록 하는 키업 이벤트
            keypressEvent(event) {

                if (event.key == 'Enter') {

                    this.ShowData();
                }
            },
        }
    }
</script>