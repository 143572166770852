<template>
    <div>
        <DxDataGrid :show-borders="true"
                    :data-source="ProjectListAll"
                    :hoverStateEnabled="true"
                    @cell-click="onCellClicked"
                    @cell-prepared="onCellPrepared">
            <DxColumn data-field="ProjectNm"
                      caption="프로젝트명"
                      width="400" />
            <DxColumn data-field="ManagerNm"
                      caption="PM"
                      alignment="center" />
            <DxColumn data-field="ContractYMD"
                      caption="계약일자"
                      alignment="center" />
            <DxColumn data-field="ProjectTypeNm"
                      caption="구분"
                      alignment="center" />
            <DxColumn data-field="Price"
                      caption="계약금액"
                      alignment="right" />
            <DxColumn data-field="StatusName"
                      caption="진행상태"
                      alignment="center" />
            <DxColumn data-field="RegDate"
                      caption="등록일"
                      alignment="center" />
            <DxColumn data-field="RegEmpNm"
                      caption="등록자"
                      alignment="center" />
        </DxDataGrid>
        <PM1003E ref="PM1003E"></PM1003E>
    </div>
</template>

<script>
    import { DxDataGrid, DxColumn } from 'devextreme-vue/data-grid';
    import PM1003E from '@/components/pm/popup/PM1003E';

    export default {
        name: 'PM1000R_Detail',
        components: { DxDataGrid, DxColumn, PM1003E },
        props: {
            templateData: {
                type: Object,
                default: () => { }
            }
        },
        data() {
            return {
                ProjectListAll: new Array()
            };
        },
        mounted() {

            // 초기 조회
            this.getProjectListAll();
        },
        methods: {

            //  목록 조회
            getProjectListAll() {

                var parent = this;

                var ProjectDetailSelectTerm = {
                    Arg: 'L',
                    UserId: parent.$parent.$parent.$parent.$parent.EmpId,
                    CompanyCode: parent.$parent.$parent.$parent.$parent.CompanyCode,
                    AccountCompanyCode: parent.templateData.data.CompanyCd,
                    ProjectName: parent.$parent.$parent.$parent.ProjectSelectTerm.ProjectName,
                    TargetYear: parent.$parent.$parent.$parent.ProjectSelectTerm.TargetYear,
                    StatusCode: parent.$parent.$parent.$parent.ProjectSelectTerm.StatusCode,
                    ProjectCategoryCode: parent.$parent.$parent.$parent.ProjectSelectTerm.ProjectCategoryCode
                };

                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/GetNormalProject'.toRealServiceUrl();

                var obj = new Object();
                obj.jsonString = JSON.stringify(ProjectDetailSelectTerm);

                var ajaxSuccessHandler = function (data) {

                    var jsonObj = JSON.parse(data.d);
                    var table = jsonObj.Table;
                    var table1 = jsonObj.Table1;

                    parent.ProjectListAll = (table.length > 0) ? table : new Array();
                    parent.ProjectListTotalCount = (table1.length > 0) ? table1[0].TotCnt : 0;
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler);
            },
            onCellClicked(e) {

                if (e.rowType == 'data') {

                    var that = this;

                    var callbackFunction = function () {

                        that.getProjectListAll();
                    }
                    // 프로젝트 상세 팝업 오픈
                    this.$refs.PM1003E.open(callbackFunction, e.data);
                }
            },
            onCellPrepared(e) {

                if (e.rowType == 'header') {

                    e.cellElement.style['textAlign'] = 'center';
                }
                if (e.rowType == 'data') {

                    e.cellElement.style['verticalAlign'] = 'middle';
                }
            },
        }
    };
</script>