﻿export default {
    state: {
        canSearch: false,
        canNew: false,
        canSave: false,
        canDelete: false,
        canPrint: false,
        canApprove: false
    },
    mutations: {
        SET_CAN_SEARCH: function (state, canSearch) {
            state.canSearch = canSearch
        },
        SET_CAN_NEW: function (state, canNew) {
            state.canNew = canNew
        },
        SET_CAN_SAVE: function (state, canSave) {
            state.canSave = canSave
        },
        SET_CAN_DELETE: function (state, canDelete) {
            state.canDelete = canDelete
        },
        SET_CAN_PRINT: function (state, canPrint) {
            state.canPrint = canPrint
        },
        SET_CAN_APPROVE: function (state, canApprove) {
            state.canApprove = canApprove
        }
    },
    actions: {
        SetAuthInfo({ commit, state }, authInfo) {
            return new Promise(function (resolve, reject) {
                commit('SET_CAN_SEARCH', authInfo.CanSearch)
                commit('SET_CAN_NEW', authInfo.CanNew)
                commit('SET_CAN_SAVE', authInfo.CanSave)
                commit('SET_CAN_DELETE', authInfo.CanDelete)
                commit('SET_CAN_PRINT', authInfo.CanPrint)
                commit('SET_CAN_APPROVE', authInfo.CanApprove)
            })
        }
    }
}