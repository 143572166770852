<template>
    <div>
        <article class="sch-wrap">
            <form role="form">
                <fieldset>
                    <legend>검색폼</legend>
                    <section class="sch-cont">
                        <section class="cols-wrap">
                            <div class="cols col-2"></div>
                            <div id="div_DateType">
                                <div class="cols col-4">
                                    <label for="selTrgtYY" class="control-label"><span>지급년도</span></label>
                                    <select id="selTrgtYY" class="form-control">
                                        <!--@{
                                        DateTime arrangeDt = new DateTime(2015, 1, 1, 0, 0, 0);
                                        }
                                        @for (int i = DateTime.Now.AddYears(1).Year; i >= arrangeDt.Year; i--)
                                        {
                                        <text>
                                            <option value="@i" @if (@i.Equals(DateTime.Now.Year)) { <text> selected="selected" </text>           }>@i</option>
                                        </text>
                                        }-->
                                    </select>
                                </div>
                                <div class="cols col-4">
                                    <label for="selTrgtMM" class="control-label"><span>지급월</span></label>
                                    <select id="selTrgtMM" class="form-control">
                                        <!--@for (int i = 1; i <= 12; i++)
                                        {
                                        <text>
                                            <option value="@i" @if (@i.Equals(DateTime.Now.Month - 1)) { <text> selected="selected" </text>           }>@i</option>
                                        </text>
                                        }-->
                                    </select>
                                </div>
                            </div>
                        </section>
                    </section>
                    <section class="sch-btn">
                        <!--@if (ViewBag.CanSearch)
                        {
                        <a href="javascript:void(0);" id="btnListSrch" class="btn btn-primary"><span><i class="glyphicon glyphicon-search"></i> 조회</span></a>
                        }-->
                    </section>
                </fieldset>
            </form>
        </article>
        <article class="view-btns-wrap "></article>
        <!--버튼 영역부-->
        <section class="btn-align">
            <div class="btn-group">
                <button type="button" id="btnPDFExport" class="btn btn-primary" onclick="fn_export();"><span class="glyphicon glyphicon-save"></span>PDF추출</button>
            </div>
        </section>
        <article class="view-btns-wrap "></article>
        <section class="tbl-contents">
            <div class="alert alert-info">
                <p class="total"><i class="glyphicon glyphicon-folder-close"></i> 전체: <span id="spTotCnt">0</span>건</p>
            </div>
            <!--출장비 결재레포트 대상자 리스트-->
            <div id="addDataList"></div>
            <div class="text-center">
                <ul id="ulpager" class="pagination"></ul>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: 'AC1080R'
    }
</script>

<style scoped>
</style>