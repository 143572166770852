<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a v-if="this.$parent.CanSearch" href="#" id="btnListSrch" class="btn btn-primary btn-row2" @click.prevent="fn_ShowData(false)">
                    <span>
                        <i class="glyphicon glyphicon-search"></i> 조회
                    </span>
                </a>
                <a v-if="this.$parent.CanNew" href="#" id="btnListInst" class="btn btn-default" @click.prevent="fn_InstData">
                    <span>
                        <i class="glyphicon glyphicon-plus"></i> 추가
                    </span>
                </a>
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-3">
                                        <label for="selJobGradeCd" class="control-label">
                                            <span>직급</span>
                                        </label>
                                        <select class="form-control" id="selJobGradeCd" name="selJobGradeCd" v-model="EmpSelectTerm.JobGradeCode">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in JobGradeCodeList" v-bind:value="item.Code">{{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="cols col-3">
                                        <label for="selEmpType" class="control-label">
                                            <span>사원구분</span>
                                        </label>
                                        <select class="form-control" id="selEmpType" name="selEmpType" v-model="EmpSelectTerm.EmployeeTypeCode">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in EmpTypeCodeList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-3">
                                        <label for="selUseYN" class="control-label">
                                            <span>사용여부</span>
                                        </label>
                                        <div class="cols-btns-cont pad-r80">
                                            <select id="selUseYN" class="form-control" v-model="EmpSelectTerm.UseYn">
                                                <option value="">== 전체 ==</option>
                                                <option value="Y" selected="selected">Y</option>
                                                <option value="N">N</option>
                                            </select>
                                        </div>
                                        <div class="cols-btns">
                                            <a href="#" id="btnSrchInit" class="btn btn-default"
                                               @click.prevent="function(){
                                                    EmpSelectTerm.SearchKeyword = null
                                                    EmpSelectTerm.JobGradeCode = ''
                                                    EmpSelectTerm.UseYn = 'Y'
                                                    EmpSelectTerm.EmployeeTypeCode = '0305010'
                                                    EmpSelectTerm.SearchType = 'NM'
                                               }">
                                                <span>
                                                    <i class="glyphicon glyphicon-repeat"></i> 초기화
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </section>

                                <section class="cols-wrap">

                                    <div class="cols col-3">
                                        <label for="selSrchType" class="control-label">
                                            <span>검색조건</span>
                                        </label>
                                        <select id="selSrchType" class="form-control" v-model="EmpSelectTerm.SearchType">
                                            <option value="NM">사원명</option>
                                            <option value="NO">사원번호</option>
                                        </select>
                                    </div>
                                    <div class="cols col-3">
                                        <label for="txtSrchText" class="control-label">
                                            <span>검색명</span>
                                        </label>
                                        <input type="text" id="txtSrchText" class="form-control" v-model="EmpSelectTerm.SearchKeyword" />
                                    </div>
                                </section>
                            </section>

                        </fieldset>
                    </form>
                </div>

            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">사원 관리</p>
                </div>
                <div>
                    <section class="tbl-contents">
                        <div class="alert alert-info total">
                            <p class="total">
                                <i class="glyphicon glyphicon-folder-close"></i>
                                전체: <span id="spTotCnt">{{EmpListTotalCount}}</span>건
                            </p>
                        </div>
                        <div class="table-responsive">
                            <table class="table table tbl-list tbl-center table-hover table-bordered" id="tb0">
                                <caption>테이블</caption>
                                <colgroup>
                                    <col class="table-colwid10" />
                                    <col class="table-colwid10" />
                                    <col class="table-colwid10" />
                                    <col class="table-colwid10" />
                                    <col class="table-colwid10" />
                                    <col class="table-colwid25" />
                                    <col class="table-colwid5" />
                                    <col class="table-colwid10" />
                                    <col class="table-colwid10" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col">사원번호</th>
                                        <th scope="col">사원구분</th>
                                        <th scope="col">사원명</th>
                                        <th scope="col">직급명</th>
                                        <th scope="col">핸드폰번호</th>
                                        <th scope="col">이메일</th>
                                        <th scope="col">사용여부</th>
                                        <th scope="col">입사일</th>
                                        <th scope="col">퇴사일</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="EmpList === null">
                                        <td colspan="9" class="text-center no-data">조회된 정보가 없습니다.</td>
                                    </tr>
                                    <tr v-else v-for="emp in EmpList" style="cursor:pointer;" @click="rowSelectEvent(emp, true)">
                                        <td>{{emp.EmpNo}}</td>
                                        <td>{{emp.EmpTypeName}}</td>
                                        <td>{{emp.EmpNm}}</td>
                                        <td>{{emp.JobGradeNm}}</td>
                                        <td>{{emp.MobileNo}}</td>
                                        <td style="text-align:left;">{{emp.EMail}}</td>
                                        <td>{{emp.UseYN}}</td>
                                        <td>{{emp.EnterYMD}}</td>
                                        <td>{{emp.RetireYMD}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </section>
                    <!--리스트 영역-->
                </div>
            </div>
        </div>
        <div class="modal fade" ref="HM1001E_Modal" tabindex="-1" role="dialog" aria-labelledby="HM1001E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">
                            <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                        </button>
                        <h3 class="modal-title">사원 등록/수정</h3>
                    </div>
                    <div class="modal-body">
                        <section class="add-wrap">
                            <form>
                                <fieldset>
                                    <legend>..</legend>
                                    <section class="cols-wrap" id="divPhoto" style="display:none;">
                                        <div class="cols col-2">
                                            <label for="txtEmpNo2" class="control-label"></label>
                                            <section class="add-cont">
                                                <div class="col-xxs-12 col-xs-12">
                                                    <img src="" style="width:100px;height:100px;" />
                                                </div>
                                            </section>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtEmpNm2" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>사원명
                                                </span>
                                            </label>
                                            <input type="text" id="txtEmpNm2" class="form-control txt" v-model="EmpDetail.EmpNm" />
                                        </div>
                                        <div class="cols col-2">
                                            <label for="txtEmpNo2" class="control-label">
                                                <span>사원번호</span>
                                            </label>
                                            <input type="text" id="txtEmpNo2" class="form-control txt" readonly="readonly" v-model="EmpDetail.EmpNo" />
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtEmpId2" class="control-label asterisk">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>사원 ID
                                                </span>
                                            </label>
                                            <div class="cols-btns-cont">
                                                <input type="text" id="txtEmpId2" class="form-control" maxlength="15" v-model="EmpDetail.EmpID" v-bind:readonly="this.PageActionType === 'V'" @change="EmpDetail.IdUseYn = 'N'" />
                                                <input type="hidden" id="hidEmpId2" class="form-control txt" v-model="EmpDetail.IdUseYn" />
                                            </div>
                                            <div v-show="this.PageActionType === 'I'" class="cols-btns">
                                                <a href="#" id="btnEmpIdChk" class="btn btn-default" @click.prevent="fn_CheckEmpId">
                                                    <span>
                                                        <i class="glyphicon glyphicon-search"></i>검사
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="selEmpSex2" class="control-label">
                                                <span><i class="glyphicon glyphicon-asterisk"></i>성별</span>
                                            </label>
                                            <select class="form-control" id="selEmpSex2" name="selEmpSex2" v-model="EmpDetail.Sex">
                                                <option value="">== 선택 ==</option>
                                                <option v-for="item in EmpSexCodeList" v-bind:value="item.Code">{{item.Name}}</option>
                                            </select>
                                        </div>
                                    </section>
                                    <!--줄간격을 띄운다-->
                                    <section class="cols-wrap">
                                        <div class="cols">
                                            <br />
                                            <label></label>
                                        </div>
                                    </section>
                                    <!--/줄간격을 띄운다-->
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="selJobGradeCd2" class="control-label">
                                                <span><i class="glyphicon glyphicon-asterisk"></i>부서</span>
                                            </label>
                                            <select class="form-control" id="selDeptCd" name="selJobGradeCd2" v-model="EmpDetail.DeptCd" @change="onDeptChange($event)">
                                                <option value="">== 선택 ==</option>
                                                <option v-for="item in DeptCodeList" v-bind:value="item.Code">{{item.Name}}</option>
                                            </select>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="selEmpSkillLev2" class="control-label">
                                                <span>팀</span>
                                            </label>
                                            <select class="form-control" id="selEmpSkillLev2" name="selDeptTeamCd" v-model="EmpDetail.DeptTeamCd" v-bind:disabled="this.isTeam === false">
                                                <option value="">== 선택 ==</option>
                                                <option v-for="item in DeptTeamCodeList" v-bind:value="item.Code">{{item.Name}}</option>
                                            </select>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="selJobGradeCd2" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>직급
                                                </span>
                                            </label>
                                            <select class="form-control" id="selJobGradeCd2" name="selJobGradeCd2" v-model="EmpDetail.JobGradeCd">
                                                <option value="">== 선택 ==</option>
                                                <option v-for="item in JobGradeCodeList" v-bind:value="item.Code">{{item.Name}}</option>
                                            </select>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="selEmpSkillLev2" class="control-label">
                                                <span>기술등급</span>
                                            </label>
                                            <select class="form-control" id="selEmpSkillLev2" name="selEmpSkillLev2" v-model="EmpDetail.SkillLevel">
                                                <option value="">== 선택 ==</option>
                                                <option v-for="item in EmpSkillLevelCodeList" v-bind:value="item.Code">{{item.Name}}</option>
                                            </select>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="selEmpType2" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>사원구분
                                                </span>
                                            </label>
                                            <select class="form-control" id="selEmpType2" name="selEmpType2"
                                                    v-model="EmpDetail.EmpType"
                                                    @change="empTypeChangeEvent">
                                                <option value="">== 선택 ==</option>
                                                <option v-for="item in EmpTypeCodeList" v-bind:value="item.Code">{{item.Name}}</option>
                                            </select>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="txtBirthYMD2" class="control-label asterisk">
                                                <span>생년월일</span>
                                            </label>
                                            <div class="input-group date form_date" v-bind:data-date="CurrentDateYYMMDD" data-date-format="yyyy-mm-dd">
                                                <input type="text" id="txtBirthYMD2" class="form-control" maxlength="10"
                                                       v-bind:value="EmpDetail.BirthYMD"
                                                       @input="function(e){
                                                            EmpDetail.BirthYMD = e.target.value.toHyphenationFormatYMD()
                                                            e.target.value = e.target.value.toHyphenationFormatYMD()
                                                       }" />
                                                <span class="input-group-addon">
                                                    <span class="glyphicon glyphicon-calendar"></span>
                                                </span>
                                            </div>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtEMail2" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>이메일
                                                </span>
                                            </label>
                                            <div class="cols-btns-cont">
                                                <input type="text" id="txtEMail2" class="form-control txt" v-model="EmpDetail.EMail" @change="EmpDetail.EmailUseYn = 'N'" />
                                                <input type="hidden" id="hidEMail2" class="form-control txt" v-model="EmpDetail.EmailUseYn" />
                                            </div>
                                            <div class="cols-btns">
                                                <button type="button" id="btnChkEmail" class="btn btn-default" @click.prevent="fn_CheckEmail">
                                                    <span>
                                                        <i class="glyphicon glyphicon-search"></i> 검사
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="txtMobileNo2" class="control-label">
                                                <span>핸드폰번호</span>
                                            </label>
                                            <input type="text" id="txtMobileNo2" class="form-control txt" maxlength="13" placeholder="'-'은 제외하고 입력하세요."
                                                   :value="EmpDetail.MobileNo"
                                                   @input="function(e){
                                                        EmpDetail.MobileNo = e.target.value.replace(/[^0-9]/g, '').replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,'$1-$2-$3')
                                                        e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,'$1-$2-$3')
                                                   }" />
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="selBankCd2" class="control-label">
                                                <span>입금은행</span>
                                            </label>
                                            <select class="form-control" id="selBankCd2" name="selBankCd2" v-model="EmpDetail.BankCd">
                                                <option value="">== 전체 ==</option>
                                                <option v-for="item in BankCodeList" v-bind:value="item.Code">{{item.Name}}</option>
                                            </select>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="txtAccountNm" class="control-label">
                                                <span>계좌번호</span>
                                            </label>
                                            <input type="text" id="txtAccountNm" class="form-control txt" maxlength="50" placeholder="'-'은 제외하고 입력하세요."
                                                   v-bind:value="EmpDetail.AccountNm"
                                                   @input="function(e){
                                                        EmpDetail.AccountNm = e.target.value.toNumber()
                                                        e.target.value = e.target.value.toNumber()
                                                   }" />
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtResidentRegtNm" class="control-label">
                                                <span>주민번호</span>
                                            </label>
                                            <input type="text" id="txtResidentRegtNm" class="form-control txt" maxlength="14" placeholder="'-'은 제외하고 입력하세요."
                                                   v-bind:value="EmpDetail.ResidentRegtNm"
                                                   @input="function(e){
                                                        EmpDetail.ResidentRegtNm = e.target.value.toResidentRegistrationNumberFormat()
                                                        e.target.value = e.target.value.toResidentRegistrationNumberFormat()
                                                   }" />
                                        </div>
                                    </section>
                                    <!--줄간견을 띄운다-->
                                    <section class="cols-wrap">
                                        <div class="cols">
                                            <br />
                                            <label></label>
                                        </div>
                                    </section>
                                    <!--/줄간견을 띄운다-->
                                    <section class="cols-wrap">
                                        <div class="cols">
                                            <label for="txtPostNo2" class="control-label">
                                                <span>주소 </span>
                                            </label>
                                            <div class="col-xs-4">
                                                <div class="cols-btns-cont pad-r70">
                                                    <input type="text" id="txtDisplayPostNo2" class="form-control txt" readonly="readonly" v-model="EmpDetail.DisplayPostNo" />
                                                    <input type="hidden" id="hidPostNo2" v-model="EmpDetail.PostNo" />
                                                    <input type="hidden" id="hidPostSeqNo2" v-model="EmpDetail.PostSeqNo" />
                                                    <input type="hidden" id="hidBldgMgrNo2" v-model="EmpDetail.BldgMgrNo" />
                                                </div>
                                                <div class="cols-btns">
                                                    <a href="#" id="btnAddrPop" class="btn btn-default" @click.prevent="searchZipCode">
                                                        <span>
                                                            <i class="glyphicon glyphicon-search"></i> 검색
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xs-8">
                                                <input type="text" id="txtAddr12" class="form-control txt" readonly="readonly" v-model="EmpDetail.Addr1" />
                                            </div>
                                        </div>
                                        <div class="cols">
                                            <input type="text" id="txtAddr22" class="form-control" v-model="EmpDetail.Addr2" />
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtCareerStart2" class="control-label asterisk">
                                                <span>경력시작일</span>
                                            </label>
                                            <div class="input-group date form_date" v-bind:data-date="CurrentDateYYMMDD" data-date-format="yyyy-mm-dd">
                                                <input type="text" id="txtCareerStart2" class="form-control" maxlength="10"
                                                       v-bind:value="EmpDetail.CareerStart"
                                                       @input="function(e){
                                                            EmpDetail.CareerStart = e.target.value.toHyphenationFormatYMD()
                                                            e.target.value = e.target.value.toHyphenationFormatYMD()
                                                       }" />
                                                <span class="input-group-addon">
                                                    <span class="glyphicon glyphicon-calendar"></span>
                                                </span>
                                            </div>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div v-if="EmpDetail.EmpType != '0305040'" id="div_Enter" class="cols col-2">
                                            <label for="txtEnterYMD2" class="control-label asterisk">
                                                <span>입사일</span>
                                            </label>
                                            <div class="input-group date form_date" v-bind:data-date="CurrentDateYYMMDD" data-date-format="yyyy-mm-dd">
                                                <input v-if="EmpDetail.EmpType === '0305040'" type="text" id="txtEnterYMD2" class="form-control" readonly="readonly" />
                                                <input v-else type="text" id="txtEnterYMD2" class="form-control" maxlength="10"
                                                       v-bind:value="EmpDetail.EnterYMD"
                                                       @input="function(e){
                                                            EmpDetail.EnterYMD = e.target.value.toHyphenationFormatYMD()
                                                            e.target.value = e.target.value.toHyphenationFormatYMD()
                                                       }" />
                                                <span class="input-group-addon">
                                                    <span class="glyphicon glyphicon-calendar"></span>
                                                </span>
                                            </div>
                                        </div>
                                        <div v-if="EmpDetail.EmpType != '0305040'" id="div_LastPrmo" class="cols col-2">
                                            <label for="txtLastPrmoYMD2" class="control-label asterisk">
                                                <span>최종진급일</span>
                                            </label>
                                            <div class="input-group date form_date" v-bind:data-date="CurrentDateYYMMDD" data-date-format="yyyy-mm-dd">
                                                <input v-if="EmpDetail.EmpType === '0305040'" type="text" id="txtLastPrmoYMD2" class="form-control" readonly="readonly" />
                                                <input v-else type="text" id="txtLastPrmoYMD2" class="form-control" maxlength="10"
                                                       v-bind:value="EmpDetail.LastPrmoYMD"
                                                       @input="function(e){
                                                            EmpDetail.LastPrmoYMD = e.target.value.toHyphenationFormatYMD()
                                                            e.target.value = e.target.value.toHyphenationFormatYMD()
                                                       }" />
                                                <span class="input-group-addon">
                                                    <span class="glyphicon glyphicon-calendar"></span>
                                                </span>
                                            </div>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <!--프리랜서, 정직원 계약직의 경우에는 입사일, 최종진급일, 퇴사일이 나오게 한다.-->
                                        <div v-if="EmpDetail.EmpType != '0305040'" id="div_Retire" class="cols col-2">
                                            <label for="txtRetireYMD2" class="control-label asterisk">
                                                <span>퇴사일</span>
                                            </label>
                                            <div class="input-group date form_date" v-bind:data-date="CurrentDateYYMMDD" data-date-format="yyyy-mm-dd">
                                                <input v-if="EmpDetail.EmpType === '0305040'" type="text" id="txtRetireYMD2" class="form-control" readonly="readonly" />
                                                <input v-else type="text" id="txtRetireYMD2" class="form-control" maxlength="10"
                                                       v-bind:value="EmpDetail.RetireYMD"
                                                       @input="function(e){
                                                            EmpDetail.RetireYMD = e.target.value.toHyphenationFormatYMD()
                                                            e.target.value = e.target.value.toHyphenationFormatYMD()
                                                       }" />
                                                <span class="input-group-addon">
                                                    <span class="glyphicon glyphicon-calendar"></span>
                                                </span>
                                            </div>
                                        </div>
                                        <!--타업체일 경우에는 타업체명만을 기록해 둔다.-->
                                        <div v-if="EmpDetail.EmpType === '0305040'" id="div_OuterName" class="cols col-2">
                                            <label for="txtOuterName" class="control-label asterisk">
                                                <span>소속 업체명</span>
                                            </label>
                                            <div class="cols-btns-cont">
                                                <input type="text" id="txtOuterNm" class="form-control txt" readonly="readonly" v-model="EmpDetail.CompanyNm" />
                                                <input type="hidden" id="hidOuterCd" class="form-control txt" v-model="EmpDetail.AffiliatedCompanyCd" />
                                            </div>
                                            <div class="cols-btns">
                                                <a href="#" id="btnItemPop1" class="btn btn-default">
                                                    <span>
                                                        <i class="glyphicon glyphicon-search"></i> 검색
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="selUseYN2" class="control-label asterisk">
                                                <span>사용여부</span>
                                            </label>
                                            <select id="selUseYN2" class="form-control" v-model="EmpDetail.UseYN">
                                                <option value="Y">Y</option>
                                                <option value="N">N</option>
                                            </select>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="selRowCnt2" class="control-label">
                                                <span>테이블 행 갯수</span>
                                            </label>
                                            <select id="selRowCnt2" class="form-control" v-model="EmpDetail.BoardCnt">
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="15">15</option>
                                                <option value="20">20</option>
                                                <option value="30">30</option>
                                            </select>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="selGrade2" class="control-label">
                                                <span><i class="glyphicon glyphicon-asterisk"></i>권한등급</span>
                                            </label>
                                            <select class="form-control" id="selGrade2" name="selGrade2" v-model="EmpDetail.AuthGrade" v-bind:disabled="PageActionType != 'I' && this.$parent.AuthGrade < '0314200'">
                                                <option value="">== 선택 ==</option>
                                                <option v-for="item in GradeCodeList" v-bind:value="item.Code">{{item.Name}}</option>
                                            </select>
                                        </div>
                                    </section>
                                    <!--줄간견을 띄운다-->
                                    <section class="cols-wrap">
                                        <div class="cols">
                                            <br />
                                        </div>
                                    </section>
                                    <!--/줄간견을 띄운다-->
                                    <div class="panel-group" id="accordion">
                                        <section class="cols-wrap">
                                            <!--학력 테이블  -- tb1-->
                                            <div class="panel panel-default">
                                                <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#colap_Arcodian1">
                                                    <h5>
                                                        <i class="glyphicon glyphicon-plus"></i>
                                                        <strong> 학력 [<span id="sp_schoolHisCnt">{{EmpAcademicCareerListTotalCount}}</span>건]</strong>
                                                    </h5>
                                                </div>
                                                <div id="colap_Arcodian1" class="panel-collapse collapse">
                                                    <div class="panel-body">
                                                        <div class="btn-wrap border-bottom">
                                                            <div style="margin: 5px 0px 3px 0px" class="fr">
                                                                <a href="#" id="btnChldInst1" class="btn btn-default" title="추가" @click.prevent="fn_InstCareer(1)">
                                                                    <span class="glyphicon glyphicon-plus"></span> 추가
                                                                </a>
                                                                <a href="#" id="btnChldRmve1" class="btn btn-default" title="삭제" @click.prevent="fn_RemoveCareer(1)">
                                                                    <span class="glyphicon glyphicon-minus"></span> 삭제
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <section class="tbl-contents">
                                                            <div class="table-responsive">
                                                                <table class="table tbl-list table-hover table-bordered" id="tb1">
                                                                    <colgroup>
                                                                        <col class="chk" />
                                                                        <col class="table-colwid30" />
                                                                        <col />
                                                                        <col class="table-colwid20" />
                                                                    </colgroup>
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" class="text-center"></th>
                                                                            <th scope="col" class="text-center">학력구분</th>
                                                                            <th scope="col" class="text-center">학교명</th>
                                                                            <th scope="col" class="text-center">졸업년월</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-if="EmpAcademicCareerList === null">
                                                                            <td colspan="4" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                                        </tr>
                                                                        <tr v-else v-for="academy in EmpAcademicCareerList">
                                                                            <td class="chk">
                                                                                <label>
                                                                                    <input type="checkbox" name="cbItem1"
                                                                                           :corpcd="academy.CorpCd"
                                                                                           :empno="academy.EmpNo"
                                                                                           :seqno="academy.SeqNo"
                                                                                           @click="removeCareerCheckEvent(1, $event.target.checked, academy)" />
                                                                                </label>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <select class="selectpicker" id="selSchoolType2" name="selSchoolType2" v-model="academy.SchoolType">
                                                                                    <option value="">== 선택 ==</option>
                                                                                    <option v-for="item in AcademyTypeCodeList" v-bind:value="item.Code">{{item.Name}}</option>
                                                                                </select>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <input type="text" name="txtSchoolNm2" class="form-control" v-model="academy.SchoolNm" placeholder="학교명" />
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <input type="text" name="txtFinishYYMM2" class="form-control" maxlength="6" placeholder="졸업년월"
                                                                                       :value="academy.FinishYYMM"
                                                                                       @input="function(e){
                                                                                        academy.FinishYYMM = e.target.value.replace(/[^0-9]/g, '')
                                                                                        e.target.value = e.target.value.replace(/[^0-9]/g, '')
                                                                                       }" />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--직장 경력 테이블  -- tb2-->
                                            <div class="panel panel-default">
                                                <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#colap_Arcodian2">
                                                    <h5><i class="glyphicon glyphicon-plus"></i><strong> 직장 경력 [<span id="sp_companyHisCnt">{{EmpWorkCareerListTotalCount}}</span>건]</strong></h5>
                                                </div>
                                                <div id="colap_Arcodian2" class="panel-collapse collapse">
                                                    <div class="panel-body">
                                                        <div class="btn-wrap border-bottom">
                                                            <div style="margin: 5px 0px 3px 0px" class="fr">
                                                                <a href="#" id="btnChldInst2" class="btn btn-default" title="추가" @click.prevent="fn_InstCareer(2)">
                                                                    <span class="glyphicon glyphicon-plus"></span> 추가
                                                                </a>
                                                                <a href="#" id="btnChldRmve2" class="btn btn-default" title="삭제" @click.prevent="fn_RemoveCareer(2)">
                                                                    <span class="glyphicon glyphicon-minus"></span> 삭제
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <section class="tbl-contents">
                                                            <div class="table-responsive">
                                                                <table class="table tbl-list table-hover table-bordered" id="tb2">
                                                                    <colgroup>
                                                                        <col class="chk" />
                                                                        <col />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid30" />
                                                                        <col class="table-colwid30" />
                                                                    </colgroup>
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" class="text-center"></th>
                                                                            <th scope="col" class="text-center">회사명</th>
                                                                            <th scope="col" class="text-center">입사일</th>
                                                                            <th scope="col" class="text-center">퇴사일</th>
                                                                            <th scope="col" class="text-center">최종직급</th>
                                                                            <th scope="col" class="text-center">담당업무</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-if="EmpWorkCareerList === null">
                                                                            <td colspan="5" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                                        </tr>
                                                                        <tr v-else v-for="(company, index) in EmpWorkCareerList">
                                                                            <td class="chk">
                                                                                <label>
                                                                                    <input type="checkbox" name="cbItem2"
                                                                                           :corpcd="company.CorpCd"
                                                                                           :empno="company.EmpNo"
                                                                                           :seqno="company.SeqNo"
                                                                                           @click="removeCareerCheckEvent(2, $event.target.checked, company)" />
                                                                                </label>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <input type="text" name="txtCompanyNm2" class="form-control" v-model="company.CompanyNm" placeholder="회사명" />
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <div class="input-group date form_date" v-bind:data-date="CurrentDateYYMMDD" data-date-format="yyyy-mm-dd" :index="index">
                                                                                    <input type="text" id="txtWorkCareerStrDate" class="form-control" placeholder="입사일" maxlength="10"
                                                                                           v-bind:value="company.StrDate"
                                                                                           @input="function(e){
                                                                                                company.StrDate = e.target.value.toHyphenationFormatYMD()
                                                                                                e.target.value = e.target.value.toHyphenationFormatYMD()
                                                                                           }" />
                                                                                    <span class="input-group-addon">
                                                                                        <span class="glyphicon glyphicon-calendar"></span>
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <div class="input-group date form_date" v-bind:data-date="CurrentDateYYMMDD" data-date-format="yyyy-mm-dd" :index="index">
                                                                                    <input type="text" id="txtWorkCareerEndDate" class="form-control" maxlength="10" placeholder="퇴사일"
                                                                                           v-bind:value="company.EndDate"
                                                                                           @input="function(e){
                                                                                                company.EndDate = e.target.value.toHyphenationFormatYMD()
                                                                                                e.target.value = e.target.value.toHyphenationFormatYMD()
                                                                                           }" />
                                                                                    <span class="input-group-addon">
                                                                                        <span class="glyphicon glyphicon-calendar"></span>
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <input type="text" name="txtLastJobGradeNm2" class="form-control" v-model="company.LastJobGradeNm" placeholder="최종직급" />
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <input type="text" name="txtLastJobTaskNm2" class="form-control" v-model="company.Task" placeholder="담당업무" />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--프로젝트 경력 테이블 tb3-->
                                            <div class="panel panel-default">
                                                <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#colap_Arcodian3">
                                                    <h5>
                                                        <i class="glyphicon glyphicon-plus"></i>
                                                        <strong> 프로젝트 경력 [<span id="sp_projectHisCnt">{{EmpProjectCareerListTotalCount}}</span>건]</strong>
                                                    </h5>
                                                </div>
                                                <div id="colap_Arcodian3" class="panel-collapse collapse">
                                                    <div class="panel-body">
                                                        <div class="btn-wrap border-bottom">
                                                            <div style="margin: 5px 0px 3px 0px" class="fr">
                                                                <a href="#" id="btnChldInst3" class="btn btn-default" title="추가" @click.prevent="fn_InstCareer(3)">
                                                                    <span class="glyphicon glyphicon-plus"></span> 추가
                                                                </a>
                                                                <a href="#" id="btnChldRmve3" class="btn btn-default" title="삭제" @click.prevent="fn_RemoveCareer(3)">
                                                                    <span class="glyphicon glyphicon-minus"></span> 삭제
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <section class="tbl-contents">
                                                            <div class="table-responsive">
                                                                <table class="table tbl-list table-hover table-bordered" id="tb3">
                                                                    <colgroup>
                                                                        <col class="chk" />
                                                                        <col />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid20" />
                                                                        <col class="table-colwid20" />
                                                                    </colgroup>
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" class="text-center"></th>
                                                                            <th scope="col" class="text-center">프로젝트명/ <br>근무회사 </th>
                                                                            <th scope="col" class="text-center">시작일/ <br> 종료일</th>
                                                                            <th scope="col" class="text-center">고객사/ <br>운영체제</th>
                                                                            <th scope="col" class="text-center">언어/ <br> DBMS</th>
                                                                            <th scope="col" class="text-center">개발툴/ <br>역할</th>
                                                                            <th scope="col" class="text-center">설명/ <br>기타</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-if="EmpProjectCareerList === null">
                                                                            <td colspan="7" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                                        </tr>
                                                                        <tr v-else v-for="(project, index) in EmpProjectCareerList">
                                                                            <td class="chk">
                                                                                <label>
                                                                                    <input type="checkbox" name="cbItem3"
                                                                                           :corpcd="project.CorpCd"
                                                                                           :empno="project.EmpNo"
                                                                                           :seqno="project.SeqNo"
                                                                                           @click="removeCareerCheckEvent(3, $event.target.checked, project)" />
                                                                                </label>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <input type="text" name="txtProjectNm2" class="form-control" v-model="project.ProjectNm" placeholder="프로젝트명" />
                                                                                <br>
                                                                                <input type="text" name="txtProjectComNM2" class="form-control" v-model="project.AffiliatedCompany" placeholder="근무회사" />
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <div class="input-group date form_date" v-bind:data-date="CurrentDateYYMMDD" data-date-format="yyyy-mm-dd" :index="index">
                                                                                    <input type="text" id="txtProjectCareerStrDate" class="form-control" placeholder="시작일" maxlength="10"
                                                                                           v-bind:value="project.StrDate"
                                                                                           @input="function(e){
                                                                                                project.StrDate = e.target.value.toHyphenationFormatYMD()
                                                                                                e.target.value = e.target.value.toHyphenationFormatYMD()
                                                                                           }" />
                                                                                    <span class="input-group-addon">
                                                                                        <span class="glyphicon glyphicon-calendar"></span>
                                                                                    </span>
                                                                                </div>
                                                                                <br>
                                                                                <div class="input-group date form_date" v-bind:data-date="CurrentDateYYMMDD" data-date-format="yyyy-mm-dd" :index="index">
                                                                                    <input type="text" id="txtProjectCareerEndDate" class="form-control" placeholder="종료일" maxlength="10"
                                                                                           v-bind:value="project.EndDate"
                                                                                           @input="function(e){
                                                                                                project.EndDate = e.target.value.toHyphenationFormatYMD()
                                                                                                e.target.value = e.target.value.toHyphenationFormatYMD()
                                                                                           }" />
                                                                                    <span class="input-group-addon">
                                                                                        <span class="glyphicon glyphicon-calendar"></span>
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <input type="text" name="txtCustomer2" class="form-control" v-model="project.Customer" placeholder="고객사" />
                                                                                <br>
                                                                                <input type="text" name="txProjecttOS2" class="form-control" v-model="project.DevOS" placeholder="운영체제" />
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <input type="text" name="txtProjectLang2" class="form-control" v-model="project.DevLanguage" placeholder="언어" />
                                                                                <br>
                                                                                <input type="text" name="txProjecttDBMS2" class="form-control" v-model="project.DevDBMS" placeholder="DBMS" />
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <input type="text" name="txtProjectDevTool2" class="form-control" v-model="project.DevTools" placeholder="개발툴" />
                                                                                <br>
                                                                                <select class="selectpicker" id="selRoleCd2" name="selRoleCd2" v-model="project.RoleCd">
                                                                                    <option value="">== 선택 ==</option>
                                                                                    <option v-for="item in RoleCodeList" v-bind:value="item.Code">{{item.Name}}</option>
                                                                                </select>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <input type="text" name="txtProjectDesc2" class="form-control" v-model="project.ProjectDesc" placeholder="설명" />
                                                                                <br>
                                                                                <input type="text" name="txtProjectETC2" class="form-control" v-model="project.DevComment" placeholder="기타" />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--자격증 테이블  -- tb4-->
                                            <div class="panel panel-default">
                                                <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#colap_Arcodian4">
                                                    <h5><i class="glyphicon glyphicon-plus"></i><strong> 자격증, 상훈 [<span id="sp_licenseHisCnt">{{EmpLicenseListTotalCount}}</span>건]</strong></h5>
                                                </div>
                                                <div id="colap_Arcodian4" class="panel-collapse collapse">
                                                    <div class="panel-body">
                                                        <div class="btn-wrap border-bottom">
                                                            <div style="margin: 5px 0px 3px 0px" class="fr">
                                                                <a href="#" id="btnChldInst4" class="btn btn-default" title="추가" @click.prevent="fn_InstCareer(4)">
                                                                    <span class="glyphicon glyphicon-plus"></span> 추가
                                                                </a>
                                                                <a href="#" id="btnChldRmve4" class="btn btn-default" title="삭제" @click.prevent="fn_RemoveCareer(4)">
                                                                    <span class="glyphicon glyphicon-minus"></span> 삭제
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <section class="tbl-contents">
                                                            <div class="table-responsive">
                                                                <table class="table tbl-list table-hover table-bordered" id="tb4">
                                                                    <colgroup>
                                                                        <col class="chk" />
                                                                        <col />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid20" />
                                                                        <col class="table-colwid30" />
                                                                    </colgroup>
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" class="text-center"></th>
                                                                            <th scope="col" class="text-center">자격증,상훈명</th>
                                                                            <th scope="col" class="text-center">취득년월</th>
                                                                            <th scope="col" class="text-center">주관기관</th>
                                                                            <th scope="col" class="text-center">설명</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-if="EmpLicenseList === null">
                                                                            <td colspan="5" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                                        </tr>
                                                                        <tr v-else v-for="license in EmpLicenseList">
                                                                            <td class="chk">
                                                                                <label>
                                                                                    <input type="checkbox" name="cbItem4"
                                                                                           :corpcd="license.CorpCd"
                                                                                           :empno="license.EmpNo"
                                                                                           :seqno="license.SeqNo"
                                                                                           @click="removeCareerCheckEvent(4, $event.target.checked, license)" />
                                                                                </label>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <input type="text" name="txtLicenseNm2" class="form-control" v-model="license.LicenseNm" placeholder="자격증, 상훈명" />
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <input type="text" name="txtLicenseYYMM2" class="form-control" maxlength="6" placeholder="취득년월"
                                                                                       :value="license.LicenseYYMM"
                                                                                       @input="function(e){
                                                                                        license.LicenseYYMM = e.target.value.replace(/[^0-9]/g, '')
                                                                                        e.target.value = e.target.value.replace(/[^0-9]/g, '')
                                                                                       }" />
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <input type="text" name="txtLicenseDept2" class="form-control" v-model="license.LicenseDept" placeholder="주관기관" />
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <input type="text" name="txtLicenseDesc2" class="form-control" v-model="license.LicenseDesc" placeholder="설명" />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--기술 테이블  -- tb6-->
                                            <div class="panel panel-default">
                                                <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#colap_Arcodian6">
                                                    <h5><i class="glyphicon glyphicon-plus"></i><strong> 기술 경력  [<span id="sp_TequnicCnt">{{EmpTechnicalCareerListTotalCount}}</span>건]</strong></h5>
                                                </div>
                                                <div id="colap_Arcodian6" class="panel-collapse collapse">
                                                    <div class="panel-body">
                                                        <div class="btn-wrap border-bottom">
                                                            <div style="margin: 5px 0px 3px 0px" class="fr">
                                                                <a href="#" id="btnChldInst6" class="btn btn-default" title="추가" @click.prevent="fn_InstCareer(5)">
                                                                    <span class="glyphicon glyphicon-plus"></span> 추가
                                                                </a>
                                                                <a href="#" id="btnChldRmve6" class="btn btn-default" title="삭제" @click.prevent="fn_RemoveCareer(5)">
                                                                    <span class="glyphicon glyphicon-minus"></span> 삭제
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <section class="tbl-contents">
                                                            <div class="table-responsive">
                                                                <table class="table tbl-list table-hover table-bordered" id="tb6">
                                                                    <colgroup>
                                                                        <col class="chk" />
                                                                        <col class="table-colwid20" />
                                                                        <col class="table-colwid30" />
                                                                    </colgroup>
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" class="text-center"></th>
                                                                            <th scope="col" class="text-center">특수 기술</th>
                                                                            <th scope="col" class="text-center">숙련도</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-if="EmpTechnicalCareerList === null">
                                                                            <td colspan="3" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                                        </tr>
                                                                        <tr v-else v-for="technical in EmpTechnicalCareerList">
                                                                            <td class="chk">
                                                                                <label>
                                                                                    <input type="checkbox" name="cbItem5"
                                                                                           :corpcd="technical.CorpCd"
                                                                                           :empno="technical.EmpNo"
                                                                                           :seqno="technical.SeqNo"
                                                                                           @click="removeCareerCheckEvent(5, $event.target.checked, technical)" />
                                                                                </label>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <input type="text" name="txtTequnicNm2" class="form-control" v-model="technical.Technique" placeholder="특수기술" />
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <input type="text" name="txtTequnicLev2" class="form-control" v-model="technical.Skill" placeholder="숙련도" />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--교육 테이블  -- tb7-->
                                            <div class="panel panel-default">
                                                <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#colap_Arcodian7">
                                                    <h5><i class="glyphicon glyphicon-plus"></i><strong> 교육 수료 경력 [<span id="sp_EduHisCnt">{{EmpEducationCareerListTotalCount}}</span>건]</strong></h5>
                                                </div>
                                                <div id="colap_Arcodian7" class="panel-collapse collapse">
                                                    <div class="panel-body">
                                                        <div class="btn-wrap border-bottom">
                                                            <div style="margin: 5px 0px 3px 0px" class="fr">
                                                                <a href="#" id="btnChldInst7" class="btn btn-default" title="추가" @click.prevent="fn_InstCareer(6)">
                                                                    <span class="glyphicon glyphicon-plus"></span> 추가
                                                                </a>
                                                                <a href="#" id="btnChldRmve7" class="btn btn-default" title="삭제" @click.prevent="fn_RemoveCareer(6)">
                                                                    <span class="glyphicon glyphicon-minus"></span> 삭제
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <section class="tbl-contents">
                                                            <div class="table-responsive">
                                                                <table class="table tbl-list table-hover table-bordered" id="tb7">
                                                                    <colgroup>
                                                                        <col class="chk" />
                                                                        <col />
                                                                        <col class="table-colwid200" />
                                                                        <col class="table-colwid20" />
                                                                        <col class="table-colwid30" />
                                                                    </colgroup>
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" class="text-center"></th>
                                                                            <th scope="col" class="text-center">교육명</th>
                                                                            <th scope="col" class="text-center">교육시작일</th>
                                                                            <th scope="col" class="text-center">교육종료일</th>
                                                                            <th scope="col" class="text-center">교육기관</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-if="EmpEducationCareerList === null">
                                                                            <td colspan="5" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                                        </tr>
                                                                        <tr v-else v-for="(education, index) in EmpEducationCareerList">
                                                                            <td class="chk">
                                                                                <label>
                                                                                    <input type="checkbox" name="cbItem6"
                                                                                           :corpcd="education.CorpCd"
                                                                                           :empno="education.EmpNo"
                                                                                           :seqno="education.SeqNo"
                                                                                           @click="removeCareerCheckEvent(6, $event.target.checked, education)" />
                                                                                </label>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <input type="text" name="txtEdutNm2" class="form-control" v-model="education.EducationNm" placeholder="교육명" />
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <div class="input-group date form_date" v-bind:data-date="CurrentDateYYMMDD" data-date-format="yyyy-mm-dd" :index="index">
                                                                                    <input type="text" id="txtEducationCareeStrDate" class="form-control" placeholder="교육시작일" maxlength="10"
                                                                                           v-bind:value="education.StrDate"
                                                                                           @input="function(e){
                                                                                                education.StrDate = e.target.value.toHyphenationFormatYMD()
                                                                                                e.target.value = e.target.value.toHyphenationFormatYMD()
                                                                                           }" />
                                                                                    <span class="input-group-addon">
                                                                                        <span class="glyphicon glyphicon-calendar"></span>
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <div class="input-group date form_date" v-bind:data-date="CurrentDateYYMMDD" data-date-format="yyyy-mm-dd" :index="index">
                                                                                    <input type="text" id="txtEducationCareeEndDate" class="form-control" placeholder="교육종료일" maxlength="10"
                                                                                           v-bind:value="education.EndDate"
                                                                                           @input="function(e){
                                                                                                education.EndDate = e.target.value.toHyphenationFormatYMD()
                                                                                                e.target.value = e.target.value.toHyphenationFormatYMD()
                                                                                           }" />
                                                                                    <span class="input-group-addon">
                                                                                        <span class="glyphicon glyphicon-calendar"></span>
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <input type="text" name="txtEduOrga2" class="form-control" v-model="education.OrgName" placeholder="교육기관" />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--첨부파일 테이블  -- tb5-->
                                            <div class="panel panel-default">
                                                <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#colap_Arcodian5">
                                                    <h5>
                                                        <i class="glyphicon glyphicon-plus"></i>
                                                        <strong>
                                                            첨부파일 [<span id="sp_AddFileCnt">{{AttachFileListTotalCount}}</span>건]
                                                        </strong>
                                                    </h5>
                                                </div>
                                                <div id="colap_Arcodian5" class="panel-collapse collapse">
                                                    <div class="panel-body">
                                                        <div class="btn-wrap border-bottom">
                                                            <div style="margin: 5px 0px 3px 0px" class="fr">
                                                                <a href="#" id="btnChldInst5" class="btn btn-default" title="추가" @click.prevent="fn_InstCareer(7)">
                                                                    <span class="glyphicon glyphicon-plus"></span> 추가
                                                                </a>
                                                                <a href="#" id="btnChldRmve5" class="btn btn-default" title="삭제" @click.prevent="fn_RemoveCareer(7)">
                                                                    <span class="glyphicon glyphicon-minus"></span> 삭제
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <section class="tbl-contents">
                                                            <div class="table-responsive">
                                                                <table class="table tbl-list table-hover table-bordered" id="tb5">
                                                                    <colgroup>
                                                                        <col class="chk" />
                                                                        <col class="table-colwid20" />
                                                                        <col class="chk" />
                                                                        <col />
                                                                    </colgroup>
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" class="text-center"></th>
                                                                            <th scope="col" class="text-center">첨부구분</th>
                                                                            <th scope="col" class="text-center">대표이미지</th>
                                                                            <th scope="col" class="text-center">첨부파일</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-if="AttachFileList === null">
                                                                            <td colspan="5" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                                        </tr>
                                                                        <tr v-else v-for="file in AttachFileList" v-show="file.IsVisible">
                                                                            <td class="chk">
                                                                                <label>
                                                                                    <input type="checkbox" name="cbItem7" @click="removeCareerCheckEvent(7, $event.target.checked, file)" />
                                                                                </label>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <select class="selectpicker" id="selFileDvsCode2" name="selFileDvsCode2" v-model="file.FileDvsCode">
                                                                                    <option value="">== 선택 ==</option>
                                                                                    <option v-for="item in FileDvsCodeList" v-bind:value="item.Code">{{item.Name}}</option>
                                                                                </select>
                                                                            </td>
                                                                            <td class="chk">
                                                                                <input type="checkbox" name="cbIsPhotoYn"
                                                                                       v-bind:checked="file.IsPhotoYn === 'Y'"
                                                                                       @click="representativeImagecheckedEvent($event.target.checked, file)" />
                                                                            </td>
                                                                            <td v-if="file.CRUD === 'C'" class="text-center">
                                                                                <input type="file" name="ctlAttachFile"
                                                                                       @change="function(e){
                                                                                            file.FileName = e.target.files[0].name
                                                                                            file.Files = e.target.files
                                                                                       }" />
                                                                            </td>
                                                                            <td v-else class="text-left">
                                                                                <a href="#" @click.prevent="fileHandler.download(file.GUID)">{{file.FileName}}</a>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    <section class="btn-align">
                                        <div class="btn-group">
                                            <button type="button" id="btnPDFexport" class="btn btn-primary" @click="exportPdf">
                                                <span class="glyphicon glyphicon-save"></span>pdf추출
                                            </button>
                                        </div>
                                        <div v-show="this.UserGrpId == '0011007'" class="btn-group">
                                            <button type="button" id="btnResetPassword" class="btn btn-primary" @click="resetPassword">
                                                <span class="glyphicon glyphicon-save"></span> 비밀번호 리셋
                                            </button>
                                        </div>
                                        <div v-show="this.$parent.CanSave" class="btn-group">
                                            <button type="button" id="btnItemSave" class="btn btn-primary" @click="validationCheckSaveData">
                                                <span class="glyphicon glyphicon-save"></span> 저장
                                            </button>
                                        </div>
                                        <div v-show="this.$parent.CanDelete && PageActionType != 'I'" class="btn-group" @click="fn_RemoveData">
                                            <button type="button" id="btnItemRmve" class="btn btn-warning">
                                                <span class="glyphicon glyphicon-edit"></span> 삭제
                                            </button>
                                        </div>
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-danger" data-dismiss="modal">
                                                <span class="glyphicon glyphicon-remove"></span> 취소
                                            </button>
                                        </div>
                                    </section>
                                </fieldset>
                            </form>
                        </section>
                    </div>
                </div>
            </div>
        </div>
        <HM1002E ref="HM1002E"></HM1002E>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle';
    import HM1002E from '@/components/hm/popup/HM1002E';
    export default {
        name: 'HM1000R',
        components: {
            ViewTitle, HM1002E,
        },
        data() {
            return {
                JobGradeCodeList: null,                 // 직급 공통코드 목록
                EmpTypeCodeList: null,                  // 사원구분 공통코드 목록
                GradeCodeList: null,                    // 권한등급 공통코드 목록
                EmpSexCodeList: null,                   // 성별 공통코드 목록
                EmpSkillLevelCodeList: null,            // 기술등급 공통코드 목록
                BankCodeList: null,                     // 은행 공통코드 목록
                AcademyTypeCodeList: null,              // 학력구분 공통코드 목록
                RoleCodeList: null,                     // 역할 공통코드 목록
                FileDvsCodeList: null,                  // 파일 구분 공통코드 목록
                EmpDataSet: null,                       // 사원관리 조회 DataSet
                EmpList: null,                          // 사원 목록
                // 사원 상세 객체
                EmpDetail: {
                    CorpCd: null,
                    EmpNo: null,
                    EmpNm: null,
                    EmpType: null,
                    EmpID: null,
                    EmpTypeName: null,
                    BirthYMD: null,
                    JobGradeCd: null,
                    JobGradeNm: null,
                    MobileNo: null,
                    EMail: null,
                    EnterYMD: null,
                    ExpertDate: null,
                    LastPrmoYMD: null,
                    RetireYMD: null,
                    UseYN: null,
                    CareerStart: null,
                    CompanyNm: null,
                    AffiliatedCompanyCd: null,
                    Sex: null,
                    SEXNM: null,
                    Department: null,
                    SkillLevel: null,
                    SkillLevelNM: null,
                    PostNo: null,
                    PostSeqNo: null,
                    DisplayPostNo: null,
                    BldgMgrNo: null,
                    Addr1: null,
                    Addr2: null,
                    ResidentRegtNm: null,
                    AccountNm: null,
                    BankCd: null,
                    BankNm: null,
                    AuthGrade: null,
                    BoardCnt: null,
                    DeptCd: null,
                    DeptTeamCd: null
                },
                EmpAcademicCareerList: null,            // 사원 학력 목록
                EmpWorkCareerList: null,                // 사원 직장경력 목록
                EmpProjectCareerList: null,             // 사원 프로젝트 목록
                EmpLicenseList: null,                   // 사원 자격증, 상훈 목록
                EmpTechnicalCareerList: null,           // 사원 기술경력 목록
                EmpEducationCareerList: null,           // 사원 교육경력 목록
                AttachFileList: null,                   // 사원 첨부파일 목록
                RemoveAcademicCareerList: null,         // 사원 학력 삭제 목록
                RemoveWorkCareerList: null,             // 사원 직장경력 삭제 목록
                RemoveProjectCareerList: null,          // 사원 프로젝트경력 삭제 목록
                RemoveLicenseList: null,                // 사원 자격증, 상훈 삭제 목록
                RemoveTechnicalCareerList: null,        // 사원 기술경력 삭제 목록
                RemoveEducationCareerList: null,        // 사원 교육경력 삭제 목록
                RemoveAttachFileList: null,             // 사원 첨부파일 삭제 목록
                EmpListTotalCount: 0,                   // 사원 목록 전체 건 수
                EmpAcademicCareerListTotalCount: 0,     // 사원 학력 목록 전체 건 수
                EmpWorkCareerListTotalCount: 0,         // 사원 직장경력 목록 전체 건 수
                EmpProjectCareerListTotalCount: 0,      // 사원 프로젝트경력 목록 전체 건 수
                EmpLicenseListTotalCount: 0,            // 사원 자격증, 상훈 목록 전체 건 수
                EmpTechnicalCareerListTotalCount: 0,    // 사원 기술경력 목록 전체 건 수
                EmpEducationCareerListTotalCount: 0,    // 사원 교육경력 목록 전체 건 수
                AttachFileListTotalCount: 0,            // 사원 첨부파일 목록 전체 건 수
                DateTimePickerTotalCount: 0,            // datetiempicker 총 갯수
                ResultUploadFile: null,                 // 첨부파일 업로드 완료 후 결과 값
                // 사원관리 조회조건 객체
                EmpSelectTerm: {
                    Arg: null,
                    UserId: this.$parent.EmpId,
                    CompanyCode: this.$parent.CompanyCode,
                    JobGradeCode: '',
                    UseYn: 'Y',
                    SearchType: 'NM',
                    SearchKeyword: null,
                    EmployeeTypeCode: '0305010'
                },
                PageActionType: 'V',
                CurrentDateYYMMDD: this.moment(new Date()).format('YYYY-MM-DD'),
                DeptCodeList: null,
                DeptTeamCodeList: null,
                // 부서의 팀 존재 여부
                isTeam: false,
                // 사용자 권한 그룹
                UserGrpId: this.$store.getters.getUserGrpId,
            }
        },
        beforeMount() {

            // 공통코드 조회
            this.EmpTypeCodeList = $.scriptBase.getCommonCode('CODE000', '0305000', '', '')
            this.GradeCodeList = $.scriptBase.getCommonCode('CODE000', '0314000', '', '')
            this.EmpSexCodeList = $.scriptBase.getCommonCode('CODE000', '0312000', '', '')
            this.EmpSkillLevelCodeList = $.scriptBase.getCommonCode('CODE000', '0313000', '', '')
            this.BankCodeList = $.scriptBase.getCommonCode('CODE000', '0101000', '', '')
            this.AcademyTypeCodeList = $.scriptBase.getCommonCode('CODE000', '0307000', '', '')
            this.RoleCodeList = $.scriptBase.getCommonCode('CODE000', '0707000', '', '')
            this.FileDvsCodeList = $.scriptBase.getCommonCode('CODE000', '0309000', '', '')
            this.DeptCodeList = $.scriptBase.getCommonCode('CODE000', '0316000', '', '')

        },
        mounted() {

            // 공통코드 조회
            this.JobGradeCodeList = $.scriptBase.getCommonCode('CODE000', '0303000', '', '')

            if (this.$parent.CanSearch) {

                this.fn_ShowData(false)
            }
        },
        updated() {

            var dateTimePickerElementList = document.querySelectorAll('.form_date')

            // datetimepicker 설정 적용
            // datetimepicker가 추가되었다면 화면 업데이트 시 재설정 함
            if (this.DateTimePickerTotalCount < dateTimePickerElementList.length) {

                for (var i = this.DateTimePickerTotalCount; i < dateTimePickerElementList.length; i++) {

                    // datetimepicker 설정
                    this.$parent.setDateTimePicker(this.dateTimePickerChangedEvent)
                }

                // datetimepicker 총 갯수 업데이트
                this.DateTimePickerTotalCount = dateTimePickerElementList.length
            }
        },
        watch: {
            'EmpDetail.DeptCd': {
                deep: true,
                handler(newData) {

                    if (newData != '') {
                        this.DeptTeamCodeList = $.scriptBase.getCommonCode('CODE000', newData, '', '')
                        if (this.DeptTeamCodeList.length != 0) {
                            this.isTeam = true
                        }
                        else {
                            this.isTeam = false
                        }
                    }
                    else {
                        this.isTeam = false
                    }
                }
            }
        },
        methods: {
            // 대표이미지 체크박스 체크 이벤트
            // targetFile: 체크한 대상 파일
            representativeImagecheckedEvent: function (isChecked, targetFile) {

                // 대상파일의 대표이미지 값 모두 N으로 변경
                this.AttachFileList.forEach(function (file) {

                    file.IsPhotoYn = 'N'
                })

                // 대상파일 대표이미지 값 설정
                if (isChecked) {

                    targetFile.IsPhotoYn = 'Y'
                }
            },
            // 날짜변경 이벤트
            dateTimePickerChangedEvent: function (e) {

                var targetInputElement = e.target.querySelector('input')
                var selectDate = this.moment(e.date).format('YYYY-MM-DD')

                switch (targetInputElement.id) {

                    case 'txtBirthYMD2': {

                        this.EmpDetail.BirthYMD = selectDate
                        break
                    }
                    case 'txtCareerStart2': {

                        this.EmpDetail.CareerStart = selectDate
                        break
                    }
                    case 'txtEnterYMD2': {

                        this.EmpDetail.EnterYMD = selectDate
                        break
                    }
                    case 'txtLastPrmoYMD2': {

                        this.EmpDetail.LastPrmoYMD = selectDate
                        break
                    }
                    case 'txtRetireYMD2': {

                        this.EmpDetail.RetireYMD = selectDate
                        break
                    }
                    case 'txtWorkCareerStrDate': {

                        var targetIndex = e.target.getAttribute('index')
                        this.EmpWorkCareerList[targetIndex].StrDate = selectDate
                        break
                    }
                    case 'txtWorkCareerEndDate': {

                        var targetIndex = e.target.getAttribute('index')
                        this.EmpWorkCareerList[targetIndex].EndDate = selectDate
                        break
                    }
                    case 'txtProjectCareerStrDate': {

                        var targetIndex = e.target.getAttribute('index')
                        this.EmpProjectCareerList[targetIndex].StrDate = selectDate
                        break
                    }
                    case 'txtProjectCareerEndDate': {

                        var targetIndex = e.target.getAttribute('index')
                        this.EmpProjectCareerList[targetIndex].EndDate = selectDate
                        break
                    }
                    case 'txtEducationCareeStrDate': {

                        var targetIndex = e.target.getAttribute('index')
                        this.EmpEducationCareerList[targetIndex].StrDate = selectDate
                        break
                    }
                    case 'txtEducationCareeEndDate': {

                        var targetIndex = e.target.getAttribute('index')
                        this.EmpEducationCareerList[targetIndex].EndDate = selectDate
                        break
                    }

                    default:
                }
            }
            , onDeptChange(event) {
                this.DeptTeamCodeList = $.scriptBase.getCommonCode('CODE130', event.target.value, '', '')
                this.EmpDetail.DeptTeamCd = ''
                if (this.DeptTeamCodeList.length != 0) {
                    this.isTeam = true
                }
                else {
                    this.isTeam = false
                }
            }
            // 우편번호 검색
            , searchZipCode: function () {

                var parent = this;

                new daum.Postcode({
                    oncomplete(data) {

                        // 주소 변수
                        let addr = '';

                        // 사용자가 도로명 주소를 선택했을 경우
                        if (data.userSelectedType === 'R') {

                            addr = (data.buildingName) ? data.roadAddress + ' (' + data.buildingName + ')' : data.roadAddress;
                            // 사용자가 지번 주소를 선택했을 경우(J)
                        } else {

                            addr = data.jibunAddress;
                        }

                        // 우편번호와 주소 정보를 해당 필드에 넣는다.
                        parent.EmpDetail.DisplayPostNo = data.zonecode;
                        parent.EmpDetail.PostNo = data.zonecode;
                        parent.EmpDetail.Addr1 = addr;
                        parent.EmpDetail.BldgMgrNo = data.buildingCode;

                        // 커서를 상세주소 필드로 이동한다.
                        document.getElementById("txtAddr22").focus();
                    }
                }).open();
            },
            // 사용자 행 선택 이벤트
            rowSelectEvent: function (row, isCallapseCareerList) {

                // DataSet 원장데이터에서 각종 경력 목록을 레퍼런스 참조 없이 복사
                var table2 = JSON.parse(JSON.stringify(this.EmpDataSet.Table2))
                var table3 = JSON.parse(JSON.stringify(this.EmpDataSet.Table3))
                var table4 = JSON.parse(JSON.stringify(this.EmpDataSet.Table4))
                var table5 = JSON.parse(JSON.stringify(this.EmpDataSet.Table5))
                var table6 = JSON.parse(JSON.stringify(this.EmpDataSet.Table6))
                var table7 = JSON.parse(JSON.stringify(this.EmpDataSet.Table7))
                var table8 = JSON.parse(JSON.stringify(this.EmpDataSet.Table8))

                // 페이지 액션 값 설정
                this.PageActionType = 'V'

                // 선택한 행의 데이터 복사
                Object.assign(this.EmpDetail, row)

                // 콤보박스 값이 null이면 초기화
                this.EmpDetail.EmpType = this.EmpDetail.EmpType === null ? '' : this.EmpDetail.EmpType
                this.EmpDetail.JobGradeCd = this.EmpDetail.JobGradeCd === null ? '' : this.EmpDetail.JobGradeCd
                this.EmpDetail.Sex = this.EmpDetail.Sex === null ? '' : this.EmpDetail.Sex
                this.EmpDetail.SkillLevel = this.EmpDetail.SkillLevel === null ? '' : this.EmpDetail.SkillLevel
                this.EmpDetail.BankCd = this.EmpDetail.BankCd === null ? '' : this.EmpDetail.BankCd
                this.EmpDetail.AuthGrade = this.EmpDetail.AuthGrade === null ? '' : this.EmpDetail.AuthGrade
                this.EmpDetail.DeptCd = this.EmpDetail.DeptCd === null ? '' : this.EmpDetail.DeptCd
                this.EmpDetail.DeptTeamCd = this.EmpDetail.DeptTeamCd === null ? '' : this.EmpDetail.DeptTeamCd
                this.DeptTeamCodeList = null

                // 선택한 직원 팀 조회
                if (this.EmpDetail.DeptCd != '') {
                    this.DeptTeamCodeList = $.scriptBase.getCommonCode('CODE000', this.EmpDetail.DeptCd, '', '')
                    if (this.DeptTeamCodeList.length != 0) {
                        this.isTeam = true
                    }
                    else {
                        this.isTeam = false
                    }
                }
                else {
                    this.isTeam = false
                }

                // Arg값 설정 (I: 신규등록, U: 수정)
                this.EmpDetail.Arg = 'U'

                // ID, 이메일 사용여부 값 설정(N: 검사미완료, Y: 검사완료)
                this.EmpDetail.IdUseYn = 'Y'
                this.EmpDetail.EmailUseYn = 'Y'

                // 사원구분(EmpType) 값이 타업체일 경우 일부 데이터 값 변경
                if (row.EmpType === '0305040') {

                    this.EmpDetail.CompanyNm = ''
                    this.EmpDetail.AffiliatedCompanyCd = ''
                }

                // 사원 상세화면 목록 변수들 초기화
                this.EmpAcademicCareerList = null
                this.EmpWorkCareerList = null
                this.EmpProjectCareerList = null
                this.EmpLicenseList = null
                this.AttachFileList = null
                this.EmpTechnicalCareerList = null
                this.EmpEducationCareerList = null

                // 사원 상세화면 학력 목록
                if (table2.length > 0) {

                    this.EmpAcademicCareerList = new Array()

                    for (var i = 0; i < table2.length; i++) {

                        if (this.EmpDetail.CorpCd != null && table2[i].CorpCd === this.EmpDetail.CorpCd && table2[i].EmpNo === this.EmpDetail.EmpNo) {

                            table2[i].CRUD = 'R'
                            table2[i].Arg = 'SI'
                            this.EmpAcademicCareerList.push(table2[i])
                        }
                    }

                    // 목록이 존재하지 않으면 초기화
                    if (this.EmpAcademicCareerList.length === 0) {

                        this.EmpAcademicCareerList = null
                    }
                }

                // 사원 상세화면 직장경력 목록
                if (table3.length > 0) {

                    this.EmpWorkCareerList = new Array()

                    for (var i = 0; i < table3.length; i++) {

                        if (this.EmpDetail.CorpCd != null && table3[i].CorpCd === this.EmpDetail.CorpCd && table3[i].EmpNo === this.EmpDetail.EmpNo) {

                            table3[i].CRUD = 'R'
                            table3[i].Arg = 'CI'
                            this.EmpWorkCareerList.push(table3[i])
                        }
                    }

                    // 목록이 존재하지 않으면 초기화
                    if (this.EmpWorkCareerList.length === 0) {

                        this.EmpWorkCareerList = null
                    }
                }

                // 사원 상세화면 프로젝트 경력 목록
                if (table4.length > 0) {

                    this.EmpProjectCareerList = new Array()

                    for (var i = 0; i < table4.length; i++) {

                        if (this.EmpDetail.CorpCd != null && table4[i].CorpCd === this.EmpDetail.CorpCd && table4[i].EmpNo === this.EmpDetail.EmpNo) {

                            table4[i].CRUD = 'R'
                            table4[i].Arg = 'PI'
                            this.EmpProjectCareerList.push(table4[i])
                        }
                    }

                    // 목록이 존재하지 않으면 초기화
                    if (this.EmpProjectCareerList.length === 0) {

                        this.EmpProjectCareerList = null
                    }
                }

                // 사원 상세화면 자격증, 상훈 목록
                if (table5.length > 0) {

                    this.EmpLicenseList = new Array()

                    for (var i = 0; i < table5.length; i++) {

                        if (this.EmpDetail.CorpCd != null && table5[i].CorpCd === this.EmpDetail.CorpCd && table5[i].EmpNo === this.EmpDetail.EmpNo) {

                            table5[i].CRUD = 'R'
                            table5[i].Arg = 'LI'
                            this.EmpLicenseList.push(table5[i])
                        }
                    }

                    // 목록이 존재하지 않으면 초기화
                    if (this.EmpLicenseList.length === 0) {

                        this.EmpLicenseList = null
                    }
                }

                // 사원 상세화면 첨부파일 목록
                if (table6.length > 0) {

                    this.AttachFileList = new Array()

                    for (var i = 0; i < table6.length; i++) {

                        if (this.EmpDetail.CorpCd != null && table6[i].CorpCd === this.EmpDetail.CorpCd && table6[i].EmpNo === this.EmpDetail.EmpNo) {

                            table6[i].CRUD = 'R'
                            table6[i].Arg = 'FI'
                            table6[i].UserId = this.$parent.EmpId
                            table6[i].IsVisible = true
                            table6[i].Files = null
                            this.AttachFileList.push(table6[i])
                        }
                    }

                    // 목록이 존재하지 않으면 초기화
                    if (this.AttachFileList.length === 0) {

                        this.AttachFileList = null
                    }
                }

                // 사원 상세화면 기술경력 목록
                if (table7.length > 0) {

                    this.EmpTechnicalCareerList = new Array()

                    for (var i = 0; i < table7.length; i++) {

                        if (this.EmpDetail.CorpCd != null && table7[i].CorpCd === this.EmpDetail.CorpCd && table7[i].EmpNo === this.EmpDetail.EmpNo) {

                            table7[i].CRUD = 'R'
                            table7[i].Arg = 'TI'
                            this.EmpTechnicalCareerList.push(table7[i])
                        }
                    }

                    // 목록이 존재하지 않으면 초기화
                    if (this.EmpTechnicalCareerList.length === 0) {

                        this.EmpTechnicalCareerList = null
                    }
                }

                // 사원 상세화면 교육 수료 목록
                if (table8.length > 0) {

                    this.EmpEducationCareerList = new Array()

                    for (var i = 0; i < table8.length; i++) {

                        if (this.EmpDetail.CorpCd != null && table8[i].CorpCd === this.EmpDetail.CorpCd && table8[i].EmpNo === this.EmpDetail.EmpNo) {

                            table8[i].CRUD = 'R'
                            table8[i].Arg = 'EI'
                            this.EmpEducationCareerList.push(table8[i])
                        }
                    }

                    // 목록이 존재하지 않으면 초기화
                    if (this.EmpEducationCareerList.length === 0) {

                        this.EmpEducationCareerList = null
                    }
                }

                // 사원 각각의 삭제 목록 변수 초기화
                this.RemoveAcademicCareerList = new Array()
                this.RemoveWorkCareerList = new Array()
                this.RemoveProjectCareerList = new Array()
                this.RemoveLicenseList = new Array()
                this.RemoveTechnicalCareerList = new Array()
                this.RemoveEducationCareerList = new Array()
                this.RemoveAttachFileList = new Array()

                // 사원 각각의 목록 건수 설정
                this.EmpAcademicCareerListTotalCount = this.EmpAcademicCareerList === null ? 0 : this.EmpAcademicCareerList.length
                this.EmpWorkCareerListTotalCount = this.EmpWorkCareerList === null ? 0 : this.EmpWorkCareerList.length
                this.EmpProjectCareerListTotalCount = this.EmpProjectCareerList === null ? 0 : this.EmpProjectCareerList.length
                this.EmpLicenseListTotalCount = this.EmpLicenseList === null ? 0 : this.EmpLicenseList.length
                this.AttachFileListTotalCount = this.AttachFileList === null ? 0 : this.AttachFileList.length
                this.EmpTechnicalCareerListTotalCount = this.EmpTechnicalCareerList === null ? 0 : this.EmpTechnicalCareerList.length
                this.EmpEducationCareerListTotalCount = this.EmpEducationCareerList === null ? 0 : this.EmpEducationCareerList.length

                if (isCallapseCareerList) {

                    // 확장된 필드들 모두 초기화(닫기)
                    document.getElementById('colap_Arcodian1').classList.remove('in')
                    document.getElementById('colap_Arcodian2').classList.remove('in')
                    document.getElementById('colap_Arcodian3').classList.remove('in')
                    document.getElementById('colap_Arcodian4').classList.remove('in')
                    document.getElementById('colap_Arcodian5').classList.remove('in')
                    document.getElementById('colap_Arcodian6').classList.remove('in')
                    document.getElementById('colap_Arcodian7').classList.remove('in')
                }

                // datetimepicker 총 갯수 초기화
                this.DateTimePickerTotalCount = 0

                // 상세 팝업 오픈
                $(this.$refs.HM1001E_Modal).modal('show')
            },
            // 사원구분 콤보박스 변경 이벤트
            empTypeChangeEvent: function () {

                // 사원구분(EmpType) 값이 타업체일 경우 일부 데이터 값 변경
                if (this.EmpDetail.EmpType === '0305040') {

                    //숨기는 값을 초기화 해준다.
                    this.EmpDetail.LastPrmoYMD = ''
                    this.EmpDetail.RetireYMD = ''
                    this.EmpDetail.EnterYMD = ''
                }
                else {

                    //타업체의 값을 초기화 해준다.
                    this.EmpDetail.CompanyNm = ''
                    this.EmpDetail.AffiliatedCompanyCd = ''
                }
            },
            // 각종 경력 삭제 대상 체크 이벤트
            removeCareerCheckEvent: function (careerIndex, isChecked, item) {

                switch (careerIndex) {

                    case 1: {

                        if (isChecked) {

                            this.RemoveAcademicCareerList.push(item)
                        }
                        else {

                            var removeIndex = this.RemoveAcademicCareerList.indexOf(item)

                            if (removeIndex > -1) {

                                this.RemoveAcademicCareerList.splice(removeIndex, 1)
                            }
                        }

                        break
                    }
                    case 2: {

                        if (isChecked) {

                            this.RemoveWorkCareerList.push(item)
                        }
                        else {

                            var removeIndex = this.RemoveWorkCareerList.indexOf(item)

                            if (removeIndex > -1) {

                                this.RemoveWorkCareerList.splice(removeIndex, 1)
                            }
                        }

                        break
                    }
                    case 3: {

                        if (isChecked) {

                            this.RemoveProjectCareerList.push(item)
                        }
                        else {

                            var removeIndex = this.RemoveProjectCareerList.indexOf(item)

                            if (removeIndex > -1) {

                                this.RemoveProjectCareerList.splice(removeIndex, 1)
                            }
                        }

                        break
                    }
                    case 4: {

                        if (isChecked) {

                            this.RemoveLicenseList.push(item)
                        }
                        else {

                            var removeIndex = this.RemoveLicenseList.indexOf(item)

                            if (removeIndex > -1) {

                                this.RemoveLicenseList.splice(removeIndex, 1)
                            }
                        }

                        break
                    }
                    case 5: {

                        if (isChecked) {

                            this.RemoveTechnicalCareerList.push(item)
                        }
                        else {

                            var removeIndex = this.RemoveTechnicalCareerList.indexOf(item)

                            if (removeIndex > -1) {

                                this.RemoveTechnicalCareerList.splice(removeIndex, 1)
                            }
                        }

                        break
                    }
                    case 6: {

                        if (isChecked) {

                            this.RemoveEducationCareerList.push(item)
                        }
                        else {

                            var removeIndex = this.RemoveEducationCareerList.indexOf(item)

                            if (removeIndex > -1) {

                                this.RemoveEducationCareerList.splice(removeIndex, 1)
                            }
                        }

                        break
                    }
                    case 7: {

                        if (isChecked) {

                            this.RemoveAttachFileList.push(item)
                        }
                        else {

                            var removeIndex = this.RemoveAttachFileList.indexOf(item)

                            if (removeIndex > -1) {

                                this.RemoveAttachFileList.splice(removeIndex, 1)
                            }
                        }

                        break
                    }
                    default:
                }
            },
            // 저장 데이터 유효성 체크
            validationCheckSaveData() {

                let parent = this;

                // 첨부파일 업로드 완료 후 호출되는 콜백 함수
                var successUploadFileCallback = function (result) {

                    for (let i = 0; i < result.files.length; i++) {

                        let resultFile = result.files[i];

                        for (let j = 0; j < parent.AttachFileList.length; j++) {

                            let file = parent.AttachFileList[j];

                            if (file.CRUD === 'C' && file.IsVisible
                                && file.FileName === resultFile.oFileName
                                && (file.GUID === null || file.GUID === '')) {

                                file.GUID = resultFile.nFileName;
                                break;
                            }
                        }
                    }

                    parent.doSave();
                }

                if (this.EmpDetail.EmpNm === null || this.EmpDetail.EmpNm === '') {

                    alert('사원명을 입력하세요.')
                    document.getElementById('txtEmpNm2').focus()

                    return
                }

                if (this.EmpDetail.EmpID === null || this.EmpDetail.EmpID === '') {

                    alert('아이디를 입력하세요.')
                    document.getElementById('txtEmpId2').focus()

                    return
                }

                if (this.EmpDetail.IdUseYn === 'N') {

                    alert('아이디 검사를 실행해 주세요.')
                    document.getElementById('txtEmpId2').focus()
                    return
                }

                if (this.EmpDetail.JobGradeCd === null || this.EmpDetail.JobGradeCd === '') {

                    alert('직급을 입력하세요.')
                    document.getElementById('selJobGradeCd2').focus()

                    return
                }

                if (this.EmpDetail.EmpType === null || this.EmpDetail.EmpType === '') {

                    alert('사원구분을 입력하세요.')
                    document.getElementById('selEmpType2').focus()

                    return
                }

                if (this.EmpDetail.EMail === null || this.EmpDetail.EMail === '') {

                    alert('이메일을 입력하세요.')
                    document.getElementById('txtEMail2').focus()

                    return
                }

                if (this.EmpDetail.EmailUseYn === 'N') {

                    alert('이메일 검사를 실행해 주세요.')
                    document.getElementById('txtEMail2').focus()

                    return
                }

                // 학력 유효성 체크
                if (this.EmpAcademicCareerList != null) {

                    for (var i = 0; i < this.EmpAcademicCareerList.length; i++) {

                        var item = this.EmpAcademicCareerList[i]

                        if (item.SchoolType === null || item.SchoolType === '') {

                            alert('학력구분을 선택하세요.')

                            return
                        }

                        if (item.SchoolNm === null || item.SchoolNm === '') {

                            alert('학교명을 입력하세요.')
                            document.querySelectorAll('#tb1 tbody tr')[i].querySelectorAll('td')[2].querySelector('input').focus()

                            return
                        }

                        if (item.FinishYYMM === null || item.FinishYYMM === '') {

                            alert('졸업년월을 입력하세요.')
                            document.querySelectorAll('#tb1 tbody tr')[i].querySelectorAll('td')[3].querySelector('input').focus()

                            return
                        }

                        if (isNaN(item.FinishYYMM)) {

                            alert('졸업년월은 숫자만 입력 가능합니다.')
                            document.querySelectorAll('#tb1 tbody tr')[i].querySelectorAll('td')[3].querySelector('input').focus()

                            return
                        }
                    }
                }

                // 직장경력 유효성 체크
                if (this.EmpWorkCareerList != null) {

                    for (var i = 0; i < this.EmpWorkCareerList.length; i++) {

                        var item = this.EmpWorkCareerList[i]

                        if (item.CompanyNm === null || item.CompanyNm === '') {

                            alert('회사명을 입력하세요.')
                            document.querySelectorAll('#tb2 tbody tr')[i].querySelectorAll('td')[1].querySelector('input').focus()

                            return
                        }

                        if (item.StrDate === null || item.StrDate === '') {

                            alert('입사일을 선택하세요.')
                            document.querySelectorAll('#tb2 tbody tr')[i].querySelectorAll('td')[2].querySelector('input').focus()

                            return
                        }

                        if (item.EndDate === null || item.EndDate === '') {

                            alert('퇴사일을 선택하세요.')
                            document.querySelectorAll('#tb2 tbody tr')[i].querySelectorAll('td')[3].querySelector('input').focus()

                            return
                        }

                        if (item.LastJobGradeNm === null || item.LastJobGradeNm === '') {

                            alert('최종직급을 입력하세요.')
                            document.querySelectorAll('#tb2 tbody tr')[i].querySelectorAll('td')[4].querySelector('input').focus()

                            return
                        }

                        if (item.Task === null || item.Task === '') {

                            alert('담당업무를 입력하세요.')
                            document.querySelectorAll('#tb2 tbody tr')[i].querySelectorAll('td')[5].querySelector('input').focus()

                            return
                        }
                    }
                }

                // 자격증, 상훈 유효성 체크
                if (this.EmpLicenseList != null) {

                    for (var i = 0; i < this.EmpLicenseList.length; i++) {

                        var item = this.EmpLicenseList[i]

                        if (item.LicenseNm === null || item.LicenseNm === '') {

                            alert('자격증(상훈)명을 입력하세요.')
                            document.querySelectorAll('#tb4 tbody tr')[i].querySelectorAll('td')[1].querySelectorAll('input')[0].focus()

                            return
                        }

                        if (item.LicenseYYMM === null || item.LicenseYYMM === '') {

                            alert('취득년월을 입력하세요.')
                            document.querySelectorAll('#tb4 tbody tr')[i].querySelectorAll('td')[2].querySelectorAll('input')[0].focus()

                            return
                        }

                        if (item.LicenseDept === null || item.LicenseDept === '') {

                            alert('주관기간을 입력하세요.')
                            document.querySelectorAll('#tb4 tbody tr')[i].querySelectorAll('td')[3].querySelectorAll('input')[0].focus()

                            return
                        }
                    }
                }

                // 기술 경력 유효성 체크
                if (this.EmpTechnicalCareerList != null) {

                    for (var i = 0; i < this.EmpTechnicalCareerList.length; i++) {

                        var item = this.EmpTechnicalCareerList[i]

                        if (item.Technique === null || item.Technique === '') {

                            alert('기술명을 입력하세요.')
                            document.querySelectorAll('#tb6 tbody tr')[i].querySelectorAll('td')[1].querySelectorAll('input')[0].focus()

                            return
                        }

                        if (item.Skill === null || item.Skill === '') {

                            alert('숙련도를 입력하세요.')
                            document.querySelectorAll('#tb6 tbody tr')[i].querySelectorAll('td')[2].querySelectorAll('input')[0].focus()

                            return
                        }
                    }
                }

                // 교육 수료 경력 유효성 체크
                if (this.EmpEducationCareerList != null) {

                    for (var i = 0; i < this.EmpEducationCareerList.length; i++) {

                        var item = this.EmpEducationCareerList[i]

                        if (item.EducationNm === null || item.EducationNm === '') {

                            alert('교육명을 입력하세요.')
                            document.querySelectorAll('#tb7 tbody tr')[i].querySelectorAll('td')[1].querySelectorAll('input')[0].focus()

                            return
                        }

                        if (item.EduStrDate === null || item.EduStrDate === '') {

                            alert('교육 시작일을 선택하세요.')
                            document.querySelectorAll('#tb7 tbody tr')[i].querySelectorAll('td')[2].querySelectorAll('input')[0].focus()

                            return
                        }

                        if (item.EduEndDate === null || item.EduEndDate === '') {

                            alert('교육 종료일을 선택하세요.')
                            document.querySelectorAll('#tb7 tbody tr')[i].querySelectorAll('td')[3].querySelectorAll('input')[0].focus()

                            return
                        }

                        if (item.OrgName === null || item.OrgName === '') {

                            alert('교육 기관을 입력하세요.')
                            document.querySelectorAll('#tb7 tbody tr')[i].querySelectorAll('td')[4].querySelectorAll('input')[0].focus()

                            return
                        }
                    }
                }

                // 첨부파일 유효성 체크
                if (this.AttachFileList != null) {

                    var uploadFileCount = 0
                    var uploadFiles = new Array()

                    for (var i = 0; i < this.AttachFileList.length; i++) {

                        var file = this.AttachFileList[i]

                        // 삭제된 파일은 제외
                        // IsVisible: false면 삭제된 파일
                        if (file.IsVisible) {

                            if (file.FileDvsCode === null || file.FileDvsCode === '') {

                                alert('첨부구분을 선택하세요.')
                                document.querySelectorAll('#tb5 tbody tr')[i].querySelectorAll('td')[1].querySelectorAll('select')[0].focus()

                                return
                            }

                            if (file.CRUD === 'C') {

                                if (file.Files === null || file.Files === '') {

                                    alert('첨부할 파일을 선택하세요')
                                    document.querySelectorAll('#tb5 tbody tr')[i].querySelectorAll('td')[3].querySelectorAll('input')[0].focus()

                                    return
                                }
                            }

                            // 신규파일이면서 삭제되지 않은 파일만 업로드 함
                            if (file.CRUD === 'C') {

                                for (var j = 0; j < file.Files.length; j++) {

                                    uploadFiles.push(file.Files[j])
                                }

                                // 업로드 할 파일 카운터 증가
                                uploadFileCount = uploadFileCount + 1
                            }
                        }
                    }
                }

                if (uploadFileCount > 0) {

                    this.fileHandler.upload(uploadFiles, successUploadFileCallback)
                }
                else {

                    this.doSave()
                }
            },
            // 데이터 저장
            doSave: function () {

                var parent = this
                var url = ''
                var obj = new Object()
                obj.jsonStringList = new Array()

                if (this.PageActionType === 'I') {

                    url = '/Mini.Service.IBS.HM.Service/HumanMgt.svc/ajax/AddEmployee'.toRealServiceUrl()
                }
                else {

                    url = '/Mini.Service.IBS.HM.Service/HumanMgt.svc/ajax/ModifyEmployee'.toRealServiceUrl()
                }

                obj.jsonStringList.push(JSON.stringify(this.EmpDetail))
                obj.jsonStringList.push(JSON.stringify(this.EmpAcademicCareerList))
                obj.jsonStringList.push(JSON.stringify(this.EmpWorkCareerList))
                obj.jsonStringList.push(JSON.stringify(this.EmpProjectCareerList))
                obj.jsonStringList.push(JSON.stringify(this.EmpLicenseList))
                obj.jsonStringList.push(JSON.stringify(this.EmpTechnicalCareerList))
                obj.jsonStringList.push(JSON.stringify(this.EmpEducationCareerList))
                obj.jsonStringList.push(JSON.stringify(this.AttachFileList))

                obj.userId = this.$parent.EmpId

                var successCallback = function (data) {

                    if (data.d > 0) {

                        if (parent.PageActionType === 'I') {

                            alert('등록 되었습니다.')
                            $(parent.$refs.HM1001E_Modal).modal('hide')
                            parent.fn_ShowData(false)
                        }
                        else {

                            alert('저장 되었습니다.')
                            parent.fn_ShowData(true)
                        }
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            // AD 계정 비밀번호 초기화
            resetPassword() {

                let parent = this;

                var successCallback = function () {

                    $(parent.$refs.HM1001E_Modal).modal('hide');
                }

                this.$refs.HM1002E.open(successCallback, this.EmpDetail.EMail);
            },
            // PDF 파일 추출
            exportPdf: function () {

                alert('구현중 입니다.')
            },
            // 아이디 검사
            fn_CheckEmpId: function () {

                // 아이디 값 null or 공백 체크
                if (this.EmpDetail.EmpID === null || this.EmpDetail.EmpID === '') {

                    alert('아이디를 입력하세요.')
                    document.getElementById('txtEmpId2').focus()

                    return
                }

                // 아이디 길이 체크
                if (this.EmpDetail.EmpID.length < 3) {

                    alert('아이디는 최소 3자리 이상 15자리 이하 입니다.')
                    return
                }

                // 아이디 유효성 체크
                if (!this.EmpDetail.EmpID.isValidIBSEmpId()) {

                    alert('잘못된 아이디 값입니다.')
                    document.getElementById('txtEmpId2').focus()
                    return
                }

                var parent = this
                var url = '/Mini.Service.IBS.HM.Service/HumanMgt.svc/ajax/GetEmployee'.toRealServiceUrl()
                var obj = new Object()

                obj.jsonString = JSON.stringify({

                    'Arg': 'IC',
                    'UserId': this.$parent.EmpId,
                    'CompanyCode': this.$parent.CompanyCode,
                    'EmpNo': this.EmpDetail.EmpNo,
                    'SearchKeyword': this.EmpDetail.EmpID
                })

                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d)
                    var table = jsonData.Table

                    if (table.length > 0) {

                        if (table[0].Cnt === 0) {

                            alert('사용 할 수 있는 아이디 입니다.')
                            parent.EmpDetail.IdUseYn = 'Y'
                        }
                        else {

                            alert('사용 할 수 없는 아이디 입니다.')
                            parent.EmpDetail.IdUseYn = 'N'
                        }
                    }
                    else {

                        alert('사용 할 수 없는 아이디 입니다.')
                        parent.EmpDetail.IdUseYn = 'N'
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback, false)
            },
            // 이메일 검사
            fn_CheckEmail: function () {

                if (this.EmpDetail.EMail === null || this.EmpDetail.EMail === '') {

                    alert('이메일을 입력하세요.')
                    document.getElementById('txtEMail2').focus()

                    return
                }

                if (!this.EmpDetail.EMail.isValidEmailAddress()) {

                    alert('잘못된 이메일 형식입니다.')
                    document.getElementById('txtEMail2').focus()

                    return
                }

                var parent = this
                var url = '/Mini.Service.IBS.HM.Service/HumanMgt.svc/ajax/GetEmployee'.toRealServiceUrl()
                var obj = new Object()

                obj.jsonString = JSON.stringify({

                    'Arg': 'E' + this.PageActionType,
                    'UserId': this.$parent.EmpId,
                    'CompanyCode': this.$parent.CompanyCode,
                    'EmpNo': this.EmpDetail.EmpNo,
                    'SearchKeyword': this.EmpDetail.EMail
                })

                var successCallback = function (data) {

                    var table = JSON.parse(data.d).Table

                    if (table.length > 0) {

                        if (table[0].Cnt === 0) {

                            alert('사용 할 수 있는 이메일 주소 입니다.')
                            parent.EmpDetail.EmailUseYn = 'Y'
                        }
                        else {

                            alert('사용 할 수 없는 이메일 주소 입니다.')
                            parent.EmpDetail.EmailUseYn = 'N'
                        }
                    }
                    else {

                        alert('사용 할 수 없는 이메일 주소 입니다.')
                        parent.EmpDetail.EmailUseYn = 'N'
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback, false)
            },
            // 데이터 조회
            fn_ShowData: function (isShowDetailPopup) {

                var parent = this
                var url = '/Mini.Service.IBS.HM.Service/HumanMgt.svc/ajax/GetEmployee'.toRealServiceUrl()
                var obj = new Object()

                // 구분 값 설정
                this.EmpSelectTerm.Arg = 'L'

                // 파라미터 설정
                obj.jsonString = JSON.stringify(this.EmpSelectTerm)

                var successCallback = function (data) {

                    parent.EmpDataSet = JSON.parse(data.d)

                    var table = parent.EmpDataSet.Table
                    var table1 = parent.EmpDataSet.Table1

                    // 사원 목록
                    if (table.length > 0) {

                        parent.EmpList = table

                        // 상세팝업 오픈 플래그 값에 따라 상세 팝업 오픈
                        if (isShowDetailPopup) {

                            for (var i = 0; i < table.length; i++) {

                                if (table[i].EmpNo === parent.EmpDetail.EmpNo) {

                                    parent.rowSelectEvent(table[i], false)

                                    break
                                }
                            }
                        }
                    }
                    else {

                        parent.EmpList = null
                    }

                    // 사원 목록 전체갯수 값 설정
                    if (table1.length > 0) {

                        parent.EmpListTotalCount = table1[0].TotCnt
                    }
                    else {

                        parent.EmpListTotalCount = 0
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 데이터 추가
            fn_InstData: function () {

                this.PageActionType = 'I'
                this.EmpDetail = {
                    Arg: 'N',
                    CorpCd: this.$parent.CompanyCode,
                    EmpNo: null,
                    EmpNm: null,
                    EmpType: '',
                    EmpID: null,
                    EmpTypeName: null,
                    BirthYMD: null,
                    JobGradeCd: '',
                    JobGradeNm: null,
                    MobileNo: null,
                    EMail: null,
                    EnterYMD: null,
                    ExpertDate: null,
                    LastPrmoYMD: null,
                    RetireYMD: null,
                    UseYN: 'Y',
                    CareerStart: null,
                    CompanyNm: null,
                    AffiliatedCompanyCd: null,
                    Sex: '',
                    SEXNM: null,
                    Department: null,
                    SkillLevel: '',
                    SkillLevelNM: null,
                    PostNo: null,
                    PostSeqNo: null,
                    DisplayPostNo: null,
                    BldgMgrNo: null,
                    Addr1: null,
                    Addr2: null,
                    ResidentRegtNm: null,
                    AccountNm: null,
                    BankCd: '',
                    BankNm: null,
                    AuthGrade: '',
                    BoardCnt: '5'
                }

                // ID, 이메일 사용여부 값 추가 (N: 검사미완료, Y: 검사완료)
                this.EmpDetail.IdUseYn = 'N'
                this.EmpDetail.EmailUseYn = 'N'

                // 사원 상세화면 목록 변수들 초기화
                this.EmpAcademicCareerList = null
                this.EmpWorkCareerList = null
                this.EmpProjectCareerList = null
                this.EmpLicenseList = null
                this.AttachFileList = null
                this.EmpTechnicalCareerList = null
                this.EmpEducationCareerList = null
                this.RemoveAcademicCareerList = new Array()
                this.RemoveWorkCareerList = new Array()
                this.RemoveProjectCareerList = new Array()
                this.RemoveLicenseList = new Array()
                this.RemoveTechnicalCareerList = new Array()
                this.RemoveEducationCareerList = new Array()
                this.RemoveAttachFileList = new Array()

                // 사원 각각의 목록 건수 초기화
                this.EmpAcademicCareerListTotalCount = 0
                this.EmpWorkCareerListTotalCount = 0
                this.EmpProjectCareerListTotalCount = 0
                this.EmpLicenseListTotalCount = 0
                this.AttachFileListTotalCount = 0
                this.EmpTechnicalCareerListTotalCount = 0
                this.EmpEducationCareerListTotalCount = 0

                // 확장된 필드들 모두 초기화(닫기)
                document.getElementById('colap_Arcodian1').classList.remove('in')
                document.getElementById('colap_Arcodian2').classList.remove('in')
                document.getElementById('colap_Arcodian3').classList.remove('in')
                document.getElementById('colap_Arcodian4').classList.remove('in')
                document.getElementById('colap_Arcodian5').classList.remove('in')
                document.getElementById('colap_Arcodian6').classList.remove('in')
                document.getElementById('colap_Arcodian7').classList.remove('in')

                // datetimepicker 총 갯수 초기화
                this.DateTimePickerTotalCount = 0

                // 상세 모달 팝업 오픈
                $(this.$refs.HM1001E_Modal).modal('show');
            },
            // 데이터 삭제
            fn_RemoveData: function () {

                var parent = this
                var url = '/Mini.Service.IBS.HM.Service/HumanMgt.svc/ajax/RemoveEmployee'.toRealServiceUrl()
                var obj = new Object()

                obj.jsonString = JSON.stringify({
                    'Arg': 'D',
                    'UserId': this.$parent.EmpId,
                    'CorpCd': this.$parent.CompanyCode,
                    'EmpNo': this.EmpDetail.EmpNo,
                    'EmpId': this.EmpDetail.EmpID
                })

                if (confirm('삭제 하시겠습니까?')) {

                    var successCallback = function (data) {

                        if (data.d > 0) {

                            alert('삭제 되었습니다.')
                            $(parent.$refs.HM1001E_Modal).modal('hide')
                            parent.fn_ShowData(false)
                        }
                    }

                    $.scriptBase.executeAjaxAsync(url, obj, successCallback)
                }
            },
            // 각종 경력 데이터 추가
            fn_InstCareer: function (careerIndex) {

                switch (careerIndex) {

                    case 1: {

                        if (this.EmpAcademicCareerList === null) {

                            this.EmpAcademicCareerList = new Array()
                        }

                        this.EmpAcademicCareerList.push({
                            CRUD: 'C',
                            Arg: 'SI',
                            CorpCd: this.EmpDetail.CorpCd,
                            EmpNo: this.EmpDetail.EmpNo,
                            SeqNo: null,
                            SchoolType: '',
                            SchoolNm: null,
                            FinishYYMM: null
                        })

                        break
                    }
                    case 2: {

                        if (this.EmpWorkCareerList === null) {

                            this.EmpWorkCareerList = new Array()
                        }

                        this.EmpWorkCareerList.push({
                            CRUD: 'C',
                            Arg: 'CI',
                            CorpCd: this.EmpDetail.CorpCd,
                            EmpNo: this.EmpDetail.EmpNo,
                            SeqNo: null,
                            CompanyNm: null,
                            StrDate: null,
                            EndDate: null,
                            LastJobGradeNm: null,
                            Task: null
                        })

                        break
                    }
                    case 3: {

                        if (this.EmpProjectCareerList === null) {

                            this.EmpProjectCareerList = new Array()
                        }

                        this.EmpProjectCareerList.push({
                            CRUD: 'C',
                            Arg: 'PI',
                            CorpCd: this.EmpDetail.CorpCd,
                            EmpNo: this.EmpDetail.EmpNo,
                            SeqNo: null,
                            ProjectNm: null,
                            StrDate: this.CurrentDateYYMMDD,
                            EndDate: this.CurrentDateYYMMDD,
                            Customer: null,
                            RoleCd: '',
                            RoleNm: null,
                            ProjectDesc: null,
                            AffiliatedCompany: null,
                            DevOS: null,
                            DevLanguage: null,
                            DevDBMS: null,
                            DevTools: null,
                            DevComment: null
                        })

                        break
                    }
                    case 4: {

                        if (this.EmpLicenseList === null) {

                            this.EmpLicenseList = new Array()
                        }

                        this.EmpLicenseList.push({
                            CRUD: 'C',
                            Arg: 'LI',
                            CorpCd: this.EmpDetail.CorpCd,
                            EmpNo: this.EmpDetail.EmpNo,
                            SeqNo: null,
                            LicenseNm: null,
                            LicenseYYMM: null,
                            LicenseDept: null,
                            LicenseDesc: null
                        })

                        break
                    }
                    case 5: {

                        if (this.EmpTechnicalCareerList === null) {

                            this.EmpTechnicalCareerList = new Array()
                        }

                        this.EmpTechnicalCareerList.push({
                            CRUD: 'C',
                            Arg: 'TI',
                            CorpCd: this.EmpDetail.CorpCd,
                            EmpNo: this.EmpDetail.EmpNo,
                            SeqNo: null,
                            Skill: null,
                            Technique: null
                        })

                        break
                    }
                    case 6: {

                        if (this.EmpEducationCareerList === null) {

                            this.EmpEducationCareerList = new Array()
                        }

                        this.EmpEducationCareerList.push({
                            CRUD: 'C',
                            Arg: 'EI',
                            CorpCd: this.EmpDetail.CorpCd,
                            EmpNo: this.EmpDetail.EmpNo,
                            SeqNo: null,
                            EducationNm: null,
                            StrDate: this.CurrentDateYYMMDD,
                            EndDate: this.CurrentDateYYMMDD,
                            OrgName: null
                        })

                        break
                    }
                    case 7: {

                        if (this.AttachFileList === null) {

                            this.AttachFileList = new Array()
                        }

                        this.AttachFileList.push({
                            CRUD: 'C',
                            Arg: 'FI',
                            UserId: this.$parent.EmpId,
                            IsVisible: true,
                            CorpCd: this.EmpDetail.CorpCd,
                            EmpNo: this.EmpDetail.EmpNo,
                            FileSeq: null,
                            FileDvsCode: '',
                            GUID: null,
                            FileName: null,
                            IsPhotoYn: 'N',
                            Files: null
                        })

                        break
                    }
                    default:
                }
            },
            // 각종 경력 데이터 삭제
            fn_RemoveCareer: function (careerIndex) {

                var parent = this
                var url = '/Mini.Service.IBS.HM.Service/HumanMgt.svc/ajax/RemoveEmployeeCareer'.toRealServiceUrl()
                var obj = new Object()

                switch (careerIndex) {

                    case 1: {

                        if (this.RemoveAcademicCareerList.length > 0) {

                            if (confirm('삭제 하시겠습니까?')) {

                                this.RemoveAcademicCareerList.forEach(function (item) {

                                    var removeIndex = parent.EmpAcademicCareerList.indexOf(item)

                                    if (removeIndex > -1) {

                                        parent.EmpAcademicCareerList.splice(removeIndex, 1)
                                    }

                                    // 삭제 플래그 설정
                                    item.Arg = 'SD'
                                })

                                var isExistDBData = this.RemoveAcademicCareerList.some(function (item) {
                                    return item.CRUD === 'R'
                                })

                                // DB에서 삭제해야할 데이터가 존재하는지 체크
                                if (isExistDBData) {

                                    obj.userId = this.$parent.EmpId
                                    obj.jsonString = JSON.stringify(this.RemoveAcademicCareerList)

                                    var successCallback = function (data) {

                                        if (data.d > 0) {

                                            parent.fn_ShowData(true)
                                        }
                                    }

                                    $.scriptBase.executeAjaxAsync(url, obj, successCallback)
                                }

                                // 학력 삭제 목록 변수 초기화
                                parent.RemoveAcademicCareerList = new Array()

                                // 선택 체크박스 모두 해제
                                document.querySelectorAll('input[type=checkbox][name=cbItem1]').forEach(function (item) {

                                    item.checked = false
                                })

                                // 학력 목록이 없으면 변수 초기화
                                if (this.EmpAcademicCareerList.length === 0) {

                                    this.EmpAcademicCareerList = null
                                }
                            }
                        }
                        else {

                            alert('삭제할 대상이 없습니다.')
                        }
                        break
                    }
                    case 2: {

                        if (this.RemoveWorkCareerList.length > 0) {

                            if (confirm('삭제 하시겠습니까?')) {

                                this.RemoveWorkCareerList.forEach(function (item) {

                                    if (item.CRUD === 'C') {

                                        var removeIndex = parent.EmpWorkCareerList.indexOf(item)

                                        if (removeIndex > -1) {

                                            parent.EmpWorkCareerList.splice(removeIndex, 1)
                                        }

                                        // 삭제 플래그 설정
                                        item.Arg = 'CD'
                                    }
                                })

                                var isExistDBData = this.RemoveWorkCareerList.some(function (item) {
                                    return item.CRUD === 'R'
                                })

                                // DB에서 삭제해야할 데이터가 존재하는지 체크
                                if (isExistDBData) {

                                    obj.userId = this.$parent.EmpId
                                    obj.jsonString = JSON.stringify(this.RemoveWorkCareerList)

                                    var successCallback = function (data) {

                                        if (data.d > 0) {

                                            parent.fn_ShowData(true)
                                        }
                                    }

                                    $.scriptBase.executeAjaxAsync(url, obj, successCallback)
                                }

                                // 직장경력 삭제 목록 변수 초기화
                                this.RemoveWorkCareerList = new Array()

                                // 선택 체크박스 모두 해제
                                document.querySelectorAll('input[type=checkbox][name=cbItem2]').forEach(function (item) {

                                    item.checked = false
                                })

                                // 직장경력 목록이 없으면 변수 초기화
                                if (this.EmpWorkCareerList.length === 0) {

                                    this.EmpWorkCareerList = null
                                }
                            }
                        }
                        else {

                            alert('삭제할 대상이 없습니다.')
                        }
                        break
                    }
                    case 3: {

                        if (this.RemoveProjectCareerList.length > 0) {

                            if (confirm('삭제 하시겠습니까?')) {

                                this.RemoveProjectCareerList.forEach(function (item) {

                                    if (item.CRUD === 'C') {

                                        var removeIndex = parent.EmpProjectCareerList.indexOf(item)

                                        if (removeIndex > -1) {

                                            parent.EmpProjectCareerList.splice(removeIndex, 1)
                                        }
                                    }

                                    // 삭제 플래그 설정
                                    item.Arg = 'PD'
                                })

                                var isExistDBData = this.RemoveProjectCareerList.some(function (item) {
                                    return item.CRUD === 'R'
                                })

                                // DB에서 삭제해야할 데이터가 존재하는지 체크
                                if (isExistDBData) {

                                    obj.userId = this.$parent.EmpId
                                    obj.jsonString = JSON.stringify(this.RemoveProjectCareerList)

                                    var successCallback = function (data) {

                                        if (data.d > 0) {

                                            parent.fn_ShowData(true)
                                        }
                                    }

                                    $.scriptBase.executeAjaxAsync(url, obj, successCallback)
                                }

                                // 프로젝트경력 삭제 목록 변수 초기화
                                this.RemoveProjectCareerList = new Array()

                                // 선택 체크박스 모두 해제
                                document.querySelectorAll('input[type=checkbox][name=cbItem3]').forEach(function (item) {

                                    item.checked = false
                                })

                                // 프로젝트경력 목록이 없으면 변수 초기화
                                if (this.EmpProjectCareerList.length === 0) {

                                    this.EmpProjectCareerList = null
                                }
                            }
                        }
                        else {

                            alert('삭제할 대상이 없습니다.')
                        }

                        break
                    }
                    case 4: {

                        if (this.RemoveLicenseList.length > 0) {

                            if (confirm('삭제 하시겠습니까?')) {

                                this.RemoveLicenseList.forEach(function (item) {

                                    if (item.CRUD === 'C') {

                                        var removeIndex = parent.EmpLicenseList.indexOf(item)

                                        if (removeIndex > -1) {

                                            parent.EmpLicenseList.splice(removeIndex, 1)
                                        }
                                    }

                                    // 삭제 플래그 설정
                                    item.Arg = 'LD'
                                })

                                var isExistDBData = this.RemoveLicenseList.some(function (item) {
                                    return item.CRUD === 'R'
                                })

                                // DB에서 삭제해야할 데이터가 존재하는지 체크
                                if (isExistDBData) {

                                    obj.userId = this.$parent.EmpId
                                    obj.jsonString = JSON.stringify(this.RemoveLicenseList)

                                    var successCallback = function (data) {

                                        if (data.d > 0) {

                                            parent.fn_ShowData(true)
                                        }
                                    }

                                    $.scriptBase.executeAjaxAsync(url, obj, successCallback)
                                }

                                // 자격증, 상훈 삭제 목록 변수 초기화
                                this.RemoveLicenseList = new Array()

                                // 선택 체크박스 모두 해제
                                document.querySelectorAll('input[type=checkbox][name=cbItem4]').forEach(function (item) {

                                    item.checked = false
                                })

                                // 자격증, 상훈 목록이 없으면 변수 초기화
                                if (this.EmpLicenseList.length === 0) {

                                    this.EmpLicenseList = null
                                }
                            }
                        }
                        else {

                            alert('삭제할 대상이 없습니다.')
                        }

                        break
                    }
                    case 5: {

                        if (this.RemoveTechnicalCareerList.length > 0) {

                            if (confirm('삭제 하시겠습니까?')) {

                                this.RemoveTechnicalCareerList.forEach(function (item) {

                                    if (item.CRUD === 'C') {

                                        var removeIndex = parent.EmpTechnicalCareerList.indexOf(item)

                                        if (removeIndex > -1) {

                                            parent.EmpTechnicalCareerList.splice(removeIndex, 1)
                                        }

                                        // 삭제 플래그 설정
                                        item.Arg = 'TD'
                                    }
                                })

                                var isExistDBData = this.RemoveTechnicalCareerList.some(function (item) {
                                    return item.CRUD === 'R'
                                })

                                // DB에서 삭제해야할 데이터가 존재하는지 체크
                                if (isExistDBData) {

                                    obj.userId = this.$parent.EmpId
                                    obj.jsonString = JSON.stringify(this.RemoveTechnicalCareerList)

                                    var successCallback = function (data) {

                                        if (data.d > 0) {

                                            parent.fn_ShowData(true)
                                        }
                                    }

                                    $.scriptBase.executeAjaxAsync(url, obj, successCallback)
                                }

                                // 기술경력 목록 변수 초기화
                                this.RemoveTechnicalCareerList = new Array()

                                // 선택 체크박스 모두 해제
                                document.querySelectorAll('input[type=checkbox][name=cbItem5]').forEach(function (item) {

                                    item.checked = false
                                })

                                // 기술경력 목록이 없으면 변수 초기화
                                if (this.EmpTechnicalCareerList.length === 0) {

                                    this.EmpTechnicalCareerList = null
                                }
                            }
                        }
                        else {

                            alert('삭제할 대상이 없습니다.')
                        }

                        break
                    }
                    case 6: {

                        if (this.RemoveEducationCareerList.length > 0) {

                            if (confirm('삭제 하시겠습니까?')) {

                                this.RemoveEducationCareerList.forEach(function (item) {

                                    if (item.CRUD === 'C') {

                                        var removeIndex = parent.EmpEducationCareerList.indexOf(item)

                                        if (removeIndex > -1) {

                                            parent.EmpEducationCareerList.splice(removeIndex, 1)
                                        }

                                        // 삭제 플래그 설정
                                        item.Arg = 'ED'
                                    }
                                })

                                var isExistDBData = this.RemoveEducationCareerList.some(function (item) {
                                    return item.CRUD === 'R'
                                })

                                // DB에서 삭제해야할 데이터가 존재하는지 체크
                                if (isExistDBData) {

                                    obj.userId = this.$parent.EmpId
                                    obj.jsonString = JSON.stringify(this.RemoveEducationCareerList)

                                    var successCallback = function (data) {

                                        if (data.d > 0) {

                                            parent.fn_ShowData(true)
                                        }
                                    }

                                    $.scriptBase.executeAjaxAsync(url, obj, successCallback)
                                }

                                // 교육경력 삭제 목록 변수 초기화
                                this.RemoveEducationCareerList = new Array()

                                // 선택 체크박스 모두 해제
                                document.querySelectorAll('input[type=checkbox][name=cbItem6]').forEach(function (item) {

                                    item.checked = false
                                })

                                // 교육경력 목록이 없으면 변수 초기화
                                if (this.EmpEducationCareerList.length === 0) {

                                    this.EmpEducationCareerList = null
                                }
                            }
                        }
                        else {

                            alert('삭제할 대상이 없습니다.')
                        }

                        break
                    }
                    case 7: {

                        if (this.RemoveAttachFileList.length > 0) {

                            if (confirm('삭제 하시겠습니까?')) {

                                var tempArray = new Array()

                                this.RemoveAttachFileList.forEach(function (item) {

                                    var removeIndex = parent.AttachFileList.indexOf(item)

                                    if (removeIndex > -1) {

                                        parent.AttachFileList[removeIndex].IsVisible = false
                                    }

                                    // DB에서 삭제 할 파일 목록 추가
                                    if (item.CRUD === 'R') {

                                        // 삭제 플래그 설정
                                        item.Arg = 'FD'

                                        tempArray.push(item)
                                    }
                                })

                                // DB에서 삭제해야할 데이터가 존재하는지 체크
                                if (tempArray.length > 0) {

                                    obj.jsonString = JSON.stringify(tempArray)

                                    var successCallback = function (data) {

                                        if (data.d > 0) {

                                            parent.fn_ShowData(true)
                                        }
                                    }

                                    url = '/Mini.Service.IBS.HM.Service/HumanMgt.svc/ajax/RemoveEmployeeFile'.toRealServiceUrl()

                                    $.scriptBase.executeAjaxAsync(url, obj, successCallback)
                                }

                                // 첨부파일 삭제 목록 변수 초기화
                                this.RemoveAttachFileList = new Array()

                                // 선택 체크박스 모두 해제
                                document.querySelectorAll('input[type=checkbox][name=cbItem7]').forEach(function (item) {

                                    item.checked = false
                                })

                                // 첨부파일 목록이 없으면 변수 초기화
                                if (this.AttachFileList.length === 0) {

                                    this.AttachFileList = null
                                }
                            }
                        }
                        else {

                            alert('삭제할 대상이 없습니다.')
                        }

                        break
                    }
                    default:
                }
            }
        }
    }
</script>