<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a v-show="this.$parent.CanSearch" href="#" id="btnListSrch" class="btn btn-primary btn-row2" @click.prevent="fn_ShowData">
                    <span>
                        <i class="glyphicon glyphicon-search"></i>조회
                    </span>
                </a>
                <a v-show="this.$parent.CanNew" href="#" id="btnListInst" class="btn btn-default" @click.prevent="fn_InstData">
                    <span>
                        <i class="glyphicon glyphicon-plus"></i>추가
                    </span>
                </a>
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-4">
                                        <label for="selInOutDvsCd" class="control-label">
                                            <span>입/출구분</span>
                                        </label>
                                        <select class="form-control" id="selInOutDvsCd" name="selInOutDvsCd" v-model="CashInOutSelectTerm.InOutDvsCode" @change="fn_ShowData">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in InOutDvsCodeList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="selDateType" class="control-label">
                                            <span>입/출일자</span>
                                        </label>
                                        <div class="col-xs-3">
                                            <select class="form-control" id="selDateType" @change="dateTypeChangeEvent">
                                                <option value="1">오늘</option>
                                                <option value="2">일주일</option>
                                                <option value="3">한달</option>
                                                <option value="4">일년</option>
                                                <option value="5" selected="selected">이번달</option>
                                            </select>
                                        </div>
                                        <div class="col-xs-9">
                                            <label for="txtStartDate" class="sr-only"><span>입/출일자</span></label>
                                            <div class="col-2 input-group date">
                                                <DxDateBox display-format="yyyy-MM-dd"
                                                           pickerType="calendar"
                                                           v-model="CashInOutSelectTerm.StartDate"
                                                           @value-changed="fn_ShowData"
                                                           style="border-radius:0;" />
                                            </div>
                                            <div class="tail"><span>~</span></div>
                                            <label for="txtEndDate" class="sr-only"><span>입/출일자</span></label>
                                            <div class="col-2 input-group date">
                                                <DxDateBox display-format="yyyy-MM-dd"
                                                           pickerType="calendar"
                                                           v-model="CashInOutSelectTerm.EndDate"
                                                           @value-changed="fn_ShowData"
                                                           style="border-radius:0;" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cols col-4">
                                        <label for="selInOutConfirm" class="control-label">
                                            <span>입/출금상태</span>
                                        </label>
                                        <select class="form-control" id="selInOutConfirm" v-model="CashInOutSelectTerm.InOutStateCd" @change="fn_ShowData">
                                            <option value="">== 전체 ==</option>
                                            <option v-if="CashInOutSelectTerm.InOutDvsCode == ''" v-for="item in InOutStateCodeList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                            <option v-if="CashInOutSelectTerm.InOutDvsCode == '0505010'" v-for="item in InStateCodeList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                            <option v-if="CashInOutSelectTerm.InOutDvsCode == '0505020'" v-for="item in OutStateCodeList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-4">
                                        <label for="selBankCd" class="control-label">
                                            <span>출처</span>
                                        </label>
                                        <select class="form-control" id="selAccSrcDvsCd" name="selAccSrcDvsCd" v-model="CashInOutSelectTerm.AccSrcDvsCode" @change="fn_ShowData">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in AccSrcDvsCodeList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-3">
                                        <label for="selCash" class="control-label">
                                            <span>현금여부</span>
                                        </label>
                                        <select class="form-control" id="selCash" v-model="CashInOutSelectTerm.CashYn" @change="fn_ShowData">
                                            <option value="">== 전체 ==</option>
                                            <option value="Y">Y</option>
                                            <option value="N">N</option>
                                        </select>
                                    </div>
                                    <div class="cols col-3">
                                        <label for="txtInOut" class="control-label">
                                            <span>입/출금명</span>
                                        </label>
                                        <input type="text" id="txtInOutNm" class="form-control txt" v-model="CashInOutSelectTerm.InOutName" />
                                    </div>
                                </section>
                            </section>
                        </fieldset>
                    </form>
                </div>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">입출금관리</p>
                </div>
                <div>
                    <!--리스트 영역-->
                    <section class="tbl-contents">
                        <div class="alert alert-info total">
                            <p>
                                <span class="glyphicon glyphicon-folder-close"></span>
                                전체: <span id="spTotCnt">{{CashInOutListTotalCount}}</span>건
                            </p>
                        </div>
                        <div class="table-responsive">
                            <DxDataGrid :data-source="CashInOutList"
                                        :allow-column-resizing="true"
                                        :column-auto-width="true"
                                        :row-alternation-enabled="true"
                                        :hoverStateEnabled="true"
                                        :show-borders="true"
                                        @row-click="rowSelectEvent"
                                        @cell-prepared="onCellPrepared"
                                        style="max-height: 500px; vertical-align: top;">
                                <DxScrolling mode="standard" useNative="false" />
                                <DxPaging :enabled="false" />
                                <DxColumn caption="입/출구분" data-field="InOutDvsName" alignment="center" />
                                <DxColumn caption="출처" data-field="AccSrcDvsName" alignment="center" />
                                <DxColumn caption="입/출예정일자" data-field="InOutYMD" alignment="center" data-type="date" format="yyyy-MM-dd" />
                                <DxColumn caption="입/출일자" data-field="ExeInOutYMD" alignment="center" data-type="date" format="yyyy-MM-dd" />
                                <DxColumn caption="입/출금명" data-field="AccountDetailNm" alignment="left" />
                                <DxColumn caption="현금여부" data-field="CashYN" alignment="center" />
                                <DxColumn caption="입/출금상태" data-field="InOutStateNm" alignment="center" />
                                <DxColumn caption="출금예정액" data-field="OutAmt" alignment="right" format="fixedPoint" />
                                <DxColumn caption="입금예정액" data-field="InAmt" alignment="right" format="fixedPoint" />
                                <DxColumn caption="출금액" data-field="ExeOutAmt" alignment="right" format="fixedPoint" />
                                <DxColumn caption="입금액" data-field="ExeInAmt" alignment="right" format="fixedPoint" />
                                <DxColumn caption="은행명" data-field="BankName" alignment="center" />
                                <DxColumn caption="계좌번호" data-field="AcntNo" alignment="center" />
                                <DxColumn caption="계정과목" data-field="AcntSubjtNm" alignment="center" />
                                <DxColumn caption="어음만기일자" data-field="CheckEndYMD" alignment="center" data-type="date" format="yyyy-MM-dd" />
                                <DxColumn caption="지출결의서/품의서번호" data-field="ExpenseReportNo" alignment="center" />
                            </DxDataGrid>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <!--상세팝업-->
        <div class="modal fade" id="AC1021E_Modal" tabindex="-1" role="dialog" aria-labelledby="AC1021E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">
                                Close
                            </span>
                        </button>
                        <h3 class="modal-title" v-if="PageActionType === 'I'">입출금관리 등록</h3>
                        <h3 class="modal-title" v-if="PageActionType === 'V'">입출금관리 수정</h3>
                    </div>
                    <div class="modal-body">
                        <section class="add-wrap">
                            <form>
                                <fieldset>
                                    <legend>...</legend>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtInOut2" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>입/출금명
                                                </span>
                                            </label>
                                            <input type="text" id="txtInOutNm2" class="form-control txt"
                                                   v-model="CashInOutDetail.AccountDetailNm"
                                                   v-bind:disabled="PageActionType === 'V'" />
                                        </div>
                                        <div class="cols col-2">
                                            <label for="selInOutDvsCd2" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>입/출구분
                                                </span>
                                            </label>
                                            <select class="form-control" id="selInOutDvsCd2" name="selInOutDvsCd2"
                                                    v-model="CashInOutDetail.InOutDvsCd"
                                                    v-bind:disabled="PageActionType === 'V'">
                                                <option value="">== 선택 ==</option>
                                                <option v-for="item in InOutDvsCodeList" v-bind:value="item.Code">
                                                    {{item.Name}}
                                                </option>
                                            </select>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="selAccSrcDvsCd2" class="control-label">
                                                <span>출처</span>
                                            </label>
                                            <select class="form-control" id="selAccSrcDvsCd2" name="selAccSrcDvsCd2" disabled="disabled"
                                                    v-model="CashInOutDetail.AccSrcDvsCd">
                                                <option value="">== 선택 ==</option>
                                                <option v-for="item in AccSrcDvsCodeList" v-bind:value="item.Code">
                                                    {{item.Name}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="selAcntSubjtCd" class="control-label">
                                                <span><i class="glyphicon glyphicon-asterisk"></i>계정과목</span>
                                            </label>
                                            <select class="form-control" id="selAcntSubjtCd" name="selAcntSubjtCd" v-model="CashInOutDetail.AcntSubjtCd">
                                                <option value="">== 선택 ==</option>
                                                <option v-for="item in AcntSubjtCodeList" v-bind:value="item.Code">
                                                    {{item.Name}}
                                                </option>
                                            </select>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="selCash2" class="control-label">
                                                <span>현금여부</span>
                                            </label>
                                            <select class="form-control" id="selCash2" v-model="CashInOutDetail.CashYN">
                                                <option value="Y">Y</option>
                                                <option value="N">N</option>
                                            </select>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="txtPrice2" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>예정액
                                                </span>
                                            </label>
                                            <input type="text" id="txtPrice2" class="form-control text-right"
                                                   v-bind:value="CashInOutDetail.Amount"
                                                   v-bind:disabled="PageActionType === 'V'"
                                                   @input="function(e){
                                                        CashInOutDetail.Amount = e.target.value.toPriceString()
                                                        e.target.value = e.target.value.toPriceString()
                                                   }" />
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtEndDate" class="control-label">
                                                <span><i class="glyphicon glyphicon-asterisk"></i>입/출예정일자</span>
                                            </label>
                                            <div class="col-1 input-group date">
                                                <DxDateBox display-format="yyyy-MM-dd"
                                                           pickerType="calendar"
                                                           v-model="CashInOutDetail.InOutYMD"
                                                           style="border-radius:0;" />
                                            </div>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="txtEndDate" class="control-label">
                                                <span>입/출일자</span>
                                            </label>
                                            <div class="col-1 input-group date">
                                                <DxDateBox display-format="yyyy-MM-dd"
                                                           pickerType="calendar"
                                                           v-model="CashInOutDetail.ExeInOutYMD"
                                                           style="border-radius:0;" />
                                            </div>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtBillNm2" class="control-label">
                                                <span>계좌번호</span>
                                            </label>
                                            <div class="cols-btns-cont">
                                                <input type="text" id="txtBank2" class="form-control txt" readonly="readonly" v-model="CashInOutDetail.AccountNumber" />
                                                <input type="hidden" id="hidBankCd2" class="form-control txt" v-model="CashInOutDetail.BankCd" />
                                                <input type="hidden" id="hidBankNm2" class="form-control txt" v-model="CashInOutDetail.BankName" />
                                                <input type="hidden" id="hidAcntNo2" class="form-control txt" v-model="CashInOutDetail.AcntNo" />
                                            </div>
                                            <div class="cols-btns">
                                                <a href="#" id="btnItemPop3" class="btn btn-default" @click.prevent="searchBankAccount">
                                                    <span>
                                                        <i class="glyphicon glyphicon-search"></i> 검색
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="selInOutConfirm2" class="control-label">
                                                <span><i class="glyphicon glyphicon-asterisk"></i>입/출금상태</span>
                                            </label>
                                            <select class="form-control" id="selInOutConfirm2" v-model="CashInOutDetail.InOutStateCd"
                                                    v-bind:disabled="CashInOutDetail.InOutDvsCd == '' || (PageActionType == 'V' && CashInOutDetail.InOutStateCd != '0517090') || (PageActionType == 'V' && CashInOutDetail.InOutStateCd != '0517110')">
                                                <option value="">== 선택 ==</option>
                                                <option v-if="CashInOutDetail.InOutDvsCd == '0505010'" v-for="item in InStateCodeList" v-bind:value="item.Code">
                                                    {{item.Name}}
                                                </option>
                                                <option v-if="CashInOutDetail.InOutDvsCd == '0505020'" v-for="item in OutStateCodeList" v-bind:value="item.Code">
                                                    {{item.Name}}
                                                </option>
                                            </select>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtInOut2" class="control-label">
                                                <span>지출결의서/품의서번호</span>
                                            </label>
                                            <input type="text" id="txtInOutNm2" class="form-control txt" v-model="CashInOutDetail.ExpenseReportNo" />
                                        </div>
                                        <div class="cols col-2" id="divCheckend" v-show="CashInOutDetail.CashYN === 'N'">
                                            <label for="txtCheckEndYMD2" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>어음만기일자
                                                </span>
                                            </label>
                                            <div class="col-1 input-group date">
                                                <DxDateBox display-format="yyyy-MM-dd"
                                                           pickerType="calendar"
                                                           v-model="CashInOutDetail.CheckEndYMD"
                                                           style="border-radius: 0;" />
                                            </div>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-1">
                                            <label for="txtInOutAmtMemo" class="control-label">
                                                <span>비고</span>
                                            </label>
                                            <textarea class="form-control txt" v-model="CashInOutDetail.InOutAmtMemo" />
                                        </div>
                                    </section>
                                </fieldset>
                            </form>
                        </section>
                    </div>
                    <div class="modal-footer">
                        <section class="btn-align">
                            <label id="txtHelpKnowTxt" class="control-label"
                                   v-show="CashInOutDetail.AccSrcDvsCd != '0509070'">
                                <span>세금계산서로 입력된 데이터는 삭제할 수 없습니다.</span>
                            </label>
                            <div v-show="this.$parent.CanSave" class="btn-group">
                                <button type="button" id="btnItemSave" class="btn btn-primary" @click="fn_SaveData">
                                    <span class="glyphicon glyphicon-save"></span> 저장
                                </button>
                            </div>
                            <div v-show="this.$parent.CanDelete && CashInOutDetail.AccSrcDvsCd == '0509070'" class="btn-group">
                                <button type="button" id="btnItemRmve" class="btn btn-warning" v-if="PageActionType === 'V'" @click="fn_RemoveData">
                                    <span class="glyphicon glyphicon-edit"></span> 삭제
                                </button>
                            </div>
                            <div class="btn-group">
                                <button type="button" class="btn btn-danger" data-dismiss="modal">
                                    <span class="glyphicon glyphicon-remove"></span> 취소
                                </button>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
        <CP1070P ref="CP1070P"></CP1070P>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle';
    import { DxDataGrid, DxColumn, DxButton, DxScrolling, DxSorting, DxPaging } from 'devextreme-vue/data-grid';
    import DxDateBox, { DxCalendarOptions } from 'devextreme-vue/date-box';
    import CP1070P from '@/components/libs/popup/CP1070P';
    export default {
        name: 'AC1020R',
        components: {
            ViewTitle, DxDataGrid, DxColumn, DxButton, DxScrolling, DxSorting, DxPaging, DxDateBox, DxCalendarOptions, CP1070P
        },
        data() {
            return {
                // 입출금구분 코드
                InOutDvsCodeList: new Array(),
                // 입출금출처 코드
                AccSrcDvsCodeList: new Array(),
                // 입출금상태 코드
                InOutStateCodeList: new Array(),
                InStateCodeList: new Array(),
                OutStateCodeList: new Array(),
                // 계정과목 코드
                AcntSubjtCodeList: new Array(),
                // 입출금관리 목록 객체
                CashInOutList: new Array(),
                // 입출금관리 상세 객체
                CashInOutDetail: {
                    SeqNo: null,
                    InOutDvsCd: '',
                    InOutDvsName: '',
                    AccSrcDvsCd: '',
                    AccSrcDvsName: '',
                    AccountDetailNm: '',
                    CashYN: '',
                    Amount: '',
                    InOutYMD: null,
                    ExeInOutYMD: null,
                    BankCd: '',
                    BankName: '',
                    AcntNo: '',
                    CheckEndYMD: '',
                    AcntSubjtCd: '',
                    InOutStateCd: '',
                    InOutAmtMemo: '',
                    ExpenseReportNo: '',
                },
                // 조회조건 객체
                CashInOutSelectTerm: {
                    Arg: 'L',
                    UserId: this.$parent.EmpId,
                    InOutName: '',
                    InOutDvsCode: '',
                    AccSrcDvsCode: '',
                    InOutStateCd: '',
                    CashYn: '',
                    StartDate: this.moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD'),
                    EndDate: this.moment(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)).format('YYYY-MM-DD'),
                },
                CashInOutListTotalCount: 0,
                PageActionType: 'V',
            }
        },
        beforeMount() {

            this.InOutDvsCodeList = $.scriptBase.getCommonCode('CODE000', '0505000', '', '');
            this.AccSrcDvsCodeList = $.scriptBase.getCommonCode('CODE000', '0509000', '', '');
            this.InOutStateCodeList = $.scriptBase.getCommonCode('CODE000', '0517000', '', '');
            this.InStateCodeList = this.InOutStateCodeList.filter((item) => item.Etc1 == 1);
            this.OutStateCodeList = this.InOutStateCodeList.filter((item) => item.Etc1 == 2);
            this.AcntSubjtCodeList = $.scriptBase.getCommonCode('CODE000', '0521000', '', '');
        },
        mounted() {

            if (this.$parent.CanSearch) {

                this.fn_ShowData(false);
            }
        },
        methods: {
            // datagrid header 가운데 정렬, column 중앙 정렬
            onCellPrepared(e) {

                if (e.rowType == 'header') {

                    e.cellElement.style['textAlign'] = 'center';
                }
                if (e.rowType == 'data') {

                    e.cellElement.style['verticalAlign'] = 'middle';
                }
            },
            // 입/출일자 날짜 타입 변경 이벤트
            dateTypeChangeEvent(event) {

                let dateType = Number(event.target.value);

                switch (dateType) {
                    case 1: {

                        this.CashInOutSelectTerm.StartDate = this.moment(new Date()).format('YYYY-MM-DD');
                        this.CashInOutSelectTerm.EndDate = this.moment(new Date()).format('YYYY-MM-DD');
                        break;
                    }
                    case 2: {

                        this.CashInOutSelectTerm.StartDate = this.moment(new Date()).add(-7, 'days').format('YYYY-MM-DD');
                        this.CashInOutSelectTerm.EndDate = this.moment(new Date()).format('YYYY-MM-DD');
                        break;
                    }
                    case 3: {

                        this.CashInOutSelectTerm.StartDate = this.moment(new Date()).add(-1, 'months').format('YYYY-MM-DD');
                        this.CashInOutSelectTerm.EndDate = this.moment(new Date()).format('YYYY-MM-DD');
                        break;
                    }
                    case 4: {

                        this.CashInOutSelectTerm.StartDate = this.moment(new Date()).add(-1, 'years').format('YYYY-MM-DD');
                        this.CashInOutSelectTerm.EndDate = this.moment(new Date()).format('YYYY-MM-DD');
                        break;
                    }
                    case 5: {

                        this.CashInOutSelectTerm.StartDate = this.moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD');
                        this.CashInOutSelectTerm.EndDate = this.moment(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)).format('YYYY-MM-DD');
                        break;
                    }
                    default:
                }
            },
            // 은행계좌 검색
            searchBankAccount() {

                var parent = this;
                var successCallback = function (result) {

                    parent.CashInOutDetail.AccountNumber = result.BankNm + " " + result.AcntNo;
                    parent.CashInOutDetail.BankCd = result.BankCd;
                    parent.CashInOutDetail.BankName = result.BankNm;
                    parent.CashInOutDetail.AcntNo = result.AcntNo;
                }

                this.$refs.CP1070P.open(successCallback);
            },
            // 목록 선택 이벤트
            rowSelectEvent(e) {

                let data = e.data;

                // 화면 액션값 설정 (I:신규, V: 상세보기)
                this.PageActionType = 'V';

                // 선택한 행 데이터 복사
                this.CashInOutDetail = Object.assign({}, data);

                // Arg, 로그인 사용자 아이디 값 추가
                this.CashInOutDetail.Arg = 'I';
                this.CashInOutDetail.UserId = this.$parent.EmpId;

                // 계좌번호 값 추가
                this.CashInOutDetail.AccountNumber = this.CashInOutDetail.BankName + "  " + this.CashInOutDetail.AcntNo;

                this.CashInOutDetail.Amount = this.CashInOutDetail.Amount.toPriceNumber();

                // 상세팝업 오픈
                $('#AC1021E_Modal').modal('show');
            },
            // 데이터 조회
            fn_ShowData() {

                var parent = this;
                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/GetCashItem'.toRealServiceUrl();
                var obj = new Object();
                obj.jsonString = JSON.stringify(this.CashInOutSelectTerm);

                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d);
                    var table = jsonData.Table;
                    var table1 = jsonData.Table1;

                    parent.CashInOutList = (table.length > 0) ? table : new Array();
                    parent.CashInOutListTotalCount = (table1.length > 0) ? table1[0].TotCnt : 0;
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 데이터 추가
            fn_InstData() {

                // 화면 액션값 설정 (I:신규, V: 상세보기)
                this.PageActionType = 'I';

                this.CashInOutDetail = {
                    Arg: 'I',
                    UserId: this.$parent.EmpId,
                    SeqNo: null,
                    InOutDvsCd: '',
                    InOutDvsName: '',
                    AccSrcDvsCd: '0509070',
                    AccSrcDvsName: '',
                    AccountDetailNm: '',
                    CashYN: 'Y',
                    Amount: '',
                    InOutYMD: null,
                    ExeInOutYMD: null,
                    AccountNumber: '',
                    BankCd: '',
                    BankName: '',
                    AcntNo: '',
                    CheckEndYMD: '',
                    AcntSubjtCd: '',
                    InOutStateCd: '',
                    InOutAmtMemo: '',
                    ExpenseReportNo: '',
                };

                // 상세팝업 오픈
                $('#AC1021E_Modal').modal('show');
            },
            // 데이터 저장
            fn_SaveData() {

                if (this.CashInOutDetail.AccountDetailNm === null || this.CashInOutDetail.AccountDetailNm === '') {

                    alert('입/출금명을 작성해주세요.');
                    document.getElementById('txtInOutNm2').focus();
                    return;
                }

                if (this.CashInOutDetail.InOutDvsCd === null || this.CashInOutDetail.InOutDvsCd === '') {

                    alert('입/출구분을 입력해주세요.');
                    document.getElementById('selInOutDvsCd2').focus();
                    return;
                }

                if (this.CashInOutDetail.CashYN === null || this.CashInOutDetail.CashYN === '') {

                    alert('현금여부를 입력해주세요.');
                    document.getElementById('selCash2').focus();
                    return;
                }

                if (this.CashInOutDetail.Amount === null || this.CashInOutDetail.Amount === '0' || this.CashInOutDetail.Amount === '') {

                    alert('예정액을 작성해주세요.');
                    document.getElementById('txtPrice2').focus();
                    return;
                }

                if (this.CashInOutDetail.Amount != null && Number(this.CashInOutDetail.Amount.replace(/,/g, '')) > 9999999999) {

                    alert('예정액은 9,999,999,999원을 넘을 수 없습니다.');
                    document.getElementById('txtPrice2').focus();
                    return;
                }

                if (this.CashInOutDetail.InOutYMD === null || this.CashInOutDetail.InOutYMD === '') {

                    alert('입/출예정일자를 입력해주세요.');
                    return;
                }

                if (this.CashInOutDetail.AccountNumber === null || this.CashInOutDetail.AccountNumber === '') {

                    alert('계좌번호를 입력해주세요.');
                    document.getElementById('txtBank2').focus();
                    return;
                }

                if (this.CashInOutDetail.InOutStateCd === null || this.CashInOutDetail.InOutStateCd === '') {

                    alert('입/출금상태를 입력해주세요.');
                    document.getElementById('selInOutConfirm2').focus();
                    return;
                }

                if (this.CashInOutDetail.CashYN === 'N') {

                    if (this.CashInOutDetail.CheckEndYMD === null || this.CashInOutDetail.CheckEndYMD === '') {

                        alert('어음만기일을 입력해주세요.');
                        return;
                    }
                }

                if (this.CashInOutDetail.AcntSubjtCd === null || this.CashInOutDetail.AcntSubjtCd === '') {

                    alert('계정과목을 선택해주세요.');
                    return;
                }

                var parent = this;
                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/SaveCashItem'.toRealServiceUrl();
                var obj = new Object();

                this.CashInOutDetail.InOutYMD = this.moment(this.CashInOutDetail.InOutYMD).format('YYYYMMDD');
                this.CashInOutDetail.ExeInOutYMD = this.CashInOutDetail.ExeInOutYMD ? this.moment(this.CashInOutDetail.ExeInOutYMD).format('YYYYMMDD') : '';
                this.CashInOutDetail.CheckEndYMD = this.CashInOutDetail.CheckEndYMD ? this.moment(this.CashInOutDetail.CheckEndYMD).format('YYYYMMDD') : '';
                this.CashInOutDetail.Amount = Number(this.CashInOutDetail.Amount.replace(/[^0-9\.-]+/g, ''));

                obj.jsonString = JSON.stringify(this.CashInOutDetail);

                // 서비스호출 성공 콜백 함수
                var successCallback = function (data) {

                    if (data.d > 0) {

                        if (parent.PageActionType === 'I') {

                            alert('등록되었습니다.');
                            $('#AC1021E_Modal').modal('hide');
                            parent.fn_ShowData();
                        } else {

                            alert('저장되었습니다.');
                            $('#AC1021E_Modal').modal('hide');
                            parent.fn_ShowData();
                        }
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 데이터 삭제
            fn_RemoveData() {

                if (confirm('삭제하시겠습니까?')) {

                    var parent = this;
                    var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/RemoveCashItem'.toRealServiceUrl();
                    var obj = new Object();
                    obj.arg = 'D';
                    obj.userId = this.$parent.EmpId;
                    obj.seqNo = this.CashInOutDetail.SeqNo;

                    var successCallback = function (data) {

                        if (data.d > 0) {

                            parent.fn_ShowData(false);
                            $('#AC1021E_Modal').modal('hide');
                        }
                    }

                    $.scriptBase.executeAjaxAsync(url, obj, successCallback);
                }
            },
        }
    }
</script>