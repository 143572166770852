<template>
    <div class="modal fade" id="CP1020P_Modal" tabindex="-1" role="dialog" aria-labelledby="defaultModal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" @click.Prevent="close">
                        <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                    </button>
                    <h3 class="modal-title">인력 검색</h3>
                </div>
                <div class="modal-body">
                    <section class="sch-wrap">
                        <form role="form">
                            <fieldset>
                                <legend>검색폼</legend>
                                <section class="sch-cont">
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="CP1020P_selEmpType" class="control-label"><span>인력 구분</span></label>
                                            <select class="form-control" id="CP1020P_selEmpType" name="CP1020P_selEmpType" v-model="ManPowerDvsCd" @change="btnSearchClicked">
                                                <option value="">= 전체 =</option>
                                                <option v-for="item in ManPowerDvsCdList" v-bind:value="item.Code">{{item.Name}}</option>
                                            </select>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="CP1020P_selSection" class="control-label"><span>직급</span></label>
                                            <select class="form-control" id="CP1020P_selSection" name="CP1020P_selSection" v-model="CompanyRankCd" @change="btnSearchClicked">
                                                <option value="">= 선택 =</option>
                                                <option v-for="item in CompanyRankCdList" v-bind:value="item.Code">{{item.Name}}</option>
                                            </select>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="CP1020P_txtSearch" class="control-label"><span>인력명</span></label>
                                            <input id="CP1020P_txtSearch" type="text" name="CP1020P_txtSearch" class="form-control txt" v-model="ManPowerEmpNm" @keypress.enter.prevent="keypressEvent" />
                                        </div>
                                    </section>
                                </section>
                                <section class="sch-btn">
                                    <a id="CP1020P_btnSearch" class="btn btn-primary btn-row2 " @click.prevent="btnSearchClicked"><span><i class="glyphicon glyphicon-search"></i> 검색</span></a>
                                </section>
                            </fieldset>
                        </form>
                    </section>
                    <section class="tbl-contents">
                        <!--리스트 영역-->
                        <div class="total alert" style="margin-bottom: 0;">
                            <p><i class="glyphicon glyphicon-folder-close"></i><span>전체: <span id="spTotCnt">{{TotalCount}}</span>건</span></p>
                        </div>
                        <div class="table-responsive">
                            <table class="table tbl-list tbl-center table-hover table-bordered">
                                <caption>테이블</caption>
                                <colgroup>
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col">사원 구분</th>
                                        <th scope="col">사원명</th>
                                        <th scope="col">직급</th>
                                        <th scope="col">이메일</th>
                                        <th scope="col">핸드폰번호</th>
                                    </tr>
                                </thead>
                                <tbody id="result">
                                    <tr v-if="ManPowerList === null">
                                        <td colspan="5">조회된 정보가 없습니다.</td>
                                    </tr>
                                    <tr v-else v-for="item in ManPowerList" style="cursor:pointer;" @click="rowClickEvent(item)">
                                        <td>{{item.CodeName}}</td>
                                        <td>{{item.EmpNm}}</td>
                                        <td>{{item.JobGradeNm}}</td>
                                        <td>{{item.EMail}}</td>
                                        <td>{{item.MobileNo}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                    <div class="text-center">
                        <ul id="CP1020P_pager" class="pagination"> </ul>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default btn-sm" @click.Prevent="close"><span class="glyphicon glyphicon-remove"></span>취소(닫기)</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CP1020P',
        data() {
            return {
                CallbackFunction: null,
                // 인력구분 리스트
                ManPowerDvsCdList: null,
                // 직급 리스트
                CompanyRankCdList: null,
                CompanyCode: this.$store.getters.getCompanyCode,
                CurrentPage: 1,
                TopSize: 10,
                // 인력구분 코드
                ManPowerDvsCd: '',
                // 직급 코드
                CompanyRankCd: '',
                // 인력명
                ManPowerEmpNm: '',
                TotalCount: 0,
                // 조회된 인력 리스트
                ManPowerList: null,
            }
        },
        beforeMount() {
            // 인력구분 코드
            this.ManPowerDvsCdList = $.scriptBase.getCommonCode('CODE000', '0305000', '', '');
            // 직급 코드
            this.CompanyRankCdList = $.scriptBase.getCommonCode('CODE000', '0303000', '', '');
        },
        methods: {
            // 인력 검색 팝업 오픈
            open(callbackFunction) {

                var parent = this;

                this.CallbackFunction = callbackFunction;
                this.CurrentPage = 1;
                this.TotalCount = 0;
                this.ManPowerList = null;

                // 인력 구분 초기화
                this.ManPowerDvsCd = '';
                // 직급 초기화
                this.CompanyRankCd = '';
                // 인력명 초기화
                this.ManPowerEmpNm = '';

                // 페이징 초기화
                $("#CP1020P_pager").pager({
                    pager: "#CP1020P_pager",
                    pagerMode: "text",
                    totalItemCount: parent.TotalCount,
                    recordPerPage: parent.TopSize,
                    currentPage: parent.CurrentPage,
                    pageIndexChanged: parent.pageChangeEvent,
                    prev10Text: "&laquo;",
                    next10Text: "&raquo;",
                    selectedPageFontColor: "green"
                });

                this.ShowData();
                $('#CP1020P_Modal').modal('show');
            },
            close() {

                $("#CP1020P_Modal").modal("hide");
            },
            // 인력 검색 팝업 오픈
            showManPowerPopup(callbackFunction) {

                var parent = this;

                this.CallbackFunction = callbackFunction;
                this.CurrentPage = 1;
                this.TotalCount = 0;
                this.ManPowerList = null;

                // 인력 구분 초기화
                this.ManPowerDvsCd = '';
                // 직급 초기화
                this.CompanyRankCd = '';
                // 인력명 초기화
                this.ManPowerEmpNm = '';

                // 페이징 초기화
                $("#CP1020P_pager").pager({
                    pager: "#CP1020P_pager",
                    pagerMode: "text",
                    totalItemCount: parent.TotalCount,
                    recordPerPage: parent.TopSize,
                    currentPage: parent.CurrentPage,
                    pageIndexChanged: parent.pageChangeEvent,
                    prev10Text: "&laquo;",
                    next10Text: "&raquo;",
                    selectedPageFontColor: "green"
                });

                this.ShowData();
                $('#CP1020P_Modal').modal('show');

            },
            // 인력 검색 메서드
            ShowData() {

                var parent = this;

                var url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/GetEmpList'.toRealServiceUrl();
                var obj = new Object();
                obj.prm = new Array();
                obj.prm.push(this.CompanyCode);
                // 사원명
                obj.prm.push(this.ManPowerEmpNm);
                // 직급선택
                obj.prm.push(this.CompanyRankCd);
                obj.prm.push(this.CurrentPage);
                obj.prm.push(this.TopSize);
                // 사원 구분
                obj.prm.push(this.ManPowerDvsCd);

                // ajax 성공 시 실행되는 핸들러 정의
                var ajaxSuccessHandler = function (data) {

                    var table = JSON.parse(data.d).Table;
                    var table1 = JSON.parse(data.d).Table1;

                    parent.ManPowerList = (table.length > 0) ? table : null;
                    parent.TotalCount = (table1.length > 0) ? table1[0].TotCnt : 0;

                    // 페이징 세팅
                    $("#CP1020P_pager").pager({
                        pager: "#CP1020P_pager",
                        pagerMode: "text",
                        totalItemCount: parent.TotalCount,
                        recordPerPage: parent.TopSize,
                        currentPage: parent.CurrentPage,
                        pageIndexChanged: parent.pageChangeEvent,
                        prev10Text: "&laquo;",
                        next10Text: "&raquo;",
                        selectedPageFontColor: "green"
                    });
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler);

            },
            // 페이지 변경 이벤트 핸들러
            pageChangeEvent(page) {

                this.CurrentPage = page;
                this.ShowData();
            },
            // 검색 버튼 클릭 이벤트 핸들러
            btnSearchClicked() {

                this.CurrentPage = 1;
                this.ShowData();
            },
            // 테이블Row 클릭 이벤트 핸들러
            rowClickEvent(item) {

                // 호출한 뷰로 선택한 인력정보 데이터 전달
                this.CallbackFunction(item);
                this.close();
            },
            // 검색어 필드에서 엔터키 입력 시 조회되도록 하는 키업 이벤트
            keypressEvent(event) {

                if (event.key == 'Enter') {

                    this.ShowData();
                }
            },
        }
    }
</script>