<template>
    <div class="modal fade" ref="CM1021E_Modal" tabindex="-1" role="dialog" aria-labelledby="CM1021E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">
                        <span aria-hidden="true">&times;</span>
                        <span class="sr-only">Close</span>
                    </button>
                    <h3 class="modal-title">업체 등록/수정</h3>
                </div>
                <div class="modal-body">
                    <section class="add-wrap">
                        <form>
                            <fieldset>
                                <legend>..</legend>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtTraderCd2" class="control-label">
                                            <span>업체코드</span>
                                        </label>
                                        <input type="text" id="txtPresidentNm2" class="form-control txt" readonly="readonly" v-model="CompanyDetail.CompanyCd" />
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtTraderNm2" class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>업체명
                                            </span>
                                        </label>
                                        <div class="cols-btns-cont">
                                            <input type="text" ref="txtTraderNm2" class="form-control txt" v-model="CompanyDetail.CompanyNm" />
                                        </div>
                                        <div class="cols-btns">
                                            <button type="button" class="btn btn-default" @click="searchCorpInfo">
                                                <i class="glyphicon glyphicon-search"></i>검색
                                            </button>
                                        </div>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtCorpRegNo2" class="control-label">
                                            <span>사업자번호</span>
                                        </label>
                                        <input type="text" id="txtCorpRegNo2" class="form-control txt" maxlength="12" placeholder="'-'은 제외하고 입력하세요."
                                               v-model="CompanyDetail.CorpRegNo"
                                               @input="function(e){
                                                CompanyDetail.CorpRegNo = e.target.value.toNumber()
                                                e.target.value = e.target.value.toNumber()
                                           }" />
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtCorpNo2" class="control-label">
                                            <span>법인번호</span>
                                        </label>
                                        <input v-model="CompanyDetail.CorpNo" type="text" id="txtCorpNo2" class="form-control txt" maxlength="14" />
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtPresidentNm2" class="control-label">
                                            <span>대표자명</span>
                                        </label>
                                        <input v-model="CompanyDetail.PresidentNm" type="text" id="txtPresidentNm2" class="form-control txt" />
                                    </div>
                                    <div class="cols col-2">
                                        <label for="selCorpType2" class="control-label">
                                            <span>업체구분</span>
                                        </label>
                                        <select v-model="CompanyDetail.CompanyType" class="form-control" id="selCorpType2" name="selCorpType2">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in CompanyTypeList" v-bind:value="item.Code">{{item.Name}}</option>
                                        </select>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols">
                                        <label for="txtPostNo2" class="control-label">
                                            <span>주소</span>
                                        </label>
                                        <div class="col-xs-4">
                                            <div class="cols-btns-cont pad-r70">
                                                <input v-model="CompanyDetail.DisplayPostNo" type="text" id="txtDisplayPostNo2" class="form-control txt" readonly="readonly" />
                                                <input type="hidden" id="hidPostNo2" v-model="CompanyDetail.PostNo" />
                                                <input type="hidden" id="hidPostSeqNo2" v-model="CompanyDetail.PostSeqNo" />
                                                <input type="hidden" id="hidBldgMgrNo2" v-model="CompanyDetail.BldgMgrNo" />
                                            </div>
                                            <div class="cols-btns">
                                                <a href="#" id="btnItemPop1" class="btn btn-default" @click.prevent="searchZipCode">
                                                    <span>
                                                        <i class="glyphicon glyphicon-search"></i> 검색
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-xs-8">
                                            <input v-model="CompanyDetail.Addr1" type="text" id="txtAddr12" class="form-control txt" readonly="readonly" />
                                        </div>
                                    </div>
                                    <div class="cols">
                                        <input v-model="CompanyDetail.Addr2" type="text" id="txtAddr22" class="form-control" />
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtTelNo2" class="control-label">
                                            <span>전화번호</span>
                                        </label>
                                        <input v-model="CompanyDetail.TelNo" type="text" id="txtTelNo2" class="form-control txt" maxlength="20" />
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtFaxNo2" class="control-label">
                                            <span>팩스번호</span>
                                        </label>
                                        <input v-model="CompanyDetail.FaxNo" type="text" id="txtFaxNo2" class="form-control txt" maxlength="20" />
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="selBankCd2" class="control-label">
                                            <span>입금은행</span>
                                        </label>
                                        <select v-model="CompanyDetail.BankCd" class="form-control" id="selBankCd2" name="selBankCd2">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in BankCodeList" v-bind:value="item.Code">{{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtBankAcntNo2" class="control-label">
                                            <span>계좌번호</span>
                                        </label>
                                        <input v-model="CompanyDetail.BankAcntNo" type="text" id="txtBankAcntNo2" class="form-control" />
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols">
                                        <label for="txtHomeURL2" class="control-label">
                                            <span>홈페이지 URL</span>
                                        </label>
                                        <input v-model="CompanyDetail.HomeURL" type="text" id="txtHomeURL2" class="form-control txt" />
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols">
                                        <label for="txtBuyURL2" class="control-label">
                                            <span>구매사이트 URL</span>
                                        </label>
                                        <input v-model="CompanyDetail.BuyURL" type="text" id="txtBuyURL2" class="form-control txt" />
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="selUseYN2" class="control-label">
                                            <span>사용여부</span>
                                        </label>
                                        <select v-model="CompanyDetail.UseYN" id="selUseYN2" class="form-control">
                                            <option value="Y">Y</option>
                                            <option value="N">N</option>
                                        </select>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="selIsShowBannerYn2" class="control-label">
                                            <span>홈페이지노출여부</span>
                                        </label>
                                        <select v-model="CompanyDetail.IsShowBannerYn" id="selIsShowBannerYn2" class="form-control">
                                            <option value="Y">Y</option>
                                            <option value="N">N</option>
                                        </select>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtBusinessType2" class="control-label"><span>업태</span></label>
                                        <input v-model="CompanyDetail.BusinessType" type="text" id="txtBusinessType2" class="form-control" />
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtBusinessItem2" class="control-label"><span>종목</span></label>
                                        <input v-model="CompanyDetail.BusinessItem" type="text" id="txtBusinessItem2" class="form-control" />
                                    </div>
                                </section>
                                <!--하단과 상단의 공백-->
                                <section class="cols-wrap">
                                    <div><label></label></div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="btn-wrap" style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 0;">
                                        <div style="width: 743px; display: flex; align-items: flex-start;">
                                            <label class="control-label"><span>첨부파일</span></label>
                                        </div>
                                        <div class="fr">
                                            <a href="#" id="btnFileInst" class="btn btn-sm btn-default" title="추가" @click.prevent="addAttachFile">
                                                <span class="glyphicon glyphicon-plus"></span> 추가
                                            </a>
                                            <a href="#" id="btnFileRmv" class="btn btn-sm btn-default" title="삭제" @click.prevent="removeAttachFile">
                                                <span class="glyphicon glyphicon-minus"></span> 삭제
                                            </a>
                                        </div>
                                    </div>
                                    <section class="tbl-contents">
                                        <div class="table-responsive">
                                            <form id="form1" action="" name="form1" method="post" enctype="multipart/form-data">
                                                <table class="table tbl-list table-hover table-bordered" id="tb1" ref="tb1" style="margin-bottom: 0;">
                                                    <colgroup>
                                                        <col class="chk" />
                                                        <col />
                                                    </colgroup>
                                                    <thead>
                                                        <tr>
                                                            <th class="chk">선택</th>
                                                            <th scope="col" class="text-center">첨부파일</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-if="CompanyDetailAttachFileList === null">
                                                            <td colspan="3" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                        </tr>
                                                        <tr v-else v-for="(item, index) in CompanyDetailAttachFileList">
                                                            <td class="chk">
                                                                <input type="checkbox" name="cbItem" v-model="item.IsChecked" />
                                                            </td>
                                                            <td v-if="item.IsNewFile == false" class="text-center" hidden>
                                                                <input type="hidden" v-model="item.BnnrSeqNo" />
                                                            </td>
                                                            <td v-if="item.IsNewFile === true" class="text-center">
                                                                <input type="file" name="ctlAttachFile"
                                                                       @change="function(e){
                                                                        item.Files = e.target.files
                                                                   }" />
                                                            </td>
                                                            <td v-else class="text-left">
                                                                <a href="#" @click.prevent="fileHandler.soliDocsDownload(item.DocId)">{{item.FileName}}</a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </form>
                                        </div>
                                    </section>
                                </section>
                            </fieldset>
                        </form>
                    </section>
                </div>
                <div class="modal-footer">
                    <div class="btn-align">
                        <div class="btn-group">
                            <button type="button" id="btnItemSave" class="btn btn-primary" @click="onClickSave">
                                <i class="glyphicon glyphicon-save"></i> 저장
                            </button>
                        </div>
                        <div v-show="PageActionType != 'I'" class="btn-group" @click="removeCompany">
                            <button type="button" id="btnItemRmv" class="btn btn-warning">
                                <i class="glyphicon glyphicon-edit"></i> 삭제
                            </button>
                        </div>
                        <div class="btn-group">
                            <button type="button" class="btn btn-danger" @click="close">
                                <i class="glyphicon glyphicon-remove"></i> 취소
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <CM1022E ref="CM1022E"></CM1022E>
    </div>
</template>

<script>
    import CM1022E from '@/components/cm/popup/CM1022E';
    export default {
        name: 'CM1021E',
        components: { CM1022E },
        data() {
            return {
                CallbackFunction: null,
                // 페이지 액션 값 (I: 신규, V: 상세)
                PageActionType: 'I',
                // 업체구분 공통코드 목록
                CompanyTypeList: new Array(),
                // 은행 공통코드 목록
                BankCodeList: new Array(),
                // 업체 상세 객체
                CompanyDetail: new Object(),
                AttachFileSelectTerm: {
                    Arg: 'F',
                    UserId: this.$store.getters.getEmpId,
                    CorpCd: this.$store.getters.getCompanyCode,
                    CompanyCd: '',
                    CompanyNm: '',
                    CompanyTypeCode: '',
                    UseYn: '',
                },
                // 첨부파일 목록
                CompanyDetailAttachFileList: null,
                // 첨부파일 삭제 목록
                RemoveAttachFileList: new Array(),
            }
        },
        beforeMount() {

            // 공통코드 조회
            this.CompanyTypeList = $.scriptBase.getCommonCode('CODE000', '0107000', '', '');
            this.BankCodeList = $.scriptBase.getCommonCode('CODE000', '0101000', '', '');
        },
        methods: {
            open(callbackFunction, selectedObj) {

                this.CallbackFunction = callbackFunction;

                this.CompanyDetail = {
                    Arg: 'I',
                    UserId: this.$store.getters.getEmpId,
                    CorpCd: this.$store.getters.getCompanyCode,
                    CompanyCd: null,
                    CompanyNm: null,
                    CompanyType: '',
                    CorpTypeName: null,
                    CorpRegNo: null,
                    CorpNo: null,
                    PresidentNm: null,
                    PostNo: null,
                    DisplayPostNo: null,
                    PostSeqNo: null,
                    BldgMgrNo: null,
                    Addr1: null,
                    Addr2: null,
                    TelNo: null,
                    FaxNo: null,
                    BusinessType: null,
                    BusinessItem: null,
                    BankCd: '',
                    BankName: null,
                    BankAcntNo: null,
                    HomeURL: null,
                    BuyURL: null,
                    UseYN: 'Y',
                    IsShowBannerYn: 'Y'
                }

                if (selectedObj) {

                    this.PageActionType = 'V';
                    this.CompanyDetail = Object.assign({}, selectedObj);
                    this.CompanyDetail.Arg = 'I';
                    this.CompanyDetail.UserId = this.$store.getters.getEmpId;
                    this.AttachFileSelectTerm.CompanyCd = this.CompanyDetail.CompanyCd;

                    this.getAttachFileList();
                } else {

                    this.PageActionType = 'I';
                    this.CompanyDetailAttachFileList = null;
                }

                $(this.$refs.CM1021E_Modal).modal('show');
            },
            close() {

                this.CompanyDetail = new Object();
                this.CompanyDetailAttachFileList = null;
                this.RemoveAttachFileList = new Array();

                $(this.$refs.CM1021E_Modal).modal('hide');
            },
            // 기업정보 검색 모달 오픈
            searchCorpInfo() {

                let parent = this;

                // 법인등록번호가 있을 때 검색 편리화
                let crno = (this.CompanyDetail.CorpNo) ? this.CompanyDetail.CorpNo : null;

                var successCallback = function (data) {

                    let corpData = data;

                    parent.CompanyDetail.CompanyNm = corpData.corpNm;
                    parent.CompanyDetail.CorpRegNo = corpData.bzno;
                    parent.CompanyDetail.CorpNo = corpData.crno;
                    parent.CompanyDetail.PresidentNm = corpData.enpRprFnm.replace(/ /g, '');
                    parent.CompanyDetail.PostNo = corpData.enpOzpno;
                    parent.CompanyDetail.DisplayPostNo = corpData.enpOzpno;
                    parent.CompanyDetail.Addr1 = corpData.enpBsadr;
                    parent.CompanyDetail.Addr2 = (corpData.enpDtadr != '') ? corpData.enpDtadr : null;
                    parent.CompanyDetail.TelNo = corpData.enpTlno.replace(/ /g, '');
                    parent.CompanyDetail.FaxNo = corpData.enpFxno.replace(/ /g, '');
                    parent.CompanyDetail.HomeURL = (corpData.enpHmpgUrl != '') ? corpData.enpHmpgUrl.replace(/ /g, '') : null;
                }

                this.$refs.CM1022E.open(successCallback, crno);
            },
            // 우편번호 검색
            searchZipCode() {

                let parent = this;

                new daum.Postcode({
                    oncomplete(data) {

                        // 주소 변수
                        let addr = '';

                        // 사용자가 도로명 주소를 선택했을 경우
                        if (data.userSelectedType === 'R') {

                            addr = (data.buildingName) ? data.roadAddress + ' (' + data.buildingName + ')' : data.roadAddress;
                            // 사용자가 지번 주소를 선택했을 경우(J)
                        } else {

                            addr = data.jibunAddress;
                        }

                        // 우편번호와 주소 정보를 해당 필드에 넣는다.
                        parent.CompanyDetail.DisplayPostNo = data.zonecode;
                        parent.CompanyDetail.PostNo = data.zonecode;
                        parent.CompanyDetail.Addr1 = addr;
                        parent.CompanyDetail.BldgMgrNo = data.buildingCode;

                        // 커서를 상세주소 필드로 이동한다.
                        document.getElementById("txtAddr22").focus();
                    }
                }).open();
            },
            // 첨부파일 조회
            getAttachFileList() {

                let parent = this;
                var url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/GetCompany'.toRealServiceUrl();
                var obj = new Object();

                obj.jsonString = JSON.stringify(this.AttachFileSelectTerm);

                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d);
                    var table = jsonData.Table;

                    if (table.length > 0) {

                        parent.CompanyDetailAttachFileList = table;
                        // 저장을 위한 가공
                        parent.CompanyDetailAttachFileList.forEach((item) => {

                            item.Arg = 'I';
                            item.UserId = parent.CompanyDetail.UserId;
                            item.IsNewFile = false;
                            item.IsChecked = false;
                            item.Files = null;
                        });
                    } else {

                        parent.CompanyDetailAttachFileList = null;
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 첨부파일 추가
            addAttachFile() {

                // 첨부파일 변수 초기화
                if (this.CompanyDetailAttachFileList == null || this.CompanyDetailAttachFileList.length == 0) {

                    this.CompanyDetailAttachFileList = new Array();
                }

                // 신규 첨부파일 오브젝트 추가
                this.CompanyDetailAttachFileList.push({

                    Arg: 'I',
                    UserId: this.$store.getters.getEmpId,
                    CorpCd: this.$store.getters.getCompanyCode,
                    CompanyCd: this.CompanyDetail.CompanyCd,
                    IsNewFile: true,
                    IsChecked: false,
                    BnnrSeqNo: null,
                    GUID: null,
                    Files: null,
                    FileName: null,
                    FileDivision: '',
                    DocId: null,
                });
            },
            // 첨부파일 그리드에서 삭제
            removeAttachFile() {

                // 체크박스 선택 여부 확인
                let check = this.CompanyDetailAttachFileList.some((item) => item.IsChecked == true);

                if (check == false) {

                    alert('삭제할 파일을 선택해 주십시오.');
                    return;
                }

                let fileTable = this.$refs.tb1.tBodies[0];

                this.CompanyDetailAttachFileList.forEach((item, index) => {

                    if (item.IsChecked == true) {

                        // DB에서 삭제할 파일 목록 세팅
                        if (item.IsNewFile == false) {

                            this.RemoveAttachFileList.push(item);
                        }
                        // 삭제 플래그 세팅
                        item.Arg = 'D';
                        // 선택 항목 돔 객체에서 삭제
                        fileTable.deleteRow(index);
                    }
                })
            },
            // 업체관리 저장 전 첨부파일 가공
            onClickSave() {

                let parent = this;

                // 그리드에서 삭제한 첨부파일 삭제
                if (this.RemoveAttachFileList.length > 0) {

                    let url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/RemoveCompanyAttachFile'.toRealServiceUrl();
                    let obj = new Object();

                    obj.jsonString = JSON.stringify(this.RemoveAttachFileList);

                    var successCallback = function (data) {

                        if (data.d > 0) {

                            parent.RemoveAttachFileList = new Array();
                            parent.CompanyDetailAttachFileList.forEach((file) => file.IsChecked = false);
                        } else {

                            alert('파일 삭제에 실패했습니다. 잠시 후 다시 시도해 주십시오.');
                        }
                    }

                    $.scriptBase.executeAjaxAsync(url, obj, successCallback);
                }

                // 첨부파일 핸들러 호출 시 실행
                var successUploadFileCallback = function (result) {

                    let attachFileList = new Array();

                    result.files.forEach((file) => {

                        attachFileList.push({
                            Arg: 'I',
                            UserId: parent.$store.getters.getEmpId,
                            CorpCd: parent.$store.getters.getCompanyCode,
                            CompanyCd: parent.CompanyDetail.CompanyCd,
                            GUID: file.nFileName,
                            FileName: file.oFileName,
                            FileDivision: ''
                        });
                    });

                    // 저장할 첨부파일이 있을 때 업체정보, 파일 함께 저장
                    parent.doSave(attachFileList);
                }

                // 신규 파일 추가 여부
                let isExistNewFile = false;

                if (this.CompanyDetailAttachFileList != null) {

                    isExistNewFile = this.CompanyDetailAttachFileList.some((file) => file.Files != null);
                }

                // 추가된 신규 파일이 있을 때
                if (isExistNewFile) {

                    let newFileList = new Array();

                    this.CompanyDetailAttachFileList.forEach((item) => {

                        if (item.IsNewFile == true && item.Files != null) {

                            for (let i = 0; i < item.Files.length; i++) {

                                item.FileName = item.Files[i].name;
                                newFileList.push(item.Files[i]);
                            }
                        }
                    });

                    if (newFileList.length > 0) {

                        // 첨부파일 핸들러 호출, GUID 생성
                        this.fileHandler.SoliDocsupload(newFileList, successUploadFileCallback);
                    }
                    // 신규 파일 없을 때 업체정보만 저장
                } else {

                    parent.doSave(null);
                }
            },
            // 업체관리 실제 저장
            doSave(attachFileList) {

                var parent = this;

                if (this.CompanyDetail.CompanyNm === null || this.CompanyDetail.CompanyNm === '') {

                    alert('업체명을 입력하세요.');
                    this.$refs.txtTraderNm2.focus();
                    return;
                }

                var url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/SaveCompany'.toRealServiceUrl();
                var obj = new Object();

                obj.jsonString = JSON.stringify(this.CompanyDetail);
                obj.jsonNewFileString = JSON.stringify(attachFileList);

                var successCallback = function (data) {

                    if (data.d > 0) {

                        alert('저장되었습니다.');
                        parent.close();
                        parent.$parent.getCompany();
                    } else {

                        alert('저장에 실패했습니다. 잠시 후 다시 시도해 주십시오.');
                        return;
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 업체관리 삭제
            removeCompany() {

                if (confirm('업체를 삭제하시겠습니까?')) {

                    let parent = this;
                    var url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/RemoveCompany'.toRealServiceUrl();
                    var obj = new Object();

                    obj.arg = 'D';
                    obj.userId = parent.$store.getters.getEmpId;
                    obj.corpCd = this.CompanyDetail.CorpCd;
                    obj.companyCd = this.CompanyDetail.CompanyCd;

                    var successCallback = function (data) {

                        if (data.d > 0) {

                            alert('삭제되었습니다.');
                            parent.close();
                            parent.$parent.getCompany();
                        } else {

                            alert('삭제에 실패했습니다. 잠시 후 다시 시도해 주십시오.');
                            return;
                        }
                    }

                    $.scriptBase.executeAjaxAsync(url, obj, successCallback);
                }
            },
        }
    }
</script>