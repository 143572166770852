<template>

    <div class="modal fade" ref="CP1080P_Modal" tabindex="-1" role="dialog" aria-labelledby="defaultModal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" @click.Prevent="close">
                        <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                    </button>
                    <h3 class="modal-title">영업 검색</h3>
                </div>
                <div class="modal-body">
                    <section class="sch-wrap">
                        <form role="form">
                            <fieldset>
                                <legend>검색폼</legend>
                                <section class="sch-cont">
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="CP1080P_txtTraderNm" class="control-label"><span>거래처</span></label>
                                            <div class="cols-btns-cont">
                                                <input type="text" ref="CP1080P_txtTraderNm" class="form-control txt" v-model="CompanyDetail.CompanyNm" @change="resetCompanyCd" @keypress.enter.prevent="keypressEvent" />
                                                <input type="hidden" ref="CP1080P_hidTraderCd" value="" v-model="CompanyDetail.CompanyCd" />
                                            </div>
                                            <div class="cols-btns">
                                                <button type="button" id="btnCP1080P_ComPop" class="btn btn-default" @click.prevent="searchCompanyCode">
                                                    <span>
                                                        <i class="glyphicon glyphicon-search"></i> 검색
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="CP1080P_txtSrchText-5" class="control-label"><span>영업명</span></label>
                                            <div class="cols-btns-cont3">
                                                <input type="text" ref="CP1080P_txtSrchText" class="form-control txt" v-model="CompanyDetail.BizNm" @keypress.enter.prevent="keypressEvent" />
                                            </div>
                                            <div class="cols-btns">
                                                <a id="CP1080P_btnSrchInit" class="btn btn-default" @click.prevent="clearCompanyCode">
                                                    <span>
                                                        <i class="glyphicon glyphicon-repeat"></i> 초기화
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </section>
                                </section>
                                <section class="sch-btn">
                                    <a id="CP1080P_btnListSrch" class="btn btn-primary" @click.prevent="btnSearchClicked">
                                        <span>
                                            <i class="glyphicon glyphicon-search"></i> 검색
                                        </span>
                                    </a>
                                </section>
                            </fieldset>
                        </form>
                    </section>
                    <section class="tbl-contents">
                        <div class="total">
                            <div class="alert alert-info total">
                                <p><i class="glyphicon glyphicon-folder-close"></i><span>전체: <span id="CP1080P_total">{{TotalCount}}</span>건</span></p>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table tbl-list tbl-center table-hover table-bordered" id="CP1080P_tb0">
                                <caption>테이블</caption>
                                <colgroup>
                                    <col class="table-colwid50" />
                                    <col class="table-colwid50" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col">거래처</th>
                                        <th scope="col">영업명</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="SalesList.length == 0">
                                        <td colspan="2">조회된 정보가 없습니다.</td>
                                    </tr>
                                    <tr v-else v-for="item in SalesList" style="cursor:pointer;" v-on:click="rowClickEvent(item)">
                                        <td>{{item.CompanyNm}}</td>
                                        <td style="text-align:left;">{{item.BizNm}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                    <div class="text-center">
                        <ul ref="CP1080P_pager" class="pagination"></ul>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-default btn-sm" @click.Prevent="close"><span class="glyphicon glyphicon-remove"></span>취소(닫기)</button>
                    </div>
                </div>
            </div>
        </div>
        <CP1030P ref="CP1080P_CP1030P"></CP1030P>
    </div>
</template>

<script>
    import CP1030P from '@/components/libs/popup/CP1030P';
    export default {
        name: 'CP1080P',
        components: {
            CP1030P
        },
        data() {
            return {
                CurrentPage: 1,                                  // 현재 페이지
                TopSize: 10,                                     // 화면에 조회되는 개수
                TotalCount: 0,                                   // 데이터 총 개수
                SalesList: new Array(),                          // 조회된 영업 리스트
                CompanyCode: this.$store.getters.getCompanyCode, // 회사 코드
                EmpId: this.$store.getters.getEmpId,             // 사용자 사번
                CallbackFunction: null,                          // 영업 선택 결과를 넘겨줄 콜백함수
                CompanyDetail: {                                 // 업체 리스트
                    CompanyNm: null,                             // 업체 이름
                    CompanyCd: null,                             // 업체 코드
                    BizNm: ''                                    // 영업명
                },
                IsSelectCompany: true                            // 부모 컴포넌트 고객사 유무
            }
        },
        methods: {
            // 영업 검색 팝업 오픈
            open(callbackFunction, companyCode, companyName, isSelectCompany) {

                this.CallbackFunction = callbackFunction;
                this.CurrentPage = 1;
                this.TotalCount = 0;
                this.SalesList = new Array();
                this.IsSelectCompany = (isSelectCompany === undefined || isSelectCompany === null) ? true : isSelectCompany;

                // 거래처 초기화
                this.CompanyDetail.CompanyNm = (companyName === undefined || companyName === null) ? '' : companyName;
                this.CompanyDetail.CompanyCd = (companyCode === undefined || companyCode === null) ? '' : companyCode;

                // 영업명 초기화
                this.CompanyDetail.BizNm = '';

                // 페이징 세팅
                $(this.$refs.CP1080P_pager).pager({
                    pager: this.$refs.CP1080P_pager,
                    pagerMode: "text",
                    totalItemCount: this.TotalCount,
                    recordPerPage: this.TopSize,
                    currentPage: this.CurrentPage,
                    pageIndexChanged: this.pageChangeEvent,
                    prev10Text: "&laquo;",
                    next10Text: "&raquo;",
                    selectedPageFontColor: "orange"
                });

                $(this.$refs.CP1080P_Modal).modal('show');
            },
            close() {

                $(this.$refs.CP1080P_Modal).modal("hide");
            },
            // 업체 검색 메서드
            searchCompanyCode() {

                var parent = this;
                var successCallback = function (result) {

                    // 결과값을 추출 후 돔객체에 바인딩
                    parent.CompanyDetail.CompanyNm = result.CompanyNm;
                    parent.CompanyDetail.CompanyCd = result.CompanyCd;
                }

                this.$refs.CP1080P_CP1030P.open(successCallback);
            },
            // 영업 검색 팝업 초기화 메서드
            clearCompanyCode() {

                this.CurrentPage = 1;
                this.TotalCount = 0;
                this.SalesList = new Array();

                // 거래처 초기화
                this.CompanyDetail.CompanyNm = '';
                this.CompanyDetail.CompanyCd = '';

                // 영업명 초기화
                this.CompanyDetail.BizNm = '';

                // 페이징
                $(this.$refs.CP1080P_pager).pager({
                    pager: this.$refs.CP1080P_pager,
                    pagerMode: "text",
                    totalItemCount: this.TotalCount,
                    recordPerPage: this.TopSize,
                    currentPage: this.CurrentPage,
                    pageIndexChanged: this.pageChangeEvent,
                    prev10Text: "&laquo;",
                    next10Text: "&raquo;",
                    selectedPageFontColor: "orange"
                });

            },

            resetCompanyCd() {

                this.CompanyDetail.CompanyCd = '';
            },

            // 영업 검색 메서드
            ShowData() {

                var parent = this;

                var url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/GetTraderBusiness'.toRealServiceUrl();
                var obj = new Object();
                obj.prm = new Array();
                obj.prm.push("L");                              // L
                obj.prm.push(this.EmpId);                       // 사용자 사번
                obj.prm.push(this.CompanyCode);                 // 회사 코드
                obj.prm.push(this.CompanyDetail.CompanyCd);     // 거래처 ID
                obj.prm.push(this.CompanyDetail.CompanyNm);     // 거래처 이름
                obj.prm.push("");                               // ""
                obj.prm.push("");                               // ""
                obj.prm.push(this.CompanyDetail.BizNm);         // 영업명
                obj.prm.push("");                               // ""
                obj.prm.push("");                               // ""
                obj.prm.push(this.TopSize);                     // 전체페이지
                obj.prm.push(this.CurrentPage);                 // 선택페이지

                // ajax 성공 시 실행되는 핸들러 정의
                var ajaxSuccessHandler = function (data) {

                    var table = JSON.parse(data.d).Table;
                    var table1 = JSON.parse(data.d).Table1;

                    parent.SalesList = (table.length > 0) ? table : new Array();
                    parent.TotalCount = (table1.length > 0) ? table1[0].TotCnt : 0;

                    // 페이징 세팅
                    $(parent.$refs.CP1080P_pager).pager({
                        pager: parent.$refs.CP1080P_pager,
                        pagerMode: "text",
                        totalItemCount: parent.TotalCount,
                        recordPerPage: parent.TopSize,
                        currentPage: parent.CurrentPage,
                        pageIndexChanged: parent.pageChangeEvent,
                        prev10Text: "&laquo;",
                        next10Text: "&raquo;",
                        selectedPageFontColor: "orange"
                    });
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler);
            },
            // 페이지 변경 이벤트 핸들러
            pageChangeEvent(page) {

                this.CurrentPage = page;
                this.ShowData();
            },
            // 검색 버튼 클릭 이벤트 핸들러
            btnSearchClicked() {

                this.CurrentPage = 1;
                this.ShowData();
            },
            // 테이블Row 클릭 이벤트 핸들러
            rowClickEvent(item) {

                // 호출한 뷰로 선택한 영업정보 데이터 전달
                this.CallbackFunction(item);

                $(this.$refs.CP1080P_Modal).modal("hide");
            },
            // 검색어 필드에서 엔터키 입력 시 조회되도록 하는 키업 이벤트
            keypressEvent(event) {

                if (event.key == 'Enter') {

                    this.ShowData();
                }
            },
        }
    }
</script>