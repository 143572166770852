<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a href="#" id="btnListSrch" class="btn btn-primary" @click.prevent="showEquipmentList">
                    <span>
                        <i class="glyphicon glyphicon-search"></i>조회
                    </span>
                </a>
                <a href="#" id="btnListInst" class="btn btn-default" @click.prevent="onAddEquipment">
                    <span>
                        <i class="glyphicon glyphicon-plus"></i>추가
                    </span>
                </a>
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-4">
                                        <label for="selEquipmentDivCd" class="control-label">
                                            <span>비품구분</span>
                                        </label>
                                        <select class="form-control" id="selEquipmentDivCd" name="selEquipmentDivCd" @change="showEquipmentList" v-model="EquipmentSelectTerm.EquipmentDivCd">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in EquipmentDivCdList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-4">
                                        <label for="selEquipmentStateCd" class="control-label">
                                            <span>사용상태</span>
                                        </label>
                                        <select class="form-control" id="selEquipmentStateCd" name="selEquipmentStateCd" @change="showEquipmentList" v-model="EquipmentSelectTerm.EquipmentStateCd">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in EquipmentStateCdList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="selDateType" class="control-label">
                                            <span>구입일자</span>
                                        </label>
                                        <div class="col-xs-3">
                                            <select class="form-control" id="selDateType" @change="dateTypeChangeEvent">
                                                <option value="1" selected="selected">== 전체 ==</option>
                                                <option value="2">오늘</option>
                                                <option value="3">일주일</option>
                                                <option value="4">한달</option>
                                                <option value="5">일년</option>
                                            </select>
                                        </div>
                                        <div class="col-xs-9">
                                            <label for="txtStartDate" class="sr-only"><span>구입일자</span></label>
                                            <div class="col-2 input-group date">
                                                <DxDateBox display-format="yyyy-MM-dd"
                                                           pickerType="calendar"
                                                           v-model="EquipmentSelectTerm.StartDate"
                                                           @value-changed="showEquipmentList"
                                                           style="border-radius:0;" />
                                            </div>
                                            <div class="tail"><span>~</span></div>
                                            <label for="txtEndDate" class="sr-only"><span>구입일자</span></label>
                                            <div class="col-2 input-group date">
                                                <DxDateBox display-format="yyyy-MM-dd"
                                                           pickerType="calendar"
                                                           v-model="EquipmentSelectTerm.EndDate"
                                                           @value-changed="showEquipmentList"
                                                           style="border-radius:0;" />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </section>
                        </fieldset>
                    </form>
                </div>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">비품관리</p>
                </div>
                <div>
                    <!--리스트 영역-->
                    <section class="tbl-contents">
                        <div class="alert alert-info total">
                            <p>
                                <span class="glyphicon glyphicon-folder-close"></span>
                                전체: <span id="spTotCnt">{{EquipmentTotalCnt}}</span>건
                            </p>
                        </div>
                        <div class="table-responsive">
                            <DxDataGrid :data-source="EquipmentList"
                                        :allow-column-resizing="true"
                                        :column-auto-width="true"
                                        :row-alternation-enabled="true"
                                        :hoverStateEnabled="true"
                                        :show-borders="true"
                                        @cell-prepared="onCellPrepared"
                                        @cell-click="onShowEquipment"
                                        style="max-height: 500px; vertical-align: top;">
                                <DxScrolling mode="standard" useNative="false" />
                                <DxPaging :enabled="false" />
                                <DxColumn caption="재물No" data-field="EquipmentNo" alignment="center" />
                                <DxColumn caption="최종사용자" data-field="FinalUserNm" alignment="center" />
                                <DxColumn caption="비품구분" data-field="EquipmentDivNm" alignment="center" />
                                <DxColumn caption="모델명" data-field="ModelNm" alignment="left" />
                                <DxColumn caption="제조사" data-field="ManufacturerNm" alignment="center" />
                                <DxColumn caption="지급일자" data-field="ProvideDate" alignment="center" data-type="date" format="yyyy-MM-dd" />
                                <DxColumn caption="구입일자" data-field="PurchaseDate" alignment="center" data-type="date" format="yyyy-MM-dd" />
                                <DxColumn caption="사용상태" data-field="UseStateNm" alignment="center" />
                            </DxDataGrid>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <AC1111E ref="AC1111E"></AC1111E>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle';
    import { DxDataGrid, DxColumn, DxButton, DxScrolling, DxSorting, DxPaging } from 'devextreme-vue/data-grid';
    import DxDateBox, { DxCalendarOptions } from 'devextreme-vue/date-box';
    import AC1111E from '@/components/ac/popup/AC1111E';

    export default {
        name: 'AC1110R',
        components: {
            ViewTitle, DxDataGrid, DxColumn, DxButton, DxScrolling, DxSorting, DxPaging, DxDateBox, DxCalendarOptions, AC1111E,
        },
        data() {
            return {
                // 비품 구분 코드
                EquipmentDivCdList: new Array(),
                // 비품 사용상태 코드
                EquipmentStateCdList: new Array(),
                // 비품관리 조회 조건
                EquipmentSelectTerm: {
                    Arg: 'L',
                    StartDate: '',
                    EndDate: '',
                    EquipmentDivCd: '',
                    EquipmentStateCd: '',
                },
                // 비품관리 목록
                EquipmentList: new Array(),
                // 비품관리 목록 전체 건수
                EquipmentTotalCnt: 0,
            }
        },
        beforeMount() {

            this.EquipmentDivCdList = $.scriptBase.getCommonCode('CODE000', '0801000', '', '');
            this.EquipmentStateCdList = $.scriptBase.getCommonCode('CODE000', '0802000', '', '');
        },
        mounted() {

            this.showEquipmentList();
        },
        methods: {

            // DataGrid Header 가운데 정렬, Column Data 중앙 정렬
            onCellPrepared(e) {

                if (e.rowType == 'header') {
                    e.cellElement.style['textAlign'] = 'center';
                }
                if (e.rowType == 'data') {
                    e.cellElement.style['verticalAlign'] = 'middle';
                }
            },
            // 구입일자 날짜 타입 변경 이벤트
            dateTypeChangeEvent: function (event) {

                let dateType = Number(event.target.value);

                switch (dateType) {
                    case 1: {

                        this.EquipmentSelectTerm.StartDate = '';
                        this.EquipmentSelectTerm.EndDate = '';
                        break;
                    }
                    case 2: {

                        this.EquipmentSelectTerm.StartDate = this.moment(new Date()).format('YYYY-MM-DD');
                        this.EquipmentSelectTerm.EndDate = this.moment(new Date()).format('YYYY-MM-DD');
                        break;
                    }
                    case 3: {

                        this.EquipmentSelectTerm.StartDate = this.moment(new Date()).add(-7, 'days').format('YYYY-MM-DD');
                        this.EquipmentSelectTerm.EndDate = this.moment(new Date()).format('YYYY-MM-DD');
                        break;
                    }
                    case 4: {

                        this.EquipmentSelectTerm.StartDate = this.moment(new Date()).add(-1, 'months').format('YYYY-MM-DD');
                        this.EquipmentSelectTerm.EndDate = this.moment(new Date()).format('YYYY-MM-DD');
                        break;
                    }
                    case 5: {

                        this.EquipmentSelectTerm.StartDate = this.moment(new Date()).add(-1, 'years').format('YYYY-MM-DD');
                        this.EquipmentSelectTerm.EndDate = this.moment(new Date()).format('YYYY-MM-DD');
                        break;
                    }
                    default:
                }
            },
            // 비품관리 목록 조회
            showEquipmentList() {

                var parent = this;

                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/SelectEquipment'.toRealServiceUrl();
                var obj = new Object();

                this.EquipmentSelectTerm.StartDate = (this.EquipmentSelectTerm.StartDate != '') ? this.moment(this.EquipmentSelectTerm.StartDate).format('YYYYMMDD') : '';
                this.EquipmentSelectTerm.EndDate = (this.EquipmentSelectTerm.EndDate != '') ? this.moment(this.EquipmentSelectTerm.EndDate).format('YYYYMMDD') : '';

                obj.jsonString = JSON.stringify(this.EquipmentSelectTerm);

                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d);
                    var table = jsonData.Table;
                    var table1 = jsonData.Table1;

                    // 비품관리 목록
                    parent.EquipmentList = (table.length > 0) ? table : new Array();
                    // 비품 목록 전체 건수
                    parent.EquipmentTotalCnt = (table1.length > 0) ? table1[0].TotCnt : 0;
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 비품관리 신규 입력
            onAddEquipment() {

                var parent = this;

                var callbackFunction = function () {

                    parent.showEquipmentList();
                }
                this.$refs.AC1111E.open(callbackFunction);
            },
            // 비품 상세 조회
            onShowEquipment(e) {

                if (e.rowType == 'data') {

                    var parent = this;

                    var callbackFunction = function () {

                        parent.showEquipmentList();
                    }
                    this.$refs.AC1111E.open(callbackFunction, e.data);
                }
            },
        }
    }
</script>