
var _pager_ = {};
var pager_name;
(function ($) {


    $.fn.pager = function (callSetting) {

        var pg = new pager_();
        pg.createpager(callSetting);
    }

    var pager_ = function () {
        this.settings;
        this.iLoop = 1;
        this.iLoopEnd = 10;
        this.pageCount = 0;
    }

    pager_.prototype.createpager = function(callSetting) {
        this.settings = $.extend({
            pager: "#pager",
            pagerMode: "text",
            totalItemCount: 100,
            recordPerPage: 10,
            currentPage: 1,
            pageIndexChanged: "",
            prev10Text: "<<",
            next10Text: ">>",
            selectedPageFontColor: "lightblue"
        }, callSetting || {});

        this.settings.pager = $(this.settings.pager);

        showPager(this.settings);
    }
    //�Է¹��� callsetting������ pager��ü ����

 
    //pager_.prototype.refresh = function (totalItemCount, recordPerPage) {
    //    this.settings.totalItemCount = totalItemCount;
    //    this.settings.recordPerPage = recordPerPage;

    //    showPager();
    //    return pageCount;
    //}
    //pageī��Ʈ�ΰͰ����� ȣ���ϴ°�..?

    var showPager = function (settings) {
        var pageCount = Math.ceil(settings.totalItemCount / settings.recordPerPage);
        var pageLink;

        settings.pager.empty();

        var baseVal = settings.currentPage - 1;
        var iLoop = (Math.floor(baseVal / 10) * 10) + 1;
        var iLoopEnd = (Math.floor(baseVal / 10) + 1) * 10;

        if (iLoopEnd > pageCount) iLoopEnd = pageCount;

        for (var page = iLoop; page <= iLoopEnd; page++) {

            //���� �������� �ƴ� ��� Ŭ�� �̺�Ʈ�� �ɾ��ش�
            if (page != settings.currentPage) {
                pageLink = $("<li style=\"cursor:pointer;\"><a> " + page + " </a></li>");
                pageLink.click(function() {  //������ ��ȣ�� Ŭ���ϴ� ����� ó��
                        settings.currentPage = parseInt($(this).text());
                        PageClick(settings);
                    });
            } else {
                //���� �������� Ŭ�� �̺�Ʈ�� ���� �ʰ�, ��Ÿ�ϸ� �ٸ��� �Ѵ�
                pageLink = $("<li class=\"active\"><a>" + page + " </a></li>");
            } 

            settings.pager.append(pageLink);
        }


        var prev10UniqueId = settings.pager.attr("id") + "_prev10";
        var next10UniqueId = settings.pager.attr("id") + "_next10";

        //���� 10��, ���� 10���� ���� ����
        settings.pager.prepend("<li id='" + prev10UniqueId + "' style=\"cursor:pointer;\"></li>");
        settings.pager.append("<li id='" + next10UniqueId + "' style=\"cursor:pointer;\"></li>");

        var $prev10Span = settings.pager.find("#" + prev10UniqueId);
        var $next10Span = settings.pager.find("#" + next10UniqueId);

        var $prev10;
        var $next10;

        //���� 10 ������ ���� ó��
        if (settings.currentPage > 10) {
            if (settings.pagerMode == "text") {
                $prev10 = $("<a>" + settings.prev10Text + "</a>");
                $prev10.click(function () {
                    GoPrev10(settings, iLoop, iLoopEnd);
                });
                
            }
            $prev10Span.append($prev10);
        } 

        //���� 10 ������ ���� ó��
        if (pageCount > iLoopEnd ) {
            if (settings.pagerMode == "text") {
                $next10 = $("<a>" + settings.next10Text + "</a>");
                $next10.click(function () {  
                    GoNext10(settings, iLoop, iLoopEnd);
                });
            }

            $next10Span.append($next10);
        } 
    }

    var PageClick = function (settings) {

        //�̺�Ʈ ����;
        settings.pageIndexChanged(settings.currentPage); //������ Ŭ�� �̺�Ʈ ȣ��
        showPager(settings);
    }

    var GoPrev10 = function (settings, iLoop, iLoopEnd) {
      var iLoop_ = iLoop - 10;
      var iLoopEnd_ = iLoopEnd - 10;
      iLoopEnd_ = Math.ceil(iLoopEnd_ / 10) * 10; //���������� �ƴ϶��, ������ end ���� 10�� ����� �����
      if (iLoop_ < 1) iLoop_ = 1;
      if (iLoopEnd_ < 10) iLoopEnd_ = 10;
        settings.currentPage = iLoop_;

        showPager(settings);
        //�̺�Ʈ ����;
        settings.pageIndexChanged(settings.currentPage); //������ Ŭ�� �̺�Ʈ ȣ��
    }

    var GoNext10 = function (settings, iLoop, iLoopEnd) {
        var iLoops_ = iLoop +  10;
        var iLoopEnds_ = iLoopEnd +  10;
        if (iLoopEnds_ > settings.pageCount) iLoopEnds_ = settings.pageCount;
        settings.currentPage = iLoops_;

        showPager(settings);
        //�̺�Ʈ ����;
        settings.pageIndexChanged(settings.currentPage); //������ Ŭ�� �̺�Ʈ ȣ��
    }

})(jQuery);