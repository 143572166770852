<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a v-show="this.$parent.CanSearch" href="#" id="btnListShow" class="btn btn-primary btn-row2" @click.prevent="fn_ShowData">
                    <span>
                        <i class="glyphicon glyphicon-search"></i>조회
                    </span>
                </a>
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="sch3-1" class="control-label">
                                            <span>시스템</span>
                                        </label>
                                        <select class="form-control" id="selSystId" name="selSystId">
                                            <option v-for="item in SystemIdList" v-bind:value="item.Code">{{item.Name}}</option>
                                        </select>
                                    </div>
                                </section>
                            </section>
                        </fieldset>
                    </form>
                    </div>
                </div>
                <div class="grid-wrap">
                    <div class="sub-title">
                        <p class="sub-title-txt">사용자 매핑</p>
                    </div>
                    <div>
                        <!--리스트 영역-->
                        <article class="row tbl-option-wrap">
                            <div class="col-xs-12 col-sm-8 tbl-option-left-wrap">
                                <div class="col-xs-12 col-sm-6 pad-l0">
                                    <h4 class="add-tlt">
                                        대상 그룹 (<span id="spTotCnt0">{{UserGroupListTotalCount}}</span>건)
                                    </h4>
                                    <div class="table-responsive-always tbl-6-width table-responsive-height">
                                        <table class="table tbl-list table-hover table-bordered" id="tb0">
                                            <caption>기본 테이블</caption>
                                            <colgroup>
                                                <col />
                                                <col />
                                                <col />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th class="text-center">선택</th>
                                                    <th class="text-center">그룹ID</th>
                                                    <th class="text-center">그룹명</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="UserGroupList === null">
                                                    <td colspan="3" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                </tr>
                                                <tr v-else v-for="item in UserGroupList">
                                                    <td class="text-center">
                                                        <label for="cb0" class="hidden">선택</label>
                                                        <input name="cb0" type="radio"
                                                               v-on:change="targetUserGroupChangeEvent"
                                                               v-bind:checked="TargetUserGroupId === item.UserGrpId"
                                                               :corpcd="item.CorpCd"
                                                               :systid="item.SystId"
                                                               :usergrpid="item.UserGrpId" />
                                                    </td>
                                                    <td class="text-center">{{item.UserGrpId}}</td>
                                                    <td class="text-center">{{item.UserGrpName}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 pad-r0">
                                    <h4 class="add-tlt">
                                        등록 사용자 (<span id="spTotCnt1">{{RegisteredUserListTotalCount}}</span>건)
                                    </h4>
                                    <div class="tbl-6-col">
                                        <div class="table-responsive-always table-responsive-height">
                                            <table class="table tbl-list table-hover table-bordered" id="tb1">
                                                <caption>기본 테이블</caption>
                                                <colgroup>
                                                    <col />
                                                    <col />
                                                    <col />
                                                    <col />
                                                </colgroup>
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">
                                                            <label for="cbAll1" class="hidden">전체선택</label>
                                                            <input name="cbAll1" type="checkbox" @click="checkedAllEvent($event.target.checked, 'cb1')">
                                                        </th>
                                                        <th class="text-center">사용자ID</th>
                                                        <th class="text-center">사용자명</th>
                                                        <th class="text-center">직급</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr name="nodata-row" v-if="RegisteredUserList === null">
                                                        <td colspan="4" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                    </tr>
                                                    <tr name="data-row" v-else v-for="item in RegisteredUserList">
                                                        <td class="text-center">
                                                            <label for="cb1" class="hidden">선택</label>
                                                            <input name="cb1" type="checkbox"
                                                                   :corpcd="item.CorpCd"
                                                                   :systid="item.SystId"
                                                                   :usergrpid="item.UserGrpId"
                                                                   :empno="item.EmpNo" />
                                                        </td>
                                                        <td class="text-center">{{item.EmpNo}}</td>
                                                        <td class="text-center">{{item.EmpNm}}</td>
                                                        <td class="text-center">{{item.JobGradeNm}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <!-- 버튼영역-->
                                <div v-if="this.$parent.CanSave" class="tbl-option-btn-wrap">
                                    <a id="btnInstData" href="#" class="btn btn-default" @click.prevent="fn_InstData">
                                        <span class="glyphicon glyphicon-chevron-left"></span>
                                    </a>
                                    <a id="btnRmveData" href="#" class="btn btn-default" @click.prevent="fn_RmveData">
                                        <span class="glyphicon glyphicon-chevron-right"></span>
                                    </a>
                                </div>
                                <!-- //버튼영역 -->
                            </div>
                            <div class="col-xs-12 col-sm-4 tbl-option-right-wrap">
                                <h4 class="add-tlt">
                                    미등록 사용자 (<span id="spTotCnt2">{{UnregisteredUserListTotalCount}}</span>건)
                                </h4>
                                <div class="table-responsive-always tbl-6-width2 table-responsive-height">
                                    <table class="table tbl-list table-hover table-bordered" id="tb2">
                                        <caption>기본 테이블</caption>
                                        <colgroup>
                                            <col />
                                            <col />
                                            <col />
                                            <col />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th class="text-center">
                                                    <label for="cbAll2" class="hidden">전체선택</label>
                                                    <input name="cbAll2" type="checkbox" @click="checkedAllEvent($event.target.checked, 'cb2')">
                                                </th>
                                                <th class="text-center">사용자ID</th>
                                                <th class="text-center">사용자명</th>
                                                <th class="text-center">직급</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="UnregisteredUserList === null">
                                                <td colspan="4" class="text-center no-data">조회된 정보가 없습니다.</td>
                                            </tr>
                                            <tr v-else v-for="item in UnregisteredUserList">
                                                <td class="text-center">
                                                    <label for="cb2" class="hidden">선택</label>
                                                    <input name="cb2" type="checkbox"
                                                           :corpcd="item.CorpCd"
                                                           :empno="item.EmpNo">
                                                </td>
                                                <td class="text-center">{{item.EmpNo}}</td>
                                                <td class="text-center">{{item.EmpNm}}</td>
                                                <td class="text-center">{{item.JobGradeNm}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle'
    export default {
        name: 'ZZ1070R',
        components: {
            ViewTitle
        },
        data() {
            return {
                SystemIdList: null,
                UserGroupList: null,
                RegisteredUserList: null,
                UnregisteredUserList: null,
                TargetUserGroupId: null,
                TargetUserGroupSystemId: null,
                TargetUserGroupCompanyCode: null,
                UserGroupListTotalCount: 0,
                RegisteredUserListTotalCount: 0,
                UnregisteredUserListTotalCount:0
            }
        },
        beforeMount() {

            // 공통코드 조회
            this.SystemIdList = $.scriptBase.getCommonCode('CODE090', this.$parent.CompanyCode, '', '')
        },
        mounted() {

            if (this.$parent.CanSearch) {

                this.fn_ShowData()
            }
        },
        methods: {
            checkedAllEvent: function (isChecked, targetElementName) {

                document.getElementsByName(targetElementName).forEach(function (item) {

                    item.checked = isChecked
                })

                if (targetElementName === 'cb1') {

                    document.getElementsByName('cbAll1').forEach(function (item) {

                        item.checked = isChecked
                    })
                }
                else if (targetElementName === 'cb2') {

                    document.getElementsByName('cbAll2').forEach(function (item) {

                        item.checked = isChecked
                    })
                }
            },
            targetUserGroupChangeEvent(e) {

                var target = e.target;

                this.TargetUserGroupId = target.getAttribute('usergrpid')
                this.TargetUserGroupSystemId = target.getAttribute('systid')
                this.TargetUserGroupCompanyCode = target.getAttribute('corpcd')
                this.fn_ShowChld()
            },
            fn_ShowChld: function () {

                var parent = this
                var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/GetUserMatrix'
                url = url.toRealServiceUrl()
                var obj = new Object();
                obj.prm = new Array();
                obj.prm.push("S2");
                obj.prm.push(this.$parent.EmpId);
                obj.prm.push(this.TargetUserGroupCompanyCode);
                obj.prm.push(this.TargetUserGroupSystemId);
                obj.prm.push(this.TargetUserGroupId);
                obj.prm.push("");

                var successCallback = function (data) {

                    var table = JSON.parse(data.d).Table;
                    var table1 = JSON.parse(data.d).Table1;

                    if (table.length > 0) {

                        parent.RegisteredUserList = table
                        parent.RegisteredUserListTotalCount = table.length
                    }
                    else {

                        parent.RegisteredUserList = null
                        parent.RegisteredUserListTotalCount = 0
                    }

                    if (table1.length > 0) {

                        parent.UnregisteredUserList = table1
                        parent.UnregisteredUserListTotalCount = table1.length
                    }
                    else {

                        parent.UnregisteredUserList = null
                        parent.UnregisteredUserListTotalCount = 0
                    }

                    parent.checkedAllEvent(false, 'cb1')
                    parent.checkedAllEvent(false, 'cb2')
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            fn_ShowData: function () {

                var parent = this
                var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/GetUserMatrix'
                url = url.toRealServiceUrl()
                var obj = new Object();
                obj.prm = new Array();
                obj.prm.push("S1");
                obj.prm.push(this.$parent.EmpId);
                obj.prm.push(this.$parent.CompanyCode);
                obj.prm.push($("#selSystId").val());
                obj.prm.push("");
                obj.prm.push("");

                var successCallback = function (data) {

                    var table = JSON.parse(data.d).Table;
                    var table1 = JSON.parse(data.d).Table1;
                    var table2 = JSON.parse(data.d).Table2;

                    if (table.length > 0) {

                        parent.UserGroupList = table
                        parent.TargetUserGroupId = table[0].UserGrpId
                        parent.TargetUserGroupSystemId = table[0].SystId
                        parent.TargetUserGroupCompanyCode = table[0].CorpCd
                        parent.UserGroupListTotalCount = table.length
                    }
                    else {

                        parent.UserGroupList = null
                        parent.TargetUserGroupId = null
                        parent.TargetUserGroupSystemId = null
                        parent.TargetUserGroupCompanyCode = null
                        parent.UserGroupListTotalCount = 0
                    }

                    if (table1.length > 0) {

                        parent.RegisteredUserList = table1
                        parent.RegisteredUserListTotalCount = table1.length
                    }
                    else {

                        parent.RegisteredUserList = null
                        parent.RegisteredUserListTotalCount = 0
                    }

                    if (table2.length > 0) {

                        parent.UnregisteredUserList = table2
                        parent.UnregisteredUserListTotalCount = table2.length
                    }
                    else {

                        parent.UnregisteredUserList = null
                        parent.UnregisteredUserListTotalCount = 0
                    }

                    parent.checkedAllEvent(false, 'cb1')
                    parent.checkedAllEvent(false, 'cb2')
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            fn_InstData: function () {

                var parent = this
                var targetElement = document.querySelectorAll('input[type=checkbox][name="cb2"]:checked')

                if (this.TargetUserGroupId === null) {

                    alert('대상 그룹을 선택하세요.')
                    return
                }

                if (targetElement.length === 0) {

                    alert("미등록 사용자를 선택하세요.")
                    return
                }

                var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/ManipulateUserMatrix'
                url = url.toRealServiceUrl()
                var obj = new Object();
                obj.prm = new Array();

                targetElement.forEach(function (target) {

                    var tempArray = new Array()
                    tempArray.push('I')
                    tempArray.push(parent.$parent.EmpId)
                    tempArray.push(parent.TargetUserGroupCompanyCode)
                    tempArray.push(parent.TargetUserGroupSystemId)
                    tempArray.push(parent.TargetUserGroupId)
                    tempArray.push(target.getAttribute('empno'))
                    obj.prm.push(tempArray)
                })

                var successcallback = function (data) {

                    if (data.d > 0) {

                        parent.fn_ShowChld()
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successcallback);
            },
            fn_RmveData: function () {

                var parent = this
                var targetElement = document.querySelectorAll('input[type=checkbox][name=cb1]:checked')

                if (this.TargetUserGroupId === null) {

                    alert("대상 그룹을 선택하세요.")
                    return
                }

                if (targetElement.length === 0) {

                    alert("삭제 할 사용자를 선택하세요.")
                    return
                }

                var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/ManipulateUserMatrix'
                url = url.toRealServiceUrl()
                var obj = new Object();
                obj.prm = new Array();

                targetElement.forEach(function (target) {

                    var tempArray = new Array();
                    tempArray.push('D')
                    tempArray.push(parent.$parent.EmpId)
                    tempArray.push(parent.TargetUserGroupCompanyCode)
                    tempArray.push(parent.TargetUserGroupSystemId)
                    tempArray.push(parent.TargetUserGroupId)
                    tempArray.push(target.getAttribute('empno'))
                    obj.prm.push(tempArray)
                })

                var successCallback = function (data) {

                    if (data.d > 0) {

                        parent.fn_ShowChld();
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            }
        }
    }
</script>

<style scoped>
</style>