<template>
    <div class="modal fade" id="HM1002E_Modal" ref="HM1002E_Modal" tabindex="-1" role="dialog" aria-labelledby="HM1002E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" @click.Prevent="close">
                        <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                    </button>
                    <h3 class="modal-title">비밀번호 초기화</h3>
                </div>
                <div class="modal-body">
                    <section class="add-wrap">
                        <form>
                            <fieldset>
                                <section class="cols-wrap">
                                    <div class="cols col-1">
                                        <label for="empEmailAddr" class="control-label"><span>사원 E-Mail</span></label>
                                        <input type="text" id="empEmailAddr" class="form-control" v-model="EmpEmail" autocomplete="username" readonly />
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-1">
                                        <label for="newPassword" class="control-label"><span>새 비밀번호</span></label>
                                        <input type="password" id="newPassword" class="form-control" v-model="EmpNewPassword" autocomplete="new-password"
                                         minlength="7" maxlength="15" required />
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-1">
                                        <label for="checkNewPassword" class="control-label"><span>비밀번호 확인</span></label>
                                        <input type="password" id="checkNewPassword" class="form-control" v-model="EmpNewPasswordCheck" autocomplete="new-password"
                                         minlength="7" maxlength="15" required />
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <br/>
                                    <label v-show="PasswordValidYN == 'Y'" id="txtHelpReset1" class="control-label" style="color: #3e65a7;">
                                        <span class="glyphicon glyphicon-ok-sign"></span> 비밀번호가 일치하며 유효합니다.
                                    </label>
                                    <label v-show="PasswordValidYN == 'N'" id="txtHelpReset2" class="control-label" style="color: #d9534f;">
                                        <span class="glyphicon glyphicon-remove-sign"></span> 비밀번호가 일치하지 않습니다.
                                    </label>
                                    <label v-show="PasswordValidYN == 'ERR'" id="txtHelpReset3" class="control-label" style="color: #d9534f;">
                                        <span class="glyphicon glyphicon-remove-sign"></span> 비밀번호는 최소 7자 ~ 최대 15자여야 하며, <br/>&nbsp;&nbsp;&nbsp;&nbsp;영문(대소문자 구분), 숫자, 특수문자(!@$%^&*)를 포함해야 합니다.
                                    </label>
                                </section>
                            </fieldset>
                        </form>
                    </section>
                </div>
                <div class="modal-footer">
                    <section class="btn-align">
                        <div class="btn-group">
                            <button type="button" class="btn btn-primary btn-sm" @click.prevent="onSavePassword" v-bind:disabled="PasswordValidYN != 'Y'">
                                <span class="glyphicon glyphicon-save"></span> 저장
                            </button>
                            <button type="button" class="btn btn-danger btn-sm" @click.Prevent="close">
                                <span class="glyphicon glyphicon-remove"></span>취소
                            </button>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'HM1002E',
        data() {
            return {
                CallbackFunction: null,
                EmpEmail: '',
                EmpNewPassword: '',
                EmpNewPasswordCheck: '',
                EmpId: this.$store.getters.getEmpId,
                PasswordValidYN: '',
            }
        },
        watch: {
            // 새 비밀번호
            'EmpNewPassword'(newData) {

                if (newData != '') {

                    let passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*?_]).{7,15}$/;

                    if (passwordRegex.test(newData)) {

                        if (newData == this.EmpNewPasswordCheck) {
                        
                            this.PasswordValidYN = 'Y';
                        } else {
                        
                            this.PasswordValidYN = 'N';
                        }
                    } else {

                        this.PasswordValidYN = 'ERR';
                    }
                } else {

                    this.PasswordValidYN = '';
                }
            },
            // 비밀번호 확인
            'EmpNewPasswordCheck'(newData) {

                if (newData != '' && newData == this.EmpNewPassword) {

                    this.PasswordValidYN = 'Y';
                } else if (newData != this.EmpNewPassword) {

                    this.PasswordValidYN = 'N';
                } else {

                    this.PasswordValidYN = '';
                }
            }
        },
        methods: {
            open(callbackFunction, email) {

                this.CallbackFunction = callbackFunction;
                this.EmpEmail = email;
                this.EmpNewPassword = '';
                this.EmpNewPasswordCheck = '';
                this.PasswordValidYN = '';
                this.EmpId = this.$store.getters.getEmpId;

                $("#HM1002E_Modal").modal("show");
            },
            close() {

                $("#HM1002E_Modal").modal("hide");
            },
            // 저장 클릭, 비밀번호 변경 메소드
            onSavePassword() {

                let parent = this;
                
                let url = '/Mini.Service.IBS.ZZ.Service/Authentication.svc/ajax/ModifyActiveDirectoryPassword'.toRealServiceUrl();
                let obj = new Object();
                obj.prm = new Array();

                obj.prm.push(this.EmpEmail);
                obj.prm.push(this.EmpNewPassword);

                var successCallback = function (data) {
                    
                    if (data.d > 0) {

                        alert('비밀번호가 변경되었습니다.');
                        parent.close();
                    } else {

                        alert('비밀번호 변경에 실패했습니다. 잠시 후 다시 시도해 주십시오.');
                        return;
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
        }
    }
</script>