<template>
    <!--일괄입력 모달 팝업-->
    <div class="modal fade" id="AC1141E_Modal" ref="AC1141E_Modal" tabindex="-1" role="dialog" aria-labelledby="AC1141E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" @click.prevent="close">
                        <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                    </button>
                    <h3 class="modal-title">예상입출 일괄입력</h3>
                </div>
                <div class="modal-body">
                    <section class="add-wrap">
                        <form>
                            <fieldset>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtTradeCont" class="control-label">
                                            <span><i class="glyphicon glyphicon-asterisk"></i>거래내용</span>
                                        </label>
                                        <input type="text" id="txtTradeCont" class="form-control txt" v-model="EstInOutInsertTerm.TradeCont" />
                                    </div>
                                    <div class="cols col-2">
                                        <label for="selAcntSubjtCd" class="control-label">
                                            <span><i class="glyphicon glyphicon-asterisk"></i>계정과목</span>
                                        </label>
                                        <select class="form-control" id="selAcntSubjtCd" v-model="EstInOutInsertTerm.AcntSubjtCd">
                                            <option value="">== 선택 ==</option>
                                            <option v-for="item in AcntSubjtCodeList" v-bind:value="item.Code">{{ item.Name }}</option>
                                        </select>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtInAmt" class="control-label">
                                            <span><i class="glyphicon glyphicon-asterisk"></i>입금액</span>
                                        </label>
                                        <input type="text" id="txtInAmt" class="form-control text-right" v-model="EstInOutInsertTerm.InAmt" @keyup="inputPrice($event, 'InAmt')" />
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtOutAmt" class="control-label">
                                            <span><i class="glyphicon glyphicon-asterisk"></i>출금액</span>
                                        </label>
                                        <input type="text" id="txtOutAmt" class="form-control text-right" v-model="EstInOutInsertTerm.OutAmt" @keyup="inputPrice($event, 'OutAmt')" />
                                    </div>
                                </section>
                                <!--빈공백-->
                                <section class="cols-wrap">
                                    <div class="cols">
                                        <label></label>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-1">
                                        <label class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>예상거래일자
                                            </span>
                                        </label>
                                        <div class="col-xs-7">
                                            <div class="col-2 input-group date">
                                                <DxDateBox display-format="yyyy-MM"
                                                           pickerType="calendar"
                                                           v-model="BatchCalculateTerm.BatchTradeStart"
                                                           style="border-radius:0;">
                                                    <DxCalendarOptions maxZoomLevel="year" />
                                                </DxDateBox>
                                            </div>
                                            <div class="tail"><span>~</span></div>
                                            <div class="col-2 input-group date">
                                                <DxDateBox display-format="yyyy-MM"
                                                           pickerType="calendar"
                                                           v-model="BatchCalculateTerm.BatchTradeEnd"
                                                           style="border-radius:0;">
                                                    <DxCalendarOptions maxZoomLevel="year" />
                                                </DxDateBox>
                                            </div>
                                        </div>
                                        <div class="col-xs-3">
                                            <select class="form-control" id="selBatchDay" ref="selBatchDay" name="selBatchDay" v-model="BatchCalculateTerm.BatchTradeDay">
                                                <option value="">== 일자 ==</option>
                                                <option v-for="date in TargetDate" v-bind:value="date">{{ date }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-1">
                                        <label for="txtEstInOutMemo" class="control-label"><span>메모</span></label>
                                        <textarea id="txtEstInOutMemo" class="form-control" v-model="EstInOutInsertTerm.EstInOutMemo" />
                                    </div>
                                </section>
                            </fieldset>
                        </form>
                    </section>
                </div>
                <div class="modal-footer">
                    <section class="btn-align">
                        <div class="btn-group">
                            <button type="button" id="btnItemSave" class="btn btn-primary" @click.prevent="onSaveEstInOutInBatches">
                                <i class="glyphicon glyphicon-save"></i> 저장
                            </button>
                        </div>
                        <div class="btn-group">
                            <button type="button" class="btn btn-danger" data-dismiss="modal" @click.prevent="close">
                                <i class="glyphicon glyphicon-remove"></i> 취소
                            </button>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { DxDateBox, DxCalendarOptions } from 'devextreme-vue/date-box';

    export default {
        name: 'AC1141E',
        components: {
            DxDateBox, DxCalendarOptions
        },
        data() {
            return {
                CallbackFunction: null,
                // 예상입출 객체
                EstInOutInsertTerm: {
                    Arg: 'I',
                    EstInOutSeq: null,
                    TradeCont: '',
                    OutAmt: 0,
                    InAmt: 0,
                    AcntSubjtCd: '',
                    TradeYMDStart: null,
                    TradeYMDEnd: null,
                    ExeTradeYMD: null,
                    InOutConfirmYN: 'N',
                    EstInOutMemo: '',
                    UserId: this.$store.getters.getEmpId,
                },
                // 계정과목 코드
                AcntSubjtCodeList: new Array(),
                // 예상거래일자 계산 조건
                BatchCalculateTerm: {
                    BatchTradeStart: null,
                    BatchTradeEnd: null,
                    BatchTradeDay: '',
                },
                // 일자 선택 조건
                TargetDate: new Array(),
            }
        },
        beforeMount() {

            this.AcntSubjtCodeList = $.scriptBase.getCommonCode('CODE000', '0521000', '', '');

            this.TargetDate.push('월말');

            for (let i = 0; i < 32; i++) {

                let targetDate = (i < 10) ? ('0' + i).toString() : i.toString();
                this.TargetDate.push(targetDate);
            }
        },
        methods: {
            open(callbackFunction) {

                this.CallbackFunction = callbackFunction;

                $("#AC1141E_Modal").modal("show");
            },
            close() {

                this.CallbackFunction();
                this.EstInOutInsertTerm = {
                    Arg: 'I',
                    EstInOutSeq: null,
                    TradeCont: '',
                    OutAmt: 0,
                    InAmt: 0,
                    AcntSubjtCd: '',
                    TradeYMDStart: null,
                    TradeYMDEnd: null,
                    ExeTradeYMD: null,
                    InOutConfirmYN: 'N',
                    EstInOutMemo: '',
                    UserId: this.$store.getters.getEmpId,
                };

                this.BatchCalculateTerm = {
                    BatchTradeStart: null,
                    BatchTradeEnd: null,
                    BatchTradeDay: '',
                };

                $("#AC1141E_Modal").modal("hide");
            },
            // 금액 입력 필드 숫자만 입력
            inputPrice(e, obj) {

                let value = e.target._value;

                value = Number(value.replaceAll(/[^0-9]/g, ''));
                this.EstInOutInsertTerm[obj] = value.toLocaleString('ko-KR');
            },
            // 일괄등록 저장 메소드
            onSaveEstInOutInBatches() {

                if (this.EstInOutInsertTerm.TradeCont == '' || this.EstInOutInsertTerm.TradeCont == null) {

                    alert('거래내용을 입력해 주십시오.');
                    $('#txtTradeCont').focus();
                    return;
                }

                if (this.EstInOutInsertTerm.AcntSubjtCd == '' || this.EstInOutInsertTerm.AcntSubjtCd == null) {

                    alert('계정과목을 선택해 주십시오.');
                    $('#selAcntSubjtCd').focus();
                    return;
                }

                if (this.EstInOutInsertTerm.InAmt == 0 && this.EstInOutInsertTerm.OutAmt == 0) {

                    alert('입금액 또는 출금액을 입력해 주십시오.');
                    $('#txtInAmt').focus();
                    return;
                }

                if (this.BatchCalculateTerm.BatchTradeStart == '' || this.BatchCalculateTerm.BatchTradeStart == null || this.BatchCalculateTerm.BatchTradeEnd == '' || this.BatchCalculateTerm.BatchTradeEnd == null || this.BatchCalculateTerm.BatchTradeDay == '' || this.BatchCalculateTerm.BatchTradeDay == null) {

                    alert('예상거래일자를 입력해 주십시오.');
                    return;
                }

                let year1 = this.moment(this.BatchCalculateTerm.BatchTradeStart).format('YYYY');
                let month1 = this.moment(this.BatchCalculateTerm.BatchTradeStart).format('YYYYMM').substr(4, 2);

                // 예상거래일자 날짜 세팅
                if (this.BatchCalculateTerm.BatchTradeDay == '월말') {

                    this.EstInOutInsertTerm.TradeYMDStart = this.moment(new Date(year1, month1, 0)).format('YYYYMMDD');
                    this.EstInOutInsertTerm.TradeYMDEnd = this.moment(this.BatchCalculateTerm.BatchTradeEnd).format('YYYYMM') + this.moment(this.EstInOutInsertTerm.TradeYMDStart).format('YYYYMMDD').substr(6, 2);
                } else {

                    this.EstInOutInsertTerm.TradeYMDStart = this.moment(this.BatchCalculateTerm.BatchTradeStart).format('YYYYMM') + this.BatchCalculateTerm.BatchTradeDay;
                    this.EstInOutInsertTerm.TradeYMDEnd = this.moment(this.BatchCalculateTerm.BatchTradeEnd).format('YYYYMM') + this.BatchCalculateTerm.BatchTradeDay;
                }

                if (this.EstInOutInsertTerm.TradeYMDStart > this.EstInOutInsertTerm.TradeYMDEnd) {

                    alert("예상거래일의 시작월은 종료월보다 클 수 없습니다.");
                    return;
                }

                this.EstInOutInsertTerm.InAmt = (this.EstInOutInsertTerm.InAmt == 0) ? 0 : Number(this.EstInOutInsertTerm.InAmt.toNumber());
                this.EstInOutInsertTerm.OutAmt = (this.EstInOutInsertTerm.OutAmt == 0) ? 0 : Number(this.EstInOutInsertTerm.OutAmt.toNumber());

                let EstInOutInsertArray = new Array();
                EstInOutInsertArray.push(this.EstInOutInsertTerm);

                let parent = this;
                let url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/ManipulateEstimatedInOut'.toRealServiceUrl();
                let obj = new Object();
                obj.jsonString = JSON.stringify(EstInOutInsertArray);

                var successCallback = function (data) {

                    if (data.d > 0) {

                        alert('저장되었습니다.');
                        parent.close();
                    } else {

                        alert('저장에 실패했습니다. 잠시 후 다시 시도해 주십시오.');
                        return;
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            }
        }
    }
</script>