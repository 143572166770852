<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <article class="tbl-contents tbl-board">
                <div>
                    <div class="sub-title">
                        <p class="sub-title-txt">공지사항 게시판</p>
                    </div>
                    <!--리스트 영역-->
                    <div class="alert alert-info alert-only total">
                        <p><i class="glyphicon glyphicon-folder-close"></i><span>전체: <span id="spTotCnt">{{NoticeListTotalCount}}</span>건</span></p>
                    </div>
                    <div class="table-responsive">
                        <table class="table table tbl-list tbl-center table-hover table-fixed table-board" id="boardList">
                            <caption>테이블</caption>
                            <colgroup>
                                <col class="number-width" />
                                <col>
                                <col class="name-width" />
                                <col class="date-width" />
                                <col class="hit-width" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th scope="col"><p class="tbl-line">no</p></th>
                                    <th scope="col"><p class="tbl-line">제목</p></th>
                                    <th scope="col"><p class="tbl-line">작성자</p></th>
                                    <th scope="col"><p class="tbl-line">작성일</p></th>
                                    <th scope="col"><p>조회수</p></th>
                                </tr>
                            </thead>
                            <tbody id="result">
                                <tr v-if="NoticeList === null">
                                    <td colspan="5" class="text-center no-data">조회된 정보가 없습니다.</td>
                                </tr>
                                <tr v-else v-for="item in NoticeList" style="cursor:pointer;" v-on:click="rowClickEvent">
                                    <td name="boardseqno">{{item.BoardSeqNo}}</td>
                                    <!--<td class="tbl-sub"><a href="#" data-toggle="modal">{{html fn_BoardSetting(BoardTitle, RegtDate, BoardSeqNo)}}</a></td>-->
                                    <td name="boardtitle">{{item.BoardTitle}}</td>
                                    <td name="regtempname">{{item.RegtEmpName}}</td><!--style="text-align:left;"-->
                                    <td name="regtdate">{{item.RegtDate}}</td><!--style="text-align:left;"-->
                                    <td name="readcnt">{{item.ReadCnt}}</td>
                                    <td name="company-code" style="display:none">{{item.CorpCd}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </article>
            <article>
                <form>
                    <fieldset>
                        <legend>검색폼</legend>
                        <section>
                            <div class="tbl-btn-area">
                                <div class="col-xs-6">
                                </div>
                                <div class="col-xs-6">
                                    <div class="btn-align">
                                        <a v-if="this.$parent.CanNew" id="btnListInst" href="#" class="btn btn-default" data-toggle="modal">
                                                <span>
                                                    <i class="glyphicon glyphicon-pencil"></i>글쓰기
                                                </span>
                                            </a>
                                    </div>
                                </div>
                            </div>
                            <div class="tbl-page-area">
                                <div class="text-center">
                                    <ul class="pagination" id="ulpager">
                                        <li>
                                            <a href="#">
                                                «
                                            </a>
                                        </li>
                                        <li class="active"><a href="#">1</a></li>
                                        <li><a href="#">2</a></li>
                                        <li><a href="#">3</a></li>
                                        <li><a href="#">4</a></li>
                                        <li><a href="#">5</a></li>
                                        <li><a href="#">»</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="tbl-sch-area">
                                <div class="cols">
                                    <div class="cols-btns-cont">
                                        <div class="col-xs-4">
                                            <select class="form-control" id="selSrchType">
                                                <option value="">전체기간</option>
                                                <option value="">오늘</option>
                                                <option value="">최근 1주</option>
                                                <option value="">입력</option>
                                            </select>
                                        </div>
                                        <div class="col-xs-4">
                                            <select class="form-control">
                                                <option value="">전체</option>
                                                <option value="C">내용</option>
                                                <option value="A">제목/내용</option>
                                                <option value="U">작성자</option>
                                                <option value="T">제목</option>
                                            </select>
                                        </div>
                                        <div class="col-xs-4">
                                            <input type="text" class="form-control" id="txtSrchText" />
                                        </div>
                                        <div class="btn-wrap">
                                            <!--공통 버튼영역-->
                                            <a v-if="this.$parent.CanSearch" id="btnListSrch" href="#" class="btn btn-primary btn-row2" @click.prevent="fn_ShowData()">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i>검색
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </fieldset>
                </form>
            </article>
        </div>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle'
    export default {
        name: 'BM1010R',
        components: {
            ViewTitle
        },
        data() {
            return {
                NoticeClsList: null,
                NoticeList: null,
                NoticeDetail: null,
                NoticeListTotalCount: 0,
                curpage: 1
            }
        },
        beforeMount() {

            // 공통코드 조회 컨트롤 없음.
        },
        mounted() {

            if (this.$parent.CanSearch) {

                this.fn_ShowData()
            }
        },
        methods: {
            // 공지사항 조회 메서드
            fn_ShowData: function () {

                var url = '/Mini.Service.IBS.BM.Service/FreeBoard.svc/ajax/GetNoticeBoard'
                url = url.toRealServiceUrl()
                var obj = new Object();
                obj.prm = new Array();
                obj.prm.push("L");
                obj.prm.push(this.$parent.EmpId);
                obj.prm.push(this.$parent.CompanyCode);
                obj.prm.push($("#selSrchType").val());
                obj.prm.push($("#txtSrchText").val());
                obj.prm.push(this.$parent.BoardCnt);
                obj.prm.push(this.curpage);

                $.scriptBase.executeAjaxAsync(url, obj, this.getNoticeListServiceCallback)
            },
            getNoticeListServiceCallback: function (data) {

                var parent = this
                var table = JSON.parse(data.d).Table;
                var receviedDBdata = JSON.parse(data.d);
                var $cmtTable = receviedDBdata.Table1;
                var totcnts = 0;
                var Pager = {
                    pageIndexChanged: function (selectedPage) {
                        parent.curpage = selectedPage;
                        parent.fn_ShowData();
                    }
                };

                if (table.length > 0) {

                    this.NoticeList = table
                    this.NoticeListTotalCount = table.length
                }
                else {

                    this.NoticeList = null
                    this.NoticeListTotalCount = 0
                }
                if (typeof $cmtTable != "undefined" && $cmtTable.length > 0) {

                    var parent = this
                    totcnts = $cmtTable[0].TotCnt;
                    $("#spTotCnt").html(totcnts)

                    // 제이쿼리 페이저
                    $("#ulpager").pager({
                        pager: "#ulpager",
                        totalItemCount: totcnts,
                        recordPerPage: this.$parent.BoardCnt,
                        currentPage: parent.curpage,
                        pageIndexChanged: Pager.pageIndexChanged,
                    });
                }
            },
            // 테이블Row 클릭 메서드
            rowClickEvent: function (event) {

                var parent = this

                var cropCd = event.target.parentNode.children['company-code'].textContent
                var boardSeqNo = event.target.parentNode.children['boardseqno'].textContent

                this.$router.push({ path: 'BM1011E?qid=13&wid=1302&eid=BM1010R&key=' + ',' + cropCd + ',' + boardSeqNo })
            }
        }
    }

</script>

<style scoped>
</style>