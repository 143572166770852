﻿export default {
    read: function (files, callbackFunction) {

        if (files != null && files.length > 0) {

            var extensions = files[0].name.split('.').pop().toLowerCase()

            if (extensions === 'csv') {

                var reader = new FileReader()

                reader.onload = function (file) {

                    var tempValue = file.target.result.split('\n')
                    var result = new Array()

                    for (var i = 1; i < tempValue.length; i++) {

                        if (tempValue[i] != null && tempValue[i] != '') {

                            result.push(tempValue[i].split(','))
                        }
                    }

                    callbackFunction(result)
                }

                reader.readAsText(files[0], 'euc-kr')
            }
            else {

                alert('csv 파일형식이 아닙니다. 다시 확인하여 주세요.')
            }
        }
        else {

            alert('파일을 선택하세요.')
        }
    }
}