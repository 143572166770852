<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a v-show="this.$parent.CanSearch" href="#" id="btnListSrch" class="btn btn-primary btn-row2" @click.prevent="getProjectListAll">
                    <span>
                        <i class="glyphicon glyphicon-search"></i> 조회
                    </span>
                </a>
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-3">
                                        <label for="selTrgtYY" class="control-label">
                                            <span>년도</span>
                                        </label>
                                        <select class="form-control" ref="selTrgtYY" name="selTrgtYY" v-model="ProjectSelectTerm.TargetYear" @change="getProjectListAll">
                                            <option v-for="year in YearsList" v-bind:value="year">
                                                {{year}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-3">
                                        <label for="txtProjectNm" class="control-label">
                                            <span>프로젝트명</span>
                                        </label>
                                        <input type="text" id="txtProjectNm" class="form-control txt" v-model="ProjectSelectTerm.ProjectName" />
                                    </div>
                                    <div class="control-label">
                                        <label for="selPeriodYN" class="control-label">
                                            <span>연월 표시여부</span>
                                        </label>
                                        <input class="checkbox-inline" type="checkbox" id="selPeriodYN" name="selPeriodYN" @click="checkEvent($event.target.checked)" />
                                    </div>
                                </section>
                            </section>
                        </fieldset>
                    </form>
                </div>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">프로젝트</p>
                </div>
                <!--리스트 영역-->
                <section class="tbl-contents">
                    <div class="table-responsive">
                        <DxDataGrid :data-source="ProjectList"
                                    :ref="ProjectList"
                                    key-expr="ProjectCd"
                                    :hoverStateEnabled="true"
                                    :allow-column-resizing="true"
                                    :column-resizing-mode="'widget'"
                                    :column-auto-width="true"
                                    :row-alternation-enabled="true"
                                    :show-borders="false"
                                    :focused-row-enabled="true"
                                    v-model:focused-row-key="focusedRowKey"
                                    @focused-row-changed="onFocusedRowChanged"
                                    @cell-prepared="onCellPrepared"
                                    @row-click="getCostList"
                                    style="max-height: 350px; min-width: 1540px; vertical-align: top;">
                            <DxScrolling mode="standard" useNative="false" />
                            <DxColumnFixing :enabled="true" />
                            <DxPaging :enabled="false" />
                            <DxColumn caption="프로젝트명" data-field="ProjectNm" />
                            <DxColumn caption="PM" data-field="ManagerNm" alignment="center" />
                            <DxColumn caption="계약일자" data-field="ContractYMD" alignment="center" />
                            <DxColumn caption="구분" data-field="ProjectTypeNm" alignment="center" />
                            <DxColumn caption="계약금액" data-field="Price" format="fixedPoint" alignment="right" />
                            <DxColumn caption="진행상태" data-field="StatusName" alignment="center" />
                        </DxDataGrid>
                    </div>
                </section>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">프로젝트별 비용 통계</p>
                </div>
                <!--리스트 영역-->
                <section class="tbl-contents">
                    <div class="table-responsive">
                        <DxDataGrid :data-source="CostList"
                                    :ref="RefCostList"
                                    :hoverStateEnabled="true"
                                    :allow-column-resizing="true"
                                    :column-resizing-mode="'widget'"
                                    :column-auto-width="true"
                                    :row-alternation-enabled="true"
                                    :show-borders="false"
                                    @cell-prepared="onCellPrepared"
                                    style="max-height: 350px; min-width: 1540px; vertical-align: top;">
                            <DxScrolling mode="standard" useNative="false" />
                            <DxColumnFixing :enabled="true" />
                            <DxPaging :enabled="false" />
                            <DxColumn caption="부서" data-field="Department" alignment="center" />
                            <DxColumn caption="사용자명" data-field="EmpNm" alignment="center" />
                            <DxColumn caption="승인요청액" data-field="ReqPrice" format="fixedPoint" alignment="right" />
                            <DxColumn caption="승인액" data-field="AprPrice" format="fixedPoint" alignment="right" />
                            <DxColumn caption="합계금액" data-field="SumPrice" format="fixedPoint" alignment="right" />
                            <DxColumn caption="건수" data-field="Counting" alignment="center" />
                        </DxDataGrid>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle';
    import { DxDataGrid, DxColumn, DxScrolling, DxColumnFixing, DxPaging } from 'devextreme-vue/data-grid';
    export default {
        name: 'RT1060R',
        components: {
            ViewTitle, DxDataGrid, DxColumn, DxScrolling, DxColumnFixing, DxPaging,
        },
        data() {
            return {
                // 년도 콤보박스 목록
                YearsList: new Array(),
                // 프로젝트 목록
                ProjectList: new Array(),
                // 프로젝트 포커스 키
                focusedRowKey: '',
                // 비용 통계 목록
                CostList: new Array(),
                RefCostList: 'dataGrid',
                // 데이터 총 개수
                TotalCount: 0,
                // 프로젝트 조회조건
                ProjectSelectTerm: {

                    Arg: 'RT',
                    UserId: this.$parent.EmpId,
                    CompanyCode: this.$parent.CompanyCode,
                    AccountCompanyCode: '',
                    AccountCompanyName: '',
                    ProjectName: '',
                    TargetYear: this.moment(new Date()).year(),
                    StatusCode: '',
                    ProjectCategoryCode: '',
                },
                // 프로젝트별 비용 조회 조건
                CostSelectTerm: {

                    PeriodYN: 'N',
                    ProjectCd: '',
                    TargetYear: this.moment(new Date()).year(),
                }
            }
        },
        mounted() {

            this.getProjectListAll();
        },
        beforeMount() {

            var startYear = this.moment(new Date()).add(1, 'year').year();
            var endYear = this.moment(new Date(2010, 1, 1, 0, 0, 0)).year();

            // 대상년도 목록 값 설정
            for (var i = startYear; i >= endYear; i--) {

                this.YearsList.push(i);
            }
        },
        methods: {
            // Datagrid header 가운데 정렬, column 중앙 정렬
            onCellPrepared(e) {

                if (e.rowType == 'header') {

                    e.cellElement.style['textAlign'] = 'center';
                }
                if (e.rowType == 'data') {

                    e.cellElement.style['verticalAlign'] = 'middle';
                }
            },
            // 마스터 그리드 프로젝트 목록 조회
            getProjectListAll() {

                var parent = this;

                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/GetNormalProject'.toRealServiceUrl();
                var obj = new Object();
                obj.jsonString = JSON.stringify(this.ProjectSelectTerm);

                var ajaxSuccessHandler = function (data) {

                    var jsonObj = JSON.parse(data.d);
                    var table = jsonObj.Table;

                    parent.ProjectList = (table.length > 0) ? table : new Array();

                    parent.CostSelectTerm.TargetYear = parent.ProjectSelectTerm.TargetYear;
                    parent.CostList = new Array();
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler);
            },
            // 프로젝트 포커스 키 변경
            onFocusedRowChanged(e) {

                this.focusedRowKey = e.component.option('focusedRowKey');
            },
            // 체크박스로 연월 표시여부 선택
            checkEvent(isChecked) {

                this.CostSelectTerm.PeriodYN = (isChecked) ? 'Y' : 'N';

                if (this.CostSelectTerm.ProjectCd != '') {

                    this.getCostList(this.CostSelectTerm.ProjectCd);
                }
            },
            // 선택한 프로젝트별 비용 목록
            getCostList(e) {

                var parent = this;

                var url = '/Mini.Service.IBS.RT.Service/AccountingReport.svc/ajax/GetCostByProject'.toRealServiceUrl();
                var obj = new Object();

                this.CostSelectTerm.ProjectCd = (e.key) ? e.key : e;
                obj.jsonString = JSON.stringify(this.CostSelectTerm);

                // 연월 표시에 따라 컬럼 세팅
                if (this.CostSelectTerm.PeriodYN == 'Y' && this.$refs[this.RefCostList].instance.columnCount() == 6) {

                    let yearMonth = { 'caption': '연월', 'dataField': 'Date', alignment: 'center', visibleIndex: 0 };
                    this.$refs[parent.RefCostList].instance.addColumn(yearMonth);
                } else if (this.CostSelectTerm.PeriodYN == 'N' && this.$refs[this.RefCostList].instance.columnCount() > 6) {

                    this.$refs[parent.RefCostList].instance.deleteColumn('연월');
                }

                var ajaxSuccessHandler = function (data) {

                    var jsonObj = JSON.parse(data.d);
                    var table = jsonObj.Table;
                    var table1 = jsonObj.Table1;

                    parent.CostList = (table.length > 0) ? table : new Array();
                    parent.TotalCount = (table1.length > 0) ? table1[0].TotCnt : 0;

                    // 연월 포맷
                    parent.CostList.forEach((item) => item.Date = parent.moment(item.Date).format('YYYY-MM'));
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler);
            }
        }
    }
</script>