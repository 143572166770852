<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a v-show="this.$parent.CanSearch" href="#" id="btnListSrch" class="btn btn-primary btn-row2" @click.prevent="showData">
                    <span>
                        <i class="glyphicon glyphicon-search"></i> 조회
                    </span>
                </a>
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-3">
                                        <label for="selTrgtYY" class="control-label">
                                            <span>년도</span>
                                        </label>
                                        <select class="form-control" ref="selTrgtYY" name="selTrgtYY" v-model="Year" @change="showData">
                                            <option v-for="year in YearsList" v-bind:value="year">
                                                {{year}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-3">
                                        <label for="selArgs" class="control-label">
                                            <span>매입상태</span>
                                        </label>
                                        <select id="selArgs" ref="selArgs" class="form-control" v-model="Arg" @change="showData">
                                            <option value="A">매입예정</option>
                                            <option value="B">세금계산서 수취완료</option>
                                            <option value="C">출금완료</option>
                                        </select>
                                    </div>
                                    <div class="cols col-3">
                                        <label for="selTypes" class="control-label">
                                            <span>프로젝트구분</span>
                                        </label>
                                        <select id="selTypes" ref="selTypes" class="form-control" v-model="Type" @change="showData">
                                            <option value="ALL">전체지출</option>
                                            <option value="EXE">매입</option>
                                            <option value="SI">SI</option>
                                            <option value="SM">SM</option>
                                            <option value="ETC">기타</option>
                                            <option value="RND">정부과제</option>
                                        </select>
                                    </div>
                                </section>
                            </section>

                        </fieldset>
                    </form>
                </div>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">월별 매입 통계</p>
                </div>
                <div>
                    <!--리스트 영역-->
                    <section class="tbl-contents">
                        <div class="alert alert-info total">
                            <p>
                                <i class="glyphicon glyphicon-folder-close"></i> <span>전체: <span id="spTotCnt">{{TotalCount}}</span>건, </span><span v-show="Arg != 'C'">매입 합계: <span id="spTotCnt">{{VATPrice}}</span>원, </span><span>VAT포함 합계: <span id="spTotCnt">{{TotalPrice}}</span>원</span>
                                <span v-show="Arg == 'C'" style="margin-left: 25px;">(출금완료 조회 금액은 VAT 포함입니다.)</span>
                            </p>
                        </div>
                        <div class="table-responsive">
                            <table class="table tbl-list tbl-center table-hover table-bordered" id="tbStatisticsPurchase">
                                <caption>테이블</caption>
                                <colgroup>
                                    <col class="chk" />
                                    <col class="table-colwid15" />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col">거래처</th>
                                        <th scope="col">프로젝트</th>
                                        <th scope="col" style="width:80px;">합계</th>
                                        <th scope="col" style="width:80px;">1월</th>
                                        <th scope="col" style="width:80px;">2월</th>
                                        <th scope="col" style="width:80px;">3월</th>
                                        <th scope="col" style="width:80px;">4월</th>
                                        <th scope="col" style="width:80px;">5월</th>
                                        <th scope="col" style="width:80px;">6월</th>
                                        <th scope="col" style="width:80px;">7월</th>
                                        <th scope="col" style="width:80px;">8월</th>
                                        <th scope="col" style="width:80px;">9월</th>
                                        <th scope="col" style="width:80px;">10월</th>
                                        <th scope="col" style="width:80px;">11월</th>
                                        <th scope="col" style="width:80px;">12월</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="StatisticsPurchaseByMonthList.length < 1">
                                        <td colspan="14" class="text-center no-data">조회된 정보가 없습니다.</td>
                                    </tr>
                                    <tr v-else v-for="item in StatisticsPurchaseByMonthList" style="cursor:pointer;">
                                        <td>{{item.CompanyNm}}</td>
                                        <td style="text-align:left">{{item.ProjectNm}}</td>
                                        <td style="text-align:right;">{{item.TotAMT}}</td>
                                        <td style="text-align:right;">{{item.JAN}}</td>
                                        <td style="text-align:right;">{{item.FEB}}</td>
                                        <td style="text-align:right;">{{item.MAR}}</td>
                                        <td style="text-align:right;">{{item.APR}}</td>
                                        <td style="text-align:right;">{{item.MAY}}</td>
                                        <td style="text-align:right;">{{item.JUN}}</td>
                                        <td style="text-align:right;">{{item.JUL}}</td>
                                        <td style="text-align:right;">{{item.AUG}}</td>
                                        <td style="text-align:right;">{{item.SEP}}</td>
                                        <td style="text-align:right;">{{item.OCT}}</td>
                                        <td style="text-align:right;">{{item.NOV}}</td>
                                        <td style="text-align:right;">{{item.DEC}}</td>
                                    </tr>
                                </tbody>
                                <!-- 합계 두 줄 짜리 -->
                                <tfoot class="total-tfoot">
                                    <tr>
                                        <td colspan="2" class="text-center">합계</td>
                                        <td>{{StatisticsPurchaseByMonthList.reduce((t, o)=>{return sumData(t, o.TotAMT)}, 0).toPriceNumber()}}</td>
                                        <td>{{StatisticsPurchaseByMonthList.reduce((t, o)=>{return sumData(t, o.JAN)}, 0).toPriceNumber()}}</td>
                                        <td>{{StatisticsPurchaseByMonthList.reduce((t, o)=>{return sumData(t, o.FEB)}, 0).toPriceNumber()}}</td>
                                        <td>{{StatisticsPurchaseByMonthList.reduce((t, o)=>{return sumData(t, o.MAR)}, 0).toPriceNumber()}}</td>
                                        <td>{{StatisticsPurchaseByMonthList.reduce((t, o)=>{return sumData(t, o.APR)}, 0).toPriceNumber()}}</td>
                                        <td>{{StatisticsPurchaseByMonthList.reduce((t, o)=>{return sumData(t, o.MAY)}, 0).toPriceNumber()}}</td>
                                        <td>{{StatisticsPurchaseByMonthList.reduce((t, o)=>{return sumData(t, o.JUN)}, 0).toPriceNumber()}}</td>
                                        <td>{{StatisticsPurchaseByMonthList.reduce((t, o)=>{return sumData(t, o.JUL)}, 0).toPriceNumber()}}</td>
                                        <td>{{StatisticsPurchaseByMonthList.reduce((t, o)=>{return sumData(t, o.AUG)}, 0).toPriceNumber()}}</td>
                                        <td>{{StatisticsPurchaseByMonthList.reduce((t, o)=>{return sumData(t, o.SEP)}, 0).toPriceNumber()}}</td>
                                        <td>{{StatisticsPurchaseByMonthList.reduce((t, o)=>{return sumData(t, o.OCT)}, 0).toPriceNumber()}}</td>
                                        <td>{{StatisticsPurchaseByMonthList.reduce((t, o)=>{return sumData(t, o.NOV)}, 0).toPriceNumber()}}</td>
                                        <td>{{StatisticsPurchaseByMonthList.reduce((t, o)=>{return sumData(t, o.DEC)}, 0).toPriceNumber()}}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle';
    export default {
        name: 'RT1020R',
        components: {
            ViewTitle
        },
        data() {
            return {
                // 년도 콤보박스 목록
                YearsList: new Array(),
                // 년도 콤보박스 값
                Year: this.moment(new Date()).year(),
                // 매입상태
                Arg: 'A',
                // 프로젝트구분
                Type: 'EXE',
                // 월별 매입 통계 목록
                StatisticsPurchaseByMonthList: new Array(),
                // 데이터 총 개수
                TotalCount: 0,
                // 매입 합계액
                TotalPrice: 0,
                // 매입 VAT제외 합계액
                VATPrice: 0
            }
        },
        beforeMount() {

            var startYear = this.moment(new Date()).add(5, 'year').year();
            var endYear = this.moment(new Date(2010, 1, 1, 0, 0, 0)).year();

            // 대상년도 목록 값 설정
            for (var i = startYear; i >= endYear; i--) {

                this.YearsList.push(i);
            }
        },
        mounted() {

            // 초기 조회
            this.showData();
        },
        methods: {
            // 합계액 계산 메소드
            sumData(t, m) {

                t = t + Number(m.replace(/,/g, ''));
                return t;
            },
            // 조회 메소드
            showData() {

                var parent = this;

                var url = '/Mini.Service.IBS.RT.Service/AccountingReport.svc/ajax/GetStatisticsPurchaseByMonth'.toRealServiceUrl();
                var obj = new Object();
                obj.year = this.Year;
                obj.args = this.Arg;
                obj.type = this.Type;

                // ajax 성공 시 실행되는 핸들러 정의
                var ajaxSuccessHandler = function (data) {

                    var table = JSON.parse(data.d).Table;
                    var table1 = JSON.parse(data.d).Table1;

                    parent.StatisticsPurchaseByMonthList = (table.length > 0) ? table : new Array();
                    parent.TotalCount = table.length;
                    // VAT 제외한 합계액
                    parent.VATPrice = (table1[0].TotalAMT != null) ? table1[0].TotalAMT.toPriceNumber() : 0;
                    // VAT 포함 합계액
                    parent.TotalPrice = (table1[0].VatIncludeAMT != null) ? table1[0].VatIncludeAMT.toPriceNumber() : 0;
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler);
            }
        }
    }
</script>