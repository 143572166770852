<template>
    <router-view></router-view>
</template>

<script>
    import { locale } from 'devextreme/localization'

    export default {
        name: 'app',
        created() {
            console.log('App Created : ')
            // devextreme ����
            locale('ko')
        }
    };
</script>

<style>
</style>
