<template>
    <div>
        <DxDataGrid ref="grdList" 
                    :data-source="ContractDetailList" 
                    :show-borders="true" 
                    :show-row-lines="true"
                    @cell-prepared="onCellPrepared"
                    @cell-click="onCellClick">
            <DxColumn caption="구분" data-field="ContractDivNm" alignment="center" width="10%" />
            <DxColumn caption="상태" data-field="ConstractStatusNm" alignment="center" width="10%" />
            <DxColumn caption="이력" data-field="ContractSeqNm" width="30%"/>
            <DxColumn caption="계약금액" data-field="ContractAmount" alignment="right" format="fixedPoint" width="10%" />
            <DxColumn caption="시작일자" data-field="ContractStartDate" alignment="center" data-type="date" width="10%" />
            <DxColumn caption="종료일자" data-field="ContractEndDate" alignment="center" data-type="date" width="10%" />
            <DxColumn caption="지급구분" data-field="CostPlayDivNm" alignment="center" width="10%" />
            <DxColumn cell-template="add" width="10%" alignment="center">
            </DxColumn>
            <template #add="{ data }">
                <DxButton text="변경계약등록" type="default" styling-mode="contained" @click="onAddChangeContractClick(data)" />
            </template>
        </DxDataGrid>
        <BZ1023E ref="BZ1023E"></BZ1023E>
    </div>
</template>

<script>
    // Dev extreme
    import { DxDataGrid, DxColumn, DxPaging, DxSummary, DxTotalItem, DxValueFormat } from 'devextreme-vue/data-grid'
    import { DxButton } from 'devextreme-vue'

	import BZ1023E from '@/components/bz/popup/BZ1023E'

    export default {
        name: 'BZ1020R_Detail'
        , components: {
            DxDataGrid
            , DxColumn
            , DxPaging
            , DxSummary
            , DxTotalItem
            , DxValueFormat
			, DxButton
            , BZ1023E
        }
        , props: {
			templateData: {
                type: Object,
                default: () => { }
            }
        }
        , data() {
            return {
				Condition: {
					CorpCd: this.$parent.$parent.CompanyCode
					, ContractNm: ''
					, CompanyNm: ''
					, CompanyCd: ''
					, TargetYear: ''
				}
                , ContractDetailList: new Array()
            };
        }
        , created() {
			this.getContractDetail()
		}
        , methods: {
            //#region init
            //#endregion

            //#region event
            onCellPrepared(e) { // datagrid header 가운데 정렬, column 중앙 정렬
                if (e.rowType == 'header') {
                    e.cellElement.style['textAlign'] = 'center'
                }
                if (e.rowType == 'data') {
                    e.cellElement.style['verticalAlign'] = 'middle'
                }
            }
            // 계약상세 파일 목록
            , getBusinessHistoryFileList: function (contractNo, contractSeqNo) {

                // 파일 목록 초기화
                var contractDetailFileList = new Array()

                this.ContractDetailFileListAll.forEach(function (item) {

                    if (contractNo === item.ContractNo && contractSeqNo === item.ContractSeqNo) {

                        item.IsNew = false
                        item.IsRemove = false
                        item.IsShow = true
                        item.IsChecked = false
                        item.Files = null
                        contractDetailFileList.push(item)
                    }
                })

                return contractDetailFileList
            }
            //#endregion

            //#region button click
            , onCellClick(e) {

                var callbackFuntion = function (result) {
                    this.$parent.getContractDetail()
                }
                var fileObj = this.getBusinessHistoryFileList(e.row.data.ContractNo, e.row.data.ContractSeqNo)

                // 팝업 오픈
                this.$refs.BZ1023E.open(callbackFuntion, null, e.row.data, fileObj, this.templateData)
            }
            , onAddChangeContractClick: function (e) {
                var that = this

                var callbackFuntion = function () {
                    that.getContractDetail()
                }

                var fileObj = this.getBusinessHistoryFileList(e.row.data.ContractNo, e.row.data.ContractSeqNo)

                e.row.data.ContractDiv = '0744020'
                e.row.data.PrntContractSeqNm = e.row.data.ContractSeqNm
                e.row.data.ContractSeqNm = null
                e.row.data.PrntContractSeqNo = e.row.data.ContractSeqNo
                e.row.data.ContractSeqNo = null
                e.row.data.ApproveCd = null
                e.row.data.ApproveDesc = null
                e.row.data.ConstractStatusCd = '0733010'

                e.row.data.PrntProjectCd = e.row.data.PrntProjectCd
                e.row.data.PrntProjectNo = e.row.data.PrntProjectNo

                this.$refs.BZ1023E.open(callbackFuntion, null, e.row.data, fileObj, this.templateData)
            }
            //#endregion

            //#region service
            , getContractDetail: function () {
                var that = this

                var ContractSelectTerm = {
                    ContractNo: this.templateData.data.ContractNo
                }

                var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/GetContractDetailList'
                url = url.toRealServiceUrl()
                var obj = new Object()

                obj.jsonString = JSON.stringify(ContractSelectTerm)

                var ajaxSuccessHandler = function (data) {
                    var jsonObj = JSON.parse(data.d)

                    that.ContractDetailList = jsonObj.Table

                    that.ContractDetailFileListAll = jsonObj.Table1

                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
            }
            //#endregion
        }
    }
</script>
