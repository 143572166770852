<template>
    <div class="login-content">
        <div class="bg-login">
            <div class="login-logo">
                <p>Copyright © 2014~2021 minisoft. All Rights Reserved.</p>
                <img src="../images/login_logo.png" alt="logo" />
            </div>
        </div>
        <div class="login-form">
            <div class="login-form-logo">
                <img src="../images/login_logo.png" alt="logo" />
            </div>
            <div class="form-wrap">
                <p class="id"><input type="text" name="username" placeholder="아이디 또는 이메일" class="form-control" @keyup="keyUpEvent" /></p>
                <p class="pw"><input type="password" name="password" placeholder="비밀번호" class="form-control" @keyup="keyUpEvent" /></p>
                <div class="checkbox">
                    <label>
                        <input type="checkbox" name="cbSaveUserId">
                        <span class="cr"><i class="cr-icon glyphicon glyphicon-ok"></i></span>아이디 저장
                    </label>
                </div>
                <p class="btn-login"><a href="#" @click.prevent="fn_ActLogon"><img src="../images/ico_login.svg" alt="login" />로그인</a></p>
                <p class="login-copyright">© 2014~2021 minisoft. All rights reserved.</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Login',
        components: {

        },
        mounted() {

            var email = this.fn_GetCookie('MiniUserEmail')
            if (typeof email != 'undefined' && email != '') {
                $('input[type="checkbox"][name="cbSaveUserId"]').prop('checked', true)
                $('input[name="username"]').val(email)
            }
        },
        methods: {
            fn_GetCookie: function (name) {

                var value = '; ' + document.cookie
                var parts = value.split('; ' + name + '=')

                if (parts.length == 2)
                    return parts.pop().split(';').shift()
            },
            fn_DelCookie: function (name) {

                document.cookie = name + '=;'//; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
            },
            fn_SetCookie: function (name, vals, days) {

                //var today = new Date()
                //var expire = new Date()

                //if (days == null || days == 0) days = 1

                //expire.setTime(today.getTime() + 3600000 * 24 * days)
                document.cookie = name + '=' + escape(vals) //+ ';expires=' + expire.toGMTString()
            },
            fn_ActLogon: function () {

                var Id = $('input[name="username"]').val()
            
                if ($('input[name="username"]').val() == '') {

                    alert('ID를 입력하세요.')
                    return
                }

                if ($('input[name="password"]').val() == '') {

                    alert('Password를 입력하세요.')
                    return
                }

                if ($('input[type="checkbox"][name="cbSaveUserId"]').is(':checked')) {

                    this.fn_SetCookie('MiniUserEmail', $('input[name="username"]').val(), '30')
                }
                else {

                    this.fn_DelCookie('MiniUserEmail')
                }

                if (!Id.includes('@minisoft.co.kr')) {
                    Id = Id + '@minisoft.co.kr'
                }

                var parent = this
                var url = '/Mini.Service.IBS.ZZ.Service/Authentication.svc/ajax/DoAuthentication'
                url = url.toRealServiceUrl()

                var obj = new Object()
                obj.prm = new Array()
                obj.prm.push(Id)
                obj.prm.push($('input[name="password"]').val())
                obj.prm.push($('input[type="checkbox"][name="cbSaveUserId"]').is(':checked') ? 'Y' : 'N')

                var successCallback = function (data) {

                    if (data.d > 0) {

                        localStorage.setItem('EmailAddress', Id)
						parent.$router.push({ path: '/' })
                    }
                    else {
                        //운영배포용 시작
                        alert('아이디 또는 비밀번호를 확인 해주세요.')
                        //운영배포용 끝

                        //개발용 시작
                        //localstorage.setitem('emailaddress', Id)
                        //parent.$router.push({ path: '/' })
                        //개발용 끝
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            keyUpEvent: function (e) {

                if (e.which == 13) {

                    this.fn_ActLogon()
                }
            }
        }
    }
</script>
<style scoped></style>