<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <!--매입상품관리-->
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a v-show="this.$parent.CanNew" href="#" id="btnListInst" class="btn btn-default" @click.prevent="onAddClick">
                    <span>
                        <i class="glyphicon glyphicon-plus"></i> 추가
                    </span>
                </a>
                <a v-show="this.$parent.CanSearch" href="#" id="btnListSrch" class="btn btn-primary btn-row2" @click.prevent="showProductList">
                    <span>
                        <i class="glyphicon glyphicon-search"></i> 조회
                    </span>
                </a>
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="selDateType" class="control-label"><span>납품예정일</span></label>
                                        <div class="col-xs-3">
                                            <select class="form-control" id="selDateType" @change="dateTypeChangeEvent">
                                                <option value="1">오늘</option>
                                                <option value="2">일주일</option>
                                                <option value="3">한달</option>
                                                <option value="4">일년</option>
                                                <option value="5" selected="selected">이번달</option>
                                            </select>
                                        </div>
                                        <div class="col-xs-9">
                                            <label for="txtStartDate" class="sr-only"><span>납품예정일</span></label>
                                            <div class="col-2 input-group date">
                                                <DxDateBox display-format="yyyy-MM-dd"
                                                           pickerType="calendar"
                                                           v-model="PurchaseProductTerm.StartDate"
                                                           @value-changed="showProductList"
                                                           style="border-radius:0;" />
                                            </div>
                                            <div class="tail"><span>~</span></div>
                                            <label for="txtEndDate" class="sr-only"><span>납품예정일</span></label>
                                            <div class="col-2 input-group date">
                                                <DxDateBox display-format="yyyy-MM-dd"
                                                           pickerType="calendar"
                                                           v-model="PurchaseProductTerm.EndDate"
                                                           @value-changed="showProductList"
                                                           style="border-radius:0;" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtCompanyNm" class="control-label"><span>프로젝트</span></label>
                                        <div class="cols-btns-cont pad-r150">
                                            <input type="text" id="txtProjectNm" class="form-control txt" readonly="readonly" v-model="PurchaseProductTerm.ProjectName" />
                                            <input type="hidden" id="hidProjectCd" v-model="PurchaseProductTerm.ProjectCode" />
                                            <input type="hidden" id="hidCompanyNm" v-model="PurchaseProductTerm.CompanyName" />
                                            <input type="hidden" id="hidCompanyCd" v-model="PurchaseProductTerm.CompanyCode" />
                                        </div>
                                        <div class="cols-btns">
                                            <a href="#" id="btnListPop1" class="btn btn-default" @click.prevent="searchProjectCode">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i> 검색
                                                </span>
                                            </a>
                                            <a href="#" id="btnSrchInit" class="btn btn-default"
                                               @click.prevent="function(){
                                                     PurchaseProductTerm.ProjectCode = ''
                                                     PurchaseProductTerm.ProjectName = ''
                                                     PurchaseProductTerm.CompanyName = ''
                                                     PurchaseProductTerm.CompanyCode = ''
                                                     showProductList()
                                                }">
                                                <span>
                                                    <i class="glyphicon glyphicon-repeat"></i> 초기화
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </section>
                            </section>
                        </fieldset>
                    </form>
                </div>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">프로젝트 매입관리</p>
                </div>
                <div>
                    <!--리스트 영역-->
                    <section class="tbl-contents">
                        <div class="alert alert-info total">
                            <p><i class="glyphicon glyphicon-folder-close"></i> <span>전체: <span id="spTotCnt">{{TotalCount}}</span>건</span></p>
                        </div>
                        <div class="table-responsive">
                            <DxDataGrid :ref="PurchaseProduct"
                                        key-expr="OrderSeq"
                                        :data-source="PurchaseProductList"
                                        :hoverStateEnabled="true"
                                        :show-borders="true"
                                        :show-row-lines="true"
                                        :allow-column-resizing="true"
                                        :focused-row-enabled="true"
                                        v-model:focused-row-key="focusedRowKey"
                                        @focused-row-changed="onFocusedRowChanged"
                                        @cell-click="onFocusedRowChanged"
                                        @cell-prepared="onCellPrepared"
                                        style="max-height: 250px;">
                                <DxPaging :enabled="false" />
                                <DxColumnFixing :enabled="true" />
                                <DxScrolling mode="standard" useNative="false" />
                                <DxColumn caption="납품예정일" data-field="DlvDueYMD" width="8%" alignment="center" />
                                <DxColumn caption="거래처명" data-field="CompanyNm" width="12%" />
                                <DxColumn caption="프로젝트명" data-field="ProjectNm" width="15%" />
                                <DxColumn caption="품목" data-field="ProductNm" width="12%" />
                                <DxColumn caption="수량" data-field="UnitCnt" width="5%" alignment="right" />
                                <DxColumn caption="판매금액" data-field="ListPriceSum" format="fixedPoint" width="10%" alignment="right" />
                                <DxColumn caption="매입금액" data-field="SalePriceSum" format="fixedPoint" width="10%" alignment="right" />
                                <DxColumn caption="부가세" data-field="SaleVATSum" format="fixedPoint" width="10%" alignment="right" />
                                <DxColumn caption="납품완료일" data-field="DlvEndYMD" width="8%" alignment="center" />
                                <DxColumn caption="납품확인서 수령여부" data-field="DlvConfirmYN" width="5%" alignment="center" />
                                <DxColumn caption="수정" type="buttons" width="5%" alignment="center">
                                    <DxButton :on-click="openProductDetail"
                                              text="수정"
                                              css-class="btn btn-warning btn-xs text-deco" />
                                </DxColumn>
                            </DxDataGrid>
                        </div>
                    </section>
                </div>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">대금지급 계획</p>
                </div>
                <div>
                    <div class="btn-wrap">
                        <a href="#" id="btnListInst2" class="btn btn-warning" @click.prevent="onOpenBatchSave">
                            <span>일괄등록</span>
                        </a>
                        <a href="#" id="btnListInst" class="btn btn-default" @click.prevent="onAddPayment">
                            <span>
                                <i class="glyphicon glyphicon-plus"></i> 추가
                            </span>
                        </a>
                        <a href="#" id="btnListSrch" class="btn btn-default btn-row2" @click.prevent="onDeletePayment">
                            <span>
                                <i class="glyphicon glyphicon-minus"></i> 삭제
                            </span>
                        </a>
                        <a href="#" id="btnListSrch" class="btn btn-primary" @click.prevent="onSavePayment">
                            <span>
                                <i class="glyphicon glyphicon-save"></i> 저장
                            </span>
                        </a>
                    </div>
                    <section class="tbl-contents">
                        <div class="table-responsive">
                            <DxDataGrid :ref="PaymentManage"
                                        key-expr="ItemNo"
                                        :data-source="PaymentList"
                                        :hoverStateEnabled="true"
                                        :show-borders="true"
                                        :show-row-lines="true"
                                        :allow-column-resizing="true"
                                        @cell-prepared="onCellPrepared"
                                        @cell-click="onCellClick"
                                        @selection-changed="onSelectionChanged"
                                        :selected-row-keys="selectedItemKeys"
                                        @saving="setFocus"
                                        style="max-height: 250px;">
                                <DxPaging :enabled="false" />
                                <DxSelection mode="multiple" :allow-select-all="allMode" :show-check-boxes-mode="'always'" />
                                <DxScrolling mode="standard" useNative="false" />
                                <DxEditing :allow-updating="true" :select-text-on-edit-start="true" :new-row-position="'last'" mode="cell" />
                                <DxColumn caption="프로젝트명" data-field="ProjectName" :allow-editing="false" alignment="left" />
                                <DxColumn caption="대금지급구분" data-field="PaymentCode" alignment="left">
                                    <DxLookup :dataSource="PaymentCodeList" display-expr="Name" value-expr="Code" />
                                </DxColumn>
                                <DxColumn caption="메모" data-field="PaymentMemo" />
                                <DxColumn caption="지급예정금액" data-field="PaymentPrice" format="fixedPoint" alignment="right" :set-cell-value="setPaymentVAT" />
                                <DxColumn caption="부가세" data-field="PaymentVAT" format="fixedPoint" alignment="right" />
                                <DxColumn caption="매입세금계산서 예정일자" data-field="BillDate" data-type="date" alignment="center" format="yyyy-MM-dd" />
                                <DxColumn caption="지급예정일자" data-field="PaymentsDate" data-type="date" alignment="center" format="yyyy-MM-dd" />
                                <DxColumn caption="세금계산서 수취여부" data-field="InvoiceRecvYN" :allow-editing="false" alignment="center" />
                            </DxDataGrid>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <CP1060P ref="CP1060P"></CP1060P>
        <PM1041E ref="PM1041E"></PM1041E>
        <PM1042E ref="PM1042E"></PM1042E>
    </div>
</template>

<style>
    .dx-datagrid-rowsview .dx-select-checkboxes-hidden > tbody > tr > td > .dx-select-checkbox {
        display: inline-block;
    }
</style>

<script>
    import ViewTitle from '@/components/ViewTitle';
    import { DxDataGrid, DxColumn, DxButton, DxScrolling, DxSorting, DxPaging, DxColumnFixing, DxSelection, DxEditing, DxLookup } from 'devextreme-vue/data-grid';
    import DxDateBox, { DxCalendarOptions } from 'devextreme-vue/date-box';
    import DataSource from 'devextreme/data/data_source';
    import ArrayStore from 'devextreme/data/array_store';
    import PM1041E from '@/components/pm/popup/PM1041E';
    import PM1042E from '@/components/pm/popup/PM1042E';
    import CP1060P from '@/components/libs/popup/CP1060P';

    export default {
        name: 'PM1040R',
        components: {
            ViewTitle, DxDataGrid, DxColumn, DxButton, DxScrolling, DxSorting, DxPaging, DxDateBox, DxCalendarOptions, DxColumnFixing, DxSelection, DxEditing, DxLookup, PM1041E, PM1042E, CP1060P,
        },
        data() {
            return {
                // 화면액션 값 설정 (I:신규, V: 상세보기)
                PageActionType: 'V',
                // 매입관리 조회 조건
                PurchaseProductTerm: {
                    Arg: 'L',
                    StartDate: this.moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD'),
                    EndDate: this.moment(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)).format('YYYY-MM-DD'),
                    CorpCd: this.$parent.CompanyCode,
                    ProjectCode: '',
                    CompanyCode: '',
                },
                // 상세 데이터 객체
                ProductDetail: new Object(),
                // 조회된 매입관리 리스트
                PurchaseProductList: new Array(),
                // 데이터 총 개수
                TotalCount: 0,
                PurchaseProduct: 'dataGrid',
                focusedRowKey: '',
                // 대금지급 구분 코드
                PaymentCodeList: new Array(),
                // 대금지급 부가세포함 여부
                PaymentVatIncludeYn: 'Y',
                // 대금지급 조회조건
                PaymentTerm: {
                    Arg: 'L',
                    CorpCd: this.$parent.CompanyCode,
                    CompanyCd: '',
                    ProjectCd: '',
                    SeqNo: ''
                },
                // 대급지급 계획 데이터
                PaymentList: new Array(),
                PaymentManage: 'dataGrid',
                paymentDataSource: new DataSource({
                    store: new ArrayStore({
                        data: this.PaymentManage,
                        key: 'ItemNo'
                    }),
                }),
                allMode: true,
                // 체크박스 선택된 아이템
                selectedItemKeys: new Array(),
                // 대금지급 저장 파라미터
                PaymentManipulateList: new Array(),
                // 대금지급 삭제 파라미터
                PaymentDeleteList: new Array(),
            }
        },
        beforeMount() {

            // 대금지급 구분 공통코드 조회
            this.PaymentCodeList = $.scriptBase.getCommonCode('CODE000', '0715000', '', '');
        },
        mounted() {
            // 초기조회
            if (this.$parent.CanSearch) {

                this.showProductList();
            }
        },
        methods: {
            // datagrid header 가운데 정렬, column 중앙 정렬
            onCellPrepared(e) {

                if (e.rowType == 'header') {
                    e.cellElement.style['textAlign'] = 'center';
                }

                if (e.rowType == 'data') {
                    e.cellElement.style['verticalAlign'] = 'middle';

                    if (e.column.dataField == 'ProjectName' || e.column.dataField == 'InvoiceRecvYN') {
                        e.cellElement.style['background-color'] = '#EAEAEA';
                        // 클릭 동작하지 않음
                        e.cellElement.style['pointer-events'] = 'none';
                    }

                    if (e.column.dataField == 'PaymentVAT') {
                        e.cellElement.style['background-color'] = '#EAEAEA';
                        // 클릭 동작함
                        e.column.allowEditing = false;
                    }
                }

                // 매입세금계산서 수취완료 시 수정 불가
                if (e.row && e.row.data.InvoiceRecvYN && e.row.data.InvoiceRecvYN == 'Y') {
                    e.cellElement.style['background-color'] = '#EAEAEA';
                    e.cellElement.style['pointer-events'] = 'none';
                }
            },
            // 발행예정일 날짜 타입 변경 이벤트
            dateTypeChangeEvent(event) {

                var dateType = Number(event.target.value);

                switch (dateType) {
                    case 1: {

                        this.PurchaseProductTerm.StartDate = this.moment(new Date()).format('YYYY-MM-DD');
                        this.PurchaseProductTerm.EndDate = this.moment(new Date()).format('YYYY-MM-DD');
                        break;
                    }
                    case 2: {

                        this.PurchaseProductTerm.StartDate = this.moment(new Date()).add(-7, 'days').format('YYYY-MM-DD');
                        this.PurchaseProductTerm.EndDate = this.moment(new Date()).format('YYYY-MM-DD');
                        break;
                    }
                    case 3: {

                        this.PurchaseProductTerm.StartDate = this.moment(new Date()).add(-1, 'months').format('YYYY-MM-DD');
                        this.PurchaseProductTerm.EndDate = this.moment(new Date()).format('YYYY-MM-DD');
                        break;
                    }
                    case 4: {

                        this.PurchaseProductTerm.StartDate = this.moment(new Date()).add(-1, 'years').format('YYYY-MM-DD');
                        this.PurchaseProductTerm.EndDate = this.moment(new Date()).format('YYYY-MM-DD');
                        break;
                    }
                    case 5: {

                        this.PurchaseProductTerm.StartDate = this.moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD');
                        this.PurchaseProductTerm.EndDate = this.moment(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)).format('YYYY-MM-DD');
                        break;
                    }
                }
            },
            // 프로젝트 검색 메서드
            searchProjectCode() {

                var parent = this;

                var successCallback = function (result) {

                    // 결과값을 추출 후 돔객체에 바인딩
                    parent.PurchaseProductTerm.ProjectName = result.ProjectNm;
                    parent.PurchaseProductTerm.ProjectCode = result.ProjectCd;
                    parent.PurchaseProductTerm.CompanyName = result.CompanyNm;
                    parent.PurchaseProductTerm.CompanyCode = result.CompanyCd;

                    parent.showProductList();
                }

                this.$refs.CP1060P.open(successCallback);
            },
            // 조회 메서드
            showProductList() {

                var parent = this;
                this.PurchaseProductTerm.Arg = 'L';

                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/GetPurchaseProduct';
                url = url.toRealServiceUrl();

                var obj = new Object();
                obj.jsonString = JSON.stringify(this.PurchaseProductTerm);

                // ajax 성공 시 실행되는 핸들러 정의
                var ajaxSuccessHandler = function (data) {

                    var table = JSON.parse(data.d).Table;
                    var table1 = JSON.parse(data.d).Table1;

                    // 매입상품 목록
                    parent.PurchaseProductList = (table.length > 0) ? table : new Array();

                    // 포커스 키 초기화
                    parent.focusedRowKey = '';

                    // 매입상품 목록 전체 건수
                    parent.TotalCount = (table1.length > 0) ? table1[0].TotCnt : 0;

                    // 대금지급 계획 그리드 초기화
                    parent.PaymentList = new Array();
                    parent.ProductDetail = new Object();
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler);
            },
            // 선택된 상품 로우 변경
            onFocusedRowChanged(e) {

                this.focusedRowKey = e.component.option('focusedRowKey');

                if (e.row && e.row.rowType == 'data') {

                    this.ProductDetail = e.row.data;
                    this.PaymentTerm.CompanyCd = this.ProductDetail.CompanyCd;
                    this.PaymentTerm.ProjectCd = this.ProductDetail.ProjectCd;
                    this.PaymentTerm.SeqNo = this.ProductDetail.SeqNo;

                    this.getPaymentList();
                }
            },
            // 상품별 대금지급 계획 조회
            getPaymentList() {

                // 재조회 시 저장하지 않은 데이터 리셋
                this.$refs[this.PaymentManage].instance.saveEditData();

                var parent = this;

                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/SelectProductPayment';
                url = url.toRealServiceUrl();

                var obj = new Object();
                obj.jsonString = JSON.stringify(this.PaymentTerm);

                var ajaxSuccessHandler = function (data) {

                    var jsonData = JSON.parse(data.d);
                    let table = jsonData.Table;
                    let table1 = jsonData.Table1;

                    // 대금지급 그리드 데이터
                    parent.PaymentList = (table.length > 0) ? table : new Array();
                    parent.PaymentVatIncludeYn = (table1.length > 0) ? table1[0].VatIncludeYn : 'Y';

                    // 전체 선택 가능 여부
                    let selectionMode = parent.PaymentList.some((item) => item.InvoiceRecvYN == 'Y');
                    parent.allMode = !selectionMode;
                }
                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler);
            },
            // 추가 버튼 클릭 이벤트 핸들러
            onAddClick() {

                var parent = this;

                var successCallback = function () {

                    parent.showProductList();
                }
                this.$refs.PM1041E.open(successCallback);
            },
            // 수정/상세 팝업 오픈
            openProductDetail(e) {

                var parent = this;
                var data = e.row.data;

                var successCallback = function () {

                    parent.showProductList();
                }
                this.$refs.PM1041E.open(successCallback, data);
            },
            // 선택한 로우 키
            onSelectionChanged(e) {

                this.selectedItemKeys = e.selectedRowKeys;
            },
            // 대금지급 계획 로우 추가
            onAddPayment() {

                if (this.focusedRowKey == '') {

                    alert('매입을 먼저 클릭해 주십시오.');
                    return;
                }

                let rowCnt = this.PaymentList.length;

                this.PurchaseProductList.forEach((item) => {
                    if (item.OrderSeq == this.focusedRowKey) {
                        this.ProductDetail = item;
                    }
                })

                this.$refs[this.PaymentManage].instance.addRow();
                this.$refs[this.PaymentManage].instance.cellValue(rowCnt, '프로젝트명', this.ProductDetail.ProjectNm);
                this.$refs[this.PaymentManage].instance.saveEditData();
            },
            // 대금지급 로우 추가했을 때 셀 포커스 설정
            setFocus(e) {

                let newData = e.changes[0];

                if (newData && newData.type == 'insert') {

                    let rowCnt = this.PaymentList.length;
                    this.$refs[this.PaymentManage].instance.editCell(rowCnt, 'PaymentCode');
                }
            },
            // VAT 계산 후 자동입력
            setPaymentVAT(newData, value) {

                // 금액 포맷으로 입력하기 위해 가공
                newData.PaymentPrice = value * 1;

                // 부가세 별도
                newData.PaymentVAT = (this.PaymentVatIncludeYn == 'N') ? (value * 0.1) : 0;
            },
            // 부가세 수정
            onCellClick(e) {

                // 클릭된 셀의 조건에 따라 동작
                // header말고 data 로우  &&     부가세 컬럼                     &&        부가세 별도              &&  Editor 열리지 않은 상태
                if (e.rowType == 'data' && e.column.dataField == 'PaymentVAT' && this.PaymentVatIncludeYn == 'N' && e.isEditing != true) {

                    if (confirm('부가세를 수정하시겠습니까?')) {

                        e.column.allowEditing = true;
                        this.$refs[this.PaymentManage].instance.editCell(e.rowIndex, 'PaymentVAT');
                    } else {

                        e.column.allowEditing = false;
                        this.$refs[this.PaymentManage].instance.cancelEditData();
                    }
                    // 부가세 컬럼  &&  Editor가 열린 상태(data 로우, 부가세 별도)
                } else if (e.column.dataField == 'PaymentVAT' && e.isEditing == true) {

                    e.column.allowEditing = true;
                    // 부가세 컬럼 (header 로우이거나, 부가세옵션 포함 영세율 면세)
                } else if (e.column.dataField == 'PaymentVAT') {

                    e.column.allowEditing = false;
                    this.$refs[this.PaymentManage].instance.cancelEditData();
                    // 부가세 컬럼이 아닌 나머지 data 로우
                } else if (e.rowType == 'data') {

                    this.$refs[this.PaymentManage].instance.editCell(e.rowIndex, e.column.dataField);
                }
            },
            // 선택한 로우 삭제
            onDeletePayment() {

                var parent = this;

                if (this.selectedItemKeys.length == 0) {

                    alert('삭제할 항목을 선택해주세요.');
                    return;
                }

                // DB에서 삭제할 항목 세팅
                let dataGrid = this.$refs[this.PaymentManage].instance;

                $(dataGrid.getSelectedRowsData()).each(function (i, o) {

                    let selectChange = new Object();

                    selectChange.CompanyCd = parent.ProductDetail.CompanyCd;
                    selectChange.ProjectCd = parent.ProductDetail.ProjectCd;
                    selectChange.ProductSeqNo = parent.ProductDetail.SeqNo;

                    if (o.ProductSeqNo && o.ItemNo) {

                        selectChange.Arg = 'DP';
                        selectChange.UserId = parent.$store.getters.getEmpId;
                        selectChange.CorpCd = parent.$store.getters.getCompanyCode;
                        selectChange.ItemNo = o.ItemNo;
                        selectChange.PurchaseNm = '';
                        selectChange.PaymentCode = '';
                        selectChange.PaymentPrice = '';
                        selectChange.PaymentVAT = '';
                        selectChange.BillDateStart = '';
                        selectChange.BillDateEnd = '';
                        selectChange.PayDateStart = '';
                        selectChange.PayDateEnd = '';
                        selectChange.PaymentMemo = '';
                        selectChange.PurchaseCompanyCd = '';
                        selectChange.VatIncludeYn = '';

                        let selectChageSch = Object.assign({}, selectChange);

                        parent.PaymentDeleteList.push(selectChageSch);
                    }
                })

                // 삭제할 항목 그리드에서 삭제
                this.selectedItemKeys.forEach((element) => this.PaymentList = this.PaymentList.filter((item) => item.ItemNo !== element))
            },
            // 대금지급 계획 저장
            onSavePayment() {

                this.$refs[this.PaymentManage].instance.saveEditData();

                var parent = this;

                let DataCount = this.PaymentList.length;
                let SumPrice = 0;

                for (let i = 0; i < DataCount; i++) {

                    if (!this.PaymentList[i].PaymentCode) {

                        alert('대금지급구분을 입력해 주십시오.');
                        return;
                    }
                    if (!this.PaymentList[i].PaymentPrice) {

                        alert('지급예정금액을 입력해 주십시오.');
                        return;
                    }
                    if (!this.PaymentList[i].BillDate) {

                        alert('매입세금계산서 예정일자를 입력해 주십시오.');
                        return;
                    }
                    if (!this.PaymentList[i].PaymentsDate) {

                        alert('지급 예정일자를 입력해 주십시오.');
                        return;
                    }

                    SumPrice = SumPrice + Number(this.PaymentList[i].PaymentPrice);

                    if (SumPrice > this.ProductDetail.SalePriceSum) {

                        // 마지막 입력한 지급예정금액 셀에 포커스
                        let focusCell = this.$refs[this.PaymentManage].instance.getCellElement(i, 4);
                        this.$refs[this.PaymentManage].instance.focus(focusCell);

                        alert('지급예정금액의 총액은 매입금액을 넘을 수 없습니다.');
                        return;
                    }

                    if (this.PaymentVatIncludeYn == 'N') {

                        let vatCal = Number(this.PaymentList[i].PaymentPrice) * 0.1;
                        // 오차범위 = 절대값(수정한 부가세 - Price * 0.1)
                        let marginOfError = Math.abs(Number(this.PaymentList[i].PaymentVAT) - vatCal);

                        // 오차가 100원 이상일 때
                        if (marginOfError > 100) {

                            this.$refs[this.PaymentManage].instance.editCell(i, 'PaymentVAT');

                            alert('부가세액을 다시 확인해 주십시오.');
                            return;
                        }
                    }
                }

                // 그리드에서 삭제한 데이터 있으면 DB에서 삭제
                if (this.PaymentDeleteList) {

                    var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/SaveProductPayment'.toRealServiceUrl();
                    var obj = new Object();
                    obj.jsonStringPaymentList = JSON.stringify(this.PaymentDeleteList);

                    var ajaxSuccessHandler = function () {

                        parent.selectedItemKeys = new Array();
                        parent.PaymentDeleteList = new Array();
                    }
                    $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler);
                }

                // 저장할 대금지급 데이터 가공
                this.PaymentManipulateList = new Array();

                this.PaymentList.forEach((item) => {

                    item.Arg = 'CP';
                    item.UserId = parent.$store.getters.getEmpId;
                    item.CorpCd = parent.$store.getters.getCompanyCode;
                    item.CompanyCd = parent.ProductDetail.CompanyCd;
                    item.ProjectCd = parent.ProductDetail.ProjectCd;
                    item.ProductSeqNo = parent.ProductDetail.SeqNo;
                    item.PurchaseNm = parent.ProductDetail.CompanyNm + '_' + parent.ProductDetail.ProjectNm + '_' + parent.ProductDetail.ProductNm;
                    item.PurchaseCompanyCd = parent.ProductDetail.PurchaseCompanyCd;
                    item.PaymentMemo = (item.PaymentMemo == undefined) ? null : item.PaymentMemo;
                    item.ItemNo = (typeof item.ItemNo == 'string') ? null : item.ItemNo;
                    item.BillDateStart = this.moment(item.BillDate).format('YYYYMMDD');
                    item.BillDateEnd = this.moment(item.BillDate).format('YYYYMMDD');
                    item.PayDateStart = this.moment(item.PaymentsDate).format('YYYYMMDD');
                    item.PayDateEnd = this.moment(item.PaymentsDate).format('YYYYMMDD');
                    item.VatIncludeYn = this.PaymentVatIncludeYn;

                    parent.PaymentManipulateList.push(item);
                })

                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/SaveProductPayment'.toRealServiceUrl();
                var obj = new Object();

                obj.jsonStringPaymentList = JSON.stringify(this.PaymentManipulateList);

                if (confirm('변경사항을 저장하시겠습니까?')) {

                    var successCallback = function (data) {

                        if (data.d > 0) {

                            alert('저장되었습니다.');
                            parent.getPaymentList();
                        } else {

                            alert('저장에 실패했습니다. 잠시 후 다시 시도해 주십시오.');
                            return;
                        }
                    }
                    $.scriptBase.executeAjaxAsync(url, obj, successCallback);
                }
            },
            onOpenBatchSave() {

                var parent = this;

                if (this.focusedRowKey == '') {

                    alert('매입을 먼저 클릭해 주십시오.');
                    return;
                }

                let data = new Object();

                this.PurchaseProductList.forEach((item) => {
                    if (item.OrderSeq == this.focusedRowKey) {
                        data = Object.assign({}, item);
                    }
                })

                let leftPrice = data.SalePriceSum;
                let SumPaymentPrice = 0;

                if (this.PaymentList.length > 0) {

                    this.PaymentList.forEach((item => {

                        SumPaymentPrice = SumPaymentPrice + Number(item.PaymentPrice);
                    }))

                    leftPrice = data.SalePriceSum - SumPaymentPrice;
                }

                var successCallback = function () {

                    parent.getPaymentList();
                }
                this.$refs.PM1042E.open(successCallback, data, leftPrice);
            },
        }
    }
</script>