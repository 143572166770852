<template>
</template>

<script>
    export default {
        name: 'BM1050R'
    }
</script>

<style scoped>
</style>