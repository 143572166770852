<template>
    <div>
        더 이상 사용하지 않는 화면입니다. (대표님께 문의 바랍니다.)
    </div>
</template>

<script>
    export default {
        name: 'PM1062R'
    }
</script>

<style scoped>
</style>