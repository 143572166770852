<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a v-show="this.$parent.CanSearch" href="#" id="btnListSrch" class="btn btn-primary btn-row2" @click.prevent="btnSearchClicked">
                    <span>
                        <i class="glyphicon glyphicon-search"></i> 조회
                    </span>
                </a>
                <button v-show="this.$parent.CanSearch" type="button" id="btnPDFExport" class="btn btn-primary" @click.prevent="fn_export">
                    <span class="glyphicon glyphicon-print"></span> PDF출력
                </button>
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-3">
                                        <label for="selBelongYY" class="control-label">
                                            <span>귀속년도</span>
                                        </label>
                                        <select id="selBelongYY" class="form-control" ref="selBelongYY" name="selBelongYY" v-model="Year">
                                            <option v-for="year in YearsList" v-bind:value="year"
                                                    v-bind:selected="moment(new Date()).year() === year">
                                                {{year}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-3">
                                        <label for="belong_Type" class="control-label"><span>소속구분</span></label>
                                        <select id="belong_Type" class="form-control" v-model="selBelongType" @change="MonthlyPaymentHistoryTerm.EmpName = null">
                                            <option value="A" selected="selected">개인</option>
                                            <option value="B">사업자</option>
                                        </select>
                                    </div>
                                    <div class="cols col-3" style="padding-left:10px">
                                        <div class="cols-btns-cont pad-r150">
                                            <input type="text" id="txtFieldNm" class="form-control txt" readonly="readonly" v-model="MonthlyPaymentHistoryTerm.EmpName" />
                                            <input type="hidden" id="hidFieldCd" v-model="MonthlyPaymentHistoryTerm.EmpNo" />
                                        </div>
                                        <div class="cols-btns">
                                            <button type="button" id="btnListPop1" class="btn btn-default" @click.prevent="searchManPowerCode">
                                                <span class="glyphicon glyphicon-search"></span> 검색
                                            </button>
                                            <a href="#" id="btnSrchInit" class="btn btn-default" @click.prevent="clearManPowerCode">
                                                <span>
                                                    <i class="glyphicon glyphicon-repeat"></i> 초기화
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </section>
                            </section>

                        </fieldset>
                    </form>
                    </div>
                </div>
                <div class="grid-wrap">
                    <div class="sub-title">
                        <p class="sub-title-txt">월별 지급 내역</p>
                    </div>
                    <div>
                        <!--리스트 영역-->
                        <section class="tbl-contents">
                            <div class="alert alert-info total">
                                <p>
                                    <i class="glyphicon glyphicon-folder-close"></i> <span>전체: <span id="spTotCnt">{{TotalCount}}</span>건</span>
                                </p>
                            </div>
                            <div class="table-responsive">
                                <table class="table table tbl-list tbl-center table-hover table-bordered" id="tb0">
                                    <caption>테이블</caption>
                                    <colgroup>
                                        <col />
                                        <col />
                                        <col />
                                        <col />
                                        <col />
                                        <col />
                                        <col />
                                        <col />
                                        <col />
                                        <col />
                                        <col />
                                        <col />
                                        <col />
                                        <col />
                                        <col />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th scope="col">이름</th>
                                            <th scope="col">프로젝트명</th>
                                            <th scope="col">계약기간</th>
                                            <th scope="col">1월</th>
                                            <th scope="col">2월</th>
                                            <th scope="col">3월</th>
                                            <th scope="col">4월</th>
                                            <th scope="col">5월</th>
                                            <th scope="col">6월</th>
                                            <th scope="col">7월</th>
                                            <th scope="col">8월</th>
                                            <th scope="col">9월</th>
                                            <th scope="col">10월</th>
                                            <th scope="col">11월</th>
                                            <th scope="col">12월</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="MonthlyPaymentHistoryList === null">
                                            <td colspan="15" class="text-center no-data">조회된 정보가 없습니다.</td>
                                        </tr>
                                        <tr v-else v-for="item in MonthlyPaymentHistoryList" style="cursor:pointer;">
                                            <td>{{item.Name}}</td>
                                            <td>{{item.ProjectNm}}</td>
                                            <td>{{item.WorkStartYMD}}<br />~<br />{{item.WorkEndYMD}}</td>
                                            <td style="text-align:right;">{{item.Pay1}}</td>
                                            <td style="text-align:right;">{{item.Pay2}}</td>
                                            <td style="text-align:right;">{{item.Pay3}}</td>
                                            <td style="text-align:right;">{{item.Pay4}}</td>
                                            <td style="text-align:right;">{{item.Pay5}}</td>
                                            <td style="text-align:right;">{{item.Pay6}}</td>
                                            <td style="text-align:right;">{{item.Pay7}}</td>
                                            <td style="text-align:right;">{{item.Pay8}}</td>
                                            <td style="text-align:right;">{{item.Pay9}}</td>
                                            <td style="text-align:right;">{{item.Pay10}}</td>
                                            <td style="text-align:right;">{{item.Pay11}}</td>
                                            <td style="text-align:right;">{{item.Pay12}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        <CP1020P ref="CP1020P"></CP1020P>
        <CP1030P ref="CP1030P"></CP1030P>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle'
    import CP1020P from '@/components/libs/popup/CP1020P'
    import CP1030P from '@/components/libs/popup/CP1030P'
    export default {
        name: 'RT1110R',
        components: {
            ViewTitle, CP1020P, CP1030P
        },
        data() {
            return {

                MonthlyPaymentHistoryList: null, // 월별 지급 내역 리스트
                YearsList: new Array(),                 // 년도 콤보박스 목록
                Year: this.moment(new Date()).year(),   // 년도
                selBelongType: 'A', // 소속 구분: A: 개인 B: 사업자
                Arg: 'L',
                TotalCount: 0 ,                          // 데이터 총 개수

                // 조회조건 데이터 객체
                MonthlyPaymentHistoryTerm: {
                    EmpName: null,
                    EmpNo: null
                }
            }
        },
        beforeMount() {

            var startYear = this.moment(new Date()).add(1, 'year').year()
            var endYear = this.moment(new Date(2010, 1, 1, 0, 0, 0)).year()

            // 대상년도 목록 값 설정
            for (var i = startYear; i >= endYear; i--) {

                this.YearsList.push(i)
            }
        },
        mounted() {
        },
        methods: {
            // 인력및 업체 검색 메서드
            searchManPowerCode: function () {

                var parent = this
                if (this.selBelongType === 'A') {

                    var successCallback = function (result) {

                        // 결과값을 추출 후 돔객체에 바인딩
                        parent.MonthlyPaymentHistoryTerm.EmpName = result.EmpNm
                        parent.MonthlyPaymentHistoryTerm.EmpNo = result.EmpNo
                    }

                    this.$refs.CP1020P.open(successCallback)
                }
                else {

                    var successCallback = function (result) {

                        parent.MonthlyPaymentHistoryTerm.EmpName = result.CompanyNm
                        parent.MonthlyPaymentHistoryTerm.EmpNo = result.CompanyCd
                    }

                    this.$refs.CP1030P.open(successCallback)
                }

            },
            // 인력 코드 초기화 메서드
            clearManPowerCode: function () {

                this.MonthlyPaymentHistoryTerm.EmpName = null
                this.MonthlyPaymentHistoryTerm.EmpNo = null
            },
            // 조회 버튼 클릭 이벤트 핸들러
            btnSearchClicked: function () {

                // 조회 유효성 검사
                if (this.MonthlyPaymentHistoryTerm.EmpName === null || this.MonthlyPaymentHistoryTerm.EmpName === '') {

                    alert('개인 또는 사업자를 입력해 주세요.')
                    return
                }

                this.showData()
            },
            // 월별 지급 내역 조회 메서드
            showData: function () {

                var parent = this
                var url = '/Mini.Service.IBS.RT.Service/AccountingReport.svc/ajax/GetMonthlyPaymentHistory_Report'
                url = url.toRealServiceUrl()

                var obj = new Object()
                obj.args = this.Arg
                obj.year = this.Year
                obj.empNo = this.MonthlyPaymentHistoryTerm.EmpNo

                if (this.selBelongType === 'A') {
                    obj.companyCode = ''
                }
                else {
                    obj.companyCode = this.$parent.CompanyCode
                }

                // ajax 성공 시 실행되는 핸들러 정의
                var ajaxSuccessHandler = function (data) {

                    var table = JSON.parse(data.d).Table

                    if (table.length > 0) {

                        parent.MonthlyPaymentHistoryList = table
                    }
                    else {

                        parent.MonthlyPaymentHistoryList = null
                    }

                    if (table.length > 0) {

                        parent.TotalCount = table.length
                    }
                    else {

                        parent.TotalCount = 0
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
            },
            // PDF 버튼 클릭 이벤트 핸들러
            fn_export: function () {

                if (this.TotalCount === 0) {

                    alert('조회된 데이터가 없습니다.')
                    return
                }

                // split해서 사용 [0]:arg, [1]:chkYN,
                var Jsonstring = 'arg=RT_MonthlyPaymentHistory&trgtYY=' + $("#selBelongYY").val()
                    + "&selBelongType=" + this.selBelongType
                    + "&fieldCd=" + $("#hidFieldCd").val()
                $.Download("/Handlers/ReportHandler.ashx", Jsonstring)
            }
        }
    }
</script>