<template>
    <div>
        <DxDataGrid :show-borders="true"
                    :data-source="ManInputMMListAll"
                    :hoverStateEnabled="true"
                    @cell-click="openInputMMDetail">
            <DxColumn data-field="EmpNm"
                      caption="성명"
                      alignment="center"
                      width="150" />
            <DxColumn data-field="Rating"
                      caption="역할"
                      alignment="center"
                      width="150" />
            <DxColumn data-field="Role"
                      caption="등급"
                      alignment="center"
                      width="150" />
            <DxColumn data-field="MainJob"
                      caption="주요업무"
                      alignment="center" />
            <DxColumn data-field="TotalInputMM"
                      caption="투입MM"
                      alignment="center"
                      width="150" />
        </DxDataGrid>
        <PM1006E ref="PM1006E"></PM1006E>
    </div>
</template>

<script>
    import { DxDataGrid, DxColumn, DxMasterDetail } from 'devextreme-vue/data-grid';
    import PM1006E from '@/components/pm/popup/PM1006E';

    export default {
        name: 'PM100R_Detail',
        components: {
            DxDataGrid, DxColumn, DxMasterDetail, PM1006E
        },
        props: {
            templateData: {
                type: Object,
                default: () => { }
            }
        },
        data() {
            return {
                ManInputMMListAll: new Array(),
                ManInputSelectTerm: {
                    Arg: 'N',
                    ProjectCd: null,
                    RoleSeq: null,
                    TargetYear: null,
                    StatusCd: null
                },
            };
        },
        created() {

            this.ManInputSelectTerm.ProjectCd = this.templateData.data.ProjectCd;
            this.ManInputSelectTerm.RoleSeq = this.templateData.data.RoleSeq;
            this.getManInputMM();
        },
        methods: {
            // 목록 조회
            getManInputMM() {

                var parent = this;

                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/GetNewManpower'.toRealServiceUrl();
                var obj = new Object();
                obj.jsonString = JSON.stringify(parent.ManInputSelectTerm);

                var ajaxSuccessHandler = function (data) {

                    var jsonObj = JSON.parse(data.d);
                    parent.ManInputMMListAll = jsonObj.Table1;
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler);
            },
            openInputMMDetail(e) {

                var parent = this;

                if (e.rowType == 'header') {

                    return;
                }

                var callbackFunction = function () {

                    // PM1070R 그리드 재조회
                    parent.$parent.$parent.$parent.getManpowerList();
                }

                // 운용인력관리 팝업 오픈
                this.$refs.PM1006E.open(callbackFunction, e.data);
            }
        }
    };
</script>