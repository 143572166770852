<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <div class="btn btn-lg btn-row">
                    <span><i id="info" class="glyphicon glyphicon-question-sign"></i></span>
                    <DxPopover :width="500" target="#info" show-event="mouseenter" hide-event="mouseleave" position="left" :contentTemplate="'template1'">
                        <template #template1>
                            <p>
                                * 현재까지의 자금현황과 미래 예상내역을 조회할 수 있습니다.<br /><br />
                                * 빨간 글씨로 표시된 거래내용 항목은, 입출금이 진행되지 않은 입출금관리 내역을 표시합니다. 입출금명을 확인하여 작업해 주십시오.<br /><br />
                                * 빨간 글씨로 표시된 계좌잔액 항목은, 매칭이 진행되지 않은 은행데이터가 존재하여 잔액이 올바르지 않음을 표시합니다. 매칭을 진행해 주십시오.
                            </p>
                        </template>
                    </DxPopover>
                </div>
                <a href="#" id="btnListSch" class="btn btn-primary btn-row" @click.prevent="getInOutAmtReport">
                    <span>
                        <i class="glyphicon glyphicon-search"></i> 조회
                    </span>
                </a>
            </div>
            <div class="sch-box">
                <form class="form-horizontal" role="form">
                    <fieldset>
                        <legend>검색폼</legend>
                        <section class="sch-cont">
                            <section class="cols-wrap">
                                <div class="cols col-2">
                                    <label for="txtTradeYMD" class="control-label"><span>거래일자</span></label>
                                    <div>
                                        <label for="txtStartDate" class="sr-only"><span>거래일자</span></label>
                                        <div class="col-2 input-group date">
                                            <DxDateBox display-format="yyyy-MM-dd"
                                                       pickerType="calendar"
                                                       v-model="InOutAmtReportSelectTerm.StartDate"
                                                       @value-changed="getInOutAmtReport"
                                                       style="border-radius:0;" />
                                        </div>
                                        <div class="tail"><span>~</span></div>
                                        <label for="txtEndDate" class="sr-only"><span>거래일자</span></label>
                                        <div class="col-2 input-group date">
                                            <DxDateBox display-format="yyyy-MM-dd"
                                                       pickerType="calendar"
                                                       v-model="InOutAmtReportSelectTerm.EndDate"
                                                       @value-changed="getInOutAmtReport"
                                                       style="border-radius:0;" />
                                        </div>
                                    </div>
                                </div>
                                <div class="cols col-3" style="display: flex; flex-wrap: wrap;">
                                    <label for="chkEstStartRow" class="control-label"><span>예상내역 이동</span></label>
                                    <input class="checkbox-inline" type="checkbox" ref="chkEstStartRow" id="chkEstStartRow" name="chkEstStartRow" v-model="FocusYN" @change="scrollToBaseLine" />
                                </div>
                            </section>
                        </section>
                    </fieldset>
                </form>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">자금현황 및 판관비</p>
                </div>
                <div>
                    <section class="tbl-contents">
                        <div class="table-responsive">
                            <DxDataGrid :data-source="InOutAmtReport"
                                        key-expr="RowNo"
                                        :ref="InOutAmtReportGrid"
                                        :hoverStateEnabled="true"
                                        :allow-column-resizing="true"
                                        :column-resizing-mode="'widget'"
                                        :column-auto-width="true"
                                        :row-alternation-enabled="true"
                                        :show-borders="false"
                                        @cell-prepared="onCellPrepared"
                                        style="max-height: 500px; vertical-align: top;">
                                <DxPaging :enabled="false" />
                                <DxScrolling mode="standard" useNative="false" />
                                <DxColumn data-field="RowNo" caption="No" alignment="center" />
                                <DxColumn data-field="InOutDvsNm" caption="입출구분" alignment="center" />
                                <DxColumn data-field="TradeYMD" caption="거래일시" data-type="date" format="yyyy-MM-dd HH:mm:ss" alignment="center" />
                                <DxColumn data-field="TradeDateNm" caption="요일" alignment="center" />
                                <DxColumn data-field="InAmt" caption="입금액" format="fixedPoint" alignment="right" />
                                <DxColumn data-field="OutAmt" caption="출금액" format="fixedPoint" alignment="right" />
                                <DxColumn data-field="AfterTradeAmt" caption="계좌잔액" format="fixedPoint" alignment="right" />
                                <DxColumn data-field="TradeCont" caption="거래내용" alignment="left" />
                                <DxColumn data-field="OpptAcntNo" caption="상대계좌번호" alignment="left" />
                                <DxColumn data-field="OpptBankNm" caption="상대은행" alignment="left" />
                                <DxColumn data-field="OpptAcntNm" caption="상대계좌예금주명" alignment="left" />
                                <DxColumn data-field="AccountDetailNm" caption="입출금명" alignment="left" />
                                <DxColumn data-field="AcntSubjtNm" caption="계정과목명" alignment="center" />
                                <DxColumn data-field="ExpenseReportNo" caption="지출결의서/품의서번호" alignment="left" />
                            </DxDataGrid>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle';
    import { DxDataGrid, DxColumn, DxScrolling, DxPaging, } from 'devextreme-vue/data-grid';
    import DxDateBox, { DxCalendarOptions } from 'devextreme-vue/date-box';
    import { DxPopover } from 'devextreme-vue/popover';

    export default {
        name: 'RT1120R',
        components: {
            ViewTitle, DxDataGrid, DxColumn, DxScrolling, DxPaging, DxDateBox, DxCalendarOptions, DxPopover,
        },
        data() {
            return {
                // 조회조건 객체
                InOutAmtReportSelectTerm: {
                    StartDate: new Date(new Date().getFullYear(), new Date().getMonth() - 6, 1),
                    EndDate: new Date(new Date().getFullYear(), new Date().getMonth() + 6, 0),
                },
                // 예상내역 이동
                FocusYN: false,
                // 자금현황 및 판관비 목록
                InOutAmtReport: new Array(),
                InOutAmtReportGrid: 'dataGrid',
                // 예상내역 구분선 정보
                LastUpdatedDate: null,
            }
        },
        mounted() {

            this.getInOutAmtReport();
        },
        methods: {
            // Datagrid header 가운데 정렬, column 중앙 정렬
            onCellPrepared(e) {

                if (e.rowType == 'header') {

                    e.cellElement.style['textAlign'] = 'center';
                }
                if (e.rowType == 'data') {

                    e.cellElement.style['verticalAlign'] = 'middle';

                    // 실제 내역, 예상 내역 구분선
                    if (e.row.data.RowNo == '') {
                        e.cellElement.style['background-color'] = '#FFAB91';
                    }

                    // 검토 거래내용   or   계좌잔액 불일치
                    if ((e.row.data.InOutDvsNm == '검토' && e.column.dataField == 'TradeCont') || (e.row.data.etc == '누락' && e.column.dataField == 'AfterTradeAmt')) {
                        e.cellElement.style['color'] = 'red';
                    }
                }
            },
            // 자금현황 및 판관비 조회
            getInOutAmtReport() {

                let parent = this;

                this.InOutAmtReportSelectTerm.StartDate = this.moment(this.InOutAmtReportSelectTerm.StartDate).format('YYYYMMDD');
                this.InOutAmtReportSelectTerm.EndDate = this.moment(this.InOutAmtReportSelectTerm.EndDate).format('YYYYMMDD');

                let url = '/Mini.Service.IBS.RT.Service/AccountingReport.svc/ajax/SelectInOutAmtReport'.toRealServiceUrl();
                let obj = new Object();
                obj.jsonString = JSON.stringify(this.InOutAmtReportSelectTerm);

                var ajaxSuccessHandler = function (data) {

                    var jsonObj = JSON.parse(data.d);
                    var table = jsonObj.Table;
                    var table1 = jsonObj.Table1;

                    // 자금현황 및 판관비
                    parent.InOutAmtReport = (table.length > 0) ? table : new Array();

                    if (table1.length > 0) {

                        // 은행입출금 로그 가장 최근 입력 정보 (거래일시, 요일)
                        parent.LastUpdatedDate = table1;
                        parent.$refs['chkEstStartRow'].disabled = false;
                        parent.setBaseLine();
                        parent.checkAfterTradeAmt();
                    } else {

                        parent.LastUpdatedDate = null;
                        parent.$refs['chkEstStartRow'].disabled = true;
                    }

                    parent.FocusYN = false;
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler);
            },
            // 실제 내역, 예상 내역 구분선 추가
            setBaseLine() {

                let baseObject = {
                    RowNo: '',
                    InOutDvsNm: '-',
                    TradeYMD: this.LastUpdatedDate[0].LastUpdatedDate,
                    TradeDateNm: this.LastUpdatedDate[0].LastUpdatedDateNm,
                    OutAmt: '',
                    InAmt: '',
                    AfterTradeAmt: this.LastUpdatedDate[0].LastUpdatedBalance,
                    TradeCont: '↑ 실제 내역 / ↓ 예상 내역',
                    OpptAcntNo: '',
                    OpptBankNm: '',
                    OpptAcntNm: '',
                    AcntSubjtNm: '',
                    ExpenseReportNo: ''
                }

                // 전체 배열의 마지막 인덱스
                let lastIndex = this.InOutAmtReport.length - 1;

                //      첫번째 인덱스의 거래일시      <=    마지막 은행데이터 거래일시
                if (this.InOutAmtReport[0].TradeYMD <= this.LastUpdatedDate[0].LastUpdatedDate) {
                    //      마지막 인덱스의 거래일시             <=     마지막 은행데이터 거래일시
                    if (this.InOutAmtReport[lastIndex].TradeYMD <= this.LastUpdatedDate[0].LastUpdatedDate) {

                        // 마지막 로우에 구분선 추가
                        this.InOutAmtReport.splice(lastIndex + 1, 0, baseObject);
                        // 마지막 인덱스의 거래일시   >   마지막 은행데이터 거래일시
                    } else {

                        let baseIndex = this.InOutAmtReport.findIndex((item) => item.TradeYMD > this.LastUpdatedDate[0].LastUpdatedDate);
                        // 중간에 구분선 추가
                        this.InOutAmtReport.splice(baseIndex, 0, baseObject);
                    }
                    // 첫번째 인덱스의 거래일시 > 마지막 은행데이터 거래일시
                } else {
                    // 첫번째 로우에 구분선 추가
                    this.InOutAmtReport.splice(0, 0, baseObject);
                }
            },
            // 매칭되지 않은 은행데이터 존재 여부 확인
            checkAfterTradeAmt() {

                let AfterTradeAmt = new Array();
                let AmtObj = {
                    RowNo: 0,
                    InAmt: 0,
                    OutAmt: 0,
                    AfterTradeAmt: 0,
                };

                // 구분선 인덱스
                let baseIndex = this.InOutAmtReport.findIndex((item) => item.InOutDvsNm == '-');

                if (baseIndex == 0) {

                    return;
                }

                if (this.InOutAmtReport[0].RowNo == 1 && this.InOutAmtReport[0].InOutDvsNm != '검토') {

                    // 구분선 위의 실제 내역만 검토
                    for (let i = 0; i < baseIndex; i++) {

                        let NewAmtObj = { ...AmtObj };

                        if (this.InOutAmtReport[i].InOutDvsNm != '검토') {

                            NewAmtObj.RowNo = this.InOutAmtReport[i].RowNo;
                            NewAmtObj.InAmt = this.InOutAmtReport[i].InAmt;
                            NewAmtObj.OutAmt = this.InOutAmtReport[i].OutAmt;
                            NewAmtObj.AfterTradeAmt = this.InOutAmtReport[i].AfterTradeAmt;

                            AfterTradeAmt.push(NewAmtObj);
                        }
                    }
                } else {

                    // 구분선 위의 실제 내역만 검토
                    for (let i = 1; i < baseIndex; i++) {

                        let NewAmtObj = { ...AmtObj };

                        if (this.InOutAmtReport[i].InOutDvsNm != '검토') {

                            NewAmtObj.RowNo = this.InOutAmtReport[i].RowNo;
                            NewAmtObj.InAmt = this.InOutAmtReport[i].InAmt;
                            NewAmtObj.OutAmt = this.InOutAmtReport[i].OutAmt;
                            NewAmtObj.AfterTradeAmt = this.InOutAmtReport[i].AfterTradeAmt;

                            AfterTradeAmt.push(NewAmtObj);
                        }
                    }

                    let SecondRowData = AfterTradeAmt[0];
                    let NewAmtObj = { ...AmtObj };

                    NewAmtObj.RowNo = this.InOutAmtReport[0].RowNo;
                    NewAmtObj.InAmt = -(this.InOutAmtReport[0].InAmt);
                    NewAmtObj.OutAmt = -(this.InOutAmtReport[0].OutAmt);
                    NewAmtObj.AfterTradeAmt = SecondRowData.AfterTradeAmt - SecondRowData.InAmt + SecondRowData.OutAmt;

                    AfterTradeAmt.splice(0, 0, NewAmtObj);
                }

                for (let i = 0; i < AfterTradeAmt.length - 1; i++) {

                    let calculatedAmt = AfterTradeAmt[i + 1].InAmt - AfterTradeAmt[i + 1].OutAmt;

                    if (AfterTradeAmt[i].AfterTradeAmt != AfterTradeAmt[i + 1].AfterTradeAmt - calculatedAmt) {

                        let errorDataIndex = this.InOutAmtReport.findIndex((row) => row.RowNo == AfterTradeAmt[i + 1].RowNo);
                        this.InOutAmtReport[errorDataIndex].etc = '누락';
                    }
                }
            },
            // 예상내역 구분선으로 스크롤 이동
            scrollToBaseLine() {

                // 체크박스 체크 시
                if (this.FocusYN) {

                    let dataGrid = this.$refs[this.InOutAmtReportGrid].$_instance;

                    let baseIndex = this.InOutAmtReport.findLastIndex((item) => item.TradeYMD == this.LastUpdatedDate[0].LastUpdatedDate);
                    let baseLine = dataGrid.getRowElement(baseIndex);

                    dataGrid.getScrollable(0).scrollToElement(baseLine);
                }
            }
        }
    }
</script>