<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->

                    <a v-show="this.$parent.CanSearch" href="#" id="btnListSrch" class="btn btn-primary btn-row2" @click.prevent="fn_ShowData(false)">
                        <span>
                            <i class="glyphicon glyphicon-search"></i> 조회
                        </span>
                    </a>
                    <a v-show="this.$parent.CanNew" href="#" id="btnListInst" class="btn btn-default" @click.prevent="fn_InstData">
                        <span>
                            <i class="glyphicon glyphicon-plus"></i> 추가
                        </span>
                    </a>
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-3">
                                        <label for="selCardCorpCd" class="control-label">
                                            <span>카드사</span>
                                        </label>
                                        <select class="form-control" id="selCardCorpCd" name="selCardCorpCd" v-model="CompanyCardSelectTerm.CardCompanyCode">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in CardCompanyCodeList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-3">
                                        <label for="selUseYN" class="control-label">
                                            <span>사용여부</span>
                                        </label>
                                        <select id="selUseYN" class="form-control" v-model="CompanyCardSelectTerm.UseYn">
                                            <option value="">== 전체 ==</option>
                                            <option value="Y" selected="selected">Y</option>
                                            <option value="N">N</option>
                                        </select>
                                    </div>
                                    <div class="cols col-3">
                                        <label for="selUseYN" class="control-label">
                                            <span>회수여부</span>
                                        </label>
                                        <select id="RecallYN" class="form-control" v-model="CompanyCardSelectTerm.RecallYn">
                                            <option value="">== 전체 ==</option>
                                            <option value="Y">Y</option>
                                            <option value="N">N</option>
                                        </select>
                                    </div>
                                </section>
                            </section>
                            <section class="sch-btn">
                            </section>
                        </fieldset>
                    </form>
                    </div>

                </div>
                <div class="grid-wrap">
                    <div class="sub-title">
                        <p class="sub-title-txt">법인카드수불 관리</p>
                    </div>
                    <div>
                        <!--리스트 영역-->
                        <section class="tbl-contents">
                            <div class="alert alert-info total">
                                <p>
                                    <i class="glyphicon glyphicon-folder-close"></i>
                                    전체: <span id="spTotCnt">{{CompanyCardListTotalCount}}</span>건
                                </p>
                            </div>
                            <div class="table-responsive">
                                <table class="table table tbl-list tbl-center table-hover table-bordered" id="tb0">
                                    <caption>테이블</caption>
                                    <colgroup>
                                        <col class="table-colwid15" />
                                        <col class="table-colwid10" />
                                        <col class="table-colwid10" />
                                        <col class="table-colwid10" />
                                        <col class="table-colwid20" />
                                        <col class="table-colwid10" />
                                        <col class="table-colwid10" />
                                        <col class="table-colwid20" />
                                        <col class="table-colwid10" />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th scope="col">카드사</th>
                                            <th scope="col">카드번호</th>
                                            <th scope="col">지급일자</th>
                                            <th scope="col">소유자</th>
                                            <th scope="col">프로젝트</th>
                                            <th scope="col">사용여부</th>
                                            <th scope="col">회수여부</th>
                                            <th scope="col">비고</th>
                                            <th scope="col">수불관리</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="CompanyCardList === null">
                                            <td colspan="9" class="text-center no-data">조회된 정보가 없습니다.</td>
                                        </tr>
                                        <tr v-else v-for="companyCard in CompanyCardList" style="cursor:pointer;" @click="rowSelectEvent(companyCard)">
                                            <td>{{companyCard.CardCorpName}}</td>
                                            <td>{{companyCard.CardNo}}</td>
                                            <td>{{companyCard.UsedDate}}</td>
                                            <td>{{companyCard.UsedEmpNm}}</td>
                                            <td v-if="companyCard.RecallYn === 'N'" style="text-align:left">{{companyCard.ProjectNm}}</td>
                                            <td v-else></td>
                                            <td>{{companyCard.UseYn}}</td>
                                            <td> {{companyCard.RecallYn}}</td>
                                            <td style="text-align:left">{{companyCard.EtcTxt}}</td>
                                            <td another="A">
                                                <button type="button" class="btn btn-warning btn-xs" @click="fn_ShowData_AC1042E(companyCard, false)">
                                                    수불관리
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </section>
                    </div>
                </div>
            </div>
        <!--상세모달 팝업-->
        <div class="modal fade" ref="AC1041E_Modal" tabindex="-1" role="dialog" aria-labelledby="AC1041E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">
                                Close
                            </span>
                        </button>
                        <h3 class="modal-title">법인카드 관리 등록/수정</h3>
                    </div>
                    <div class="modal-body">
                        <section class="add-wrap">
                            <form>
                                <fieldset>
                                    <legend>..</legend>
                                    <section class="cols-wrap">
                                        <div class="cols col-3">
                                            <label for="AC1041E_selCardCorpCd" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>카드사
                                                </span>
                                            </label>
                                            <select class="form-control" id="AC1041E_selCardCorpCd" name="AC1041E_selCardCorpCd"
                                                    v-model="CompanyCardDetail.CardCorpCd"
                                                    v-bind:disabled="PageActionType === 'V'">
                                                <option value="">== 선택 ==</option>
                                                <option v-for="item in CardCompanyCodeList" v-bind:value="item.Code">
                                                    {{item.Name}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="cols col-3">
                                            <label for="txtCardNo2" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>카드번호
                                                </span>
                                            </label>
                                            <input type="text" id="txtCardNo2" class="form-control " maxlength="20"
                                                   v-bind:value="CompanyCardDetail.CardNo"
                                                   v-bind:disabled="PageActionType === 'V'"
                                                   @input="function(e){
                                                        CompanyCardDetail.CardNo = e.target.value.toCardNumberFoormat()
                                                        e.target.value = e.target.value.toCardNumberFoormat()
                                                   }" />
                                        </div>
                                        <div class="cols col-3">
                                            <label for="selUseYN2" class="control-label asterisk">
                                                <span>사용여부</span>
                                            </label>
                                            <input type="hidden" name="hidCorpCd2" :value="this.$parent.CompanyCode" />
                                            <select id="selUseYN2" class="form-control" v-model="CompanyCardDetail.UseYn">
                                                <option value="Y">Y</option>
                                                <option value="N">N</option>
                                            </select>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-12">
                                            <label for="txtEtcTxt2" class="control-label">비고</label>
                                            <input type="text" id="txtEtcTxt2" class="form-control txt" v-model="CompanyCardDetail.EtcTxt" />
                                        </div>
                                    </section>
                                </fieldset>
                            </form>
                        </section>
                    </div>
                    <div class="modal-footer btn-align">
                        <div class="btn-group">
                            <a v-show="this.$parent.CanSave" href="#" id="btnItemSave" class="btn btn-primary" @click.prevent="fn_SaveData">
                                <span>
                                    <i class="glyphicon glyphicon-save"></i> 저장
                                </span>
                            </a>
                            <a v-show="this.$parent.CanDelete && PageActionType != 'I'" href="#" id="btnItemRmve" class="btn btn-warning" @click.prevent="fn_RemoveData">
                                <span>
                                    <i class="glyphicon glyphicon-edit"></i> 삭제
                                </span>
                            </a>
                            <a href="#" class="btn btn-danger" data-dismiss="modal">
                                <span>
                                    <i class="glyphicon glyphicon-remove"></i> 취소
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--수불관리모달 팝업-->
        <div class="modal fade" ref="AC1042E_Modal" tabindex="-1" role="dialog" aria-labelledby="AC1042E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <section class="tbl-contents">
                            <article class="view-btns-wrap ">
                                <div class="btn-group-justified">
                                    <a v-show="this.$parent.CanNew" href="#" id="AC1042E_btnListInst" class="btn btn-primary btn-row2" @click.prevent="fn_InstData_AC1042E">
                                        <span class="glyphicon glyphicon-plus"></span>
                                        <span>추가</span>
                                    </a>
                                </div>
                            </article>
                            <div class="alert alert-info total">
                                <span class="glyphicon glyphicon-folder-close"></span>
                                <span>
                                    전체: <span id="AC1042E_spTotCnt">{{CompanyCardListTotalCount_AC1042E}}</span>건
                                </span>
                            </div>
                            <div class="table-responsive">
                                <table class="table table tbl-list tbl-center table-hover table-bordered" id="AC1042E_tb0">
                                    <caption>테이블</caption>
                                    <colgroup>
                                        <col class="table-colwid20" />
                                        <col class="table-colwid20" />
                                        <col class="table-colwid10" />
                                        <col class="table-colwid10" />
                                        <col class="table-colwid10" />
                                        <col class="table-colwid15" />
                                        <col class="table-colwid5" />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th scope="col">카드사</th>
                                            <th scope="col">카드번호</th>
                                            <th scope="col">수령자</th>
                                            <th scope="col">수령일자</th>
                                            <th scope="col">회수일자</th>
                                            <th scope="col">프로젝트</th>
                                            <th scope="col">수령확인</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="CompanyCardList_AC1042E === null">
                                            <td colspan="7" class="text-center no-data">조회된 정보가 없습니다.</td>
                                        </tr>
                                        <tr v-else v-for="companyCard in CompanyCardList_AC1042E" style="cursor:pointer;" @click="rowSelectEvent_AC1042E(companyCard)">
                                            <td style="text-align:left;">{{companyCard.CardCorpName}}</td>
                                            <td>{{companyCard.CardNo}}</td>
                                            <td>{{companyCard.RecvEmpNm}}</td>
                                            <td>{{companyCard.RecvYMD}}</td>
                                            <td>{{companyCard.RtnYMD}}</td>
                                            <td style="text-align:left;">{{companyCard.ProjectNm}}</td>
                                            <td v-if="companyCard.IsRecvEmp === 'Y' && companyCard.IsRecvEnd === 'N'" another="R">
                                                <button v-show="this.$parent.CanSave" type="button" class="btn btn-warning btn-xs">수령확인</button>
                                            </td>
                                            <td v-else another="R"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="text-center">
                                <ul id="AC1042E_ulpager" class="pagination"></ul>
                            </div>
                            <section class="btn-align">
                                <div class="btn-group">
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">
                                        <i class="glyphicon glyphicon-remove"></i> 취소
                                    </button>
                                </div>
                            </section>
                        </section>
                    </div>
                </div>
            </div>
        </div>
        <!--수불관리모달 신규팝업-->
        <div class="modal fade" ref="AC1043E_Modal" tabindex="-1" role="dialog" aria-labelledby="AC1043E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                        <h3 class="modal-title">법인카드 수불 내역 등록/수정</h3>
                    </div>
                    <div class="modal-body">
                        <section class="add-wrap">
                            <form>
                                <fieldset>
                                    <legend>..</legend>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="AC1043E_txtCardCorpNm2" class="control-label">
                                                <span class="glyphicon glyphicon-asterisk"></span>카드사
                                            </label>
                                            <input type="text" id="AC1043E_txtCardCorpNm2" class="form-control txt" readonly="readonly" v-model="CompanyCardDetail_AC1042E.CardCorpName" />
                                            <input type="hidden" id="AC1043E_hidCardCorpCd2" v-model="CompanyCardDetail_AC1042E.CardCorpCd" />
                                        </div>
                                        <div class="cols col-2">
                                            <label for="AC1043E_txtCardNo2" class="control-label">
                                                <span class="glyphicon glyphicon-asterisk"></span>카드번호
                                            </label>
                                            <input type="text" id="AC1043E_txtCardNo2" class="form-control txt" readonly="readonly" v-model="CompanyCardDetail_AC1042E.CardNo" />
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="AC1043E_txtRecvYMD2" class="control-label">
                                                <span></span>수령일자
                                            </label>
                                            <div class="input-group date form_date" v-bind:data-date="CurrentDateYYMMDD" data-date-format="yyyy-mm-dd">
                                                <input type="text" id="AC1043E_txtRecvYMD2" class="form-control" maxlength="10"
                                                       v-bind:value="CompanyCardDetail_AC1042E.RecvYMD"
                                                       @input="function(e){
                                                                        CompanyCardDetail_AC1042E.RecvYMD = e.target.value.toHyphenationFormatYMD()
                                                                        e.target.value = e.target.value.toHyphenationFormatYMD()
                                                                   }" />
                                                <span class="input-group-addon">
                                                    <span class="glyphicon glyphicon-calendar"></span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="AC1043E_txtRecvEmpNm2" class="control-label">
                                                <span class="glyphicon glyphicon-asterisk"></span>수령자
                                            </label>
                                            <div class="cols-btns-cont">
                                                <input type="text" ref="AC1043E_txtRecvEmpNm2" class="form-control txt" readonly="readonly" v-model="CompanyCardDetail_AC1042E.RecvEmpNm" />
                                                <input type="hidden" id="AC1043E_hidRecvEmpNo2" v-model="CompanyCardDetail_AC1042E.RecvEmpNo" />
                                            </div>
                                            <div class="cols-btns">
                                                <button type="button" id="AC1043E_btnItemRecv" class="btn btn-default"
                                                        v-show="PageActionType === 'I_AC1042E'
                                                                    || (CompanyCardDetail_AC1042E.IsInstEmp === 'Y'
                                                                    && CompanyCardDetail_AC1042E.IsRecvEnd === 'N'
                                                                    && CompanyCardDetail_AC1042E.IsRetnEnd === 'N')"
                                                        @click="searchAcceptor">
                                                    <span class="glyphicon glyphicon-search"></span> 검색
                                                </button>
                                            </div>
                                        </div>
                                    </section>
                                    <section class="cols-wrap" id="rtnGroup">
                                        <div class="cols col-2">
                                            <label for="AC1043E_txtRtnYMD2" class="control-label">
                                                <span></span>회수일자
                                            </label>
                                            <div class="input-group date form_date" v-bind:data-date="CurrentDateYYMMDD" data-date-format="yyyy-mm-dd">
                                                <input type="text" id="AC1043E_txtRtnYMD2" class="form-control" maxlength="10"
                                                       v-bind:value="CompanyCardDetail_AC1042E.RtnYMD"
                                                       @input="function(e){
                                                                        CompanyCardDetail_AC1042E.RtnYMD = e.target.value.toHyphenationFormatYMD()
                                                                        e.target.value = e.target.value.toHyphenationFormatYMD()
                                                                   }" />
                                                <span class="input-group-addon">
                                                    <span class="glyphicon glyphicon-calendar"></span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="AC1043E_txtRtnEmpNm2" class="control-label">
                                                <span class="glyphicon glyphicon-asterisk"></span>회수자
                                            </label>
                                            <div class="cols-btns-cont">
                                                <input type="text" id="AC1043E_txtRtnEmpNm2" class="form-control txt" readonly="readonly" v-model="CompanyCardDetail_AC1042E.RtnEmpNm" />
                                                <input type="hidden" id="AC1043E_hidRtnEmpNo2" v-model="CompanyCardDetail_AC1042E.RtnEmpNo" />
                                            </div>
                                            <div class="cols-btns">
                                                <button type="button" id="AC1043E_btnItemRtn" class="btn btn-default"
                                                        v-show="PageActionType === 'I_AC1042E'
                                                                || (CompanyCardDetail_AC1042E.IsInstEmp === 'Y'
                                                                && CompanyCardDetail_AC1042E.IsRecvEnd === 'Y'
                                                                && CompanyCardDetail_AC1042E.IsRetnEnd === 'N')"
                                                        @click="searchRepossessor">
                                                    <span class="glyphicon glyphicon-search"></span> 검색
                                                </button>
                                            </div>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="AC1043E_txtTraderNm" class="control-label">
                                                <span></span>거래처
                                            </label>
                                            <input type="text" id="AC1043E_txtTraderNm2" class="form-control txt" readonly="readonly" v-model="CompanyCardDetail_AC1042E.TraderNm" />
                                            <input type="hidden" id="AC1043E_hidTraderCd2" v-model="CompanyCardDetail_AC1042E.CompanyCd" />
                                        </div>
                                        <div class="cols col-2">
                                            <label for="AC1043E_txtProjectNm2" class="control-label">
                                                <span></span>프로젝트
                                            </label>
                                            <div class="cols-btns-cont">
                                                <input type="text" id="AC1043E_txtProjectNm2" class="form-control txt" readonly="readonly" v-model="CompanyCardDetail_AC1042E.ProjectNm" />
                                                <input type="hidden" id="AC1043E_hidProjectCd2" v-model="CompanyCardDetail_AC1042E.ProjectCd" />
                                            </div>
                                            <div class="cols-btns">
                                                <button type="button" id="AC1043E_btnItemProject" class="btn btn-default"
                                                        v-show="PageActionType === 'I_AC1042E'
                                                                    || (CompanyCardDetail_AC1042E.IsInstEmp === 'Y'
                                                                    && CompanyCardDetail_AC1042E.IsRecvEnd === 'N'
                                                                    && CompanyCardDetail_AC1042E.IsRetnEnd === 'N')"
                                                        @click="searchProject">
                                                    <span class="glyphicon glyphicon-search"></span> 검색
                                                </button>
                                            </div>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols">
                                            <label for="AC1043E_txtEtcTxt2" class="control-label">
                                                <span></span>비고
                                            </label>
                                            <input type="text" id="AC1043E_txtEtcTxt2" class="form-control txt" v-model="CompanyCardDetail_AC1042E.EtcTxt" />
                                        </div>
                                    </section>
                                </fieldset>
                            </form>
                        </section>
                    </div>
                    <div class="modal-footer btn-align">
                        <div v-show="this.$parent.CanSave" class="btn-group">
                            <button type="button" id="AC1043E_btnItemSave" class="btn btn-primary" @click="fn_SaveData_AC1042E">
                                <i class="glyphicon glyphicon-save"></i> 저장
                            </button>
                        </div>
                        <div v-show="this.$parent.CanDelete && PageActionType != 'I_AC1042E'" class="btn-group">
                            <button type="button" id="AC1043E_btnItemRmve" class="btn btn-warning" @click="fn_RemoveData_AC1042E">
                                <i class="glyphicon glyphicon-edit"></i> 삭제
                            </button>
                        </div>
                        <div class="btn-group">
                            <button type="button" class="btn btn-danger" data-dismiss="modal">
                                <i class="glyphicon glyphicon-remove"></i> 취소
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <CP1020P ref="CP1020P"></CP1020P>
        <CP1060P ref="CP1060P"></CP1060P>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle'
    import CP1020P from '@/components/libs/popup/CP1020P'
    import CP1060P from '@/components/libs/popup/CP1060P'
    export default {
        name: 'AC1040',
        components: {
            ViewTitle, CP1020P, CP1060P
        },
        data() {
            return {
                CardCompanyCodeList: null,
                CompanyCardList: null,
                CompanyCardList_AC1042E: null,
                // 상세 데이터 객체
                CompanyCardDetail: {
                    CardCorpName: null,
                    CardNo: null,
                    CardCorpCd: null,
                    CorpCd: null,
                    UseYn: null,
                    EtcTxt: null,
                    RecallYn: null,
                    UsedEmpNm: null,
                    UsedDate: null,
                    ProjectNm: null,
                    OrderSeq: null
                },
                // 수불목록 상세 데이터 객체
                CompanyCardDetail_AC1042E: {
                    RecallYn: null,
                    CorpCd: null,
                    CardNo: null,
                    SeqNo: null,
                    CardCorpCd: null,
                    CardCorpName: null,
                    SendYMD: null,
                    SendEmpNo: null,
                    SendEmpNm: null,
                    RecvYMD: null,
                    RecvEmpNo: null,
                    RecvEmpNm: null,
                    RtnYMD: null,
                    RtnEmpNo: null,
                    RtnEmpNm: null,
                    CompanyCd: null,
                    TraderNm: null,
                    ProjectCd: null,
                    ProjectNm: null,
                    EtcTxt: null,
                    IsInstEmp: null,
                    IsRecvEmp: null,
                    IsRecvEnd: null,
                    IsRetnEnd: null
                },
                // 조회조건 데이터 객체
                CompanyCardSelectTerm: {
                    Arg: 'PL',
                    UserId: this.$parent.EmpId,
                    CompanyCode: this.$parent.CompanyCode,
                    CardCompanyCode: '',
                    UseYn: 'Y',
                    RecallYn: '',
                    TopSize: 10,
                    CurrentPage: 1
                },
                // 수불목록 조회조건 데이터 객체
                CompanyCardSelectTerm_AC1042E: {
                    Arg: 'CL',
                    UserId: this.$parent.EmpId,
                    CompanyCode: this.$parent.CompanyCode,
                    CardNo: '',
                    CardCompanyName: '',
                    CardCompanyCode: '',
                    TopSize: 10,
                    CurrentPage: 1
                },
                CompanyCardListTotalCount: 0,
                CompanyCardListTotalCount_AC1042E: 0,
                PageActionType: 'V',
                CurrentDateYYMMDD: this.moment(new Date()).format('YYYY-MM-DD')
            }
        },
        beforeMount() {

            this.CardCompanyCodeList = $.scriptBase.getCommonCode('CODE000', '0103000', '', '')
        },
        mounted() {

            // datetimepicker 설정
            this.$parent.setDateTimePicker(this.changedDateTimePicker)

            if (this.$parent.CanSearch) {

                this.fn_ShowData(false)
            }
        },
        methods: {
            // 목록 선택 이벤트
            rowSelectEvent: function (row) {

                // 버튼 클릭 시 해당 이벤트 스킵
                if (event.target.type === 'button') {

                    return
                }

                // 화면 액션값 설정 (I:신규, V: 상세보기)
                this.PageActionType = 'V'

                // 선택한 행 데이터 복사
                Object.assign(this.CompanyCardDetail, row)

                // Arg, 로그인 사용자 아이디 값, 로그인사용자 회사코드 추가
                this.CompanyCardDetail.Arg = 'PI'
                this.CompanyCardDetail.UserId = this.$parent.EmpId
                this.CompanyCardDetail.CompanyCode = this.$parent.CompanyCode

                $(this.$refs.AC1041E_Modal).modal('show')
            },
            // 수불관리 목록 선택 이벤트
            rowSelectEvent_AC1042E: function (row) {

                this.PageActionType = 'V_AC1042E'

                // 선택한 행 데이터 복사
                Object.assign(this.CompanyCardDetail_AC1042E, row)

                // Arg, 로그인 사용자 아이디 값 추가
                this.CompanyCardDetail_AC1042E.Arg = 'CI'
                this.CompanyCardDetail_AC1042E.UserId = this.$parent.EmpId

                $(this.$refs.AC1043E_Modal).modal('show')
            },
            // 페이지 변경 이벤트
            pageIndexChangeEvent: function (pageIndex) {
                
                this.CompanyCardSelectTerm.CurrentPage = pageIndex
                this.fn_ShowData(false)
            },
            // 페이지 변경 이벤트
            pageIndexChangeEvent_AC1042E: function (pageIndex) {

                this.CompanyCardSelectTerm_AC1042E.CurrentPage = pageIndex
                this.fn_ShowData_AC1042E(this.CompanyCardDetail, false)
            },
            // 수령자 조회 
            searchAcceptor: function (searchType) {

                var parent = this
                var successCallback = function (result) {

                    parent.CompanyCardDetail_AC1042E.RecvEmpNm = result.EmpNm
                    parent.CompanyCardDetail_AC1042E.RecvEmpNo = result.EmpNo
                }

                this.$refs.CP1020P.open(successCallback)
            },
            // 회수자 조회 
            searchRepossessor: function (searchType) {

                var parent = this
                var successCallback = function (result) {
                    
                    parent.CompanyCardDetail_AC1042E.RtnEmpNm = result.EmpNm
                    parent.CompanyCardDetail_AC1042E.RtnEmpNo = result.EmpNo
                }

                this.$refs.CP1020P.open(successCallback)
            },
            // 프로젝트 검색
            searchProject: function () {

                var parent = this

                var successCallback = function (result) {

                    parent.CompanyCardDetail_AC1042E.ProjectNm = result.ProjectNm
                    parent.CompanyCardDetail_AC1042E.ProjectCd = result.ProjectCd
                }

                this.$refs.CP1060P.open(successCallback, null, null, true)
            },
            // 날짜변경 이벤트
            changedDateTimePicker: function (e) {

                var targetInputElement = e.target.querySelector('input')
                var selectDate = this.moment(e.date).format('YYYY-MM-DD')

                switch (targetInputElement.id) {

                    case 'AC1043E_txtRecvYMD2': {

                        this.CompanyCardDetail_AC1042E.RecvYMD = selectDate

                        break
                    }
                    case 'AC1043E_txtRtnYMD2': {

                        this.CompanyCardDetail_AC1042E.RtnYMD = selectDate

                        break
                    }
                    default:
                }
            },
            // 수불관리 조회
            fn_ShowData: function (isOpenDetailPopup) {

                var parent = this
                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/GetCardCompanyList'.toRealServiceUrl()
                var obj = new Object()
                obj.jsonString = JSON.stringify(this.CompanyCardSelectTerm)

                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d)
                    var table1 = jsonData.Table1
                    var table2 = jsonData.Table2

                    if (table1.length > 0) {

                        parent.CompanyCardList = table1

                        // 상세팝업 오픈 플래그 값에 따라 상세 팝업 오픈
                        if (isOpenDetailPopup) {

                            for (var i = 0; i < table1.length; i++) {

                                if (table1[i].CorpCd === parent.CompanyCardDetail.CorpCd
                                    && table1[i].CardNo === parent.CompanyCardDetail.CardNo) {

                                    parent.rowSelectEvent(table1[i])

                                    break;
                                }
                            }
                        }
                    }
                    else {

                        parent.CompanyCardList = null
                    }

                    if (table2.length > 0) {

                        parent.CompanyCardListTotalCount = table2[0].cnt

                        // pager 설정
                        $('#ulpager').pager({
                            pager: '#ulpager',
                            totalItemCount: parent.CompanyCardListTotalCount,
                            recordPerPage: parent.CompanyCardSelectTerm.TopSize,
                            currentPage: parent.CompanyCardSelectTerm.CurrentPage,
                            pageIndexChanged: parent.pageIndexChangeEvent
                        })
                    }
                    else {

                        parent.CompanyCardListTotalCount = 0
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            // 법인카드별 수불목록 조회
            fn_ShowData_AC1042E: function (targetRow, isOpenDetailPopup) {

                var parent = this
                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/GetCardCompanyHistory'.toRealServiceUrl()
                var obj = new Object()

                // 선택한 행의 데이터를 조회조건으로 설정
                this.CompanyCardDetail = Object.assign({}, targetRow)
                this.CompanyCardSelectTerm_AC1042E.CardNo = this.CompanyCardDetail.CardNo
                this.CompanyCardSelectTerm_AC1042E.CardCompanyCode = this.CompanyCardDetail.CardCorpCd
                this.CompanyCardSelectTerm_AC1042E.CardCompanyName = this.CompanyCardDetail.CardCorpName


                obj.jsonString = JSON.stringify(this.CompanyCardSelectTerm_AC1042E)

                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d)
                    var table1 = jsonData.Table1
                    var table2 = jsonData.Table2

                    if (table2.length > 0) {

                        parent.CompanyCardList_AC1042E = table2
                    }
                    else {

                        parent.CompanyCardList_AC1042E = null
                    }

                    if (table1.length > 0) {

                        parent.CompanyCardListTotalCount_AC1042E = table1[0].TotCnt

                        // 페이징 설정
                        $('#AC1042E_ulpager').pager({
                            pager: '#AC1042E_ulpager',
                            totalItemCount: parent.CompanyCardListTotalCount_AC1042E,
                            recordPerPage: parent.CompanyCardSelectTerm_AC1042E.TopSize,
                            currentPage: parent.CompanyCardSelectTerm_AC1042E.CurrentPage,
                            pageIndexChanged: parent.pageIndexChangeEvent_AC1042E
                        })
                    }
                    else {

                        parent.CompanyCardListTotalCount_AC1042E = 0
                    }

                    // 상세팝업 오픈 플래그 값에 따라 상세 팝업 오픈
                    if (isOpenDetailPopup) {

                        for (var i = 0; i < table1.length; i++) {

                            if (table2[i].CorpCd === parent.CompanyCardDetail.CorpCd
                                && table2[i].CardNo === parent.CompanyCardDetail.CardNo
                                && table2[i].SeqNo === parent.CompanyCardDetail.SeqNo) {

                                parent.rowSelectEvent_AC1042E(table2[i])

                                break;
                            }
                        }
                    }

                    $(parent.$refs.AC1042E_Modal).modal('show')
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            // 수불관리 추가
            fn_InstData: function () {

                // 화면 액션값 설정 (I:신규, V: 상세보기)
                this.PageActionType = 'I'

                this.CompanyCardDetail = {
                    Arg: 'PI',
                    UserId: this.$parent.EmpId,
                    CompanyCode: this.$parent.CompanyCode,
                    CardCorpName: null,
                    CardNo: null,
                    CardCorpCd: '',
                    CorpCd: null,
                    UseYn: 'Y',
                    EtcTxt: null,
                    RecallYn: null,
                    UsedEmpNm: null,
                    UsedDate: null,
                    ProjectNm: null,
                    OrderSeq: null
                }

                $(this.$refs.AC1041E_Modal).modal('show')
            },
            // 법인카드별 수불목록 추가
            fn_InstData_AC1042E: function () {

                if (this.CompanyCardDetail.RecallYn === null
                    || this.CompanyCardDetail.RecallYn === ''
                    || this.CompanyCardDetail.RecallYn === 'Y') {

                    this.PageActionType = 'I_AC1042E'

                    this.CompanyCardDetail_AC1042E = {
                        Arg: 'CI',
                        UserId: this.$parent.EmpId,
                        RecallYn: null,
                        CorpCd: this.$parent.CompanyCode,
                        CardNo: this.CompanyCardDetail.CardNo,
                        SeqNo: null,
                        CardCorpCd: this.CompanyCardDetail.CardCorpCd,
                        CardCorpName: this.CompanyCardDetail.CardCorpName,
                        SendYMD: null,
                        SendEmpNo: null,
                        SendEmpNm: null,
                        RecvYMD: null,
                        RecvEmpNo: null,
                        RecvEmpNm: null,
                        RtnYMD: null,
                        RtnEmpNo: '20200002',
                        RtnEmpNm: '이현정',
                        CompanyCd: null,
                        TraderNm: null,
                        ProjectCd: null,
                        ProjectNm: null,
                        EtcTxt: null,
                        IsInstEmp: 'Y',
                        IsRecvEmp: null,
                        IsRecvEnd: 'N',
                        IsRetnEnd: 'N'
                    }

                    $(this.$refs.AC1043E_Modal).modal("show")
                }
                else {

                    alert('반환되지 않은 카드는 새로 등록하실 수 없습니다.')
                }
            },
            // 수불관리 저장
            fn_SaveData: function () {

                if (this.CompanyCardDetail.CardCorpCd === null || this.CompanyCardDetail.CardCorpCd === '') {

                    alert('카드사를 선택하세요.')
                    document.getElementById('AC1041E_selCardCorpCd').focus()

                    return
                }

                if (this.CompanyCardDetail.CardNo === null || this.CompanyCardDetail.CardNo === '') {

                    alert('카드번호를 입력하세요.')
                    document.getElementById('txtCardNo2').focus()

                    return
                }
                else {

                    var regx = /^\d+-\d+-\d+-\d+$/

                    if (!regx.test(this.CompanyCardDetail.CardNo)) {

                        alert('카드번호 양식을 다시 확인해주세요')
                        document.getElementById('txtCardNo2').focus()

                        return
                    }
                }

                var parent = this
                var url = "/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/SaveCardCompany".toRealServiceUrl();
                var obj = new Object();
                obj.jsonString = JSON.stringify(this.CompanyCardDetail)

                var successCallback = function (data) {

                    if (parent.PageActionType === 'I') {

                        alert('등록 되었습니다.')
                        $(parent.$refs.AC1041E_Modal).modal('hide')
                        parent.fn_ShowData(false)
                    }
                    else {

                        alert('저장 되었습니다.')
                        parent.fn_ShowData(true)
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            // 법인카드별 수불내역 저장
            fn_SaveData_AC1042E: function () {

                var parent = this
                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/SaveCardCompanyHistory'.toRealServiceUrl()
                var obj = new Object()
                obj.jsonString = JSON.stringify(this.CompanyCardDetail_AC1042E)

                // 서비스 호출 성공 후 콜백 함수
                var successCallback = function (data) {

                    if (parent.PageActionType === 'I_AC1042E') {

                        alert('등록 되었습니다.')
                        $(parent.$refs.AC1043E_Modal).modal('hide')
                        parent.fn_ShowData(false)
                        parent.fn_ShowData_AC1042E(parent.CompanyCardDetail, false)
                    }
                    else {

                        alert('회수처리 되었습니다.')
                        $(parent.$refs.AC1043E_Modal).modal('hide')
                        parent.fn_ShowData(true)
                        parent.fn_ShowData_AC1042E(parent.CompanyCardDetail, true)
                    }
                }

                if (this.PageActionType === 'I_AC1042E') {
                    
                    if (this.CompanyCardDetail_AC1042E.RecvEmpNo === null
                        || this.CompanyCardDetail_AC1042E.RecvEmpNo === '') {

                        alert('수령자를 입력하세요.')
                        this.$refs.AC1043E_txtRecvEmpNm2.focus()
                    }
                    else {

                        if (confirm('등록 하시겠습니까?')) {

                            $.scriptBase.executeAjaxAsync(url, obj, successCallback)
                        }
                    }
                }
                else {

                    if (this.CompanyCardDetail_AC1042E.RtnYMD === null
                        || this.CompanyCardDetail_AC1042E.RtnYMD === '') {

                        alert('회수일자를 입력하세요.')
                        $(this.$refs.AC1043E_txtRtnYMD2).focus()
                    }
                    else if (this.CompanyCardDetail_AC1042E.RtnEmpNm === null
                        || this.CompanyCardDetail_AC1042E.RtnEmpNm === '') {

                        alert('회수자를 입력하세요.')
                        this.$refs.AC1043E_txtRtnEmpNm2.focus()
                    }
                    else {

                        if (confirm('회수처리 하시겠습니까?')) {

                            $.scriptBase.executeAjaxAsync(url, obj, successCallback)
                        }
                    }
                }
            },
            // 수불관리 삭제
            fn_RemoveData: function () {

                if (confirm('삭제 하시겠습니까?')) {

                    var parent = this
                    var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/RemoveCardCompany'.toRealServiceUrl()
                    var obj = new Object()
                    obj.arg = 'PD'
                    obj.userId = this.$parent.EmpId
                    obj.companyCode = this.$parent.CompanyCode
                    obj.cardNo = this.CompanyCardDetail.CardNo

                    var successCallback = function (data) {

                        if (data.d > 0) {

                            alert('삭제 되었습니다.')
                            parent.fn_ShowData(false)
                            $(parent.$refs.AC1041E_Modal).modal('hide')
                        }
                    }

                    $.scriptBase.executeAjaxAsync(url, obj, successCallback)
                }
            },
            // 수불내역 삭제
            fn_RemoveData_AC1042E: function () {

                if (confirm('삭제 하시겠습니까?')) {

                    var parent = this
                    var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/RemoveCardCompanyHistory'.toRealServiceUrl()
                    var obj = new Object()

                    this.CompanyCardDetail_AC1042E.Arg = 'CD'
                    this.CompanyCardDetail_AC1042E.UserId = this.$parent.EmpId

                    obj.jsonString = JSON.stringify(this.CompanyCardDetail_AC1042E)

                    var successCallback = function (data) {

                        if (data.d > 0) {

                            alert('삭제 되었습니다.')
                            parent.fn_ShowData_AC1042E(parent.CompanyCardDetail, false)
                            $(parent.$refs.AC1043E_Modal).modal('hide')
                        }
                    }

                    $.scriptBase.executeAjaxAsync(url, obj, successCallback)
                }
            }
        }
    }
</script>