<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->

                <a href="#" id="btnListSrch" class="btn btn-primary btn-row2" v-show="this.$parent.CanSearch" @click.prevent="getManpower(false)">
                    <span>
                        <i class="glyphicon glyphicon-search"></i> 조회
                    </span>
                </a>

                <a href="#" id="btnListInst" class="btn btn-default"  @click.prevent="addManpower">
                    <span>
                        <i class="glyphicon glyphicon-plus"></i> 추가
                    </span>
                </a>
               
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-3">
                                        <label for="selTrgtYY" class="control-label">
                                            <span>년도</span>
                                        </label>
                                        <select id="selTrgtYY" class="form-control" v-model="ManpowerOperationSelectTerm.TargetYear">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="year in TargetYearList" v-bind:value="year"
                                                    v-bind:selected="moment(new Date()).year() === year">
                                                {{year}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-3">
                                        <label for="selStatusCd" class="control-label">
                                            <span>진행상태</span>
                                        </label>
                                        <select class="form-control" id="selStatusCd" name="selStatusCd" v-model="ManpowerOperationSelectTerm.StatusCode">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in StatusCodeList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-3">
                                        <label for="txtCompanyNm" class="control-label">
                                            <span>프로젝트</span>
                                        </label>
                                        <div class="cols-btns-cont pad-r150">
                                            <input type="text" id="txtProjectNm" class="form-control" readonly="readonly" v-model="ManpowerOperationSelectTerm.ProjectName" />
                                            <input type="hidden" id="hidProjectCd" v-model="ManpowerOperationSelectTerm.ProjectCode" />
                                            <input type="hidden" id="hidCompanyNm" v-model="ManpowerOperationSelectTerm.ProjectCompanyName" />
                                            <input type="hidden" id="hidCompanyCd" v-model="ManpowerOperationSelectTerm.ProjectCompanyCode" />
                                        </div>
                                        <div class="cols-btns">
                                            <a href="#" id="btnListPop1" class="btn btn-default"
                                               @click.prevent="searchProject(false)">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i> 검색
                                                </span>
                                            </a>
                                            <a href="#" id="btnSrchInit" class="btn btn-default"
                                               @click.prevent="function(){
                                                    ManpowerOperationSelectTerm.ProjectCode = null
                                                    ManpowerOperationSelectTerm.ProjectName = null
                                                    ManpowerOperationSelectTerm.ProjectCompanyCode = null
                                                    ManpowerOperationSelectTerm.ProjectCompanyName = null
                                                    ManpowerOperationSelectTerm.CurrentPage = 1
                                               }">
                                                <span>
                                                    <i class="glyphicon glyphicon-repeat"></i> 초기화
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </section>
                            </section>

                        </fieldset>
                    </form>
                </div>

            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">인력운용 관리</p>
                </div>
                <div>
                    <!--리스트 영역-->
                    <section class="tbl-contents">
                        <div class="alert alert-info total">
                            <p>
                                <span class="glyphicon glyphicon-folder-close"></span>
                                <span>
                                    전체: <span id="spTotCnt">{{ManpowerOperationListTotalCount}}</span>건
                                </span>
                            </p>
                        </div>
                        <div class="table-responsive">
                            <table class="table table tbl-list tbl-center table-hover table-bordered" id="tb0">
                                <caption>테이블</caption>
                                <colgroup>
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col">프로젝트</th>
                                        <th scope="col">거래처</th>
                                        <th scope="col">소속구분</th>
                                        <th scope="col">이름</th>
                                        <th scope="col">투입시작일</th>
                                        <th scope="col">투입종료일</th>
                                        <th scope="col" id="colCOntractMoney">계약금액</th>
                                        <th scope="col">투입 MM</th>
                                        <th scope="col">용역계약서 출력</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="ManpowerOperationList === null">
                                        <td colspan="9" class="text-center no-data">조회된 정보가 없습니다.</td>
                                    </tr>
                                    <tr v-else v-for="manpowerOperation in ManpowerOperationList" style="cursor:pointer;"
                                        @click="rowSelectEvent(manpowerOperation)">
                                        <td style="text-align:left;">{{manpowerOperation.ProjectNm}}</td>
                                        <td style="text-align:left;">{{manpowerOperation.CompanyNm}}</td>
                                        <td>{{manpowerOperation.BelongDvsName}}</td>
                                        <td>{{manpowerOperation.Name}}</td>
                                        <td>{{manpowerOperation.WorkStartYMD}}</td>
                                        <td>{{manpowerOperation.WorkEndYMD}}</td>
                                        <td v-if="manpowerOperation.IsShowPriceField" style="text-align:right;">{{manpowerOperation.ContractAmountSet}}</td>
                                        <td v-else style="text-align:right;">*****</td>
                                        <td style="text-align:right;">{{manpowerOperation.WorkMM}}</td>
                                        <td another="A">
                                            <button type="button" class="btn btn-warning btn-xs"
                                                    @click.prevent="exportPdf(manpowerOperation)">
                                                PDF출력
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="text-center">
                            <ul ref="ulpager" class="pagination"></ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <div class="modal fade" ref="PM1031E_Modal" tabindex="-1" role="dialog" aria-labelledby="PM1031E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                        <h3 class="modal-title">인력실적 상세</h3>
                    </div>
                    <div class="modal-body">
                        <section class="add-wrap">
                            <form>
                                <fieldset>
                                    <legend>입력폼 col3</legend>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtProjectNm2" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>프로젝트
                                                </span>
                                            </label>
                                            <div class="cols-btns-cont">
                                                <input type="text" ref="txtProjectNm2" class="form-control txt" readonly="readonly" v-model="ManpowerOperationDetail.ProjectNm" />
                                                <input type="hidden" id="hidProjectCd2" v-model="ManpowerOperationDetail.ProjectCd" />
                                                <input type="hidden" id="hidSeqNo2" v-model="ManpowerOperationDetail.SeqNo" />
                                                <input type="hidden" id="hidCorpCd2" v-model="ManpowerOperationDetail.CorpCd" />
                                            </div>
                                            <div class="cols-btns">
                                                <a href="#" id="btnItemPop3" class="btn btn-default"
                                                   v-show="PageActionType === 'I'"
                                                   @click.prevent="searchProject(true)">
                                                    <span>
                                                        <i class="glyphicon glyphicon-search"></i> 검색
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="txtCompanyNm" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>거래처
                                                </span>
                                            </label>
                                            <input type="text" ref="txtCompanyNm2" class="form-control txt" readonly="readonly" v-model="ManpowerOperationDetail.CompanyNm" />
                                            <input type="hidden" id="hidCompanyCd2" v-model="ManpowerOperationDetail.CompanyCd" />
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="selBelongDvsCd2" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>소속구분
                                                </span>
                                            </label>
                                            <select class="form-control" ref="selBelongDvsCd2" name="selBelongDvsCd2"
                                                    v-model="ManpowerOperationDetail.BelongDvsCd"
                                                    v-bind:disabled="PageActionType != 'I'"
                                                    @change="belongDevisionChangedEvent">
                                                <option value="">== 선택 ==</option>
                                                <option v-for="item in BelongDivisionCodeList" v-bind:value="item.Code">
                                                    {{item.Name}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="txtWorkerNm2" class="control-label">
                                                <span v-if="ManpowerOperationDetail.BelongDvsCd === '0711020'">
                                                    <i class="glyphicon glyphicon-asterisk"></i>업체
                                                </span>
                                                <span v-else>
                                                    <i class="glyphicon glyphicon-asterisk"></i>이름
                                                </span>
                                            </label>
                                            <div class="cols-btns-cont">
                                                <input type="text" ref="txtWorkerNm2" class="form-control txt" readonly="readonly" v-model="ManpowerOperationDetail.Name" />
                                                <input type="hidden" id="hidWorkEmpNo2" v-model="ManpowerOperationDetail.WorkEmpNo" />
                                                <input type="hidden" id="hidWorkCompanyCd2" v-model="ManpowerOperationDetail.InputCompanyCd" />
                                            </div>
                                            <div class="cols-btns">
                                                <a href="#" id="btnItemPop5" class="btn btn-default"
                                                   v-if="ManpowerOperationDetail.BelongDvsCd === '0711020'"
                                                   v-show="PageActionType === 'I'"
                                                   @click.prevent="searchCompany">
                                                    <span class="glyphicon glyphicon-search"></span> 검색
                                                </a>
                                                <a href="#" id="btnItemPop5" class="btn btn-default"
                                                   v-else
                                                   v-show="PageActionType === 'I'"
                                                   @click.prevent="searchManpower">
                                                    <span class="glyphicon glyphicon-search"></span> 검색
                                                </a>
                                            </div>
                                        </div>
                                    </section>
                                    <section class="cols-wrap" id="secRoleTechClas">
                                        <div class="cols col-2">
                                            <label for="selRoleCd2" class="control-label">
                                                <span>역할</span>
                                            </label>
                                            <select class="form-control" id="selRoleCd2" name="selRoleCd2"
                                                    v-model="ManpowerOperationDetail.RoleCd"
                                                    v-bind:disabled="ManpowerOperationDetail.BelongDvsCd === '0711020'">
                                                <option value="">== 선택 ==</option>
                                                <option v-for="item in RoleCodeList" v-bind:value="item.Code">
                                                    {{item.Name}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="selTechClsCd2" class="control-label">
                                                <span>등급</span>
                                            </label>
                                            <select class="form-control" id="selTechClsCd2" name="selTechClsCd2"
                                                    v-model="ManpowerOperationDetail.TechClsCd">
                                                <option value="">== 선택 ==</option>
                                                <option v-for="item in TechnicalGradeCodeList" v-bind:value="item.Code">
                                                    {{item.Name}}
                                                </option>
                                            </select>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2 ">
                                            <label for="txtWorkMM2" class="control-label">
                                                <span>투입MM</span>
                                            </label>
                                            <input type="text" id="txtWorkMM2" class="form-control text-right" maxlength="5"
                                                   v-bind:value="ManpowerOperationDetail.WorkMM"
                                                   @input="function(e){
                                                        ManpowerOperationDetail.WorkMM = e.target.value.toDecimal()
                                                        e.target.value = e.target.value.toDecimal()
                                                   }" />
                                        </div>
                                        <div class="cols col-2">
                                            <label for="txtWorkStartYMD2" class="control-label asterisk">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>투입기간
                                                </span>
                                            </label>
                                            <div class="col-2 input-group date form_date" v-bind:data-date="CurrentDateYYMMDD" data-date-format="yyyy-mm-dd">
                                                <input type="text" id="txtWorkStartYMD2" ref="txtWorkStartYMD2" class="form-control" maxlength="10"
                                                       v-bind:value="ManpowerOperationDetail.WorkStartYMD"
                                                       @input="function(e){
                                                            ManpowerOperationDetail.WorkStartYMD = e.target.value.toHyphenationFormatYMD()
                                                            e.target.value = e.target.value.toHyphenationFormatYMD()
                                                       }" />
                                                <span class="input-group-addon">
                                                    <span class="glyphicon glyphicon-calendar"></span>
                                                </span>
                                            </div>
                                            <span class="tail"><span>~</span></span>
                                            <div class="col-2 input-group date form_date" v-bind:data-date="CurrentDateYYMMDD" data-date-format="yyyy-mm-dd">
                                                <input type="text" id="txtWorkEndYMD2" ref="txtWorkEndYMD2" class="form-control" maxlength="10"
                                                       v-bind:value="ManpowerOperationDetail.WorkEndYMD"
                                                       @input="function(e){
                                                            ManpowerOperationDetail.WorkEndYMD = e.target.value.toHyphenationFormatYMD()
                                                            e.target.value = e.target.value.toHyphenationFormatYMD()
                                                       }" />
                                                <span class="input-group-addon">
                                                    <span class="glyphicon glyphicon-calendar"></span>
                                                </span>
                                            </div>
                                        </div>
                                    </section>
                                    <section class="cols-wrap"></section>
                                    <section class="cols-wrap" id="secPriceNWork"
                                             v-show="(this.$parent.AuthGrade < '0314310' && ManpowerOperationDetail.BelongDvsCd != '0711010')
                                             || (ManpowerOperationDetail.WorkEmpNo === this.$parent.EmpId && this.$parent.AuthGrade === '0314510')">
                                        <div class="cols col-2">
                                            <label for="txtWorkPrice2" class="control-label">
                                                <span>계약 금액</span>
                                            </label>
                                            <input type="text" ref="txtWorkPrice2" class="form-control text-right" maxlength="13"
                                                   v-bind:value="ManpowerOperationDetail.ContractAmountSet"
                                                   @input="function(e){
                                                        ManpowerOperationDetail.ContractAmount = e.target.value.toNumber()
                                                        ManpowerOperationDetail.ContractAmountSet = e.target.value.toPriceString()
                                                        e.target.value = e.target.value.toPriceString()
                                                   }" />
                                        </div>
                                    </section>
                                    <section class="cols-wrap" id="secPayMet">
                                        <div class="cols col-2">
                                            <label for="selPayMetCd2" class="control-label">
                                                <span>지급 방법</span>
                                            </label>
                                            <select class="form-control" id="selPayMetCd2" name="selPayMetCd2" 
                                                    v-model="ManpowerOperationDetail.PaymentMethodCd">
                                                <option value="">== 선택 ==</option>
                                                <option v-for="item in PaymentMethodCodeList" v-bind:value="item.Code">
                                                    {{item.Name}}
                                                </option>
                                            </select>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-xxs-12">
                                            <label for="txtEtcTxt2" class="control-label">
                                                <span>비고</span>
                                            </label>
                                            <textarea id="txtEtcTxt2" class="form-control" style="height:60px; resize:none" v-model="ManpowerOperationDetail.EtcTxt"></textarea>
                                        </div>
                                    </section>
                                    <!--하단과 상단의 공백-->
                                    <section class="cols-wrap">
                                        <div><label></label></div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div><label></label></div>
                                    </section>
                                    <!--지급 예정 내역-->
                                    <div class="panel panel-default" id="secPayPlan"
                                         v-show="(this.$parent.AuthGrade < '0314310' && (ManpowerOperationDetail.BelongDvsCd === '0711020' || ManpowerOperationDetail.BelongDvsCd === '0711030'))
                                         || (PageActionType != 'I' && ManpowerOperationDetail.WorkEmpNo === this.$parent.EmpId && this.$parent.AuthGrade === '0314510')">
                                        <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#colap_Arcodian1">
                                            <p>
                                                <i class="glyphicon glyphicon-plus"></i>
                                                <span>&nbsp;지급 예정 내역</span>
                                            </p>
                                        </div>
                                        <div id="colap_Arcodian1" class="panel-collapse collapse">
                                            <div class="btn-wrap border-bottom">
                                                <div style="margin: 5px 0px 3px 0px" class="fr">
                                                    <a href="#" id="btnChldAuto" class="btn btn-default" title="자동"
                                                       @click.prevent="addExpectPaymentHistory(true)">
                                                        <span class="glyphicon glyphicon-plus"></span> 자동
                                                    </a>
                                                    <a href="#" id="btnChldInst" class="btn btn-default" title="추가"
                                                       @click.prevent="addExpectPaymentHistory(false)">
                                                        <span class="glyphicon glyphicon-plus"></span> 추가
                                                    </a>
                                                    <a href="#" id="btnChldRmve" class="btn btn-default" title="삭제"
                                                       @click.prevent="removeExpectPaymentHistory">
                                                        <span class="glyphicon glyphicon-minus"></span> 삭제
                                                    </a>
                                                </div>
                                            </div>
                                            <section class="tbl-contents">
                                                <div class="table-responsive">
                                                    <table class="table tbl-list table-hover table-bordered" id="tb1">
                                                        <colgroup>
                                                            <col class="chk" />
                                                            <col />
                                                            <col />
                                                            <col />
                                                            <col />
                                                            <col />
                                                            <col />
                                                        </colgroup>
                                                        <thead>
                                                            <tr>
                                                                <th class="chk">선택</th>
                                                                <th id="th_Year" scope="col" class="text-center table-colwid15"
                                                                    v-show="ManpowerOperationDetail.PaymentMethodCd === '0721110'">
                                                                    년
                                                                </th>
                                                                <th id="th_Month" scope="col" class="text-center table-colwid15"
                                                                    v-show="ManpowerOperationDetail.PaymentMethodCd === '0721110'">
                                                                    월
                                                                </th>
                                                                <th id="th_PayMet" scope="col" class="text-center table-colwid15"
                                                                    v-show="ManpowerOperationDetail.PaymentMethodCd === '0721120'">
                                                                    지불종류
                                                                </th>
                                                                <th scope="col" class="text-center table-colwid15">단가</th>
                                                                <th scope="col" class="text-center table-colwid10">지급예정일</th>
                                                                <th scope="col" class="text-center table-colwid10">지급일</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-if="ExpectPaymentHistoryList === null">
                                                                <td colspan="7" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                            </tr>
                                                            <tr v-else v-for="(expectPaymentHistory, index) in ExpectPaymentHistoryList" v-show="expectPaymentHistory.IsShow">
                                                                <td class="chk">
                                                                    <label>
                                                                        <input type="checkbox" name="cbItem2"
                                                                               v-model="expectPaymentHistory.IsRemove"
                                                                               @click="function(e){
                                                                                    expectPaymentHistory.IsRemove = e.target.checked
                                                                               }" />
                                                                    </label>
                                                                </td>
                                                                <td class="text-center" v-show="ManpowerOperationDetail.PaymentMethodCd === '0721110'">
                                                                    <select name="selStandYear2" class="form-control" v-model="expectPaymentHistory.StandYear">
                                                                        <option v-for="year in TargetYearList" v-bind:value="year">
                                                                            {{year}}
                                                                        </option>
                                                                    </select>
                                                                </td>
                                                                <td class="text-center" v-show="ManpowerOperationDetail.PaymentMethodCd === '0721110'">
                                                                    <select name="selStandMonth2" class="form-control" v-model="expectPaymentHistory.StandMonth">
                                                                        <option v-for="month in TargetMonthList" v-bind:value="month">
                                                                            {{month}}
                                                                        </option>
                                                                    </select>
                                                                </td>
                                                                <td class="text-center" v-show="ManpowerOperationDetail.PaymentMethodCd === '0721120'">
                                                                    <select class="form-control" id="selPayMet2" name="selPayMet2"
                                                                            v-model="expectPaymentHistory.PaymentDvsCd">
                                                                        <option value="">== 선택 ==</option>
                                                                        <option v-for="item in PaymentCodeList" v-bind:value="item.Code">
                                                                            {{item.Name}}
                                                                        </option>
                                                                    </select>
                                                                </td>
                                                                <td class="text-center">
                                                                    <input type="text" name="txtPrice2" class="form-control text-right"
                                                                           v-bind:value="expectPaymentHistory.Price"
                                                                           @input="function(e){
                                                                                expectPaymentHistory.Price = e.target.value.toPriceString()
                                                                                e.target.value = e.target.value.toPriceString()
                                                                           }" />
                                                                </td>
                                                                <td class="text-center">
                                                                    <div class="input-group date form_date" data-date-format="yyyy-mm-dd"
                                                                         v-bind:data-date="CurrentDateYYMMDD"
                                                                         v-bind:index="index">
                                                                        <input type="text" id="txtPayScheduleDate2" name="txtPayScheduleDate2" class="form-control" maxlength="10"
                                                                               v-bind:value="expectPaymentHistory.PayScheduleDate"
                                                                               @input="function(e){
                                                                                    expectPaymentHistory.PayScheduleDate = e.target.value.toHyphenationFormatYMD()
                                                                                    e.target.value = e.target.value.toHyphenationFormatYMD()
                                                                               }" />
                                                                        <span class="input-group-addon">
                                                                            <span class="glyphicon glyphicon-calendar"></span>
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td class="text-center">
                                                                    <div class="input-group date form_date" data-date-format="yyyy-mm-dd"
                                                                         v-bind:data-date="CurrentDateYYMMDD"
                                                                         v-bind:index="index">
                                                                        <input type="text" id="txtPayDate2" name="txtPayDate2" class="form-control" maxlength="10"
                                                                               v-bind:value="expectPaymentHistory.PayDate"
                                                                               @input="function(e){
                                                                                    expectPaymentHistory.PayDate = e.target.value.toHyphenationFormatYMD()
                                                                                    e.target.value = e.target.value.toHyphenationFormatYMD()
                                                                               }" />
                                                                        <span class="input-group-addon">
                                                                            <span class="glyphicon glyphicon-calendar"></span>
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                    <section class="cols-wrap">
                                        <div><label></label></div>
                                    </section>
                                    <!--프로젝트 투입 정보-->
                                    <div class="panel-group" id="accordion"
                                         v-show="PageActionType != 'I' && this.$parent.AuthGrade === '0314310' && ManpowerOperationDetail.BelongDvsCd != '0711020'">
                                        <section class="cols-wrap">
                                            <div><label></label></div>
                                        </section>
                                        <section class="cols-wrap">
                                            <h4 class="add-tlt">프로젝트 경력</h4>
                                            <div class="btn-wrap border-bottom"></div>
                                        </section>
                                        <section class="cols-wrap">
                                            <div><label></label></div>
                                        </section>
                                        <section class="cols-wrap">
                                            <!--프로젝트 경력 테이블  -- tb3-->
                                            <div class="panel panel-default">
                                                <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#colap_Arcodian3">
                                                    <p>
                                                        <i class="glyphicon glyphicon-plus"></i>
                                                        <span>&nbsp;프로젝트 경력</span>
                                                    </p>
                                                </div>
                                                <div id="colap_Arcodian3" class="panel-collapse collapse">
                                                    <div class="panel-body">
                                                        <div class="btn-wrap border-bottom">
                                                            <div style="margin: 5px 0px 3px 0px" class="fr">
                                                                <a href="#" id="btnChldInst3" class="btn btn-default" title="추가" @click.prevent="addProjectCareer">
                                                                    <span class="glyphicon glyphicon-plus"></span> 추가
                                                                </a>
                                                                <a href="#" id="btnChldRmve3" class="btn btn-default" title="삭제" @click.prevent="removeProjectCareer">
                                                                    <span class="glyphicon glyphicon-minus"></span> 삭제
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <section class="tbl-contents">
                                                            <div class="table-responsive">
                                                                <table class="table tbl-list table-hover table-bordered" id="tb3">
                                                                    <colgroup>
                                                                        <col class="chk" />
                                                                        <col />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid20" />
                                                                        <col class="table-colwid20" />
                                                                    </colgroup>
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" class="text-center"></th>
                                                                            <th scope="col" class="text-center">프로젝트명/ <br>근무회사 </th>
                                                                            <th scope="col" class="text-center">시작일/ <br> 종료일</th>
                                                                            <th scope="col" class="text-center">고객사/ <br>운영체제</th>
                                                                            <th scope="col" class="text-center">언어/ <br> DBMS</th>
                                                                            <th scope="col" class="text-center">개발툴/ <br>역할</th>
                                                                            <th scope="col" class="text-center">설명/ <br>기타</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-if="ManpowerProjectCareerList === null">
                                                                            <td colspan="7" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                                        </tr>
                                                                        <tr v-else v-for="(career, index) in ManpowerProjectCareerList" v-show="career.IsShow">
                                                                            <td class="chk">
                                                                                <label>
                                                                                    <input type="checkbox" name="cbItem2" 
                                                                                           v-model="career.IsRemove"
                                                                                           @click="function(e){
                                                                                                career.IsRemove = e.target.checked
                                                                                           }" />
                                                                                </label>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <input type="text" name="txtProjectNm2" class="form-control" placeholder="프로젝트명"
                                                                                       v-model="career.ProjectNm" />
                                                                                <br>
                                                                                <input type="text" name="txtProjectComNM2" class="form-control" placeholder="근무회사"
                                                                                       v-model="career.AffiliatedCompany" />
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <div class="input-group date form_date" v-bind:data-date="CurrentDateYYMMDD" data-date-format="yyyy-mm-dd" :index="index">
                                                                                    <input type="text" id="txtStrDate2" class="form-control" placeholder="시작일" maxlength="10"
                                                                                           v-bind:value="career.StrDate"
                                                                                           @input="function(e){
                                                                                                career.StrDate = e.target.value.toHyphenationFormatYMD()
                                                                                                e.target.value = e.target.value.toHyphenationFormatYMD()
                                                                                           }" />
                                                                                    <span class="input-group-addon">
                                                                                        <span class="glyphicon glyphicon-calendar"></span>
                                                                                    </span>
                                                                                </div>
                                                                                <br>
                                                                                <div class="input-group date form_date" v-bind:data-date="CurrentDateYYMMDD" data-date-format="yyyy-mm-dd" :index="index">
                                                                                    <input type="text" id="txtEndDate2" class="form-control" placeholder="종료일" maxlength="10"
                                                                                           v-bind:value="career.EndDate"
                                                                                           @input="function(e){
                                                                                                career.EndDate = e.target.value.toHyphenationFormatYMD()
                                                                                                e.target.value = e.target.value.toHyphenationFormatYMD()
                                                                                           }" />
                                                                                    <span class="input-group-addon">
                                                                                        <span class="glyphicon glyphicon-calendar"></span>
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <input type="text" name="txtCustomer2" class="form-control" placeholder="고객사"
                                                                                       v-model="career.Customer" />
                                                                                <br>
                                                                                <input type="text" name="txProjecttOS2" class="form-control" placeholder="운영체제"
                                                                                       v-model="career.DevOS" />
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <input type="text" name="txtProjectLang2" class="form-control" placeholder="언어"
                                                                                       v-model="career.DevLanguage" />
                                                                                <br>
                                                                                <input type="text" name="txProjecttDBMS2" class="form-control" placeholder="DBMS"
                                                                                       v-model="career.DevDBMS" />
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <input type="text" name="txtProjectDevTool2" class="form-control" placeholder="개발툴"
                                                                                       v-model="career.DevTools" />
                                                                                <br>
                                                                                <select class="form-control" id="selRoleCd2" name="selRoleCd2" v-model="career.RoleCd">
                                                                                    <option value="">== 선택 ==</option>
                                                                                    <option v-for="item in TechnicalGradeCodeList" v-bind:value="item.Code">
                                                                                        {{item.Name}}
                                                                                    </option>
                                                                                </select>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <input type="text" name="txtProjectDesc2" class="form-control" placeholder="설명"
                                                                                       v-model="career.ProjectDesc" />
                                                                                <br>
                                                                                <input type="text" name="txtProjectETC2" class="form-control" placeholder="기타"
                                                                                       v-model="career.DevComment" />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    <section class="btn-align">
                                        <label id="txtHelpKnowTxt" class="control-label"
                                               v-show="PageActionType != 'I'
                                               && ExpectPaymentHistoryDetail.ConfirmYN === 'Y'">
                                            <span>지급 예정 내역 중 하나 이상의 항목이 <br>매입 완료 처리 되어 삭제 할 수 없습니다.</span>
                                        </label>
                                        <div class="btn-group">
                                            <button type="button" id="btnItemSave" class="btn btn-primary"
                                                    v-show="this.$parent.CanSave"
                                                    @click="saveManpower">
                                                <i class="glyphicon glyphicon-save"></i> 저장
                                            </button>
                                        </div>
                                        <div class="btn-group">
                                            <button type="button" id="btnItemRmve" class="btn btn-warning"
                                                    v-show="this.$parent.CanDelete
                                                    && PageActionType != 'I'
                                                    && ExpectPaymentHistoryDetail.ConfirmYN != 'Y'"
                                                    @click="removeManpower">
                                                <i class="glyphicon glyphicon-edit"></i> 삭제
                                            </button>
                                        </div>
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-danger" data-dismiss="modal">
                                                <i class="glyphicon glyphicon-remove"></i> 취소
                                            </button>
                                        </div>
                                    </section>
                                </fieldset>
                            </form>
                        </section>
                    </div>
                </div>
            </div>
        </div>
        <CP1020P ref="CP1020P"></CP1020P>
        <CP1030P ref="CP1030P"></CP1030P>
        <CP1060P ref="CP1060P"></CP1060P>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle'
    import CP1020P from '@/components/libs/popup/CP1020P'
    import CP1030P from '@/components/libs/popup/CP1030P'
    import CP1060P from '@/components/libs/popup/CP1060P'
    export default {
        name: 'PM1030R',
        components: {
            ViewTitle, CP1020P, CP1030P, CP1060P
        },
        data() {
            return {
                BelongDivisionCodeList: null,           // 소속구분 공통코드 목록
                RoleCodeList: null,                     // 역할 공통코드 목록
                TechnicalGradeCodeList: null,           // 등급 공통코드 목록
                PaymentMethodCodeList: null,            // 지급방법 공통코드 목록
                PaymentCodeList: null,                  // 지급구분 공통코드 목록
                StatusCodeList: null,                   // 프로젝트 상태 코드
                TargetYearList: new Array(),            // 년도 목록
                TargetMonthList: new Array(),           // 월 목록
                ManpowerOperationList: null,            // 인력운용관리 목록
                AllExpectPaymentHistoryList: null,      // 전체 지급예정내역 목록
                ExpectPaymentHistoryList: null,         // 지급예정내역 목록
                ManpowerProjectCareerList: null,        // 해당 인력의 프로젝트 경력 목록
                // 상세객체
                ManpowerOperationDetail: {
                    Arg: null,
                    UserId: this.$parent.EmpId,
                    CorpCd: this.$parent.CompanyCode,
                    CompanyCd: null,
                    CompanyNm: null,
                    ProjectCd: null,
                    ProjectNm: null,
                    SeqNo: null,
                    TechClsCd: null,
                    TechClsName: null,
                    RoleCd: null,
                    RoleName: null,
                    BelongDvsCd: null,
                    BelongDvsName: null,
                    WorkEmpNo: null,
                    EtcTxt: null,
                    WorkStartYMD: null,
                    WorkEndYMD: null,
                    RegtDate: null,
                    RegtEmpName: null,
                    Name: null,
                    ImputCompanyNm: null,
                    InputCompanyCd: null,
                    ContractAmount: null,
                    ContractAmountSet: null,
                    PaymentMethodCd: null,
                    PaymentMetoodCd: null,
                    WorkMM: null
                },
                // 조회조건 객체
                ManpowerOperationSelectTerm: {
                    Arg: 'L',
                    UserId: this.$parent.EmpId,
                    CompanyCode: this.$parent.CompanyCode,
                    ProjectCode: null,
                    ProjectName: null,
                    ProjectCompanyCode: null,
                    ProjectCompanyName: null,
                    TopSize: 10,
                    CurrentPage: 1, 
                    TargetYear: this.moment(new Date()).year(),
                    StatusCode: '0701030'
                },
                // 지급예정 내역 상세객체
                ExpectPaymentHistoryDetail: {
                    Arg: null,
                    UserId: this.$parent.EmpId,
                    CRUD: null,
                    IsRemove: false,
                    IsShow: true,
                    CorpCd: this.$parent.CompanyCode,
                    CompanyCd: null,
                    ProjectCd: null,
                    SeqNo: null,
                    ItemSeqNo: null,
                    PaymentDvsCd: null,
                    StandYearMonth: null,
                    StandYear: null,
                    StandMonth: null,
                    Price: null,
                    VAT: null,
                    PayScheduleDate: null,
                    PayDate: null,
                    PayYn: 'N',
                    ConfirmYN: null
                },
                // 프로젝트 경력 상세객체
                ManpowerProjectCareerDetail: {
                    Arg: null,
                    UserId: this.$parent.EmpId,
                    CRUD: null,
                    IsRemove: false,
                    IsShow: true,
                    CorpCd: this.$parent.CompanyCode,
                    EmpNo: null,
                    SeqNo: null,
                    ProjectNm: null,
                    StrDate: null,
                    EndDate: null,
                    Customer: null,
                    RoleCd: '',
                    RoleNm: null,
                    ProjectDesc: null,
                    AffiliatedCompany: null,
                    DevOS: null,
                    DevLanguage: null,
                    DevDBMS: null,
                    DevTools: null,
                    DevComment: null
                },
                ManpowerOperationListTotalCount: 0,         // 인력운용관리 목록 전체 건수
                PageActionType: null,                   // 페이지 액션 값(I: 신규, V: 상세)
                CurrentDateYYMMDD: this.moment(new Date()).format('YYYY-MM-DD'),    // 현재 날짜
                DateTimePickerTotalCount: 0            // 화면에서 사용하는 날짜컨트롤 전체 갯수
            }
        },
        beforeMount() {

            // 공통코드 조회
            this.BelongDivisionCodeList = $.scriptBase.getCommonCode('CODE000', '0711000', '', '')
            this.RoleCodeList = $.scriptBase.getCommonCode('CODE000', '0709000', '', '')
            this.TechnicalGradeCodeList = $.scriptBase.getCommonCode('CODE000', '0707000', '', '')
            this.PaymentMethodCodeList = $.scriptBase.getCommonCode('CODE000', '0721100', '', '')
            this.PaymentCodeList = $.scriptBase.getCommonCode('CODE000', '0715000', '', '')
            this.StatusCodeList = $.scriptBase.getCommonCode('CODE000', '0701000', '', '')

            var startYear = this.moment(new Date()).add(1, 'year').year()
            var endYear = this.moment(new Date(2013, 1, 1, 0, 0, 0)).year()

            // 대상년도 목록 값 설정
            for (var i = startYear; i >= endYear; i--) {

                this.TargetYearList.push(i)
            }

            // 대상월 목록 값 설정
            this.TargetMonthList.push('01')
            this.TargetMonthList.push('02')
            this.TargetMonthList.push('03')
            this.TargetMonthList.push('04')
            this.TargetMonthList.push('05')
            this.TargetMonthList.push('06')
            this.TargetMonthList.push('07')
            this.TargetMonthList.push('08')
            this.TargetMonthList.push('09')
            this.TargetMonthList.push('10')
            this.TargetMonthList.push('11')
            this.TargetMonthList.push('12')
        },
        mounted() {

            if (this.$parent.CanSearch) {

                this.getManpower(false)
            }
        },
        updated() {

            var parent = this
            var dateTimePickerElementList = document.querySelectorAll('.form_date')

            // datetimepicker 설정 적용
            // datetimepicker가 추가되었다면 화면 업데이트 시 재설정 함
            if (this.DateTimePickerTotalCount < dateTimePickerElementList.length) {

                for (var i = this.DateTimePickerTotalCount; i < dateTimePickerElementList.length; i++) {

                    // datetimepicker 설정
                    this.$parent.setDateTimePicker(this.dateTimePickerChangedEvent)
                }

                // datetimepicker 총 갯수 업데이트
                this.DateTimePickerTotalCount = dateTimePickerElementList.length
            }
        },
        methods: {
            // 날짜변경 이벤트
            dateTimePickerChangedEvent: function (e) {

                var targetInputElement = e.target.querySelector('input')
                var selectDate = this.moment(e.date).format('YYYY-MM-DD')

                switch (targetInputElement.id) {
                    case 'txtWorkStartYMD2': {

                        this.ManpowerOperationDetail.WorkStartYMD = selectDate
                        break
                    }
                    case 'txtWorkEndYMD2': {

                        this.ManpowerOperationDetail.WorkEndYMD = selectDate
                        break
                    }
                    case 'txtPayDate2': {

                        var targetIndex = e.target.getAttribute('index')
                        this.ExpectPaymentHistoryList[targetIndex].PayDate = selectDate
                        break
                    }
                    case 'txtPayScheduleDate2': {

                        var targetIndex = e.target.getAttribute('index')
                        this.ExpectPaymentHistoryList[targetIndex].PayScheduleDate = selectDate
                        break
                    }
                    case 'txtStrDate2': {

                        var targetIndex = e.target.getAttribute('index')
                        this.ManpowerProjectCareerList[targetIndex].StrDate = selectDate
                        break
                    }
                    case 'txtEndDate2': {

                        var targetIndex = e.target.getAttribute('index')
                        this.ManpowerProjectCareerList[targetIndex].EndDate = selectDate
                        break
                    }
                    default:
                }
            },
            // 소속구분 변경 이벤트
            belongDevisionChangedEvent: function () {

                // 협력사
                if (this.ManpowerOperationDetail.BelongDvsCd === '0711020') {

                    // 역할, 등급 값 초기화
                    this.ManpowerOperationDetail.RoleCd = ''
                } else {

                    // 기본값은 선택
                    this.ManpowerOperationDetail.PaymentMethodCd = ''
                }

                this.ManpowerOperationDetail.Name = null
                this.ManpowerOperationDetail.WorkEmpNo = null
                this.ManpowerOperationDetail.InputCompanyCd = null
                this.ManpowerOperationDetail.CorpCd = null
                
                // 지급 예정 내역 관련 변수 초기화
                this.ExpectPaymentHistoryList = null
            },
            // 페이지 변경 이벤트
            // pageIndex: 선택한 페이지 번호
            pageIndexChangeEvent: function (pageIndex) {

                this.ManpowerOperationSelectTerm.CurrentPage = pageIndex
                this.getManpower(false)
            },
            // 목록 선택 이벤트
            // row: 선택한 행
            rowSelectEvent: function (row) {

                // 버튼 클릭으로 해당 이벤트 발생 시 무시
                if (event.target.type === 'button') {

                    return;
                }

                var parent = this

                // 화면 액션값 설정 (I:신규, V: 상세보기)
                this.PageActionType = 'V'

                // 선택한 행 데이터 복사
                Object.assign(this.ManpowerOperationDetail, row)

                this.ExpectPaymentHistoryList = new Array()

                // 선택한 행에 해당하는 지급내역 건 설정
                if (this.AllExpectPaymentHistoryList != null) {

                    this.AllExpectPaymentHistoryList.forEach(function (item) {

                        if (item.CorpCd === parent.ManpowerOperationDetail.CorpCd
                            && item.CompanyCd === parent.ManpowerOperationDetail.CompanyCd
                            && item.ProjectCd === parent.ManpowerOperationDetail.ProjectCd
                            && item.SeqNo === parent.ManpowerOperationDetail.SeqNo) {

                            item.Arg = 'I'
                            item.UserId = parent.$parent.EmpId
                            item.CRUD = 'R'
                            item.IsRemove = false
                            item.IsShow = true
                            item.StandYear = item.PayScheduleDate.substr(0, 4)
                            item.StandMonth = item.PayScheduleDate.substr(5, 2)

                            parent.ExpectPaymentHistoryList.push(item)
                        }
                    })
                }
                
                // PM이고 소속구분이 협력사가 아닐 경우만 인력의 프로젝트 경력을 조회 함
                if (this.$parent.AuthGrade === '0314310' && this.ManpowerOperationDetail.BelongDvsCd != '0711020') {

                    this.getManpowerProjectCareer(row.WorkEmpNo)
                }
                
                // 지급내역 건이 없으면 값 초기화
                this.ExpectPaymentHistoryList = this.ExpectPaymentHistoryList.length === 0 ? null : this.ExpectPaymentHistoryList

                $(this.$refs.PM1031E_Modal).modal('show')
            },
            // 프로젝트 검색
            // isDetail: 상세화면에서 호출인지 여부
            searchProject: function (isDetail) {

                var parent = this

                var successCallback = function (result) {

                    if (isDetail) {
                        
                        parent.ManpowerOperationDetail.ProjectCd = result.ProjectCd
                        parent.ManpowerOperationDetail.ProjectNm = result.ProjectNm

                        parent.ManpowerOperationDetail.CompanyCd = result.CompanyCd
                        parent.ManpowerOperationDetail.CompanyNm = result.CompanyNm
                    }
                    else {

                        parent.ManpowerOperationSelectTerm.ProjectCode = result.ProjectCd
                        parent.ManpowerOperationSelectTerm.ProjectName = result.ProjectNm

                        parent.ManpowerOperationSelectTerm.ProjectCompanyCode = result.CompanyCd
                        parent.ManpowerOperationSelectTerm.ProjectCompanyName = result.CompanyNm

                        parent.ManpowerOperationSelectTerm.CurrentPage = 1

                        // 프로젝트 선택 시 조회
                        parent.getManpower(false)
                    }
                }

                this.$refs.CP1060P.open(successCallback, null, null, true)
            },
            // 인력 이름 조회
            searchManpower() {

                var parent = this
                var successCallback = function (result) {

                    parent.ManpowerOperationDetail.Name = result.EmpNm
                    parent.ManpowerOperationDetail.WorkEmpNo = result.EmpNo
                    parent.ManpowerOperationDetail.InputCompanyCd = null
                    parent.ManpowerOperationDetail.CorpCd = result.CorpCd
                }

                this.$refs.CP1020P.open(successCallback)
            },
            // 업체명 검색
            searchCompany: function (isDetail) {

                var parent = this
                var successCallback = function (result) {

                    parent.ManpowerOperationDetail.Name = result.CompanyNm
                    parent.ManpowerOperationDetail.WorkEmpNo = null
                    parent.ManpowerOperationDetail.InputCompanyCd = result.CompanyCd
                    parent.ManpowerOperationDetail.CorpCd = result.CorpCd
                }

                this.$refs.CP1030P.open(successCallback);
            },
            // PDF 파일 내보내기
            // targetRow: PDF 파일로 내보낼 대상 건
            exportPdf: function (targetRow) {

                alert('운영에서 오류남')
            },
            // 지급예정내역 추가
            // isAuto: 자동 추가 여부
            addExpectPaymentHistory: function (isAuto) {
                
                if (isAuto) {
                    
                    if (this.ManpowerOperationDetail.WorkStartYMD === null
                        || this.ManpowerOperationDetail.WorkStartYMD === '') {

                        alert('투입기간을 선택하세요.')
                        this.$refs.txtWorkStartYMD2.focus()
                    }
                    else if (this.ManpowerOperationDetail.WorkEndYMD === null
                        || this.ManpowerOperationDetail.WorkEndYMD === '') {

                        alert('투입기간을 선택하세요.')
                        this.$refs.txtWorkEndYMD2.focus()
                    }
                    else {

                        if (!this.ManpowerOperationDetail.WorkStartYMD.isDate()) {

                            alert('투입기간을 잘못 입력 하셨습니다.')
                            this.$refs.txtWorkStartYMD2.focus()
                        }
                        else if (!this.ManpowerOperationDetail.WorkEndYMD.isDate()) {

                            alert('투입기간을 잘못 입력 하셨습니다.')
                            this.$refs.txtWorkEndYMD2.focus()
                        }
                        else if (this.ManpowerOperationDetail.WorkStartYMD > this.ManpowerOperationDetail.WorkEndYMD) {

                            alert('투입 시작일은 종료일보다 클 수 없습니다.')
                            this.$refs.txtWorkStartYMD2.focus()
                        }
                        else if (this.ManpowerOperationDetail.ContractAmount === null
                            || this.ManpowerOperationDetail.ContractAmount === '') {

                            alert('계약금액을 입력하여 주세요')
                            this.$refs.txtWorkPrice2.focus()
                        }
                        else if (Number(this.ManpowerOperationDetail.ContractAmount) > 2147483647) {

                            alert('계약금액은 2,147,483,647을 넘을 수 없습니다.')
                            this.$refs.txtWorkPrice2.focus()
                        }
                        else {

                            // 자동계산 실행
                            this.doAutoCalculateExpectPayment()
                        }
                    }
                }
                else {

                    if (this.ExpectPaymentHistoryList === null || this.ExpectPaymentHistoryList === '') {

                        this.ExpectPaymentHistoryList = new Array()
                    }

                    // 지급예정 상세 객체 복사
                    var tempObj = Object.assign({}, this.ExpectPaymentHistoryDetail)

                    tempObj.Arg = 'I'
                    tempObj.CRUD = 'C'
                    tempObj.StandYear = this.moment(new Date()).year()
                    tempObj.StandMonth = this.moment(new Date()).format('MM')
                    tempObj.PaymentDvsCd = ''
                    
                    this.ExpectPaymentHistoryList.push(tempObj)
                }
            },
            // 지급예정내역 자동계산
            doAutoCalculateExpectPayment: function () {

                // 자동 계산 시 무조건 초기화
                this.ExpectPaymentHistoryList = new Array()

                switch (this.ManpowerOperationDetail.PaymentMethodCd) {

                    case '0721110': {   // 선금/중도금/잔금
                        var startPrice = Math.floor(Number(this.ManpowerOperationDetail.ContractAmount) * 0.3).toPriceNumber()
                        var mediumPrice = Math.floor(Number(this.ManpowerOperationDetail.ContractAmount) * 0.3).toPriceNumber()
                        var endPrice = Math.floor(Number(this.ManpowerOperationDetail.ContractAmount) * 0.4).toPriceNumber()

                        // 지급예정 상세 객체 복사
                        var startPriceObj = Object.assign({}, this.ExpectPaymentHistoryDetail)
                        var mediumPriceObj = Object.assign({}, this.ExpectPaymentHistoryDetail)
                        var endPriceObj = Object.assign({}, this.ExpectPaymentHistoryDetail)

                        startPriceObj.Arg = 'I'
                        startPriceObj.CRUD = 'C'
                        startPriceObj.PaymentDvsCd = '0715010'
                        startPriceObj.Price = startPrice
                        startPriceObj.PayScheduleDate = this.ManpowerOperationDetail.WorkStartYMD

                        mediumPriceObj.Arg = 'I'
                        mediumPriceObj.CRUD = 'C'
                        mediumPriceObj.PaymentDvsCd = '0715020'
                        mediumPriceObj.Price = mediumPrice
                        mediumPriceObj.PayScheduleDate = '2020-01-01'

                        endPriceObj.Arg = 'I'
                        endPriceObj.CRUD = 'C'
                        endPriceObj.PaymentDvsCd = '0715030'
                        endPriceObj.Price = endPrice
                        endPriceObj.PayScheduleDate = this.ManpowerOperationDetail.WorkEndYMD

                        // 선금, 중도금, 잔금 신규 추가
                        this.ExpectPaymentHistoryList.push(startPriceObj)
                        this.ExpectPaymentHistoryList.push(mediumPriceObj)
                        this.ExpectPaymentHistoryList.push(endPriceObj)

                        break
                    }
                    case '0721120': {   // 월단위

                        var startYear = this.ManpowerOperationDetail.WorkStartYMD.substr(0, 4)
                        var endYear = this.ManpowerOperationDetail.WorkEndYMD.substr(0, 4)

                        var startMonth = this.ManpowerOperationDetail.WorkStartYMD.substr(5, 2)
                        var endMonth = this.ManpowerOperationDetail.WorkEndYMD.substr(5, 2)

                        var diffYear = Number(endYear) - Number(startYear)
                        var diffMonth = (diffYear * 12 + Number(endMonth) + 1) - Number(startMonth)

                        var monthlyPrice = Math.round(Number(this.ManpowerOperationDetail.ContractAmount) / diffMonth)

                        for (var i = 1; i <= diffMonth; i++) {

                            // 월별 지급일 계산 (매달 15일)
                            var payScheduleDate = this.moment(
                                new Date(
                                    startYear
                                    + '-'
                                    + startMonth
                                    + '-15'
                                )
                            ).add(i, 'month').format('YYYY-MM-DD')

                            // 지급예정 상세 객체 복사
                            var tempObj = Object.assign({}, this.ExpectPaymentHistoryDetail)

                            tempObj.Arg = 'I'
                            tempObj.CRUD = 'C'
                            tempObj.StandYear = payScheduleDate.substr(0, 4)
                            tempObj.StandMonth = payScheduleDate.substr(5, 2)
                            tempObj.Price = monthlyPrice.toPriceNumber()
                            tempObj.PayScheduleDate = payScheduleDate
                            
                            this.ExpectPaymentHistoryList.push(tempObj)
                        }

                        break
                    }
                    default:
                }
            },
            // 지급예정내역 삭제
            removeExpectPaymentHistory: function () {

                var parent = this
                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/RemoveManpowerExpectPaymentHistory'.toRealServiceUrl()
                var obj = new Object()

                // DB에서 삭제해야할 아이템 목록
                var removeList = new Array()

                // 삭제할 대상이 있는지 체크
                var isReomve = this.ExpectPaymentHistoryList.some(function (item) {

                    return item.IsRemove
                })

                if (isReomve) {

                    if (confirm('삭제 하시겠습니까?')) {

                        // 삭제된 대상은 리스트에서 제외 (플래그 값 false로 변경)
                        this.ExpectPaymentHistoryList.forEach(function (item) {

                            if (item.IsRemove) {

                                item.IsShow = false

                                // 신규 아이템이 아니면 DB에서 삭제하기 위해 목록 추가
                                if (item.CRUD === 'R') {

                                    item.Arg = 'D'
                                    removeList.push(item)
                                }
                            }
                        })

                        // 서비스호출 성공 콜백 함수
                        var successCallback = function (data) {

                            if (data.d > 0) {

                                alert('삭제 되었습니다.')
                                parent.getManpower(true)
                            }
                        }

                        if (removeList.length > 0) {

                            obj.jsonString = JSON.stringify(removeList)
                            $.scriptBase.executeAjaxAsync(url, obj, successCallback)
                        }
                        else {

                            // 보여지는 아이템이 있는지 체크
                            var isShow = this.ExpectPaymentHistoryList.some(function (item) {

                                return item.IsShow
                            })

                            // 아이템이 존재하지 않으면 지급예정 내역 목록 값 초기화
                            if (!isShow) {

                                this.ExpectPaymentHistoryList = null
                            }
                        }
                    }
                }
                else {

                    alert('삭제할 대상이 없습니다.')
                }
            },
            // 데이터 조회
            // isOpenDetailPopup: 상세팝업 오픈여부
            getManpower: function (isOpenDetailPopup) {

                var parent = this
                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/GetManpowerOperation'.toRealServiceUrl()
                var obj = new Object()

                obj.jsonString = JSON.stringify(this.ManpowerOperationSelectTerm)

                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d)
                    var table = jsonData.Table
                    var table1 = jsonData.Table1
                    var table2 = jsonData.Table2

                    // 목록
                    if (table.length > 0) {

                        // PM이하 권한이면 계약금액 마스킹 처리
                        table.forEach(function (item) {

                            if (parent.$parent.AuthGrade < '0314310') {

                                item.IsShowPriceField = true
                            }
                            else {

                                item.IsShowPriceField = false
                            }
                        })

                        parent.ManpowerOperationList = table
                    }
                    else {

                        parent.ManpowerOperationList = null
                    }

                    // 목록 전체 카운터
                    if (table1.length > 0) {

                        parent.ManpowerOperationListTotalCount = table1[0].TotCnt

                        // pager 설정
                        $(parent.$refs.ulpager).pager({
                            pager: parent.$refs.ulpager,
                            totalItemCount: parent.ManpowerOperationListTotalCount,
                            recordPerPage: parent.ManpowerOperationSelectTerm.TopSize,
                            currentPage: parent.ManpowerOperationSelectTerm.CurrentPage,
                            pageIndexChanged: parent.pageIndexChangeEvent
                        })
                    }
                    else {

                        parent.ManpowerOperationListTotalCount = 0
                    }

                    // 전체 지급예정내역 목록
                    if (table2.length > 0) {

                        parent.AllExpectPaymentHistoryList = table2
                    }
                    else {

                        parent.AllExpectPaymentHistoryList = null
                    }

                    // 상세팝업 오픈 플래그 값에 따라 상세 팝업 오픈
                    if (isOpenDetailPopup) {

                        for (var i = 0; i < table.length; i++) {

                            if (table[i].CorpCd === parent.ManpowerOperationDetail.CorpCd
                                && table[i].CompanyCd === parent.ManpowerOperationDetail.CompanyCd
                                && table[i].ProjectCd === parent.ManpowerOperationDetail.ProjectCd
                                && table[i].SeqNo === parent.ManpowerOperationDetail.SeqNo) {

                                parent.rowSelectEvent(table[i])

                                break
                            }
                        }
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            // 데이터 추가
            addManpower: function () {

                // 화면 액션값 설정 (I:신규, V: 상세보기)
                this.PageActionType = 'I'

                // 상세객체
                this.ManpowerOperationDetail = {
                    CorpCd: null,
                    CompanyCd: this.ManpowerOperationSelectTerm.ProjectCompanyCode,
                    CompanyNm: this.ManpowerOperationSelectTerm.ProjectCompanyName,
                    ProjectCd: this.ManpowerOperationSelectTerm.ProjectCode,
                    ProjectNm: this.ManpowerOperationSelectTerm.ProjectName,
                    SeqNo: null,
                    TechClsCd: '',
                    TechClsName: null,
                    RoleCd: '',
                    RoleName: null,
                    BelongDvsCd: '',
                    BelongDvsName: null,
                    WorkEmpNo: null,
                    EtcTxt: null,
                    WorkStartYMD: null,
                    WorkEndYMD: null,
                    RegtDate: null,
                    RegtEmpName: null,
                    Name: null,
                    ImputCompanyNm: null,
                    InputCompanyCd: null,
                    ContractAmount: null,
                    ContractAmountSet: null,
                    PaymentMethodCd: '',
                    PaymentMetoodCd: null,
                    WorkMM: null
                }

                // 지급예정내역 접기
                document.getElementById('colap_Arcodian1').classList.remove('in')

                // datetimepicker 총 갯수 초기화
                this.DateTimePickerTotalCount = 0

                // 지급 예정 내역 관련 변수 초기화
                this.ExpectPaymentHistoryList = null

                // 선택 체크박스 모두 해제
                document.querySelectorAll('input[type=checkbox][name=cbItem2]').forEach(function (item) {

                    item.checked = false
                })

                // 상세팝업 오픈
                $(this.$refs.PM1031E_Modal).modal('show')
            },
            // 인력운용 저장
            saveManpower: function () {

                if (this.ManpowerOperationDetail.ProjectNm === null
                    || this.ManpowerOperationDetail.ProjectNm === '') {

                    alert('프로젝트 명을 선택하세요.')
                    this.$refs.txtProjectNm2.focus()
                }
                else if (this.ManpowerOperationDetail.CompanyNm === null
                    || this.ManpowerOperationDetail.CompanyNm === '') {

                    alert('거래처를 입력하세요.')
                    this.$refs.txtCompanyNm2.focus()
                }
                else if (this.ManpowerOperationDetail.BelongDvsCd === null
                    || this.ManpowerOperationDetail.BelongDvsCd === '') {

                    alert('소속구분을 입력하세요.')
                    this.$refs.selBelongDvsCd2.focus()
                }
                else if (this.ManpowerOperationDetail.Name === null
                    || this.ManpowerOperationDetail.Name === '') {

                    alert('이름을 선택하세요.')
                    this.$refs.txtWorkerNm2.focus()
                }
                else if (this.ManpowerOperationDetail.WorkStartYMD === null
                    || this.ManpowerOperationDetail.WorkStartYMD === '') {

                    alert('투입 시작일을 선택하세요.')
                    this.$refs.txtWorkStartYMD2.focus()
                }
                else if (this.ManpowerOperationDetail.WorkEndYMD === null
                    || this.ManpowerOperationDetail.WorkEndYMD === '') {

                    alert('투입 종료일을 선택하세요.')
                    this.$refs.txtWorkEndYMD2.focus()
                }
                else {

                    if (!this.ManpowerOperationDetail.WorkStartYMD.isDate()) {

                         alert('투입기간 형식이 잘 못 되었습니다.')
                         this.$refs.txtWorkStartYMD2.focus()
                    }
                    else if (!this.ManpowerOperationDetail.WorkEndYMD.isDate()) {

                        alert('투입기간 형식이 잘 못 되었습니다.')
                        this.$refs.txtWorkEndYMD2.focus()
                    }
                    else if (this.ManpowerOperationDetail.WorkStartYMD > this.ManpowerOperationDetail.WorkEndYMD) {

                         alert('투입 시작일은 종료일보다 클 수 없습니다.')
                         this.$refs.txtWorkStartYMD2.focus()
                    }
                    else if (this.ManpowerOperationDetail.ContractAmount != null
                        && Number(this.ManpowerOperationDetail.ContractAmount) > 2147483647) {

                        alert('계약금액은 2,147,483,647을 넘을 수 없습니다.')
                        this.$refs.txtWorkPrice2.focus()
                    }
                    else {

                        var isValidate = true

                        // 지급예정내역 유효성 검사
                        if (this.ExpectPaymentHistoryList != null) {
                            
                            switch (this.ManpowerOperationDetail.BelongDvsCd) {

                                case '0711020': {   // 협력사

                                    for (var i = 0; i < this.ExpectPaymentHistoryList.length; i++) {

                                        var item = this.ExpectPaymentHistoryList[i]

                                        if (item.PaymentDvsCd === null || item.PaymentDvsCd === '') {

                                            alert('지불종류를 선택하세요')
                                            document.querySelectorAll('#tb1 tbody tr')[i].querySelectorAll('td')[3].querySelectorAll('select')[0].focus()
                                            isValidate = false
                                            break
                                        }
                                        else if (item.Price === null || item.Price === '') {

                                            alert('단가를 입력하세요')
                                            document.querySelectorAll('#tb1 tbody tr')[i].querySelectorAll('td')[4].querySelectorAll('input')[0].focus()
                                            isValidate = false
                                            break
                                        }
                                        else if (item.PayScheduleDate === null || item.PayScheduleDate === '') {

                                            alert('지급예정일 선택하세요')
                                            document.querySelectorAll('#tb1 tbody tr')[i].querySelectorAll('td')[5].querySelectorAll('input')[0].focus()
                                            isValidate = false
                                            break
                                        }
                                    }

                                    break
                                }
                                case '0711030': {   // 프리랜서

                                    for (var i = 0; i < this.ExpectPaymentHistoryList.length; i++) {

                                        var item = this.ExpectPaymentHistoryList[i]

                                        if (item.Price === null || item.Price === '') {

                                            alert('단가를 입력하세요')
                                            document.querySelectorAll('#tb1 tbody tr')[i].querySelectorAll('td')[4].querySelectorAll('input')[0].focus()
                                            isValidate = false
                                            break
                                        }
                                        else if (item.PayScheduleDate === null || item.PayScheduleDate === '') {

                                            alert('지급예정일 선택하세요')
                                            document.querySelectorAll('#tb1 tbody tr')[i].querySelectorAll('td')[5].querySelectorAll('input')[0].focus()
                                            isValidate = false
                                            break
                                        }
                                    }

                                    break
                                }
                                default:
                            }
                        }

                        // 인력 프로젝트 경력 유효성 검사
                        if (this.ManpowerProjectCareerList != null) {

                            for (var i = 0; i < this.ManpowerProjectCareerList.length; i++) {

                                var item = this.ManpowerProjectCareerList[i]

                                if (item.ProjectNm === null || item.ProjectNm === '') {

                                    alert('프로젝트명을 입력하세요.')
                                    document.querySelectorAll('#tb3 tbody tr')[i].querySelectorAll('td')[1].querySelectorAll('input')[0].focus()
                                    isValidate = false
                                    break
                                }
                                else if (item.AffiliatedCompany === null || item.AffiliatedCompany === '') {

                                    alert('프로젝트 근무회사를 입력하세요.')
                                    document.querySelectorAll('#tb3 tbody tr')[i].querySelectorAll('td')[1].querySelectorAll('input')[1].focus()
                                    isValidate = false
                                    break
                                }
                                else if (item.StrDate === null || item.StrDate === '') {

                                    alert('프로젝트 시작일을 선택하세요.')
                                    document.querySelectorAll('#tb3 tbody tr')[i].querySelectorAll('td')[2].querySelectorAll('input')[0].focus()
                                    isValidate = false
                                    break
                                }
                                else if (item.EndDate === null || item.EndDate === '') {

                                    alert('프로젝트 종료일을 선택하세요.')
                                    document.querySelectorAll('#tb3 tbody tr')[i].querySelectorAll('td')[2].querySelectorAll('input')[1].focus()
                                    isValidate = false
                                    break
                                }
                                else if (item.Customer === null || item.Customer === '') {

                                    alert('프로젝트 고객사를 입력하세요.')
                                    document.querySelectorAll('#tb3 tbody tr')[i].querySelectorAll('td')[3].querySelectorAll('input')[0].focus()
                                    isValidate = false
                                    break
                                }
                                else if (item.DevOS === null || item.DevOS === '') {

                                    alert('프로젝트 운영체제를 입력하세요.')
                                    document.querySelectorAll('#tb3 tbody tr')[i].querySelectorAll('td')[3].querySelectorAll('input')[1].focus()
                                    isValidate = false
                                    break
                                }
                                else if (item.DevLanguage === null || item.DevLanguage === '') {

                                    alert('프로젝트 언어를 입력하세요.')
                                    document.querySelectorAll('#tb3 tbody tr')[i].querySelectorAll('td')[4].querySelectorAll('input')[0].focus()
                                    isValidate = false
                                    break
                                }
                                else if (item.DevDBMS === null || item.DevDBMS === '') {

                                    alert('프로젝트 DBMS를 입력하세요.')
                                    document.querySelectorAll('#tb3 tbody tr')[i].querySelectorAll('td')[4].querySelectorAll('input')[1].focus()
                                    isValidate = false
                                    break
                                }
                                else if (item.DevTools === null || item.DevTools === '') {

                                    alert('프로젝트 개발툴을 입력하세요.')
                                    document.querySelectorAll('#tb3 tbody tr')[i].querySelectorAll('td')[5].querySelectorAll('input')[0].focus()
                                    isValidate = false
                                    break
                                }
                                else if (item.RoleCd === null || item.RoleCd === '') {

                                    alert('프로젝트 역할을 선택하세요.')
                                    document.querySelectorAll('#tb3 tbody tr')[i].querySelectorAll('td')[5].querySelectorAll('select')[0].focus()
                                    isValidate = false
                                    break
                                }
                            }
                        }

                        if (isValidate) {

                            // 인력 중복 프로젝트 체크
                            this.checkOverlapProject()
                        }
                    }
                }
            },
            // 인력운용 저장 실행
            doSaveManpower: function () {

                var parent = this
                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/SaveManpowerOperation'.toRealServiceUrl()
                var obj = new Object()
                var expectPaymentHistoryList = new Array()
                var projectCareerList = new Array()

                this.ManpowerOperationDetail.Arg = 'I'
                this.ManpowerOperationDetail.UserId = this.$parent.EmpId

                // 지급예정 내역 저장할 아이템들만 설정 (삭제되지 않은 아이템)
                if (this.ExpectPaymentHistoryList != null) {

                    this.ExpectPaymentHistoryList.forEach(function (item) {

                        if (!item.IsRemove) {

                            item.Arg = 'I'
                            item.ProjectCd = parent.ManpowerOperationDetail.ProjectCd
                            item.CompanyCd = parent.ManpowerOperationDetail.CompanyCd
                            
                            if (parent.ManpowerOperationDetail.PaymentMethodCd === '0721110') {

                                item.PaymentDvsCd = '0715050'
                                item.PurchaseNm = parent.ManpowerOperationDetail.ProjectNm + '_' + parent.ManpowerOperationDetail.Name + '_' + item.StandYear + item.StandMonth
                            }
                            else {

                                item.PurchaseNm = parent.ManpowerOperationDetail.ProjectNm + '_' + parent.ManpowerOperationDetail.Name + '_' + item.PayScheduleDate.toNumber()
                            }

                            expectPaymentHistoryList.push(item)
                        }
                    })
                }

                // 프로젝트 경력 저장할 아이템들만 설정 (삭제되지 않은 아이템)
                if (this.ManpowerProjectCareerList != null) {

                    this.ManpowerProjectCareerList.forEach(function (item) {

                        if (!item.IsRemove) {

                            item.Arg = 'PP'
                            item.EmpNo = parent.ManpowerOperationDetail.WorkEmpNo

                            projectCareerList.push(item)
                        }
                    })
                }
                
                obj.jsonString = JSON.stringify(this.ManpowerOperationDetail)
                obj.jsonExpectPaymentHistoryString = JSON.stringify(expectPaymentHistoryList)
                obj.jsonProjectCareerString = JSON.stringify(projectCareerList)

                // 서비스호출 성공 콜백 함수
                var successCallback = function (data) {

                    if (data.d > 0) {

                        if (parent.PageActionType === 'I') {

                            alert('등록 되었습니다.')
                            $(parent.$refs.PM1031E_Modal).modal('hide')
                            parent.getManpower(false)
                        }
                        else {

                            alert('저장 되었습니다.')
                            parent.getManpower(true)
                        }
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            // 인력운용 삭제
            removeManpower: function () {

                var parent = this
                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/RemoveManpowerOperation'.toRealServiceUrl()
                var obj = new Object()

                this.ManpowerOperationDetail.Arg = 'D'

                obj.jsonString = JSON.stringify(this.ManpowerOperationDetail)

                // 서비스호출 성공 콜백 함수
                var successCallback = function (data) {

                    if (data.d > 0) {

                        alert('삭제 되었습니다.')
                        $(parent.$refs.PM1031E_Modal).modal('hide')
                        parent.getManpower(false)
                    }
                }
                
                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            // 투입인력 중복 체크
            checkOverlapProject: function () {

                var parent = this
                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/GetManpowerOperation'.toRealServiceUrl()
                var obj = new Object()

                obj.jsonString = JSON.stringify({
                    'Arg': 'C',
                    'UserId': this.ManpowerOperationDetail.WorkEmpNo === null || this.ManpowerOperationDetail.WorkEmpNo === '' ? '0' : this.ManpowerOperationDetail.WorkEmpNo,
                    'CorpCd': this.ManpowerOperationDetail.CorpCd,
                    'ProjectCd': this.ManpowerOperationDetail.ProjectCd,
                    'CompanyCd': this.ManpowerOperationDetail.CompanyCd,
                    'SeqNo': this.ManpowerOperationDetail.InputCompanyCd === null || this.ManpowerOperationDetail.InputCompanyCd === '' ? '0' : this.ManpowerOperationDetail.InputCompanyCd,
                    'WorkStartYMD': this.ManpowerOperationDetail.WorkStartYMD.replace(/-/g, ''),
                    'WorkEndYMD': this.ManpowerOperationDetail.WorkEndYMD.replace(/-/g, ''),
                    'TopSize': this.ManpowerOperationDetail.SeqNo === null || this.ManpowerOperationDetail.SeqNo === '' ? '0' : this.ManpowerOperationDetail.SeqNo,
                })

                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d)
                    var table = jsonData.Table

                    if (table.length > 0) {

                        if (table[0].ChkCnt > 0) {

                            alert('해당프로젝트의 투입기간이 겹칩니다.')
                        }
                        else {

                            // 저장 실행
                            parent.doSaveManpower()
                        }
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            // 해당 인력의 프로젝트 경력 조회
            // workEmpNo: 프로젝트 경력 조회 대상 인력의 사원번호
            getManpowerProjectCareer: function (workUserId) {

                var parent = this
                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/GetManpowerOperation'.toRealServiceUrl()
                var obj = new Object()

                obj.jsonString = JSON.stringify({
                    'Arg': 'MC',
                    'UserId': workUserId,
                    'CorpCd': this.$parent.CompanyCode
                })

                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d)
                    var table = jsonData.Table

                    // 목록
                    if (table.length > 0) {

                        // 원본데이터 가공 (필요한 값들 추가)
                        table.forEach(function (item) {

                            item.Arg = 'PP'
                            item.CRUD = 'R'
                            item.UserId = parent.$parent.EmpId
                            item.IsRemove = false
                            item.IsShow = true
                        })

                        parent.ManpowerProjectCareerList = table
                    }
                    else {

                        parent.ManpowerProjectCareerList = null
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            // 해당 인력의 프로젝트 경력 추가
            addProjectCareer: function () {

                if (this.ManpowerProjectCareerList === null || this.ManpowerProjectCareerList === '') {

                    this.ManpowerProjectCareerList = new Array()
                }

                // 프로젝트 경력 상세 객체 복사
                var tempObj = Object.assign({}, this.ManpowerProjectCareerDetail)

                tempObj.Arg = 'PP'
                tempObj.CRUD = 'C'
                tempObj.StrDate = this.moment(new Date()).format('YYYY-MM-DD')
                tempObj.EndDate = this.moment(new Date()).format('YYYY-MM-DD')

                this.ManpowerProjectCareerList.push(tempObj)
            },
            // 해당 인력의 프로젝트 경력 삭제
            removeProjectCareer: function () {

                var parent = this
                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/RemoveManpowerProjectCareer'.toRealServiceUrl()
                var obj = new Object()

                // DB에서 삭제해야할 아이템 목록
                var removeList = new Array()

                // 삭제할 대상이 있는지 체크
                var isReomve = this.ManpowerProjectCareerList.some(function (item) {

                    return item.IsRemove
                })

                if (isReomve) {

                    if (confirm('삭제 하시겠습니까?')) {

                        // 삭제된 대상은 리스트에서 제외 (플래그 값 false로 변경)
                        this.ManpowerProjectCareerList.forEach(function (item) {

                            if (item.IsRemove) {

                                item.IsShow = false

                                // 신규 아이템이 아니면 DB에서 삭제하기 위해 목록 추가
                                if (item.CRUD === 'R') {

                                    item.Arg = 'PD'
                                    removeList.push(item)
                                }
                            }
                        })

                        // 서비스호출 성공 콜백 함수
                        var successCallback = function (data) {

                            if (data.d > 0) {

                                alert('삭제 되었습니다.')
                                parent.getManpower(true)
                            }
                        }

                        if (removeList.length > 0) {

                            obj.jsonString = JSON.stringify(removeList)
                            $.scriptBase.executeAjaxAsync(url, obj, successCallback)
                        }
                        else {

                            // 보여지는 아이템이 있는지 체크
                            var isShow = this.ManpowerProjectCareerList.some(function (item) {

                                return item.IsShow
                            })

                            // 아이템이 존재하지 않으면 프로젝트 경력 목록 값 초기화
                            if (!isShow) {

                                this.ManpowerProjectCareerList = null
                            }
                        }
                    }
                }
                else {

                    alert('삭제할 대상이 없습니다.')
                }
            }
        }
    }
</script>