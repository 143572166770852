<template>
    <!-- 이수 교육 등록/수정 -->
    <div class="modal fade" id="HM1051E_Modal" ref="HM1051E_Modal" tabindex="-1" role="dialog" aria-labelledby="HM1051E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">
                        <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                    </button>
                    <h3 class="modal-title">이수 교육 등록/수정</h3>
                </div>
                <div class="modal-body">
                    <section class="add-wrap">
                        <form>
                            <fieldset>
                                <legend></legend>
                                <section class="cols-wrap">
                                    <div class="cols">
                                        <label for="txtEmpNm" class="control-label">
                                            <span>이수자</span>
                                        </label>
                                        <div class="cols-btns-cont pad-r150">
                                            <input type="text" id="txtEmpNm" class="form-control txt" readonly="readonly" v-model="EduHistory.EmpNm" />
                                        </div>
                                        <div class="cols-btns">
                                            <a href="#" id="HM1051E_btnSearchBtn" class="btn btn-default" @click.prevent="searchEmp">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i> 검색
                                                </span>
                                            </a>
                                            <a href="#" id="HM1051E_btnResetBtn" class="btn btn-default" @click.prevent="resetEmp">
                                                <span>
                                                    <i class="glyphicon glyphicon-repeat"></i> 초기화
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols">
                                        <label for="txtEduTitle" class="control-label">
                                            <span>교육명</span>
                                        </label>
                                        <input type="text" id="txtEduTitle" class="form-control txt" v-model="EduHistory.EduTitle" />
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols">
                                        <label for="txtEduInstitution" class="control-label">
                                            <span>교육기관</span>
                                        </label>
                                        <input type="text" id="txtEduInstitution" class="form-control txt" v-model="EduHistory.EduInstitution" />
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols">
                                        <label for="txtEduContents" class="control-label"><span>교육내용</span></label>
                                        <DxHtmlEditor id="txtEduContents" height="350px" value-type="html" v-model="EduHistory.EduContents" class="form-control txt">
                                            <DxMediaResizing :enabled="true" />
                                            <DxToolbar :multiline="isMultiLine">
                                                <DxItem format-name="undo" />
                                                <DxItem format-name="redo" />
                                                <DxItem format-name="separator" />
                                                <DxItem :format-values="sizeValues"
                                                        format-name="size" />
                                                <DxItem format-name="separator" />
                                                <DxItem format-name="bold" />
                                                <DxItem format-name="italic" />
                                                <DxItem format-name="strike" />
                                                <DxItem format-name="underline" />
                                                <DxItem format-name="separator" />
                                                <DxItem format-name="orderedList" />
                                                <DxItem format-name="bulletList" />
                                                <DxItem format-name="separator" />
                                                <DxItem format-name="color" />
                                                <DxItem format-name="background" />
                                                <DxItem format-name="separator" />
                                                <DxItem format-name="link" />
                                                <DxItem format-name="separator" />
                                                <DxItem format-name="clear" />
                                                <DxItem format-name="codeBlock" />
                                                <DxItem format-name="blockquote" />
                                            </DxToolbar>
                                        </DxHtmlEditor>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtStartDate" class="control-label">
                                            <span>시작 예정일</span>
                                        </label>

                                        <DxDateBox style="padding:0px" id="txtStartDate" v-model="EduHistory.StartDate" class="form-control txt" type="date" display-format="yyyy-MM-dd"></DxDateBox>

                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtEndDate" class="control-label">
                                            <span>종료 예정일</span>
                                        </label>

                                        <DxDateBox style="padding:0px" id="txtEndDate" v-model="EduHistory.EndDate" class="form-control txt" type="date" display-format="yyyy-MM-dd"></DxDateBox>

                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtEduTime" class="control-label">
                                            <span>교육 이수 시간</span>
                                        </label>
                                        <input id="txtEduTime" v-model="EduHistory.EduTime" type="number" class="form-control txt">
                                    </div>
                                    <div class="cols col-2">
                                        <label for="cbxRequiredYn" class="control-label">
                                            <span>필수 교육 유무</span>
                                        </label>
                                        <input id="cbxRequiredYn" type="checkbox" v-model="EduHistory.RequiredYn" true-value="Y" false-value="N" class="form-control btn" />
                                    </div>
                                </section>
                                <section class="btn-align">
                                    <div v-show="CanSave" class="btn-group">
                                        <button type="button" id="btnItemSave" class="btn btn-primary" @click.prevent="saveData">
                                            <i class="glyphicon glyphicon-save"></i> 저장
                                        </button>
                                    </div>
                                    <div v-show="CanDelete" class="btn-group">
                                        <button type="button" id="btnItemRmve" class="btn btn-warning" @click.Prevent="removeData">
                                            <span class="glyphicon glyphicon-edit"></span> 삭제
                                        </button>
                                    </div>
                                    <div v-show="CanApprove" class="btn-group">
                                        <button type="button" id="btnItemRmve" class="btn btn-warning" @click.Prevent="approveData">
                                            <span class="glyphicon glyphicon-edit"></span> 전자결재
                                        </button>
                                    </div>
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-danger" data-dismiss="modal">
                                            <i class="glyphicon glyphicon-remove"></i> 취소
                                        </button>
                                    </div>
                                </section>
                            </fieldset>
                        </form>
                    </section>
                </div>
            </div>
        </div>
        <form action="https://office.hiworks.com/minisoft/approval/popup/write" target="_blank" method="post" ref="frmApproval">
            <input type="hidden" name="form_id" value="N_25" />
            <input type="hidden" name="subject" v-model="EduHistory.EduTitle" />
            <input v-if="EduHistory.RequiredYn === 'Y'" type="hidden" name="contents" v-model="'<p><H1>필수 이수 교육</H1></p>'+EduHistory.EduContents" />
            <input v-else-if="EduHistory.RequiredYn === 'N'" type="hidden" name="contents" v-model="EduHistory.EduContents" />
            <input type="hidden" name="callback_url" value="https://ibs.minisoft.co.kr/v2/services/HumanMgt_NoneAuth.svc/Get/SetEduHistoryApprove" />
            <!--<input type="hidden" name="callback_url" value="http://demo.minisoft.co.kr/v2/services/HumanMgt_NoneAuth.svc/Get/SetEduHistoryApprove" />-->
            <input type="hidden" name="modify_contents_flag" value="Y" />
            <input type="hidden" name="modify_files_flag" value="Y" />
            <input type="hidden" name="state" v-model="EduHistory.EduEmpNo+''+EduHistory.EduNo" />
        </form>

    </div>
</template>

<script>
    import moment from 'moment'

    import CP1020P from '@/components/libs/popup/CP1020P'

    import {
        DxDateBox
    } from 'devextreme-vue'
    import {
        DxHtmlEditor,
        DxToolbar,
        DxMediaResizing,
        DxItem
    } from 'devextreme-vue/html-editor'

    export default {
        name: 'HM1051E',
        components: {
            CP1020P,
            DxDateBox,
            DxHtmlEditor,
            DxToolbar,
            DxMediaResizing,
            DxItem
        },
        data() {
            return {
                CallbackFunction: null,     // Caller Function
                Condition: null,
                EduHistory: {
                    EduEmpNo: null
                    , EmpNm: null
                    , EduNo: -1
                    , EduTitle: null
                    , EduInstitution: null
                    , EduContents: null
                    , StartDate: moment(new Date()).format('YYYY-MM-DD')
                    , EndDate: moment(new Date()).format('YYYY-MM-DD')
                    , EduTime: 0
                    , RequiredYn: 'N'
                    , EmpNo: null
                },
                CanSave: true,
                CanDelete: true,
                CanApprove: true,
                sizeValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'],
                IsNew: true, 
                isMultiLine: true
            }
        },
        methods: {
            open: function (callbackFunction, condition, selectedObj) {

                if (selectedObj) {
                    // 전자 결재 완료 여부
                    this.EduHistory = selectedObj
                    if (selectedObj.ApproveCd) {
                        this.CanDelete = false
                        this.CanApprove = false
                    } else {
                        this.CanDelete = true
                        this.CanApprove = true
                    }
                } else {
                    this.reset()
                    this.EduHistory.EduEmpNo = this.$parent.$parent.EmpId,
                    this.EduHistory.EmpNm = this.$parent.$parent.UserName
                    this.CanApprove = false
                    this.CanDelete = false
                }

                this.CallbackFunction = callbackFunction
                this.Condition = condition
                this.EduHistory.EmpNo = this.$parent.$parent.EmpId

                $("#HM1051E_Modal").modal("show")
            },
            close: function () {
                $("#HM1051E_Modal").modal("hide")
                this.CallbackFunction()
            },
            reset() {
                Object.assign(this.$data, this.$options.data())
            },
            //업체 영업 저장 메서드
            saveData: function () {
                var that = this
                var url = '/Mini.Service.IBS.HM.Service/HumanMgt.svc/ajax/SetEduHistory'
                url = url.toRealServiceUrl()


                var obj = new Object()
                obj.jsonString = JSON.stringify(that.EduHistory)

                var ajaxSuccessHandler = function (data) {
                    that.close()
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
            },
            // 영업관리 삭제 버튼 클릭 이벤트 핸들러
            removeData: function () {
                var that = this
                var url = '/Mini.Service.IBS.HM.Service/HumanMgt.svc/ajax/RemoveEduHistory'
                url = url.toRealServiceUrl()
                var obj = new Object()
                obj.jsonString = JSON.stringify(that.EduHistory)

                var ajaxSuccessHandler = function (data) {
                    that.close()
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
            },
            approveData: function () {
                alert("결재 요청시 교육 이수증 첨부 바랍니다.")
                // popup
                this.$refs.frmApproval.submit()
                this.close()
            },
            searchEmp: function (e) {
                var that = this
                var successCallback = function (result) {

                    that.EduHistory.EmpNm = result.EmpNm
                    that.EduHistory.EduEmpNo = result.EmpNo
                }

                this.$parent.$refs.CP1020P.showManPowerPopup(successCallback)
            },
            resetEmp: function (e) {
                this.EduHistory.EmpNm = null
                this.EduHistory.EduEmpNo = null
            }
        }
    }
</script>

