<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">

            <div class="btn-wrap">
                <a href="#" id="btnListShow" class="btn btn-primary btn-row2" @click.prevent="getProjectList">
                    <span>
                        <i class="glyphicon glyphicon-search"></i>조회
                    </span>
                </a>
            </div>
            <div class="sch-box">
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-4">
                                        <label for="selTrgtYY" class="control-label">
                                            <span>년도</span>
                                        </label>
                                        <select id="selTrgtYY" class="form-control" v-model="ProjectSelectTerm.TargetYear">
                                            <option v-for="year in YearsList" v-bind:value="year">
                                                {{year}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-3">
                                        <label for="txtCompanyNM" class="control-label">
                                            <span>거래처</span>
                                        </label>
                                        <div class="cols-btns-cont  pad-r150">
                                            <input type="text" id="txtCompanyNm" class="form-control txt" disabled="disabled" v-model="ProjectSelectTerm.AccountCompanyName" />
                                            <input type="hidden" id="hidCompanyCd" v-model="ProjectSelectTerm.AccountCompanyCode" />
                                        </div>
                                        <div class="cols-btns">
                                            <a href="#" id="btnItemPop1" class="btn btn-default" @click.prevent="searchCompany">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i> 검색
                                                </span>
                                            </a>
                                            <a href="#" id="btnItemClear1" class="btn btn-default"
                                               @click.prevent="function(){
                                                    ProjectSelectTerm.AccountCompanyName = null
                                                    ProjectSelectTerm.AccountCompanyCode = null
                                                    ProjectSelectTerm.ProjectName = null
                                               }">
                                                <span>
                                                    <i class="glyphicon glyphicon-repeat"></i> 초기화
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="cols col-3">
                                        <label class="control-label">
                                            <span>프로젝트명</span>
                                        </label>
                                        <input type="text" class="form-control" v-model="ProjectSelectTerm.ProjectName" />
                                    </div>
                                </section>
                            </section>
                        </fieldset>
                    </form>
                </div>
            </div>
            <div class="col-md-5 col-xs-12">
                <div class="grid-wrap">
                    <div class="sub-title">
                        <p class="sub-title-txt">프로젝트</p>
                    </div>
                    <section class="tbl-contents">
                        <div class="alert alert-info total">
                            <p>
                                <span class="glyphicon glyphicon-folder-close"></span>
                                <span>전체: <span id="spTotCnt">{{ProjectListTotalCount}}</span>건</span>
                            </p>
                        </div>
                        <div class="table-responsive">
                            <DxDataGrid id="grid-container"
                                        key-expr="ProjectCd"
                                        :show-borders="false"
                                        :data-source="ProjectList"
                                        :hoverStateEnabled="true"
                                        :column-auto-width="true"
                                        :focused-row-enabled="true"
                                        v-model:focused-row-key="focusedRowKey"
                                        @focused-row-changed="onFocusedRowChanged"
                                        @cell-prepared="onCellPrepared"
                                        @row-click="getCostWeightList"
                                        style="max-height: 450px;">
                                <DxScrolling mode="standard" useNative="false" />
                                <DxPaging :enabled="false" />
                                <DxColumn data-field="CompanyNm"
                                          caption="거래처명"
                                          alignment="left" />
                                <DxColumn data-field="ProjectNm"
                                          caption="프로젝트명"
                                          alignment="left" />
                            </DxDataGrid>
                        </div>
                    </section>
                </div>
            </div>

            <div class="col-md-7 col-xs-12">
                <div class="grid-wrap">
                    <div class="sub-title">
                        <p class="sub-title-txt">프로젝트별 가중치</p>
                    </div>
                    <div class="btn-wrap" style="margin: 22px 0px 20px 0px">
                        <a href="#" id="btnListInst" class="btn btn-default" @click.prevent="addDataTable">
                            <span>
                                <i class="glyphicon glyphicon-plus"></i> 추가
                            </span>
                        </a>
                        <a href="#" id="btnListInst" class="btn btn-default" @click.prevent="deleteCostWeight">
                            <span>
                                <i class="glyphicon glyphicon-minus"></i> 삭제
                            </span>
                        </a>
                        <a href="#" id="btnListInst" class="btn btn-default" @click.prevent="saveCostWeight">
                            <span>
                                <i class="glyphicon glyphicon-save"></i> 저장
                            </span>
                        </a>
                    </div>
                    <div class="table-responsive">
                        <DxDataGrid id="grid-container"
                                    @cell-prepared="onCellPrepared"
                                    @selection-changed="onSelectionChanged"
                                    :selected-row-keys="selectedItemKeys"
                                    :data-source="CostWeightList"
                                    :ref="CostWeightMangage"
                                    :show-borders="false"
                                    :hoverStateEnabled="true"
                                    :column-auto-width="true"
                                    :repaint-changes-only="true">
                            <DxEditing :allow-updating="true"
                                       :select-text-on-edit-start="true"
                                       :new-row-position="newRowPosition"
                                       mode="cell" />
                            <DxSelection mode="multiple" />
                            <DxColumn data-field="CostDivCd"
                                      caption="비용구분"
                                      alignment="center"
                                      width="15%"
                                      :set-cell-value="setOrgCost">
                                <DxLookup :dataSource="CostDivList"
                                          display-expr="Name"
                                          value-expr="Code" />
                            </DxColumn>
                            <DxColumn data-field="OrgCostAmt"
                                      caption="실제비용금액"
                                      :allow-editing="false"
                                      alignment="right"
                                      width="15%"
                                      format="#,##0.##" />
                            <DxColumn data-field="WeightRate"
                                      caption="가중치비율"
                                      alignment="right"
                                      width="15%"
                                      :set-cell-value="setWeightCost" />
                            <DxColumn data-field="WeightCostAmt"
                                      caption="가중치적용금액"
                                      :allow-editing="false"
                                      alignment="right"
                                      width="15%"
                                      format="#,##0.##" />
                            <DxColumn data-field="MemoCont"
                                      caption="메모내용"
                                      alignment="left" />
                        </DxDataGrid>
                    </div>
                </div>
            </div>
        </div>
        <CP1030P ref="CP1030P"></CP1030P>
    </div>
</template>
<script>
    import ViewTitle from '@/components/ViewTitle'
    import CP1030P from '@/components/libs/popup/CP1030P'

    import { DxDataGrid, DxColumn, DxEditing, DxSelection, DxScrolling, DxPaging, DxLookup } from 'devextreme-vue/data-grid';
    import DataSource from 'devextreme/data/data_source';
    import ArrayStore from 'devextreme/data/array_store';

    export default {
        name: 'PM1090R',
        components: {
            ViewTitle,
            CP1030P,
            DxDataGrid,
            DxColumn,
            DxEditing,
            DxSelection,
            DxScrolling,
            DxPaging,
            DxLookup,
        },
        data() {
            return {

                CallbackFunction: null,
                Condition: null,
                focusedRowKey: '',
                // 그리드 추가 위치
                newRowPosition: 'last',
                CostWeightMangage: 'dataGrid',
                dataSource: new DataSource({
                    store: new ArrayStore({
                        data: this.CostWeightList,
                        key: 'CostDivCd'
                    }),
                }),
                // 체크박스 선택된 아이템
                selectedItems: new Array(),
                selectedItemKeys: new Array(),
                UserId: '',
                YearsList: new Array(),
                // 비용구분코드 목록
                CostDivList: new Array(),
                // 프로젝트 목록
                ProjectList: new Array(),
                ProjectListTotalCount: 0,                
                // 프로젝트별 가중치 목록
                CostWeightList: new Array(),                
                // 프로젝트 조회 조건
                ProjectSelectTerm: {

                    Arg: 'L',
                    UserId: '',
                    CompanyCode: '0001',
                    ProjectName: '',
                    // 거래처코드
                    AccountCompanyCode: '',
                    AccountCompanyName: null,
                    TargetYear: this.moment(new Date()).year(),
                    StatusCode: '',
                    ProjectCategoryCode: '',
                },
                // 회사비용 평균치 조회 조건
                GeneralCorpCost: {

                    TargetYear: '',
                    // 비용구분코드
                    CostDivCd: ''
                },
                // 일반회사비용 평균치
                CorpCostAmt: new Array(),
                // 프로젝트별 가중치 조회 조건
                CostWeightTerm: {

                    ProjectCd: ''
                },
                // 가중치 저장/삭제 파라미터
                CostWeightManipulateList: new Array(),
            }
        },
        created() {

            // 프로젝트 초기 조회
            this.getProjectList()
        },
        beforeMount() {

            // 공통코드 조회
            this.CostDivList = $.scriptBase.getCommonCode('CODE000', '0512000', '', '')
            this.UserId = this.$store.getters.getEmpId

            var startYear = this.moment(new Date()).add(1, 'year').year()
            var endYear = this.moment(new Date(2013, 1, 1, 0, 0, 0)).year()

            // 대상년도 목록 값 설정
            for (var i = startYear; i >= endYear; i--) {

                this.YearsList.push(i)
            }
        },
        methods: {

            // datagrid header 가운데 정렬, column 중앙 정렬
            onCellPrepared(e) {

                if (e.rowType == 'header') {
                    e.cellElement.style['textAlign'] = 'center'
                }
                if (e.rowType == 'data') {
                    e.cellElement.style['verticalAlign'] = 'middle'
                }
            },

            onFocusedRowChanged(e) {

                this.focusedRowKey = e.component.option('focusedRowKey');
            },

            // 거래처 검색 버튼
            searchCompany() {

                var parent = this

                var successCallback = function (result) {

                    parent.ProjectSelectTerm.AccountCompanyCode = result.CompanyCd
                    parent.ProjectSelectTerm.AccountCompanyName = result.CompanyNm
                }

                this.$refs.CP1030P.open(successCallback)
            },

            // 프로젝트 조회
            getProjectList() {

                var parent = this
                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/GetNormalProject'.toRealServiceUrl()
                var obj = new Object()
                obj.jsonString = JSON.stringify(this.ProjectSelectTerm)

                var ajaxSuccessHandler = function (data) {

                    var jsonObj = JSON.parse(data.d)
                    parent.CostWeightList = []
                    parent.focusedRowKey = ''
                    parent.ProjectList = jsonObj.Table
                    parent.ProjectListTotalCount = jsonObj.Table1[0]['TotCnt']                    
                }
                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
            },

            // 프로젝트별 가중치 목록 조회
            getCostWeightList(e) {

                var parent = this
                this.CorpCostAmt = []

                if (e.data) {

                    this.CostWeightTerm.ProjectCd = e.data.ProjectCd
                    this.GeneralCorpCost.TargetYear = e.data.ConStartYMD.substr(0, 4)
                } else {

                    this.CostWeightTerm.ProjectCd = e.ProjectCd
                }                

                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/GetCostWeightByProject'.toRealServiceUrl()
                var obj = new Object()
                obj.jsonString = JSON.stringify(this.CostWeightTerm)

                var ajaxSuccessHandler = function (data) {

                    var jsonObj = JSON.parse(data.d)
                    parent.CostWeightList = jsonObj.Table
                    parent.getOrgCost()
                }
                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
            },

            // 선택된 프로젝트 계약종료년도 기준으로 인당평균비용 조회
            getOrgCost() {

                var parent = this
                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/GetGeneralCorpCost'.toRealServiceUrl()
                var obj = new Object()
                obj.jsonString = JSON.stringify(this.GeneralCorpCost)

                var successCallback = function (data) {

                    var jsonObj = JSON.parse(data.d)

                    if (jsonObj.Table.length > 0) {

                        parent.CorpCostAmt = jsonObj.Table
                    }
                }
                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },

            // 프로젝트 계약종료년도 기준으로 일반회사비용 평균치 조회
            setOrgCost(newData, value) {

                var index = this.CorpCostAmt.find(e => e.CostDivCd == value)
                newData.CostDivCd = value
                newData.OrgCostAmt = index.CostAmt1
            },

            // 선택한 로우 인덱스
            onSelectionChanged(e) {

                this.selectedItemKeys = e.selectedRowKeys
            },

            // 가중치 그리드 추가
            addDataTable: function () {
                
                this.$refs[this.CostWeightMangage].instance.addRow()
            },

            // 가중치 적용 비용 계산
            setWeightCost(newData, value, currentRowData) {

                newData.WeightRate = value

                if (value > 100) {
                    alert('가중치를 다시 확인해 주십시오.')
                    return
                }

                var RateCost = currentRowData.OrgCostAmt * value
                newData.WeightCostAmt = currentRowData.OrgCostAmt + RateCost
            },

            // 가중치 그리드 저장
            saveCostWeight: function () {

                var parent = this
                var CostWeightManipulate = new Object()
                var DataCount = this.CostWeightList.length

                for (var i = 0; i < DataCount; i++) {

                    var check = this.CostWeightManipulateList.some(e => e.CostDivCd === this.CostWeightList[i].CostDivCd)

                    if (check) {

                        alert('비용구분은 중복으로 입력할 수 없습니다.')
                        this.CostWeightManipulateList = []
                        return
                    }

                    CostWeightManipulate.Arg = 'I'
                    CostWeightManipulate.ProjectCd = this.CostWeightTerm.ProjectCd
                    CostWeightManipulate.CostDivCd = this.CostWeightList[i].CostDivCd
                    CostWeightManipulate.OrgCostAmt = this.CostWeightList[i].OrgCostAmt
                    CostWeightManipulate.WeightRate = this.CostWeightList[i].WeightRate
                    CostWeightManipulate.WeightCostAmt = this.CostWeightList[i].WeightCostAmt
                    CostWeightManipulate.MemoCont = this.CostWeightList[i].MemoCont
                    CostWeightManipulate.UserId = this.UserId

                    if (!CostWeightManipulate.OrgCostAmt) CostWeightManipulate.OrgCostAmt = 0
                    if (!CostWeightManipulate.WeightRate) CostWeightManipulate.WeightRate = 0
                    if (!CostWeightManipulate.WeightCostAmt) CostWeightManipulate.WeightCostAmt = CostWeightManipulate.OrgCostAmt
                    if (!CostWeightManipulate.MemoCont) CostWeightManipulate.MemoCont = ''

                    var CostObject = Object.assign({}, CostWeightManipulate)
                    this.CostWeightManipulateList.push(CostObject)
                }                

                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/UpsertCostWeightByProject'.toRealServiceUrl()
                var obj = new Object()
                obj.jsonString = JSON.stringify(this.CostWeightManipulateList)

                if (DataCount > 0) {

                    var ajaxSuccessHandler = function () {

                        alert('저장되었습니다.')
                        parent.CostWeightManipulateList = []
                        parent.getCostWeightList(CostWeightManipulate)
                    }
                    $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
                } else {

                    alert('프로젝트별 가중치를 입력 후 시도해 주십시오.')
                }
            },

            // 선택한 로우 삭제
            deleteCostWeight: function () {

                var parent = this
                var selectChange = new Object()
                var dataGrid = this.$refs[this.CostWeightMangage].instance

                // 그리드 선택 삭제
                $(dataGrid.getSelectedRowsData()).each(function (i, o) {

                    if (o.CostDivCd && o.OrgCostAmt) {

                        selectChange.Arg = 'D'
                        selectChange.ProjectCd = parent.CostWeightTerm.ProjectCd
                        selectChange.CostDivCd = o.CostDivCd
                        selectChange.OrgCostAmt = null
                        selectChange.WeightRate = null
                        selectChange.WeightCostAmt = null
                        selectChange.MemoCont = null
                        selectChange.UserId = parent.UserId

                        var selectChageSch = Object.assign({}, selectChange)

                        parent.selectedItems.push(selectChageSch)
                    }
                })

                if (this.selectedItemKeys.length == 0) {
                    alert('삭제할 항목을 선택해주세요.')
                    return
                }

                this.selectedItemKeys.forEach((key) => {
                    this.dataSource.store().remove(key)
                })

                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/UpsertCostWeightByProject'
                url = url.toRealServiceUrl()

                var obj = new Object()
                obj.jsonString = JSON.stringify(this.selectedItems)

                var ajaxSuccessHandler = function () {

                    alert("삭제되었습니다.")
                    parent.selectedItemKeys = []
                    parent.selectedItems = []
                    parent.getCostWeightList(selectChange)
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
            },
        }
    }
</script>