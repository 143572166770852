<template>
	<!--걔약상세등록-->
	<div class="modal fade" id="BZ1022E_Modal" ref="BZ1022E_Modal" tabindex="-1" role="dialog" aria-labelledby="BZ1022E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<button type="button" class="close" data-dismiss="modal">
						<span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
					</button>
					<h3 class="modal-title">계약 상세등록/수정</h3>
				</div>
				<div class="modal-body">
					<section class="add-wrap">
						<form>
							<div class="main-nav-wrap">
								<ul class="nav nav-tabs" style="border-bottom:0px;">
									<li role="presentation" class="active main-project-list">
										<a href="#contractDetail_List" aria-controls="project_table" role="tab" data-toggle="tab" title="계약상세등록">
											<span>계약상세등록</span>
										</a>
									</li>
									<li role="presentation" class="main-project-graph">
										<a href="#contract_Manpower" aria-controls="project_graph" role="tab" data-toggle="tab" title="월별투입인력">
											<span>월별투입인력</span>
										</a>
									</li>
									<li role="presentation" class="main-project-graph">
										<a href="#contract_Purchase" aria-controls="project_graph" role="tab" data-toggle="tab" title="매입">
											<span>매입</span>
										</a>
									</li>
								</ul>
							</div>
							<fieldset>
								<div class="tab-content" style="padding-bottom: 20px;">
									<div role="tabpanel" class="tab-pane active" id="contractDetail_List">
										<article class="project-board tbl-board">
											<section class="cols-wrap">
												<div class="btn-wrap border-bottom">
													<div class="cols col-2">
														<label for="txtContractCorpNm" class="control-label">
															<span><i class="glyphicon glyphicon-asterisk"></i>계약명상세</span>
														</label>
														<input type="text" id="txtContractCorpNm" class="form-control txt" v-model="ContractDetail.ContractSeqNm" />
													</div>
													<div class="cols col-2">
														<label for="selConstractStatusCd" class="control-label">
															<span>계약상태</span>
														</label>
														<input type="text" id="txtConstractStatusCd" class="form-control txt" v-model="ContractDetail.ConstractStatusNm" disabled="disabled" />
														<span hidden="hidden" v-text="ContractDetail.ConstractStatusCd"></span>
													</div>
												</div>
											</section>
											<section class="cols-wrap">
												<div class="cols col-2">
													<label for="txtContractStartDate" class="control-label">
														<span><i class="glyphicon glyphicon-asterisk"></i>계약기간</span>
													</label>
													<div class="col-2 input-group date form_date">
														<DxDateBox id="txtContractStartDate" v-model="ContractDetail.ContractStartDate" class="form-control txt" type="date" date-serialization-format="yyyy-MM-dd" display-format="yyyy-MM-dd"></DxDateBox>
													</div>
													<span class="tail"><span>~</span></span>
													<div class="col-2 input-group date form_date">
														<DxDateBox id="txtContractEndDate" v-model="ContractDetail.ContractEndDate" class="form-control txt" type="date" date-serialization-format="yyyy-MM-dd" display-format="yyyy-MM-dd"></DxDateBox>
													</div>
												</div>
												<div class="cols col-2">
													<label for="txtContractAmount" class="control-label">
														<span><i class="glyphicon glyphicon-asterisk"></i>계약금액</span>
													</label>
													<input type="text" id="txtContractAmount" class="form-control txt" v-model="ContractDetail.ContractAmount"
														   @input="function(e){
                                                            ContractDetail.ContractAmount = e.target.value.toPriceString()
                                                            e.target.value = e.target.value.toPriceString()
                                                       }" />
												</div>
											</section>
											<section class="cols-wrap">
												<div class="cols col-2">
													<label for="selVAYIncludeYn" class="control-label">
														<span>부가세 포함</span>
													</label>
													<select class="form-control" id="selVAYIncludeYn" name="selVAYIncludeYn"
															v-model="ContractDetail.VAYIncludeYn"
															@change="selectBoxChangedEvent">
														<option value="">== 선택 ==</option>
														<option value="Y">Y</option>
														<option value="N">N</option>
													</select>
												</div>
												<div class="cols col-2">
													<label for="txtVATAmount" class="control-label">
														<span>부가세</span>
													</label>
													<input type="text" id="txtVATAmount" class="form-control txt" disabled="disabled" v-model="ContractDetail.VATAmount" />
												</div>
											</section>
											<section class="cols-wrap">
												<div class="cols col-2">
													<label for="selContractDiv" class="control-label">
														<span>계약구분</span>
													</label>
													<input type="text" id="txtConstractStatusCd" class="form-control txt" v-model="ContractDetail.ContractDivNm" disabled="disabled" />
													<span hidden="hidden" v-text="ContractDetail.ContractDiv"></span>
												</div>
											</section>
											<section class="cols-wrap">
												<div class="cols col-2">
													<label for="selCostPlayDiv" class="control-label">
														<span>대금지급 구분</span>
													</label>
													<select class="form-control" id="selCostPlayDiv" name="selCostPlayDiv"
															v-model="ContractDetail.CostPlayDiv">
														<option value="">== 선택 ==</option>
														<option v-for="item in CostPlayDivList" v-bind:value="item.Code">{{item.Name}}</option>
													</select>
												</div>
												<div class="cols cols col-2">
													<label for="txtCostPlayMeans" class="control-label">
														<span>대급지급 조건</span>
													</label>
													<input type="text" id="txtCostPlayMeans" class="form-control txt" v-model="ContractDetail.CostPlayMeans" />
												</div>
											</section>
											<section class="cols-wrap">
												<div class="cols">
													<label for="txtRemark" class="control-label">
														<span>비고</span>
													</label>
													<input type="text" id="txtRemark" class="form-control txt" v-model="ContractDetail.Remark" />
												</div>
											</section>
											<section class="cols-wrap">
												<div class="cols">
													<label for="txtContractExecGuarantee" class="control-label">
														<span>계약이행보증</span>
													</label>
													<input type="text" id="txtContractExecGuarantee" class="form-control txt" v-model="ContractDetail.ContractExecGuarantee" />
												</div>
											</section>
											<section class="cols-wrap">
												<div class="cols">
													<label for="txtExecGuaranteeIns" class="control-label">
														<span>이행보증보험</span>
													</label>
													<input type="text" id="txtExecGuaranteeIns" class="form-control txt" v-model="ContractDetail.ExecGuaranteeIns" />
												</div>
											</section>
											<section class="cols-wrap">
												<div class="cols">
													<label for="txtDefactGuaranteePeriod" class="control-label">
														<span>하자보증기간</span>
													</label>
													<input type="text" id="txtDefactGuaranteePeriod" class="form-control txt" v-model="ContractDetail.DefactGuaranteePeriod" />
												</div>
											</section>
											<section class="cols-wrap">
												<div class="cols">
													<label for="txtEduTitle" class="control-label">
														<span>계약서 서류</span>
													</label>
												</div>
												<div class="fr">
													<a href="#" id="btnChldInst5" class="btn btn-default" title="추가" @click.prevent="addAttachFile('0737010')">
														<span class="glyphicon glyphicon-plus"></span> 추가
													</a>
													<a href="#" id="btnChldRmve5" class="btn btn-default" title="삭제" @click.prevent="removeAttachFile('0737010')">
														<span class="glyphicon glyphicon-minus"></span> 삭제
													</a>
												</div>
											</section>
											<section class="tbl-contents">
												<div class="table-responsive">
													<form id="form5" action="" name="form5" method="post" enctype="multipart/form-data">
														<table class="table tbl-list table-hover table-bordered" id="tb5">
															<colgroup>
																<col class="chk" />
																<col />
															</colgroup>
															<thead>
																<tr>
																	<th class="chk table-colwid5">선택</th>
																	<th scope="col" class="text-center">첨부파일</th>
																</tr>
															</thead>
															<tbody>
																<tr v-if="ContractFileList === null">
																	<td colspan="2" class="text-center no-data">조회된 정보가 없습니다.</td>
																</tr>
																<tr v-else v-for="ContractFile in ContractFileList" v-show="ContractFile.IsShow && ContractFile.FileDivision == '0737010'">
																	<td class="chk">
																		<label>
                                                                            <input type="checkbox" name="cbItem2" @click="checkBoxCheckEvent($event.target.checked, ContractFile)" />
                                                                        </label>
																	</td>
																	<td v-show="ContractFile.IsNew" class="text-center">
																		<input type="file" name="ctlAttachFile"
																			   @change="function(e){
                                                                            ContractFile.Files = e.target.files
                                                                       }" />
																	</td>
																	<td v-show="!ContractFile.IsNew" class="text-left">
																		<a href="#" @click.prevent="fileHandler.soliDocsDownload(ContractFile.DocID)">{{ContractFile.FileName}}</a>
																	</td>
																</tr>
															</tbody>
														</table>
													</form>
												</div>
											</section>
											<section class="cols-wrap">
												<div class="cols">
													<label for="txtEduTitle" class="control-label">
														<span>관련 서류</span>
													</label>
												</div>
												<div class="fr">
													<a href="#" id="btnChldInst5" class="btn btn-default" title="추가" @click.prevent="addAttachFile('0737020')">
														<span class="glyphicon glyphicon-plus"></span> 추가
													</a>
													<a href="#" id="btnChldRmve5" class="btn btn-default" title="삭제" @click.prevent="removeAttachFile('0737020')">
														<span class="glyphicon glyphicon-minus"></span> 삭제
													</a>
												</div>
											</section>
											<section class="tbl-contents">
												<div class="table-responsive">
													<form id="form5" action="" name="form5" method="post" enctype="multipart/form-data">
														<table class="table tbl-list table-hover table-bordered" id="tb5">
															<colgroup>
																<col class="chk" />
																<col />
															</colgroup>
															<thead>
																<tr>
																	<th class="chk table-colwid5">선택</th>
																	<th scope="col" class="text-center">첨부파일</th>
																</tr>
															</thead>
															<tbody>
																<tr v-if="ContractFileList === null">
																	<td colspan="2" class="text-center no-data">조회된 정보가 없습니다.</td>
																</tr>
																<tr v-else v-for="ContractFile in ContractFileList" v-show="ContractFile.IsShow && ContractFile.FileDivision == '0737020'">
																	<td class="chk">
																		<label>
                                                                            <input type="checkbox" name="cbItem2" @click="checkBoxCheckEvent($event.target.checked, ContractFile)" />
                                                                        </label>
																	</td>
																	<td v-show="ContractFile.IsNew" class="text-center">
																		<input type="file" name="ctlAttachFile"
																			   @change="function(e){
																			   ContractFile.Files = e.target.files
																			   ContractFile.Files = e.target.files
                                                                       }" />
																	</td>
																	<td v-show="!ContractFile.IsNew" class="text-left">
																		<a href="#" @click.prevent="fileHandler.soliDocsDownload(ContractFile.DocID)">{{ContractFile.FileName}}</a>
																	</td>
																</tr>
															</tbody>
														</table>
													</form>
												</div>
											</section>
											<section class="btn-align">
												<div v-show="CanSave" class="btn-group">
													<button type="button" id="btnItemSave" class="btn btn-primary" @click.prevent="saveContractDetail">
														<i class="glyphicon glyphicon-save"></i> 저장
													</button>
												</div>
												<div v-show="CanApprove" class="btn-group">
													<button type="button" id="btnItemRmve" class="btn btn-warning" @click.Prevent="checkApprove1" v-bind:disabled="!isActive">
														<span class="glyphicon glyphicon-edit"></span> 결재요청
													</button>
												</div>
												<div class="btn-group">
													<button type="button" class="btn btn-danger" @click.Prevent="close">
														<i class="glyphicon glyphicon-remove"></i> 취소
													</button>
												</div>
											</section>
										</article>
									</div>
									<div role="tabpanel" class="tab-pane" id="contract_Manpower">
										<section class="cols-wrap">
											<div class="btn-wrap border-bottom">
												<div class="cols col-2">
													<label for="txtContractStartDate" class="control-label">
														<span><i class="glyphicon glyphicon-asterisk"></i>수행기간</span>
													</label>
													<div class="col-2 input-group date form_date">
														<DxDateBox id="txtContractStartDate" v-model="ContractDetail.ContractStartDate" class="form-control txt" type="date" display-format="yyyy-MM">
															<DxCalendarOptions max-zoom-level="year" min-zoom-level="decade" zoom-level="year" />
														</DxDateBox>
													</div>
													<span class="tail"><span>~</span></span>
													<div class="col-2 input-group date form_date">
														<DxDateBox id="txtContractEndDate" v-model="ContractDetail.ContractEndDate" class="form-control txt" type="date" display-format="yyyy-MM">
															<DxCalendarOptions max-zoom-level="year" min-zoom-level="decade" zoom-level="year" />
														</DxDateBox>
													</div>
												</div>
												<div class="cols col-2">
													<label for="btnPerson" class="control-label">
														<a href="#" id="btnPerson" class="btn btn-default" @click.prevent="selectManager">
															<span>생성</span>
														</a>
													</label>
													<div style="margin: 5px 0px 3px 0px" class="fr">
														<a href="#" id="btnChldInst" class="btn btn-default" title="추가" @click.prevent="selectManagerAdd">
															<span class="glyphicon glyphicon-plus"></span> 추가
														</a>
														<button type="button" id="btnItemRmve" class="btn btn-default" @click.Prevent="deleteRecords">
															<span class="glyphicon glyphicon-minus"></span> 삭제
														</button>
													</div>
												</div>
											</div>
										</section>
										<section>
											<DxDataGrid :data-source="DataSource"
														:columns="DefaultColumns"
														:show-borders="true"
														:allow-column-reordering="true"
														:selected-row-keys="selectedItemKeys"
														:ref="ContractMM"
														@cell-click="cellClick">
												<DxEditing :allow-updating="true"
														   :allow-adding="false"
														   :allow-deleting="false"
														   mode="cell" />
												<DxSelection mode="multiple" />
											</DxDataGrid>
										</section>
										<section class="btn-align">
											<div v-show="CanSave" class="btn-group">
												<button type="button" id="btnItemSave" class="btn btn-primary" @click.prevent="contractManPoewerSave">
													<i class="glyphicon glyphicon-save"></i> 저장
												</button>
											</div>
											<div v-show="CanApprove" class="btn-group">
												<button type="button" id="btnItemRmve" class="btn btn-warning" @click.Prevent="checkApprove1" v-bind:disabled="!isActive">
													<span class="glyphicon glyphicon-edit"></span> 결재요청
												</button>
											</div>
											<div class="btn-group">
												<button type="button" class="btn btn-danger" @click.Prevent="close">
													<i class="glyphicon glyphicon-remove"></i> 취소
												</button>
											</div>
										</section>
									</div>
									<!-- 매입탭 -->
									<div role="tabpanel" class="tab-pane" id="contract_Purchase">
										<section class="cols-wrap">
											<div class="btn-wrap border-bottom">
												<div style="margin: 5px 0px 3px 0px" class="fr">
													<a href="#" id="btnChldInst" class="btn btn-default" title="추가" @click.prevent="PurchaseHistoryWrite">
														<span class="glyphicon glyphicon-plus"></span> 추가
													</a>
												</div>
											</div>
										</section>
										<section>
											<DxDataGrid :data-source="ProductList"
														:show-borders="true"
														@cell-click="onCellClick">
												<!--<DxEditing :allow-adding="false"
														   :allow-deleting="true" />
												<DxSelection mode="multiple" />-->
												<DxColumn caption="상품명" data-field="ProductNm" alignment="center" width="150" />
												<DxColumn caption="매입품유형" data-field="ProductTypeNm" alignment="center" width="100" />
												<DxColumn caption="수량" data-field="UnitCnt" alignment="center" width="70" />
												<DxColumn caption="납품금액" data-field="DeliveryPrice" alignment="center" format="fixedPoint" width="180" />
												<DxColumn caption="매입금액" data-field="PurchasePrice" alignment="center" format="fixedPoint" width="180" />
												<DxColumn caption="매입처명" data-field="CompanyNm" alignment="center" width="150" />
												<DxColumn caption="매입처담당자" data-field="CompanyManager" alignment="center" width="120" />
												<DxColumn caption="매입 예상일" data-field="DlvDueYMD" alignment="center" data-type="date" width="120" />
												<DxColumn type="buttons" width="120">
													<DxButton text="삭제"
															  :on-click="DeleteClick">
													</DxButton>
												</DxColumn>
											</DxDataGrid>
										</section>
										<section class="btn-align">
											<div v-show="CanSave" class="btn-group">
												<button type="button" id="btnItemSave" class="btn btn-primary" @click.prevent="contractManPoewerSave">
													<i class="glyphicon glyphicon-save"></i> 저장
												</button>
											</div>
											<div v-show="CanApprove" class="btn-group">
												<button type="button" id="btnItemRmve" class="btn btn-warning" @click.Prevent="checkApprove1" v-bind:disabled="!isActive">
													<span class="glyphicon glyphicon-edit"></span> 결재요청
												</button>
											</div>
											<div class="btn-group">
												<button type="button" class="btn btn-danger" @click.Prevent="close">
													<i class="glyphicon glyphicon-remove"></i> 취소
												</button>
											</div>
										</section>
									</div>
								</div>
							</fieldset>
						</form>
					</section>
				</div>
			</div>
		</div>
		<CP1022P ref="CP1022P"></CP1022P>
		<CP1024P ref="CP1024P"></CP1024P>
		<BZ1025E ref="BZ1025E"></BZ1025E>
		<BZ1027E ref="BZ1027E"></BZ1027E>

	</div>
</template>

<script>
	import CP1022P from '@/components/libs/popup/CP1022P'
	import CP1024P from '@/components/libs/popup/CP1024P'
	import BZ1025E from '@/components/bz/popup/BZ1025E'
	import BZ1027E from '@/components/bz/popup/BZ1027E'
	//import EmployeeDropDownBoxComponent from '@/components/libs/popup/CP1024P'

	import { DxDateBox, DxCalendarOptions } from "devextreme-vue/date-box"

	import DxDataGrid, {
		DxColumn,
		DxEditing,
		DxLookup,
		DxSelection,
		DxScrolling,
		DxButton
	} from 'devextreme-vue/data-grid'

	import DxButtonx from 'devextreme-vue/button'

	import DataSource from 'devextreme/data/data_source';

	export default {
		name: 'BZ1022E',
		components: {
			DxDateBox,
			DxButton,
			DxButtonx,
			DxDataGrid,
			DxColumn,
			DxEditing,
			DxLookup,
			DxSelection,
			DxScrolling,
			DxCalendarOptions,
			CP1022P,
			CP1024P,
			BZ1025E,
			BZ1027E
		},
		data() {
			return {
				Progress: null,								// 프로젝트 구분
				ConstractStatusList: null,					// 계약진행상태
				//CostPayDivList: null,					    // 대금지급
				//ContractDivList: null,					// 계약구분
				RoleList: null,								// 역할구분
				RatingList: null,							// 등급구분
				CallbackFunction: null,                     // Caller Function
				Condition: null,
				ContractFileList: null,
				Contract: {
					EmpNo: null,
					ContractNo: null,
					ContractNm: null,
					OrderCompCd: null,
					OrderCompNm: null,
					ContractCompCd: null,
					ContractCompNm: null,
					ProjDiv: '',
					MgrEmpNo: null,
					MgrEmpNm: null,
					Remark: null,
					ConfirmYn: null,
					BizNo: null,
					CntContractSeqNo: null
				},
				ContractDetail: {
					ContractNo: null,
					ContractSeqNo: null,
					ContractSeqNm: null,
					ContractDiv: '',
					ContractStartDate: null,
					ContractEndDate: null,
					ContractAmount: null,
					VAYIncludeYn: 'N',
					VATAmount: '0',
					RelationContract: '',
					ProjMigYn: null,
					BizSeqNo: null,
					Remark: null,
					RegtDate: null,
					RegtEmpNo: null,
					UpdateDate: null,
					UpdateEmpNo: null,
					CostPlayDiv: '',
					CostPlayMeans: null,
					ContractExecGuarantee: null,
					ExecGuaranteeIns: null,
					DefactGuaranteePeriod: null,
					ConstractStatusCd: '0733010',
					ConstractStatusNm: '계약진행'
				},
				ContractFile: {
					ContractNo: null
					, ContractSeqNo: null
					, FileSeq: null
					, GUID: null
					, Extension: null
					, FileName: null
					, FliePath: null
					, RegtDate: null
					, RegtEmpNo: null
					, UpdateDate: null
					, UpdateEmpNo: null
                    , FileDivision: null
				},
				DefaultColumns: [
					{
						caption: "성명", dataField: "InputEmpNm", width: "80"
					},
					{
						caption: "역할", dataField: "Role", width: "80",
						lookup: {
							dataSource: $.scriptBase.getCommonCode('CODE000', '0709000', '', ''),
							valueExpr: "Code", // "id" contains the same values as "authorId"
							displayExpr: "Name"
						}
					},
					{
						caption: "등급", dataField: "Rating", width: "80",
						lookup: {
							dataSource: $.scriptBase.getCommonCode('CODE000', '0313000', '', ''),
							valueExpr: "Code", // "id" contains the same values as "authorId"
							displayExpr: "Name"
						}
					},
					{ caption: "주요업무", dataField: "MainJob", width: "200" }
				],
				ContractInputManpowerSch: {
					ContractNo: null,
					ContractSeqNo: null,
					InputEmpNo: null,
					InputYear: null,
					InputMonth: null,
					InputMM: null,
					RoleSeq: null
				},
				ContractProduct: {
					ContractNo: null,
					ContractSeqNo: null,
				},
				ProductList: new Array(),
				DataSource: new Array(),
				DataSourceList: new Array(),
				ContractInputManpowerSchList: new Array(),
				ContractInputManpowerSchListUp: new Array(),
				DateSum: new Array(),
				DateSumList: new Array(),
				selectChange: {
					InputEmpNo: null,
					ContractNo: null,
					ContractSeqNo: null
				},
				CanSave: true,
				CanDelete: true,
				CanApprove: true,
				isActive: false,
				sizeValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'],
				IsNew: true,
				isMultiLine: true,
				ContractInputManpower: {
					ContractNo: null,
					ContractSeqNo: null,
					InputEmpNm: null,
					InputEmpNo: null,
					Rating: null,
					Role: null,
					ManJob: null,
					RoleSeq: null
				},
                RemoveAttachFileList: null, // 첨부파일 삭제 목록
				selectedItemKeys: [],
				start: new Date(),
				end: new Date(),
				SaveArg: new Array(),
				ContractMM: 'dataGrid',
				Process: "Y"

			}
		},
		watch: {
			ContractDetail: {
				deep: true,
				handler(newData) {

					if (newData.VAYIncludeYn === 'N') {

						this.autoCalculateVat()
					}
				}
			}
		},
		beforeMount() {
			// 공통코드 조회
			this.Progress = $.scriptBase.getCommonCode('CODE000', '0732000', '', '')				// 프로젝트 구분
			this.ConstractStatusList = $.scriptBase.getCommonCode('CODE000', '0733000', '', '')		// 계약진행상태
			this.CostPlayDivList = $.scriptBase.getCommonCode('CODE000', '0721100', '', '')			// 대금지급
			this.ContractDivList = $.scriptBase.getCommonCode('CODE000', '0744000', '', '')			// 계약구분
			this.RoleList = $.scriptBase.getCommonCode('CODE000', '0709000', '', '')				// 역할구분
			this.RatingList = $.scriptBase.getCommonCode('CODE000', '0313000', '', '')				// 등급구분
		},
		methods: {
			open: function (callbackFunction, condition, selectedObj, conObj) {


				if (selectedObj) {
					this.reset()
					// 전자 결재 완료 여부
					this.Contract = Object.assign({}, selectedObj)
					if (conObj.CntContractSeqNo == '0') {
						this.ContractDetail.ContractDiv = "0744010"
						this.ContractDetail.ContractDivNm = "본계약"
					}
					else {
						this.ContractDetail.ContractDiv = "0744030"
						this.ContractDetail.ContractDivNm = "추가계약"
					}

				} else {
					this.reset()
					this.ContractDetail.ContractNo = conObj.ContractNo
					//this.ContractDetail.ContractSeqNm = conObj.ContractNm
					if (conObj.CntContractSeqNo == '0') {
						this.ContractDetail.ContractDiv = "0744010"
						this.ContractDetail.ContractDivNm = "본계약"
					}
					else {
						this.ContractDetail.ContractDiv = "0744030"
						this.ContractDetail.ContractDivNm = "추가계약"
					}
				}

				this.Condition = condition
				this.ContractDetail.Arg = 'PI'
				this.ContractDetail.EmpNo = this.$parent.$parent.EmpId
				this.CallbackFunction = callbackFunction
				// 첨부파일 삭제 목록 변수 초기화
                this.RemoveAttachFileList = new Array()


				$("#BZ1022E_Modal").modal("show")
			},
			close: function () {
				$("#BZ1022E_Modal").modal("hide")
				this.CallbackFunction()
			},
			reset: function () {
				var ConstractStatusList = this.ConstractStatusList
				var RatingList = this.RatingList
				var RoleList = this.RoleList
				var Years = this.Years
				Object.assign(this.$data, this.$options.data())
				this.ConstractStatusList = ConstractStatusList
				this.RatingList = RatingList
				this.RoleList = RoleList
				this.Years = Years
			}
			, getContractFiles() {	// 계약상세 첨부파일 조회
                var that = this

                var ContractSelectTerm = {
                    ContractNo: this.ContractDetail.ContractNo
                    , ContractSeqNo: parseInt(this.ContractDetail.ContractSeqNo)
                }

                var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/GetContractDetailFileList'
                url = url.toRealServiceUrl()
                var obj = new Object()

                obj.jsonString = JSON.stringify(ContractSelectTerm)

                var ajaxSuccessHandler = function (data) {
                    var jsonObj = JSON.parse(data.d)

                    that.ContractFileList = jsonObj.Table
                    for (i = 0; i < that.ContractFileList.length; i++) {
                        that.ContractFileList[i].IsChecked = false
                        that.ContractFileList[i].IsNew = false
                        that.ContractFileList[i].IsRemove = false
                        that.ContractFileList[i].IsShow = true
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
            }
			// 월별 투입인력 삭제
			, deleteRecords: function () {

				var that = this

				that.checkApprove()

				if (that.Process == "Y") {
					const dataGrid = this.$refs[this.ContractMM].instance;

					// 그리드 선택 삭제
					$(dataGrid.getSelectedRowsData()).each(function (i, o) {
						//clslist += o.InputEmpNo + ',';

						that.selectChange.ContractNo = o.ContractNo
						that.selectChange.ContractSeqNo = o.ContractSeqNo
						that.selectChange.InputEmpNo = o.InputEmpNo

						var selectChageSch = Object.assign({}, that.selectChange)

						that.selectedItemKeys.push(selectChageSch)


					})
					if (that.selectedItemKeys.length == 0) {
						alert('삭제할 항목을 선택해주세요.')
						return
					}

					var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/DeleteContractMMPlan'
					url = url.toRealServiceUrl()

					var obj = new Object()
					obj.jsonString = JSON.stringify(that.selectedItemKeys)

					var ajaxSuccessHandler = function (data) {


						that.selectContractManpower()
						alert("삭제되었습니다.")

					}

					$.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
				}
				else if (that.Process == "A") {

				}
				else if (that.Process == "U") {
					alert('정보가 업데이트 되었습니다. 계약 재조회를 해주세요')
				}
				else if(that.Process == "D") {
					alert('삭제된 계약건입니다.')
				}

				

				
			},
			// 매입상품 삭제
			DeleteClick: function (e) {
				if (e.row.rowType === "data") {

					var that = this
					var DeleteList = new Array()

					that.DeleteList = {
						ContractNo: e.row.data.ContractNo,
						ContractSeqNo: e.row.data.ContractSeqNo,
						RoleSeq: e.row.data.RoleSeq
					}

					var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/RemoveContractProduct'
					url = url.toRealServiceUrl()

					var obj = new Object()
					obj.jsonString = JSON.stringify(that.DeleteList)

					var ajaxSuccessHandler = function (data) {


						that.selectContractProduct()
						alert("삭제되었습니다.")

					}

					$.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
				}
			},
			// 담당자 검색
			searchManager: function () {

				var parent = this

				var successCallback = function (result) {

					parent.ContractDetail.MgrEmpNm = result.EmpNm
					parent.ContractDetail.MgrEmpNo = result.EmpNo
				}

				this.$refs.CP1021P.showManPopup(successCallback, "BZ1022E_Modal")
			},
			onCellClick(e) {

				if (e.rowType === "data" && e.column.dataField === "ProductNm") {
					var callbackFuntion = function (result) {
					}

					var Arg = "CV"

					// 팝업 오픈
					this.$refs.BZ1025E.open(callbackFuntion, Arg, e.row.data)
				}
			},
			//월별투입인력 생성 전 담당자 검색
			selectManager: function () {

				var parent = this

				if (!parent.ContractDetail.ContractSeqNo) {

					alert('변경계약을 먼저 저장해주세요.')
					document.getElementById('txtContractCorpNm').focus()
					parent.pageIndex = 1

					return;
				}

				parent.checkApprove()

				if (parent.Process == "Y") {
					var successCallback = function (result) {

						parent.DataSource.InputEmpNm = result.EmpNm
						parent.DataSource.InputEmpNo = result.EmpNo

						parent.SaveArg.Arg = "New"

						setTimeout(parent.createAndSave(), 5000);
					}

					this.$refs.CP1024P.open(successCallback)
				}
				else if (parent.Process == "A") {

				}
				else if (parent.Process == "U") {
					alert('정보가 업데이트 되었습니다. 계약 재조회를 해주세요')
				}
				else if (that.Process == "D") {
					alert('삭제된 계약건입니다.')
				}
			},
			//월별투입인력 추가 전 담당자 검색
			selectManagerAdd: function () {

				var parent = this

				if (!parent.ContractDetail.ContractSeqNo) {

					alert('계약상세등록을 먼저 저장해주세요.')
					document.getElementById('txtContractCorpNm').focus()
					parent.pageIndex = 1

					return;
				}

				parent.checkApprove()

				if (parent.Process == "Y") {
					parent.contractManPoewerAdd()

					var successCallback = function (result) {

						parent.DataSource.InputEmpNm = result.EmpNm
						parent.DataSource.InputEmpNo = result.EmpNo

						parent.SaveArg.Arg = "Add"

						setTimeout(parent.createAndSave(), 5000);
					}

					parent.$refs.CP1024P.open(successCallback)
				}
				else if (parent.Process == "A") {

				}
				else if (parent.Process == "U") {
					alert('정보가 업데이트 되었습니다. 계약 재조회를 해주세요')
				}
				else if (that.Process == "D") {
					alert('삭제된 계약건입니다.')
				}
			},
			//매입내역 추가
			PurchaseHistoryWrite: function () {

				var parent = this

				if (!this.ContractDetail.ContractSeqNo) {

					alert('계약상세등록을 먼저 저장해주세요.')
					document.getElementById('txtContractCorpNm').focus()
					this.pageIndex = 1

					return;
				}

				var Arg = "CI"

				parent.checkApprove()

				if (parent.Process == "Y") {
					var successCallback = function () {

					}

					this.$refs.BZ1025E.open(successCallback, Arg, null)
				}
				else if (parent.Process == "A") {

				}
				else if (parent.Process == "U") {
					alert('정보가 업데이트 되었습니다. 계약 재조회를 해주세요')
				}
				else if (that.Process == "D") {
					alert('삭제된 계약건입니다.')
				}

				
			},
			// 콤보박스 변경 이벤트
			selectBoxChangedEvent: function () {

				if (event.target.value === 'N') {

					// 부가세 자동계산
					this.autoCalculateVat()

					this.ContractDetail.VAYIncludeYn = 'N'
				}
				else {

					this.ContractDetail.VATAmount = '0'
					this.ContractDetail.VAYIncludeYn = 'Y'
				}
			},
			// 부가세 자동계산
			autoCalculateVat: function () {

				if (this.ContractDetail.ContractAmount) {

					var vat = Number(this.ContractDetail.ContractAmount.toNumber()) * 0.1
					this.ContractDetail.VATAmount = Math.round(vat).toPriceNumber()
				}
				else {

					this.ContractDetail.VATAmount = null
				}
			},
			cellClick: function (e) {
				var that = this
				if (e.rowType === "data" && e.column.dataField === "InputEmpNm") {
					var parent = this

					var successCallback = function (result) {

						parent.DataSource.InputEmpNm = result.EmpNm
						parent.DataSource.InputEmpNo = result.EmpNo
					}

					this.$refs.CP1022P.open(successCallback)
				}
			},
			// 월별투입인력 생성
			createAndSave: function () {
				
				this.ContractInputManpowerSchList = []
				this.DateSumList = []
				this.ContractInputManpower = {
					ContractNo: null,
					ContractSeqNo: null,
					InputEmpNm: null,
					InputEmpNo: null,
					Rating: null,
					Role: null,
					ManJob: null,
					RoleSeq: null
				}

				//var calStartDate = this.ContractDetail.ContractStartDate.toLocaleDateString('ko', { year: 'numeric', month: '2-digit', day: '2-digit' })
				//var calEndDate = this.ContractDetail.ContractEndDate.toLocaleDateString('ko', { year: 'numeric', month: '2-digit', day: '2-digit' })

				var calStartDate = this.ContractDetail.ContractStartDate
				var calEndDate = this.ContractDetail.ContractEndDate

				var startYaer = calStartDate.substring(0, 4)
				var startMonth = calStartDate.substring(6, 8)

				var endtYaer = calEndDate.substring(0, 4)
				var endMonth = calEndDate.substring(6, 8)

				if (startYaer == endtYaer) {
					var dateCalculate = (endMonth - startMonth) + 1
				}
				else {
					var dateCalculate = (endMonth - startMonth) + 1
				}

				//var dateCalculate = (this.end.getTime() - this.start.getTime()) / (24 * 60 * 60 * 1000 * 30)
				//var diffMonth = Math.floor(dateCalculate)



				for (var i = 0; i < dateCalculate; i++) {
					var columnName = i
					if (columnName != 0) {
						startMonth++
						if (startMonth >= 13) {
							startMonth = 1
							startYaer++
						}
						this.ContractInputManpowerSch.ContractNo = this.ContractDetail.ContractNo
						this.ContractInputManpowerSch.ContractSeqNo = this.ContractDetail.ContractSeqNo
						this.ContractInputManpowerSch.InputEmpNo = this.DataSource.InputEmpNo
						this.ContractInputManpowerSch.InputYear = startYaer
						this.ContractInputManpowerSch.InputMonth = ("0" + startMonth).slice(-2)
						this.ContractInputManpowerSch.EmpNo = this.ContractDetail.EmpNo
						this.ContractInputManpowerSch.InputMM = null
						this.ContractInputManpowerSch.Arg = "In"
						this.DateSum.Sum = this.ContractInputManpowerSch.InputYear + this.ContractInputManpowerSch.InputMonth

					}
					else {
						this.ContractInputManpowerSch.ContractNo = this.ContractDetail.ContractNo
						this.ContractInputManpowerSch.ContractSeqNo = this.ContractDetail.ContractSeqNo
						this.ContractInputManpowerSch.InputEmpNo = this.DataSource.InputEmpNo
						this.ContractInputManpowerSch.InputYear = startYaer
						this.ContractInputManpowerSch.InputMonth = ("0" + startMonth).slice(-2)
						this.ContractInputManpowerSch.EmpNo = this.ContractDetail.EmpNo
						this.ContractInputManpowerSch.InputMM = null
						this.ContractInputManpowerSch.Arg = "In"
						this.DateSum.Sum = this.ContractInputManpowerSch.InputYear + this.ContractInputManpowerSch.InputMonth
					}

					var contractInputManpowerSch = Object.assign({}, this.ContractInputManpowerSch)
					var dateSum = Object.assign({}, this.DateSum)

					this.ContractInputManpowerSchList.push(contractInputManpowerSch)
					//this.DateSumList.push(dateSum)
				}

				var that = this
				if (that.SaveArg.Arg == "New") {
					var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/SaveContractMMPlan'
				}
				else {
					var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/AddContractMMPlan'
				}
				url = url.toRealServiceUrl()

				var obj = new Object()

				this.ContractInputManpower.ContractNo = this.ContractDetail.ContractNo
				this.ContractInputManpower.ContractSeqNo = this.ContractDetail.ContractSeqNo
				this.ContractInputManpower.InputEmpNo = this.DataSource.InputEmpNo
				this.ContractInputManpower.EmpNo = this.ContractDetail.EmpNo

				obj.jsonString = JSON.stringify(this.ContractInputManpower)
				obj.jsonString2 = JSON.stringify(this.ContractInputManpowerSchList)

				var ajaxSuccessHandler = function (data) {

					that.selectContractManpower()

				}

				$.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)

			},
			//월별투입인력 추가
			contractManPoewerAdd: function () {

				var that = this
				var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/UpdateContractMMPlan'
				url = url.toRealServiceUrl()

				var obj = new Object()

				var DataCount = that.DataSource.length
				var DateCount = that.DateSumList.length

				for (var i = 0; i < DataCount; i++) {
					that.ContractInputManpower.ContractNo = that.DataSource[i].ContractNo
					that.ContractInputManpower.ContractSeqNo = that.DataSource[i].ContractSeqNo
					that.ContractInputManpower.InputEmpNo = that.DataSource[i].InputEmpNo
					that.ContractInputManpower.Rating = that.DataSource[i].Rating
					that.ContractInputManpower.Role = that.DataSource[i].Role
					that.ContractInputManpower.ManJob = that.DataSource[i].MainJob
					that.ContractInputManpower.RoleSeq = that.DataSource[i].RoleSeq
					that.ContractInputManpower.EmpNo = that.ContractDetail.EmpNo

					var mmPlan = Object.assign({}, that.ContractInputManpower)
					that.DataSourceList.push(mmPlan)

					for (var k = 0; k < DateCount; k++) {
						var DateMM = that.DateSumList[k]

						that.ContractInputManpowerSch.ContractNo = that.DataSource[i].ContractNo
						that.ContractInputManpowerSch.ContractSeqNo = that.DataSource[i].ContractSeqNo
						that.ContractInputManpowerSch.InputEmpNo = that.DataSource[i].InputEmpNo
						that.ContractInputManpowerSch.InputYear = (that.DateSumList[k]).slice(0, 4)
						that.ContractInputManpowerSch.InputMonth = (that.DateSumList[k]).slice(-2)
						that.ContractInputManpowerSch.InputMM = that.DataSource[i][DateMM]
						that.ContractInputManpowerSch.RoleSeq = that.DataSource[i].RoleSeq
						that.ContractInputManpowerSch.Arg = 'Up'
						//that.ContractInputManpowerSch.Arg = 'In'

						var contractInputManpowerSch = Object.assign({}, that.ContractInputManpowerSch)
						that.ContractInputManpowerSchListUp.push(contractInputManpowerSch)
					}
				}

				obj.jsonString = JSON.stringify(that.DataSourceList)
				obj.jsonString2 = JSON.stringify(that.ContractInputManpowerSchListUp)

				var ajaxSuccessHandler = function (data) {

				}

				$.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)

			},
			//월별투입인력 업데이트
			contractManPoewerSave: function () {

				var that = this

				that.checkApprove()

				if (that.Process == "Y") {
					var that = this
					var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/UpdateContractMMPlan'
					url = url.toRealServiceUrl()

					var obj = new Object()

					var DataCount = that.DataSource.length
					var DateCount = that.DateSumList.length

					for (var i = 0; i < DataCount; i++) {
						that.ContractInputManpower.ContractNo = that.DataSource[i].ContractNo
						that.ContractInputManpower.ContractSeqNo = that.DataSource[i].ContractSeqNo
						that.ContractInputManpower.InputEmpNo = that.DataSource[i].InputEmpNo
						that.ContractInputManpower.Rating = that.DataSource[i].Rating
						that.ContractInputManpower.Role = that.DataSource[i].Role
						that.ContractInputManpower.ManJob = that.DataSource[i].MainJob
						that.ContractInputManpower.RoleSeq = that.DataSource[i].RoleSeq
						that.ContractInputManpower.EmpNo = that.ContractDetail.EmpNo

						var mmPlan = Object.assign({}, that.ContractInputManpower)
						that.DataSourceList.push(mmPlan)

						for (var k = 0; k < DateCount; k++) {
							var DateMM = that.DateSumList[k]

							that.ContractInputManpowerSch.ContractNo = that.DataSource[i].ContractNo
							that.ContractInputManpowerSch.ContractSeqNo = that.DataSource[i].ContractSeqNo
							that.ContractInputManpowerSch.InputEmpNo = that.DataSource[i].InputEmpNo
							that.ContractInputManpowerSch.InputYear = (that.DateSumList[k]).slice(0, 4)
							that.ContractInputManpowerSch.InputMonth = (that.DateSumList[k]).slice(-2)
							that.ContractInputManpowerSch.InputMM = that.DataSource[i][DateMM]
							that.ContractInputManpowerSch.RoleSeq = that.DataSource[i].RoleSeq
							that.ContractInputManpowerSch.Arg = 'Up'
							that.ContractInputManpowerSch.EmpNo = this.ContractDetail.EmpNo
							//that.ContractInputManpowerSch.Arg = 'In'

							var contractInputManpowerSch = Object.assign({}, that.ContractInputManpowerSch)
							that.ContractInputManpowerSchListUp.push(contractInputManpowerSch)
						}
					}

					obj.jsonString = JSON.stringify(that.DataSourceList)
					obj.jsonString2 = JSON.stringify(that.ContractInputManpowerSchListUp)

					var ajaxSuccessHandler = function (data) {

						alert('저장되었습니다.')

					}

					$.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
				}
				else if (that.Process == "A") {

				}
				else if (that.Process == "U") {
					alert('정보가 업데이트 되었습니다. 계약 재조회를 해주세요')
				}
				else if (that.Process == "D") {
					alert('삭제된 계약건입니다.')
				}
			},
			// 계약상세 월별투입인력 조회
			selectContractManpower: function () {

				var that = this
				var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/GetContractMMPlanList'
				url = url.toRealServiceUrl()

				var obj = new Object()
				obj.jsonString = JSON.stringify(that.ContractInputManpower)

				var ajaxSuccessHandler = function (data) {

					var jsonObj = JSON.parse(data.d)

					that.resetColumns()

					if (!jsonObj.Table) {
						jsonObj.Table = new Array()
					}

					that.DataSource = jsonObj.Table
					// 그리드 바인딩
					that.DataCount = jsonObj.Table.length

					var columnsCount = that.DataCount > 0 ? Object.keys(jsonObj.Table[0]).length : 0

					for (var i = 0; i < columnsCount - 9; i++) {
						var columnName = Object.keys(jsonObj.Table[0])[i]
						var newColumn = { 'caption': columnName, 'dataField': columnName, width: '100' }
						that.DefaultColumns.push(newColumn)
						that.DateSumList.push(columnName)
					}

				}

				$.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
			},
			resetColumns: function () {
				var cnt = this.DefaultColumns.length

				for (var i = 4; i < cnt; i++) {
					this.DefaultColumns.splice(4, 1)
				}
			},
			// 계약상세등록
			saveContractDetail: function () {

				var that = this

				var successUploadFileCallback = function (result) {

					that.doSaveContractDetail(result)
				}

				if (!this.ContractDetail.ContractSeqNm) {

					alert('계약명상세를 입력하세요.')
					document.getElementById('txtContractCorpNm').focus()
					this.pageIndex = 1

					return;
				}
				if (!this.ContractDetail.ConstractStatusCd) {

					alert('계약상태를 선택하세요.')
					document.getElementById('selConstractStatusCd').focus()
					this.pageIndex = 0

					return;
				}
				if (!this.ContractDetail.ContractStartDate) {

					alert('계약기간을 시작일자를 선택하세요.')
					document.getElementById('txtContractStartDate').focus()
					this.pageIndex = 1

					return;
				}
				if (!this.ContractDetail.ContractEndDate) {

					alert('계약기간을 종료일자를 선택하세요.')
					document.getElementById('txtContractEndDate').focus()
					this.pageIndex = 1

					return;
				}
				if (!this.ContractDetail.ContractAmount) {

					alert('계약금액을 입력하세요.')
					document.getElementById('txtContractAmount').focus()
					this.pageIndex = 1

					return;
				}
				if (!this.ContractDetail.ContractDiv) {

					alert('계약구분을 입력하세요.')
					document.getElementById('selContractDiv').focus()
					this.pageIndex = 1

					return;
				}

				if (this.ContractFileList != null && this.ContractFileList != '') {

					// 신규 파일이 존재하는지 체크
					var isExistNewFile = this.ContractFileList.some(function (file) {
						return file.IsNew
					})

					var newFileList = new Array()

					if (isExistNewFile) {

						var isValidateFiles = true

						for (var i = 0; i < this.ContractFileList.length; i++) {

							var file = this.ContractFileList[i]

							if (file.IsNew && !file.IsRemove) {

								if (file.Files != null) {

									for (var j = 0; j < file.Files.length; j++) {

                                        file.FileName = file.Files[j].name
										newFileList.push(file.Files[j])
									}
								}
								else {

									alert('첨부파일을 선택하세요.')

									isValidateFiles = false
									break
								}
							}
						}

						if (isValidateFiles) {

                            this.fileHandler.SoliDocsupload(newFileList, successUploadFileCallback)
						}
					}
					else {
						that.doSaveContractDetail(null)
					}
				}
				else {

					that.doSaveContractDetail(null)
				}
			},
			//살제 계약상세 저장 메서드
			doSaveContractDetail: function (uploadFiles) {

				var that = this

				if (that.Process == "A") {
					alert('전자결재가 진행중입니다.')
					return;
				}
				
                var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/SaveContractDetail'
				url = url.toRealServiceUrl()
				var attachFileList = new Array()
                var division = ""

				that.ContractDetail.startDate = that.ContractDetail.ContractStartDate
				that.ContractDetail.endDate = that.ContractDetail.ContractEndDate

				that.ContractDetail.BizNo = null
				that.ContractDetail.PrntContractSeqNo = null
				that.ContractDetail.ProjectCd = null
				that.ContractDetail.ProjectNo = null

				var obj = new Object()
				obj.jsonString = JSON.stringify(that.ContractDetail)

				if (uploadFiles != null) {
					uploadFiles.files.forEach(function (object) {

                        for (var i = 0; i < that.ContractFileList.length; i++) {
                            if (object.oFileName == that.ContractFileList[i].FileName) {
                                division = that.ContractFileList[i].FileDivision
                                that.ContractFileList[i].IsNew = false
                            }
                        }

						attachFileList.push({
							'Arg': 'CI'
							, 'EmpNo': that.ContractDetail.EmpNo
							, 'ContractNo': that.ContractDetail.ContractNo
							, 'ContractSeqNo': that.ContractDetail.ContractSeqNo
							, 'GUID': object.nFileName
							, 'FileName': object.oFileName
                            , 'FileDivision': division
                            , 'DocID': ''
						})
					})
				}

				obj.jsonFileString = JSON.stringify(attachFileList)

				var ajaxSuccessHandler = function (data) {
					var datasplit = data.d.split(':')
					that.ContractDetail.ContractSeqNo = datasplit[0]
					var flag = datasplit[1]

					if (flag == "-1") {
						alert('전자결재가 진행중입니다.')
					}
					else {
						//that.ContractDetail.ContractSeqNo = data.d
						alert('저장되었습니다.')
						that.isActive = true
                        that.getContractFiles()
					}
				}

				$.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
			},
			saveManPowerPlan: function () {

				var that = this
				var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/SaveContractDetail'
				url = url.toRealServiceUrl()

				var obj = new Object()
				obj.jsonString = JSON.stringify(that.DefaultColumns)

			},

			// 첨부파일 체크박스 메서드
			checkBoxCheckEvent(isChecked, item) {
                if (isChecked) {

                    this.RemoveAttachFileList.push(item)
                }
                else {

                    var removeIndex = this.RemoveAttachFileList.indexOf(item)

                    if (removeIndex > -1) {

                        this.RemoveAttachFileList.splice(removeIndex, 1)
                    }
                }
            },

			// 첨부파일 추가
			addAttachFile: function (division) {

				if (this.ContractFileList === null || this.ContractFileList === '') {

					this.ContractFileList = new Array()
				}
                else {
                    var isContractFile = this.ContractFileList.some(function (file) {
                        return file.FileDivision == '0737010'
                    })

                    if (division == '0737010' && isContractFile) {
                        alert("계약서 파일은 한개만 등록이 가능합니다.")
                        return false
                    }
                }

				// 신규파일 추가
				this.ContractFileList.push({
					IsNew: true
					, IsRemove: false
					, IsShow: true
					, IsChecked: false
					, Files: null
					, CorpCd: null
					, CompanyCd: null
					, BizNo: null
					, SeqNo: null
					, FileSeq: null
					, FileName: null
					, GUID: null
                    , FileDivision: division
                    , DocId: null
				})
			},
			// 첨부파일 삭제
			removeAttachFile: function () {

				var that = this
                var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/RemoveContractDetailFile'
                url = url.toRealServiceUrl()
                var obj = new Object()

                if (that.RemoveAttachFileList.length > 0) {
					if (confirm('선택한 파일을 삭제 하시겠습니까?')) {
                        var removefilelist = new Array()

                        that.RemoveAttachFileList.forEach(function (item) {
							var removeIndex = that.ContractFileList.indexOf(item)

							if (removeIndex > -1) {
                                that.ContractFileList[removeIndex].IsShow = false
							}

							// DB에서 삭제 할 파일 목록 추가
                            // 선택한 파일 삭제 플래그 변경
                            item.IsRemove = item.ischecked

                            // 기존파일은 db에서 삭제하기위해 별도로 모음
                            if (!item.IsNew && item.IsChecked === true) {

                                item.Arg = 'cd'
                                removefilelist.push(item)
                            }
						})
                        if (removefilelist.length > 0) {

                            obj.jsonFilrString = JSON.stringify(removefilelist)

							var successCallback = function (data) {
                                that.getContractFiles()
                            }

                            $.scriptBase.executeAjaxAsync(url, obj, successCallback)
                        }

                        // 첨부파일 삭제 목록 변수 초기화
                        this.RemoveAttachFileList = new Array()

                        // 선택 체크박스 모두 해제
                        document.querySelectorAll('input[type=checkbox][name=cbItem2]').forEach(function (item) {

                            item.checked = false
                        })

                        // 첨부파일 목록이 없으면 변수 초기화
                        if (that.ContractFileList.length === 0) {

                            that.ContractFileList = null
                        }
                    }
                }

				//var that = this
				//var removeFileList = new Array()

				//// 삭제할 파일이 존재하는지 체크
				//var isExist = this.ContractFileList.some(function (object) {

				//	return object.IsChecked
				//})

				//if (isExist) {

				//	if (confirm('삭제 하시겠습니까?')) {

				//		// 삭제할 파일들만 별도의 리스트 객체에 담기
				//		this.ContractFileList.forEach(function (object) {

				//			// 선택한 파일 삭제 플래그 변경
				//			object.IsRemove = object.IsChecked

				//			// 기존파일은 DB에서 삭제하기위해 별도로 모음
				//			if (!object.IsNew && object.IsChecked === true) {

				//				object.Arg = 'CD'
				//				removeFileList.push(object)
				//			}
				//		})

				//		var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/RemoveContractDetailFile'
				//		url = url.toRealServiceUrl()
				//		var obj = new Object()

				//		obj.jsonFilrString = JSON.stringify(removeFileList)

				//		// 서비스호출 성공 콜백 함수
				//		var successCallback = function (data) {

				//			//$(that.$refs.BZ1003E_Modal).modal('hide')
				//			alert('삭제가 완료되었습니다.')
                            
				//		}

				//		$.scriptBase.executeAjaxAsync(url, obj, successCallback)

				//	}
				//	else {
				//		alert('삭제할 파일이 없습니다.')
				//	}
				//}
				//else {

				//	alert('삭제할 파일이 없습니다.')
				//}
			},
			// 계약상세 매입상품 조회
			selectContractProduct: function () {

				var that = this
				var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/GetContractProduct'
				url = url.toRealServiceUrl()

				var obj = new Object()

				that.ContractProduct.ContractNo = that.ContractDetail.ContractNo
				that.ContractProduct.ContractSeqNo = that.ContractDetail.ContractSeqNo

				obj.jsonString = JSON.stringify(that.ContractProduct)

				var ajaxSuccessHandler = function (data) {

					var jsonObj = JSON.parse(data.d)

					that.ProductList = jsonObj.Table

				}

				$.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
			},
			// 영업관리 삭제 버튼 클릭 이벤트 핸들러
			removeData: function () {
				var that = this
				//var url = '/Mini.Service.IBS.HM.Service/HumanMgt.svc/ajax/RemoveEduHistory'
				//url = url.toRealServiceUrl()
				//var obj = new Object()
				//obj.jsonString = JSON.stringify(that.EduHistory)

				//var ajaxSuccessHandler = function (data) {
				//    that.close()
				//}

				//$.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
			},
			approveData: function () {
				//alert("결재 요청시 교육 이수증 첨부 바랍니다.")
				//// popup
				//this.$refs.frmApproval.submit()
				//this.close()

				var parent = this

				//var calStartDate = this.ContractDetail.ContractStartDate.toLocaleDateString('ko', { year: 'numeric', month: '2-digit', day: '2-digit' })
				//var calEndDate = this.ContractDetail.ContractEndDate.toLocaleDateString('ko', { year: 'numeric', month: '2-digit', day: '2-digit' })

				var calStartDate = this.ContractDetail.startDate
				var calEndDate = this.ContractDetail.endDate

				//var start = calStartDate.substring(0, 4) + "-" + calStartDate.substring(6, 8) + "-" + calStartDate.substring(10, 12)
				//var end = calEndDate.substring(0, 4) + "-" + calEndDate.substring(6, 8) + "-" + calEndDate.substring(10, 12)

				//parent.ContractDetail.start = start
				//parent.ContractDetail.end = end

				parent.ContractDetail.start = calStartDate.toLocaleDateString('ko', { year: 'numeric', month: '2-digit', day: '2-digit' })
				parent.ContractDetail.end = calEndDate.toLocaleDateString('ko', { year: 'numeric', month: '2-digit', day: '2-digit' })

				var successCallback = function (result) {

				}

				this.$refs.BZ1027E.open(successCallback, parent.ContractDetail)
			},
			// 전자결재 진행여부 확인
			checkApprove: function () {

				var that = this

				var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/SetCheckApprove'
				url = url.toRealServiceUrl()
				var obj = new Object()
				obj.jsonString = JSON.stringify(this.ContractDetail)

				var ajaxSuccessHandler = function (data) {
					if (data.d == "-1") {
						alert('전자결재가 진행중입니다.')
						that.Process = "A"
					}
					else if (data.d == "1") {
						that.Process = "Y"

					}
				}

				$.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
			},

			// 전자결재 진행여부 확인
			checkApprove1: function () {

				var that = this

				var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/SetCheckApprove'
				url = url.toRealServiceUrl()
				var obj = new Object()
				obj.jsonString = JSON.stringify(this.ContractDetail)

				var ajaxSuccessHandler = function (data) {
					if (data.d == "-1") {
						alert('전자결재가 진행중입니다.')
						that.Process = "A"
					}
					else if (data.d == "1") {
						that.Process = "Y"
						that.doSaveContractDetail()
						that.approveData()
					}
				}

				$.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
			}
		}
	}
</script>
