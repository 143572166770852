<template>
    <div>
        <article class="tbl-contents tbl-board">
            <div class="alert alert-info total alert-only">
                <p>
                    <i class="glyphicon glyphicon-folder-close"></i>
                    <span>전체: <span id="spTotCnt">0</span>건</span>
                </p>
            </div>
            <div class="table-responsive">
                <table class="table tbl-list tbl-center table-hover table-fixed table-board" id="boardList">
                    <colgroup>
                        <col class="number-width" />
                        <col />
                        <col class="name-width" />
                        <col class="name-width" />
                        <col class="number-width" />
                        <col class="number-width" />
                        <col class="number-width" />
                    </colgroup>
                    <thead>
                        <tr style="cursor:pointer">
                            <th scope="col"><p class="tbl-line">No</p></th>
                            <th scope="col"><p class="tbl-line">건의내용</p></th>
                            <th scope="col"><p class="tbl-line">작성자</p></th>
                            <th scope="col"><p class="tbl-line">작성일</p></th>
                            <th scope="col"><p class="tbl-line">처리완료</p></th>
                            <th scope="col"><p class="tbl-line">반려여부</p></th>
                            <th scope="col"><p class="tbl-line">조회수</p></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="7" class="text-center no-data">조회된 정보가 없습니다.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </article>

        <article>
            <form>
                <fieldset>
                    <legend>검색폼</legend>
                    <section>
                        <div class="tbl-btn-area">
                            <div class="col-xs-6">
                            </div>
                            <div class="col-xs-6">
                                <div class="btn-align">
                                    <!--@if (ViewBag.CanNew)
                                    {
                                    <a id="btnListInst" class="btn btn-default" data-toggle="modal">
                                        <span><i class="glyphicon glyphicon-pencil"></i> 글쓰기</span>
                                    </a>
                                    }-->
                                </div>
                            </div>
                        </div>
                        <div class="tbl-page-area">
                            <div class="text-center">
                                <ul class="pagination" id="ulpager"></ul>
                            </div>
                        </div>
                        <div class="tbl-sch-area">
                            <div class="cols">
                                <div class="cols-btns-cont">
                                    <div class="col-xs-3">
                                        <select id="selYn" class="form-control">
                                            <option value="">처리여부</option>
                                            <option value="Y">Y</option>
                                            <option value="N">N</option>
                                        </select>
                                    </div>
                                    <div class="col-xs-3">
                                        <select class="form-control" id="selDateType">
                                            <option value="">전체기간</option>
                                            <option value="D">오늘</option>
                                            <option value="W">최근 1주</option>
                                        </select>
                                    </div>
                                    <div class="col-xs-3">
                                        <select class="form-control" id="selSrchType">
                                            <option value="">검색조건</option>
                                            <option value="C">내용</option>
                                            <option value="U">작성자</option>
                                            <option value="T">제목</option>
                                        </select>
                                    </div>
                                    <div class="col-xs-3">
                                        <input type="text" class="form-control" id="txtSrchText" />
                                    </div>
                                </div>
                                <div class="cols-btns">
                                    <!--@if (ViewBag.CanSearch)
                                    {
                                    <a href="javascript:void(0);" id="btnListSrch" class="btn btn-primary btn-row2"><span><i class="glyphicon glyphicon-search"></i> 검색</span></a>
                                    }-->
                                </div>

                            </div>
                        </div>
                    </section>
                </fieldset>
            </form>
        </article>
    </div>
</template>

<script>
    export default {
        name: 'BM1020R'
    }
</script>

<style scoped>
</style>