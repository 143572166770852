<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a href="#" id="btnListSrch" class="btn btn-primary btn-row2" @click.prevent="showBillData">
                    <span>
                        <i class="glyphicon glyphicon-search"></i> 조회
                    </span>
                </a>
                <a href="#" id="btnListSave" class="btn btn-default" @click.prevent="updateCorpCardBill">
                    <span>
                        <i class="glyphicon glyphicon-save"></i> 저장
                    </span>
                </a>
            </div>
            <div class="sch-box">
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="selDateType" class="control-label">
                                            <span>승인일자</span>
                                        </label>
                                        <div class="col-xs-3">
                                            <select class="form-control" id="selDateType" @change="dateTypeChangeEvent">
                                                <option value="1">작년도</option>
                                                <option value="2">이번 연도</option>
                                                <option value="3" selected="selected">전월</option>
                                                <option value="4">당월</option>
                                            </select>
                                        </div>
                                        <div class="col-xs-9">
                                            <label for="txtStartDate" class="sr-only"><span>승인일자</span></label>
                                            <div class="col-2 input-group date">
                                                <DxDateBox display-format="yyyy-MM-dd"
                                                           pickerType="calendar"
                                                           v-model="CorpCardBillSelectTerm.StartDate"
                                                           @value-changed="showBillData"
                                                           style="border-radius:0;" />
                                            </div>
                                            <div class="tail"><span>~</span></div>
                                            <label for="txtEndDate" class="sr-only"><span>승인일자</span></label>
                                            <div class="col-2 input-group date">
                                                <DxDateBox display-format="yyyy-MM-dd"
                                                           pickerType="calendar"
                                                           v-model="CorpCardBillSelectTerm.EndDate"
                                                           @value-changed="showBillData"
                                                           style="border-radius:0;" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cols col-4">
                                        <label for="selCardNo" class="control-label">
                                            <span>카드번호</span>
                                        </label>
                                        <select class="form-control" id="selCardNo" v-model="CorpCardBillSelectTerm.CorpCardNo" @change="showBillData">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in CorpCardNoList" v-bind:value="item.CardNo">
                                                {{item.CardNo}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-4">
                                        <label for="selProcessingYN" class="control-label">
                                            <span>처리여부</span>
                                        </label>
                                        <select class="form-control" id="selProcessingYN" v-model="CorpCardBillSelectTerm.ProcessingYN" @change="showBillData">
                                            <option value="">== 전체 ==</option>
                                            <option value="Y">Y</option>
                                            <option value="N">N</option>
                                        </select>
                                    </div>
                                </section>
                            </section>
                        </fieldset>
                    </form>
                </div>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">법인카드 청구관리</p>
                </div>
                <div>
                    <div class="alert alert-info total">
                        <p>
                            <span class="glyphicon glyphicon-folder-close"></span>
                            전체: <span id="spTotCnt">{{ CorpCardBillCnt }}</span>건
                        </p>
                    </div>
                    <div class="table-responsive">
                        <DxDataGrid id="grid-container"
                                    :ref="BillData"
                                    :data-source="CorpCardBillList"
                                    :show-borders="false"
                                    :show-row-lines="true"
                                    :hoverStateEnabled="true"
                                    :allow-column-resizing="true"
                                    :column-resizing-mode="'widget'"
                                    :column-auto-width="true"
                                    :column-min-width="30"
                                    :repaint-changes-only="true"
                                    @cell-prepared="onCellPrepared"
                                    @row-updated="onRowUpdated"
                                    style="max-height: 500px; vertical-align: top;">
                            <DxScrolling mode="standard" useNative="false" />
                            <DxPaging :enabled="false" />
                            <DxKeyboardNavigation :edit-on-key-press="true"
                                                  :enter-key-action="'startEdit'"
                                                  :enter-key-direction="'row'" />
                            <DxEditing :allow-updating="true"
                                       :select-text-on-edit-start="true"
                                       start-edit-action="click"
                                       mode="cell" />
                            <DxColumn data-field="RowNo" caption="순번" alignment="center" width="3%" :allow-editing="false" />
                            <DxColumn data-field="ApprovedYMD" caption="승인일시" alignment="center" data-type="date" format="yyyy-MM-dd HH:mm:ss" :allow-editing="false" />
                            <DxColumn data-field="UseStoreNm" caption="이용가맹점명" alignment="left" :allow-editing="false" />
                            <DxColumn data-field="ApprovedAmt" caption="승인금액" alignment="right" format="fixedPoint" :allow-editing="false" />
                            <DxColumn data-field="ApprovedUSDAmt" caption="외화승인금액" alignment="right" format="fixedPoint" :allow-editing="false" />
                            <DxColumn data-field="ProjectCd" caption="프로젝트코드" alignment="center" :set-cell-value="setProjectInfo" />
                            <DxColumn type="buttons" width="2%" :allow-editing="false">
                                <DxButton css-class="glyphicon glyphicon-search text-deco" :on-click="searchProject" />
                            </DxColumn>
                            <DxColumn data-field="ProjectNm" caption="프로젝트명" alignment="left" :allow-editing="false" />
                            <DxColumn data-field="CompanyNm" caption="거래처명" alignment="left" :allow-editing="false" />
                            <DxColumn data-field="AcntSubjtCd" caption="계정과목코드" alignment="center" :set-cell-value="setAcntSubjtNm" />
                            <DxColumn data-field="AcntSubjtNm" caption="계정과목명" alignment="center" :set-cell-value="setAcntSubjtCd">
                                <DxLookup :dataSource="AcntSubjtCdList" display-expr="Name" value-expr="Code" />
                            </DxColumn>
                            <DxColumn data-field="MemoCont" caption="메모" alignment="left" />
                            <DxColumn data-field="CardNo" caption="카드번호" alignment="center" :allow-editing="false" />
                            <DxColumn data-field="UseDivNm" caption="이용구분명" alignment="center" :allow-editing="false" />
                        </DxDataGrid>
                    </div>
                </div>
            </div>
        </div>
        <CP1060P ref="CP1060P"></CP1060P>
    </div>
</template>

<style>
    .dx-datagrid .dx-link.glyphicon.glyphicon-search.text-deco {
        text-decoration: none;
    }
</style>

<script>
    import CP1060P from '@/components/libs/popup/CP1060P';
    import ViewTitle from '@/components/ViewTitle';
    import { DxDataGrid, DxColumn, DxButton, DxEditing, DxPaging, DxScrolling, DxSorting, DxSelection, DxLookup, DxKeyboardNavigation, } from 'devextreme-vue/data-grid';
    import DxDateBox, { DxCalendarOptions } from 'devextreme-vue/date-box';

    export default {
        name: 'AC1130R',
        components: {
            CP1060P, ViewTitle, DxDateBox, DxCalendarOptions, DxDataGrid, DxColumn, DxButton, DxEditing, DxPaging, DxScrolling, DxSorting, DxSelection, DxLookup, DxKeyboardNavigation,
        },
        data() {
            return {
                // 계정과목코드 목록
                AcntSubjtCdList: new Array(),
                // 법인카드 목록
                CorpCardNoList: new Array(),
                // 법인카드청구 조회조건 객체
                CorpCardBillSelectTerm: {
                    Arg: 'C',
                    StartDate: new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).getFullYear(), new Date(new Date().setMonth(new Date().getMonth() - 1)).getMonth(), 1),
                    EndDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
                    CorpCardNo: '',
                    ProcessingYN: 'N',
                    UserId: this.$store.getters.getEmpId,
                },
                BillData: 'dataGrid',
                // 법인카드청구 목록
                CorpCardBillList: new Array(),
                // 목록 전체 개수
                CorpCardBillCnt: 0,
                // 법인카드청구 저장 객체
                CorpCardBillInsertTerm: new Array(),
            }
        },
        beforeMount() {

            // 법인카드청구관리에서 사용할 계정과목코드 목록
            this.AcntSubjtCdList = $.scriptBase.getCommonCode('CODE000', '0521000', '', '').filter((item) => item.Etc1 == 'CCB');

            let data = $.scriptBase.getCorpCardList(this.$parent.EmpId);
            // 로그인된 아이디에 따라 조회가능한 카드번호 목록
            this.CorpCardNoList = JSON.parse(data.d).Table;
            // 조회 권한 (M: 시스템관리자 or 회계관리자, C: 임원)
            this.CorpCardBillSelectTerm.Arg = JSON.parse(data.d).Table1[0].Auth;
        },
        mounted() {

            this.showBillData();
        },
        methods: {
            // DataGrid Header 가운데 정렬, Column Data 중앙 정렬
            onCellPrepared(e) {

                if (e.rowType == 'header') {
                    e.cellElement.style['textAlign'] = 'center';
                }
                if (e.rowType == 'data') {
                    e.cellElement.style['verticalAlign'] = 'middle';

                    // 조작하지 않는 컬럼의 색상, 마우스 포인터 세팅
                    if (e.column.dataField == 'RowNo' || e.column.dataField == 'CardNo' || e.column.dataField == 'ApprovedYMD'
                        || e.column.dataField == 'UseDivNm' || e.column.dataField == 'UseStoreNm' || e.column.dataField == 'ApprovedAmt'
                        || e.column.dataField == 'ApprovedUSDAmt' || e.column.dataField == 'CompanyNm' || e.column.dataField == 'ProjectNm') {
                        e.cellElement.style['background-color'] = '#EAEAEA';
                        e.cellElement.style['pointer-events'] = 'none';
                    }
                }
            },
            // 거래일자 날짜 타입 변경 이벤트
            dateTypeChangeEvent(event) {

                let dateType = Number(event.target.value);

                switch (dateType) {
                    case 1: {
                        // 작년도
                        this.CorpCardBillSelectTerm.StartDate = new Date(new Date(this.moment(new Date()).add(-1, 'years')).getFullYear(), 0, 1);
                        this.CorpCardBillSelectTerm.EndDate = new Date(new Date().getFullYear(), 0, 0);
                        break;
                    }
                    case 2: {
                        // 이번 연도
                        this.CorpCardBillSelectTerm.StartDate = new Date(new Date().getFullYear(), 0, 1);
                        this.CorpCardBillSelectTerm.EndDate = new Date(new Date(this.moment(new Date()).add(1, 'years')).getFullYear(), 0, 0);
                        break;
                    }
                    case 3: {
                        // 전월
                        this.CorpCardBillSelectTerm.StartDate = new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).getFullYear(), new Date(new Date().setMonth(new Date().getMonth() - 1)).getMonth(), 1);
                        this.CorpCardBillSelectTerm.EndDate = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
                        break;
                    }
                    case 4: {
                        // 당월
                        this.CorpCardBillSelectTerm.StartDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                        this.CorpCardBillSelectTerm.EndDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
                        break;
                    }
                    default:
                }
            },
            // 법인카드청구 목록 조회
            showBillData() {

                var parent = this;

                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/SelectCorpCardBill'.toRealServiceUrl();
                var obj = new Object();

                this.CorpCardBillSelectTerm.StartDate = this.moment(this.CorpCardBillSelectTerm.StartDate).format('YYYYMMDD');
                this.CorpCardBillSelectTerm.EndDate = this.moment(this.CorpCardBillSelectTerm.EndDate).format('YYYYMMDD');

                obj.jsonString = JSON.stringify(this.CorpCardBillSelectTerm);

                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d);
                    var table = jsonData.Table;

                    // 법인카드청구 목록
                    parent.CorpCardBillList = (table.length > 0) ? table : new Array();

                    // 법인카드청구 전체 건수
                    parent.CorpCardBillCnt = (table.length > 0) ? table.length : 0;
                }
                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 프로젝트 검색 모달 오픈
            searchProject(e) {

                let parent = this;
                let rowIndex = (e.row) ? e.row.rowIndex : e;

                var successCallback = function (selectedObj) {

                    parent.CorpCardBillList[rowIndex].CorpCd = selectedObj.CorpCd;
                    parent.CorpCardBillList[rowIndex].CompanyCd = selectedObj.CompanyCd;
                    parent.CorpCardBillList[rowIndex].CompanyNm = selectedObj.CompanyNm;
                    parent.CorpCardBillList[rowIndex].ProjectCd = selectedObj.ProjectCd;
                    parent.CorpCardBillList[rowIndex].ProjectNm = selectedObj.ProjectNm;
                }

                this.$refs.CP1060P.open(successCallback);
            },
            // 프로젝트 코드 입력 시 나머지 입력
            //          (사용 X, 프로젝트코드, 로우 데이터)
            setProjectInfo(newData, value, e) {

                // 입력된 프로젝트 코드를 지웠을 때
                if (value == '' || value == null) {

                    let index = this.CorpCardBillList.findIndex((item) => item.RowNo == e.RowNo);
                    this.CorpCardBillList[index].ProjectCd = value;
                    this.CorpCardBillList[index].ProjectNm = null;
                    this.CorpCardBillList[index].CorpCd = null;
                    this.CorpCardBillList[index].CompanyCd = null;
                    this.CorpCardBillList[index].CompanyNm = null;
                    return;
                }

                let parent = this;

                var url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/GetProjectList'.toRealServiceUrl();
                var obj = new Object();
                obj.prm = new Array();
                obj.prm.push("CD", this.$parent.EmpId, "", "", value, "", "", "", "", "", "");

                var ajaxSuccessHandler = function (data) {

                    let table = JSON.parse(data.d).Table;

                    if (table.length > 0) {

                        let index = parent.CorpCardBillList.findIndex((item) => item.RowNo == e.RowNo);
                        parent.CorpCardBillList[index].ProjectCd = value;
                        parent.CorpCardBillList[index].ProjectNm = table[0].ProjectNm;
                        parent.CorpCardBillList[index].CorpCd = table[0].CorpCd;
                        parent.CorpCardBillList[index].CompanyCd = table[0].CompanyCd;
                        parent.CorpCardBillList[index].CompanyNm = table[0].CompanyNm;
                    } else {

                        let index = parent.CorpCardBillList.findIndex((item) => item.RowNo == e.RowNo);
                        parent.searchProject(index);
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler);
            },
            // 계정과목명 룩업 선택 시 코드 입력
            setAcntSubjtCd(newData, value) {

                newData.AcntSubjtCd = value;
                newData.AcntSubjtNm = value;
            },
            // 계정과목코드 입력 시 명 선택
            setAcntSubjtNm(newData, value) {

                newData.AcntSubjtCd = value;
                newData.AcntSubjtNm = value;
            },
            // 데이터 입력 시 저장 객체 세팅
            onRowUpdated(e) {

                let data = e.data;

                let inserted = this.CorpCardBillInsertTerm.findIndex((item) => item.RowNo == data.RowNo);

                // 저장 객체에 데이터가 있을 때
                if (inserted > -1) {

                    this.CorpCardBillInsertTerm[inserted] = data;

                    // 수정이 일어났지만 저장할 데이터가 없는 경우 객체에서 삭제
                    for (let i = 0; i < this.CorpCardBillInsertTerm.length; i++) {

                        let item = this.CorpCardBillInsertTerm[i];

                        if ((item.AcntSubjtCd == null || item.AcntSubjtCd == '') && (item.ProjectCd == null || item.ProjectCd == '') && (item.MemoCont == null || item.MemoCont == '')) {

                            this.CorpCardBillInsertTerm.splice(i, 1);
                            i--;
                        }
                    }
                } else {

                    // 입력한 로우 데이터를 저장 객체에 처음으로 삽입
                    this.CorpCardBillInsertTerm.push(data);
                }
            },
            // 법인카드 청구 내역 저장
            updateCorpCardBill() {

                let parent = this;
                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/ManipulateCorpCardBillLog'.toRealServiceUrl();
                var obj = new Object();

                if (this.CorpCardBillInsertTerm.length == 0) {

                    alert('저장할 변경 사항이 없습니다.');
                    return;
                }

                // 저장 객체 세팅
                this.CorpCardBillInsertTerm.forEach((item) => {

                    item.Arg = 'IU';
                    item.UserId = this.$store.getters.getEmpId;
                    // 프로젝트, 계정과목 입력 완료 여부
                    item.ProcessingYN = ((item.AcntSubjtCd == '' || item.AcntSubjtCd == null) || (item.ProjectCd == '' || item.ProjectCd == null)) ? 'N' : 'Y';
                })

                // 입력 완료되지 않은 저장사항 유무
                let unfinished = this.CorpCardBillInsertTerm.some((item) => item.ProcessingYN == 'N');

                // alert 메시지 세팅
                let message = '';

                if (unfinished == true) {

                    message = '입력되지 않은 프로젝트나 계정과목이 있습니다. 작성하신 곳까지 저장하시겠습니까?';
                } else {

                    message = '입력하신 정보를 저장하시겠습니까?';
                }

                obj.jsonString = JSON.stringify(this.CorpCardBillInsertTerm);

                if (confirm(message)) {

                    var successCallback = function (data) {

                        if (data.d > 0) {

                            alert('저장되었습니다.');
                            parent.CorpCardBillInsertTerm = new Array();
                            parent.showBillData();
                        } else {

                            alert('저장에 실패했습니다. 잠시 후 다시 시도해 주십시오.');
                            return;
                        }
                    }

                    $.scriptBase.executeAjaxAsync(url, obj, successCallback);
                }
            },
        }
    }
</script>