<template>
    <!--상세 모달 팝업-->
    <div class="modal fade" id="CM1011E_Modal" ref="CM1011E_Modal" tabindex="-1" role="dialog" aria-labelledby="CM1011E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" @click.prevent="close">
                        <span aria-hidden="true">&times;</span>
                        <span class="sr-only">Close</span>
                    </button>
                    <h3 class="modal-title">은행계좌잔액관리 상세</h3>
                </div>
                <div class="modal-body">
                    <section>
                        <div class="sub-title">
                            <p class="sub-title-txt">은행계좌잔액</p>
                            <div class="btn-wrap">
                                <a href="#" id="btnAcntBalanceSearch" class="btn btn-primary" @click.prevent="showAcntBalance">
                                    <span>
                                        <i class="glyphicon glyphicon-search"></i> 조회
                                    </span>
                                </a>
                                <a href="#" id="btnAutoMatch" class="btn btn-warning" @click.prevent="manageAcntBalance">
                                    <span>잔액반영</span>
                                </a>
                            </div>
                        </div>
                        <section class="sch-wrap">
                            <form role="form">
                                <fieldset>
                                    <legend>검색폼</legend>
                                    <section class="sch-cont">
                                        <section class="cols-wrap">
                                            <div class="cols col-2">
                                                <label for="selDateType" class="control-label">
                                                    <span>기준일자</span>
                                                </label>
                                                <div class="col-xs-3">
                                                    <select class="form-control" id="selDateType" @change="dateTypeChangeEvent">
                                                        <option value="1">오늘</option>
                                                        <option value="2">일주일</option>
                                                        <option value="3" selected="selected">한달</option>
                                                        <option value="4">일년</option>
                                                    </select>
                                                </div>
                                                <div class="col-xs-9">
                                                    <label for="txtStartDate" class="sr-only"><span>기준일자</span></label>
                                                    <div class="col-2 input-group date">
                                                        <DxDateBox display-format="yyyy-MM-dd"
                                                                   pickerType="calendar"
                                                                   v-model="AcntBalanceSelectTerm.StartDate"
                                                                   @value-changed="showAcntBalance"
                                                                   style="border-radius:0;" />
                                                    </div>
                                                    <div class="tail"><span>~</span></div>
                                                    <label for="txtEndDate" class="sr-only"><span>기준일자</span></label>
                                                    <div class="col-2 input-group date">
                                                        <DxDateBox display-format="yyyy-MM-dd"
                                                                   pickerType="calendar"
                                                                   v-model="AcntBalanceSelectTerm.EndDate"
                                                                   @value-changed="showAcntBalance"
                                                                   style="border-radius:0;" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="cols col-3">
                                                <label for="txtAcntNm" class="control-label">
                                                    <span>은행계좌</span>
                                                </label>
                                                <div class="cols-btns-cont">
                                                    <input type="text" id="txtAcntInfo" class="form-control txt" readonly="readonly" v-model="AcntBalanceSelectTerm.AcntInfo" @change="showAcntBalance" />
                                                    <input type="hidden" id="hidBankCd" class="form-control txt" v-model="AcntBalanceSelectTerm.BankCd" />
                                                    <input type="hidden" id="hidAcntNo" class="form-control txt" v-model="AcntBalanceSelectTerm.AcntNo" />
                                                </div>
                                                <div class="cols-btns">
                                                    <a href="#" id="btnItemPop3" class="btn btn-default" @click.prevent="searchBankAccount">
                                                        <span>
                                                            <i class="glyphicon glyphicon-search"></i> 검색
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </section>
                                    </section>
                                </fieldset>
                            </form>
                        </section>
                        <section class="tbl-contents">
                            <div class="alert" style="margin-bottom: 0;">
                                <p><i class="glyphicon glyphicon-folder-close"></i><span> 전체 : <span>{{AcntBalanceTotalCnt}}</span>건</span></p>
                            </div>
                            <div class="table-responsive">
                                <DxDataGrid id="grid-container"
                                            :data-source="AcntBalanceList"
                                            :show-borders="false"
                                            :hoverStateEnabled="true"
                                            :row-alternation-enabled="true"
                                            :column-auto-width="true"
                                            @cell-prepared="onCellPrepared"
                                            style="max-height: 400px; vertical-align: top;">
                                    <DxScrolling mode="standard" useNative="false" />
                                    <DxPaging :enabled="false" />
                                    <DxColumn data-field="BaseYMD" caption="기준일자" alignment="center" data-type="date" format="yyyy-MM-dd" />
                                    <DxColumn data-field="BankName" caption="은행명" alignment="center" />
                                    <DxColumn data-field="AcntNo" caption="계좌번호" alignment="left" />
                                    <DxColumn data-field="AcntName" caption="계좌명" alignment="left" />
                                    <DxColumn data-field="BeforeBalance" caption="전일잔액" alignment="right" format="fixedPoint" />
                                    <DxColumn data-field="Balance" caption="잔액" alignment="right" format="fixedPoint" />
                                    <DxColumn data-field="OutAmt" caption="출금액" alignment="right" format="fixedPoint" />
                                    <DxColumn data-field="InAmt" caption="입금액" alignment="right" format="fixedPoint" />
                                </DxDataGrid>
                            </div>
                        </section>
                    </section>
                </div>
                <div class="modal-footer btn-align">
                    <div class="btn-group">
                        <button type="button" class="btn btn-danger" @click.prevent="close">
                            <span class="glyphicon glyphicon-remove"></span>취소
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="CM1012E_Modal" tabindex="-1" role="dialog" aria-labelledby="CM1012E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static" style="top: 15%;">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" @click.prevent="closeSndModal">
                                <span aria-hidden="true">&times;</span>
                                <span class="sr-only">Close</span>
                            </button>
                            <h3 class="modal-title">은행계좌 잔액반영</h3>
                        </div>
                        <div class="modal-body">
                            <div class="sub-title">
                                <p class="sub-title-txt">잔액반영 조건</p>
                            </div>
                            <section class="cols-wrap">
                                <div class="cols col-2">
                                    <label for="selBaseYMD" class="control-label">
                                        <span>반영기준일자</span>
                                    </label>
                                    <div class="input-group date" ref="selBaseYMD">
                                        <DxDateBox display-format="yyyy-MM-dd"
                                                   pickerType="calendar"
                                                   v-model="AcntBalanceUpdateTerm.BaseYMD"
                                                   :max="new Date()"
                                                   style="border-radius:0;" />
                                    </div>
                                </div>
                                <div class="cols col-2">
                                    <label for="txtAcntNm" class="control-label">
                                        <span>은행계좌</span>
                                    </label>
                                    <div class="cols-btns-cont">
                                        <input type="text" id="txtAcntInfo" class="form-control txt" readonly="readonly" v-model="AcntBalanceUpdateTerm.AcntInfo" />
                                        <input type="hidden" id="hidBankCd" class="form-control txt" v-model="AcntBalanceUpdateTerm.BankCd" />
                                        <input type="hidden" id="hidAcntNo" class="form-control txt" v-model="AcntBalanceUpdateTerm.AcntNo" />
                                    </div>
                                </div>
                            </section>
                            <div class="sub-title" style="margin-top: 40px;">
                                <p class="sub-title-txt">최근 은행데이터</p>
                            </div>
                            <section class="cols-wrap">
                                <div class="cols col-2">
                                    <label for="txtTradeYMD" class="control-label">
                                        <span>거래일시</span>
                                    </label>
                                    <h4 class="form-control" id="txtTradeYMD" style="border: 0; box-shadow: unset;">{{ InOutAmtLogInfo.TradeYMD }}</h4>
                                </div>
                                <div class="cols col-2">
                                    <label for="txtAfterTradeAmt" class="control-label">
                                        <span>거래후잔액</span>
                                    </label>
                                    <h4 class="form-control" id="txtAfterTradeAmt" style="border: 0; box-shadow: unset;">{{ InOutAmtLogInfo.AfterTradeAmt }}</h4>
                                </div>
                            </section>
                        </div>
                        <div class="modal-footer btn-align">
                            <div class="btn-group">
                                <button type="button" class="btn btn-primary" @click.prevent="updateAcntBalance">
                                    <span class="glyphicon glyphicon-save"></span>잔액반영
                                </button>
                            </div>
                            <div class="btn-group">
                                <button type="button" class="btn btn-danger" @click.prevent="closeSndModal">
                                    <span class="glyphicon glyphicon-remove"></span>취소
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <CP1070P ref="CP1070P"></CP1070P>
    </div>
</template>

<script>
    import { DxDataGrid, DxColumn, DxPaging, DxScrolling, } from 'devextreme-vue/data-grid';
    import DxDateBox, { DxCalendarOptions } from 'devextreme-vue/date-box';
    import CP1070P from '@/components/libs/popup/CP1070P';

    export default {
        name: 'CM1011E',
        components: { DxDateBox, DxCalendarOptions, DxDataGrid, DxColumn, DxPaging, DxScrolling, CP1070P },
        data() {
            return {
                CallbackFunction: null,
                // 은행계좌잔액 검색 조건
                AcntBalanceSelectTerm: {
                    Arg: 'L',
                    StartDate: this.moment(new Date()).add(-1, 'months').format('YYYY-MM-DD'),
                    EndDate: this.moment(new Date()).format('YYYY-MM-DD'),
                    AcntInfo: 'IBK기업 038-123408-04-041',
                    BankCd: '0101110',
                    AcntNo: '038-123408-04-041'
                },
                // 은행계좌잔액 목록
                AcntBalanceList: new Array(),
                // 은행계좌잔액 총 개수
                AcntBalanceTotalCnt: 0,
                // 은행계좌잔액 반영 조건
                AcntBalanceUpdateTerm: {
                    Arg: 'I',
                    AcntInfo: '',
                    BankCd: '',
                    AcntNo: '',
                    BaseYMD: this.moment(new Date()).format('YYYY-MM-DD'),
                    UserID: this.$store.getters.getEmpId,
                    RecentBaseYMD: ''
                },
                InOutAmtLogInfo: {
                    TradeYMD: '',
                    AfterTradeAmt: 0
                }
            }
        },
        methods: {
            open(callbackFunction) {

                this.CallbackFunction = callbackFunction;
                $("#CM1011E_Modal").modal("show");
                this.showAcntBalance();
            },
            close() {

                $("#CM1011E_Modal").modal("hide");
                this.AcntBalanceSelectTerm = {
                    Arg: 'L',
                    StartDate: this.moment(new Date()).add(-1, 'months').format('YYYY-MM-DD'),
                    EndDate: this.moment(new Date()).format('YYYY-MM-DD'),
                    AcntInfo: 'IBK기업 038-123408-04-041',
                    BankCd: '0101110',
                    AcntNo: '038-123408-04-041'
                };
                this.AcntBalanceList = new Array();
                this.AcntBalanceTotalCnt = 0;
                this.CallbackFunction();
            },
            // 기준일자 날짜 타입 변경 이벤트
            dateTypeChangeEvent(event) {

                let dateType = Number(event.target.value);

                switch (dateType) {
                    case 1: {

                        this.AcntBalanceSelectTerm.StartDate = this.moment(new Date()).format('YYYY-MM-DD');
                        this.AcntBalanceSelectTerm.EndDate = this.moment(new Date()).format('YYYY-MM-DD');
                        break;
                    }
                    case 2: {

                        this.AcntBalanceSelectTerm.StartDate = this.moment(new Date()).add(-7, 'days').format('YYYY-MM-DD');
                        this.AcntBalanceSelectTerm.EndDate = this.moment(new Date()).format('YYYY-MM-DD');
                        break;
                    }
                    case 3: {

                        this.AcntBalanceSelectTerm.StartDate = this.moment(new Date()).add(-1, 'months').format('YYYY-MM-DD');
                        this.AcntBalanceSelectTerm.EndDate = this.moment(new Date()).format('YYYY-MM-DD');
                        break;
                    }
                    case 4: {

                        this.AcntBalanceSelectTerm.StartDate = this.moment(new Date()).add(-1, 'years').format('YYYY-MM-DD');
                        this.AcntBalanceSelectTerm.EndDate = this.moment(new Date()).format('YYYY-MM-DD');
                        break;
                    }
                    default:
                }
            },
            // 은행계좌 검색
            searchBankAccount() {

                /*
                var parent = this;
                var successCallback = function (result) {

                    parent.AcntBalanceSelectTerm.AcntInfo = result.BankNm + " " + result.AcntNo;
                    parent.AcntBalanceSelectTerm.BankCd = result.BankCd;
                    parent.AcntBalanceSelectTerm.AcntNo = result.AcntNo;

                    parent.AcntBalanceUpdateTerm.BankCd = result.BankCd;
                    parent.AcntBalanceUpdateTerm.AcntNo = result.AcntNo;
                }

                this.$refs.CP1070P.open(successCallback);*/
                alert('현재 주거래 계좌의 잔액만 관리하고 있습니다.');
                return;
            },
            // DataGrid Header 가운데 정렬, Column Data 중앙 정렬
            onCellPrepared(e) {

                if (e.rowType == 'header') {
                    e.cellElement.style['textAlign'] = 'center';
                }
                if (e.rowType == 'data') {
                    e.cellElement.style['verticalAlign'] = 'middle';
                }
            },
            // 은행계좌잔액 목록 조회
            showAcntBalance() {

                var parent = this;
                var url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/SelectAcntBalance'.toRealServiceUrl();
                var obj = new Object();

                this.AcntBalanceSelectTerm.StartDate = this.moment(this.AcntBalanceSelectTerm.StartDate).format('YYYYMMDD');
                this.AcntBalanceSelectTerm.EndDate = this.moment(this.AcntBalanceSelectTerm.EndDate).format('YYYYMMDD');

                obj.jsonString = JSON.stringify(this.AcntBalanceSelectTerm);

                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d);
                    var table = jsonData.Table;
                    var table1 = jsonData.Table1;
                    var table2 = jsonData.Table2;

                    parent.AcntBalanceList = (table.length > 0) ? table : new Array();
                    parent.AcntBalanceTotalCnt = (table1.length > 0) ? table1[0].TotCnt : 0;
                    parent.AcntBalanceUpdateTerm.RecentBaseYMD = (table2.length > 0) ? table2[0].BaseYMD : this.moment(new Date()).format('YYYY-MM-DD');
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 잔액반영 모달 오픈
            manageAcntBalance() {

                var parent = this;

                // 잔액반영할 계좌정보
                if (this.AcntBalanceSelectTerm.AcntInfo.length > 0) {

                    this.AcntBalanceUpdateTerm.AcntInfo = this.AcntBalanceSelectTerm.AcntInfo;
                    this.AcntBalanceUpdateTerm.BankCd = this.AcntBalanceSelectTerm.BankCd;
                    this.AcntBalanceUpdateTerm.AcntNo = this.AcntBalanceSelectTerm.AcntNo;
                }

                let getInOutAmtLogTerm = {
                    Arg: 'M',
                    TargetDate: '',
                    RoundNo: '',
                    InOutDvsCd: '',
                    MatchStateCd: ''
                }

                // 가장 최근 업로드한 은행데이터의 일시와 잔액 표시
                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/SelectInOutAmtLog'.toRealServiceUrl();
                var obj = new Object();
                obj.jsonString = JSON.stringify(getInOutAmtLogTerm);

                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d);
                    var table = jsonData.Table;

                    parent.InOutAmtLogInfo = (table.length > 0) ? table[0] : new Object();

                    if (table.length > 0) {

                        parent.InOutAmtLogInfo.TradeYMD = parent.moment(parent.InOutAmtLogInfo.TradeYMD).format('YYYY-MM-DD  hh:mm:ss');
                        parent.InOutAmtLogInfo.AfterTradeAmt = parent.InOutAmtLogInfo.AfterTradeAmt.toPriceNumber();
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);

                $('#CM1012E_Modal').modal('show');
            },
            // 잔액반영 모달 닫기
            closeSndModal() {

                $('#CM1012E_Modal').modal('hide');
            },
            // 은행계좌 잔액반영
            updateAcntBalance() {

                var parent = this;

                var url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/ManipulateAcntBalance'.toRealServiceUrl();
                var obj = new Object();
                obj.jsonString = JSON.stringify(this.AcntBalanceUpdateTerm);

                let balanceDateDiff = this.moment(new Date(this.AcntBalanceUpdateTerm.BaseYMD)).diff(this.moment(this.AcntBalanceUpdateTerm.RecentBaseYMD), 'days');
                if (balanceDateDiff == 0) {

                    alert('선택한 반영기준일자의 잔액이 이미 존재합니다. 다시 확인해 주십시오.');
                    return;
                }

                let logDateDiff = this.moment(new Date(this.AcntBalanceUpdateTerm.BaseYMD)).diff(this.moment(new Date(this.InOutAmtLogInfo.TradeYMD)), 'days');
                if (logDateDiff > 1) {

                    if (confirm('최근 등록된 은행데이터의 거래일시와 반영기준일자의 차이가 ' + logDateDiff + '일입니다. 은행데이터가 등록되지 않은 날짜는 금액변동 없이 잔액이 등록됩니다. 이대로 잔액을 반영하시겠습니까?')) {

                        var successCallback = function (data) {

                            if (data.d > 0) {

                                alert('잔액이 반영되었습니다.');
                                parent.closeSndModal();
                            } else {

                                alert('잔액 반영에 실패하였습니다. 잠시 후 다시 시도해 주십시오.');
                            }
                        }

                        $.scriptBase.executeAjaxAsync(url, obj, successCallback);
                    }
                }
            },
        }
    }
</script>