<template>
    <div class="tags-view-container" style="overflow-x:auto;white-space:nowrap">
        <div class="tags-view-wrapper">
            <a href="#" v-for="menu in this.$parent.KeepAliveMenuList"
                         :to="menu.Path"
                         :class="menu.IsActive ? 'tags-view-item active':'tags-view-item'"
                         @click.prevent="menuTabClickEvent(menu)">
                {{menu.MenuName}}
                <span class="el-icon-close" @click.prevent.stop="closeMenuTab(menu)" />
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TagsView",
        methods: {
            closeMenuTab: function ( menu) {

                var keepAliveMenuList = this.$parent.KeepAliveMenuList
                var removeIndex = keepAliveMenuList.indexOf(menu)
                var isActive = menu.IsActive
                
                if (removeIndex > -1) {

                    keepAliveMenuList.splice(removeIndex, 1)
                    
                    if (isActive) {

                        if (removeIndex === 0) {

                            if (keepAliveMenuList.length > 0) {

                                // Active될 탭 조정
                                keepAliveMenuList.forEach(function (item) {

                                    if (item.MenuName === keepAliveMenuList[0].MenuName) {

                                        item.IsActive = true
                                    }
                                    else {

                                        item.IsActive = false
                                    }
                                })

                                this.$router.push(keepAliveMenuList[0].Path)
                            }
                            else {
                                
                                this.$router.push('/')
                            }
                        }
                        else {
                            
                            if (keepAliveMenuList.length > 1) {

                                // Active될 탭 조정
                                keepAliveMenuList.forEach(function (item) {

                                    if (item.MenuName === keepAliveMenuList[removeIndex - 1].MenuName) {

                                        item.IsActive = true
                                    }
                                    else {

                                        item.IsActive = false
                                    }
                                })

                                this.$router.push(keepAliveMenuList[removeIndex - 1].Path)
                            }
                            else {

                                // Active될 탭 조정
                                keepAliveMenuList.forEach(function (item) {

                                    if (item.MenuName === keepAliveMenuList[0].MenuName) {

                                        item.IsActive = true
                                    }
                                    else {

                                        item.IsActive = false
                                    }
                                })

                                this.$router.push(keepAliveMenuList[0].Path)
                            }
                        }
                    }
                }
            },
            menuTabClickEvent: function (menu) {

                this.$parent.KeepAliveMenuList.forEach(function (item) {

                    if (item.MenuName === menu.MenuName) {

                        item.IsActive = true
                    }
                    else {

                        item.IsActive = false
                    }
                })

                // 현재 라우트 경로랑 동일하지 않으면 push
                if (this.$route.path != menu.Path.substring(0, menu.Path.indexOf('?'))) {

                    this.$router.push(menu.Path)
                }
            }
        }
    }
</script>

<style scoped>
</style>