<template>
    <div class="modal fade" id="AC1031E_Modal" tabindex="-1" role="dialog" aria-labelledby="AC1031E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">
                        <span aria-hidden="true">&times;</span>
                        <span class="sr-only">Close</span>
                    </button>
                    <h3 v-if="PageActionType == 'I'" class="modal-title">경비승인요청 등록</h3>
                    <h3 v-if="PageActionType == 'V'" class="modal-title">경비승인요청 조회</h3>
                </div>
                <div class="modal-body">
                    <section class="add-wrap">
                        <form>
                            <fieldset>
                                <legend>..</legend>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtUseYMD" class="control-label asterisk">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>사용일자
                                            </span>
                                        </label>
                                        <div class="col-1 input-group date">
                                            <DxDateBox display-format="yyyy-MM-dd"
                                                       pickerType="calendar"
                                                       v-model="ExpenseRequestDetail.UseYMD"
                                                       style="border-radius:0;" />
                                        </div>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="selAcntCd" class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>지출구분
                                            </span>
                                        </label>
                                        <select class="form-control" id="selAcntCd" name="selAcntCd"
                                                v-model="ExpenseRequestDetail.AcntCd">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in AcntCodeList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="selUseTypeCd" class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>사용구분
                                            </span>
                                        </label>
                                        <select class="form-control" id="selUseTypeCd" name="selUseTypeCd"
                                                v-model="ExpenseRequestDetail.UseTypeCd">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in UseTypeCodeList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtPrice" class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>금액
                                            </span>
                                        </label>
                                        <input type="text" id="txtPrice" class="form-control text-right"
                                               v-bind:value="ExpenseRequestDetail.Price"
                                               @input="function(e){
                                                    ExpenseRequestDetail.Price = e.target.value.toPriceString()
                                                    e.target.value = e.target.value.toPriceString()
                                               }" />
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols">
                                        <label for="txtUseTxt" class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>사용사유
                                            </span>
                                        </label>
                                        <textarea id="txtUseTxt" class="form-control" form-groups="10"
                                                  v-model="ExpenseRequestDetail.UseTxt"></textarea>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtProjectNm" class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>프로젝트
                                            </span>
                                        </label>
                                        <div class="cols-btns-cont ">
                                            <input type="text" id="txtProjectNm" class="form-control txt" disabled="disabled"
                                                   v-model="ExpenseRequestDetail.ProjectNm" />
                                            <input type="hidden" id="hidProjectCd2" v-model="ExpenseRequestDetail.ProjectCd" />
                                            <input type="hidden" id="hidCardCorpCd2" v-model="ExpenseRequestDetail.CardCorpCd" />
                                            <input type="hidden" id="hidTraderCd2" v-model="ExpenseRequestDetail.CompanyCd" />
                                        </div>
                                        <div class="cols-btns">
                                            <button type="button" id="btnItemPop2" class="btn btn-default" @click="searchProject">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i>검색
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="cols col-2" id="divCardNo"
                                         v-show="ExpenseRequestDetail.UseTypeCd == '0501010'">
                                        <label for="txtCardNo" class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>카드번호
                                            </span>
                                        </label>
                                        <div class="cols-btns-cont ">
                                            <input type="text" id="txtCardNo" class="form-control txt" maxlength="20" disabled="disabled"
                                                   v-model="ExpenseRequestDetail.DisplayCardNo" />
                                            <input type="hidden" id="txtRealCardNo" class="form-control txt"
                                                   v-model="ExpenseRequestDetail.CardNo" />
                                        </div>
                                        <div class="cols-btns">
                                            <button type="button" id="btnItemPop3" class="btn btn-default" @click="searchCorporationCard">
                                                <span class="glyphicon glyphicon-search"></span> 검색
                                            </button>
                                        </div>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2" id="divApvEmpNm">
                                        <label for="txtApvEmpNm" class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>승인자
                                            </span>
                                        </label>
                                        <div class="cols-btns-cont">
                                            <input type="text" id="txtApvEmpNm" class="form-control txt" disabled="disabled"
                                                   v-model="ExpenseRequestDetail.AprvEmpNm" />
                                            <input type="hidden" id="hidApvEmpNo"
                                                   v-model="ExpenseRequestDetail.AprvEmpNo" />
                                        </div>
                                        <div class="cols-btns">
                                            <button type="button" id="btnItemPop4" class="btn btn-default" @click="searchApprover">
                                                <span class="glyphicon glyphicon-search"></span> 검색
                                            </button>
                                        </div>
                                    </div>
                                    <div class="cols col-2" id="divAprvDvsCd">
                                        <label for="selAprvDvsCd" class="control-label">
                                            <span>승인구분</span>
                                        </label>
                                        <select class="form-control" id="selAprvDvsCd" name="selAprvDvsCd"
                                                v-model="ExpenseRequestDetail.AprvDvsCd"
                                                v-bind:disabled="ExpenseRequestDetail.IsCompt == 'Y' || ExpenseRequestDetail.IsChker == 'N'">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in AprvDvsCodeList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols" id="divRejectTxt">
                                        <label for="txtRejectTxt" class="control-label">
                                            <span>반려사유</span>
                                        </label>
                                        <textarea id="txtRejectTxt" class="form-control" form-groups="10"
                                                  v-model="ExpenseRequestDetail.RejectTxt"
                                                  v-bind:disabled="ExpenseRequestDetail.AprvDvsCd != '0105030'"></textarea>
                                    </div>
                                </section>
                                <div class="form-group">
                                    <div class="btn-wrap">
                                        <div class="fr">
                                            <a href="#" id="btnChdInst" class="btn btn-default btn-sm" title="추가"
                                               v-show="ExpenseRequestDetail.IsOwner == 'Y'" @click.prevent="addAttachFile">
                                                <span class="glyphicon glyphicon-plus"></span> 추가
                                            </a>
                                            <a href="#" id="btnChdRmv" class="btn btn-default btn-sm" title="삭제"
                                               v-show="ExpenseRequestDetail.IsOwner == 'Y'" @click.prevent="removeAttachFile">
                                                <span class="glyphicon glyphicon-minus"></span> 삭제
                                            </a>
                                        </div>
                                    </div>
                                    <section class="tbl-contents">
                                        <div class="table-responsive">
                                            <table class="table tbl-list table-hover table-bordered" id="tb1" ref="tb1">
                                                <colgroup>
                                                    <col class="chk" />
                                                    <col />
                                                </colgroup>
                                                <thead>
                                                    <tr>
                                                        <th scope="col" class="chk text-center">선택</th>
                                                        <th scope="col" class="text-center">첨부파일</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-if="ExistAttachFile == false">
                                                        <td colspan="2" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                    </tr>
                                                    <tr v-else v-for="attachFile in AttachFileList" v-show="attachFile.IsVisible">
                                                        <td class="chk">
                                                            <label>
                                                                <input type="checkbox" name="cbItem2" v-model="attachFile.IsChecked" />
                                                            </label>
                                                        </td>
                                                        <td v-if="attachFile.IsNewFile" class="text-center">
                                                            <input type="file" name="ctlAttachFile"
                                                                   @change="function(e){
                                                                        attachFile.Files = e.target.files
                                                                   }" />
                                                        </td>
                                                        <td v-else class="text-left">
                                                            <a href="#" @click.prevent="downloadAttachFile(attachFile.GUID)">{{attachFile.FileName}}</a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                </div>
                            </fieldset>
                        </form>
                    </section>
                </div>
                <div class="modal-footer">
                    <div class="btn-align">
                        <div class="btn-group">
                            <button type="button" id="btnItemSave" class="btn btn-primary" @click="onClickSave">
                                <i class="glyphicon glyphicon-save"></i> 저장
                            </button>
                        </div>
                        <div v-show="PageActionType == 'V' && ExpenseRequestDetail.IsOwner == 'Y'" class="btn-group">
                            <button type="button" id="btnItemRmv" class="btn btn-warning" @click="fn_RemoveData">
                                <i class="glyphicon glyphicon-edit"></i> 삭제
                            </button>
                        </div>
                        <div class="btn-group">
                            <button type="button" class="btn btn-danger" @click="close">
                                <i class="glyphicon glyphicon-remove"></i> 취소
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <CP1020P ref="CP1020P"></CP1020P>
        <CP1050P ref="CP1050P"></CP1050P>
        <CP1060P ref="CP1060P"></CP1060P>
    </div>
</template>

<script>
    import DxDateBox, { DxCalendarOptions } from 'devextreme-vue/date-box';
    import CP1020P from '@/components/libs/popup/CP1020P';
    import CP1050P from '@/components/libs/popup/CP1050P';
    import CP1060P from '@/components/libs/popup/CP1060P';
    export default {
        name: 'AC1031E',
        components: {
            DxDateBox, DxCalendarOptions, CP1020P, CP1050P, CP1060P,
        },
        data() {
            return {
                CallbackFunction: null,
                // 페이지 액션 값 (I: 신규, V: 상세)
                PageActionType: 'I',
                // 지출구분 목록
                AcntCodeList: new Array(),
                // 사용구분 목록
                UseTypeCodeList: new Array(),
                // 승인구분 목록
                AprvDvsCodeList: new Array(),
                // 첨부파일 조회 조건
                AttachFileSelectTerm: {
                    Arg: 'F',
                    UserId: this.$store.getters.getEmpId,
                    CorpCd: this.$store.getters.getCompanyCode,
                    SeqNo: null,
                    EmpNo: null,
                    ReqYMD: null,
                    CompanyCd: '',
                    ProjectCode: '',
                    AcntCode: '',
                    AprvDvsCode: '',
                    StartDate: null,
                    EndDate: null,
                    UseTypeCode: '',
                    AuthGrade: null,
                    AprvEmpNo: null,
                    CardCorpCd: null,
                    CardNo: null
                },
                // 첨부파일 목록
                AttachFileList: new Array(),
                // 첨부파일 삭제 목록
                RemoveAttachFileList: new Array(),
                // 경비승인요청 상세 객체
                ExpenseRequestDetail: new Object(),
                ExistAttachFile: false,
            }
        },
        beforeMount() {
            // 공통코드 조회
            this.AcntCodeList = $.scriptBase.getCommonCode('CODE100', this.$store.getters.getCompanyCode, '', '');
            this.UseTypeCodeList = $.scriptBase.getCommonCode('CODE000', '0501000', '', '');
            this.AprvDvsCodeList = $.scriptBase.getCommonCode('CODE000', '0105000', '', '');
        },
        watch: {
            AttachFileList: {
                deep: true,
                handler(val) {
                    this.ExistAttachFile = this.AttachFileList.some((item) => item.IsVisible == true);
                }
            }
        },
        methods: {
            open(callbackFunction, selectedObj, cardInfo) {

                this.CallbackFunction = callbackFunction;

                // 경비승인요청 상세 초기값
                this.ExpenseRequestDetail = {
                    CorpCd: this.$store.getters.getCompanyCode,
                    EmpNo: this.$store.getters.getEmpId,
                    EmpNm: null,
                    ReqYMD: null,
                    DisplayReqYMD: null,
                    SeqNo: null,
                    UseYMD: null,
                    CompanyCd: null,
                    CompanyNm: null,
                    ProjectCd: null,
                    ProjectNm: null,
                    Price: null,
                    AcntCd: '',
                    UseTxt: null,
                    UseTypeCd: '0501010',
                    CardNo: null,
                    DisplayCardNo: null,
                    CardCorpCd: null,
                    CardCorpName: null,
                    AprvEmpNo: null,
                    AprvEmpNm: null,
                    AprvDvsCd: '0105010',
                    RejectTxt: null,
                    AprvDate: null,
                    RegtDate: null,
                    IsOwner: null,
                    IsChker: 'N',
                    IsCompt: 'N'
                }

                // 상세 조회
                if (selectedObj) {

                    this.PageActionType = 'V';
                    this.ExpenseRequestDetail = Object.assign({}, selectedObj);
                    this.ExpenseRequestDetail.UserId = this.$store.getters.getEmpId;
                    this.AttachFileSelectTerm.SeqNo = this.ExpenseRequestDetail.SeqNo;
                    this.AttachFileSelectTerm.EmpNo = this.ExpenseRequestDetail.EmpNo;
                    this.AttachFileSelectTerm.ReqYMD = this.ExpenseRequestDetail.ReqYMD;
                    this.AttachFileSelectTerm.CompanyCd = this.ExpenseRequestDetail.CompanyCd;
                    this.AttachFileSelectTerm.ProjectCode = this.ExpenseRequestDetail.ProjectCd;

                    this.getAttachFiles();
                // 신규 입력
                } else {

                    this.PageActionType = 'I';
                    this.ExpenseRequestDetail.Arg = 'I';
                    this.ExpenseRequestDetail.UserId = this.$store.getters.getEmpId;
                    this.ExpenseRequestDetail.IsOwner = 'Y';

                    // 법인카드 소지자일 경우, 법인카드 정보 세팅
                    if (cardInfo) {

                        this.ExpenseRequestDetail.DisplayCardNo = cardInfo.DisplayCardNo;
                        this.ExpenseRequestDetail.CardNo = cardInfo.CardNo;
                        this.ExpenseRequestDetail.CardCorpCd = cardInfo.CardCorpCd;
                        this.ExpenseRequestDetail.CardCorpName = cardInfo.CardCorpName;
                    }
                }

                $("#AC1031E_Modal").modal("show");
            },
            close() {

                this.ExpenseRequestDetail = new Object();
                this.AttachFileList = new Array();
                this.RemoveAttachFileList = new Array();

                $("#AC1031E_Modal").modal("hide");
            },
            // 프로젝트 검색
            searchProject() {

                var parent = this;
                var successCallback = function (result) {

                    parent.ExpenseRequestDetail.ProjectNm = result.ProjectNm;
                    parent.ExpenseRequestDetail.ProjectCd = result.ProjectCd;
                    parent.ExpenseRequestDetail.CompanyCd = result.CompanyCd;
                }

                this.$refs.CP1060P.showProjectPopup(successCallback);
            },
            // 법인카드 검색
            searchCorporationCard() {

                var parent = this;
                var successCallback = function (result) {

                    parent.ExpenseRequestDetail.DisplayCardNo = result.DisplayCardNo;
                    parent.ExpenseRequestDetail.CardNo = result.CardNo;
                    parent.ExpenseRequestDetail.CardCorpCd = result.CardCorpCd;
                }

                this.$refs.CP1050P.showCorporationPopUp(successCallback);
            },
            // 승인자 검색
            searchApprover() {

                var parent = this;
                var successCallback = function (result) {

                    parent.ExpenseRequestDetail.AprvEmpNm = result.EmpNm;
                    parent.ExpenseRequestDetail.AprvEmpNo = result.EmpNo;
                }

                this.$refs.CP1020P.showManPowerPopup(successCallback);
            },
            // 첨부파일 조회
            getAttachFiles() {

                let parent = this;
                let url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/GetExpenseRequest'.toRealServiceUrl();
                let obj = new Object();
                
                obj.jsonString = JSON.stringify(this.AttachFileSelectTerm);

                var successCallback = function(data) {

                    var jsonData = JSON.parse(data.d);
                    var table = jsonData.Table;

                    if (table.length > 0) {

                        parent.AttachFileList = table;
                        parent.AttachFileList.forEach((item) => {

                            item.IsNewFile = false;
                            item.IsVisible = true;
                            item.IsChecked = false;
                            item.Files = null;
                        })
                    } else {

                        parent.AttachFileList = new Array();
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 첨부파일 다운로드
            downloadAttachFile(guid) {

                let handlerUrl = 'Mini.Service.IBS.CM.Service/Handlers/FileDownloadHandler.ashx'.toRealServiceUrl();
                location.href = handlerUrl + '?key=' + guid;
            },
            // 첨부파일 추가
            addAttachFile() {

                // 신규 첨부파일 객체 추가
                this.AttachFileList.push({

                    IsNewFile: true,
                    IsVisible: true,
                    IsChecked: false,
                    Files: null,
                    CorpCd: null,
                    EmpNo: null,
                    ReqYMD: null,
                    SeqNo: null,
                    FileSeq: null,
                    FileName: null,
                    GUID: null,
                });
            },
            // 첨부파일 그리드에서 삭제
            removeAttachFile() {

                let parent = this;
                // 체크박스 선택 여부 확인
                let check = this.AttachFileList.some((item) => item.IsChecked == true);

                if (check == false) {

                    alert('삭제할 파일을 선택해 주십시오.');
                    return;
                }

                this.AttachFileList.forEach((item) => {

                    if (item.IsChecked == true) {

                        // 선택 항목 숨기기
                        item.IsChecked = false;
                        item.IsVisible = false;

                        // DB에서 삭제할 파일 목록 세팅
                        if (item.IsNewFile == false) {

                            item.Arg = 'FD';
                            item.UserId = parent.$store.getters.getEmpId;
                            item.File = null;
                            parent.RemoveAttachFileList.push(item);
                        }
                    }
                })

                this.AttachFileList = this.AttachFileList.filter((item) => item.IsVisible == true);
            },
            // 저장 버튼 클릭 메소드
            onClickSave() {

                let parent = this;

                // 그리드에서 삭제한 첨부파일 삭제
                if (this.RemoveAttachFileList.length > 0) {

                    let url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/RemoveExpenseRequestFile'.toRealServiceUrl();
                    let obj = new Object();

                    obj.jsonFileString = JSON.stringify(this.RemoveAttachFileList);

                    var successCallback = function (data) {

                        if (data.d > 0) {

                            parent.RemoveAttachFileList = new Array();
                            parent.AttachFileList.forEach((file) => file.IsChecked = false);
                        } else {

                            alert('파일 삭제에 실패했습니다. 잠시 후 다시 시도해 주십시오.');
                        }
                    }

                    $.scriptBase.executeAjaxAsync(url, obj, successCallback);
                }

                let uploadFileCount = 0;
                let formData = new FormData();

                formData.append('action', 'attachfiles');

                // 신규 파일 추가 여부
                let isExistNewFile = false;

                if (this.AttachFileList != null) {

                    isExistNewFile = this.AttachFileList.some((file) => file.Files != null);
                }

                // 추가된 신규 파일이 있을 때 가공
                if (isExistNewFile) {

                    this.AttachFileList.forEach((item) => {

                        if (item.IsNewFile == true && item.Files != null) {

                            for (let i = 0; i < item.Files.length; i++) {

                                formData.append('attachfiles["' + i + '"]', item.Files[i]);
                            }

                            uploadFileCount = uploadFileCount + 1;
                        }
                    });

                    if (uploadFileCount > 0) {

                        let opts = {

                            url: 'Mini.Service.IBS.CM.Service/Handlers/FileUploadHandler.ashx'.toRealServiceUrl(),
                            data: formData,
                            cache: false,
                            contentType: false,
                            processData: false,
                            type: 'POST',
                            success: function (result) {

                                let ResultUploadFile = JSON.parse(result);
                                parent.fn_SaveData(ResultUploadFile);
                            }
                        }

                        if (formData.fake) {

                            opts.xhr = function () {

                                var xhr = $.ajaxSettings.xhr();
                                xhr.send = xhr.sendAsBinary;
                                return xhr;
                            }
                            opts.contentType = 'multipart/form-data; boundary=' + formData.boundary;
                            opts.data = formData.toString();
                        }

                        $.ajax(opts);
                    }
                } else {

                    // 신규 파일 없을 때 경비승인요청 내용만 저장
                    this.fn_SaveData(null);
                }
            },
            // 실제 저장 메소드
            fn_SaveData(ResultUploadFile) {

                if (this.ExpenseRequestDetail.UseYMD === null || this.ExpenseRequestDetail.UseYMD === '') {

                    alert('사용일자를 입력해 주십시오.');
                    return;
                } else {

                    this.ExpenseRequestDetail.UseYMD = this.moment(this.ExpenseRequestDetail.UseYMD).format('YYYYMMDD');
                }

                if (this.ExpenseRequestDetail.UseYMD > this.moment(new Date()).format('YYYYMMDD')) {

                    alert('사용일자는 오늘 이전이어야 합니다.');
                    return;
                }

                if (this.ExpenseRequestDetail.UseTypeCd === null || this.ExpenseRequestDetail.UseTypeCd === '') {

                    alert('사용구분을 입력해 주십시오.');
                    document.getElementById('selUseTypeCd').focus();
                    return;
                }

                if (this.ExpenseRequestDetail.AcntCd === null || this.ExpenseRequestDetail.AcntCd === '') {

                    alert('지출구분을 입력해 주십시오.');
                    document.getElementById('selAcntCd').focus();
                    return;
                }

                if (this.ExpenseRequestDetail.ProjectNm === null || this.ExpenseRequestDetail.ProjectNm === '') {

                    alert('프로젝트를 입력해 주십시오.');
                    document.getElementById('txtProjectNm').focus();
                    return;
                }

                if (this.ExpenseRequestDetail.UseTxt === null || this.ExpenseRequestDetail.UseTxt === '') {

                    alert('사용내역을 입력해 주십시오.');
                    document.getElementById('txtUseTxt').focus();
                    return;
                }

                if (this.ExpenseRequestDetail.UseTypeCd === '0501010') {

                    if (this.ExpenseRequestDetail.DisplayCardNo === null
                        || this.ExpenseRequestDetail.DisplayCardNo === '') {

                        alert('법인카드를 선택해 주십시오.');
                        document.getElementById('txtCardNo').focus();
                        return;
                    }
                }

                if (this.ExpenseRequestDetail.Price === null
                    || this.ExpenseRequestDetail.Price === ''
                    || this.ExpenseRequestDetail.Price === '0') {

                    alert('금액을 입력해 주십시오.');
                    document.getElementById('txtPrice').focus();
                    return;
                }

                if (this.ExpenseRequestDetail.Price != null
                    && Number(this.ExpenseRequestDetail.Price.replace(/,/g, '')) > 2147483647) {

                    alert('금액은 2,147,483,647을 넘을 수 없습니다.');
                    document.getElementById('txtPrice').focus();
                    return;
                }

                if ((this.ExpenseRequestDetail.AprvEmpNo === null || this.ExpenseRequestDetail.AprvEmpNo === '')
                    && (this.ExpenseRequestDetail.UseTypeCd === '0501030' || this.ExpenseRequestDetail.UseTypeCd === '0501040')) {

                    alert('승인자를 입력해 주십시오.');
                    document.getElementById('txtAprvEmpNm').focus();
                    return;
                }

                this.ExpenseRequestDetail.ReqYMD = (this.ExpenseRequestDetail.ReqYMD == null) ? this.moment(new Date()).format('YYYYMMDD') : this.ExpenseRequestDetail.ReqYMD;

                let parent = this;
                let url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/SaveExpenseRequest'.toRealServiceUrl();
                let obj = new Object();
                let attachFileList = new Array();

                if (ResultUploadFile != null) {

                    ResultUploadFile.files.forEach((item) => {

                        let fileObject = {
                            'Arg': 'FI',
                            'UserId': parent.$store.getters.getEmpId,
                            'CorpCd': parent.$store.getters.getCompanyCode,
                            'EmpNo': parent.ExpenseRequestDetail.EmpNo,
                            'ReqYMD': parent.ExpenseRequestDetail.ReqYMD,
                            'SeqNo': parent.ExpenseRequestDetail.SeqNo,
                            'nFileName': item.nFileName,
                            'oFileName': item.oFileName,
                        }

                        attachFileList.push(fileObject);
                    })
                } else {

                    attachFileList = null;
                }

                obj.jsonString = JSON.stringify(this.ExpenseRequestDetail);
                obj.jsonFileString = JSON.stringify(attachFileList);

                // 서비스호출 성공 콜백 함수
                var successCallback = function (data) {

                    if (data.d > 0) {

                        if (parent.PageActionType === 'I') {

                            alert('등록되었습니다.');
                            parent.close();
                            parent.$parent.fn_ShowData();
                        } else {

                            alert('저장되었습니다.');
                            parent.close();
                            parent.$parent.fn_ShowData();
                        }
                    } else {

                        alert('저장에 실패했습니다. 잠시 후 다시 시도해 주십시오.');
                        return;
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 데이터 삭제
            fn_RemoveData() {

                if (confirm('경비승인요청을 삭제하시겠습니까?')) {

                    var parent = this;
                    var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/RemoveExpenseRequest'.toRealServiceUrl();
                    var obj = new Object();
                    obj.arg = 'D';
                    obj.userId = this.$store.getters.getEmpId;
                    obj.corpCd = this.ExpenseRequestDetail.CorpCd;
                    obj.empNo = this.ExpenseRequestDetail.EmpNo;
                    obj.rquYmd = this.ExpenseRequestDetail.ReqYMD;
                    obj.seqNo = this.ExpenseRequestDetail.SeqNo;

                    var successCallback = function (data) {

                        if (data.d > 0) {

                            alert('삭제되었습니다.');
                            parent.close();
                            parent.$parent.fn_ShowData();
                        } else {

                            alert('삭제에 실패했습니다. 잠시 후 다시 시도해 주십시오.');
                            return;
                        }
                    }

                    $.scriptBase.executeAjaxAsync(url, obj, successCallback);
                }
            },
        }
    }
</script>