<template>
    <!--상품 상세 정보 모달 창-->
    <div class="modal fade" id="CM1042E_Modal" ref="CM1042E_Modal" tabindex="-1" role="dialog" aria-labelledby="CM1042E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">
                        <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                    </button>
                    <h3 class="modal-title">상품관리 상세</h3>
                </div>
                <div class="modal-body">
                    <section class="add-wrap">
                        <form>
                            <fieldset>
                                <legend></legend>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label class="control-label">
                                            <span>상품코드</span>
                                        </label>
                                        <input id="CM1042E_ProductCd" type="text" class="form-control txt" disabled v-model="ProductDetail.ProductCd" />
                                    </div>
                                    <div class="cols col-2">
                                        <label class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>상품명
                                            </span>
                                        </label>
                                        <input type="text" id="CM1042E_ProductNm" class="form-control txt" v-model="ProductDetail.ProductNm" />
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols">
                                        <label class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>상품내역
                                            </span>
                                        </label>
                                        <textarea id="CM1042E_ProductDesc" class="form-control" form-groups="12" style="height:120px;resize:none" v-model="ProductDetail.ProductDesc"></textarea>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class=" cols col-2">
                                        <label class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>단가
                                            </span>
                                        </label>
                                        <input id="CM1042E_UnitPrice" type="text" class="form-control text-right"
                                               v-bind:value="ProductDetail.UnitPrice"
                                               @input="function(e){
                                                            ProductDetail.UnitPrice = e.target.value.toPriceString()
                                                            e.target.value = e.target.value.toPriceString()
                                                       }" />
                                    </div>
                                    <div class="cols col-2">
                                        <label class="control-label">
                                            <span>부가세</span>
                                        </label>
                                        <input id="CM1042E_UnitVAT" type="text" class="form-control text-right" disabled v-model="ProductDetail.UnitVAT" />
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols">
                                        <label class="control-label">사용여부</label>
                                        <select id="CM1042E_UseYN" class="form-control" v-model="ProductDetail.UseYN">
                                            <option selected="selected">Y</option>
                                            <option>N</option>
                                        </select>
                                    </div>
                                </section>
                                <section class="btn-align">
                                    <div class="btn-group">
                                        <button type="button" id="btnItemSave" class="btn btn-block btn-primary" @click="saveData">
                                            <span class="glyphicon glyphicon-save"></span> 저장
                                        </button>
                                    </div>
                                    <div class="btn-group">
                                        <button type="button" id="btnItemRmve" class="btn btn-warning" @click="removeData">
                                            <i class="glyphicon glyphicon-edit"></i> 삭제
                                        </button>
                                    </div>
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-block btn-danger" data-dismiss="modal">
                                            <span class="glyphicon glyphicon-remove"></span> 취소
                                        </button>
                                    </div>
                                </section>
                            </fieldset>
                        </form>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


    export default {
        name: 'CM1042E',
        data() {
            return {
                CallbackFunction: null,     // Caller Function
                ProgressList: null,             // 진행상황 공통코드 리스트
                // 상품 상세
                ProductDetail: {
                    RowSeq: null,
                    CorpCd: null,   // 회사코드
                    ProductCd: null,
                    ProductNm: null,
                    ProductDesc: null,
                    UnitPrice: null,
                    UnitVAT: null,
                    UseYN: 'Y',
                    RegtDate: null,
                    RegtEmpNo: null,
                    UpdateDate: null,
                    UpdateEmpNo: null
                }
            }
        },
        watch:
            // < 단가 입력시 부가세 자동 계산 >
        {
            ProductDetail: {
                // watch는 객체와 배열 안의 정보변화를 눈치챌 수 없음
                // ProductDetail은 객체이기 때문에 deep과 handler를 이용
                deep: true,
                handler(newData)
                {
                    if (newData.UnitPrice != null && newData.UnitPrice != '') {

                        var tempVal = Number(newData.UnitPrice.toNumber())

                        // vat값 자동계산
                        if (tempVal > 0) {

                            this.ProductDetail.UnitVAT = Math.round((tempVal / 10)).toPriceNumber()
                        }
                        else {

                            this.ProductDetail.UnitVAT = '0'
                        }
                    }
                    else {

                        this.ProductDetail.UnitVAT = '0'
                    }
                }
                
            }
        },
        beforeMount() {

            // < 공통코드 조회 >
            this.ProgressList = $.scriptBase.getCommonCode('CODE000', '0723000', '', '') // 진행상황

        },
        methods: {
            // < 창 열림 >
            open: function (callbackFunction, selectedObj) {
                this.CallbackFunction = callbackFunction
                // ''/null/undefined를 따로따로 구분하지 않아도 객체를 넣으면 알아서 비교해줌
                if (selectedObj) {
                    this.ProductDetail = selectedObj
                    this.ProductDetail.Arg = 'V'
                }
                else {
                    this.reset()
                    this.ProductDetail.Arg = 'I'
                }

                this.ProductDetail.UserId = this.$parent.$parent.EmpId 
                this.ProductDetail.CorpCd = this.$parent.$parent.CompanyCode


                $("#CM1042E_Modal").modal("show")
            },
            // < 창 닫힘 >
            close: function () {
                $("#CM1042E_Modal").modal("hide")
            },
            // < 수정사항 원상복구 >
            reset() {
                var progressList = this.ProgressList
                Object.assign(this.$data, this.$options.data())
                this.ProgressList = progressList
            },
            // < 정보 저장(수정/등록) >
            saveData: function () {

                var parent = this
                var selectObj = this.ProductDetail

                if (!selectObj.ProductNm) {

                    alert('상품명을 입력하세요.')
                    $(CM1042E_ProductNm).focus()
                    return
                }
                if (!selectObj.ProductDesc) {

                    alert('상품내역을 입력하세요.')
                    $(CM1042E_ProductDesc).focus()
                    return
                }
                if (!selectObj.UnitPrice) {

                    alert('단가를 입력하세요.')
                    $(CM1042E_UnitPrice).focus()
                    return
                }

                var url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/SaveCompanyProduct'
                url = url.toRealServiceUrl()
                var obj = new Object()
                
                obj.jsonString = JSON.stringify(this.ProductDetail)

                // 서비스호출 성공 콜백 함수
                var successCallback = function (data) {

                    if (data.d > 0) {

                        if (parent.ProductDetail.Arg == 'I') {

                            alert('등록 되었습니다.')
                            // $emit을 이용하여 부모창에서 함수(function)을 가져올 수 있음
                            // 새로고침할 필요없이 데이터가 업데이트 되면 목록을 다시 불러오도록 하기 위해 사용
                            parent.$emit("getData");
                            parent.close()
                        }
                        else {

                            alert('저장 되었습니다.')
                            parent.$emit("getData");
                            parent.close()
                        }
                    }
                }
                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            // < 정보 삭제(사용 여부 값 변경) >
            removeData: function () {

                if (this.ProductDetail === null && this.PageActionType === 'I') {

                    alert('삭제할 정보가 존재 하지 않습니다.')
                    return
                }

                if (confirm('삭제시 상품에 관련된 모든 데이터가 삭제됩니다. \n삭제하시겠습니까?')) {

                    var parent = this

                    var url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/SaveCompanyProduct'
                    url = url.toRealServiceUrl()
                    var obj = new Object()

                    // 목록 조회 조건에 사용여부(UseYN)이 Y일 것이 포함되어 있음
                    this.ProductDetail.UseYN = 'N'

                    obj.jsonString = JSON.stringify(this.ProductDetail)


                    // 서비스호출 성공 콜백 함수
                    var successCallback = function (data) {

                        if (data.d > 0) {

                            alert('삭제 되었습니다.')

                            $(parent.$refs.CM1042E_Modal).modal('hide')
                            parent.$emit("getData");
                        }
                        else {

                            alert('삭제에 실패하였습니다. 잠시 후 다시 시도해 주세요.')
                        }
                    }

                    $.scriptBase.executeAjaxAsync(url, obj, successCallback)

                }
            }
        }
    }
</script>

<style scoped>
</style>