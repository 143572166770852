<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a v-show="this.$parent.CanSearch" href="#" id="btnListSrch" class="btn btn-primary btn-row2" @click.prevent="getProjectListAll">
                    <span>
                        <i class="glyphicon glyphicon-search"></i> 조회
                    </span>
                </a>
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-4">
                                        <label for="selTrgtYY" class="control-label">
                                            <span>년도</span>
                                        </label>
                                        <select class="form-control" ref="selTrgtYY" name="selTrgtYY" v-model="ProjectSelectTerm.TargetYear" @change="getProjectListAll">
                                            <option value="">전체</option>
                                            <option v-for="year in YearsList" v-bind:value="year">
                                                {{year}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-4">
                                        <label for="txtProjectNm" class="control-label">
                                            <span>프로젝트명</span>
                                        </label>
                                        <input type="text" id="txtProjectNm" class="form-control txt" v-model="ProjectSelectTerm.ProjectName" />
                                    </div>
                                    <div class="cols col-4">
                                        <label for="selStatusCd" class="control-label">
                                            <span>진행상태</span>
                                        </label>
                                        <select class="form-control" ref="selStatusCd" name="selStatusCd" v-model="ProjectSelectTerm.StatusCd" @change="getProjectListAll">
                                            <option value="">전체</option>
                                            <option v-for="item in StatusList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-4">
                                        <div>
                                            <div class="form-check radio-inline">
                                                <input class="form-check-input" type="radio" name="queryBase" id="전체조회" v-model="ProjectSelectTerm.QueryBase" value="1">
                                                <label class="form-check-label control-label" for="전체조회">
                                                    전체조회
                                                </label>
                                            </div>
                                            <div class="form-check radio-inline">
                                                <input class="form-check-input" type="radio" name="queryBase" id="현재시점" v-model="ProjectSelectTerm.QueryBase" value="2" checked>
                                                <label class="form-check-label control-label" for="현재시점">
                                                    현재시점
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </section>
                        </fieldset>
                    </form>
                </div>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">프로젝트</p>
                </div>
                <div>
                    <!--리스트 영역-->
                    <section class="tbl-contents">
                        <div class="table-responsive">
                            <DxDataGrid id="grid-container"
                                        :show-borders="false"
                                        :data-source="ProjectStatisticList"
                                        :hoverStateEnabled="true"
                                        :allow-column-resizing="true"
                                        :column-auto-width="true"
                                        :row-alternation-enabled="true"
                                        @cell-prepared="onCellPrepared"
                                        @cell-click="insertInputMM"
                                        @cell-hover-changed="setHover"
                                        style="max-height: 500px; vertical-align: top;">
                                <DxPaging :enabled="false" />
                                <DxColumnFixing :enabled="true" />
                                <DxScrolling mode="standard" useNative="false" />
                                <DxColumn data-field="CompanyNm" caption="거래처명" :fixed="true" />
                                <DxColumn data-field="ProjectNm" caption="프로젝트명" :fixed="true" />
                                <DxColumn data-field="Prj_Period" caption="계약기간" />
                                <DxColumn data-field="Prj_MM" caption="계약월수" />
                                <DxColumn data-field="Exe_Period" caption="수행기간" />
                                <DxColumn data-field="Exe_MM" caption="수행월수" />
                                <DxColumn data-field="Curr_MM" caption="현재월수" />
                                <DxColumn data-field="InputMM" caption="투입MM" template="inputMMButton" :fixed="false" alignment="center" />
                                <template #inputMMButton="{ data }">
                                    <div>{{ data.InputMM }}</div>
                                </template>
                                <DxColumn data-field="StatusCd" caption="진행상태" alignment="center" />
                                <DxColumn data-field="ContractAMT" caption="계약금액" format="#,##0" />
                                <DxColumn data-field="SalesAMT" caption="매출금액" format="#,##0" />
                                <DxColumn data-field="PurchaseAMT" caption="매입금액" format="#,##0" />
                                <DxColumn data-field="EstimateEtcCost" caption="예상비용" format="#,##0" />
                                <DxColumn data-field="MarginalProfits" caption="한계이익" format="#,##0" />
                                <DxColumn data-field="MarginalProfitsRate" caption="한계이익율" format="#,##0.00%" />
                                <DxColumn data-field="InputExe" caption="메모" />
                            </DxDataGrid>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle';
    import { DxDataGrid, DxColumn, DxButton, DxPaging, DxColumnFixing, DxScrolling } from 'devextreme-vue/data-grid';
    export default {
        name: 'RT1070R',
        components: {
            ViewTitle,
            DxDataGrid,
            DxColumn,
            DxButton,
            DxPaging,
            DxColumnFixing,
            DxScrolling
        },
        data() {
            return {
                // 조회조건 년도 목록
                YearsList: new Array(),
                // 프로젝트 진행상태 목록
                StatusList: new Array(),
                // 프로젝트 수익현황 목록
                ProjectStatisticList: new Array(),
                // 프로젝트 조회조건
                ProjectSelectTerm: {
                    ProjectName: '',
                    TargetYear: this.moment(new Date()).year(),
                    QueryBase: 2,
                    StatusCd: '',
                },
            }
        },
        mounted() {

            this.getProjectListAll();
        },
        beforeMount() {

            var startYear = this.moment(new Date()).add(5, 'year').year();
            var endYear = this.moment(new Date(2010, 1, 1, 0, 0, 0)).year();

            // 대상년도 목록 값 설정
            for (var i = startYear; i >= endYear; i--) {

                this.YearsList.push(i);
            }

            // 프로젝트 진행상태 코드
            var status = $.scriptBase.getCommonCode('CODE000', '0701000', '', '');
                                 // 착수, 진행중, 종료
            this.StatusList.push(status[1], status[2], status[3]);
        },
        watch: {
            // 조회조건 - 전체조회/현재시점 변경
            'ProjectSelectTerm.QueryBase'(newData) {
                this.getProjectListAll();
            }
        },
        methods: {
            // datagrid header 가운데 정렬, column 중앙 정렬
            onCellPrepared(e) {

                if (e.rowType == 'header') {

                    e.cellElement.style['textAlign'] = 'center';
                }
                if (e.rowType == 'data') {

                    e.cellElement.style['verticalAlign'] = 'middle';
                }
            },
            // 마스터 그리드 프로젝트 목록 조회
            getProjectListAll() {

                var parent = this;

                var url = '/Mini.Service.IBS.RT.Service/AccountingReport.svc/ajax/GetProjectStatistic'.toRealServiceUrl();
                var obj = new Object();
                obj.jsonString = JSON.stringify(this.ProjectSelectTerm);

                var ajaxSuccessHandler = function (data) {

                    var jsonObj = JSON.parse(data.d);
                    var table = jsonObj.Table;
                    parent.ProjectStatisticList = (table.length > 0) ? table : new Array();

                    parent.ProjectStatisticList.forEach((item) => {

                        if (item.InputMM == undefined) {

                            item.InputMM = '[입력]';
                        }
                    });
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler);
            },
            // 인력운용관리로 이동
            insertInputMM(e) {

                if (e.column.name == 'InputMM') {

                    var isExist = false;
                    var menuInfoObject = null;

                    menuInfoObject = {

                        Path: '/PM/ProjectManager/PM1070R?qid=05&wid=0513&eid=PM1070R',
                        ComponentName: 'PM1070R',
                        MenuName: '인력운용관리',
                        IsActive: true
                    };

                    // 동일한 메뉴가 열려있는지 검사
                    this.$parent.KeepAliveMenuList.forEach((item) => {

                        if (item.MenuName === menuInfoObject.MenuName) {

                            isExist = true;
                            item.IsActive = true;
                        } else {

                            item.IsActive = false;
                        }
                    });

                    // 동일 메뉴가 없다면 메뉴 탭 추가
                    if (!isExist) {

                        this.$parent.KeepAliveMenuList.push(menuInfoObject);
                    }

                    this.$router.push(menuInfoObject.Path);
                }
            },
            // InputMM 링크처럼 보이기
            setHover(e) {

                if (e.rowType == 'data' && e.column.name == 'InputMM') {

                    e.cellElement.onmouseover = () => {

                        e.cellElement.style['cursor'] = 'pointer';
                        e.cellElement.style['color'] = '#337ab7';
                        e.cellElement.style['font-weight'] = '900';
                    }
                    e.cellElement.onmouseout = () => {

                        e.cellElement.style['cursor'] = null;
                        e.cellElement.style['color'] = null;
                        e.cellElement.style['font-weight'] = null;
                    }
                }
            }
        }
    }
</script>