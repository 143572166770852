<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a href="#" id="btnListSrch" class="btn btn-primary btn-row2" @click.prevent="showBankData">
                    <span>
                        <i class="glyphicon glyphicon-search"></i> 조회
                    </span>
                </a>
                <a href="#" id="btnListSave" class="btn btn-default" @click.prevent="saveBankData">
                    <span>
                        <i class="glyphicon glyphicon-save"></i> 저장
                    </span>
                </a>
                <a href="#" id="btnAddMatch" class="btn btn-default" @click.prevent="onAddMatch">
                    <span>은행매칭</span>
                </a>
            </div>
            <div class="sch-box">
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-4">
                                        <label for="txtDate" class="control-label">
                                            <span>등록일자</span>
                                        </label>
                                        <div class="input-group date">
                                            <DxDateBox pickerType="calendar"
                                                       display-format="yyyy-MM-dd"
                                                       v-model="BankDataSelectTerm.TargetDate"
                                                       @value-changed="refreshOptions"
                                                       style="border-radius:0;" />
                                        </div>
                                    </div>
                                    <div class="cols col-4">
                                        <label for="selRound" class="control-label">
                                            <span>등록회차</span>
                                        </label>
                                        <select class="form-control" id="selRound" v-model="BankDataSelectTerm.RoundNo" @change="showBankData">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in RoundNoList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-4">
                                        <label for="selInOutDvs" class="control-label">
                                            <span>입/출구분</span>
                                        </label>
                                        <select class="form-control" id="selInOutDvs" v-model="BankDataSelectTerm.InOutDvsCd" @change="showBankData">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in InOutDvsCodeList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-4">
                                        <label for="selMatchState" class="control-label">
                                            <span>매칭상태</span>
                                        </label>
                                        <select class="form-control" id="selMatchState" v-model="BankDataSelectTerm.MatchStateCd" @change="showBankData">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in MatchStateCodeList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                </section>
                            </section>
                        </fieldset>
                    </form>
                </div>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">은행데이터 관리</p>
                    <div class="btn-wrap">
                        <label for="btnListInst" class="btn btn-default">
                            <span>
                                <i class="glyphicon glyphicon-plus"></i> 업로드
                            </span>
                        </label>
                        <input type="file" id="btnListInst" accept=".xls, .xlsx" @click="setInsertTerm" @change="convertExcel" style="display: none;" />
                    </div>
                </div>
                <div>
                    <div class="alert alert-info total">
                        <p>
                            <span class="glyphicon glyphicon-folder-close"></span>
                            전체: <span id="spTotCnt">{{ BankDataListCnt }}</span>건
                        </p>
                    </div>
                    <div class="table-responsive">
                        <DxDataGrid id="grid-container"
                                    :ref="BankData"
                                    :data-source="BankDataList"
                                    :show-borders="false"
                                    :hoverStateEnabled="true"
                                    :allow-column-resizing="true"
                                    :column-resizing-mode="'widget'"
                                    :row-alternation-enabled="true"
                                    :column-auto-width="true"
                                    :repaint-changes-only="true"
                                    @cell-prepared="onCellPrepared"
                                    style="max-height: 500px; vertical-align: top;">
                            <DxScrolling mode="standard" useNative="false" />
                            <DxColumnFixing :enabled="true" />
                            <DxPaging :enabled="false" />
                            <DxColumn data-field="TradeYMD" caption="거래일자" alignment="center" data-type="date" format="yyyy-MM-dd" />
                            <DxColumn data-field="OutAmt" caption="출금액" alignment="right" format="fixedPoint" />
                            <DxColumn data-field="InAmt" caption="입금액" alignment="right" format="fixedPoint" />
                            <DxColumn data-field="MatchedAmt" caption="실행매칭액" alignment="right" format="fixedPoint" />
                            <DxColumn data-field="AfterTradeAmt" caption="거래후잔액" alignment="right" format="fixedPoint" />
                            <DxColumn data-field="TradeCont" caption="계좌거래내용" alignment="left" />
                            <DxColumn data-field="OpptAcntNo" caption="상대계좌번호" alignment="left" />
                            <DxColumn data-field="InOutLogMemo" caption="메모" alignment="left" />
                            <DxColumn data-field="OpptBankNm" caption="상대은행" alignment="center" />
                            <DxColumn data-field="TradeDivNm" caption="거래구분" alignment="center" />
                            <DxColumn data-field="CheckAmt" caption="수표어음금액" alignment="right" format="fixedPoint" />
                            <DxColumn data-field="CMSCd" caption="CMS코드" alignment="left" />
                            <DxColumn data-field="OpptAcntNm" caption="상대계좌예금주명" alignment="center" />
                            <DxColumn data-field="FileUploadYMD" caption="등록일자" alignment="center" data-type="date" format="yyyy-MM-dd" />
                            <DxColumn data-field="MatchStateNm" caption="매칭상태" alignment="center" />
                        </DxDataGrid>
                    </div>
                </div>
            </div>
        </div>
        <AC1101E ref="AC1101E"></AC1101E>
    </div>
</template>

<script>
    import AC1101E from '@/components/ac/popup/AC1101E';
    import ViewTitle from '@/components/ViewTitle';
    import { DxDataGrid, DxColumn, DxPaging, DxScrolling, DxColumnFixing, DxSorting, DxSelection, } from 'devextreme-vue/data-grid';
    import DxDateBox, { DxCalendarOptions } from 'devextreme-vue/date-box';
    import * as XLSX from 'xlsx';

    export default {
        name: 'AC1100R',
        components: {
            AC1101E, ViewTitle, DxDateBox, DxCalendarOptions, DxDataGrid, DxColumn, DxPaging, DxScrolling, DxColumnFixing, DxSorting, DxSelection, XLSX,
        },
        data() {
            return {
                // 등록회차 목록
                RoundNoList: new Array(),
                // 입출금구분 코드
                InOutDvsCodeList: new Array(),
                // 매칭상태 코드
                MatchStateCodeList: new Array(),
                // 은행데이터 조회조건
                BankDataSelectTerm: {
                    Arg: 'L',
                    TargetDate: this.moment().format('YYYY-MM-DD'),
                    RoundNo: '',
                    InOutDvsCd: '',
                    MatchStateCd: '',
                },
                BankData: 'dataGrid',
                // 은행데이터 목록
                BankDataList: new Array(),
                // 은행데이터 목록 건수
                BankDataListCnt: 0,
                // 은행데이터 업로드 객체
                BankDataInsertTerm: new Array(),
                // 업로드 데이터 DB 유효성 검사 통과여부
                isValid: true,
            }
        },
        beforeMount() {

            this.InOutDvsCodeList = $.scriptBase.getCommonCode('CODE000', '0505000', '', '');
            this.MatchStateCodeList = $.scriptBase.getCommonCode('CODE000', '0519000', '', '');
        },
        mounted() {

            this.showBankData();
        },
        methods: {

            // DataGrid Header 가운데 정렬, Column Data 중앙 정렬
            onCellPrepared(e) {

                if (e.rowType == 'header') {
                    e.cellElement.style['textAlign'] = 'center';
                }
                if (e.rowType == 'data') {
                    e.cellElement.style['verticalAlign'] = 'middle';
                }
            },
            // 조회조건 등록일자 변경 시 다른 조건 초기화
            refreshOptions() {

                this.BankDataSelectTerm.RoundNo = '';
                this.BankDataSelectTerm.InOutDvsCd = '';
                this.BankDataSelectTerm.MatchStateCd = '';

                this.showBankData();
            },
            // 은행데이터 목록 조회
            showBankData() {

                var parent = this;
                this.RoundNoList = [{ Code: '01', Name: '01회' }];
                this.isValid = true;
                this.selectedItemKeys = new Array();

                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/SelectInOutAmtLog'.toRealServiceUrl();
                var obj = new Object();

                this.BankDataSelectTerm.TargetDate = this.moment(this.BankDataSelectTerm.TargetDate).format('YYYYMMDD');

                obj.jsonString = JSON.stringify(this.BankDataSelectTerm);

                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d);
                    var table = jsonData.Table;
                    var table1 = jsonData.Table1;
                    var table2 = jsonData.Table2;

                    // 은행데이터 목록
                    parent.BankDataList = (table.length > 0) ? table : new Array();

                    // 은행데이터 전체 건수
                    parent.BankDataListCnt = (table1.length > 0) ? table1[0].TotCnt : 0;

                    // 등록회차
                    if (table2.length > 0) {

                        let round = new Array();
                        table2.forEach((item) => round.push({ Code: item.RoundNo, Name: item.RoundNo + '회' }));

                        // 등록회차 콤보박스 중복없이 만들기
                        parent.RoundNoList = Array.from(new Set(round));
                    }
                }
                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 엑셀파일 업로드 전 데이터 세팅
            setInsertTerm() {

                // 저장되지 않은 업로드 객체 있을 때 초기화
                if (this.BankDataInsertTerm.length > 0) {

                    this.BankDataInsertTerm = new Array();
                }

                // 등록일자 당일로 설정
                let TargetDate = this.moment().format('YYYYMMDD');

                if (this.BankDataSelectTerm.TargetDate != TargetDate) {

                    this.BankDataSelectTerm.TargetDate = TargetDate;
                }

                this.showBankData();
            },
            // 엑셀파일 json 형식으로 변환
            convertExcel: function (e) {

                var parent = this;

                let selectedFile = e.target.files[0];

                if (selectedFile) {

                    let fileReader = new FileReader();

                    fileReader.readAsBinaryString(selectedFile);
                    fileReader.onload = (event) => {

                        let data = event.target.result;
                        let workbook = XLSX.read(data, { type: "binary", cellDates: true, dateNF: "yyyy-mm-dd" });

                        workbook.SheetNames.forEach(sheet => {

                            let ExcelData = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
                            let mapping = {

                                '거래일시': 'TradeYMD',
                                '출금': 'OutAmt',
                                '입금': 'InAmt',
                                '거래후 잔액': 'AfterTradeAmt',
                                '거래내용': 'TradeCont',
                                '상대계좌번호': 'OpptAcntNo',
                                '상대은행': 'OpptBankNm',
                                '메모': 'InOutLogMemo',
                                '거래구분': 'TradeDivNm',
                                '수표어음금액': 'CheckAmt',
                                'CMS코드': 'CMSCd',
                                '상대계좌예금주명': 'OpptAcntNm'
                            };

                            ExcelData = ExcelData.filter((e) => e.거래구분 != undefined);
                            parent.renameKeys(mapping, ExcelData);
                        });

                        if (confirm('선택한 엑셀 파일을 그리드에 업로드하시겠습니까?')) {

                            // 엑셀 데이터 형식 유효성 검사
                            let check1 = parent.BankDataInsertTerm.findIndex((item) => typeof item.AfterTradeAmt !== 'number') + 1;
                            let check2 = parent.BankDataInsertTerm.findIndex((item) => typeof item.CheckAmt !== 'number') + 1;
                            let check3 = parent.BankDataInsertTerm.findIndex((item) => typeof item.InAmt !== 'number') + 1;
                            let check4 = parent.BankDataInsertTerm.findIndex((item) => typeof item.OutAmt !== 'number') + 1;
                            let check5 = parent.BankDataInsertTerm.findIndex((item) => item.TradeYMD === 'inValid') + 1;
                            let allPass = (check1 || check2 || check3 || check4 || check5);

                            if (check1 !== 0) {

                                alert(check1 + '번째 "거래후 잔액" 항목이 올바르지 않습니다. 엑셀 파일을 확인해 주십시오.');
                            }
                            if (check2 !== 0) {

                                alert(check2 + '번째 "수표어음금액" 항목이 올바르지 않습니다. 엑셀 파일을 확인해 주십시오.');
                            }
                            if (check3 !== 0) {

                                alert(check3 + '번째 "입금" 항목이 올바르지 않습니다. 엑셀 파일을 확인해 주십시오.');
                            }
                            if (check4 !== 0) {

                                alert(check4 + '번째 "출금" 항목이 올바르지 않습니다. 엑셀 파일을 확인해 주십시오.');
                            }
                            if (check5 !== 0) {

                                alert(check5 + '번째 "거래일시" 항목이 올바르지 않습니다. 엑셀 파일을 확인해 주십시오.');
                            }
                            // 데이터 오류 있으면 초기화하고 멈춤
                            if (allPass !== 0) {

                                parent.BankDataInsertTerm = new Array();
                                return;
                            }

                            // 업로드 데이터 DB에서 유효성 검사
                            parent.checkBankData();
                        } else {

                            // 업로드 파일 저장 취소 시 객체 초기화
                            parent.BankDataInsertTerm = new Array();
                        }
                    };
                } else {

                    alert('선택된 엑셀 파일이 없습니다.');
                    return;
                }
            },
            // 엑셀데이터 객체 키 변경
            renameKeys(mapping, objArr) {

                let renamedObjArr = new Array();

                for (let obj of objArr) {

                    let renamedObj = {
                        Arg: 'I',
                        UserId: this.$parent.EmpId,
                        InOutLogSeq: null,
                        TradeYMD: '',
                        OutAmt: 0,
                        InAmt: 0,
                        AfterTradeAmt: 0,
                        TradeCont: '',
                        OpptAcntNo: '',
                        InOutLogMemo: '',
                        OpptBankNm: '',
                        TradeDivNm: '',
                        CheckAmt: 0,
                        CMSCd: '',
                        OpptAcntNm: '',
                        FileUploadYMD: '',
                        MatchStateCd: '',
                        MatchYMD: '',
                    };

                    for (let [before, after] of Object.entries(mapping)) {

                        if (obj[before]) {

                            renamedObj[after] = obj[before];
                        }
                    }

                    renamedObj.InOutLogSeq = Math.random();
                    renamedObj.TradeYMD = this.moment(renamedObj.TradeYMD).format('YYYY-MM-DD HH:mm:ss');
                    renamedObj.FileUploadYMD = this.moment().format('YYYYMMDD');
                    renamedObjArr.push(renamedObj);
                }

                this.BankDataInsertTerm = renamedObjArr;
            },
            // 엑셀 데이터 DB에서 유효성 체크
            checkBankData() {

                var parent = this;
                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/CheckInOutAmtLog'.toRealServiceUrl();

                let BankDataCheckTerm = new Array();
                this.BankDataInsertTerm.forEach((item) => BankDataCheckTerm.push({
                    TradeYMD: item.TradeYMD,
                    OutAmt: item.OutAmt,
                    InAmt: item.InAmt,
                    AfterTradeAmt: item.AfterTradeAmt,
                    CheckAmt: item.CheckAmt,
                    CMSCd: item.CMSCd
                }));

                var obj = new Object();
                obj.jsonString = JSON.stringify(BankDataCheckTerm);

                var successCallback = function (data) {

                    var table = JSON.parse(data.d).Table;

                    let alert1 = table.find((item) => item.Alert1 != 'PASS');
                    alert1 = (alert1 != undefined) ? alert1.Alert1 : '';

                    let alert2 = table.find((item) => item.Alert2 != 'PASS');
                    alert2 = (alert2 != undefined) ? alert2.Alert2 : '';

                    // DB에 저장된 것과 중복된 데이터가 있거나, 금액이 맞지 않을 때
                    if (alert1 != '' || alert2 != '') {

                        alert(alert1 + alert2);
                        return;
                    }

                    // 같은 날짜에 입력된 데이터가 있을 때 등록회차 계산
                    if (parent.BankDataList.length > 0) {

                        let lastNo = parseInt(parent.RoundNoList.at(-1)['Code']);
                        let round = (lastNo + 1 > 9) ? lastNo + 1 : '0' + (lastNo + 1);
                        parent.RoundNoList.push({ Code: round, Name: round + '회' });

                        // 조회조건 등록회차 업데이트
                        parent.BankDataSelectTerm.RoundNo = round.toString();
                    } else {

                        parent.BankDataSelectTerm.RoundNo = '01';
                    }

                    // 그리드 데이터 초기화
                    parent.BankDataList = new Array();
                    // 새로 업로드한 엑셀 데이터 출력
                    parent.BankDataList = Array.from(new Set(parent.BankDataInsertTerm));
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 엑셀로 읽어온 은행데이터 저장
            saveBankData() {

                var parent = this;
                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/ManipulateInOutAmtLog'.toRealServiceUrl();
                var obj = new Object();

                if (this.BankDataInsertTerm.length == 0) {

                    alert('저장할 엑셀 파일을 먼저 업로드해 주십시오.');
                    return;
                }

                if (this.isValid == false) {

                    alert('업로드된 엑셀 파일에 문제가 있습니다. 다시 확인해 주십시오.');
                    return;
                }

                // 저장할 Seq 초기화
                this.BankDataInsertTerm.forEach((item) => item.InOutLogSeq = null);
                // 저장할 등록회차 설정
                this.BankDataInsertTerm.forEach((item) => item.RoundNo = this.BankDataSelectTerm.RoundNo);

                obj.jsonString = JSON.stringify(this.BankDataInsertTerm);

                if (confirm('업로드한 데이터를 저장하시겠습니까?')) {

                    var successCallback = function (data) {

                        if (data.d > 0) {

                            alert('저장되었습니다.');

                            // 저장한 회차를 조회조건으로 설정
                            parent.BankDataSelectTerm.RoundNo = parent.BankDataInsertTerm[0].RoundNo;
                            parent.BankDataInsertTerm = new Array();
                            parent.showBankData();
                        } else {

                            alert('저장에 실패했습니다. 잠시 후 다시 시도해 주십시오.');
                            parent.BankDataInsertTerm = new Array();
                            parent.showBankData();
                        }
                    }
                    $.scriptBase.executeAjaxAsync(url, obj, successCallback);
                }
            },
            // 은행매칭 버튼 메소드
            onAddMatch() {

                var parent = this;

                var callbackFunction = function () {

                    parent.showBankData();
                }
                this.$refs.AC1101E.open(callbackFunction);
            },
        }
    }
</script>