<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a v-show="this.$parent.CanSave" href="#" class="btn btn-default" @click.prevent="fn_AddUserGroup">
                    <span>
                        <i class="glyphicon glyphicon-pencil"></i>등록
                    </span>
                </a>
            </div>
            <div class="sch-box">
                <div class="sch-form">
                    <!--조건 선언부-->
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-3">
                                        <label for="sch3-1" class="control-label">
                                            <span>시스템</span>
                                        </label>
                                        <select class="form-control" ref="selSystId" name="selSystId">
                                            <option v-for="item in SystemIdList" v-bind:value="item.Code">{{item.Name}}</option>
                                        </select>
                                    </div>
                                </section>
                            </section>
                            <section class="sch-btn">
                                <a v-show="this.$parent.CanSearch" href="#" class="btn btn-primary btn-row2" @click.prevent="fn_ShowData(false)">
                                    <span>
                                        <i class="glyphicon glyphicon-search"></i>조회
                                    </span>
                                </a>
                            </section>
                        </fieldset>
                    </form>

                </div>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">사용자 그룹 관리</p>
                </div>
                <div>
                    <!--리스트 영역-->
                    <div class="alert alert-info total">
                        <p>
                            <i class="glyphicon glyphicon-folder-close"></i>
                            <span>
                                전체: <span id="spTotCnt">{{UserGrpListTotalCount}}</span>건
                            </span>
                        </p>
                    </div>
                    <div class="table-responsive">
                        <table class="table table tbl-list tbl-center table-hover table-bordered" id="tb0">
                            <caption>테이블</caption>
                            <colgroup>
                                <col />
                                <col />
                                <col />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th scope="col">그룹 ID</th>
                                    <th scope="col">그룹 명</th>
                                    <th scope="col">설명</th>
                                </tr>
                            </thead>
                            <tbody id="result">
                                <tr v-if="UserGroupList === null">
                                    <td colspan="3">조회된 정보가 없습니다.</td>
                                </tr>
                                <tr v-else v-for="item in UserGroupList" style="cursor:pointer;" @click="rowSelectEvent(item)">
                                    <td name="usergroup-id">{{item.UserGrpId}}</td>
                                    <td name="usergroup-name" style="text-align:left;">{{item.UserGrpName}}</td>
                                    <td name="usergroup-desc" style="text-align:left;">{{item.UserGrpDesc}}</td>
                                    <td name="user-class-name">{{item.UserClsName}}</td>
                                    <td name="system-id" style="display:none">{{item.SystId}}</td>
                                    <td name="company-code" style="display:none">{{item.CorpCd}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" ref="ZZ1041E_Modal" tabindex="-1" role="dialog" aria-labelledby="ZZ1031E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                        <h3 class="modal-title">사용자 그룹 상세</h3>
                    </div>
                    <div class="modal-body">
                        <section class="add-wrap">
                            <form>
                                <fieldset>
                                    <legend>입력폼 col3</legend>
                                    <section class="cols-wrap">
                                        <div class="cols">
                                            <label for="txtUserGrpId2" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>그룹 ID
                                                </span>
                                            </label>
                                            <input type="text" ref="txtUserGrpId2" class="form-control txt" maxlength="8" 
                                                   v-bind:readonly="PageActionType != 'I'"
                                                   v-model="UserGrpDetail.UserGrpId"/>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols">
                                            <label for="txtUserGrpName2" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>그룹 명
                                                </span>
                                            </label>
                                            <input type="text" ref="txtUserGrpName2" class="form-control txt" v-model="UserGrpDetail.UserGrpName"/>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols">
                                            <label for="txtUserGrpDesc2" class="control-label">
                                                <span>설명</span>
                                            </label>
                                            <textarea id="txtUserGrpDesc2" class="form-control" form-groups="10" v-model="UserGrpDetail.UserGrpDesc"></textarea>
                                        </div>
                                    </section>
                                    <section class="btn-align">
                                        <div v-show="this.$parent.CanSave" class="btn-group">
                                            <button type="button" id="btnViewSave" @click.prevent="fn_SaveUserGroup" class="btn btn-primary">
                                                <span class="glyphicon glyphicon-save"></span> 저장
                                            </button>
                                        </div>
                                        <div v-show="this.$parent.CanDelete && PageActionType !='I'" class="btn-group">
                                            <button type="button" id="btnViewRmve" class="btn btn-warning" @click.prevent="fn_RemoveUserGroup">
                                                <span class="glyphicon glyphicon-edit"></span> 삭제
                                            </button>
                                        </div>
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-danger" data-dismiss="modal">
                                                <span class="glyphicon glyphicon-remove"></span> 취소
                                            </button>
                                        </div>
                                    </section>
                                </fieldset>
                            </form>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
    import ViewTitle from '@/components/ViewTitle'
    export default {
        name: 'ZZ1040R',
        components: {
            ViewTitle
        },
        data() {
            return {
                SystemIdList: null,         // 시스템 공통코드 목록
                UserGroupList: null,        // 사용자 그룹 목록
                // 사용자 그룹 상세 객체
                UserGrpDetail: {
                    CorpCd: null,
                    SystId: null,
                    UserGrpId: null,
                    UserGrpName: null,
                    UserGrpDesc: null
                },
                UserGrpListTotalCount: 0,   // 사용자 그룹 목록 전체 갯수
                PageActionType: 'V'         // I: 등록, V: 저장
            }
        },
        beforeMount() {

            // 공통코드 조회
            this.SystemIdList = $.scriptBase.getCommonCode('CODE090', this.$parent.CompanyCode, '', '')
        },
        mounted() {
            

            if (this.$parent.CanSearch) {

                this.fn_ShowData(false)
            }
        },
        methods: {
            // 목록 행 선택 이벤트
            // row: 선택한 행 데이터
            rowSelectEvent: function (row) {

                // 화면 액션값 설정 (I:신규, V: 상세보기)
                this.PageActionType = 'V'

                // 선택한 행 데이터 복사
                Object.assign(this.UserGrpDetail, row)

                this.UserGrpDetail.Arg = 'V'
                this.UserGrpDetail.UserId = this.$parent.EmpId
                this.UserGrpDetail.CorpCd = this.$parent.CompanyCode

                // 상세팝업 오픈
                $(this.$refs.ZZ1041E_Modal).modal('show')
            },
            // 사용자 그룹 관리 조회 메서드
            fn_ShowData: function (isOpenDetailPopup) {

                var parent = this
                var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/GetUserGroup'.toRealServiceUrl()
                var obj = new Object()

                obj.userId = this.$parent.EmpId
                obj.companyCode = this.$parent.CompanyCode
                obj.systemId = this.$refs.selSystId.value

                var successCallback = function (data) {

                    var jsonDataTable = JSON.parse(data.d).Table

                    if (jsonDataTable.length > 0) {

                        parent.UserGroupList = jsonDataTable
                        parent.UserGrpListTotalCount = jsonDataTable.length
                    }
                    else {

                        parent.UserGroupList = null
                        parent.UserGrpListTotalCount = 0
                    }

                    // 상세팝업 오픈 플래그 값에 따라 상세 팝업 오픈
                    if (isOpenDetailPopup) {

                        for (var i = 0; i < table.length; i++) {

                            if (table[i].CorpCd === parent.ProgramGroupDetail.CorpCd
                                && table[i].SystId === parent.ProgramGroupDetail.SystId
                                && table[i].UserGrpId === parent.ProgramGroupDetail.UserGrpId) {

                                parent.rowSelectEvent(table[i])

                                break
                            }
                        }
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            // 사용자 그룹 저장(V) 메서드
            fn_SaveUserGroup: function () {

                if (this.UserGrpDetail.UserGrpId === null || this.UserGrpDetail.UserGrpId === '') {

                    alert('그룹ID를 입력하세요.')
                    this.$refs.txtUserGrpId2.focus()
                    return
                }

                if (this.UserGrpDetail.UserGrpName === null || this.UserGrpDetail.UserGrpName === '') {

                    alert('그룹명을 입력하세요.')
                    this.$refs.txtUserGrpName2.focus()
                    return
                }

                var parent = this
                var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/SaveUserGroup'.toRealServiceUrl()
                var obj = new Object()
                obj.jsonString = JSON.stringify(this.UserGrpDetail)
                
                var successCallback = function (data) {

                    if (data.d > 0) {

                        if (parent.PageActionType == 'I') {

                            alert('등록 되었습니다.')
                            $(parent.$refs.ZZ1041E_Modal).modal('hide')
                            parent.fn_ShowData(false)
                        }
                        else {

                            alert('저장 되었습니다.')
                            parent.fn_ShowData(true)
                        }
                    }
                    else {

                        if (parent.PageActionType === 'I') {

                            alert('등록되지 않았습니다. 관리자에게 문의하세요.')
                        }
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            // 프로그램 그룹 신규추가(I) 메서드
            fn_AddUserGroup: function () {

                // 화면 액션값 설정 (I:신규, V: 상세보기)
                this.PageActionType = 'I'

                // 상세객체 초기화
                this.UserGrpDetail = {
                    Arg: 'I',
                    UserId: this.$parent.EmpId,
                    CorpCd: this.$parent.CompanyCode,
                    SystId: this.$refs.selSystId.value,
                    UserGrpId: null,
                    UserGrpName: null,
                    UserGrpDesc: null
                }

                // 상세팝업 오픈
                $(this.$refs.ZZ1041E_Modal).modal('show')
            },
            // 프로그램 그룹 삭제 머시드
            fn_RemoveUserGroup: function () {

                if (confirm('삭제 하시겠습니까?')) {

                    var parent = this
                    var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/RemoveUserGroup'.toRealServiceUrl()
                    var obj = new Object();

                    // 삭제 플래그 설정
                    this.UserGrpDetail.Arg = 'D'

                    obj.jsonString = JSON.stringify(this.UserGrpDetail)

                    var successCallback = function (data) {

                        if (data.d > 0) {

                            alert('삭제 되었습니다.')
                            $(parent.$refs.ZZ1041E_Modal).modal('hide')
                            parent.fn_ShowData(false)
                        }
                    }

                    $.scriptBase.executeAjaxAsync(url, obj, successCallback)
                }
            }
        }
    }
</script>

<style scoped>
</style>