<template>
	<!--상세 모달 팝업-->
	<div class="modal fade" id="PM1004E_Modal" ref="PM1004E_Modal" tabindex="-1" role="dialog" aria-labelledby="PM1004E_modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<button type="button" class="close" @click.Prevent="close">
						<span aria-hidden="true">&times;</span><span class="sr-only">close</span>
					</button>
					<h3 class="modal-title">프로젝트 코멘트 관리</h3>
				</div>
				<div class="modal-body">
					<section class="add-wrap">
						<form>
							<fieldset>
								<legend>..</legend>
								<section class="cols-wrap">
									<div class="cols col-2">
										<label for="PM1004E_txtcompanynm" class="control-label">
											<span>거래처</span>
										</label>
										<input type="text" id="PM1004E_txtcompanynm" class="form-control" v-model="ProjectDetail.CompanyNm" disabled="disabled" />
										<input type="hidden" id="PM1004E_hidcorpcd" v-model="ProjectDetail.CorpCd" />
										<input type="hidden" id="PM1004E_hidcompanycd" v-model="ProjectDetail.CompanyCd" />
										<input type="hidden" id="PM1004E_hidseqno" v-model="ProjectDetail.SeqNo" />
									</div>
									<div class="cols col-2">
										<label for="PM1004E_txtprogectnm" class="control-label">
											<span>프로젝트명</span>
										</label>
										<input type="text" id="PM1004E_txtprojectnm" class="form-control" v-model="ProjectDetail.ProjectNm" disabled="disabled" />
										<input type="hidden" id="PM1004E_hidprojectcd" v-model="ProjectDetail.ProjectCd" />
									</div>
								</section>
								<section class="cols-wrap">
									<div class="cols">
										<label for="PM1004E_txtcmtvalue" class="control-label asterisk">
											<span>
												<i class="glyphicon glyphicon-asterisk"></i>코멘트
											</span>
										</label>
										<textarea id="PM1004E_txtcmtvalue" class="form-control" rows="10" style="height: 120px; resize: none"
												  v-model="ProjectCommentDetail.CommentContents"></textarea>
									</div>
								</section>
								<section class="btn-align">
									<div class="btn-group">
										<button type="button" id="btncmtsave" class="btn btn-primary"
												@click="saveProjectComment">
											<i class="glyphicon glyphicon-save"></i> 저장
										</button>
									</div>
									<div class="btn-group">
										<button type="button" id="btncmtrmve" class="btn btn-warning"
												v-show="CommentPageActionType != 'I'"
												@click="removeProjectComment">
											<i class="glyphicon glyphicon-edit"></i> 삭제
										</button>
									</div>
									<div class="btn-group">
										<button type="button" class="btn btn-danger" @click.Prevent="close(2)">
											<span class="glyphicon glyphicon-remove"></span> 취소
										</button>
									</div>
								</section>
							</fieldset>
						</form>
					</section>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	export default {
		name: 'PM1003E',
		components: {
		},
		data() {
			return {
				CallbackFunction: null,
				StatusCodeList: null,
				ProjectCategoryCodeList: null,
				TargetYearList: new Array(),
				ProjectListAll: null,
				ProjectCommentList: null,
				ProjectBusinessHistoryList: null,
				// 상세 객체
				ProjectDetail: {
					CompanyNm: null,
					ProjectNm: null,
					ContractYMD: null,
					Price: null,
					VAT: null,
					StatusName: null,
					RegDate: null,
					RegEmpNm: null,
					ProjectDesc: null,
					StatusCd: null,
					BizNo: null,
					BizNm: null,
					ConStartYMD: null,
					ConEndYMD: null,
					ExeStartYMD: null,
					ExeEndYMD: null,
					CorpCd: null,
					CompanyCd: null,
					ProjectCd: null,
					RotProjectNm: null,
					RotProjectCd: null,
					ManagerNo: null,
					ManagerNm: null,
					ContractCompanyCd: null,
					ContractCompanyNm: null,
					ProjectType: null,
					ProjectTypeNm: null,
					IncludeVatYn: 'N',
					BusinessYn: 'N'
				},
				// 코멘트 상세 객체
				ProjectCommentDetail: {
					SeqNo: null,
					CommentContents: null,
					CompanyNm: null,
					ProjectNm: null,
					CmtRegYMD: null,
					CommentCut: null
				},
				PageActionType: 'V',
				CurrentDateYYMMDD: this.moment(new Date()).format('YYYY-MM-DD'),
				CommentPageActionType: 'I'
			}
		},
		beforeMount() {
			// 공통코드 조회
			this.StatusCodeList = $.scriptBase.getCommonCode('CODE000', '0701000', '', '')
			this.ProjectCategoryCodeList = $.scriptBase.getCommonCode('CODE000', '0732000', '', '')
		},
		methods: {
			// 프로젝트 상세 조회
			open: function (callbackFuction, selectedObj, projectCommentDetail) {

				this.CallbackFuction = callbackFuction
				
				this.CommentPageActionType = selectedObj
				this.ProjectCommentDetail = projectCommentDetail
				this.ProjectDetail.CorpCd = projectCommentDetail.CorpCd
				this.ProjectDetail.CompanyCd = projectCommentDetail.CompanyCd
				this.ProjectDetail.CompanyNm = projectCommentDetail.CompanyNm
				this.ProjectDetail.SeqNo = projectCommentDetail.SeqNo
				this.ProjectDetail.ProjectCd = projectCommentDetail.ProjectCd
				this.ProjectDetail.ProjectNm = projectCommentDetail.ProjectNm

				$("#PM1004E_Modal").modal("show")
			},
			close: function () {
				$("#PM1004E_Modal").modal("hide")
			},
			reset() {
				var progressList = this.ProgressList
				Object.assign(this.$data, this.$options.data())
				this.ProgressList = progressList
			},
			// 코멘트 저장
			saveProjectComment: function () {

				var parent = this
				var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/SaveNomalProjectComment'.toRealServiceUrl()
				var obj = new Object()

				obj.jsonString = JSON.stringify(this.ProjectCommentDetail)

				// 서비스호출 성공 콜백 함수
				var successCallback = function (data) {

					if (data.d > 0) {

						if (parent.CommentPageActionType === 'I') {

							alert('등록 되었습니다.')
							$("#PM1004E_Modal").modal("hide")

							parent.$parent.getProjectCommentList(
								parent.ProjectDetail.CorpCd,
								parent.ProjectDetail.CompanyCd
								, parent.ProjectDetail.ProjectCd, false)
						}
						else {

							alert('저장 되었습니다.')
							$("#PM1004E_Modal").modal("hide")

							parent.$parent.getProjectCommentList(
								parent.ProjectDetail.CorpCd,
								parent.ProjectDetail.CompanyCd,
								parent.ProjectDetail.ProjectCd, true)
						}
					}
				}

				$.scriptBase.executeAjaxAsync(url, obj, successCallback)
			},
			// 코멘트 삭제
			removeProjectComment: function () {

				if (confirm('삭제 하시겠습니까?')) {

					var parent = this
					var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/RemoveNomalProjectComment'.toRealServiceUrl()
					var obj = new Object()

					this.ProjectCommentDetail.Arg = 'D'

					obj.jsonString = JSON.stringify(this.ProjectCommentDetail)

					var successCallback = function (data) {

						if (data.d > 0) {

							alert('삭제 됐습니다.')
							$(parent.$refs.PM1004E_Modal).modal('hide')

							parent.$parent.getProjectCommentList(
								parent.ProjectDetail.CorpCd,
								parent.ProjectDetail.CompanyCd
								, parent.ProjectDetail.ProjectCd, false)
						}
					}

					$.scriptBase.executeAjaxAsync(url, obj, successCallback)
				}
			}
		}
	}
</script>

<style scoped>
</style>