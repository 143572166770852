<template>
    <div class="modal fade" id="AC1091E_Modal" ref="AC1091E_Modal" tabindex="-1" role="dialog" aria-labelledby="AC1091E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">
                        <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                    </button>
                    <h3 class="modal-title" v-if="isNew === true">일반회사비용 등록</h3>
                    <h3 class="modal-title" v-if="isNew === false">일반회사비용 수정</h3>
                </div>
                <div class="modal-body">
                    <section class="add-wrap">
                        <form>
                            <fieldset>
                                <legend>..</legend>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label class="control-label">
                                            <span>년도</span>
                                        </label>
                                        <select id="selTrgtYY" class="form-control" v-model="NewCorpCost.AcctYear">
                                            <option v-for="year in TargetYearList" v-bind:value="year.toString()">{{ year }}</option>
                                        </select>
                                    </div>
                                    <div class="cols col-2">
                                        <label class="control-label">
                                            <span>월</span>
                                        </label>
                                        <select id="selTrgtMM" class="form-control" v-model="NewCorpCost.AcctMonth" readonly>
                                            <option v-for="month in TargetMonthList" v-bind:value="month">{{ month }}</option>
                                        </select>
                                    </div>                                    
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2"> 
                                        <label class="control-label">
                                            <span>비용구분</span>
                                        </label>
                                        <select id="costDivCd" class="form-control" v-model="NewCorpCost.CostDivCd">
                                            <option v-for="costCd in CostDivList" v-bind:value="costCd.Code">{{ costCd.Name }}</option>
                                        </select>
                                    </div>
                                    <div class="cols col-2">
                                        <label class="control-label">
                                            <span>비용금액</span>
                                        </label>
                                        <input type="text" class="form-control" maxlength="15" v-model="NewCorpCost.CostAmt1"
                                        oninput="this.value = this.value.replace(/[^-\.0-9]/g, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');"/>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols">
                                        <label class="control-label">
                                            <span>메모 내용</span>
                                        </label>
                                        <textarea class="form-control" v-model="NewCorpCost.MemoCont"/>
                                    </div>
                                </section>
                            </fieldset>
                        </form>
                    </section>
                    <div class="btn-align">
                        <div class="btn-group">
                            <button type="button" class="btn btn-primary" @click.prevent="saveCorpCost">
                                <i class="glyphicon glyphicon-save"></i> 저장
                            </button>
                        </div>
                        <div class="btn-group">
                            <button type="button" class="btn btn-danger" @click.prevent="close">
                                <i class="glyphicon glyphicon-remove"></i> 취소
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
    select[readonly] {
        background-color: #ddd;
        pointer-events: none;
    }
</style>
<script>
    import DxDataGrid from 'devextreme-vue/data-grid'
    export default {
        name: 'AC1091E',
        components: {
            DxDataGrid
        },
        data() {
            return {
                CallbackFunction: null,
                // 대상년도 리스트
                TargetYearList: new Array(),
                TargetMonthList: new Array(),
                // 비용구분코드 리스트
                CostDivList: new Array(),
                OldCorpCostList: new Array(),
                NewCorpCost: {
                    Arg: 'I',
                    AcctYear: '',
                    AcctMonth: '00',
                    CostDivCd: '',
                    CostAmt1: '',
                    MemoCont: '',
                    EmpNo: ''
                },
                isNew: true,
            }
        },
        beforeMount() {

            // 비용구분 공통코드
            this.CostDivList = $.scriptBase.getCommonCode('CODE000', '0512000', '', '')

            // 대상년도 목록 값 설정
            var startYear = this.moment(new Date()).add(1, 'year').year()
            var endYear = this.moment(new Date(2013, 1, 1, 0, 0, 0)).year()
            for (var i = startYear; i >= endYear; i--) {

                this.TargetYearList.push(i)
            }

            for (var i = 0; i <= 12; i++) {
                i = `${i >= 10 ? i : '0' + i}`
                this.TargetMonthList.push(i)
            }
        },
        methods: {
            open: function (callbackFunction, isNew, selectedObj) {
                var parent = this
                this.CallbackFunction = callbackFunction
                this.OldCorpCostList = selectedObj
                this.NewCorpCost.AcctMonth = '00'

                if (isNew == false) {
                    this.NewCorpCost = selectedObj
                    this.NewCorpCost.CostAmt1 = this.NewCorpCost.CostAmt1.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                    this.NewCorpCost.MemoCont = this.NewCorpCost.메모내용
                    this.isNew = false
                } else {
                    this.isNew = true
                }
                
                $("#AC1091E_Modal").modal("show")
            },
            close: function () {
                $("#AC1091E_Modal").modal("hide")
                this.NewCorpCost = {}
                this.CallbackFunction()
            },
            reset() {
                Object.assign(this.$data, this.$options.data())
            },
            // 일반회사비용 저장
            saveCorpCost: function () {

                var parent = this
                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/SaveGeneralCorpCost'.toRealServiceUrl()
                var obj = new Object()

                parent.NewCorpCost.Arg = 'I'

                if (!parent.NewCorpCost.AcctYear) {
                    alert("년도를 입력해주십시오.")
                    return
                }
                if (!parent.NewCorpCost.CostDivCd) {
                    alert("비용구분명을 입력해주십시오.")
                    return
                }

                if (parent.isNew == true) {

                    // 년도, 월, 비용구분명이 중복되는지 체크
                    var check = parent.OldCorpCostList.some(e => e.AcctYear === parent.NewCorpCost.AcctYear && e.AcctMonth === parent.NewCorpCost.AcctMonth && e.CostDivCd === parent.NewCorpCost.CostDivCd)

                    if (!check) {
                        parent.NewCorpCost.EmpNo = this.$store.getters.getEmpId

                        if (!parent.NewCorpCost.CostAmt1) {
                            parent.NewCorpCost.CostAmt1 = ''
                        }
                        if (!parent.NewCorpCost.MemoCont) {
                            parent.NewCorpCost.MemoCont = ''
                        }
                    } else {

                        alert("새로 입력하신 내용의 년도별 비용구분이 이미 존재합니다. 다시 확인해주십시오.")
                        return
                    }
                }                

                obj.jsonString = JSON.stringify(parent.NewCorpCost)

                var ajaxSuccessHandler = function (data) {

                    alert("저장되었습니다.")
                    parent.close()
                }
                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
            },
        }
    }
</script>