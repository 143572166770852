<template>
    <div id="menu-title-auth" class="title-wrap">
        <p class="title">
            <span class="title-txt">{{PageHeader}}</span>
            <a v-if="IsFavorite != null && IsFavorite != ''" href="#" class="title-fav"
               @click.prevent="saveFavoritesMenu">
                <img v-if="IsFavorite === 'Y'" src="../images/ico_fav_add.svg" alt="favorite_add">
                <img v-else="IsFavorite === 'N'" src="../images/ico_fav.svg" class="fav-default" alt="favorite">
            </a>
            <span class="breadcrumb-txt" v-html="Navigator">{{Navigator}}</span>
        </p>
    </div>
</template>

<script>
    export default {
        name: 'ViewTitle',
        data() {
            return {
                Navigator: null,
                PageHeader: null,
                IsFavorite: 'N'
            }
        },
        mounted() {

            var root = this.$parent.$parent

            // 사용자 정보조회
            root.getUserInfo()

            // 화면권한 조회
            root.getAuthInfo()

            this.Navigator = this.$store.getters.getNavigator
            this.PageHeader = this.$store.getters.getPageHeader
            this.IsFavorite = this.$store.getters.getIsFavorite
        },
        methods: {
            // 즐겨찾기 메뉴 저장
            saveFavoritesMenu: function () {

                var root = this.$parent.$parent
                var parent = this

                if (root.ProgramId === null || root.MenuId1 === null || root.MenuId2 === null
                    || root.ProgramId === '' || root.MenuId1 === '' || root.MenuId2 === '') {

                    alert('메뉴에 등록되지 않은 회면은 즐겨찾기 할 수 없습니다.')
                    return
                }
                
                var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/SaveFavoritesMenu'.toRealServiceUrl()
                var obj = new Object()

                obj.jsonString = JSON.stringify({
                    'Arg': parent.IsFavorite == 'Y' ? 'D' : 'I',
                    'UserId': root.EmpId,
                    'CorpCd': root.CompanyCode,
                    'SystId': root.SystemId,
                    'EmpNo': root.EmpId,
                    'BookMarkId': root.ProgramId,
                    'BookMarkName': parent.PageHeader,
                    'PrntBookMarkId': '0',
                    'LevevlNo': '1',
                    'MenuYN': 'N'
                })

                var successCallback = function (data) {

                    if (data.d > 0) {

                        parent.IsFavorite = parent.IsFavorite === 'N' ? 'Y' : 'N'

                        if (parent.IsFavorite === 'Y') {

                            alert('즐겨찾기에서 등록 되었습니다.')
                        }
                        else {

                            alert('즐겨찾기에서 삭제 되었습니다.')
                        }

                        // 즐겨찾기 메뉴 목록 재조회
                        root.getFavoritesMenu()
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback, false);
            }
        }
    }
</script>

<style scoped>
</style>