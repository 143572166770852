﻿export default {
    state: {
        userId: null,
        userName: null,
        positionName: null,
        emailAddress: null,
        companyCode: null,
        empId: null,
        authGrade: null,
        userGrpId: null,
    },
    mutations: {
        SET_USER_ID: function (state, userId) {
            state.userId = userId
        },
        SET_USER_NAME: function (state, userName) {
            state.userName = userName
        },
        SET_POSITION_NAME: function (state, positionName) {
            state.positionName = positionName
        },
        SET_EMAIL_ADDRESS: function (state, emailAddress) {
            state.emailAddress = emailAddress
        },
        SET_COMPANY_CODE: function (state, companyCode) {
            state.companyCode = companyCode
        },
        SET_EMP_ID: function (state, empId) {
            state.empId = empId
        },
        SET_AUTH_GRADE: function (state, authGrade) {
            state.authGrade = authGrade
        },
        SET_USER_GRP_ID: function(state, userGrpId) {
            state.userGrpId = userGrpId
        },
        SET_USER_ICON: function(state, iconPhoto) {
            state.iconPhoto = iconPhoto
        },
    },
    actions: {
        SetUserInfo({ commit, state }, userInfo) {
            return new Promise(function (resolve, reject) {
                commit('SET_USER_ID', userInfo.UserId)
                commit('SET_USER_NAME', userInfo.EmpName)
                commit('SET_POSITION_NAME', userInfo.PositionName1)
                commit('SET_EMAIL_ADDRESS', userInfo.Email)
                commit('SET_COMPANY_CODE', userInfo.CompanyCode)
                commit('SET_EMP_ID', userInfo.EmpId)
                commit('SET_AUTH_GRADE', userInfo.AuthGrade)
                commit('SET_USER_GRP_ID', userInfo.UserGrpId)
                commit('SET_USER_ICON', userInfo.IconPhoto)
            })
        }
    }
}