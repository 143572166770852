<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle>인력투입계획</ViewTitle>
        <!---->
        <div class="content-box">
            <div class="btn-wrap">
                <a v-show="this.$parent.CanSearch" href="#" id="btnListSrch" class="btn btn-primary btn-row2" @click.prevent="btnSearchClicked">
                    <span>
                        <i class="glyphicon glyphicon-search"></i> 조회
                    </span>
                </a>
                <a v-if="this.$parent.CanNew" href="#" id="btnListInst" class="btn btn-default" @click.prevent="btnAddClicked">
                    <span>
                        <i class="glyphicon glyphicon-plus"></i> 추가
                    </span>
                </a>
                <!-- 삭제 버튼 추가 -->
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="cols-wrap">
                                <div class="cols col-5">
                                    <label for="selTrgtYY" class="control-label">
                                        <span>년도</span>
                                    </label>
                                    <select class="form-control" ref="selTrgtYY" name="selTrgtYY" v-model="BusinessSelectTerm.TargetYear">
                                        <option v-for="year in YearsList" v-bind:value="year">
                                            {{year}}
                                        </option>
                                    </select>
                                </div>
                                <div class="cols col-2">
                                    <label for="txtBizNm" class="control-label">
                                        <span> 영업명 </span>
                                    </label>
                                    <div class="cols-btns-cont pad-r150">
                                        <input type="text" id="txtBizNm" class="form-control txt" disabled="disabled" v-model="BusinessSelectTerm.BizNm" />
                                    </div>
                                    <div class="cols-btns">
                                        <button type="button" id="btnSaleBizPop" class="btn btn-default" @click="searchBusiness">
                                            <span>
                                                <i class="glyphicon glyphicon-search"></i> 검색
                                            </span>
                                        </button>
                                        <button type="button" id="btnSaleReset" class="btn btn-default" @click="resetBusiness">
                                            <span>
                                                <i class="glyphicon glyphicon-repeat"></i> 초기화
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div class="cols col-4">
                                    <label for="txtProjectNm" class="control-label" style="text-align:center">
                                        <span>진행상황</span>
                                    </label>
                                    <select class="form-control" id="selStatusCd" name="selStatusCd"
                                            v-model="BusinessSelectTerm.DvsCd">
                                        <option value="">== 전체 ==</option>
                                        <option v-for="item in ProgressList" v-bind:value="item.Code">
                                            {{item.Name}}
                                        </option>
                                    </select>
                                </div>
                            </section>
                        </fieldset>
                    </form>
                </div>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">투입인력계획</p>
                </div>
                <div>
                    <!--리스트 영역-->
                    <section class="tbl-contents">
                        <div class="table-responsive">
                            <!--<DxDataGrid ref="grdList" :data-source="DataSource" :columns="DefaultColumns" @row-click="rowClick">
                            </DxDataGrid>-->

                            <DxDataGrid id="grid-container"
                                        :show-borders="false"
                                        :show-row-lines="true"
                                        :data-source="BusinessList"
                                        :hoverStateEnabled="true"
                                        @cell-prepared="onCellPrepared">
                                <DxLoadPanel :enabled="false" />
                                <DxScrolling mode="infinite" />
                                <DxSorting mode="none" />
                                <DxColumn cell-template="detail" width="5%" alignment="center" />
                                <template #detail="{ data }">
                                    <DxButton icon="chevronnext" styling-mode="text" @click="onCollapseClick(data)" />
                                </template>
                                <DxColumn data-field="CompanyNm"
                                          caption="업체"
                                          width="20%" />
                                <DxColumn data-field="TotalMM"
                                          caption="MM"
                                          alignment="center"
                                          width="5%" />
                                <DxColumn data-field="BizNm"
                                          caption="영업명"
                                          width="45%" />
                                <DxMasterDetail :enabled="false"
                                                template="masterDetailTemplate" />
                                <template #masterDetailTemplate="{ data }">
                                    <BZ1010R_Detail :template-data="data" />
                                </template>
                            </DxDataGrid>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <CP1080P ref="CP1080P"></CP1080P>
        <BZ1011E ref="BZ1011E"></BZ1011E>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle'
    import CP1080P from '@/components/libs/popup/CP1080P'
    import BZ1011E from '@/components/bz/popup/BZ1011E'
    import BZ1010R_Detail from '@/components/bz/libs/BZ1010R_Detail'

    //import EventBus from 'EventBus';

    import { DxDataGrid, DxColumn, DxMasterDetail, DxScrolling, DxSorting, DxLoadPanel } from 'devextreme-vue/data-grid'
    import { DxButton } from 'devextreme-vue'

    export default {
        name: 'BZ1010R',
        components: {
            ViewTitle, CP1080P, BZ1011E, BZ1010R_Detail, DxDataGrid, DxColumn, DxMasterDetail, DxScrolling, DxSorting, DxLoadPanel, DxButton
        },
        data() {
            return {
                Condition: {
                    CorpCd: this.$parent.CompanyCode
                    , BizNm: null
                    , BizNo: null
                    , CompanyNm: null
                    , CompanyCd: null
                    , TargetYear: this.moment(new Date()).year()
                }
                // 조회조건 데이터 객체
				, BusinessSelectTerm: {
                    Arg: 'M'
                    , UserId: this.$parent.EmpId
                    , CorpCd: this.$parent.CompanyCode
                    , BizNm: null
                    , BusinessNo: null
                    , CompanyNm: null
                    , CompanyCode: null
                    , DvsCd: '0723020'								// 진행상황
                    , SrchText: null								// 영업명
                    , TargetYear: this.moment(new Date()).year()	// 년도
                }
                , DataCount: 0
                , DataSource: new Array()
                , DefaultColumns: [
                    { caption: "역할", dataField: "RoleNm" },
                    { caption: "인력명", dataField: "RoleEmpNm" },
                    { caption: "비고", dataField: "RoleDesc" }
                ]
                , YearsList: new Array()                            // 년도 콤보박스 목록
                , BusinessList: new Array()				            // 조회된 영업관리 리스트
                , ProgressList: null								// 진행상황 공통코드 리스트
            }
        },
        created() {
            var that = this

            that.$EventBus.$off('BZ1010R_OPEN')
            that.$EventBus.$on('BZ1010R_OPEN', (row) => {
                that.BusinessSelectTerm.BusinessNo = row.BizNo
                that.BusinessSelectTerm.BizNm = row.BizNm
                that.BusinessSelectTerm.CompanyNm = row.CompanyNm
                that.BusinessSelectTerm.CompanyCode = row.CompanyCd

                /*that.searchBusinessMMPlan()*/
            })
        },
        beforeMount() {

            this.ProgressList = $.scriptBase.getCommonCode('CODE000', '0723000', '', '') // 진행상황

            var startYear = this.moment(new Date()).add(1, 'year').year()
            var endYear = this.moment(new Date(2013, 1, 1, 0, 0, 0)).year()

            // 대상년도 목록 값 설정
            for (var i = startYear; i >= endYear; i--) {

                this.YearsList.push(i)
            }
        },
        methods: {
            // datagrid header 가운데 정렬, column 중앙 정렬
            onCellPrepared(e) {
                if (e.rowType == 'header') {
                    e.cellElement.style['textAlign'] = 'center'
                }
                if (e.rowType == 'data') {
                    e.cellElement.style['verticalAlign'] = 'middle'
                }
            }
            // 마스터 그리드 선택 이벤트
            , onCollapseClick(e) {
                this.clicked = !this.clicked;
                var expanded = e.component.isRowExpanded(e.row.key)
                if (expanded) {
                    e.component.collapseAll(-1);
                }
                else {
                    e.component.collapseAll(-1);
                    e.component.expandRow(e.row.key);
                }
            }
            , btnSearchClicked: function (e) {

                //this.searchBusinessMMPlan()
                this.getBusiness()

            },
            btnAddClicked: function (e) {
                var that = this

                if (that.BusinessSelectTerm.BusinessNo) {
                    that.Condition.CorpCd = that.BusinessSelectTerm.CorpCd
                    that.Condition.BizNm = that.BusinessSelectTerm.BizNm
                    that.Condition.BizNo = that.BusinessSelectTerm.BusinessNo
                    that.Condition.CompanyNm = that.BusinessSelectTerm.CompanyNm
                    that.Condition.CompanyCd = that.BusinessSelectTerm.CompanyCode
                    that.Condition.TargetYear = that.BusinessSelectTerm.TargetYear
                }

                var successCallback = function (result) {
                    /*that.searchBusinessMMPlan()*/
                    that.getBusiness()
                }

                that.$refs.BZ1011E.open(successCallback, that.Condition, null)
            },
            // 영업 검색
            searchBusiness: function () {

                var that = this

                var successCallback = function (result) {

                    that.BusinessSelectTerm.BizNm = result.BizNm
                    that.BusinessSelectTerm.BusinessNo = result.BizNo

                    // 영업명 선택 시 고객사 자동 설정
                    that.BusinessSelectTerm.CompanyNm = result.CompanyNm
                    that.BusinessSelectTerm.CompanyCode = result.CompanyCd
                }

                that.$refs.CP1080P.open(successCallback, that.BusinessSelectTerm.CompanyCode, that.BusinessSelectTerm.CompanyNm)
            },
            resetBusiness: function () {
                var that = this

                that.BusinessSelectTerm.BizNm = null
                that.BusinessSelectTerm.BusinessNo = null

                // 영업명 선택 시 고객사 자동 설정
                that.BusinessSelectTerm.CompanyNm = null
                that.BusinessSelectTerm.CompanyCode = null

                // 그리드 초기화 호출
                that.resetColumns()
                that.DataSource = null
                that.DataCount = 0

            }
            // 업체 영업 목록 조회
            , getBusiness: function () {

                var that = this

                var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/GetBusinessHistory'
                url = url.toRealServiceUrl()
                var obj = new Object()

                this.BusinessSelectTerm.BusinessNo = null

                obj.jsonString = JSON.stringify(this.BusinessSelectTerm)

                var ajaxSuccessHandler = function (data) {
                    var jsonObj = JSON.parse(data.d)

                    // 확장여부 값 추가
                    jsonObj.Table.forEach(function (item) {

                        item.IsExpand = false
                    })

                    that.BusinessList = jsonObj.Table

                    // 매입상품 목록 전체 건수
                    that.TotalCount = jsonObj.Table1[0].TotCnt
                    that.BusinessHistoryListAll = jsonObj.Table2

                    // 영업관리 이력 파일 전체 목록
                    that.BusinessHistoryFileListAll = jsonObj.Table3
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
            }
            , searchBusinessMMPlan: function () {
                var that = this
                //if (!that.BusinessSelectTerm.BusinessNo) {
                //    alert('영업을 선택해 주세요.')
                //    return
                //}
                // 조회
                var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/GetBusinessMMPlanList'
                url = url.toRealServiceUrl()
                var obj = new Object()
                obj.jsonString = JSON.stringify(that.BusinessSelectTerm)

                var ajaxSuccessHandler = function (data) {
                    var jsonObj = JSON.parse(data.d)

                    that.resetColumns()

                    if (!jsonObj.Table) {
                        jsonObj.Table = new Array()
                    }

                    that.DataSource = jsonObj.Table
                    // 그리드 바인딩
                    that.DataCount = jsonObj.Table.length

                    var columnsCount = that.DataCount > 0 ? Object.keys(jsonObj.Table[0]).length : 0

                    for (var i = 0; i < columnsCount - 8; i++) {
                        var columnName = Object.keys(jsonObj.Table[0])[i]
                        var newColumn = { 'caption': columnName, 'dataField': columnName }
                        that.DefaultColumns.push(newColumn)
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
            },
            resetColumns: function () {
                var cnt = this.DefaultColumns.length

                for (var i = 3; i < cnt; i++) {
                    this.DefaultColumns.splice(3, 1)
                }
            },
            rowClick: function (e) {
                var that = this
                if (e.rowType === "data") {
                    var successCallback = function (result) {
                        /* that.searchBusinessMMPlan()*/
                        that.getBusiness()
                    }

                    this.$refs.BZ1011E.open(successCallback, this.BusinessSelectTerm, e.data)
                }
            }
        }
    }

</script>
