﻿export default {
    state: {
        navigator: null,
        title: null,
        pageHeader: null,
        isFavorite: null
    },
    mutations: {
        SET_NAVIGATOR: function (state, navigator) {
            state.navigator = navigator
        },
        SET_TITLE: function (state, title) {
            state.title = title
        },
        SET_PAGE_HEADER: function (state, pageHeader) {
            state.pageHeader = pageHeader
        },
        SET_IS_FAVORITE: function (state, isFavorite) {
            state.isFavorite = isFavorite
        }
    },
    actions: {
        SetNavigator({ commit, state }, navigatorInfo) {
            return new Promise(function (resolve, reject) {
                commit('SET_NAVIGATOR', navigatorInfo.Navigator)
            })
        },
        SetIsFavorite({ commit, state }, favoriteInfo) {
            return new Promise(function (resolve, reject) {
                commit('SET_IS_FAVORITE', favoriteInfo.IsFavorite)
            })
        },
        SetViewInfo({ commit, state }, viewInfo) {
            return new Promise(function (resolve, reject) {
                commit('SET_TITLE', viewInfo.Title)
                commit('SET_PAGE_HEADER', viewInfo.PageHeader)
            })
        }
    }
}