<template>
    <!--영업 이력 추가 상세-->
    <div class="modal fade" id="PM1081E_Modal" ref="PM1081E_Modal" tabindex="-1" role="dialog" aria-labelledby="PM1081E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" @click.Prevent="onContractFileDetailclose">
                        <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                    </button>
                    <h3 v-if="!isDetail" class="modal-title">계약서 등록</h3>
                    <h3 v-else class="modal-title">계약서 상세</h3>
                </div>
                <div class="modal-body">
                    <section class="add-wrap">
                        <form>
                            <fieldset>
                                <legend></legend>
                                <section class="cols-wrap">
                                    <div class="cols col-12">
                                        <label for="txtCompanyNm" class="control-label">
                                            <span><i class="glyphicon glyphicon-asterisk"></i>프로젝트</span>
                                        </label>
                                        <div class="cols-btns-cont pad-r150">
                                            <input type="text" class="form-control" readonly="readonly" v-model="contractDetail.projectNm" />
                                        </div>
                                        <div class="cols-btns" v-show="!isDetail">
                                            <a href="#" class="btn btn-default" @click.prevent="onProjectSerchClick()">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i> 검색
                                                </span>
                                            </a>
                                            <a href="#" class="btn btn-default" @click.prevent="onProjectInitClick()">
                                                <span>
                                                    <i class="glyphicon glyphicon-repeat"></i> 초기화
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-12">
                                        <label class="control-label">
                                            <span><i class="glyphicon glyphicon-asterisk"></i>계약서명</span>
                                        </label>
                                        <input type="text" class="form-control txt" v-model="contractDetail.contractFileNm" />
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label class="control-label">
                                            <span>계약업체</span>
                                        </label>
                                        <input type="text" class="form-control txt" v-model="contractDetail.contractCompanyNm" readonly="readonly" />
                                    </div>
                                    <div class="cols col-2">
                                        <label class="control-label">
                                            <span>계약업체 담당자</span>
                                        </label>
                                        <input type="text" class="form-control txt" v-model="contractDetail.contractCompanyMgrNm" readonly="readonly" />
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-12">
                                        <label class="control-label">
                                            <span>비고</span>
                                        </label>
                                        <input type="text" class="form-control txt" v-model="contractDetail.contractFileRemark" />
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols">
                                        <label for="txtEduTitle" class="control-label">
                                            <span><i class="glyphicon glyphicon-asterisk"></i>계약서 서류</span>
                                        </label>
                                    </div>
                                    <div class="fr">
                                        <a href="#" class="btn btn-default" title="추가" @click.prevent="onAddContractFileClick('0737010')">
                                            <span class="glyphicon glyphicon-plus"></span> 추가
                                        </a>
                                        <a href="#" class="btn btn-default" title="삭제" @click.prevent="removeAttachFile('0737010')">
                                            <span class="glyphicon glyphicon-minus"></span> 삭제
                                        </a>
                                    </div>
                                </section>
                                <section class="tbl-contents">
                                    <div class="table-responsive">
                                        <form id="form5" action="" name="form5" method="post" enctype="multipart/form-data">
                                            <table class="table tbl-list table-hover table-bordered" id="tb5">
                                                <colgroup>
                                                    <col class="chk" />
                                                    <col />
                                                </colgroup>
                                                <thead>
                                                    <tr>
                                                        <th class="chk table-colwid5">선택</th>
                                                        <th scope="col" class="text-center">첨부파일</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-if="contractFileList === null">
                                                        <td colspan="2" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                    </tr>
                                                    <tr v-else v-for="ContractFile in contractFileList" v-show="ContractFile.IsShow && ContractFile.FileDivision == '0737010'" >
                                                        <td class="chk">
                                                            <label>
                                                                <input type="checkbox" name="cbItem2" @click="checkBoxCheckEvent($event.target.checked, ContractFile)" />
                                                            </label>
                                                        </td>
                                                        <td v-show="ContractFile.IsNew" class="text-center">
                                                            <input type="file" name="ctlAttachFile"
                                                                   @change="function(e){ ContractFile.Files = e.target.files }" />
                                                        </td>
                                                        <td v-show="!ContractFile.IsNew" class="text-left">
                                                            <a href="#" @click.prevent="fileHandler.soliDocsDownload(ContractFile.DocID)">{{ContractFile.FileName}}</a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols">
                                        <label for="txtEduTitle" class="control-label">
                                            <span>관련 서류</span>
                                        </label>
                                    </div>
                                    <div class="fr">
                                        <a href="#" class="btn btn-default" title="추가" @click.prevent="onAddContractFileClick('0737020')">
                                            <span class="glyphicon glyphicon-plus"></span> 추가
                                        </a>
                                        <a href="#" class="btn btn-default" title="삭제" @click.prevent="removeAttachFile('0737020')">
                                            <span class="glyphicon glyphicon-minus"></span> 삭제
                                        </a>
                                    </div>
                                </section>
                                <section class="tbl-contents">
                                    <div class="table-responsive">
                                        <form id="form5" action="" name="form5" method="post" enctype="multipart/form-data">
                                            <table class="table tbl-list table-hover table-bordered" id="tb5">
                                                <colgroup>
                                                    <col class="chk" />
                                                    <col />
                                                </colgroup>
                                                <thead>
                                                    <tr>
                                                        <th class="chk table-colwid5">선택</th>
                                                        <th scope="col" class="text-center">첨부파일</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-if="contractFileList === null">
                                                        <td colspan="2" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                    </tr>
                                                    <tr v-else v-for="ContractFile in contractFileList" v-show="ContractFile.IsShow && ContractFile.FileDivision == '0737020'">
                                                        <td class="chk">
                                                            <label>
                                                                <input type="checkbox" name="cbItem2" @click="checkBoxCheckEvent($event.target.checked, ContractFile)" />
                                                            </label>
                                                        </td>
                                                        <td v-show="ContractFile.IsNew" class="text-center">
                                                            <input type="file" name="ctlAttachFile"
                                                                   @change="function(e){ ContractFile.Files = e.target.files }" />
                                                        </td>
                                                        <td v-show="!ContractFile.IsNew" class="text-left">
                                                            <a href="#" @click.prevent="fileHandler.soliDocsDownload(ContractFile.DocID)">{{ContractFile.FileName}}</a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                    </div>
                                </section>
                            </fieldset>
                        </form>
                    </section>
                    <section class="btn-align">
                        <a v-show="this.$parent.$parent.CanSave" href="#" class="btn btn-primary" @click.prevent="onSaveContractFile">
                            <span>
                                <i class="glyphicon glyphicon-save"></i> 저장
                            </span>
                        </a>
                        <a v-show="this.$parent.$parent.CanDelete && isDetail" href="#" class="btn btn-warning" @click.prevent="onRemoveDetailClick">
                            <span>
                                <i class="glyphicon glyphicon-edit"></i> 삭제
                            </span>
                        </a>
                        <a href="#" class="btn btn-danger" @click.Prevent="onContractFileDetailclose">
                            <span>
                                <i class="glyphicon glyphicon-remove"></i> 취소
                            </span>
                        </a>
                    </section>
                </div>
            </div>
        </div>
        <CP1060P ref="CP1060P"></CP1060P>
    </div>
</template>

<script>
    //DevExtreme Componet
    import { DxDateBox } from 'devextreme-vue'
    import { DxHtmlEditor, DxToolbar, DxMediaResizing, DxItem } from 'devextreme-vue/html-editor'

    // Modal
    import CP1060P from '@/components/libs/popup/CP1060P'

    export default {
        name: 'PM1081E'
        , components: {
            DxDateBox
            , DxHtmlEditor
            , DxToolbar
            , DxMediaResizing
            , DxItem
            , CP1060P
        }
        , data() {
            return {
                CallbackFunction: null
                , contractDetail: {                 // 계약서 상세 정보
                    projctCd: null                  // 프로젝트 코드
                    , projectNo: null               // 프로젝트 번호
                    , projectNm: ''                 // 프로젝트 명
                    , contractCompanyNm: ''         // 계약업체 명
                    , contractCompanyMgrNm: ''      // 계약업체 담당자 명
                    , contractFileNm: ''            // 계약서 명
                    , contractFileRemark: ''        // 계약서 비고
                    , contractNo: ''                // 계약번호
                    , contractSeqNo: ''             // 계약차수
                    , companyCd: null               // 업체코드
                    , userNo: ''                    // 사용자No
                    , email:''                      // 사용자 email
                }
                , contractFileList: new Array()     // 계약서 파일, 관련서류 파일
                , removeAttachFileList: new Array()        // 첨부파일 삭제 목록
                , isContractFile: false             // 계약서 파일 여부
                , isDetail: false                   // 상세보기 여부
            }
        }
        , beforbeforeMount() {
            
        }
        , methods: {
            //#region init
            initProject() {
                this.contractDetail.projctCd = null
                this.contractDetail.projectNo = null
                this.contractDetail.projectNm = ''
                this.contractDetail.contractFileNm = ''
                this.contractDetail.contractCompanyNm = ''
                this.contractDetail.contractCompanyMgrNm = ''
                this.contractDetail.contractNo = ''
                this.contractDetail.contractSeqNo = ''
                this.contractDetail.companyCd = null
                this.contractDetail.contractFileRemark = ''
                this.contractFileList = new Array()
                this.removeAttachFileList = new Array() 
                this.isContractFile = false
                this.isDetail = false
            }
            //#endregion

            //#region Service
            , getProjectFileInfo() {
                var that = this

                var ContractSelectTerm = {
                      ProjectCd: this.contractDetail.projctCd
                    , ProjectNo: parseInt(this.contractDetail.projectNo)
                }

                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/GetProjectFileDetail'
                url = url.toRealServiceUrl()
                var obj = new Object()

                obj.jsonString = JSON.stringify(ContractSelectTerm)

                var ajaxSuccessHandler = function (data) {
                    var jsonObj = JSON.parse(data.d)

                    that.contractDetail.projctCd = jsonObj.Table[0].ProjectCd
                    that.contractDetail.projectNo = jsonObj.Table[0].ProjectNo
                    that.contractDetail.projectNm = jsonObj.Table[0].ProjectNm
                    that.contractDetail.contractCompanyMgrNm = jsonObj.Table[0].ContractCompanyMgrNm
                    that.contractDetail.contractCompanyNm = jsonObj.Table[0].ContractCompanyNm
                    that.contractDetail.contractSeqNo = jsonObj.Table[0].ContractSeqNo
                    that.contractDetail.contractNo = jsonObj.Table[0].ContractNo
                    that.contractDetail.contractFileNm = jsonObj.Table[0].ContractFileName
                    that.contractDetail.contractFileRemark = jsonObj.Table[0].FileDesc
                    that.contractDetail.companyCd = jsonObj.Table[0].CompanyCd

                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
            }
            , getContractFiles() {	// 계약상세 첨부파일 조회
                var that = this

                var ContractSelectTerm = {
                    Arg: 'M'
                    , ProjectCd: this.contractDetail.projctCd 
                    , ProjectNo: parseInt(this.contractDetail.projectNo)
                    , Year: ''
                    , FileDivision: ''
                    , Text: ''
                }

                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/GetProjectFileList'
                url = url.toRealServiceUrl()
                var obj = new Object()

                obj.jsonString = JSON.stringify(ContractSelectTerm)

                var ajaxSuccessHandler = function (data) {
                    var jsonObj = JSON.parse(data.d)
                    
                    if (that.isDetail) {
                        that.contractFileList = jsonObj.Table

                        var isExistContractFile = that.contractFileList.some(function (file) { // 계약서 파일이 존재하는지 체크
                            return file.FileDivision == '0737010'
                        })

                        if (isExistContractFile) {
                            that.isContractFile = true
                        }
                        else {
                            that.isContractFile = false
                        }

                        for (var i = 0; i < that.contractFileList.length; i++) {
                            that.contractFileList[i].IsChecked = false
                            that.contractFileList[i].IsNew = false
                            that.contractFileList[i].IsRemove = false
                            that.contractFileList[i].IsShow = true
                        }
                    }

                    if (!that.isDetail && jsonObj.Table.length > 0) { // 생성 화면일때 Table의 count가 0보다 크면 생성 불가능
                        alert("이미 계약서가 등록되어있는 프로젝트입니다. 다른 프로젝트를 선택해주세요.")
                        that.onProjectInitClick()
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
            }
            , setContractFile(uploadFiles) {   // 계약상세 첨부파일 저장

                var that = this
                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/SaveContractFileDetail'
                url = url.toRealServiceUrl()
                var attachFileList = new Array()
                var division = ""

                if (this.contractDetail.projctCd == null || this.contractDetail.projctCd == undefined || this.contractDetail.projctCd == '') {
                    alert('프로젝트를 선택해주세요.')
                    return false
                }

                if (this.contractDetail.contractFileNm == null || this.contractDetail.contractFileNm == undefined || this.contractDetail.contractFileNm == '') {
                    alert('계약서명을 입력해주세요.')
                    return false
                }

                if (this.contractFileList.length == 0) {
                    alert('계약서 파일을 선택해주세요.')
                    return false
                }

                var obj = new Object()
                obj.jsonString = JSON.stringify(that.contractDetail)

                if (uploadFiles != null) {
                    uploadFiles.files.forEach(function (object) {

                        for (var i = 0; i < that.contractFileList.length; i++) {
                            if (object.oFileName == that.contractFileList[i].FileName) {
                                division = that.contractFileList[i].FileDivision
                            }
                        }

                        attachFileList.push({
                            'CompanyCd': that.contractDetail.companyCd
                            , 'ProjectCd': that.contractDetail.projctCd
                            , 'ProjectNo': that.contractDetail.projectNo
                            , 'ContractNo': that.contractDetail.contractNo
                            , 'ContractSeqNo': that.contractDetail.contractSeqNo
                            , 'GUID': object.nFileName
                            , 'FileName': object.oFileName
                            , 'FileDivision': division
                            , 'DocID': ''
                            , 'EmpNo': that.contractDetail.userNo
                        })
                    })
                }

                obj.jsonFileString = JSON.stringify(attachFileList)

                var ajaxSuccessHandler = function (data) {
                    alert('저장되었습니다.')
                    that.onContractFileDetailclose()
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
            }
            , removeDetail() {
                if (confirm(this.contractDetail.contractFileNm + " 해당 계약서 상세를 삭제하시겠습니까?") == true) {

                    var that = this
                    var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/RemoveContractDetail'
                    url = url.toRealServiceUrl()

                    var obj = new Object()
                    var removeInfo = {
                        ProjectCd: this.contractDetail.projctCd
                        , ProjectNo: parseInt(this.contractDetail.projectNo)
                    }
                    obj.jsonString = JSON.stringify(removeInfo)
                    obj.jsonFileString = JSON.stringify(that.contractFileList)

                    var ajaxSuccessHandler = function (data) {
                        alert('삭제되었습니다.')
                        that.onContractFileDetailclose()
                    }

                    $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)

                } else {
                    return
                }
            }
            //#endregion

            //#region ButtonClick
            , onProjectSerchClick() {                   // 프로젝트 조회 클릭
                this.onProjectModalOpen()
            }
            , onProjectInitClick() {                    // 프로젝트 초기화 클릭
                this.initProject()
            }
            , onAddContractFileClick(type) {            // 첨부파일 추가 클릭
                this.addAttachFile(type)
            }
            , onSaveContractFile() {                    // 첨부파일 저장
                this.checkFileList()
            }
            , onRemoveDetailClick() {                   // 계약서 상세 삭제 클릭
                this.removeDetail()
            }
            //#endregion

            //#region Event
            
            , checkBoxCheckEvent(IsChecked, item) { // 첨부파일 체크박스 메서드
                if (IsChecked) {
                    item.IsChecked = true
                    this.removeAttachFileList.push(item)
                }
                else {

                    var removeIndex = this.removeAttachFileList.indexOf(item)

                    if (removeIndex > -1) {

                        this.removeAttachFileList.splice(removeIndex, 1)
                    }
                }
            }
            , addAttachFile: function (division) { // 첨부파일 추가

                if (this.contractFileList === null || this.contractFileList === '') {

                    this.contractFileList = new Array()
                }

                else {
                    var isExistContractFile = this.contractFileList.some(function (file) {
                        return file.FileDivision == '0737010'
                    })

                    if (division == '0737010' && isExistContractFile) {
                        alert("계약서 파일은 한개만 등록이 가능합니다.")
                        return false
                    }
                }

                this.contractFileList.push({ // 신규파일 추가
                    IsNew: true
                    , IsRemove: false
                    , IsShow: true
                    , IsChecked: false
                    , Files: null
                    , CorpCd: null
                    , CompanyCd: null
                    , BizNo: null
                    , SeqNo: null
                    , FileSeq: null
                    , FileName: null
                    , GUID: null
                    , FileDivision: division
                    , DocId: null
                })
            }
            , removeAttachFile: function () { // 첨부파일 삭제

                var that = this
                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/RemoveContractDetailFile'
                url = url.toRealServiceUrl()
                var obj = new Object()

                if (that.removeAttachFileList.length > 0) {
                    if (confirm('선택한 파일을 삭제 하시겠습니까?')) {
                        var removefilelist = new Array()

                        that.removeAttachFileList.forEach(function (item) {
                            var removeIndex = that.contractFileList.indexOf(item)

                            if (removeIndex > -1) {
                                that.contractFileList[removeIndex].IsShow = false
                            }

                            // DB에서 삭제 할 파일 목록 추가
                            // 선택한 파일 삭제 플래그 변경
                            item.IsRemove = item.IsChecked

                            // 기존파일은 db에서 삭제하기위해 별도로 모음
                            if (!item.IsNew && item.IsChecked === true) {

                                item.Arg = 'cd'
                                removefilelist.push(item)
                            }
                        })
                        if (removefilelist.length > 0) {

                            obj.jsonFilrString = JSON.stringify(removefilelist)

                            var successCallback = function (data) {

                                //if (data.d > 0) {

                                //    //parent.fn_ShowData(true)
                                //}
                                that.getContractFiles()
                            }

                            $.scriptBase.executeAjaxAsync(url, obj, successCallback)
                        }
                        // 첨부파일 삭제 목록 변수 초기화
                        this.removeAttachFileList = new Array()

                        // 선택 체크박스 모두 해제
                        document.querySelectorAll('input[type=checkbox][name=cbItem2]').forEach(function (item) {

                            item.checked = false
                        })

                        // 첨부파일 목록이 없으면 변수 초기화
                        if (that.contractFileList.length === 0) {

                            that.contractFileList = null
                        }
                    }
                }
            }
            , checkFileList() {
                var that = this

                var successUploadFileCallback = function (result) {

                    that.setContractFile(result)
                }

                if (this.contractFileList != null && this.contractFileList != '') {

                    var isExistNewFile = this.contractFileList.some(function (file) { // 신규 파일이 존재하는지 체크
                        return file.IsNew
                    })

                    var newFileList = new Array()

                    if (isExistNewFile) {

                        var isValidateFiles = true

                        for (var i = 0; i < this.contractFileList.length; i++) {

                            var file = this.contractFileList[i]

                            if (file.IsNew && !file.IsRemove) {

                                if (file.Files != null) {

                                    for (var j = 0; j < file.Files.length; j++) {

                                        file.FileName = file.Files[j].name
                                        newFileList.push(file.Files[j])
                                    }
                                }
                                else {

                                    alert('첨부파일을 선택하세요.')

                                    isValidateFiles = false
                                    break
                                }
                            }
                        }

                        if (isValidateFiles) {

                            this.fileHandler.SoliDocsupload(newFileList, successUploadFileCallback)
                        }
                    }
                    else {
                        that.setContractFile(null)
                    }
                }
                else {

                    that.setContractFile(null)
                }
            }
            //#endregion

            //#region Modal
            , onContractFileDetailOpen: function (callbackFunction, type, userId, email, projectcd, projectno) {
                this.CallbackFunction = callbackFunction
                if (type == 'new') {
                    this.isDetail = false
                    this.contractDetail.userNo = userId
                    this.contractDetail.email = email
                    this.initProject()
                }
                else {
                    this.isDetail = true
                    this.contractDetail.userNo = userId
                    this.contractDetail.email = email
                    this.contractDetail.projctCd = projectcd
                    this.contractDetail.projectNo = projectno

                    this.getProjectFileInfo()
                    this.getContractFiles()
                }

                $("#PM1081E_Modal").modal("show")
            }
            , onContractFileDetailclose: function () {
                this.initProject()
                this.CallbackFunction('close')
                $("#PM1081E_Modal").modal("hide")
            }
            , reset: function () {
                Object.assign(this.$data, this.$options.data())
            }
            , onProjectModalOpen() {                // 프로젝트 조회 ModalOpen
                var that = this

                var successCallback = function (result) {

                    that.contractDetail.projctCd = result.ProjectCd
                    that.contractDetail.projectNo = result.ProjectNo
                    that.contractDetail.projectNm = result.ProjectNm
                    that.contractDetail.contractFileNm = result.ProjectNm
                    that.contractDetail.contractCompanyNm = result.ContractCompanyNm
                    that.contractDetail.contractCompanyMgrNm = result.ContractCompanyMgrNm
                    that.contractDetail.contractNo = result.ContractNo
                    that.contractDetail.contractSeqNo = result.ContractSeqNo
                    that.contractDetail.companyCd = result.CompanyCd

                    that.getContractFiles()
                }

                this.$refs.CP1060P.open(successCallback, null, null, true)
            }
            //#endregion
        }
    }
</script>