<template>
    <!--영업 이력 추가 상세-->
    <div class="modal fade" id="BZ1011E_Modal" ref="BZ1011E_Modal" tabindex="-1" role="dialog" aria-labelledby="BZ1011E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">
                        <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                    </button>
                    <h3 class="modal-title">인력 추가</h3>
                </div>
                <div class="modal-body">
                    <section class="add-wrap">
                        <form>
                            <fieldset>
                                <legend></legend>
                                <section class="cols-wrap">
                                    <div class="cols col-1" v-show="IsNew">
                                        <label for="txtCompanyNm" class="control-label">
                                            <span>영업명</span>
                                        </label>
                                        <div class="cols-btns-cont pad-r150">
                                            <input type="text" id="txtProjectNm" class="form-control" readonly="readonly" v-model="BusinessSelectTerm.BizNm" />
                                        </div>
                                        <div class="cols-btns">
                                            <button type="button" id="btnSaleBizPop" class="btn btn-default" @click="searchBusiness">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i> 검색
                                                </span>
                                            </button>
                                            <button type="button" id="btnSaleReset" class="btn btn-default" @click="resetBusiness">
                                                <span>
                                                    <i class="glyphicon glyphicon-repeat"></i> 초기화
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtRoleEmpNm" class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>투입 인력
                                            </span>
                                        </label>
                                        <input type="text" id="txtRoleEmpNm" class="form-control txt" v-model="BusinessMMPlan.RoleEmpNm" />
                                    </div>
                                    <div class="cols col-2">
                                        <label for="cbxRoleCd" class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>역할
                                            </span>
                                        </label>
                                        <!-- 공통 코드 적용 -->
                                        <select id="cbxRoleCd" class="form-control" v-model="BusinessMMPlan.RoleCd">
                                            <option value="">= 선택 =</option>
                                            <option v-for="roleType in RoleTypes" v-bind:value="roleType.Code">
                                                {{roleType.Name}}
                                            </option>
                                        </select>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-12">
                                        <label for="txtRoleDesc" class="control-label">
                                            <span>설명</span>
                                        </label>
                                        <input type="text" id="txtRoleDesc" class="form-control txt" v-model="BusinessMMPlan.RoleDesc" />
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="btn-wrap border-bottom">
                                        <div class="fr">
                                            <a href="#" id="btnChldInst" class="btn btn-default" title="추가" @click.prevent="addMM">
                                                <span class="glyphicon glyphicon-plus"></span> 추가
                                            </a>
                                            <a href="#" id="btnChldRmve" class="btn btn-default" title="삭제" @click.prevent="removeMM">
                                                <span class="glyphicon glyphicon-minus"></span> 삭제
                                            </a>
                                        </div>
                                    </div>
                                </section>
                                <section class="tbl-contents">
                                    <div class="table-responsive">
                                        <table class="table tbl-list table-hover table-bordered" id="tb5">
                                            <colgroup>
                                                <col class="chk" />
                                                <col />
                                                <col />
                                                <col />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th class="chk table-colwid5">선택</th>
                                                    <th scope="col" class="text-center">투입 년</th>
                                                    <th scope="col" class="text-center">투입 월</th>
                                                    <th scope="col" class="text-center">투입 MM</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="businessMMPlanDetail in BusinessMMPlanDetailList">
                                                    <td class="chk">
                                                        <label>
                                                            <input id="ckbChecked" v-model="businessMMPlanDetail.IsChecked" type="checkbox" />
                                                        </label>
                                                    </td>
                                                    <td class="text-center">
                                                        <select id="selRunYear" class="form-control" v-model="businessMMPlanDetail.RunYear">
                                                            <option v-for="year in Years" v-bind:value="year">
                                                                {{year}}
                                                            </option>
                                                        </select>
                                                    </td>
                                                    <td class="text-center">
                                                        <select id="selRunMonth" class="form-control" v-model="businessMMPlanDetail.RunMonth">
                                                            <option v-for="month in Months" v-bind:value="month">
                                                                {{month}}
                                                            </option>
                                                        </select>
                                                    </td>
                                                    <td class="text-center">
                                                        <label>
                                                            <input id="txtRunMM" v-model="businessMMPlanDetail.RunMM" />
                                                        </label>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </section>
                            </fieldset>
                        </form>
                    </section>
                    <section class="btn-align">
                        <a v-show="this.$store.getters.getCanSave " href="#" id="btnItemSave5" class="btn btn-primary" @click.prevent="saveBusinessMMPlan">
                            <span>
                                <i class="glyphicon glyphicon-save"></i> 저장
                            </span>
                        </a>
                        <a v-if="(this.$store.getters.getCanDelete && !IsNew)" href="#" id="btnItemRmve5" class="btn btn-warning" @click.prevent="removeBusinessMMPlan">
                            <span>
                                <i class="glyphicon glyphicon-edit"></i> 삭제
                            </span>
                        </a>
                        <a href="#" class="btn btn-danger" data-dismiss="modal">
                            <span>
                                <i class="glyphicon glyphicon-remove"></i> 취소
                            </span>
                        </a>
                    </section>
                </div>
            </div>
        </div>
        <CP1080P ref="CP1080P"></CP1080P>
    </div>
</template>

<script>
    import moment from 'moment'

    import CP1080P from '@/components/libs/popup/CP1080P'

    export default {
        name: 'BZ1011E',
        components: {
            CP1080P
        },
        data() {
            return {
                CallbackFunction: null
                // 투입 인력 항목
                , BusinessMMPlan: {
                    CorpCd: null,         // 회사코드
                    CompanyCd: null,        // 업체코드
                    BizNo: null,            // 업체이름
                    RoleSeq: null,             //
                    RoleEmpNm: 'TDB',
                    RoleCd: '',
                    RoleDesc: null,
                    EmpId: null             // 등록자
                }
                , BusinessMMPlanDetailList: new Array()
                // 투입 인력 계획 항목
                , BusinessMMPlanDetail: {
                    IsChecked: false,
                    CorpCd: null,         // 회사코드
                    CompanyCd: null,
                    BizNo: null,
                    RoleSeq: null,
                    RunYear: moment(new Date()).format('YYYY'),
                    RunMonth: moment(new Date()).format('MM'),
                    RunMM: null,
                    EmpId: null             // 등록/수정 자
                }
                // 조회조건 데이터 객체
                , BusinessSelectTerm: {
                    Arg: 'M'
                    , UserId: null
                    , CorpCd: null
                    , BizNm: null
                    , BusinessNo: null
                    , CompanyNm: null
                    , CompanyCode: null
                    , DvsCd: null								    // 진행상황
                    , SrchText: null								// 영업명
                    , TargetYear: moment(new Date()).year()	// 년도

                    //this.$store.getters.getEmpId
                    //this.$store.getters.getCompanyCode
                }
                , RoleTypes: new Array()
                , Years: new Array()
                , Months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
                , IsNew: true
            }
        },
        beforeMount() {
            // 인력구분 조회
            this.RoleTypes = $.scriptBase.getCommonCode('CODE000', '0709000', '', '')

            var startYear = this.moment(new Date()).add(1,'year').year()
            var endYear = this.moment(new Date(2013, 1, 1, 0, 0, 0)).year()

            // 대상년도 목록 값 설정
            for (var i = startYear; i >= endYear; i--) {

                this.Years.push(i)
            }
        },
        methods: {
            open: function (callbackFunction, bizObj, selectedObj) {
                
                if (selectedObj) {
                    // BusinessMMPlanDetail 조회
                    this.IsNew = false

                    this.findBusinessMMPlan(selectedObj)
                }
                else {
                    this.reset()
                    if (bizObj) {
                        this.BusinessMMPlan.CompanyNm = bizObj.CompanyNm   // 업체 영업 이름
                        this.BusinessMMPlan.CompanyCd = bizObj.CompanyCd   // 업체 영업 코드
                        this.BusinessMMPlan.BizNo = bizObj.BizNo           // 부모 hidden 번호
                        this.BusinessMMPlan.CorpCd = bizObj.CorpCd
                    }
                }


                this.CallbackFunction = callbackFunction

                //this.BusinessMMPlan.CorpCd = this.$parent.$parent.CompanyCode
                //this.BusinessMMPlanDetail.CorpCd = this.$parent.$parent.CompanyCode
                //this.BusinessMMPlanDetail.CompanyCd = bizObj.CompanyCd
                //this.BusinessMMPlanDetail.BizNo = bizObj.BizNo 

                $("#BZ1011E_Modal").modal("show")
            },
            close: function () {
                this.reset()
                $("#BZ1011E_Modal").modal("hide")
            },
            reset: function () {
                var RoleTypes = this.RoleTypes
                var Years = this.Years
                Object.assign(this.$data, this.$options.data())
                this.RoleTypes = RoleTypes
                this.Years = Years
            },
            // 투입 인력 계획 조회
            findBusinessMMPlan: function (obj) {
                var that = this

                var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/GetBusinessMMPlan'
                url = url.toRealServiceUrl()

                var sendobj = new Object()
                sendobj.jsonString = JSON.stringify(obj)

                var ajaxSuccessHandler = function (data) {

                    var jsonObj = JSON.parse(data.d)
                    that.BusinessMMPlan = jsonObj.Table[0]
                    that.BusinessMMPlanDetailList = jsonObj.Table1
                }
                $.scriptBase.executeAjaxAsync(url, sendobj, ajaxSuccessHandler)
            },
            // 영업 검색
            searchBusiness: function () {

                var that = this

                var successCallback = function (result) {

                    that.BusinessSelectTerm.BizNm = result.BizNm

                    that.BusinessMMPlan.CompanyNm = result.CompanyNm
                    that.BusinessMMPlan.CompanyCd = result.CompanyCd
                    that.BusinessMMPlan.BizNo = result.BizNo
                    that.BusinessMMPlan.CorpCd = result.CorpCd

                }

                that.$refs.CP1080P.open(successCallback, that.BusinessSelectTerm.CompanyCode, that.BusinessSelectTerm.CompanyNm)
            },
            resetBusiness: function () {
                var that = this

                that.BusinessSelectTerm.BizNm = null
                that.BusinessSelectTerm.BusinessNo = null

                // 영업명 선택 시 고객사 자동 설정
                that.BusinessSelectTerm.CompanyNm = null
                that.BusinessSelectTerm.CompanyCode = null

            },
            // 업체 영업 이력 저장
            saveBusinessMMPlan: function () {

                var that = this

                var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/SaveBusinessMMPlan'
                url = url.toRealServiceUrl()
                var empid = that.$store.getters.getEmpId

                that.BusinessMMPlan.EmpId = empid
                that.BusinessMMPlanDetailList.map(item => item.EmpId = empid)

                var obj = new Object()
                obj.jsonString = JSON.stringify(that.BusinessMMPlan)
                obj.jsonString2 = JSON.stringify(that.BusinessMMPlanDetailList)

                var ajaxSuccessHandler = function (data) {
                    that.CallbackFunction()
                    that.close()
                }
                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
            },

            // 영업이력 삭제 버튼 클릭 이벤트 핸들러
            removeBusinessMMPlan: function () {

                if (confirm('삭제하시겠습니까?')) {

                    var that = this

                    var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/RemoveBusinessMMPlan'
                    url = url.toRealServiceUrl()

                    var obj = new Object()
                    obj.jsonString = JSON.stringify(that.BusinessMMPlan)

                    var ajaxSuccessHandler = function (data) {
                        that.CallbackFunction()
                        that.close()
                    }
                    $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
                }
            },
            // 첨부파일 추가
            addMM: function () {
                var businessMMPlanDetail = Object.assign({}, this.BusinessMMPlanDetail)
                businessMMPlanDetail.RoleSeq = this.BusinessMMPlan.RoleSeq
                businessMMPlanDetail.BizNo = this.BusinessMMPlan.BizNo
                businessMMPlanDetail.CompanyCd = this.BusinessMMPlan.CompanyCd
                businessMMPlanDetail.CorpCd = this.BusinessMMPlan.CorpCd
                businessMMPlanDetail.EmpId = this.$store.getters.getEmpId
                this.BusinessMMPlanDetailList.push(businessMMPlanDetail)
            },
            // 첨부파일 삭제
            removeMM: function () {
                this.BusinessMMPlanDetailList = this.BusinessMMPlanDetailList.filter(item => item.IsChecked === false)
            }
        }
    }
</script>
