import Vue from 'vue'
import App from './App'
import router from './router'
import store from './vuex/store'
import elementUi from 'element-ui'
import VueCurrencyInput from 'vue-currency-input'





import './css/base/bootstrap.css'
import './css/base/bootstrap-modal-custom.css'
import './css/base/bootstrap-datetimepicker.min.css'
import './css/site.css'
import 'element-ui/lib/theme-chalk/index.css'
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import './css/dx_custom.css'

import '@/styles/index.scss'

import moment from 'moment'
import fileHandler from './scripts/file-handler'
import csvHandler from './scripts/csv-handler'



import './scripts/base/jquery-1.11.0.min.js'
import './scripts/base/jquery-ui.min.js'
import './scripts/base/bootstrap.js'
import './scripts/base/bootstrap-datetimepicker.min.js'
import './scripts/base/jquery.board.pager.js'
import './scripts/base/jstree.min.js'
import './scripts/core/script-base.js'
import './scripts/core/ibs-prototype.js'




Vue.config.productionTip = true
Vue.prototype.moment = moment
Vue.prototype.fileHandler = fileHandler
Vue.prototype.csvHandler = csvHandler


Vue.prototype.$EventBus = new Vue()

const pluginOptions = {
    /* see config reference */
    globalOptions: { currency: 'KRW' }
}

Vue.use(VueCurrencyInput, pluginOptions)


new Vue({
    router,
    store,
    moment,
    elementUi,
    render: h => h(App)
}).$mount('#app') 





