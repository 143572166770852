<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">경비승인</p>
                </div>
                <div>
                    <!--리스트 영역-->
                    <section class="tbl-contents">
                        <div class="alert alert-info total">
                            <p class="total">
                                <i class="glyphicon glyphicon-folder-close"></i>
                                전체: <span id="spTotCnt">{{ExpenseRequestListTotalCount}}</span>건
                                <span style="margin-left: 25px;">반려는 개별 승인 버튼을 통해 진행해 주십시오.</span>
                            </p>
                            <div class="btn-group fr">
                                <a href="#" id="link1" class="btn btn-warning" @click.prevent="multipleApprove">
                                    <span class="glyphicon glyphicon-edit"></span> 일괄승인
                                </a>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <DxDataGrid id="grid-container"
                                        :show-borders="false"
                                        :data-source="ExpenseRequestList"
                                        :hoverStateEnabled="true"
                                        :row-alternation-enabled="true"
                                        :column-auto-width="true"
                                        @selection-changed="onSelectionChanged"
                                        :selected-row-keys="selectedItemKeys"
                                        style="max-height: 500px; vertical-align: top;">
                                <DxScrolling mode="standard" useNative="false" />
                                <DxSelection mode="multiple"
                                            :show-check-boxes-mode="'always'" />
                                <DxColumnFixing :enabled="true" />
                                <DxColumn data-field="RegtDate" caption="신청일자" alignment="center"/>
                                <DxColumn data-field="ProjectNm" caption="프로젝트" alignment="center"/>
                                <DxColumn data-field="EmpNm" caption="신청자" alignment="center" />
                                <DxColumn data-field="UseYMD" caption="사용일자" alignment="center" />
                                <DxColumn data-field="Price" caption="금액" alignment="center" />
                                <DxColumn data-field="AcntCdName" caption="지출구분" alignment="center" />
                                <DxColumn data-field="UseTypeName" caption="사용구분" alignment="center" />
                                <DxColumn data-field="AprvEmpNm" caption="승인자" alignment="center" />
                                <DxColumn data-field="AprvDvsName" caption="승인구분" alignment="center" />
                                <DxColumn data-field="UseTxt" caption="사용사유"/>
                                <DxColumn type="buttons" caption="승인" width="5%" :fixed="true">
                                    <DxButton :on-click="doApprove"
                                              text="승인"
                                              css-class="btn btn-warning btn-xs text-deco" />
                                </DxColumn>
                            </DxDataGrid>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <AC1031E ref="AC1031E"></AC1031E>
    </div>
</template>

<style>
    .dx-datagrid .dx-link.btn.btn-warning.btn-xs.text-deco {
        text-decoration: none;
        color: white;
    }
</style>

<script>
    import ViewTitle from '@/components/ViewTitle';
    import AC1031E from '@/components/ac/popup/AC1031E';
    import { DxDataGrid, DxColumn, DxEditing, DxSelection, DxScrolling, DxSorting, DxButton, DxColumnFixing } from 'devextreme-vue/data-grid';

    export default {
        name: 'AC1032R',
        components: {
            ViewTitle, AC1031E, DxDataGrid, DxColumn, DxEditing, DxSelection, DxScrolling, DxSorting, DxButton, DxColumnFixing
        },
        data() {
            return {
                // 조회조건 객체
                ExpenseRequestSelectTerm: {
                    Arg: 'A',
                    UserId: this.$store.getters.getEmpId,
                    CorpCd: this.$parent.CompanyCode,
                    EmpNo: this.$store.getters.getEmpId,
                    ReqYMD: null,
                    ProjectCode: null,
                    ProjectName: null,
                    CompanyCd: null,
                    SeqNo: null,
                    AcntCode: null,
                    AprvDvsCode: null,
                    StartDate: null,
                    EndDate: null,
                    UseTypeCode: null,
                    AuthGrade: this.$parent.AuthGrade,
                    AprvEmpNo: null,
                    CardNo: null,
                    CardCorpCd: null
                },
                // 경비승인요청 목록
                ExpenseRequestList: new Array(),
                // 경비승인요청 전체 건수
                ExpenseRequestListTotalCount: 0,
                // 체크박스 선택한 아이템
                selectedItemKeys: new Array(),
                // 일괄승인할 아이템 목록
                MultipleExpenseRequest: new Array(),
            }
        },
        mounted() {
            if (this.$parent.CanSearch) {

                this.fn_ShowData();
            }
        },
        methods: {
            // 선택한 로우 인덱스
            onSelectionChanged(e) {

                this.selectedItemKeys = e.selectedRowKeys;
            },
            // 경비 승인처리
            // targetRow: 승인처리 대상 리스트 항목
            doApprove(e) {

                let parent = this;
                let data = e.row.data;

                data.Arg = 'A';
                //승인 처리 시 승인구분 값 변경(0105020 : 승인완료)
                data.AprvDvsCd = '0105020';

                let successCallback = function () {

                    parent.fn_ShowData();
                }

                this.$refs.AC1031E.open(successCallback, data, null);
            },
            // 데이터 조회
            fn_ShowData() {

                var parent = this;
                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/GetExpenseRequest'.toRealServiceUrl();
                var obj = new Object();
                obj.jsonString = JSON.stringify(this.ExpenseRequestSelectTerm);

                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d);
                    var table = jsonData.Table;
                    var table1 = jsonData.Table1;

                    // 목록
                    parent.ExpenseRequestList = (table.length > 0) ? table : new Array();

                    // 목록 전체 카운터
                    parent.ExpenseRequestListTotalCount = (table1.length > 0) ? table1[0].TotCnt : 0;
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 일괄승인처리
            multipleApprove() {

                var parent = this;
                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/SaveMultipleExpenseRequest'.toRealServiceUrl();
                var obj = new Object();

                this.MultipleExpenseRequest = this.selectedItemKeys;

                if (this.MultipleExpenseRequest.length == 0) {

                    alert('승인할 항목을 선택해 주십시오.');
                    return;
                }

                this.MultipleExpenseRequest.forEach((item) => {

                    // Arg, 로그인사용자 아이디 값 추가
                    item.Arg = 'A';
                    item.UserId = this.$store.getters.getEmpId;
                    //승인 처리 시 승인구분 값 변경(0105020 : 승인완료)
                    item.AprvDvsCd = '0105020';
                })

                obj.jsonString = JSON.stringify(this.MultipleExpenseRequest);

                if (confirm('일괄승인 하시겠습니까?')) {

                    var successCallback = function (data) {
                        
                        if (data.d > 0) {

                            alert('일괄승인 되었습니다.');
                            parent.fn_ShowData();
                        } else {

                            alert('승인에 실패했습니다. 잠시 후 다시 시도해 주십시오.');
                            return;
                        }
                    }

                    $.scriptBase.executeAjaxAsync(url, obj, successCallback);
                }
            },
        }
    }
</script>