﻿import Vue from 'vue'
import Router from 'vue-router'

import Login from '@/components/Login'
//import MainLayout from '@/components/MainLayout'
import Layout from '@/components/Layout'
import Main from '@/components/Main'
import AC1000R from '@/components/ac/accounting/AC1000R'
import AC1010R from '@/components/ac/accounting/AC1010R'
import AC1020R from '@/components/ac/accounting/AC1020R'
import AC1030R from '@/components/ac/accounting/AC1030R'
import AC1032R from '@/components/ac/accounting/AC1032R'
import AC1040R from '@/components/ac/accounting/AC1040R'
import AC1050R from '@/components/ac/accounting/AC1050R'
import AC1060R from '@/components/ac/accounting/AC1060R'
import AC1070R from '@/components/ac/accounting/AC1070R'
import AC1080R from '@/components/ac/accounting/AC1080R'
import AC1090R from '@/components/ac/accounting/AC1090R'
import AC1100R from '@/components/ac/accounting/AC1100R'
import AC1110R from '@/components/ac/accounting/AC1110R'
import AC1120R from '@/components/ac/accounting/AC1120R'
import AC1130R from '@/components/ac/accounting/AC1130R'
import AC1140R from '@/components/ac/accounting/AC1140R'

import BM1010R from '@/components/bm/free_board/BM1010R'
import BM1011E from '@/components/bm/free_board/BM1011E'
import BM1020R from '@/components/bm/free_board/BM1020R'
import BM1050R from '@/components/bm/free_board/BM1050R'

import BZ1000R from '@/components/bz/business/BZ1000R'
import BZ1010R from '@/components/bz/business/BZ1010R'
import BZ1020R from '@/components/bz/business/BZ1020R'
import BZ1030R from '@/components/bz/business/BZ1030R'

import CM1000R from '@/components/cm/base_infomation/CM1000R'
import CM1010R from '@/components/cm/base_infomation/CM1010R'
import CM1020R from '@/components/cm/base_infomation/CM1020R'
import CM1030R from '@/components/cm/base_infomation/CM1030R'
import CM1040R from '@/components/cm/base_infomation/CM1040R'

import HM1000R from '@/components/hm/human_management/HM1000R'
import HM1050R from '@/components/hm/human_management/HM1050R'

import PM1000R from '@/components/pm/project_manager/PM1000R'
import PM1010R from '@/components/pm/project_manager/PM1010R'
import PM1020R from '@/components/pm/project_manager/PM1020R'
import PM1030R from '@/components/pm/project_manager/PM1030R'
import PM1040R from '@/components/pm/project_manager/PM1040R'
import PM1050R from '@/components/pm/project_manager/PM1050R'
import PM1060R from '@/components/pm/project_manager/PM1060R'
import PM1062R from '@/components/pm/project_manager/PM1062R'
import PM1070R from '@/components/pm/project_manager/PM1070R'
import PM1080R from '@/components/pm/project_manager/PM1080R'
import PM1090R from '@/components/pm/project_manager/PM1090R'

import RT1000R from '@/components/rt/accounting/RT1000R'
import RT1010R from '@/components/rt/accounting/RT1010R'
import RT1020R from '@/components/rt/accounting/RT1020R'
import RT1030R from '@/components/rt/accounting/RT1030R'
import RT1040R from '@/components/rt/accounting/RT1040R'
import RT1060R from '@/components/rt/accounting/RT1060R'
import RT1070R from '@/components/rt/accounting/RT1070R'
import RT1100R from '@/components/rt/accounting/RT1100R'
import RT1110R from '@/components/rt/accounting/RT1110R'
import RT1120R from '@/components/rt/accounting/RT1120R'
import RT2000R from '@/components/rt/project/RT2000R'
import RT2010R from '@/components/rt/project/RT2010R'

import ZZ1000R from '@/components/zz/system/ZZ1000R'
import ZZ1010R from '@/components/zz/system/ZZ1010R'
import ZZ1012R from '@/components/zz/system/ZZ1012R'
import ZZ1020R from '@/components/zz/system/ZZ1020R'
import ZZ1021R from '@/components/zz/system/ZZ1021R'
import ZZ1040R from '@/components/zz/system/ZZ1040R'
import ZZ1050R from '@/components/zz/system/ZZ1050R'
import ZZ1070R from '@/components/zz/system/ZZ1070R'
import ZZ1100R from '@/components/zz/system/ZZ1100R'

Vue.use(Router)

const requireAuth = () => (from, to, next) => {
    let cookieKey = "MiniUserInfoContext=";
    let result = "";
    const cookieArr = document.cookie.split(";");

    for (let i = 0; i < cookieArr.length; i++) {
        if (cookieArr[i][0] === " ") {
            cookieArr[i] = cookieArr[i].substring(1);
        }

        if (cookieArr[i].indexOf(cookieKey) === 0) {
            result = cookieArr[i].slice(cookieKey.length, cookieArr[i].length);
        }
    }

    if (result != "") {
        return next()
    }
    else {
        next('/Login?returnPath=/')
    }

    //const cookie = document.cookie.indexOf('MiniUserInfoContext')

    //if (cookie != -1) {
    //    return next()
    //}
    //else {
    //    next('/Login?returnPath=/')
    //}
}

export default new Router({
    routes: [
        {
            path: '/', // 첫 화면을 로그인 화면으로 설정한다
            //name: 'Layout',
            component: Layout,
            children: [
                {
                    path: '/',
                    name: 'Main',
                    component: Main
                },
                {
                    path: '/AC/Accounting/AC1000R',
                    name: 'AC1000R',
                    component: AC1000R
                },
                {
                    path: '/AC/Accounting/AC1010R',
                    name: 'AC1010R',
                    component: AC1010R
                },
                {
                    path: '/AC/Accounting/AC1020R',
                    name: 'AC1020R',
                    component: AC1020R
                },
                {
                    path: '/AC/Accounting/AC1030R',
                    name: 'AC1030R',
                    component: AC1030R
                },
                {
                    path: '/AC/Accounting/AC1032R',
                    name: 'AC1032R',
                    component: AC1032R
                },
                {
                    path: '/AC/Accounting/AC1040R',
                    name: 'AC1040R',
                    component: AC1040R
                },
                {
                    path: '/AC/Accounting/AC1050R',
                    name: 'AC1050R',
                    component: AC1050R
                },
                {
                    path: '/AC/Accounting/AC1060R',
                    name: 'AC1060R',
                    component: AC1060R
                },
                {
                    path: '/AC/Accounting/AC1070R',
                    name: 'AC1070R',
                    component: AC1070R
                },
                {
                    path: '/AC/Accounting/AC1080R',
                    name: 'AC1080R',
                    component: AC1080R
                },
                {
                    path: '/AC/Accounting/AC1090R',
                    name: 'AC1090R',
                    component: AC1090R
                },
                {
                    path: '/AC/Accounting/AC1100R',
                    name: 'AC1100R',
                    component: AC1100R
                },
                {
                    path: '/AC/Accounting/AC1110R',
                    name: 'AC1110R',
                    component: AC1110R
                },
                {
                    path: '/AC/Accounting/AC1120R',
                    name: 'AC1120R',
                    component: AC1120R
                },
                {
                    path: '/AC/Accounting/AC1130R',
                    name: 'AC1130R',
                    component: AC1130R
                },
                {
                    path: '/AC/Accounting/AC1140R',
                    name: 'AC1140R',
                    component: AC1140R
                },
                {
                    path: '/BM/FreeBoard/BM1010R',
                    name: 'BM1010R',
                    component: BM1010R
                },
                {
                    path: '/BM/FreeBoard/BM1011E',
                    name: 'BM1011E',
                    component: BM1011E
                },
                {
                    path: '/BM/FreeBoard/BM1020R',
                    name: 'BM1020R',
                    component: BM1020R
                },
                {
                    path: '/BM/FreeBoard/BM1050R',
                    name: 'BM1050R',
                    component: BM1050R
                },
                {
                    path: '/BZ/Business/BZ1000R',
                    name: 'BZ1000R',
                    component: BZ1000R
                },
                {
                    path: '/BZ/Business/BZ1010R',
                    name: 'BZ1010R',
                    component: BZ1010R
                },
                {
                    path: '/BZ/Business/BZ1020R',
                    name: 'BZ1020R',
                    component: BZ1020R
                },
                {
                    path: '/BZ/Business/BZ1030R',
                    name: 'BZ1030R',
                    component: BZ1030R
                },
                {
                    path: '/CM/BaseInfomation/CM1000R',
                    name: 'CM1000R',
                    component: CM1000R
                },
                {
                    path: '/CM/BaseInfomation/CM1010R',
                    name: 'CM1010R',
                    component: CM1010R
                },
                {
                    path: '/CM/BaseInfomation/CM1020R',
                    name: 'CM1020R',
                    component: CM1020R
                },
                {
                    path: '/CM/BaseInfomation/CM1030R',
                    name: 'CM1030R',
                    component: CM1030R
                },
                {
                    path: '/CM/BaseInfomation/CM1040R',
                    name: 'CM1040R',
                    component: CM1040R
                },
                {
                    path: '/HM/HumanManagement/HM1000R',
                    name: 'HM1000R',
                    component: HM1000R
                },
                {
                    path: '/HM/HumanManagement/HM1050R',
                    name: 'HM1050R',
                    component: HM1050R
                },
                {
                    path: '/PM/ProjectManager/PM1000R',
                    name: 'PM1000R',
                    component: PM1000R
                },
                {
                    path: '/PM/ProjectManager/PM1010R',
                    name: 'PM1010R',
                    component: PM1010R
                },
                {
                    path: '/PM/ProjectManager/PM1020R',
                    name: 'PM1020R',
                    component: PM1020R
                },
                {
                    path: '/PM/ProjectManager/PM1030R',
                    name: 'PM1030R',
                    component: PM1030R
                },
                {
                    path: '/PM/ProjectManager/PM1040R',
                    name: 'PM1040R',
                    component: PM1040R
                },
                {
                    path: '/PM/ProjectManager/PM1050R',
                    name: 'PM1050R',
                    component: PM1050R
                },
                {
                    path: '/PM/ProjectManager/PM1060R',
                    name: 'PM1060R',
                    component: PM1060R
                },
                {
                    path: '/PM/ProjectManager/PM1062R',
                    name: 'PM1062R',
                    component: PM1062R
                },
                {
                    path: '/PM/ProjectManager/PM1070R',
                    name: 'PM1070R',
                    component: PM1070R
                },
                {
                    path: '/PM/ProjectManager/PM1080R',
                    name: 'PM1080R',
                    component: PM1080R
                },
                {
                    path: '/PM/ProjectManager/PM1090R',
                    name: 'PM1090R',
                    component: PM1090R
                },
                {
                    path: '/RT/Accounting/RT1000R',
                    name: 'RT1000R',
                    component: RT1000R
                },
                {
                    path: '/RT/Accounting/RT1010R',
                    name: 'RT1010R',
                    component: RT1010R
                },
                {
                    path: '/RT/Accounting/RT1020R',
                    name: 'RT1020R',
                    component: RT1020R
                },
                {
                    path: '/RT/Accounting/RT1030R',
                    name: 'RT1030R',
                    component: RT1030R
                },
                {
                    path: '/RT/Accounting/RT1040R',
                    name: 'RT1040R',
                    component: RT1040R
                },
                {
                    path: '/RT/Accounting/RT1060R',
                    name: 'RT1060R',
                    component: RT1060R
                },
                {
                    path: '/RT/Accounting/RT1070R',
                    name: 'RT1070R',
                    component: RT1070R
                },
                {
                    path: '/RT/Accounting/RT1100R',
                    name: 'RT1100R',
                    component: RT1100R
                },
                {
                    path: '/RT/Accounting/RT1110R',
                    name: 'RT1110R',
                    component: RT1110R
                },
                {
                    path: '/RT/Accounting/RT1120R',
                    name: 'RT1120R',
                    component: RT1120R
                },
                {
                    path: '/RT/Project/RT2000R',
                    name: 'RT2000R',
                    component: RT2000R
                },
                {
                    path: '/RT/Project/RT2010R',
                    name: 'RT2010R',
                    component: RT2010R
                },
                {
                    path: '/ZZ/System/ZZ1000R',
                    name: 'ZZ1000R',
                    component: ZZ1000R
                },
                {
                    path: '/ZZ/System/ZZ1010R',
                    name: 'ZZ1010R',
                    component: ZZ1010R
                },
                {
                    path: '/ZZ/System/ZZ1012R',
                    name: 'ZZ1012R',
                    component: ZZ1012R
                },
                {
                    path: '/ZZ/System/ZZ1020R',
                    name: 'ZZ1020R',
                    component: ZZ1020R
                },
                {
                    path: '/ZZ/System/ZZ1021R',
                    name: 'ZZ1021R',
                    component: ZZ1021R
                },
                {
                    path: '/ZZ/System/ZZ1040R',
                    name: 'ZZ1040R',
                    component: ZZ1040R
                },
                {
                    path: '/ZZ/System/ZZ1050R',
                    name: 'ZZ1050R',
                    component: ZZ1050R
                },
                {
                    path: '/ZZ/System/ZZ1070R',
                    name: 'ZZ1070R',
                    component: ZZ1070R
                },
                {
                    path: '/ZZ/System/ZZ1100R',
                    name: 'ZZ1100R',
                    component: ZZ1100R
                }
            ],
            beforeEnter: requireAuth()
        },
        {
            path: '/Login',
            name: 'Login',
            component: Login
        }
    ]
})