<template>
    <div>
        <DxDataGrid ref="grdBusinessMMPlanList" 
                    :data-source="DataSource" 
                    :show-borders="true" 
                    :show-row-lines="true"
                    :columns="DefaultColumns"
                    @cell-prepared="onCellPrepared"
                    @row-click="rowClick">
        </DxDataGrid>
        <BZ1011E ref="BZ1011E"></BZ1011E>
    </div>
</template>

<script>
    // Dev extreme
    import { DxDataGrid, DxColumn, DxPaging, DxSummary, DxTotalItem, DxValueFormat } from 'devextreme-vue/data-grid'
    import { DxButton } from 'devextreme-vue'

    import BZ1011E from '@/components/bz/popup/BZ1011E'

    export default {
        name: 'BZ1020R_Detail'
        , components: {
            DxDataGrid
            , DxColumn
            , DxPaging
            , DxSummary
            , DxTotalItem
            , DxValueFormat
			, DxButton
            , BZ1011E
        }
        , props: {
			templateData: {
                type: Object,
                default: () => { }
            }
        }
        , data() {
            return {
                DataSource: new Array()
                , DefaultColumns: [
                    { caption: "역할", dataField: "RoleNm", alignment: 'center' },
                    { caption: "인력명", dataField: "RoleEmpNm", alignment: 'center' },
                    { caption: "비고", dataField: "RoleDesc", alignment: 'center' }
                ]
            };
        }
        , created() {
            this.searchBusinessMMPlan()
		}
        , methods: {
            //#region init
            //#endregion

            //#region event
            onCellPrepared(e) { // datagrid header 가운데 정렬, column 중앙 정렬
                if (e.rowType == 'header') {
                    e.cellElement.style['textAlign'] = 'center'
                }
                if (e.rowType == 'data') {
                    e.cellElement.style['verticalAlign'] = 'middle'
                }
            }
            , resetColumns: function () {
                var cnt = this.DefaultColumns.length

                for (var i = 3; i < cnt; i++) {
                    this.DefaultColumns.splice(3, 1)
                }
            }
            , rowClick: function (e) {
                var that = this
                if (e.rowType === "data") {
                    var successCallback = function (result) {
                        that.searchBusinessMMPlan()
                    }

                    this.$refs.BZ1011E.open(successCallback, this.BusinessSelectTerm, e.data)
                }
            }
            //#endregion

            //#region button click
            //#endregion

            //#region service
            , searchBusinessMMPlan: function () {
                var that = this
                // 조회
                var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/GetBusinessMMPlanList'
                url = url.toRealServiceUrl()
                var obj = new Object()
                obj.jsonString = JSON.stringify(that.templateData.data)

                var ajaxSuccessHandler = function (data) {
                    var jsonObj = JSON.parse(data.d)

                    that.resetColumns()

                    if (!jsonObj.Table) {
                        jsonObj.Table = new Array()
                    }

                    that.DataSource = jsonObj.Table
                    // 그리드 바인딩
                    that.DataCount = jsonObj.Table.length

                    var columnsCount = that.DataCount > 0 ? Object.keys(jsonObj.Table[0]).length : 0

                    for (var i = 0; i < columnsCount - 8; i++) {
                        var columnName = Object.keys(jsonObj.Table[0])[8 + i]
                        var newColumn = { 'caption': columnName, 'dataField': columnName }
                        that.DefaultColumns.push(newColumn)
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
            }
            //#endregion
        }
    }
</script>
