<template>
	<!-- 계약 마스터 등록 / 수정 -->
	<div class="content-box">
		<div class="modal fade" id="BZ1021E_Modal" ref="BZ1021E_Modal" tabindex="-1" role="dialog" aria-labelledby="BZ1021E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<button type="button" class="close" data-dismiss="modal">
							<span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
						</button>
						<h3 class="modal-title">계약 등록/수정</h3>
					</div>
					<!--계약등록-->
					<div class="modal-body">
						<section class="add-wrap">
							<form>
								<fieldset>
									<legend></legend>
									<section class="cols-wrap">
										<div class="cols">
											<label for="txtContractNm" class="control-label">
												<span>계약명</span>
											</label>
											<input type="text" id="txtContractNm" class="form-control txt" v-model="Contract.ContractNm" />
										</div>
									</section>
									<section class="cols-wrap">
										<div class="cols col-2">
											<label for="txtContractCompNm" class="control-label">
												<span><i class="glyphicon glyphicon-asterisk"></i>계약업체</span>
											</label>
											<!--<div class="cols-btns-cont">-->
											<input type="text" id="txtContractCompNm" class="form-control txt" disabled="disabled" v-model="Contract.ContractCompNm" />
											<input type="hidden" id="hideContractCompCd" v-model="Contract.ContractCompCd" />
											<!--</div>-->
											<!--<div class="cols-btns">
												<a href="#" id="btnContractComp" class="btn btn-default" @click.prevent="searchCompany(1)">
													<span>
														<i class="glyphicon glyphicon-search"></i> 검색
													</span>
												</a>
											</div>-->
										</div>
										<div class="cols col-2">
											<label for="txtOrderCorpNm1" class="control-label">
												<span><i class="glyphicon glyphicon-asterisk"></i>계약업체 담당자</span>
											</label>
											<div class="cols-btns-cont">
												<input type="text" id="txtContractMgrSeqNm" class="form-control txt" disabled="disabled" v-model="ContractMgr.ContractMgrSeqNm" />
												<input type="hidden" id="hideContractMgrSeqNo" v-model="ContractMgr.ContractMgrSeqNo" />
											</div>
											<div class="cols-btns">
												<a href="#" id="btnPerson" class="btn btn-default" @click.prevent="searchManager(1)">
													<span>
														<i class="glyphicon glyphicon-search"></i> 검색
													</span>
												</a>
											</div>
										</div>
									</section>
									<section class="cols-wrap">
										<div class="cols col-2">
											<label for="txtOrderCompNm" class="control-label">
												<span><i class="glyphicon glyphicon-asterisk"></i>발주업체</span>
											</label>
											<div class="cols-btns-cont">
												<input type="text" id="txtOrderCompNm" class="form-control txt" disabled="disabled" v-model="Contract.OrderCompNm" />
												<input type="hidden" id="hideOrderCompCd" v-model="Contract.OrderCompCd" />
											</div>
											<div class="cols-btns">
												<a href="#" id="btnOrderComp" class="btn btn-default" @click.prevent="searchCompany(2)">
													<span>
														<i class="glyphicon glyphicon-search"></i> 검색
													</span>
												</a>
											</div>
										</div>
										<div class="cols col-2">
											<label for="txtOrderCorpNm1" class="control-label">
												<span>발주업체 담당자</span>
											</label>
											<div class="cols-btns-cont">
												<input type="text" id="txtOrderMgrSeqNm" class="form-control txt" disabled="disabled" v-model="ContractMgr.OrderMgrSeqNm" />
												<input type="hidden" id="hideOrderMgrSeqNo" v-model="ContractMgr.OrderMgrSeqNo" />
											</div>
											<div class="cols-btns">
												<a href="#" id="btnPerson" class="btn btn-default" @click.prevent="searchManager(2)">
													<span>
														<i class="glyphicon glyphicon-search"></i> 검색
													</span>
												</a>
											</div>
										</div>
									</section>
									<section class="cols-wrap">
										<div class="cols col-2">
											<label for="selProjDiv" class="control-label">
												<span>프로젝트 구분</span>
											</label>
											<select class="form-control" id="selProjDiv" name="selProjDiv"
													v-model="Contract.ProjDiv">
												<option value="">== 선택 ==</option>
												<option v-for="item in Progress" v-bind:value="item.Code">
													{{item.Name}}
												</option>
											</select>
										</div>
										<div class="cols col-2">
											<label for="txtMgrEmpNm" class="control-label">
												<span>자사담당자</span>
											</label>
											<div class="cols-btns-cont">
												<input type="text" id="txtMgrEmpNm" class="form-control txt" disabled="disabled" v-model="Contract.MgrEmpNm" />
												<input type="hidden" id="txtMgrEmpNo" v-model="Contract.MgrEmpNo" />
											</div>
											<div class="cols-btns">
												<a href="#" id="btnPerson" class="btn btn-default" @click.prevent="searchComManager">
													<span>
														<i class="glyphicon glyphicon-search"></i> 검색
													</span>
												</a>
											</div>
										</div>
										<!--<div class="cols col-2">
											<label for="selConstractStatusCd" class="control-label">
												<span><i class="glyphicon glyphicon-asterisk"></i>계약상태</span>
											</label>
											<select class="form-control" id="selConstractStatusCd" name="selConstractStatusCd"
													v-model="Contract.ConstractStatusCd">
												<option value="">== 선택 ==</option>
												<option v-for="item in ConstractStatusList" v-bind:value="item.Code">
													{{item.Name}}
												</option>
											</select>
										</div>-->
									</section>
									<!--<section class="cols-wrap">
										<div class="cols col-2">
											<label for="selCostPlayDiv" class="control-label">
												<span>대금지급 구분</span>
											</label>
											<select class="form-control" id="selCostPlayDiv" name="selCostPlayDiv"
													v-model="Contract.CostPlayDiv">
												<option value="">== 선택 ==</option>
												<option v-for="item in CostPlayDivList" v-bind:value="item.Code">{{item.Name}}</option>
											</select>
										</div>
										<div class="cols cols col-2">
											<label for="txtCostPlayMeans" class="control-label">
												<span>대급지급</span>
											</label>
											<input type="text" id="txtCostPlayMeans" class="form-control txt" v-model="Contract.CostPlayMeans" />
										</div>
									</section>-->
									<section class="cols-wrap">
										<div class="cols">
											<label for="txtRemark" class="control-label">
												<span>비고</span>
											</label>
											<input type="text" id="txtRemark" class="form-control txt" v-model="Contract.Remark" />
										</div>
									</section>
									<!--<section class="cols-wrap">
										<div class="cols">
											<label for="txtContractExecGuarantee" class="control-label">
												<span>계약이행보증</span>
											</label>
											<input type="text" id="txtContractExecGuarantee" class="form-control txt" v-model="Contract.ContractExecGuarantee" />
										</div>
									</section>
									<section class="cols-wrap">
										<div class="cols">
											<label for="txtExecGuaranteeIns" class="control-label">
												<span>이행보증보험</span>
											</label>
											<input type="text" id="txtExecGuaranteeIns" class="form-control txt" v-model="Contract.ExecGuaranteeIns" />
										</div>
									</section>
									<section class="cols-wrap">
										<div class="cols">
											<label for="txtDefactGuaranteePeriod" class="control-label">
												<span>하자보증기간</span>
											</label>
											<input type="text" id="txtDefactGuaranteePeriod" class="form-control txt" v-model="Contract.DefactGuaranteePeriod" />
										</div>
									</section>-->
									<!--<section class="btn-align">
										<div v-show="CanSave" class="btn-group">
											<button type="button" id="btnItemSave" class="btn btn-primary" @click.prevent="saveData">
												<i class="glyphicon glyphicon-save"></i> 저장
											</button>
										</div>
										<div v-show="CanDelete" class="btn-group">
											<button type="button" id="btnItemRmve" class="btn btn-warning" @click.Prevent="removeData">
												<span class="glyphicon glyphicon-edit"></span> 삭제
											</button>
										</div>
										<div v-show="CanApprove" class="btn-group">
											<button type="button" id="btnItemRmve" class="btn btn-warning" @click.Prevent="approveData">
												<span class="glyphicon glyphicon-edit"></span> 전자결재
											</button>
										</div>
										<div class="btn-group">
											<button type="button" class="btn btn-danger" data-dismiss="modal">
												<i class="glyphicon glyphicon-remove"></i> 취소
											</button>
										</div>
									</section>-->
									<section class="btn-align">
										<div v-show="CanSave" class="btn-group">
											<button type="button" id="btnItemSave" class="btn btn-primary" @click.prevent="saveData">
												<i class="glyphicon glyphicon-save"></i> 저장
											</button>
										</div>
										<div class="btn-group">
											<button type="button" class="btn btn-danger" data-dismiss="modal">
												<i class="glyphicon glyphicon-remove"></i> 취소
											</button>
										</div>
									</section>
								</fieldset>
							</form>
						</section>
					</div>
				</div>
			</div>
			<!--<form action="https://office.hiworks.com/minisoft/approval/popup/write" target="_blank" method="post" ref="frmApproval">
				<input type="hidden" name="form_id" value="N_25" />
				<input type="hidden" name="subject" v-model="EduHistory.EduTitle" />
				<input v-if="EduHistory.RequiredYn === 'Y'" type="hidden" name="contents" v-model="'<p><H1>필수 이수 교육</H1></p>'+EduHistory.EduContents" />
				<input v-else-if="EduHistory.RequiredYn === 'N'" type="hidden" name="contents" v-model="EduHistory.EduContents" />
				<input type="hidden" name="callback_url" value="https://ibs.minisoft.co.kr/v2/services/HumanMgt_NoneAuth.svc/Get/SetEduHistoryApprove" />
				<input type="hidden" name="modify_contents_flag" value="Y" />
				<input type="hidden" name="modify_files_flag" value="Y" />
				<input type="hidden" name="state" v-model="EduHistory.EduEmpNo+''+EduHistory.EduNo" />
			</form>-->
			<CP1020P ref="CP1020P"></CP1020P>
			<CP1021P ref="CP1021P"></CP1021P>
			<CP1022P ref="CP1022P"></CP1022P>
			<CP1030P ref="CP1030P"></CP1030P>
			<CP1040P ref="CP1040P" style="padding:0px;"></CP1040P>

</div>
	</div>
</template>

<script>
	import { DxDataGrid, DxColumn } from 'devextreme-vue/data-grid';
	import CP1020P from '@/components/libs/popup/CP1020P'
	import CP1021P from '@/components/libs/popup/CP1021P'
	import CP1022P from '@/components/libs/popup/CP1022P'
	import CP1030P from '@/components/libs/popup/CP1030P'
	import CP1040P from '@/components/libs/popup/CP1040P'

	import {
		DxDateBox
	} from 'devextreme-vue'
	import {
		DxHtmlEditor,
		DxToolbar,
		DxMediaResizing,
		DxItem
	} from 'devextreme-vue/html-editor'
import { Select } from 'element-ui';

	export default {
		name: 'BZ1021E',
		components: {
			DxDateBox,
			DxHtmlEditor,
			DxToolbar,
			DxMediaResizing,
			DxItem,
			DxDataGrid,
			DxColumn,
			CP1020P,
			CP1021P,
			CP1022P,
			CP1030P,
			CP1040P
		},
		data() {
			return {
				CallbackFunction: null,     // Caller Function
				Condition: null,
				Contract: {
					EmpNo: null,
					ContractNo: null,
					ContractNm: null,
					OrderCompCd: null,
					OrderCompNm: null,
					ContractCompCd: null,
					ContractCompNm: null,
					ProjDiv: '', 
					MgrEmpNo: null,
					MgrEmpNm: null,
					Remark: null,
					ConfirmYn: null,
					BizNo: null
				},
				ContractMgr: {
					ContractNo: null,
					ContractMgrSeqNm: null,
					ContractMgrSeqNo: null,
					OrderMgrSeqNm: null,
					OrderMgrSeqNo: null,
					CompDiv: null,
					RegtDate: null,
					RegtEmpNo: null,
					UpdateDate: null,
					UpdateEmpNo: null
				},
				CanSave: true,
				CanDelete: true,
				CanApprove: true,
				sizeValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'],
				IsNew: true,
				isMultiLine: true,
				pageIndex: 0,
			}
		},
		computed: {
			hasPageArray: function () {
				return this.pageIndex
			},
		},
		beforeMount() {
			// 공통코드 조회
			this.Progress = $.scriptBase.getCommonCode('CODE000', '0732000', '', '')				// 프로젝트 구분
			this.ConstractStatusList = $.scriptBase.getCommonCode('CODE000', '0733000', '', '')		// 계약진행상태
			this.CostPlayDivList = $.scriptBase.getCommonCode('CODE000', '0721100', '', '')			// 대금지급
		},
		methods: {
			open: function (callbackFunction, condition, selectedObj) {

				if (selectedObj) {
					// 전자 결재 완료 여부
					this.Contract = selectedObj
					this.ContractMgr.OrderMgrSeqNo = selectedObj.OrderMgrSeqNo
					this.ContractMgr.OrderMgrSeqNm = selectedObj.OrderMgrNm
					this.ContractMgr.ContractMgrSeqNo = selectedObj.ContractMgrSeqNo
					this.ContractMgr.ContractMgrSeqNm = selectedObj.ContractMgrNm

				} else {
					this.reset()

				}

				this.CallbackFunction = callbackFunction
				this.Condition = condition
				this.Contract.EmpNo = this.$parent.$parent.EmpId

				$("#BZ1021E_Modal").modal("show")
			},
			close: function () {
				$("#BZ1021E_Modal").modal("hide")
				this.CallbackFunction()
			},
			reset() {
				Object.assign(this.$data, this.$options.data())
			},
			// 업체 검색
			// targetIndex: 1: 상세팝업화면 계약업체 검색, 2: 상세팝업화면 발주업체 검색
			searchCompany: function (targetIndex) {

				var parent = this

				var successCallback = function (result) {

					switch (targetIndex) {

						case 1: {

							parent.Contract.ContractCompNm = result.CompanyNm
							parent.Contract.ContractCompCd = result.CompanyCd

							break
						}
						case 2: {
							
							parent.Contract.OrderCompNm = result.CompanyNm
							parent.Contract.OrderCompCd = result.CompanyCd
							parent.ContractMgr.OrderMgrSeqNm = ""
							parent.ContractMgr.OrderMgrSeqNo = ""

							break
						}
						default:
					}
				}

				this.$refs.CP1030P.open(successCallback)
			},
			// 담당자 검색 1.계약업체 담당자 2.발주업체 담당자
			searchManager: function (targetIndex) {

				var parent = this

				var companycode = {
					CompanyNm: parent.Contract.OrderCompNm,
					CompanyCd: parent.Contract.OrderCompCd
				}

				if (!parent.Contract.OrderCompCd && !parent.Contract.OrderCompNm) {
					companycode = {
						CompanyCd: "",
						CompanyNm: ""
					}
				}

				var successCallback = function (result) {

					switch (targetIndex) {

						case 1: {
							
							parent.ContractMgr.ContractMgrSeqNm = result.MgrNm
							parent.ContractMgr.ContractMgrSeqNo = result.SeqNo
							parent.Contract.ContractCompNm = result.TraderNm
							parent.Contract.ContractCompCd = result.CompanyCd

							break
						}
						case 2: {
							
							parent.ContractMgr.OrderMgrSeqNm = result.MgrNm
							parent.ContractMgr.OrderMgrSeqNo = result.SeqNo
							parent.Contract.OrderCompNm = result.TraderNm
							parent.Contract.OrderCompCd = result.CompanyCd

							break
						}
						default:
					}
				}

				this.$refs.CP1040P.manageropen(successCallback, companycode)
			},
			// 자사담당자 검색
			searchComManager: function () {

				var parent = this

				var successCallback = function (result) {

					parent.Contract.MgrEmpNm = result.EmpNm
					parent.Contract.MgrEmpNo = result.EmpNo
				}

				if (parent.Contract.ContractNo) {
					this.$refs.CP1020P.open(successCallback)
				}
				else {
					this.$refs.CP1022P.open(successCallback)
				}
			},
			//업체 영업 저장 메서드
			saveData: function () {

				if (!this.Contract.ContractCompNm) {

					alert('계약업체를 선택하세요.')
					document.getElementById('txtContractCompNm').focus()
					this.pageIndex = 0

					return;
				}
				if (!this.Contract.OrderCompNm) {

					alert('발주업체를 선택하세요.')
					document.getElementById('txtOrderCompNm').focus()
					this.pageIndex = 0

					return;
				}
				if (!this.ContractMgr.ContractMgrSeqNm) {

					alert('계약업체담당자를 선택하세요.')
					document.getElementById('txtContractMgrSeqNm').focus()
					this.pageIndex = 0

					return;
				}
				if (confirm('저장 하시겠습니까?')) {
					var parent = this
					var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/SaveContract'
					url = url.toRealServiceUrl()

					this.Contract.ContractMgrSeqNm = this.ContractMgr.ContractMgrSeqNm
					this.Contract.ContractMgrSeqNo = this.ContractMgr.ContractMgrSeqNo
					this.Contract.OrderMgrSeqNm = this.ContractMgr.OrderMgrSeqNm
					this.Contract.OrderMgrSeqNo = this.ContractMgr.OrderMgrSeqNo
					this.Contract.CompDiv = this.ContractMgr.CompDiv
					this.Contract.RegtDate = this.ContractMgr.RegtDate
					this.Contract.RegtEmpNo = this.ContractMgr.RegtEmpNo
					this.Contract.UpdateDate = this.ContractMgr.UpdateDate
					this.Contract.UpdateEmpNo = this.ContractMgr.UpdateEmpNo

					var obj = new Object()

					obj.jsonString = JSON.stringify(parent.Contract)

					var ajaxSuccessHandler = function (data) {
						alert('등록되었습니다.')
					    parent.close()
					}

					$.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
				}
			},
			// 영업관리 삭제 버튼 클릭 이벤트 핸들러
			removeData: function () {
				var that = this
				//var url = '/Mini.Service.IBS.HM.Service/HumanMgt.svc/ajax/RemoveEduHistory'
				//url = url.toRealServiceUrl()
				//var obj = new Object()
				//obj.jsonString = JSON.stringify(that.EduHistory)

				//var ajaxSuccessHandler = function (data) {
				//    that.close()
				//}

				//$.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
			},
			approveData: function () {
				//alert("결재 요청시 교육 이수증 첨부 바랍니다.")
				//// popup
				//this.$refs.frmApproval.submit()
				//this.close()
			}
		}
	}
</script>
