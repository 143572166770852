<template>
    <div id="Header">
        <p class="side-toggle">
            <a href="#">
                <img src="../images/ico_sidemenu_hide.svg" alt="sidemenu" @click.prevent="sideMenuClickEvent">
            </a>
            <a href="#">
                <img src="../images/ico_sidemenu_open.svg" alt="sidemenu" @click.prevent="sideMenuClickEvent">
            </a>
        </p>
        <!-- 토글 버튼 : 끝 -->
        <!-- 로그아웃 버튼 : 시작 -->
        <p class="poweroff">
            <a href="#" @click.prevent="logout">
                <img src="../images/ico_poweroff.svg" alt="poweroff">
            </a>
        </p>
        <!-- 로그아웃 버튼 : 끝 -->
        <!-- 프로필 영역 : 시작 -->
        <div class="profile">
            <p class="profile-img"><img :src="iconPhoto" alt="profile-img"></p>
            <p class="profile-txt">
                <span class="txt-team">{{this.$parent.PositionName}}</span><br>
                <span class="txt-name">{{this.$parent.UserName}}</span>
            </p>
        </div>
        <!-- 프로필 영역 : 끝 -->
        <!-- 툴바 메뉴 : 시작 -->
        <div class="menu">
            <ul>
                <!--<li>
                    <a href="#">
                        <img src="../images/ico_contact.svg" alt="contact">
                    </a>
                </li>-->
                <li>
                    <a href="#" @click.prevent="copyUrlClickEvent">
                        <img src="../images/ico_url.svg" alt="url">
                    </a>
                    <input type="hidden" ref="url" :value="url" />
                </li>
                <li>
                    <a href="#" @click.prevent="fullScreenClickEvent">
                        <img src="../images/ico_expand.svg" alt="expand">
                    </a>
                </li>
            </ul>
            <ul>
                <li>
                    <a href="#"  @click.prevent="menuTabClose">
                        <img src="../images/ico_close.svg" alt="close">
                    </a>
                </li>
                <li>
                    <a href="#" @click.prevent="allMenuTabClose">
                        <img src="../images/ico_closeall.svg" alt="closeall">
                    </a>
                </li>
                <!--<li>
                    <a href="#" @click.prevent="onRestoreClick">
                        <img src="../images/ico_restore.svg" alt="restore">
                    </a>
                </li>-->
            </ul>
            <ul>
                <!--<li>
                    <a href="#" @click.prevent="onFavoriteClick">
                        <img src="../images/ico_favorite.svg" alt="favorite">
                    </a>
                </li>-->
                <li>
                    <a href="#">
                        <img src="../images/ico_help.svg" alt="help">
                    </a>
                </li>
            </ul>
        </div>
        <!-- 툴바 메뉴 : 시작 -->
    </div>
</template>

<script>

    export default {
        name: 'Header',
        data() {
            return {
                url: null,
                iconPhoto: require('../images/img_profile.svg'),
            }
        },
        beforeMount() {

        },
        methods: {
            // 프로필 사진 로드 (개발중)
            loadIconPhoto() {

                let parent = this;

                let url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/GetIconPhoto'.toRealServiceUrl();
                let obj = new Object();
                obj.prm = new Array();

                let iconGuid = this.$store.getters.getUserIcon;
                obj.prm.push(iconGuid);

                let successCallback = function(data) {

                    let jsonData = JSON.parse(data.d);
                    console.log(jsonData);
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 로그아웃
            logout: function () {
                var date = new Date();
                date.setDate(date.getDate() - 1);

                var willCookie = "";
                willCookie += "MiniUserInfoContext=;";
                //willCookie = 'MiniUserInfoContext=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                //willCookie += "Expires=" + date.toUTCString();

                // 쿠키를 집어넣는다.
                document.cookie = willCookie;

                localStorage.clear();

                this.$router.push({ path: '/Login' })
            },
            // 좌측 메뉴 열고 닫기 버튼 클릭 이벤트
            sideMenuClickEvent: function () {
                
                $('body').toggleClass('active')

                if ($('body').hasClass('active') && $('.overlay').css('display') === 'none') {

                    $('body').css('overflow', 'auto')
                }
                else {

                    $('body').css('overflow', 'hidden')
                }
            },
            // 현재화면 Url 복사 버튼 클릭 이벤트
            copyUrlClickEvent: function () {
                // URL 복사
                //this.$refs.url.setAttribute('type', 'text')
                //this.$refs.url.select()
                //document.execCommand('copy')
                //this.$refs.url.setAttribute('type', 'hidden')
                //this.$refs.url.blur()

                //alert(this.url + ' 경로를 복사하였습니다.')
            },
            // 전체화면 버튼 클릭 이벤트
            fullScreenClickEvent: function () {

                // 전체 화면
                if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {

                    // current working methods
                    if (document.documentElement.requestFullscreen) {

                        document.documentElement.requestFullscreen()
                    }
                    else if (document.documentElement.mozRequestFullScreen) {

                        document.documentElement.mozRequestFullScreen()
                    }
                    else if (document.documentElement.webkitRequestFullscreen) {

                        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                    }
                }
                else {

                    if (document.cancelFullScreen) {

                        document.cancelFullScreen()
                    }
                    else if (document.mozCancelFullScreen) {

                        document.mozCancelFullScreen()
                    }
                    else if (document.webkitCancelFullScreen) {

                        document.webkitCancelFullScreen()
                    }
                }
            },
            // 메뉴 탭 닫기
            menuTabClose: function () {

                if (this.$parent.KeepAliveMenuList.length > 0) {

                    if (confirm('현재 화면 닫겠습니까?')) {

                        for (var i = 0; i < this.$parent.KeepAliveMenuList.length; i++) {

                            var menu = this.$parent.KeepAliveMenuList[i]

                            if (menu.IsActive) {

                                // 탭화면 닫는 함수 호출
                                this.$parent.$refs.tagsview.closeMenuTab(menu)
                                break
                            }
                        }
                    }
                }
                else {

                    alert('활성화 되어있는 화면이 없습니다.')
                }
            },
            // 전체 메뉴 탭 닫기
            allMenuTabClose: function () {

                if (this.$parent.KeepAliveMenuList.length > 0) {

                    if (confirm('전체 화면 닫겠습니까?')) {

                        // Keep Alive 메뉴 객체 초기화
                        this.$parent.KeepAliveMenuList = new Array()

                        // 메인으로 이동
                        this.$router.push('/')
                    }
                }
                else {

                    alert('활성화 되어있는 화면이 없습니다.')
                }
            }
            //이전화면 열기
            //onRestoreClick: function () {
            //    //if (confirm("이전 화면 열겠습니까?")) {
            //    //    // 마지막 종료메뉴 열기(로그아웃시 열려 있던 화면 Reload)
            //    //    const restoreView = []
            //    //    const restore = JSON.parse(localStorage.getItem('restore'))
            //    //    const routes = store.getters.routers
            //    //    for (const view of restore) {
            //    //        const tempViews = routes.filter(v => { return v.children !== undefined && v.children[0].name === view })
            //    //        restoreView.push(tempViews[0])
            //    //    }

            //    //    for (const review of restoreView) {
            //    //        this.$store.dispatch('addView', this.getTagsView(review))
            //    //    }
            //    //}
            //},
            // 즐겨찾기 추가
            //onFavoriteClick: function () {
            //    //const menuID = this.$route.meta.menuid

            //    //var url = process.env.VUE_APP_DATA_RESTAPI + 'dataservice'
            //    //var service = new FoxRestDataClient(url)

            //    //var request = new FoxDataRequest()
            //    //request.parameters.SystCode = store.getters.systcode
            //    //request.parameters.MenuID = menuID
            //    //request.parameters.UserID = store.getters.userid
            //    //request.queryId = "MenuContainer.UP_FX_SaveFavorite"

            //    //service.executeDataSet(request, function (response) {
            //    //    if (response.success) {
            //    //        store.dispatch('AddSaveFavortes', menuID).then(() => {
            //    //            $('.title-fav').addClass('active')
            //    //            alert('즐겨찾기 추가 하였습니다.')

            //    //        })
            //    //    }
            //    //}, function (error) {
            //    //    console.log('Header onFavClick onError : ' + error)
            //    //})
            //}
        }
    }
</script>

<style scoped>
</style>