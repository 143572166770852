<template>
    <!--상세 모달 팝업-->
    <div class="modal fade" id="PM1041E_Modal" ref="PM1041E_Modal" tabindex="-1" role="dialog" aria-labelledby="PM1041E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" @click.Prevent="close">
                        <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                    </button>
                    <h3 class="modal-title" v-if="PageActionType == 'I'">매입상품 등록</h3>
                    <h3 class="modal-title" v-if="PageActionType == 'V'">매입상품 상세</h3>
                </div>
                <div class="modal-body">
                    <section class="add-wrap">
                        <form>
                            <fieldset>
                                <legend></legend>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtProjectNm2" class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>프로젝트
                                            </span>
                                        </label>
                                        <section class="add-cont">
                                            <div class="cols-btns-cont">
                                                <input type="text" id="txtProjectNm2" ref="txtProjectNm2" class="form-control txt" readonly="readonly" v-model="PurchaseProductDetail.ProjectNm" />
                                                <input type="hidden" id="hideProjectCd" v-model="PurchaseProductDetail.ProjectCd" />
                                            </div>
                                            <div class="cols-btns">
                                                <a href="#" id="btnItemPop2" class="btn btn-default" @click.prevent="popSearchProjectCode">
                                                    <span>
                                                        <i class="glyphicon glyphicon-search"></i> 검색
                                                    </span>
                                                </a>
                                            </div>
                                        </section>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtCompanyNm" class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>거래처
                                            </span>
                                        </label>
                                        <input type="text" id="txtCompanyNm2" ref="txtCompanyNm2" class="form-control" readonly="readonly" v-model="PurchaseProductDetail.CompanyNm" />
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtProductNm2" class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>품목
                                            </span>
                                        </label>
                                        <input type="text" id="txtProductNm2" ref="txtProductNm2" class="form-control" v-model="PurchaseProductDetail.ProductNm" />
                                    </div>
                                </section>

                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="cbProductType" class="control-label">
                                            <span><i class="glyphicon glyphicon-asterisk"></i>품목 유형</span>
                                        </label>
                                        <select class="form-control" id="cbProductType" ref="cbProductType" name="cbProductType" v-model="PurchaseProductDetail.ProductType">
                                            <option value="">== 선택 ==</option>
                                            <option v-for="item in ProductTypeList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtUnitCnt2" class="control-label"><span>수량</span></label>
                                        <input type="text" id="txtUnitCnt2" class="form-control text-right" maxlength="3" v-model="PurchaseProductDetail.UnitCnt" />
                                    </div>
                                </section>

                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtListPriceSum2" ref="txtListPriceSum2" class="control-label"><span>판매금액</span></label>
                                        <input type="text" id="txtListPriceSum2" class="form-control text-right"
                                               v-model="PurchaseProductDetail.ListPriceSum" @keyup="inputPrice($event, 'ListPriceSum')" />
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtSalePriceSum2" ref="txtSalePriceSum2" class="control-label"><span><i class="glyphicon glyphicon-asterisk"></i>매입금액</span></label>
                                        <input type="text" id="txtSalePriceSum2" class="form-control text-right"
                                               v-model="PurchaseProductDetail.SalePriceSum" @keyup="inputPrice($event, 'SalePriceSum')" />
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="selVatIncludeYn" class="control-label">
                                            <span>V.A.T</span>
                                        </label>
                                        <select class="form-control" id="selVatIncludeYn" ref="selVatIncludeYn" name="selVatIncludeYn"
                                                v-model="PurchaseProductDetail.VatIncludeYn"
                                                @change="selectBoxVatChangedEvent">
                                            <option value="">== 선택 ==</option>
                                            <option value="Y">포함</option>
                                            <option value="N">별도</option>
                                            <option value="Z">영세율</option>
                                            <option value="F">면세</option>
                                        </select>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtSaleVATSum2" ref="txtSaleVATSum2" class="control-label">
                                            <span>납품부가세</span>
                                        </label>
                                        <input type="text" id="txtSaleVATSum2" class="form-control text-right" style="background-color: #EAEAEA;" readonly="readonly"
                                               v-model="PurchaseProductDetail.SaleVATSum" @click="removeReadonly" />
                                    </div>
                                </section>

                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="cbDlvConfirmYN2" class="control-label">
                                            <span>납품확인서<br> 수령여부</span>
                                        </label>
                                        <select class="form-control" id="cbDlvConfirmYN2" name="cbDlvConfirmYN2"
                                                v-model="PurchaseProductDetail.DlvConfirmYN"
                                                @change="selectBoxChangedEvent">
                                            <option value="Y">Y</option>
                                            <option value="N">N</option>
                                        </select>
                                    </div>
                                </section>

                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtOrderCorpNm1" class="control-label">
                                            <span><i class="glyphicon glyphicon-asterisk"></i>매입처명</span>
                                        </label>
                                        <div class="cols-btns-cont">
                                            <input type="text" id="txtCompanyNm" class="form-control txt" disabled="disabled" v-model="PurchaseProductDetail.PurchaseCompanyNm" />
                                            <input type="hidden" id="hideCompanyCd" v-model="PurchaseProductDetail.PurchaseCompanyCd" />
                                        </div>
                                        <div class="cols-btns">
                                            <a href="#" id="btnPerson" class="btn btn-default" @click.prevent="searchCompany">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i> 검색
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtCompanyManager" class="control-label">
                                            <span>매입처 담당자</span>
                                        </label>
                                        <input type="text" id="txtCompanyManager" ref="txtCompanyManager" class="form-control txt" maxlength="3" v-model="PurchaseProductDetail.CompanyManager" />
                                    </div>
                                </section>

                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtCompanyTel" class="control-label">
                                            <span>매입처 연락처</span>
                                        </label>
                                        <input type="text" id="txtCompanyTel" ref="txtCompanyTel" class="form-control txt" v-model="PurchaseProductDetail.CompanyTel" />
                                    </div>
                                </section>

                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtDlvDueYMD2" class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>납품예정일
                                            </span>
                                        </label>
                                        <DxDateBox id="txtDlvDueYMD2" v-model="PurchaseProductDetail.DlvDueYMD" class="form-control txt" type="date" display-format="yyyy-MM-dd"></DxDateBox>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtDlvEndYMD2" class="control-label">
                                            <span>납품완료일</span>
                                        </label>
                                        <DxDateBox id="txtDlvEndYMD2" v-model="PurchaseProductDetail.DlvEndYMD" class="form-control txt" type="date" display-format="yyyy-MM-dd"></DxDateBox>
                                    </div>
                                </section>
                                <!--하단과 상단의 공백-->
                                <section class="cols-wrap">
                                    <div><label></label></div>
                                </section>
                                <!--/줄간격을 띄운다-->
                                <div class="panel-group" id="accordion">
                                    <section class="cols-wrap">
                                        <!--라이센스-->
                                        <div class="panel panel-default">
                                            <div class="panel-heading" style="display: flex; align-items: center; justify-content: space-between;">
                                                <div data-toggle="collapse" data-parent="#accordion" href="#colap_Arcodian1" style="width: 80%;">
                                                    <i class="glyphicon glyphicon-plus"></i>
                                                    <strong> 라이센스</strong>
                                                </div>
                                                <div>
                                                    <a href="#" id="btnChldInst" class="btn btn-sm btn-default" title="추가" @click.prevent="addLicense">
                                                        <span class="glyphicon glyphicon-plus"></span> 추가
                                                    </a>
                                                    <a href="#" id="btnChldRmve" class="btn btn-sm btn-default" title="삭제" @click.prevent="removeLicense">
                                                        <span class="glyphicon glyphicon-minus"></span> 삭제
                                                    </a>
                                                </div>
                                            </div>
                                            <div id="colap_Arcodian1" class="panel-collapse collapse in" style="overflow-y: auto; max-height: 170px;">
                                                <div class="panel-body">
                                                    <section class="tbl-contents">
                                                        <div class="table-responsive">
                                                            <table class="table tbl-list table-hover table-bordered" id="tb1" ref="tb1">
                                                                <colgroup>
                                                                    <col class="chk" />
                                                                    <col />
                                                                    <col />
                                                                    <col />
                                                                    <col />
                                                                    <col />
                                                                </colgroup>
                                                                <thead>
                                                                    <tr>
                                                                        <th class="chk table-colwid15">선택</th>
                                                                        <th scope="col" class="text-center" hidden>No</th>
                                                                        <th scope="col" class="text-center table-colwid15">Version</th>
                                                                        <th scope="col" class="text-center table-colwid15">Edition</th>
                                                                        <th scope="col" class="text-center table-colwid15">Licenses</th>
                                                                        <th scope="col" class="text-center table-colwid15">InstallKey</th>
                                                                        <th scope="col" class="text-center table-colwid15">비고</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-if="LicenseList.length == 0">
                                                                        <td colspan="6" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                                    </tr>
                                                                    <tr v-else v-for="(license, index) in LicenseList">
                                                                        <td class="chk">
                                                                            <input type="checkbox" name="cbItem" v-model="license.IsChecked" />
                                                                        </td>
                                                                        <td class="text-center" hidden>
                                                                            <input type="hidden" v-model="license.ItemNo" />
                                                                        </td>
                                                                        <td class="text-center">
                                                                            <input type="text" name="txtVersion2" class="form-c ontrol text-left" v-model="license.Version" />
                                                                        </td>
                                                                        <td class="text-center">
                                                                            <select class="form-control" id="selEdition2" name="selEdition2" v-model="license.Edition">
                                                                                <option value="">== 선택 ==</option>
                                                                                <option v-for="item in EditionCodeList" v-bind:value="item.Code">
                                                                                    {{item.Name}}
                                                                                </option>
                                                                            </select>
                                                                        </td>
                                                                        <td class="text-center">
                                                                            <select class="form-control" id="selLicense2" name="selLicense2" v-model="license.License">
                                                                                <option value="">== 선택 ==</option>
                                                                                <option v-if="license.Edition == '0719010'" v-for="item in EntLicenseCodeList" v-bind:value="item.Code">
                                                                                    {{item.Name}}
                                                                                </option>
                                                                                <option v-if="license.Edition == '0719020'" v-for="item in StdLicenseCodeList" v-bind:value="item.Code">
                                                                                    {{item.Name}}
                                                                                </option>
                                                                            </select>
                                                                        </td>
                                                                        <td class="text-center" hidden>
                                                                            <input type="text" name="selEtc1hd" class="form-control text-left" v-model="license.Etc1" />
                                                                        </td>
                                                                        <td class="text-center">
                                                                            <input type="text" name="txtInstallKey2" class="form-control text-left" v-model="license.InstallKey" />
                                                                        </td>
                                                                        <td class="text-center">
                                                                            <input type="text" name="txtEtc2" class="form-control text-left" v-model="license.LicenseDesc" />
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </fieldset>
                        </form>
                    </section>
                </div>
                <div class="btn-align modal-footer">
                    <div v-show="this.$parent.$parent.CanSave" class="btn-group">
                        <button type="button" id="btnItemSave" class="btn btn-primary" @click.prevent="saveProductDetail">
                            <i class="glyphicon glyphicon-save"></i> 저장
                        </button>
                    </div>
                    <div v-show="this.$parent.$parent.CanDelete" class="btn-group">
                        <button v-show="PageActionType == 'V'" type="button" id="btnItemRmve" class="btn btn-warning" @click.Prevent="removeProductData">
                            <i class="glyphicon glyphicon-edit"></i> 삭제
                        </button>
                    </div>
                    <div class="btn-group">
                        <button type="button" class="btn btn-danger" @click.Prevent="close">
                            <i class="glyphicon glyphicon-remove"></i> 취소
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <CP1030P ref="CP1030P"></CP1030P>
        <CP1060P ref="CP1060P"></CP1060P>
    </div>
</template>

<script>
    import { DxDateBox, DxCalendarOptions } from 'devextreme-vue/date-box';
    import CP1060P from '@/components/libs/popup/CP1060P';
    import CP1030P from '@/components/libs/popup/CP1030P';

    export default {
        name: 'PM1041E',
        components: {
            DxDateBox, DxCalendarOptions, CP1060P, CP1030P,
        },
        data() {
            return {
                CallbackFunction: null,
                // 상세 입력 모드 - 신규(I)/수정(V)
                PageActionType: 'I',
                // 매입상품 상세 객체
                PurchaseProductDetail: new Object(),
                // 라이선스 조회 조건
                ProductLicenseTerm: {
                    Arg: 'L',
                    CorpCd: '',
                    ProjectCd: '',
                    CompanyCd: '',
                    SeqNo: 0
                },
                // 라이선스 목록
                LicenseList: new Array(),
                // 삭제할 라이선스 목록
                RemoveLicenseList: new Array(),
                // 부가세 수정여부
                ModifiedVat: false,
            }
        },
        watch: {
            'PurchaseProductDetail.VatIncludeYn': function (newData) {

                if (newData === 'N') {

                    this.autoCalculateVat();
                }
            }
        },
        beforeMount() {

            // Edition 공통코드 목록
            this.EditionCodeList = $.scriptBase.getCommonCode('CODE000', '0719000', '', '');
            // 전체 라이선스 공통코드 목록
            this.LicenseCodeList = $.scriptBase.getCommonCode('CODE000', '0721000', '', '');
            // 엔터프라이스 라이선스 공통코드 목록
            this.EntLicenseCodeList = this.LicenseCodeList.filter((item) => item.Etc1 == 'Ent');
            // 스탠다드 라이선스 공통코드 목록
            this.StdLicenseCodeList = this.LicenseCodeList.filter((item) => item.Etc1 == 'Std');
            // 매입품 유형 공통코드 목록
            this.ProductTypeList = $.scriptBase.getCommonCode('CODE000', '0735000', '', '');
        },
        methods: {

            //#region init
            reset() {

                Object.assign(this.$data, this.$options.data());
            },
            //#endregion

            //#region event
            open(callbackFunction, selectedObj) {

                this.CallbackFunction = callbackFunction;

                this.PurchaseProductDetail = {

                    Arg: 'PI',
                    UserId: this.$store.getters.getEmpId,
                    CorpCd: this.$store.getters.getCompanyCode,
                    CompanyCd: '',
                    CompanyNm: '',
                    ProjectCd: '',
                    ProjectNm: '',
                    SeqNo: -1,
                    ProductNm: null,
                    ProductType: '',
                    UnitCnt: 0,
                    ListPriceSum: 0,
                    SalePriceSum: 0,
                    VatIncludeYn: 'Y',
                    SaleVATSum: 0,
                    DlvDueYMD: new Date(),
                    DlvEndYMD: null,
                    DlvConfirmYN: 'N',
                    PurchaseCompanyCd: '',
                    PurchaseCompanyNm: '',
                    CompanyManager: null,
                    CompanyTel: null,
                }

                if (selectedObj) {

                    this.PageActionType = 'V';
                    this.PurchaseProductDetail = Object.assign({}, selectedObj);

                    this.PurchaseProductDetail.Arg = 'PI';
                    this.PurchaseProductDetail.UserId = this.$store.getters.getEmpId;
                    this.PurchaseProductDetail.ListPriceSum = this.PurchaseProductDetail.ListPriceSum.toLocaleString('ko-KR');
                    this.PurchaseProductDetail.SalePriceSum = this.PurchaseProductDetail.SalePriceSum.toLocaleString('ko-KR');
                    if (this.PurchaseProductDetail.SaleVATSum != 0) this.PurchaseProductDetail.SaleVATSum = this.PurchaseProductDetail.SaleVATSum.toLocaleString('ko-KR');

                    this.ProductLicenseTerm.CorpCd = this.PurchaseProductDetail.CorpCd;
                    this.ProductLicenseTerm.CompanyCd = this.PurchaseProductDetail.CompanyCd;
                    this.ProductLicenseTerm.ProjectCd = this.PurchaseProductDetail.ProjectCd;
                    this.ProductLicenseTerm.SeqNo = this.PurchaseProductDetail.SeqNo;

                    this.getLicenseList();
                } else {

                    this.PageActionType = 'I';
                    this.LicenseList = new Array();
                }

                $("#PM1041E_Modal").modal("show");
            },
            close() {

                this.PurchaseProductDetail = new Object();
                this.LicenseList = new Array();
                this.RemoveLicenseList = new Array();

                // readonly 속성 세팅
                txtSaleVATSum2.setAttribute('readonly', 'readonly');
                this.ModifiedVat = false;

                $("#PM1041E_Modal").modal("hide");
            },
            // 구입금액 숫자만 입력, 콤마 처리
            inputPrice(e, obj) {

                let value = e.target._value;

                if (isNaN(e.key)) {

                    this.PurchaseProductDetail[obj] = 0;
                } else {

                    value = Number(value.replaceAll(',', ''));
                    this.PurchaseProductDetail[obj] = value.toLocaleString('ko-KR');

                    if (obj == 'SalePriceSum' && this.PurchaseProductDetail.VatIncludeYn == 'N') {

                        this.autoCalculateVat();
                    }
                }
            },
            // 라이선스 추가 버튼
            addLicense() {

                if (this.LicenseList == null || this.LicenseList.length == 0) {

                    this.LicenseList = new Array();
                }

                this.LicenseList.push({

                    CRUD: 'C',
                    Arg: 'CI',
                    UserId: this.$store.getters.getEmpId,
                    CorpCd: this.$store.getters.getCompanyCode,
                    CompanyCd: this.PurchaseProductDetail.CompanyCd,
                    CompanyNm: this.PurchaseProductDetail.CompanyNm,
                    ProjectCd: this.PurchaseProductDetail.ProjectCd,
                    ProjectNm: this.PurchaseProductDetail.ProjectNm,
                    SeqNo: this.PurchaseProductDetail.SeqNo,
                    ItemNo: null,
                    Version: null,
                    Edition: '',
                    License: '',
                    InstallKey: null,
                    LicenseDesc: null,
                    EditName: null,
                    ELicenseName: null,
                    Etc1: null,
                    IsChecked: false,
                });
            },
            // 라이선스 삭제 버튼
            removeLicense() {

                // 체크박스 선택 여부 확인
                let check = this.LicenseList.some((item) => item.IsChecked == true);

                if (check == false) {

                    alert('삭제할 항목이 없습니다.');
                    return;
                }

                // 돔 객체
                let tableData = this.$refs.tb1.tBodies[0];

                this.LicenseList.forEach((item, index) => {

                    if (item.IsChecked == true) {

                        // DB에서 삭제할 라이선스 목록 세팅
                        if (item.CRUD == 'R') {

                            this.RemoveLicenseList.push(item);
                        }
                        // 삭제 플래그 세팅
                        item.Arg = 'CD';
                        // 선택 항목 돔 객체에서 삭제
                        tableData.deleteRow(index);
                    }
                })
            },
            //#endregion

            //#region service

            // 라이센스 목록 조회
            getLicenseList() {

                var parent = this;

                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/SelectProductLicense';
                url = url.toRealServiceUrl();

                var obj = new Object();
                obj.jsonString = JSON.stringify(this.ProductLicenseTerm);

                var successCallback = function (data) {

                    var table = JSON.parse(data.d).Table;
                    parent.LicenseList = (table.length > 0) ? table : new Array();

                    if (table.length > 0) {

                        parent.LicenseList.forEach((item) => {

                            item.Arg = 'CI';
                            item.CRUD = 'R';
                            item.UserId = parent.$store.getters.getEmpId;
                            item.IsChecked = false;
                        })
                    }
                }
                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 매입상품 상세 저장
            saveProductDetail() {

                var parent = this;

                // 매입상품 저장 유효성 검사
                if (!this.PurchaseProductDetail.ProjectNm) {

                    alert('프로젝트를 입력해 주십시오.');
                    $(this.$refs.txtProjectNm2).focus();
                    return;
                }
                if (!this.PurchaseProductDetail.CompanyNm) {

                    alert('거래처를 입력해 주십시오.');
                    $(this.$refs.txtCompanyNm2).focus();
                    return;
                }
                if (!this.PurchaseProductDetail.ProductNm) {

                    alert('품목을 입력해 주십시오.');
                    $(this.$refs.txtProductNm2).focus();
                    return;
                }
                if (!this.PurchaseProductDetail.ProductType) {

                    alert('품목 유형을 선택해 주십시오.');
                    $(this.$refs.cbProductType).focus();
                    return;
                }
                if (!this.PurchaseProductDetail.SalePriceSum) {

                    alert('매입금액을 입력해 주십시오.');
                    $(this.$refs.txtSalePriceSum2).focus();
                    return;
                }
                if (this.ModifiedVat == true) {

                    let vatCal = Number(this.PurchaseProductDetail.SalePriceSum.toNumber()) * 0.1;
                    // 오차범위 = 절대값(수정한 부가세 - Price * 0.1)
                    let marginOfError = Math.abs(Number(this.PurchaseProductDetail.SaleVATSum.toNumber()) - vatCal);

                    // 오차가 100원 이상일 때
                    if (marginOfError > 100) {

                        alert("납품부가세액을 다시 확인해 주십시오.");
                        $('#txtSaleVATSum2').focus();
                        return;
                    }
                }
                if (!this.PurchaseProductDetail.PurchaseCompanyNm) {

                    alert('매입처명을 입력해 주십시오.');
                    $(this.$refs.txtCompanyNm).focus();
                    return;
                }
                if (!this.PurchaseProductDetail.DlvDueYMD) {

                    alert('납품예정일을 입력해 주십시오.');
                    $(this.$refs.txtDlvDueYMD2).focus();
                    return;
                }

                // 저장 플래그로 객체 가공
                this.LicenseList = this.LicenseList.filter((item) => item.Arg == 'CI');

                // 라이선스 저장 유효성 검사
                if (this.LicenseList.length > 0) {

                    for (let i = 0; i < this.LicenseList.length; i++) {

                        let item = this.LicenseList[i];

                        if (item.Version === null || item.Version === '') {

                            alert('Version을 입력하세요.');
                            document.querySelectorAll('#tb1 tbody tr')[i].querySelectorAll('td')[2].querySelectorAll('input')[0].focus();
                            return;
                        }

                        if (item.Edition === null || item.Edition === '') {

                            alert('Edition을 입력하세요.');
                            document.querySelectorAll('#tb1 tbody tr')[i].querySelectorAll('td')[3].querySelectorAll('select')[0].focus();
                            return;
                        }

                        if (item.License === null || item.License === '') {

                            alert('License를 입력하세요.');
                            document.querySelectorAll('#tb1 tbody tr')[i].querySelectorAll('td')[4].querySelectorAll('select')[0].focus();
                            return;
                        }

                        if (item.InstallKey === null || item.InstallKey === '') {

                            alert('InstallKey를 입력하세요.');
                            document.querySelectorAll('#tb1 tbody tr')[i].querySelectorAll('td')[6].querySelectorAll('input')[0].focus();
                            return;
                        }
                    }
                }

                // 라이선스 DB 삭제
                if (this.RemoveLicenseList.length > 0) {

                    var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/RemoveLicense'.toRealServiceUrl();
                    var obj = new Object();

                    obj.jsonString = JSON.stringify(this.RemoveLicenseList);

                    var successCallback = function (data) {

                        if (data.d > 0) {

                            // 삭제 객체 초기화
                            parent.RemoveLicenseList = new Array();
                            parent.LicenseList.forEach((item) => item.IsChecked = false);
                        } else {

                            alert('라이선스 삭제에 실패했습니다. 잠시 후 다시 시도해 주십시오.');
                            return;
                        }
                    }
                    $.scriptBase.executeAjaxAsync(url, obj, successCallback);
                }

                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/SavePurchaseProduct'.toRealServiceUrl();
                var obj = new Object();

                obj.jsonString = JSON.stringify(this.PurchaseProductDetail);
                obj.jsonStringLicenseList = JSON.stringify(this.LicenseList);

                var successCallback = function (data) {

                    if (data.d > 0) {

                        if (parent.PageActionType === 'I') {

                            alert('등록되었습니다.');
                            parent.close();
                        } else {

                            alert('저장되었습니다.');
                            parent.close();
                        }
                    } else {

                        alert('저장에 실패했습니다. 잠시 후 다시 시도해 주십시오.');
                        return;
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 매입상품 상세 삭제
            removeProductData: function () {

                if (this.PurchaseProductDetail === null && this.PageActionType === "I") {

                    alert("삭제할 정보가 존재하지 않습니다.");
                    return;
                }

                if (confirm("해당 매입과 관련 정보를 모두 삭제하시겠습니까?")) {

                    var parent = this;

                    var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/RemovePurchaseProduct'.toRealServiceUrl();
                    var obj = new Object();

                    this.PurchaseProductDetail.Arg = 'PD';

                    obj.jsonString = JSON.stringify(this.PurchaseProductDetail);

                    // 서비스호출 성공 콜백 함수
                    var successCallback = function (data) {

                        if (data.d > 0) {

                            alert('삭제되었습니다.');
                            parent.close();
                        } else {

                            alert('삭제에 실패하였습니다. 잠시 후 다시 시도해 주세요.');
                        }
                    }

                    $.scriptBase.executeAjaxAsync(url, obj, successCallback);
                }
            },
            //#endregion

            //#region 콤보박스 핸들러

            // VAT 포함 콤보박스 이벤트 핸들러
            selectBoxVatChangedEvent(event) {

                // readonly 속성 세팅
                txtSaleVATSum2.setAttribute('readonly', 'readonly');
                this.ModifiedVat = false;
                // 포함
                if (event.target.value === 'N') {

                    // 부가세 자동계산
                    this.autoCalculateVat();
                    this.PurchaseProductDetail.VatIncludeYn = 'N';
                }
                // 별도
                else if (event.target.value === 'Y') {

                    this.PurchaseProductDetail.SaleVATSum = '0';
                    this.PurchaseProductDetail.VatIncludeYn = 'Y';
                }
                // 영세율
                else if (event.target.value === 'Z') {

                    this.PurchaseProductDetail.SaleVATSum = '0';
                    this.PurchaseProductDetail.VatIncludeYn = 'Z';
                }
                // 면세
                else if (event.target.value === 'F') {

                    this.PurchaseProductDetail.SaleVATSum = '0';
                    this.PurchaseProductDetail.VatIncludeYn = 'F';
                }
            },
            // 납품확인서 수령여부 콤보박스 변경 이벤트 핸들러
            selectBoxChangedEvent(event) {

                this.PurchaseProductDetail.DlvConfirmYN = (event.target.value === 'N') ? 'N' : 'Y';
            },
            // 부가세 readonly 속성 제거
            removeReadonly() {

                let readonly = txtSaleVATSum2.getAttribute('readonly');

                // readonly이고, 부가세 별도일 때
                if (readonly == 'readonly' && this.PurchaseProductDetail.VatIncludeYn == 'N') {

                    if (confirm('부가세를 수정하시겠습니까?')) {

                        // 부가세 수정 가능
                        document.getElementById('txtSaleVATSum2').removeAttribute('readonly');
                        this.ModifiedVat = true;
                    }
                }
            },
            // 부가세 자동계산
            autoCalculateVat() {

                if (this.ModifiedVat == false) {

                    if (this.PurchaseProductDetail.SalePriceSum != null && this.PurchaseProductDetail.SalePriceSum != '0') {

                        let vat = Number(this.PurchaseProductDetail.SalePriceSum.toNumber()) * 0.1;
                        this.PurchaseProductDetail.SaleVATSum = Math.round(vat).toPriceNumber();
                    } else {

                        this.PurchaseProductDetail.SaleVATSum = null;
                    }
                }
            },
            //#endregion

            //#region Modal

            // 팝업_프로젝트 검색 메서드
            popSearchProjectCode() {

                var parent = this;

                var successCallback = function (result) {

                    // 결과값을 추출 후 돔객체에 바인딩
                    parent.PurchaseProductDetail.ProjectNm = result.ProjectNm;
                    parent.PurchaseProductDetail.ProjectCd = result.ProjectCd;
                    parent.PurchaseProductDetail.CompanyNm = result.CompanyNm;
                    parent.PurchaseProductDetail.CompanyCd = result.CompanyCd;
                }
                this.$refs.CP1060P.open(successCallback);
            },

            // 업체 검색
            searchCompany() {

                var parent = this;

                var successCallback = function (result) {

                    parent.PurchaseProductDetail.PurchaseCompanyNm = result.CompanyNm;
                    parent.PurchaseProductDetail.PurchaseCompanyCd = result.CompanyCd;
                }

                this.$refs.CP1030P.open(successCallback);
            },

            //#endregion
        }
    }
</script>