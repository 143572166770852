<template>
    <div class="modal fade" id="CP1021P_Modal" tabindex="-1" role="dialog" aria-labelledby="defaultModal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" @click.Prevent="close">
                        <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                    </button>
                    <h3 class="modal-title">인력 검색</h3>
                </div>
                <div class="modal-body">
                    <section class="sch-wrap">
                        <form role="form">
                            <fieldset>
                                <legend>검색폼</legend>
                                <section class="sch-cont">
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="CP1021P_selEmpType" class="control-label"><span>인력 구분</span></label>
                                            <select class="form-control" v-model="EmpInfo.InputEmpType" id="CP1021P_selEmpType" name="CP1021P_selEmpType">
                                                <option value="">= 전체 =</option>
                                                <option v-for="item in ManPower" v-bind:value="item.Code">{{item.Name}}</option>
                                            </select>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="CP1021P_txtSearch" class="control-label"><span>인력명</span></label>
                                            <input id="CP1021P_txtSearch" type="text" v-model="EmpInfo.InputEmpNm" name="CP1021P_txtSearch" class="form-control txt" @keypress.enter.prevent="keypressEvent" />
                                        </div>
                                        <div class="cols col-2">
                                            <label for="CP1021P_selSection" class="control-label"><span>직급</span></label>
                                            <select class="form-control" v-model="EmpInfo.InputSection" id="CP1021P_selSection" name="CP1021P_selSection">
                                                <option value="">= 선택 =</option>
                                                <option v-for="item in CompanyRank" v-bind:value="item.Code">{{item.Name}}</option>
                                            </select>
                                        </div>
                                    </section>
                                </section>
                                <section class="sch-btn">
                                    <a id="CP1021P_btnSearch" class="btn btn-primary btn-row2 " @click.prevent="btnSearchClicked"><span><i class="glyphicon glyphicon-search"></i> 검색</span></a>
                                </section>
                            </fieldset>
                        </form>
                    </section>
                    <section class="tbl-contents">
                        <!--리스트 영역-->
                        <div class="total">
                            <p><i class="glyphicon glyphicon-folder-close"></i><span>전체: <span id="spTotCnt">{{TotalCount}}</span>건</span></p>
                        </div>
                        <div class="table-responsive">
                            <table class="table tbl-list tbl-center table-hover table-bordered">
                                <caption>테이블</caption>
                                <colgroup>
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col">사원 구분</th>
                                        <th scope="col">사원명</th>
                                        <th scope="col">직급</th>
                                        <th scope="col">이메일</th>
                                        <th scope="col">핸드폰번호</th>
                                    </tr>
                                </thead>
                                <tbody id="result">
                                    <tr v-if="ManPowerList === null">
                                        <td colspan="5">조회된 정보가 없습니다.</td>
                                    </tr>
                                    <tr v-else v-for="item in ManPowerList" style="cursor:pointer;" v-on:click="rowClickEvent(item)">
                                        <td>{{item.CodeName}}</td>
                                        <td>{{item.EmpNm}}</td>
                                        <td>{{item.JobGradeNm}}</td>
                                        <td>{{item.EMail}}</td>
                                        <td>{{item.MobileNo}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                    <div class="text-center">
                        <ul id="CP1021P_pager" class="pagination"> </ul>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default btn-sm" @click.Prevent="close"><span class="glyphicon glyphicon-remove"></span>취소(닫기)</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CP1021P',
        data() {
            return {
                ManPower: null,         // 인력구분 리스트
                CompanyRank: null,      // 직급 리스트
                CurrentPage: 1,         // 현재 페이지
                TopSize: 10,            // 화면에 조회되는 개수
                TotalCount: 0,          // 데이터 총 개수
                ManPowerList: null,     // 조회된 인력 리스트
                CompanyCode: this.$store.getters.getCompanyCode, // 컴페니 코드
                CallbackFunction: null, // 인력 선택 결과를 넘겨줄 콜백함수,
                EmpInfo: {
                    InputEmpNm: '',
                    InputEmpType: '',
                    InputSection: ''
                }
            }
        },
        beforeMount() {
            // 인력구분 조회
            this.ManPower = $.scriptBase.getCommonCode('CODE000', '0305000', '', '')
            // 직급 조회
            this.CompanyRank = $.scriptBase.getCommonCode('CODE000', '0303000', '', '')
        },
        methods: {
            // 인력 검색 팝업 오픈
            open: function (callbackFunction, condition, empInfo) {
                var parent = this

                this.CallbackFunction = callbackFunction

                if (empInfo) {
                    this.EmpInfo.InputEmpNm = empInfo;
                }

                this.CurrentPage = 1
                this.TotalCount = 0
                this.EmpInfo.InputEmpType = ''
                this.EmpInfo.Section = ''
                this.ManPowerList = null

                this.ShowData()

                $('#CP1021P_Modal').modal('show')
            },
            close: function () {
                $('#CP1021P_Modal').modal('hide')
            },
            // 인력 검색 메서드
            ShowData: function () {

                var parent = this

                var url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/GetEmpList'
                url = url.toRealServiceUrl()
                var obj = new Object()
                obj.prm = new Array()
                obj.prm.push(this.CompanyCode)                  // 회사코드
                obj.prm.push(this.EmpInfo.InputEmpNm)           // 사원명
                obj.prm.push(this.EmpInfo.Section)              // 직급선택
                obj.prm.push(this.CurrentPage)                  // 선택페이지
                obj.prm.push(this.TopSize)                      // 전체페이지
                obj.prm.push(this.EmpInfo.InputEmpType)         // 사원 구분

                // ajax 성공 시 실행되는 핸들러 정의
                var ajaxSuccessHandler = function (data) {

                    var table = JSON.parse(data.d).Table
                    var table1 = JSON.parse(data.d).Table1

                    if (table.length > 0) {

                        parent.ManPowerList = table
                    }
                    else {

                        parent.ManPowerList = null
                    }

                    if (table1.length > 0) {

                        parent.TotalCount = table1[0].TotCnt
                    }
                    else {

                        parent.TotalCount = 0
                    }

                    // 페이징 초기화
                    $("#CP1021P_pager").pager({
                        pager: "#CP1021P_pager",
                        pagerMode: "text",
                        totalItemCount: parent.TotalCount,
                        recordPerPage: parent.TopSize,
                        currentPage: parent.CurrentPage,
                        pageIndexChanged: parent.pageChangeEvent,
                        prev10Text: "&laquo;",
                        next10Text: "&raquo;",
                        selectedPageFontColor: "green"
                    })
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)

            },
            // 페이지 변경 이벤트 핸들러
            pageChangeEvent: function (page) {
                this.CurrentPage = page
                this.ShowData()
            },
            // 검색 버튼 클릭 이벤트 핸들러
            btnSearchClicked: function () {
                this.CurrentPage = 1
                this.ShowData()
            },
            // 테이블Row 클릭 이벤트 핸들러
            rowClickEvent: function (item) {

                // 호출한 뷰로 선택한 인력정보 데이터 전달
                this.CallbackFunction(item)

                $("#CP1021P_Modal").modal("hide")
            },
            // 검색어 필드에서 엔터키 입력 시 조회되도록 하는 키업 이벤트
            keypressEvent(event) {

                if (event.which == 13) {

                    this.ShowData()
                }
            }
        }
    }
</script>