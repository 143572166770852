<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <article class="sch-wrap">
                    <form role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="selTrgtYY" class="control-label">
                                            <span>대상년도</span>
                                        </label>
                                        <select id="selTrgtYY" class="form-control" v-model="ExpenseBudgetApplySelectTerm.TargetYear">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="year in TargetYearList" v-bind:value="year" v-bind:selected="moment(new Date()).year() === year">
                                                {{year}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="selAprvDvsCd" class="control-label">
                                            <span>프로젝트</span>
                                        </label>
                                        <div class="cols-btns-cont pad-r150">
                                            <input type="text" id="txtProjectNm" class="form-control" readonly="readonly" v-model="ExpenseBudgetApplySelectTerm.ProjectName" />
                                            <input type="hidden" id="hidProjectCd" v-model="ExpenseBudgetApplySelectTerm.ProjectCode" />
                                            <input type="hidden" id="hidCompanyCd" v-model="ExpenseBudgetApplySelectTerm.ProjectCompanyCode" />

                                        </div>
                                        <div class="cols-btns">
                                            <a href="#" id="btnShowProject" class="btn btn-default" @click.prevent="searchProject(false)">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i> 검색
                                                </span>
                                            </a>
                                            <a href="#" id="btnSrchInit" class="btn btn-default"
                                               @click.prevent="function(e){
                                                    ExpenseBudgetApplySelectTerm.ProjectName = null
                                                    ExpenseBudgetApplySelectTerm.ProjectCode = null
                                                    ExpenseBudgetApplySelectTerm.ProjectCompanyCode = null
                                               }">
                                                <span>
                                                    <i class="glyphicon glyphicon-repeat"></i> 초기화
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="selAprvDvsCd" class="control-label">
                                            <span>승인구분</span>
                                        </label>
                                        <select class="form-control" id="selAprvDvsCd" name="selAprvDvsCd" v-model="ExpenseBudgetApplySelectTerm.ApprovalCategoryCode">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in AprvDvsCodeList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                </section>
                            </section>
                            <section class="sch-btn">
                                <a v-show="this.$parent.CanSearch" href="#" id="btnSearchData" class="btn btn-primary btn-row2" @click.prevent="getExpenseBudgetApply()">
                                    <span>
                                        <i class="glyphicon glyphicon-search"></i> 조회
                                    </span>
                                </a>
                            </section>
                        </fieldset>
                    </form>
                </article>
                <article class="view-btns-wrap ">
                    <div class="btn-group-justified">
                        <a v-show="this.$parent.CanNew" href="#" id="btnInstData" class="btn btn-default" @click.prevent="addExpenseBudgetApply">
                            <span>
                                <i class="glyphicon glyphicon-plus"></i> 추가
                            </span>
                        </a>
                    </div>
                </article>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">경비예산신청</p>
                </div>
                <div>
                    <!--리스트 영역-->
                    <section class="tbl-contents">
                        <div class="alert alert-info total">
                            <p>
                                <i class="glyphicon glyphicon-folder-close"></i>
                                전체: <span id="spTotCnt">{{ExpenseBudgetApplyListTotalCount}}</span>건
                            </p>
                        </div>
                        <div class="table-responsive">
                            <table class="table table tbl-list tbl-center table-hover table-bordered" id="tb_main">
                                <caption>테이블</caption>
                                <colgroup>
                                    <col class="table-colwid30" />
                                    <col class="table-colwid10" />
                                    <col class="table-colwid10" />
                                    <col class="table-colwid10" />
                                    <col class="table-colwid20" />
                                    <col class="table-colwid10" />
                                    <col class="table-colwid5" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col">프로젝트명</th>
                                        <th scope="col">거래처명</th>
                                        <th scope="col">계약일자</th>
                                        <th scope="col">프로젝트기간</th>
                                        <th scope="col">담당PM</th>
                                        <th scope="col">예산총액</th>
                                        <th scope="col">승인구분</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="ExpenseBudgetApplyList === null">
                                        <td colspan="7" class="text-center no-data">조회된 정보가 없습니다.</td>
                                    </tr>
                                    <tr v-else v-for="expenseBudgetApply in ExpenseBudgetApplyList" style="cursor:pointer;" @click="getExpenseBudgetApplyDetail(expenseBudgetApply)">
                                        <td style="text-align:left;">{{expenseBudgetApply.ProjectNm}}</td>
                                        <td style="text-align:left;">{{expenseBudgetApply.CompanyNm}}</td>
                                        <td>{{expenseBudgetApply.ContractYMDReplace}}</td>
                                        <td>{{expenseBudgetApply.ProjectDT}}</td>
                                        <td>{{expenseBudgetApply.MarkPM}}</td>
                                        <td style="text-align:right;">{{expenseBudgetApply.BudgetTotAmtReplace}}</td>
                                        <td>{{expenseBudgetApply.ApprvalNm}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="text-center">
                            <ul ref="ulpager" class="pagination"></ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <div class="modal fade" ref="AC1051E_Modal" tabindex="-1" role="dialog" aria-labelledby="AC1051E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                        <h3 class="modal-title">경비예산 신청</h3>
                    </div>
                    <div class="modal-body">
                        <section class="add-wrap">
                            <form>
                                <fieldset>
                                    <legend>..</legend>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtProjectNm2" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>프로젝트명
                                                </span>
                                            </label>
                                            <div class="cols-btns-cont">
                                                <input type="text" ref="txtProjectNm2" class="form-control txt" readonly="readonly" v-model="ExpenseBudgetApplyDetail.ProjectNm" />
                                                <input type="hidden" id="hidProjectCd2" v-model="ExpenseBudgetApplyDetail.ProjectCd" />
                                                <input type="hidden" id="hidCompanyCd2" v-model="ExpenseBudgetApplyDetail.CompanyCd" />
                                                <input type="hidden" id="hidSeqNo2" v-model="ExpenseBudgetApplyDetail.SeqNo" />
                                            </div>
                                            <div class="cols-btns">
                                                <a href="#" id="btnSearchProject2" class="btn btn-default"
                                                   v-show="this.$parent.CanSave 
                                                   && (
                                                        PageActionType === 'I' 
                                                        || (ExpenseBudgetApplyDetail.RegtEmpNo === this.$parent.EmpId && ExpenseBudgetApplyDetail.ApprovalCd === '0117010')
                                                   )"
                                                   @click.prevent="searchProject(true)">
                                                    <span>
                                                        <i class="glyphicon glyphicon-search"></i> 검색
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                        <section class="cols-wrap">
                                            <div class="cols col-2">
                                                <label for="txtTrgtYY2" class="control-label asterisk">
                                                    <span>대상년도</span>
                                                </label>
                                                <div class="input-group" v-bind:data-date="CurrentDateYYMMDD" data-date-format="yyyy-mm-dd">
                                                    <input type="text" id="txtTrgtYY2" class="form-control" value="" readonly="readonly" v-model="ExpenseBudgetApplyDetail.TrgtYY" />
                                                    <span class="input-group-addon">
                                                        <span class="glyphicon glyphicon-calendar"></span>
                                                    </span>
                                                </div>
                                            </div>
                                        </section>
                                        <!--첫번째라인-->
                                        <section class="cols-wrap">
                                            <div class="cols col-2">
                                                <label for="txtReqDate2" class="control-label asterisk">
                                                    <span>신청일</span>
                                                </label>
                                                <div class="input-group" v-bind:data-date="CurrentDateYYMMDD" data-date-format="yyyy-mm-dd">
                                                    <input type="text" id="txtReqDate2" class="form-control" value="" readonly="readonly"
                                                           v-bind:value="ExpenseBudgetApplyDetail.RegtDate"
                                                           @input="function(e){
                                                                ExpenseBudgetApplyDetail.RegtDate = e.target.value.toHyphenationFormatYMD()
                                                                e.target.value = $event.target.value.toHyphenationFormatYMD()
                                                           }" />
                                                    <span class="input-group-addon">
                                                        <span class="glyphicon glyphicon-calendar"></span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="cols col-2">
                                                <label for="txtTotalPrice2" class="control-label">
                                                    <span>기본예산총액</span>
                                                </label>
                                                <input type="text" id="txtTotalPrice2" class="form-control text-right" readonly="readonly" v-model="ExpenseBudgetApplyDetail.BudgetTotAmt" />
                                            </div>
                                        </section>
                                        <!--두번째라인-->
                                        <section class="cols-wrap">
                                            <div class="cols col-2">
                                                <label for="txtEmpNm2" class="control-label">
                                                    <span>신청자</span>
                                                </label>
                                                <input type="text" id="txtEmpNm2" readonly="readonly" class="form-control text-left" v-model="ExpenseBudgetApplyDetail.MarkPM" />
                                                <input type="hidden" id="hidEmpNo2" v-model="ExpenseBudgetApplyDetail.RegtEmpNo" />
                                            </div>
                                            <div class="cols col-2">
                                                <label for="txtWorkPrice2" class="control-label">
                                                    <span>승인단계</span>
                                                </label>
                                                <input type="text" id="txtAprvNm2" class="form-control text-left" readonly="readonly" v-model="ExpenseBudgetApplyDetail.ApprvalNm" />
                                                <input type="hidden" id="hidAprvNm2" v-model="ExpenseBudgetApplyDetail.ApprovalCd" />
                                            </div>
                                        </section>
                                        <!--세번째라인-->
                                        <section class="cols-wrap">
                                            <div class="cols">
                                                <label for="txtEtcTxt2" class="control-label">
                                                    <span>특이사항</span>
                                                </label>
                                                <textarea id="txtEtc2" class="form-control" style="height:100px; resize:none" v-model="ExpenseBudgetApplyDetail.Remark"></textarea>
                                            </div>
                                        </section>
                                        <!--네번째라인-->
                                    </section>
                                    <!--아코디언 접이식부-->
                                    <div class="panel-group" id="accordion">
                                        <section class="cols-wrap">
                                            <!--예산기준-->
                                            <div class="panel panel-default">
                                                <div class="panel-heading" style="cursor:pointer" data-toggle="collapse" data-parent="#accordion" href="#colap_Arcodian1">
                                                    <p>
                                                        <i class="glyphicon glyphicon-plus"></i>
                                                        <span>&nbsp;예산 기준</span>
                                                    </p>
                                                </div>
                                                <div id="colap_Arcodian1" class="panel-collapse collapse">
                                                    <div class="panel-body">
                                                        <section class="cols-wrap">
                                                            <div class="cols col-2">
                                                                <label for="txtEating2" class="control-label">
                                                                    <span>1인 식대</span>
                                                                </label>
                                                                <div class="cols-btns-cont">
                                                                    <input type="text" ref="txtEating2" class="form-control text-right" title="1인 식대 x 월평균 근무일수 x 투입MM" 
                                                                           v-bind:value="ExpenseBudgetApplyDetail.FoodExp"
                                                                           @input="function(e){
                                                                                ExpenseBudgetApplyDetail.FoodExp = e.target.value.toPriceString()
                                                                                e.target.value = e.target.value.toPriceString()
                                                                           }"/>
                                                                </div>
                                                                <div class="cols-btns">
                                                                    <a href="#" id="btnEating2" class="btn btn-default" @click.prevent="autoCalculateExpenses(1, true)"
                                                                       v-show="PageActionType === 'I' || (ExpenseBudgetApplyDetail.RegtEmpNo === this.$parent.EmpId && ExpenseBudgetApplyDetail.ApprovalCd === '0117010')">
                                                                        <span class="glyphicon glyphicon-save"></span>자동
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div class="cols col-2">
                                                                <label for="txtJobDoDate2" class="control-label">
                                                                    <span>월 평균 근무일수</span>
                                                                </label>
                                                                <input type="text" ref="txtJobDoDate2" class="form-control text-right" title="1인 식대 x 월평균 근무일수 x 투입MM" 
                                                                       v-bind:value="ExpenseBudgetApplyDetail.WorkDayAvg" 
                                                                       @input="function(e){
                                                                            ExpenseBudgetApplyDetail.WorkDayAvg = e.target.value.toNumber()
                                                                            e.target.value = e.target.value.toNumber()
                                                                       }"/>
                                                            </div>
                                                        </section>
                                                        <!--1번-->
                                                        <section class="cols-wrap">
                                                            <div class="cols col-2">
                                                                <label for="txtDrinkPrice2" class="control-label">
                                                                    <span>1인 회식비</span>
                                                                </label>
                                                                <input type="text" ref="txtDrinkPrice2" class="form-control text-right" title="회식비 x 월 회식 횟수 x 투입MM" 
                                                                       v-bind:value="ExpenseBudgetApplyDetail.TogetherExp" 
                                                                       @input="function(e){
                                                                            ExpenseBudgetApplyDetail.TogetherExp = e.target.value.toPriceString()
                                                                            e.target.value = e.target.value.toPriceString()
                                                                       }"/>
                                                            </div>
                                                            <div class="cols col-2">
                                                                <label for="txtDrinkCount2" class="control-label">
                                                                    <span>월 회식횟수</span>
                                                                </label>
                                                                <div class="cols-btns-cont">
                                                                    <input type="text" ref="txtDrinkCount2" class="form-control text-right" title="1인 식대 x 월 회식 횟수 x 투입MM" 
                                                                           v-bind:value="ExpenseBudgetApplyDetail.TogetherCnt" 
                                                                           @input="function(e){
                                                                                ExpenseBudgetApplyDetail.TogetherCnt = e.target.value.toNumber()
                                                                                e.target.value = e.target.value.toNumber()
                                                                           }"/>
                                                                </div>
                                                                <div class="cols-btns">
                                                                    <a href="#" id="btnDrink2" class="btn btn-default" @click.prevent="autoCalculateExpenses(2, true)"
                                                                       v-show="PageActionType === 'I' || (ExpenseBudgetApplyDetail.RegtEmpNo === this.$parent.EmpId && ExpenseBudgetApplyDetail.ApprovalCd === '0117010')">
                                                                        <span class="glyphicon glyphicon-save"></span>자동
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </section>
                                                        <!--2번-->
                                                        <section class="cols-wrap">
                                                            <div class="cols col-2">
                                                                <label for="txtTaxi2" class="control-label">
                                                                    <span>교통비</span>
                                                                </label>
                                                                <input type="text" ref="txtTaxi2" class="form-control text-right" title="교통비 x 월 지급 횟수 x 투입MM" 
                                                                       v-bind:value="ExpenseBudgetApplyDetail.TransExp" 
                                                                       @input="function(e){
                                                                            ExpenseBudgetApplyDetail.TransExp = e.target.value.toPriceString()
                                                                            e.target.value = e.target.value.toPriceString()
                                                                       }"/>
                                                            </div>
                                                            <div class="cols col-2">
                                                                <label for="txtTaxiCnt2" class="control-label">
                                                                    <span>월 지급 횟수</span>
                                                                </label>
                                                                <div class="cols-btns-cont">
                                                                    <input type="text" ref="txtTaxiCnt2" class="form-control text-right" title="교통비 x 월 지급 횟수 x 투입MM" 
                                                                           v-bind:value="ExpenseBudgetApplyDetail.TransExpPayCnt" 
                                                                           @input="function(e){
                                                                                ExpenseBudgetApplyDetail.TransExpPayCnt = e.target.value.toNumber()
                                                                                e.target.value = e.target.value.toNumber()
                                                                           }"/>
                                                                </div>
                                                                <div class="cols-btns">
                                                                    <a href="#" id="btnTaxi2" class="btn btn-default" @click.prevent="autoCalculateExpenses(3, true)"
                                                                       v-show="PageActionType === 'I' || (ExpenseBudgetApplyDetail.RegtEmpNo === this.$parent.EmpId && ExpenseBudgetApplyDetail.ApprovalCd === '0117010')">
                                                                        <span class="glyphicon glyphicon-save"></span>자동
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </section>
                                                        <!--3번-->
                                                        <br />
                                                        <section class="cols-wrap">
                                                            <div class="cols col-2">
                                                                <label for="txtDream2" class="control-label">
                                                                    <span>숙박비</span>
                                                                </label>
                                                                <div class="cols-btns-cont">
                                                                    <input type="text" ref="txtDream2" class="form-control text-right" title="숙박비 각각 지급" 
                                                                           v-bind:value="ExpenseBudgetApplyDetail.LodgeExp" 
                                                                           @input="function(e){
                                                                                ExpenseBudgetApplyDetail.LodgeExp = e.target.value.toPriceString()
                                                                                e.target.value = e.target.value.toPriceString()
                                                                           }"/>
                                                                </div>
                                                                <div class="cols-btns">
                                                                    <a href="#" id="btnDream2" class="btn btn-default" @click.prevent="autoCalculateExpenses(4, true)"
                                                                       v-show="PageActionType === 'I' || (ExpenseBudgetApplyDetail.RegtEmpNo === this.$parent.EmpId && ExpenseBudgetApplyDetail.ApprovalCd === '0117010')">
                                                                        <span class="glyphicon glyphicon-save"></span>자동
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div class="cols col-2">
                                                                <label for="txtPropose2" class="control-label">
                                                                    <span>접대비</span>
                                                                </label>
                                                                <div class="cols-btns-cont">
                                                                    <input type="text" ref="txtPropose2" class="form-control text-right" title="접대비 각각 지급" 
                                                                           v-bind:value="ExpenseBudgetApplyDetail.EntertainExp" 
                                                                           @input="function(e){
                                                                                ExpenseBudgetApplyDetail.EntertainExp = e.target.value.toPriceString()
                                                                                e.target.value = e.target.value.toPriceString()
                                                                           }"/>
                                                                </div>
                                                                <div class="cols-btns">
                                                                    <a href="#" id="btnPropose2" class="btn btn-default" @click.prevent="autoCalculateExpenses(5, true)"
                                                                       v-show="PageActionType === 'I' || (ExpenseBudgetApplyDetail.RegtEmpNo === this.$parent.EmpId && ExpenseBudgetApplyDetail.ApprovalCd === '0117010')">
                                                                        <span class="glyphicon glyphicon-save"></span>자동
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </section>
                                                        <!--4번-->
                                                        <section class="cols-wrap">
                                                            <div class="cols col-2">
                                                                <label for="txtSomo2" class="control-label">
                                                                    <span>소모품비</span>
                                                                </label>
                                                                <div class="cols-btns-cont">
                                                                    <input type="text" ref="txtSomo2" class="form-control text-right" title="소모품비 각각 지급" 
                                                                           v-bind:value="ExpenseBudgetApplyDetail.SuppliesExp" 
                                                                           @input="function(e){
                                                                                ExpenseBudgetApplyDetail.SuppliesExp = e.target.value.toPriceString()
                                                                                e.target.value = e.target.value.toPriceString()
                                                                           }"/>
                                                                </div>
                                                                <div class="cols-btns">
                                                                    <a href="#" id="btnSomo2" class="btn btn-default" @click.prevent="autoCalculateExpenses(6, true)"
                                                                       v-show="PageActionType === 'I' || (ExpenseBudgetApplyDetail.RegtEmpNo === this.$parent.EmpId && ExpenseBudgetApplyDetail.ApprovalCd === '0117010')">
                                                                        <span class="glyphicon glyphicon-save"></span>자동
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div class="cols col-2">
                                                                <label for="txtEtcPrice2" class="control-label">
                                                                    <span>기타비</span>
                                                                </label>
                                                                <div class="cols-btns-cont">
                                                                    <input type="text" ref="txtEtcPrice2" class="form-control text-right" title="기타비 각각 지급" 
                                                                           v-bind:value="ExpenseBudgetApplyDetail.EtcExp" 
                                                                           @input="function(e){
                                                                                ExpenseBudgetApplyDetail.EtcExp = e.target.value.toPriceString()
                                                                                e.target.value = e.target.value.toPriceString()
                                                                           }"/>
                                                                </div>
                                                                <div class="cols-btns">
                                                                    <a href="#" id="btnEtcPrice2" class="btn btn-default" @click.prevent="autoCalculateExpenses(7, true)"
                                                                       v-show="PageActionType === 'I' || (ExpenseBudgetApplyDetail.RegtEmpNo === this.$parent.EmpId && ExpenseBudgetApplyDetail.ApprovalCd === '0117010')">
                                                                        <span class="glyphicon glyphicon-save"></span>자동
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </section>
                                                        <!--5번-->
                                                    </div>
                                                </div>
                                            </div>
                                            <!--예산편성-->
                                            <div class="panel panel-default">
                                                <div class="panel-heading" style="cursor:pointer" data-toggle="collapse" data-parent="#accordion" href="#colap_Arcodian2">
                                                    <p>
                                                        <i class="glyphicon glyphicon-plus"></i>
                                                        <span>&nbsp;예산 편성</span>
                                                    </p>
                                                </div>
                                                <div id="colap_Arcodian2" class="panel-collapse collapse">
                                                    <div class="panel-body">
                                                        <section id="secCsvSet" class="cols-wrap"
                                                                 v-show="PageActionType === 'I' || (ExpenseBudgetApplyDetail.RegtEmpNo === this.$parent.EmpId && ExpenseBudgetApplyDetail.ApprovalCd === '0117010')">
                                                            <div class="cols">
                                                                <label for="add1-1" class="control-label">
                                                                    <span>CSV 양식</span>
                                                                </label>
                                                                <div class="cols-btns-cont pad-r165">
                                                                    <input type="file" name="inputCsvImportFile" ref="inputCsvImportFile"
                                                                           @change="function(e){
                                                                                BudgetOrganizeCsvFiles = e.target.files
                                                                           }" />
                                                                </div>
                                                                <div class="cols-btns">
                                                                    <a v-show="this.$parent.CanSave" href="#" id="btnCsvImpt" class="btn btn-default wix-90"
                                                                       @click.prevent="readCsvFileData">
                                                                        <span>
                                                                            <i class="glyphicon glyphicon-pencil"></i> 입력
                                                                        </span>
                                                                    </a>
                                                                    <a v-show="this.$parent.CanSave" href="#" id="btnCsvStyleDown" class="btn btn-default"
                                                                       click.prevent="alert('구현중입니다.')">
                                                                        <span>
                                                                            <i class="glyphicon glyphicon-save"></i> 다운
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </section>
                                                        <section class="tbl-contents">
                                                            <div class="table-responsive">
                                                                <table class="table tbl-list table-hover table-bordered" id="tb_sub1">
                                                                    <colgroup>
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col width="25%" />
                                                                        <col class="table-colwid10" />
                                                                    </colgroup>
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" class="text-center">년/월</th>
                                                                            <th scope="col" class="text-center">투입MM</th>
                                                                            <th scope="col" class="text-center">식대</th>
                                                                            <th scope="col" class="text-center">회식비</th>
                                                                            <th scope="col" class="text-center">교통비</th>
                                                                            <th scope="col" class="text-center">숙박비</th>
                                                                            <th scope="col" class="text-center">접대비</th>
                                                                            <th scope="col" class="text-center">소모품비</th>
                                                                            <th scope="col" class="text-center">기타</th>
                                                                            <th scope="col" class="text-center">비고</th>
                                                                            <th scope="col" class="text-center">합계</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-if="BudgetOrganizeList === null">
                                                                            <td colspan="11" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                                        </tr>
                                                                        <tr v-else v-for="budgetOrganize in BudgetOrganizeList">
                                                                            <td v-show="!budgetOrganize.IsTotalRow">{{budgetOrganize.DateMM}}</td>
                                                                            <td v-show="budgetOrganize.IsTotalRow">총합</td>

                                                                            <td v-show="!budgetOrganize.IsTotalRow">
                                                                                <input type="text" name="txtItemMM" class="form-control text-right number" readonly="readonly"
                                                                                       v-bind:value="budgetOrganize.MM"
                                                                                       @input="function(e){
                                                                                            budgetOrganize.MM = e.target.value.toDecimal()
                                                                                            e.target.value = e.target.value.toDecimal()
                                                                                       }" />
                                                                            </td>
                                                                            <td v-show="budgetOrganize.IsTotalRow" style="text-align:right;">{{budgetOrganize.MM}}</td>

                                                                            <td v-show="!budgetOrganize.IsTotalRow" class="text-center">
                                                                                <input type="text" name="txtItem02" class="form-control text-right number"
                                                                                       v-bind:value="budgetOrganize.MealsRepl"
                                                                                       @input="budgetOrganizeInputEvent(1, budgetOrganize)" />
                                                                            </td>
                                                                            <td v-show="budgetOrganize.IsTotalRow" style="text-align:right;">{{budgetOrganize.MealsRepl}}</td>

                                                                            <td v-show="!budgetOrganize.IsTotalRow" class="text-center">
                                                                                <input type="text" name="txtItem12" class="form-control text-right number"
                                                                                       v-bind:value="budgetOrganize.TogetherJoyRepl"
                                                                                       @input="budgetOrganizeInputEvent(2, budgetOrganize)" />
                                                                            </td>
                                                                            <td v-show="budgetOrganize.IsTotalRow" style="text-align:right;">{{budgetOrganize.TogetherJoyRepl}}</td>

                                                                            <td v-show="!budgetOrganize.IsTotalRow" class="text-center">
                                                                                <input type="text" name="txtItem22" class="form-control text-right number"
                                                                                       v-bind:value="budgetOrganize.TransportationRepl"
                                                                                       @input="budgetOrganizeInputEvent(3, budgetOrganize)" />
                                                                            </td>
                                                                            <td v-show="budgetOrganize.IsTotalRow" style="text-align:right;">{{budgetOrganize.TransportationTotal}}</td>

                                                                            <td v-show="!budgetOrganize.IsTotalRow" class="text-center">
                                                                                <input type="text" name="txtItem32" class="form-control text-right number"
                                                                                       v-bind:value="budgetOrganize.RoomChargeRepl"
                                                                                       @input="budgetOrganizeInputEvent(4, budgetOrganize)" />
                                                                            </td>
                                                                            <td v-show="budgetOrganize.IsTotalRow" style="text-align:right;">{{budgetOrganize.RoomChargeTotal}}</td>

                                                                            <td v-show="!budgetOrganize.IsTotalRow" class="text-center">
                                                                                <input type="text" name="txtItem42" class="form-control text-right number"
                                                                                       v-bind:value="budgetOrganize.EntertainmentRepl"
                                                                                       @input="budgetOrganizeInputEvent(5, budgetOrganize)" />
                                                                            </td>
                                                                            <td v-show="budgetOrganize.IsTotalRow" style="text-align:right;">{{budgetOrganize.EntertainmentTotal}}</td>

                                                                            <td v-show="!budgetOrganize.IsTotalRow" class="text-center">
                                                                                <input type="text" name="txtItem52" class="form-control text-right number"
                                                                                       v-bind:value="budgetOrganize.SuppliesRepl"
                                                                                       @input="budgetOrganizeInputEvent(6, budgetOrganize)" />
                                                                            </td>
                                                                            <td v-show="budgetOrganize.IsTotalRow" style="text-align:right;">{{budgetOrganize.SuppliesTotal}}</td>

                                                                            <td v-show="!budgetOrganize.IsTotalRow" class="text-center">
                                                                                <input type="text" name="txtItem62" class="form-control text-right number"
                                                                                       v-bind:value="budgetOrganize.OthersRepl"
                                                                                       @input="budgetOrganizeInputEvent(7, budgetOrganize)" />
                                                                            </td>
                                                                            <td v-show="budgetOrganize.IsTotalRow" style="text-align:right;">{{budgetOrganize.OthersTotal}}</td>

                                                                            <td v-show="!budgetOrganize.IsTotalRow" class="text-center">
                                                                                <input type="text" name="txtETC2" class="form-control text-left" v-model="budgetOrganize.Remark" />
                                                                            </td>
                                                                            <td v-show="budgetOrganize.IsTotalRow"></td>

                                                                            <td v-show="!budgetOrganize.IsTotalRow" class="text-center">
                                                                                <input type="text" name="txtRowAllCnt2" class="form-control text-right" readonly="readonly" v-model="budgetOrganize.Sum" />
                                                                            </td>
                                                                            <td v-show="budgetOrganize.IsTotalRow" style="text-align:right;">{{budgetOrganize.Sum}}</td>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </section>
                                                        <section class="cols-wrap add-wrap">
                                                            <div class="cols col-2">
                                                                <label for="txtProjectNm" class="control-label">
                                                                    <span>
                                                                        <i class="glyphicon glyphicon-asterisk"></i>담당이사
                                                                    </span>
                                                                </label>
                                                                <div class="cols-btns-cont">
                                                                    <input type="text" id="txtPMNm2" class="form-control txt" readonly="readonly" v-model="ExpenseBudgetApplyDetail.ChrgEmpNm" />
                                                                    <input type="hidden" ref="hidPMCd2" v-model="ExpenseBudgetApplyDetail.ChrgEmpNo" />
                                                                </div>
                                                                <div class="cols-btns">
                                                                    <a href="#" id="btnPM2" class="btn btn-default" @click.prevent="searchManager">
                                                                        <span>
                                                                            <i class="glyphicon glyphicon-search"></i> 검색
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </section>
                                                        <label class="control-label">
                                                            <span>
                                                                ※ 예산 총액에 따른 결재 단계
                                                                <br /> &nbsp;1억 미만 : 담당이사(CFO참조)
                                                                <br />&nbsp;1억 이상 ~ 3억 미만 : 담당이사 -> CFO
                                                                <br /> &nbsp;3억 이상 : 담당이사 -> CFO -> CEO
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--프로젝트 투입인력-->
                                            <div class="panel panel-default">
                                                <div class="panel-heading" style="cursor:pointer" data-toggle="collapse" data-parent="#accordion" href="#colap_Arcodian3">
                                                    <p>
                                                        <i class="glyphicon glyphicon-plus"></i>
                                                        <span>&nbsp;프로젝트 투입인력</span>
                                                    </p>
                                                </div>
                                                <div id="colap_Arcodian3" class="panel-collapse collapse">
                                                    <div class="panel-body">
                                                        <section class="tbl-contents">
                                                            <div class="table-responsive">
                                                                <table class="table tbl-list table-hover table-bordered" id="tb_sub2">
                                                                    <colgroup>
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                    </colgroup>
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" class="text-center">소속구분</th>
                                                                            <th scope="col" class="text-center">이름</th>
                                                                            <th scope="col" class="text-center">투입시작일</th>
                                                                            <th scope="col" class="text-center">투입종료일</th>
                                                                            <th scope="col" class="text-center">역할</th>
                                                                            <th scope="col" class="text-center">등급</th>
                                                                            <th scope="col" class="text-center">투입MM</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-if="ExpenseBudgetProjectMemberList === null">
                                                                            <td colspan="7" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                                        </tr>
                                                                        <tr v-else v-for="member in ExpenseBudgetProjectMemberList">
                                                                            <td style="text-align:center;">{{member.BelongDvsName}}</td>
                                                                            <td style="text-align:center;">{{member.Name}}</td>
                                                                            <td style="text-align:center;">{{member.WorkStartYMD}}</td>
                                                                            <td style="text-align:center;">{{member.WorkEndYMD}}</td>
                                                                            <td style="text-align:center;">{{member.RoleName}}</td>
                                                                            <td style="text-align:center;">{{member.TechClsName}}</td>
                                                                            <td style="text-align:right;">{{member.WorkMM}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    <section class="btn-align">
                                        <label v-show="ExpenseBudgetApplyDetail.BudgetTotAmt != null 
                                               && ExpenseBudgetApplyDetail.BudgetTotAmt != ''" id="txtHelpKnowTxt" class="control-label">
                                            <span v-show="TotalBudget < 100000000">결제선 : 담당이사(CFO참조)</span>
                                            <span v-show="TotalBudget >= 100000000 && TotalBudget < 300000000">결제선 : 담당이사 -> CFO </span>
                                            <span v-show="TotalBudget >= 300000000">결제선 : 담당이사 -> CFO -> CEO</span>
                                        </label>
                                        <a href="#" id="btnAprvReq2" class="btn btn-primary"
                                           v-show="this.$parent.CanSave && (PageActionType === 'I' || (ExpenseBudgetApplyDetail.RegtEmpNo === this.$parent.EmpId && ExpenseBudgetApplyDetail.ApprovalCd === '0117010'))"
                                           @click.prevent="saveExpenseBudgetApply(true)">
                                            <span>
                                                <i class="glyphicon glyphicon-save"></i>승인요청
                                            </span>
                                        </a>
                                        <a href="#" id="btnItemSave2" class="btn btn-warning" @click.prevent="saveExpenseBudgetApply(false)"
                                           v-show="this.$parent.CanSave && (PageActionType === 'I' || (ExpenseBudgetApplyDetail.RegtEmpNo === this.$parent.EmpId && ExpenseBudgetApplyDetail.ApprovalCd === '0117010'))">
                                            <span>
                                                <i class="glyphicon glyphicon-edit"></i>저장
                                            </span>
                                        </a>
                                        <a href="#" class="btn btn-danger" data-dismiss="modal">
                                            <span>
                                                <i class="glyphicon glyphicon-remove"></i>취소
                                            </span>
                                        </a>
                                    </section>
                                </fieldset>
                            </form>
                        </section>
                    </div>
                </div>
            </div>
        </div>
        <CP1020P ref="CP1020P"></CP1020P>
        <CP1060P ref="CP1060P"></CP1060P>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle'
    import CP1020P from '@/components/libs/popup/CP1020P'
    import CP1060P from '@/components/libs/popup/CP1060P'
    export default {
        name: 'AC1050R',
        components: {
            ViewTitle, CP1020P, CP1060P
        },
        data() {
            return {
                AprvDvsCodeList: null,                  // 승인구분 공통코드 목록
                ExpenseBudgetApplyList: null,           // 경비예산신청 목록
                ExpenseBudgetProjectMemberList: null,   // 프로젝트 투입인력 목록
                BudgetOrganizeList: null,               // 예산편성 목록
                // 상세정보 객체
                ExpenseBudgetApplyDetail: {
                    Arg: null,
                    UserId: this.$parent.EmpId,
                    CorpCd: this.$parent.CompanyCode,
                    CompanyCd: null,
                    CompanyNm: null,
                    ProjectCd: null,
                    ProjectNm: null,
                    ContractYMD: null,
                    ContractYMDReplace: null,
                    ProjectDT: null,
                    TrgtYY: null,
                    SeqNo: null,
                    BudgetType: null,
                    BudgetTotAmt: null,
                    BudgetTotAmtReplace: null,
                    ApprovalStepType: null,
                    ApprovalCd: null,
                    ApprvalNm: null,
                    ChrgEmpNo: null,
                    ChrgEmpNm: null,
                    Remark: null,
                    RegtDate: null,
                    RegtEmpNo: null,
                    MarkPM: null,
                    FoodExp: null,
                    TogetherExp: null,
                    TogetherCnt: null,
                    TransExp: null,
                    TransExpPayCnt: null,
                    LodgeExp: null,
                    EntertainExp: null,
                    SuppliesExp: null,
                    EtcExp: null,
                    WorkDayAvg: null
                },
                // 조회조건 객체
                ExpenseBudgetApplySelectTerm: {
                    Arg: 'L',
                    UserId: this.$parent.EmpId,
                    CompanyCode: this.$parent.CompanyCode,
                    TargetYear: this.moment(new Date()).year(),
                    ProjectName: null,
                    ProjectCompanyCode: null,
                    ProjectCode: null,
                    ApprovalCategoryCode: '',
                    TopSize: 10,
                    CurrentPage: 1
                },
                // 상세 조회조건 객체
                ExpenseBudgetApplyDetailSelectTerm: {
                    Arg: 'DL',
                    UserId: this.$parent.EmpId,
                    CompanyCode: this.$parent.CompanyCode,
                    TargetYear: null,
                    ProjectCompanyCode: null,
                    ProjectCode: null,
                    SeqNo: null
                },
                // 프로젝트 멤버 조회조건 객체
                ProjectMemberSelectTerm: {
                    Arg: 'CM',
                    CompanyCode: this.$parent.CompanyCode,
                    ProjectCompanyCode: null,
                    ProjectCode: null
                },
                // 예산편성 상세 객체
                BudgetOrganizeDetail: {
                    IsTotalRow: false,
                    TrgtYYMM: null,
                    DateMM: null,
                    MM: null,
                    Meals: null,
                    MealsRepl: null,
                    SeqMeals: null,
                    Remark: null,
                    TogetherJoy: null,
                    TogetherJoyRepl: null,
                    SeqTogetherJoy: null,
                    Transportation: null,
                    TransportationRepl: null,
                    TransportationTotal: null,
                    SeqTransportation: null,
                    RoomCharge: null,
                    RoomChargeRepl: null,
                    RoomChargeTotal: null,
                    SeqRoomCharge: null,
                    Entertainment: null,
                    EntertainmentRepl: null,
                    EntertainmentTotal: null,
                    SeqEntertainment: null,
                    Supplies: null,
                    SuppliesRepl: null,
                    SuppliesTotal: null,
                    SeqSupplies: null,
                    Others: null,
                    OthersRepl: null,
                    OthersTotal: null,
                    SeqOthers: null,
                    Sum: null
                },
                BudgetOrganizeCsvFiles: null,   // 예산편성 CSV 파일
                ExpenseBudgetApplyListTotalCount: 0,
                TargetYearList: new Array(),
                CurrentDateYYMMDD: this.moment(new Date()).format('YYYY-MM-DD'),
                TotalBudget: 0                     // 기본예산총액
            }
        },
        beforeMount() {

            // 공통코드 조회
            this.AprvDvsCodeList = $.scriptBase.getCommonCode('CODE000', '0117000', '', '')

            var startYear = this.moment(new Date()).add(1, 'year').year()
            var endYear = this.moment(new Date(2010, 1, 1, 0, 0, 0)).year()

            // 대상년도 목록 값 설정
            for (var i = startYear; i >= endYear; i--) {

                this.TargetYearList.push(i)
            }
        },
        mounted() {

            var parent = this

            $('.form_date').off('changeDate')
            $('.form_date').datetimepicker({
                weekStart: 1,
                todayBtn: 1,
                autoclose: 1,
                todayHighlight: 1,
                startView: 2,
                minView: 2,
                forceParse: 0,
                keyboardNavigation: true,
                language: 'kr',
            }).on('changeDate', function (e) {

                var targetInputElement = e.target.querySelector('input')
                var selectDate = parent.moment(e.date).format('YYYY-MM-DD')

                switch (targetInputElement.id) {
                    //case 'txtStrDate': {

                    //    parent.ExpenseRequestSelectTerm.StartDate = selectDate
                    //    break
                    //}
                    //case 'txtEndDate': {

                    //    parent.ExpenseRequestSelectTerm.EndDate = selectDate
                    //    break
                    //}
                    //case 'txtUseYMD2': {

                    //    parent.ExpenseRequestDetail.UseYMD = selectDate
                    //    break
                    //}
                    default:
                }
            })

            if (this.$parent.CanSearch) {

                this.getExpenseBudgetApply()
            }
        },
        watch: {
            BudgetOrganizeCsvFiles: function (newValue) {

                // 예산편성 시 선택한 csv파일이 없으면 목록 초기화
                if (newValue === null || newValue === '' || newValue.length === 0) {

                    this.BudgetOrganizeList = null
                }
            }
        },
        methods: {
            // 예산편성 입력 변경 이벤트
            budgetOrganizeInputEvent: function (index, targetRow) {

                switch (index) {

                    case 1: {

                        targetRow.Meals = event.target.value.toNumber()
                        targetRow.MealsRepl = event.target.value.toPriceString()
                        

                        break
                    }
                    case 2: {

                        targetRow.TogetherJoy = event.target.value.toNumber()
                        targetRow.TogetherJoyRepl = event.target.value.toPriceString()

                        break
                    }
                    case 3: {

                        targetRow.Transportation = event.target.value.toNumber()
                        targetRow.TransportationRepl = event.target.value.toPriceString()

                        break
                    }
                    case 4: {

                        targetRow.RoomCharge = event.target.value.toNumber()
                        targetRow.RoomChargeRepl = event.target.value.toPriceString()

                        break
                    }
                    case 5: {

                        targetRow.Entertainment = event.target.value.toNumber()
                        targetRow.EntertainmentRepl = event.target.value.toPriceString()

                        break
                    }
                    case 6: {

                        targetRow.Supplies = event.target.value.toNumber()
                        targetRow.SuppliesRepl = event.target.value.toPriceString()

                        break
                    }
                    case 7: {

                        targetRow.Others = event.target.value.toNumber()
                        targetRow.OthersRepl = event.target.value.toPriceString()
                        
                        break
                    }
                    default:
                }

                event.target.value = event.target.value.toPriceString()

                // 월별 경비 총합 계산
                targetRow.Sum = (
                    Number(targetRow.Meals)
                    + Number(targetRow.TogetherJoy)
                    + Number(targetRow.Transportation)
                    + Number(targetRow.RoomCharge)
                    + Number(targetRow.Entertainment)
                    + Number(targetRow.Supplies)
                    + Number(targetRow.Others)
                ).toPriceNumber()

                var totalRow = this.BudgetOrganizeList[this.BudgetOrganizeList.length - 1]

                // 총합 행의 대상 값들 초기화
                totalRow.Meals = '0'
                totalRow.TogetherJoy = '0'
                totalRow.TransportationTotal = '0'
                totalRow.RoomChargeTotal = '0'
                totalRow.EntertainmentTotal = '0'
                totalRow.SuppliesTotal = '0'
                totalRow.OthersTotal = '0'
                totalRow.Sum = '0'

                // 총합 행의 경비 항목별 총합 계산
                this.BudgetOrganizeList.forEach(function (object) {

                    if (!object.IsTotalRow) {

                        totalRow.Meals = Number(totalRow.Meals) + Number(object.Meals)
                        totalRow.MealsRepl = totalRow.Meals.toPriceNumber()

                        totalRow.TogetherJoy = Number(totalRow.TogetherJoy) + Number(object.TogetherJoy)
                        totalRow.TogetherJoyRepl = totalRow.TogetherJoy.toPriceNumber()

                        totalRow.TransportationTotal = (Number(totalRow.TransportationTotal.toNumber()) + Number(object.Transportation)).toPriceNumber()
                        totalRow.RoomChargeTotal = (Number(totalRow.RoomChargeTotal.toNumber()) + Number(object.RoomCharge)).toPriceNumber()
                        totalRow.EntertainmentTotal = (Number(totalRow.EntertainmentTotal.toNumber()) + Number(object.Entertainment)).toPriceNumber()
                        totalRow.SuppliesTotal = (Number(totalRow.SuppliesTotal.toNumber()) + Number(object.Supplies)).toPriceNumber()
                        totalRow.OthersTotal = (Number(totalRow.OthersTotal.toNumber()) + Number(object.Others)).toPriceNumber()
                    }
                })

                // 경비 총합 계산
                totalRow.Sum = (
                    Number(totalRow.Meals)
                    + Number(totalRow.TogetherJoy)
                    + Number(totalRow.TransportationTotal === null ? '0' : totalRow.TransportationTotal.toNumber())
                    + Number(totalRow.RoomChargeTotal === null ? '0' : totalRow.RoomChargeTotal.toNumber())
                    + Number(totalRow.EntertainmentTotal === null ? '0' : totalRow.EntertainmentTotal.toNumber())
                    + Number(totalRow.SuppliesTotal === null ? '0' : totalRow.SuppliesTotal.toNumber())
                    + Number(totalRow.OthersTotal === null ? '0' : totalRow.OthersTotal.toNumber())
                ).toPriceNumber()

                // 기본예산 총액 설정
                this.ExpenseBudgetApplyDetail.BudgetTotAmt = totalRow.Sum
                this.TotalBudget = totalRow.Sum.toNumber()
            },
            // csv 파일 읽기 완료 후 호출되는 콜백 함수
            csvReadCallback: function (result) {

                var parent = this

                // 예산신청 목록 변수 초기화
                this.BudgetOrganizeList = new Array()

                // 총합 행 객체 생성
                var totalRowObject = Object.assign({}, parent.BudgetOrganizeDetail)
                totalRowObject.IsTotalRow = true

                // csv 파일 데이터 설정
                result.forEach(function (item) {

                    var tempObject = Object.assign({}, parent.BudgetOrganizeDetail)

                    tempObject.DateMM = item[0]
                    tempObject.MM = item[1]
                    tempObject.MealsRepl = item[2].toPriceString()
                    tempObject.TogetherJoyRepl = item[3].toPriceString()
                    tempObject.TransportationRepl = item[4]
                    tempObject.RoomChargeRepl = item[5].toPriceString()
                    tempObject.EntertainmentRepl = item[6].toPriceString()
                    tempObject.SuppliesRepl = item[7].toPriceString()
                    tempObject.OthersRepl = item[8]

                    // 예산편성 목록에 추가적으로 필요한 항목(합계 등) 추가
                    tempObject.IsTotalRow = false
                    tempObject.MealsTotal = null
                    tempObject.TogetherJoyTotal = null
                    tempObject.TransportationTotal = null
                    tempObject.RoomChargeTotal = null
                    tempObject.EntertainmentTotal = null
                    tempObject.SuppliesTotal = null
                    tempObject.OthersTotal = null
                    tempObject.Sum = 0

                    parent.BudgetOrganizeList.push(tempObject)

                    // 투입MM 항목 총합
                    totalRowObject.MM = Number(totalRowObject.MM) + Number(tempObject.MM)
                })

                // 총합 행 추가
                this.BudgetOrganizeList.push(totalRowObject)

                // 경비항목별 자동계산 실행
                parent.autoCalculateExpenses(3, false)
                parent.autoCalculateExpenses(4, false)
                parent.autoCalculateExpenses(5, false)
                parent.autoCalculateExpenses(6, false)
                parent.autoCalculateExpenses(7, false)
            },
            // csv파일 데이터 읽기
            readCsvFileData: function () {

                alert('구현중 입니다.')

                //var projectCode = this.ExpenseBudgetApplyDetail.ProjectCd

                //if (projectCode === null || projectCode === '') {

                //    alert('프로젝트를 선택하여 주세요.')
                //    this.$refs.txtProjectNm2.focus()
                //}
                //else {

                //    if (confirm('CSV의 값으로 내용을 대체하시겠습니까?')) {

                //        this.csvHandler.read(this.BudgetOrganizeCsvFiles, this.csvReadCallback)
                //    }
                //}
            },
            // 담당이사 조회
            searchManager() {

                var parent = this
                var successCallback = function (result) {

                    parent.ExpenseBudgetApplyDetail.ChrgEmpNm = result.EmpNm
                    parent.ExpenseBudgetApplyDetail.ChrgEmpNo = result.EmpNo
                }

                this.$refs.CP1020P.showManPowerPopup(successCallback)
            },
            // 프로젝트 검색
            // isDetail: 상세팝업에서 검색인지 여부
            searchProject: function (isDetail) {

                var parent = this
                var successCallback = function (result) {

                    if (isDetail) {

                        parent.ExpenseBudgetApplyDetail.ProjectNm = result.ProjectNm
                        parent.ExpenseBudgetApplyDetail.ProjectCd = result.ProjectCd
                        parent.ExpenseBudgetApplyDetail.CompanyCd = result.CompanyCd

                        // 대상년도 설정
                        parent.ExpenseBudgetApplyDetail.TrgtYY = result.ContractYMD.substring(0, 4)

                        // 해당 프로젝트 투입인력 조회
                        parent.getExpenseBudgetProjectMember(result.CompanyCd, result.ProjectCd, false)
                    }
                    else {

                        parent.ExpenseBudgetApplySelectTerm.ProjectName = result.ProjectNm
                        parent.ExpenseBudgetApplySelectTerm.ProjectCode = result.ProjectCd
                        parent.ExpenseBudgetApplySelectTerm.ProjectCompanyCode = result.CompanyCd
                    }
                }

                this.$refs.CP1060P.showProjectPopup(successCallback)
            },
            // 각종 경비 자동계산
            // expensesType: 경비구분(1: 식대, 2: 회식비, 3: 교통비, 4: 숙박비, 5: 접대비, 6: 소모품비)
            // isStandard: 예산기준 값으로 자동계산할지 여부 (예산기준을 무시하면 월별합계 총합만 계산)
            autoCalculateExpenses: function (expensesType, isStandard) {

                var parent = this
                var projectCode = parent.ExpenseBudgetApplyDetail.ProjectCd

                this.TotalBudget = 0

                // 프로젝트 선택은 필수
                if (projectCode != null && projectCode != '') {
                    
                    if (this.BudgetOrganizeList != null) {

                        for (var i = 0; i < this.BudgetOrganizeList.length; i++) {

                            var item = this.BudgetOrganizeList[i]

                            if (isStandard) {

                                switch (expensesType) {

                                    case 1: {

                                        if (this.ExpenseBudgetApplyDetail.FoodExp === null
                                            || this.ExpenseBudgetApplyDetail.FoodExp === '') {

                                            alert('1인 식대를 입력하세요.')
                                            this.$refs.txtEating2.focus()

                                            return
                                        }
                                        else if (this.ExpenseBudgetApplyDetail.WorkDayAvg === null
                                            || this.ExpenseBudgetApplyDetail.WorkDayAvg === '') {

                                            alert('월 평균 근무 일수를 입력하세요.')
                                            this.$refs.txtJobDoDate2.focus()

                                            return
                                        }
                                        else {

                                            var mealsResult = Number(item.MM)
                                                * Number(this.ExpenseBudgetApplyDetail.FoodExp.toNumber())
                                                * Number(this.ExpenseBudgetApplyDetail.WorkDayAvg)

                                            item.Meals = mealsResult
                                            item.MealsRepl = mealsResult.toPriceNumber()
                                        }

                                        break
                                    }
                                    case 2: {

                                        if (this.ExpenseBudgetApplyDetail.TogetherExp === null
                                            || this.ExpenseBudgetApplyDetail.TogetherExp === '') {

                                            alert('1인 회식비를 입력하세요.')
                                            this.$refs.txtDrinkPrice2.focus()

                                            return
                                        }
                                        else if (this.ExpenseBudgetApplyDetail.TogetherCnt === null
                                            || this.ExpenseBudgetApplyDetail.TogetherCnt === '') {

                                            alert('월 회식횟수를 입력하세요.')
                                            this.$refs.txtDrinkCount2.focus()

                                            return
                                        }
                                        else {

                                            var togetherResult = Number(item.MM)
                                                * Number(this.ExpenseBudgetApplyDetail.TogetherExp.toNumber())
                                                * Number(this.ExpenseBudgetApplyDetail.TogetherCnt)

                                            item.TogetherJoy = togetherResult
                                            item.TogetherJoyRepl = togetherResult.toPriceNumber()
                                        }

                                        break
                                    }
                                    case 3: {

                                        if (this.ExpenseBudgetApplyDetail.TransExp === null
                                            || this.ExpenseBudgetApplyDetail.TransExp === '') {

                                            alert('교통비를 입력하세요.')
                                            this.$refs.txtTaxi2.focus()

                                            return
                                        }
                                        else if (this.ExpenseBudgetApplyDetail.TransExpPayCnt === null
                                            || this.ExpenseBudgetApplyDetail.TransExpPayCnt === '') {

                                            alert('월 지급횟수를 입력하세요.')
                                            this.$refs.txtTaxiCnt2.focus()

                                            return
                                        }
                                        else {

                                            // 총합 행이면 교통비 총합 계산
                                            // 월단위 행이면 각각 계산
                                            if (item.IsTotalRow) {

                                                // 교통비 총합 초기화
                                                item.TransportationTotal = '0'

                                                this.BudgetOrganizeList.forEach(function (object) {

                                                    // 교통비 총합 계산 (루프 돌때마다 합산)
                                                    item.TransportationTotal = (Number(item.TransportationTotal.toNumber()) + Number(object.Transportation)).toPriceNumber()
                                                })
                                            }
                                            else {

                                                var transportationResult = Number(this.ExpenseBudgetApplyDetail.TransExp.toNumber())
                                                    * Number(this.ExpenseBudgetApplyDetail.TransExpPayCnt)

                                                item.Transportation = transportationResult
                                                item.TransportationRepl = transportationResult.toPriceNumber()
                                            }
                                        }

                                        break
                                    }
                                    case 4: {

                                        if (this.ExpenseBudgetApplyDetail.LodgeExp === null
                                            || this.ExpenseBudgetApplyDetail.LodgeExp === '') {

                                            alert('숙박비를 입력하세요.')
                                            this.$refs.txtDream2.focus()

                                            return
                                        }
                                        else {

                                            // 총합 행이면 숙박비 총합 계산
                                            // 월단위 행이면 각각 계산
                                            if (item.IsTotalRow) {

                                                // 숙박비 총합 초기화
                                                item.RoomChargeTotal = '0'

                                                this.BudgetOrganizeList.forEach(function (object) {

                                                    // 숙박비 총합 계산 (루프 돌때마다 합산)
                                                    item.RoomChargeTotal = (Number(item.RoomChargeTotal.toNumber()) + Number(object.RoomCharge)).toPriceNumber()
                                                })
                                            }
                                            else {

                                                item.RoomCharge = this.ExpenseBudgetApplyDetail.LodgeExp.toNumber()
                                                item.RoomChargeRepl = this.ExpenseBudgetApplyDetail.LodgeExp
                                            }
                                        }

                                        break
                                    }
                                    case 5: {

                                        if (this.ExpenseBudgetApplyDetail.EntertainExp === null
                                            || this.ExpenseBudgetApplyDetail.EntertainExp === '') {

                                            alert('접대비를 입력하세요.')
                                            this.$refs.txtPropose2.focus()

                                            return
                                        }
                                        else {

                                            // 총합 행이면 접대비 총합 계산
                                            // 월단위 행이면 각각 계산
                                            if (item.IsTotalRow) {

                                                // 접대비 총합 초기화
                                                item.EntertainmentTotal = '0'

                                                this.BudgetOrganizeList.forEach(function (object) {

                                                    // 접대비 총합 계산 (루프 돌때마다 합산)
                                                    item.EntertainmentTotal = (Number(item.EntertainmentTotal.toNumber()) + Number(object.Entertainment)).toPriceNumber()
                                                })
                                            }
                                            else {

                                                item.Entertainment = this.ExpenseBudgetApplyDetail.EntertainExp.toNumber()
                                                item.EntertainmentRepl = this.ExpenseBudgetApplyDetail.EntertainExp
                                            }
                                        }

                                        break
                                    }
                                    case 6: {

                                        if (this.ExpenseBudgetApplyDetail.SuppliesExp === null
                                            || this.ExpenseBudgetApplyDetail.SuppliesExp === '') {

                                            alert('소모품비를 입력하세요.')
                                            this.$refs.txtSomo2.focus()

                                            return
                                        }
                                        else {

                                            // 총합 행이면 소모품비 총합 계산
                                            // 월단위 행이면 각각 계산
                                            if (item.IsTotalRow) {

                                                // 소모품비 총합 초기화
                                                item.SuppliesTotal = '0'

                                                this.BudgetOrganizeList.forEach(function (object) {

                                                    // 소모품비 총합 계산 (루프 돌때마다 합산)
                                                    item.SuppliesTotal = (Number(item.SuppliesTotal.toNumber()) + Number(object.Supplies)).toPriceNumber()
                                                })
                                            }
                                            else {

                                                item.Supplies = this.ExpenseBudgetApplyDetail.SuppliesExp.toNumber()
                                                item.SuppliesRepl = this.ExpenseBudgetApplyDetail.SuppliesExp
                                            }
                                        }

                                        break
                                    }
                                    case 7: {

                                        if (this.ExpenseBudgetApplyDetail.EtcExp === null
                                            || this.ExpenseBudgetApplyDetail.EtcExp === '') {

                                            alert('기타비를 입력하세요.')
                                            this.$refs.txtEtcPrice2.focus()

                                            return
                                        }
                                        else {

                                            // 총합 행이면 기타비 총합 계산
                                            // 월단위 행이면 각각 계산
                                            if (item.IsTotalRow) {

                                                // 기타비 총합 초기화
                                                item.OthersTotal = '0'

                                                this.BudgetOrganizeList.forEach(function (object) {

                                                    // 기타비 총합 계산 (루프 돌때마다 합산)
                                                    item.OthersTotal = (Number(item.OthersTotal.toNumber()) + Number(object.Others)).toPriceNumber()
                                                })
                                            }
                                            else {

                                                item.Others = this.ExpenseBudgetApplyDetail.EtcExp.toNumber()
                                                item.OthersRepl = this.ExpenseBudgetApplyDetail.EtcExp
                                            }
                                        }

                                        break
                                    }
                                    default:
                                }
                            }
                            else {

                                switch (expensesType) {
                                    
                                    case 3: {

                                        // 총합 행이면 교통비 총합 계산
                                        // 월단위 행이면 각각 계산
                                        if (item.IsTotalRow) {

                                            // 교통비 총합 초기화
                                            item.TransportationTotal = '0'

                                            this.BudgetOrganizeList.forEach(function (object) {

                                                // 교통비 총합 계산 (루프 돌때마다 합산)
                                                item.TransportationTotal = (Number(item.TransportationTotal.toNumber()) + Number(object.Transportation)).toPriceNumber()
                                            })
                                        }

                                        break
                                    }
                                    case 4: {

                                        // 총합 행이면 숙박비 총합 계산
                                        // 월단위 행이면 각각 계산
                                        if (item.IsTotalRow) {

                                            // 숙박비 총합 초기화
                                            item.RoomChargeTotal = '0'

                                            this.BudgetOrganizeList.forEach(function (object) {

                                                // 숙박비 총합 계산 (루프 돌때마다 합산)
                                                item.RoomChargeTotal = (Number(item.RoomChargeTotal.toNumber()) + Number(object.RoomCharge)).toPriceNumber()
                                            })
                                        }

                                        break
                                    }
                                    case 5: {

                                        // 총합 행이면 접대비 총합 계산
                                        // 월단위 행이면 각각 계산
                                        if (item.IsTotalRow) {

                                            // 접대비 총합 초기화
                                            item.EntertainmentTotal = '0'

                                            this.BudgetOrganizeList.forEach(function (object) {

                                                // 접대비 총합 계산 (루프 돌때마다 합산)
                                                item.EntertainmentTotal = (Number(item.EntertainmentTotal.toNumber()) + Number(object.Entertainment)).toPriceNumber()
                                            })
                                        }

                                        break
                                    }
                                    case 6: {

                                        // 총합 행이면 소모품비 총합 계산
                                        // 월단위 행이면 각각 계산
                                        if (item.IsTotalRow) {

                                            // 소모품비 총합 초기화
                                            item.SuppliesTotal = '0'

                                            this.BudgetOrganizeList.forEach(function (object) {

                                                // 소모품비 총합 계산 (루프 돌때마다 합산)
                                                item.SuppliesTotal = (Number(item.SuppliesTotal.toNumber()) + Number(object.Supplies)).toPriceNumber()
                                            })
                                        }

                                        break
                                    }
                                    case 7: {

                                        // 총합 행이면 기타비 총합 계산
                                        // 월단위 행이면 각각 계산
                                        if (item.IsTotalRow) {

                                            // 기타비 총합 초기화
                                            item.OthersTotal = '0'

                                            this.BudgetOrganizeList.forEach(function (object) {

                                                // 기타비 총합 계산 (루프 돌때마다 합산)
                                                item.OthersTotal = (Number(item.OthersTotal.toNumber()) + Number(object.Others)).toPriceNumber()
                                            })
                                        }

                                        break
                                    }
                                    default:
                                }
                            }

                            // 월별 경비 총합 계산
                            item.Sum = (Number(item.Meals)
                                + Number(item.TogetherJoy) + Number(item.Transportation) + Number(item.RoomCharge)
                                + Number(item.Entertainment) + Number(item.Supplies) + Number(item.Others)
                            ).toPriceNumber()
                        }

                        var totalRow = this.BudgetOrganizeList[this.BudgetOrganizeList.length - 1]

                        // 경비 총합 계산
                        totalRow.Sum = (
                            Number(totalRow.Meals)
                            + Number(totalRow.TogetherJoy)
                            + Number(totalRow.TransportationTotal === null ? '0' : totalRow.TransportationTotal.toNumber())
                            + Number(totalRow.RoomChargeTotal === null ? '0' : totalRow.RoomChargeTotal.toNumber())
                            + Number(totalRow.EntertainmentTotal === null ? '0' : totalRow.EntertainmentTotal.toNumber())
                            + Number(totalRow.SuppliesTotal === null ? '0' : totalRow.SuppliesTotal.toNumber())
                            + Number(totalRow.OthersTotal === null ? '0' : totalRow.OthersTotal.toNumber())
                        ).toPriceNumber()
                        
                        // 기본예산 총액 설정
                        this.ExpenseBudgetApplyDetail.BudgetTotAmt = totalRow.Sum
                        this.TotalBudget = totalRow.Sum.toNumber()
                    }
                }
                else {

                    alert('프로젝트를 선택하여 주세요.')
                    this.$refs.txtProjectNm2.focus()
                }
            },
            // 페이지 변경 이벤트
            // pageIndex: 선택한 페이지 번호
            pageIndexChangeEvent: function (pageIndex) {

                this.ExpenseBudgetApplySelectTerm.CurrentPage = pageIndex
                this.getExpenseBudgetApply()
            },
            // 경비예산신청 목록 조회
            getExpenseBudgetApply: function () {

                var parent = this
                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/GetExpenseBudgetApply'.toRealServiceUrl()
                var obj = new Object()
                obj.jsonString = JSON.stringify(this.ExpenseBudgetApplySelectTerm)

                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d)
                    var table = jsonData.Table
                    var table1 = jsonData.Table1

                    // 목록
                    if (table.length > 0) {

                        parent.ExpenseBudgetApplyList = table
                    }
                    else {

                        parent.ExpenseBudgetApplyList = null
                    }

                    // 목록 전체 카운터
                    if (table1.length > 0) {

                        parent.ExpenseBudgetApplyListTotalCount = table1[0].TotCnt

                        // pager 설정
                        $(parent.$refs.ulpager).pager({
                            pager: parent.$refs.ulpager,
                            totalItemCount: parent.ExpenseBudgetApplyListTotalCount,
                            recordPerPage: parent.ExpenseBudgetApplySelectTerm.TopSize,
                            currentPage: parent.ExpenseBudgetApplySelectTerm.CurrentPage,
                            pageIndexChanged: parent.pageIndexChangeEvent
                        })
                    }
                    else {

                        parent.CompanyCardListTotalCount = 0
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            // 경비예산신청 추가
            addExpenseBudgetApply: function () {

                // 화면 액션값 설정 (I:신규, V: 상세보기)
                this.PageActionType = 'I'

                // 프로젝트 투입인력 목록 초기화
                this.ExpenseBudgetProjectMemberList = null

                // 예산 편성 목록 초기화
                this.BudgetOrganizeList = null

                // CSV 파일 관련 값 초기화
                this.BudgetOrganizeCsvFiles = null
                this.$refs.inputCsvImportFile.value = null

                // 상세정보 객체 초기화
                this.ExpenseBudgetApplyDetail = {
                    Arg: 'I',
                    UserId: this.$parent.EmpId,
                    CorpCd: this.$parent.CompanyCode,
                    CompanyCd: null,
                    CompanyNm: null,
                    ProjectCd: null,
                    ProjectNm: null,
                    ContractYMD: null,
                    ContractYMDReplace: null,
                    ProjectDT: null,
                    TrgtYY: null,
                    SeqNo: null,
                    BudgetType: '0115010',
                    BudgetTotAmt: null,
                    BudgetTotAmtReplace: null,
                    ApprovalStepType: null,
                    ApprovalCd: '0117010',
                    ApprvalNm: '승인대기',
                    ChrgEmpNo: null,
                    ChrgEmpNm: null,
                    Remark: null,
                    RegtDate: this.moment(new Date()).format('YYYY-MM-DD'),
                    RegtEmpNo: this.$parent.EmpId,
                    MarkPM: this.$parent.UserName,
                    FoodExp: null,
                    TogetherExp: null,
                    TogetherCnt: null,
                    TransExp: null,
                    TransExpPayCnt: null,
                    LodgeExp: null,
                    EntertainExp: null,
                    SuppliesExp: null,
                    EtcExp: null,
                    WorkDayAvg: '20'
                }

                $(this.$refs.AC1051E_Modal).modal('show')
            },
            // 경비예산신청 상세 조회
            // targetRow: 리스트에서 선택한 행
            getExpenseBudgetApplyDetail: function (targetRow) {

                var parent = this
                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/GetExpenseBudgetApplyDetail'.toRealServiceUrl()
                var obj = new Object()

                // 화면 액션값 설정 (I:신규, V: 상세보기)
                this.PageActionType = 'V'

                this.ExpenseBudgetApplyDetailSelectTerm.TargetYear = targetRow.TrgtYY
                this.ExpenseBudgetApplyDetailSelectTerm.ProjectCompanyCode = targetRow.CompanyCd
                this.ExpenseBudgetApplyDetailSelectTerm.ProjectCode = targetRow.ProjectCd
                this.ExpenseBudgetApplyDetailSelectTerm.SeqNo = targetRow.SeqNo

                obj.jsonString = JSON.stringify(this.ExpenseBudgetApplyDetailSelectTerm)
                
                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d)
                    var table = jsonData.Table
                    var table1 = jsonData.Table1

                    // 상세데이터
                    if (table.length > 0) {

                        table[0].Arg = 'I'

                        // 기본예산 총액 값 설정
                        parent.TotalBudget = table[0].BudgetTotAmt

                        // 돈관련된 값들 ',' 찍기
                        table[0].BudgetTotAmt = table[0].BudgetTotAmt.toPriceNumber()
                        table[0].FoodExp = table[0].FoodExp.toPriceNumber()
                        table[0].TogetherExp = table[0].TogetherExp.toPriceNumber()
                        table[0].TransExp = table[0].TransExp.toPriceNumber()
                        table[0].LodgeExp = table[0].LodgeExp.toPriceNumber()
                        table[0].EntertainExp = table[0].EntertainExp.toPriceNumber()
                        table[0].SuppliesExp = table[0].SuppliesExp.toPriceNumber()
                        table[0].EtcExp = table[0].EtcExp.toPriceNumber()
                        
                        Object.assign(parent.ExpenseBudgetApplyDetail, table[0])

                        // 예산편성 목록
                        if (table1.length > 0) {

                            var totalRowObject = Object.assign({}, parent.BudgetOrganizeDetail)
                            totalRowObject.IsTotalRow = true

                            // 예산편성 목록에 추가적으로 필요한 항목(합계 등) 추가
                            table1.forEach(function (item) {

                                item.IsTotalRow = false
                                item.MealsTotal = null
                                item.TogetherJoyTotal = null
                                item.TransportationTotal = null
                                item.RoomChargeTotal = null
                                item.EntertainmentTotal = null
                                item.SuppliesTotal = null
                                item.OthersTotal = null
                                item.Sum = 0

                                // 투입MM 항목 총합
                                totalRowObject.MM = Number(totalRowObject.MM) + Number(item.MM)
                            })

                            parent.BudgetOrganizeList = table1

                            // 총합 행 추가
                            parent.BudgetOrganizeList.push(totalRowObject)

                            // 경비항목별 자동계산 실행
                            parent.autoCalculateExpenses(1, true)
                            parent.autoCalculateExpenses(2, true)
                            parent.autoCalculateExpenses(3, true)
                            parent.autoCalculateExpenses(4, true)
                            parent.autoCalculateExpenses(5, true)
                            parent.autoCalculateExpenses(6, true)
                            parent.autoCalculateExpenses(7, true)
                        }
                        else {

                            parent.BudgetOrganizeList = null
                        }

                        // 해당 프로젝트 투입인력 조회
                        parent.getExpenseBudgetProjectMember(parent.ExpenseBudgetApplyDetail.CompanyCd, parent.ExpenseBudgetApplyDetail.ProjectCd, true)

                        // 상세팝업 오픈
                        $(parent.$refs.AC1051E_Modal).modal('show')
                    }
                    else {

                        alert('상세 데이터가 존재하지 않습니다. 관리자에게 문의하세요.')
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            // 데이터 상세 팝업 > 프로젝트 투입인력 목록 조회
            getExpenseBudgetProjectMember: function (projectCompanyCode, projectCode, isCheckedData) {

                var parent = this
                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/GetExpenseBudgetProjectMember'.toRealServiceUrl()
                var obj = new Object()
                
                this.ProjectMemberSelectTerm.ProjectCompanyCode = projectCompanyCode
                this.ProjectMemberSelectTerm.ProjectCode = projectCode

                obj.jsonString = JSON.stringify(this.ProjectMemberSelectTerm)

                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d)
                    var table = jsonData.Table
                    var table1 = jsonData.Table1
                    var table2 = jsonData.Table2
                    var table3 = jsonData.Table3

                    if (table1.length > 0 && table1[0].MMCnt > 0 && !isCheckedData) {

                        alert('프로젝트에 기본 예산으로 설정된 값이 있습니다. 현재 프로젝트로 기본 예산 설정 하실수 없습니다.')

                        // 관련 값들 초기화
                        parent.initializeProjectBudget()

                        return
                    }

                    if (table2.length > 0 && table[0].MMCnt > 0) {

                        parent.ExpenseBudgetProjectMemberList = table2

                        var tempObject = Object.assign({}, parent.BudgetOrganizeDetail)

                        if (table3.length > 0 && !isCheckedData) {
                            
                            // 년/월, 투입MM을 제외한 값 초기화
                            table3.forEach(function (item) {

                                item.IsTotalRow = false
                                item.TrgtYYMM = null
                                item.Meals = null
                                item.MealsRepl = null
                                item.MealsTotal = null
                                item.SeqMeals = null
                                item.Remark = null
                                item.TogetherJoy = null
                                item.TogetherJoyRepl = null
                                item.TogetherJoyTotal = null
                                item.SeqTogetherJoy = null
                                item.Transportation = null
                                item.TransportationRepl = null
                                item.TransportationTotal = null
                                item.SeqTransportation = null
                                item.RoomCharge = null
                                item.RoomChargeRepl = null
                                item.RoomChargeTotal = null
                                item.SeqRoomCharge = null
                                item.Entertainment = null
                                item.EntertainmentRepl = null
                                item.EntertainmentTotal = null
                                item.SeqEntertainment = null
                                item.Supplies = null
                                item.SuppliesRepl = null
                                item.SuppliesTotal = null
                                item.SeqSupplies = null
                                item.Others = null
                                item.OthersRepl = null
                                item.OthersTotal = null
                                item.SeqOthers = null
                                item.Sum = 0

                                // 투입MM 항목 총합
                                tempObject.MM = Number(tempObject.MM) + Number(item.MM)
                            })

                            parent.BudgetOrganizeList = table3

                            tempObject.IsTotalRow = true

                            // 총합 행 추가
                            parent.BudgetOrganizeList.push(tempObject)
                        }
                        else {

                            if (!isCheckedData) {

                                parent.BudgetOrganizeList = null
                            }
                        }
                    }
                    else {

                        alert('프로젝트에 투입된 인력이 없습니다. 인력을 먼저 추가하세요')

                        // 관련 값들 초기화
                        parent.initializeProjectBudget()
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            // 프로젝트 예산 초기화
            initializeProjectBudget: function () {

                // 프로젝트명 초기화
                this.ExpenseBudgetApplyDetail.ProjectNm = null
                this.ExpenseBudgetApplyDetail.ProjectCd = null
                this.ExpenseBudgetApplyDetail.CompanyCd = null

                // 대상년도 초기화
                this.ExpenseBudgetApplyDetail.TrgtYY = null

                // 프로젝트 투입인력 목록 초기화
                this.ExpenseBudgetProjectMemberList = null

                // 예산 편성 목록 초기화
                this.BudgetOrganizeList = null

                // 기본예산총액 초기화
                this.ExpenseBudgetApplyDetail.BudgetTotAmt = null
            },
            // 경비예산신청 저장
            saveExpenseBudgetApply: function (isApprovalRequest) {

                if (this.ExpenseBudgetApplyDetail.ProjectNm === null
                    || this.ExpenseBudgetApplyDetail.ProjectNm === '') {

                    alert('프로젝트명을 선택하세요.')
                    this.$refs.txtProjectNm2.focus()
                }
                else if (this.ExpenseBudgetApplyDetail.ChrgEmpNo === null
                    || this.ExpenseBudgetApplyDetail.ChrgEmpNo === '') {

                    alert('담당 이사를 선택하세요.')
                    this.$refs.hidPMCd2.focus()
                }
                else {

                    var parent = this
                    var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/SaveExpenseBudgetApply'.toRealServiceUrl()
                    var obj = new Object()
                    var budgetOrganizeList = new Array()

                    // 승인요청인지 단순 저장인지에 따라 승인코드 값을 설정(0117020: 승인대기, 0117010: 작성중)
                    this.ExpenseBudgetApplyDetail.ApprovalCd = isApprovalRequest ? '0117020' : '0117010'

                    obj.jsonString = JSON.stringify(this.ExpenseBudgetApplyDetail)

                    // 예산편성 목록을 저장하기 위해 데이터 가공
                    this.BudgetOrganizeList.forEach(function (object) {

                        var tempObject = {
                            Arg: 'DI',
                            UserId: parent.$parent.EmpId,
                            CorpCd: parent.$parent.CompanyCode,
                            CompanyCd: parent.ExpenseBudgetApplyDetail.CompanyCd,
                            ProjectCd: parent.ExpenseBudgetApplyDetail.ProjectCd,
                            TrgtYY: parent.ExpenseBudgetApplyDetail.TrgtYY,
                            SeqNo: parent.ExpenseBudgetApplyDetail.SeqNo,
                            TrgtYYMM: object.DateMM,
                            CommitMM: object.MM,
                            BudgetAccntCd: null,
                            BudgetAmt: null,
                            ItemSeqNo: null,
                            Remark: object.Remark
                        }

                        // 총합 행은 제외
                        if (!object.IsTotalRow) {
                            
                            // 식사비용(기본정보 년월, 투입MM 값도 같이 넣어줌)
                            var foodExpensesObject = Object.assign({}, tempObject)
                            foodExpensesObject.Arg = 'DF'
                            foodExpensesObject.BudgetAccntCd = '0001010'
                            foodExpensesObject.BudgetAmt = object.Meals
                            foodExpensesObject.ItemSeqNo = object.SeqMeals

                            // 회식비용
                            var diningExpensesObject = Object.assign({}, tempObject)
                            diningExpensesObject.BudgetAccntCd = '0001040'
                            diningExpensesObject.BudgetAmt = object.TogetherJoy
                            diningExpensesObject.ItemSeqNo = object.SeqTogetherJoy

                            // 교통비용
                            var transportationExpensesObject = Object.assign({}, tempObject)
                            transportationExpensesObject.BudgetAccntCd = '0001060'
                            transportationExpensesObject.BudgetAmt = object.Transportation
                            transportationExpensesObject.ItemSeqNo = object.SeqTransportation

                            // 숙박비용
                            var lodgmentExpensesObject = Object.assign({}, tempObject)
                            lodgmentExpensesObject.BudgetAccntCd = '0001240'
                            lodgmentExpensesObject.BudgetAmt = object.RoomCharge
                            lodgmentExpensesObject.ItemSeqNo = object.SeqRoomCharge

                            // 접대비용
                            var entertainmentExpensesObject = Object.assign({}, tempObject)
                            entertainmentExpensesObject.BudgetAccntCd = '0001100'
                            entertainmentExpensesObject.BudgetAmt = object.Entertainment
                            entertainmentExpensesObject.ItemSeqNo = object.SeqEntertainment

                            // 소모품비용
                            var expendablesExpensesObject = Object.assign({}, tempObject)
                            expendablesExpensesObject.BudgetAccntCd = '0001110'
                            expendablesExpensesObject.BudgetAmt = object.Supplies
                            expendablesExpensesObject.ItemSeqNo = object.SeqSupplies

                            // 기타비용
                            var otherExpensesObject = Object.assign({}, tempObject)
                            otherExpensesObject.BudgetAccntCd = '0001250'
                            otherExpensesObject.BudgetAmt = object.Others
                            otherExpensesObject.ItemSeqNo = object.SeqOthers
                            
                            budgetOrganizeList.push(foodExpensesObject)
                            budgetOrganizeList.push(diningExpensesObject)
                            budgetOrganizeList.push(transportationExpensesObject)
                            budgetOrganizeList.push(lodgmentExpensesObject)
                            budgetOrganizeList.push(entertainmentExpensesObject)
                            budgetOrganizeList.push(expendablesExpensesObject)
                            budgetOrganizeList.push(otherExpensesObject)
                        }
                    })

                    obj.jsonBudgetOrganizeListString = JSON.stringify(budgetOrganizeList)
                    
                    // TODO: 승인요청일시 승인담담자에게 메일발송 로직 구현 필요

                    // 서비스호출 성공 콜백 함수
                    var successCallback = function (data) {

                        if (data.d > 0) {

                            if (parent.PageActionType === 'I') {

                                alert('등록 되었습니다.')
                                $(parent.$refs.AC1051E_Modal).modal('hide')

                                // 목록 재조회
                                parent.getExpenseBudgetApply()
                            }
                            else {

                                alert('저장 되었습니다.')

                                // 목록 재조회
                                parent.getExpenseBudgetApply()

                                // 상세 재조회
                                parent.getExpenseBudgetApplyDetail(parent.ExpenseBudgetApplyDetail)
                            }
                        }
                    }

                    $.scriptBase.executeAjaxAsync(url, obj, successCallback)
                }
            }
        }
    }
</script>