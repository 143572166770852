<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a v-show="this.$parent.CanSearch" href="#" id="btnListSrch" class="btn btn-primary btn-row2" data-dismiss="modal" @click.prevent="getProjectList">
                    <span>
                        <i class="glyphicon glyphicon-search"></i> 조회
                    </span>
                </a>
                <a v-show="this.$parent.CanNew" href="#" id="btnListInst" class="btn btn-default" @click.prevent="onAddProjectClick">
                    <span>
                        <i class="glyphicon glyphicon-plus"></i> 등록
                    </span>
                </a>
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-3">
                                        <label for="selTrgtYY" class="control-label">
                                            <span>년도</span>
                                        </label>
                                        <select id="selTrgtYY" class="form-control" v-model="ProjectSelectTerm.TargetYear" @change="getProjectList">
                                            <option v-for="year in TargetYearList" v-bind:value="year">
                                                {{year}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-3">
                                        <label for="selStatusCd" class="control-label">
                                            <span>진행상태</span>
                                        </label>
                                        <select class="form-control" id="selStatusCd" name="selStatusCd" v-model="ProjectSelectTerm.StatusCode" @change="getProjectList">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in StatusCodeList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-3">
                                        <label for="selPrjetSect" class="control-label">
                                            <span>프로젝트 구분</span>
                                        </label>
                                        <select class="form-control" id="selPrjetSect" name="selPrjetSect" v-model="ProjectSelectTerm.ProjectCategoryCode" @change="getProjectList">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in ProjectCategoryCodeList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtCompanyNM" class="control-label">
                                            <span>거래처</span>
                                        </label>
                                        <div class="cols-btns-cont  pad-r150">
                                            <input type="text" id="txtCompanyNm" class="form-control txt" disabled="disabled" v-model="ProjectSelectTerm.AccountCompanyName" />
                                            <input type="hidden" id="hidCompanyCd" v-model="ProjectSelectTerm.AccountCompanyCode" />
                                        </div>
                                        <div class="cols-btns">
                                            <a href="#" id="btnItemPop1" class="btn btn-default" @click.prevent="searchCompany">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i> 검색
                                                </span>
                                            </a>
                                            <a href="#" id="btnItemClear1" class="btn btn-default"
                                               @click.prevent="function(){
                                                    ProjectSelectTerm.AccountCompanyName = null
                                                    ProjectSelectTerm.AccountCompanyCode = null
                                                    getProjectList()
                                               }">
                                                <span>
                                                    <i class="glyphicon glyphicon-repeat"></i> 초기화
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtProjectNm" class="control-label">
                                            <span>프로젝트명</span>
                                        </label>
                                        <input type="text" id="txtProjectNm" class="form-control txt" v-model="ProjectSelectTerm.ProjectName" />
                                    </div>
                                </section>
                            </section>
                        </fieldset>
                    </form>
                </div>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">프로젝트 일반</p>
                </div>
                <div>
                    <!--리스트 영역-->
                    <section class="tbl-contents">
                        <div class="alert alert-info total">
                            <p>
                                <span class="glyphicon glyphicon-folder-close"></span>
                                <span>전체: <span id="spTotCnt">{{ProjectListTotalCount}}</span>건</span>
                            </p>
                        </div>
                        <div class="table-responsive">
                            <!--마스터 그리드 영역-->
                            <DxDataGrid id="grid-container"
                                        :show-borders="false"
                                        :data-source="ProjectList"
                                        :hoverStateEnabled="true"
                                        @cell-prepared="onCellPrepared">
                                <DxLoadPanel :enabled="false" />
                                <DxScrolling mode="standard" useNative="false" />
                                <DxSorting mode="none" />
                                <DxPaging :enabled="false" />
                                <DxColumn cell-template="detail" width="5%" alignment="center" />
                                <template #detail="{ data }">
                                    <DxButton icon="chevronnext" styling-mode="text" @click="onCollapseClick(data)" />
                                </template>
                                <DxColumn data-field="CompanyNm"
                                          caption="거래처명"
                                          alignment="left" />
                                <DxColumn data-field="SContractAmount"
                                          caption="합계"
                                          format="fixedPoint"
                                          alignment="right" />
                                <DxMasterDetail :enabled="false"
                                                template="masterDetailTemplate" />
                                <template #masterDetailTemplate="{ data }">
                                    <PM1000R_Detail :template-data="data" />
                                </template>
                            </DxDataGrid>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <CP1030P ref="CP1030P"></CP1030P>
        <PM1008E ref="PM1008E"></PM1008E>
    </div>
</template>

<script>
    import { DxDataGrid, DxColumn, DxMasterDetail, DxScrolling, DxPaging, DxSorting, DxLoadPanel } from 'devextreme-vue/data-grid';
    import { DxButton } from 'devextreme-vue';

    import ViewTitle from '@/components/ViewTitle';
    import CP1030P from '@/components/libs/popup/CP1030P';
    import PM1000R_Detail from '@/components/pm/libs/PM1000R_Detail';
    import PM1008E from '@/components/pm/popup/PM1008E';

    export default {
        name: 'PM1000R'
        , components: {
            ViewTitle, CP1030P, PM1000R_Detail, DxDataGrid, DxColumn, DxMasterDetail, DxButton, DxScrolling, DxPaging, DxSorting, DxLoadPanel, PM1008E
        }
        , data() {
            return {
                // 프로젝트 진행상태 코드
                StatusCodeList: new Array(),
                // 프로젝트 구분 코드
                ProjectCategoryCodeList: new Array(),
                // 조회조건 년도 목록
                TargetYearList: new Array(),
                // 프로젝트 목록
                ProjectList: new Array(),
                // 조회조건 객체
                ProjectSelectTerm: {
                    Arg: 'M',
                    UserId: this.$parent.EmpId,
                    CompanyCode: this.$parent.CompanyCode,
                    AccountCompanyCode: null,
                    AccountCompanyName: null,
                    ProjectName: null,
                    TargetYear: this.moment(new Date()).year(),
                    StatusCode: '0701030',
                    ProjectCategoryCode: '',
                },
                // 프로젝트 전체 건수
                ProjectListTotalCount: 0,
            }
        },
        beforeMount() {
            // 공통코드 조회
            this.StatusCodeList = $.scriptBase.getCommonCode('CODE000', '0701000', '', '');
            this.ProjectCategoryCodeList = $.scriptBase.getCommonCode('CODE000', '0732000', '', '');

            var startYear = this.moment(new Date()).add(1, 'year').year();
            var endYear = this.moment(new Date(2013, 1, 1, 0, 0, 0)).year();

            // 대상년도 목록 값 설정
            for (var i = startYear; i >= endYear; i--) {

                this.TargetYearList.push(i);
            }
        },
        mounted() {

            this.getProjectList();
        },
        methods: {
            // Datagrid header 가운데 정렬, column 중앙 정렬
            onCellPrepared(e) {

                if (e.rowType == 'header') {

                    e.cellElement.style['textAlign'] = 'center';
                }
                if (e.rowType == 'data') {

                    e.cellElement.style['verticalAlign'] = 'middle';
                }
            },
            // 거래처 검색 버튼
            searchCompany() {

                var parent = this;

                var successCallback = function (result) {

                    parent.ProjectSelectTerm.AccountCompanyCode = result.CompanyCd;
                    parent.ProjectSelectTerm.AccountCompanyName = result.CompanyNm;

                    parent.getProjectList();
                }

                this.$refs.CP1030P.open(successCallback);
            },
            // 프로젝트 등록
            onAddProjectClick() {

                // 화면 액션값 설정 (I:신규, V: 상세보기)
                this.PageActionType = 'I';

                var callbackFunction = function () {

                    parent.getProjectList();
                }

                this.$refs.PM1008E.open(callbackFunction);
            },
            // 마스터 그리드 버튼 선택 이벤트
            onCollapseClick(e) {

                var expanded = e.component.isRowExpanded(e.row.key);

                if (expanded) {

                    e.component.collapseAll(-1);
                } else {

                    e.component.collapseAll(-1);
                    e.component.expandRow(e.row.key);
                }
            },
            // 마스터 그리드 프로젝트 목록 조회
            getProjectList() {

                var that = this;

                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/GetNormalProject'.toRealServiceUrl();

                var obj = new Object();
                obj.jsonString = JSON.stringify(this.ProjectSelectTerm);

                var ajaxSuccessHandler = function (data) {

                    var jsonObj = JSON.parse(data.d);
                    var table = jsonObj.Table;

                    that.ProjectList = (table.length > 0) ? table : new Array();
                    that.ProjectListTotalCount = (table.length > 0) ? table.length : 0;
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler);
            },
        }
    }
</script>