<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a href="#" id="btnListSrch" class="btn btn-primary btn-row2" @click.prevent="showBillData">
                    <span>
                        <i class="glyphicon glyphicon-search"></i> 조회
                    </span>
                </a>
                <a href="#" id="btnListSave" class="btn btn-default" @click.prevent="saveBillData">
                    <span>
                        <i class="glyphicon glyphicon-save"></i> 저장
                    </span>
                </a>
            </div>
            <div class="sch-box">
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="selDateType" class="control-label">
                                            <span>승인일자</span>
                                        </label>
                                        <div class="col-xs-3">
                                            <select class="form-control" id="selDateType" @change="dateTypeChangeEvent">
                                                <option value="1">작년도</option>
                                                <option value="2">이번 연도</option>
                                                <option value="3" selected="selected">전월</option>
                                                <option value="4">당월</option>
                                            </select>
                                        </div>
                                        <div class="col-xs-9">
                                            <label for="txtStartDate" class="sr-only"><span>승인일자</span></label>
                                            <div class="col-2 input-group date">
                                                <DxDateBox display-format="yyyy-MM-dd"
                                                           pickerType="calendar"
                                                           v-model="CorpCardBillSelectTerm.StartDate"
                                                           @value-changed="showBillData"
                                                           style="border-radius:0;" />
                                            </div>
                                            <div class="tail"><span>~</span></div>
                                            <label for="txtEndDate" class="sr-only"><span>승인일자</span></label>
                                            <div class="col-2 input-group date">
                                                <DxDateBox display-format="yyyy-MM-dd"
                                                           pickerType="calendar"
                                                           v-model="CorpCardBillSelectTerm.EndDate"
                                                           @value-changed="showBillData"
                                                           style="border-radius:0;" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cols col-4">
                                        <label for="selCardNo" class="control-label">
                                            <span>카드번호</span>
                                        </label>
                                        <select class="form-control" id="selCardNo" v-model="CorpCardBillSelectTerm.CorpCardNo" @change="showBillData">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in CorpCardNoList" v-bind:value="item.CardNo">
                                                {{item.CardNo + ' ' + item.EmpNm}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-4">
                                        <label for="selProcessingYN" class="control-label">
                                            <span>처리여부</span>
                                        </label>
                                        <select class="form-control" id="selProcessingYN" v-model="CorpCardBillSelectTerm.ProcessingYN" @change="showBillData">
                                            <option value="">== 전체 ==</option>
                                            <option value="Y">Y</option>
                                            <option value="N">N</option>
                                        </select>
                                    </div>
                                </section>
                            </section>
                        </fieldset>
                    </form>
                </div>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">법인카드 청구관리(관리용)</p>
                    <div class="btn-wrap">
                        <label for="btnListInst" class="btn btn-default">
                            <span>
                                <i class="glyphicon glyphicon-plus"></i> 업로드
                            </span>
                        </label>
                        <input type="file" id="btnListInst" accept=".xls, .xlsx" @click="setInsertTerm" @change="convertExcel" style="display: none;" />
                    </div>
                </div>
                <div>
                    <div class="alert alert-info total">
                        <p>
                            <span class="glyphicon glyphicon-folder-close"></span>
                            전체: <span id="spTotCnt">{{ CorpCardBillCnt }}</span>건
                        </p>
                    </div>
                    <div class="table-responsive">
                        <DxDataGrid id="grid-container"
                                    :ref="BillData"
                                    :data-source="CorpCardBillList"
                                    :show-borders="false"
                                    :hoverStateEnabled="true"
                                    :allow-column-resizing="true"
                                    :column-resizing-mode="'widget'"
                                    :row-alternation-enabled="true"
                                    :column-auto-width="true"
                                    :column-min-width="50"
                                    :repaint-changes-only="true"
                                    @cell-prepared="onCellPrepared"
                                    style="max-height: 500px; vertical-align: top; width: 100%;">
                            <DxScrolling mode="standard" useNative="false" />
                            <DxColumnFixing :enabled="true" />
                            <DxPaging :enabled="false" />
                            <DxColumn data-field="RowNo" caption="순번" width="3%" alignment="center" />
                            <DxColumn data-field="CardNo" caption="카드번호" alignment="center" />
                            <DxColumn data-field="UseEmpNm" caption="사용자명" alignment="center" />
                            <DxColumn data-field="ApprovedYMD" caption="승인일시" alignment="center" data-type="date" format="yyyy-MM-dd HH:mm:ss" />
                            <DxColumn data-field="UseDivNm" caption="이용구분명" alignment="center" />
                            <DxColumn data-field="InstallmentMonth" caption="할부개월" alignment="center" />
                            <DxColumn data-field="UseStoreNm" caption="이용가맹점명" alignment="left" />
                            <DxColumn data-field="ApprovedAmt" caption="승인금액" alignment="right" format="fixedPoint" />
                            <DxColumn data-field="ApprovedUSDAmt" caption="외화승인금액" alignment="right" format="fixedPoint" />
                            <DxColumn data-field="CompanyNm" caption="거래처명" alignment="left" />
                            <DxColumn data-field="ProjectNm" caption="프로젝트명" alignment="left" />
                            <DxColumn data-field="AcntSubjtNm2" caption="계정과목명" alignment="center" />
                            <DxColumn data-field="MemoCont" caption="메모" alignment="left" />
                            <DxColumn data-field="ProcessingYN" caption="처리여부" width="5%" alignment="center" />
                        </DxDataGrid>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle';
    import { DxDataGrid, DxColumn, DxPaging, DxScrolling, DxColumnFixing, DxSorting, DxSelection, } from 'devextreme-vue/data-grid';
    import DxDateBox, { DxCalendarOptions } from 'devextreme-vue/date-box';
    import DxDropDownBox from 'devextreme-vue/drop-down-box';
    import * as XLSX from 'xlsx';

    export default {
        name: 'AC1120R',
        components: {
            ViewTitle, DxDateBox, DxCalendarOptions, DxDataGrid, DxColumn, DxPaging, DxScrolling, DxColumnFixing, DxSorting, DxSelection, DxDropDownBox, XLSX,
        },
        data() {
            return {
                // 법인카드 목록
                CorpCardNoList: new Array(),
                // 법인카드청구 조회조건 객체
                CorpCardBillSelectTerm: {
                    Arg: 'C',
                    StartDate: new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).getFullYear(), new Date(new Date().setMonth(new Date().getMonth() - 1)).getMonth(), 1),
                    EndDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
                    CorpCardNo: '',
                    ProcessingYN: '',
                    UserId: this.$store.getters.getEmpId,
                },
                BillData: 'dataGrid',
                // 법인카드청구 목록
                CorpCardBillList: new Array(),
                CorpCardBillCnt: 0,
                // 법인카드청구 업로드 객체
                CorpCardBillInsertTerm: new Array(),
            }
        },
        beforeMount() {

            let data = $.scriptBase.getCorpCardList(this.$parent.EmpId);
            this.CorpCardNoList = JSON.parse(data.d).Table;
            // 조회 권한 (M: 시스템관리자 or 회계관리자, C: 임원)
            this.CorpCardBillSelectTerm.Arg = JSON.parse(data.d).Table1[0].Auth;
        },
        mounted() {

            this.showBillData();
        },
        methods: {
            // DataGrid Header 가운데 정렬, Column Data 중앙 정렬
            onCellPrepared(e) {

                if (e.rowType == 'header') {
                    e.cellElement.style['textAlign'] = 'center';
                }
                if (e.rowType == 'data') {
                    e.cellElement.style['verticalAlign'] = 'middle';
                }
            },
            // 거래일자 날짜 타입 변경 이벤트
            dateTypeChangeEvent(event) {

                let dateType = Number(event.target.value);

                switch (dateType) {
                    case 1: {
                        // 작년도
                        this.CorpCardBillSelectTerm.StartDate = new Date(new Date(this.moment(new Date()).add(-1, 'years')).getFullYear(), 0, 1);
                        this.CorpCardBillSelectTerm.EndDate = new Date(new Date().getFullYear(), 0, 0);
                        break;
                    }
                    case 2: {
                        // 이번 연도
                        this.CorpCardBillSelectTerm.StartDate = new Date(new Date().getFullYear(), 0, 1);
                        this.CorpCardBillSelectTerm.EndDate = new Date(new Date(this.moment(new Date()).add(1, 'years')).getFullYear(), 0, 0);
                        break;
                    }
                    case 3: {
                        // 전월
                        this.CorpCardBillSelectTerm.StartDate = new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).getFullYear(), new Date(new Date().setMonth(new Date().getMonth() - 1)).getMonth(), 1);
                        this.CorpCardBillSelectTerm.EndDate = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
                        break;
                    }
                    case 4: {
                        // 당월
                        this.CorpCardBillSelectTerm.StartDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                        this.CorpCardBillSelectTerm.EndDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
                        break;
                    }
                    default:
                }
            },
            // 법인카드청구 목록 조회
            showBillData() {

                var parent = this;

                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/SelectCorpCardBill'.toRealServiceUrl();
                var obj = new Object();

                this.CorpCardBillSelectTerm.StartDate = this.moment(this.CorpCardBillSelectTerm.StartDate).format('YYYYMMDD');
                this.CorpCardBillSelectTerm.EndDate = this.moment(this.CorpCardBillSelectTerm.EndDate).format('YYYYMMDD');

                obj.jsonString = JSON.stringify(this.CorpCardBillSelectTerm);

                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d);
                    var table = jsonData.Table;

                    // 법인카드청구 목록
                    parent.CorpCardBillList = (table.length > 0) ? table : new Array();

                    // 법인카드청구 전체 건수
                    parent.CorpCardBillCnt = (table.length > 0) ? table.length : 0;
                }
                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 엑셀파일 업로드 전 데이터 세팅
            setInsertTerm() {

                // 저장되지 않은 업로드 객체 있을 때 초기화
                if (this.CorpCardBillInsertTerm.length > 0) {

                    this.CorpCardBillInsertTerm = new Array();
                }
            },
            // 엑셀파일 json 형식으로 변환
            convertExcel(e) {

                var parent = this;

                let selectedFile = e.target.files[0];

                if (selectedFile) {

                    let fileReader = new FileReader();

                    fileReader.readAsBinaryString(selectedFile);
                    fileReader.onload = (event) => {

                        let data = event.target.result;
                        let workbook = XLSX.read(data, { type: "binary", cellDates: true, dateNF: "yyyy-mm-dd" });

                        workbook.SheetNames.forEach(sheet => {

                            let ExcelData = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
                            let mapping = {
                                ' ': 'RowNo',
                                '이용구분': 'UseDivNm',
                                '승인일시': 'ApprovedYMD',
                                '카드번호': 'CardNo',
                                '이용가맹점명': 'UseStoreNm',
                                '승인금액': 'ApprovedAmt',
                                '외화승인금액': 'ApprovedUSDAmt',
                                '할부개월': 'InstallmentMonth'
                            };

                            ExcelData = ExcelData.filter((e) => e.카드번호 != undefined && e.취소금액 == 0);
                            parent.renameKeys(mapping, ExcelData);
                        });

                        if (confirm('선택한 엑셀 파일을 그리드에 업로드하시겠습니까?')) {

                            // 엑셀 데이터 형식 유효성 검사
                            let check1 = parent.CorpCardBillInsertTerm.findIndex((item) => typeof item.ApprovedAmt !== 'number') + 1;
                            let check2 = parent.CorpCardBillInsertTerm.findIndex((item) => typeof item.ApprovedUSDAmt !== 'number') + 1;
                            let check3 = parent.CorpCardBillInsertTerm.findIndex((item) => item.ApprovedYMD == 'Invalid date') + 1;
                            let allPass = (check1 || check2 || check3);

                            if (check1 !== 0) {

                                let cnt = parent.CorpCardBillInsertTerm[check1 - 1].RowNo;
                                alert(cnt + '번 "승인금액" 항목이 올바르지 않습니다. 엑셀 파일을 확인해 주십시오.');
                            }
                            if (check2 !== 0) {

                                let cnt = parent.CorpCardBillInsertTerm[check2 - 1].RowNo;
                                alert(cnt + '번 "승인금액(USD)" 항목이 올바르지 않습니다. 엑셀 파일을 확인해 주십시오.');
                            }
                            if (check3 !== 0) {

                                let cnt = parent.CorpCardBillInsertTerm[check3 - 1].RowNo;
                                alert(cnt + '번 "승인일시" 항목이 올바르지 않습니다. 엑셀 파일을 확인해 주십시오.');
                            }
                            // 데이터 오류 있으면 초기화하고 멈춤
                            if (allPass !== 0) {

                                parent.CorpCardBillInsertTerm = new Array();
                                return;
                            }

                            // 업로드 데이터 DB에서 유효성 검사
                            parent.checkBillData();
                        } else {

                            // 업로드 파일 저장 취소 시 객체 초기화
                            parent.CorpCardBillInsertTerm = new Array();
                        }
                    };
                } else {

                    alert('선택된 엑셀 파일이 없습니다.');
                    return;
                }
            },
            // 엑셀데이터 객체 키 변경
            renameKeys(mapping, objArr) {

                let renamedObjArr = new Array();

                for (let obj of objArr) {

                    let renamedObj = {
                        Arg: 'IN',
                        UserId: this.$parent.EmpId,
                        RowNo: 0,
                        BillLogSeq: null,
                        CardNo: '',
                        ApprovedYMD: '',
                        UseDivNm: '',
                        InstallmentMonth: null,
                        UseStoreNm: '',
                        ApprovedAmt: 0,
                        ApprovedUSDAmt: 0,
                        AcntSubjtCd: null,
                        CorpCd: null,
                        CompanyCd: null,
                        ProjectCd: null,
                        MemoCont: '',
                        ProcessingYN: 'N',
                    };

                    for (let [before, after] of Object.entries(mapping)) {

                        if (obj[before]) {

                            renamedObj[after] = obj[before];
                        }
                    }

                    renamedObj.ApprovedYMD = this.moment(renamedObj.ApprovedYMD).format('YYYY-MM-DD HH:mm:ss');
                    renamedObjArr.push(renamedObj);
                }

                this.CorpCardBillInsertTerm = renamedObjArr;
            },
            // 엑셀 데이터 DB에서 유효성 체크
            checkBillData() {

                let parent = this;
                let url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/ValidateCorpCardBill'.toRealServiceUrl();

                let BillDataCheckTerm = new Array();
                this.CorpCardBillInsertTerm.forEach((item) => BillDataCheckTerm.push({
                    RowNo: item.RowNo,
                    CardNo: item.CardNo,
                    ApprovedYMD: item.ApprovedYMD,
                    UseStoreNm: item.UseStoreNm,
                    ApprovedAmt: item.ApprovedAmt,
                    ApprovedUSDAmt: item.ApprovedUSDAmt,
                }));

                var obj = new Object();
                obj.jsonString = JSON.stringify(BillDataCheckTerm);

                var successCallback = function (data) {

                    var table = JSON.parse(data.d).Table;

                    // 엑셀파일 카드번호 유효성 검사
                    let alert1 = table.find((item) => item.Alert1 != 'PASS');
                    alert1 = (alert1 != undefined) ? alert1.Alert1 : '';

                    if (alert1 != '') {

                        alert(alert1);
                        return;
                    }

                    // 이미 입력된 데이터와 엑셀 파일 데이터 비교
                    let alert2 = table.find((item) => item.Alert2 != 'PASS');
                    alert2 = (alert2 != undefined) ? alert2.Alert2 : '';

                    if (alert2 != '') {

                        alert(alert2);
                        return;
                    }

                    parent.CorpCardBillInsertTerm.forEach((item) => {

                        let cardInfo = table.find((info) => info.RowNo == item.RowNo);
                        item.CardNo = cardInfo.CardNo;
                        item.UseEmpNo = cardInfo.UseEmpNo;
                        item.UseEmpNm = cardInfo.UseEmpNm;
                    })

                    // 그리드 데이터 초기화
                    parent.CorpCardBillList = new Array();
                    // 새로 업로드한 엑셀 데이터 출력
                    parent.CorpCardBillList = Array.from(new Set(parent.CorpCardBillInsertTerm));
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 엑셀로 업로드한 청구데이터 저장
            saveBillData() {

                let parent = this;
                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/ManipulateCorpCardBillLog'.toRealServiceUrl();
                var obj = new Object();

                if (this.CorpCardBillInsertTerm.length == 0) {

                    alert('저장할 엑셀 파일을 먼저 업로드해 주십시오.');
                    return;
                }

                obj.jsonString = JSON.stringify(this.CorpCardBillInsertTerm);

                if (confirm('업로드한 데이터를 저장하시겠습니까?')) {

                    this.CorpCardBillInsertTerm.forEach((item) => item.CancelYMD = '');

                    var successCallback = function (data) {

                        if (data.d > 0) {

                            alert('저장되었습니다.');
                            parent.CorpCardBillInsertTerm = new Array();
                            parent.showBillData();
                        } else {

                            alert('저장에 실패했습니다. 잠시 후 다시 시도해 주십시오.');
                            parent.CorpCardBillInsertTerm = new Array();
                            parent.showBillData();
                        }
                    }
                    $.scriptBase.executeAjaxAsync(url, obj, successCallback);
                }
            },
        }
    }
</script>