<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a v-show="this.$parent.CanSearch" href="#" id="btnListSrch" class="btn btn-primary btn-row2" @click="btnSearchClicked">
                    <span>
                        <i class="glyphicon glyphicon-search"></i> 조회
                    </span>
                </a>
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="selTrgtYY" class="control-label">
                                            <span>년도</span>
                                        </label>
                                        <select class="form-control" ref="selTrgtYY" name="selTrgtYY" v-model="SearchCondition.TargetYear">
                                            <option v-for="year in YearsList" v-bind:value="year" v-bind:key="year">
                                                {{year}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-2" style="padding-left:10px">
                                        <div class="cols-btns-cont pad-r150">
                                            <input type="text" id="txtFieldNm" class="form-control txt" readonly="readonly" v-model="SearchCondition.EmpName" />
                                            <input type="hidden" id="hidFieldCd" v-model="SearchCondition.EmpNo" />
                                        </div>
                                        <div class="cols-btns">
                                            <button type="button" id="btnListPop1" class="btn btn-default" @click.prevent="searchEmployee">
                                                <span class="glyphicon glyphicon-search"></span> 검색
                                            </button>
                                            <a href="#" id="btnSrchInit" class="btn btn-default" @click.prevent="clearSearchEmployee">
                                                <span>
                                                    <i class="glyphicon glyphicon-repeat"></i> 초기화
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </section>
                            </section>
                        </fieldset>
                    </form>
                </div>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">인력별 프로젝트 투입 현황</p>
                </div>
                <div>
                    <!--리스트 영역-->
                    <section class="tbl-contents">
                        <div class="alert alert-info total">
                            <p>
                                <i class="glyphicon glyphicon-folder-close"></i> <span>전체: <span id="spTotCnt">{{TotalCount}}</span>건</span>
                            </p>
                        </div>
                        <div class="table-responsive">

                        </div>
                    </section>
                </div>
            </div>
        </div>
        <CP1020P ref="CP1020P"></CP1020P>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle'
    import CP1020P from '@/components/libs/popup/CP1020P'

    import {
        DxDataGrid,
        DxColumn,
        DxGrouping,
        DxGroupPanel,
        DxPager,
        DxPaging,
        DxSearchPanel
    } from 'devextreme-vue/data-grid';

    export default {
        name: 'RT2000R', 
        components: {
            ViewTitle,
            CP1020P,
            DxDataGrid,
            DxColumn,
            DxGrouping,
            DxGroupPanel,
            DxPager,
            DxPaging,
            DxSearchPanel
        },
        data() {
            return {
                TotalCount: 0,
                YearsList: new Array(),                      // 검색 일자 목록
                SearchCondition: {                           // 검색 조건
                    TargetYear: this.moment(new Date()).year(),
                    EmpId: null, 
                    EmpName: null
                }, 
                ProjectSchList: new Array()
            }
        },
        beforeMount() {
            // 대상년도 목록 값 설정
            var startYear = this.moment(new Date()).add(1, 'year').year()
            var endYear = this.moment(new Date(2010, 1, 1, 0, 0, 0)).year()
            for (var i = startYear; i >= endYear; i--) {
                this.YearsList.push(i)
            }
        },
        methods: {
            btnSearchClicked: function () {
                // 검색 버튼 클릭

            }, 
            searchEmployee: function () {
                // 직원 검색
                var successCallback = function (result) {

                    this.SearchCondition.EmpName = result.EmpNm
                    this.SearchCondition.EmpId = result.EmpNo
                }

                this.$refs.CP1020P.showManPowerPopup(successCallback)
            }, 
            clearSearchEmployee: function () {
                // 직원 검색 초기화
                this.SearchCondition.EmpId = null
                this.SearchCondition.EmpName = null
            }
        }, 
        computed: {

        }
    }
</script>

