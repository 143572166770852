<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a v-show="this.$parent.CanSearch" href="#" class="btn btn-primary btn-row2" @click.prevent="fn_ShowData(false)">
                    <span>
                        <i class="glyphicon glyphicon-search"></i>조회
                    </span>
                </a>
                <a v-show="this.$parent.CanSave" href="#" class="btn btn-default" @click.prevent="fn_AddProgramGroup">
                    <span>
                        <i class="glyphicon glyphicon-pencil"></i>등록
                    </span>
                </a>
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-3">
                                        <label for="sch3-1" class="control-label">
                                            <span>시스템</span>
                                        </label>
                                        <select class="form-control" ref="selSystId" name="selSystId">
                                            <option v-for="item in SystemIdList" v-bind:value="item.Code">{{item.Name}}</option>
                                        </select>
                                    </div>
                                </section>
                            </section>

                        </fieldset>
                    </form>
                    </div>

                </div>
                <div class="grid-wrap">
                    <div class="sub-title">
                        <p class="sub-title-txt">프로그램 그룹 관리</p>
                    </div>
                    <div>
                        <!--리스트 영역-->
                        <div class="alert alert-info total">
                            <p>
                                <i class="glyphicon glyphicon-folder-close"></i>
                                <span>
                                    전체: <span id="spTotCnt">{{ProgrGrpListTotalCount}}</span>건
                                </span>
                            </p>
                        </div>
                        <div class="table-responsive">
                            <table class="table table tbl-list tbl-center table-hover table-bordered">
                                <caption>테이블</caption>
                                <colgroup>
                                    <col />
                                    <col />
                                    <col />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col">그룹 ID</th>
                                        <th scope="col">그룹 명</th>
                                        <th scope="col">설명</th>
                                    </tr>
                                </thead>
                                <tbody id="result">
                                    <tr v-if="ProgramGroupList === null">
                                        <td colspan="3">조회된 정보가 없습니다.</td>
                                    </tr>
                                    <tr v-else v-for="item in ProgramGroupList" style="cursor:pointer;" v-on:click="rowSelectEvent(item)">
                                        <td name="programgroup-id">{{item.ProgrGrpId}}</td>
                                        <td name="programgroup-name" style="text-align:left;">{{item.ProgrGrpName}}</td>
                                        <td name="programgroup-desc" style="text-align:left;">{{item.ProgrGrpDesc}}</td>
                                        <td name="program-class-name">{{item.ProgrClsName}}</td>
                                        <td name="system-id" style="display:none">{{item.SystId}}</td>
                                        <td name="company-code" style="display:none">{{item.CorpCd}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        <div class="modal fade" ref="ZZ1011E_Modal" tabindex="-1" role="dialog" aria-labelledby="ZZ1011E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                        <h3 class="modal-title">프로그램 그룹 상세</h3>
                    </div>
                    <div class="modal-body">
                        <section class="add-wrap">
                            <form>
                                <fieldset>
                                    <legend>입력폼 col3</legend>
                                    <section class="cols-wrap">
                                        <div class="cols">
                                            <label for="txtProgrGrpId2" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>그룹 ID
                                                </span>
                                            </label>
                                            <input type="text" ref="txtProgrGrpId2" class="form-control txt" maxlength="8" 
                                                   v-bind:readonly="PageActionType !='I'"
                                                   v-model="ProgramGroupDetail.ProgrGrpId" />
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols">
                                            <label for="txtProgrGrpName2" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>그룹 명
                                                </span>
                                            </label>
                                            <input type="text" ref="txtProgrGrpName2" class="form-control txt" v-model="ProgramGroupDetail.ProgrGrpName" />
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols">
                                            <label for="txtProgrGrpDesc2" class="control-label">
                                                <span>설명</span>
                                            </label>
                                            <textarea id="txtProgrGrpDesc2" class="form-control" form-groups="10" v-model="ProgramGroupDetail.ProgrGrpDesc"></textarea>
                                        </div>
                                    </section>
                                    <section class="btn-align">
                                        <div v-show="this.$parent.CanSave" class="btn-group">
                                            <button type="button" id="btnViewSave" @click.prevent="fn_SaveProgramGroup" class="btn btn-primary">
                                                <span class="glyphicon glyphicon-save"></span> 저장
                                            </button>
                                        </div>
                                        <div v-show="this.$parent.CanDelete && PageActionType != 'I'" class="btn-group">
                                            <button type="button" id="btnViewRmve" class="btn btn-warning" @click.prevent="fn_RemoveProgramGroup">
                                                <span class="glyphicon glyphicon-edit"></span> 삭제
                                            </button>
                                        </div>
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-danger" data-dismiss="modal">
                                                <span class="glyphicon glyphicon-remove"></span> 취소
                                            </button>
                                        </div>
                                    </section>
                                </fieldset>
                            </form>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle'
    export default {
        name: 'ZZ1010R',
        components: {
            ViewTitle
        },
        data() {
            return {
                SystemIdList: null,         // 시스템 공통코드 목록
                ProgramGroupList: null,     // 프로그램 그룹 목록   
                // 프로그램 그룹 상세객체
                ProgramGroupDetail: {
                    CorpCd: null,
                    SystId: null,
                    ProgrGrpId: null,
                    ProgrGrpName: null,
                    ProgrGrpDesc: null,
                    RegtDate: null,
                    RegtEmpNo: null,
                    UpdateDate: null,
                    UpdateEmpNo: null,
                    ProgrIdCnt: null
                },
                ProgrGrpListTotalCount: 0,  // 프로그램 그룹 목록 전체 갯수
                PageActionType: 'V'         // I: 등록, V: 저장
            }
        },
        beforeMount() {

            // 공통코드 조회
            this.SystemIdList = $.scriptBase.getCommonCode('CODE090', this.$parent.CompanyCode, '', '')
        },
        mounted() {
            
            if (this.$parent.CanSearch) {

                this.fn_ShowData(false)
            }
        },
        methods: {
            // 목록 행 선택 이벤트
            rowSelectEvent: function (row) {

                // 화면 액션값 설정 (I:신규, V: 상세보기)
                this.PageActionType = 'V'

                // 선택한 행 데이터 복사
                Object.assign(this.ProgramGroupDetail, row)

                this.ProgramGroupDetail.Arg = 'V'
                this.ProgramGroupDetail.UserId = this.$parent.EmpId
                this.ProgramGroupDetail.CorpCd = this.$parent.CompanyCode

                // 상세팝업 오픈
                $(this.$refs.ZZ1011E_Modal).modal('show')
            },
            // 프로그램 그룹 목록 조회
            fn_ShowData: function (isOpenDetailPopup) {

                var parent = this
                var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/GetProgramGroup'.toRealServiceUrl()
                var obj = new Object()
                obj.userId = this.$parent.EmpId
                obj.companyCode = this.$parent.CompanyCode
                obj.systemId = this.$refs.selSystId.value

                var successCallback = function (data) {

                    var table = JSON.parse(data.d).Table;

                    if (table.length > 0) {

                        parent.ProgramGroupList = table
                        parent.ProgrGrpListTotalCount = table.length
                    }
                    else {

                        parent.ProgramGroupList = null
                        parent.ProgrGrpListTotalCount = 0
                    }

                    // 상세팝업 오픈 플래그 값에 따라 상세 팝업 오픈
                    if (isOpenDetailPopup) {

                        for (var i = 0; i < table.length; i++) {

                            if (table[i].CorpCd === parent.ProgramGroupDetail.CorpCd
                                && table[i].SystId === parent.ProgramGroupDetail.SystId
                                && table[i].ProgrGrpId === parent.ProgramGroupDetail.ProgrGrpId) {

                                parent.rowSelectEvent(table[i])

                                break
                            }
                        }
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            // 프로그램 그룹 신규 등록
            fn_AddProgramGroup: function () {

                // 화면 액션값 설정 (I:신규, V: 상세보기)
                this.PageActionType = 'I'

                // 상세객체 초기화
                this.ProgramGroupDetail = {
                    Arg: 'I',
                    UserId: this.$parent.EmpId,
                    CorpCd: this.$parent.CompanyCode,
                    SystId: this.$refs.selSystId.value,
                    ProgrGrpId: null,
                    ProgrGrpName: null,
                    ProgrGrpDesc: null,
                    RegtDate: null,
                    RegtEmpNo: null,
                    UpdateDate: null,
                    UpdateEmpNo: null,
                    ProgrIdCnt: null
                }

                // 상세팝업 오픈
                $(this.$refs.ZZ1011E_Modal).modal('show')
            },
            // 프로그램 그룹 저장
            fn_SaveProgramGroup: function () {

                if (this.ProgramGroupDetail.ProgrGrpId === null || this.ProgramGroupDetail.ProgrGrpId === '') {

                    alert('그룹 ID를 입력하세요.')
                    this.$refs.txtProgrGrpId2.focus()
                    return
                }

                if (this.ProgramGroupDetail.ProgrGrpName === null || this.ProgramGroupDetail.ProgrGrpName === '') {

                    alert('그룹 명을 입력하세요.')
                    this.$refs.txtProgrGrpName2.focus()
                    return
                }

                var parent = this
                var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/SaveProgramGroup'.toRealServiceUrl()
                var obj = new Object()
                obj.jsonString = JSON.stringify(this.ProgramGroupDetail)

                var successCallback = function (data) {

                    if (data.d > 0) {

                        if (parent.PageActionType === 'I') {

                            alert('등록 되었습니다.')
                            $(parent.$refs.ZZ1011E_Modal).modal('hide')
                            parent.fn_ShowData(false);
                        }
                        else {

                            alert('저장 되었습니다.')
                            parent.fn_ShowData(true);
                        }
                    }
                    else {

                        if (parent.PageActionType === 'I') {

                            alert('등록되지 않았습니다. 관리자에게 문의하세요.')
                        }
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            // 프로그램 그룹 삭제 메서드
            fn_RemoveProgramGroup: function () {
                
                if (confirm('삭제 하시겠습니까?')) {

                    var parent = this
                    var url = "/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/RemoveProgramGroup".toRealServiceUrl()
                    var obj = new Object()

                    // 삭제 플래그 설정
                    this.ProgramGroupDetail.Arg = 'D'

                    obj.jsonString = JSON.stringify(this.ProgramGroupDetail)

                    var successCallback = function (data) {

                        if (data.d > 0) {

                            alert('삭제 되었습니다.')
                            $(parent.$refs.ZZ1011E_Modal).modal('hide')
                            parent.fn_ShowData(false)
                        }
                    }

                    $.scriptBase.executeAjaxAsync(url, obj, successCallback)
                }
            }
        }
    }
</script>

<style scoped>
</style>