<template>
	<!-- 상세등록 전자결재 -->
	<div class="modal fade" id="BZ1024E_Modal" ref="BZ1024E_Modal" tabindex="-1" role="dialog" aria-labelledby="BZ1024E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<button type="button" class="close" @click.Prevent="close">
						<span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
					</button>
					<h3 class="modal-title">결재 요청</h3>
				</div>
				<!--결재 요청-->
				<div class="modal-body">
					<section class="add-wrap">
						<form>
							<fieldset>
								<legend></legend>
								<section class="cols-wrap">
									<div class="cols">
										<label for="txtContractPaymentNm" class="control-label">
											<span><i class="glyphicon glyphicon-asterisk"></i>결재 내역</span>
										</label>
										<input type="text" id="txtContractNm" class="form-control txt" v-model="ContractDetail.ContractPaymentNm" />
									</div>
								</section>
								<span hidden="hidden" v-text="ContractPayment.ContractPaymentDetail"></span>
								<div id="contractDetail">
									<section id="section1" class="cols-wrap">
										<div class="cols col-2">
											<label for="txtContractNm" class="control-label">
												<span>계약명상세<br /></span>
											</label>
											<label class="form-control txt">
												<span>{{ContractDetail.ContractSeqNm}}</span>
											</label>
										</div>
									</section>
									<section id="section2" class="cols-wrap">
										<div class="cols col-2">
											<label for="txtContractStartDate" class="control-label">
												<span>계약기간</span>
											</label>
											<div class="col-2 input-group date form_date">
												<label class="form-control txt">
													<span>{{ContractDetail.ContractStartDate}}</span>
												</label>
											</div>
											<span class="tail"><span>&nbsp;~</span></span>
											<div class="col-2 input-group date form_date">
												<label class="form-control txt">
													<span>{{ContractDetail.ContractEndDate}}</span>
												</label>
											</div>
										</div>
										<div class="cols col-2">
											<label for="txtContractAmount" class="control-label">
												<span>계약금액<br /></span>
											</label>
											<label class="form-control txt">
												<span>{{ContractDetail.ContractAmount}}</span>
											</label>
										</div>
									</section>
									<section id="section3" class="cols-wrap">
										<div class="cols col-2">
											<label for="selVAYIncludeYn" class="control-label">
												<span>부가세 포함<br /></span>
											</label>
											<div class="col-2 input-group date form_date">
												<label class="form-control txt">
													<span>{{ContractDetail.VAYIncludeYn}}</span>
												</label>
											</div>
										</div>
										<div class="cols col-2">
											<label for="txtVATAmount" class="control-label">
												<span>부가세<br /></span>
											</label>
											<label class="form-control txt">
												<span>{{ContractDetail.VATAmount}}</span>
											</label>
										</div>
									</section>
									<section id="section4" class="cols-wrap">
										<div class="cols col-2">
											<label for="selContractDiv" class="control-label">
												<span>계약구분<br /></span>
											</label>
											<div class="col-2 input-group date form_date">
												<label class="form-control txt">
													<span>{{ContractDetail.ContractDivNm}}</span>
												</label>
												<!--<input type="hidden" id="hideContractCompCd" v-model="Contract.ContractDiv" />-->
											</div>
										</div>
									</section>
									<section id="section5" class="cols-wrap">
										<div class="cols col-2">
											<label for="selCostPlayDiv" class="control-label">
												<span>대금지급 구분<br /></span>
											</label>
											<div class="col-2 input-group date form_date">
												<label class="form-control txt">
													<span>{{ContractDetail.CostPlayDivNm}}</span>
												</label>
												<!--<input type="hidden" id="hideContractCompCd" v-model="Contract.CostPlayDiv" />-->
											</div>
										</div>
										<div class="cols cols col-2">
											<label for="txtCostPlayMeans" class="control-label">
												<span>대급지급 조건<br /></span>
											</label>
											<label class="form-control txt">
												<span>{{ContractDetail.CostPlayMeans}}</span>
											</label>
											<!--<input type="hidden" id="hideContractCompCd" v-model="Contract.CostPlayMeans" />-->
										</div>
									</section>
									<section id="section6" class="cols-wrap">
										<div class="cols">
											<label for="txtRemark" class="control-label">
												<span>비고<br /></span>
											</label>
											<label class="form-control txt">
												<span>{{ContractDetail.Remark}}</span>
											</label>
										</div>
									</section>
									<section id="section7" class="cols-wrap">
										<div class="cols">
											<label for="txtContractExecGuarantee" class="control-label">
												<span>계약이행보증<br /></span>
											</label>
											<label class="form-control txt">
												<span>{{ContractDetail.ContractExecGuarantee}}</span>
											</label>
										</div>
									</section>
									<section id="section8" class="cols-wrap">
										<div class="cols">
											<label for="txtExecGuaranteeIns" class="control-label">
												<span>이행보증보험<br /></span>
											</label>
											<label class="form-control txt">
												<span>{{ContractDetail.ExecGuaranteeIns}}</span>
											</label>
										</div>
									</section>
									<section id="section9" class="cols-wrap">
										<div class="cols">
											<label for="txtDefactGuaranteePeriod" class="control-label">
												<span>하자보증기간<br /></span>
											</label>
											<label class="form-control txt">
												<span>{{ContractDetail.DefactGuaranteePeriod}}</span>
											</label>
										</div>
									</section>
									<section id="section10">
										<DxDataGrid :data-source="DataSource"
													:columns="DefaultColumns"
													:show-borders="true"
													:allow-column-reordering="true">
										</DxDataGrid>
									</section>
									<section id="section11">
										<DxDataGrid :data-source="ContractProductList"
													:show-borders="true"
													:allow-column-reordering="true">
											<DxColumn caption="상품명" data-field="ProductNm" alignment="center" width="150" />
											<DxColumn caption="매입품유형" data-field="ProductTypeNm" alignment="center" width="100" />
											<DxColumn caption="수량" data-field="UnitCnt" alignment="center" width="70" />
											<DxColumn caption="납품금액" data-field="DeliveryPrice" alignment="center" format="fixedPoint" width="180" />
											<DxColumn caption="매입금액" data-field="PurchasePrice" alignment="center" format="fixedPoint" width="180" />
											<DxColumn caption="매입처명" data-field="CompanyNm" alignment="center" width="150" />
											<DxColumn caption="매입처담당자" data-field="CompanyManager" alignment="center" width="120" />
											<DxColumn caption="매입 예상일" data-field="DlvDueYMD" alignment="center" data-type="date" width="120" />
										</DxDataGrid>
									</section>
								</div>
								<section class="btn-align">
									<div v-show="CanSave" class="btn-group">
										<button type="button" id="btnItemSave" class="btn btn-primary" @click.prevent="approveData">
											<i class="glyphicon glyphicon-save"></i> 결재요청
										</button>
									</div>
									<div class="btn-group">
										<button type="button" class="btn btn-danger" @click.Prevent="close">
											<i class="glyphicon glyphicon-remove"></i> 취소
										</button>
									</div>
								</section>
							</fieldset>
						</form>
					</section>
				</div>
			</div>
		</div>
		<form action="https://office.hiworks.com/minisoft/approval/popup/write" target="_blank" method="post" ref="frmApproval">
			<input type="hidden" name="form_id" value="N_26" />
			<input type="hidden" name="subject" v-model="ContractDetail.ContractPaymentNm" />
			<input type="hidden" name="contents" v-model="'<p><H1>계약 결재 요청</H1></p>'+ContractPayment.ContractPaymentDetail" />
			<input type="hidden" name="callback_url" value="https://ibs.minisoft.co.kr/v2/services/HumanMgt_NoneAuth.svc/Get/SetContractApprove" />
			<!--<input type="hidden" name="callback_url" value="http://demo.minisoft.co.kr/v2/services/HumanMgt_NoneAuth.svc/Get/SetContractApprove" />-->
			<input type="hidden" name="modify_contents_flag" value="Y" />
			<input type="hidden" name="modify_files_flag" value="Y" />
			<input type="hidden" name="state" v-model="ContractDetail.ContractNo+''+ContractDetail.ContractSeqNo" />
		</form>
	</div>
</template>

<script>
	import DxButton from 'devextreme-vue/button';
	import DxDataGrid, {
		DxColumn,
		DxEditing,
		DxLookup,
		DxSelection,
		DxScrolling
	} from 'devextreme-vue/data-grid'

	import {
		DxDateBox,
		DxCalendarOptions
	} from 'devextreme-vue/date-box'
	import {
		DxHtmlEditor,
		DxToolbar,
		DxMediaResizing,
		DxItem
	} from 'devextreme-vue/html-editor'

	export default {
		name: 'BZ1024E',
		components: {
			DxDateBox,
			DxButton,
			DxDataGrid,
			DxColumn,
			DxEditing,
			DxLookup,
			DxSelection,
			DxScrolling,
			DxCalendarOptions,
			DxHtmlEditor,
			DxToolbar,
			DxMediaResizing,
			DxItem,
		},
		data() {
			return {
				CallbackFunction: null,     // Caller Function
				Condition: null,
				Contract: {
					EmpNo: null,
					ContractNo: null,
					ContractNm: null,
					OrderCompCd: null,
					OrderCompNm: null,
					ContractCompCd: null,
					ContractCompNm: null,
					ProjDiv: '',
					MgrEmpNo: null,
					MgrEmpNm: null,
					Remark: null,
					ConfirmYn: null,
					BizNo: null
				},
				ContractMgr: {
					ContractNo: null,
					ContractMgrSeqNm: null,
					ContractMgrSeqNo: null,
					OrderMgrSeqNm: null,
					OrderMgrSeqNo: null,
					CompDiv: null,
					RegtDate: null,
					RegtEmpNo: null,
					UpdateDate: null,
					UpdateEmpNo: null
				},
				ContractDetail: {
					ContractNo: null,
					ContractSeqNo: null,
					ContractSeqNm: null,
					ContractDiv: '',
					ContractStartDate: null,
					ContractEndDate: null,
					ContractAmount: null,
					VAYIncludeYn: 'N',
					VATAmount: '0',
					RelationContract: '',
					ProjMigYn: null,
					BizSeqNo: null,
					Remark: null,
					RegtDate: null,
					RegtEmpNo: null,
					UpdateDate: null,
					UpdateEmpNo: null,
					CostPlayDiv: '',
					CostPlayMeans: null,
					ContractExecGuarantee: null,
					ExecGuaranteeIns: null,
					DefactGuaranteePeriod: null,
					ConstractStatusCd: '',
					EmpNo: null,
					ContractPaymentNo: -1,
					ContractContents: null

				},
				ContractPayment: {
					ContractPaymentDetail: null
				},
				DefaultColumns: [
					{
						caption: "성명", dataField: "InputEmpNm", width: "200"
					},
					{
						caption: "역할", dataField: "Role", width: "100",
						lookup: {
							dataSource: $.scriptBase.getCommonCode('CODE000', '0709000', '', ''),
							valueExpr: "Code", // "id" contains the same values as "authorId"
							displayExpr: "Name"
						}
					},
					{
						caption: "등급", dataField: "Rating", width: "100",
						lookup: {
							dataSource: $.scriptBase.getCommonCode('CODE000', '0313000', '', ''),
							valueExpr: "Code", // "id" contains the same values as "authorId"
							displayExpr: "Name"
						}
					},
					{ caption: "주요업무", dataField: "MainJob", width: "200" }
				],
				DataSource: new Array(),
				DataSourceList: new Array(),
				ContractProductList: this.$parent.ProductList,
				CanSave: true,
				CanDelete: true,
				CanApprove: true,
				sizeValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'],
				IsNew: true,
				isMultiLine: true,
				pageIndex: 0,
			}
		},
		beforeMount() {
			// 공통코드 조회
			this.Progress = $.scriptBase.getCommonCode('CODE000', '0732000', '', '')				// 프로젝트 구분
			this.ConstractStatusList = $.scriptBase.getCommonCode('CODE000', '0733000', '', '')		// 계약진행상태
			this.CostPlayDivList = $.scriptBase.getCommonCode('CODE000', '0721100', '', '')			// 대금지급
			this.ContractDivList = $.scriptBase.getCommonCode('CODE000', '0744000', '', '')			// 계약구분
		},
		methods: {
			open: function (callbackFunction, selectedObj) {

				if (selectedObj) {
					// 전자 결재 완료 여부
					this.ContractDetail = selectedObj
					this.selectContractManpower()
					//this.ContractDetail.EmpNo = this.$parent.$parent.$parent.$parent.$parent.$parent.EmpId
					this.ContractDetail.ContractStartDate = (selectedObj.ContractStartDate).slice(0, 10)
					this.ContractDetail.ContractEndDate = (selectedObj.ContractEndDate).slice(0, 10)
					//this.DataSource = this.$parent.DataSource
					//this.DataSourceList = this.$parent.DataSourceList
					this.ContractProductList = this.$parent.ProductList
					//this.ContractPayment.ContractPaymentDetail = this.$el.children[0].children[0].children[1].children[0].children[0][0].children[3].outerHTML

				} else {
					alert("잘못된 접근입니다.")
				}

				this.CallbackFunction = callbackFunction

				$("#BZ1024E_Modal").modal("show")
			},
			close: function () {
				$("#BZ1024E_Modal").modal("hide")
				this.CallbackFunction()
			},
			reset() {
				Object.assign(this.$data, this.$options.data())
			},
			// 계약상세 월별투입인력 조회
			selectContractManpower: function () {

				var that = this
				var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/GetContractMMPlanList'
				url = url.toRealServiceUrl()

				var obj = new Object()
				obj.jsonString = JSON.stringify(that.ContractDetail)

				var ajaxSuccessHandler = function (data) {

					var jsonObj = JSON.parse(data.d)

					that.resetColumns()

					if (!jsonObj.Table) {
						jsonObj.Table = new Array()
					}

					that.DataSource = jsonObj.Table
					// 그리드 바인딩
					that.DataCount = jsonObj.Table.length

					var columnsCount = that.DataCount > 0 ? Object.keys(jsonObj.Table[0]).length : 0

					for (var i = 0; i < columnsCount - 9; i++) {
						var columnName = Object.keys(jsonObj.Table[0])[i]
						var newColumn = { 'caption': columnName, 'dataField': columnName, width: '100' }
						that.DefaultColumns.push(newColumn)
						that.ContractPayment.ContractPaymentDetail = that.$el.children[0].children[0].children[1].children[0].children[0][0].children[3].innerHTML
					}
					//that.ContractPayment.ContractPaymentDetail = that.$el.children[0].children[0].children[1].children[0].children[0][0].children[3].outerHTML
					that.ContractPayment.ContractPaymentDetail = that.$el.children[0].children[0].children[1].children[0].children[0][0].children[3].innerHTML
				}

				

				$.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
			},
			resetColumns: function () {
				var cnt = this.DefaultColumns.length

				for (var i = 4; i < cnt; i++) {
					this.DefaultColumns.splice(4, 1)
				}
			},
			approveData: function () {
				// 결제내역 필수 값 유효성 검사
				if (!this.ContractDetail.ContractPaymentNm) {

					alert('결재내역 명을 입력하세요.')
					document.getElementById('txtContractNm').focus()

					return
				}
				// popup
				//alert("{{ ContractDetail.ContractContents}}")
				
				//this.ContractPayment.ContractPaymentDetail = this.$el.children[0].children[0].children[1].children[0].children[0][0].children[3].outerHTML
				//this.ContractPayment.ContractPaymentDetail = this.$el.children[0].children[0].children[1].children[0].children[0][0].children[3].innerHTML
				this.$refs.frmApproval.submit()
				this.close()
			},
		}
	}
</script>
