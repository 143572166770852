<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <article class="view-btns-wrap">
                    <div class="btn-group-justified" v-show="this.$parent.CanSearch">
                        <button type="button" id="btnPDFExport" class="btn btn-primary" @click.prevent="fn_export">
                            <span class="glyphicon glyphicon-print"></span>사업소득지급명세서
                        </button>
                        <a href="#" id="btnServiceExport_Excel" class="btn btn-default" @click.prevent="fn_MonthServiceExcelExport">
                            <span><i class="fa fa-file-excel-o"></i> 월별용역비</span>
                        </a>
                        <a href="#" id="btnRemiExport_Excel" class="btn btn-default" @click.prevent="fn_RemiExcelExport">
                            <span><i class="fa fa-file-excel-o"></i> 송금양식</span>
                        </a>
                    </div>
                </article>
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div id="div_DateType">
                                        <div class="cols col-4">
                                            <label for="selBelongYY" class="control-label">
                                                <span>귀속년도</span>
                                            </label>
                                            <select id="selBelongYY" class="form-control" ref="selBelongYY" name="selBelongYY" v-model="Year">
                                                <option v-for="year in YearsList" v-bind:value="year"
                                                        v-bind:selected="moment(new Date()).year() === year">
                                                    {{year}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="cols col-4">
                                            <label for="selBelongMM" class="control-label"><span>귀속월</span></label>
                                            <select class="form-control" id="selBelongMM" ref="selBelongMM" name="selBelongMM" v-model="Month">
                                                <option v-for="item in MonthList" v-bind:value="item.Code"
                                                        v-bind:selected="item.Code === GetMonth">
                                                    {{item.Code}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </section>

                            </section>
                            <section class="sch-btn">
                                <a v-show="this.$parent.CanSearch" href="#" id="btnListSrch" class="btn btn-primary btn-row2" @click.prevent="btnSearchClicked">
                                    <span>
                                        <i class="glyphicon glyphicon-search"></i> 조회
                                    </span>
                                </a>
                            </section>
                        </fieldset>
                    </form>
                    </div>
                </div>
                <div class="grid-wrap">
                    <div class="sub-title">
                        <p class="sub-title-txt">월별 지급 통계</p>
                    </div>
                    <div>
                        <!--리스트 영역-->
                        <section class="tbl-contents">
                            <div class="alert alert-info total">
                                <p>
                                    <i class="glyphicon glyphicon-folder-close"></i> <span>전체: <span id="spTotCnt">{{TotalCount}}</span>건</span>
                                </p>
                            </div>
                            <div class="table-responsive">
                                <table class="table table tbl-list tbl-center table-hover table-bordered" id="tb0">
                                    <caption>테이블</caption>
                                    <colgroup>
                                        <col />
                                        <col />
                                        <col />
                                        <col />
                                        <col />
                                        <col />
                                        <col />
                                        <col />
                                        <col />
                                    </colgroup>

                                    <thead>
                                        <tr>
                                            <th scope="col">귀속년월</th>
                                            <th scope="col">성명</th>
                                            <th scope="col">프로젝트명</th>
                                            <th scope="col">주민(사업자등록)번호</th>
                                            <th scope="col">시작일</th>
                                            <th scope="col">종료일</th>
                                            <th scope="col">총액</th>
                                            <th scope="col">개인/사업자</th>
                                            <th scope="col">은행명/계좌번호</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="ManPowerListItemList === null">
                                            <td colspan="9" class="text-center no-data">조회된 정보가 없습니다.</td>
                                        </tr>
                                        <tr v-else v-for="item in ManPowerListItemList">
                                            <td>{{item.StandYM}}</td>
                                            <td>{{item.Name}}</td>
                                            <td>{{item.ProjectNm}}</td>
                                            <td>
                                                <input type="text" name="txtResidentRegtNm" style="width:130px;" v-bind:value="item.ResidentRegtNm" maxlength="14" />
                                            </td>
                                            <td>{{item.WorkStartYMD}}</td>
                                            <td>{{item.WorkEndYMD}}</td>
                                            <td style="text-align:right;">{{item.PriceSet}}</td>
                                            <td>{{item.BelongDvsNm}}</td>
                                            <td>{{item.BankNm}}&nbsp;{{item.AccountNm}}</td>
                                            <td hidden="hidden" name="hidBelongCd">{{item.BelongDvsCd}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle'
    export default {
        name: 'RT1100R',
        components: {
            ViewTitle
        },
        data() {
            return {
                ManPowerListItemList: null,             // 월별 지급 통계 리스트
                YearsList: new Array(),                 // 년도 콤보박스 목록
                Year: this.moment(new Date()).year(),   // 년도
                Month: this.moment(new Date()).month(), // 월
                Arg: 'L',                               // L
                TotalCount: 0,                          // 데이터 총 개수
                MonthList: null,                        // 월 리스트
                GetMonth: null,                         // 현재 월
                StandYM: null,                          // 년 월 데이터 가공
                PersonalYN: null,                       // 조회된 데이터 중 '개인'의 데이터 유무
                $dt0: null,
                $dt1: null,
                $ds0: null

            }
        },
        beforeMount() {

            var startYear = this.moment(new Date()).add(1, 'year').year()
            var endYear = this.moment(new Date(2010, 1, 1, 0, 0, 0)).year()

            // 대상년도 목록 값 설정
            for (var i = startYear; i >= endYear; i--) {
                this.YearsList.push(i)
            }

            // 월 리스트 가공
            var thisMonth = 1;
            var array = new Array()

            // 배열 데이터 가공
            for (var i = 0; i < 12; i++) {
                array[i] = { 'Code': thisMonth++ }
            }

            // 년도 조회
            this.MonthList = array

            var nowMonth = new Date()
            var month = nowMonth.getMonth() + 1
            this.GetMonth = month

            
        },
        methods: {
            // 조회 버튼 클릭 이벤트 핸들러
            btnSearchClicked: function () {

                this.StandYM = this.$refs.selBelongYY.value + (this.$refs.selBelongMM.value.length == 1 ? "0" + this.$refs.selBelongMM.value : this.$refs.selBelongMM.value)

                this.showData()
            },
            // 월별 지급 톨계 조회 메서드
            showData: function () {

                var parent = this

                var url = '/Mini.Service.IBS.RT.Service/AccountingReport.svc/ajax/GetManPowerListItem_Report'
                url = url.toRealServiceUrl()

                this.Arg = 'L'

                var obj = new Object()
                obj.args = this.Arg
                obj.companyCode = this.$parent.CompanyCode
                obj.standYM = this.StandYM

                // ajax 성공 시 실행되는 핸들러 정의
                var ajaxSuccessHandler = function (data) {

                    var table = JSON.parse(data.d).Table
                    var table1 = JSON.parse(data.d).Table1

                    parent.$dt0 = table
                    parent.$dt1 = table1

                    if (table.length > 0) {

                        parent.ManPowerListItemList = table
                        parent.PersonalYN = 'Y'
                    }
                    else {

                        parent.ManPowerListItemList = null
                        parent.PersonalYN = 'N'
                    }

                    if (table.length > 0) {

                        parent.TotalCount = table.length
                    }
                    else {

                        parent.TotalCount = 0
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
            },
            // 사업소득지급명세서 레포트 출력
            fn_export: function () {

                if (this.TotalCount === 0) {

                    alert('조회된 데이터가 없습니다.')
                    return
                }

                if (this.personalYN == 'N') {

                    alert("'개인'건의 데이터가 1건 이상 있어야 합니다.")
                    return
                }


                var obj = new Object()
                obj.prm = new Array()

                for (var i = 0; i < $("#tb0 tbody tr").length; i++) {
                    // 사업자가 아닌 '개인'에 해당하는 주민번호만 prm에 push.
                    if ($("#tb0 tbody tr").eq(i).find('td[name="hidBelongCd"]')[0].innerText == '0711030') {
                        obj.prm.push($("#tb0 tbody tr").eq(i).find('input[name="txtResidentRegtNm"]').val())
                    }
                }

                // split해서 사용 [0]:arg, [1]:CorpCd, [2]:기준년월
                var Jsonstring = 'arg=RT_IncomePayment&corpCd=' + this.$parent.CompanyCode
                + "&standYM=" + this.StandYM;

                // txtResidentRegtNm값 n개 추가
                for (var i = 0; i < obj.prm.length; i++) {
                    Jsonstring = Jsonstring + "&ResiPrm=" + obj.prm[i];
                }

                $.Download("/Handlers/ReportHandler.ashx", Jsonstring)
            },
            // 월별용역비 엑셀 버튼 클릭 이벤트 핸들러
            fn_MonthServiceExcelExport: function () {

                if (this.TotalCount === 0) {
                    alert('조회된 데이터가 없습니다.')
                    return
                }

                this.Arg = 'MS'

                var url = '/Mini.Service.IBS.RT.Service/AccountingReport.svc/ajax/GetManPowerListItem_Report'
                url = url.toRealServiceUrl()

                var obj = new Object()
                obj.args = this.Arg
                obj.companyCode = this.$parent.CompanyCode
                obj.standYM = this.StandYM

                var parent = this

                var ajaxSuccessHandler = function (data) {

                    parent.$ds0 = JSON.parse(data.d);
                    parent.$dt0 = parent.$ds0.Table;
                    if (typeof parent.$dt0 != "undefined" && parent.$dt0.length > 0) {
                        var mappingData = {
                            StandYM: "귀속년월(손익계산서)",
                            PayDateYMD: "지급일",
                            Name: "성명(업체명)",
                            ProjectNm: "투입 프로젝트명",
                            WorkStartYMD: "시작일",
                            WorkEndYMD: "종료일",
                            PriceMMPaymentSet: "월 비용",
                            BelongDvsNm: "개인/사업자",

                        }


                        $.fn_ExcelExportUseMaping(parent.$dt0, parent.StandYM + "_월별용역비", mappingData)

                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
            },
            // 송금양식 엑셀 버튼 클릭 이벤트 핸들러
            fn_RemiExcelExport: function () {

                if (this.TotalCount === 0) {
                    alert('조회된 데이터가 없습니다.')
                    return
                }

                this.Arg = 'RM'

                var url = '/Mini.Service.IBS.RT.Service/AccountingReport.svc/ajax/GetManPowerListItem_Report'
                url = url.toRealServiceUrl()

                var obj = new Object()
                obj.args = this.Arg
                obj.companyCode = this.$parent.CompanyCode
                obj.standYM = this.StandYM

                var parent = this

                var ajaxSuccessHandler = function (data) {

                    parent.$ds0 = JSON.parse(data.d);
                    parent.$dt0 = parent.$ds0.Table;
                    if (typeof parent.$dt0 != "undefined" && parent.$dt0.length > 0) {
                        var mappingData = {
                            BankNm: "입금은행",
                            AccountNm: "입금계좌",
                            PriceMMPaymentSet: "이체금액",
                            Name: "받는사람",
                            PayDateYMD: "지급일",
                            PrintedContent: "받는분 통장인쇄내용",
                        }

                        $.fn_ExcelExportUseMaping(parent.$dt0, parent.StandYM + "_송금양식", mappingData);

                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
            }
        }
    }
</script>