<template>
    <div class="wrap">
        <!-- 사이드메뉴 : 시작 -->
        <div class="sidemenu">
            <p class="logo">
                <img src="../images/logo.svg" alt="logo">
            </p>
            <div class="sidemenu-title">
                <p class="sidemenu-title-mainmenu">메인메뉴</p>
            </div>
            <div class="sidemenu-content">
                <!-- 사이드메뉴 트리 : 시작 -->
                <div id="tab1" class="tab-content">
                    <!--기존 IBS 디자인 그대로 : 시작-->
                    <section class="tab-content menu-list">
                        <nav role="tabpanel" class="tab-pane active">
                            <!--기존 IBS 디자인 그대로 : 시작-->
                            <ul class="menu-content">
                                <li class="collapsed active main-menu current-menu" id="liMain"  @click.prevent="topMenuClickEvent(0)">
                                    <router-link to="/">
                                        <span class="menu-icon icon-main"></span>
                                        <span class="menu-name">메인으로</span>
                                    </router-link>
                                </li>
                                <li v-show="menuTop01.length != 0" data-toggle="collapse" data-target="#menu01" class="collapsed active main-menu accodion-menu" id="menuTop01" 
                                    @click.prevent="topMenuClickEvent(1)">
                                    <a href="#">
                                        <span class="menu-icon icon-system"></span>
                                        <span class="menu-name">시스템관리</span>
                                        <span class="menu-arrow"></span>
                                    </a>
                                </li>
                                <li class="sub-menu side-sub collapse" id="menu01">
                                    <ul id="menuSub01">
                                        <li v-for="menuItem in SubMenuList" v-if="menuItem.PrntMenuId == '01'">
                                            <a href="#" @click.prevent="subMenuClickEvent(menuItem, false)">{{menuItem.MenuName}}</a>
                                        </li>
                                    </ul>
                                </li>
                                <li v-show="menuTop02.length != 0" data-toggle="collapse" data-target="#menu02" class="collapsed active main-menu accodion-menu" id="menuTop02" 
                                    @click.prevent="topMenuClickEvent(2)">
                                    <a href="#">
                                        <span class="menu-icon icon-strandard"></span>
                                        <span class="menu-name">기준정보</span>
                                        <span class="arrow menu-arrow"></span>
                                    </a>
                                </li>
                                <li class="sub-menu side-sub collapse" id="menu02">
                                    <ul id="menuSub02">
                                        <li v-for="menuItem in SubMenuList" v-if="menuItem.PrntMenuId == '02'">
                                            <a href="#" @click.prevent="subMenuClickEvent(menuItem, false)">{{menuItem.MenuName}}</a>
                                        </li>
                                    </ul>
                                </li>
                                <li v-show="menuTop03.length != 0" data-toggle="collapse" data-target="#menu03" class="collapsed active main-menu accodion-menu" id="menuTop03"
                                    @click.prevent="topMenuClickEvent(3)">
                                    <a href="#">
                                        <span class="menu-icon icon-hrmng"></span>
                                        <span class="menu-name">인사관리</span>
                                        <span class="arrow menu-arrow"></span>
                                    </a>
                                </li>
                                <li class="sub-menu side-sub collapse" id="menu03">
                                    <ul id="menuSub03">
                                        <li v-for="menuItem in SubMenuList" v-if="menuItem.PrntMenuId == '03'">
                                            <a href="#" @click.prevent="subMenuClickEvent(menuItem, false)">{{menuItem.MenuName}}</a>
                                        </li>
                                    </ul>
                                </li>
                                <li v-show="menuTop04.length != 0" data-toggle="collapse" data-target="#menu04" class="collapsed active main-menu accodion-menu" id="menuTop04"
                                    @click.prevent="topMenuClickEvent(4)">
                                    <a href="#">
                                        <span class="menu-icon icon-sales"></span>
                                        <span class="menu-name">영업관리</span>
                                        <span class="arrow menu-arrow"></span>
                                    </a>
                                </li>
                                <li class="sub-menu side-sub collapse" id="menu04">
                                    <ul id="menuSub04">
                                        <li v-for="menuItem in SubMenuList" v-if="menuItem.PrntMenuId == '04'">
                                            <a href="#" @click.prevent="subMenuClickEvent(menuItem, false)">{{menuItem.MenuName}}</a>
                                        </li>
                                    </ul>
                                </li>
                                <li v-show="menuTop05.length != 0" data-toggle="collapse" data-target="#menu05" class="collapsed active main-menu accodion-menu" id="menuTop05"
                                    @click.prevent="topMenuClickEvent(5)">
                                    <a href="#">
                                        <span class="menu-icon icon-project"></span>
                                        <span class="menu-name">프로젝트관리</span>
                                        <span class="arrow menu-arrow"></span>
                                    </a>
                                </li>
                                <li class="sub-menu side-sub collapse" id="menu05">
                                    <ul id="menuSub05">
                                        <li v-for="menuItem in SubMenuList" v-if="menuItem.PrntMenuId == '05'">
                                            <a href="#" @click.prevent="subMenuClickEvent(menuItem, false)">{{menuItem.MenuName}}</a>
                                        </li>
                                    </ul>
                                </li>
                                <li v-show="menuTop06.length != 0" data-toggle="collapse" data-target="#menu06" class="collapsed active main-menu accodion-menu" id="menuTop06"
                                    @click.prevent="topMenuClickEvent(6)">
                                    <a href="#">
                                        <span class="menu-icon icon-accounting"></span>
                                        <span class="menu-name">회계관리</span>
                                        <span class="arrow menu-arrow"></span>
                                    </a>
                                </li>
                                <li class="sub-menu side-sub collapse" id="menu06">
                                    <ul id="menuSub06">
                                        <li v-for="menuItem in SubMenuList" v-if="menuItem.PrntMenuId == '06'">
                                            <a href="#" @click.prevent="subMenuClickEvent(menuItem, false)">{{menuItem.MenuName}}</a>
                                        </li>
                                    </ul>
                                </li>
                                <!--<li data-toggle="collapse" data-target="#menu07" class="collapsed active" id="menuTop07" @click.prevent="topMenuClickEvent(7)">
                                    <a href="#"><i class="ic-mini ic-board"></i> 게시판 <span class="arrow"></span></a>
                                </li>
                                <li class="sub-menu collapse" id="menu07">
                                    <ul id="menuSub07">
                                        <li v-for="menuItem in SubMenuList" v-if="menuItem.PrntMenuId == '07'">
                                            <a href="#" @click.prevent="subMenuClickEvent(menuItem, false)">{{menuItem.MenuName}}</a>
                                        </li>
                                    </ul>
                                </li>-->
                                <li v-show="menuTop08.length != 0" data-toggle="collapse" data-target="#menu08" class="collapsed active main-menu accodion-menu" id="menuTop08"
                                    @click.prevent="topMenuClickEvent(8)">
                                    <a href="#">
                                        <span class="menu-icon icon-report"></span>
                                        <span class="menu-name">레포트</span>
                                        <span class="arrow menu-arrow"></span>
                                    </a>
                                </li>
                                <li class="sub-menu side-sub collapse" id="menu08">
                                    <ul id="menuSub08">
                                        <li v-for="menuItem in SubMenuList" v-if="menuItem.PrntMenuId == '08'">
                                            <a href="#" @click.prevent="subMenuClickEvent(menuItem, false)">{{menuItem.MenuName}}</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <!--기존 IBS 디자인 그대로 : 끝-->
                        </nav>
                    </section>
                    <!--기존 IBS 디자인 그대로 : 끝-->
                </div>
                <div id="tab2" class="tab-content" style="display:none">
                    <!--기존 IBS 디자인 그대로 : 시작-->
                    <ul class="menu-content favorites ">
                        <li data-toggle="collapse" data-target="#favMenu" class="collapsed active main-menu accodion-menu">
                            <a href="#">
                                <span class="menu-icon icon-report"></span>
                                <span class="menu-name">즐겨찾기</span>
                                <span class="arrow menu-arrow"></span>
                            </a>
                        </li>
                        <li class="sub-menu side-sub collapse" id="favMenu">
                            <ul id="favMenuSub">
                                <li v-for="menuItem in FavoritesMenuList">
                                    <a href="#" @click.prevent="subMenuClickEvent(menuItem, true)">{{menuItem.BookMarkName}}</a>
                                    <!--<router-link :to="{path: menuItem.MenuUrl}">{{menuItem.BookMarkName}}</router-link>-->
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <!--기존 IBS 디자인 그대로 : 끝-->
                </div>
                <!--<div id="tab3" class="tab-content" style="display:none">
                    <span>탭컨텐츠3</span>
                </div>
                <div id="tab4" class="tab-content" style="display:none">
                    <span>탭컨텐츠4</span>
                </div>-->
                <!-- 사이드메뉴 트리 : 끝 -->
            </div>
            <div class="sidemenu-nav">
                <ul class="tabs">
                    <li class="active">
                        <a href="#tab1" @click.prevent="sideMenuTabClickevent($event.target, 'tab1')" />
                    </li>
                    <li>
                        <a href="#tab2" @click.prevent="sideMenuTabClickevent($event.target, 'tab2')" />
                    </li>
                    <!--<li>
                        <a href="#tab3" @click.prevent="sideMenuTabClickevent($event.target, 'tab3')" />
                    </li>
                    <li>
                        <a href="#tab4" @click.prevent="sideMenuTabClickevent($event.target, 'tab4')" />
                    </li>-->
                </ul>
            </div>
        </div>
        <!-- 사이드메뉴 : 끝 -->
        <div class="content-wrap">
            <div class="header">
                <Header></Header>
            </div>
            <!-- 상단 메뉴 탭 : 시작 -->
            <div class="tab-pannel">
                <TagsView ref="tagsview" />
            </div>
            <!-- 상단 메뉴 탭 : 끝 -->
            <!-- 컨텐츠 : 시작 -->
            <div class="content-scroll-wrap">
                <section>
                    <div class="content">
                        <keep-alive :include="KeepAliveComponentNameList">
                            <router-view></router-view>
                        </keep-alive>
                    </div>
                </section>
            </div>
            <!-- 컨텐츠 : 끝 -->
        </div>
        <div class="overlay" @click="overlayClickEvent" />
    </div>
</template>

<script>

    import Header from '@/components/Header'
    import TagsView from '@/components/TagsView'

    export default {
        name: 'Layout',
        components: {
            Header, TagsView
        },
        data() {
            return {
                KeepAliveComponentNameList: new Array(),
                KeepAliveMenuList: new Array(),
                UserName: this.$store.getters.getUserName,
                PositionName: this.$store.getters.getPositionName,
                CompanyCode: this.$store.getters.getCompanyCode,
                EmpId: this.$store.getters.getEmpId,
                AuthGrade: this.$store.getters.getAuthGrade,
                Email: this.$store.getters.getEmailAddress,
                TopMenuList: null,  // 최상위 메뉴목록
                SubMenuList: null,  // 서브 메뉴 목록
                FavoritesMenuList: null,    // 즐겨찾기 메뉴 목록
                SystemId: '10',
                Navigator: null,
                PageHeader: null,
                IsFavorite: 'N',
                CanSearch: this.$store.getters.getCanSearch,
                CanNew: this.$store.getters.getCanNew,
                CanSave: this.$store.getters.getCanSave,
                CanDelete: this.$store.getters.getCanDelete,
                CanPrint: this.$store.getters.getCanPrint,
                CanApprove: this.$store.getters.getCanApprove,
                ProgramId: null,
                MenuId1: null,
                MenuId2: null,
                BoardCnt: 10,
                CurrentPage: 1,
                BoardListTotalCount: 0,
                SelectedTopMenuId: null,
                menuTop01: null,
                menuTop02: null,
                menuTop03: null,
                menuTop04: null,
                menuTop05: null,
                menuTop06: null,
                menuTop07: null,
                menuTop08: null
            }
        },
        beforeMount() {
            //if (this.$store.getters.getEmpId === null) {
            //    this.$router.push({ path: '/Login' })
            //}
            //else {
            //    // 사용자 정보조회
            //    this.getUserInfo();

            //    // 메뉴 트리 조회
            //    this.getMenuTree()

            //    // 즐겨찾기 메뉴 조회
            //    this.getFavoritesMenu()
            //}
            // 사용자 정보조회
            this.getUserInfo();

            // 메뉴 트리 조회
            this.getMenuTree()

            // 즐겨찾기 메뉴 조회
            this.getFavoritesMenu()
        },
        mounted() {

            var parent = this
            
            // 창 사이즈 변경 이벤트 설정
            window.onresize = function () {

                parent.resizeEvent()
            }

            // 최초 창 사이즈 변경 이벤트 한번 호출
            this.resizeEvent()

            // 최초 Url로 바로 접근하는 화면이 있으면 KeepAlive 목록에 추가
            if (this.ProgramId != null && this.MenuId1 != null && this.MenuId2 != null
                && this.ProgramId != '' && this.MenuId1 != '' && this.MenuId2 != '') {

                for (var i = 0; i < this.SubMenuList.length; i++) {

                    var subMenu = this.SubMenuList[i]

                    if (subMenu.MenuId === this.ProgramId) {

                        var obj = {
                            Path: subMenu.MenuUrl,
                            ComponentName: subMenu.MenuId,
                            MenuName: subMenu.MenuName,
                            IsActive: true
                        }

                        this.KeepAliveMenuList.push(obj)

                        break
                    }
                }
            }
        },
        watch: {
            // 캐시한 컴포넌트 목록 변경 시 자동호출 함수
            KeepAliveMenuList: function (newList) {

                var parent = this
                this.KeepAliveComponentNameList = new Array()

                // 캐시할 컴포넌트 이름 목록을 재설정 함
                newList.forEach(function (item) {

                    parent.KeepAliveComponentNameList.push(item.ComponentName)
                })
            }
        },
        methods: {
            // empid 변경
   //         change: function () {
   //             this.EmpId = 
			//},

            // 브라우저 창 크기 변경 이벤트
            resizeEvent: function () {
                
                // 창 가로 사이즈가 1025 이하면 좌측 메뉴 닫기 (반대면 열기)
                if (window.innerWidth < 1025) {

                    document.getElementsByTagName('body')[0].classList.add('active')
                }
                else {

                    document.getElementsByTagName('body')[0].classList.remove('active')
                }
            },
            // 사용자 권한정보 조회
            getAuthInfo: function () {

                this.ProgramId = this.$route.query.eid
                this.MenuId1 = this.$route.query.qid
                this.MenuId2 = this.$route.query.wid

                $.scriptBase.getAuthInfo(this.SystemId
                    , this.CompanyCode
                    , this.ProgramId
                    , this.MenuId1
                    , this.MenuId2
                    , this.EmpId
                    , this.$store)

                this.CanSearch = this.$store.getters.getCanSearch
                this.CanNew = this.$store.getters.getCanNew
                this.CanSave = this.$store.getters.getCanSave
                this.CanDelete = this.$store.getters.getCanDelete
                this.CanPrint = this.$store.getters.getCanPrint
                this.CanApprove = this.$store.getters.getCanApprove
                this.Navigator = this.$store.getters.getNavigator
                this.PageHeader = this.$store.getters.getPageHeader
                this.IsFavorite = this.$store.getters.getIsFavorite
            },
            // 최상위 메뉴 클릭 이벤트
            topMenuClickEvent: function (meunNum) {

                if (meunNum === 0) {

                    $('#liMain').addClass('current-menu')

                    for (var i = 1; i < 9; i++) {

                        if (i != meunNum) {

                            $('#menuTop0' + i).addClass('collapsed');
                            $('#menu0' + i).removeClass('in');
                        }
                    }

                    return
                }
                else {
                    for (var i = 1; i < 9; i++) {

                        if (i != meunNum) {

                            $('#liMain').removeClass('current-menu')
                            $('#menuTop0' + i).addClass('collapsed');
                            $('#menu0' + i).removeClass('in');
                        }
                    }
                }
            },
            // 서브메뉴 클릭 이벤트
            subMenuClickEvent: function (menu, isBookmarkMenu) {

                this.resizeEvent()
                
                var isExist = false
                var menuInfoObject = null

                if (isBookmarkMenu) {

                    menuInfoObject = {
                        Path: menu.MenuUrl,
                        ComponentName: menu.BookMarkId,
                        MenuName: menu.BookMarkName,
                        IsActive: true
                    }
                }
                else {

                    menuInfoObject = {
                        Path: menu.MenuUrl,
                        ComponentName: menu.MenuId,
                        MenuName: menu.MenuName,
                        IsActive: true
                    }
                }

                // 동일한 메뉴가 있는지 검사
                this.KeepAliveMenuList.forEach(function (item) {

                    if (item.MenuName === menuInfoObject.MenuName) {

                        isExist = true
                    }

                    if (item.MenuName === menuInfoObject.MenuName) {


                        item.IsActive = true
                    }
                    else {

                        item.IsActive = false
                    }
                })

                // 동일 메뉴가 없다면 메뉴 추가
                if (!isExist) {

                    this.KeepAliveMenuList.push(menuInfoObject)
                }

                // 현재 라우트 경로랑 동일하지 않으면 push
                if (this.$route.path != menuInfoObject.Path.substring(0, menuInfoObject.Path.indexOf('?'))) {

                    this.$router.push(menuInfoObject.Path)
                } 
            },
            // 좌측 메뉴 영역 하단 탭 클릭 이벤트
            sideMenuTabClickevent: function (target, tabName) {

                document.querySelectorAll('.tabs li').forEach(function (element) {

                    element.classList.remove('active')
                })

                target.parentNode.classList.add('active');

                document.getElementById('tab1').style.display = 'none'
                document.getElementById('tab2').style.display = 'none'
                //document.getElementById('tab3').style.display = 'none'
                //document.getElementById('tab4').style.display = 'none'
                document.getElementById(tabName).style.display = 'block'
            },
            // 오버레이 클릭 이벤트
            overlayClickEvent: function () {

                $('body').toggleClass('active')
                $('body').css('overflow', 'auto')
            },
            // 사용자 정보 조회
            getUserInfo: function () {
                // 사용자 정보 조회
                $.scriptBase.getUserInfo(this.$store);

                this.UserName = this.$store.getters.getUserName
                this.PositionName = this.$store.getters.getPositionName
                this.CompanyCode = this.$store.getters.getCompanyCode
                this.EmpId = this.$store.getters.getEmpId
                this.AuthGrade = this.$store.getters.getAuthGrade
                this.EmailAddress = this.$store.getters.getEmailAddress
            },
            // datetimepicker 설정
            // changedDateCallback: 날짜 변경 시 호출할 콜백 함수
            setDateTimePicker: function (changeDateCallback) {

                // datetimepicker dates 설정
                $.fn.datetimepicker.dates['kr'] = {
                    days: ['일', '월', '화', '수', '목', '금', '토'],
                    daysShort: ['일', '월', '화', '수', '목', '금', '토'],
                    daysMin: ['일', '월', '화', '수', '목', '금', '토'],
                    months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
                    monthsShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
                    today: '오늘',
                    clear: "clear",
                    meridiem: ['am', 'pm'],
                    format: "yyyy-mm-dd",
                    weekstart: 0
                }

                $('.form_date').off('changeDate')
                $('.form_date').datetimepicker({
                    weekStart: 1,
                    todayBtn: 1,
                    autoclose: 1,
                    todayHighlight: 1,
                    startView: 2,
                    minView: 2,
                    forceParse: 0,
                    keyboardNavigation: true,
                    language: 'kr',
                }).on('changeDate', function (e) {

                    changeDateCallback(e)
                })
            },
            // 메뉴트리 조회
            getMenuTree: function () {

                var parent = this
                var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/GetMenuTreeList'.toRealServiceUrl()
                var obj = new Object()

                obj.jsonString = JSON.stringify({
                    'CorpCd': this.CompanyCode,
                    'SystId': this.SystemId,
                    'UserId': this.EmpId,
                    'CheckQueryAuth': 'Y'
                })

                var successCallback = function (data) {

                    var jsonData = $.parseJSON(data.d)
                    var table = jsonData.Table
                    var table1 = jsonData.Table1
                    
                    // 최상위 메뉴
                    if (table.length > 0) {

                        parent.TopMenuList = table
                    }
                    else {

                        parent.TopMenuList = null
                    }

                    // 서브메뉴
                    if (table1.length > 0) {

                        parent.SubMenuList = table1
                    }
                    else {

                        parent.SubMenuList = null
                    }

                    // 서브메뉴 없으면 상위메뉴 UI 숨김 처리
                    var Arr01 = table1.filter(function (item) {

                        return item.PrntMenuId === "01"
                    })

                    var Arr02 = table1.filter(function (item) {

                        return item.PrntMenuId === "02"
                    })

                    var Arr03 = table1.filter(function (item) {

                        return item.PrntMenuId === "03"
                    })

                    var Arr04 = table1.filter(function (item) {

                        return item.PrntMenuId === "04"
                    })

                    var Arr05 = table1.filter(function (item) {

                        return item.PrntMenuId === "05"
                    })

                    var Arr06 = table1.filter(function (item) {

                        return item.PrntMenuId === "06"
                    })

                    var Arr07 = table1.filter(function (item) {

                        return item.PrntMenuId === "07"
                    })

                    var Arr08 = table1.filter(function (item) {

                        return item.PrntMenuId === "08"
                    })

                    parent.menuTop01 = Arr01
                    parent.menuTop02 = Arr02
                    parent.menuTop03 = Arr03
                    parent.menuTop04 = Arr04
                    parent.menuTop05 = Arr05
                    parent.menuTop06 = Arr06
                    parent.menuTop07 = Arr07
                    parent.menuTop08 = Arr08
                }

                $.scriptBase.executeAjax(url, obj, successCallback)
            },
            // 즐겨찾기 메뉴 조회
            getFavoritesMenu: function () {

                var parent = this
                var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/GetFavoritesMenu'.toRealServiceUrl()
                var obj = new Object()
                obj.jsonString = JSON.stringify({

                    'Arg': 'T',
                    'UserId': this.EmpId,
                    'CorpCd': this.CompanyCode,
                    'SystId': this.SystemId,
                    'EmpNo': this.EmpId,
                    'BookMarkName': this.PageHeader
                })

                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d)
                    var table = jsonData.Table

                    if (table.length > 0) {

                        parent.FavoritesMenuList = table
                    }
                    else {

                        parent.FavoritesMenuList = null
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            }
        }
    }
</script>

<style scoped src="../css/temp_style.css"></style>