<template>
    <div class="modal fade" ref="CP1050P_Modal" id="CP1050P_Modal" tabindex="-1" role="dialog" aria-labelledby="defaultModal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">
                        <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                    </button>
                    <h3 class="modal-title">법인카드 검색</h3>
                </div>
                <div class="modal-body">
                    <!-- 검색영역 -->
                    <section class="sch-wrap">
                        <form class="form-horizontal" role="form">
                            <fieldset class="sch-form">
                                <legend>검색폼</legend>
                                <section class="sch-cont">
                                    <section class="cols-wrap">
                                        <div class="cols col-3">
                                            <label for="CP1050P_CardCp" class="control-label"><span>카드사선택</span></label>
                                            <select class="form-control" id="CP1050P_CardCp" name="CP1050P_CardCp" v-model="CorpSelection" @change="btnSearchClicked">
                                                <option value="">= 선택 =</option>
                                                <option v-for="item in Corporation" v-bind:value="item.Code">{{item.Name}}</option>
                                            </select>
                                        </div>
                                        <div class="cols col-3">
                                            <label for="CP1050P_UseYN" class="control-label"><span>사용여부</span></label>
                                            <select id="CP1050P_UseYN" class="form-control" v-model="UseYN" @change="btnSearchClicked">
                                                <option value="">== 전체 ==</option>
                                                <option value="Y" selected>Y</option>
                                                <option value="N">N</option>
                                            </select>
                                        </div>
                                        <div class="cols col-3">
                                            <label for="txtCardNo" class="control-label"><span>카드번호</span></label>
                                            <input type="text" class="form-control" id="txtCardNo" maxlength="4" placeholder="카드번호 마지막 4자리" v-model="LastCardNo"
                                                   onKeyup="this.value=this.value.replace(/[^0-9]/g,'')" @keypress.enter.prevent="keypressEvent" />
                                        </div>
                                    </section>
                                </section>
                                <section class="sch-btn">
                                    <a href="#" id="CP1050P_btnSearch" class="btn btn-primary" @click.prevent="btnSearchClicked"><span class="glyphicon glyphicon-search"></span> <span>검색</span></a>
                                </section>
                            </fieldset>
                        </form>
                    </section>
                    <!-- 테이블 -->
                    <section class="tbl-contents">
                        <div class="total alert" style="margin-bottom: 0;">
                            <p><i class="glyphicon glyphicon-folder-close"></i><span>전체: <span id="CP1050P_total">{{TotalCount}}</span>건</span></p>
                        </div>
                        <div class="table-responsive">
                            <table class="table tbl-list tbl-center table-hover table-bordered">
                                <caption>테이블</caption>
                                <colgroup>
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col">카드사</th>
                                        <th scope="col">카드번호</th>
                                        <th scope="col">사용여부</th>
                                        <th scope="col">비고</th>
                                    </tr>
                                </thead>
                                <tbody id="result">
                                    <tr v-if="CorporationList === null">
                                        <td colspan="4">조회된 정보가 없습니다.</td>
                                    </tr>
                                    <tr v-else v-for="item in CorporationList" style="cursor:pointer;" v-on:click="rowClickEvent(item)">
                                        <td>{{item.BankNm}}</td>
                                        <td>{{item.CardNo}}</td>
                                        <td>{{item.UseYN}}</td>
                                        <td>{{item.EtcTxt}}</td>
                                    </tr>
                                </tbody>
                                <tbody></tbody>
                            </table>
                        </div>
                    </section>
                    <!-- 페이징 -->
                    <div class="text-center">
                        <ul id="CP1050P_pager" ref="CP1050P_pager" class="pagination"></ul>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default btn-sm" @click.prevent="close"><span class="glyphicon glyphicon-remove"></span> 취소(닫기)</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CP1050P',
        data() {
            return {
                // 법인카드사 리스트
                Corporation: null,
                // 데이터 총 개수
                TotalCount: 0,
                // 조회된 법인카드 리스트
                CorporationList: null,
                // 회사 코드
                CompanyCode: this.$store.getters.getCompanyCode,
                // 법인카드사
                CorpSelection: '',
                // 사용여부
                UseYN: 'Y',
                // 카드번호 뒷자리
                LastCardNo: '',
                // 현재 페이지
                CurrentPage: 1,
                // 화면에 조회되는 개수
                TopSize: 10,
                // 법인카드 선택 결과를 넘겨줄 콜백함수
                CallbackFunction: null,
            }
        },
        beforeMount() {
            // 카드구분 조회
            this.Corporation = $.scriptBase.getCommonCode('CODE000', '0103000', '', '');
        },
        methods: {
            // 법인카드 검색 팝업 오픈
            open(callbackFunction) {

                var parent = this;

                this.CallbackFunction = callbackFunction;
                this.CurrentPage = 1;
                this.TotalCount = 0;
                this.CorporationList = null;

                // 카드사선택 초기화
                this.CorpSelection = '';
                // 사용여부 초기화
                this.UseYN = 'Y';
                // 카드번호 초기화
                this.LastCardNo = '';

                // 페이징 세팅
                $(this.$refs.CP1050P_pager).pager({
                    pager: this.$refs.CP1050P_pager,
                    pagerMode: "text",
                    totalItemCount: parent.TotalCount,
                    recordPerPage: parent.TopSize,
                    currentPage: parent.CurrentPage,
                    pageIndexChanged: parent.pageChangeEvent,
                    prev10Text: "&laquo;",
                    next10Text: "&raquo;",
                    selectedPageFontColor: "orange"
                })

                $(this.$refs.CP1050P_Modal).modal('show');
            },
            close() {

                $(this.$refs.CP1050P_Modal).modal('hide');
            },
            // 법인카드 검색 팝업 오픈
            showCorporationPopUp(callbackFunction) {

                var parent = this;

                this.CallbackFunction = callbackFunction;
                this.CurrentPage = 1;
                this.TotalCount = 0;
                this.CorporationList = null;

                // 카드사선택 초기화
                this.CorpSelection = '';
                // 사용여부 초기화
                this.UseYN = 'Y';
                // 카드번호 초기화
                this.LastCardNo = '';

                // 페이징 세팅
                $(this.$refs.CP1050P_pager).pager({
                    pager: this.$refs.CP1050P_pager,
                    pagerMode: "text",
                    totalItemCount: parent.TotalCount,
                    recordPerPage: parent.TopSize,
                    currentPage: parent.CurrentPage,
                    pageIndexChanged: parent.pageChangeEvent,
                    prev10Text: "&laquo;",
                    next10Text: "&raquo;",
                    selectedPageFontColor: "orange"
                })

                $(this.$refs.CP1050P_Modal).modal('show');
                this.ShowData();
            },
            // 검색 버튼 클릭 이벤트 핸들러
            btnSearchClicked() {

                this.CurrentPage = 1;
                this.ShowData();
            },
            // 법인카드 조회 메서드
            ShowData() {

                var parent = this;

                var url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/GetCorpCardList'.toRealServiceUrl();
                var obj = new Object();
                obj.prm = new Array();
                // 회사코드
                obj.prm.push(this.CompanyCode);
                //카드사 선택
                obj.prm.push(this.CorpSelection);
                //사용여부
                obj.prm.push(this.UseYN);
                obj.prm.push(this.CurrentPage);
                obj.prm.push(this.TopSize);
                obj.prm.push("");
                obj.prm.push(this.LastCardNo);

                // ajax 성공 시 실행되는 핸들러 정의
                var ajaxSuccessHandler = function (data) {

                    var table = JSON.parse(data.d).Table;
                    var table1 = JSON.parse(data.d).Table1;

                    parent.CorporationList = (table.length > 0) ? table : null;
                    parent.TotalCount = (table1.length > 0) ? table1[0].TotCnt : 0;

                    $(parent.$refs.CP1050P_pager).pager({
                        pager: parent.$refs.CP1050P_pager,
                        pagerMode: "text",
                        totalItemCount: parent.TotalCount,
                        recordPerPage: parent.TopSize,
                        currentPage: parent.CurrentPage,
                        pageIndexChanged: parent.pageChangeEvent,
                        prev10Text: "&laquo;",
                        next10Text: "&raquo;",
                        selectedPageFontColor: "orange"
                    });
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler);
            },
            // 페이지 변경 이벤트 핸들러
            pageChangeEvent(page) {

                this.CurrentPage = page;
                this.ShowData();
            },
            // 테이블Row 클릭 이벤트 핸들러
            rowClickEvent(item) {

                // 호출한 뷰로 선택한 법인카드 데이터 전달
                this.CallbackFunction(item);
                this.close();
            },
            // 검색어 필드에서 엔터키 입력 시 조회되도록 하는 키업 이벤트
            keypressEvent(event) {

                if (event.key == 'Enter') {

                    this.ShowData();
                }
            },
        }
    }
</script>