<template>
    <div>
        <div class="dashboard-wrap">
            <!-- 타이틀 영역 -->
            <ViewTitle></ViewTitle>
            <div class="content-box">
                <div class="btn-wrap">
                    <!--공통 버튼영역-->
                    <a v-show="this.$parent.CanSearch" href="#" class="btn btn-primary btn-row2" @click.prevent="fn_ShowData">
                        <span>
                            <i class="glyphicon glyphicon-search"></i>조회
                        </span>
                    </a>
                    <a v-show="this.$parent.CanSave" href="#" class="btn btn-default" @click.prevent="fn_InstData">
                        <span>
                            <i class="glyphicon glyphicon-pencil"></i>등록
                        </span>
                    </a>
                </div>
                <div class="sch-box">
                    <!--조건 선언부-->
                    <div class="sch-form">
                        <form class="form-horizontal" role="form">
                            <fieldset>
                                <legend>검색폼</legend>
                                <section class="sch-cont">
                                    <section class="cols-wrap">
                                        <div class="cols col-3">
                                            <label for="sch3-1" class="control-label">
                                                <span>시스템</span>
                                            </label>
                                            <select class="form-control" ref="selSystId" name="selSystId" v-model="ProgramSelectTerm.SystemId">
                                                <option v-for="item in SystemIdList" v-bind:value="item.Code">{{item.Name}}</option>
                                            </select>
                                        </div>
                                        <div class="cols col-3">
                                            <label for="sch3-1" class="control-label">
                                                <span>프로그램 분류</span>
                                            </label>
                                            <select class="form-control" id="selProgrCls" name="selProgrCls" v-model="ProgramSelectTerm.ProgramClassCode">
                                                <option value="">== 전체 ==</option>
                                                <option v-for="item in ProgrClsList" v-bind:value="item.Code">{{item.Name}}</option>
                                            </select>
                                        </div>
                                        <div class="cols col-3">
                                            <label for="sch3-1" class="control-label">
                                                <span>프로그램 구분</span>
                                            </label>
                                            <select class="form-control" id="selProgrType" name="selProgrType" v-model="ProgramSelectTerm.ProgramTypeCode">
                                                <option value="">== 전체 ==</option>
                                                <option v-for="item in ProgrTypeList" v-bind:value="item.Code">{{item.Name}}</option>
                                            </select>
                                        </div>
                                    </section>
                                </section> 

                            </fieldset>
                        </form>
                    </div>

                </div>
                <div class="grid-wrap">
                    <div class="sub-title">
                        <p class="sub-title-txt">프로그램 관리</p>
                    </div>
                    <div>
                        <div class="alert alert-info total">
                            <p>
                                <i class="glyphicon glyphicon-folder-close"></i>
                                <span>
                                    전체: <span id="spTotCnt">{{ProgramListTotalCount}}</span>건
                                </span>
                            </p>
                        </div>
                        <div class="table-responsive">
                            <table class="table table tbl-list tbl-center table-hover table-bordered">
                                <caption></caption>
                                <colgroup>
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col">프로그램 ID</th>
                                        <th scope="col">프로그램 명</th>
                                        <th scope="col">프로그램 경로</th>
                                        <th scope="col">프로그램 분류</th>
                                        <th scope="col">프로그램 구분</th>
                                    </tr>
                                </thead>
                                <tbody id="result">
                                    <tr v-if="ProgramList === null">
                                        <td colspan="5">조회된 정보가 없습니다.</td>
                                    </tr>
                                    <tr v-else v-for="item in ProgramList" style="cursor:pointer;" @click="fn_ShowDetail(item)">
                                        <td name="program-id">{{item.ProgrId}}</td>
                                        <td name="program-name" style="text-align:left;">{{item.ProgrName}}</td>
                                        <td name="program-path" style="text-align:left;">{{item.ProgrPath}}</td>
                                        <td name="program-class-name">{{item.ProgrClsName}}</td>
                                        <td name="program-type-name">{{item.ProgrTypeName}}</td>
                                        <td name="system-id" style="display:none">{{item.SystId}}</td>
                                        <td name="company-code" style="display:none">{{item.CorpCd}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" ref="ZZ1001E_Modal" tabindex="-1" role="dialog" aria-labelledby="ZZ1001E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                        <h3 class="modal-title">프로그램 상세</h3>
                    </div>
                    <div class="modal-body">
                        <section class="add-wrap">
                            <form>
                                <fieldset>
                                    <legend>입력폼 col3</legend>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtProgrId2" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>프로그램 ID
                                                </span>
                                            </label>
                                            <input ref="txtProgrId2" type="text" name="" class="form-control"
                                                   v-model="ProgramDetail.ProgrId" 
                                                   v-bind:readonly="PageActionType != 'I'"/>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="txtProgrName2" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>프로그램 명
                                                </span>
                                            </label>
                                            <input ref="txtProgrName2" type="text" name="" class="form-control" 
                                                   v-model="ProgramDetail.ProgrName" />
                                        </div>
                                        <div class="cols col-2">
                                            <label for="txtProgrPath2" class="control-label">
                                                <span>프로그램 경로</span>
                                            </label>
                                            <input id="txtProgrPath2" type="text" name="" class="form-control" v-model="ProgramDetail.ProgrPath" />
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="selProgrCls2" class="control-label">
                                                <span class="glyphicon glyphicon-asterisk"></span>프로그램 분류
                                            </label>
                                            <select class="form-control" ref="selProgrCls2" name="selProgrCls2" v-model="ProgramDetail.ProgrCls">
                                                <option value="">== 선택 ==</option>
                                                <option v-for="item in ProgrClsList" v-bind:value="item.Code">{{item.Name}}</option>
                                            </select>
                                            <input type="checkbox" id="cbAuthYn2" 
                                                   v-bind:checked="ProgramDetail.AuthYn === 'Y'"
                                                   @change="function(e){
                                                        ProgramDetail.AuthYn = e.target.checked ? 'Y' : 'N'
                                                   }"/> 권한 체크 함
                                        </div>
                                        <div class="cols col-2">
                                            <label for="selProgrType2" class="control-label">
                                                <span class="glyphicon glyphicon-asterisk"></span>프로그램 구분
                                            </label>
                                            <select class="form-control" ref="selProgrType2" name="selProgrType2" v-model="ProgramDetail.ProgrType">
                                                <option value="">== 선택 ==</option>
                                                <option v-for="item in ProgrTypeList" v-bind:value="item.Code">{{item.Name}}</option>
                                            </select>
                                            <input type="checkbox" id="cbChargeYn2" 
                                                   v-bind:checked="ProgramDetail.ChargeYn === 'Y'"
                                                   @change="function(e){
                                                        ProgramDetail.ChargeYn = e.target.checked ? 'Y' : 'N'
                                                   }"/> 담당 업무 여부
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtPkgName2" class="control-label">
                                                <span>패키지 명</span>
                                            </label>
                                            <input id="txtPkgName2" type="text" name="" class="form-control" v-model="ProgramDetail.PkgName" />
                                        </div>
                                        <div class="cols col-2">
                                            <label for="txtClassName2" class="control-label">
                                                <span>클래스 명</span>
                                            </label>
                                            <input id="txtClassName2" type="text" name="" class="form-control" v-model="ProgramDetail.ClassName" />
                                        </div>
                                        <div class="cols col-2">
                                            <label for="txtStartParameter2" class="control-label">
                                                <span>Parameter</span>
                                            </label>
                                            <input id="txtStartParameter2" type="text" name="" class="form-control" v-model="ProgramDetail.StartParameter" />
                                        </div>
                                        <div class="cols col-2">
                                            <label for="txtIcon2" class="control-label">
                                                <span>Icon 경로</span>
                                            </label>
                                            <input id="txtIcon2" type="text" name="" class="form-control" v-model="ProgramDetail.Icon" />
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2 ">
                                            <label for="txtEtc1Name2" class="control-label">
                                                <span>기타 버튼명1</span>
                                            </label>
                                            <input id="txtEtc1Name2" type="text" name="" class="form-control txt" v-model="ProgramDetail.Etc1Name" />
                                        </div>
                                        <div class="cols col-2">
                                            <label for="txtEtc2Name2" class="control-label">
                                                <span>기타 버튼명2</span>
                                            </label>
                                            <input id="txtEtc2Name2" type="text" name="" class="form-control txt" v-model="ProgramDetail.Etc2Name" />
                                        </div>
                                        <div class="cols col-2">
                                            <label for="txtEtc3Name2" class="control-label">
                                                <span>기타 버튼명3</span>
                                            </label>
                                            <input id="txtEtc3Name2" type="text" name="" class="form-control txt" v-model="ProgramDetail.Etc3Name" />
                                        </div>
                                        <div class="cols col-2">
                                            <label for="txtEtc4Name2" class="control-label">
                                                <span>기타 버튼명4</span>
                                            </label>
                                            <input id="txtEtc4Name2" type="text" name="" class="form-control txt" v-model="ProgramDetail.Etc4Name" />
                                        </div>
                                        <div class="cols col-2">
                                            <label for="txtEtc5Name2" class="control-label">
                                                <span>기타 버튼명5</span>
                                            </label>
                                            <input id="txtEtc5Name2" type="text" name="" class="form-control txt" v-model="ProgramDetail.Etc5Name" />
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols">
                                            <label for="txtNote2" class="control-label">
                                                <span>설명</span>
                                            </label>
                                            <textarea id="txtNote2" class="form-control" form-groups="10" v-model="ProgramDetail.Note"></textarea>
                                        </div>
                                    </section>
                                    <section class="btn-align">
                                        <div v-show="this.$parent.CanSave" class="btn-group">
                                            <button type="button" class="btn btn-primary" @click.prevent="fn_SaveData">
                                                <span class="glyphicon glyphicon-save"></span> 저장
                                            </button>
                                        </div>
                                        <div v-show="this.$parent.CanDelete && PageActionType != 'I'" class="btn-group">
                                            <button type="button" class="btn btn-warning" @click.prevent="fn_RemoveData">
                                                <span class="glyphicon glyphicon-edit"></span> 삭제
                                            </button>
                                        </div>
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-danger" data-dismiss="modal">
                                                <span class="glyphicon glyphicon-remove"></span> 취소
                                            </button>
                                        </div>
                                    </section>
                                </fieldset>
                            </form>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle'
    export default {
        name: 'ZZ1000R',
        components: {
            ViewTitle
        },
        data() {
            return {
                SystemIdList: null,         // 시스템 공통코드
                ProgrClsList: null,         // 프로그램 분류 공통코드
                ProgrTypeList: null,        // 프로그램 구분 공통코드
                ProgramList: null,          // 목록
                // 상세정보 객체
                ProgramDetail: {
                    CorpCd: null,
                    SystId: null,
                    ProgrId: null,
                    ProgrName: null,
                    ProgrCls: null,
                    ProgrDesc: null,
                    ProgrType: null,
                    ProgrPath: null,
                    Icon: null,
                    AuthYn: null,
                    AuditYN: null,
                    ChargeYn: null,
                    Etc1Name: null,
                    Etc2Name: null,
                    Etc3Name: null,
                    Etc4Name: null,
                    Etc5Name: null,
                    Note: null,
                    PkgName: null,
                    ClassName: null,
                    StartParameter: null
                },
                // 조회조건 객체
                ProgramSelectTerm: {
                    CompanyCode: this.$parent.CompanyCode,
                    SystemId: '10',
                    ProgramClassCode: '',
                    ProgramTypeCode: ''
                },
                ProgramListTotalCount: 0,   // 목록 전체 갯수
                PageActionType: 'V'               // I: 등록, V: 저장
            }
        },
        beforeMount() {

            // 공통코드 조회
            this.SystemIdList = $.scriptBase.getCommonCode('CODE090', this.$parent.CompanyCode, '', '')
            this.ProgrClsList = $.scriptBase.getCommonCode('CODE000', '0901000', '', '')
            this.ProgrTypeList = $.scriptBase.getCommonCode('CODE000', '0902000', '', '')
        },
        mounted() {
            
            if (this.$parent.CanSearch) {

                this.fn_ShowData()
            }
        },
        methods: {
            // 목록 조회
            fn_ShowData: function () {

                var parent = this
                var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/GetProgramList'.toRealServiceUrl()
                var obj = new Object()

                obj.jsonString = JSON.stringify(this.ProgramSelectTerm)

                // 서비스 호출 성공 콜백
                var successCallback = function (data) {

                    var table = JSON.parse(data.d).Table

                    if (table.length > 0) {

                        parent.ProgramList = table
                        parent.ProgramListTotalCount = table.length
                    }
                    else {

                        parent.ProgramList = null
                        parent.ProgramListTotalCount = 0
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            // 상세 조회
            fn_ShowDetail: function (row) {

                var parent = this
                var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/GetProgram'.toRealServiceUrl()
                var obj = new Object()

                obj.companyCode = row.CorpCd
                obj.systemId = row.SystId
                obj.programId = row.ProgrId

                var successCallback = function (data) {

                    // 페이지 액션 값 변경 (I: 신규, V: 상세)
                    parent.PageActionType = 'V'

                    var table = JSON.parse(data.d).Table

                    if (table.length > 0) {

                        parent.ProgramDetail = null
                        parent.ProgramDetail = table[0]
                    }

                    // 상세팝업 오픈
                    $(parent.$refs.ZZ1001E_Modal).modal('show')
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            // 저장
            fn_SaveData: function () {

                if (this.ProgramDetail.ProgrId === null || this.ProgramDetail.ProgrId === '') {

                    alert('프로그램 ID를 입력하세요.')
                    this.$refs.txtProgrId2.focus()
                    return
                }

                if (this.ProgramDetail.ProgrName === null || this.ProgramDetail.ProgrName === '') {

                    alert('프로그램명을 입력하세요.')
                    this.$refs.txtProgrName2.focus()
                    return
                }

                if (this.ProgramDetail.ProgrCls === null || this.ProgramDetail.ProgrCls === '') {

                    alert('프로그램 분류를 선택하세요.')
                    this.$refs.selProgrCls2.focus()
                    return
                }

                if (this.ProgramDetail.ProgrType === null || this.ProgramDetail.ProgrType === '') {

                    alert('프로그램 구분를 선택하세요.')
                    this.$refs.selProgrType2.focus()
                    return
                }

                var parent = this
                var url = ''

                if (this.PageActionType === 'I')
                    url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/AddProgram'.toRealServiceUrl()

                if (this.PageActionType === 'V')
                    url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/ModifyProgram'.toRealServiceUrl()
                
                var obj = new Object()

                // 로그인 사용자 아이디 추가
                this.ProgramDetail.UserId = this.$parent.EmpId

                obj.jsonString = JSON.stringify(this.ProgramDetail)

                // 서비스 호출 성공 콜백
                var successCallback = function (data) {

                    if (data.d > 0) {

                        if (parent.PageActionType === 'I') {

                            alert('등록 되었습니다.')
                            
                            $(parent.$refs.ZZ1001E_Modal).modal('hide')
                        }
                        else {

                            alert('저장 되었습니다.')

                            parent.fn_ShowDetail(parent.ProgramDetail)
                        }

                        parent.fn_ShowData()
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            // 신규등록
            fn_InstData: function () {

                // 페이지 액션 값 변경 (I: 신규, V: 상세)
                this.PageActionType = 'I'

                // 상세 객체 초기화
                this.ProgramDetail = {
                    CorpCd: this.$parent.CompanyCode,
                    SystId: this.$refs.selSystId.value,
                    ProgrId: null,
                    ProgrName: null,
                    ProgrCls: '',
                    ProgrDesc: null,
                    ProgrType: '',
                    ProgrPath: null,
                    Icon: null,
                    AuthYn: 'N',
                    AuditYN: null,
                    ChargeYn: 'N',
                    Etc1Name: null,
                    Etc2Name: null,
                    Etc3Name: null,
                    Etc4Name: null,
                    Etc5Name: null,
                    Note: null,
                    PkgName: null,
                    ClassName: null,
                    StartParameter: null
                }

                // 상세 팝업 오픈
                $(this.$refs.ZZ1001E_Modal).modal('show')
            },
            // 삭제
            fn_RemoveData: function () {

                var parent = this

                if (confirm('삭제 하시겠습니까?')) {

                    var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/RemoveProgram'.toRealServiceUrl()
                    var obj = new Object()

                    obj.userId = this.$parent.EmpId
                    obj.companyCode = this.ProgramDetail.CorpCd
                    obj.systemId = this.ProgramDetail.SystId
                    obj.programId = this.ProgramDetail.ProgrId

                    var successCallback = function (data) {

                        if (data.d > 0) {

                            alert('삭제 되었습니다.')
                            $(parent.$refs.ZZ1001E_Modal).modal('hide')

                            parent.fn_ShowData(false)
                        }
                    }

                    $.scriptBase.executeAjax(url, obj, successCallback);
                }
            },
        }
    }
</script>