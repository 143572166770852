<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a v-show="this.$parent.CanSearch" href="#" id="btnListSrch" class="btn btn-primary btn-row2" @click="btnSearchClicked">
                    <span>
                        <i class="glyphicon glyphicon-search"></i> 조회
                    </span>
                </a>
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-3">
                                        <label for="selTrgtYY" class="control-label">
                                            <span>년도</span>
                                        </label>
                                        <select class="form-control" ref="selTrgtYY" name="selTrgtYY" v-model="SearchCondition.TargetYear">
                                            <option v-for="year in YearsList" v-bind:value="year" v-bind:key="year">
                                                {{year}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-3">
                                        <label for="txtFieldNm" class="control-label">
                                            <span>사원명</span>
                                        </label>
                                        <div class="cols-btns-cont pad-r150">
                                            <input type="text" id="txtFieldNm" class="form-control txt" readonly="readonly" v-model="SearchCondition.EmpName" />
                                            <input type="hidden" id="hidFieldCd" v-model="SearchCondition.EmpNo" />
                                        </div>
                                        <div class="cols-btns">
                                            <button type="button" id="btnListPop1" class="btn btn-default" @click.prevent="searchEmployee">
                                                <span class="glyphicon glyphicon-search"></span> 검색
                                            </button>
                                            <a href="#" id="btnSrchInit" class="btn btn-default" @click.prevent="clearSearchEmployee">
                                                <span>
                                                    <i class="glyphicon glyphicon-repeat"></i> 초기화
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="cols col-3">
                                        <label for="selEmpType" class="control-label">
                                            <span>사원구분</span>
                                        </label>
                                        <select class="form-control" id="selEmpType" name="selEmpType" v-model="SearchCondition.EmployeeTypeCode">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in EmpTypeCodeList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                </section>
                            </section>
                        </fieldset>
                    </form>
                </div>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">인력별 프로젝트 투입 현황</p>
                </div>
                <div>
                    <!--리스트 영역-->
                    <section class="tbl-contents">
                        <div class="alert alert-info total">
                            <p>
                                <i class="glyphicon glyphicon-folder-close"></i> <span>전체: <span id="spTotCnt">{{EmpListCount}}</span>건</span>
                            </p>
                        </div>
                        <!--<div class="table-responsive">
                            <DxDataGrid ref="grdList"
                                        :data-source="ProjectSchList"
                                        :hoverStateEnabled="true"
                                        :show-borders="true"
                                        :show-row-lines="true"
                                        @cell-prepared="onCellPrepared">
                                <DxScrolling mode="infinite" />
                                <DxColumn cell-template="detail" width="5%" alignment="center" />
                                <template #detail="{ data }">
                                    <DxButton icon="chevronnext" styling-mode="text" @click="onCollapseClick(data)" />
                                </template>
                                <DxColumn caption="사원명" data-field="EmpNm" alignment="center" />
                                <DxColumn caption="팀명" data-field="DeptName" alignment="center" />
                                <DxMasterDetail :enabled="false" template="masterDetailTemplate" />
                                <template #masterDetailTemplate="{ data }">
                                    <RT2010R_Detail :template-data="data" />
                                </template>
                            </DxDataGrid>
                        </div>-->
                        <div class="table-responsive">
                            <DxDataGrid ref="grdList"
                                        :data-source="ProjectSchList"
                                        :hoverStateEnabled="true"
                                        :show-borders="true"
                                        :show-row-lines="true"
                                        @cell-prepared="onCellPrepared"
                                        key-expr="EmpNo">
                                <DxScrolling mode="virtual" />
                                <DxColumn cell-template="detail" width="5%" alignment="center" />
                                <template #detail="{ data }">
                                    <DxButton icon="chevronnext" styling-mode="text" @click="onCollapseClick(data)" />
                                </template>
                                <DxColumn caption="이름" data-field="EmpNm" alignment="center" />
                                <DxColumn caption="본부" data-field="Dept" alignment="center" :group-index="0" />
                                <DxColumn caption="팀" data-field="Team" alignment="center" :group-index="1" />
                                <DxMasterDetail :enabled="false" template="masterDetailTemplate" />
                                <template #masterDetailTemplate="{ data }">
                                    <RT2010R_Detail :template-data="data" />
                                </template>
                            </DxDataGrid>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <CP1020P ref="CP1020P"></CP1020P>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle'
    import CP1020P from '@/components/libs/popup/CP1020P'
    import RT2010R_Detail from '@/components/rt/libs/RT2010R_Detail'

    import DxDataGrid, { DxColumn, DxMasterDetail, DxScrolling, DxGrouping, DxGroupPanel, DxSummary, DxGroupItem } from 'devextreme-vue/data-grid'
    import { DxButton } from 'devextreme-vue'

    export default {
        name: 'RT2000R', 
        components: {
            ViewTitle
            , DxDataGrid
            , DxColumn
            , DxMasterDetail
            , DxScrolling
            , DxButton
            , DxGrouping
            , DxGroupPanel
            , DxSummary
            , DxGroupItem
            , CP1020P
            , RT2010R_Detail
        },
        data() {
            return {
                TotalCount: 0
                , EmpTypeCodeList: null                  // 사원구분 공통코드 목록
                , YearsList: new Array()                    // 검색 일자 목록
                , SearchCondition: {                           // 검색 조건
                    TargetYear: this.moment(new Date()).year()
                    , EmpId: null
                    , EmpName: null
                    , EmployeeTypeCode: '0305010'
                }
                , ProjectSchList: new Array()
                , EmpListCount: 0
            }
        },
        beforeMount() {
            this.EmpTypeCodeList = $.scriptBase.getCommonCode('CODE000', '0305000', '', '')

            // 대상년도 목록 값 설정
            var startYear = this.moment(new Date()).add(1, 'year').year()
            var endYear = this.moment(new Date(2010, 1, 1, 0, 0, 0)).year()
            for (var i = startYear; i >= endYear; i--) {
                this.YearsList.push(i)
            }
        },
        methods: {
            onCellPrepared(e) {
                if (e.rowType == 'header') {
                    e.cellElement.style['textAlign'] = 'center'
                }
                if (e.rowType == 'data') {
                    e.cellElement.style['verticalAlign'] = 'middle'
                }
            }
            , btnSearchClicked: function () {
                // 검색 버튼 클릭
                var that = this

                var url = '/Mini.Service.IBS.RT.Service/AccountingReport.svc/ajax/GetDepartmentList_Report'.toRealServiceUrl()
                var obj = new Object()

                var obj = new Object()
                obj.args = this.SearchCondition.EmployeeTypeCode
                obj.year = this.SearchCondition.TargetYear
                obj.empNo = this.SearchCondition.EmpNo

                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d)
                    that.ProjectSchList = jsonData.Table
                    that.EmpListCount = jsonData.Table.length
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)

            }
            , searchEmployee: function () {
                const that = this
                // 직원 검색
                var successCallback = function (result) {

                    that.SearchCondition.EmpName = result.EmpNm
                    that.SearchCondition.EmpNo = result.EmpNo
                }

                this.$refs.CP1020P.showManPowerPopup(successCallback)
            }
            , clearSearchEmployee: function () {
                // 직원 검색 초기화
                this.SearchCondition.EmpId = null
                this.SearchCondition.EmpName = null
            }
            // 마스터 그리드 버튼 선택 이벤트
            , onCollapseClick(e) {
                this.clicked = !this.clicked
                var expanded = e.component.isRowExpanded(e.row.key)
                if (expanded) {
                    e.component.collapseAll(-1);
                }
                else {
                    e.component.collapseAll(-1);
                    e.component.expandRow(e.row.key);
                }
            }
        }
    }
</script>

