<template>
    <div class="modal fade" id="CP1070P_Modal" tabindex="-1" role="dialog" aria-labelledby="defaultModal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" @click.Prevent="close">
                        <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                    </button>
                    <h3 class="modal-title">은행계좌 검색</h3>
                </div>
                <div class="modal-body">
                    <!-- 검색영역 -->
                    <section class="sch-wrap">
                        <form role="form">
                            <fieldset>
                                <legend>검색폼</legend>
                                <section class="sch-cont">
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="CP1070P_AccountCp" class="control-label"><span>은행명</span></label>
                                            <select class="form-control" id="CP1070P_AccountCp" name="CP1070P_AccountCp">
                                                <option value="">= 선택 =</option>
                                                <option v-for="item in Bank" v-bind:value="item.Code">{{item.Name}}</option>
                                            </select>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="CP1070P_UseYN" class="control-label"><span>사용여부</span></label>
                                            <select id="CP1070P_UseYN" class="form-control">
                                                <option value="">== 전체 ==</option>
                                                <option value="Y">Y</option>
                                                <option value="N">N</option>
                                            </select>
                                        </div>
                                    </section>
                                </section>
                                <section class="sch-btn">
                                    <a id="CP1070P_btnSearch" class="btn btn-primary" @click.prevent="btnSearchClicked"><span><i class="glyphicon glyphicon-search"></i> 검색</span></a>
                                </section>
                            </fieldset>
                        </form>
                    </section>
                    <!-- 테이블 -->
                    <section class="tbl-contents">
                        <div class="alert" style="margin-bottom: 0;">
                            <p><i class="glyphicon glyphicon-folder-close"></i><span> 전체 : <span id="CP1070P_total">{{TotalCount}}</span>건</span></p>
                        </div>
                        <div class="table-responsive">
                            <table class="table tbl-list tbl-center table-hover table-bordered">
                                <caption>테이블</caption>
                                <colgroup>
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col">은행명</th>
                                        <th scope="col">계좌번호</th>
                                        <th scope="col">계좌별칭</th>
                                        <th scope="col">사용여부</th>
                                    </tr>
                                    <tr v-if="BankList.length == 0">
                                        <td colspan="4">조회된 정보가 없습니다.</td>
                                    </tr>
                                    <tr v-else v-for="item in BankList" style="cursor:pointer;" v-on:click="rowClickEvent(item)">
                                        <td>{{item.BankNm}}</td>
                                        <td>{{item.AcntNo}}</td>
                                        <td>{{item.AcntName}}</td>
                                        <td>{{item.UseYN}}</td>
                                    </tr>
                                </thead>
                                <tbody></tbody>
                            </table>
                        </div>
                    </section>
                    <!-- 페이징 -->
                    <div class="text-center">
                        <ul id="CP1070P_pager" class="pagination"></ul>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default btn-sm" @click.Prevent="close"><span class="glyphicon glyphicon-remove"></span>취소(닫기)</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CP1070P',
        data() {
            return {
                // 은행명
                Bank: new Array(),
                // 현재 페이지
                CurrentPage: 1,
                // 화면에 조회되는 개수
                TopSize: 10,
                // 은행계좌 데이터 총 개수
                TotalCount: 0,
                // 조회된 은행계좌 리스트
                BankList: new Array(),
                // 회사 코드
                CompanyCode: this.$store.getters.getCompanyCode,
                // 은행계좌 선택 결과를 넘겨줄 콜백함수
                CallbackFunction: null
            }
        },
        beforeMount() {
            // 은행 공통코드 조회
            this.Bank = $.scriptBase.getCommonCode('CODE000', '0101000', '', '');
        },
        methods: {
            // 업체 검색 팝업 오픈
            open(callbackFunction) {

                var parent = this;
                this.CallbackFunction = callbackFunction;
                this.CurrentPage = 1;
                this.TotalCount = 0;
                this.BankList = new Array();

                // 은행명 초기화
                document.getElementById('CP1070P_AccountCp').value = '';
                // 사용여부 초기화
                document.getElementById('CP1070P_UseYN').value = '';

                // 페이징 세팅
                $("#CP1070P_pager").pager({
                    pager: "#CP1070P_pager",
                    pagerMode: "text",
                    totalItemCount: parent.TotalCount,
                    recordPerPage: parent.TopSize,
                    currentPage: parent.CurrentPage,
                    pageIndexChanged: parent.pageChangeEvent,
                    prev10Text: "&laquo;",
                    next10Text: "&raquo;",
                    selectedPageFontColor: "orange"
                });

                $('#CP1070P_Modal').modal('show');
                this.ShowData();
            },
            close() {

                $('#CP1070P_Modal').modal("hide");
            },
            // 은행계좌 조회 메서드
            ShowData() {

                var parent = this;

                var url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/GetAccountList';
                url = url.toRealServiceUrl();

                var obj = new Object();
                obj.prm = new Array();
                obj.prm.push(this.CompanyCode);              // 회사코드
                obj.prm.push($("#CP1070P_AccountCp").val()); // 계좌 선택
                obj.prm.push($("#CP1070P_UseYN").val());     // 사용여부
                obj.prm.push(this.CurrentPage);              // 선택페이지
                obj.prm.push(this.TopSize);                  // 전체페이지

                // ajax 성공 시 실행되는 핸들러 정의
                var ajaxSuccessHandler = function (data) {

                    var table = JSON.parse(data.d).Table;
                    var table1 = JSON.parse(data.d).Table1;

                    parent.BankList = (table.length > 0) ? table : new Array();
                    parent.TotalCount = (table1.length > 0) ? table1[0].TotCnt : 0;

                    // 페이징 세팅
                    $("#CP1070P_pager").pager({
                        pager: "#CP1070P_pager",
                        pagerMode: "text",
                        totalItemCount: parent.TotalCount,
                        recordPerPage: parent.TopSize,
                        currentPage: parent.CurrentPage,
                        pageIndexChanged: parent.pageChangeEvent,
                        prev10Text: "&laquo;",
                        next10Text: "&raquo;",
                        selectedPageFontColor: "orange"
                    });
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler);
            },
            // 페이징 변경 이벤트 핸들러
            pageChangeEvent(page) {

                this.CurrentPage = page;
                this.ShowData();
            },
            // 검색 버튼 클릭 이벤트 핸들러
            btnSearchClicked() {

                this.CurrentPage = 1;
                this.ShowData();
            },
            // 테이블Row 클릭 이벤트 핸들러
            rowClickEvent(item) {

                // 호출한 뷰로 선택한 은행계좌 데이터 전달
                this.CallbackFunction(item);

                $("#CP1070P_Modal").modal("hide");
            }
        }
    }
</script>