<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a v-show="this.$parent.CanSearch" href="#" id="btnListSrch" class="btn btn-primary btn-row2" @click.prevent="fn_ShowData(false)">
                    <span>
                        <i class="glyphicon glyphicon-search"></i> 조회
                    </span>
                </a>
                <a v-show="this.$parent.CanNew" href="#" id="btnListInst" class="btn btn-default" @click.prevent="fn_InstData">
                    <span>
                        <i class="glyphicon glyphicon-plus"></i> 추가
                    </span>
                </a>
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtStrDate" class="control-label">
                                            <span>발행예정일</span>
                                        </label>
                                        <div class="col-xs-3">
                                            <select class="form-control" id="txtStrDate" @change="dateTypeChangeEvent">
                                                <option value="1">오늘</option>
                                                <option value="2">일주일</option>
                                                <option value="3">한달</option>
                                                <option value="4">일년</option>
                                                <option value="5" selected="selected">이번달</option>
                                            </select>
                                        </div>
                                        <div class="col-xs-9">
                                            <label for="txtStartDate" class="sr-only">
                                                <span>발행예정일</span>
                                            </label>
                                            <div class="col-2 input-group date">
                                                <DxDateBox display-format="yyyy-MM-dd"
                                                           pickerType="calendar"
                                                           v-model="TaxInvoicSelectTerm.StartDate"
                                                           @value-changed="fn_ShowData(false)"
                                                           style="border-radius:0;">
                                                </DxDateBox>
                                            </div>
                                            <div class="tail"><span>~</span></div>
                                            <label for="txtEndDate" class="sr-only"><span>발행예정일</span></label>
                                            <div class="col-2 input-group date">
                                                <DxDateBox display-format="yyyy-MM-dd"
                                                           pickerType="calendar"
                                                           v-model="TaxInvoicSelectTerm.EndDate"
                                                           @value-changed="fn_ShowData(false)"
                                                           style="border-radius:0;">
                                                </DxDateBox>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtCompanyNm" class="control-label">
                                            <span>프로젝트</span>
                                        </label>
                                        <div class="cols-btns-cont pad-r150">
                                            <input type="text" id="txtProjectNm" class="form-control txt" readonly="readonly" v-model="TaxInvoicSelectTerm.ProjectName" @change="fn_ShowData(false)" />
                                            <input type="hidden" id="hidProjectCd" v-model="TaxInvoicSelectTerm.ProjectCode" />
                                        </div>
                                        <div class="cols-btns">
                                            <a href="#" id="btnListPop1" class="btn btn-default" @click.prevent="searchProjectCode">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i> 검색
                                                </span>
                                            </a>
                                            <a href="#" id="btnSrchInit" class="btn btn-default" @click.prevent="clearSelectTerm">
                                                <span><i class="glyphicon glyphicon-repeat"></i> 초기화</span>
                                            </a>
                                        </div>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-3">
                                        <label for="txtBillNm" class="control-label">
                                            <span>발행명</span>
                                        </label>
                                        <input type="text" id="txtBillNm" class="form-control" v-model="TaxInvoicSelectTerm.BillName" />
                                    </div>
                                    <div class="cols col-3">
                                        <label for="selPaymentCd" class="control-label">
                                            <span>발행여부</span>
                                        </label>
                                        <select class="form-control" id="selPaymentCd" name="selPaymentCd" v-model="TaxInvoicSelectTerm.PaymentCode" @change="fn_ShowData(false)">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in PaymentCodeList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-3">
                                        <label for="selSourceCd" class="control-label">
                                            <span>출처</span>
                                        </label>
                                        <select class="form-control" id="selSourceCd" name="selSourceCd" v-model="TaxInvoicSelectTerm.SourceCode" @change="fn_ShowData(false)">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in SourceCodeList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                </section>
                            </section>
                        </fieldset>
                    </form>
                </div>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">매출세금계산서 관리</p>
                </div>
                <div>
                    <!--리스트 영역-->
                    <div class="alert alert-info total">
                        <p>
                            <span class="glyphicon glyphicon-folder-close"></span>
                            전체: <span id="spTotCnt">{{TaxInvoiceListTotalCount}}</span>건
                        </p>
                    </div>
                    <div class="table-responsive">
                        <DxDataGrid id="grid-container"
                                    :show-borders="false"
                                    :data-source="TaxInvoiceList"
                                    :hoverStateEnabled="true"
                                    :allow-column-resizing="true"
                                    :column-resizing-mode="'widget'"
                                    :column-auto-width="true"
                                    :row-alternation-enabled="true"
                                    @cell-prepared="onCellPrepared"
                                    @row-click="rowSelectEvent"
                                    @selection-changed="onSelectionChanged"
                                    :selected-row-keys="selectedItemKeys"
                                    style="max-height: 500px; min-width: 1540px; vertical-align: top;">
                            <DxScrolling mode="standard" useNative="false" />
                            <DxColumnFixing :enabled="true" />
                            <DxPaging :enabled="false" />
                            <DxColumn data-field="TaxBillSrcDvsNm" caption="출처" alignment="center" />
                            <DxColumn data-field="BillNm" caption="발행명" alignment="left" />
                            <DxColumn data-field="BillDueYMD" caption="발행예정일" alignment="center" />
                            <DxColumn data-field="BillYMD" caption="발행일" alignment="center" />
                            <DxColumn data-field="PayInDueYMD" caption="입금예정일" alignment="center" />
                            <DxColumn data-field="Price" caption="매출액" format="#,##0" alignment="right" />
                            <DxColumn data-field="VAT" caption="부가세" format="#,##0" alignment="right" />
                            <DxColumn data-field="SumPrice" caption="계" format="#,##0" alignment="right" />
                            <DxColumn data-field="BillYNNm" caption="발행여부" alignment="center" />
                            <DxSummary>
                                <DxTotalItem column="BillNm" alignment="center" display-format="합계" />
                                <DxTotalItem column="Price" summary-type="sum" value-format="#,##0" display-format="{0}" />
                                <DxTotalItem column="VAT" summary-type="sum" value-format="#,##0" display-format="{0}" />
                                <DxTotalItem column="SumPrice" summary-type="sum" value-format="#,##0" display-format="{0}" />
                            </DxSummary>
                        </DxDataGrid>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="AC1001E_Modal" tabindex="-1" role="dialog" aria-labelledby="AC1001E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                        <h3 class="modal-title" v-show="PageActionType == 'I'">매출세금계산서 등록</h3>
                        <h3 class="modal-title" v-show="PageActionType == 'V'">매출세금계산서 수정</h3>
                    </div>
                    <div class="modal-body">
                        <section class="add-wrap">
                            <form>
                                <fieldset>
                                    <legend>입력폼 col3</legend>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtCompanyNm" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>세금계산서명
                                                </span>
                                            </label>
                                            <input type="text" id="txtBillNm2" class="form-control txt"
                                                   v-model="TaxInvoiceDetail.BillNm"
                                                   v-bind:readonly="ReadOnly === true" />
                                            <input type="hidden" id="txthideSetArg" v-model="TaxInvoiceDetail.InOutConfirmYN" />
                                        </div>
                                        <div class="cols col-2">
                                            <label for="txtBillYMD2" class="control-label">
                                                <span>발행일</span>
                                            </label>
                                            <div class="col-1 input-group date form-control" id="div_BillYMD">
                                                <DxDateBox display-format="yyyy-MM-dd"
                                                           pickerType="calendar"
                                                           v-model="TaxInvoiceDetail.BillYMD"
                                                           :input-attr="{ 'aria-lable' : 'Disabled' }"
                                                           :disabled="ReadOnly"
                                                           style="border: none;" />
                                            </div>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtBillCompanyNm2" class="control-label">
                                                <span><i class="glyphicon glyphicon-asterisk"></i>매출처명</span>
                                            </label>
                                            <div class="cols-btns-cont">
                                                <input type="text" id="txtPublishCompanyNm2" class="form-control txt" readonly="readonly" v-model="TaxInvoiceDetail.PublishCompanyNm" />
                                                <input type="hidden" id="hidPublishCompanyCd2" value="" v-model="TaxInvoiceDetail.PublishCompanyCd" />
                                            </div>
                                            <div class="cols-btns">
                                                <button type="button" id="btnPublishCompany" class="btn btn-default"
                                                        v-show="ReadOnly === false"
                                                        @click="searchCompany">
                                                    <span>
                                                        <i class="glyphicon glyphicon-search"></i> 검색
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="txtSourceNm2" class="control-label">
                                                <span>출처구분</span>
                                            </label>
                                            <input type="text" id="txtSourceNm2" class="form-control txt" readonly="readonly" v-model="TaxInvoiceDetail.TaxBillSrcDvsNm" />
                                            <input type="hidden" id="txtSourceCd2" class="form-control txt" v-model="TaxInvoiceDetail.TaxBillSrcDvsCd" />
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtPrice2" class="control-label">
                                                <span><i class="glyphicon glyphicon-asterisk"></i>매출액</span>
                                            </label>
                                            <input type="text" id="txtPrice2" class="form-control text-right"
                                                   v-bind:value="TaxInvoiceDetail.Price"
                                                   v-bind:readonly="ReadOnly === true"
                                                   @change="autoCalculateVat"
                                                   @input="function(e){
                                                        TaxInvoiceDetail.Price = e.target.value.toPriceString()
                                                        e.target.value = e.target.value.toPriceString()
                                                   }" />
                                        </div>
                                        <div class="cols col-2">
                                            <label for="selVatIncludeYn" class="control-label">
                                                <span>부가세구분</span>
                                            </label>
                                            <select class="form-control" id="selVatIncludeYn" ref="selVatIncludeYn" name="selVatIncludeYn"
                                                    v-bind:disabled="ReadOnly === true"
                                                    v-model="TaxInvoiceDetail.IncludeVatYn"
                                                    @change="autoCalculateVat">
                                                <option value="">== 선택 ==</option>
                                                <option value="Y">포함</option>
                                                <option value="N">별도</option>
                                                <option value="Z">영세율</option>
                                                <option value="F">면세</option>
                                            </select>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtVAT2" class="control-label">
                                                <span>부가세</span>
                                            </label>
                                            <input type="text" id="txtVAT2" class="form-control text-right"
                                                   style="background-color: #EAEAEA; pointer-events: none;"
                                                   v-bind:value="TaxInvoiceDetail.VAT"
                                                   @input="function(e){
                                                        TaxInvoiceDetail.VAT = e.target.value.toPriceString()
                                                        e.target.value = e.target.value.toPriceString()
                                                   }" />
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtMgrSeqNm2" class="control-label">
                                                <span>거래담당</span>
                                            </label>
                                            <div class="cols-btns-cont">
                                                <input type="text" id="txtMgrNm2" class="form-control txt" readonly="readonly" v-model="TaxInvoiceDetail.MgrNm" />
                                                <input type="hidden" id="hidMgrSeqNo2" v-model="TaxInvoiceDetail.MgrSeqNo" />
                                            </div>
                                            <div class="cols-btns">
                                                <button type="button" id="btnMgrSrhPop" class="btn btn-default"
                                                        v-show="ReadOnly === false"
                                                        @click="searchAccountManager">
                                                    <span>
                                                        <i class="glyphicon glyphicon-search"></i> 검색
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="txtBillElecSeqNo2" class="control-label">
                                                <span>고유번호</span>
                                            </label>
                                            <input type="text" id="txtBillElecSeqNo2" class="form-control text-right" maxlength="20"
                                                   v-model="TaxInvoiceDetail.BillElecSeqNo"
                                                   v-bind:readonly="ReadOnly === true" />
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <!-- 입금 예정일 -->
                                            <label for="txtPayInDueYMD" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>입금예정일
                                                </span>
                                            </label>
                                            <div class="col-1 input-group date form-control" id="div_PayInDueYMD">
                                                <DxDateBox display-format="yyyy-MM-dd"
                                                           pickerType="calendar"
                                                           v-model="TaxInvoiceDetail.PayInDueYMD"
                                                           :input-attr="{ 'aria-lable' : 'Disabled' }"
                                                           :disabled="ReadOnly"
                                                           style="border: none;" />
                                            </div>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="selTaxPayCd2" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>발행여부
                                                </span>
                                            </label>
                                            <select class="form-control" id="selTaxPayCd2" name="selTaxPayCd2"
                                                    v-model="TaxInvoiceDetail.BillYN"
                                                    v-bind:disabled="TaxInvoiceDetail.InOutConfirmYN === 'Y'"
                                                    @change="changeReadOnly">
                                                <option value="">== 선택 ==</option>
                                                <option v-for="item in TaxPayCodeList" v-bind:value="item.Code">
                                                    {{item.Name}}
                                                </option>
                                            </select>
                                        </div>
                                    </section>
                                    <section class="cols-wrap" style="margin-bottom: 20px;">
                                        <div class="cols col-1">
                                            <label for="txtCollectMemo" class="control-label">
                                                <span>비고</span>
                                            </label>
                                            <textarea id="txtCollectMemo" class="form-control" v-model="TaxInvoiceDetail.SaleInvoiceMemo" v-bind:readonly="ReadOnly === true" />
                                        </div>
                                    </section>
                                </fieldset>
                            </form>
                        </section>
                    </div>
                    <div class="modal-footer">
                        <section class="btn-align">
                            <div v-show="this.$parent.CanSave" class="btn-group">
                                <label v-show="TaxInvoiceDetail.InOutConfirmYN === 'Y'" class="control-label" id="label_InfoLabel"> 입금이 진행되었습니다. 수정, 삭제가 불가능합니다.</label>
                                <button v-show="TaxInvoiceDetail.InOutConfirmYN != 'Y'" type="button" id="btnItemSave" class="btn btn-primary" @click="fn_SaveData">
                                    <span class="glyphicon glyphicon-save"></span> 저장
                                </button>
                            </div>
                            <div v-show="this.$parent.CanDelete" class="btn-group">
                                <button v-show="TaxInvoiceDetail.InOutConfirmYN === 'N'" type="button" id="btnItemRmve" class="btn btn-warning" @click="fn_RemoveData">
                                    <span class="glyphicon glyphicon-edit"></span> 삭제
                                </button>
                            </div>
                            <div class="btn-group">
                                <button type="button" class="btn btn-danger" data-dismiss="modal">
                                    <span class="glyphicon glyphicon-remove"></span> 취소
                                </button>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
        <CP1030P ref="CP1030P"></CP1030P>
        <CP1040P ref="CP1040P"></CP1040P>
        <CP1060P ref="CP1060P"></CP1060P>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle';
    import CP1030P from '@/components/libs/popup/CP1030P';
    import CP1040P from '@/components/libs/popup/CP1040P';
    import CP1060P from '@/components/libs/popup/CP1060P';
    import { DxDataGrid, DxColumn, DxPaging, DxScrolling, DxColumnFixing, DxSorting, DxSummary, DxTotalItem, } from 'devextreme-vue/data-grid';
    import DxDateBox, { DxCalendarOptions } from 'devextreme-vue/date-box';
    export default {
        name: 'AC1000R',
        components: {
            ViewTitle, CP1030P, CP1040P, CP1060P, DxDateBox, DxCalendarOptions, DxDataGrid, DxColumn, DxPaging, DxScrolling, DxColumnFixing, DxSorting, DxSummary, DxTotalItem,
        },
        data() {
            return {
                selectedItemKeys: new Array(),
                PaymentCodeList: new Array(),
                SourceCodeList: new Array(),
                TaxPayCodeList: new Array(),
                TaxInvoiceList: new Array(),       // 세금계산서 목록
                // 상세 데이터 객체
                TaxInvoiceDetail: {
                    SeqNo: null,
                    TaxBillSrcDvsCd: null,
                    TaxBillSrcDvsNm: null,
                    BillYMD: null,
                    PayInDueYMD: null,
                    BillNm: null,
                    Price: null,
                    VAT: null,
                    SumPrice: null,
                    MgrSeqNo: null,
                    MgrNm: null,
                    BillElecSeqNo: null,
                    BillYN: null,
                    BillYNNm: null,
                    RegtDate: null,
                    RegtEmpNo: null,
                    RegtEmpNm: null,
                    UpdateDate: null,
                    UpdateEmpNo: null,
                    InOutConfirmYN: null,
                    BillDueYMD: null,
                    PublishCompanyCd: null,
                    PublishCompanyNm: null,
                    IncludeVatYn: '',
                    SaleInvoiceMemo: '',
                },
                // 조회조건 데이터 객체
                TaxInvoicSelectTerm: {
                    Arg: 'L',
                    UserId: this.$parent.EmpId,
                    CorpCd: this.$parent.CompanyCode,
                    ProjectCode: '',
                    ProjectName: '',
                    CompanyName: '',
                    CompanyCode: '',
                    BillName: '',
                    PaymentCode: '',
                    SourceCode: '',
                    StartDate: this.moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD'),
                    EndDate: this.moment(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)).format('YYYY-MM-DD'),
                    TopSize: 10,
                    CurrentPage: 1
                },
                TaxInvoiceListTotalCount: 0,
                PageActionType: 'V',
                ReadOnly: false,
            }
        },
        beforeMount() {

            // 공통코드 조회
            this.PaymentCodeList = $.scriptBase.getCommonCode('CODE000', '0513000', '', '');
            this.SourceCodeList = $.scriptBase.getCommonCode('CODE000', '0507000', '', '');
            this.TaxPayCodeList = $.scriptBase.getCommonCode('CODE000', '0513000', '', '');
        },
        mounted() {

            if (this.$parent.CanSearch) {

                this.fn_ShowData(false);
            }
        },
        methods: {
            // 발행예정일 날짜 타입 변경 이벤트
            dateTypeChangeEvent: function (event) {

                var dateType = Number(event.target.value);

                switch (dateType) {
                    case 1: {

                        this.TaxInvoicSelectTerm.StartDate = this.moment(new Date()).format('YYYY-MM-DD');
                        this.TaxInvoicSelectTerm.EndDate = this.moment(new Date()).format('YYYY-MM-DD');
                        break;
                    }
                    case 2: {

                        this.TaxInvoicSelectTerm.StartDate = this.moment(new Date()).add(-7, 'days').format('YYYY-MM-DD');
                        this.TaxInvoicSelectTerm.EndDate = this.moment(new Date()).format('YYYY-MM-DD');
                        break;
                    }
                    case 3: {

                        this.TaxInvoicSelectTerm.StartDate = this.moment(new Date()).add(-1, 'months').format('YYYY-MM-DD');
                        this.TaxInvoicSelectTerm.EndDate = this.moment(new Date()).format('YYYY-MM-DD');
                        break;
                    }
                    case 4: {

                        this.TaxInvoicSelectTerm.StartDate = this.moment(new Date()).add(-1, 'years').format('YYYY-MM-DD');
                        this.TaxInvoicSelectTerm.EndDate = this.moment(new Date()).format('YYYY-MM-DD');
                        break;
                    }
                    case 5: {

                        this.TaxInvoicSelectTerm.StartDate = this.moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD');
                        this.TaxInvoicSelectTerm.EndDate = this.moment(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)).format('YYYY-MM-DD');
                        break;
                    }
                    default:
                }
            },
            // datagrid header 가운데 정렬, column 중앙 정렬
            onCellPrepared(e) {

                if (e.rowType == 'header') {
                    e.cellElement.style['textAlign'] = 'center';
                }
                if (e.rowType == 'data') {
                    e.cellElement.style['verticalAlign'] = 'middle';
                }
            },
            // 프로젝트 검색 메서드
            searchProjectCode: function () {

                var parent = this;

                var successCallback = function (result) {

                    // 결과값을 추출 후 돔객체에 바인딩
                    parent.TaxInvoicSelectTerm.ProjectName = result.ProjectNm;
                    parent.TaxInvoicSelectTerm.ProjectCode = result.ProjectCd;
                    parent.TaxInvoicSelectTerm.CompanyName = result.CompanyNm;
                    parent.TaxInvoicSelectTerm.CompanyCode = result.CompanyCd;

                    parent.fn_ShowData();
                }

                this.$refs.CP1060P.showProjectPopup(successCallback);
            },

            // 조회조건 초기화 메서드
            clearSelectTerm: function () {

                this.TaxInvoicSelectTerm.ProjectCode = '';
                this.TaxInvoicSelectTerm.ProjectName = '';
                this.TaxInvoicSelectTerm.CompanyName = '';
                this.TaxInvoicSelectTerm.CompanyCode = '';
                this.TaxInvoicSelectTerm.BillName = '';
                this.TaxInvoicSelectTerm.PaymentCode = '';
                this.TaxInvoicSelectTerm.SourceCode = '';
                this.TaxInvoicSelectTerm.StartDate = this.moment().add(-1, 'year').format('YYYY-MM-DD');
                this.TaxInvoicSelectTerm.EndDate = this.moment().format('YYYY-MM-DD');

                this.fn_ShowData();
            },
            // 발행업체 검색
            searchCompany: function () {

                var parent = this;
                var successCallback = function (result) {

                    parent.TaxInvoiceDetail.PublishCompanyCd = result.CompanyCd;
                    parent.TaxInvoiceDetail.PublishCompanyNm = result.CompanyNm;
                }

                this.$refs.CP1030P.open(successCallback);
            },
            // 거래처담당 검색
            searchAccountManager: function () {

                var parent = this;
                var successCallback = function (result) {

                    parent.TaxInvoiceDetail.MgrNm = result.MgrNm;
                    parent.TaxInvoiceDetail.MgrSeqNo = result.SeqNo;
                }

                this.$refs.CP1040P.open(successCallback);
            },
            // 선택한 로우 인덱스
            onSelectionChanged(e) {

                this.selectedItemKeys = e.selectedRowKeys;
            },
            // 목록 선택 이벤트
            rowSelectEvent(e) {

                let data = (e.data) ? e.data : e;

                // 화면 액션값 설정 (I:신규, V: 상세보기)
                this.PageActionType = 'V';

                // 선택한 행 데이터 복사
                this.TaxInvoiceDetail = Object.assign({}, data);

                if (this.TaxInvoiceDetail.IncludeVatYn === undefined) {

                    this.TaxInvoiceDetail.IncludeVatYn = (this.TaxInvoiceDetail.VAT != '') ? 'N' : 'Y';
                }

                // 금액 형식 변환
                this.TaxInvoiceDetail.Price = this.TaxInvoiceDetail.Price.toPriceNumber();
                this.TaxInvoiceDetail.VAT = this.TaxInvoiceDetail.VAT.toPriceNumber();

                // Arg, 로그인 사용자 아이디 값 추가
                this.TaxInvoiceDetail.Arg = 'I';
                this.TaxInvoiceDetail.UserId = this.$parent.EmpId;

                if (this.TaxInvoiceDetail.InOutConfirmYN === 'I') {

                    this.TaxInvoiceDetail.TaxBillSrcDvsNm = '프로젝트 없음';
                    this.TaxInvoiceDetail.TaxBillSrcDvsCd = '0507030';
                }

                // 캘린더 수정 비활성화
                this.ReadOnly = (this.TaxInvoiceDetail.BillYN === '0513010' || this.TaxInvoiceDetail.InOutConfirmYN === 'Y') ? true : false;

                $('#AC1001E_Modal').modal('show');
            },
            // 캘린더 수정 비활성화
            changeReadOnly() {

                this.ReadOnly = (this.TaxInvoiceDetail.BillYN === '0513010' || this.TaxInvoiceDetail.InOutConfirmYN === 'Y') ? true : false;
            },
            // 데이터 조회
            fn_ShowData(isOpenDetailPopup) {

                var parent = this;
                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/GetTaxInvoice'.toRealServiceUrl();
                var obj = new Object();
                obj.jsonString = JSON.stringify(this.TaxInvoicSelectTerm);

                // 서비스 호출 성공 콜백 함수
                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d);
                    var table = jsonData.Table;
                    var table1 = jsonData.Table1;

                    if (table.length > 0) {

                        // 조회 목록 데이터 설정
                        parent.TaxInvoiceList = table;

                        // 상세팝업 오픈 플래그 값에 따라 상세 팝업 오픈
                        if (isOpenDetailPopup) {

                            for (var i = 0; i < table.length; i++) {

                                if (table[i].SeqNo === parent.TaxInvoiceDetail.SeqNo) {

                                    parent.rowSelectEvent(table[i]);
                                    break;
                                }
                            }
                        }
                    } else {

                        // 조회된 목록이 없으면 변수 초기화
                        parent.TaxInvoiceList = new Array();
                    }

                    // 세금계산서 목록 전체 카운터 설정
                    parent.TaxInvoiceListTotalCount = (table1.length > 0) ? table1[0].TotCnt : 0;
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 데이터 등록
            fn_InstData() {

                // 화면 액션값 설정 (I:신규, V: 상세보기)
                this.PageActionType = 'I';
                this.ReadOnly = false;

                // 상세 객체 초기화
                this.TaxInvoiceDetail = {
                    Arg: 'I',
                    UserId: this.$parent.EmpId,
                    SeqNo: null,
                    TaxBillSrcDvsCd: '0507030',
                    TaxBillSrcDvsNm: '프로젝트 없음',
                    BillYMD: null,
                    PayInDueYMD: null,
                    BillNm: null,
                    Price: '0',
                    VAT: '0',
                    MgrSeqNo: null,
                    MgrNm: null,
                    BillElecSeqNo: null,
                    BillYN: '',
                    BillYNNm: null,
                    RegtDate: null,
                    RegtEmpNo: null,
                    RegtEmpNm: null,
                    UpdateDate: null,
                    UpdateEmpNo: null,
                    InOutConfirmYN: 'I', // I: 신규 입력, N: 발행 예정인 상태의 수정 가능함, Y: 수정 불가능
                    BillDueYMD: null,
                    PublishCompanyCd: null,
                    PublishCompanyNm: null,
                    IncludeVatYn: '',
                };

                // 등록 팝업 오픈
                $('#AC1001E_Modal').modal('show');
            },
            // 데이터 저장
            fn_SaveData: function () {

                if (this.TaxInvoiceDetail.BillNm === null || this.TaxInvoiceDetail.BillNm === '') {

                    alert('세금계산서명을 입력하세요.');
                    document.getElementById('txtBillNm2').focus();
                    return;
                }
                if (this.TaxInvoiceDetail.PublishCompanyCd === null || this.TaxInvoiceDetail.PublishCompanyCd === '') {

                    alert('매출처명을 입력하세요.');
                    return;
                }
                if (this.TaxInvoiceDetail.Price === null || this.TaxInvoiceDetail.Price === '') {

                    alert('매출액을 입력하세요.');
                    return;
                }
                if (!this.TaxInvoiceDetail.PayInDueYMD) {

                    alert('입금예정일을 입력하세요.');
                    return;
                }
                if (this.TaxInvoiceDetail.BillYN === null || this.TaxInvoiceDetail.BillYN === '') {

                    alert('발행여부를 선택하세요.');
                    document.getElementById('selTaxPayCd2').focus();
                    return;
                }
                if (this.TaxInvoiceDetail.BillYN === '0513010') {

                    if (this.TaxInvoiceDetail.BillYMD === null || this.TaxInvoiceDetail.BillYMD === '') {

                        alert('발행일을 입력하세요.');
                        return;
                    }
                }

                var parent = this;
                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/SaveTaxInvoice'.toRealServiceUrl();
                var obj = new Object();
                this.TaxInvoiceDetail.BillYMD = this.TaxInvoiceDetail.BillYMD ? this.moment(this.TaxInvoiceDetail.BillYMD).format('YYYYMMDD') : null;
                this.TaxInvoiceDetail.BillDueYMD = this.moment(this.TaxInvoiceDetail.BillDueYMD).format('YYYYMMDD');
                this.TaxInvoiceDetail.PayInDueYMD = this.moment(this.TaxInvoiceDetail.PayInDueYMD).format('YYYYMMDD');
                this.TaxInvoiceDetail.Price = Number(this.TaxInvoiceDetail.Price.replace(/[^0-9\.-]+/g, ''));
                this.TaxInvoiceDetail.VAT = Number(this.TaxInvoiceDetail.VAT.replace(/[^0-9\.-]+/g, ''));

                obj.jsonString = JSON.stringify(this.TaxInvoiceDetail);

                // 서비스호출 성공 콜백 함수
                var successCallback = function (data) {

                    if (data.d > 0) {

                        if (parent.PageActionType === 'I') {

                            alert('등록되었습니다.');
                            $('#AC1001E_Modal').modal('hide');
                            parent.fn_ShowData(false);
                        } else {

                            alert('저장되었습니다.');
                            parent.fn_ShowData(false);
                        }
                    } else {

                        alert('저장에 실패하였습니다. 잠시 후 다시 시도해 주세요.');
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 데이터 삭제
            fn_RemoveData: function () {

                if (confirm('매출세금계산서를 삭제하시겠습니까?')) {

                    var parent = this;
                    var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/RemoveTaxInvoice'.toRealServiceUrl();
                    var obj = new Object();
                    obj.arg = 'D';
                    obj.userId = this.$parent.EmpId;
                    obj.seqNo = this.TaxInvoiceDetail.SeqNo;

                    var successCallback = function (data) {

                        if (data.d > 0) {

                            alert('삭제되었습니다.');
                            $('#AC1001E_Modal').modal('hide');
                            parent.fn_ShowData(false);
                        } else {

                            alert('삭제에 실패하였습니다. 잠시 후 다시 시도해 주세요.');
                        }
                    }

                    $.scriptBase.executeAjaxAsync(url, obj, successCallback);
                }
            },
            // 부가세 자동계산
            autoCalculateVat: function () {

                if (this.TaxInvoiceDetail.Price != null && this.TaxInvoiceDetail.Price != '' && this.TaxInvoiceDetail.IncludeVatYn === 'N') {

                    let vatcal = Number(this.TaxInvoiceDetail.Price.replace(/[^0-9\.-]+/g, '')) * 0.1;
                    this.TaxInvoiceDetail.VAT = Math.round(vatcal).toPriceNumber();
                } else {

                    this.TaxInvoiceDetail.VAT = 0;
                }
            },
        }
    }
</script>