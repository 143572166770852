var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-wrap"},[_c('div',{staticClass:"content-box"},[_c('article',{staticClass:"board-view"},[_c('form',[_c('fieldset',[_c('legend'),_vm._m(0),_c('section',{staticClass:"board-view-wrap"},[_vm._m(1),_vm._m(2),_c('section',{staticClass:"tbl-contents"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table tbl-list table-hover table-bordered",attrs:{"id":"tb1"}},[_vm._m(3),_vm._m(4),_c('tbody',[(_vm.NoticeDetailKist === null)?_c('tr',[_c('td',{staticClass:"text-center no-data",attrs:{"colspan":"2"}},[_vm._v("조회된 정보가 없습니다..")])]):_vm._e()])])])]),_vm._m(5)]),_c('section',{staticClass:"board-reply-wrap"},[_c('div',{staticClass:"reply-view-area",attrs:{"id":"divtest"}}),_c('div',{staticClass:"board-page"}),_c('div',{staticClass:"board-view-list"},[_c('table',{staticClass:"table-fixed",attrs:{"id":"nextPrevtb"}},[_vm._m(6),_c('tr',[_vm._m(7),_c('td',[_c('a',{attrs:{"href":"#","id":"prevContents"},on:{"click":function($event){$event.preventDefault();return _vm.prevContentClickEvent.apply(null, arguments)}}})]),_c('td',{attrs:{"id":"prevEmpName"}}),_c('td',{attrs:{"id":"prevDate"}})]),_vm._m(8)])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"board-view-head"},[_c('p',{attrs:{"id":"Title"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"board-view-info"},[_c('ul',[_c('li',{staticClass:"board-name-wrap"},[_c('i',{staticClass:"fa fa-user"}),_c('span',[_vm._v("작성자")]),_c('span',{staticClass:"board-name-cont",attrs:{"id":"spwriteName"}})]),_c('li',{staticClass:"board-hit-wrap"},[_c('i',{staticClass:"fa fa-eye"}),_c('span',[_vm._v("조회수")]),_c('span',{staticClass:"board-hit-cont",attrs:{"id":"spwriteClickCnt"}})]),_c('li',{staticClass:"board-date-wrap"},[_c('i',{staticClass:"fa fa-clock-o"}),_c('span',[_vm._v("작성일")]),_c('span',{staticClass:"board-date-cont",attrs:{"id":"spwriteDate"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"board-view-cont"},[_c('p',[_c('pre',{attrs:{"id":"Contents"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('colgroup',[_c('col',{staticClass:"chk"}),_c('col')])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"text-center",attrs:{"scope":"col"}}),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("첨부파일")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"board-view-btn"},[_c('a',{attrs:{"href":"#","id":"btnItemEdit"}},[_c('i',{staticClass:"fa fa-pencil-square-o"}),_vm._v("수정")]),_c('a',{attrs:{"href":"#","id":"btnItemRmve"}},[_c('i',{staticClass:"fa fa-trash-o"}),_vm._v("삭제")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('colgroup',[_c('col',{staticClass:"view-list-next"}),_c('col',{staticClass:"view-list-sub"}),_c('col',{staticClass:"view-list-name"}),_c('col',{staticClass:"view-list-date"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('i',{staticClass:"fa fa-caret-up"}),_c('span',[_vm._v(" 이전글")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_c('i',{staticClass:"fa fa-caret-down"}),_c('span',[_vm._v(" 다음글")])]),_vm._v(" "),_c('td',[_c('a',{attrs:{"href":"#","id":"nextContents"}})]),_c('td',{attrs:{"id":"nextEmpName"}}),_c('td',{attrs:{"id":"nextDate"}})])
}]

export { render, staticRenderFns }