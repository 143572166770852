<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a v-show="this.$parent.CanSearch" href="#" id="btnListSrch" class="btn btn-primary btn-row2" @click.prevent="btnSearchClicked">
                    <span>
                        <i class="glyphicon glyphicon-search"></i> 조회
                    </span>
                </a>
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtCompanyNm" class="control-label"><span>프로젝트</span></label>
                                        <div class="cols-btns-cont pad-r150">
                                            <input type="text" id="txtProjectNm" class="form-control txt" readonly="readonly" v-model="StatisticsPurchaseByProjectTerm.ProjectName" />
                                            <input type="hidden" id="hidCorpCd" v-model="StatisticsPurchaseByProjectTerm.CorpCd" />
                                            <input type="hidden" id="hidProjectCd" v-model="StatisticsPurchaseByProjectTerm.ProjectCode" />
                                            <input type="hidden" id="hidCompanyNm" v-model="StatisticsPurchaseByProjectTerm.CompanyName" />
                                            <input type="hidden" id="hidCompanyCd" v-model="StatisticsPurchaseByProjectTerm.CompanyCode" />
                                        </div>
                                        <div class="cols-btns">
                                            <a href="#" id="btnPrjListPop" class="btn btn-default" @click.prevent="searchProjectCode">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i> 검색
                                                </span>
                                            </a>
                                            <a href="#" id="btnSrchInit" class="btn btn-default" @click.prevent="clearProjectCode">
                                                <span>
                                                    <i class="glyphicon glyphicon-repeat"></i> 초기화
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </section>
                            </section>

                        </fieldset>
                    </form>
                </div>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">프로젝트별 매입 통계</p>
                </div>
                <div>
                    <!--리스트 영역-->
                    <section class="tbl-contents">
                        <div class="alert alert-info total">
                            <p>
                                <i class="glyphicon glyphicon-folder-close"></i> <span>전체: <span id="spTotCnt">{{TotalCount}}</span>건</span>
                            </p>
                        </div>
                        <div class="table-responsive">
                            <table class="table table tbl-list tbl-center table-hover table-bordered" id="tbStatisticsPuchase">
                                <caption>테이블</caption>
                                <colgroup>
                                    <col class="chk" />
                                    <col class="table-colwid15" />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">거래처</th>
                                        <th scope="col">프로젝트</th>
                                        <th scope="col" style="width:100px;">계약금액</th>
                                        <th scope="col" style="width:100px;">매입예정총액</th>
                                        <th scope="col" style="width:100px;">매입확정총액</th>
                                        <th scope="col" style="width:105px;">매입실행총액</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="StatisticsPurchaseByProjectList_1.length == 0">
                                        <td colspan="7" class="text-center no-data">조회된 정보가 없습니다.</td>
                                    </tr>
                                    <tr v-else v-for="item in StatisticsPurchaseByProjectList_1" style="cursor:pointer;">
                                        <td class="chk"></td>
                                        <td>{{item.CompanyNm}}</td>
                                        <td style="text-align:left">{{item.ProjectNm}}</td>
                                        <td style="text-align:right;">{{item.ContractAmount}}</td>
                                        <td style="text-align:right;">{{item.TaxInvoiceAmtSum}}</td>
                                        <td style="text-align:right;">{{item.ReceiveTaxInvoiceAmtSum}}</td>
                                        <td style="text-align:right;">{{item.AccAmtSum}}</td>
                                    </tr>
                                    <tr v-show="StatisticsPurchaseByProjectList_1.length > 0">
                                        <td class="chk"></td>
                                        <td colspan="6">
                                            <table class="table table tbl-list tbl-center table-hover table-bordered" id="tbStatisticsPuchaseDetail">
                                                <caption></caption>
                                                <colgroup>
                                                    <col />
                                                    <col />
                                                    <col />
                                                    <col />
                                                    <col />
                                                    <col />
                                                </colgroup>
                                                <thead>
                                                    <tr>
                                                        <th scope="col">매입명</th>
                                                        <th scope="col">출처명</th>
                                                        <th scope="col">매입실행일</th>
                                                        <th scope="col">매입예정금액</th>
                                                        <th scope="col">매입확정금액</th>
                                                        <th scope="col">매입실행금액</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-if="StatisticsPurchaseByProjectList_2.length == 0">
                                                        <td colspan="6" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                    </tr>
                                                    <tr v-else v-for="item in StatisticsPurchaseByProjectList_2" style="cursor:pointer;">
                                                        <td style="text-align:left">{{item.PurchaseNm}}</td>
                                                        <td>{{item.SrcDvcName}}</td>
                                                        <td style="text-align:right;width:100px;">{{item.ExcuteDt}}</td>
                                                        <td style="text-align:right;width:100px;">{{item.Amt}}</td>
                                                        <td style="text-align:right;width:100px;">{{item.IssuedAmt}}</td>
                                                        <td style="text-align:right;width:100px;">{{item.InOutAmt}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <CP1060P ref="CP1060P"></CP1060P>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle';
    import CP1060P from '@/components/libs/popup/CP1060P';
    export default {
        name: 'RT1000R',
        components: {
            ViewTitle, CP1060P
        },
        data() {
            return {
                // 프로젝트별 매입 통계 리스트
                StatisticsPurchaseByProjectList_1: new Array(),
                // 프로젝트별 매입 통계 리스트의 내부 리스트
                StatisticsPurchaseByProjectList_2: new Array(),
                // 테이블 건수
                TotalCount: 0,
                // 조회조건 데이터 객체
                StatisticsPurchaseByProjectTerm: {
                    // 회사코드
                    CorpCd: '',
                    // 프로젝트 코드
                    ProjectCode: '',
                    // 프로젝트 이름
                    ProjectName: '',
                    // 거래처 이름
                    CompanyName: '',
                    // 거래처 코드
                    CompanyCode: '',
                }
            }
        },
        methods: {
            // 프로젝트 검색 메서드
            searchProjectCode() {

                var parent = this;

                var successCallback = function (result) {

                    // 결과값을 추출 후 돔객체에 바인딩
                    parent.StatisticsPurchaseByProjectTerm.CorpCd = result.CorpCd;
                    parent.StatisticsPurchaseByProjectTerm.ProjectName = result.ProjectNm;
                    parent.StatisticsPurchaseByProjectTerm.ProjectCode = result.ProjectCd;
                    parent.StatisticsPurchaseByProjectTerm.CompanyName = result.CompanyNm;
                    parent.StatisticsPurchaseByProjectTerm.CompanyCode = result.CompanyCd;
                }

                this.$refs.CP1060P.open(successCallback);
            },
            // 프로젝트 초기화 메서드
            clearProjectCode() {

                this.StatisticsPurchaseByProjectTerm.CorpCd = '';
                this.StatisticsPurchaseByProjectTerm.ProjectName = '';
                this.StatisticsPurchaseByProjectTerm.ProjectCode = '';
                this.StatisticsPurchaseByProjectTerm.CompanyName = '';
                this.StatisticsPurchaseByProjectTerm.CompanyCode = '';
            },
            // 조회 버튼 클릭 이벤트 핸들러
            btnSearchClicked() {

                var corpCode = this.StatisticsPurchaseByProjectTerm.CorpCd;

                // 유효성검사
                if (corpCode === undefined || corpCode === '' || corpCode === null) {

                    this.StatisticsPurchaseByProjectList_1 = new Array();
                    this.StatisticsPurchaseByProjectList_2 = new Array();
                    alert("프로젝트를 선택해 주십시오.");
                    return;
                }

                this.showData();
            },
            // 프로젝트별 매입 통계 조회 메서드
            showData() {

                var parent = this;

                var url = '/Mini.Service.IBS.RT.Service/AccountingReport.svc/ajax/GetStatisticsPurchaseByProject'.toRealServiceUrl();
                var obj = new Object();
                obj.jsonString = JSON.stringify(this.StatisticsPurchaseByProjectTerm);

                // ajax 성공 시 실행되는 핸들러 정의
                var ajaxSuccessHandler = function (data) {

                    // 테이블
                    var table = JSON.parse(data.d).Table;
                    parent.StatisticsPurchaseByProjectList_1 = (table.length > 0) ? table : new Array();

                    // 테이블 내부 테이블
                    var table1 = JSON.parse(data.d).Table1;
                    parent.StatisticsPurchaseByProjectList_2 = (table1.length > 0) ? table1 : new Array();

                    // 테이블 건수
                    parent.TotalCount = (table.length > 0 || table1.length > 0) ? (table.length + '/' + table1.length) : 0;
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler);
            }
        }
    }
</script>