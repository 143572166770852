<template>
    <div class="modal fade" ref="CP1030P_Modal" tabindex="-1" role="dialog" aria-labelledby="defaultModal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" @click.Prevent="close">
                        <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                    </button>
                    <h3 class="modal-title">업체 검색</h3>
                </div>
                <div class="modal-body">
                    <!-- 검색영역 -->
                    <section class="sch-wrap">
                        <form role="form">
                            <fieldset>
                                <legend>검색폼</legend>
                                <section class="sch-cont">
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="CP1030P_selSection" class="control-label"><span>업체 구분</span></label>
                                            <select class="form-control" ref="CP1030P_selSection" name="CP1030P_selSection">
                                                <option value="">= 선택 =</option>
                                                <option v-for="item in Company" v-bind:value="item.Code">{{item.Name}}</option>
                                            </select>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="CP1030P_txtSearch" class="control-label"><span>업체명</span></label>
                                            <input ref="CP1030P_txtSearch" type="text" name="CP1030P_txtSearch" class="form-control txt" @keypress.enter.prevent="keypressEvent" />
                                        </div>
                                    </section>

                                </section>

                                <section class="sch-btn">
                                    <a id="CP1030P_btnSearch" class="btn btn-primary" @click.prevent="btnSearchClicked"><span><i class="glyphicon glyphicon-search"></i> 검색</span></a>
                                </section>
                            </fieldset>
                        </form>
                    </section>
                    <!-- 리스트 영역 -->
                    <section class="tbl-contents">
                        <div class="total alert" style="margin-bottom: 0;">
                            <p><i class="glyphicon glyphicon-folder-close"></i><span>전체: <span id="CP1030P_total">{{TotalCount}}</span>건</span></p>
                        </div>

                        <div class="table-responsive">
                            <table class="table tbl-list tbl-center table-hover table-bordered">
                                <caption>테이블</caption>
                                <colgroup>
                                    <col />
                                    <col />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col">업체 구분</th>
                                        <th scope="col">업체명</th>
                                    </tr>
                                </thead>
                                <tbody id="result">
                                    <tr v-if="CompanyList === null">
                                        <td colspan="2">조회된 정보가 없습니다.</td>
                                    </tr>
                                    <tr v-else v-for="item in CompanyList" style="cursor: pointer;" @click="rowClickEvent(item)">
                                        <td name="company-corpnm">{{item.CorpNm}}</td>
                                        <td name="company-name">{{item.CompanyNm}}</td>
                                    </tr>
                                </tbody>
                                <tbody></tbody>
                            </table>
                        </div>
                    </section>
                    <!-- 페이징 -->
                    <div class="text-center">
                        <ul ref="CP1030P_pager" id="CP1030P_pager" class="pagination"></ul>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default btn-sm" @click.Prevent="close"><span class="glyphicon glyphicon-remove"></span>취소(닫기)</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CP1030P',
        data() {
            return {
                Company: new Array(),                            // 업체구분 리스트
                CurrentPage: 1,                                  // 현재 페이지
                TopSize: 10,                                     // 화면에 조회되는 개수
                TotalCount: 0,                                   // 데이터 총 개수
                CompanyList: new Array(),                        // 조회된 업체 리스트
                CompanyCode: this.$store.getters.getCompanyCode, // 회사 코드
                CallbackFunction: null                           // 업체 선택 결과를 넘겨줄 콜백함수
            }
        },
        beforeMount() {

            // 업체구분 조회
            this.Company = $.scriptBase.getCommonCode('CODE000', '0107000', '', '');
        },
        methods: {
            // 업체 검색 팝업 오픈
            open(callbackFunction) {

                this.CallbackFunction = callbackFunction;
                this.CurrentPage = 1;
                this.TotalCount = 0;
                this.CompanyList = new Array();

                // 업체 구분 초기화
                this.$refs.CP1030P_selSection.value = '';

                // 업체명 초기화
                this.$refs.CP1030P_txtSearch.value = '';

                // 페이징 초기화
                $(this.$refs.CP1030P_pager).pager({
                    pager: this.$refs.CP1030P_pager,
                    pagerMode: "text",
                    totalItemCount: this.TotalCount,
                    recordPerPage: this.TopSize,
                    currentPage: this.CurrentPage,
                    pageIndexChanged: this.pageChangeEvent,
                    prev10Text: "&laquo;",
                    next10Text: "&raquo;",
                    selectedPageFontColor: "green"
                });

                this.ShowData();

                $(this.$refs.CP1030P_Modal).modal('show');
            },
            close() {

                $(this.$refs.CP1030P_Modal).modal("hide");
            },
            // 업체 조회 메서드
            ShowData() {

                var parent = this;

                var url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/GetCompanyList'.toRealServiceUrl();
                var obj = new Object();
                obj.prm = new Array();
                obj.prm.push(this.CompanyCode);                       // 사원정보회사코드
                obj.prm.push(this.$refs.CP1030P_txtSearch.value);     // 업체명
                obj.prm.push(this.$refs.CP1030P_selSection.value);    // 담당구분
                obj.prm.push(this.CurrentPage);                       // 선택페이지
                obj.prm.push(this.TopSize);                           // 전체페이지

                // ajax 성공 시 실행되는 핸들러 정의
                var ajaxSuccessHandler = function (data) {

                    var table = JSON.parse(data.d).Table;
                    var table1 = JSON.parse(data.d).Table1;

                    parent.CompanyList = (table.length > 0) ? table : new Array();
                    parent.TotalCount = (table1.length > 0) ? table1[0].TotCnt : 0;

                    // 페이징 세팅
                    $(parent.$refs.CP1030P_pager).pager({
                        pager: parent.$refs.CP1030P_pager,
                        pagerMode: "text",
                        totalItemCount: parent.TotalCount,
                        recordPerPage: parent.TopSize,
                        currentPage: parent.CurrentPage,
                        pageIndexChanged: parent.pageChangeEvent,
                        prev10Text: "&laquo;",
                        next10Text: "&raquo;",
                        selectedPageFontColor: "green"
                    });
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler);
            },
            // 페이지 변경 이벤트 핸들러
            pageChangeEvent(page) {

                this.CurrentPage = page;
                this.ShowData();
            },
            // 검색 버튼 클릭 이벤트 핸들러
            btnSearchClicked() {

                this.CurrentPage = 1;
                this.ShowData();
            },
            // 테이블Row 클릭 이벤트 핸들러
            rowClickEvent(item) {

                // 호출한 뷰로 선택한 업체정보 데이터 전달
                this.CallbackFunction(item);
                this.close();
            },
            // 검색어 필드에서 엔터키 입력 시 조회되도록 하는 키업 이벤트
            keypressEvent(event) {

                if (event.key == 'Enter') {

                    this.ShowData();
                }
            },
        }
    }
</script>