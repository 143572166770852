<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a v-show="this.$parent.CanSearch" href="#" id="btnListSrch" class="btn btn-primary" @click.prevent="fn_ShowData">
                    <span>
                        <i class="glyphicon glyphicon-search"></i> 조회
                    </span>
                </a>
                <a v-show="this.$parent.CanNew" href="#" id="btnListInst" class="btn btn-default" @click.prevent="fn_InstData">
                    <span>
                        <i class="glyphicon glyphicon-plus"></i> 추가
                    </span>
                </a>
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-3">
                                        <label for="selTrgtYY" class="control-label">
                                            <span>사용연월</span>
                                        </label>
                                        <div class="col-xs-5">
                                            <select id="selTrgtYY" class="form-control" v-model="ExpenseRequestSelectTerm.TargetYear" @change="fn_ShowData">
                                                <option value="">== 전체 ==</option>
                                                <option v-for="year in TargetYearList"
                                                        v-bind:value="year">
                                                    {{year}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-xs-5">
                                            <select id="selTrgtMM" class="form-control" v-model="ExpenseRequestSelectTerm.TargetMonth" @change="fn_ShowData">
                                                <option value="">== 전체 ==</option>
                                                <option v-for="month in TargetMonthList"
                                                        v-bind:value="month">
                                                    {{month}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="cols col-3">
                                        <label for="selUseTypeCd" class="control-label">
                                            <span>사용구분</span>
                                        </label>
                                        <select class="form-control" id="selUseTypeCd" name="selUseTypeCd" v-model="ExpenseRequestSelectTerm.UseTypeCode" @change="fn_ShowData">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in UseTypeCodeList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-3">
                                        <label for="selAprvDvsCd" class="control-label">
                                            <span>승인구분</span>
                                        </label>
                                        <select class="form-control" id="selAprvDvsCd" name="selAprvDvsCd" v-model="ExpenseRequestSelectTerm.AprvDvsCode" @change="fn_ShowData">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in AprvDvsCodeList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-3">
                                        <label for="selAcntCd" class="control-label">
                                            <span>계정과목</span>
                                        </label>
                                        <select class="form-control" id="selAcntCd" name="selAcntCd" v-model="ExpenseRequestSelectTerm.AcntCode" @change="fn_ShowData">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in AcntCodeList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-3">
                                        <label for="txtProjectNm" class="control-label">
                                            <span>프로젝트</span>
                                        </label>
                                        <div class="cols-btns-cont pad-r150">
                                            <input type="text" id="txtProjectNm" class="form-control txt" readonly="readonly" v-model="ExpenseRequestSelectTerm.ProjectName" />
                                            <input type="hidden" id="hidProjectCd" v-model="ExpenseRequestSelectTerm.ProjectCode" />
                                            <input type="hidden" id="hidTraderCd" value="" v-model="ExpenseRequestSelectTerm.TraderCode" />
                                        </div>
                                        <div class="cols-btns">
                                            <a v-show="this.$parent.CanSearch" href="#" id="btnListPop1" class="btn btn-default" @click.prevent="searchProject">
                                                <i class="glyphicon glyphicon-search"></i> 검색
                                            </a>
                                            <a v-show="this.$parent.CanSearch" href="#" id="btnSrchInit1" class="btn btn-default wix75"
                                               @click.prevent="function(e){
                                                    ExpenseRequestSelectTerm.ProjectCode = null
                                                    ExpenseRequestSelectTerm.ProjectName = null
                                               }">
                                                <i class="glyphicon glyphicon-repeat"></i> 초기화
                                            </a>
                                        </div>
                                    </div>
                                    <div class="cols col-3">
                                        <label for="txtCardNo" class="control-label">
                                            <span>카드번호</span>
                                        </label>
                                        <div class="cols-btns-cont pad-r150">
                                            <input type="text" id="txtCardNo" class="form-control txt" readonly="readonly" v-model="ExpenseRequestSelectTerm.DisplayCardNo" />
                                            <input type="hidden" id="txtCardNo" class="form-control txt" v-model="ExpenseRequestSelectTerm.CardNo" />
                                        </div>
                                        <div class="cols-btns">
                                            <a v-show="this.$parent.CanSearch" href="#" id="btnListPop2" class="btn btn-default" @click.prevent="searchCorporationCard">
                                                <i class="glyphicon glyphicon-search"></i> 검색
                                            </a>
                                            <a v-show="this.$parent.CanSearch" href="#" id="btnSrchInit2" class="btn btn-default wix75"
                                               @click.prevent="function(e){
                                                    ExpenseRequestSelectTerm.CardNo = null
                                                    ExpenseRequestSelectTerm.DisplayCardNo = null
                                                    ExpenseRequestSelectTerm.CardCorpCd = null
                                                    ExpenseRequestSelectTerm.CardCorpName = null
                                               }">
                                                <i class="glyphicon glyphicon-repeat"></i> 초기화
                                            </a>
                                        </div>
                                    </div>
                                </section>
                            </section>
                        </fieldset>
                    </form>
                </div>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">경비승인 요청</p>
                </div>
                <div>
                    <!--리스트 영역-->
                    <section class="tbl-contents">
                        <div class="alert alert-info">
                            <p class="total">
                                <i class="glyphicon glyphicon-folder-close"></i>
                                전체: <span id="spTotCnt">{{ExpenseRequestListTotalCount}}</span>건
                            </p>
                        </div>
                        <div class="table-responsive">
                            <DxDataGrid id="grid-container"
                                        :show-borders="false"
                                        :data-source="ExpenseRequestList"
                                        :hoverStateEnabled="true"
                                        :row-alternation-enabled="true"
                                        :column-auto-width="true"
                                        @row-click="rowSelectEvent"
                                        style="max-height: 500px; vertical-align: top;">
                                    <DxScrolling mode="standard" useNative="false" />
                                    <DxPaging :enabled="false" />
                                    <DxColumnFixing :enabled="true" />
                                    <DxColumn data-field="RegtDate" caption="신청일자" alignment="center" />
                                    <DxColumn data-field="EmpNm" caption="신청자" alignment="center" />
                                    <DxColumn data-field="DisplayCardNo" caption="카드번호" alignment="center" />
                                    <DxColumn data-field="UseYMD" caption="사용일자" alignment="center" />
                                    <DxColumn data-field="Price" caption="금액" alignment="right" />
                                    <DxColumn data-field="UseTypeName" caption="사용구분" alignment="center" />
                                    <DxColumn data-field="AprvEmpNm" caption="승인자" alignment="center" />
                                    <DxColumn data-field="AprvDvsName" caption="승인구분" alignment="center" />
                            </DxDataGrid>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <AC1031E ref="AC1031E"></AC1031E>
        <CP1050P ref="CP1050P"></CP1050P>
        <CP1060P ref="CP1060P"></CP1060P>
    </div>
</template>
<script>
    import ViewTitle from '@/components/ViewTitle';
    import { DxDataGrid, DxColumn, DxScrolling, DxSorting, DxPaging, DxButton, DxColumnFixing } from 'devextreme-vue/data-grid';
    import DxDateBox, { DxCalendarOptions } from 'devextreme-vue/date-box';
    import AC1031E from '@/components/ac/popup/AC1031E';
    import CP1050P from '@/components/libs/popup/CP1050P';
    import CP1060P from '@/components/libs/popup/CP1060P';
    export default {
        name: 'AC1030R',
        components: {
            ViewTitle, DxDataGrid, DxColumn, DxScrolling, DxSorting, DxPaging, DxButton, DxColumnFixing, DxDateBox, DxCalendarOptions, AC1031E, CP1050P, CP1060P,
        },
        data() {
            return {
                // 사용연도 목록
                TargetYearList: new Array(),
                // 사용월 목록
                TargetMonthList: new Array(),
                // 사용구분 목록
                UseTypeCodeList: new Array(),
                // 승인구분 목록
                AprvDvsCodeList: new Array(),
                // 계정과목 목록
                AcntCodeList: new Array(),
                // 조회조건 객체
                ExpenseRequestSelectTerm: {
                    Arg: 'L',
                    UserId: this.$parent.EmpId,
                    CorpCd: this.$parent.CompanyCode,
                    SeqNo: null,
                    EmpNo: this.$store.getters.getEmpId,
                    ReqYMD: null,
                    ProjectCode: '',
                    ProjectName: null,
                    CompanyCd: null,
                    AcntCode: '',
                    AprvDvsCode: '',
                    StartDate: null,
                    EndDate: null,
                    UseTypeCode: '',
                    AuthGrade: this.$parent.AuthGrade,
                    AprvEmpNo: null,
                    TargetYear: this.moment(new Date()).format('YYYY'),
                    TargetMonth: this.moment(new Date()).format('MM'),
                    DisplayCardNo: null,
                    CardCorpCd: null,
                    CardCorpName: null,
                    CardNo: null
                },
                // 경비승인요청 목록
                ExpenseRequestList: null,
                // 경비승인요청 전체 건수
                ExpenseRequestListTotalCount: 0,
                //법인카드 데이터 객체
                CompanyCardDetail: new Object(),
            }
        },
        beforeMount() {

            // 공통코드 조회
            this.AcntCodeList = $.scriptBase.getCommonCode('CODE100', this.$parent.CompanyCode, '', '');
            this.UseTypeCodeList = $.scriptBase.getCommonCode('CODE000', '0501000', '', '');
            this.AprvDvsCodeList = $.scriptBase.getCommonCode('CODE000', '0105000', '', '');

            var startYear = this.moment(new Date()).year();
            var endYear = this.moment(new Date(2010, 1, 1, 0, 0, 0)).year();

            // 대상연도 목록 값 설정
            for (let i = startYear; i >= endYear; i--) {

                this.TargetYearList.push(i);
            }

            // 대상월 목록 값 설정
            for (let j = 1; j < 13; j++) {

                let month = (j < 10) ? '0' + j : j.toString();
                this.TargetMonthList.push(month);
            }
        },
        mounted() {

            if (this.$parent.CanSearch) {

                this.fn_ShowData();
            }
        },
        watch: {
            // 조회조건 - 카드번호 변경
            'ExpenseRequestSelectTerm.CardNo'(newData) {
                this.fn_ShowData();
            },
            // 조회조건 - 프로젝트 변경
            'ExpenseRequestSelectTerm.ProjectCode'(newData) {
                this.fn_ShowData();
            }
        },
        methods: {
            // 프로젝트 검색
            searchProject() {

                var parent = this;
                var successCallback = function (result) {

                    parent.ExpenseRequestSelectTerm.ProjectName = result.ProjectNm;
                    parent.ExpenseRequestSelectTerm.ProjectCode = result.ProjectCd;
                    parent.ExpenseRequestSelectTerm.TraderCode = result.CompanyCd;
                }

                this.$refs.CP1060P.showProjectPopup(successCallback);
            },
            // 법인카드 검색
            searchCorporationCard() {

                var parent = this;
                var successCallback = function (result) {

                    parent.ExpenseRequestSelectTerm.DisplayCardNo = result.DisplayCardNo;
                    parent.ExpenseRequestSelectTerm.CardNo = result.CardNo;
                    parent.ExpenseRequestSelectTerm.CardCorpCd = result.CardCorpCd;
                    parent.ExpenseRequestSelectTerm.CardCorpName = result.CardCorpName;
                }

                this.$refs.CP1050P.showCorporationPopUp(successCallback);
            },
            // 로우 선택 이벤트
            rowSelectEvent(e) {

                let parent = this;
                let row = e.data;
                row.Arg = 'V';

                let successCallback = function () {

                    parent.fn_ShowData();
                }

                this.$refs.AC1031E.open(successCallback, row, null);
            },
            // 데이터 조회
            fn_ShowData() {

                var parent = this;
                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/GetExpenseRequest'.toRealServiceUrl();
                var obj = new Object();

                // 선택한 연월에 따라 사용일자 설정
                this.setUseDate();

                obj.jsonString = JSON.stringify(this.ExpenseRequestSelectTerm);

                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d);
                    var table = jsonData.Table;
                    var table1 = jsonData.Table1;
                    var table2 = jsonData.Table2;

                    // 목록
                    parent.ExpenseRequestList = (table.length > 0) ? table : null;

                    // 목록 전체 카운터
                    parent.ExpenseRequestListTotalCount = (table1.length > 0) ? table1[0].TotCnt : 0;

                    // 카드정보
                    parent.CompanyCardDetail = (table2.length > 0) ? table2[0] : null;
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 데이터 추가
            fn_InstData() {

                let parent = this;

                let successCallback = function () {

                    parent.fn_ShowData();
                }

                this.$refs.AC1031E.open(successCallback, null, this.CompanyCardDetail);
            },
            // 사용일자 설정
            setUseDate() {

                if (this.ExpenseRequestSelectTerm.TargetYear != null && this.ExpenseRequestSelectTerm.TargetYear != '') {

                    if (this.ExpenseRequestSelectTerm.TargetMonth != null && this.ExpenseRequestSelectTerm.TargetMonth != '') {

                        // 시작일을 선택 년월의 첫날로 설정
                        this.ExpenseRequestSelectTerm.StartDate = this.ExpenseRequestSelectTerm.TargetYear
                            + '-'
                            + this.ExpenseRequestSelectTerm.TargetMonth + '-01';

                        // 종료일을 선택 년월의 마지막날로 설정
                        this.ExpenseRequestSelectTerm.EndDate = this.ExpenseRequestSelectTerm.TargetYear
                            + '-'
                            + this.ExpenseRequestSelectTerm.TargetMonth
                            + '-'
                            + new Date(this.ExpenseRequestSelectTerm.TargetYear, this.ExpenseRequestSelectTerm.TargetMonth, 0).getDate();
                    } else {

                        // 월을 선택하지 않았을 시 시작일, 종료일을 선택년도의 첫달 첫날, 마지막달 마지막날로 설정
                        this.ExpenseRequestSelectTerm.StartDate = this.ExpenseRequestSelectTerm.TargetYear + '-' + '01-01';
                        this.ExpenseRequestSelectTerm.EndDate = this.ExpenseRequestSelectTerm.TargetYear + '-12-31';
                    }
                } else {

                    // 년월을 선택하지 않았을 시 전체 검색하도록 null값 초기화
                    this.ExpenseRequestSelectTerm.StartDate = null;
                    this.ExpenseRequestSelectTerm.EndDate = null;
                }
            }
        }
    }
</script>