<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>

        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a v-show="this.$parent.CanSearch" href="#" id="btnListSrch" class="btn btn-primary btn-row2" @click.prevent="getCompanyManager(false)">
                    <span>
                        <i class="glyphicon glyphicon-search"></i> 조회
                    </span>
                </a>
                <a v-show="this.$parent.CanNew" href="#" id="btnListInst" class="btn btn-default" @click.prevent="addCompanyManager">
                    <span>
                        <i class="glyphicon glyphicon-plus"></i> 추가
                    </span>
                </a>
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols">
                                        <label for="txtTraderNm" class="control-label"><span>업체명</span></label>
                                        <div class="cols-btns-cont pad-r150">
                                            <input type="text" id="txtTraderNm" class="form-control txt" readonly="readonly" v-model="CompanyManagerSelectTerm.TraderName" />
                                            <input type="hidden" id="hidTraderCd" v-model="CompanyManagerSelectTerm.TraderCode" />
                                        </div>
                                        <div class="cols-btns">
                                            <a href="#" id="btnListPop1" class="btn btn-default" @click.prevent="searchCompany(false)">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i>검색
                                                </span>
                                            </a>
                                            <a href="#" id="btnSrchInit" class="btn btn-default"
                                               @click.prevent="function(e){
                                                    CompanyManagerSelectTerm.TraderCode = null
                                                    CompanyManagerSelectTerm.TraderName = null
                                               }">
                                                <span>
                                                    <i class="glyphicon glyphicon-repeat"></i> 초기화
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="selMgrRoleCd" class="control-label">
                                            <span>담당구분</span>
                                        </label>
                                        <select class="form-control" id="selMgrRoleCd" name="selMgrRoleCd" v-model="CompanyManagerSelectTerm.ManagerRoleCode">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in ManagerRoleCodeList" v-bind:value="item.Code">{{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="sch3-1" class="control-label">
                                            <span>사용여부</span>
                                        </label>
                                        <select id="selUseYN" class="form-control" v-model="CompanyManagerSelectTerm.UseYn">
                                            <option value="">== 전체 ==</option>
                                            <option value="Y">Y</option>
                                            <option value="N">N</option>
                                        </select>
                                    </div>
                                </section>
                            </section>

                        </fieldset>
                    </form>
                    </div>

                </div>
                <div class="grid-wrap">
                    <div class="sub-title">
                        <p class="sub-title-txt">업체담당자 관리</p>
                    </div>
                    <div>
                        <!--리스트 영역-->
                        <div class="alert alert-info total">
                            <p>
                                <i class="glyphicon glyphicon-folder-close"></i>
                                <span>
                                    전체: <span id="spTotCnt">{{CompanyManagerListTotalCount}}</span>건
                                </span>
                            </p>
                        </div>
                        <div class="table-responsive">
                            <table class="table table tbl-list tbl-center table-hover table-bordered" id="tb0">
                                <caption>테이블</caption>
                                <colgroup>
                                    <col class="table-colwid20" />
                                    <col class="table-colwid5" />
                                    <col class="table-colwid5" />
                                    <col class="table-colwid15" />
                                    <col class="table-colwid10" />
                                    <col class="table-colwid10" />
                                    <col class="table-colwid15" />
                                    <col class="table-colwid15" />
                                    <col class="table-colwid5" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col">업체명</th>
                                        <th scope="col">성명</th>
                                        <th scope="col">직급</th>
                                        <th scope="col">담당</th>
                                        <th scope="col">전화번호</th>
                                        <th scope="col">팩스번호</th>
                                        <th scope="col">이메일</th>
                                        <th scope="col">세금계산서이메일</th>
                                        <th scope="col">사용여부</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="CompanyManagerList === null">
                                        <td colspan="9" class="text-center no-data">조회된 정보가 없습니다..</td>
                                    </tr>
                                    <tr v-else v-for="item in CompanyManagerList" style="cursor:pointer;" @click="rowSelectEvent(item)">
                                        <td style="text-align:left;">{{item.CompanyNm}}</td>
                                        <td>{{item.MgrNm}}</td>
                                        <td>{{item.JobGradeNm}}</td>
                                        <td style="text-align:left;">{{item.ArrMgrRoleName}}</td>
                                        <td>{{item.TelNo}}</td>
                                        <td>{{item.FaxNo}}</td>
                                        <td style="text-align:left;" another='E'>
                                            <a href="#">{{item.EMail}}</a>
                                        </td>
                                        <td style="text-align:left;" another='E'>
                                            <a href="#">{{item.BillEMail}}</a>
                                        </td>
                                        <td>{{item.UseYN}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="text-center">
                            <ul ref="ulpager" class="pagination"></ul>
                        </div>
                    </div>
                </div>
                <div class="modal fade" ref="CM1031E_Modal" tabindex="-1" role="dialog" aria-labelledby="CM1031E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">
                                    <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                                </button>
                                <h3 class="modal-title">업체 담당자 등록/수정</h3>
                            </div>
                            <div class="modal-body">
                                <section class="add-wrap">
                                    <form>
                                        <fieldset>
                                            <legend></legend>
                                            <section class="cols-wrap">
                                                <div class="cols col-2">
                                                    <label for="txtProjectNm2" class="control-label">
                                                        <span>
                                                            <i class="glyphicon glyphicon-asterisk"></i>업체명
                                                        </span>
                                                    </label>
                                                    <div class="cols-btns-cont">
                                                        <input v-model="CompanyManagerDetail.CompanyNm" type="text" ref="txtTraderNm2" class="form-control txt" readonly="readonly" />
                                                        <input v-model="CompanyManagerDetail.CompanyCd" type="hidden" id="hidTraderCd2" class="form-control txt" />
                                                    </div>
                                                    <div class="cols-btns">
                                                        <a v-show="PageActionType === 'I'" href="#" id="btnItemPop2" class="btn btn-default" @click.prevent="searchCompany(true)">
                                                            <span>
                                                                <i class="glyphicon glyphicon-search"></i> 검색
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="cols col-2">
                                                    <label for="txtTraderNm" class="control-label">
                                                        <span>
                                                            <i class="glyphicon glyphicon-asterisk"></i>담당자 명
                                                        </span>
                                                    </label>
                                                    <input v-model="CompanyManagerDetail.MgrNm" type="text" ref="txtMgrNm2" class="form-control" />
                                                </div>
                                            </section>
                                            <section class="cols-wrap">
                                                <div class="cols col-2">
                                                    <label for="txtJobGradeNm2" class="control-label">
                                                        <span>직급</span>
                                                    </label>
                                                    <input v-model="CompanyManagerDetail.JobGradeNm" type="text" id="txtJobGradeNm2" class="form-control" />
                                                </div>
                                                <div class="cols col-2">
                                                    <label for=" txttelno2" class="control-label">
                                                        <span>
                                                            <i class="glyphicon glyphicon-asterisk"></i>전화번호
                                                        </span>
                                                    </label>
                                                    <input type="text" ref="txtTelNo2" class="form-control txt" maxlength="13" placeholder="'-'은 제외하고 입력하세요."
                                                           v-bind:value="CompanyManagerDetail.TelNo"
                                                           @input="function(e){
                                                            CompanyManagerDetail.TelNo = e.target.value.toTelNumberFormat()
                                                            e.target.value = e.target.value.toTelNumberFormat()
                                                       }" />
                                                </div>
                                            </section>
                                            <section class="cols-wrap">
                                                <div class="cols">
                                                    <label for="txtBoardTitle2" class="control-label">
                                                        <span>담당구분</span>
                                                    </label>
                                                    <div class="checkbox-wrap" id="divMgrRoleCd2">
                                                        <label v-for="(item, index) in ManagerRoleCodeList" class="radio-inline">
                                                            <input type="checkbox" name="cbMgrRoleCd" :value="item.Code" /> {{item.Name}}
                                                        </label>
                                                    </div>
                                                </div>
                                            </section>
                                            <section class="cols-wrap">
                                                <div class="cols col-2">
                                                    <label for="txtFaxNo2" class="control-label">
                                                        <span>팩스번호</span>
                                                    </label>
                                                    <input type="text" ref="txtFaxNo2" class="form-control" maxlength="13" placeholder="'-'은 제외하고 입력하세요."
                                                           v-bind:value="CompanyManagerDetail.FaxNo"
                                                           @input="function(e){
                                                            CompanyManagerDetail.FaxNo = e.target.value.toTelNumberFormat()
                                                            e.target.value = e.target.value.toTelNumberFormat()
                                                       }" />
                                                </div>
                                                <div class="cols col-2">
                                                    <label for="txtMobileNo2" class="control-label">
                                                        <span>핸드폰번호</span>
                                                    </label>
                                                    <input type="text" ref="txtMobileNo2" class="form-control" maxlength="13" placeholder="'-'은 제외하고 입력하세요."
                                                           v-bind:value="CompanyManagerDetail.MobileNo"
                                                           @input="function(e){
                                                            CompanyManagerDetail.MobileNo = e.target.value.toTelNumberFormat()
                                                            e.target.value = e.target.value.toTelNumberFormat()
                                                       }" />
                                                </div>
                                            </section>
                                            <section class="cols-wrap">
                                                <div class="cols col-2">
                                                    <label for="txtEMail2" class="control-label">
                                                        <span>이메일</span>
                                                    </label>
                                                    <input type="text" ref="txtEMail2" class="form-control txt"
                                                           v-model="CompanyManagerDetail.EMail" />
                                                </div>
                                                <div class="cols col-2">
                                                    <label for="txtBillEMail2" class="control-label">
                                                        <span>세금계산서이메일</span>
                                                    </label>
                                                    <input type="text" ref="txtBillEMail2" class="form-control txt"
                                                           v-model="CompanyManagerDetail.BillEMail" />
                                                </div>
                                            </section>
                                            <section class="cols-wrap">
                                                <div class="cols col-2">
                                                    <label for="selUseYN2" class="control-label">
                                                        <span>사용여부</span>
                                                    </label>
                                                    <select id="selUseYN2" class="form-control">
                                                        <option value="Y">Y</option>
                                                        <option value="N">N</option>
                                                    </select>
                                                </div>
                                            </section>
                                            <div class="btn-align">
                                                <div v-show="this.$parent.CanSave" class="btn-group">
                                                    <button type="button" id="btnItemSave" class="btn btn-primary" @click="saveCompanyManager">
                                                        <i class="glyphicon glyphicon-save"></i> 저장
                                                    </button>
                                                </div>
                                                <div v-show="this.$parent.CanDelete && PageActionType != 'I'" class="btn-group" @click="removeCompanyManager">
                                                    <button type="button" id="btnItemRmve" class="btn btn-warning">
                                                        <i class="glyphicon glyphicon-edit"></i> 삭제
                                                    </button>
                                                </div>
                                                <div class="btn-group">
                                                    <button type="button" class="btn btn-danger" data-dismiss="modal">
                                                        <i class="glyphicon glyphicon-remove"></i> 취소
                                                    </button>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </form>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <CP1030P ref="CP1030P"></CP1030P>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle'
    import CP1030P from '@/components/libs/popup/CP1030P'
    export default {
        name: 'CM1030R',
        components: {
            ViewTitle, CP1030P
        },
        data() {
            return {
                ManagerRoleCodeList: null,  // 담당구분 공통코드 목록
                CompanyManagerList: null,   // 업체담당자 목록
                // 업체담당자 상세
                CompanyManagerDetail: {
                    CorpCd: null,
                    CompanyCd: null,
                    CompanyNm: null,
                    SeqNo: null,
                    MgrNm: null,
                    JobGradeNm: null,
                    ArrMgrRoleCd: null,
                    ArrMgrRoleName: null,
                    TelNo: null,
                    MobileNo: null,
                    FaxNo: null,
                    EMail: null,
                    BillEMail: null,
                    UseYN: null
                },
                // 업체담당자 목록 조회조건
                CompanyManagerSelectTerm: {
                    Arg: 'L',
                    UserId: this.$parent.EmpId,
                    CompanyCode: this.$parent.CompanyCode,
                    TraderCode: null,
                    TraderName: null,
                    ManagerRoleCode: '',
                    UseYn: '',
                    CurrentPage: 1,
                    TopSize: 10
                },
                PageActionType: 'V',                // 페이지 액션 값 (I: 신규, V: 상세)
                CompanyManagerListTotalCount: 0     // 업체담당자 목록 전체 건수
            }
        },
        beforeMount() {

            // 공통코드 조회
            this.ManagerRoleCodeList = $.scriptBase.getCommonCode('CODE000', '0109000', '', '')
        },
        mounted() {

            if (this.$parent.CanSearch) {

                this.getCompanyManager(false)
            }
        },
        methods: {
            // 업체담당자 행 선택 이벤트
            rowSelectEvent: function (row) {

                // 페이지 액션 값 설정
                this.PageActionType = 'V'

                // 선택한 행의 데이터 복사
                Object.assign(this.CompanyManagerDetail, row)

                var checkBoxList = document.querySelectorAll('input[name=cbMgrRoleCd]')
                
                // 담당구분 체크박스 해당하는 값 체크 설정
                if (this.CompanyManagerDetail.ArrMgrRoleCd != null && this.CompanyManagerDetail.ArrMgrRoleCd != '') {

                    var arrMgrRoleCdList = this.CompanyManagerDetail.ArrMgrRoleCd.split(',')

                    for (var i = 0; i < checkBoxList.length; i++) {

                        var checkBox = checkBoxList[i]

                        for (var j = 0; j < arrMgrRoleCdList.length; j++) {

                            var arrMgrRoleCd = arrMgrRoleCdList[j]

                            if (checkBox.value === arrMgrRoleCd) {

                                checkBox.checked = true

                                break;
                            }
                            else {

                                checkBox.checked = false
                            }
                        }
                    }
                }
                else {

                    // 담당구분 체크박스 초기화
                    checkBoxList.forEach(function (checkBox) {

                        checkBox.checked = false
                    })
                }
                
                this.CompanyManagerDetail.Arg = 'PI'
                this.CompanyManagerDetail.UserId = this.$parent.EmpId

                // 상세 팝업 오픈
                $(this.$refs.CM1031E_Modal).modal('show');
            },
            // 페이지 변경 이벤트
            pageIndexChangeEvent: function (pageIndex) {

                this.CompanyManagerSelectTerm.CurrentPage = pageIndex
                this.getCompanyManager(false)
            },
            // 업체명 검색
            searchCompany: function (isDetail) {

                var parent = this
                var successCallback = function (result) {

                    if (!isDetail) {

                        parent.CompanyManagerSelectTerm.TraderName = result.CompanyNm
                        parent.CompanyManagerSelectTerm.TraderCode = result.CompanyCd
                    }
                    else {

                        parent.CompanyManagerDetail.CompanyNm = result.CompanyNm
                        parent.CompanyManagerDetail.CompanyCd = result.CompanyCd
                    }
                }

                this.$refs.CP1030P.open(successCallback);
            },
            // 업체담당자 목록 조회
            getCompanyManager: function (isOpenDetailPopup) {

                var parent = this
                var url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/GetCompanyManager'.toRealServiceUrl()
                var obj = new Object()

                obj.jsonString = JSON.stringify(this.CompanyManagerSelectTerm)

                var successCallback = function (data) {
                    
                    var table = JSON.parse(data.d).Table
                    var table1 = JSON.parse(data.d).Table1

                    if (table.length > 0) {

                        parent.CompanyManagerList = table
                    }
                    else {

                        parent.CompanyManagerList = null
                    }

                    if (table1.length > 0) {

                        parent.CompanyManagerListTotalCount = table1[0].TotCnt

                        $(parent.$refs.ulpager).pager({
                            pager: parent.$refs.ulpager,
                            totalItemCount: parent.CompanyManagerListTotalCount,
                            recordPerPage: parent.CompanyManagerSelectTerm.TopSize,
                            currentPage: parent.CompanyManagerSelectTerm.CurrentPage,
                            pageIndexChanged: parent.pageIndexChangeEvent
                        })
                    }
                    else {

                        parent.CompanyManagerListTotalCount = 0
                    }

                    // 상세팝업 오픈 플래그 값에 따라 상세 팝업 오픈
                    if (isOpenDetailPopup) {

                        for (var i = 0; i < table.length; i++) {

                            if (table[i].CorpCd === parent.CompanyManagerDetail.CorpCd
                                && table[i].CompanyCd === parent.CompanyManagerDetail.CompanyCd
                                && table[i].SeqNo === parent.CompanyManagerDetail.SeqNo) {

                                parent.rowSelectEvent(table[i])

                                break
                            }
                        }
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 업체담당자 등록화면 오픈
            addCompanyManager: function () {

                // 페이지 액션 값 설정
                this.PageActionType = 'I'

                // 상세 객체 초기화
                this.CompanyManagerDetail = {
                    Arg: 'PI',
                    UserId: this.$parent.EmpId,
                    CorpCd: this.$parent.CompanyCode,
                    CompanyCd: null,
                    CompanyNm: null,
                    SeqNo: null,
                    MgrNm: null,
                    JobGradeNm: null,
                    ArrMgrRoleCd: null,
                    ArrMgrRoleName: null,
                    TelNo: null,
                    MobileNo: null,
                    FaxNo: null,
                    EMail: null,
                    BillEMail: null,
                    UseYN: 'Y'
                }

                var checkBoxList = document.querySelectorAll('input[name=cbMgrRoleCd]')

                // 담당구분 체크박스 초기화
                checkBoxList.forEach(function (checkBox) {

                    checkBox.checked = false
                })

                $(this.$refs.CM1031E_Modal).modal('show')
            },
            // 업체담당자 저장
            saveCompanyManager: function () {

                if (this.CompanyManagerDetail.CompanyNm === null || this.CompanyManagerDetail.CompanyNm === '') {

                    alert('업체명을 선택하세요.')
                    this.$refs.txtTraderNm2.focus()
                    return
                }

                if (this.CompanyManagerDetail.MgrNm === null || this.CompanyManagerDetail.MgrNm === '') {

                    alert('담당자명을 입력하세요.')
                    this.$refs.txtMgrNm2.focus()
                    return
                }

                if (this.CompanyManagerDetail.TelNo === null || this.CompanyManagerDetail.TelNo === '') {

                    alert('전화번호를 입력하세요.')
                    this.$refs.txtTelNo2.focus()
                    return
                }

                if (this.CompanyManagerDetail.EMail != null && this.CompanyManagerDetail.EMail != '') {

                    if (!this.CompanyManagerDetail.EMail.isValidEmailAddress()) {

                        alert('이메일 형식이 잘못 되었습니다.')
                        this.$refs.txtEMail2.focus()
                        return
                    }
                }

                if (this.CompanyManagerDetail.BillEMail != null && this.CompanyManagerDetail.BillEMail != '') {

                    if (!this.CompanyManagerDetail.BillEMail.isValidEmailAddress()) {

                        alert('세금계산서 이메일 형식이 잘못 되었습니다.')
                        this.$refs.txtBillEMail2.focus()
                        return
                    }
                }

                if (this.CompanyManagerDetail.TelNo != null && this.CompanyManagerDetail.TelNo != '') {

                    if (!this.CompanyManagerDetail.TelNo.isValidTelNumber()) {

                        alert('전화번호 형식이 잘못 되었습니다.')
                        this.$refs.txtTelNo2.focus()
                        return
                    }
                }

                if (this.CompanyManagerDetail.FaxNo != null && this.CompanyManagerDetail.FaxNo != '') {

                    if (!this.CompanyManagerDetail.FaxNo.isValidTelNumber()) {

                        alert('팩스번호 형식이 잘못 되었습니다.')
                        this.$refs.txtFaxNo2.focus()
                        return
                    }
                }

                if (this.CompanyManagerDetail.MobileNo != null && this.CompanyManagerDetail.MobileNo != '') {

                    if (!this.CompanyManagerDetail.MobileNo.isValidTelNumber()) {

                        alert('핸드폰 번호 형식이 잘못 되었습니다.')
                        this.$refs.txtMobileNo2.focus()
                        return
                    }
                }

                var parent = this
                var url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/SaveCompanyManager'.toRealServiceUrl()
                var obj = new Object()
                var companyManagerRoleList = new Array()

                obj.jsonString = JSON.stringify(this.CompanyManagerDetail)

                var checkBoxList = document.querySelectorAll('input[name=cbMgrRoleCd]')

                checkBoxList.forEach(function (element) {

                    if (element.checked) {

                        companyManagerRoleList.push({
                            Arg: 'CI',
                            UserId: parent.$parent.EmpId,
                            CorpCd: parent.PageActionType === 'I' ? parent.$parent.CompanyCode : parent.CompanyManagerDetail.CorpCd,
                            CompanyCd: parent.CompanyManagerDetail.CompanyCd,
                            SeqNo: parent.CompanyManagerDetail.SeqNo,
                            MgrRoleCd: element.value
                        })
                    }
                })

                obj.jsonCompanyManagerRoleString = JSON.stringify(companyManagerRoleList.length === 0 ? null : companyManagerRoleList)

                var successCallback = function (data) {

                    if (data.d > 0) {

                        if (parent.PageActionType === 'I') {

                            alert('등록 되었습니다.')

                            // 상세 팝업 닫기
                            $(parent.$refs.CM1031E_Modal).modal('hide')

                            parent.getCompanyManager(false)
                        }
                        else {

                            alert('저장 되었습니다.')
                            parent.getCompanyManager(true)
                        }
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            // 업체담당자 삭제
            removeCompanyManager: function () {

                if (confirm('삭제 하시겠습니까?')) {

                    var parent = this
                    var url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/RemoveCompanyManager'.toRealServiceUrl()
                    var obj = new Object()

                    // 플래그값 설정
                    this.CompanyManagerDetail.Arg = 'PD'

                    obj.jsonString = JSON.stringify(this.CompanyManagerDetail)

                    var successCallback = function (data) {

                        if (data.d > 0) {

                            alert('삭제 되었습니다.')

                            // 상세 팝업 닫기
                            $(parent.$refs.CM1031E_Modal).modal('hide')

                            parent.getCompanyManager(false)
                        }
                    }

                    $.scriptBase.executeAjaxAsync(url, obj, successCallback)
                }
            }
        }
    }
</script>

<style scoped>
</style>