<template>
    <div class="modal fade" ref="CP1040P_Modal" tabindex="-1" role="dialog" aria-labelledby="defaultModal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" @click.Prevent="close">
                        <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                    </button>
                    <h3 class="modal-title">거래처담당자 검색</h3>
                </div>
                <div class="modal-body">
                    <!-- 검색영역 -->
                    <section class="sch-wrap">
                        <form role="form">
                            <fieldset>
                                <legend>검색폼</legend>
                                <section class="sch-cont">
                                    <section class="cols-wrap">
                                        <div class="cols col-1">
                                            <label for="CP1040P_selTrader" class="control-label"><span>거래처</span></label>
                                            <div class="cols-btns-cont pad-r150">
                                                <input id="CP1040P_selTrader" type="text" class="form-control txt" readonly="readonly" v-model="CompanyDetail.CompanyNm" />
                                                <input type="hidden" v-model="CompanyDetail.CompanyCd" />
                                            </div>
                                            <div class="cols-btns">
                                                <a href="#" id="CP1040P_btnItemPop1" class="btn btn-default" @click.prevent="searchCompanyCode">
                                                    <span><i class="glyphicon glyphicon-search"></i> 검색</span>
                                                </a>
                                                <a href="#" id="CP1040P_btnSrchInit" class="btn btn-default" @click.prevent="clearCompanyCode">
                                                    <span><i class="glyphicon glyphicon-repeat"></i> 초기화</span>
                                                </a>
                                            </div>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="CP1040P_selSection" class="control-label"><span>담당구분</span></label>
                                            <select class="form-control" ref="CP1040P_selSection" name="CP1040P_selSection" v-model="ManagerRoleCd" @change="btnSearchClicked">
                                                <option value="">= 전체 =</option>
                                                <option v-for="item in ManagerRoleCdList" v-bind:value="item.Code">{{item.Name}}</option>
                                            </select>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="CP1040P_MgrSearch" class="control-label"><span>담당자명</span></label>
                                            <input ref="CP1040P_MgrSearch" type="text" name="CP1040P_MgrSearch" class="form-control" v-model="ManagerNm" @keypress.enter.prevent="keypressEvent" />
                                        </div>
                                    </section>
                                </section>
                                <section class="sch-btn">
                                    <a id="CP1040P_btnSearch" class="btn btn-primary" @click.prevent="btnSearchClicked"><span><i class="glyphicon glyphicon-search"></i> 검색</span></a>
                                </section>
                            </fieldset>
                        </form>
                    </section>
                    <!-- 테이블 -->
                    <section class="tbl-contents">
                        <div class="total alert" style="margin-bottom: 0;">
                            <p><i class="glyphicon glyphicon-folder-close"></i><span>전체: <span id="CP1040P_total">{{TotalCount}}</span>건</span></p>
                        </div>
                        <div class="table-responsive">
                            <table class="table tbl-list tbl-center table-hover table-bordered">
                                <caption>테이블</caption>
                                <colgroup>
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col">거래처</th>
                                        <th scope="col">이름</th>
                                        <th scope="col">직급</th>
                                        <th scope="col">담당구분</th>
                                        <th scope="col">전화번호</th>
                                        <th scope="col">휴대폰번호</th>
                                        <th scope="col">사용여부</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="AccountManagerList === null">
                                        <td colspan="7">조회된 정보가 없습니다.</td>
                                    </tr>
                                    <tr v-else v-for="item in AccountManagerList" style="cursor:pointer;" @click="rowClickEvent(item)">
                                        <td>{{item.TraderNm}}</td>
                                        <td>{{item.MgrNm}}</td>
                                        <td>{{item.JobGradeNm}}</td>
                                        <td>{{item.ArrMgrRoleName}}</td>
                                        <td>{{item.TelNo}}</td>
                                        <td>{{item.MobileNo}}</td>
                                        <td>{{item.UseYN}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                    <!-- 페이징 -->
                    <div class="text-center">
                        <ul ref="CP1040P_pager" class="pagination"></ul>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default btn-sm" @click.Prevent="close"><span class="glyphicon glyphicon-remove"></span>취소(닫기)</button>
                </div>
            </div>
        </div>
        <CP1030P ref="CP1030P"></CP1030P>
    </div>
</template>

<script>
    import CP1030P from '@/components/libs/popup/CP1030P';
    export default {
        name: 'CP1040P',
        components: {
            CP1030P
        },
        data() {
            return {
                // 담당구분 리스트
                ManagerRoleCdList: null,
                // 현재 페이지
                CurrentPage: 1,
                // 화면에 조회되는 개수
                TopSize: 10,
                // 데이터 총 개수
                TotalCount: 0,
                // 조회된 인력 리스트
                AccountManagerList: null,
                // 회사 코드
                CompanyCode: this.$store.getters.getCompanyCode,
                // 업체 선택 결과를 넘겨줄 콜백함수
                CallbackFunction: null,
                // 업체 리스트
                CompanyDetail: {
                    // 업체 이름
                    CompanyNm: '',
                    // 업체 코드
                    CompanyCd: ''
                },
                // 담당구분
                ManagerRoleCd: '',
                // 담당자명
                ManagerNm: '',
            }
        },
        beforeMount() {
            // 담당구분 공통코드
            this.ManagerRoleCdList = $.scriptBase.getCommonCode('CODE000', '0109000', '', '');
        },
        watch: {
            // 조회조건 - 거래처 코드 변경
            'CompanyDetail.CompanyCd'(newData) {
                this.btnSearchClicked();
            },
        },
        methods: {
            // 거래처담당자 검색 팝업 오픈
            open(callbackFunction) {

                this.CallbackFunction = callbackFunction;
                this.CurrentPage = 1;
                this.TotalCount = 0;
                this.AccountManagerList = null;

                // 거래처 초기화
                this.CompanyDetail.CompanyNm = '';
                this.CompanyDetail.CompanyCd = '';
                // 담당구분 초기화
                this.ManagerRoleCd = '';
                // 담당자명 초기화
                this.ManagerNm = '';

                // 페이징 세팅
                $(this.$refs.CP1040P_pager).pager({
                    pager: this.$refs.CP1040P_pager,
                    pagerMode: "text",
                    totalItemCount: this.TotalCount,
                    recordPerPage: this.TopSize,
                    currentPage: this.CurrentPage,
                    pageIndexChanged: this.pageChangeEvent,
                    prev10Text: "&laquo;",
                    next10Text: "&raquo;",
                    selectedPageFontColor: "orange"
                });

                this.ShowData();
                $(this.$refs.CP1040P_Modal).modal('show');
            },
            close() {

                $(this.$refs.CP1040P_Modal).modal("hide");
            },
            // 업체 검색 메서드
            searchCompanyCode() {

                var parent = this;
                var successCallback = function (result) {

                    // 결과값을 추출 후 돔객체에 바인딩
                    parent.CompanyDetail.CompanyNm = result.CompanyNm;
                    parent.CompanyDetail.CompanyCd = result.CompanyCd;
                }

                this.$refs.CP1030P.open(successCallback);
            },
            // 거래처 초기화 메서드
            clearCompanyCode() {

                this.CurrentPage = 1;

                // 거래처 초기화
                this.CompanyDetail.CompanyNm = '';
                this.CompanyDetail.CompanyCd = '';

                // 담당구분
                this.ManagerRoleCd = '';
                // 담당자명
                this.ManagerNm = '';

                this.ShowData();
            },
            // 거래처담당자 검색 메서드
            ShowData() {

                var parent = this;

                var url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/GetTraderMgrList'.toRealServiceUrl();
                var obj = new Object();
                obj.prm = new Array();
                // 회사코드
                obj.prm.push(this.CompanyCode);
                //거래처
                obj.prm.push(this.CompanyDetail.CompanyCd);
                //담당구분
                obj.prm.push(this.ManagerRoleCd);
                //담당자명
                obj.prm.push(this.ManagerNm);
                obj.prm.push(this.CurrentPage);
                obj.prm.push(this.TopSize);

                // ajax 성공 시 실행되는 핸들러 정의
                var ajaxSuccessHandler = function (data) {

                    var table = JSON.parse(data.d).Table;
                    var table1 = JSON.parse(data.d).Table1;

                    parent.AccountManagerList = (table.length > 0) ? table : null;
                    parent.TotalCount = (table1.length > 0) ? table1[0].TotCnt : 0;

                    // 페이징 세팅
                    $(parent.$refs.CP1040P_pager).pager({
                        pager: parent.$refs.CP1040P_pager,
                        pagerMode: "text",
                        totalItemCount: parent.TotalCount,
                        recordPerPage: parent.TopSize,
                        currentPage: parent.CurrentPage,
                        pageIndexChanged: parent.pageChangeEvent,
                        prev10Text: "&laquo;",
                        next10Text: "&raquo;",
                        selectedPageFontColor: "orange"
                    });
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler);
            },
            // 페이지 변경 이벤트 핸들러
            pageChangeEvent(page) {

                this.CurrentPage = page;
                this.ShowData();
            },
            // 검색 버튼 클릭 이벤트 핸들러
            btnSearchClicked() {

                this.CurrentPage = 1;
                this.ShowData();
            },
            // 테이블Row 클릭 이벤트 핸들러
            rowClickEvent(item) {

                // 호출한 뷰로 선택한 거래처담당자 데이터 전달
                this.CallbackFunction(item);
                this.close();
            },
            // 검색어 필드에서 엔터키 입력 시 조회되도록 하는 키업 이벤트
            keypressEvent(event) {

                if (event.key == 'Enter') {

                    this.ShowData();
                }
            },
        }
    }
</script>
