<template>
    <div class="dashboard-wrap">
        <div class="content-box">
            <article class="board-view">
                <form>
                    <fieldset>
                        <legend></legend>
                        <section class="board-view-head">
                            <p id="Title"></p>
                        </section>
                        <section class="board-view-wrap">
                            <div class="board-view-info">
                                <ul>
                                    <li class="board-name-wrap">
                                        <i class="fa fa-user"></i>
                                        <span>작성자</span>
                                        <span class="board-name-cont" id="spwriteName"></span>
                                    </li>
                                    <li class="board-hit-wrap">
                                        <i class="fa fa-eye"></i>
                                        <span>조회수</span>
                                        <span class="board-hit-cont" id="spwriteClickCnt"></span>
                                    </li>
                                    <li class="board-date-wrap">
                                        <i class="fa fa-clock-o"></i>
                                        <span>작성일</span>
                                        <span class="board-date-cont" id="spwriteDate"></span>
                                    </li>
                                </ul>
                            </div>
                            <div class="board-view-cont">
                                <p><pre id="Contents"></pre></p>
                            </div>
                            <section class="tbl-contents">
                                <div class="table-responsive">
                                    <table class="table tbl-list table-hover table-bordered" id="tb1">
                                        <colgroup>
                                            <col class="chk" />
                                            <col />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th scope="col" class="text-center"></th>
                                                <th scope="col" class="text-center">첨부파일</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="NoticeDetailKist === null">
                                                <td colspan="2" class="text-center no-data">조회된 정보가 없습니다..</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </section>

                            <div class="board-view-btn">
                                <a href="#" id="btnItemEdit"><i class="fa fa-pencil-square-o"></i>수정</a><a href="#" id="btnItemRmve"><i class="fa fa-trash-o"></i>삭제</a>
                            </div>
                        </section>

                        <section class="board-reply-wrap">
                            <div class="reply-view-area" id="divtest">

                            </div>
                            <div class="board-page">

                            </div>


                            <div class="board-view-list">
                                <table class="table-fixed" id="nextPrevtb">
                                    <colgroup>
                                        <col class="view-list-next" />
                                        <col class="view-list-sub" />
                                        <col class="view-list-name" />
                                        <col class="view-list-date" />
                                    </colgroup>
                                    <tr>
                                        <td><i class="fa fa-caret-up"></i><span> 이전글</span></td>
                                        <td><a href="#" id="prevContents" @click.prevent="prevContentClickEvent"></a></td>
                                        <td id="prevEmpName"></td>
                                        <td id="prevDate"></td>
                                    </tr>
                                    <tr>
                                        <td><i class="fa fa-caret-down"></i><span> 다음글</span></td> <!--nextcontests 속성 : class="re-view-list"-->
                                        <td><a href="#" id="nextContents"></a></td>
                                        <td id="nextEmpName"></td>
                                        <td id="nextDate"></td>
                                    </tr>
                                </table>
                            </div>
                        </section>
                    </fieldset>
                </form>
            </article>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'BM1011E',
        data() {
            return {
                viewjson: null, // 선택된 데이터 테이블
                prevjson: null, // 이전글 테이블
                prevjson: null, // 다음글 테이블
                filejson: null, // 선택된 데이터 테이블의 파일데이터(Table1)
                pageaction: "V",
                $DBdata: null,
                pageNUmArry: null
            }
        },
        beforeMount() {

            //url 가공
            var thisURL = document.location.href;
            var toSplit = thisURL.split(',');

            var arr = new Array();
            for (var i in toSplit) {
                if (i == 0) continue;
                arr.push(toSplit[i]);
            }

            this.fn_ShowItem(arr);
        },
        mounted() {
            
        },
        methods: {
            // 공지사항 상세 조회 메서드
            fn_ShowItem: function (arr) {

                this.filejson = null;
                this.pageaction = "V";


                var url = '/Mini.Service.IBS.BM.Service/FreeBoard.svc/ajax/GetNoticeBoard_small'
                url = url.toRealServiceUrl()

                var obj = new Object();
                obj.prm = new Array();
                obj.prm.push(this.$parent.EmpId);
                obj.prm.push(arr[0]);
                obj.prm.push(arr[1]);

                this.pageNUmArry = arr
                var parent = this

                var successCallback = function myfunction(data) {

                    parent.$DBdata = JSON.parse(data.d);

                    var Seqindex = parent.pageNUmArry[1] * 1;
                    
                    // 선택된 게시글 추출
                    parent.viewjson = $.grep(parent.$DBdata.Table, function (item, index) {
                        return (item.CorpCd == parent.pageNUmArry[0] && item.BoardSeqNo == parent.pageNUmArry[1]);
                    });

                    // 이전 게시글 추출
                    parent.viewjsonPrev = $.grep(parent.$DBdata.Table, function (item, index) {
                        return (item.CorpCd == arr[0] && item.BoardSeqNo == Seqindex + 1);
                    });

                    // 다음 게시글 추출
                    parent.viewjsonNext = $.grep(parent.$DBdata.Table, function (item, index) {
                        return (item.CorpCd == arr[0] && item.BoardSeqNo == Seqindex - 1);
                    });

                    // 파일 추출
                    parent.filejson = $.grep(parent.$DBdata.Table1, function (item, index) {
                        return (item.BoardSeqNo == Seqindex);
                    });

                    // 선택된 게시글 바인딩
                    if (typeof parent.viewjson != "undefined" && parent.viewjson.length > 0) {
                                                                                                                                                                                                            
                        $("#txtBoardTitle2").val(parent.viewjson[0].BoardTitle);
                        $("#txtBoardContents2").val(parent.viewjson[0].BoardContents);

                        $("#editCmtText").parent().parent().show();
                        $("#btnItemEdit").parent().hide();
                        $("#btnItemRmve").parent().hide();


                        if (parent.viewjson[0].IsOwner == "Y") {
                            
                            $("#btnItemEdit").parent().show();
                            $("#btnItemRmve").parent().show();
                        }

                        //선택된 데이터 매칭
                        $("#spwriteName").html(parent.viewjson[0].RegtEmpName);
                        $("#spwriteDate").html(parent.viewjson[0].RegtDate);
                        $("#spwriteClickCnt").html(parent.viewjson[0].ReadCnt);
                        $("#Contents").html(parent.viewjson[0].BoardContents);
                        $("#Title").html(parent.viewjson[0].BoardTitle);
                        $("#repleCnt").html(parent.viewjson[0].CmtCnt);

                    }
                    // 이전 게시글 바인딩
                    if (typeof parent.viewjsonPrev != "undefined" && parent.viewjsonPrev.length > 0) {

                        $("#prevContents").html(parent.viewjsonPrev[0].BoardTitle)
                        $("#prevEmpName").html(parent.viewjsonPrev[0].RegtEmpName)
                        $("#prevDate").html(parent.viewjsonPrev[0].RegtDate)
                    }
                    // 이전글이 존재하지 않을 경우
                    else {
                        $("#prevContents").html("이전글이 존재하지 않습니다.")
                        $("#prevEmpName").html("")
                        $("#prevDate").html("")
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            prevContentClickEvent: function () {

                // 페이지 교체에 필요한 지역 매개변수
                var corpCd = this.viewjsonPrev[0].CorpCd
                var boardSeqNo = this.viewjsonPrev[0].BoardSeqNo
                var orderseq = this.viewjsonPrev[0].OrderSeq

                //$(location).attr("href", "@Url.Content("BM1011E")?qid=13&wid=1302&eid=BM1010R&key=" + "," + viewjsonPrev[0].CorpCd + "," + viewjsonPrev[0].BoardSeqNo + "," + viewjsonPrev[0].OrderSeq);
                this.$router.push({ path: 'BM1011E?qid=13&wid=1302&eid=BM1010R&key=' + ',' + corpCd + ',' + boardSeqNo }) // + "," + orderseq 
            }
        }
    }
</script>

<style scoped>
</style>