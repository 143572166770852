﻿export default {
    getUserId: state => state.userInfo.userId,
    getUserName: state => state.userInfo.userName,
    getPositionName: state => state.userInfo.positionName,
    getEmailAddress: state => state.userInfo.emailAddress,
    getCompanyCode: state => state.userInfo.companyCode,
    getEmpId: state => state.userInfo.empId,
    getAuthGrade: state => state.userInfo.authGrade,
    getCanSearch: state => state.authInfo.canSearch,
    getCanNew: state => state.authInfo.canNew,
    getCanSave: state => state.authInfo.canSave,
    getCanDelete: state => state.authInfo.canDelete,
    getCanPrint: state => state.authInfo.canPrint,
    getCanApprove: state => state.authInfo.canApprove,
    getNavigator: state => state.appInfo.navigator,
    getTitle: state => state.appInfo.title,
    getPageHeader: state => state.appInfo.pageHeader,
    getIsFavorite: state => state.appInfo.isFavorite,
    getUserGrpId: state => state.userInfo.userGrpId,
    getUserIcon: state => state.userInfo.iconPhoto,
    //getProjectCd: state => state.projectName.ProjectCd,
    //getProjectNm: state => state.projectName.ProjectNm,
    //getProjectNo: state => state.projectName.ProjectNo,
    //getProjectSeq: state => state.projectName.ProjectSeq
}