<template>
    <div>
        <div class="dashboard-wrap">
            <!-- 타이틀 영역 -->
            <ViewTitle></ViewTitle>
            <div class="content-box">
                <div class="btn-wrap">
                    <a v-if="this.$parent.CanSearch" href="#" class="btn btn-primary btn-row2" @click.prevent="showWarraynty">
                        <span>
                            <i class="glyphicon glyphicon-search"></i>조회
                        </span>
                    </a>
                    <a v-if="this.$parent.CanNew" href="#" class="btn btn-default" @click.prevent="btnAddClicked">
                        <span>
                            <i class="glyphicon glyphicon-plus"></i>추가
                        </span>
                    </a>
                </div>
                <div class="sch-box">
                    <div class="sch-form">
                        <form class="form-horizontal" role="form">
                            <fieldset>
                                <legend>검색폼</legend>
                                <section class="sch-cont">
                                    <section class="cols-wrap">
                                        <div class="cols col-3">
                                            <label for="selTrgtYY" class="control-label">
                                                <span>년도</span>
                                            </label>
                                            <select class="form-control" ref="selTrgtYY" name="selTrgtYY" v-model="InsuranceSelectTerm.Year" @change="showWarraynty">
                                                <option v-for="Year in TargetYearList" v-bind:value="Year">
                                                    {{Year}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="cols col-3">
                                            <label for="selWrntDvsCd" class="control-label"><span>보증종류</span></label>
                                            <select class="form-control" id="selWrntDvsCd" name="selWrntDvsCd" v-model="InsuranceSelectTerm.WrntDvsCode" @change="showWarraynty">
                                                <option value="">== 전체 ==</option>
                                                <option v-for="item in InsuranceKinds" v-bind:value="item.Code">{{item.Name}}</option>
                                            </select>
                                        </div>
                                        <div class="cols col-3">
                                            <label for="selWrntMethodCd" class="control-label"><span>보증방법</span></label>
                                            <select class="form-control" id="selWrntMethodCd" name="selWrntMethodCd" v-model="InsuranceSelectTerm.WrntMethodCode" @change="showWarraynty">
                                                <option value="">== 전체 ==</option>
                                                <option v-for="item in InsuranceWay" v-bind:value="item.Code">{{item.Name}}</option>
                                            </select>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="txtCompanyNm" class="control-label"><span>프로젝트</span></label>
                                            <div class="cols-btns-cont pad-r150">
                                                <input type="text" id="txtProjectNm" class="form-control txt" v-model="InsuranceSelectTerm.ProjectName" readonly="readonly" @change="showWarraynty" />
                                                <input type="hidden" id="hidProjectCd" v-model="InsuranceSelectTerm.ProjectCode" />
                                                <input type="hidden" id="hidCompanyNm" v-model="InsuranceSelectTerm.CompanyName" />
                                                <input type="hidden" id="hidCompanyCd" v-model="InsuranceSelectTerm.CompanyCode" />
                                            </div>
                                            <div class="cols-btns">
                                                <a href="#" id="btnListPop1" class="btn btn-default" @click.prevent="searchProjectCode">
                                                    <span>
                                                        <i class="glyphicon glyphicon-search"></i> 검색
                                                    </span>
                                                </a>
                                                <a href="#" id="btnSrchInit" class="btn btn-default" @click.prevent="clearProjectCode">
                                                    <span>
                                                        <i class="glyphicon glyphicon-repeat"></i> 초기화
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </section>
                                </section>
                            </fieldset>
                        </form>
                    </div>
                </div>
                <div class="grid-wrap">
                    <div class="sub-title">
                        <p class="sub-title-txt">보증보험 관리</p>
                    </div>
                    <!--리스트 영역-->
                    <div class="alert alert-info total">
                        <p><i class="glyphicon glyphicon-folder-close"></i> <span>전체: <span id="spTotCnt">{{TotalCount}}</span>건</span></p>
                    </div>
                    <div class="table-responsive">
                        <DxDataGrid ref="insuranceList"
                                    :data-source="InsuranceList"
                                    :hoverStateEnabled="true"
                                    :show-borders="true"
                                    :show-row-lines="true"
                                    @cell-prepared="onCellPrepared"
                                    @cell-click="onCellClick">
                            <DxColumn caption="거래처명" data-field="CompanyNm" width="20%" />
                            <DxColumn caption="프로젝트명" data-field="ProjectNm" width="20%" />
                            <DxColumn caption="보험가입회사명" data-field="InsuCoNm" width="20%" />
                            <DxColumn caption="보증종류" data-field="WrntDvsName" width="10%" alignment="center" />
                            <DxColumn caption="보증방법" data-field="WrntMethodName" width="10%" alignment="center" />
                            <DxColumn caption="등록일자" data-field="RegtDate" width="10%" alignment="center" />
                            <DxColumn caption="등록자" data-field="RegtEmpName" width="10%" alignment="center" />
                            <DxPaging :enabled="false" />
                        </DxDataGrid>
                    </div>
                </div>
            </div>
            <!--보증보험 상세 팝업-->
            <div class="modal fade" ref="PM1011E_Modal" tabindex="-1" role="dialog" aria-labelledby="PM1011E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" @click.Prevent="close">
                                <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                            </button>
                            <h3 class="modal-title">보증보험 상세</h3>
                        </div>
                        <div class="modal-body">
                            <section class="add-wrap">
                                <form>
                                    <fieldset>
                                        <legend>입력폼 col3</legend>
                                        <section class="cols-wrap">
                                            <div class="cols col-2">
                                                <label for="txtProjectNm2" class="control-label"><span><i class="glyphicon glyphicon-asterisk"></i>프로젝트</span></label>
                                                <div class="cols-btns-cont">
                                                    <input type="text" id="txtProjectNm2" class="form-control txt" readonly="readonly" v-model="InsuranceDetail.ProjectNm" />
                                                    <input type="hidden" id="hidProjectCd2" v-model="InsuranceDetail.ProjectCd" />
                                                </div>
                                                <div class="cols-btns">
                                                    <a href="#" id="btnItemPop2" class="btn btn-default" @click.prevent="popSearchProjectCode" v-show="PageActionType === 'I'">
                                                        <span>
                                                            <i class="glyphicon glyphicon-search"></i> 검색
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="cols col-2">
                                                <label for="txtCompanyNm" class="control-label">
                                                    <span>
                                                        <i class="glyphicon glyphicon-asterisk"></i>거래처
                                                    </span>
                                                </label>
                                                <input type="text" id="txtCompanyNm2" class="form-control txt" readonly="readonly" v-model="InsuranceDetail.CompanyNm" />
                                                <input type="hidden" id="hidCompanyCd2" v-model="InsuranceDetail.CompanyCd" />
                                            </div>
                                        </section>
                                        <section class="cols-wrap">
                                            <div class="cols col-2">
                                                <label for="selWrntDvsCd2" class="control-label">
                                                    <span>
                                                        <i class="glyphicon glyphicon-asterisk"></i>보증종류
                                                    </span>
                                                </label>
                                                <select class="form-control" id="selWrntDvsCd2" name="selWrntDvsCd2"
                                                        v-model="InsuranceDetail.WrntDvsCd">
                                                    <option value="">== 전체 ==</option>
                                                    <option v-for="item in InsuranceKinds" v-bind:value="item.Code">{{item.Name}}</option>
                                                </select>
                                            </div>
                                            <div class="cols col-2">
                                                <label for="selWrntMethodCd2" class="control-label">
                                                    <span>
                                                        <i class="glyphicon glyphicon-asterisk"></i>보증방법
                                                    </span>
                                                </label>
                                                <select class="form-control" id="selWrntMethodCd2" name="selWrntMethodCd2"
                                                        v-model="InsuranceDetail.WrntMethodCd">
                                                    <option value="">== 전체 ==</option>
                                                    <option v-for="item in InsuranceWay" v-bind:value="item.Code">{{item.Name}}</option>
                                                </select>
                                            </div>
                                        </section>

                                        <section class="cols-wrap">
                                            <div class="cols col-2" id="div_BizNm">
                                                <label for="txtInsuCoNm2" class="control-label">
                                                    <span>
                                                        <i class="glyphicon glyphicon-asterisk"></i>보험가입회사
                                                    </span>
                                                </label>
                                                <div class="cols-btns-cont">
                                                    <input type="text" id="txtInsuCoNm2" class="form-control txt" readonly="readonly"
                                                           v-model="InsuranceDetail.InsuCoNm" />
                                                    <input type="hidden" id="hidInsuNo2" value="" v-model="InsuranceDetail.InsuCoCd" />
                                                </div>
                                                <div class="cols-btns">
                                                    <button type="button" id="btnIInsuPop" class="btn btn-default" @click.prevent="searchCompanyCode">
                                                        <span>
                                                            <i class="glyphicon glyphicon-search"></i> 검색
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="cols col-2">
                                                <label for="txtInsuYMD2" class="control-label">
                                                    <span>
                                                        <i class="glyphicon glyphicon-asterisk"></i>보험가입일
                                                    </span>
                                                </label>
                                                <div class="input-group col-1">
                                                    <DxDateBox display-format="yyyy-MM-dd"
                                                               pickerType="calendar"
                                                               v-model="InsuranceDetail.InsuYMD"
                                                               style="border-radius: 0;" />
                                                </div>
                                            </div>
                                        </section>

                                        <section class="cols-wrap">
                                            <div class="cols col-1">
                                                <label class="control-label"><span>보증기간</span></label>
                                                <div class="col-xs-7">
                                                    <div class="col-2 input-group date">
                                                        <DxDateBox display-format="yyyy-MM-dd"
                                                                   pickerType="calendar"
                                                                   v-model="InsuranceDetail.WrntStartYMD"
                                                                   style="border-radius: 0;" />
                                                    </div>
                                                    <div class="tail"><span>~</span></div>
                                                    <label for="txtEndDate" class="sr-only"><span>보증기간</span></label>
                                                    <div class="col-2 input-group date">
                                                        <DxDateBox display-format="yyyy-MM-dd"
                                                                   pickerType="calendar"
                                                                   v-model="InsuranceDetail.WrntEndYMD"
                                                                   style="border-radius: 0;" />
                                                    </div>
                                                </div>
                                                <label for="txtWrntRate2" class="control-label" style="margin-left: 10px;"><span>보증비율</span></label>
                                                <div class="col-xs-3">
                                                    <input type="text" id="txtWrntRate2" class="form-control text-right"
                                                           v-bind:value="InsuranceDetail.WrntRate"
                                                           @input="function(e){
                                                            InsuranceDetail.WrntRate = e.target.value.toPriceString()
                                                            e.target.value = e.target.value.toPriceString()
                                                       }" />
                                                </div>
                                            </div>
                                            <div class="cols col-2">
                                                <label for="txtfeePrice" class="control-label">
                                                    <span><i class="glyphicon glyphicon-asterisk"></i>보험료</span>
                                                </label>
                                                <input type="text" id="txtfeePrice" class="form-control text-right"
                                                       v-bind:value="InsuranceDetail.feePrice"
                                                       @input="function(e){
                                                            InsuranceDetail.feePrice = e.target.value.toPriceString()
                                                            e.target.value = e.target.value.toPriceString()
                                                       }" />
                                            </div>
                                        </section>

                                        <section class="cols-wrap">
                                            <div class="cols col-2">
                                                <label for="txtInsuPrice2" class="control-label"><span>계약금액</span></label>
                                                <input type="text" id="txtInsuPrice2" class="form-control text-right"
                                                       v-bind:value="InsuranceDetail.InsuPrice"
                                                       @input="function(e){
                                                            InsuranceDetail.InsuPrice = e.target.value.toPriceString()
                                                            e.target.value = e.target.value.toPriceString()
                                                       }" />
                                            </div>
                                            <div class="cols col-2">
                                                <label for="txtWrntPrice2" class="control-label"><span>보험가입금액</span></label>
                                                <input type="text" id="txtWrntPrice2" class="form-control text-right"
                                                       v-bind:value="InsuranceDetail.WrntPrice"
                                                       @input="function(e){
                                                            InsuranceDetail.WrntPrice = e.target.value.toPriceString()
                                                            e.target.value = e.target.value.toPriceString()
                                                       }" />
                                            </div>
                                        </section>
                                        <section class="cols-wrap">
                                            <div><label></label></div>
                                        </section>
                                        <section class="cols-wrap">
                                            <div><label></label></div>
                                        </section>
                                        <section class="cols-wrap">
                                            <h4 class="add-tlt">첨부파일</h4>
                                            <div class="btn-wrap">
                                                <div style="margin: 5px 0px 3px 0px" class="fr">
                                                    <a href="#" id="btnChldInst1" class="btn btn-default btn-sm" title="추가"
                                                       @click.prevent="addAttachFile">
                                                        <span class="glyphicon glyphicon-plus"></span> 추가
                                                    </a>
                                                    <a href="#" id="btnChldRmve1" class="btn btn-default btn-sm" title="삭제"
                                                       @click.prevent="removeAttachFile">
                                                        <span class="glyphicon glyphicon-minus"></span> 삭제
                                                    </a>
                                                </div>
                                            </div>
                                            <section class="tbl-contents">
                                                <div class="table-responsive">
                                                    <table class="table tbl-list table-hover table-bordered" id="tbl_AddFile">
                                                        <colgroup>
                                                            <col class="chk" />
                                                            <col />
                                                        </colgroup>
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" class="text-center">선택</th>
                                                                <th scope="col" class="text-center">첨부파일</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-if="AttachFileList.length == 0">
                                                                <td colspan="2" class="text-center no-data">조회된 파일이 없습니다.</td>
                                                            </tr>
                                                            <tr v-else v-for="item in AttachFileList" v-show="!item.IsRemove">
                                                                <td class="chk">
                                                                    <label>
                                                                        <input type="checkbox" name="cbItem2"
                                                                               v-bind:value="item.IsSelected"
                                                                               @click="{
                                                                                    item.IsSelected = $event.target.checked
                                                                               }" />
                                                                    </label>
                                                                </td>
                                                                <td v-if="item.IsNew" class="text-center">
                                                                    <input type="file" name="ctlAttachFile"
                                                                           @change="{
                                                                                item.Files = $event.target.files
                                                                           }" />
                                                                </td>
                                                                <td v-else class="text-left">
                                                                    <a href="#" @click.prevent="fileHandler.download(item.GUID)">{{item.FileName}}</a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </section>
                                        </section>
                                    </fieldset>
                                </form>
                            </section>
                        </div>
                        <div class="modal-footer btn-align">
                            <label v-show="InsuranceDetail.ConfirmYN === 'Y'" id="txtHelpKnowTxt" class="control-label"><span>해당 보증보험은 매입이 완료되어 삭제, 수정할 수 없습니다.</span></label>

                            <div v-show="this.$parent.CanSave" class="btn-group">
                                <button v-show="InsuranceDetail.ConfirmYN !== 'Y'" type="button" id="btnItemSave" class="btn btn-primary" @click.prevent="saveData">
                                    <i class="glyphicon glyphicon-save"></i> 저장
                                </button>
                            </div>
                            <div v-show="this.$parent.CanDelete" class="btn-group">
                                <button v-show="InsuranceDetail.ConfirmYN !== 'Y' && PageActionType == 'V'" type="button" id="btnItemRmve" class="btn btn-warning" @click.Prevent="removeData">
                                    <i class="glyphicon glyphicon-edit"></i> 삭제
                                </button>
                            </div>
                            <div class="btn-group">
                                <button type="button" class="btn btn-danger" @click.Prevent="close">
                                    <i class="glyphicon glyphicon-remove"></i> 취소
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CP1030P ref="CP1030P"></CP1030P>
            <CP1060P ref="CP1060P"></CP1060P>
        </div>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle';
    import CP1030P from '@/components/libs/popup/CP1030P';
    import CP1060P from '@/components/libs/popup/CP1060P';
    import { DxDataGrid, DxColumn, DxMasterDetail, DxButton, DxScrolling, DxSorting, DxLoadPanel, DxPaging } from 'devextreme-vue/data-grid';
    import DxDateBox, { DxCalendarOptions } from 'devextreme-vue/date-box';

    export default {
        name: 'PM1010R',
        components: {
            ViewTitle,
            DxDataGrid,
            DxColumn,
            DxMasterDetail,
            DxButton,
            DxScrolling,
            DxSorting,
            DxLoadPanel,
            DxPaging,
            DxDateBox,
            DxCalendarOptions,
            CP1030P,
            CP1060P,
        },
        data() {
            return {
                InsuranceKinds: new Array(),                                          // 보증 종류
                InsuranceWay: new Array(),                                            // 보증방법
                TotalCount: 0,                                                 // 데이터 총 개수
                InsuranceList: new Array(),                                           // 조회된 보증보험 리스트
                AllAttachFileList: new Array(),                                       // 전체 첨부파일 목록
                AttachFileList: new Array(),                                          // 첨부파일 목록
                // 상세 데이터 객체
                InsuranceDetail: {
                    WrntDvsCd: null,
                    WrntDvsName: null,
                    WrntRate: null,
                    WrntPrice: null,
                    WrntMethodCd: null,
                    WrntMethodName: null,
                    WrntStartYMD: null,
                    WrntEndYMD: null,
                    InsuYMD: null,
                    InsuCoCd: null,
                    feePrice: null,
                    InsuPrice: null,
                    ProjectNm: null,
                    CompanyNm: null,
                    RegtDate: null,
                    RegtEmpName: null,
                    CorpCd: null,
                    CompanyCd: null,
                    ProjectCd: null,
                    SeqNo: null,
                    InsuCoNm: null,
                    ConfirmYN: null,
                },
                // 조회조건 데이터 객체
                InsuranceSelectTerm: {
                    Arg: 'L',
                    UserId: this.$parent.EmpId,
                    CorpCd: this.$parent.CompanyCode,
                    ProjectCode: '',
                    ProjectName: '',
                    WrntDvsCode: '',
                    WrntMethodCode: '',
                    CompanyName: '',
                    CompanyCode: '',
                    Year: this.moment(new Date()).year(),
                },
                // 첨부파일 상세 객체
                AttachFileDetail: {
                    Arg: null,
                    UserId: this.$parent.EmpId,
                    CorpCd: null,
                    CompanyCd: null,
                    ProjectCd: null,
                    SeqNo: null,
                    FileSeq: null,
                    GUID: null,
                    FileName: null,
                    IsNew: false,
                    IsRemove: false,
                    IsSelected: false,
                    Files: null
                },
                TargetYearList: new Array(),                                     // 년도 목록
                EmpId: this.$store.getters.getEmpId,                             // 사용자 사번
                CompanyCode: this.$store.getters.getCompanyCode,                 // 회사 코드
                CallbackFunction: null,                                          // 보증보험 선택 결과를 넘겨줄 콜백함수
                CurrentDateYYMMDD: this.moment(new Date()).format('YYYY-MM-DD'), // 현재 날짜
                ConfirmYN: null,                                                 // 저장 삭제 버튼 활성화 컨트롤
                PageActionType: 'V',                                             // 화면액션 값 설정 (I:신규, V: 상세보기)
            }
        },
        beforeMount() {

            // 보증종류 조회
            this.InsuranceKinds = $.scriptBase.getCommonCode('CODE000', '0703000', '', '');
            // 보증방법 조회
            this.InsuranceWay = $.scriptBase.getCommonCode('CODE000', '0705000', '', '');

            //#region 년도 조회 리스트 세팅
            var startYear = this.moment(new Date()).add(5, 'year').year();
            var endYear = this.moment(new Date(2013, 1, 1, 0, 0, 0)).year();

            for (var i = startYear; i >= endYear; i--) {        // 대상년도 목록 값 설정

                this.TargetYearList.push(i);
            }
            //#endregion
        },
        mounted() {

            if (this.$parent.CanSearch) {

                this.showWarraynty(false);
            }
        },
        methods: {

            onCellPrepared(e) {

                if (e.rowType == 'header') {
                    e.cellElement.style['textAlign'] = 'center';
                }
                if (e.rowType == 'data') {
                    e.cellElement.style['verticalAlign'] = 'middle';
                }
            },
            close: function () {

                $(this.$refs.PM1011E_Modal).modal("hide");
                this.showWarraynty();
            },
            // 보증보험관리 조회 메서드
            showWarraynty: function myfunction(isOpenDetailPopup) {

                var parent = this;

                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/GetWarranty';
                url = url.toRealServiceUrl();
                var obj = new Object();
                obj.jsonString = JSON.stringify(this.InsuranceSelectTerm);

                // ajax 성공 시 실행되는 핸들러 정의
                var ajaxSuccessHandler = function (data) {

                    var table = JSON.parse(data.d).Table;
                    var table1 = JSON.parse(data.d).Table1;
                    var table2 = JSON.parse(data.d).Table2;

                    // 목록
                    parent.InsuranceList = (table.length > 0) ? table : new Array();

                    // 목록 전체 건수
                    parent.TotalCount = (table1.length > 0) ? table1[0].TotCnt : 0;

                    // 첨부파일 전체
                    parent.AllAttachFileList = (table2.length > 0) ? table2 : new Array();

                    // 상세팝업 오픈 플래그 값에 따라 상세 팝업 오픈
                    if (isOpenDetailPopup) {

                        for (var i = 0; i < table.length; i++) {

                            if (table[i].CorpCd === parent.InsuranceDetail.CorpCd
                                && table[i].CompanyCd === parent.InsuranceDetail.CompanyCd
                                && table[i].ProjectCd === parent.InsuranceDetail.ProjectCd
                                && table[i].SeqNo === parent.InsuranceDetail.SeqNo) {

                                parent.onCellClick(table[i]);
                                break;
                            }
                        }
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler);
            },
            // 테이블Row 클릭 이벤트 핸들러
            onCellClick: function (row) {

                if (row.rowType === "data") {

                    // 화면 액션값 설정 (I:신규, V: 상세보기)
                    this.PageActionType = 'V';

                    // 선택한 행 데이터 복사
                    this.InsuranceDetail = Object.assign({}, row.data);

                    // 수정 시 필요한 객체 추가
                    this.InsuranceDetail.Arg = 'I';
                    this.InsuranceDetail.UserId = this.$parent.EmpId;
                    this.InsuranceDetail.CompanyCode = this.$parent.CompanyCode;

                    // 상세 페이지 금액 데이터 가공
                    this.InsuranceDetail.WrntRate = (this.InsuranceDetail.WrntRate != null) ? this.InsuranceDetail.WrntRate.toString().toPriceString() : null;
                    this.InsuranceDetail.InsuPrice = (this.InsuranceDetail.InsuPrice != null) ? this.InsuranceDetail.InsuPrice.toString().toPriceString() : null;
                    this.InsuranceDetail.feePrice = (this.InsuranceDetail.feePrice != null) ? this.InsuranceDetail.feePrice.toString().toPriceString() : null;
                    this.InsuranceDetail.WrntPrice = (this.InsuranceDetail.WrntPrice != null) ? this.InsuranceDetail.WrntPrice.toString().toPriceString() : null;

                    // 대상 첨부파일 추출
                    this.extractTargetFiles(row.data);

                    // 보증 보험 상세 팝업 오픈
                    $(this.$refs.PM1011E_Modal).modal("show");
                }
            },
            // 대상 첨부파일 추출
            // targetRow: 선택한 리스트 항목
            extractTargetFiles: function (targetRow) {

                var parent = this;

                // 첨부파일 목록 초기화
                this.AttachFileList = new Array();

                // 첨부파일 설정
                this.AllAttachFileList.forEach((object) => {

                    if (object.CorpCd === targetRow.CorpCd
                        && object.CompanyCd === targetRow.CompanyCd
                        && object.ProjectCd === targetRow.ProjectCd
                        && object.SeqNo === targetRow.SeqNo) {

                        object.IsNew = false;
                        object.IsRemove = false;
                        object.IsSelected = false;
                        object.Files = null;

                        parent.AttachFileList.push(object);
                    }
                });

                // 대상파일이 없으면 파일 목록 객체 초기화
                if (this.AttachFileList.length <= 0) {

                    this.AttachFileList = new Array();
                }
            },
            // 프로젝트 검색 메서드
            searchProjectCode: function () {

                var parent = this;

                var successCallback = function (result) {

                    // 결과값을 추출 후 돔객체에 바인딩
                    parent.InsuranceSelectTerm.ProjectName = result.ProjectNm;
                    parent.InsuranceSelectTerm.ProjectCode = result.ProjectCd;
                    parent.InsuranceSelectTerm.CompanyName = result.CompanyNm;
                    parent.InsuranceSelectTerm.CompanyCode = result.CompanyCd;

                    parent.showWarraynty(false);
                }

                this.$refs.CP1060P.showProjectPopup(successCallback);
            },
            // 팝업_프로젝트 검색 메서드
            popSearchProjectCode: function () {

                var parent = this;

                var successCallback = function (result) {

                    // 결과값을 추출 후 돔객체에 바인딩
                    parent.InsuranceDetail.ProjectNm = result.ProjectNm;
                    parent.InsuranceDetail.ProjectCd = result.ProjectCd;
                    parent.InsuranceDetail.CompanyNm = result.CompanyNm;
                    parent.InsuranceDetail.CompanyCd = result.CompanyCd;
                }

                this.$refs.CP1060P.showProjectPopup(successCallback);
            },
            // 프로젝트 초기화 메서드
            clearProjectCode: function () {

                this.InsuranceSelectTerm.ProjectCode = '';
                this.InsuranceSelectTerm.ProjectName = '';
                this.InsuranceSelectTerm.WrntDvsCode = '';
                this.InsuranceSelectTerm.WrntMethodCode = '';
                this.InsuranceSelectTerm.CompanyName = '';
                this.InsuranceSelectTerm.CompanyCode = '';
            },
            // 보증보험 관리 추가 버튼 클릭 이벤트 핸들러
            btnAddClicked: function () {

                // 화면 액션값 설정 (I:신규, V: 상세보기)
                this.PageActionType = 'I';

                // 상세데이터 객체 초기화
                this.InsuranceDetail = {
                    Arg: 'I',
                    UserId: this.$parent.EmpId,
                    WrntDvsCd: '',
                    WrntDvsName: null,
                    WrntRate: null,
                    WrntPrice: null,
                    WrntMethodCd: '',
                    WrntMethodName: null,
                    WrntStartYMD: null,
                    WrntEndYMD: null,
                    InsuYMD: null,
                    InsuCoCd: null,
                    InsuPrice: null,
                    feePrice: null,
                    ProjectNm: this.InsuranceSelectTerm.ProjectName,
                    CompanyNm: this.InsuranceSelectTerm.CompanyName,
                    RegtDate: null,
                    RegtEmpName: null,
                    CorpCd: this.$parent.CompanyCode,
                    CompanyCd: this.InsuranceSelectTerm.CompanyCode,
                    ProjectCd: this.InsuranceSelectTerm.ProjectCode,
                    SeqNo: null,
                    InsuCoNm: null,
                    ConfirmYN: null,
                    TraderCd: null,
                    PurChaseNm: this.InsuranceDetail.ProjectCd + "_" + this.InsuranceDetail.WrntDvsCd,
                };

                // 첨부파일 목록객체 초기화
                this.AttachFileList = new Array();

                // 보증 보험 상세 팝업 오픈
                $(this.$refs.PM1011E_Modal).modal("show");
            },
            // 업체 검색 팝업 오픈
            searchCompanyCode: function () {

                var parent = this;

                var successCallback = function (result) {

                    // 보험 가입 회사 바인딩
                    parent.InsuranceDetail.InsuCoNm = result.CompanyNm;
                    parent.InsuranceDetail.InsuCoCd = result.CompanyCd;
                }

                this.$refs.CP1030P.open(successCallback);
            },
            // 보증보험관리 저장 메서드
            saveData: function () {

                if (this.InsuranceDetail.ProjectNm === '' || this.InsuranceDetail.ProjectNm === null) {

                    alert('프로젝트명을 입력하세요.');
                    document.getElementById('txtProjectNm2').focus();
                    return;
                }

                if (this.InsuranceDetail.CompanyCd === '' || this.InsuranceDetail.CompanyCd === null) {

                    alert('거래처를 입력하세요.');
                    document.getElementById('txtCompanyNm2').focus();
                    return;
                }

                if (this.InsuranceDetail.WrntDvsCd === '' || this.InsuranceDetail.WrntDvsCd === null) {

                    alert("보증종류를 입력하세요.");
                    document.getElementById('selWrntDvsCd2').focus();
                    return;
                }
                if (this.InsuranceDetail.WrntMethodCd === '' || this.InsuranceDetail.WrntMethodCd === null) {

                    alert('보증방법을 입력하세요.');
                    document.getElementById('selWrntMethodCd2').focus();
                    return;
                }
                if (this.InsuranceDetail.InsuCoNm === '' || this.InsuranceDetail.InsuCoNm === null) {

                    alert('보험가입회사를 입력하세요.');
                    document.getElementById('txtInsuCoNm2').focus();
                    return;
                }
                if (this.InsuranceDetail.InsuYMD === '' || this.InsuranceDetail.InsuYMD === null) {

                    alert('보험가입일을 입력하세요.');
                    return;
                }

                if (this.InsuranceDetail.WrntStartYMD === null || this.InsuranceDetail.WrntEndYMD === '') {
                    
                    alert('보증기간을 선택하세요');
                    return;
                } else {

                    this.InsuranceDetail.WrntStartYMD = this.moment(this.InsuranceDetail.WrntStartYMD).format('YYYYMMDD');
                    this.InsuranceDetail.WrntEndYMD = this.moment(this.InsuranceDetail.WrntEndYMD).format('YYYYMMDD');
                }

                if (this.InsuranceDetail.feePrice === null || this.InsuranceDetail.feePrice === '') {

                    alert('수수료를 입력해주세요.');
                    document.getElementById('txtfeePrice').focus();
                    return;
                }

                if (this.InsuranceDetail.InsuPrice === null || this.InsuranceDetail.InsuPrice === '') {

                    alert('보험가입금액을 입력하세요');
                    document.getElementById('txtInsuPrice2').focus();
                    return;
                }
                
                if (Number(this.InsuranceDetail.InsuPrice.replace(/,/g, "")) > 2147483647) {

                    alert('보험가입금액은 2,147,483,647을 넘을 수 없습니다.');
                    document.getElementById('txtInsuPrice2').focus();
                    return;
                }

                if (this.InsuranceDetail.WrntPrice === null || this.InsuranceDetail.WrntPrice === '') {

                    alert('보증금액을 입력하세요');
                    document.getElementById('txtWrntPrice2').focus();
                    return;
                }
                
                if (Number(this.InsuranceDetail.WrntPrice.replace(/,/g, "")) > 2147483647) {

                    alert('보증금액은 2,147,483,647을 넘을 수 없습니다.');
                    document.getElementById('txtWrntPrice2').focus();
                    return;
                }

                var parent = this;
                var newFileList = new Array();

                // 파일업로드 완료 후 호출되는 콜백함수
                var successUploadFileCallback = function (result) {

                    // 저장 실행
                    parent.doSave(result);
                }

                if (this.AttachFileList.length > 0) {

                    // 신규 파일이 존재하는지 체크
                    var isExistNewFile = this.AttachFileList.some((file) => {

                        return file.IsNew;
                    })

                    // 신규파일이 존재하면 파일 업로드 후 저장
                    if (isExistNewFile) {

                        var isValidateFiles = true;

                        for (var i = 0; i < this.AttachFileList.length; i++) {

                            var file = this.AttachFileList[i];

                            if (file.IsNew && !file.IsRemove) {

                                if (file.Files != null && file.Files.length > 0) {

                                    for (var j = 0; j < file.Files.length; j++) {

                                        newFileList.push(file.Files[j]);
                                    }
                                } else {

                                    alert('첨부파일을 선택하세요.');
                                    isValidateFiles = false;
                                    break;
                                }
                            }
                        }

                        if (isValidateFiles) {

                            this.fileHandler.upload(newFileList, successUploadFileCallback);
                        }
                    } else {

                        // 파일이 존재하지 않으면 저장
                        this.doSave(null);
                    }
                } else {

                    // 파일이 존재하지 않으면 저장
                    this.doSave(null);
                }
            },
            doSave: function (uploadFiles) {

                var parent = this;

                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/SaveWarranty'.toRealServiceUrl();
                var obj = new Object();
                var attachFileList = new Array();

                this.InsuranceDetail.PurChaseNm = this.InsuranceDetail.CompanyNm + "_" + this.InsuranceDetail.ProjectNm + "_";

                obj.jsonString = JSON.stringify(this.InsuranceDetail);

                if (uploadFiles != null) {

                    attachFileList = new Array();

                    uploadFiles.files.forEach((object) => {

                        attachFileList.push({
                            'Arg': 'FI',
                            'UserId': parent.$parent.EmpId,
                            'CorpCd': parent.$parent.CompanyCode,
                            'CompanyCd': parent.InsuranceDetail.CompanyCd,
                            'ProjectCd': parent.InsuranceDetail.ProjectCd,
                            'SeqNo': parent.InsuranceDetail.SeqNo,
                            'GUID': object.nFileName,
                            'FileName': object.oFileName,
                        });
                    });
                }

                obj.jsonFileListString = JSON.stringify(attachFileList);

                // 서비스호출 성공 콜백 함수
                var successCallback = function (data) {

                    if (data.d > 0) {

                        if (parent.PageActionType === 'I') {

                            alert('등록되었습니다.');
                            parent.close();
                            // 등록 후 그리드 재조회
                            parent.showWarraynty(false);
                        } else {

                            alert('저장되었습니다.');
                            parent.close();
                            // 등록 후 그리드 재조회
                            parent.showWarraynty(false);
                        }
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 보증보험관리 삭제 메서드
            removeData: function () {

                if (this.InsuranceDetail === null && this.PageActionType === "I") {

                    alert("삭제할 정보가 존재하지 않습니다.");
                    return;
                }

                if (confirm("삭제하시겠습니까?")) {

                    var parent = this;

                    var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/RemoveWarranty'.toRealServiceUrl();
                    var obj = new Object();

                    // 삭제 시 필요한 객체 추가
                    this.InsuranceDetail.Arg = 'D';
                    this.InsuranceDetail.UserId = this.$parent.EmpId;
                    this.InsuranceDetail.CompanyCode = this.$parent.CompanyCode;

                    obj.jsonString = JSON.stringify(this.InsuranceDetail);

                    // 서비스호출 성공 콜백 함수
                    var successCallback = function (data) {

                        if (data.d > 0) {

                            alert('삭제되었습니다.');
                            parent.close();
                            parent.showWarraynty(false);
                        } else {

                            alert('삭제에 실패하였습니다. 잠시 후 다시 시도해 주세요.');
                        }
                    }

                    $.scriptBase.executeAjaxAsync(url, obj, successCallback);
                }
            },
            // 첨부파일 추가
            addAttachFile: function () {

                // 첨부파일 목록 객체 초기화
                if (this.AttachFileList.length > 0) {

                    this.AttachFileList = new Array();
                }

                var tempObject = Object.assign({}, this.AttachFileDetail);
                tempObject.IsNew = true;

                this.AttachFileList.push(tempObject);
            },
            // 첨부파일 삭제
            removeAttachFile: function () {

                var parent = this;
                var removeFileList = new Array();

                if (this.AttachFileList.length > 0) {

                    var isExistSelected = this.AttachFileList.some((object) => {

                        return object.IsSelected;
                    })

                    if (isExistSelected) {

                        if (confirm('삭제하시겠습니까?')) {

                            this.AttachFileList.forEach((object) => {

                                // 선택한 파일 삭제 플래그 변경
                                object.IsRemove = object.IsSelected;

                                // 기존파일은 DB에서 삭제하기위해 별도로 모음
                                if (!object.IsNew) {

                                    object.Arg = 'FD';
                                    removeFileList.push(object);
                                }
                            });

                            // 삭제되지 않은 파일이 남아있는지 체크
                            var isExistFile = this.AttachFileList.some((object) => {

                                return !object.IsRemove;
                            });

                            // 삭제되지 않은 파일이 존재하지 않으면 파일목록 객체 초기화
                            if (!isExistFile) {

                                this.AttachFileList = new Array();
                            }

                            var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/RemoveWarrantyFile'.toRealServiceUrl();
                            var obj = new Object();

                            obj.jsonFileListString = JSON.stringify(removeFileList);

                            // 서비스호출 성공 콜백 함수
                            var successCallback = function (data) {

                                if (data.d > 0) {

                                    alert('삭제되었습니다.');
                                    parent.showWarraynty(true);
                                }
                            }

                            $.scriptBase.executeAjaxAsync(url, obj, successCallback);
                        }
                    } else {

                        alert('삭제할 파일을 선택하세요.');
                    }
                } else {

                    alert('삭제할 파일을 선택하세요.');
                }
            },
        }
    }
</script>