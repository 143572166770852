p
<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a v-show="this.$parent.CanSearch" href="#" id="btnListSrch" class="btn btn-primary btn-row2" @click.prevent="onProductSearchClick">
                    <span>
                        <i class="glyphicon glyphicon-search"></i> 조회
                    </span>
                </a>
                <a v-show="this.$parent.CanNew" href="#" id="btnListInst" class="btn btn-default" @click.prevent="onAddCompanyProductClick">
                    <span>
                        <i class="glyphicon glyphicon-pencil"></i> 등록
                    </span>
                </a>
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="sch3-1" class="control-label">
                                            <span>사용여부</span>
                                        </label>
                                        <select id="CM1040R_UseYN" class="form-control" v-model="ProductListSelectTerm.UseYn">
                                            <option value="Y" selected="selected">Y</option>
                                            <option value="N">N</option>
                                        </select>
                                    </div>
                                </section>
                            </section>

                        </fieldset>
                    </form>
                </div>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">상품 관리</p>
                </div>
                <div>
                    <!--리스트 영역-->
                    <div class="alert alert-info total">
                        <p>
                            <i class="glyphicon glyphicon-folder-close"></i>
                            <span>
                                전체:
                                <span id="CM1040R_total">
                                    {{TotalCount}}
                                </span>건
                            </span>
                        </p>
                    </div>
                    <!--그리드-->
                    <div class="table-responsive">
                        <DxDataGrid id="grid-container"
                                    :data-source="ProductList"
                                    :hover-state-enabled="true"
                                    :show-border="false"
                                    :show-row-lines="true"
                                    key-expr="ProductNm"
                                    @cell-prepared="onCellPrepared"
                                    @cell-click="onRowClick">
                            <DxColumn data-field="ProductNm"
                                      caption="상품명"
                                      alignment="left" 
                                      width="30%"/>
                            <DxColumn data-field="ProductDesc"
                                      caption="설명"
                                      alignment="left" 
                                      width="45%"/>
                            <DxColumn data-field="UnitPrice"
                                      caption="단가"
                                      alignment="right" 
                                      width="10%"/>
                            <DxColumn data-field="UnitVAT"
                                      caption="부가세"
                                      alignment="right" 
                                      width="10%"/>
                            <DxColumn data-field="UseYN"
                                      caption="사용여부"
                                      alignment="center" 
                                      width="5%"/>
                        </DxDataGrid>
                    </div>
                    <!-- 페이지링 S -->
                    <div class="text-center">
                        <ul ref="CM1040R_pager" class="pagination"></ul>
                    </div>
                    <!-- 페이지링 E -->
                </div>
            </div>
            <!--모달 창 불러오기-->
            <CM1042E ref="CM1042E" @getData="getCompanyProduct"></CM1042E>
        </div>
    </div>
</template>






<script>
    import { DxDataGrid, DxColumn } from 'devextreme-vue/data-grid';
    import ViewTitle from '@/components/ViewTitle'
    import CM1042E from '@/components/cm/popup/CM1042E'

    export default {
        name: 'CM1040R',
        components: {
            ViewTitle, DxDataGrid, DxColumn, CM1042E
        },
        data() {
            return {
                ProgressList: null,                         // 진행상황 공통코드 리스트
                ProductList: new Array(),                   // 조회된 상품 리스트
                TargetYearList: new Array(),

                // 상품 상세
                ProductDetail: {
                    RowSeq: null,
                    CorpCd: null,
                    ProductCd: null,
                    ProductNm: null,
                    ProductDesc: null,
                    UnitPrice: null,
                    UnitVAT: null,
                    UseYN: null,
                    RegtDate: null,
                    RegtEmpNo: null,
                    UpdateDate: null,
                    UpdateEmpNo: null,
                },
                // 상품목록 조회조건
                ProductListSelectTerm: {//
                    UseYn: 'Y',
                    TopSize: 100,
                    CurrentPage: 1
                },
                ProductListTotalCount: 0,   // 상품목록 전체 건수
                PageActionType: 'V',        // 페이지 액션 값 (I: 신규, V: 상세)
                TotalCount: 0
            }
        },
        beforeMount() {

            // 공통코드 조회
            this.ProgressList = $.scriptBase.getCommonCode('CODE000', '0723000', '', '') // 진행상황

            var startYear = this.moment(new Date()).year()
            var endYear = this.moment(new Date(2013, 1, 1, 0, 0, 0)).year()

            // 대상년도 목록 값 설정
            for (var i = startYear; i >= endYear; i--) {

                this.TargetYearList.push(i)
            }
        },
        mounted() {

            this.$refs.CM1042E.moment = this.moment

            // 초기조회
            if (this.$parent.CanSearch) {

                this.getCompanyProduct()
            }
        },
        methods: {
            //#region init
            //#endregion

            //#region event
            onCellPrepared(e) { // datagrid header 가운데 정렬, column 중앙 정렬
                if (e.rowType == 'header') {
                    e.cellElement.style['textAlign'] = 'center'
                }
                if (e.rowType == 'data') {
                    e.cellElement.style['verticalAlign'] = 'middle'
                }
            }
            //#endregion

            //#region button click
            // < 그리드 상품 행 선택 이벤트 >
            , onRowClick(e) {

                if (e.rowType === "data") {//

                    var parent = this

                    // 화면 액션값 설정 (I:신규, V: 상세보기)
                    this.PageActionType = 'V'

                    var callbackFunction = function () {

                        parent.getCompanyProduct()
                    }

                    // 상품 상세 정보 조회
                    this.$refs.CM1042E.open(callbackFunction, e.data)
                }
            }
            , onProductSearchClick() {
                this.getCompanyProduct()
            }
            // < 그리드 상품 신규 등록 >
            , onAddCompanyProductClick: function () {

                // 화면 액션값 설정 (I:신규, V: 상세보기)
                this.PageActionType = 'I'

                var callbackFunction = function () {

                    parent.getCompanyProduct()
                }
                // 상품등록창 오픈
                this.$refs.CM1042E.open(callbackFunction)
            }
            //#endregion

            //#region service
            // < 그리드 회사상품 목록 조회 >
            , getCompanyProduct: function () {

                var parent = this

                var url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/GetCompanyProduct'
                url = url.toRealServiceUrl() //
                var obj = new Object()

                this.ProductListSelectTerm.ProductNm = null

                // 프로그래밍 언어를 사람이 이해할 수 있는 언어로 JSON을 활용하여 번역
                obj.jsonString = JSON.stringify(this.ProductListSelectTerm)

                var ajaxSuccessHandler = function (data) {

                    var jsonObj = JSON.parse(data.d)

                    parent.ProductList = jsonObj.Table

                    // 게시물(=행=row) 개수
                    parent.TotalCount = jsonObj.Table1[0].TotCnt

                }

                // 콜백 함수
                var successCallback = function (result) {

                    // 결과값을 추출 후 돔객체에 바인딩
                    // 바인딩(값을 넣는 것)은 한쪽(되도록이면 부모)에서 하는 게 권장됨
                    this.$parent.ProductListSelectTerm.ProductNm = result.ProductNm //
                    this.$parent.ProductListSelectTerm.ProductCd = result.ProductCd

                    // 업체 적용 후 재조회
                    this.$parent.getCompanyProduct()
                }
                // url, Object, function을 넘겨서 처리하도록 함(비동기)
                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler, successCallback)
            }
            //#endregion
        }
    }
</script>



<style scoped></style>