<template>
    <!--상세 모달 팝업-->
    <div class="modal fade" id="PM1008E_Modal" ref="PM1008E_Modal" tabindex="-1" role="dialog" aria-labelledby="PM1008E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-lg ">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" @click.Prevent="close">
                        <span aria-hidden="true">&times;</span>
                        <span class="sr-only">Close</span>
                    </button>
                    <h3 class="modal-title">프로젝트 상세</h3>
                </div>
                <div class="modal-body">
                    <section class="add-wrap">
                        <form>
                            <fieldset>
                                <legend>..</legend>
                                <!--화면 첫번째 줄-->
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtSalesCompanyNM" class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>고객사
                                            </span>
                                        </label>
                                        <div class="cols-btns-cont">
                                            <input type="text" id="txtCompanyNm2" class="form-control txt" disabled="disabled" v-model="ProjectDetail.CompanyNm" />
                                            <input type="hidden" id="hidCompanyCd2" v-model="ProjectDetail.CompanyCd" />
                                        </div>
                                        <div class="cols-btns">
                                            <button type="button" id="btnSaleComPop" class="btn btn-default" @click="searchCompany(2)">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i> 검색
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="cols col-2" id="div_BizNm">
                                        <label for="txtBizNm2" class="control-label">
                                            <span>
                                                영업명
                                            </span>
                                        </label>
                                        <div class="cols-btns-cont">
                                            <input type="text" id="txtBizNm2" class="form-control txt" disabled="disabled" v-model="ProjectDetail.BizNm" />
                                            <input type="hidden" id="hidBizNo2" v-model="ProjectDetail.BizNo" />
                                        </div>
                                        <div class="cols-btns">
                                            <button type="button" id="btnSaleBizPop" class="btn btn-default"
                                                    @click="searchBusiness">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i> 검색
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </section>
                                <!--화면 두번째 줄-->
                                <section class="cols-wrap">
                                    <div class="cols col-1">
                                        <label for="txtPrice2" class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>프로젝트명
                                            </span>
                                        </label>
                                        <input type="text" id="txtProjectNm2" class="form-control" v-model="ProjectDetail.ProjectNm" />
                                        <input type="hidden" id="hidProjectCd2" v-model="ProjectDetail.ProjectCd" />
                                    </div>
                                </section>
                                <!--화면에 한줄 추가-->
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtPrjPersonNm2" class="control-label">
                                            <span><i class="glyphicon glyphicon-asterisk"></i>PM</span>
                                        </label>
                                        <div class="cols-btns-cont">
                                            <input type="text" id="txtPrjPersonNm2" class="form-control txt" disabled="disabled" v-model="ProjectDetail.ManagerNm" />
                                            <input type="hidden" id="hidPrjPersonCd2" v-model="ProjectDetail.ManagerNo" />
                                        </div>
                                        <div class="cols-btns">
                                            <button type="button" id="btnPrjPerson" class="btn btn-default" @click="searchManager">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i> 검색
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtSubContractNm2" class="control-label">
                                            <span>도급사</span>
                                        </label>
                                        <div class="cols-btns-cont">
                                            <input type="text" id="txtSubContractNm2" class="form-control txt" disabled="disabled" v-model="ProjectDetail.ContractCompanyNm" />
                                            <input type="hidden" id="hidSubContractCd2" v-model="ProjectDetail.ContractCompanyCd" />
                                        </div>
                                        <div class="cols-btns">
                                            <button type="button" id="btnSubContract" class="btn btn-default" @click="searchCompany(3)">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i> 검색
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </section>
                                <!--화면 세번째 줄-->
                                <section class="cols-wrap">
                                    <div class="cols">
                                        <label for="txtProgDetail" class="control-label">
                                            <span>설명</span>
                                        </label>
                                        <input type="text" id="txtProjectDesc2" class="form-control" v-model="ProjectDetail.ProjectDesc" />
                                    </div>
                                </section>
                                <!--화면 네번쨰 중-->
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>계약일자
                                            </span>
                                        </label>
                                        <div class="col-2 input-group date form_date">
                                            <DxDateBox id="txtConStartYMD" v-model="ProjectDetail.ConStartYMD" class="form-control txt" type="date" display-format="yyyy-MM-dd"></DxDateBox>
                                        </div>
                                        <span class="tail"><span>~</span></span>
                                        <div class="col-2 input-group date form_date">
                                            <DxDateBox id="txtConEndYMD" v-model="ProjectDetail.ConEndYMD" class="form-control txt" type="date" display-format="yyyy-MM-dd"></DxDateBox>
                                        </div>
                                    </div>
                                    <div class="cols col-2">
                                        <label class="control-label">
                                            <span><i class="glyphicon glyphicon-asterisk"></i>수행일자</span>
                                        </label>
                                        <div class="col-2 input-group date form_date">
                                            <DxDateBox id="txtExeStartYMD" v-model="ProjectDetail.ExeStartYMD" class="form-control txt" type="date" display-format="yyyy-MM-dd"></DxDateBox>
                                        </div>
                                        <span class="tail"><span>~</span></span>
                                        <div class="col-2 input-group date form_date">
                                            <DxDateBox id="txtExeEndYMD" v-model="ProjectDetail.ExeEndYMD" class="form-control txt" type="date" display-format="yyyy-MM-dd"></DxDateBox>
                                        </div>
                                    </div>
                                </section>
                                <!--화면 다섯번째 줄-->
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="Payment" class="control-label">
                                            <span>계약금</span>
                                        </label>
                                        <input type="text" id="Payment" class="form-control text-right"
                                               v-bind:value="ProjectDetail.Price"
                                               @input="function(e){
                                                        ProjectDetail.Price = e.target.value.toPriceString()
                                                        e.target.value = e.target.value.toPriceString()
                                                   }" />
                                    </div>
                                    <div class="cols col-2">
                                        <label for="selVatIncludeYn" class="control-label">
                                            <span>V.A.T</span>
                                        </label>
                                        <select class="form-control" id="selVatIncludeYn" ref="selVatIncludeYn" name="selVatIncludeYn"
                                                v-model="ProjectDetail.IncludeVatYn"
                                                @change="selectBoxVatChangedEvent">
                                            <option value="">== 선택 ==</option>
                                            <option value="Y">포함</option>
                                            <option value="N">별도</option>
                                            <option value="Z">영세율</option>
                                            <option value="F">면세</option>
                                        </select>
                                    </div>
                                </section>
                                <!--화면 여섯번쨰 줄-->
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtVAT2" class="control-label"><span>부가세</span></label>
                                        <input type="text" id="txtVAT2" class="form-control text-right"
                                               v-model="ProjectDetail.VAT"
                                               disabled="disabled" />
                                        <!--v-bind:disabled="ProjectDetail.IncludeVatYn === null
                                                             || ProjectDetail.IncludeVatYn === ''
                                                             || ProjectDetail.IncludeVatYn === 'Y'" />-->
                                    </div>
                                    <div class="cols col-2">
                                        <label for="selStatusCd2" class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>진행상태
                                            </span>
                                        </label>
                                        <select class="form-control" id="selStatusCd2" name="selStatusCd2" v-model="ProjectDetail.StatusCd">
                                            <option value="">== 선택 ==</option>
                                            <option v-for="item in StatusCodeList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                </section>
                                <!--화면의 일곱번째 줄-->
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtRootProjectNm2" class="control-label">
                                            <span>상위 프로젝트</span>
                                        </label>
                                        <div class="cols-btns-cont">
                                            <input type="text" id="txtRootProjectNm2" class="form-control txt" disabled="disabled" v-model="ProjectDetail.RotProjectNm" />
                                            <input type="hidden" id="hidRootProjectCd2" v-model="ProjectDetail.RotProjectCd" />
                                        </div>
                                        <div class="cols-btns">
                                            <button type="button" id="btnRootPrjPop" class="btn btn-default" @click="searchProject">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i> 검색
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="selPrjetSection" class="control-label">
                                            <span><i class="glyphicon glyphicon-asterisk"></i>프로젝트 구분</span>
                                        </label>
                                        <select class="form-control" id="selPrjetSection" name="selPrjetSection" v-model="ProjectDetail.ProjectType">
                                            <option value="">== 선택 ==</option>
                                            <option v-for="item in ProjectCategoryCodeList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                </section>
                            </fieldset>
                        </form>
                    </section>
                </div>
                <div class="modal-footer">
                    <!--버튼 영역부-->
                    <section class="btn-align">
                        <div class="btn-group">
                            <button type="button" id="btnItemSave" class="btn btn-primary" @click="saveData">
                                <i class="glyphicon glyphicon-save"></i> 저장
                            </button>
                        </div>
                        <div class="btn-group">
                            <button type="button" class="btn btn-danger" data-dismiss="modal">
                                <span class="glyphicon glyphicon-remove"></span> 취소
                            </button>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <CP1021P ref="CP1021P"></CP1021P>
        <PM1004E ref="PM1004E"></PM1004E>
        <CP1030P ref="CP1030P"></CP1030P>
        <CP1060P ref="CP1060P"></CP1060P>
        <CP1080P ref="CP1080P"></CP1080P>
    </div>
</template>

<script>
    import CP1021P from '@/components/libs/popup/CP1021P';
    import CP1030P from '@/components/libs/popup/CP1030P';
    import CP1060P from '@/components/libs/popup/CP1060P';
    import CP1080P from '@/components/libs/popup/CP1080P';
    import PM1004E from '@/components/pm/popup/PM1004E';

    import { DxDateBox, DxCalendarOptions } from 'devextreme-vue/date-box';

    export default {
        name: 'PM1008E',
        components: {
            DxDateBox, DxCalendarOptions, CP1021P, CP1030P, CP1060P, CP1080P, PM1004E
        },
        data() {
            return {
                CallbackFunction: null,
                StatusCodeList: new Array(),
                ProjectCategoryCodeList: new Array(),
                TargetYearList: new Array(),
                ProjectListAll: new Array(),
                // 상세 객체
                ProjectDetail: {
                    CompanyNm: null,
                    ProjectNm: null,
                    ContractYMD: null,
                    Price: '0',
                    VAT: null,
                    StatusName: null,
                    RegDate: null,
                    RegEmpNm: null,
                    ProjectDesc: null,
                    StatusCd: '',
                    BizNo: null,
                    BizNm: null,
                    ConStartYMD: null,
                    ConEndYMD: null,
                    ExeStartYMD: null,
                    ExeEndYMD: null,
                    CorpCd: null,
                    CompanyCd: null,
                    ProjectCd: null,
                    RotProjectNm: null,
                    RotProjectCd: null,
                    ManagerNo: null,
                    ManagerNm: null,
                    ContractCompanyCd: null,
                    ContractCompanyNm: null,
                    ProjectType: '',
                    ProjectTypeNm: null,
                    IncludeVatYn: 'N',
                    BusinessYn: 'N'
                },
                PageActionType: 'I',
            }
        },
        watch: {
            ProjectDetail: {
                deep: true,
                handler(newData) {

                    if (newData.IncludeVatYn === 'N') {

                        this.autoCalculateVat();
                    }
                }
            }
        },
        beforeMount() {

            // 공통코드 조회
            this.StatusCodeList = $.scriptBase.getCommonCode('CODE000', '0701000', '', '');
            this.ProjectCategoryCodeList = $.scriptBase.getCommonCode('CODE000', '0732000', '', '');
        },
        methods: {
            // 프로젝트 상세 조회
            open(callbackFunction) {

                this.CallbackFunction = callbackFunction;
                this.reset();
                this.ProjectDetail.Arg = 'I';
                this.ProjectDetail.UserId = this.$parent.$parent.EmpId;
                this.ProjectDetail.CorpCd = this.$parent.$parent.CompanyCode;

                $("#PM1008E_Modal").modal("show");
            },
            close() {

                $("#PM1008E_Modal").modal("hide");
            },
            reset() {

                Object.assign(this.$data, this.$options.data());
                this.StatusCodeList = $.scriptBase.getCommonCode('CODE000', '0701000', '', '');
                this.ProjectCategoryCodeList = $.scriptBase.getCommonCode('CODE000', '0732000', '', '');
            },
            // 부가세 콤보박스 변경 이벤트
            //#region VAT 포함 이벤트 핸들러 selectBoxVatChangedEvent()
            selectBoxVatChangedEvent(event) {
                // 포함
                if (event.target.value === 'N') {

                    // 부가세 자동계산
                    this.autoCalculateVat();
                    this.ProjectDetail.IncludeVatYn = 'N';
                }
                // 별도
                else if (event.target.value === 'Y') {

                    this.ProjectDetail.VAT = '0';
                    this.ProjectDetail.IncludeVatYn = 'Y';
                }
                // 영세율
                else if (event.target.value === 'Z') {

                    this.ProjectDetail.VAT = '0';
                    this.ProjectDetail.IncludeVatYn = 'Z';
                }
                // 면세
                else if (event.target.value === 'F') {

                    this.ProjectDetail.VAT = '0';
                    this.ProjectDetail.IncludeVatYn = 'F';
                }
            },
            // 부가세 자동계산
            autoCalculateVat() {

                if (this.ProjectDetail.Price != null && this.ProjectDetail.Price != '') {

                    var vatCal = Number(this.ProjectDetail.Price.toNumber()) * 0.1;
                    this.ProjectDetail.VAT = Math.round(vatCal).toPriceNumber();
                } else {

                    this.ProjectDetail.VAT = null;
                }
            },
            // 저장(수정/등록)
            saveData() {

                var parent = this;

                if (!this.ProjectDetail.CompanyNm) {

                    alert('고객사를 입력하세요.');
                    document.getElementById('txtCompanyNm2').focus();
                    return;
                }

                if (!this.ProjectDetail.ProjectNm) {

                    alert('프로젝트명을 입력하세요.');
                    document.getElementById('txtProjectNm2').focus();
                    return;
                }

                if (!this.ProjectDetail.StatusCd) {

                    alert('진행상태를 입력하세요.');
                    document.getElementById('selStatusCd2').focus();
                    return;
                }

                if (!this.ProjectDetail.ConStartYMD || !this.ProjectDetail.ConEndYMD) {

                    alert('계약일자를 선택하세요.');
                    return;
                } else {

                    // 입력된 계약일자 데이터 가공
                    this.ProjectDetail.ConStartYMD = this.moment(this.ProjectDetail.ConStartYMD).format('YYYYMMDD');
                    this.ProjectDetail.ConEndYMD = this.moment(this.ProjectDetail.ConEndYMD).format('YYYYMMDD');
                }

                if (this.ProjectDetail.ConStartYMD > this.ProjectDetail.ConEndYMD) {

                    alert('계약 시작일은 계약 종료일보다 클 수 없습니다.');
                    return;
                }

                if (this.ProjectDetail.ExeStartYMD == null || this.ProjectDetail.ExeEndYMD == null) {

                    if (confirm('수행일자가 입력되지 않았습니다. 계약일자와 동일하게 입력하시겠습니까?')) {

                        this.ProjectDetail.ExeStartYMD = this.ProjectDetail.ConStartYMD;
                        this.ProjectDetail.ExeEndYMD = this.ProjectDetail.ConEndYMD;
                    }
                    return;
                } else {

                    // 입력된 수행일자 데이터 가공
                    this.ProjectDetail.ExeStartYMD = this.moment(this.ProjectDetail.ExeStartYMD).format('YYYYMMDD');
                    this.ProjectDetail.ExeEndYMD = this.moment(this.ProjectDetail.ExeEndYMD).format('YYYYMMDD');
                }

                if (this.ProjectDetail.ExeStartYMD > this.ProjectDetail.ExeEndYMD) {

                    alert('수행 시작일은 수행 종료일보다 클 수 없습니다.');
                    return;
                }

                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/SaveNomalProject'.toRealServiceUrl();
                var obj = new Object();

                obj.jsonString = JSON.stringify(this.ProjectDetail);

                // 서비스호출 성공 콜백 함수
                var successCallback = function (data) {

                    if (data.d > 0) {

                        alert('등록되었습니다.');
                        parent.close();
                    } else {

                        alert('프로젝트 등록에 실패했습니다. 잠시 후 다시 시도해 주십시오.');
                        return;
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 업체 검색
            // targetIndex: 1: 목록 화면 조회조건, 2: 상세팝업화면 고객사 검색, 3: 상세팝업화면 도급사 검색
            searchCompany(targetIndex) {

                var parent = this;

                var successCallback = function (result) {

                    switch (targetIndex) {

                        case 1: {

                            parent.ProjectSelectTerm.AccountCompanyName = result.CompanyNm;
                            parent.ProjectSelectTerm.AccountCompanyCode = result.CompanyCd;
                            break;
                        }
                        case 2: {

                            parent.ProjectDetail.CompanyNm = result.CompanyNm;
                            parent.ProjectDetail.CompanyCd = result.CompanyCd;

                            // 상위 프로젝트 값 초기화
                            parent.ProjectDetail.RotProjectNm = null;
                            parent.ProjectDetail.RotProjectCd = null;
                            break;
                        }
                        case 3: {

                            parent.ProjectDetail.ContractCompanyNm = result.CompanyNm;
                            parent.ProjectDetail.ContractCompanyCd = result.CompanyCd;
                            break;
                        }
                        default:
                    }
                }

                this.$refs.CP1030P.open(successCallback);
            },
            // 영업 검색
            searchBusiness() {

                var parent = this;

                var successCallback = function (result) {

                    parent.ProjectDetail.BizNm = result.BizNm;
                    parent.ProjectDetail.BizNo = result.BizNo;
                }

                if (this.PageActionType === 'I') {

                    this.$refs.CP1080P.open(successCallback);
                }
            },
            // 담당자 검색
            searchManager() {

                var parent = this;

                var successCallback = function (result) {

                    parent.ProjectDetail.ManagerNm = result.EmpNm;
                    parent.ProjectDetail.ManagerNo = result.EmpNo;
                }

                this.$refs.CP1021P.open(successCallback);
            },
            // 상위 프로젝트 검색
            searchProject() {

                var parent = this;

                if (this.ProjectDetail.CompanyNm === null || this.ProjectDetail.CompanyNm === '') {

                    alert('거래처를 먼저 선택해야 합니다.');
                    return;
                }

                var successCallback = function (result) {

                    parent.ProjectDetail.RotProjectNm = result.ProjectNm;
                    parent.ProjectDetail.RotProjectCd = result.ProjectCd;
                }

                this.$refs.CP1060P.open(successCallback, this.ProjectDetail.CompanyCd, this.ProjectDetail.CompanyNm, true);
            }
        }
    }
</script>