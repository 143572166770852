﻿(function ($, undefined) {
    function scriptBase() {

        this['getUserInfo'] = function (store) {
            var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/GetAuthenticatedUserInfomation'
            url = url.toRealServiceUrl()
            var obj = new Object()
            obj.prm = new Array()
            obj.prm.push('')
            obj.prm.push('')
            obj.prm.push('0001')
            obj.prm.push('')
            obj.prm.push('')
            if (localStorage.getItem('EmailAddress') != null) {
                obj.prm.push(localStorage.getItem('EmailAddress'))
            }
            else {
                obj.prm.push('')
            }

            var successCallback = function (data) {

                var userInfo = JSON.parse(data.d).Table[0]

                // 스토어에 사용자정보 설정
                store.dispatch('SetUserInfo', userInfo)
            }

            $.scriptBase.executeAjax(url, obj, successCallback)
        }

        this['getAuthInfo'] = function (systemId, companyCode, programId, menuId1, menuId2, empNo, store) {
            var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/GetAuthOfUserId'
            url = url.toRealServiceUrl()
            var obj = new Object()
            obj.corpCd = companyCode
            obj.systId = systemId
            obj.progrId = programId
            obj.empNo = empNo
            obj.menuId1 = menuId1
            obj.menuId2 = menuId2

            var successCallback = function (data) {
                var authInfo = JSON.parse(data.d).Table[0];
                var navigatorInfo = JSON.parse(data.d).Table1[0];
                var viewInfo = JSON.parse(data.d).Table2[0];
                var isFavoriteInfo = JSON.parse(data.d).Table3[0];

                store.dispatch('SetAuthInfo', authInfo)
                store.dispatch('SetNavigator', navigatorInfo)
                store.dispatch('SetViewInfo', viewInfo)
                store.dispatch('SetIsFavorite', isFavoriteInfo)
            }

            $.scriptBase.executeAjax(url, obj, successCallback)
        }

        this['getCommonCode'] = function (arg, key1, key2, key3) {
            var result = null;
            var url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/GetCodeListForControl'
            url = url.toRealServiceUrl()
            var obj = new Object();
            obj.prm = new Array();
            obj.prm.push(arg);
            obj.prm.push(key1);
            obj.prm.push(key2);
            obj.prm.push(key3);

            var successCallback = function (data) {
                result = JSON.parse(data.d).Table;
            }

            $.scriptBase.executeAjax(url, obj, successCallback)

            return result;
        }

        this['getCorpCardList'] = function (key1) {
            var result = null;
            var url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/GetCorpCardListForBill'.toRealServiceUrl();
            var obj = new Object();
            obj.prm = new Array();
            obj.prm.push(key1);

            var successCallback = function (data) {
                //result = JSON.parse(data.d).Table;
                result = data;
            }

            $.scriptBase.executeAjax(url, obj, successCallback);

            return result;
        }

        //서비스호출(비동기)
        this['executeAjaxAsync'] = function (serviceUrl, objParameter, successCallback, isLoadingBar) {

            if (typeof isLoadingBar == "undefined") isLoadingBar = true

            var cookieValue = document.cookie.indexOf('MiniUserInfoContext')

            if (cookieValue == -1) {
                //return;
            }

            objParameter = JSON.stringify(objParameter)

            if (isLoadingBar) {

                $('#LoadingModal').modal('show')
            }

            $.ajax({
                type: "POST",
                contentType: "application/json;charset=utf-8",
                url: serviceUrl,
                data: objParameter,
                dataType: "json",
                //xhrFields: {
                //    withCredentials: true
                //},
                success: function (data) {

                    if (isLoadingBar) {

                        $("#LoadingModal").modal('hide')
                    }

                    successCallback(data)
                },
                error: function (err, status, error) {

                    if (isLoadingBar) $("#LoadingModal").modal("hide")
                    if (typeof err != "undefined") alert("네트워크 오류가 발생했습니다.")

                    //funcErrorHandler()
                }
            })
        }

        //서비스호출(동기)
        this['executeAjax'] = function (serviceUrl, objParameter, successCallback, isLoadingBar) {

            if (typeof isLoadingBar == "undefined") isLoadingBar = true

            var cookieValue = document.cookie.indexOf('MiniUserInfoContext')

            if (cookieValue == -1) {
                //return;
            }

            objParameter = JSON.stringify(objParameter)

            if (isLoadingBar) {

                $("#LoadingModal").modal('show')
            }

            $.ajax({
                type: "POST",
                contentType: "application/json;charset=utf-8",
                url: serviceUrl,
                data: objParameter,
                dataType: "json",
                async: false,
                success: function (data) {
                    
                    if (isLoadingBar) {

                        $("#LoadingModal").modal('hide')
                    }

                    successCallback(data)
                },
                error: function (err, status, error) {

                    if (isLoadingBar) $("#LoadingModal").modal("hide")
                    if (typeof err != "undefined") alert("네트워크 오류가 발생했습니다.")

                    //funcErrorHandler()
                }
            })
        }
    }

    $['scriptBase'] = new scriptBase();
}(jQuery))