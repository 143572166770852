<template>
    <!--상세 모달 팝업-->
    <div class="modal fade" id="AC1111E_Modal" ref="AC1111E_Modal" tabindex="-1" role="dialog" aria-labelledby="AC1111E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" @click.Prevent="close">
                        <span aria-hidden="true">&times;</span>
                        <span class="sr-only">Close</span>
                    </button>
                    <h3 class="modal-title">비품관리 상세</h3>
                </div>
                <div class="modal-body">
                    <section class="add-wrap">
                        <form>
                            <fieldset>
                                <legend>..</legend>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtEquipmentNo" class="control-label"><span>재물No</span></label>
                                        <input type="text" id="txtEquipmentNo" class="form-control" readonly="readonly" v-model="EquipmentDetail.EquipmentNo" />
                                    </div>
                                    <div class="cols col-2">
                                        <label for="selEquipmentDiv" class="control-label">
                                            <span><i class="glyphicon glyphicon-asterisk"></i>비품구분</span>
                                        </label>
                                        <select class="form-control" id="selEquipmentDiv" v-model="EquipmentDetail.EquipmentDivCd">
                                            <option value="">== 선택 ==</option>
                                            <option v-for="item in EquipmentDivCdList" v-bind:value="item.Code">{{item.Name}}</option>
                                        </select>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtModelNm" class="control-label">
                                            <span><i class="glyphicon glyphicon-asterisk"></i>모델명</span>
                                        </label>
                                        <input type="text" id="txtModelNm" class="form-control" v-model="EquipmentDetail.ModelNm" />
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtManufacturerNm" class="control-label"><span>제조사</span></label>
                                        <input type="text" id="txtManufacturerNm" class="form-control" v-model="EquipmentDetail.ManufacturerNm" />
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtCPU" class="control-label"><span>CPU</span></label>
                                        <input type="text" id="txtCPU" class="form-control" v-model="EquipmentDetail.CPU" />
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtRAM" class="control-label"><span>RAM</span></label>
                                        <input type="text" id="txtRAM" class="form-control" v-model="EquipmentDetail.RAM" />
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtHDD" class="control-label"><span>HDD</span></label>
                                        <input type="text" id="txtHDD" class="form-control" v-model="EquipmentDetail.HDD" />
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtSerialNo" class="control-label">
                                            <span><i class="glyphicon glyphicon-asterisk"></i>시리얼번호</span>
                                        </label>
                                        <input type="text" id="txtSerialNo" class="form-control" v-model="EquipmentDetail.SerialNo" />
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div><label></label></div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtFinalUserNm" class="control-label"><span>최종사용자</span></label>
                                        <div class="cols-btns-cont">
                                            <input type="text" id="txtFinalUserNm" class="form-control txt" disabled="disabled" v-model="EquipmentDetail.FinalUserNm" />
                                            <input type="hidden" id="hidFinalUserNo" v-model="EquipmentDetail.FinalUserNo" />
                                        </div>
                                        <div class="cols-btns">
                                            <button type="button" id="btnFinalUser" class="btn btn-default" @click="searchEmp">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i> 검색
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label class="control-label"><span>지급일자</span></label>
                                        <div class="col-1 input-group date form_date">
                                            <DxDateBox id="txtProvideDate" v-model="EquipmentDetail.ProvideDate" class="form-control txt" type="date" display-format="yyyy-MM-dd"></DxDateBox>
                                        </div>
                                    </div>
                                    <div class="cols col-2">
                                        <label class="control-label"><span>구입일자</span></label>
                                        <div class="col-1 input-group date form_date">
                                            <DxDateBox id="txtPurchaseDate" v-model="EquipmentDetail.PurchaseDate" class="form-control txt" type="date" display-format="yyyy-MM-dd"></DxDateBox>
                                        </div>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="PurchaseCostAmt" class="control-label"><span>구입금액</span></label>
                                        <input type="text" id="PurchaseCostAmt" class="form-control text-right" @keyup="inputPrice" v-model="EquipmentDetail.PurchaseCostAmt" />
                                    </div>
                                    <div class="cols col-2">
                                        <label for="selUseStateCd" class="control-label">
                                            <span><i class="glyphicon glyphicon-asterisk"></i>사용상태</span>
                                        </label>
                                        <select class="form-control" id="selUseStateCd" v-model="EquipmentDetail.UseStateCd">
                                            <option value="">== 선택 ==</option>
                                            <option v-for="item in EquipmentStateCdList" v-bind:value="item.Code">{{item.Name}}</option>
                                        </select>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-1">
                                        <label for="txtNoteCont" class="control-label"><span>기타</span></label>
                                        <input type="text" id="txtNoteCont" class="form-control" v-model="EquipmentDetail.NoteCont" />
                                    </div>
                                </section>
                            </fieldset>
                        </form>
                    </section>
                </div>
                <section class="cols-wrap">
                    <div><label></label></div>
                </section>
                <div class="modal-footer btn-align">
                    <div class="btn-group">
                        <button type="button" id="btnItemSave" class="btn btn-primary" @click.prevent="saveEquipmentDetail">
                            <i class="glyphicon glyphicon-save"></i> 저장
                        </button>
                    </div>
                    <div class="btn-group">
                        <button type="button" id="btnItemRmve" class="btn btn-warning" @click.prevent="deleteEquipment" v-show="PageActionType != 'I'">
                            <i class="glyphicon glyphicon-edit"></i> 삭제
                        </button>
                    </div>
                    <div class="btn-group">
                        <button type="button" class="btn btn-danger" data-dismiss="modal" @click.prevent="close">
                            <span class="glyphicon glyphicon-remove"></span> 취소
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <CP1020P ref="CP1020P"></CP1020P>
    </div>
</template>

<script>
    import { DxDataGrid, DxColumn, DxPaging, DxScrolling, DxColumnFixing, DxSorting, DxSelection, } from 'devextreme-vue/data-grid';
    import DxDateBox, { DxCalendarOptions } from 'devextreme-vue/date-box';
    import CP1020P from '@/components/libs/popup/CP1020P';

    export default {
        name: 'AC1111E',
        components: {
            DxDateBox, DxCalendarOptions, DxDataGrid, DxColumn, DxPaging, DxScrolling, DxColumnFixing, DxSorting, DxSelection, CP1020P,
        },
        data() {
            return {
                CallbackFunction: null,
                // 상세 입력 모드 - 신규(I)/수정(V)
                PageActionType: 'I',
                // 비품 구분 코드
                EquipmentDivCdList: new Array(),
                // 비품 사용상태 코드
                EquipmentStateCdList: new Array(),
                // 비품 상세
                EquipmentDetail: new Object(),
                // 로그인 유저 정보
                UserId: this.$store.getters.getEmpId,
            }
        },
        beforeMount() {

            this.EquipmentDivCdList = $.scriptBase.getCommonCode('CODE000', '0801000', '', '');
            this.EquipmentStateCdList = $.scriptBase.getCommonCode('CODE000', '0802000', '', '');
        },
        methods: {

            open: function (callbackFunction, selectedObj) {

                this.CallbackFunction = callbackFunction;

                this.EquipmentDetail = {
                    Arg: 'I',
                    EquipmentNo: null,
                    EquipmentDivCd: '',
                    ModelNm: '',
                    ManufacturerNm: '',
                    CPU: null,
                    RAM: null,
                    HDD: null,
                    SerialNo: '',
                    FinalUserNo: '',
                    FinalUserNm: '',
                    ProvideDate: null,
                    PurchaseDate: null,
                    PurchaseCostAmt: '',
                    UseStateCd: '',
                    EtcCont: null,
                    NoteCont: null,
                    UserId: this.UserId
                };

                if (selectedObj) {

                    this.PageActionType = 'V';
                    this.EquipmentDetail = selectedObj;
                    this.EquipmentDetail.Arg = 'I';
                    this.EquipmentDetail.UserId = this.UserId;
                    this.EquipmentDetail.PurchaseCostAmt = this.EquipmentDetail.PurchaseCostAmt.toLocaleString('ko-KR');
                } else {

                    this.PageActionType = 'I';
                }
                $("#AC1111E_Modal").modal("show");
            },
            close: function () {

                $("#AC1111E_Modal").modal("hide");
                this.EquipmentDetail = new Object();
                this.$parent.showEquipmentList();
            },
            // 사용자 검색
            searchEmp() {

                var parent = this;

                var successCallback = function (result) {

                    parent.EquipmentDetail.FinalUserNo = result.EmpNo;
                    parent.EquipmentDetail.FinalUserNm = result.EmpNm;
                }
                this.$refs.CP1020P.open(successCallback);
            },
            // 구입금액 숫자만 입력, 콤마 처리
            inputPrice(e) {

                let value = e.target._value;

                if (isNaN(e.key)) {

                    this.EquipmentDetail.PurchaseCostAmt = 0;
                } else {

                    value = Number(value.replaceAll(',', ''));
                    this.EquipmentDetail.PurchaseCostAmt = value.toLocaleString('ko-KR');
                }
            },
            // 저장 메소드
            saveEquipmentDetail() {

                var parent = this;

                if (this.EquipmentDetail.EquipmentDivCd == '') {

                    alert('비품구분을 선택해 주십시오.');
                    return;
                }
                if (this.EquipmentDetail.ModelNm == '') {

                    alert('모델명을 입력해 주십시오.');
                    return;
                }
                if (this.EquipmentDetail.SerialNo == '') {

                    alert('시리얼번호를 입력해 주십시오.');
                    return;
                }
                if (this.EquipmentDetail.UseStateCd == '') {

                    alert('사용상태를 선택해 주십시오.');
                    return;
                }

                // 날짜 형식 맞게 저장
                this.EquipmentDetail.ProvideDate = (this.EquipmentDetail.ProvideDate != null) ? this.moment(this.EquipmentDetail.ProvideDate).format('YYYYMMDD') : null;
                this.EquipmentDetail.PurchaseDate = (this.EquipmentDetail.PurchaseDate != null) ? this.moment(this.EquipmentDetail.PurchaseDate).format('YYYYMMDD') : null;
                // 구입금액 콤마 빼고 숫자만 저장
                this.EquipmentDetail.PurchaseCostAmt = Number(this.EquipmentDetail.PurchaseCostAmt.replaceAll(',', ''));

                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/ManipulateEquipment'.toRealServiceUrl();
                var obj = new Object();

                obj.jsonString = JSON.stringify(this.EquipmentDetail);

                if (confirm('비품관리 상세 내용을 저장하시겠습니까?')) {

                    var successCallback = function (data) {

                        if (data.d > 0) {

                            alert('저장되었습니다.');
                            parent.close();
                            parent.$parent.showEquipmentList();
                        } else {

                            alert('저장에 실패했습니다. 잠시 후 다시 시도해주세요.');
                        }
                    }
                    $.scriptBase.executeAjaxAsync(url, obj, successCallback);
                }
            },
            // 삭제 메소드
            deleteEquipment() {

                var parent = this;

                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/ManipulateEquipment'.toRealServiceUrl();
                var obj = new Object();
                this.EquipmentDetail.Arg = 'D';

                obj.jsonString = JSON.stringify(this.EquipmentDetail);

                if (confirm('비품관리 상세 내용을 삭제하시겠습니까?')) {

                    var successCallback = function (data) {

                        if (data.d > 0) {

                            alert('삭제되었습니다.');
                            parent.close();
                            parent.$parent.showEquipmentList();
                        } else {

                            alert('삭제에 실패했습니다. 잠시 후 다시 시도해주세요.');
                        }
                    }
                    $.scriptBase.executeAjaxAsync(url, obj, successCallback);
                }
            },
        }
    }
</script>