<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <article class="sch-wrap">
                    <form role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="selTrgtYY" class="control-label">
                                            <span>대상년도</span>
                                        </label>
                                        <select id="selTrgtYY" class="form-control"
                                                v-model="ListSelectTerm.TargetYear">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="year in TargetYearList"
                                                    v-bind:value="year"
                                                    v-bind:selected="moment(new Date()).year() === year">
                                                {{year}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="selAprvDvsCd" class="control-label">
                                            <span>프로젝트</span>
                                        </label>
                                        <div class="cols-btns-cont pad-r150">
                                            <input type="text" id="txtProjectNm" class="form-control" readonly="readonly" v-model="ListSelectTerm.ProjectName" />
                                            <input type="hidden" id="hidProjectCd" value="" v-model="ListSelectTerm.ProjectCode" />
                                            <input type="hidden" id="hidComapnyCd" value="" v-model="ListSelectTerm.ProjectCompanyCode" />
                                        </div>
                                        <div class="cols-btns">
                                            <a v-show="this.$parent.CanSearch" href="#" id="btnShowProject" class="btn btn-default"
                                               @click.prevent="searchProject(false)">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i> 검색
                                                </span>
                                            </a>
                                            <a v-show="this.$parent.CanSearch" href="#" id="btnSrchInit" class="btn btn-default"
                                               @click.prevent="function(e){
                                                    ListSelectTerm.ProjectName = null
                                                    ListSelectTerm.ProjectCompanyCode = null
                                                    ListSelectTerm.ProjectCode = null
                                               }">
                                                <span>
                                                    <i class="glyphicon glyphicon-repeat"></i> 초기화
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="selAprvDvsCd" class="control-label">
                                            <span>승인구분</span>
                                        </label>
                                        <select class="form-control" id="selAprvDvsCd" name="selAprvDvsCd"
                                                v-model="ListSelectTerm.ApprovalCategoryCode">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in AprvDvsCodeList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-4">
                                        <label for="chkMineYN" class="control-label">
                                            <span>내 승인대상</span>
                                        </label>
                                        <input type="checkbox" name="chkMineYN" v-model="ListSelectTerm.IsMyApproval" />
                                    </div>
                                    <div class="cols col-4">
                                        <label for="selCostTypeCd" class="control-label">
                                            <span>예산구분</span>
                                        </label>
                                        <select class="form-control" id="selCostTypeCd" name="selCostTypeCd"
                                                v-model="ListSelectTerm.BudgetCategoryCode">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in CostTypeCodeList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                </section>
                            </section>
                            <section class="sch-btn">
                                <a v-show="this.$parent.CanSearch" href="#" id="btnSearchData" class="btn btn-primary btn-row2"
                                   @click.prevent="getExpenseBudgetApproval">
                                    <span>
                                        <i class="glyphicon glyphicon-search"></i> 조회
                                    </span>
                                </a>
                            </section>
                        </fieldset>
                    </form>
                </article>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">프로젝트 경비예산 승인</p>
                </div>
                <div>
                    <!--리스트 영역-->
                    <section class="tbl-contents">
                        <div class="alert alert-info total">
                            <p>
                                <i class="glyphicon glyphicon-folder-close"></i>
                                전체: <span id="spTotCnt">{{ListTotalCount}}</span>건
                            </p>
                        </div>
                        <div class="table-responsive">
                            <table class="table table tbl-list tbl-center table-hover table-bordered" id="tb0">
                                <caption>테이블</caption>
                                <colgroup>
                                    <col class="table-colwid15" />
                                    <col class="table-colwid10" />
                                    <col class="table-colwid10" />
                                    <col class="table-colwid10" />
                                    <col class="table-colwid20" />
                                    <col class="table-colwid10" />
                                    <col class="table-colwid10" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col">프로젝트명</th>
                                        <th scope="col">예산구분</th>
                                        <th scope="col">계약일자</th>
                                        <th scope="col">프로젝트 기간</th>
                                        <th scope="col">담당PM</th>
                                        <th scope="col">예산총액</th>
                                        <th scope="col">승인구분</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="ProjectExpenseBudgetApprovalList === null">
                                        <td colspan="7" class="text-center no-data">조회된 정보가 없습니다.</td>
                                    </tr>
                                    <tr v-else v-for="item in ProjectExpenseBudgetApprovalList" style="cursor:pointer;"
                                        @click="getExpenseBudgetApprovalDetail(item)">
                                        <td style="text-align:left;">{{item.ProjectNm}}</td>
                                        <td>{{item.BudgetTypeNm}}</td>
                                        <td>{{item.ContractYMDReplace}}</td>
                                        <td>{{item.ProjectDT}}</td>
                                        <td>{{item.MarkPM}}</td>
                                        <td style="text-align:right;">{{item.BudgetTotAmtReplace}}</td>
                                        <td>{{item.ApprvalNm}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="text-center">
                            <ul ref="ulpager" class="pagination"></ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <div class="modal fade" ref="AC1071E_Modal" tabindex="-1" role="dialog" aria-labelledby="AC1071E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                        <h3 class="modal-title">경비예산 승인</h3>
                    </div>
                    <div class="modal-body">
                        <section class="add-wrap">
                            <form>
                                <fieldset>
                                    <legend>..</legend>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtProjectNm2" class="control-label">
                                                <span>프로젝트명</span>
                                            </label>
                                            <input type="text" id="txtProjectNm2" class="form-control txt" readonly="readonly" v-model="ProjectExpenseBudgetApprovalDetail.ProjectNm" />
                                            <input type="hidden" id="hidProjectCd2" v-model="ProjectExpenseBudgetApprovalDetail.ProjectCd" />
                                            <input type="hidden" id="hidCompanyCd2" v-model="ProjectExpenseBudgetApprovalDetail.CompanyCd" />
                                            <input type="hidden" id="hidTrgtYY" v-model="ProjectExpenseBudgetApprovalDetail.TrgtYY" />
                                            <input type="hidden" id="hidSeqNo2" v-model="ProjectExpenseBudgetApprovalDetail.SeqNo" />
                                            <input type="hidden" id="hidAppralStepType2" v-model="ProjectExpenseBudgetApprovalDetail.ApprovalStepType" />
                                        </div>
                                        <div class="cols col-2">
                                            <label for="txtCostType2" class="control-label">
                                                <span>예산구분</span>
                                            </label>
                                            <input type="text" id="txtCostType2" class="form-control text-right" readonly="readonly" v-model="ProjectExpenseBudgetApprovalDetail.BudgetTypeNm" />
                                            <input type="hidden" id="hidCostTypeCd2" class="form-control text-right" readonly="readonly" v-model="ProjectExpenseBudgetApprovalDetail.BudgetType" />
                                        </div>
                                    </section>
                                    <!--첫번째라인-->
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtTotalPrice2" class="control-label">
                                                <span>기본예산총액</span>
                                            </label>
                                            <input type="text" id="txtTotalPrice2" class="form-control text-right" readonly="readonly" v-model="ProjectExpenseBudgetApprovalDetail.BudgetTotAmt" />
                                        </div>
                                        <div class="cols col-2">
                                            <label for="txtAddTotalPrice2" class="control-label">
                                                <span>추가예산누적총액</span>
                                            </label>
                                            <input type="text" id="txtAddTotalPrice2" class="form-control text-right" readonly="readonly" v-model="ProjectExpenseBudgetApprovalDetail.AdditionalBudgetCumulativeTotalAmount" />
                                        </div>
                                    </section>
                                    <!--두번째 라인-->
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtAllTotPrice2" class="control-label">
                                                <span>누적 합산 총액</span>
                                            </label>
                                            <input type="text" id="txtAllTotPrice2" class="form-control text-right" readonly="readonly" v-model="ProjectExpenseBudgetApprovalDetail.BudgetCumulativeTotalAmount" />
                                        </div>
                                    </section>
                                    <!--세번째라인-->
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtReqDate2" class="control-label asterisk">
                                                <span>신청일</span>
                                            </label>
                                            <input type="text" id="txtReqDate2" class="form-control" readonly="readonly" v-model="ProjectExpenseBudgetApprovalDetail.RegtDate" />
                                        </div>
                                        <div class="cols col-2">
                                            <label for="txtEmpNm2" class="control-label">
                                                <span>신청자</span>
                                            </label>
                                            <input type="text" id="txtEmpNm2" readonly="readonly" class="form-control text-left" v-model="ProjectExpenseBudgetApprovalDetail.MarkPM" />
                                            <input type="hidden" id="hidEmpNo2" v-model="ProjectExpenseBudgetApprovalDetail.RegtEmpNo" />
                                            <input type="hidden" id="hidEmpEmail2" v-model="ProjectExpenseBudgetApprovalDetail.MarkPMEmail" />
                                        </div>
                                    </section>
                                    <!--네번째라인-->
                                    <section class="cols-wrap">
                                        <div class="cols">
                                            <label for="txtEtcTxt2" class="control-label">
                                                <span>특이사항</span>
                                            </label>
                                            <textarea id="txtEtc2" class="form-control" style="height:100px; resize:none" readonly="readonly" v-model="ProjectExpenseBudgetApprovalDetail.Remark"></textarea>
                                        </div>
                                    </section>
                                    <!--다섯번째라인-->
                                    <!--아코디언 접이식부-->
                                    <div class="panel-group" id="accordion">
                                        <section class="cols-wrap">
                                            <!--기본 예산 편성-->
                                            <div class="panel panel-default">
                                                <div class="panel-heading clear" style="cursor:pointer;" data-toggle="collapse" data-parent="#accordion" href="#colap_Arcodian1">
                                                    <p style="float:left;" class="col-sm-2">
                                                        <i class="glyphicon glyphicon-plus"></i>
                                                        <span>&nbsp;기본 예산 편성 </span>
                                                    </p>
                                                    <p style="text-align:right;" class="col-sm-10" id="pTotalPrice">{{ProjectExpenseBudgetApprovalDetail.BudgetTotAmt}}</p>
                                                </div>
                                                <div ref="colap_Arcodian1" id="colap_Arcodian1" class="panel-collapse collapse">
                                                    <div class="panel-body">
                                                        <section class="tbl-contents">
                                                            <div class="table-responsive">
                                                                <table class="table tbl-list table-hover table-bordered" id="tb_sub1">
                                                                    <colgroup>
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col width="25%" />
                                                                        <col class="table-colwid10" />
                                                                    </colgroup>
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" class="text-center">년/월</th>
                                                                            <th scope="col" class="text-center">투입MM</th>
                                                                            <th scope="col" class="text-center">식대</th>
                                                                            <th scope="col" class="text-center">회식비</th>
                                                                            <th scope="col" class="text-center">교통비</th>
                                                                            <th scope="col" class="text-center">숙박비</th>
                                                                            <th scope="col" class="text-center">접대비</th>
                                                                            <th scope="col" class="text-center">소모품비</th>
                                                                            <th scope="col" class="text-center">기타</th>
                                                                            <th scope="col" class="text-center">비고</th>
                                                                            <th scope="col" class="text-center">합계</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-if="BasicBudgetOrganizeList === null">
                                                                            <td colspan="11" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                                        </tr>
                                                                        <tr v-else v-for="item in BasicBudgetOrganizeList">
                                                                            <td style="text-align:center;">{{item.DateMM}}</td>
                                                                            <td style="text-align:right;">{{item.MM}}</td>
                                                                            <td style="text-align:right;">{{item.MealsRepl}}</td>
                                                                            <td style="text-align:right;">{{item.TogetherJoyRepl}}</td>
                                                                            <td style="text-align:right;">{{item.TransportationRepl}}</td>
                                                                            <td style="text-align:right;">{{item.RoomChargeRepl}}</td>
                                                                            <td style="text-align:right;">{{item.EntertainmentRepl}}</td>
                                                                            <td style="text-align:right;">{{item.SuppliesRepl}}</td>
                                                                            <td style="text-align:right;">{{item.OthersRepl}}</td>
                                                                            <td style="text-align:left;">{{item.Remark}}</td>
                                                                            <td style="text-align:right;">{{item.Sum}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--추가 예산편성-->
                                            <div v-show="ProjectExpenseBudgetApprovalDetail.BudgetType != '0115010'" class="panel panel-default">
                                                <div class="panel-heading clear" style="cursor:pointer" data-toggle="collapse" data-parent="#accordion" href="#colap_Arcodian2">
                                                    <p style="float:left;" class="col-sm-2">
                                                        <i class="glyphicon glyphicon-plus"></i>
                                                        <span>&nbsp;추가 예산 편성 </span>
                                                    </p>
                                                    <p style="text-align:right;" class="col-sm-10" id="pAddTotalPrice">{{AdditionalBudgetApplyPrice}}</p>
                                                </div>
                                                <div ref="colap_Arcodian2" id="colap_Arcodian2" class="panel-collapse collapse">
                                                    <div class="panel-body">
                                                        <section class="tbl-contents">
                                                            <div class="table-responsive">
                                                                <table class="table tbl-list table-hover table-bordered" id="tb_sub2">
                                                                    <colgroup>
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                    </colgroup>
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" class="text-center">년월</th>
                                                                            <th scope="col" class="text-center">계정</th>
                                                                            <th scope="col" class="text-center">신청금액</th>
                                                                            <th scope="col" class="text-center">신청사유</th>
                                                                            <th scope="col" class="text-center">신청일</th>
                                                                            <th scope="col" class="text-center">신청자</th>
                                                                            <th scope="col" class="text-center">승인여부</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-if="AdditionalBudgetOrganizeList === null">
                                                                            <td colspan="10" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                                        </tr>
                                                                        <tr v-else v-for="item in AdditionalBudgetOrganizeList">
                                                                            <td style="text-align:center;">{{item.DateMM}}</td>
                                                                            <td style="text-align:center;">{{item.BudgetAccntCdNm}}</td>
                                                                            <td style="text-align:right;">{{item.BudgetAmtRepl}}</td>
                                                                            <td style="text-align:left;">{{item.Remark}}</td>
                                                                            <td style="text-align:center;">{{item.RegtDateRepl}}</td>
                                                                            <td style="text-align:center;">{{item.MarkPm}}</td>
                                                                            <td style="text-align:center;">{{item.ApprovalCdNm}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </section>
                                                        <section class="cols-wrap add-wrap">
                                                            <div class="cols col-2">
                                                                <label for="txtJobDoDate2" class="control-label">
                                                                    <span>추가예산 신청금액</span>
                                                                </label>
                                                                <input type="text" id="txtAddExpense2" class="form-control text-right" readonly="readonly" v-model="AdditionalBudgetApplyPrice" />
                                                            </div>
                                                            <div class="cols col-2">
                                                                <label for="txtJobDoDate2" class="control-label">
                                                                    <span>추가예산 승인금액</span>
                                                                </label>
                                                                <input type="text" id="txtAddAccptExpense2" class="form-control text-right" readonly="readonly" v-model="AdditionalBudgetApprovalPrice" />
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--승인 여부-->
                                            <div class="panel panel-default">
                                                <div class="panel-heading clear" style="cursor:pointer" data-toggle="collapse" data-parent="#accordion" href="#colap_Arcodian3">
                                                    <p v-if="AdditionalBudgetOrganizeList === null" style="float:left;" class="col-sm-2" id="pConformNm">
                                                        <i class="glyphicon glyphicon-plus"></i>
                                                        <span>&nbsp;예산승인</span>
                                                    </p>
                                                    <p v-else style="float:left;" class="col-sm-2" id="pConformNm">
                                                        <i class="glyphicon glyphicon-plus"></i>
                                                        <span>&nbsp;추가예산승인</span>
                                                    </p>
                                                </div>
                                                <div ref="colap_Arcodian3" id="colap_Arcodian3" class="panel-collapse collapse">
                                                    <div class="panel-body">
                                                        <section v-show="ApprovalCount >= 1" class="tbl-contents">
                                                            <h5>1단계 승인</h5>
                                                            <div class="btn-wrap border-bottom"></div>
                                                            <div class="cols col-2">
                                                                <div class="cols col-2">
                                                                    <label class="control-label">
                                                                        <span>담당이사 승인</span>
                                                                    </label>
                                                                    <input type="text" readonly="readonly" class="form-control text-left" v-model="BasicBudgetApprovalDetail1.AppralStepEmpNm" />
                                                                    <input type="hidden" v-model="BasicBudgetApprovalDetail1.AppralStepEmpNo" />
                                                                </div>
                                                                <div class="cols col-2">
                                                                    <div class="cols-btns-cont">
                                                                        <select class="form-control" ref="selApprovalCategory1"
                                                                                v-model="BasicBudgetApprovalDetail1.ApprovalStepCd"
                                                                                v-show="this.$parent.CanSave">
                                                                            <option value="">== 전체 ==</option>
                                                                            <option v-for="item in AprvDvsCodeList" v-bind:value="item.Code">
                                                                                {{item.Name}}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                    <div class="cols-btns">
                                                                        <a href="#" class="btn btn-default" ref="btnApproval1"
                                                                           v-show="this.$parent.CanSave"
                                                                           @click.prevent="approvalExpenseBudget(1)">
                                                                            <span>
                                                                                <i class="glyphicon glyphicon-save"></i>승인
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div class="cols col-xs-12">
                                                                    <label class="control-label">
                                                                        <span>처리일시</span>
                                                                    </label>
                                                                    <input type="text" readonly="readonly" class="form-control text-left" v-model="BasicBudgetApprovalDetail1.ApprralStepDateRepl" />
                                                                </div>
                                                            </div>
                                                            <div class="cols col-2">
                                                                <label class="control-label">
                                                                    <span>
                                                                        <i class="glyphicon glyphicon-asterisk"></i>반려사유
                                                                    </span>
                                                                </label>
                                                                <textarea class="form-control" style="height:70px; resize:none"
                                                                          v-model="BasicBudgetApprovalDetail1.ApprovalStepRejectTxt"
                                                                          v-bind:disabled="BasicBudgetApprovalDetail1.ApprovalStepCd != '0117040'"></textarea>
                                                            </div>
                                                            <!--공백-->
                                                            <div><label></label></div>
                                                        </section>
                                                        <section v-show="ApprovalCount >= 1" class="cols-wrap">
                                                            <div><label></label></div>
                                                        </section>
                                                        <section v-show="ApprovalCount >= 2" class="tbl-contents">
                                                            <h5>2단계 승인</h5>
                                                            <div class="btn-wrap border-bottom"></div>
                                                            <div class="cols col-2">
                                                                <div class="cols col-2">
                                                                    <label class="control-label">
                                                                        <span>CFO 승인</span>
                                                                    </label>
                                                                    <input type="text" readonly="readonly" class="form-control text-left" v-model="BasicBudgetApprovalDetail2.AppralStepEmpNm" />
                                                                    <input type="hidden" v-model="BasicBudgetApprovalDetail2.AppralStepEmpNo" />
                                                                </div>
                                                                <div class="cols col-2">
                                                                    <div class="cols-btns-cont">
                                                                        <select class="form-control" ref="selApprovalCategory2"
                                                                                v-model="BasicBudgetApprovalDetail2.ApprovalStepCd"
                                                                                v-show="this.$parent.CanSave">
                                                                            <option value="">== 전체 ==</option>
                                                                            <option v-for="item in AprvDvsCodeList" v-bind:value="item.Code">
                                                                                {{item.Name}}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                    <div class="cols-btns">
                                                                        <a href="#" class="btn btn-default" ref="btnApproval2"
                                                                           v-show="this.$parent.CanSave"
                                                                           @click.prevent="approvalExpenseBudget(2)">
                                                                            <span>
                                                                                <i class="glyphicon glyphicon-save"></i>승인
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div class="cols col-xs-12">
                                                                    <label class="control-label">
                                                                        <span>처리일시</span>
                                                                    </label>
                                                                    <input type="text" readonly="readonly" class="form-control text-left" v-model="BasicBudgetApprovalDetail2.ApprralStepDateRepl" />
                                                                </div>
                                                            </div>
                                                            <div class="cols col-2">
                                                                <label class="control-label">
                                                                    <span>
                                                                        <i class="glyphicon glyphicon-asterisk"></i>반려사유
                                                                    </span>
                                                                </label>
                                                                <textarea class="form-control" style="height:70px; resize:none"
                                                                          v-model="BasicBudgetApprovalDetail2.ApprovalStepRejectTxt"
                                                                          v-bind:disabled="BasicBudgetApprovalDetail2.ApprovalStepCd != '0117040'"></textarea>
                                                            </div>
                                                            <!--공백-->
                                                            <div><label></label></div>
                                                        </section>
                                                        <section v-show="ApprovalCount >= 2" class="cols-wrap">
                                                            <div><label></label></div>
                                                        </section>
                                                        <section v-show="ApprovalCount === 3" class="tbl-contents">
                                                            <h5>3단계 승인</h5>
                                                            <div class="btn-wrap border-bottom"></div>
                                                            <div class="cols col-2">
                                                                <div class="cols col-2">
                                                                    <label class="control-label">
                                                                        <span>CEO 승인</span>
                                                                    </label>
                                                                    <input type="text" readonly="readonly" class="form-control text-left" v-model="BasicBudgetApprovalDetail3.AppralStepEmpNm" />
                                                                    <input type="hidden" v-model="BasicBudgetApprovalDetail3.AppralStepEmpNo" />
                                                                </div>
                                                                <div class="cols col-2">
                                                                    <div class="cols-btns-cont">
                                                                        <select class="form-control" ref="selApprovalCategory3"
                                                                                v-model="BasicBudgetApprovalDetail3.ApprovalStepCd"
                                                                                v-show="this.$parent.CanSave"
                                                                                @click.prevent="approvalExpenseBudget(3)">
                                                                            <option value="">== 전체 ==</option>
                                                                            <option v-for="item in AprvDvsCodeList" v-bind:value="item.Code">
                                                                                {{item.Name}}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                    <div class="cols-btns">
                                                                        <a href="#" class="btn btn-default" ref="btnApproval3"
                                                                           v-show="this.$parent.CanSave">
                                                                            <span>
                                                                                <i class="glyphicon glyphicon-save"></i>승인
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div class="cols col-xs-12">
                                                                    <label class="control-label">
                                                                        <span>처리일시</span>
                                                                    </label>
                                                                    <input type="text" readonly="readonly" class="form-control text-left" v-model="BasicBudgetApprovalDetail3.ApprralStepDateRepl" />
                                                                </div>
                                                            </div>
                                                            <div class="cols col-2">
                                                                <label class="control-label">
                                                                    <span>
                                                                        <i class="glyphicon glyphicon-asterisk"></i>반려사유
                                                                    </span>
                                                                </label>
                                                                <textarea class="form-control" style="height:70px; resize:none"
                                                                          v-model="BasicBudgetApprovalDetail3.ApprovalStepRejectTxt"
                                                                          v-bind:disabled="BasicBudgetApprovalDetail3.ApprovalStepCd != '0117040'"></textarea>
                                                            </div>
                                                            <!--공백-->
                                                            <div><label></label></div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--프로젝트 투입인력-->
                                            <div class="panel panel-default">
                                                <div class="panel-heading clear" style="cursor:pointer" data-toggle="collapse" data-parent="#accordion" href="#colap_Arcodian4">
                                                    <p style="float:left;" class="col-sm-2">
                                                        <i class="glyphicon glyphicon-plus"></i><span>&nbsp;프로젝트 투입인력</span>
                                                    </p>
                                                </div>
                                                <div ref="colap_Arcodian4" id="colap_Arcodian4" class="panel-collapse collapse">
                                                    <div class="panel-body">
                                                        <section class="tbl-contents">
                                                            <div class="table-responsive">
                                                                <table class="table tbl-list table-hover table-bordered" id="tb_sub3">
                                                                    <colgroup>
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                        <col class="table-colwid10" />
                                                                    </colgroup>
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" class="text-center">소속구분</th>
                                                                            <th scope="col" class="text-center">이름</th>
                                                                            <th scope="col" class="text-center">투입시작일</th>
                                                                            <th scope="col" class="text-center">투입종료일</th>
                                                                            <th scope="col" class="text-center">역할</th>
                                                                            <th scope="col" class="text-center">등급</th>
                                                                            <th scope="col" class="text-center">투입MM</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-if="ExpenseBudgetProjectMemberList === null">
                                                                            <td colspan="7" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                                        </tr>
                                                                        <tr v-else v-for="item in ExpenseBudgetProjectMemberList">
                                                                            <td style="text-align:center;">{{item.BelongDvsName}}</td>
                                                                            <td style="text-align:center;">{{item.Name}}</td>
                                                                            <td style="text-align:center;">{{item.WorkStartYMD}}</td>
                                                                            <td style="text-align:center;">{{item.WorkEndYMD}}</td>
                                                                            <td style="text-align:center;">{{item.RoleName}}</td>
                                                                            <td style="text-align:center;">{{item.TechClsName}}</td>
                                                                            <td style="text-align:center;">{{item.WorkMM}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </fieldset>
                            </form>
                        </section>
                    </div>
                </div>
            </div>
        </div>
        <CP1060P ref="CP1060P"></CP1060P>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle'
    import CP1060P from '@/components/libs/popup/CP1060P'
    export default {
        name: 'AC1070R',
        components: {
            ViewTitle, CP1060P
        },
        data() {
            return {
                AprvDvsCodeList: null,                  // 승인구분 공통코드 목록
                CostTypeCodeList: null,                 // 예산구분 공통코드 목록
                ProjectExpenseBudgetApprovalList: null, // 프로젝트 경비예산 승인 목록
                ExpenseBudgetProjectMemberList: null,   // 프로젝트 투입인력 목록
                BasicBudgetOrganizeList: null,          // 기본예산편성 목록
                AdditionalBudgetOrganizeList: null,     // 추가예산편성 목록
                // 경비예산 승인 목록 조회 조건
                ListSelectTerm: {
                    Arg: null,
                    UserId: this.$parent.EmpId,
                    CompanyCode: this.$parent.CompanyCode,
                    TargetYear: this.moment(new Date()).year(),
                    ProjectName: null,
                    ProjectCompanyCode: null,
                    ProjectCode: null,
                    ApprovalCategoryCode: '',
                    BudgetCategoryCode: '',
                    IsMyApproval: false,
                    TopSize: 10,
                    CurrentPage: 1
                },
                // 프로젝트 경비예산 승인 상세
                ProjectExpenseBudgetApprovalDetail: {
                    CorpCd: null,
                    CompanyCd: null,
                    CompanyNm: null,
                    ProjectCd: null,
                    ProjectNm: null,
                    ContractYMD: null,
                    ContractYMDReplace: null,
                    ProjectDT: null,
                    TrgtYY: null,
                    SeqNo: null,
                    BudgetType: null,
                    BudgetTypeNm: null,
                    BudgetTotAmt: null,
                    BudgetTotAmtReplace: null,
                    ApprovalStepType: null,
                    ApprovalCd: null,
                    ApprvalNm: null,
                    ChrgEmpNo: null,
                    Remark: null,
                    RegtDate: null,
                    RegtEmpNo: null,
                    MarkPM: null,
                    MarkPMEmail: null,
                    UpdateDate: null,
                    UpdateEmpNo: null,
                    AdditionalBudgetCumulativeTotalAmount: null,
                    BudgetCumulativeTotalAmount: null
                },
                // 기본예산 승인 상세 1단계
                BasicBudgetApprovalDetail1: {
                    Arg: 'R',
                    UserId: this.$parent.EmpId,
                    CorpCd: this.$parent.CompanyCode,
                    AppralOrderSeq: 1,
                    CompanyCd: null,
                    CompanyNm: null,
                    ProjectCd: null,
                    ProjectNm: null,
                    ContractYMD: null,
                    ContractYMDReplace: null,
                    ProjectDT: null,
                    TrgtYY: null,
                    SeqNo: null,
                    BudgetType: null,
                    BudgetTotAmt: null,
                    BudgetTotAmtReplace: null,
                    ApprovalStepType: null,
                    ApprovalCd: null,
                    ApprvalNm: null,
                    ChrgEmpNo: null,
                    ChrgEmpNm: null,
                    Remark: null,
                    RegtDate: null,
                    RegtEmpNo: null,
                    MarkPM: null,
                    BudgetTypeNm: null,
                    AppralStepEmpNo: null,
                    AppralStepEmpNm: null,
                    ApprralStepDate: null,
                    ApprralStepDateRepl: null,
                    ApprovalStepCd: null,
                    ApprovalStepNm: null,
                    ApprovalStepRejectTxt: null,
                    ApprovalSeqNo: null
                },
                // 기본예산 승인 상세 2단계
                BasicBudgetApprovalDetail2: {
                    Arg: 'M',
                    UserId: this.$parent.EmpId,
                    CorpCd: this.$parent.CompanyCode,
                    AppralOrderSeq: 2,
                    CorpCd: null,
                    CompanyCd: null,
                    CompanyNm: null,
                    ProjectCd: null,
                    ProjectNm: null,
                    ContractYMD: null,
                    ContractYMDReplace: null,
                    ProjectDT: null,
                    TrgtYY: null,
                    SeqNo: null,
                    BudgetType: null,
                    BudgetTotAmt: null,
                    BudgetTotAmtReplace: null,
                    ApprovalStepType: null,
                    ApprovalCd: null,
                    ApprvalNm: null,
                    ChrgEmpNo: null,
                    ChrgEmpNm: null,
                    Remark: null,
                    RegtDate: null,
                    RegtEmpNo: null,
                    MarkPM: null,
                    BudgetTypeNm: null,
                    AppralStepEmpNo: null,
                    AppralStepEmpNm: null,
                    ApprralStepDate: null,
                    ApprralStepDateRepl: null,
                    ApprovalStepCd: null,
                    ApprovalStepNm: null,
                    ApprovalStepRejectTxt: null,
                    ApprovalSeqNo: null
                },
                // 기본예산 승인 상세 3단계
                BasicBudgetApprovalDetail3: {
                    Arg: 'A',
                    UserId: this.$parent.EmpId,
                    CorpCd: this.$parent.CompanyCode,
                    AppralOrderSeq: 3,
                    CorpCd: null,
                    CompanyCd: null,
                    CompanyNm: null,
                    ProjectCd: null,
                    ProjectNm: null,
                    ContractYMD: null,
                    ContractYMDReplace: null,
                    ProjectDT: null,
                    TrgtYY: null,
                    SeqNo: null,
                    BudgetType: null,
                    BudgetTotAmt: null,
                    BudgetTotAmtReplace: null,
                    ApprovalStepType: null,
                    ApprovalCd: null,
                    ApprvalNm: null,
                    ChrgEmpNo: null,
                    ChrgEmpNm: null,
                    Remark: null,
                    RegtDate: null,
                    RegtEmpNo: null,
                    MarkPM: null,
                    BudgetTypeNm: null,
                    AppralStepEmpNo: null,
                    AppralStepEmpNm: null,
                    ApprralStepDate: null,
                    ApprralStepDateRepl: null,
                    ApprovalStepCd: null,
                    ApprovalStepNm: null,
                    ApprovalStepRejectTxt: null,
                    ApprovalSeqNo: null
                },
                ListTotalCount: 0,                      // 프로젝트 경비예산 승인 목록\
                AdditionalBudgetApplyPrice: 0,          // 추가예산 신청금액
                AdditionalBudgetApprovalPrice: 0,       // 추가예산 승인금액
                ApprovalCount: 0,                       // 승인 카운터
                TargetYearList: new Array(),
                PageActionType: null                    // 화면 액션값 설정 (I:신규, V: 상세보기)
            }
        },
        beforeMount() {

            // 공통코드 조회
            this.AprvDvsCodeList = $.scriptBase.getCommonCode('CODE030', '0117000', 'Result', '')
            this.CostTypeCodeList = $.scriptBase.getCommonCode('CODE000', '0115000', '', '')
            var startYear = this.moment(new Date()).add(1, 'year').year()
            var endYear = this.moment(new Date(2010, 1, 1, 0, 0, 0)).year()

            // 대상년도 목록 값 설정
            for (var i = startYear; i >= endYear; i--) {

                this.TargetYearList.push(i)
            }
        },
        mounted() {

            if (this.$parent.CanSearch) {

                this.getExpenseBudgetApproval()
            }
        },
        methods: {
            // 페이지 변경 이벤트
            // pageIndex: 선택한 페이지 번호
            pageIndexChangeEvent: function (pageIndex) {

                this.ListSelectTerm.CurrentPage = pageIndex
                this.getExpenseBudgetApproval()
            },
            // 프로젝트 검색
            // isDetail: 상세팝업에서 검색인지 여부
            searchProject: function (isDetail) {

                var parent = this
                var successCallback = function (result) {

                    if (isDetail) {

                        //parent.ExpenseBudgetApplyDetail.ProjectNm = result.ProjectNm
                        //parent.ExpenseBudgetApplyDetail.ProjectCd = result.ProjectCd
                        //parent.ExpenseBudgetApplyDetail.CompanyCd = result.CompanyCd

                        //// 대상년도 설정
                        //parent.ExpenseBudgetApplyDetail.TrgtYY = result.ContractYMD.substring(0, 4)

                        //// 해당 프로젝트 투입인력 조회
                        //parent.getExpenseBudgetProjectMember(result.CompanyCd, result.ProjectCd, false)
                    }
                    else {

                        parent.ListSelectTerm.ProjectName = result.ProjectNm
                        parent.ListSelectTerm.ProjectCode = result.ProjectCd
                        parent.ListSelectTerm.ProjectCompanyCode = result.CompanyCd
                    }
                }

                this.$refs.CP1060P.showProjectPopup(successCallback)
            },
            // 프로젝트 경비예산 승인 목록 조회
            getExpenseBudgetApproval: function () {

                var parent = this
                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/GetProjectExpenseBudgetApproval'.toRealServiceUrl()
                var obj = new Object()

                this.ListSelectTerm.Arg = 'L'

                obj.jsonString = JSON.stringify(this.ListSelectTerm)

                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d)
                    var table = jsonData.Table
                    var table1 = jsonData.Table1

                    // 목록
                    if (table.length > 0) {

                        parent.ProjectExpenseBudgetApprovalList = table
                    }
                    else {

                        parent.ProjectExpenseBudgetApprovalList = null
                    }

                    // 목록 전체 카운터
                    if (table1.length > 0) {

                        parent.ListTotalCount = table1[0].TotCnt

                        // pager 설정
                        $(parent.$refs.ulpager).pager({
                            pager: parent.$refs.ulpager,
                            totalItemCount: parent.ListTotalCount,
                            recordPerPage: parent.ListSelectTerm.TopSize,
                            currentPage: parent.ListSelectTerm.CurrentPage,
                            pageIndexChanged: parent.pageIndexChangeEvent
                        })
                    }
                    else {

                        parent.ListTotalCount = 0
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            // 프로젝트 경비예산 승인 상세 조회
            getExpenseBudgetApprovalDetail: function (targetRow) {
                
                var parent = this
                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/GetProjectExpenseBudgetApprovalDetail'.toRealServiceUrl()
                var obj = new Object()

                this.ListSelectTerm.Arg = 'DL'

                obj.jsonString = JSON.stringify({
                    'Arg': 'DL',
                    'UserId': this.$parent.EmpId,
                    'CompanyCode': this.$parent.CompanyCode,
                    'CompanyCd': targetRow.CompanyCd,
                    'ProjectCd': targetRow.ProjectCd,
                    'TrgtYY': targetRow.TrgtYY,
                    'SeqNo': targetRow.SeqNo,
                    'BudgetType': targetRow.BudgetType
                })

                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d)

                    var table = jsonData.Table      //기본 예산 정보 조회
                    var table1 = jsonData.Table1    //기본 예산 아이템 조회
                    var table2 = jsonData.Table2    //추가 예산 아이템
                    var table3 = jsonData.Table3    //현재 보이는 추가 예산 프로젝트 총합
                    var table4 = jsonData.Table4    //승인 완료된 추가 에산
                    var table5 = jsonData.Table5    //프로젝트 인력 정보
                    var table6 = jsonData.Table6    //프로젝트 validation 정보

                    // 기본정보
                    if (table.length > 0) {

                        table[0].RegtDate = table[0].RegtDate.substring(0, 10)
                        table[0].BudgetTotAmt = table[0].BudgetTotAmt.toPriceNumber()
                        parent.ProjectExpenseBudgetApprovalDetail = table[0]
                    }
                    else {

                        parent.ProjectExpenseBudgetApprovalDetail = null
                    }

                    // 기본예산편성 목록
                    if (table1.length > 0) {
                        
                        // 총합계 행 객체
                        var totalRowObject = {
                            TrgtYYMM: null,
                            DateMM: '총합',
                            MM: null,
                            Meals: null,
                            MealsRepl: null,
                            SeqMeals: null,
                            Remark: null,
                            TogetherJoy: null,
                            TogetherJoyRepl: null,
                            SeqTogetherJoy: null,
                            Transportation: null,
                            TransportationRepl: null,
                            SeqTransportation: null,
                            RoomCharge: null,
                            RoomChargeRepl: null,
                            SeqRoomCharge: null,
                            Entertainment: null,
                            EntertainmentRepl: null,
                            SeqEntertainment: null,
                            Supplies: null,
                            SuppliesRepl: null,
                            SeqSupplies: null,
                            Others: null,
                            OthersRepl: null,
                            SeqOthers: null,
                            Sum: null
                        };

                        table1.forEach(function (object) {

                            // 월별 경비 총합
                            object.Sum = (Number(object.Meals)
                                + Number(object.TogetherJoy)
                                + Number(object.Transportation)
                                + Number(object.RoomCharge)
                                + Number(object.Entertainment)
                                + Number(object.Supplies)
                                + Number(object.Others)).toPriceNumber()

                            // 투입 MM 총합
                            totalRowObject.MM = Number(totalRowObject.MM) + Number(object.MM)

                            // 식비 총합
                            totalRowObject.Meals = Number(totalRowObject.Meals) + Number(object.Meals)
                            totalRowObject.MealsRepl = totalRowObject.Meals.toPriceNumber()

                            // 회식비 총합
                            totalRowObject.TogetherJoy = Number(totalRowObject.TogetherJoy) + Number(object.TogetherJoy)
                            totalRowObject.TogetherJoyRepl = totalRowObject.TogetherJoy.toPriceNumber()

                            // 교통비 총합
                            totalRowObject.Transportation = Number(totalRowObject.Transportation) + Number(object.Transportation)
                            totalRowObject.TransportationRepl = totalRowObject.Transportation.toPriceNumber()

                            // 숙박비 총합
                            totalRowObject.RoomCharge = Number(totalRowObject.RoomCharge) + Number(object.RoomCharge)
                            totalRowObject.RoomChargeRepl = totalRowObject.RoomCharge.toPriceNumber()

                            // 접대비 총합
                            totalRowObject.Entertainment = Number(totalRowObject.Entertainment) + Number(object.Entertainment)
                            totalRowObject.EntertainmentRepl = totalRowObject.Entertainment.toPriceNumber()

                            // 소모품비 총합
                            totalRowObject.Supplies = Number(totalRowObject.Supplies) + Number(object.Supplies)
                            totalRowObject.SuppliesRepl = totalRowObject.Supplies.toPriceNumber()

                            // 기타비용 총합
                            totalRowObject.Others = Number(totalRowObject.Others) + Number(object.Others)
                            totalRowObject.OthersRepl = totalRowObject.Others.toPriceNumber()
                        })

                        // 모든 경비 총합
                        totalRowObject.Sum = (Number(totalRowObject.Meals)
                            + Number(totalRowObject.TogetherJoy)
                            + Number(totalRowObject.Transportation)
                            + Number(totalRowObject.RoomCharge)
                            + Number(totalRowObject.Entertainment)
                            + Number(totalRowObject.Supplies)
                            + Number(totalRowObject.Others)).toPriceNumber()

                        parent.BasicBudgetOrganizeList = table1

                        // 총합 행 추가
                        parent.BasicBudgetOrganizeList.push(totalRowObject)
                    }
                    else {

                        parent.BasicBudgetOrganizeList = null
                    }

                    // 추가예산편성 목록
                    if (table2.length > 0) {

                        // 다음 필드 값 추가예산 값으로 변경
                        parent.ProjectExpenseBudgetApprovalDetail.Remark = table2[0].Remark
                        parent.ProjectExpenseBudgetApprovalDetail.SeqNo = table2[0].SeqNo
                        parent.ProjectExpenseBudgetApprovalDetail.BudgetType = table2[0].BudgetType
                        parent.ProjectExpenseBudgetApprovalDetail.RegtDate = table2[0].RegtDate.substring(0, 10)
                        parent.ProjectExpenseBudgetApprovalDetail.ApprovalStepType = table2[0].ApprovalStepType

                        // 추가예산구분 값이 존재하면 예산구분 값 변경
                        parent.ProjectExpenseBudgetApprovalDetail.BudgetTypeNm = table2[0].BudgetTypeNm === null ? ProjectExpenseBudgetApprovalDetail.BudgetTypeNm : table2[0].BudgetTypeNm

                        // 추가예산 신청금액
                        parent.AdditionalBudgetApplyPrice = table2[0].BudgetTotAmt === null || table2[0].BudgetTotAmt === '' ? '0' : table2[0].BudgetTotAmt.toPriceString()

                        // 추가예산 필드 확장
                        $(parent.$refs.colap_Arcodian2).collapse('show')

                        parent.AdditionalBudgetOrganizeList = table2
                    }
                    else {

                        parent.AdditionalBudgetOrganizeList = null
                    }

                    // 추가예산누적총액
                    if (table3.length > 0) {

                        parent.ProjectExpenseBudgetApprovalDetail.AdditionalBudgetCumulativeTotalAmount = table3[0].AddExpense
                    }
                    else {

                        parent.ProjectExpenseBudgetApprovalDetail.AdditionalBudgetCumulativeTotalAmount = '0'
                    }
                    
                    var cumulativeTatalAmount = parent.ProjectExpenseBudgetApprovalDetail.AdditionalBudgetCumulativeTotalAmount === null
                        ? '0' : parent.ProjectExpenseBudgetApprovalDetail.AdditionalBudgetCumulativeTotalAmount

                    var totalAmount = parent.ProjectExpenseBudgetApprovalDetail.BudgetTotAmt
                    
                    // 누적합산 총액
                    parent.ProjectExpenseBudgetApprovalDetail.BudgetCumulativeTotalAmount = (
                        Number(cumulativeTatalAmount.toNumber()) + Number(totalAmount.toNumber())
                    ).toPriceNumber()

                    // 추가예산 승인금액
                    if (table4.length > 0) {

                        parent.AdditionalBudgetApprovalPrice = table4[0].AddExpense
                    }
                    else {

                        parent.AdditionalBudgetApprovalPrice = '0'
                    }

                    // 프로젝트 인력 목록
                    if (table5.length > 0) {

                        parent.ExpenseBudgetProjectMemberList = table5
                    }
                    else {

                        parent.ExpenseBudgetProjectMemberList = null
                    }

                    // 기본예산 승인 상세 (3단계 승인이며 아이템 갯수에 따라 상세객체 설정)
                    switch (table6.length) {

                        case 1: {

                            parent.ApprovalCount = 1
                            Object.assign(parent.BasicBudgetApprovalDetail1, table6[0])

                            if (table6[0].AppralStepEmpNo === parent.$parent.EmpId
                                && table6[0].ApprovalStepCd === '0117020') {

                                parent.$refs.selApprovalCategory1.style.display = 'block'
                                parent.$refs.btnApproval1.style.display = 'block'
                            }
                            else {

                                parent.$refs.selApprovalCategory1.style.display = 'none'
                                parent.$refs.btnApproval1.style.display = 'none'
                            }

                            break
                        }
                        case 2: {

                            parent.ApprovalCount = 2
                            Object.assign(parent.BasicBudgetApprovalDetail1, table6[0])
                            Object.assign(parent.BasicBudgetApprovalDetail2, table6[1])

                            if (table6[0].AppralStepEmpNo === parent.$parent.EmpId
                                && table6[0].ApprovalStepCd === '0117020'
                                && table6[1].ApprovalStepCd === '0117020') {

                                parent.$refs.selApprovalCategory1.style.display = 'block'
                                parent.$refs.btnApproval1.style.display = 'block'
                            }
                            else {

                                parent.$refs.selApprovalCategory1.style.display = 'none'
                                parent.$refs.btnApproval1.style.display = 'none'
                            }

                            if (table6[1].AppralStepEmpNo === parent.$parent.EmpId
                                && table6[0].ApprovalStepCd != '0117020'
                                && table6[1].ApprovalStepCd === '0117020') {

                                parent.$refs.selApprovalCategory2.style.display = 'block'
                                parent.$refs.btnApproval2.style.display = 'block'
                            }
                            else {

                                parent.$refs.selApprovalCategory2.style.display = 'none'
                                parent.$refs.btnApproval2.style.display = 'none'
                            }

                            break
                        }
                        case 3: {

                            parent.ApprovalCount = 3
                            Object.assign(parent.BasicBudgetApprovalDetail1, table6[0])
                            Object.assign(parent.BasicBudgetApprovalDetail2, table6[1])
                            Object.assign(parent.BasicBudgetApprovalDetail3, table6[2])

                            if (table6[0].AppralStepEmpNo === parent.$parent.EmpId
                                && table6[0].ApprovalStepCd === '0117020'
                                && table6[1].ApprovalStepCd === '0117020'
                                && table6[2].ApprovalStepCd === '0117020') {

                                parent.$refs.selApprovalCategory1.style.display = 'block'
                                parent.$refs.btnApproval1.style.display = 'block'
                            }
                            else {

                                parent.$refs.selApprovalCategory1.style.display = 'none'
                                parent.$refs.btnApproval1.style.display = 'none'
                            }

                            if (table6[1].AppralStepEmpNo === parent.$parent.EmpId
                                && table6[0].ApprovalStepCd != '0117020'
                                && table6[1].ApprovalStepCd === '0117020'
                                && table6[2].ApprovalStepCd === '0117020') {

                                parent.$refs.selApprovalCategory2.style.display = 'block'
                                parent.$refs.btnApproval2.style.display = 'block'
                            }
                            else {

                                parent.$refs.selApprovalCategory2.style.display = 'none'
                                parent.$refs.btnApproval2.style.display = 'none'
                            }

                            if (table6[2].AppralStepEmpNo === parent.$parent.EmpId
                                && table6[0].ApprovalStepCd != '0117020'
                                && table6[1].ApprovalStepCd != '0117020'
                                && table6[2].ApprovalStepCd === '0117020') {

                                parent.$refs.selApprovalCategory3.style.display = 'block'
                                parent.$refs.btnApproval3.style.display = 'block'
                                
                            }
                            else {

                                parent.$refs.selApprovalCategory3.style.display = 'none'
                                parent.$refs.btnApproval3.style.display = 'none'
                            }

                            break
                        }
                        default: {

                            parent.ApprovalCount = 0
                            parent.BasicBudgetApprovalDetail1 = null
                            parent.BasicBudgetApprovalDetail2 = null
                            parent.BasicBudgetApprovalDetail3 = null

                            parent.$refs.selApprovalCategory1.style.display = 'none'
                            parent.$refs.btnApproval1.style.display = 'none'
                            parent.$refs.selApprovalCategory2.style.display = 'none'
                            parent.$refs.btnApproval2.style.display = 'none'
                            parent.$refs.selApprovalCategory3.style.display = 'none'
                            parent.$refs.btnApproval3.style.display = 'none'
                        }
                    }

                    $(parent.$refs.AC1071E_Modal).modal('show')
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            // 프로젝트 경비예산 승인
            // approvalStep: 승인단계 (1: 1단계, 2: 2단계, 3: 3단계)
            approvalExpenseBudget: function (approvalStep) {

                switch (approvalStep) {

                    case 1: {

                        var approvalStepCode = this.BasicBudgetApprovalDetail1.ApprovalStepCd
                        var rejectContents = this.BasicBudgetApprovalDetail1.ApprovalStepRejectTxt

                        if (approvalStepCode != null && (approvalStepCode === '0117030' || approvalStepCode === '0117040')) {

                            if (approvalStepCode === '0117040' && (rejectContents === null || rejectContents === '')) {

                                alert('반려사유를 입력하세요.')
                            }
                            else {

                                // 승인실행
                                this.doApproval(1)
                            }
                        }
                        else {

                            alert('승인구분을 선택하세요.')
                            this.$refs.selApprovalCategory1.focus()
                        }

                        break
                    }
                    case 2: {

                        var approvalStepCode = this.BasicBudgetApprovalDetail2.ApprovalStepCd
                        var rejectContents = this.BasicBudgetApprovalDetail2.ApprovalStepRejectTxt

                        if (approvalStepCode != null && (approvalStepCode === '0117030' || approvalStepCode === '0117040')) {

                            if (approvalStepCode === '0117040' && (rejectContents === null || rejectContents === '')) {

                                alert('반려사유를 입력하세요.')
                            }
                            else {

                                // 승인실행
                                this.doApproval(2)
                            }
                        }
                        else {

                            alert('승인구분을 선택하세요.')
                            this.$refs.selApprovalCategory2.focus()
                        }

                        break
                    }
                    case 3: {

                        var approvalStepCode = this.BasicBudgetApprovalDetail3.ApprovalStepCd
                        var rejectContents = this.BasicBudgetApprovalDetail3.ApprovalStepRejectTxt

                        if (approvalStepCode != null && (approvalStepCode === '0117030' || approvalStepCode === '0117040')) {

                            if (approvalStepCode === '0117040' && (rejectContents === null || rejectContents === '')) {

                                alert('반려사유를 입력하세요.')
                            }
                            else {

                                // 승인실행
                                this.doApproval(3)
                            }
                        }
                        else {

                            alert('승인구분을 선택하세요.')
                            this.$refs.selApprovalCategory3.focus()
                        }

                        break
                    }
                    default:
                }
            },
            // 승인 실행
            // approvalStep: 승인단계 (1: 1단계, 2: 2단계, 3: 3단계)
            doApproval: function (approvalStep) {

                var parent = this
                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/ApprovalProjectExpenseBudget'.toRealServiceUrl()
                var obj = new Object()

                var successCallback = function (data) {

                    if (data.d > 0) {

                        alert('승인 되었습니다.')

                        // 상세팝업닫기
                        $(parent.$refs.AC1071E_Modal).modal('hide')

                        // 목록 제조회
                        parent.getExpenseBudgetApproval()
                    }
                }

                switch (approvalStep) {

                    case 1: {

                        obj.jsonString = JSON.stringify(this.BasicBudgetApprovalDetail1)
                        $.scriptBase.executeAjaxAsync(url, obj, successCallback)

                        break
                    }
                    case 2: {

                        obj.jsonString = JSON.stringify(this.BasicBudgetApprovalDetail2)
                        $.scriptBase.executeAjaxAsync(url, obj, successCallback)

                        break
                    }
                    case 3: {

                        obj.jsonString = JSON.stringify(this.BasicBudgetApprovalDetail3)
                        $.scriptBase.executeAjaxAsync(url, obj, successCallback)

                        break
                    }
                    default:
                }
            }
        }
    }
</script>