<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>

        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a v-if="this.$parent.CanSearch" href="#" id="btnListShow" class="btn btn-primary btn-row2" @click.prevent="fn_ShowData">
                    <span>
                        <i class="glyphicon glyphicon-search"></i>조회
                    </span>
                </a>
                <a v-if="this.$parent.CanNew" href="#" id="btnListInst" class="btn btn-default" @click.prevent="fn_InstData">
                    <span>
                        <i class="glyphicon glyphicon-plus"></i>추가
                    </span>
                </a>
                <a v-if="this.$parent.CanSave" href="#" id="btnListSave" class="btn btn-default" @click.prevent="fn_SaveData">
                    <span>
                        <i class="glyphicon glyphicon-save"></i>저장
                    </span>
                </a>
                <a v-if="this.$parent.CanDelete" href="#" id="btnListRmve" class="btn btn-default" @click.prevent="fn_RmveData">
                    <span>
                        <i class="glyphicon glyphicon-remove"></i>삭제
                    </span>
                </a>
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="sch3-1" class="control-label"><span>시스템</span></label>
                                        <select class="form-control" id="selSystId" name="selSystId">
                                            <option v-for="item in SystemIdList" v-bind:value="item.Code">{{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="sch3-1" class="control-label"><span>상위 메뉴명</span></label>
                                        <div class="col-2">
                                            <input type="text" id="txtPrntMenuName" class="form-control txt" readonly="readonly" />
                                        </div>
                                        <div class="col-2">
                                            <input type="text" id="txtPrntMenuId" class="form-control txt" readonly="readonly" />
                                            <input type="hidden" id="hidLevelNo" value="" />
                                        </div>
                                    </div>
                                </section>
                            </section>

                        </fieldset>
                        </form>
                    
                </div>

            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">메뉴 관리(폴더)</p>
                </div>
                <div>
                    <!--리스트 영역-->
                    <article class="row">
                        <div class="col-xs-12 col-sm-5 col-md-3">
                            <section class="tree-wrap">
                                <div id="treeContents10">
                                    Loading...
                                </div>
                            </section>
                        </div>
                        <div class="col-md-9 col-sm-7 col-xs-12">
                            <section class="tbl-contents">
                                <div class="table-responsive">
                                    <table class="table tbl-list table-hover table-bordered" id="tb0">
                                        <colgroup>
                                            <col class="chk" />
                                            <col />
                                            <col />
                                            <col />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th scope="col" class="text-center">
                                                    <input type="checkbox" name="cbAll" @click="checkedAllEvent($event.target.checked, 'cb0')" />
                                                </th>
                                                <th scope="col" class="text-center">메뉴 ID</th>
                                                <th scope="col" class="text-center">메뉴명</th>
                                                <th scope="col" class="text-center">순번</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="MenuList === null">
                                                <td colspan="4" class="text-center no-data">조회된 정보가 없습니다.</td>
                                            </tr>
                                            <tr v-else v-for="item in MenuList">
                                                <td class="chk">
                                                    <input type="checkbox" name="cb0" :crud="item.CRUD" />
                                                </td>
                                                <td>
                                                    <input type="text" name="txtMenuId" maxlength="10" class="form-control"
                                                           v-bind:readonly="item.CRUD === 'R'"
                                                           v-model="item.MenuId" />
                                                </td>
                                                <td>
                                                    <input type="text" name="txtMenuName" maxlength="100" class="form-control" v-model="item.MenuName" />
                                                </td>
                                                <td>
                                                    <input type="text" name="txtRowSeqNo" class="form-control" v-model="item.RowSeqNo" />
                                                </td>
                                                <td>
                                                    <input type="text" name="txtOrderSeq" class="form-control" v-model="item.OrderSeq" style="display:none" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </section>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle'
    export default {
        name: 'ZZ1020R',
        components: {
            ViewTitle
        },
        data() {
            return {
                SystemIdList: null,
                MenuList: null,
                AddMenuSeq: 0
            }
        },
        beforeMount() {

            // 공통코드 조회
            this.SystemIdList = $.scriptBase.getCommonCode('CODE090', this.$parent.CompanyCode, '', '')
        },
        mounted() {

            if (this.$parent.CanSearch) {

                this.fn_ShowData()
            }
        },
        methods: {
            checkedAllEvent: function (isChecked, targetElementName) {

                document.getElementsByName(targetElementName).forEach(function (item) {

                    item.checked = isChecked
                })

                document.getElementsByName('cbAll').forEach(function (item) {

                    item.checked = isChecked
                })
            },
            fn_ShowChld: function () {

                var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/GetMenu'
                url = url.toRealServiceUrl()
                var obj = new Object();
                obj.prm = new Array();
                obj.prm.push("V");
                obj.prm.push(this.$parent.EmpId);
                obj.prm.push(this.$parent.CompanyCode);
                obj.prm.push($("#selSystId").val());
                obj.prm.push("");
                obj.prm.push($("#txtPrntMenuId").val());
                obj.prm.push("");
                obj.prm.push("");

                var parent = this
                var successCallback = function (data) {

                    var table = JSON.parse(data.d).Table;

                    if (table.length > 0) {

                        parent.MenuList = table
                    }
                    else {

                        parent.MenuList = null
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            fn_ChldTree: function (pid, $dt) {
                var parent = this
                var html = "";
                var child = $.grep($dt, function (o, i) { return (o.PrntMenuId == pid); });

                if (child.length > 0) {
                    html += "<ul>";
                    $.each(child, function (i, o) {
                        if (o.ChildreYn == "Y") {
                            html += "<li menuid=\"" + o.MenuId + "\" levelno=\"" + o.LevelNo + "\">" + o.MenuName;
                            html += parent.fn_ChldTree(o.MenuId, $dt);
                            html += "</li>";
                        } else {
                            html += "<li menuid=\"" + o.MenuId + "\" levelno=\"" + o.LevelNo + "\">" + o.MenuName + "</li>";
                        }
                    });

                    html += "</ul>";
                }

                return html;
            },
            fn_ShowData: function () {

                var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/GetMenu'
                url = url.toRealServiceUrl()
                var obj = new Object();
                obj.prm = new Array();
                obj.prm.push("T");
                obj.prm.push(this.$parent.EmpId);
                obj.prm.push(this.$parent.CompanyCode);
                obj.prm.push($("#selSystId").val());

                for (var i = 0; i < 4; i++) obj.prm.push("");

                var parent = this
                var successCallback = function (data) {

                    var table = JSON.parse(data.d).Table;
                    var table1 = JSON.parse(data.d).Table1;
                    var table2 = JSON.parse(data.d).Table2;

                    if (table.length > 0 && table1.length > 0) {

                        var html = "";

                        $.each(table, function (i, o) {

                            if (o.ChildreYn == "Y") {

                                html += "<li menuid=\"" + o.MenuId + "\" levelno=\"" + o.LevelNo + "\" data-jstree='{\"opened\":true }'>" + o.MenuName;

                                var child = $.grep(table1, function (oo, ii) {

                                    return (oo.PrntMenuId == o.MenuId);
                                });

                                if (child.length > 0) {

                                    html += "<ul>";
                                    $.each(child, function (ii, oo) {
                                        var seloption = "";
                                        if (ii == 0) {
                                            $("#txtPrntMenuName").val(oo.MenuName);
                                            $("#txtPrntMenuId").val(oo.MenuId);
                                            $("#hidLevelNo").val(oo.LevelNo);
                                            seloption = "data-jstree='{\"selected\":true, \"opened\":true }' ";
                                            parent.fn_ShowChld();
                                        } else {
                                            seloption = "";
                                        }

                                        if (oo.ChildreYn == "Y") {
                                            html += "<li menuid=\"" + oo.MenuId + "\" levelno=\"" + oo.LevelNo + "\" " + seloption + ">" + oo.MenuName;
                                            html += parent.fn_ChldTree(oo.MenuId, table2);
                                            html += "</li>";
                                        } else {
                                            html += "<li menuid=\"" + oo.MenuId + "\" levelno=\"" + oo.LevelNo + "\" " + seloption + ">" + oo.MenuName + "</li>";
                                        }
                                    });
                                    html += "</ul>";
                                }
                                html += "</li>";

                            } else {
                                html += "<li menuid=\"" + o.MenuId + "\" levelno=\"" + o.LevelNo + "\" data-jstree='{\"opened\":true }'>" + o.MenuName + "</li>";
                            }
                        });

                        $("#treeContents10").empty().append("<ul>" + html + "</ul>").jstree("destroy").jstree().unbind("select_node.jstree").bind("select_node.jstree", function (evt, data) {
                            $("#txtPrntMenuName").val(data.node.text);
                            $("#txtPrntMenuId").val(data.node.li_attr.menuid);
                            $("#hidLevelNo").val(data.node.li_attr.levelno);
                            parent.fn_ShowChld();
                        });

                        parent.checkedAllEvent(false, 'cb0')
                    }
                };

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            fn_InstData: function () {

                var obj = {
                    OrderSeq: this.AddMenuSeq + 1
                    , CorpCd: null
                    , SystId: null
                    , MenuId: null
                    , MenuName: null
                    , PrntMenuId: null
                    , MenuAction: null
                    , Icon: null
                    , LevelNo: null
                    , RowSeqNo: null
                    , CRUD: 'C'
                }

                if (this.MenuList === null) {

                    this.MenuList = new Array()
                }

                this.MenuList.push(obj)
                this.AddMenuSeq = this.AddMenuSeq + 1
            },
            fn_SaveData: function () {

                var parent = this
                var targetElement = document.querySelectorAll('#tb0 tbody tr')

                if (targetElement.length === 0) {

                    alert("저장항목이 없습니다.")
                    return
                }

                for (var i = 0; i < targetElement.length; i++) {

                    var menuIdElement = targetElement[i].querySelector('input[type=text][name=txtMenuId]')
                    var menuNameElement = targetElement[i].querySelector('input[type=text][name=txtMenuName]')
                    var rowSeqNoElement = targetElement[i].querySelector('input[type=text][name=txtRowSeqNo]')

                    if (menuIdElement.value === '') {

                        alert('메뉴 ID를 입력하세요.')
                        menuIdElement.focus()

                        return;
                    }

                    if (menuNameElement.value === '') {

                        alert('메뉴명을 입력하세요.')
                        menuNameElement.focus()

                        return;
                    }

                    if (rowSeqNoElement.value === '') {

                        alert('순번을 입력하세요.')
                        rowSeqNoElement.focus()

                        return;
                    }

                    if (isNaN(rowSeqNoElement.value)) {

                        alert('숫자만 입력하세요.')
                        rowSeqNoElement.focus()

                        return;
                    }
                }

                var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/ManipulateMenu'
                url = url.toRealServiceUrl()
                var obj = new Object();
                obj.prm = new Array();

                targetElement.forEach(function (target) {

                    var tempArray = new Array();
                    tempArray.push("I");
                    tempArray.push(parent.$parent.EmpId);
                    tempArray.push(parent.$parent.CompanyCode);
                    tempArray.push($("#selSystId").val());
                    tempArray.push(target.querySelector('input[type=text][name=txtMenuId]').value)
                    tempArray.push(target.querySelector('input[type=text][name=txtMenuName]').value)
                    tempArray.push($("#txtPrntMenuId").val());
                    tempArray.push("");
                    tempArray.push("");
                    tempArray.push(parseInt($("#hidLevelNo").val()) + 1);
                    tempArray.push(target.querySelector('input[type=text][name=txtRowSeqNo]').value);
                    obj.prm.push(tempArray);
                })

                var successCallback = function (data) {

                    if (data.d > 0) {

                        parent.MenuList.forEach(function (item) {

                            item.CRUD = 'R'
                        })

                        parent.AddMenuSeq = 0
                    }
                }
                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            fn_RmveData: function () {

                var parent = this
                var removeTargetElement = document.querySelectorAll('input[type=checkbox][name=cb0]:checked')
                if (removeTargetElement.length == 0) {

                    alert("삭제 할 항목을 선택하세요.")
                    return
                }

                if (confirm("삭제하시겠습니까?")) {

                    removeTargetElement.forEach(function (target) {

                        var targetCRUD = target.getAttribute('crud')
                        var targetOrderSeq = target.parentNode.parentNode.querySelector('input[type=text][name=txtOrderSeq]').value
                        var targetMenuId = target.parentNode.parentNode.querySelector('input[type=text][name=txtMenuId]').value
                        
                        if (targetCRUD == 'R') {

                            var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/ManipulateMenu'
                            url = url.toRealServiceUrl()
                            var obj = new Object();
                            obj.prm = new Array();

                            var tempArray = new Array();
                            tempArray.push("D");
                            tempArray.push(parent.$parent.EmpId);
                            tempArray.push(parent.$parent.CompanyCode);
                            tempArray.push($("#selSystId").val());
                            tempArray.push(targetMenuId);
                            for (var i = 0; i < 6; i++) tempArray.push("");
                            obj.prm.push(tempArray);

                            var successCallback = function (data) {

                                if (data.d > 0) {

                                    // 삭제할 아이템의 인덱스 검색
                                    var removeItemIndex = parent.MenuList.findIndex(function (item) {

                                        return item.MenuId === targetMenuId
                                    })

                                    // 해당 인덱스 삭제
                                    if (removeItemIndex > -1) {

                                        parent.MenuList.splice(removeItemIndex, 1)
                                    }
                                }
                            }

                            $.scriptBase.executeAjaxAsync(url, obj, successCallback);
                        }
                        else {

                            // 삭제할 아이템의 인덱스 검색
                            var removeItemIndex = parent.MenuList.findIndex(function (item) {

                                return item.CRUD === 'C' && item.OrderSeq == targetOrderSeq
                            })

                            // 해당 인덱스 삭제
                            if (removeItemIndex > -1) {

                                parent.MenuList.splice(removeItemIndex, 1)
                            }
                        }
                    })

                    this.checkedAllEvent(false, 'cb0')
                }
            }
        }
    }
</script>

<style scoped>
</style>