<template>
    <div class="modal fade" ref="CM1022E_Modal" tabindex="-1" role="dialog" aria-labelledby="CM1022E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" @click.Prevent="close">
                        <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                    </button>
                    <h3 class="modal-title">기업정보 검색</h3>
                </div>
                <div class="modal-body">
                    <section class="sch-wrap">
                        <form role="form">
                            <fieldset>
                                <legend>검색폼</legend>
                                <section class="sch-cont">
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtSearchCorp" class="control-label"><span>기업명</span></label>
                                            <input type="text" class="form-control txt" id="txtSearchCorp" v-model="corpNm" @keyup="keyUpEvent" />
                                        </div>
                                        <div class="cols col-2">
                                            <label for="txtSearchCorp2" class="control-label"><span>법인번호</span></label>
                                            <input type="text" class="form-control txt" id="txtSearchCorp2" v-model="crno" @keyup="keyUpEvent" />
                                        </div>
                                    </section>
                                </section>
                                <section class="sch-btn">
                                    <a id="btnSearch" class="btn btn-primary" @click="getCompanyInfo"><span><i class="glyphicon glyphicon-search"></i> 검색</span></a>
                                </section>
                            </fieldset>
                        </form>
                    </section>
                    <section class="tbl-contents">
                        <div class="total alert" style="margin-bottom: 0;">
                            <p><i class="glyphicon glyphicon-folder-close"></i><span>전체: <span>{{ CorpDataTotalCnt }}</span>건</span></p>
                        </div>
                        <div class="table-responsive">
                            <DxDataGrid id="grid-container"
                                        :data-source="CorpDataList"
                                        :show-borders="false"
                                        :hoverStateEnabled="true"
                                        :row-alternation-enabled="true"
                                        @cell-prepared="onCellPrepared"
                                        @row-click="rowClickEvent"
                                        style="max-height: 400px; vertical-align: top;">
                                <DxScrolling mode="standard" useNative="false" />
                                <DxPaging :enabled="false" />
                                <DxColumn data-field="corpNm" caption="법인명" alignment="center" />
                                <DxColumn data-field="enpRprFnm" caption="대표자성명" alignment="center" />
                                <DxColumn data-field="bzno" caption="사업자등록번호" alignment="center" />
                            </DxDataGrid>
                        </div>
                    </section>
                </div>
                <div class="modal-footer btn-align">
                    <div class="btn-group">
                        <button type="button" class="btn btn-danger" @click.prevent="close">
                            <span class="glyphicon glyphicon-remove"></span>취소
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { DxDataGrid, DxColumn, DxPaging, DxScrolling, } from 'devextreme-vue/data-grid';
    export default {
        name: 'CM1022E',
        components: { DxDataGrid, DxColumn, DxPaging, DxScrolling, },
        data() {
            return {
                CallbackFunction: null,
                // 기업정보 조회 조건
                // 기업명
                corpNm: '',
                // 법인번호
                crno: '',
                // 한번에 받을 수 있는 데이터의 수
                numOfRows: 9999,
                pageNo: 1,
                resultType: 'json',
                serviceKey: 'Pz5yYs9Svd4weeZyBLJDXsrYn14QKIQDSX41c5V3wH5Chiiy3KKdIQinf0jy2RYzsN5tNwaLtE26GSverTHIhw%3D%3D',
                // 조회된 기업 목록
                CorpDataList: new Array(),
                // 조회된 전체 기업 수
                CorpDataTotalCnt: 0,
            }
        },
        methods: {
            open(callbackFunction, crno) {

                this.CallbackFunction = callbackFunction;
                this.crno = (crno) ? crno.replace('-', '') : '';

                $(this.$refs.CM1022E_Modal).modal('show');
            },
            close() {

                this.corpNm = '';
                this.crno = '';
                this.pageNo = 1;
                this.CorpDataList = new Array();
                this.CorpDataTotalCnt = 0;

                $(this.$refs.CM1022E_Modal).modal('hide');
            },
            // DataGrid Header 가운데 정렬, Column Data 중앙 정렬
            onCellPrepared(e) {

                if (e.rowType == 'header') {
                    e.cellElement.style['textAlign'] = 'center';
                }
                if (e.rowType == 'data') {
                    e.cellElement.style['verticalAlign'] = 'middle';
                }
            },
            keyUpEvent(e) {

                if (e.key == 'Enter') {
                
                    this.getCompanyInfo();
                }
            },
            // 기업정보 조회
            getCompanyInfo() {

                let parent = this;
                this.CorpDataList = new Array();
                this.CorpDataTotalCnt = 0;

                // 필수 파라미터로 세팅된 URL
                let urlStr = `https://apis.data.go.kr/1160100/service/GetCorpBasicInfoService_V2/getCorpOutline_V2?ServiceKey=${parent.serviceKey}&pageNo=${parent.pageNo}&numOfRows=${parent.numOfRows}&resultType=${parent.resultType}`;
                let url = new URL(urlStr);
                let urlParams = url.searchParams;

                this.corpNm = (this.corpNm.length > 0) ? this.corpNm.trim() : '';
                this.crno = (this.crno.length > 0) ? this.crno.trim() : '';

                // 법인번호 미입력 시 기업명 필수 입력
                if (this.crno == null || this.crno == '') {

                    if (this.corpNm == '' || this.corpNm == null) {

                        alert('기업명을 입력해 주십시오.');
                        return;
                    }
                    if (this.corpNm.length < 2) {

                        alert('기업명을 정확히 입력해 주십시오.');
                        return;
                    }

                    // 기업명 URL에 추가
                    urlParams.append('corpNm', this.corpNm);
                } else {

                    // 기업명 입력 시 URL에 추가
                    if (this.corpNm != '' || this.corpNm != null) {

                        urlParams.append('corpNm', this.corpNm);
                    }

                    // 법인번호 입력 시 URL에 추가
                    urlParams.append('crno', this.crno);
                }

                // 기업정보 조회 API 호출 function()
                async function getData() {

                    $("#LoadingModal").modal('show');

                    const response = await fetch(url);
                    const data = await response.json();

                    let errorCode = data.response.header.resultCode;
                    let errorMessage = data.response.header.resultMsg;

                    // 정상 응답이 아닐 때
                    if (errorCode != '00') {

                        console.log({ 'errorCode': errorCode, 'errorMessage': errorMessage, 'url': 'https://www.data.go.kr/data/15043184/openapi.do#/API%20%EB%AA%A9%EB%A1%9D/getCorpOutline_V2' });
                        alert('일시적인 오류로 데이터를 조회할 수 없습니다. 관리자에게 문의하십시오.');
                        return;
                    }

                    parent.setCompanyInfo(data);
                }

                // 실제 호출
                getData();
            },
            // 응답받은 기업정보로 그리드 세팅
            setCompanyInfo(data) {

                $("#LoadingModal").modal('hide');

                let resultCount = data.response.body.totalCount;

                if (resultCount == 0) {

                    alert('검색된 기업정보가 없습니다.');
                    return;
                }

                // 같은 업체의 데이터가 여러 건 응답되는 경우가 있어, 그 중 가장 최신 데이터를 추려야 함
                // API 응답 데이터
                let corpInfoList = data.response.body.items.item;

                // 사업자번호 기준으로 가장 최신 데이터 그룹화
                const groupedData = corpInfoList.reduce((acc, obj) => {

                    if (!acc[obj.bzno] || acc[obj.bzno].fstOpegDt < obj.fstOpegDt) {

                        acc[obj.bzno] = obj;
                    }

                    return acc;
                }, {});

                // 생성한 객체를 배열로 변환, 그리드 세팅
                this.CorpDataList = Object.values(groupedData);
                this.CorpDataTotalCnt = (this.CorpDataList.length > 0) ? this.CorpDataList.length : 0;
            },
            // 테이블 Row 클릭 이벤트 핸들러
            rowClickEvent(e) {

                let corpData = e.data;

                // 호출한 뷰로 선택한 기업정보 데이터 전달
                this.CallbackFunction(corpData);
                this.close();
            },
        }
    }
</script>