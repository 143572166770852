<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <!--프로젝트유지보수관리-->
        <div class="content-box">
            <div class="sch-box">
                <!--조건 선언부-->
                <article class="sch-wrap">
                    <form role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="selAprvDvsCd" class="control-label">
                                            <span>프로젝트</span>
                                        </label>
                                        <div class="cols-btns-cont pad-r150">
                                            <input type="text" id="txtProjectNm" class="form-control" readonly="readonly" v-model="MaintenanceSelectTerm.ProjectName" />
                                            <input type="hidden" id="hidProjectCd" v-model="MaintenanceSelectTerm.ProjectCode" />
                                            <input type="hidden" id="hidCompanyCd" v-model="MaintenanceSelectTerm.CompanyCode" />
                                        </div>
                                        <div class="cols-btns">
                                            <a href="#" id="btnShowProject" class="btn btn-default" @click.prevent="searchProjectCode(1)">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i> 검색
                                                </span>
                                            </a>
                                            <a href="#" id="btnSrchInit" class="btn btn-default"
                                               @click.prevent="function(e){
                                                    MaintenanceSelectTerm.ProjectName = ''
                                                    MaintenanceSelectTerm.ProjectCode = ''
                                                    MaintenanceSelectTerm.CompanyCode = ''
                                               }">
                                                <span>
                                                    <i class="glyphicon glyphicon-repeat"></i> 초기화
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="selDateType" class="control-label"><span>요청일</span></label>
                                        <div class="col-xs-12">
                                            <div class="input-group date form_date" v-bind:data-date="CurrentDateYYMMDD" data-date-format="yyyy-mm-dd">
                                                <input type="text" id="_txtStrDate" class="form-control" maxlength="10"
                                                       v-bind:value="MaintenanceSelectTerm.StrDate"
                                                       @input="function(e){
                                                                MaintenanceSelectTerm.StrDate = e.target.value.toHyphenationFormatYMD()
                                                                e.target.value = e.target.value.toHyphenationFormatYMD()
                                                       }" />
                                                <span class="input-group-addon">
                                                    <span class="glyphicon glyphicon-calendar"></span>
                                                </span>
                                            </div>
                                            <span class="tail" id="dayDiv_"><span>~</span></span>
                                            <div class="input-group date form_date" v-bind:data-date="CurrentDateYYMMDD" data-date-format="yyyy-mm-dd">
                                                <input type="text" id="_txtEndDate" class="form-control" maxlength="10"
                                                       v-bind:value="MaintenanceSelectTerm.EndDate"
                                                       @input="function(e){
                                                                MaintenanceSelectTerm.EndDate = $event.target.value.toHyphenationFormatYMD()
                                                                $event.target.value = $event.target.value.toHyphenationFormatYMD()
                                                       }" />
                                                <span class="input-group-addon">
                                                    <span class="glyphicon glyphicon-calendar"></span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="selAprvDvsCd" class="control-label">
                                            <span>서비스항목</span>
                                        </label>
                                        <select class="form-control" id="selServiceCd" name="selServiceCd"
                                                v-model="MaintenanceSelectTerm.ServiceCd">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in ServiceList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="cols col-2">
                                        <label for="selAprvDvsCd" class="control-label">
                                            <span>처리결과</span>
                                        </label>
                                        <select class="form-control" id="selCompleted" name="selCompleted"
                                                v-model="MaintenanceSelectTerm.Completed">
                                            <option v-for="item in ProgressList" v-bind:value="item.Code">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                </section>
                            </section>
                            <section class="sch-btn">
                                <a v-show="this.$parent.CanSearch" href="#" id="btnSearchData" class="btn btn-primary btn-row2" @click.prevent="btnSearchClicked">
                                    <span>
                                        <i class="glyphicon glyphicon-search"></i> 조회
                                    </span>
                                </a>
                            </section>
                        </fieldset>
                    </form>
                </article>
                <!--공통 버튼 영역-->
                <article class="view-btns-wrap ">
                    <div class="btn-group-justified">
                        <a v-show="this.$parent.CanNew" href="#" id="btnInstData" class="btn btn-default" @click.prevent="btnAddClicked">
                            <span>
                                <i class="glyphicon glyphicon-plus"></i>신청
                            </span>
                        </a>
                    </div>
                </article>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">프로젝트 유지보수 관리</p>
                </div>
                <div>
                    <!--리스트 영역-->
                    <section class="tbl-contents">
                        <div class="alert alert-info total">
                            <p>
                                <i class="glyphicon glyphicon-folder-close"></i><span>전체: <span id="spTotCnt">{{TotalCount}}</span>건</span>
                            </p>
                        </div>
                        <div class="table-responsive">
                            <table class="table table tbl-list tbl-center table-hover table-bordered" id="tb0">
                                <caption>테이블</caption>
                                <colgroup>
                                    <col class="table-colwid15" />
                                    <col class="table-colwid10" />
                                    <col class="table-colwid10" />
                                    <col class="table-colwid10" />
                                    <col class="table-colwid20" />
                                    <col class="table-colwid10" />
                                    <col class="table-colwid10" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col" width="20%">프로젝트</th>
                                        <th scope="col" width="8%">서비스항목</th>
                                        <th scope="col" width="8%">요청일</th>
                                        <th scope="col" width="45%">요청내용</th>
                                        <th scope="col" width="5%">요청자</th>
                                        <th scope="col" width="5%">지원자</th>
                                        <th scope="col" width="5%">처리결과</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="MaintenanceList === null">
                                        <td colspan="7" class="text-center no-data">조회된 정보가 없습니다.</td>
                                    </tr>
                                    <tr v-else v-for="item in MaintenanceList" style="cursor:pointer;" v-on:click="rowClickEvent(item)">
                                        <td>{{item.ProjectNm}}</td>
                                        <td>{{item.ServiceNm}}</td>
                                        <td>{{item.ReqDate}}</td>
                                        <td style="text-align:left">{{item.ReqContent}}</td>
                                        <td>{{item.ReqName}}</td>
                                        <td>{{item.SupportName}}</td>
                                        <td>{{item.CompletedNm}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="text-center">
                            <ul id="ulpager" class="pagination"></ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <!--상세 화면-->
        <div class="modal fade" ref="PM1051E_Modal" tabindex="-1" role="dialog" aria-labelledby="PM1051E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">
                            <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                        </button>
                        <h3 class="modal-title">유지보수 내용</h3>
                    </div>
                    <div class="modal-body">
                        <section class="add-wrap">
                            <form>
                                <fieldset>
                                    <legend></legend>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtProjectNm2" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>프로젝트
                                                </span>
                                            </label>
                                            <section class="add-cont">
                                                <div class="cols-btns-cont">
                                                    <input type="text" id="txtProjectNm2" ref="txtProjectNm2" class="form-control txt" readonly="readonly" v-model="MaintenanceDetail.ProjectNm" />
                                                    <input type="hidden" id="hidProjectCd2" v-model="MaintenanceDetail.ProjectCd" />
                                                    <input type="hidden" id="hidCompanyCd2" v-model="MaintenanceDetail.CompanyCd" />
                                                    <input type="hidden" id="TrgtYYMM" v-model="MaintenanceDetail.TrgtYYMM" />
                                                    <input type="hidden" id="SeqNo" v-model="MaintenanceDetail.SeqNo" />
                                                </div>
                                                <div class="cols-btns">
                                                    <a href="#" id="btnItemPop2" class="btn btn-default" @click.prevent="searchProjectCode(2)">
                                                        <span>
                                                            <i class="glyphicon glyphicon-search"></i> 검색
                                                        </span>
                                                    </a>
                                                </div>
                                            </section>
                                        </div>
                                        <div class="cols col-2">
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="selAprvDvsCd" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>서비스항목
                                                </span>
                                            </label>
                                            <select class="form-control" id="ServicdAnctCd" ref="ServicdAnctCd" name="ServicdAnctCd"
                                                    v-model="MaintenanceDetail.ServiceAcnt">
                                                <option value="">== 전체 ==</option>
                                                <option v-for="item in ServicdAnctCodeList" v-bind:value="item.Code">
                                                    {{item.Name}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="txtUnitCnt2" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>요청일시
                                                </span>
                                            </label>
                                            <div class="input-group date form_date" v-bind:data-date="CurrentDateYYMMDD" data-date-format="yyyy-mm-dd">
                                                <input type="text" id="txtReqStr_1" ref="txtReqStr_1" class="form-control" maxlength="10"
                                                       v-bind:value="MaintenanceDetail.ReqStr_1"
                                                       @input="function(e){
                                                                MaintenanceDetail.ReqStr_1 = e.target.value.toHyphenationFormatYMD()
                                                                e.target.value = e.target.value.toHyphenationFormatYMD()
                                                       }" />
                                                <span class="input-group-addon">
                                                    <span class="glyphicon glyphicon-calendar"></span>
                                                </span>
                                            </div>
                                            <div class="cols col-2">
                                                <input type="text" id="txtReqStr_2" ref="txtReqStr_2" class="form-control" value="" placeholder="hh:mm" maxlength="5" v-bind:value="MaintenanceDetail.ReqStr_2" 
                                                       @input="function(e){
                                                                MaintenanceDetail.ReqStr_2 = e.target.value.toColonFormatHHMM()
                                                                e.target.value = e.target.value.toColonFormatHHMM()
                                                       }"/>
                                            </div>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtSalePrice2" class="control-label">
                                                <span>요청자 소속</span>
                                            </label>
                                            <input type="text" id="txtReqParty" class="form-control" v-model="MaintenanceDetail.ReqParty"/>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="txtSalePriceSum2" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>요청자 이름
                                                </span>
                                            </label>
                                            <input type="text" id="txtReqName" ref="txtReqName" class="form-control" v-model="MaintenanceDetail.ReqName"/>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols">
                                            <label for="txtContents" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>요청 내용
                                                </span>
                                            </label>
                                            <textarea id="txtcontent" ref="txtcontent" class="form-control" form-groups="10" style="resize:none; height:80px;" v-model="MaintenanceDetail.ReqContent"></textarea>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div><label></label></div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols">
                                            <label for="txtContents" class="control-label">
                                                <span>요청자 첨부</span>
                                            </label>
                                            <div class="btn-wrap btn-align">
                                                <div style="margin: 5px 0px 3px 0px" class="fr">
                                                    <a href="#" id="btnChldInst1" class="btn btn-default" title="추가" @click.prevent="addRequestAttachFile">
                                                        <span class="glyphicon glyphicon-plus"></span> 추가
                                                    </a>
                                                    <a href="#" id="btnChldRmve1" class="btn btn-default" title="삭제" @click.prevent="removeAttachFile(1)">
                                                        <span class="glyphicon glyphicon-minus"></span> 삭제
                                                    </a>
                                                </div>
                                            </div>
                                            <section class="tbl-contents">
                                                <div class="table-responsive">
                                                    <table class="table tbl-list table-hover table-bordered" id="tb1">
                                                        <colgroup>
                                                            <col class="chk" />
                                                            <col />
                                                        </colgroup>
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" class="text-center"></th>
                                                                <th scope="col" class="text-center">첨부파일</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-if="MaintenanceRequestFileList === null">
                                                                <td colspan="2" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                            </tr>
                                                            <tr v-else v-for="maintenanceRequestFile in MaintenanceRequestFileList" v-show="maintenanceRequestFile.IsShow">
                                                                <td class="chk">
                                                                    <label>
                                                                        <input v-model="maintenanceRequestFile.IsChecked" type="checkbox" name="cbItem2"
                                                                               @click="function(e){
                                                                                    maintenanceRequestFile.IsRemove = e.target.checked
                                                                               }"/>
                                                                    </label>
                                                                </td>
                                                                <td v-show="maintenanceRequestFile.IsNew" class="text-center">
                                                                    <input type="file" name="ctlAttachFile"
                                                                           @change="function(e){
                                                                                maintenanceRequestFile.Files = e.target.files
                                                                           }" />
                                                                </td>
                                                                <td v-show="!maintenanceRequestFile.IsNew" class="text-left">
                                                                    <a href="#" @click.prevent="fileHandler.download(maintenanceRequestFile.GUID)">{{maintenanceRequestFile.FileName}}</a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </section>

                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="selAprvDvsCd" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>지원매체
                                                </span>
                                            </label>
                                            <select class="form-control" id="SupprotDvsCd" ref="SupprotDvsCd" name="SupprotDvsCd"
                                                    v-model="MaintenanceDetail.SupportCd">
                                                <option value="">== 전체 ==</option>
                                                <option v-for="item in SupprotDvsCdList" v-bind:value="item.Code">
                                                    {{item.Name}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="cols col-2">
                                            <label for="txtUnitCnt2" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>지원일시
                                                </span>
                                            </label>
                                            <div class="input-group date form_date" v-bind:data-date="CurrentDateYYMMDD" data-date-format="yyyy-mm-dd">
                                                <input type="text" id="txtSupportStr1" ref="txtSupportStr1" class="form-control" maxlength="10"
                                                       v-bind:value="MaintenanceDetail.SupportStr1"
                                                       @input="function(e){
                                                                MaintenanceDetail.SupportStr1 = e.target.value.toHyphenationFormatYMD()
                                                                e.target.value = e.target.value.toHyphenationFormatYMD()
                                                       }" />
                                                <span class="input-group-addon">
                                                    <span class="glyphicon glyphicon-calendar"></span>
                                                </span>
                                            </div>
                                            <div class="cols col-2">
                                                <input type="text" id="txtSupportStr2" ref="txtSupportStr2" class="form-control" value="" placeholder="hh:mm" maxlength="5" v-bind:value="MaintenanceDetail.SupportStr2" 
                                                       @input="function(e){
                                                                MaintenanceDetail.SupportStr2 = e.target.value.toColonFormatHHMM()
                                                                e.target.value = e.target.value.toColonFormatHHMM()
                                                       }"/>
                                            </div>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="txtSalePriceSum2" class="control-label">
                                                <span>지원자이름</span>
                                            </label>
                                            <input type="text" id="txtSuppoter" class="form-control" disabled="disabled" v-model="MaintenanceDetail.SupportEmpNm"/>
                                            <input type="hidden" id="hidSuppoter" v-model="MaintenanceDetail.SupportEmpNo"/>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols">
                                            <label for="txtContents" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>지원내용
                                                </span>
                                            </label>
                                            <textarea id="txtSupportContent" ref="txtSupportContent" class="form-control" form-groups="10" style="resize:none; height:80px;" v-model="MaintenanceDetail.SupportContent"></textarea>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-2">
                                            <label for="selAprvDvsCd" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>처리결과
                                                </span>
                                            </label>
                                            <select class="form-control" id="CompletedDvs" ref="CompletedDvs" name="CompletedDvs"
                                                    v-model="MaintenanceDetail.CompletedCd">
                                                <option value="">== 전체 ==</option>
                                                <option v-for="item in CompletedDvsList" v-bind:value="item.Code">
                                                    {{item.Name}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="cols col-2">
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols">
                                            <label for="txtContents" class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>비고
                                                </span>
                                            </label>
                                            <textarea id="txtRemark" ref="txtRemark" class="form-control" form-groups="10" style="resize:none; height:80px;" v-model="MaintenanceDetail.Remark"></textarea>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div><label></label></div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols">
                                            <label for="txtContents" class="control-label">
                                                <span>지원자 첨부</span>
                                            </label>
                                            <div class="btn-wrap btn-align">
                                                <div style="margin: 5px 0px 3px 0px" class="fr">
                                                    <a href="#" id="btnChldInst2" class="btn btn-default" title="추가" @click.prevent="addApplicantAttachFile">
                                                        <span class="glyphicon glyphicon-plus"></span> 추가
                                                    </a>
                                                    <a href="#" id="btnChldRmve2" class="btn btn-default" title="삭제" @click.prevent="removeAttachFile(2)">
                                                        <span class="glyphicon glyphicon-minus"></span> 삭제
                                                    </a>
                                                </div>
                                            </div>
                                            <section class="tbl-contents">
                                                <div class="table-responsive">
                                                    <table class="table tbl-list table-hover table-bordered" id="tb2">
                                                        <colgroup>
                                                            <col class="chk" />
                                                            <col />
                                                        </colgroup>
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" class="text-center"></th>
                                                                <th scope="col" class="text-center">첨부파일</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-if="MaintenanceApplicantFileList === null">
                                                                <td colspan="2" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                            </tr>
                                                            <tr v-else v-for="maintenanceApplicantFile in MaintenanceApplicantFileList" v-show="maintenanceApplicantFile.IsShow">
                                                                <td class="chk">
                                                                    <label>
                                                                        <input v-model="maintenanceApplicantFile.IsChecked" type="checkbox" name="cbItem2"
                                                                               @click="function(e){
                                                                                    maintenanceApplicantFile.IsRemove = e.target.checked
                                                                               }"/>
                                                                    </label>
                                                                </td>
                                                                <td v-show="maintenanceApplicantFile.IsNew" class="text-center">
                                                                    <input type="file" name="ctlAttachFile"
                                                                           @change="function(e){
                                                                                maintenanceApplicantFile.Files = e.target.files
                                                                           }" />
                                                                </td>
                                                                <td v-show="!maintenanceApplicantFile.IsNew" class="text-left">
                                                                    <a href="#" @click.prevent="fileHandler.download(maintenanceApplicantFile.GUID)">{{maintenanceApplicantFile.FileName}}</a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </section>
                                        </div>
                                    </section>
                                    <section class="btn-align">
                                        <div v-show="this.$parent.CanSave" class="btn-group">
                                            <button type="button" id="btnItemSave" class="btn btn-primary" @click.prevent="saveData">
                                                <i class="glyphicon glyphicon-save"></i> 저장
                                            </button>
                                        </div>
                                        <div v-show="this.$parent.CanDelete" class="btn-group">
                                            <button type="button" id="btnItemRmve" class="btn btn-warning" @click.Prevent="removeData">
                                                <i class="glyphicon glyphicon-edit"></i> 삭제
                                            </button>
                                        </div>
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-danger" data-dismiss="modal">
                                                <i class="glyphicon glyphicon-remove"></i> 취소
                                            </button>
                                        </div>
                                    </section>
                                </fieldset>
                            </form>
                        </section>
                    </div>
                </div>
            </div>
        </div>
        <CP1060P ref="CP1060P"></CP1060P>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle'
    import CP1060P from '@/components/libs/popup/CP1060P'
    export default {
        name: 'PM1050R',
        components: {
            ViewTitle, CP1060P
        },
        data() {
            return {
                MaintenanceList: null,                  // 프로젝트 유지보수관리 리스트
                MaintenanceRequestFileList: null,       // 프로그램유지보수관리 요청자 상세 파일 목록
                MaintenanceApplicantFileList: null,     // 프로그램유지보수관리 지원자 상세 파일 목록
                // 영업관리 이력 요청자 파일상세객체
                MaintenanceRequestFileDetail: {
                    IsNew: false,
                    IsRemove: false,
                    IsShow: true,
                    IsChecked: false,
                    Files: null,
                    FileSeqNo: null,
                    AttachTypeCd: null,
                    GUID: null,
                    FileName: null
                },
                // 영업관리 이력 지원자 파일상세객체
                MaintenanceRequestFileDetail: {
                    IsNew: false,
                    IsRemove: false,
                    IsShow: true,
                    IsChecked: false,
                    Files: null,
                    FileSeqNo: null,
                    AttachTypeCd: null,
                    GUID: null,
                    FileName: null
                },
                // 상세 데이터 객체
                MaintenanceDetail: {
                    ProjectCd: null,        // 프로젝트 코드
                    ProjectNm: null,        // 프로젝트 이름
                    CompanyCd: null,        // 업체 코드
                    ServiceAcnt: null,      // 서비스항목
                    ReqDate: null,          // 요청 일시
                    ReqStr_1: null,         // 요청일
                    ReqStr_2: null,         // 요청시간
                    ReqParty: null,         // 요청자 소속
                    ReqName: null,          // 요청자 이름
                    ReqContent: null,       // 요청 내용
                    SupportCd: null,        // 지원매체 코드
                    SupportDate: null,      // 지원 일시
                    SupportStr1: null,      // 지원일
                    SupportStr2: null,      // 지원시간
                    SupportEmpNo: null,     // 사용자 사번
                    SupportEmpNm: null,     // 사용자 이름
                    SupportContent: null,   // 지원내용
                    CompletedCd: null,      // 처리결과 코드
                    Remark: null,           // 비고
                    TrgtYYMM: null,         // 요청일 가공 데이터
                    SeqNo: null,            // Row 순서
                    IsOwner: null           // Y 바인딩 //TODO: 확인필요
                },
                // 조회 조건 데이터 객체
                MaintenanceSelectTerm: {
                    Arg: 'L',                           // L
                    UserId: this.$parent.EmpId,         // 사용자 사번
                    CorpCd: this.$parent.CompanyCode,   // 회사 코드
                    ProjectCode: '',                    // 프로젝트 코드
                    CompanyCode: '',                    // 업체 코드
                    StrDate: this.moment(new Date()).format('YYYY-MM-DD'), // 요청일 시작
                    EndDate: this.moment(new Date()).format('YYYY-MM-DD'), // 요청일 끝
                    ServiceCd: '',                      // 서비스항목
                    Completed: '0729010',               // 처리결과
                    ProjectName: '',                    // 프로젝트 이름
                    TopSize: 10,                        // 현재 보여줄 Row 개수
                    CurrentPage: 1,                     // 현제 페이징 번호
                },
                // 상세 조건 데이터 객체
                MaintenanceDetailSelect: {
                    Arg: 'R',                           // L
                    UserId: this.$parent.EmpId,         // 사용자 사번
                    CorpCd: this.$parent.CompanyCode,   // 회사 코드
                    ProjectCode: null,                  // 프로젝트 코드
                    CompanyCode: null,                  // 업체 코드
                    SeqNo: null,                        // Row 번호
                    TrgtYYMM: null                      // 요청일 가공한 데이터
                },
                TotalCount: 0,                          // 데이터 총 개수
                PageActionType: 'V',                    // 화면액션 값 설정 (I:신규, V: 상세보기)
                CurrentDateYYMMDD: this.moment(new Date()).format('YYYY-MM-DD') // 현재 날짜
            }
        },
        beforeMount() {

            // 공통코드 조회
            this.ServiceList = $.scriptBase.getCommonCode('CODE000', '0725000', '', '') // 서비스항목

            this.ProgressList = $.scriptBase.getCommonCode('CODE000', '0729000', '', '') // 처리결과

            this.ServicdAnctCodeList = $.scriptBase.getCommonCode('CODE000', '0725000', '', '') // 서비스항목

            this.SupprotDvsCdList = $.scriptBase.getCommonCode('CODE000', '0727000', '', '') // 지원결과

            this.CompletedDvsList = $.scriptBase.getCommonCode('CODE000', '0729000', '', '') // 처리결과

        },
        mounted() {

            var parent = this

            // datetimepicker 사용
            $('.form_date').off('changeDate')
            $('.form_date').datetimepicker({
                weekStart: 1,
                todayBtn: 1,
                autoclose: 1,
                todayHighlight: 1,
                startView: 2,
                minView: 2,
                forceParse: 0,
                keyboardNavigation: true,
                language: 'kr',
            }).on('changeDate', function (e) {

                var targetInputElement = e.target.querySelector('input')
                var selectDate = parent.moment(e.date).format('YYYY-MM-DD')

                switch (targetInputElement.id) {
                    case '_txtStrDate': {

                        parent.MaintenanceSelectTerm.StrDate = selectDate

                        break
                    }
                    case '_txtEndDate': {

                        parent.MaintenanceSelectTerm.EndDate = selectDate

                        break
                    }
                    case 'txtReqStr_1': {

                        parent.MaintenanceDetail.ReqStr_1 = selectDate

                        // trgtYYMM 데이터 가공
                        var trgtProcessing = selectDate.replace('-', '')
                        var trgtYYMM = trgtProcessing.substring(0, 6)

                        parent.MaintenanceDetail.TrgtYYMM = trgtYYMM

                        break
                    }
                    case 'txtSupportStr1': {

                        parent.MaintenanceDetail.SupportStr1 = selectDate

                        break
                    }
                    default:
                }
                })

            if (this.$parent.CanSearch) {

                this.showData(false)
            }
        },
        methods: {

            // 포로젝트 검색 메서드
            searchProjectCode: function (index) {

                var parent = this

                var successCallback = function (result) {

                    switch (index) {

                        case 1: {
                            // 결과값을 추출 후 돔객체에 바인딩
                            parent.MaintenanceSelectTerm.ProjectName = result.ProjectNm
                            parent.MaintenanceSelectTerm.ProjectCode = result.ProjectCd
                            parent.MaintenanceSelectTerm.CompanyName = result.CompanyNm
                            parent.MaintenanceSelectTerm.CompanyCode = result.CompanyCd

                            parent.showData()
                        }
                        case 2: {

                            // 결과값을 추출 후 돔객체에 바인딩
                            parent.MaintenanceDetail.ProjectNm = result.ProjectNm
                            parent.MaintenanceDetail.ProjectCd = result.ProjectCd
                            parent.MaintenanceDetail.CompanyCd = result.CompanyCd
                        }

                        default:
                    }
                }

                this.$refs.CP1060P.showProjectPopup(successCallback)
            },
            // 검색 버튼 클릭 이벤트 핸들러
            btnSearchClicked: function () {

                this.MaintenanceSelectTerm.CurrentPage = 1
                this.showData()
            },
            // 조회 메서드
            showData: function () {
                var parent = this

                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/GetMaintenanceList'
                url = url.toRealServiceUrl()

                var obj = new Object()
                obj.jsonString = JSON.stringify(this.MaintenanceSelectTerm)

                // ajax 성공 시 실행되는 핸들러 정의
                var ajaxSuccessHandler = function (data) {

                    var table = JSON.parse(data.d).Table
                    var table1 = JSON.parse(data.d).Table1

                    // 프로젝트 유지보수관리 목록
                    if (table.length > 0) {

                        parent.MaintenanceList = table
                    }
                    else {

                        parent.MaintenanceList = null
                    }

                    // 매입상품 목록 전체 건수
                    if (table1.length > 0) {

                        parent.TotalCount = table1[0].TotCnt
                    }
                    else {

                        parent.TotalCount = 0
                    }

                    // 페이징 세팅
                    $("#ulpager").pager({
                        pager: "#ulpager",
                        totalItemCount: parent.TotalCount,
                        recordPerPage: parent.MaintenanceSelectTerm.TopSize,
                        currentPage: parent.MaintenanceSelectTerm.CurrentPage,
                        pageIndexChanged: parent.pageChangeEvent,
                    })
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
            },
            // 페이징 변경 이벤트 핸들러
            pageChangeEvent: function (page) {

                this.MaintenanceSelectTerm.CurrentPage = page
                this.showData()
            },
            // 추가 버튼 클릭 이벤트 핸들러
            btnAddClicked: function () {

                // 화면 액션값 설정 (I:신규, V: 상세보기)
                this.PageActionType = 'I'

                // 파일 목록 초기화
                this.MaintenanceRequestFileList = null // 요청자 첨부

                this.MaintenanceApplicantFileList = null // 지원자 첨부

                // 상세 데이터 객체 초기화
                this.MaintenanceDetail = {
                    UserId: this.$parent.EmpId,         // 사용자 사번
                    CorpCd: this.$parent.CompanyCode,   // 회사 코드
                    ProjectCd: null,                    // 프로젝트 코드
                    ProjectNm: null,                    // 프로젝트 이름
                    CompanyNm: null,                    // 거래처 이름
                    CompanyCd: null,                    // 거래처 코드
                    ServiceAcnt: '',                    // 서비스항목 코드
                    ReqDate: null,                      // 요청 일시
                    ReqStr_1: null,                     // 요청일
                    ReqStr_2: null,                     // 요청시간
                    ReqParty: null,                     // 요청자 소속
                    ReqName: null,                      // 요청자 이름
                    ReqContent: null,                   // 요청 내용
                    SupportCd: '',                      // 지원매체 코드
                    SupportDate: null,                  // 지원 일시
                    SupportStr1: null,                  // 지원일
                    SupportStr2: null,                  // 지원시간
                    SupportEmpNo: this.$parent.EmpId,   // 사용자 사번
                    SupportEmpNm: this.$parent.UserName,// 사용자 이름
                    SupportContent: null,               // 지원내용
                    CompletedCd: '',                    // 처리결과 코드
                    Remark: null,                       // 비고
                    SeqNo: null,                        // Row 순서
                    TrgtYYMM: null,                     // 요청일 가공 데이터
                }

                $(this.$refs.PM1051E_Modal).modal('show')
            },
            rowClickEvent: function (row) {

                // 요청자 파일 목록 초기화
                this.MaintenanceRequestFileList = new Array()

                // 지원자 파일 목록 초기화
                this.MaintenanceApplicantFileList = new Array()

                var parent = this

                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/GetMaintenanceDetail'
                url = url.toRealServiceUrl()
                var obj = new Object()

                this.MaintenanceDetailSelect.ProjectCode = row.ProjectCd
                this.MaintenanceDetailSelect.CompanyCode = row.CompanyCd
                this.MaintenanceDetailSelect.SeqNo = row.SeqNo
                this.MaintenanceDetailSelect.TrgtYYMM = row.TrgtYYMM

                obj.jsonString = JSON.stringify(this.MaintenanceDetailSelect)

                // ajax 성공 시 실행되는 핸들러 정의
                var ajaxSuccessHandler = function (data) {

                    // 상세 데이터
                    var table = JSON.parse(data.d).Table
                    // 요청자 첨부 상세 데이터
                    var table1 = JSON.parse(data.d).Table1
                    // 지원자 첨부 상세 데이터
                    var table2 = JSON.parse(data.d).Table2

                    // 프로젝트 유지보수상세 바인딩
                    if (table.length > 0) {

                        Object.assign(parent.MaintenanceDetail, table[0])

                        // 요청일시 데이터 가공 후 바인딩
                        var requestDate = table[0].ReqDate

                        parent.MaintenanceDetail.ReqStr_1 = requestDate.substring(0, 10)
                        parent.MaintenanceDetail.ReqStr_2 = requestDate.substring(11, 16)

                        // 지원 일시 데이터 가공 후 바인딩
                        var supportDate = table[0].SupportDate

                        parent.MaintenanceDetail.SupportStr1 = supportDate.substring(0, 10)
                        parent.MaintenanceDetail.SupportStr2 = supportDate.substring(11, 16)
                    }
                    else {
                        Object.assign(parent.MaintenanceDetail, null)
                    }

                    // 요청자 첨부 파일 전체 목록
                    if (table1.length > 0) {

                        // 파일 목록 초기화
                        parent.MaintenanceRequestFileList = new Array()

                        table1.forEach(function (object) {

                            object.IsNew = false
                            object.IsShow = true
                            object.IsChecked = false
                            object.Files = null
                        })
                        
                        parent.MaintenanceRequestFileList = table1

                        // 데이터가 없을 경우 일때 다시 리스트 객체를 null 초기화
                        if (parent.MaintenanceRequestFileList.length === 0) {

                            parent.MaintenanceRequestFileList = null
                        }
                    }
                    else {
                        parent.MaintenanceRequestFileList = null
                    }

                    // 지원자 첨부 파일 전체 목록
                    if (table2.length > 0) {

                        // 파일 목록 초기화
                        parent.MaintenanceApplicantFileList = new Array()

                        table2.forEach(function (object) {

                            object.IsNew = false
                            object.IsShow = true
                            object.IsChecked = false
                            object.Files = null
                        })

                        parent.MaintenanceApplicantFileList = table2

                        // 데이터가 없을 경우 일때 다시 리스트 객체를 null 초기화
                        if (parent.MaintenanceApplicantFileList.length === 0) {

                            parent.MaintenanceApplicantFileList = null
                        }
                    }
                    else {
                        parent.MaintenanceApplicantFileList = null
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)

                $(this.$refs.PM1051E_Modal).modal('show')
            },
            // 프로젝트 유지보수관리 유효성 검사 메서드
            saveData: function () {

                var parent = this
                var isValidateMaintenanceRequestFiles = true        // 요청자 신규첨부파일 유효성 체크 여부
                var isValidateMaintenanceApplicantFiles = true      // 자원자 신규첨부파일 유효성 체크 여부
                var newMaintenanceRequestFileList = new Array()     // 요청자 신규첨부파일 목록
                var newMaintenanceApplicantFileList = new Array()   // 지원자 신규첨부파일 목록
                var uploadResultMaintenanceRequestFile = null       // 요청자 신규첨부파일 업로드 결과

                // 요청자 신규첨부파일 업로드 성공후 콜백 함수
                var successMaintenanceRequestFileUploadCallback = function (result) {

                    // 결과 설정
                    uploadResultMaintenanceRequestFile = result

                    // 지원자 신규첨부파일 업로드 실행
                    parent.fileHandler.upload(newMaintenanceApplicantFileList, successMaintenanceApplicantFileUploadCallback)
                }

                // 지원자 신규첨부파일 업로드 성공후 콜백 함수
                var successMaintenanceApplicantFileUploadCallback = function (result) {

                    // 저장 실행
                    parent.doSaveData(uploadResultMaintenanceRequestFile, result)
                }

                if (this.MaintenanceDetail.ProjectNm === '' || this.MaintenanceDetail.ProjectNm === null) {

                    this.$refs.txtProjectNm2.focus()
                    alert('프로젝트를 선택해주세요.')
                    return 
                }
                if (this.MaintenanceDetail.ServiceAcnt === '' || this.MaintenanceDetail.ServiceAcnt === null) {

                    this.$refs.ServicdAnctCd.focus()
                    alert("서비스항목을 선택해주세요.");
                    return
                } 
                if (this.MaintenanceDetail.ReqStr_1 === '' || this.MaintenanceDetail.ReqStr_1 === null) {

                    this.$refs.txtReqStr_1.focus()
                    alert('요청일을 확인해주세요.') 
                    return 
                }
                if (this.MaintenanceDetail.ReqStr_2 === '' || this.MaintenanceDetail.ReqStr_2 === null) {

                    this.$refs.txtReqStr_2.focus()
                    alert('요청시간을 확인해주세요.')
                    return
                }
                if (!this.MaintenanceDetail.ReqStr_2.isTime()) {

                    this.$refs.txtReqStr_2.focus()
                    alert('요청시간형식을 확인해주세요.')
                    return
                }
                if (this.MaintenanceDetail.ReqName === '' || this.MaintenanceDetail.ReqName === null) {

                    this.$refs.txtReqName.focus()
                    alert('요청자 이름은 반드시 입력하셔야합니다.')
                    return
                }
                if (this.MaintenanceDetail.ReqContent === '' || this.MaintenanceDetail.ReqContent === null) {

                    this.$refs.txtcontent.focus()
                    alert("요청내용은 반드시 입력하셔야합니다.");
                    return
                }
                if (this.MaintenanceDetail.SupportCd === '' || this.MaintenanceDetail.SupportCd === null) {

                    this.$refs.SupprotDvsCd.focus()
                    alert('지원매체는 반드시 선택하셔야합니다.')
                    return
                }
                if (this.MaintenanceDetail.SupportStr1 === '' || this.MaintenanceDetail.SupportStr1 === null) {

                    this.$refs.txtSupportStr1.focus()
                    alert('지원일을 확인해주세요.')
                    return 
                }
                if (this.MaintenanceDetail.SupportStr2 === '' || this.MaintenanceDetail.SupportStr2 === null) {

                    this.$refs.txtSupportStr2.focus()
                    alert('지원시간을 확인해주세요.')
                    return
                }
                if (!this.MaintenanceDetail.SupportStr2.isTime()) {

                    this.$refs.txtSupportStr2.focus()
                    alert('지원시간형식을 확인해주세요.')
                    return
                }
                if (this.MaintenanceDetail.SupportContent === '' || this.MaintenanceDetail.SupportContent === null) {

                    this.$refs.txtSupportContent.focus()
                    alert('지원내용을 입력해주세요.')
                    return
                }
                if (this.MaintenanceDetail.CompletedCd === '' || this.MaintenanceDetail.SupportContent === null) {

                    this.$refs.CompletedDvs.focus()
                    alert('처리결과는 반드시 선택하셔야합니다.');
                    return
                }
                if (this.MaintenanceDetail.Remark === '' || this.MaintenanceDetail.Remark === null) {

                    $(this.$refs.txtRemark).focus()
                    alert("비고를 입력해주세요.");
                    return 
                }

                // 요청자 신규 첨부파일 업로드
                if (this.MaintenanceRequestFileList != null) {

                    // 신규 파일이 존재하는지 체크
                    var isExistNewFile = this.MaintenanceRequestFileList.some(function (file) {

                        return file.IsNew
                    })
                    
                    if (isExistNewFile) {
                        
                        for (var i = 0; i < this.MaintenanceRequestFileList.length; i++) {

                            var file = this.MaintenanceRequestFileList[i]

                            if (file.IsNew && !file.IsRemove) {

                                if (file.Files != null) {

                                    for (var j = 0; j < file.Files.length; j++) {

                                        newMaintenanceRequestFileList.push(file.Files[j])
                                    }
                                }
                                else {

                                    alert('첨부파일을 선택하세요.')

                                    isValidateMaintenanceRequestFiles = false
                                    break
                                }
                            }
                        }
                    }
                }

                // 지원자 신규 첨부파일 업로드
                if (this.MaintenanceApplicantFileList != null) {

                    // 신규 파일이 존재하는지 체크
                    var isExistNewFile = this.MaintenanceApplicantFileList.some(function (file) {

                        return file.IsNew
                    })
                    
                    if (isExistNewFile) {

                        var isValidateFiles = true

                        for (var i = 0; i < this.MaintenanceApplicantFileList.length; i++) {

                            var file = this.MaintenanceApplicantFileList[i]

                            if (file.IsNew && !file.IsRemove) {

                                if (file.Files != null) {

                                    for (var j = 0; j < file.Files.length; j++) {

                                        newMaintenanceApplicantFileList.push(file.Files[j])
                                    }
                                }
                                else {

                                    alert('첨부파일을 선택하세요.')

                                    isValidateMaintenanceApplicantFiles = false
                                    break
                                }
                            }
                        }

                        
                    }
                }

                // 신규첨부파일이 존재하지 않으면 저장 실행
                if (newMaintenanceRequestFileList.length <= 0
                    && newMaintenanceApplicantFileList.length <= 0) {

                    this.doSaveData(null, null)
                }
                else {

                    // 요청자, 지원자 신규첨부파일 유효성 검사 성공 시 파일 업로드 실행
                    if (isValidateMaintenanceRequestFiles && isValidateMaintenanceApplicantFiles) {

                        // 요청자 부터 실행(지원자는 요청자 완료 후 콜백함수에서 실행)
                        this.fileHandler.upload(newMaintenanceRequestFileList, successMaintenanceRequestFileUploadCallback)
                    }
                }
            },
            // 프로젝트 유지보수관리 실제 저장 메서드
            doSaveData: function (uploadMaintenanceRequestFiles, uploadMaintenanceApplicantFiles) {

                var parent = this

                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/SaveMaintenanceDetail'
                url = url.toRealServiceUrl()
                var obj = new Object()
                var attachFileList = new Array()

                // 저장시 상세 객체 초기화
                this.MaintenanceDetail.Arg = 'I'
                this.MaintenanceDetail.UserId = this.$parent.EmpId,
                this.MaintenanceDetail.CorpCd = this.$parent.CompanyCode
                this.MaintenanceDetail.ReqDate = this.MaintenanceDetail.ReqStr_1 + ' ' + this.MaintenanceDetail.ReqStr_2
                this.MaintenanceDetail.SupportDate = this.MaintenanceDetail.SupportStr1 + ' ' + this.MaintenanceDetail.SupportStr2

                obj.jsonString = JSON.stringify(this.MaintenanceDetail)

                // 요청자 첨부파일
                if (uploadMaintenanceRequestFiles != null
                    && uploadMaintenanceRequestFiles.files.length > 0) {

                    uploadMaintenanceRequestFiles.files.forEach(function (object) {

                        attachFileList.push({
                            'Arg': 'FI',
                            'CorpCd': parent.$parent.CompanyCode,
                            'CompanyCd': parent.MaintenanceDetail.CompanyCd,
                            'ProjectCd': parent.MaintenanceDetail.ProjectCd,
                            'TrgtYYMM': parent.MaintenanceDetail.TrgtYYMM,
                            'SeqNo': parent.MaintenanceDetail.SeqNo,
                            'UserId': parent.$parent.EmpId,
                            'AttachTypeCd': '0731010',
                            'GUID': object.nFileName,
                            'FileName': object.oFileName,
                        })
                    })
                }

                // 지원자 첨부파일
                if (uploadMaintenanceApplicantFiles != null
                    && uploadMaintenanceApplicantFiles.files.length > 0) {

                    uploadMaintenanceApplicantFiles.files.forEach(function (object) {

                        attachFileList.push({
                            'Arg': 'FI',
                            'CorpCd': parent.$parent.CompanyCode,
                            'CompanyCd': parent.MaintenanceDetail.CompanyCd,
                            'ProjectCd': parent.MaintenanceDetail.ProjectCd,
                            'TrgtYYMM': parent.MaintenanceDetail.TrgtYYMM,
                            'SeqNo': parent.MaintenanceDetail.SeqNo,
                            'UserId': parent.$parent.EmpId,
                            'AttachTypeCd': '0731020',
                            'GUID': object.nFileName,
                            'FileName': object.oFileName,
                        })
                    })
                }

                obj.jsonFileString = JSON.stringify(attachFileList)

                // 서비스호출 성공 콜백 함수
                var successCallback = function (data) {

                    if (data.d > 0) {

                        if (parent.PageActionType === 'I') {

                            alert('등록 되었습니다.')
                            $(parent.$refs.PM1051E_Modal).modal('hide')
                            // 등록 후 그리드 재 조회
                            parent.showData()
                        }
                        else {

                            alert('저장 되었습니다.')
                            $(parent.$refs.PM1051E_Modal).modal('hide')
                            // 등록 후 그리드 재 조회
                            parent.showData()
                        }
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            // 프로젝트 유지보수관리 삭제 메서드
            removeData: function () {
                if (this.MaintenanceDetail === null && this.PageActionType === "I") {

                    alert("삭제할 정보가 존재 하지 않습니다.")
                    return
                }

                if (confirm("삭제하시겠습니까?")) {

                    var parent = this

                    var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/RemoveMaintenanceDetail'
                    url = url.toRealServiceUrl()

                    var obj = new Object()

                    this.MaintenanceDetail.Arg = 'D'
                    this.MaintenanceDetail.CorpCd = this.$parent.CompanyCode

                    obj.jsonString = JSON.stringify(this.MaintenanceDetail)

                    // 서비스호출 성공 콜백 함수
                    var successCallback = function (data) {

                        if (data.d > 0) {

                            alert('삭제 되었습니다.')

                            $(parent.$refs.PM1051E_Modal).modal('hide')
                            parent.showData()
                        }
                        else {

                            alert('삭제에 실패하였습니다. 잠시 후 다시 시도해 주세요.')
                        }
                    }

                    $.scriptBase.executeAjaxAsync(url, obj, successCallback)
                }
            },
            // 요청자 첨부파일 추가
            addRequestAttachFile: function () {

                if (this.MaintenanceRequestFileList === null || this.MaintenanceRequestFileList === '') {

                    this.MaintenanceRequestFileList = new Array()
                }

                // 신규파일 추가
                this.MaintenanceRequestFileList.push({
                    IsNew: true,
                    IsRemove: false,
                    IsShow: true,
                    IsChecked: false,
                    Files: null,
                    FileSeqNo: null,
                    AttachTypeCd: null,
                    GUID: null,
                    FileName: null
                })
            },
            // 요청자 첨부파일 추가
            addApplicantAttachFile: function () {

                if (this.MaintenanceApplicantFileList === null || this.MaintenanceApplicantFileList === '') {

                    this.MaintenanceApplicantFileList = new Array()
                }

                // 신규파일 추가
                this.MaintenanceApplicantFileList.push({
                    IsNew: true,
                    IsRemove: false,
                    IsShow: true,
                    IsChecked: false,
                    Files: null,
                    FileSeqNo: null,
                    AttachTypeCd: null,
                    GUID: null,
                    FileName: null
                })
            },
            // 첨부파일 삭제
            removeAttachFile: function (index) {

                var parent = this
                var removeFileList = new Array()

                switch (index) {

                    case 1: {

                        if (this.MaintenanceRequestFileList != null) {

                            // 삭제할 파일이 존재하는지 체크
                            var isExist = this.MaintenanceRequestFileList.some(function (file) {

                                return file.IsRemove
                            })

                            if (isExist) {

                                if (confirm('삭제 하시겠습니까?')) {

                                    // 삭제할 파일들만 별도의 리스트 객체에 담기
                                    this.MaintenanceRequestFileList.forEach(function (object) {

                                        // 선택한 파일 삭제 플래그 변경
                                        object.IsRemove = object.IsChecked

                                        // 기존파일은 DB에서 삭제하기위해 별도로 모음
                                        if (!object.IsNew && object.IsChecked === true) {

                                            object.Arg = 'FD'
                                            object.CorpCd = parent.$parent.CompanyCode
                                            object.CompanyCd = parent.MaintenanceDetail.CompanyCd
                                            object.ProjectCd = parent.MaintenanceDetail.ProjectCd
                                            object.TrgtYYMM = parent.MaintenanceDetail.TrgtYYMM
                                            object.SeqNo = parent.MaintenanceDetail.SeqNo
                                            object.AttachTypeCd = '0731010'
                                            removeFileList.push(object)
                                        }
                                    })

                                    var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/RemoveMaintenanceFile'
                                    url = url.toRealServiceUrl()
                                    var obj = new Object()

                                    obj.jsonFilrString = JSON.stringify(removeFileList)

                                    // 서비스호출 성공 콜백 함수
                                    var successCallback = function (data) {

                                        if (data.d > 0) {

                                            alert('삭제 되었습니다.')

                                            $(parent.$refs.PM1051E_Modal).modal('hide')
                                            parent.showData()
                                        }
                                        else {

                                            alert('삭제에 실패하였습니다. 잠시 후 다시 시도해 주세요.')
                                        }
                                    }

                                    $.scriptBase.executeAjaxAsync(url, obj, successCallback)

                                }
                                else {
                                    alert('삭제할 파일이 없습니다.')
                                }
                            }
                            else {

                                alert('삭제할 파일이 없습니다.')
                            }
                        }
                        else {

                            alert('삭제할 파일이 없습니다.')
                        }

                        break
                    }
                    case 2: {

                        if (this.MaintenanceApplicantFileList != null) {
                            // 삭제할 파일이 존재하는지 체크
                            var isExist = this.MaintenanceApplicantFileList.some(function (file) {

                                return file.IsRemove
                            })

                            if (isExist) {

                                if (confirm('삭제 하시겠습니까?')) {

                                    // 삭제할 파일들만 별도의 리스트 객체에 담기
                                    this.MaintenanceApplicantFileList.forEach(function (object) {

                                        // 선택한 파일 삭제 플래그 변경
                                        object.IsRemove = object.IsChecked

                                        // 기존파일은 DB에서 삭제하기위해 별도로 모음
                                        if (!object.IsNew && object.IsChecked === true) {

                                            object.Arg = 'FD'
                                            object.CorpCd = parent.$parent.CompanyCode
                                            object.CompanyCd = parent.MaintenanceDetail.CompanyCd
                                            object.ProjectCd = parent.MaintenanceDetail.ProjectCd
                                            object.TrgtYYMM = parent.MaintenanceDetail.TrgtYYMM
                                            object.SeqNo = parent.MaintenanceDetail.SeqNo
                                            object.AttachTypeCd = '0731020'
                                            removeFileList.push(object)
                                        }
                                    })

                                    var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/RemoveMaintenanceFile'
                                    url = url.toRealServiceUrl()
                                    var obj = new Object()

                                    obj.jsonFilrString = JSON.stringify(removeFileList)

                                    // 서비스호출 성공 콜백 함수
                                    var successCallback = function (data) {

                                        if (data.d > 0) {
                                            alert('삭제 되었습니다.')

                                            $(parent.$refs.PM1051E_Modal).modal('hide')
                                            parent.showData()
                                        }
                                        else {

                                            alert('삭제에 실패하였습니다. 잠시 후 다시 시도해 주세요.')
                                        }
                                    }

                                    $.scriptBase.executeAjaxAsync(url, obj, successCallback)

                                }
                                else {
                                    alert('삭제할 파일이 없습니다.')
                                }
                            }
                            else {

                                alert('삭제할 파일이 없습니다.')
                            }
                        }
                        else {

                            alert('삭제할 파일이 없습니다.')
                        }

                        break
                    }

                    default:
                }

                
            }
        }
    }
</script>