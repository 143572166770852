﻿export default {
    // 파일 다운로드
    download: function (guid) {

        var handlerUrl = 'Mini.Service.IBS.CM.Service/Handlers/FileDownloadHandler.ashx'
        handlerUrl = handlerUrl.toRealServiceUrl()

        location.href = handlerUrl + '?key=' + guid
    },

    soliDocsDownload: function (DocID) {
        var handlerUrl = 'Mini.Service.IBS.CM.Service/Handlers/SoliDocsFileDownloadHandler.ashx'
        handlerUrl = handlerUrl.toRealServiceUrl()

        location.href = handlerUrl + '?key=' + DocID
    },

    // 파일 업로드
    upload: function (files, successCallback) {

        var formData = new FormData()
        formData.append('action', 'attachfiles')

        var handlerUrl = 'Mini.Service.IBS.CM.Service/Handlers/FileUploadHandler.ashx'
        handlerUrl = handlerUrl.toRealServiceUrl()

        for (var i = 0; i < files.length; i++) {

            formData.append('attachfiles["' + i + '"]', files[i])
        }

        var opts = {

            url: handlerUrl,
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            type: 'POST',
            success: function (result) {
                
                successCallback(JSON.parse(result))
            }
        }

        if (formData.fake) {

            opts.xhr = function () {

                var xhr = $.ajaxSettings.xhr()
                xhr.send = xhr.sendAsBinary
                return xhr
            }
            opts.contentType = 'multipart/form-data; boundary=' + formData.boundary;
            opts.data = formData.toString()
        }

        $.ajax(opts)
    },
    
    SoliDocsupload: function (files, successCallback) {

        var formData = new FormData()
        formData.append('action', 'attachfiles')

        var handlerUrl = 'Mini.Service.IBS.CM.Service/Handlers/SoliDocsFileUploadHandler.ashx'
        handlerUrl = handlerUrl.toRealServiceUrl()

        for (var i = 0; i < files.length; i++) {

            formData.append('attachfiles["' + i + '"]', files[i])
        }

        var opts = {

            url: handlerUrl,
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            type: 'POST',
            success: function (result) {
                
                successCallback(JSON.parse(result))
            }
        }

        if (formData.fake) {

            opts.xhr = function () {

                var xhr = $.ajaxSettings.xhr()
                xhr.send = xhr.sendAsBinary
                return xhr
            }
            opts.contentType = 'multipart/form-data; boundary=' + formData.boundary;
            opts.data = formData.toString()
        }

        $.ajax(opts)
    },
}