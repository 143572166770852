<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a v-if="this.$parent.CanSearch" href="#" id="btnListShow" class="btn btn-primary btn-row2" @click.prevent="fn_ShowData">
                    <span>
                        <i class="glyphicon glyphicon-search"></i> 조회
                    </span>
                </a>
                <a v-show="this.$parent.CanNew" href="#" id="btnListInst" class="btn btn-default" @click.prevent="fn_InstData">
                    <span>
                        <i class="glyphicon glyphicon-plus"></i>추가
                    </span>
                </a>
                <a v-show="this.$parent.CanSave" href="#" id="btnListSave" class="btn btn-default" @click.prevent="fn_SaveData">
                    <span>
                        <i class="glyphicon glyphicon-save"></i>저장
                    </span>
                </a>
                <a v-show="this.$parent.CanDelete" href="#" id="btnListRmve" class="btn btn-default" @click.prevent="fn_RmveData">
                    <span>
                        <i class="glyphicon glyphicon-remove"></i>삭제
                    </span>
                </a>
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols">
                                        <label for="sch3-1" class="control-label">
                                            <span>상위 코드명</span>
                                        </label>
                                        <div class="col-2">
                                            <input type="text" id="txtPrntCodeName" class="form-control" readonly="readonly" />
                                        </div>
                                        <div class="col-2">
                                            <input type="text" id="txtPrntCode" class="form-control" readonly="readonly" />
                                        </div>
                                    </div>
                                </section>
                            </section>

                        </fieldset>
                    </form>
                    </div>
                </div>
                <div class="grid-wrap">
                    <div class="sub-title">
                        <p class="sub-title-txt">공통코드 관리</p>
                    </div>
                    <div>
                        <!--리스트 영역-->
                        <div class="col-xs-12 col-sm-5 col-md-3">
                            <section class="tree-wrap">
                                <div id="treeContents10">Loading...</div>
                            </section>
                        </div>
                        <div class="col-md-9 col-sm-7 col-xs-12">
                            <section class="tbl-contents">
                                <div class="alert alert-info total">
                                    <p>
                                        <i class="glyphicon glyphicon-folder-close"></i>
                                        <span>
                                            전체: <span id="spTotCnt">{{CommonCodeListTotalCount}}</span>건
                                        </span>
                                    </p>
                                </div>
                                <div class="table-responsive">
                                    <table class="table table tbl-list tbl-center table-hover table-bordered" id="tb0">
                                        <caption>테이블</caption>
                                        <colgroup>
                                            <col />
                                            <col />
                                            <col />
                                            <col />
                                            <col />
                                            <col />
                                            <col />
                                            <col />
                                            <col />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th scope="col" class="text-center">
                                                    <input type="checkbox" name="cbAll" @click="checkedAllEvent($event.target.checked, 'cb0')" />
                                                </th>
                                                <th scope="col">코드</th>
                                                <th scope="col">코드명</th>
                                                <th scope="col">단축명</th>
                                                <th scope="col">정렬순번</th>
                                                <th scope="col">
                                                    <a href="#" id="aUseYN">사용</a>
                                                </th>
                                                <th scope="col">기타1</th>
                                                <th scope="col">기타2</th>
                                                <th scope="col">기타3</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="CommonCodeList === null">
                                                <td colspan="9">조회된 정보가 없습니다.</td>
                                            </tr>
                                            <tr v-else v-for="(item, index) in CommonCodeList">
                                                <td class="chk">
                                                    <input type="checkbox" name="cb0" :basecode="item.BaseCode" :index="index" :crud="item.CRUD" />
                                                </td>
                                                <td>
                                                    <input type="text" name="txtBaseCode" maxlength="7" class="form-control"
                                                           v-model="item.BaseCode"
                                                           v-bind:readonly="item.CRUD === 'R'" />
                                                </td>
                                                <td>
                                                    <input type="text" name="txtCodeName" class="form-control" v-model="item.CodeName" />
                                                </td>
                                                <td>
                                                    <input type="text" name="txtCodeAbbr" class="form-control" v-model="item.CodeAbbr" />
                                                </td>
                                                <td>
                                                    <input type="text" name="txtRowSeqNo" class="form-control" v-model="item.RowSeqNo" />
                                                </td>
                                                <td>
                                                    <input type="checkbox" name="cbUseYN" v-model="item.UseYN" />
                                                </td>
                                                <td>
                                                    <input type="text" name="txtEtc1" class="form-control" v-model="item.Etc1" />
                                                </td>
                                                <td>
                                                    <input type="text" name="txtEtc2" class="form-control" v-model="item.Etc2" />
                                                </td>
                                                <td>
                                                    <input type="text" name="txtEtc3" class="form-control" v-model="item.Etc3" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle'
    export default {
        name: 'CM1000R',
        components: {
            ViewTitle
        },
        data() {
            return {
                CommonCodeList: null,
                ParentCodeList: null,
                ChildCodeList: null,
                CommonCodeListTotalCount: 0
            }
        },
        mounted() {

            if (this.$parent.CanSearch) {

                this.fn_ShowData()
            }
        },
        methods: {
            checkedAllEvent: function (isChecked, targetElementName) {

                document.getElementsByName(targetElementName).forEach(function (item) {

                    item.checked = isChecked
                })

                document.getElementsByName('cbAll').forEach(function (item) {

                    item.checked = isChecked
                })
            },
            fn_ShowChld: function () {

                if ($('#txtPrntCode').val() == '0000000') {

                    this.CommonCodeList = this.ParentCodeList;
                }
                else {

                    this.CommonCodeList = this.ChildCodeList;
                }
                
                if (this.CommonCodeList != null) {

                    this.CommonCodeList = $.grep(this.CommonCodeList, function (item, index) {

                        return (item.PrntCode == $('#txtPrntCode').val());
                    });
                    
                    this.CommonCodeListTotalCount = this.CommonCodeList.length
                }
                else {

                    this.CommonCodeListTotalCount = 0
                }

                this.checkedAllEvent(false, 'cb0')
            },
            fn_ChldTree: function (pid, $dt) {

                var parent = this
                var html = ''
                var child = $.grep($dt, function (o, i) {

                    return (o.PrntCode == pid)
                })

                if (child.length > 0) {

                    html += '<ul>'

                    $.each(child, function (i, o) {

                        if (o.ChildreYn == 'Y') {

                            html += '<li basecode="' + o.BaseCode + '">' + o.CodeName
                            html += parent.fn_ChldTree(o.BaseCode, $dt)
                            html += '</li>'
                        }
                        else {

                            html += '<li basecode="' + o.BaseCode + '">' + o.CodeName + '</li>'
                        }
                    })

                    html += '</ul>'
                }
                return html;
            },
            fn_ShowData: function () {

                var parent = this
                var url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/GetBaseCode'
                url = url.toRealServiceUrl()
                var obj = new Object()
                obj.prm = new Array()
                obj.prm.push('T')
                obj.prm.push(this.$parent.EmpId)
                obj.prm.push(this.$parent.CompanyCode)
                obj.prm.push('')

                var successCallback = function (data) {
                    
                    var table = JSON.parse(data.d).Table
                    var table1 = JSON.parse(data.d).Table1
                    var table2 = JSON.parse(data.d).Table2

                    if (table.length > 0 && table1.length > 0) {

                        // 사용여부 값에 따라 데이터 가공 (사용 체크박스 바인딩을 위함)
                        table1.forEach(function (item) {

                            if (item.UseYN === 'Y') {

                                item.UseYN = true
                            }
                            else {

                                item.UseYN = false
                            }
                        })

                         // 사용여부 값에 따라 데이터 가공 (사용 체크박스 바인딩을 위함)
                        table2.forEach(function (item) {

                            if (item.UseYN === 'Y') {

                                item.UseYN = true
                            }
                            else {

                                item.UseYN = false
                            }
                        })

                        parent.ParentCodeList = table1
                        parent.ChildCodeList = table2

                        var html = ''

                        $.each(table, function (i, o) {

                            if (o.ChildreYn == 'Y') {

                                html += '<li basecode="' + o.BaseCode + '" data-jstree="{\"opened\":true }">' + o.CodeName

                                if (table1.length > 0) {

                                    html += '<ul>'

                                    $.each(table1, function (ii, oo) {

                                        var seloption = ''

                                        if (ii == 0) {

                                            $('#txtPrntCodeName').val(oo.CodeName)
                                            $('#txtPrntCode').val(oo.BaseCode)
                                            seloption = "data-jstree='{\"selected\":true, \"opened\":true }' "
                                            parent.fn_ShowChld()
                                        }
                                        if (oo.ChildreYn == 'Y') {

                                            html += '<li basecode="' + oo.BaseCode + '" ' + seloption + '>' + oo.CodeName
                                            html += parent.fn_ChldTree(oo.BaseCode, table2)
                                            html += '</li>'
                                        }
                                        else {

                                            html += '<li basecode="' + oo.BaseCode + '" ' + seloption + '>' + oo.CodeName + '</li>'
                                        }
                                    })
                                
                                    html += '</ul>'
                                }
                                html += '</li>'
                            }
                            else {

                                html += "<li basecode=\"" + o.BaseCode + "\" data-jstree='{\"opened\":true }'>" + o.CodeName + "</li>"
                            }
                        })

                        $('#treeContents10').empty().append('<ul>' + html + '</ul>').jstree('destroy').jstree().unbind('select_node.jstree').bind('select_node.jstree', function (evt, data) {

                            $('#txtPrntCodeName').val(data.node.text)
                            $('#txtPrntCode').val(data.node.li_attr.basecode)
                            parent.fn_ShowChld()
                        })
                    }
                    else {

                        parent.ParentCodeList = null
                        parent.ChildCodeList = null
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            fn_InstData: function () {

                var obj = {

                    BaseCode: null
                    , CodeName: null
                    , CodeAbbr: null
                    , PrntCode: null
                    , RowSeqNo: null
                    , UseYN: 'N'
                    , Etc1: null
                    , Etc2: null
                    , Etc3: null
                    , ChildreYn: 'N'
                    , CRUD: 'C'
                }

                if (this.CommonCodeList === null) {

                    this.CommonCodeList = new Array()
                }

                this.CommonCodeList.push(obj)
            },
            fn_SaveData: function () {

                var parent = this
                var targetElement = document.querySelectorAll('#tb0 tbody tr')

                if ($("#txtPrntCode").val() === '') {

                    alert('상위 코드를 선택하세요.')
                    return
                }

                if (targetElement.length === 0) {

                    alert("저장항목이 없습니다.")
                    return
                }
                
                for (var i = 0; i < targetElement.length; i++) {

                    var baseCodeElement = targetElement[i].querySelector('input[type=text][name=txtBaseCode]')
                    var codeNameElement = targetElement[i].querySelector('input[type=text][name=txtCodeName]')
                    var rowSeqNoElement = targetElement[i].querySelector('input[type=text][name=txtRowSeqNo]')
                    
                    if (baseCodeElement.value === '') {

                        alert('코드를 입력하세요.')
                        baseCodeElement.focus()

                        return;
                    }

                    if (isNaN(baseCodeElement.value)) {

                        alert('코드는 숫자만 입력하세요.')
                        baseCodeElement.focus()

                        return;
                    }

                    if (baseCodeElement.value.length < 7) {

                        alert('코드는 7자리입니다.')
                        baseCodeElement.focus()

                        return;
                    }

                    if ($("#txtPrntCode").val() === baseCodeElement.value) {

                        alert('부모 코드와 같을 수 없습니다.')
                        baseCodeElement.focus()

                        return;
                    }
                    
                    if (codeNameElement.value === '') {

                        alert('코드명을 입력하세요.')
                        codeNameElement.focus()

                        return;
                    }

                    if (rowSeqNoElement.value === '') {

                        alert('정렬순번을 입력하세요.')
                        rowSeqNoElement.focus()

                        return;
                    }

                    if (isNaN(rowSeqNoElement.value)) {

                        alert('정렬순번은 숫자만 입력하세요.')
                        rowSeqNoElement.focus()

                        return;
                    }
                }
                
                var url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/ManipulateBaseCode'
                url = url.toRealServiceUrl()
                var obj = new Object();
                obj.prm = new Array();

                this.CommonCodeList.forEach(function (item) {

                    var tempArray = new Array()

                    tempArray.push('I')
                    tempArray.push(parent.$parent.EmpId)
                    tempArray.push(parent.$parent.CompanyCode)
                    tempArray.push('')
                    tempArray.push(item.BaseCode)
                    tempArray.push('')
                    tempArray.push('')
                    tempArray.push('')
                    tempArray.push(item.CodeName)
                    tempArray.push(item.CodeAbbr === null ? '' : item.CodeAbbr)
                    tempArray.push('')
                    tempArray.push($('#txtPrntCode').val())
                    tempArray.push(item.RowSeqNo)
                    tempArray.push(item.Etc1 === null ? '' : item.Etc1)
                    tempArray.push(item.Etc2 === null ? '' : item.Etc2)
                    tempArray.push(item.Etc3 === null ? '' : item.Etc3)
                    tempArray.push(item.UseYN ? 'Y' : 'N')

                    obj.prm.push(tempArray)
                })

                var successCallback = function (data) {

                    parent.fn_ShowData();
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            fn_RmveData: function () {

                var parent = this
                var targetElement = document.querySelectorAll('#tb0 tbody input[type=checkbox][name=cb0]:checked')

                if (targetElement.length === 0) {

                    alert('삭제 할 항목을 선택하세요.')
                    return
                }

                if (confirm('삭제하시겠습니까?')) {

                    var url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/ManipulateBaseCode'
                    url = url.toRealServiceUrl()
                    var obj = new Object();
                    obj.prm = new Array();

                    targetElement.forEach(function (element) {

                        var crud = element.getAttribute('crud')
                        var baseCode = element.getAttribute('basecode')
                        var index = element.getAttribute('index')

                        if (crud === 'R') {

                            var tempArray = new Array()
                            tempArray.push('D')
                            tempArray.push(parent.$parent.EmpId)
                            tempArray.push(parent.$parent.CompanyCode)
                            tempArray.push('')
                            tempArray.push(baseCode)

                            for (var i = 0; i < 12; i++) tempArray.push('');

                            obj.prm.push(tempArray);
                        }
                        else {

                            // 해당 인덱스 삭제
                            if (index > -1) {

                                parent.CommonCodeList.splice(index, 1)
                            }
                        }
                    })

                    if (obj.prm.length > 0) {

                        var successCallback = function (data) {

                            parent.fn_ShowData()
                        }

                        $.scriptBase.executeAjaxAsync(url, obj, successCallback)
                    }
                }
            }
        }
    }
</script>

<style scoped>
</style>