<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>

        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a v-if="this.$parent.CanSearch" href="#" id="btnListShow" class="btn btn-primary btn-row2" @click.prevent="fn_ShowData">
                    <span>
                        <i class="glyphicon glyphicon-search"></i> 조회
                    </span>
                </a>
                <a v-if="this.$parent.CanSave" href="#" id="btnListSave" class="btn btn-default" @click.prevent="fn_SaveData">
                    <span class="glyphicon glyphicon-save"></span>
                    <span> 저장</span>
                </a>
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <fieldset>
                    <legend>검색폼</legend>
                    <section class="sch-cont">
                        <section class="cols-wrap">
                            <div class="cols col-2">
                                <label for="sch3-1" class="control-label"><span>시스템</span></label>
                                <select class="form-control" id="selSystId" name="selSystId">
                                    <option v-for="item in SystemIdList" v-bind:value="item.Code">{{item.Name}}</option>
                                </select>
                            </div>
                            <div class="cols col-2">
                                <label for="sch3-1" class="control-label"><span>상위 메뉴명</span></label>
                                <div class="col-2">
                                    <input type="text" id="txtPrntMenuName" class="form-control txt" readonly="readonly" />
                                </div>
                                <div class="col-2">
                                    <input type="text" id="txtPrntMenuId" class="form-control txt" readonly="readonly" />
                                    <input type="hidden" id="hidLevelNo" value="" />
                                </div>
                            </div>
                        </section>
                        <section class="cols-wrap">
                            <div class="cols col-2">
                                <label for="sch3-1" class="control-label"><span>프로그램 분류</span></label>
                                <select class="form-control" id="selProgrCls" name="selProgrCls" @click="fn_ShowProg">
                                    <option value="">== 프로그램 분류 ==</option>
                                    <option v-for="item in ProgrClsList" v-bind:value="item.Code">{{item.Name}}</option>
                                </select>
                            </div>
                        </section>
                    </section>
                </fieldset>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">메뉴 관리(아이템)</p>
                </div>
                <div>
                    <!--리스트 영역-->
                    <div class="col-xs-12 col-sm-3 pad-r0">
                        <section class="tree-wrap">
                            <div id="treeContents10">Loading...</div>
                        </section>
                    </div>
                    <div class="tbl-option-wrap col-xs-12 col-sm-9">
                        <div class="col-xs-12 col-sm-6 tbl-option-left-wrap">
                            <div class="col-xs-12 pad-r0">
                                <section class="tbl-contents">
                                    <div class="table-responsive table-responsive-height">
                                        <table class="table tbl-list table-hover table-bordered" id="tb0">
                                            <colgroup>
                                                <col class="chk" />
                                                <col />
                                                <col />
                                                <col />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th class="text-center">
                                                        <label for="cbAll" class="hidden">전체선택</label>
                                                        <input name="cbAll" type="checkbox" @click="checkedAllEvent($event.target.checked, 'cb0')">
                                                    </th>
                                                    <th class="text-center">순번</th>
                                                    <th class="text-center">메뉴 표시명</th>
                                                    <th class="text-center">프로그램명</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="MenuList === null">
                                                    <td colspan="4" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                </tr>
                                                <tr v-else v-for="item in MenuList">
                                                    <td class="chk">
                                                        <input type="checkbox" name="cb0" 
                                                               :corpcd="item.CorpCd" 
                                                               :systid="item.SystId" 
                                                               :menuid="item.MenuId" 
                                                               :progrid="item.ProgrId"
                                                               :crud="item.CRUD" />
                                                    </td>
                                                    <td>
                                                        <input class="wix30 text-right" type="text" name="txtRowSeqNo0" v-model="item.RowSeqNo" />
                                                    </td>
                                                    <td>
                                                        <input type="text" name="txtDispName0" v-model="item.DispName" />
                                                    </td>
                                                    <td>{{item.ProgrName}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </section>
                            </div>
                            <!-- 버튼영역-->
                            <div v-if="this.$parent.CanSave" class="tbl-option-btn-wrap">
                                <a id="btnListInst" href="#" class="btn btn-default" @click.prevent="fn_InstData">
                                    <span class="glyphicon glyphicon-chevron-left" title=""></span>
                                </a>
                                <a id="btnListRmve" href="#" class="btn btn-default" @click.prevent="fn_RmveData">
                                    <span class="glyphicon glyphicon-chevron-right" title=""></span>
                                </a>
                            </div>
                            <!-- //버튼영역 -->
                        </div>
                        <div class="col-xs-12 col-sm-6 tbl-option-right-wrap">
                            <section class="tbl-contents">
                                <div class="table-responsive table-responsive-height">
                                    <table class="table tbl-list table-hover table-bordered" id="tb1">
                                        <colgroup>
                                            <col class="chk" />
                                            <col />
                                            <col />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th class="text-center">
                                                    <label for="cbAll1" class="hidden">전체선택</label>
                                                    <input name="cbAll1" type="checkbox" @click="checkedAllEvent($event.target.checked, 'cb1')">
                                                </th>
                                                <th class="text-center">프로그램 분류</th>
                                                <th class="text-center">프로그램명</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="ProgramList === null">
                                                <td colspan="3" class="text-center no-data">조회된 정보가 없습니다.</td>
                                            </tr>
                                            <tr v-else v-for="item in ProgramList">
                                                <td class="chk">
                                                    <input type="checkbox" name="cb1"
                                                           :corpcd="item.CorpCd"
                                                           :systid="item.SystId"
                                                           :progrid="item.ProgrId"
                                                           :progrname="item.ProgrName" />
                                                </td>
                                                <td>{{item.ProgrClsName}}</td>
                                                <td>{{item.ProgrName}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle'
    export default {
        name: 'ZZ1021R',
        components: {
            ViewTitle
        },
        data() {
            return {
                SystemIdList: null,
                ProgrClsList: null,
                MenuList: null,
                ProgramList: null
            }
        },
        beforeMount() {

            // 공통코드 조회
            this.SystemIdList = $.scriptBase.getCommonCode('CODE090', this.$parent.CompanyCode, '', '')
            this.ProgrClsList = $.scriptBase.getCommonCode('CODE000', '0901000', '', '')
        },
        mounted() {

            if (this.$parent.CanSearch) {

                this.fn_ShowData()
            }
        },
        methods: {
            checkedAllEvent: function (isChecked, targetElementName) {

                document.getElementsByName(targetElementName).forEach(function (item) {

                    item.checked = isChecked
                })

                if (targetElementName === 'cb0') {

                    document.getElementsByName('cbAll').forEach(function (item) {

                        item.checked = isChecked
                    })
                }
                else if (targetElementName === 'cb1') {

                    document.getElementsByName('cbAll1').forEach(function (item) {

                        item.checked = isChecked
                    })
                }
            },
            fn_ShowProg: function () {

                var parent = this
                var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/GetMenuProgram'
                url = url.toRealServiceUrl()
                var obj = new Object();
                obj.prm = new Array();
                obj.prm.push("S2");
                obj.prm.push(this.$parent.EmpId);
                obj.prm.push(this.$parent.CompanyCode);
                obj.prm.push($("#selSystId").val());
                obj.prm.push("");
                obj.prm.push($("#selProgrCls").val());
                obj.prm.push("");

                var successcallback = function (data) {

                    var table = JSON.parse(data.d).Table;

                    if (table.length > 0) {

                        parent.ProgramList = table
                    }
                    else {

                        parent.ProgramList = null
                    }

                    parent.checkedAllEvent(false, 'cb1')
                }

                $.scriptBase.executeAjax(url, obj, successcallback);
            },
            fn_ShowChld: function () {

                var parent = this
                var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/GetMenuProgram'
                url = url.toRealServiceUrl()
                var obj = new Object()
                obj.prm = new Array()
                obj.prm.push('S1')
                obj.prm.push(this.$parent.EmpId)
                obj.prm.push(this.$parent.CompanyCode)
                obj.prm.push($('#selSystId').val())
                obj.prm.push($('#txtPrntMenuId').val())
                obj.prm.push($('#selProgrCls').val())
                obj.prm.push('')

                var successcallback = function (data) {
                    
                    var table = JSON.parse(data.d).Table;
                    var table1 = JSON.parse(data.d).Table1;

                    if (table.length > 0) {

                        parent.MenuList = table
                        parent.checkedAllEvent(false, 'cb0')
                    }
                    else {

                        parent.MenuList = null
                    }

                    if (table1.length > 0) {

                        parent.ProgramList = table1
                        parent.checkedAllEvent(false, 'cb1')
                    }
                    else {

                        parent.ProgramList = null
                    }
                }

                $.scriptBase.executeAjax(url, obj, successcallback);
            },
            fn_ChldTree: function (pid, $dt) {
                var parent = this
                var html = "";
                var child = $.grep($dt, function (o, i) { return (o.PrntMenuId == pid); });

                if (child.length > 0) {
                    html += "<ul>";
                    $.each(child, function (i, o) {
                        if (o.ChildreYn == "Y") {
                            html += "<li menuid=\"" + o.MenuId + "\" levelno=\"" + o.LevelNo + "\">" + o.MenuName;
                            html += parent.fn_ChldTree(o.MenuId, $dt);
                            html += "</li>";
                        } else {
                            html += "<li menuid=\"" + o.MenuId + "\" levelno=\"" + o.LevelNo + "\">" + o.MenuName + "</li>";
                        }
                    });

                    html += "</ul>";
                }

                return html;
            },
            fn_ShowData: function () {

                var parent = this
                var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/GetMenu'
                url = url.toRealServiceUrl()
                var obj = new Object()
                obj.prm = new Array()
                obj.prm.push('T')
                obj.prm.push(this.$parent.EmpId)
                obj.prm.push(this.$parent.CompanyCode)
                obj.prm.push($('#selSystId').val())

                for (var i = 0; i < 4; i++) obj.prm.push('');

                var successCallback = function (data) {

                    var json = JSON.parse(data.d);
                    var $dt0 = json.Table;
                    var $dt1 = json.Table1;
                    var $dt2 = json.Table2;

                    if (typeof $dt0 != "undefined" && typeof $dt1 != "undefined" && $dt0.length > 0 && $dt1.length > 0) {

                        var html = "";

                        $.each($dt0, function (i, o) {

                            if (o.ChildreYn == "Y") {
                                html += "<li menuid=\"" + o.MenuId + "\" levelno=\"" + o.LevelNo + "\" data-jstree='{\"opened\":true }'>" + o.MenuName;
                                var child = $.grep($dt1, function (oo, ii) { return (oo.PrntMenuId == o.MenuId); });

                                if (child.length > 0) {
                                    html += "<ul>";
                                    $.each(child, function (ii, oo) {
                                        var seloption = "";
                                        if (ii == 0) {
                                            $("#txtPrntMenuName").val(oo.MenuName);
                                            $("#txtPrntMenuId").val(oo.MenuId);
                                            $("#hidLevelNo").val(oo.LevelNo);
                                            seloption = "data-jstree='{\"selected\":true, \"opened\":true }' ";
                                            parent.fn_ShowChld();
                                        } else {
                                            seloption = "";
                                        }

                                        if (oo.ChildreYn == "Y") {
                                            html += "<li menuid=\"" + oo.MenuId + "\" levelno=\"" + oo.LevelNo + "\" " + seloption + ">" + oo.MenuName;
                                            html += parent.fn_ChldTree(oo.MenuId, $dt2);
                                            html += "</li>";
                                        } else {
                                            html += "<li menuid=\"" + oo.MenuId + "\" levelno=\"" + oo.LevelNo + "\" " + seloption + ">" + oo.MenuName + "</li>";
                                        }
                                    });
                                    html += "</ul>";
                                }
                                html += "</li>";

                            } else {
                                html += "<li menuid=\"" + o.MenuId + "\" levelno=\"" + o.LevelNo + "\" data-jstree='{\"opened\":true }'>" + o.MenuName + "</li>";
                            }
                        });

                        $("#treeContents10").empty().append("<ul>" + html + "</ul>").jstree("destroy").jstree().unbind("select_node.jstree").bind("select_node.jstree", function (evt, data) {
                            $("#txtPrntMenuName").val(data.node.text);
                            $("#txtPrntMenuId").val(data.node.li_attr.menuid);
                            $("#hidLevelNo").val(data.node.li_attr.levelno);
                            parent.fn_ShowChld();
                        });
                    }
                };
                $.scriptBase.executeAjax(url, obj, successCallback);
            },
            fn_InstData: function () {

                var parent = this
                var targetElement = document.querySelectorAll('#tb1 tbody input[type=checkbox]:checked')

                if (targetElement.length == 0) {

                    alert('프로그램을 선택하세요.')
                    return
                }

                targetElement.forEach(function (target) {

                    var companyCd = target.getAttribute('corpcd')
                    var systemId = target.getAttribute('systid')
                    var programId = target.getAttribute('progrid')
                    var programName = target.getAttribute('progrname')

                    var obj = {
                        ProgrName: programName
                        , DispName: programName
                        , RowSeqNo: null
                        , CorpCd: companyCd
                        , SystId: systemId
                        , MenuId: null
                        , PrntMenuId: null
                        , ProgrId: programId
                        , CRUD: 'I'
                    }

                    if (parent.MenuList === null) {

                        parent.MenuList = new Array()
                    }
                    // 메뉴 추가
                    parent.MenuList.push(obj)
                })
            },
            fn_SaveData: function () {
                
                var parent = this
                var targetElement = document.querySelectorAll('#tb0 tbody tr')

                var tfs = true;
                var msg = "";
                var obj = null;

                if (targetElement.length === 0) {

                    alert("저장항목이 없습니다.")
                    return
                }

                for (var i = 0; i < targetElement.length; i++) {

                    var menuDisplayName = targetElement[i].querySelector('input[type=text][name=txtDispName0]')
                    var rowSeqNoElement = targetElement[i].querySelector('input[type=text][name=txtRowSeqNo0]')

                    if (menuDisplayName.value === '') {

                        alert('메뉴 표시명을 입력하세요.')
                        menuDisplayName.focus()

                        return;
                    }

                    if (rowSeqNoElement.value === '') {

                        alert('순번을 입력하세요.')
                        rowSeqNoElement.focus()

                        return;
                    }

                    if (isNaN(rowSeqNoElement.value)) {

                        alert('숫자만 입력하세요.')
                        rowSeqNoElement.focus()

                        return;
                    }
                }

                var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/ManipulateMenuProgram'
                url = url.toRealServiceUrl()
                var obj = new Object();
                obj.prm = new Array();

                this.MenuList.forEach(function (menuItem) {

                    var tempArray = new Array()
                    tempArray.push("I")
                    tempArray.push(parent.$parent.EmpId)
                    tempArray.push(menuItem.CorpCd)
                    tempArray.push(menuItem.SystId)
                    tempArray.push($("#txtPrntMenuId").val())
                    tempArray.push(menuItem.MenuId === null ? '' : menuItem.MenuId)
                    tempArray.push(menuItem.ProgrName)
                    tempArray.push(menuItem.RowSeqNo)
                    tempArray.push(menuItem.ProgrId)
                    obj.prm.push(tempArray)
                })

                var successCallback = function (data) {

                    parent.fn_ShowData()
                }

                $.scriptBase.executeAjax(url, obj, successCallback);
            },
            fn_RmveData: function () {

                var parent = this
                var targetElement = document.querySelectorAll('#tb0 tbody tr td input[type=checkbox][name=cb0]:checked')

                if (targetElement.length === 0) {

                    alert('삭제 할 항목을 선택하세요.')
                    return
                }

                if (confirm('삭제하시겠습니까?')) {

                    var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/ManipulateMenuProgram'
                    url = url.toRealServiceUrl()
                    var obj = new Object();
                    obj.prm = new Array();

                    targetElement.forEach(function (target) {

                        var crud = target.getAttribute('crud')
                        var companyCd = target.getAttribute('corpcd')
                        var systemId = target.getAttribute('systid')
                        var programId = target.getAttribute('progrid')
                        var menuId = target.getAttribute('menuid')

                        if (crud === 'R') {

                            var tempArray = new Array();
                            tempArray.push('D');
                            tempArray.push(parent.$parent.EmpId);
                            tempArray.push(companyCd);
                            tempArray.push(systemId);
                            tempArray.push('');
                            tempArray.push(menuId);
                            tempArray.push('');
                            tempArray.push('');
                            tempArray.push(programId);
                            obj.prm.push(tempArray);
                        }
                    })

                    if (obj.prm.length > 0) {

                        var successCallback = function (data) {

                            parent.fn_ShowData()
                        }

                        $.scriptBase.executeAjax(url, obj, successCallback);
                    }
                    else {

                        this.fn_ShowData()
                    }
                }
            }
        }
    }
</script>

<style scoped>
</style>