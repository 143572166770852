<template>
    <div>
        <!-- 은행데이터 매칭 -->
        <div class="modal fade" id="AC1101E_Modal" ref="AC1101E_Modal" tabindex="-1" role="dialog" aria-labelledby="AC1101E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" @click.prevent="close">
                            <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                        </button>
                        <h3 class="modal-title">은행데이터-입출금관리 매칭</h3>
                    </div>
                    <!-- 매칭 -->
                    <div class="modal-body">
                        <section>
                            <div class="sub-title">
                                <p class="sub-title-txt">은행데이터</p>
                                <div class="btn-wrap">
                                    <a href="#" id="btnBankSearch" class="btn btn-primary" @click.prevent="showBkLogAndAcDetail">
                                        <span>
                                            <i class="glyphicon glyphicon-search"></i> 조회
                                        </span>
                                    </a>
                                    <a href="#" id="btnAutoMatch" class="btn btn-warning" @click.prevent="openSndModal">
                                        <span>자동매칭</span>
                                    </a>
                                </div>
                            </div>
                            <section class="sch-wrap">
                                <form role="form">
                                    <fieldset>
                                        <legend>검색폼</legend>
                                        <section class="sch-cont">
                                            <section class="cols-wrap">
                                                <div class="cols col-4">
                                                    <label for="txtDate" class="control-label"><span>등록일자</span></label>
                                                    <div class="input-group date">
                                                        <DxDateBox pickerType="calendar"
                                                                   display-format="yyyy-MM-dd"
                                                                   v-model="ManualListSelectTerm.TargetDate"
                                                                   @value-changed="showBkLogAndAcDetail"
                                                                   style="border-radius:0;" />
                                                    </div>
                                                </div>
                                                <div class="cols col-4">
                                                    <label for="selMatchYn" class="control-label"><span>매칭상태</span></label>
                                                    <select class="form-control" id="selMatchYn" v-model="ManualListSelectTerm.MatchStateCd" @change="showBkLogAndAcDetail">
                                                        <option value="">== 전체 ==</option>
                                                        <option v-for="item in MatchStateCodeList" v-bind:value="item.Code">
                                                            {{item.Name}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="cols col-4">
                                                    <label for="selAutoYn" class="control-label"><span>자동매칭여부</span></label>
                                                    <select class="form-control" id="selAutoYn" v-model="ManualListSelectTerm.AutoMatchYn" @change="showBkLogAndAcDetail">
                                                        <option value="">== 전체 ==</option>
                                                        <option value="Y">Y</option>
                                                        <option value="N">N</option>
                                                    </select>
                                                </div>
                                            </section>
                                        </section>
                                    </fieldset>
                                </form>
                            </section>
                            <section class="tbl-contents">
                                <div class="table-responsive">
                                    <DxDataGrid id="grid-container"
                                                :data-source="BankDataList"
                                                :show-borders="false"
                                                :hoverStateEnabled="true"
                                                :allow-column-resizing="true"
                                                :column-resizing-mode="'widget'"
                                                :row-alternation-enabled="true"
                                                :column-auto-width="true"
                                                @cell-prepared="onCellPrepared"
                                                @selection-changed="onBankSelectionChanged"
                                                :selected-row-keys="selectedBankKeys"
                                                style="max-height: 200px; vertical-align: top;">
                                        <DxScrolling mode="standard" useNative="false" />
                                        <DxSelection mode="multiple" :show-check-boxes-mode="'always'" :allow-select-all="false" />
                                        <DxColumnFixing :enabled="true" />
                                        <DxPaging :enabled="false" />
                                        <DxColumn data-field="FileUploadYMD" caption="등록일자" alignment="center" data-type="date" format="yyyy-MM-dd" />
                                        <DxColumn data-field="TradeYMD" caption="거래일자" alignment="center" data-type="date" format="yyyy-MM-dd" />
                                        <DxColumn data-field="OutAmt" caption="출금액" alignment="right" format="fixedPoint" />
                                        <DxColumn data-field="InAmt" caption="입금액" alignment="right" format="fixedPoint" />
                                        <DxColumn data-field="MatchedAmt" caption="실행매칭액" alignment="right" format="fixedPoint" />
                                        <DxColumn data-field="TradeCont" caption="계좌거래내용" alignment="left" />
                                        <DxColumn data-field="OpptAcntNo" caption="상대계좌번호" alignment="left" />
                                        <DxColumn data-field="InOutLogMemo" caption="메모" alignment="left" />
                                        <DxColumn data-field="OpptBankNm" caption="상대은행" alignment="center" />
                                        <DxColumn data-field="TradeDivNm" caption="거래구분" alignment="center" />
                                        <DxColumn data-field="OpptAcntNm" caption="상대계좌예금주명" alignment="center" />
                                        <DxColumn data-field="MatchStateNm" caption="매칭상태" alignment="center" />
                                        <DxColumn data-field="AutoMatchYn" caption="자동매칭여부" alignment="center" />
                                    </DxDataGrid>
                                </div>
                            </section>
                        </section>
                        <section>
                            <div class="sub-title">
                                <p class="sub-title-txt">입출금관리</p>
                            </div>
                            <section class="tbl-contents">
                                <div class="table-responsive">
                                    <DxDataGrid id="grid-container"
                                                :data-source="AccountDetailList"
                                                :show-borders="false"
                                                :hoverStateEnabled="true"
                                                :allow-column-resizing="true"
                                                :column-resizing-mode="'widget'"
                                                :row-alternation-enabled="true"
                                                :column-auto-width="true"
                                                @cell-prepared="onCellPrepared"
                                                @selection-changed="onAccountSelectionChanged"
                                                :selected-row-keys="selectedAccountKeys"
                                                style="max-height: 200px; vertical-align: top;">
                                        <DxScrolling mode="standard" useNative="false" />
                                        <DxSelection mode="multiple" :show-check-boxes-mode="'always'" />
                                        <DxColumnFixing :enabled="true" />
                                        <DxPaging :enabled="false" />
                                        <DxColumn data-field="AccSrcDvsName" caption="출처" alignment="center" />
                                        <DxColumn data-field="AccountDetailNm" caption="입/출금명" alignment="left" />
                                        <DxColumn data-field="AcntSubjtNm" caption="계정과목" alignment="center" />
                                        <DxColumn data-field="OutAmt" caption="출금액" alignment="right" format="fixedPoint" />
                                        <DxColumn data-field="InAmt" caption="입금액" alignment="right" format="fixedPoint" />
                                        <DxColumn data-field="InOutYMD" caption="입/출예정일자" alignment="center" data-type="date" format="yyyy-MM-dd" />
                                        <DxColumn data-field="ExeOutAmt" caption="실행출금액" alignment="right" format="fixedPoint" />
                                        <DxColumn data-field="ExeInAmt" caption="실행입금액" alignment="right" format="fixedPoint" />
                                        <DxColumn data-field="InOutStateNm" caption="입/출금상태" alignment="center" />
                                        <DxColumn data-field="TradeYMD" caption="거래일자" alignment="center" data-type="date" format="yyyy-MM-dd" />
                                        <DxColumn data-field="TradeCont" caption="계좌거래내용" alignment="left" />
                                        <DxColumn data-field="OpptAcntNo" caption="상대계좌번호" alignment="left" />
                                        <DxColumn data-field="InOutLogMemo" caption="메모" alignment="left" />
                                        <DxColumn data-field="OpptBankNm" caption="상대은행" alignment="center" />
                                        <DxColumn data-field="TradeDivNm" caption="거래구분" alignment="center" />
                                    </DxDataGrid>
                                </div>
                            </section>
                        </section>
                    </div>
                    <div class="modal-footer btn-align">
                        <div class="btn-group">
                            <button type="button" class="btn btn-primary" @click.prevent="saveMatchedData">
                                <span class="glyphicon glyphicon-save"></span>저장
                            </button>
                        </div>
                        <div class="btn-group">
                            <button type="button" class="btn btn-danger" @click.prevent="close">
                                <span class="glyphicon glyphicon-remove"></span>취소
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="AC1102E_Modal" tabindex="-1" role="dialog" aria-labelledby="defaultModal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" @click.prevent="closeSndModal">
                            <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                        </button>
                        <h3 class="modal-title">은행데이터-입출금관리 자동매칭</h3>
                    </div>
                    <div class="modal-body">
                        <section class="sch-wrap">
                            <form role="form">
                                <fieldset>
                                    <legend>검색폼</legend>
                                    <section class="sch-cont">
                                        <div class="cols-wrap">
                                            <div class="cols col-3">
                                                <label for="AC1102E_txtDate" class="control-label"><span>등록일자</span></label>
                                                <div class="input-group date">
                                                    <DxDateBox pickerType="calendar"
                                                               display-format="yyyy-MM-dd"
                                                               v-model="AutoListSelectTerm.TargetDate"
                                                               @value-changed="showAutoMatchData"
                                                               style="border-radius:0;" />
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </fieldset>
                            </form>
                        </section>
                        <div class="sub-title" style="margin-top: 10px;">
                            <p class="sub-title-txt">자동매칭 목록</p>
                        </div>
                        <section class="tbl-contents">
                            <div class="table-responsive">
                                <DxDataGrid id="grid-container"
                                            :data-source="AutoMatchedDataList"
                                            @cell-prepared="onCellPrepared"
                                            @selection-changed="onMatchSelectionChanged"
                                            :selected-row-keys="autoMatchedKeys"
                                            style="max-height: 400px; vertical-align: top;">
                                    <DxScrolling mode="standard" useNative="false" />
                                    <DxSelection mode="multiple" :show-check-boxes-mode="'always'" />
                                    <DxColumnFixing :enabled="true" />
                                    <DxPaging :enabled="false" />
                                    <DxColumn caption="은행데이터">
                                        <DxColumn data-field="TradeYMD" caption="거래일자" alignment="center" data-type="date" format="yyyy-MM-dd" />
                                        <DxColumn data-field="TradeCont" caption="계좌거래내용" alignment="left" />
                                        <DxColumn data-field="BkOutAmt" caption="출금액" alignment="right" format="fixedPoint" />
                                        <DxColumn data-field="BkInAmt" caption="입금액" alignment="right" format="fixedPoint" />
                                    </DxColumn>
                                    <DxColumn caption="입출금관리">
                                        <DxColumn data-field="InOutYMD" caption="입/출예정일자" alignment="center" data-type="date" format="yyyy-MM-dd" />
                                        <DxColumn data-field="AccountDetailNm" caption="입/출금명" alignment="left" />
                                        <DxColumn data-field="AcOutAmt" caption="출금액" alignment="right" format="fixedPoint" />
                                        <DxColumn data-field="AcInAmt" caption="입금액" alignment="right" format="fixedPoint" />
                                    </DxColumn>
                                </DxDataGrid>
                            </div>
                        </section>
                    </div>
                    <div class="modal-footer btn-align">
                        <div class="btn-group">
                            <button type="button" class="btn btn-primary" @click.prevent="saveMatchedData">
                                <span class="glyphicon glyphicon-save"></span>저장
                            </button>
                        </div>
                        <div class="btn-group">
                            <button type="button" class="btn btn-danger" @click.prevent="closeSndModal">
                                <span class="glyphicon glyphicon-remove"></span>취소
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { DxDataGrid, DxColumn, DxPaging, DxScrolling, DxColumnFixing, DxSorting, DxSelection, } from 'devextreme-vue/data-grid';
    import DxDateBox, { DxCalendarOptions } from 'devextreme-vue/date-box';

    export default {
        name: 'AC1101E',
        components: {
            DxDateBox, DxCalendarOptions, DxDataGrid, DxColumn, DxPaging, DxScrolling, DxColumnFixing, DxSorting, DxSelection,
        },
        data() {
            return {
                // 매칭상태 코드
                MatchStateCodeList: new Array(),
                // 목록 조회조건
                ManualListSelectTerm: {
                    Arg: 'ML',
                    TargetDate: this.moment().format('YYYY-MM-DD'),
                    MatchStateCd: '',
                    AutoMatchYn: '',
                },
                // 자동매칭 조건
                AutoListSelectTerm: {
                    Arg: 'AL',
                    TargetDate: this.moment().format('YYYY-MM-DD'),
                    MatchStateCd: '',
                    AutoMatchYn: '',
                },
                // 매칭모드
                MatchingMode: '',
                // 은행데이터 목록
                BankDataList: new Array(),
                // 입출금관리 목록
                AccountDetailList: new Array(),
                // 은행데이터 체크박스 키
                selectedBankKeys: new Array(),
                // 입출금관리 체크박스 키
                selectedAccountKeys: new Array(),
                // 매칭된 데이터 목록
                MatchedDataList: new Array(),
                // 자동매칭 데이터 목록
                AutoMatchedDataList: new Array(),
                // 자동매칭 체크박스 키
                autoMatchedKeys: new Array(),
            }
        },
        beforeMount() {

            this.MatchStateCodeList = $.scriptBase.getCommonCode('CODE000', '0519000', '', '');
        },
        methods: {
            open(callbackFunction) {

                this.CallbackFunction = callbackFunction;
                $("#AC1101E_Modal").modal("show");
                this.MatchingMode = 'Manual';
                this.showBkLogAndAcDetail();
            },
            close() {

                $("#AC1101E_Modal").modal("hide");
                this.ManualListSelectTerm = {
                    Arg: 'ML',
                    TargetDate: this.moment().format('YYYY-MM-DD'),
                    MatchStateCd: '',
                    AutoMatchYn: '',
                };
                this.BankDataList = new Array();
                this.AccountDetailList = new Array();
                this.CallbackFunction();
            },
            // 자동매칭 모달 열기, 자동매칭 메소드 실행
            openSndModal() {

                $("#AC1102E_Modal").modal("show");
                this.MatchingMode = 'Auto';
                this.showAutoMatchData();
            },
            // 자동매칭 모달 닫기
            closeSndModal() {

                $("#AC1102E_Modal").modal("hide");
                this.AutoListSelectTerm = {
                    Arg: 'AL',
                    TargetDate: this.moment().format('YYYY-MM-DD'),
                    MatchStateCd: '',
                    AutoMatchYn: '',
                };
                this.AutoMatchedDataList = new Array();
                this.autoMatchedKeys = new Array();
                this.MatchingMode = 'Manual';
            },
            // DataGrid Header 가운데 정렬, Column Data 중앙 정렬
            onCellPrepared(e) {

                if (e.rowType == 'header') {
                    e.cellElement.style['textAlign'] = 'center';
                }
                if (e.rowType == 'data') {
                    e.cellElement.style['verticalAlign'] = 'middle';
                }
            },
            // 은행데이터-입출금관리 매칭가능 목록 조회
            showBkLogAndAcDetail() {

                var parent = this;
                this.selectedBankKeys = new Array();
                this.selectedAccountKeys = new Array();

                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/SelectInOutAmtLogToMatch'.toRealServiceUrl();
                var obj = new Object();

                this.ManualListSelectTerm.TargetDate = this.moment(this.ManualListSelectTerm.TargetDate).format('YYYYMMDD');

                obj.jsonString = JSON.stringify(this.ManualListSelectTerm);

                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d);
                    var table = jsonData.Table;
                    var table1 = jsonData.Table1;

                    // 은행데이터 목록
                    parent.BankDataList = (table.length > 0) ? table : new Array();
                    // 입출금관리 목록
                    parent.AccountDetailList = (table1.length > 0) ? table1 : new Array();
                }
                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 선택한 은행데이터
            onBankSelectionChanged(e) {

                // 하나의 로우만 선택할 수 있도록 설정
                this.selectedBankKeys = (e.selectedRowKeys.length > 1) ? e.currentSelectedRowKeys : e.selectedRowKeys;
            },
            // 선택한 입출금관리
            onAccountSelectionChanged(e) {

                this.selectedAccountKeys = e.selectedRowKeys;
            },
            // 선택된 매칭 데이터 가공
            setMatchedData() {

                this.MatchedDataList = new Array();

                let MatchedDataInfo = {
                    Arg: 'A',
                    UserId: this.$parent.$parent.EmpId,
                    SeqNo: 0,
                    InOutLogSeq: 0,
                    AutoMatchYn: 'N',
                    ExeInAmt: 0,
                    ExeOutAmt: 0,
                    AfterTradeAmt: 0,
                    ExeInOutYMD: this.moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                };

                if (this.MatchingMode == 'Manual') {

                    for (let i = 0; i < this.selectedAccountKeys.length; i++) {

                        let NewMatchedData = { ...MatchedDataInfo };

                        NewMatchedData.SeqNo = this.selectedAccountKeys[i].SeqNo;
                        NewMatchedData.InOutLogSeq = this.selectedBankKeys[0].InOutLogSeq;
                        NewMatchedData.ExeInAmt = this.selectedBankKeys[0].InAmt;
                        NewMatchedData.ExeOutAmt = this.selectedBankKeys[0].OutAmt;
                        NewMatchedData.AfterTradeAmt = this.selectedBankKeys[0].AfterTradeAmt;
                        NewMatchedData.ExeInOutYMD = this.moment(this.selectedBankKeys[0].TradeYMD).format('YYYY-MM-DD HH:mm:ss');

                        this.MatchedDataList.push(NewMatchedData);
                    }
                }

                if (this.MatchingMode == 'Auto') {

                    for (let i = 0; i < this.autoMatchedKeys.length; i++) {

                        let NewMatchedData = { ...MatchedDataInfo };

                        NewMatchedData.SeqNo = this.autoMatchedKeys[i].SeqNo;
                        NewMatchedData.InOutLogSeq = this.autoMatchedKeys[i].InOutLogSeq;
                        NewMatchedData.AutoMatchYn = 'Y';
                        NewMatchedData.ExeInAmt = this.autoMatchedKeys[i].BkInAmt;
                        NewMatchedData.ExeOutAmt = this.autoMatchedKeys[i].BkOutAmt;
                        NewMatchedData.AfterTradeAmt = this.autoMatchedKeys[i].AfterTradeAmt;
                        NewMatchedData.ExeInOutYMD = this.moment(this.autoMatchedKeys[i].TradeYMD).format('YYYY-MM-DD HH:mm:ss');

                        this.MatchedDataList.push(NewMatchedData);
                    }
                }
            },
            // 자동매칭 목록 조회
            showAutoMatchData() {

                var parent = this;
                this.AutoMatchedDataList = new Array();

                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/SelectInOutAmtLogToMatch'.toRealServiceUrl();
                var obj = new Object();

                this.AutoListSelectTerm.TargetDate = this.moment(this.AutoListSelectTerm.TargetDate).format('YYYYMMDD');
                obj.jsonString = JSON.stringify(this.AutoListSelectTerm);

                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d);
                    var table = jsonData.Table;

                    // 자동매칭된 데이터 목록
                    parent.AutoMatchedDataList = (table.length > 0) ? table : new Array();
                    // 체크박스 전체 설정
                    parent.autoMatchedKeys = (table.length > 0) ? table : new Array();
                }
                $.scriptBase.executeAjaxAsync(url, obj, successCallback);

            },
            // 자동매칭 체크박스 변경
            onMatchSelectionChanged(e) {

                this.autoMatchedKeys = e.selectedRowKeys;
            },
            // 매칭된 데이터 저장 메소드
            saveMatchedData() {

                var parent = this;

                if (this.MatchingMode == 'Manual' && (this.selectedBankKeys.length == 0 || this.selectedAccountKeys.length == 0)) {

                    alert('저장할 매칭 데이터가 없습니다.');
                    return;
                }
                if (this.MatchingMode == 'Auto' && this.autoMatchedKeys.length == 0) {

                    alert('저장할 자동매칭 데이터가 없습니다.');
                    return;
                }

                this.setMatchedData();

                var url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/ManipulateInOutAmtLogMap'.toRealServiceUrl();
                var obj = new Object();

                obj.jsonString = JSON.stringify(this.MatchedDataList);

                if (confirm('선택한 매칭을 저장하시겠습니까?')) {

                    var successCallback = function (data) {

                        if (data.d > 0) {

                            alert('저장되었습니다.');
                            parent.MatchedDataList = new Array();

                            if (parent.MatchingMode == 'Manual') {

                                parent.showBkLogAndAcDetail();
                                return;
                            }
                            if (parent.MatchingMode == 'Auto') {

                                parent.showAutoMatchData();
                                return;
                            }
                        } else {

                            alert('저장에 실패했습니다. 잠시 후 다시 시도해주세요.');
                            parent.MatchedDataList = new Array();
                        }
                    }
                    $.scriptBase.executeAjaxAsync(url, obj, successCallback);
                }
            },
        },
    }
</script>