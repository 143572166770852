<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a href="#" id="btnListSrch" class="btn btn-primary btn-row2" v-show="this.$parent.CanSearch" @click.prevent="getManpowerList()">
                    <span>
                        <i class="glyphicon glyphicon-search"></i> 조회
                    </span>
                </a>
                <a href="#" id="btnListInst" class="btn btn-default" @click.prevent="addManpower">
                    <span>
                        <i class="glyphicon glyphicon-plus"></i> 생성
                    </span>
                </a>
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-4">
                                        <label for="selTrgtYY" class="control-label" style="text-align:center">
                                            <span>년도</span>
                                        </label>
                                        <select id="selTrgtYY" class="form-control" v-model="ProjectMMListTerm.TargetYear" @change="getManpowerList">
                                            <option v-for="year in YearsList" v-bind:value="year">
                                                {{year}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-4">
                                        <label for="selProjectStatus" class="control-label">
                                            <span>프로젝트 상태</span>
                                        </label>
                                        <select id="selProjectStatus" class="form-control" v-model="ProjectMMListTerm.StatusCd" @change="getManpowerList">
                                            <option value="">전체</option>
                                            <option v-for="status in StatusCodeList" v-bind:value="status.Code">
                                                {{status.Name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtCompanyNm" class="control-label">
                                            <span>프로젝트</span>
                                        </label>
                                        <div class="cols-btns-cont pad-r150">
                                            <input type="text" id="txtProjectNm" class="form-control" readonly="readonly" v-model="ProjectMMListTerm.ProjectNm" />
                                            <input type="hidden" id="hidProjectCd" v-model="ProjectMMListTerm.ProjectCd" />
                                        </div>
                                        <div class="cols-btns">
                                            <a href="#" id="btnListPop1" class="btn btn-default" @click.prevent="searchProject">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i> 검색
                                                </span>
                                            </a>
                                            <a href="#" id="btnSrchInit" class="btn btn-default"
                                               @click.prevent="function(){
                                                    ProjectMMListTerm.ProjectCd = null
                                                    ProjectMMListTerm.ProjectNm = null
                                               }">
                                                <span>
                                                    <i class="glyphicon glyphicon-repeat"></i> 초기화
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </section>
                            </section>
                        </fieldset>
                    </form>
                </div>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">인력운용 관리</p>
                </div>
                <div>
                    <!--리스트 영역-->
                    <section class="tbl-contents">
                        <div class="alert alert-info total">
                            <p>
                                <span class="glyphicon glyphicon-folder-close"></span>
                                <span>
                                    전체: <span id="spTotCnt">{{ProjectMMListTotalCount}}</span>건
                                </span>
                            </p>
                        </div>
                        <div class="table-responsive">
                            <!--마스터 그리드 영역-->
                            <DxDataGrid id="grid-container"
                                        :show-borders="false"
                                        :data-source="ProjectMMList"
                                        :hoverStateEnabled="true"
                                        @cell-prepared="onCellPrepared">
                                <DxLoadPanel :enabled="false" />
                                <DxScrolling mode="standard" useNative="false" />
                                <DxPaging :enabled="false" />
                                <DxColumn cell-template="detail" width="5%" alignment="center" />
                                <template #detail="{ data }">
                                    <DxButton icon="chevronnext" styling-mode="text" @click="onCollapseClick(data)" />
                                </template>
                                <DxColumn data-field="ProjectNm"
                                          caption="프로젝트명"
                                          alignment="left"
                                          :group-index="0" />
                                <DxColumn data-field="RoleSeq"
                                          caption="차수"
                                          alignment="center" />
                                <DxColumn data-field="Confirmation"
                                          caption="확정여부"
                                          alignment="center" />
                                <DxColumn data-field="TotalInputMM"
                                          caption="총 투입 MM"
                                          alignment="center" />
                                <DxMasterDetail :enabled="false"
                                                template="masterDetailTemplate" />
                                <template #masterDetailTemplate="{ data }">
                                    <PM1070R_Detail :template-data="data" />
                                </template>
                            </DxDataGrid>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <CP1060P ref="CP1060P"></CP1060P>
        <PM1006E ref="PM1006E"></PM1006E>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle';
    import { DxDataGrid, DxColumn, DxPaging, DxMasterDetail, DxScrolling, DxLoadPanel } from 'devextreme-vue/data-grid';
    import { DxButton } from 'devextreme-vue';
    import CP1060P from '@/components/libs/popup/CP1060P';
    import PM1006E from '@/components/pm/popup/PM1006E';
    import PM1070R_Detail from '@/components/pm/libs/PM1070R_Detail';

    export default {
        name: 'PM1070R',
        components: {
            ViewTitle, CP1060P, PM1006E, PM1070R_Detail, DxDataGrid, DxColumn, DxPaging, DxMasterDetail, DxButton, DxScrolling, DxLoadPanel
        },
        data() {
            return {
                // 프로젝트 상태 목록
                StatusCodeList: new Array(),
                // 년도 목록
                YearsList: new Array(),
                // 인력운용 목록 조회조건 객체
                ProjectMMListTerm: {
                    Arg: 'N',
                    ProjectCd: null,
                    ProjectNm: '',
                    RoleSeq: null,
                    TargetYear: this.moment(new Date()).year(),
                    StatusCd: '0701030',
                },
                // 인력운용관리 목록 전체 건수
                ProjectMMListTotalCount: 0,
                // 인력운용관리 목록
                ProjectMMList: new Array(),
                // 마스터 그리드 키
                expandRowKey: null
            }
        },
        beforeMount() {

            // 공통코드 조회
            this.StatusCodeList = $.scriptBase.getCommonCode('CODE000', '0701000', '', '');

            var startYear = this.moment(new Date()).add(1, 'year').year();
            var endYear = this.moment(new Date(2013, 1, 1, 0, 0, 0)).year();

            // 대상년도 목록 값 설정
            for (var i = startYear; i >= endYear; i--) {

                this.YearsList.push(i);
            }
        },
        mounted() {

            this.getManpowerList();
        },
        watch: {
            // 조회조건 - 프로젝트 변경
            'ProjectMMListTerm.ProjectCd'(newData) {
                this.getManpowerList();
            },
        },
        methods: {
            // datagrid header 가운데 정렬, column 중앙 정렬
            onCellPrepared(e) {

                if (e.rowType == 'header') {

                    e.cellElement.style['textAlign'] = 'center';
                }
                if (e.rowType == 'data') {

                    e.cellElement.style['verticalAlign'] = 'middle';
                }
            },
            // 마스터 그리드 버튼 선택 이벤트
            onCollapseClick(e) {

                this.clicked = !this.clicked;
                let expanded = e.component.isRowExpanded(e.row.key);

                if (expanded) {

                    e.component.collapseAll(-1);
                    this.expandRowKey = null;
                } else {

                    e.component.collapseAll(-1);
                    e.component.expandRow(e.row.key);
                    this.expandRowKey = e.row.key;
                }
            },
            // 프로젝트 검색
            searchProject() {

                let parent = this;

                var successCallback = function (result) {

                    parent.ProjectMMListTerm.ProjectCd = result.ProjectCd;
                    parent.ProjectMMListTerm.ProjectNm = result.ProjectNm;
                    parent.ProjectMMListTerm.StatusCd = result.StatusCd;

                    // 프로젝트 선택 시 조회
                    parent.getManpowerList();
                }

                this.$refs.CP1060P.open(successCallback, null, null, true);
            },
            // 인력운용관리 리스트 조회
            getManpowerList() {

                let parent = this;

                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/GetNewManpower'.toRealServiceUrl();
                var obj = new Object();

                obj.jsonString = JSON.stringify(this.ProjectMMListTerm);

                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d);

                    parent.ProjectMMList = jsonData.Table;
                    parent.ProjectMMListTotalCount = jsonData.Table.length;
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 인력운용관리 계획 추가
            addManpower() {

                // 디테일 그리드 열려있으면 접기
                if (this.expandRowKey != null) {

                    this.$children[1].$_instance.collapseAll(-1);
                    this.expandRowKey = null;
                }

                let parent = this;

                var callbackFunction = function () {

                    parent.getManpowerList();
                }

                // 상세팝업 오픈
                this.$refs.PM1006E.open(callbackFunction, null);
            },
        }
    }
</script>