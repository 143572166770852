<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <a href="#" id="btnListSrch" class="btn btn-primary btn-row2" @click.prevent="getEstInOutList">
                    <span>
                        <i class="glyphicon glyphicon-search"></i> 조회
                    </span>
                </a>
            </div>
            <div class="sch-box">
                <!-- 날짜(from~to), 확정여부, 계정과목, 거래내용 -->
                <form class="form-horizontal" role="form">
                    <fieldset>
                        <legend>검색폼</legend>
                        <section class="sch-cont">
                            <section class="cols-wrap">
                                <div class="cols col-2">
                                    <label for="selDateType" class="control-label"><span>거래일자</span></label>
                                    <div class="col-xs-3">
                                        <select class="form-control" id="selDateType" v-model="EstInOutSelectTerm.ExeYN" @change="getEstInOutList">
                                            <option value="N">예상거래일자</option>
                                            <option value="Y">실거래일자</option>
                                        </select>
                                    </div>
                                    <div class="col-xs-9">
                                        <label for="txtStartDate" class="sr-only"><span>거래일자</span></label>
                                        <div class="col-2 input-group date">
                                            <DxDateBox display-format="yyyy-MM-dd"
                                                       pickerType="calendar"
                                                       v-model="EstInOutSelectTerm.StartDate"
                                                       @value-changed="getEstInOutList"
                                                       style="border-radius:0;" />
                                        </div>
                                        <div class="tail"><span>~</span></div>
                                        <label for="txtEndDate" class="sr-only"><span>거래일자</span></label>
                                        <div class="col-2 input-group date">
                                            <DxDateBox display-format="yyyy-MM-dd"
                                                       pickerType="calendar"
                                                       v-model="EstInOutSelectTerm.EndDate"
                                                       @value-changed="getEstInOutList"
                                                       style="border-radius:0;" />
                                        </div>
                                    </div>
                                </div>
                                <div class="cols col-4">
                                    <label for="selInOutConfirm" class="control-label">
                                        <span>입출확정여부</span>
                                    </label>
                                    <select class="form-control" id="selInOutConfirm" v-model="EstInOutSelectTerm.InOutConfirmYN" @change="getEstInOutList">
                                        <option value="">== 전체 ==</option>
                                        <option value="N">N</option>
                                        <option value="Y">Y</option>
                                    </select>
                                </div>
                                <div class="cols col-4">
                                    <label for="selAcntSubjt" class="control-label">
                                        <span>계정과목명</span>
                                    </label>
                                    <select class="form-control" id="selAcntSubjt" v-model="EstInOutSelectTerm.AcntSubjtCd" @change="getEstInOutList">
                                        <option value="">== 전체 ==</option>
                                        <option v-for="item in AcntSubjtCodeList" v-bind:value="item.Code">
                                            {{item.Name}}
                                        </option>
                                    </select>
                                </div>
                            </section>
                            <section class="cols-wrap">
                                <div class="cols col-3">
                                    <label for="txtTradeCont" class="control-label">
                                        <span>거래내용</span>
                                    </label>
                                    <input type="text" id="txtTradeCont" class="form-control txt" v-model="EstInOutSelectTerm.TradeCont" />
                                </div>
                            </section>
                        </section>
                    </fieldset>
                </form>
            </div>
            <div class="grid-wrap">
                <div style="display: flex; justify-content: space-between;">
                    <div class="sub-title">
                        <p class="sub-title-txt">예상입출관리</p>
                    </div>
                    <div class="cols-wrap" style="width: 40%; display: flex; justify-content: flex-end;">
                        <div class="cols col-2">
                            <label for="selInOutConfirm" class="control-label"><span>입출금확정</span></label>
                            <select class="form-control" id="selInOutConfirm" v-model="BatchInOutConfirmYN" @change="onConfirmInBatches" v-bind:disabled="BatchInOutConfirmYNDisabled">
                                <option value="">직접선택</option>
                                <option value="Y">Y</option>
                                <option value="N">N</option>
                            </select>
                        </div>
                        <div class="btn-wrap">
                            <a href="#" id="btnListBatch" class="btn btn-warning" @click.prevent="onAddInBatchesEstInOut">
                                <span>일괄입력</span>
                            </a>
                            <a href="#" id="btnListInst" class="btn btn-default" @click.prevent="onAddEstInOut">
                                <span><i class="glyphicon glyphicon-plus"></i> 추가</span>
                            </a>
                            <a href="#" id="btnListRmv" class="btn btn-default btn-row2" @click.prevent="onDeleteEstInOut">
                                <span><i class="glyphicon glyphicon-minus"></i> 삭제</span>
                            </a>
                            <a href="#" id="btnListSave" class="btn btn-primary" @click.prevent="onSaveEstInOut">
                                <span><i class="glyphicon glyphicon-save"></i> 저장</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="alert alert-info total">
                        <p><i class="glyphicon glyphicon-folder-close"></i> <span>전체: <span id="spTotCnt">{{EstInOutTotalCnt}}</span>건</span></p>
                    </div>
                    <section class="tbl-contents">
                        <div class="table-responsive">
                            <DxDataGrid :ref="EstInOutGrid"
                                        key-expr="EstInOutSeq"
                                        :data-source="EstInOutList"
                                        :hoverStateEnabled="true"
                                        :show-borders="true"
                                        :show-row-lines="true"
                                        :allow-column-resizing="true"
                                        @selection-changed="onSelectionChanged"
                                        :selected-row-keys="SelectedItemKeys"
                                        @cell-prepared="onCellPrepared"
                                        @row-updated="onRowUpdated"
                                        @saving="setFocus"
                                        style="max-height: 500px;">
                                <DxKeyboardNavigation :edit-on-key-press="true"
                                                      :enter-key-action="'startEdit'"
                                                      :enter-key-direction="'row'" />
                                <DxPaging :enabled="false" />
                                <DxSelection mode="multiple" :allow-select-all="allMode" :show-check-boxes-mode="'always'" />
                                <DxScrolling mode="standard" useNative="false" />
                                <DxEditing :allow-updating="true" :select-text-on-edit-start="true" :new-row-position="'last'" mode="cell" />
                                <DxColumn caption="예상거래일자" width="10%" data-field="EstTradeYMD" data-type="date" alignment="center" format="yyyy-MM-dd" />
                                <DxColumn caption="실거래일자" width="10%" data-field="ExeTradeYMD" data-type="date" alignment="center" format="yyyy-MM-dd" />
                                <DxColumn caption="출금액" width="10%" data-field="OutAmt" format="fixedPoint" alignment="right" />
                                <DxColumn caption="입금액" width="10%" data-field="InAmt" format="fixedPoint" alignment="right" />
                                <DxColumn caption="거래내용" data-field="TradeCont" alignment="left" />
                                <DxColumn caption="계정과목" width="10%" data-field="AcntSubjtCd" alignment="center">
                                    <DxLookup :dataSource="AcntSubjtCodeList" display-expr="Name" value-expr="Code" />
                                </DxColumn>
                                <DxColumn caption="메모" data-field="EstInOutMemo" alignment="left" />
                                <DxColumn caption="입출금확정여부" width="8%" data-field="InOutConfirmYN" alignment="center">
                                    <DxLookup :dataSource="InOutConfirmLookUp" />
                                </DxColumn>
                                <DxColumn caption="입출금진행여부" width="8%" data-field="MatchedState" alignment="center"></DxColumn>
                            </DxDataGrid>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <AC1141E ref="AC1141E"></AC1141E>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle';
    import AC1141E from '@/components/ac/popup/AC1141E';
    import { DxDataGrid, DxColumn, DxButton, DxScrolling, DxSorting, DxPaging, DxColumnFixing, DxSelection, DxEditing, DxLookup, DxKeyboardNavigation } from 'devextreme-vue/data-grid';
    import DxDateBox, { DxCalendarOptions } from 'devextreme-vue/date-box';

    export default {
        name: 'AC1140R',
        components: {
            ViewTitle, AC1141E, DxDataGrid, DxColumn, DxButton, DxScrolling, DxSorting, DxPaging, DxDateBox, DxCalendarOptions, DxColumnFixing, DxSelection, DxEditing, DxLookup, DxKeyboardNavigation,
        },
        data() {
            return {
                // 계정과목 코드
                AcntSubjtCodeList: new Array(),
                // 예상입출관리 조회 조건 객체
                EstInOutSelectTerm: {
                    Arg: 'L',
                    // 거래일자 구분('Y' : 실거래일자, 'N': 예상거래일자)
                    ExeYN: 'N',
                    // 거래일자
                    StartDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                    EndDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
                    // 입출금 확정여부
                    InOutConfirmYN: '',
                    // 계정과목 코드
                    AcntSubjtCd: '',
                    // 거래내용
                    TradeCont: ''
                },
                // 입출확정여부 일괄 처리 플래그
                BatchInOutConfirmYN: '',
                // 입출확정여부 일괄 처리 콤보박스 Disabled
                BatchInOutConfirmYNDisabled: true,
                // 예상입출관리 목록
                EstInOutList: new Array(),
                EstInOutGrid: 'dataGrid',
                // 예상입출관리 전체 건수
                EstInOutTotalCnt: 0,
                // 입출 확정여부 룩업 객체
                InOutConfirmLookUp: ['Y', 'N'],
                // 체크박스 전체선택 가능 여부
                allMode: true,
                // 체크박스 선택된 아이템 키
                SelectedItemKeys: new Array(),
                // 체크박스 선택된 아이템 데이터
                SelectedItemData: new Array(),
                // 예상입출관리 저장 객체
                EstInOutManipulateList: new Array(),
            }
        },
        beforeMount() {
            this.AcntSubjtCodeList = $.scriptBase.getCommonCode('CODE000', '0521000', '', '');
        },
        mounted() {
            this.getEstInOutList();
        },
        methods: {
            // datagrid header 가운데 정렬, column 중앙 정렬
            onCellPrepared(e) {

                if (e.rowType == 'header') {

                    e.cellElement.style['textAlign'] = 'center';
                }
                if (e.rowType == 'data') {

                    e.cellElement.style['verticalAlign'] = 'middle';

                    // 입출금진행여부 그리드에서 직접 조작 X
                    if (e.column.dataField == 'MatchedState') {
                        e.cellElement.style['background-color'] = '#EAEAEA';
                        e.cellElement.style['pointer-events'] = 'none';
                    }
                }

                // 입출금 진행된 건 수정 불가
                if (e.row && e.row.data.MatchedState && e.row.data.MatchedState == 'Y') {
                    e.cellElement.style['background-color'] = '#EAEAEA';
                    e.cellElement.style['pointer-events'] = 'none';
                }
            },
            // 예상입출 목록 조회
            getEstInOutList() {

                let parent = this;
                let url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/SelectEstimatedInOut'.toRealServiceUrl();
                let obj = new Object();

                this.EstInOutSelectTerm.StartDate = this.moment(this.EstInOutSelectTerm.StartDate).format('YYYYMMDD');
                this.EstInOutSelectTerm.EndDate = this.moment(this.EstInOutSelectTerm.EndDate).format('YYYYMMDD');

                this.EstInOutManipulateList = new Array();
                this.SelectedItemKeys = new Array();

                obj.jsonString = JSON.stringify(this.EstInOutSelectTerm);

                var ajaxSuccessHandler = function (data) {

                    var table = JSON.parse(data.d).Table;
                    var table1 = JSON.parse(data.d).Table1;

                    // 예상입출 목록
                    parent.EstInOutList = (table.length > 0) ? table : new Array();
                    parent.EstInOutList.forEach((item) => item.Arg = 'R');
                    // 예상입출 목록 전체 건수
                    parent.EstInOutTotalCnt = (table1.length > 0) ? table1[0].EstInOutTotalCnt : 0;

                    // 전체 선택 가능 여부
                    let selectionMode = parent.EstInOutList.some((item) => item.MatchedState == 'Y');
                    parent.allMode = !selectionMode;
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler);
            },
            // 선택한 로우 데이터
            onSelectionChanged(e) {

                this.SelectedItemKeys = e.selectedRowKeys;
                this.SelectedItemData = e.selectedRowsData;

                if (this.SelectedItemKeys.length > 0) {

                    this.BatchInOutConfirmYNDisabled = false;
                } else {

                    this.BatchInOutConfirmYNDisabled = true;
                }
            },
            // 예상입출 로우 추가
            onAddEstInOut() {

                let rowCnt = this.EstInOutList.length;

                this.$refs[this.EstInOutGrid].instance.addRow();
                this.$refs[this.EstInOutGrid].instance.cellValue(rowCnt, '입출금확정여부', 'N');
                this.$refs[this.EstInOutGrid].instance.cellValue(rowCnt, '입출금진행여부', 'N');
                this.$refs[this.EstInOutGrid].instance.saveEditData();
            },
            // 예상입출 로우 추가 후 셀 포커스 설정
            setFocus(e) {

                let newData = e.changes[0];

                if (newData && newData.type == 'insert') {

                    let rowCnt = this.EstInOutList.length;
                    this.$refs[this.EstInOutGrid].instance.editCell(rowCnt, 'EstTradeYMD');
                }
            },
            // 셀 수정 시 저장 플래그 세팅
            onRowUpdated(e) {

                let data = e.data;

                if (data.Arg == 'I') {

                    return;
                }

                this.EstInOutList.forEach((item) => {
                    if (item.EstInOutSeq == data.EstInOutSeq) {
                        item.Arg = 'I';
                        item.UserId = this.$store.getters.getEmpId;
                    }
                })
            },
            // 입출확정여부 일괄 조작
            onConfirmInBatches() {

                this.SelectedItemData.forEach((item) => {

                    item.Arg = 'I';
                    item.UserId = this.$store.getters.getEmpId;
                    item.InOutConfirmYN = this.BatchInOutConfirmYN;
                })

                this.SelectedItemKeys = new Array();
                this.SelectedItemData = new Array();
                this.BatchInOutConfirmYN = '';
            },
            // 예상입출 일괄입력
            onAddInBatchesEstInOut() {

                let parent = this;

                var successCallback = function () {

                    parent.getEstInOutList();
                }

                this.$refs.AC1141E.open(successCallback);
            },
            // 로우 삭제 메소드
            onDeleteEstInOut() {

                if (this.SelectedItemKeys.length == 0) {

                    alert('삭제할 항목을 선택해주세요.');
                    return;
                }

                // DB에서 삭제할 항목 세팅
                this.SelectedItemData.forEach((item) => {

                    if (item.Arg == 'R') {

                        item.Arg = 'D';
                        item.TradeYMDStart = '';
                        item.TradeYMDEnd = '';
                        item.UserId = this.$store.getters.getEmpId;
                        this.EstInOutManipulateList.push(item);
                    } else {

                        item.Arg = 'D';
                    }
                });

                // 선택 항목 그리드에서 삭제
                this.EstInOutList = this.EstInOutList.filter((item) => item.Arg != 'D');
            },
            // 저장 버튼 메소드
            onSaveEstInOut() {

                let parent = this;
                let url = '/Mini.Service.IBS.AC.Service/Accounting.svc/ajax/ManipulateEstimatedInOut'.toRealServiceUrl();
                let obj = new Object();

                if (this.EstInOutList.length == 0 && this.EstInOutManipulateList.length == 0) {

                    alert('저장할 내용이 없습니다.');
                    return;
                }

                if (this.EstInOutList.length > 0) {

                    let listCnt = this.EstInOutList.length;

                    for (let i = 0; i < listCnt; i++) {

                        if (this.EstInOutList[i].EstTradeYMD == undefined || this.EstInOutList[i].EstTradeYMD == '' || this.EstInOutList[i].EstTradeYMD == null) {

                            alert('예상거래일자를 입력해 주십시오.');
                            let focusCell = this.$refs[this.EstInOutGrid].instance.getCellElement(i, 1);
                            this.$refs[this.EstInOutGrid].instance.focus(focusCell);
                            return;
                        }

                        if (this.EstInOutList[i].TradeCont == undefined || this.EstInOutList[i].TradeCont == '' || this.EstInOutList[i].TradeCont == null) {

                            alert('거래내용을 입력해 주십시오.');
                            let focusCell = this.$refs[this.EstInOutGrid].instance.getCellElement(i, 5);
                            this.$refs[this.EstInOutGrid].instance.focus(focusCell);
                            return;
                        }

                        if (this.EstInOutList[i].AcntSubjtCd == undefined || this.EstInOutList[i].AcntSubjtCd == '' || this.EstInOutList[i].AcntSubjtCd == null) {

                            alert('계정과목을 선택해 주십시오.');
                            let focusCell = this.$refs[this.EstInOutGrid].instance.getCellElement(i, 6);
                            this.$refs[this.EstInOutGrid].instance.focus(focusCell);
                            return;
                        }
                    }
                }

                this.EstInOutList.forEach((item) => {

                    let newEstObj = {
                        Arg: 'I',
                        EstInOutSeq: null,
                        TradeYMDStart: null,
                        TradeYMDEnd: null,
                        ExeTradeYMD: null,
                        OutAmt: 0,
                        InAmt: 0,
                        TradeCont: null,
                        AcntSubjtCd: null,
                        InOutConfirmYN: 'N',
                        EstInOutMemo: null,
                        UserId: this.$store.getters.getEmpId,
                    };

                    // 새로 입력/수정된 데이터
                    if (item.Arg == 'I') {
                        newEstObj.EstInOutSeq = (typeof item.EstInOutSeq == 'string') ? null : item.EstInOutSeq;
                        newEstObj.TradeYMDStart = this.moment(item.EstTradeYMD).format('YYYYMMDD');
                        newEstObj.TradeYMDEnd = this.moment(item.EstTradeYMD).format('YYYYMMDD');
                        newEstObj.ExeTradeYMD = (item.ExeTradeYMD) ? this.moment(item.ExeTradeYMD).format('YYYYMMDD') : newEstObj.ExeTradeYMD;
                        newEstObj.OutAmt = (item.OutAmt) ? item.OutAmt : newEstObj.OutAmt;
                        newEstObj.InAmt = (item.InAmt) ? item.InAmt : newEstObj.InAmt;
                        newEstObj.TradeCont = (item.TradeCont) ? item.TradeCont : newEstObj.TradeCont;
                        newEstObj.AcntSubjtCd = item.AcntSubjtCd;
                        newEstObj.InOutConfirmYN = (item.InOutConfirmYN) ? item.InOutConfirmYN : newEstObj.InOutConfirmYN;
                        newEstObj.EstInOutMemo = (item.EstInOutMemo) ? item.EstInOutMemo : newEstObj.EstInOutMemo;

                        this.EstInOutManipulateList.push(newEstObj);
                    }
                })

                if (this.EstInOutManipulateList.length == 0) {

                    alert('저장할 변경사항이 없습니다.');
                    return;
                }

                obj.jsonString = JSON.stringify(this.EstInOutManipulateList);

                var successCallback = function (data) {

                    if (data.d > 0) {

                        alert('저장되었습니다.');
                        parent.EstInOutManipulateList = new Array();
                        parent.getEstInOutList();
                    } else {

                        alert('저장에 실패했습니다. 잠시 후 다시 시도해 주십시오.');
                        return;
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
        }
    }
</script>