﻿// 금액을 천단위로 콤마를 찍어 문자열로 반환
String.prototype.toPriceString = function () {
    if(!this) return "0"
    return this.replace(/[^0-9]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

// 금액을 천단위로 콤마를 찍어 문자열로 반환
Number.prototype.toPriceNumber = function () {
    if(!this) return 0
    return this.toString().replace(/[^0-9]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

// 숫자만 문자열로 반환
String.prototype.toNumber = function () {
    
    return this.replace(/[^0-9]/g, '')
}

// 소수만 문자열로 반환
String.prototype.toDecimal = function () {

    var result = ''
    
    if (this.trim() != '' && this != '.') {

        if (this.includes('.')) {

            // 숫자와 '.'만 남기고 제거
            // '.' 문자가 2번이상 반복되면 '.'로 치환
            var tempArray = this.replace(/[^0-9.]/g, '').replace(/[.]{2,}/g, '.').split('.')

            // 배열을 반복하며 재조합
            tempArray.forEach(function (item, index) {

                if (index === 0) {

                    result = item.concat('.')
                }
                else {

                    result = result + item
                }
            })
        }
        else {

            result = this.replace(/[^0-9]/g, '')
        }
    }

    return result
}

// 서비스 Url을 운영환경에 맞게 변경해서 반환
//String.prototype.toRealServiceUrl = function () {

//    var resultUrl = this;

//    if (location.hostname != 'localhost') {
        
//        var tempArr = this.split('/')                              
//        var serviecName = tempArr[tempArr.length - 3]
//        var actionName = tempArr[tempArr.length - 1]

//        resultUrl = '/V2/Services/' + serviecName + '/ajax/' + actionName
//    }

//    return resultUrl
//}

// 서비스 Url을 운영환경에 맞게 변경해서 반환
String.prototype.toRealServiceUrl = function () {

    var resultUrl = this;

    if (location.hostname != 'localhost') {

        var startIndex = resultUrl.indexOf('/', 1)
        var endIndex = resultUrl.length

        var serviecUrl = resultUrl.substring(startIndex, endIndex)
        serviecUrl.indexOf('/') == 0 ? serviecUrl = serviecUrl.replace('/', '') : serviecUrl

        resultUrl = '/V2/Services/' + serviecUrl
    } else {

        resultUrl.indexOf('/') == 0 ? resultUrl = resultUrl.replace('/', '') : resultUrl
        resultUrl = 'http://localhost/' + resultUrl
    }

    return resultUrl
}

// yyyy-mm-dd 포맷으로 반환
String.prototype.toHyphenationFormatYMD = function () {
        
    return this.replace(/[^0-9]/g, '').replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')
}

// hh:mm 포맷으로 반환
String.prototype.toColonFormatHHMM = function () {
    return this.replace(/[^0-9]/g, '').replace(/(\d{2})(\d{2})/, '$1:$2')
}

// 주민등록번호 포맷으로 반환
String.prototype.toResidentRegistrationNumberFormat = function () {

    return this.replace(/[^0-9]/g, '').replace(/([0-9]{6})([0-9]{7})/, '$1-$2')
}

// 계좌번호 포맷으로 반환
String.prototype.toAccountNumberFoormat = function () {

    return this.replace(/[^0-9-]/g, '').replace(/^[-]+/g, '').replace(/(-{2,})$/g, '-')
}

// 카드번호 포맷으로 반환
String.prototype.toCardNumberFoormat = function () {

    return this.replace(/[^0-9-]/g, '').replace(/^[-]+/g, '').replace(/(-{2,})$/g, '-')
}

// 전화, 팩스, 휴대폰 번호 포맷으로 반환
String.prototype.toTelNumberFormat = function () {

    return this.replace(/[^0-9]/g, '').replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3')
}

// 유효한 전화번호, 팩스번호 또는 핸드폰 번호인지 체크
String.prototype.isValidTelNumber = function () {

    var exp = /(^02.{0}|^01.{1}|[0-9]{3})-([0-9]+)-([0-9]{4})/

    return exp.test(this)
}

// 유효한 이메일 주소인지 체크
String.prototype.isValidEmailAddress = function () {

    var exp = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/

    return exp.test(this)
}

// 유효한 IBS 사용자 아이디인지 체크
String.prototype.isValidIBSEmpId = function () {

    var exp = /^([\w-]+(?:\.[\w-]+)*)$/

    return exp.test(this)
}

// 유효한 계좌번호인지 체크
String.prototype.isValidAccountNumber = function () {

    var regx1 = /^\d+-\d+-\d+$/
    var regx2 = /^\d+-\d+-\d+-\d+$/

    if (!regx1.test(this) && !regx2.test(this)) {

        return false
    }
    else {

        return true
    }
}

// 유효한 날짜인지 체크
String.prototype.isDate = function () {

    var regExp = /^(19|20)\d{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[0-1])$/

    return regExp.test(this)
}

// 유효한 시간인지 체크
String.prototype.isTime = function () {

    var exp = /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/

    return exp.test(this)
}