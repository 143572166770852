<template>
	<!-- 상세등록 전자결재 -->

	<div class="modal fade" id="BZ1025E_Modal" ref="BZ1025E_Modal" tabindex="-1" role="dialog" aria-labelledby="BZ1025E_Modal" aria-hi dden="true" data-keyboard="false" data-backdrop="static">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<button type="button" class="close" @click.Prevent="close">
						<span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
					</button>
					<h3 class="modal-title">매입상품 상세</h3>
				</div>
				<div class="modal-body">
					<section class="add-wrap">
						<form>
							<fieldset>
								<legend></legend>
								<section class="cols-wrap">
									<div class="cols">
										<label for="txtProductNm" class="control-label">
											<span><i txtProductNm="glyphicon glyphicon-asterisk"></i>상품명</span>
										</label>
										<input type="text" id="txtProductNm" class="form-control txt" v-model="PurchaseProductDetail.ProductNm" />
									</div>
								</section>
								<section class="cols-wrap">
									<div class="cols col-2">
										<label for="cbProductType" class="control-label">
											<span><i class="glyphicon glyphicon-asterisk"></i>매입품 유형</span>
										</label>
										<select class="form-control" id="cbProductType" name="cbProductType"
												v-model="PurchaseProductDetail.ProductType">
											<option value="">== 선택 ==</option>
											<option v-for="item in ProductTypeList" v-bind:value="item.Code">
												{{item.Name}}
											</option>
										</select>
									</div>
									<div class="cols col-2">
										<label for="txtUnitCnt" class="control-label">
											<span><i class="glyphicon glyphicon-asterisk"></i>수량</span>
										</label>
										<input type="text" id="txtUnitCnt" class="form-control text-right" maxlength="3" v-model="PurchaseProductDetail.UnitCnt" />
									</div>
								</section>
								<section class="cols-wrap">
									<div class="cols col-2">
										<label for="txtDeliveryPrice" class="control-label">
											<span><i class="glyphicon glyphicon-asterisk"></i>납품 금액</span>
										</label>
										<input type="text" id="txtDeliveryPrice" ref="txtListPrice2" class="form-control text-right"
											   v-bind:value="PurchaseProductDetail.DeliveryPrice"
											   @input="function(e){
                                                        PurchaseProductDetail.DeliveryPrice = e.target.value.toPriceString()
                                                        e.target.value = e.target.value.toPriceString()
                                                   }" />
									</div>
									<div class="cols col-2">
										<label for="txtPurchasePrice" ref="txtPurchasePrice" class="control-label">
											<span><i class="glyphicon glyphicon-asterisk"></i>매입 금액</span>
										</label>
										<input type="text" id="txtPurchasePrice" class="form-control text-right"
											   v-bind:value="PurchaseProductDetail.PurchasePrice"
											   @input="function(e){
                                                        PurchaseProductDetail.PurchasePrice = e.target.value.toPriceString()
                                                        e.target.value = e.target.value.toPriceString()
                                                   }" />
									</div>
								</section>
								<section class="cols-wrap">
									<div class="cols col-2">
										<label for="selVatIncludeYn" class="control-label">
											<span>VAT</span>
										</label>
										<select class="form-control" id="selVatIncludeYn" name="selVatIncludeYn"
												v-model="PurchaseProductDetail.VatIncludeYn"
												@change="selectBoxChangedEvent">
											<option value="">== 선택 ==</option>
											<option value="Y">포함</option>
											<option value="N">별도</option>
											<option value="Z">영세율</option>
											<option value="F">면세</option>
										</select>
									</div>
									<div class="cols col-2">
										<label for="txtVatAmount" class="control-label">
											<span>부가세</span>
										</label>
										<input type="text" id="txtVatAmount" class="form-control text-right" disabled="disabled" v-model="PurchaseProductDetail.VatAmount" />
									</div>
								</section>
								<section class="cols-wrap">
									<div class="cols col-2">
										<label for="txtOrderCorpNm1" class="control-label">
											<span>매입처명</span>
										</label>
										<div class="cols-btns-cont">
											<input type="text" id="txtCompanyNm" class="form-control txt" disabled="disabled" v-model="PurchaseProductDetail.CompanyNm" />
											<input type="hidden" id="hideCompanyCd" v-model="PurchaseProductDetail.CompanyCd" />
										</div>
										<div class="cols-btns">
											<a href="#" id="btnPerson" class="btn btn-default" @click.prevent="searchCompany(1)">
												<span>
													<i class="glyphicon glyphicon-search"></i> 검색
												</span>
											</a>
										</div>
									</div>
									<div class="cols col-2">
										<label for="txtCompanyManager" class="control-label">
											<span>매입처 담당자</span>
										</label>
										<input type="text" id="txtCompanyManager" class="form-control txt" maxlength="3" v-model="PurchaseProductDetail.CompanyManager" />
									</div>
								</section>
								<section class="cols-wrap">
									<div class="cols col-2">
										<label for="txtCompanyTel" class="control-label">
											<span>매입처 연락처</span>
										</label>
										<input type="text" id="txtCompanyTel" class="form-control txt" v-model="PurchaseProductDetail.CompanyTel" />
									</div>
									<div class="cols col-2">
										<label for="txtDlvDueYMD" class="control-label">
											<span><i class="glyphicon glyphicon-asterisk"></i>납품예정일</span>
										</label>
										<DxDateBox id="txtDlvDueYMD" v-model="PurchaseProductDetail.DlvDueYMD" type="date" display-format="yyyy-MM-dd"></DxDateBox>
									</div>
								</section>
								<section class="cols-wrap">
									<div class="cols">
										<label for="txtRemark" class="control-label">
											<span>비고</span>
										</label>
										<input type="text" id="txtRemark" class="form-control txt" v-model="PurchaseProductDetail.Remark" />
									</div>
								</section>
								<section class="btn-align">
									<label v-show="PurchaseProductDetail.ConfirmYN === 'Y'" id="lbl_Message_RmvSav" class="control-label">
										<span>매입승인 처리되어 삭제할 수 없습니다.</span>
									</label>
									<div v-show="this.$parent.CanSave" class="btn-group">
										<button v-show="PurchaseProductDetail.ConfirmYN !== 'Y'" type="button" id="btnItemSave" class="btn btn-primary" @click.prevent="saveData">
											<i class="glyphicon glyphicon-save"></i> 저장
										</button>
									</div>
									<div class="btn-group">
										<button type="button" class="btn btn-danger" @click.Prevent="close">
											<i class="glyphicon glyphicon-remove"></i> 취소
										</button>
									</div>
								</section>

							</fieldset>
						</form>
					</section>
				</div>
			</div>
		</div>
		<CP1030P ref="CP1030P"></CP1030P>
	
	</div>
</template>

<script>
	import CP1030P from '@/components/libs/popup/CP1030P'
	import DxButton from 'devextreme-vue/button';
	import DxDataGrid, {
		DxColumn,
		DxEditing,
		DxLookup,
		DxSelection,
		DxScrolling
	} from 'devextreme-vue/data-grid'

	import {
		DxDateBox,
		DxCalendarOptions
	} from 'devextreme-vue/date-box'
	import {
		DxHtmlEditor,
		DxToolbar,
		DxMediaResizing,
		DxItem
	} from 'devextreme-vue/html-editor'

	export default {
		name: 'BZ1025E',
		components: {
			DxDateBox,
			DxButton,
			DxDataGrid,
			DxColumn,
			DxEditing,
			DxLookup,
			DxSelection,
			DxScrolling,
			DxCalendarOptions,
			DxHtmlEditor,
			DxToolbar,
			DxMediaResizing,
			DxItem,
			CP1030P,
		},
		data() {
			return {
				CallbackFunction: null,     // Caller Function
				Condition: null,
				Contract: {
					EmpNo: null,
					ContractNo: null,
					ContractNm: null,
					OrderCompCd: null,
					OrderCompNm: null,
					ContractCompCd: null,
					ContractCompNm: null,
					ProjDiv: '',
					MgrEmpNo: null,
					MgrEmpNm: null,
					Remark: null,
					ConfirmYn: null,
					BizNo: null
				},
				ContractMgr: {
					ContractNo: null,
					ContractMgrSeqNm: null,
					ContractMgrSeqNo: null,
					OrderMgrSeqNm: null,
					OrderMgrSeqNo: null,
					CompDiv: null,
					RegtDate: null,
					RegtEmpNo: null,
					UpdateDate: null,
					UpdateEmpNo: null
				},
				ContractDetail: {
					ContractNo: null,
					ContractSeqNo: null,
					ContractSeqNm: null,
					ContractDiv: '',
					ContractStartDate: null,
					ContractEndDate: null,
					ContractAmount: null,
					VAYIncludeYn: 'N',
					VATAmount: '0',
					RelationContract: '',
					ProjMigYn: null,
					BizSeqNo: null,
					Remark: null,
					RegtDate: null,
					RegtEmpNo: null,
					UpdateDate: null,
					UpdateEmpNo: null,
					CostPlayDiv: '',
					CostPlayMeans: null,
					ContractExecGuarantee: null,
					ExecGuaranteeIns: null,
					DefactGuaranteePeriod: null,
					ConstractStatusCd: '',
					EmpNo: null,
					ContractPaymentNo: -1,
					ContractContents: null

				},
				ContractPayment: {
					ContractPaymentDetail: null
				},
				PurchaseProductDetail: {
					ProductNm: null,		// 상품 이름
					ProductType: '',		// 매입품 유형
					UnitCnt: null,			// 수량
					DeliveryPrice: null,	// 납품금액
					PurchasePrice: null,	// 매입금액
					VatIncludeYn: 'N',		// VAT
					VatAmount: '0',			// 부가세
					CompanyNm: null,		// 매입처 이름
					CompanyCd: null,		// 매입처 코드
					CompanyManager: null,	// 매입처 담당자
					CompanyTel: null,		// 매입처 연락처
					DlvDueYMD: null,		// 납품 예정일
					Remark: null,			// 비고
				},
				//조회조건 데이터 객체
				PurchaseProductTerm: {
					Arg: 'L',
					UserId: this.$parent.EmpId,
					CorpCd: this.$parent.CompanyCode,
					ProjectCode: '',
					ProjectName: '',
					CompanyName: '',
					CompanyCode: '',
					SeqNo: '',
					TopSize: 10,
					CurrentPage: 1
				},
				start: new Date(),
			}
		},
		watch: {
			PurchaseProductDetail: {
				deep: true,
				handler(newData) {

					if (newData.VatIncludeYn === 'N') {

						this.autoCalculateVat()
					}
				}
			}
		},
		beforeMount() {
			// 공통코드 조회
			this.ProductTypeList = $.scriptBase.getCommonCode('CODE000', '0735000', '', '')			// 매입품 유형
		},
		methods: {
			open: function (callbackFunction, Arg, selectobject) {

				// 화면 액션값 설정 (I:신규, V: 상세보기)/ 
				if (Arg == "V") {
					this.PageActionType = 'I'

					this.PurchaseProductDetail = selectobject
					this.PurchaseProductDetail.InputEmpNo = this.$parent.$parent.$parent.$parent.$parent.$parent.EmpId

					this.CallbackFunction = callbackFunction
				}
				else if (Arg == "I") {
					this.PurchaseProductDetail = {

						ContractNo: this.$parent.ContractDetail.ContractNo,
						ContractSeqNo: this.$parent.ContractDetail.ContractSeqNo,
						ProductNm: '',			// 상품 이름
						ProductType: '',		// 매입품 유형
						UnitCnt: '1',			// 수량
						DeliveryPrice: '0',		// 납품금액
						PurchasePrice: '0',		// 매입금액
						VatIncludeYn: 'N',		// VAT
						VatAmount: '0',			// 부가세
						CompanyNm: '',			// 매입처 이름
						CompanyCd: '',			// 매입처 코드
						CompanyManager: '',		// 매입처 담당자
						CompanyTel: '',			// 매입처 연락처
						DlvDueYMD: '',			// 납품 예정일
						Remark: '',				// 비고
						InputEmpNo: this.$parent.$parent.$parent.$parent.$parent.$parent.EmpId,
						RoleSeq: null
					}
					this.CallbackFunction = callbackFunction
				}
				else if (Arg == "CV") {
					this.PageActionType = 'I'

					this.PurchaseProductDetail = selectobject
					this.PurchaseProductDetail.InputEmpNo = this.$parent.$parent.$parent.EmpId

					this.CallbackFunction = callbackFunction
				}
				else if (Arg == "CI") {
					this.PurchaseProductDetail = {

						ContractNo: this.$parent.ContractDetail.ContractNo,
						ContractSeqNo: this.$parent.ContractDetail.ContractSeqNo,
						ProductNm: '',			// 상품 이름
						ProductType: '',		// 매입품 유형
						UnitCnt: '1',			// 수량
						DeliveryPrice: '0',		// 납품금액
						PurchasePrice: '0',		// 매입금액
						VatIncludeYn: 'N',		// VAT
						VatAmount: '0',			// 부가세
						CompanyNm: '',			// 매입처 이름
						CompanyCd: '',			// 매입처 코드
						CompanyManager: '',		// 매입처 담당자
						CompanyTel: '',			// 매입처 연락처
						DlvDueYMD: '',			// 납품 예정일
						Remark: '',				// 비고
						InputEmpNo: this.$parent.$parent.$parent.EmpId,
						RoleSeq: null
					}
					this.CallbackFunction = callbackFunction
				}
				

				$("#BZ1025E_Modal").modal("show")
			},
			close: function () {
				$("#BZ1025E_Modal").modal("hide")
				this.CallbackFunction()
			},
			reset() {
				Object.assign(this.$data, this.$options.data())
			},
			// VAT 포함 이벤트 핸들러
			selectBoxChangedEvent: function () {

				// 포함
				if (event.target.value === 'N') {

					// 부가세 자동계산
					this.autoCalculateVat()

					this.PurchaseProductDetail.VatIncludeYn = 'N'
				}
				// 별도
				else if (event.target.value === 'Y') {

					this.PurchaseProductDetail.VatAmount = '0'
					this.PurchaseProductDetail.VatIncludeYn = 'Y'
				}
				// 영세율
				else if (event.target.value === 'Z') {

					this.PurchaseProductDetail.VatAmount = '0'
					this.PurchaseProductDetail.VatIncludeYn = 'Z'
				}
				// 면세
				else if (event.target.value === 'F') {

					this.PurchaseProductDetail.VatAmount = '0'
					this.PurchaseProductDetail.VatIncludeYn = 'F'
				}
			},
			// 업체 검색
			// targetIndex: 1: 상세팝업화면 계약업체 검색, 2: 상세팝업화면 발주업체 검색
			searchCompany: function (targetIndex) {

				var parent = this

				var successCallback = function (result) {

					switch (targetIndex) {

						case 1: {

							parent.PurchaseProductDetail.CompanyNm = result.CompanyNm
							parent.PurchaseProductDetail.CompanyCd = result.CompanyCd

							break
						}
						case 2: {

							parent.PurchaseProductDetail.CompanyNm = result.CompanyNm
							parent.PurchaseProductDetail.CompanyCd = result.CompanyCd

							break
						}
						default:
					}
				}

				this.$refs.CP1030P.open(successCallback)
			},
			// 부가세 자동계산
			autoCalculateVat: function () {

				if (this.PurchaseProductDetail.PurchasePrice != null && this.PurchaseProductDetail.PurchasePrice != '0') {

					var vat = Number(this.PurchaseProductDetail.PurchasePrice.toNumber()) * 0.1
					this.PurchaseProductDetail.VatAmount = Math.round(vat).toPriceNumber()
				}
				else {

					this.PurchaseProductDetail.VatAmount = null
				}
			},
			// 저장 메서드
			saveData: function () {

				// 매입 상품 저장 유효성 검사
				if (!this.PurchaseProductDetail.ProductType) {

					alert('매입품 유형을 입력하세요.')
					$(this.$refs.cbProductType).focus()
					return
				}
				if (!this.PurchaseProductDetail.UnitCnt || (this.PurchaseProductDetail.UnitCnt) === 0) {

					alert('수량은 1이상으로 입력하세요.')
					$(this.$refs.txtUnitCnt).focus()
					return
				}
				if (this.PurchaseProductDetail.DeliveryPrice === '' || this.PurchaseProductDetail.DeliveryPrice === null) {

					alert('납품 금액을 입력하세요.')
					$(this.$refs.txtDeliveryPrice).focus()
					return
				}
				if (this.PurchaseProductDetail.PurchasePrice === '' || this.PurchaseProductDetail.PurchasePrice === null) {

					alert('매입금액을 입력하세요.')
					$(this.$refs.txtPurchasePrice).focus()
					return
				}
                if (this.PurchaseProductDetail.DlvDueYMD === '' || this.PurchaseProductDetail.DlvDueYMD === null || this.PurchaseProductDetail.DlvDueYMD === undefined) {
                    alert('납품예정일을 선택해주세요.')
                    $(this.$refs.txtDlvDueYMD).focus()
                    return
                }
                if (this.PurchaseProductDetail.DlvDueYMD !== '' && (this.PurchaseProductDetail.DlvDueYMD < this.ContractDetail.ContractStartDate)) {
                    alert('납품예정일은 계약기간 내에서 입력해주세요.')
                    $(this.$refs.txtDlvDueYMD).focus()
                    return
                }

				var parent = this

				var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/SaveContractProduct'.toRealServiceUrl()
				var obj = new Object()

				this.PurchaseProductDetail.DlvDueYMD = this.PurchaseProductDetail.DlvDueYMD.toLocaleDateString('ko', { year: 'numeric', month: '2-digit', day: '2-digit' })

				obj.jsonString = JSON.stringify(this.PurchaseProductDetail)

				var successCallback = function (data) {

					alert('등록 되었습니다.')
					
					parent.$parent.selectContractProduct()
					parent.close()
				}

				$.scriptBase.executeAjaxAsync(url, obj, successCallback)
			},
		}
	}
</script>
