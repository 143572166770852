<template>
	<div class="dashboard-wrap">
		<!-- 타이틀 영역 -->
		<ViewTitle>영업내역</ViewTitle>
		<!--영업관리-->
		<div class="content-box">
			<div class="btn-wrap">
				<a v-show="this.$parent.CanSearch" href="#" id="btnListSrch" class="btn btn-primary btn-row2" @click.prevent="onSearchClick">
					<span>
						<i class="glyphicon glyphicon-search"></i> 조회
					</span>
				</a>
				<a v-if="this.$parent.CanNew" href="#" id="btnListInst" class="btn btn-default" @click.prevent="onAddBusinessClick">
					<span>
						<i class="glyphicon glyphicon-plus"></i> 추가
					</span>
				</a>
			</div>
			<div class="sch-box">
				<!--조건 선언부-->
				<div class="sch-form">
					<form class="form-horizontal" role="form">
						<fieldset>
							<legend>검색폼</legend>
							<section class="sch-cont">
								<section class="cols-wrap">
									<div class="cols col-2">
										<label for="selTrgtYY" class="control-label" style="text-align:center">
											<span>년도</span>
										</label>
										<select id="selTrgtYY" class="form-control" v-model="BusinessSelectTerm.TargetYear">
											<option v-for="year in TargetYearList" v-bind:value="year" >
												{{year}}
											</option>
										</select>
									</div>
									<div class="cols col-2">
										<label for="txtProjectNm" class="control-label" style="text-align:center">
											<span>진행상황</span>
										</label>
										<select class="form-control" id="selStatusCd" name="selStatusCd" v-model="BusinessSelectTerm.DvsCd">
											<option value="">== 전체 ==</option>
											<option v-for="item in ProgressList" v-bind:value="item.Code">
												{{item.Name}}
											</option>
										</select>
									</div>
								</section>
								<section class="cols-wrap">
									<div class="cols col-2">
										<label for="txtTraderNm" class="control-label" style="text-align:center">
											<span>업체명</span>
										</label>
										<div class="cols-btns-cont pad-r150">
											<input type="text" id="txtTraderNm" class="form-control txt" readonly="readonly" v-model="BusinessSelectTerm.CompanyName" />
											<input type="hidden" id="hidTraderCd" v-model="BusinessSelectTerm.CompanyCode" />
										</div>
										<div class="cols-btns">
											<a href="#" id="btnCompanyCode" class="btn btn-default" @click.prevent="onCompanyCodeClick">
												<span>
													<i class="glyphicon glyphicon-search"></i> 검색
												</span>
											</a>
											<a href="#" id="btnSrchInit" class="btn btn-default"
											   @click.prevent="function(){
                                                     BusinessSelectTerm.CompanyCode = null
                                                     BusinessSelectTerm.CompanyName = null
                                                }">
												<span>
													<i class="glyphicon glyphicon-repeat"></i> 초기화
												</span>
											</a>
										</div>
									</div>
									<div class="cols col-2">
										<label for="txtProjectNm" class="control-label" style="text-align:center">
											<span>영업명</span>
										</label>
										<input type="text" id="txtSrchText" class="form-control txt" v-model="BusinessSelectTerm.SrchText" />
									</div>
								</section>
							</section>
						</fieldset>
					</form>
				</div>
			</div>
			<div class="grid-wrap">
				<div class="sub-title">
					<p class="sub-title-txt">영업관리</p>
				</div>
				<div>
					<!--리스트 영역-->
					<section class="tbl-contents">
						<div class="alert alert-info total">
							<p>
								<i class="glyphicon glyphicon-folder-close"></i><span>전체: <span id="spTotCnt">{{TotalCount}}</span>건</span>
							</p>
						</div>
					</section>
				</div>
			</div>
			<!--마스터 디테일 그리드 영역-->
			<div class="table-responsive">
				<DxDataGrid id="grid-container"
							:show-borders="false"
							:show-row-lines="true"
							:data-source="BusinessList"
							:hoverStateEnabled="true"
							@cell-prepared="onCellPrepared"
							@cell-click="onCellClicked">
					<DxLoadPanel :enabled="false" />
					<DxScrolling mode="standard" useNative="false"  />
					<DxSorting mode="none" />
					<DxColumn cell-template="detail" width="5%" alignment="center"/>
					<template #detail="{ data }">
						<DxButton icon="chevronnext" styling-mode="text" @click="onCollapseClick(data)" />
					</template>
					<DxColumn data-field="CompanyNm"
							  caption="업체"
							  width="20%" />
					<DxColumn data-field="DvsCdName"
							  caption="상태"
							  alignment="center"
							  width="5%" />
					<DxColumn data-field="LastPrice"
							  caption="예가"
							  alignment="right"
							  format="fixedPoint"
							  width="10%" />
					<DxColumn data-field="TotalMM"
							  caption="MM"
							  alignment="center"
							  width="5%" />
					<DxColumn data-field="BizNm"
							  caption="영업명" 
							  width="45%"/>
					<DxColumn cell-template="add" width="10%" alignment="center">
					</DxColumn>
					<template #add="{ data }">
						<DxButton text="영업이력추가" type="default" styling-mode="contained" @click="onAddBusinessHistoryClick(data)" />
					</template>
					<DxMasterDetail :enabled="false"
									template="masterDetailTemplate" />
					<template #masterDetailTemplate="{ data }">
						<BZ1000R_Detail :template-data="data" />
					</template>
				</DxDataGrid>
			</div>
		</div>
		<CP1030P ref="CP1030P"></CP1030P>
		<BZ1001E ref="BZ1001E"></BZ1001E>
		<BZ1002E ref="BZ1002E"></BZ1002E>
	</div>
</template>

<script>
	import ViewTitle from '@/components/ViewTitle'

	import { DxDataGrid, DxColumn, DxMasterDetail, DxScrolling, DxSorting, DxLoadPanel } from 'devextreme-vue/data-grid'
    import { DxButton } from 'devextreme-vue'

	import CP1030P from '@/components/libs/popup/CP1030P'
	import BZ1001E from '@/components/bz/popup/BZ1001E'
	import BZ1002E from '@/components/bz/popup/BZ1002E'
	import BZ1000R_Detail from '@/components/bz/libs/BZ1000R_Detail'


	export default {
		name: 'BZ1000R',
		components: {
			ViewTitle, CP1030P, BZ1001E, BZ1002E, BZ1000R_Detail, DxDataGrid, DxColumn, DxMasterDetail, DxButton, DxScrolling, DxSorting, DxLoadPanel
		},
		data() {
			return {
				// 진행상황 공통코드 리스트
				ProgressList: new Array(),
				// 조회된 영업관리 리스트
				BusinessList: new Array(),
				// 영업관리 이력 목록 상세 리스트
				BusinessHistoryList: new Array(),
				// 영업관리 이력 목록 모든 상세 리스트
				BusinessHistoryListAll: null,
				// 영업관리 이력 상세 파일 전체
				BusinessHistoryFileListAll: new Array(),
				// 년도 목록
				TargetYearList: new Array(),
				// 조회조건 데이터 객체
				BusinessSelectTerm: {
					Arg: 'L',
					UserId: this.$store.getters.getEmpId,
					CorpCd: this.$store.getters.getCompanyCode,
					// 업체 코드
					CompanyCode: null,
					// 업체 이름
					CompanyName: null,
					// 진행상황(디폴트 값 '영업')
					DvsCd: '0723020',
					// 영업명
					SrchText: null,
					// 년도
					TargetYear: this.moment(new Date()).year()
				},
				TotalCount: 0
			}
		},
		beforeMount() {

			// 공통코드 조회
			this.ProgressList = $.scriptBase.getCommonCode('CODE000', '0723000', '', '');

			// 대상년도 목록 값 설정
			var startYear = this.moment(new Date()).year();
			var endYear = this.moment(new Date(2013, 1, 1, 0, 0, 0)).year();
			
			for (var i = startYear; i >= endYear; i--) {
				this.TargetYearList.push(i);
			}
		},
		mounted() {

			this.$refs.BZ1001E.moment = this.moment

            if (this.$parent.CanSearch) { // 초기조회
				this.getBusiness()
			}
		},
		methods: {
			// datagrid header 가운데 정렬, column 중앙 정렬
            onCellPrepared(e) { 

                if (e.rowType == 'header') {
                    e.cellElement.style['textAlign'] = 'center';
                }
                if (e.rowType == 'data') {
                    e.cellElement.style['verticalAlign'] = 'middle';
                }
			},
			// 마스터 그리드 선택 이벤트
            onCollapseClick(e) {

                //this.clicked = !this.clicked;
                var expanded = e.component.isRowExpanded(e.row.key);
                
				if (expanded) {

                    e.component.collapseAll(-1);
                } else {

                    e.component.collapseAll(-1);
                    e.component.expandRow(e.row.key);
                }
			},
            // 마스터 그리드 상태, 영업명, MM cell 클릭 이벤트
			onCellClicked(e) {
                var that = this
                if (e.rowType === "data" && e.column.dataField === "DvsCdName" || e.column.dataField === "BizNm") {
                    // 화면 액션값 설정 (I:신규, V: 상세보기)
                    this.PageActionType = 'V'

                    var callbackFunction = function (result) {

                        that.getBusiness()
                    }

                    // 업체 영업 화면(업체명, 영업명, 담당자,영업상태)
                    this.$refs.BZ1001E.open(callbackFunction, e.data)
                }
                if (e.rowType === "data" && e.column.dataField === "TotalMM") {
                    var menus = that.SubMenuList.filter(item => item.MenuId === 'BZ1010R')
                    if (menus.length > 0) {

                        that.subMenuClickEvent(menus[0], false)

                        // 인력 투입 계획
                        setTimeout(() => {
                            that.$EventBus.$emit('BZ1010R_OPEN', e.data)
                        }, 500)
                    }
                }
            },
			// 검색 조건 업체 검색 메서드
            onCompanyCodeClick() { 

                var that = this

                var successCallback = function (result) {

                    // 결과값을 추출 후 돔객체에 바인딩
                    that.BusinessSelectTerm.CompanyName = result.CompanyNm
                    that.BusinessSelectTerm.CompanyCode = result.CompanyCd
                    
                    that.getBusiness() // 업체 적용 후 재조회
                }
                
                this.$refs.CP1030P.open(successCallback) // 업체 검색 팝업 오픈
			},
            // 조회 버튼 클릭 이벤트 핸들러
            onSearchClick() {
                this.getBusiness()
			},
            // 추가 버튼 클릭 이벤트 핸들러
            onAddBusinessClick() {

				var that = this

                this.PageActionType = 'I' // 화면 액션값 설정 (I:신규, V: 상세보기)

                var successCallback = function (result) {

                    that.getBusiness()

                }
                
                this.$refs.BZ1001E.open(successCallback, null) // 보증 보험 상세 팝업 오픈
			},
            // 마스터 그리드 영업이력 추가 버튼 클릭
            onAddBusinessHistoryClick(e) {

                // 화면 액션값 설정 (I:신규, V: 상세보기)
                this.PageActionType = 'I'

                var callbackFunction = function (result) {

                    parent.getBusiness()
                }

                // 업체 영업이력 팝업 오픈
                this.$refs.BZ1002E.open(callbackFunction, e.row.data, null, null)

            },
            // 업체 영업 목록 조회
            getBusiness: function () {

                var that = this

                var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/GetBusinessHistory'
                url = url.toRealServiceUrl()
                var obj = new Object()

                this.BusinessSelectTerm.BusinessNo = null

                obj.jsonString = JSON.stringify(this.BusinessSelectTerm)

                var ajaxSuccessHandler = function (data) {
                    var jsonObj = JSON.parse(data.d)

                    // 확장여부 값 추가
                    jsonObj.Table.forEach(function (item) {

                        item.IsExpand = false
                    })

                    that.BusinessList = jsonObj.Table

                    // 매입상품 목록 전체 건수
                    that.TotalCount = jsonObj.Table1[0].TotCnt
                    that.BusinessHistoryListAll = jsonObj.Table2

                    // 영업관리 이력 파일 전체 목록
                    that.BusinessHistoryFileListAll = jsonObj.Table3
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
            },
		}
	}
</script>