<template>
    <div class="main-wrap">
        <!-- 왼쪽 -->
        <!-- 공지사항 게시판-->
        <a class="main-notice-wrap" id="notiLst">
            <i class="fa fa-bullhorn"></i>
            <span id="spanNotiTm" class="notice-date">{{CurrentDateYYMMDD}}</span>
            <span id="spanNotiMsg" class="notice-content">새로운 공지사항이 없습니다.</span>
        </a>
        <!-- //공지사항 게시판-->
        <div class="collapse main-search-area" id="collapseExample">
            <div class="well">
            </div>
        </div>
        <!-- 스케줄 현황 사용안함
        <header class="main-tlt-wrap">
            <div class="main-tlt">
                <h2>
                    <span>스케쥴 현황</span>
                </h2>
            </div>
        </header>
        <section class="main-contents-wrap">
            <div class="tab-content" style="padding: 0px;">
                <div class="col-xs-7" style="padding-top: 15px; padding-bottom: 15px">
                    <div class="tbl-cal-title">
                        <a href='#' class="btn btn-default btn-left">
                            <span class="glyphicon glyphicon-chevron-left">
                                <span class="hidden">이전</span>
                            </span>
                        </a>
                        <p id="calDate">{{CurrentDateYYMM}}</p>
                        <a href="#" class="btn btn-default btn-right">
                            <span class="glyphicon glyphicon-chevron-right">
                                <span class="hidden">다음</span>
                            </span>
                        </a>
                    </div>
                    <table class="table tbl-cal tbl-list table-bordered tbl-col-fixed" id="tb0">
                        <caption>캘린더</caption>
                        <colgroup>
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                        </colgroup>
                        <thead>
                            <tr style="height:70px">
                                <th scope="col">일</th>
                                <th scope="col">월</th>
                                <th scope="col">화</th>
                                <th scope="col">수</th>
                                <th scope="col">목</th>
                                <th scope="col">금</th>
                                <th scope="col">토</th>
                            </tr>
                        </thead>
                        <tbody id="calParent">
                            <tr>
                                <td class="sunday"><a href="#" id="s10"><span class="tbl-date-cont"></span></a></td>
                                <td><a href="#" id="s11"><span class="tbl-date-cont"></span></a></td>
                                <td><a href="#" id="s12"><span class="tbl-date-cont"></span></a></td>
                                <td><a href="#" id="s13"><span class="tbl-date-cont"></span></a></td>
                                <td><a href="#" id="s14"><span class="tbl-date-cont"></span></a></td>
                                <td><a href="#" id="s15"><span class="tbl-date-cont"></span></a></td>
                                <td class="saturday"><a href="#" id="s16"><span class="tbl-date-cont"></span></a></td>
                            </tr>
                            <tr>
                                <td class="sunday"><a href="#" id="s20"><span class="tbl-date-cont"></span></a></td>
                                <td><a href="#" id="s21"><span class="tbl-date-cont"></span></a></td>
                                <td><a href="#" id="s22"><span class="tbl-date-cont"></span></a></td>
                                <td><a href="#" id="s23"><span class="tbl-date-cont"></span></a></td>
                                <td><a href="#" id="s24"><span class="tbl-date-cont"></span></a></td>
                                <td><a href="#" id="s25"><span class="tbl-date-cont"></span></a></td>
                                <td class="saturday"><a href="#" id="s26"><span class="tbl-date-cont"></span></a></td>
                            </tr>
                            <tr>
                                <td class="sunday"><a href="#" id="s30"><span class="tbl-date-cont"></span></a></td>
                                <td><a href="#" id="s31"><span class="tbl-date-cont"></span></a></td>
                                <td><a href="#" id="s32"><span class="tbl-date-cont"></span></a></td>
                                <td><a href="#" id="s33"><span class="tbl-date-cont"></span></a></td>
                                <td><a href="#" id="s34"><span class="tbl-date-cont"></span></a></td>
                                <td><a href="#" id="s35"><span class="tbl-date-cont"></span></a></td>
                                <td class="saturday"><a href="#" id="s36"><span class="tbl-date-cont"></span></a></td>
                            </tr>
                            <tr>
                                <td class="sunday"><a href="#" id="s40"><span class="tbl-date-cont"></span></a></td>
                                <td><a href="#" id="s41"><span class="tbl-date-cont"></span></a></td>
                                <td><a href="#" id="s42"><span class="tbl-date-cont"></span></a></td>
                                <td><a href="#" id="s43"><span class="tbl-date-cont"></span></a></td>
                                <td><a href="#" id="s44"><span class="tbl-date-cont"></span></a></td>
                                <td><a href="#" id="s45"><span class="tbl-date-cont"></span></a></td>
                                <td class="saturday"><a href="#" id="s46"><span class="tbl-date-cont"></span></a></td>
                            </tr>
                            <tr>
                                <td class="sunday"><a href="#" id="s50"><span class="tbl-date-cont"></span></a></td>
                                <td><a href="#" id="s51"><span class="tbl-date-cont"></span></a></td>
                                <td><a href="#" id="s52"><span class="tbl-date-cont"></span></a></td>
                                <td><a href="#" id="s53"><span class="tbl-date-cont"></span></a></td>
                                <td><a href="#" id="s54"><span class="tbl-date-cont"></span></a></td>
                                <td><a href="#" id="s55"><span class="tbl-date-cont"></span></a></td>
                                <td class="saturday"><a href="#" id="s56"><span class="tbl-date-cont"></span></a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-xs-5" style="padding-top: 15px; padding-bottom: 15px">
                    <p class="tbl-cal-title text-info" id="txtResult"></p>
                    <div>
                        <table class="table tbl-list table-hover tbl-date" id="userMapTable">
                            <caption>기본 테이블</caption>
                            <colgroup>
                                <col class="" />
                                <col />
                                <col />
                                <col />
                                <col />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>시작 일시</th>
                                    <th>종료 일시</th>
                                    <th>Schedule</th>
                                    <th>구분</th>
                                </tr>
                            </thead>
                            <tbody id="databox"></tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>-->
        <header class="main-tlt-wrap">
            <div class="main-tlt">
                <a id="btnPref" class="btn" @click.prevent="changeProjectYear(true, false)">
                    <i class="glyphicon glyphicon-menu-left"></i>
                </a>
                <h2>
                    <select ref="selPrjYear" id="selPrjYear" class="form-year"
                            v-model="SelectProjectYear"
                            @change="fn_ShowData">
                        <option v-for="item in ProjectYearList" v-bind:value="item">{{item}}</option>
                    </select>
                    <input id="txtPjYear" type="hidden" v-model="SelectProjectYear" />
                    <span>프로젝트 현황</span>
                </h2>
                <a id="btnNext" class="btn" @click.prevent="changeProjectYear(false, true)">
                    <i class="glyphicon glyphicon-menu-right"></i>
                </a>
            </div>
        </header>
        <section class="main-contents-wrap">
            <div class="main-tabs-wrap">
                <div class="main-select-wrap">
                    <select class="project-search" id="selPrjStateChoice" 
                            v-model="SelectProjectStatus"
                            @change="changeProjectStatus($event.target.value)">
                        <option value="">전체</option>
                        <option v-for="item in ProjectStatusCodeList" v-bind:value="item.Code">
                            {{item.Name}}
                        </option>
                    </select>
                    <div class="project-search-result">
                        <span>
                            총 <span id="spTotCnt">{{ProjectListTotalCount}}</span>건
                        </span>
                    </div>
                    <div class="main-myproject">
                        <input type="checkbox" id="ex_chk" @click="checkedMyProject($event.target.checked)"/>
                        <label for="ex_chk">
                            <span>내 프로젝트</span>
                        </label>
                    </div>
                </div>
                <div class="main-nav-wrap">
                    <ul class="nav nav-tabs">
                        <li role="presentation" class="active main-project-list">
                            <a href="#project_list" aria-controls="project_table" role="tab" data-toggle="tab" title="프로젝트 현황 리스트">
                                <i class="ic-mini ic-list"></i>
                            </a>
                        </li>
                        <li role="presentation" class="main-project-graph">
                            <a href="#project_graph" aria-controls="project_graph" role="tab" data-toggle="tab" title="프로젝트 현황 그래프">
                                <i class="ic-mini ic-graph"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- Tab panes -->
            <div class="tab-content" style="padding-bottom: 20px;">
                <!-- 프로젝트 현황 -->
                <div role="tabpanel" class="tab-pane active" id="project_list">
                    <article class="project-board tbl-board">
                        <div class="table-responsive">
                            <table class="table tbl-list table-hover" id="projectLst">
                                <caption>테이블</caption>
                                <colgroup>
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col"><p></p></th>
                                        <th scope="col"><p>프로젝트명</p></th>
                                        <th scope="col"><p>계약일자</p></th>
                                        <th scope="col"><p>시작일자</p></th>
                                        <th scope="col"><p>종료일자</p></th>
                                        <th scope="col"><p>거래처명</p></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="ProjectList === null">
                                        <td colspan="6" class="text-center no-data">조회된 정보가 없습니다.</td>
                                    </tr>
                                    <tr v-else
                                        v-for="item in ProjectList"
                                        v-show="SelectProjectStatus === '' || item.StatusCd === SelectProjectStatus">
                                        <td v-show="item.StatusCd === '0701010'" class="project-status">
                                            <span class="project-color1">영업</span>
                                        </td>
                                        <td v-show="item.StatusCd === '0701020'" class="project-status">
                                            <span class="project-color3">착수</span>
                                        </td>
                                        <td v-show="item.StatusCd === '0701030'" class="project-status">
                                            <span class="project-color2">진행중</span>
                                        </td>
                                        <td v-show="item.StatusCd === '0701040'" class="project-status">
                                            <span class="project-color4">종료</span>
                                        </td>
                                        <td v-show="item.StatusCd === '0701050'" class="project-status">
                                            <span class="project-color5">취소</span>
                                        </td>
                                        <td class="project-name">{{item.ProjectNm}}</td>
                                        <td class="text-center">{{item.ContractYMD}}</td>
                                        <td class="text-center">{{item.ConStartYMD}}</td>
                                        <td class="text-center">{{item.ConEndYMD}}</td>
                                        <td>{{item.CompanyNm}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </article>
                </div>
                <!-- //프로젝트 현황 -->
                <!-- 그래프화  -->
                <div role="tabpanel" class="tab-pane" id="project_graph">
                    <header class="project-alret">
                        <div class="fr">
                            <ul class="project-status">
                                <li><span class="project-color2"></span> 진행중</li>
                                <li><span class="project-color1"></span> 영업</li>
                                <li><span class="project-color3"></span> 착수</li>
                                <li><span class="project-color4"></span> 종료</li>
                                <li><span class="project-color5"></span> 취소</li>
                            </ul>
                        </div>
                    </header>
                    <div class="table-responsive">
                        <table class="table tbl-graph" id="projectGraph">
                            <caption>테이블</caption>
                            <colgroup>
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th rowspan="2" class="project-name">프로젝트명</th>
                                    <th id="01" class="pj_1M  month">1월</th>
                                    <th id="02" class="pj_2M  month">2월</th>
                                    <th id="03" class="pj_3M  month">3월</th>
                                    <th id="04" class="pj_4M  month">4월</th>
                                    <th id="05" class="pj_5M  month">5월</th>
                                    <th id="06" class="pj_6M  month">6월</th>
                                    <th id="07" class="pj_7M  month">7월</th>
                                    <th id="08" class="pj_8M  month">8월</th>
                                    <th id="09" class="pj_9M  month">9월</th>
                                    <th id="10" class="pj_10M month">10월</th>
                                    <th id="11" class="pj_11M month">11월</th>
                                    <th id="12" class="pj_12M month">12월</th>
                                </tr>
                            </thead>
                            <tbody id="projectGraphColor">
                                <tr v-if="ProjectList === null">
                                    <td colspan="13" class="text-center no-data">조회된 정보가 없습니다.</td>
                                </tr>
                                <tr v-else v-for="item in ProjectList">
                                    <td class="project-name" style="text-align:left;">{{item.ProjectNm}}</td>
                                    <td another="JAN"><p></p></td>
                                    <td another="FEB"><p></p></td>
                                    <td another="MAR"><p></p></td>
                                    <td another="APR"><p></p></td>
                                    <td another="MAY"><p></p></td>
                                    <td another="JUN"><p></p></td>
                                    <td another="JUL"><p></p></td>
                                    <td another="AUG"><p></p></td>
                                    <td another="SEP"><p></p></td>
                                    <td another="OCT"><p></p></td>
                                    <td another="NOV"><p></p></td>
                                    <td another="DEC"><p></p></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- //그래프화 -->
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: 'Main',
        data() {
            return {
                ProjectStatusCodeList: null,                                        // 프로젝트 상태 공통코드 목록
                SelectProjectStatus: '',                                             // 프로젝트 상태
                SelectProjectYear: this.moment(new Date()).year(),                  // 선택한 년도
                ProjectYearList: new Array(),                                       // 년도 목록
                MainDataSet: null,                                                  // 메인 데이터 셋
                ProjectList: null,                                                  // 프로젝트 목록
                CurrentYear: this.moment(new Date()).year(),                        // 현재 년도
                ProjectListTotalCount: 0,                                           // 프로젝트 목록 총 갯수
                CurrentDateYYMMDD: this.moment(new Date()).format('YYYY-MM-DD'),    // 현재 날짜
                CurrentDateYYMM: this.moment(new Date()).format('YYYY-MM')          // 현재 날짜
            }
        },
        beforeMount() {

            // 공통코드
            this.ProjectStatusCodeList = $.scriptBase.getCommonCode('CODE000', '0701000', '', '')
            
            
            var startYear = this.CurrentYear + 2

            // 프로젝트 년도 목록 생성
            for (var i = 0; i <= 12; i++) {

                this.ProjectYearList.push(startYear - i)
            }
        },
        mounted() {

            // 프로젝트 조회
            this.fn_ShowData();
        },
        methods: {
            // 프로젝트 년도 콤보박스 변경 이벤트
            changeProjectYear: function (isPrev, isNext) {

                var startYear = Number(this.ProjectYearList[this.ProjectYearList.length - 1])
                var endYear = Number(this.ProjectYearList[0])
                var selectedYear = Number(this.SelectProjectYear)

                if (isPrev) {

                    if (selectedYear > startYear) {

                        this.SelectProjectYear = (selectedYear - 1).toString()
                    }

                    this.fn_ShowData()
                }
                else if (isNext) {

                    if (selectedYear < endYear) {

                        this.SelectProjectYear = (selectedYear + 1).toString()
                    }

                    this.fn_ShowData()
                }
            },
            // 프로젝트 진행상태 콤보박스 변경 이벤트
            changeProjectStatus: function (statusCode) {

                var parent = this

                // 프로젝트 목록 초기화
                this.ProjectList = null

                if (statusCode === '') {

                    this.ProjectList = this.MainDataSet.Table
                }
                else {

                    this.MainDataSet.Table.forEach(function (item) {

                        if (parent.ProjectList === null) {

                            parent.ProjectList = new Array()
                        }

                        if (item.StatusCd === statusCode) {

                            parent.ProjectList.push(item)
                        }
                    })

                    // 목록이 없다면 null 값 설정
                    if (this.ProjectList.length != null && this.ProjectList.length === 0) {

                        parent.ProjectList = null
                    }
                }
            },
            // 내 프로젝트 체크박스 체크 이벤트
            checkedMyProject: function (isChecked) {

                var parent = this

                // 프로젝트 목록 초기화
                this.ProjectList = null

                if (isChecked) {
                    
                    this.MainDataSet.Table.forEach(function (item) {

                        if (parent.ProjectList === null) {

                            parent.ProjectList = new Array()
                        }

                        if (item.IJoinProject === 'Y') {

                            parent.ProjectList.push(item)
                        }
                    })

                    // 목록이 없다면 null 값 설정
                    if (this.ProjectList.length != null && parent.ProjectList.length === 0) {

                        parent.ProjectList = null
                    }
                }
                else {

                    this.ProjectList = this.MainDataSet.Table
                }
            },
            // 메인 데이터 조회
            fn_ShowData: function () {

                var parent = this
                var url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/GetMain'.toRealServiceUrl()
                var obj = new Object()

                obj.jsonString = JSON.stringify({
                    'Arg': 'ML',
                    'UserId': this.$parent.EmpId,
                    'CorpCd': this.$parent.CompanyCode,
                    'StrDate': this.SelectProjectYear + '0101',
                    'EndDate': this.SelectProjectYear + '1231',
                    'CurYear': this.SelectProjectYear,
                })

                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d)
                    var table = jsonData.Table
                    var table1 = jsonData.Table1

                    parent.MainDataSet = jsonData

                    // 프로젝트 목록
                    if (table.length > 0) {

                        parent.ProjectList = table
                    }
                    else {

                        parent.ProjectList = null
                    }

                    // 프로젝트 총 갯수
                    if (table1.length > 0) {

                        parent.ProjectListTotalCount = table1[0].TotCnt
                    }
                    else {

                        parent.ProjectListTotalCount = 0
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            }
        }
    }
</script>

<style scoped src="../css/main.css"></style>