<template>
    <!-- 교육 이수 관리 -->
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle>교육 이수 관리</ViewTitle>
        <!---->
        <div class="content-box">
            <div class="btn-wrap">
                <a v-show="this.$parent.CanSearch" href="#" id="btnListSrch" class="btn btn-primary btn-row2" @click.prevent="onSearchClick">
                    <span>
                        <i class="glyphicon glyphicon-search"></i> 조회
                    </span>
                </a>
                <a v-if="this.$parent.CanNew" href="#" id="btnListInst" class="btn btn-default" @click.prevent="onAddClick">
                    <span>
                        <i class="glyphicon glyphicon-plus"></i> 추가
                    </span>
                </a>
                <!-- 삭제 버튼 추가 -->
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="cols-wrap">
                                <div class="cols col-2">
                                    <label for="selTrgtYY" class="control-label">
                                        <span>년도</span>
                                    </label>
                                    <select class="form-control" ref="selTrgtYY" name="selTrgtYY" v-model="Condition.Year">
                                        <option v-for="year in YearsList" v-bind:value="year">
                                            {{year}}
                                        </option>
                                    </select>
                                </div>
                                <div class="cols col-2">
                                    <label for="txtEmpNm" class="control-label">
                                        <span>이수자</span>
                                    </label>
                                    <div class="cols-btns-cont pad-r150">
                                        <input type="text" id="txtEmpNm" class="form-control txt" readonly="readonly" v-model="Condition.EmpNm" />
                                    </div>
                                    <div class="cols-btns">
                                        <a href="#" id="btnSearchBtn" class="btn btn-default" @click.prevent="onSearchEmpClick">
                                            <span>
                                                <i class="glyphicon glyphicon-search"></i> 검색
                                            </span>
                                        </a>
                                        <a href="#" id="btnResetBtn" class="btn btn-default" @click.prevent="onResetEmpClick">
                                            <span>
                                                <i class="glyphicon glyphicon-repeat"></i> 초기화
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </section>
                        </fieldset>
                    </form>
                </div>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">이수 교육 관리</p>
                </div>
                <div>
                    <!--리스트 영역--> 
                    <section class="tbl-contents">
                        <div class="alert alert-info total">
                            <p>
                                <i class="glyphicon glyphicon-folder-close"></i>
                                전체: <span id="spTotCnt">{{DataCount}}</span>건
                            </p>
                        </div>
                        <div class="table-responsive">
                            <DxDataGrid ref="grdList"
                                        :data-source="DataSource"
                                        :show-borders="true"
                                        :show-row-lines="true"
                                        @cell-prepared="onCellPrepared"
                                        @row-click="onRowClick">
                                <DxColumn data-field="EmpNm"
                                          caption="이수자"
                                          alignment="center"
                                          width="10%" />
                                <DxColumn data-field="ApproveNm"
                                          caption="승인여부"
                                          alignment="center"
                                          width="10%" />
                                <DxColumn data-field="EduTitle"
                                          caption="교육명"
                                          width="30%" />
                                <DxColumn data-field="EduInstitution"
                                          caption="교육기관"
                                          width="20%" />
                                <DxColumn data-field="StartDate"
                                          caption="시작일자"
                                          data-type="date"
                                          date-serialization-format="yyyy-MM-dd"
                                          alignment="center"
                                          width="10%" />
                                <DxColumn data-field="EndDate"
                                          caption="종료일자"
                                          data-type="date"
                                          date-serialization-format="yyyy-MM-dd"
                                          alignment="center"
                                          width="10%" />
                                <DxColumn data-field="EduTime"
                                          caption="이수시간"
                                          width="10%" />
                            </DxDataGrid>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <HM1051E ref="HM1051E"></HM1051E>
        <CP1020P ref="CP1020P"></CP1020P>
    </div>

</template>

<script>
    import ViewTitle from '@/components/ViewTitle'
    import HM1051E from '@/components/hm/popup/HM1051E'
    import CP1020P from '@/components/libs/popup/CP1020P'

    import DxDataGrid, { DxColumn } from 'devextreme-vue/data-grid'


    export default {
        name: 'HM1050R'
        , components: {
            ViewTitle, DxDataGrid, DxColumn, HM1051E, CP1020P
        }
        , data() {
            return {
                Condition: {
                    CorpCd: this.$parent.CompanyCode
                    , Year: this.moment(new Date()).year()
                    , EmpNo: this.$parent.EmpId
                    , EmpNm: this.$parent.UserName
                }
                , YearsList: new Array()
                , DataCount: 0
                , DataSource: new Array()
            }
        }
        , beforeMount() {
            var startYear = this.moment(new Date()).add(1, 'year').year()
            var endYear = this.moment(new Date(2013, 1, 1, 0, 0, 0)).year()

            // 대상년도 목록 값 설정
            for (var i = startYear; i >= endYear; i--) {
                this.YearsList.push(i)
            }
        }
        , methods: {
            //#region init
            //#endregion

            //#region event
            onCellPrepared(e) { // datagrid header 가운데 정렬, column 중앙 정렬
                if (e.rowType == 'header') {
                    e.cellElement.style['textAlign'] = 'center'
                }
                if (e.rowType == 'data') {
                    e.cellElement.style['verticalAlign'] = 'middle'
                }
            }
            //#endregion

            //#region button click
            , onSearchClick: function () {

                this.getEduHistory()

            }
            , onAddClick: function () {
                var that = this

                var callbackFunction = function (result) {
                    that.getEduHistory()
                }

                that.$refs.HM1051E.open(callbackFunction, null)
            }
            , onSearchEmpClick: function () {
                var that = this
                var successCallback = function (result) {

                    that.Condition.EmpNm = result.EmpNm
                    that.Condition.EmpNo = result.EmpNo
                }

                this.$refs.CP1020P.open(successCallback)
            }
            , onResetEmpClick: function () {
                this.Condition.EmpNo = null
                this.Condition.EmpNm = null
            }
            , onRowClick: function (e) {
                var that = this
                if (e.rowType === "data") {
                    var callbackFunction = function (result) {
                        that.getEduHistory()
                    }

                    that.$refs.HM1051E.open(callbackFunction, that.Condition, e.data)
                }
            }
            //#endregion

            //#region service
            , getEduHistory: function () {
                var that = this
                var url = '/Mini.Service.IBS.HM.Service/HumanMgt.svc/ajax/GetEduHistoryList'
                url = url.toRealServiceUrl()

                var obj = new Object()
                obj.jsonString = JSON.stringify(that.Condition)

                var ajaxSuccessHandler = function (data) {
                    var jsonObj = JSON.parse(data.d)

                    if (!jsonObj.Table) {
                        jsonObj.Table = new Array()
                    }

                    that.DataSource = jsonObj.Table

                    // 그리드 바인딩
                    that.DataCount = jsonObj.Table.length
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
            }
            //#endregion
        }
    }
</script>

