<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a v-show="this.$parent.CanSearch" href="#" class="btn btn-primary btn-row2" @click.prevent="fn_ShowData">
                    <span>
                        <i class="glyphicon glyphicon-search"></i>조회
                    </span>
                </a>
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="cols-wrap">
                                <div class="cols col-2">
                                    <label for="sch3-1" class="control-label">
                                        <span>시스템</span>
                                    </label>
                                    <select class="form-control" id="selSystId" name="selSystId" v-model="ProgramMatrixSelectTerm.SystemId">
                                        <option v-for="item in SystemIdList" v-bind:value="item.Code">{{item.Name}}</option>
                                    </select>
                                </div>
                                <div class="cols col-2">
                                    <label for="sch3-1" class="control-label">
                                        <span>프로그램 분류</span>
                                    </label>
                                    <select class="form-control" id="selProgrCls" name="selProgrCls"
                                            v-model="ProgramMatrixSelectTerm.ProgramClassCode"
                                            @change="getUnregisteredProgramByProgramClass">
                                        <option value="">== 전체 ==</option>
                                        <option v-for="item in ProgrClsList" v-bind:value="item.Code">{{item.Name}}</option>
                                    </select>
                                </div>
                            </section>
                        </fieldset>
                    </form>
                    </div>
                </div>
                <div class="grid-wrap">
                    <div class="sub-title">
                        <p class="sub-title-txt">프로그램 그룹 맵핑</p>
                    </div>
                    <div>
                        <article class="row tbl-option-wrap">
                            <div class="col-xs-12 col-sm-8 tbl-option-left-wrap">
                                <div class="col-xs-12 col-sm-6 pad-l0">
                                    <h4>
                                        대상 그룹 (<span id="spTotCnt0">{{ProgramMatrixListTotalCount}}</span>건)
                                    </h4>
                                    <div class="table-responsive-always table-responsive-height">
                                        <table class="table tbl-list table-hover table-bordered" id="tb0">
                                            <caption></caption>
                                            <colgroup>
                                                <col />
                                                <col />
                                                <col />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th class="text-center">선택</th>
                                                    <th class="text-center">그룹ID</th>
                                                    <th class="text-center">그룹명</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="ProgramMatrixList === null">
                                                    <td colspan="3" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                </tr>
                                                <tr v-else v-for="(item, index) in ProgramMatrixList">
                                                    <td class="text-center">
                                                        <label for="cb0" class="hidden">선택</label>
                                                        <input name="cb0" type="radio"
                                                               v-on:change="targetGroupChangeEvent"
                                                               v-bind:checked="SelectTargetGroupId === item.ProgrGrpId"
                                                               :value="item.ProgrGrpId" />
                                                    </td>
                                                    <td class="text-center">{{item.ProgrGrpId}}</td>
                                                    <td>{{item.ProgrGrpName}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 pad-r0">
                                    <h4>
                                        등록 프로그램 (<span id="spTotCnt1">{{ProgramMatrixListTotalCount1}}</span>건)
                                    </h4>
                                    <div class="table-responsive-always table-responsive-height">
                                        <table class="table tbl-list table-hover table-bordered" id="tb1">
                                            <caption></caption>
                                            <colgroup>
                                                <col />
                                                <col />
                                                <col />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th class="text-center">
                                                        <label for="cbAll1" class="hidden">전체선택</label>
                                                        <input name="cbAll1" type="checkbox" @click="checkedAllEvent($event.target.checked, 'cb1')">
                                                    </th>
                                                    <th class="text-center">프로그램ID</th>
                                                    <th class="text-center">프로그램명</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="ProgramMatrixList1 === null">
                                                    <td colspan="3" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                </tr>
                                                <tr v-else v-for="item in ProgramMatrixList1">
                                                    <td class="text-center">
                                                        <label for="cb1" class="hidden">선택</label>
                                                        <input name="cb1" type="checkbox" :value="item.ProgrId" />
                                                    </td>
                                                    <td class="text-center">{{item.ProgrId}}</td>
                                                    <td>{{item.ProgrName}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- 버튼영역-->
                                <div v-show="this.$parent.CanSave" class="tbl-option-btn-wrap">
                                    <a id="btnInstData" href="#" class="btn btn-default" @click.prevent="addUnregisteredProgram">
                                        <span class="glyphicon glyphicon-chevron-left"></span>
                                    </a>
                                    <a id="btnRmveData" href="#" class="btn btn-default" @click.prevent="removeRegisteredProgram">
                                        <span class="glyphicon glyphicon-chevron-right"></span>
                                    </a>
                                </div>
                                <!-- //버튼영역 -->
                            </div>
                            <div class="col-xs-12 col-sm-4 tbl-option-right-wrap">
                                <h4>
                                    미등록 프로그램 (<span id="spTotCnt2">{{ProgramMatrixListTotalCount2}}</span>건)
                                </h4>
                                <div class="table-responsive-always table-responsive-height">
                                    <table class="table tbl-list table-hover table-bordered" id="tb2">
                                        <caption></caption>
                                        <colgroup>
                                            <col />
                                            <col />
                                            <col />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th class="text-center">
                                                    <label for="cbAll2" class="hidden">전체선택</label>
                                                    <input name="cbAll2" type="checkbox" @click="checkedAllEvent($event.target.checked, 'cb2')">
                                                </th>
                                                <th class="text-center">프로그램ID</th>
                                                <th class="text-center">프로그램명</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="ProgramMatrixList2 === null">
                                                <td colspan="3" class="text-center no-data">조회된 정보가 없습니다.</td>
                                            </tr>
                                            <tr v-else v-for="item in ProgramMatrixList2">
                                                <td class="text-center">
                                                    <label for="cb2" class="hidden">선택</label>
                                                    <input name="cb2" type="checkbox" :value="item.ProgrId">
                                                </td>
                                                <td class="text-center">{{item.ProgrId}}</td>
                                                <td>{{item.ProgrName}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle'
    export default {
        name: 'ZZ1012R',
        components: {
            ViewTitle
        },
        data() {
            return {
                SystemIdList: null,
                ProgrClsList: null,
                // 조회조건 객체
                ProgramMatrixSelectTerm: {
                    Arg: null,
                    UserId: this.$parent.EmpId,
                    CorpCd: this.$parent.CompanyCode,
                    SystemId: '10',
                    ProgramClassCode: ''
                },
                ProgramMatrixList: null,            // 대상그룹 목록
                ProgramMatrixList1: null,           // 등록 프로그램 목록
                ProgramMatrixList2: null,           // 미등록 프로그램 목록
                ProgramMatrixListTotalCount: 0,     // 대상그룹 목록 전체 갯수
                ProgramMatrixListTotalCount1: 0,    // 등록 프로그램 목록 전체 갯수
                ProgramMatrixListTotalCount2: 0,    // 미등록 프로그램 목록 전체 갯수
                SelectTargetGroupId: 0,
                RegisteredProgramListChecked: false,
                UnregisteredProgramListChecked: false
            }
        },
        beforeMount() {

            // 공통코드 조회
            this.SystemIdList = $.scriptBase.getCommonCode('CODE090', this.$parent.CompanyCode, '', '')
            this.ProgrClsList = $.scriptBase.getCommonCode('CODE000', '0901000', '', '')
        },
        mounted() {

            if (this.$parent.CanSearch) {

                this.fn_ShowData(false)
            }
        },
        methods: {
            // 프로그램 분류별 프로그램 목록 조회
            getUnregisteredProgramByProgramClass: function () {

                if (document.querySelectorAll('input[name="cb0"]:checked').length > 0) {

                    var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/GetUnregisteredProgramByProgramClass'
                    url = url.toRealServiceUrl()

                    var obj = new Object()

                    obj.jsonString = JSON.stringify({
                        'Arg': 'S3',
                        'UserId': this.$parent.EmpId,
                        'CorpCd': this.$parent.CompanyCode,
                        'SystId': this.ProgramMatrixSelectTerm.SystemId,
                        'ProgrGrpId': this.SelectTargetGroupId,
                        'ProgrCls': this.ProgramMatrixSelectTerm.ProgramClassCode
                    })

                    var parent = this
                    var successCallback = function (data) {

                        var table = JSON.parse(data.d).Table;

                        if (table.length > 0) {

                            parent.ProgramMatrixList2 = table
                            parent.ProgramMatrixListTotalCount2 = table.length
                        }
                        else {

                            parent.ProgramMatrixList2 = null
                            parent.ProgramMatrixListTotalCount2 = 0
                        }
                    }

                    $.scriptBase.executeAjaxAsync(url, obj, successCallback);
                }
            },
            checkedAllEvent: function (isChecked, targetElementName) {

                document.getElementsByName(targetElementName).forEach(function (item) {

                    item.checked = isChecked
                })

                if (targetElementName === 'cb1') {

                    document.getElementsByName('cbAll1').forEach(function (item) {

                        item.checked = isChecked
                    })
                }
                else if (targetElementName === 'cb2') {

                    document.getElementsByName('cbAll2').forEach(function (item) {

                        item.checked = isChecked
                    })
                }
            },
            // 대상그룹 변경 이벤트
            targetGroupChangeEvent(e) {

                var target = e.target;

                this.SelectTargetGroupId = target.value
                this.getProgramInTargetGroup()
            },
            // 미등록 프로그램 등록
            addUnregisteredProgram: function () {
                
                if ($("input[type='checkbox'][name='cb2']:checked").length == 0) {

                    alert('미등록 프로그램을 선택하세요.')
                    return
                }

                var parent = this
                var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/AddUnregisteredProgram'.toRealServiceUrl()
                var obj = new Object()

                var programList = new Array()

                document.querySelectorAll('input[type="checkbox"][name="cb2"]:checked').forEach(function (item) {

                    programList.push({
                        'Arg': 'I',
                        'UserId': parent.$parent.EmpId,
                        'CorpCd': parent.$parent.CompanyCode,
                        'SystId': parent.ProgramMatrixSelectTerm.SystemId,
                        'ProgrGrpId': parent.SelectTargetGroupId,
                        'ProgrId': item.value
                    })
                })

                obj.jsonString = JSON.stringify(programList)

                var successCallback = function (data) {

                    if (data.d > 0) {

                        parent.getProgramInTargetGroup()
                    }
                }
                
                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 등록 프로그램 삭제
            removeRegisteredProgram: function () {

                if (document.querySelectorAll('input[name="cb1"]:checked').length === 0) {

                    alert('등록 프로그램을 선택하세요.')
                    return
                }

                var parent = this
                var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/RemoveRegisteredProgram'.toRealServiceUrl()
                var obj = new Object()

                var programList = new Array()

                document.querySelectorAll('input[name="cb1"]:checked').forEach(function (item) {

                    programList.push({
                        'Arg': 'D',
                        'UserId': parent.$parent.EmpId,
                        'CorpCd': parent.$parent.CompanyCode,
                        'SystId': parent.ProgramMatrixSelectTerm.SystemId,
                        'ProgrGrpId': parent.SelectTargetGroupId,
                        'ProgrId': item.value
                    })
                })

                obj.jsonString = JSON.stringify(programList)

                var successCallback = function (data) {

                    if (data.d > 0) {

                        parent.getProgramInTargetGroup()
                    }
                }
                
                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            // 대상그룹에 속해있는 프로그램 목록 조회
            getProgramInTargetGroup: function () {

                var parent = this
                var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/GetProgramInTargetGroup'.toRealServiceUrl()
                var obj = new Object()

                obj.jsonString = JSON.stringify({
                    'Arg': 'S2',
                    'UserId': this.$parent.EmpId,
                    'CorpCd': this.$parent.CompanyCode,
                    'SystId': this.ProgramMatrixSelectTerm.SystemId,
                    'ProgrGrpId': this.SelectTargetGroupId,
                    'ProgrCls': this.ProgramMatrixSelectTerm.ProgramClassCode
                })
                
                var successCallback = function (data) {

                    var table = JSON.parse(data.d).Table;
                    var table1 = JSON.parse(data.d).Table1;

                    if (table.length > 0) {

                        parent.ProgramMatrixList1 = table
                        parent.ProgramMatrixListTotalCount1 = table.length
                    }
                    else {

                        parent.ProgramMatrixList1 = null
                        parent.ProgramMatrixListTotalCount1 = 0
                    }

                    if (table1.length > 0) {

                        parent.ProgramMatrixList2 = table1
                        parent.ProgramMatrixListTotalCount2 = table1.length
                    }
                    else {

                        parent.ProgramMatrixList2 = null
                        parent.ProgramMatrixListTotalCount2 = 0
                    }

                    parent.checkedAllEvent(false, 'cb1')
                    parent.checkedAllEvent(false, 'cb2')
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 데이터 조회
            fn_ShowData: function () {

                var parent = this
                var url = '/Mini.Service.IBS.ZZ.Service/SystMgt.svc/ajax/GetProgramMatrix'.toRealServiceUrl()
                var obj = new Object()

                // 플래그 값 설정
                this.ProgramMatrixSelectTerm.Arg = 'S1'

                obj.jsonString = JSON.stringify(this.ProgramMatrixSelectTerm)

                var successCallback = function (data) {

                    var json = JSON.parse(data.d)

                    var table = json.Table
                    var table1 = json.Table1
                    var table2 = json.Table2

                    if (table.length > 0) {

                        parent.ProgramMatrixList = table
                        parent.ProgramMatrixListTotalCount = table.length
                        parent.SelectTargetGroupId = table[0].ProgrGrpId
                    }
                    else {

                        parent.ProgramMatrixListTotalCount = 0
                        parent.ProgramMatrixList = null
                        parent.SelectTargetGroupId = 0
                    }

                    if (table1.length > 0) {

                        parent.ProgramMatrixList1 = table1
                        parent.ProgramMatrixListTotalCount1 = table1.length
                    }
                    else {

                        parent.ProgramMatrixList1 = null
                        parent.ProgramMatrixListTotalCount1 = 0
                    }

                    if (table2.length > 0) {
                        parent.ProgramMatrixList2 = table2
                        parent.ProgramMatrixListTotalCount2 = table2.length
                    }
                    else {

                        parent.ProgramMatrixList2 = null
                        parent.ProgramMatrixListTotalCount2 = 0
                    }

                    parent.checkedAllEvent(false, 'cb1')
                    parent.checkedAllEvent(false, 'cb2')
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            }
        }
    }
</script>

<style scoped>
</style>