﻿import Vue from 'vue'
import Vuex from 'vuex'

import getters from './getters'
import userInfo from './modules/user-info'
import authInfo from './modules/auth-info'
import appInfo from './modules/app-info'
//import projectName from './modules/project-name'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        userInfo, authInfo, appInfo
    },
    getters
})


