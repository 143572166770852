<template>
	<!-- 상세등록 전자결재 -->
	<div class="modal fade" id="AC1013E_Modal" ref="AC1013E_Modal" tabindex="-1" role="dialog" aria-labelledby="AC1013E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<button type="button" class="close" @click.Prevent="close">
						<span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
					</button>
					<h3 class="modal-title">매입 요청</h3>
				</div>
				<!--결재 요청-->
				<div class="modal-body">
					<section class="add-wrap">
						<form>
							<fieldset>
								<legend></legend>
								<section class="cols-wrap">
									<div class="cols">
										<label for="txtContractPaymentNm" class="control-label">
											<span><i class="glyphicon glyphicon-asterisk"></i>결재 내역</span>
										</label>
										<input type="text" id="txtContractNm" class="form-control txt" v-model="PurchasePlanDetail.ProductPaymentNm" />
									</div>
								</section>
								<span hidden="hidden" v-text="ProductPayment.ProductPaymentDetail"></span>
								<div id="contractDetail">
									<section id="section1" class="cols-wrap">
										<div class="cols">
											<label for="txtContractNm" class="control-label">
												<span>매입명<br /></span>
											</label>
											<label class="form-control txt">
												<span>{{PurchasePlanDetail.PurchaseNm}}</span>
											</label>
										</div>
									</section>
									<section id="section1" class="cols-wrap">
										<div class="cols col-2">
											<label for="txtContractNm" class="control-label">
												<span>매입출처<br /></span>
											</label>
											<label class="form-control txt">
												<span>{{PurchasePlanDetail.SrcDvsCd}}</span>
											</label>
										</div>
										<div class="cols col-2">
											<label for="txtContractNm" class="control-label">
												<span>매입금액<br /></span>
											</label>
											<label class="form-control txt">
												<span>{{PurchasePlanDetail.Amt}}</span>
											</label>
										</div>
									</section>
									<section id="section1" class="cols-wrap">
										<div class="cols col-2">
											<label for="txtContractNm" class="control-label">
												<span>매입부가세<br /></span>
											</label>
											<label class="form-control txt">
												<span>{{PurchasePlanDetail.VAT}}</span>
											</label>
										</div>
										<div class="cols col-2">
											<label for="txtContractNm" class="control-label">
												<span>매입실행일<br /></span>
											</label>
											<label class="form-control txt">
												<span>{{PurchasePlanDetail.ExcuteDt_}}</span>
											</label>
										</div>
									</section>
									<section class="cols-wrap">
										<div class="cols">
											<label for="txtContractNm" class="control-label">
												<span>비고<br /></span>
											</label>
											<label class="form-control txt">
												<span>{{PurchasePlanDetail.ResonText}}</span>
											</label>
										</div>
									</section>
								</div>
							<!--<div>
								<h4>
									매입명 : 
								</h4>
								<br>
								<table>
									<thead>
										<tr>
											<th style="text-align: left; width: 320px; border:1px solid black;">Task Subject</th>
											<th style="text-align: center; border: 1px solid black;">Status</th>
											<th style="text-align: right; border: 1px solid black;">Progress</th>
										</tr>
									</thead>
									<tbody>
										<tr style="text-align: center;">
											<td style="text-align: left; width: 320px; border: 1px solid black;">Approve Personal Computer Upgrade Plan</td>
											<td style="text-align: center; color: #5cb85c; border: 1px solid black;">
												<p style="font-size: 1.15em;">✓</p>
												<p>Completed</p>
											</td>
											<td style="text-align: right; border: 1px solid black;">100%</td>
										</tr>
									</tbody>
								</table>
								<br>
							</div>-->
								<section class="btn-align">
									<div v-show="CanSave" class="btn-group">
										<button type="button" id="btnItemApprove1" class="btn btn-primary" @click.prevent="approveData">
											<i class="glyphicon glyphicon-save"></i> 매입요청
										</button>
									</div>
									<div class="btn-group">
										<button type="button" class="btn btn-danger" @click.Prevent="close">
											<i class="glyphicon glyphicon-remove"></i> 취소
										</button>
									</div>
								</section>
							</fieldset>
						</form>
					</section>
				</div>
			</div>
		</div>
		<form action="https://office.hiworks.com/minisoft/approval/popup/write" target="_blank" method="post" ref="frmApproval">
			<input type="hidden" name="form_id" value="N_35" />
			<input type="hidden" name="subject" v-model="PurchasePlanDetail.ProductPaymentNm" />
			<input type="hidden" name="contents" v-model="'<p><H1>매입 결재 요청</H1></p>'+ProductPayment.ProductPaymentDetail" />
			<input type="hidden" name="callback_url" value="https://ibs.minisoft.co.kr/v2/services/HumanMgt_NoneAuth.svc/Get/SetAccountPurchaseApprove" />
			<!--<input type="hidden" name="callback_url" value="http://demo.minisoft.co.kr/v2/services/HumanMgt_NoneAuth.svc/Get/SetAccountPurchaseApprove" />-->
			<input type="hidden" name="modify_contents_flag" value="Y" />
			<input type="hidden" name="modify_files_flag" value="Y" />
			<input type="hidden" name="state" v-model="PurchasePlanDetail.SeqNo" />
		</form>
	</div>
</template>

<script>
	import DxButton from 'devextreme-vue/button';
	import DxDataGrid, {
		DxColumn,
		DxEditing,
		DxLookup,
		DxSelection,
		DxScrolling
	} from 'devextreme-vue/data-grid'

	import {
		DxDateBox,
		DxCalendarOptions
	} from 'devextreme-vue/date-box'
	import {
		DxHtmlEditor,
		DxToolbar,
		DxMediaResizing,
		DxItem
	} from 'devextreme-vue/html-editor'

	export default {
		name: 'AC1013E',
		components: {
			DxDateBox,
			DxButton,
			DxDataGrid,
			DxColumn,
			DxEditing,
			DxLookup,
			DxSelection,
			DxScrolling,
			DxCalendarOptions,
			DxHtmlEditor,
			DxToolbar,
			DxMediaResizing,
			DxItem,
		},
		data() {
			return {
				CallbackFunction: null,     // Caller Function
				Condition: null,
				PurchasePlanDetail: {
					Arg: 'I',
					//UserId: this.$parent.EmpId,
					//UserName: this.$parent.UserName,
					UserId: null,
					UserName: null,
					OrderSeq: null,
					SeqNo: null,
					ExcuteDt: null,
					PurchaseNm: null,
					SrcDvsCd: '',
					SrcDvcName: null,
					ProjectCd: null,
					ProjectNm: null,
					Amt: null,
					VAT: null,
					ConfirmYN: 'N',
					RejectYN: 'N',
					RejectEmpNo: null,
					RejectReason: null,
					RejectNm: null,
					IsOwner: null,
					//EMail: this.$parent.Email,
					EMail: null,
					IsEdit: 'N',
					ExcuteDt_: null,
					ProductPaymentNm: null,
					ResonText: null
				},
				ProductPayment: {
					ProductPaymentDetail: null
				},
				CanSave: true,
				CanDelete: true,
				CanApprove: true,
				sizeValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'],
				IsNew: true,
				isMultiLine: true,
				pageIndex: 0,
			}
		},
		beforeMount() {
			// 공통코드 조회
			//this.Progress = $.scriptBase.getCommonCode('CODE000', '0732000', '', '')				// 프로젝트 구분
			//this.ConstractStatusList = $.scriptBase.getCommonCode('CODE000', '0733000', '', '')		// 계약진행상태
			//this.CostPlayDivList = $.scriptBase.getCommonCode('CODE000', '0721100', '', '')			// 대금지급
			//this.ContractDivList = $.scriptBase.getCommonCode('CODE000', '0744000', '', '')			// 계약구분
		},
		methods: {
			open: function (callbackFunction, selectedObj) {

				if (selectedObj) {
					// 전자 결재 완료 여부
					this.PurchasePlanDetail = selectedObj
					this.ProductPayment.ProductPaymentDetail = this.$el.children[0].children[0].children[1].children[0].children[0][0].children[2].innerHTML
					//this.selectContractManpower()
					//this.ContractDetail.EmpNo = this.$parent.$parent.$parent.$parent.$parent.$parent.EmpId
					//this.ContractDetail.ContractStartDate = (selectedObj.ContractStartDate).slice(0, 10)
					//this.ContractDetail.ContractEndDate = (selectedObj.ContractEndDate).slice(0, 10)
					//this.DataSource = this.$parent.DataSource
					//this.DataSourceList = this.$parent.DataSourceList
					//this.ContractProductList = this.$parent.ProductList
					//this.ContractPayment.ContractPaymentDetail = this.$el.children[0].children[0].children[1].children[0].children[0][0].children[3].outerHTML

				} else {
					alert("잘못된 접근입니다.")
				}

				this.CallbackFunction = callbackFunction

				$("#AC1013E_Modal").modal("show")
			},
			close: function () {
				$("#AC1013E_Modal").modal("hide")
				this.CallbackFunction()
			},
			reset() {
				Object.assign(this.$data, this.$options.data())
			},
			// 계약상세 월별투입인력 조회
			//selectContractManpower: function () {

			//	var that = this
			//	var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/GetContractMMPlanList'
			//	url = url.toRealServiceUrl()

			//	var obj = new Object()
			//	obj.jsonString = JSON.stringify(that.ContractDetail)

			//	var ajaxSuccessHandler = function (data) {

			//		var jsonObj = JSON.parse(data.d)

			//		that.resetColumns()

			//		if (!jsonObj.Table) {
			//			jsonObj.Table = new Array()
			//		}

			//		that.DataSource = jsonObj.Table
			//		// 그리드 바인딩
			//		that.DataCount = jsonObj.Table.length

			//		var columnsCount = that.DataCount > 0 ? Object.keys(jsonObj.Table[0]).length : 0

			//		for (var i = 0; i < columnsCount - 9; i++) {
			//			var columnName = Object.keys(jsonObj.Table[0])[i]
			//			var newColumn = { 'caption': columnName, 'dataField': columnName, width: '100' }
			//			that.DefaultColumns.push(newColumn)
			//			that.ContractPayment.ContractPaymentDetail = that.$el.children[0].children[0].children[1].children[0].children[0][0].children[3].innerHTML
			//		}
			//		//that.ContractPayment.ContractPaymentDetail = that.$el.children[0].children[0].children[1].children[0].children[0][0].children[3].outerHTML
			//		that.ContractPayment.ContractPaymentDetail = that.$el.children[0].children[0].children[1].children[0].children[0][0].children[3].innerHTML
			//	}

				

			//	$.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
			//},
			resetColumns: function () {
				var cnt = this.DefaultColumns.length

				for (var i = 4; i < cnt; i++) {
					this.DefaultColumns.splice(4, 1)
				}
			},
			approveData: function () {
				// 결제내역 필수 값 유효성 검사
				if (!this.PurchasePlanDetail.ProductPaymentNm) {

					alert('결재내역 명을 입력하세요.')
					document.getElementById('txtContractNm').focus()

					return
				}
				this.ProductPayment.ProductPaymentDetail = this.$el.children[0].children[0].children[1].children[0].children[0][0].children[2].innerHTML
				// popup
				//alert("{{ ContractDetail.ContractContents}}")
				
				//this.ContractPayment.ContractPaymentDetail = this.$el.children[0].children[0].children[1].children[0].children[0][0].children[3].outerHTML
				//this.ContractPayment.ContractPaymentDetail = this.$el.children[0].children[0].children[1].children[0].children[0][0].children[3].innerHTML
				this.$refs.frmApproval.submit()
				this.close()
			},
		}
	}
</script>
