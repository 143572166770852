<template>
    <div class="modal fade" id="PM1021E_Modal" ref="PM1021E_Modal" tabindex="-1" role="dialog" aria-labelledby="PM1021E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" @click.prevent="close">
                        <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                    </button>
                    <h3 class="modal-title" v-if="PageActionType == 'I'">수금계획관리 입력</h3>
                    <h3 class="modal-title" v-if="PageActionType == 'V'">수금계획관리 수정</h3>
                </div>
                <div class="modal-body">
                    <section class="add-wrap">
                        <form>
                            <fieldset>
                                <legend>입력폼 col3</legend>
                                <section class="cols-wrap" id="divBatchOrNot" v-if="PageActionType == 'I'">
                                    <div class="col-4 radio-control">
                                        <input type="radio" id="single" name="BatchOrNot" v-model="BatchOrNot" value="single" style="margin-right: 5px;">
                                        <label class="control-label" for="single">
                                            <span>단건 등록</span>
                                        </label>
                                    </div>
                                    <div class="col-4 radio-control">
                                        <input type="radio" id="batch" name="BatchOrNot" v-model="BatchOrNot" value="batch" style="margin-right: 5px;">
                                        <label class="control-label" for="batch">
                                            <span>일괄 등록</span>
                                        </label>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtProjectNm2" class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>프로젝트
                                            </span>
                                        </label>
                                        <div class="cols-btns-cont">
                                            <input type="text" id="txtProjectNm2" class="form-control txt" readonly="readonly" v-model="CollectMoneyDetail.ProjectNm" />
                                            <input type="hidden" id="hidProjectCd2" v-model="CollectMoneyDetail.ProjectCd" />
                                        </div>
                                        <div class="cols-btns">
                                            <a href="#" id="btnItemPop2" class="btn btn-default" @click.prevent="searchProjectCode" v-show="PageActionType == 'I'">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i> 검색
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtCompanyNm" class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>거래처
                                            </span>
                                        </label>
                                        <input type="text" id="txtCompanyNm2" class="form-control txt" readonly="readonly" v-model="CollectMoneyDetail.CompanyNm" />
                                        <input type="hidden" id="hidCompanyCd2" v-model="CollectMoneyDetail.CompanyCd" />
                                        <input type="hidden" id="hidContractCompanyCd2" />
                                    </div>
                                </section>
                                <section class="cols-wrap" v-show="PageActionType == 'I'">
                                    <div class="cols col-2">
                                        <label for="txtProjectPrice2" class="control-label">
                                            <span>계약금액</span>
                                        </label>
                                        <input type="text" id="txtProjectPrice2" class="form-control text-right" readonly="readonly" v-model="CollectMoneyDetail.ProjectPrice" />
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtProjectLeftPrc2" class="control-label">
                                            <span>남은금액</span>
                                        </label>
                                        <input type="text" id="txtProjectLeftPrc2" class="form-control text-right" readonly="readonly" v-model="CollectMoneyDetail.ProjectLeftPrc" />
                                    </div>
                                </section>
                                <!--빈공백-->
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label></label>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="selCollectDvsCd2" class="control-label">
                                            <span><i class="glyphicon glyphicon-asterisk"></i>수금종류</span>
                                        </label>
                                        <select class="form-control" id="selCollectDvsCd2" name="selCollectDvsCd2" v-model="CollectMoneyDetail.CollectDvsCd" v-bind:disabled="CollectMoneyDetail.BillYMDUseYN == 'Y'"
                                                @change="function(e){CollectMoneyDetail.CollectDvsName=e.target.options[e.target.options.selectedIndex].text}">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in CollectDvsCodeList" v-bind:value="item.Code">{{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtPrice2" class="control-label">
                                            <span>금액</span>
                                        </label>
                                        <input type="text" id="txtPrice2" class="form-control text-right" v-model="CollectMoneyDetail.Price"
                                               v-bind:disabled="CollectMoneyDetail.BillYMDUseYN == 'Y'"
                                               @input="function(e){
                                                    CollectMoneyDetail.Price = e.target.value.toPriceString()
                                                    e.target.value = e.target.value.toPriceString()
                                               }" />
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="selVatIncludeYn" class="control-label">
                                            <span>부가세구분</span>
                                        </label>
                                        <select class="form-control" id="selVatIncludeYn" ref="selVatIncludeYn" name="selVatIncludeYn" v-model="CollectMoneyDetail.IncludeVatYn"
                                                @change="selectBoxVatChangedEvent" v-bind:disabled="CollectMoneyDetail.BillYMDUseYN == 'Y'">
                                            <option value="">== 선택 ==</option>
                                            <option value="Y">포함</option>
                                            <option value="N">별도</option>
                                            <option value="Z">영세율</option>
                                            <option value="F">면세</option>
                                        </select>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtVAT2" class="control-label">
                                            <span>부가세</span>
                                        </label>
                                        <input type="text" id="txtVAT2" class="form-control text-right" style="background-color: #EAEAEA;" readonly="readonly"
                                               v-model="CollectMoneyDetail.VAT" @click="removeReadonly" />
                                    </div>
                                </section>
                                <section class="cols-wrap" v-if="BatchOrNot === 'single'">
                                    <div class="cols col-2">
                                        <label for=" txtbilldueymd2" class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>발행예정일
                                            </span>
                                        </label>
                                        <div class="input-group date col-1 form-control">
                                            <DxDateBox display-format="yyyy-MM-dd"
                                                       pickerType="calendar"
                                                       v-model="CollectMoneyDetail.BillDueStartYMD"
                                                       :input-attr="{ 'aria-lable' : 'Disabled' }"
                                                       :disabled="CollectMoneyDetail.BillYMDUseYN == 'Y'"
                                                       style="border: none;">
                                            </DxDateBox>
                                        </div>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtPayInDueYMD" class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>입금예정일
                                            </span>
                                        </label>
                                        <div class="input-group date col-1 form-control">
                                            <DxDateBox display-format="yyyy-MM-dd"
                                                       pickerType="calendar"
                                                       v-model="CollectMoneyDetail.PayInDueStartYMD"
                                                       :input-attr="{ 'aria-lable' : 'Disabled' }"
                                                       :disabled="CollectMoneyDetail.BillYMDUseYN == 'Y'"
                                                       style="border: none;">
                                            </DxDateBox>
                                        </div>
                                    </div>
                                </section>
                                <section v-if="BatchOrNot == 'batch' && PageActionType == 'I'">
                                    <section class="cols-wrap">
                                        <div class="cols col-1">
                                            <label class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>발행예정일
                                                </span>
                                            </label>
                                            <div class="col-xs-7">
                                                <div class="col-2 input-group date">
                                                    <DxDateBox display-format="yyyy-MM"
                                                               pickerType="calendar"
                                                               v-model="BatchCalculateTerm.BatchBillStart"
                                                               style="border-radius:0;">
                                                        <DxCalendarOptions maxZoomLevel="year" />
                                                    </DxDateBox>
                                                </div>
                                                <div class="tail"><span>~</span></div>
                                                <div class="col-2 input-group date">
                                                    <DxDateBox display-format="yyyy-MM"
                                                               pickerType="calendar"
                                                               v-model="BatchCalculateTerm.BatchBillEnd"
                                                               style="border-radius:0;">
                                                        <DxCalendarOptions maxZoomLevel="year" />
                                                    </DxDateBox>
                                                </div>
                                            </div>
                                            <div class="col-xs-3">
                                                <select class="form-control" id="selBatchDay" ref="selBatchDay" name="selBatchDay" v-model="BatchCalculateTerm.BatchBillDay">
                                                    <option value="">== 일자 ==</option>
                                                    <option v-for="date in TargetDate" v-bind:value="date">{{ date }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </section>
                                    <section class="cols-wrap">
                                        <div class="cols col-1">
                                            <label class="control-label">
                                                <span>
                                                    <i class="glyphicon glyphicon-asterisk"></i>입금예정일
                                                </span>
                                            </label>
                                            <div class="col-xs-7">
                                                <div class="col-2 input-group date">
                                                    <DxDateBox display-format="yyyy-MM"
                                                               pickerType="calendar"
                                                               v-model="BatchCalculateTerm.BatchPayStart"
                                                               style="border-radius:0;">
                                                        <DxCalendarOptions maxZoomLevel="year" />
                                                    </DxDateBox>
                                                </div>
                                                <div class="tail"><span>~</span></div>
                                                <div class="col-2 input-group date">
                                                    <DxDateBox display-format="yyyy-MM"
                                                               pickerType="calendar"
                                                               v-model="BatchCalculateTerm.BatchPayEnd"
                                                               style="border-radius:0;">
                                                        <DxCalendarOptions maxZoomLevel="year" />
                                                    </DxDateBox>
                                                </div>
                                            </div>
                                            <div class="col-xs-3">
                                                <select class="form-control" id="selBatchDay" ref="selBatchDay" name="selBatchDay" v-model="BatchCalculateTerm.BatchPayDay">
                                                    <option value="">== 일자 ==</option>
                                                    <option v-for="date in TargetDate" v-bind:value="date">{{ date }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </section>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-1">
                                        <label for="txtCollectMemo" class="control-label">
                                            <span>비고</span>
                                        </label>
                                        <textarea id="txtCollectMemo" class="form-control" v-model="CollectMoneyDetail.CollectMemo" v-bind:disabled="CollectMoneyDetail.BillYMDUseYN === 'Y'" />
                                    </div>
                                </section>
                            </fieldset>
                        </form>
                    </section>
                </div>
                <div class="modal-footer">
                    <section class="btn-align">
                        <label v-show="CollectMoneyDetail.BillYMDUseYN == 'Y'" id="txtHelpKnowTxt" class="control-label">
                            <span>매출세금계산서가 발행되었습니다. 수정, 삭제가 불가능합니다.</span>
                        </label>
                        <div class="btn-group">
                            <button v-show="CollectMoneyDetail.BillYMDUseYN != 'Y'" type="button" id="btnItemSave" class="btn btn-primary" @click.prevent="saveCollectMoney">
                                <i class="glyphicon glyphicon-save"></i> 저장
                            </button>
                        </div>
                        <div class="btn-group">
                            <button v-show="CollectMoneyDetail.BillYMDUseYN != 'Y' && PageActionType == 'V'" type="button" id="btnItemRmve" class="btn btn-warning" @click.Prevent="removeCollectMoney">
                                <i class="glyphicon glyphicon-edit"></i> 삭제
                            </button>
                        </div>
                        <div class="btn-group">
                            <button type="button" class="btn btn-danger" data-dismiss="modal" @click.prevent="close">
                                <i class="glyphicon glyphicon-remove"></i> 취소
                            </button>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <CP1060P ref="CP1060P"></CP1060P>
    </div>
</template>

<style>
    .radio-control {
        float: left;
        padding-bottom: 20px;
    }
</style>

<script>
    import DxDateBox, { DxCalendarOptions } from 'devextreme-vue/date-box';
    import CP1060P from '@/components/libs/popup/CP1060P';

    export default {
        name: 'PM1021E',
        components: { DxDateBox, DxCalendarOptions, CP1060P },
        data() {
            return {
                CallbackFunction: null,
                // 입력 화면액션 플래그 (I: 신규, V: 상세)
                PageActionType: 'I',
                // 일괄 등록 여부 (batch: 일괄, single: 단건)
                BatchOrNot: 'single',
                // 수금종류 코드 목록
                CollectDvsCodeList: new Array(),
                // 수금계획 상세 객체
                CollectMoneyDetail: new Object(),
                // 발행예정일, 입금예정일 계산 조건
                BatchCalculateTerm: {
                    BatchBillStart: null,
                    BatchBillEnd: null,
                    BatchPayStart: null,
                    BatchPayEnd: null,
                    BatchBillDay: '',
                    BatchPayDay: '',
                },
                // 일괄등록 시 날짜 선택 조건
                TargetDate: new Array(),
                // 남은 금액
                TotPrice: 0,
                // 부가세 수정여부
                ModifiedVat: false,
            }
        },
        watch: {
            CollectMoneyDetail: {
                deep: true,
                handler(newData) {

                    if (newData.IncludeVatYn === 'N') {

                        this.autoCalculateVat();
                    }
                }
            }
        },
        beforeMount() {

            // 수금종류 조회
            this.CollectDvsCodeList = $.scriptBase.getCommonCode('CODE000', '0715000', '', '');

            // 날짜 콤보박스 세팅
            this.TargetDate.push('월말');

            for (let i = 1; i < 32; i++) {

                let targetDate = (i < 10) ? ('0' + i).toString() : i.toString();
                this.TargetDate.push(targetDate);
            }
        },
        methods: {
            // 모달 오픈
            open(callbackFunction, selectedObj) {

                this.CallbackFunction = callbackFunction;

                this.CollectMoneyDetail = {
                    Arg: 'PI',
                    UserId: this.$store.getters.getEmpId,
                    CorpCd: this.$store.getters.getCompanyCode,               // 사용자 회사코드
                    CompanyCd: null,            // 회사 코드
                    CompanyNm: null,            // 회사 이름
                    ProjectCd: null,            // 업체 코드
                    ProjectNm: null,            // 업체 이름
                    SeqNo: -1,                // row 번호
                    CollectDvsCd: '',         // 수금종류 코드
                    CollectDvsName: null,       // 수금종류 이름
                    Price: 0,                // 계약 금액
                    IncludeVatYn: '',           // 부과세 포함, 미포함
                    VAT: 0,                  // 부과세
                    BillDueStartYMD: null,           // 발행예정일
                    BillDueEndYMD: null,           // 발행예정일
                    PayInDueStartYMD: null,          // 입금 예정일
                    PayInDueEndYMD: null,          // 입금 예정일
                    BillYMDUseYN: null,         // 세금 계산서 발행 여부
                    ContractCompanyCd: null,    // 거래처
                    ProjectPrice: 0,         // 계약 금액
                    ProjectLeftPrc: 0,       // 남은 금액
                    CollectMemo: '',            // 수금계획메모
                }

                // 로우 클릭, 상세 조회
                if (selectedObj) {

                    this.PageActionType = 'V';
                    this.BatchOrNot = 'single';
                    this.CollectMoneyDetail = Object.assign({}, selectedObj);
                    this.CollectMoneyDetail.Arg = 'PI';
                    this.CollectMoneyDetail.UserId = this.$store.getters.getEmpId;
                    // 추가 버튼, 신규 등록
                } else {

                    this.PageActionType = 'I';
                    this.BatchOrNot = 'single';
                    this.CollectMoneyDetail.Arg = 'PI';
                    this.CollectMoneyDetail.UserId = this.$store.getters.getEmpId;
                }

                $("#PM1021E_Modal").modal("show");
            },
            // 모달 닫기
            close() {

                this.CollectMoneyDetail = new Object();
                this.BatchCalculateTerm = {
                    BatchBillStart: null,
                    BatchBillEnd: null,
                    BatchPayStart: null,
                    BatchPayEnd: null,
                    BatchBillDay: '',
                    BatchPayDay: '',
                };
                this.TotPrice = 0;

                // readonly 속성 세팅
                txtVAT2.setAttribute('readonly', 'readonly');
                this.ModifiedVat = false;

                $("#PM1021E_Modal").modal("hide");
            },
            // VAT 포함 이벤트 핸들러
            selectBoxVatChangedEvent(event) {

                // readonly 속성 세팅
                txtVAT2.setAttribute('readonly', 'readonly');
                this.ModifiedVat = false;
                // 별도
                if (event.target.value === 'N') {

                    // 부가세 자동계산
                    this.autoCalculateVat();
                    this.CollectMoneyDetail.IncludeVatYn = 'N';
                }
                // 포함
                else if (event.target.value === 'Y') {

                    this.CollectMoneyDetail.VAT = '0';
                    this.CollectMoneyDetail.IncludeVatYn = 'Y';
                }
                // 영세율
                else if (event.target.value === 'Z') {

                    this.CollectMoneyDetail.VAT = '0';
                    this.CollectMoneyDetail.IncludeVatYn = 'Z';
                }
                // 면세
                else if (event.target.value === 'F') {

                    this.CollectMoneyDetail.VAT = '0';
                    this.CollectMoneyDetail.IncludeVatYn = 'F';
                }
            },
            // 부가세 readonly 속성 제거
            removeReadonly() {

                let readonly = txtVAT2.getAttribute('readonly');

                // readonly이고, 부가세 별도일 때, 세금계산서 발행완료 아닐 때
                if (readonly == 'readonly' && this.CollectMoneyDetail.IncludeVatYn == 'N' && this.CollectMoneyDetail.BillYMDUseYN != 'Y') {

                    if (confirm('부가세를 수정하시겠습니까?')) {

                        // 부가세 수정 가능
                        document.getElementById('txtVAT2').removeAttribute('readonly');
                        this.ModifiedVat = true;
                    }
                }
            },
            // 부가세 자동계산
            autoCalculateVat() {

                if (this.ModifiedVat == false) {

                    if (this.CollectMoneyDetail.Price != null && this.CollectMoneyDetail.Price != '') {

                        let vatCal = Number(this.CollectMoneyDetail.Price.toNumber()) * 0.1;
                        this.CollectMoneyDetail.VAT = Math.round(vatCal).toPriceNumber();
                    } else {

                        this.CollectMoneyDetail.VAT = null;
                    }
                }

            },
            // 프로젝트 검색 메서드
            searchProjectCode() {

                var parent = this;

                var successCallback = function (result) {

                    // 결과값을 추출 후 돔객체에 바인딩
                    parent.CollectMoneyDetail.ProjectNm = result.ProjectNm;
                    parent.CollectMoneyDetail.ProjectCd = result.ProjectCd;
                    parent.CollectMoneyDetail.CompanyNm = result.CompanyNm;
                    parent.CollectMoneyDetail.CompanyCd = result.CompanyCd;
                    parent.CollectMoneyDetail.ManagerNm = result.ManagerNm;

                    // 프로젝트 계약금액
                    parent.CollectMoneyDetail.ProjectPrice = result.Price;
                    parent.CollectMoneyDetail.IncludeVatYn = '';

                    // 계약 금액 변수 선언
                    var resultPrice = result.Price;
                    var Price = resultPrice;

                    // 계약금액 데이터 가공
                    Price = (resultPrice != '0') ? resultPrice.replace(/,/gi, "") : '0';

                    // TotPrice 조회에 필요한 객체
                    let TotPrjPrice = {
                        Arg: 'CP',
                        UserId: this.$store.getters.getEmpId,
                        CorpCd: result.CorpCd,
                        CompanyCode: result.CompanyCd,
                        ProjectCode: result.ProjectCd,
                        StartDate: this.moment(new Date()).add(-1, 'month').format('YYYY-MM-DD'),
                        EndDate: this.moment(new Date()).format('YYYY-MM-DD'),
                    }

                    // TotPrice 값 조회 메서드
                    parent.showTotPrjPrice(TotPrjPrice);

                    // 남은금액 값 바인딩
                    parent.CollectMoneyDetail.ProjectLeftPrc = (Number(Price) - Number(parent.TotPrice));
                    parent.CollectMoneyDetail.ProjectLeftPrc = parent.CollectMoneyDetail.ProjectLeftPrc.toString().toPriceString();
                }

                this.$refs.CP1060P.showProjectPopup(successCallback);
            },
            // TotPrice 조회 메서드
            showTotPrjPrice(TotPrjPrice) {

                var parent = this;

                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/GetCollectMoney'.toRealServiceUrl();
                var obj = new Object();
                obj.jsonString = JSON.stringify(TotPrjPrice);

                // ajax 성공 시 실행되는 핸들러 정의
                var ajaxSuccessHandler = function (data) {

                    var table = JSON.parse(data.d).Table;
                    parent.TotPrice = (table.length > 0) ? table[0].TotPrice : 0;
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler);
            },
            // 저장 메서드
            saveCollectMoney() {

                // 저장 유효성 검사
                if (this.CollectMoneyDetail.ProjectNm === '' || this.CollectMoneyDetail.ProjectNm === null) {

                    alert("프로젝트명을 입력하세요.");
                    $("#txtProjectNm2").focus();
                    return;
                }
                if (this.CollectMoneyDetail.CompanyNm === '' || this.CollectMoneyDetail.CompanyNm === null) {

                    alert("거래처를 입력하세요.");
                    $("#txtCompanyNm2").focus();
                    return;
                }
                if (this.CollectMoneyDetail.CollectDvsCd === '' || this.CollectMoneyDetail.CollectDvsCd === null) {

                    alert("수금종류를 입력하세요.");
                    $("#selCollectDvsCd2").focus();
                    return;
                }
                if (this.CollectMoneyDetail.Price.length > 0 && Number(this.CollectMoneyDetail.Price.replace(/,/g, ""), 10) > 2147483647) {

                    alert("금액은 2,147,483,647을 넘을 수 없습니다.");
                    $("#txtPrice2").focus();
                    return;
                }
                if (this.CollectMoneyDetail.VAT.length > 0 && Number(this.CollectMoneyDetail.VAT.replace(/,/g, ""), 10) > 2147483647) {

                    alert("부가세는 2,147,483,647을 넘을 수 없습니다.");
                    $("#txtVAT2").focus();
                    return;
                }
                if (this.ModifiedVat == true) {

                    let vatCal = Number(this.CollectMoneyDetail.Price.toNumber()) * 0.1;
                    // 오차범위 = 절대값(수정한 부가세 - Price * 0.1)
                    let marginOfError = Math.abs(Number(this.CollectMoneyDetail.VAT.toNumber()) - vatCal);

                    // 오차가 100원 이상일 때
                    if (marginOfError > 100) {

                        alert("부가세액을 다시 확인해 주십시오.");
                        $('#txtVAT2').focus();
                        return;
                    }
                }

                // 단건 입력 시
                if (this.BatchOrNot == 'single') {

                    if (this.CollectMoneyDetail.BillDueStartYMD === '' || this.CollectMoneyDetail.BillDueStartYMD === null || this.CollectMoneyDetail.BillDueStartYMD === undefined) {

                        alert("발행예정일을 입력하세요.");
                        return;
                    } else if (this.CollectMoneyDetail.PayInDueStartYMD === '' || this.CollectMoneyDetail.PayInDueStartYMD === null || this.CollectMoneyDetail.PayInDueStartYMD === undefined) {

                        alert("입금예정일을 입력하세요.");
                        return;
                    }
                    // 시작, 종료일 동일한 값으로 넘기기
                    this.CollectMoneyDetail.BillDueStartYMD = this.moment(this.CollectMoneyDetail.BillDueStartYMD).format('YYYYMMDD');
                    this.CollectMoneyDetail.BillDueEndYMD = this.CollectMoneyDetail.BillDueStartYMD;
                    this.CollectMoneyDetail.PayInDueStartYMD = this.moment(this.CollectMoneyDetail.PayInDueStartYMD).format('YYYYMMDD');
                    this.CollectMoneyDetail.PayInDueEndYMD = this.CollectMoneyDetail.PayInDueStartYMD;
                }
                // 일괄 입력 시
                if (this.BatchOrNot == 'batch') {

                    // 종료일 입력 여부 확인
                    if (this.BatchCalculateTerm.BatchBillStart === '' || this.BatchCalculateTerm.BatchBillStart === null || this.BatchCalculateTerm.BatchBillEnd === '' || this.BatchCalculateTerm.BatchBillEnd === null || this.BatchCalculateTerm.BatchBillDay === '' || this.BatchCalculateTerm.BatchBillDay === null) {

                        alert("발행예정일을 입력하세요.");
                        return;
                    } else if (this.BatchCalculateTerm.BatchPayStart === '' || this.BatchCalculateTerm.BatchPayStart === null || this.BatchCalculateTerm.BatchPayEnd === '' || this.BatchCalculateTerm.BatchPayEnd === null || this.BatchCalculateTerm.BatchPayDay === '' || this.BatchCalculateTerm.BatchPayDay === null) {

                        alert("입금예정일을 입력하세요.");
                        return;
                    }

                    let year1 = this.moment(this.BatchCalculateTerm.BatchBillStart).format('YYYY');
                    let month1 = this.moment(this.BatchCalculateTerm.BatchBillStart).format('YYYYMM').substr(4, 2);
                    let year2 = this.moment(this.BatchCalculateTerm.BatchBillEnd).format('YYYY');
                    let month2 = this.moment(this.BatchCalculateTerm.BatchBillEnd).format('YYYYMM').substr(4, 2);

                    let year3 = this.moment(this.BatchCalculateTerm.BatchPayStart).format('YYYY');
                    let month3 = this.moment(this.BatchCalculateTerm.BatchPayStart).format('YYYYMM').substr(4, 2);
                    let year4 = this.moment(this.BatchCalculateTerm.BatchPayEnd).format('YYYY');
                    let month4 = this.moment(this.BatchCalculateTerm.BatchPayEnd).format('YYYYMM').substr(4, 2);

                    // 발행예정일 날짜 세팅
                    if (this.BatchCalculateTerm.BatchBillDay == '월말') {

                        this.CollectMoneyDetail.BillDueStartYMD = this.moment(new Date(year1, month1, 0)).format('YYYYMMDD');
                        this.CollectMoneyDetail.BillDueEndYMD = this.moment(this.BatchCalculateTerm.BatchBillEnd).format('YYYYMM') + this.moment(this.CollectMoneyDetail.BillDueStartYMD).format('YYYYMMDD').substr(6, 2);
                    } else {

                        this.CollectMoneyDetail.BillDueStartYMD = this.moment(this.BatchCalculateTerm.BatchBillStart).format('YYYYMM') + this.BatchCalculateTerm.BatchBillDay;
                        this.CollectMoneyDetail.BillDueEndYMD = this.moment(this.BatchCalculateTerm.BatchBillEnd).format('YYYYMM') + this.BatchCalculateTerm.BatchBillDay;
                    }

                    // 입금예정일 날짜 세팅
                    if (this.BatchCalculateTerm.BatchPayDay == '월말') {

                        this.CollectMoneyDetail.PayInDueStartYMD = this.moment(new Date(year3, month3, 0)).format('YYYYMMDD');
                        this.CollectMoneyDetail.PayInDueEndYMD = this.moment(this.BatchCalculateTerm.BatchPayEnd).format('YYYYMM') + this.moment(this.CollectMoneyDetail.PayInDueStartYMD).format('YYYYMMDD').substr(6, 2);
                    } else {

                        this.CollectMoneyDetail.PayInDueStartYMD = this.moment(this.BatchCalculateTerm.BatchPayStart).format('YYYYMM') + this.BatchCalculateTerm.BatchPayDay;
                        this.CollectMoneyDetail.PayInDueEndYMD = this.moment(this.BatchCalculateTerm.BatchPayEnd).format('YYYYMM') + this.BatchCalculateTerm.BatchPayDay;
                    }

                    if (this.CollectMoneyDetail.BillDueStartYMD > this.CollectMoneyDetail.BillDueEndYMD) {

                        alert("발행예정일의 시작월은 종료월보다 클 수 없습니다.");
                        return;
                    } else if (this.CollectMoneyDetail.PayInDueStartYMD > this.CollectMoneyDetail.PayInDueEndYMD) {

                        alert("입금예정일의 시작월은 종료월보다 클 수 없습니다.");
                        return;
                    }

                    // 발행예정일, 입금예정일 개월수 계산
                    let billMonthCnt = month2 - month1 + 1 + 12 * (year2 - year1);
                    let payMonthCnt = month4 - month3 + 1 + 12 * (year4 - year3);

                    if (billMonthCnt != payMonthCnt) {

                        alert("발행예정일과 입금예정일의 개월수를 동일하게 입력해주세요.");
                        return;
                    }
                }

                var parent = this;

                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/SaveCollectMoney'.toRealServiceUrl();
                var obj = new Object();
                this.CollectMoneyDetail.BillNm = this.CollectMoneyDetail.CompanyNm + '_' + this.CollectMoneyDetail.ProjectNm + '_' + this.CollectMoneyDetail.ManagerNm + '_' + this.CollectMoneyDetail.CollectDvsName + '_';
                this.CollectMoneyDetail.ContractCompanyCd = this.CollectMoneyDetail.CompanyCd;
                obj.jsonString = JSON.stringify(this.CollectMoneyDetail);

                var successCallback = function (data) {

                    if (data.d > 0) {

                        if (parent.PageActionType == 'I') {

                            alert('등록되었습니다.');
                            parent.close();
                            // 등록 후 그리드 재조회
                            parent.$parent.showCollectMoney();
                        } else {

                            alert('저장되었습니다.');
                            parent.close();
                            // 등록 후 그리드 재조회
                            parent.$parent.showCollectMoney();
                        }
                    } else {

                        alert('저장에 실패했습니다. 잠시 후 다시 시도해 주세요.');
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 수금계획 삭제
            removeCollectMoney() {

                if (this.CollectMoneyDetail == null || this.PageActionType === "I") {

                    alert("삭제할 정보가 존재하지 않습니다.");
                    return;
                }

                if (confirm("삭제하시겠습니까?")) {

                    var parent = this;

                    var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/RemoveCollectMoney'.toRealServiceUrl();
                    var obj = new Object();

                    // 삭제 시 필요한 객체 추가
                    this.CollectMoneyDetail.Arg = 'PD';
                    this.CollectMoneyDetail.UserId = this.$parent.$parent.EmpId;
                    this.CollectMoneyDetail.CompanyCode = this.$parent.CompanyCode;

                    obj.jsonString = JSON.stringify(this.CollectMoneyDetail);

                    // 서비스호출 성공 콜백 함수
                    var successCallback = function (data) {

                        if (data.d > 0) {

                            alert('삭제되었습니다.');
                            parent.close();
                            parent.$parent.showCollectMoney();
                        } else {

                            alert('삭제에 실패했습니다. 잠시 후 다시 시도해 주세요.');
                        }
                    }

                    $.scriptBase.executeAjaxAsync(url, obj, successCallback);
                }
            },
        }
    }
</script>