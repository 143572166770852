<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <article class="view-btns-wrap ">
                    <div class="btn-group-justified">
                        <a href="#" id="btnListSrch" class="btn btn-primary btn-row2" @click.prevent="btnSearchClicked">
                            <span>
                                <i class="glyphicon glyphicon-search"></i> 조회
                            </span>
                        </a>
                        <button type="button" id="btnExcelExport" class="btn btn-default" hidden="hidden" @click.prevent="excelExport">
                            <span>
                                <i class="glyphicon glyphicon-edit"></i> 엑셀출력
                            </span>
                        </button>
                    </div>
                </article>
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtCompanyNm" class="control-label">
                                            <span>사용년월</span>
                                        </label>
                                        <div class="cols col-2">
                                            <div class="col-xs-12">
                                                <div class="col-2 input-group date form_date " v-bind:data-date="CurrentDateYYMMDD" data-date-format="yyyy-mm">
                                                    <input type="text" id="txtYYMM" class="form-control" maxlength="10"
                                                           v-bind:value="YYMM"
                                                           @input="function(e){
                                                            YYMM = e.target.value.toHyphenationFormatYMD()
                                                            e.target.value = e.target.value.toHyphenationFormatYMD()
                                                           }" />
                                                    <span class="input-group-addon">
                                                        <span class="glyphicon glyphicon-calendar"></span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </section>

                        </fieldset>
                    </form>
                </div>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">법인카드 통계 레포트</p>
                </div>
                <div>
                    <!--리스트 영역-->
                    <div class="col-sm-6">
                        <section class="tbl-contents">
                            <!--좌측 상단 테이블-->
                            <table class="table table tbl-list tbl-center table-hover table-bordered" id="tb0">
                                <caption>테이블</caption>
                                <colgroup>
                                    <col class="chk" />
                                    <col class="table-colwid15" />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col">순번</th>
                                        <th scope="col">카드번호</th>
                                        <th scope="col">카드사</th>
                                        <th scope="col">소유자</th>
                                        <th scope="col">승인금액(관리용)</th>
                                        <th scope="col">승인금액(보고용)</th>
                                        <th scope="col">주요 사용처</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="CardUsedList === null">
                                        <td colspan="7" class="text-center no-data">조회된 정보가 없습니다.</td>
                                    </tr>
                                    <tr v-else v-for="item in CardUsedList" style="cursor:pointer;" v-on:click="rowClickEvent(item)">
                                        <td style="text-align:center">{{item.OrderSeq}}</td>
                                        <td style="text-align:center">{{item.CardNo}}</td>
                                        <td style="text-align:center;">{{item.CardName}}</td>
                                        <td style="text-align:center;">{{item.UsedName}}</td>
                                        <td style="text-align:right;">{{item.Amt}}</td>
                                        <td style="text-align:right;">{{item.Amt1}}</td>
                                        <td style="text-align:left;">{{item.Usedtitle}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <!--좌측 하단 테이블-->
                            <table class="table tbl-list table-hover table-bordered" id="tb2">
                                <colgroup>
                                    <col class="table-colwid10" />
                                    <col class="table-colwid10" />
                                    <col class="table-colwid10" />
                                    <col class="table-colwid10" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col" class="text-center">순번</th>
                                        <th scope="col" class="text-center">카드사</th>
                                        <th scope="col" class="text-center">승인금액(관리용)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="CardApprovedList === null">
                                        <td colspan="3" class="text-center no-data">조회된 정보가 없습니다.</td>
                                    </tr>
                                    <tr v-else v-for="item in CardApprovedList" style="cursor:pointer;">
                                        <td style="text-align:center">{{item.OrderSeq}}</td>
                                        <td style="text-align:center;">{{item.CardName}}</td>
                                        <td style="text-align:right;">{{item.Amt}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </section>
                    </div>
                    <!--리스트(right) 영역-->
                    <div class="col-sm-6">
                        <section class="tbl-contents">
                            <!--우측 테이블-->
                            <table class="table  table-hover tbl-list table-bordered" id="tb1">
                                <colgroup>
                                    <col class="table-colwid10" />
                                    <col class="table-colwid10" />
                                    <col class="table-colwid10" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col" class="text-center">계정과목</th>
                                        <th scope="col" class="text-center">계정별 사용금액(관리용)</th>
                                        <th scope="col" class="text-center">계정별 사용금액(보고용)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="CardExpenditurelist === null">
                                        <td colspan="3" class="text-center no-data">조회된 정보가 없습니다.</td>
                                    </tr>
                                    <tr v-else v-for="item in CardExpenditurelist" style="cursor:pointer;">
                                        <td style="text-align:left">{{item.AcntName}}</td>
                                        <td style="text-align:right;">{{item.Amt}}</td>
                                        <td style="text-align:right;">{{item.Amt1}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </section>
                    </div>
                </div>
            </div>
        </div>
        <!--카드 상세 내역 모달-->
        <div class="modal fade sch-modal" id="RT1040R_Modal" ref="RT1040R_Modal" tabindex="-1" role="dialog" aria-labelledby="RT1040R_Modal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">
                            <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                        </button>
                        <h3 class="modal-title">카드상세내역</h3>
                    </div>
                    <div class="modal-body">
                        <!-- 검색영역 -->
                        <section class="sch-wrap">
                            <form role="form">
                                <fieldset>
                                    <legend>검색폼</legend>
                                    <section class="sch-btn">
                                        <button type="button" id="btnHlidayExlExpt" class="btn btn-default" @click.prevent="detailExcelExport">
                                            <span>
                                                <i class="glyphicon glyphicon-search"></i> Excel Export
                                            </span>
                                        </button>
                                    </section>
                                </fieldset>
                            </form>
                        </section>
                        <!-- //검색영역 -->
                        <section class="tbl-contents">
                            <table class="table  table-hover tbl-list table-bordered" id="tbDetail">
                                <colgroup>
                                    <col class="table-colwid10" />
                                    <col class="table-colwid10" />
                                    <col class="table-colwid10" />
                                    <col class="table-colwid10" />
                                    <col class="table-colwid10" />
                                    <col class="table-colwid10" />
                                    <col class="table-colwid10" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col">소유자</th>
                                        <th scope="col">사용자</th>
                                        <th scope="col">카드번호</th>
                                        <th scope="col">사용일자</th>
                                        <th scope="col">금액</th>
                                        <th scope="col">사용구분</th>
                                        <th scope="col">계정</th>
                                        <th scope="col">사유</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="CardUsedDetail === null">
                                        <td colspan="3" class="text-center no-data">조회된 정보가 없습니다.</td>
                                    </tr>
                                    <tr v-else v-for="item in CardUsedDetail" style="cursor:pointer;">
                                        <td>{{item.UsedEmpNm}}</td>
                                        <td>{{item.EmpNm}}</td>
                                        <td>{{item.DisplayCardNo}}</td>
                                        <td class="text-center">{{item.UseYMD}}</td>
                                        <td class="text-right">{{item.Price}}</td>
                                        <td class="text-center">{{item.UseTypeName}}</td>
                                        <td class="text-center">{{item.AcntNm}}</td>
                                        <td class="text-center">{{item.UseTxt}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle'
    export default {
        name: 'RT1040R',
        components: {
            ViewTitle
        },
        data() {
            return {
                $ds0: null,                 // 엑셀 출력에 필요한 객체
                $selectedName: null,        // 엑셀 출력에 필요한 객체
                CardUsedList: null,         // 카드 사용자 목록(좌측 상단 테이블)
                CardApprovedList: null,     // 카드 승인 목록(좌측 하단 테이블)
                CardExpenditurelist: null,  // 지출목록(우측 테이블)
                CardUsedDetail: null,       // 카드 상세 내역
                YYMM: this.moment(new Date()).format('YYYY-MM'),             // 사용 년월
                CurrentDateYYMMDD: this.moment(new Date()).format('YYYY-MM') // 날짜 포멧
            }
        },
        beforeMount() {
        },
        mounted() {

            var parent = this

            $('.form_date').off('changeDate')
            $('.form_date').datetimepicker({
                weekStart: 1,
                todayBtn: 1,
                autoclose: 1,
                todayHighlight: 1,
                startView: 2,
                minView: 2,
                forceParse: 0,
                keyboardNavigation: true,
                language: 'kr',
            }).on('changeDate', function (e) {

                var targetInputElement = e.target.querySelector('input')
                var selectDate = parent.moment(e.date).format('YYYY-MM')

                switch (targetInputElement.id) {
                    case 'txtYYMM': {

                        parent.YYMM = selectDate

                        break
                    }
                    default:
                }
            })

            if (this.$parent.CanSearch) {

                this.showData()
            }
        },
        methods: {
            // 조회 버튼 클릭 이벤트 핸들러
            btnSearchClicked: function () {

                this.showData()
            },
            // 법인카드 통계 레포트
            showData: function () {
                var parent = this

                var url = '/Mini.Service.IBS.RT.Service/AccountingReport.svc/ajax/GetCardUsedList'
                url = url.toRealServiceUrl()
                var obj = new Object()

                obj.args = 'L'
                obj.usedYYMM = this.YYMM

                // ajax 성공 시 실행되는 핸들러 정의
                var ajaxSuccessHandler = function (data) {

                    var table = JSON.parse(data.d).Table
                    var table2 = JSON.parse(data.d).Table2
                    var table3 = JSON.parse(data.d).Table3

                    if (table.length > 0) {

                        // 좌측 상단 그리드 금액 콤마 정규식 적용
                        table.forEach(function (item) {

                            item.Amt = item.Amt.toPriceNumber()
                            item.Amt1 = item.Amt1.toPriceNumber()
                        })
                        // 우측 그리드 금액 콤마 정규식 적용
                        table2.forEach(function (item) {

                            item.Amt = item.Amt.toPriceNumber()
                            item.Amt1 = item.Amt1.toPriceNumber()
                        })
                        // 좌측 하단 그리드 금액 콤마 정규식 적용
                        table3.forEach(function (item) {

                            item.Amt = item.Amt.toPriceNumber()
                        })

                        parent.CardUsedList = table
                        parent.CardApprovedList = table3
                        parent.CardExpenditurelist = table2
                    }
                    else {

                        parent.CardUsedList = null
                        parent.CardApprovedList = null
                        parent.CardExpenditurelist = null
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
            },
            // 좌측 상단 테이블Row 클릭 이벤트 핸들러
            rowClickEvent: function (row) {

                // 선택된 Row 카드번호
                var realCardNo = row.RealCardNo1
                // 조회된 년월
                var date = this.YYMM

                this.showDetailData(realCardNo, date)

            },
            // 카드 상세 내역 조회
            showDetailData: function (realCardNo, date) {

                var parent = this

                var url = '/Mini.Service.IBS.RT.Service/AccountingReport.svc/ajax/GetCardUsedDetail'
                url = url.toRealServiceUrl()
                var obj = new Object()

                obj.cardNo = realCardNo
                obj.currentDate = date

                // ajax 성공 시 실행되는 핸들러 정의
                var ajaxSuccessHandler = function (data) {

                    var table = JSON.parse(data.d).Table

                    if (table.length > 0) {

                        parent.CardUsedDetail = table
                    }
                    else {

                        parent.CardUsedDetail = null
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)

                $(this.$refs.RT1040R_Modal).modal('show')
            },
            // 엑셀 출력 버튼 클릭 이벤트 핸들러
            excelExport: function () {

                alert('RT1040R 엑셀 기능 구현 필요')
                //if (typeof $ds0 != "undefined") {
                //    var t1, t2, t3;

                //    var t1obj = Array();
                //    t1obj.push("");
                //    t1obj.push("");
                //    t1 = $ds0.Table.concat(t1obj);

                //    var t2obj = Array();
                //    t2obj.push("");
                //    t2obj.push("");
                //    var test1 = { CardName: '계정과목', AcntName1: '계정과목', Amt: '계정별 사용금액(관리용)', Amt1: '계정별 사용금액(보고용)' };
                //    var objs1 = Array();
                //    objs1.push(test1);
                //    t2 = objs1.concat($ds0.Table2).concat(t2obj);

                //    var test2 = { CardName: '카드사', AcntName1: '계정과목', Amt: '승인금액(관리용)', Amt1: '승인금액(보고용)' };
                //    var objs2 = Array();
                //    objs2.push(test2);

                //    var t3obj = Array();
                //    t3obj.push("");
                //    t3obj.push("");
                //    t3 = objs2.concat($ds0.Table3).concat(t3obj);

                //    var tot = t1.concat(t2).concat(t3);

                //    var mappingData = {
                //        CardNo: "카드번호",
                //        CardName: "카드사",
                //        Amt: "승인금액(관리용)",
                //        Amt1: "승인금액(보고용)",
                //        UsedName: "카드소유자",
                //        AcntName: "계정과목",
                //        Amt: "계정별 사용금액(관리용)",
                //        Amt1: "계정별 사용금액(보고용)",
                //        CardName: "카드사",
                //        Amt: "승인금액(관리용)",
                //        Amt1: "승인금액(보고용)"
                //    }

                //    var title = $("#txtYYMM").val().replace(/-/g, '').substring(0, 6);

                //    $.fn_ExcelExportUseMaping(tot, title + " 법인카드사용내역통계", mappingData);

                //} else {
                //    alert("조회된 데이터가 없습니다.");
                //}
            },
            // 그리드 상세 엑셀 버튼 클릭 이벤트 핸들러
            detailExcelExport: function myfunction() {

                alert('RT1040R 상세 엑셀 기능 구현 필요')
            }
        }
    }
</script>