<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a v-show="this.$parent.CanSearch" href="#" id="btnListSrch" class="btn btn-primary btn-row2" @click.prevent="getCompany()">
                    <span>
                        <i class="glyphicon glyphicon-search"></i> 조회
                    </span>
                </a>
                <a v-show="this.$parent.CanNew" href="#" id="btnListInst" class="btn btn-default" @click.prevent="addCompany">
                    <span>
                        <i class="glyphicon glyphicon-plus"></i> 추가
                    </span>
                </a>

            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols">
                                        <label for="txtTraderNm" class="control-label">
                                            <span>업체명</span>
                                        </label>
                                        <input type="text" id="txtTraderNm" class="form-control txt"
                                               v-model="CompanySelectTerm.CompanyNm"
                                               @keyup="function(e){
                                                    if(e.which === 13) getCompany()
                                               }" />
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="selCorpType" class="control-label">
                                            <span>업체구분</span>
                                        </label>
                                        <select class="form-control" id="selCorpType" name="selCorpType" v-model="CompanySelectTerm.CompanyTypeCode">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in CompanyTypeList" v-bind:value="item.Code">{{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="sch3-1" class="control-label">
                                            <span>사용여부</span>
                                        </label>
                                        <select id="selUseYN" class="form-control" v-model="CompanySelectTerm.UseYn">
                                            <option value="">== 전체 ==</option>
                                            <option value="Y">Y</option>
                                            <option value="N">N</option>
                                        </select>
                                    </div>
                                </section>
                            </section>
                        </fieldset>
                    </form>
                </div>

            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">업체 관리</p>
                </div>
                <div>
                    <!--리스트 영역-->
                    <div class="alert alert-info total">
                        <p>
                            <i class="glyphicon glyphicon-folder-close"></i>
                            <span>
                                전체: <span id="spTotCnt">{{TotalCount}}</span>건
                            </span>
                        </p>
                    </div>
                    <div class="table-responsive">
                        <DxDataGrid id="grid-container"
                                    :show-borders="false"
                                    :show-row-lines="true"
                                    :data-source="CompanyList"
                                    :hoverStateEnabled="true"
                                    @cell-prepared="onCellPrepared"
                                    @row-click="rowClickEvent">
                            <DxLoadPanel :enabled="false" />
                            <DxScrolling row-rendering-mode="virtual" />
                            <DxPaging :page-size="10" />
                            <DxSorting mode="none" />
                            <DxColumn data-field="CompanyNm"
                                      caption="업체"
                                      width="20%" />
                            <DxColumn data-field="CorpTypeName"
                                      caption="업체구분"
                                      alignment="center"
                                      width="10%" />
                            <DxColumn data-field="CorpRegNo"
                                      caption="사업자번호"
                                      alignment="center"
                                      width="10%" />
                            <DxColumn data-field="TelNo"
                                      caption="전화번호"
                                      alignment="center"
                                      width="10%" />
                            <DxColumn data-field="BankName"
                                      caption="입금은행"
                                      alignment="center"
                                      width="10%" />
                            <DxColumn data-field="BankAcntNo"
                                      caption="입금계좌"
                                      alignment="center"
                                      width="15%" />
                            <DxColumn cell-template="homeUrl"
                                      data-field="HomeURL"
                                      caption="홈페이지 URL"
                                      width="20%" />
                            <template #homeUrl="{data}">
                                <a v-bind:href="data.value" target="_blank">{{data.value}}</a>
                            </template>
                            <DxColumn data-field="UseYN"
                                      caption="사용여부"
                                      alignment="center"
                                      width="5%" />
                        </DxDataGrid>
                    </div>
                </div>
            </div>
            <CM1021E ref="CM1021E"></CM1021E>
        </div>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle';
    import CM1021E from '@/components/cm/popup/CM1021E';
    import { DxDataGrid, DxColumn, DxMasterDetail, DxScrolling, DxSorting, DxLoadPanel, DxPaging } from 'devextreme-vue/data-grid';
    export default {
        name: 'CM1020R',
        components: {
            ViewTitle, CM1021E, DxDataGrid, DxColumn, DxMasterDetail, DxScrolling, DxSorting, DxLoadPanel, DxPaging
        },
        data() {
            return {
                // 업체구분 공통코드 목록
                CompanyTypeList: new Array(),
                // 업체 목록
                CompanyList: new Array(),
                // 업체 목록 조회조건 객체
                CompanySelectTerm: {
                    Arg: 'L',
                    UserId: this.$parent.EmpId,
                    CorpCd: this.$parent.CompanyCode,
                    CompanyCd: '',
                    CompanyNm: '',
                    CompanyTypeCode: '',
                    UseYn: '',
                },
                // 업체 목록 총 개수
                TotalCount: 0,
            }
        },
        beforeMount() {

            // 공통코드 조회
            this.CompanyTypeList = $.scriptBase.getCommonCode('CODE000', '0107000', '', '');
        },
        mounted() {

            if (this.$parent.CanSearch) {

                this.getCompany();
            }
        },
        methods: {
            // datagrid header 가운데 정렬, column 중앙 정렬
            onCellPrepared(e) {

                if (e.rowType == 'header') {

                    e.cellElement.style['textAlign'] = 'center';
                }
                if (e.rowType == 'data') {

                    e.cellElement.style['verticalAlign'] = 'middle';
                }
            },
            // 업체관리 조회
            getCompany() {

                var parent = this;
                var url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/GetCompany'.toRealServiceUrl();
                var obj = new Object();

                obj.jsonString = JSON.stringify(this.CompanySelectTerm);

                var successCallback = function (data) {

                    var jsonData = JSON.parse(data.d);
                    var table = jsonData.Table;
                    var table1 = jsonData.Table1;

                    parent.CompanyList = (table.length > 0) ? table : new Array();
                    parent.TotalCount = (table1.length > 0) ? table1[0].TotCnt : 0;
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 업체관리 목록 클릭 이벤트
            rowClickEvent(e) {

                var parent = this;
                var row = e.data;

                var successCallback = function () {

                    parent.getCompany();
                }

                this.$refs.CM1021E.open(successCallback, row);
            },
            // 업체관리 신규등록
            addCompany() {

                let parent = this;

                var successCallback = function () {

                    parent.getCompany();
                }

                this.$refs.CM1021E.open(successCallback);
            },
        }
    }
</script>