<template>
	<!--디테일 그리드 영역-->
	<div>
		<DxDataGrid :data-source="BusinessHistoryListAll"
					:show-borders="true"
					:show-row-lines="true"
					@cell-prepared="onCellPrepared"
					@cell-click="onCellClicked">
			<DxColumn data-field="HistoryNm"
					  caption="영업이력명" 
					  width="10%"/>
			<DxColumn data-field="BizDate"
					  caption="영업일" 
					  alignment="center" 
					  width="10%"/>
			<DxColumn data-field="HistoryDesc"
					  caption="설명"
					  cell-template="historyDesc" 
					  width="70%"/>
			<template #historyDesc="{ data }">
				<span style="white-space: normal; overflow: hidden; display:-webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; word-break:break-word;" v-html="data.value.replace(/(?:\r\n|\r|\n)/g, '<br />')"></span>
			</template>
			<DxColumn data-field="EmpNm"
					  caption="작성자" 
					  alignment="center" 
					  width="10%"/>
		</DxDataGrid>

		<BZ1003E ref="BZ1003E"></BZ1003E>
	</div>
</template>
<script>

	import { DxDataGrid, DxColumn } from 'devextreme-vue/data-grid'
	import BZ1003E from '@/components/bz/popup/BZ1003E'

	export default {
		components: {
			DxDataGrid
			, DxColumn
			, BZ1003E
		},
		props: {
			templateData: {
				type: Object,
				default: () => { }
			}
		},
		data() {
			return {
				BusinessHistoryListAll: new Array()
			};
		},
		created() {
			// 초기조회
			this.getBusinessDetail()
		},
		methods: {
			//#region init
			//#endregion

			//#region event
            onCellPrepared(e) { // datagrid header 가운데 정렬, column 중앙 정렬
                if (e.rowType == 'header') {
                    e.cellElement.style['textAlign'] = 'center'
                }
                if (e.rowType == 'data') {
                    e.cellElement.style['verticalAlign'] = 'middle'
                }
            }
			//#endregion

			//#region button click
            , onCellClicked(e) { // cell 클릭 이벤트

                this.PageActionType = 'V' // 화면 액션값 설정 (I:신규, V: 상세보기)

                var fileObj = this.getBusinessHistoryFileList(e.data.BizNo, e.data.SeqNo, e.data.CompanyCd) // 업체 상세 이력 조회 메서드

                var callbackFunction = function (result) {

                    parent.getBusiness()
                }

                this.$refs.BZ1003E.open(callbackFunction, null, e.data, fileObj) // 업체 영업이력 팝업 오픈
            }
			//#endregion

			//#region service
            , getBusinessHistoryFileList: function (bizNo, seqNo, companyCode) { // 업체 영업이력 파일 목록

                // 파일 목록 초기화
                var businessHistoryFileList = new Array()

                this.BusinessHistoryFileListAll.forEach(function (item) {

                    if (bizNo === item.BizNo && seqNo === item.SeqNo && companyCode === item.CompanyCd) {

                        item.IsNew = false
                        item.IsRemove = false
                        item.IsShow = true
                        item.IsChecked = false
                        item.Files = null
                        businessHistoryFileList.push(item)
                    }
                })

                return businessHistoryFileList
			}
            // 해당 영업 디테일 목록 조회
            , getBusinessDetail: function () {

                var parent = this

                var BusinessSelectTerm = {
                    Arg: 'L',
                    CorpCd: this.templateData.data.CorpCd,
                    BusinessNo: this.templateData.data.BizNo,
                    CompanyCode: this.templateData.data.CompanyCd,  // 업체 코드
                    SrchText: this.templateData.data.SrchText,		// 영업명
                    DvsCd: this.templateData.data.DvsCd,			// 진행상황
                    TargetYear: this.templateData.data.TargetYear	// 년도
                }

                var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/GetBusinessHistoryDetail'
                url = url.toRealServiceUrl()
                var obj = new Object()

                obj.jsonString = JSON.stringify(BusinessSelectTerm)

                var ajaxSuccessHandler = function (data) {
                    var jsonObj = JSON.parse(data.d)

                    // 확장여부 값 추가
                    jsonObj.Table.forEach(function (item) {

                        item.IsExpand = false
                    })

                    parent.BusinessHistoryListAll = jsonObj.Table

                    parent.BusinessHistoryFileListAll = jsonObj.Table1
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
            }
			//#endregion
		}
	};
</script>
<style>
	.master-detail-caption {
		padding: 0 0 5px 10px;
		font-size: 14px;
		font-weight: bold;
	}
</style>
