<template>
    <!--일괄입력 모달 팝업-->
    <div class="modal fade" id="PM1042E_Modal" ref="PM1042E_Modal" tabindex="-1" role="dialog" aria-labelledby="PM1042E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" @click.prevent="close">
                        <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                    </button>
                    <h3 class="modal-title">대금지급 일괄입력</h3>
                </div>
                <div class="modal-body">
                    <section class="add-wrap">
                        <form>
                            <fieldset>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtProjectNm" class="control-label">
                                            <span>프로젝트명</span>
                                        </label>
                                        <div class="cols-btns-cont">
                                            <input type="text" id="txtProjectNm" class="form-control txt" readonly="readonly" v-model="ProductPaymentInsertTerm.ProjectNm" />
                                        </div>
                                        <div class="cols-btns">
                                            <button type="button" class="btn btn-default" v-bind:disabled="true">
                                                <i class="glyphicon glyphicon-search"></i>검색
                                            </button>
                                        </div>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtCompanyNm" class="control-label">
                                            <span>거래처명</span>
                                        </label>
                                        <input type="text" id="txtCompanyNm" class="form-control txt" readonly="readonly" v-model="ProductPaymentInsertTerm.CompanyNm" />
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtProductNm" class="control-label">
                                            <span>품목</span>
                                        </label>
                                        <input type="text" id="txtProductNm" class="form-control txt" readonly="readonly" v-model="ProductPaymentInsertTerm.ProductNm" />
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtProductLeftPrice" class="control-label">
                                            <span>남은 매입금액</span>
                                        </label>
                                        <input type="text" id="txtProductLeftPrice" class="form-control txt-right" readonly="readonly" v-model="ProductPaymentInsertTerm.LeftPrice" />
                                    </div>
                                </section>
                                <!--빈공백-->
                                <section class="cols-wrap">
                                    <div class="cols">
                                        <label></label>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="selPaymentCd" class="control-label">
                                            <span><i class="glyphicon glyphicon-asterisk"></i>대금지급구분</span>
                                        </label>
                                        <select class="form-control" id="selPaymentCd" v-model="ProductPaymentInsertTerm.PaymentCode">
                                            <option value="">== 선택 ==</option>
                                            <option v-for="item in PaymentCodeList" v-bind:value="item.Code">{{ item.Name }}</option>
                                        </select>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtPaymentPrice" class="control-label">
                                            <span><i class="glyphicon glyphicon-asterisk"></i>지급예정금액</span>
                                        </label>
                                        <input type="text" id="txtPaymentPrice" class="form-control text-right"
                                               v-model="ProductPaymentInsertTerm.PaymentPrice" @keyup="inputPrice($event, 'PaymentPrice')" />
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="txtVatIncludeYN" class="control-label">
                                            <span>부가세구분</span>
                                        </label>
                                        <input type="text" id="txtVatIncludeYN" class="form-control txt" readonly="readonly" v-model="ProductPaymentInsertTerm.VatIncludeYnNm" />
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtPaymentVAT" class="control-label">
                                            <span>부가세</span>
                                        </label>
                                        <input type="text" id="txtPaymentVAT" class="form-control text-right" readonly="readonly"
                                               v-model="ProductPaymentInsertTerm.PaymentVAT" @click="removeReadonly" @keyup="inputPrice($event, 'PaymentVAT')" />
                                    </div>
                                </section>
                                <!--빈공백-->
                                <section class="cols-wrap">
                                    <div class="cols">
                                        <label></label>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-1">
                                        <label class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>발행예정일
                                            </span>
                                        </label>
                                        <div class="col-xs-7">
                                            <div class="col-2 input-group date">
                                                <DxDateBox display-format="yyyy-MM"
                                                           pickerType="calendar"
                                                           v-model="BatchCalculateTerm.BatchBillStart"
                                                           style="border-radius:0;">
                                                    <DxCalendarOptions maxZoomLevel="year" />
                                                </DxDateBox>
                                            </div>
                                            <div class="tail"><span>~</span></div>
                                            <div class="col-2 input-group date">
                                                <DxDateBox display-format="yyyy-MM"
                                                           pickerType="calendar"
                                                           v-model="BatchCalculateTerm.BatchBillEnd"
                                                           style="border-radius:0;">
                                                    <DxCalendarOptions maxZoomLevel="year" />
                                                </DxDateBox>
                                            </div>
                                        </div>
                                        <div class="col-xs-3">
                                            <select class="form-control" id="selBatchDay" ref="selBatchDay" name="selBatchDay" v-model="BatchCalculateTerm.BatchBillDay">
                                                <option value="">== 일자 ==</option>
                                                <option v-for="date in TargetDate" v-bind:value="date">{{ date }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-1">
                                        <label class="control-label">
                                            <span>
                                                <i class="glyphicon glyphicon-asterisk"></i>지급예정일
                                            </span>
                                        </label>
                                        <div class="col-xs-7">
                                            <div class="col-2 input-group date">
                                                <DxDateBox display-format="yyyy-MM"
                                                           pickerType="calendar"
                                                           v-model="BatchCalculateTerm.BatchPayStart"
                                                           style="border-radius:0;">
                                                    <DxCalendarOptions maxZoomLevel="year" />
                                                </DxDateBox>
                                            </div>
                                            <div class="tail"><span>~</span></div>
                                            <div class="col-2 input-group date">
                                                <DxDateBox display-format="yyyy-MM"
                                                           pickerType="calendar"
                                                           v-model="BatchCalculateTerm.BatchPayEnd"
                                                           style="border-radius:0;">
                                                    <DxCalendarOptions maxZoomLevel="year" />
                                                </DxDateBox>
                                            </div>
                                        </div>
                                        <div class="col-xs-3">
                                            <select class="form-control" id="selBatchDay" ref="selBatchDay" name="selBatchDay" v-model="BatchCalculateTerm.BatchPayDay">
                                                <option value="">== 일자 ==</option>
                                                <option v-for="date in TargetDate" v-bind:value="date">{{ date }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </section>
                                <section class="cols-wrap">
                                    <div class="cols col-1">
                                        <label for="txtPaymentMemo" class="control-label">
                                            <span>메모</span>
                                        </label>
                                        <textarea id="txtPaymentMemo" class="form-control" v-model="ProductPaymentInsertTerm.MemoCont" />
                                    </div>
                                </section>
                            </fieldset>
                        </form>
                    </section>
                </div>
                <div class="modal-footer">
                    <section class="btn-align">
                        <div class="btn-group">
                            <button type="button" id="btnItemSave" class="btn btn-primary" @click.prevent="saveProductPayment">
                                <i class="glyphicon glyphicon-save"></i> 저장
                            </button>
                        </div>
                        <div class="btn-group">
                            <button type="button" class="btn btn-danger" data-dismiss="modal" @click.prevent="close">
                                <i class="glyphicon glyphicon-remove"></i> 취소
                            </button>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { DxDateBox, DxCalendarOptions } from 'devextreme-vue/date-box';

    export default {
        name: 'PM1042E',
        components: {
            DxDateBox, DxCalendarOptions
        },
        data() {
            return {
                CallbackFunction: null,
                // 일괄등록 저장 객체
                ProductPaymentInsertTerm: new Object(),
                // 발행예정일, 지급예정일 계산 조건
                BatchCalculateTerm: {
                    BatchBillStart: null,
                    BatchBillEnd: null,
                    BatchPayStart: null,
                    BatchPayEnd: null,
                    BatchBillDay: '',
                    BatchPayDay: '',
                },
                // 일자 선택 조건
                TargetDate: new Array(),
                // 부가세 수정여부
                ModifiedVat: false,
            }
        },
        beforeMount() {

            // 대금지급 구분 공통코드 조회
            this.PaymentCodeList = $.scriptBase.getCommonCode('CODE000', '0715000', '', '');

            this.TargetDate.push('월말');
            for (let i = 1; i < 32; i++) {

                let targetDate = (i < 10) ? ('0' + i).toString() : i.toString();
                this.TargetDate.push(targetDate);
            }
        },
        methods: {
            //                   매입 상품 정보, 매입 남은 금액
            open(callbackFunction, selectedObj, leftPrice) {

                this.CallbackFunction = callbackFunction;

                // 저장 객체 세팅
                this.ProductPaymentInsertTerm = {
                    Arg: 'CP',
                    UserId: this.$store.getters.getEmpId,
                    CorpCd: this.$store.getters.getCompanyCode,
                    CompanyCd: selectedObj.CompanyCd,
                    CompanyNm: selectedObj.CompanyNm,
                    ProjectCd: selectedObj.ProjectCd,
                    ProjectNm: selectedObj.ProjectNm,
                    ProductSeqNo: selectedObj.SeqNo,
                    ProductNm: selectedObj.ProductNm,
                    PurchaseNm: selectedObj.CompanyNm + '_' + selectedObj.ProjectNm + '_' + selectedObj.ProductNm,
                    PurchaseCompanyCd: selectedObj.PurchaseCompanyCd,
                    LeftPrice: leftPrice.toPriceNumber(),
                    VatIncludeYn: selectedObj.VatIncludeYn,
                    VatIncludeYnNm: '',
                    ItemNo: -1,
                    PaymentCode: '',
                    PaymentPrice: 0,
                    PaymentVAT: 0,
                    BillDateStart: null,
                    BillDateEnd: null,
                    PayDateStart: null,
                    PayDateEnd: null,
                    PaymentMemo: '',
                }

                switch(selectedObj.VatIncludeYn) {

                    case 'N': {
                        this.ProductPaymentInsertTerm.VatIncludeYnNm = '별도';
                        break;
                    }
                    case 'Y': {
                        this.ProductPaymentInsertTerm.VatIncludeYnNm = '포함';
                        break;
                    }
                    case 'Z': {
                        this.ProductPaymentInsertTerm.VatIncludeYnNm = '영세율';
                        break;
                    }
                    case 'F': {
                        this.ProductPaymentInsertTerm.VatIncludeYnNm = '면세';
                        break;
                    }
                }

                $("#PM1042E_Modal").modal("show");
            },
            close() {

                this.CallbackFunction();

                this.ProductPaymentInsertTerm = new Object();
                this.BatchCalculateTerm = {
                    BatchBillStart: null,
                    BatchBillEnd: null,
                    BatchPayStart: null,
                    BatchPayEnd: null,
                    BatchBillDay: '',
                    BatchPayDay: '',
                };

                txtPaymentVAT.setAttribute('readonly', 'readonly');
                this.ModifiedVat = false;

                $("#PM1042E_Modal").modal("hide");
            },
            // datagrid header 가운데 정렬, column 중앙 정렬
            onCellPrepared(e) {

                if (e.rowType == 'header') {
                    e.cellElement.style['textAlign'] = 'center';
                }

                if (e.rowType == 'data') {
                    e.cellElement.style['verticalAlign'] = 'middle';
                }
            },
            // 구입금액 숫자만 입력, 콤마 처리
            inputPrice(e, obj) {

                let value = e.target._value;

                if (isNaN(e.key)) {

                    this.ProductPaymentInsertTerm[obj] = 0;
                    this.ProductPaymentInsertTerm.PaymentVAT = (this.ProductPaymentInsertTerm.PaymentPrice == 0) ? 0 : this.ProductPaymentInsertTerm.PaymentVAT;
                } else {

                    value = Number(value.replaceAll(',', ''));
                    this.ProductPaymentInsertTerm[obj] = value.toLocaleString('ko-KR');

                    if (obj == 'PaymentPrice' && this.ProductPaymentInsertTerm.VatIncludeYn == 'N') {

                        this.autoCalculateVat();
                    }
                }
            },
            // 부가세 자동계산
            autoCalculateVat() {

                // 부가세 직접 수정하지 않았을 때 동작
                if (this.ModifiedVat == false) {

                    if (this.ProductPaymentInsertTerm.PaymentPrice != null && this.ProductPaymentInsertTerm.PaymentPrice != '') {

                        let vatCal = Number(this.ProductPaymentInsertTerm.PaymentPrice.toNumber()) * 0.1;
                        this.ProductPaymentInsertTerm.PaymentVAT = Math.round(vatCal).toPriceNumber();
                    } else {

                        this.ProductPaymentInsertTerm.PaymentVAT = null;
                    }
                }
            },
            // 부가세 readonly 속성 제거
            removeReadonly() {

                let readonly = txtPaymentVAT.getAttribute('readonly');

                // readonly이고, 부가세 별도일 때
                if (readonly == 'readonly' && this.ProductPaymentInsertTerm.VatIncludeYn == 'N') {

                    if (confirm('부가세를 수정하시겠습니까?')){

                        // 부가세 직접 수정 가능
                        document.getElementById('txtPaymentVAT').removeAttribute('readonly');
                        this.ModifiedVat = true;
                    }
                }
            },
            // 저장 메소드
            saveProductPayment() {

                if (this.ProductPaymentInsertTerm.LeftPrice == 0) {

                    alert('남은 매입금액이 없어 대금지급 계획을 생성할 수 없습니다. 다시 확인해 주십시오.');
                    $('#txtProductLeftPrice').focus();
                    return;
                }

                if (this.ProductPaymentInsertTerm.PaymentCode == '' || this.ProductPaymentInsertTerm.PaymentCode == null) {

                    alert('대금지급구분을 선택해 주십시오.');
                    $('#selPaymentCd').focus();
                    return;
                }

                // 날짜 입력 여부 확인
                if (this.BatchCalculateTerm.BatchBillStart === '' || this.BatchCalculateTerm.BatchBillStart === null || this.BatchCalculateTerm.BatchBillEnd === '' || this.BatchCalculateTerm.BatchBillEnd === null || this.BatchCalculateTerm.BatchBillDay === '' || this.BatchCalculateTerm.BatchBillDay === null) {

                    alert("발행예정일을 입력하세요.");
                    return;
                } else if (this.BatchCalculateTerm.BatchPayStart === '' || this.BatchCalculateTerm.BatchPayStart === null || this.BatchCalculateTerm.BatchPayEnd === '' || this.BatchCalculateTerm.BatchPayEnd === null || this.BatchCalculateTerm.BatchPayDay === '' || this.BatchCalculateTerm.BatchPayDay === null) {

                    alert("지급예정일을 입력하세요.");
                    return;
                }

                let year1 = this.moment(this.BatchCalculateTerm.BatchBillStart).format('YYYY');
                let month1 = this.moment(this.BatchCalculateTerm.BatchBillStart).format('YYYYMM').substr(4, 2);
                let year2 = this.moment(this.BatchCalculateTerm.BatchBillEnd).format('YYYY');
                let month2 = this.moment(this.BatchCalculateTerm.BatchBillEnd).format('YYYYMM').substr(4, 2);

                let year3 = this.moment(this.BatchCalculateTerm.BatchPayStart).format('YYYY');
                let month3 = this.moment(this.BatchCalculateTerm.BatchPayStart).format('YYYYMM').substr(4, 2);
                let year4 = this.moment(this.BatchCalculateTerm.BatchPayEnd).format('YYYY');
                let month4 = this.moment(this.BatchCalculateTerm.BatchPayEnd).format('YYYYMM').substr(4, 2);

                // 발행예정일 날짜 세팅
                if (this.BatchCalculateTerm.BatchBillDay == '월말') {

                    this.ProductPaymentInsertTerm.BillDateStart = this.moment(new Date(year1, month1, 0)).format('YYYYMMDD');
                    this.ProductPaymentInsertTerm.BillDateEnd = this.moment(this.BatchCalculateTerm.BatchBillEnd).format('YYYYMM') + this.moment(this.ProductPaymentInsertTerm.BillDateStart).format('YYYYMMDD').substr(6, 2);
                } else {

                    this.ProductPaymentInsertTerm.BillDateStart = this.moment(this.BatchCalculateTerm.BatchBillStart).format('YYYYMM') + this.BatchCalculateTerm.BatchBillDay;
                    this.ProductPaymentInsertTerm.BillDateEnd = this.moment(this.BatchCalculateTerm.BatchBillEnd).format('YYYYMM') + this.BatchCalculateTerm.BatchBillDay;
                }

                // 지급예정일 날짜 세팅
                if (this.BatchCalculateTerm.BatchPayDay == '월말') {

                    this.ProductPaymentInsertTerm.PayDateStart = this.moment(new Date(year3, month3, 0)).format('YYYYMMDD');
                    this.ProductPaymentInsertTerm.PayDateEnd = this.moment(this.BatchCalculateTerm.BatchPayEnd).format('YYYYMM') + this.moment(this.ProductPaymentInsertTerm.PayDateStart).format('YYYYMMDD').substr(6, 2);
                } else {

                    this.ProductPaymentInsertTerm.PayDateStart = this.moment(this.BatchCalculateTerm.BatchPayStart).format('YYYYMM') + this.BatchCalculateTerm.BatchPayDay;
                    this.ProductPaymentInsertTerm.PayDateEnd = this.moment(this.BatchCalculateTerm.BatchPayEnd).format('YYYYMM') + this.BatchCalculateTerm.BatchPayDay;
                }

                if (this.ProductPaymentInsertTerm.BillDateStart > this.ProductPaymentInsertTerm.BillDateEnd) {

                    alert("발행예정일의 시작월은 종료월보다 클 수 없습니다.");
                    return;
                } else if (this.ProductPaymentInsertTerm.PayDateStart > this.ProductPaymentInsertTerm.PayDateEnd) {

                    alert("지급예정일의 시작월은 종료월보다 클 수 없습니다.");
                    return;
                }

                // 발행예정일, 입금예정일 개월수 계산
                let billMonthCnt = month2 - month1 + 1 + 12 * (year2 - year1);
                let payMonthCnt = month4 - month3 + 1 + 12 * (year4 - year3);

                if (billMonthCnt != payMonthCnt) {

                    alert("발행예정일과 지급예정일의 개월수를 동일하게 입력해주세요.");
                    return;
                }

                if (this.ProductPaymentInsertTerm.PaymentPrice == 0) {

                    alert('지급예정금액을 입력해 주십시오.');
                    $('#txtPaymentPrice').focus();
                    return;
                }

                // 생성될 대금지급계획의 총 지급예정금액
                let SumPrice = payMonthCnt * this.ProductPaymentInsertTerm.PaymentPrice;

                if (this.ProductPaymentInsertTerm.LeftPrice < SumPrice) {

                    alert('생성될 지급예정금액의 총액이 남은 매입금액보다 많습니다. 다시 확인해 주십시오.');
                    $('#txtPaymentPrice').focus();
                    return;
                }

                // 부가세를 직접 입력했을 때
                if (this.ModifiedVat == true) {

                    let vatCal = Number(this.ProductPaymentInsertTerm.PaymentPrice.toNumber()) * 0.1;
                    // 오차범위 = 절대값(수정한 부가세 - Price * 0.1)
                    let marginOfError = Math.abs(Number(this.ProductPaymentInsertTerm.PaymentVAT.toNumber()) - vatCal);

                    // 오차가 100원 이상일 때
                    if (marginOfError > 100) {

                        alert("부가세액을 다시 확인해 주십시오.");
                        $('#txtPaymentVAT').focus();
                        return;
                    }
                }

                this.ProductPaymentInsertTerm.PaymentPrice = Number(this.ProductPaymentInsertTerm.PaymentPrice.toNumber());
                this.ProductPaymentInsertTerm.PaymentVAT = (this.ProductPaymentInsertTerm.PaymentVAT == 0) ? 0 : Number(this.ProductPaymentInsertTerm.PaymentVAT.toNumber());

                let ProductPaymentInsertArray = new Array();
                ProductPaymentInsertArray.push(this.ProductPaymentInsertTerm);

                let parent = this;

                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/SaveProductPayment'.toRealServiceUrl();
                var obj = new Object();
                obj.jsonStringPaymentList = JSON.stringify(ProductPaymentInsertArray);

                var successCallback = function (data) {

                    if (data.d > 0) {

                        alert('등록되었습니다.');
                        parent.close();
                    } else {

                        alert('저장에 실패했습니다. 잠시 후 다시 시도해 주십시오.');
                        return;
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
        }
    }
</script>