<template>
    <!-- 계약 관리 -->
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle>계약 관리</ViewTitle>
        <!---->
        <div class="content-box">
            <div class="btn-wrap">
                <a v-show="this.$parent.CanSearch" href="#" id="btnListSrch" class="btn btn-primary btn-row2" @click.prevent="onSearchClick">
                    <span>
                        <i class="glyphicon glyphicon-search"></i> 조회
                    </span>
                </a>
                <a v-if="this.$parent.CanNew" href="#" id="btnListInst" class="btn btn-default" @click.prevent="onAddClick">
                    <span>
                        <i class="glyphicon glyphicon-plus"></i> 추가
                    </span>
                </a>
                <!-- 삭제 버튼 추가 -->
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="cols-wrap">
                                <div class="cols col-2">
                                    <label for="selTrgtYY" class="control-label" style="text-align:center">
                                        <span>년도</span>
                                    </label>
                                    <select id="selTrgtYY" class="form-control" v-model="Condition.TargetYear">
                                        <option v-for="year in YearsList" v-bind:value="year">
                                            {{year}}
                                        </option>
                                    </select>
                                </div>
                                <div class="cols col-2">
                                    <label for="txtContractName" class="control-label" style="text-align:center">
                                        <span>계약명</span>
                                    </label>
                                    <input type="text" id="txtContractName" class="form-control txt" v-model="Condition.ContractNm" />
                                </div>
                            </section>
                            <section class="cols-wrap">
                                <div class="cols col-2">
                                    <label for="txtTraderNm" class="control-label" style="text-align:center">
                                        <span>업체명(발주, 도급)</span>
                                    </label>
                                    <div class="cols-btns-cont pad-r150">
                                        <input type="text" id="txtTraderNm" class="form-control txt" readonly="readonly" v-model="Condition.CompanyNm" />
                                    </div>
                                    <div class="cols-btns">
                                        <a href="#" id="btnCompanyCode" class="btn btn-default" @click.prevent="onCompanyCodeClick">
                                            <span>
                                                <i class="glyphicon glyphicon-search"></i> 검색
                                            </span>
                                        </a>
                                        <a href="#" id="btnSrchInit" class="btn btn-default" @click.prevent="onResetCompnayClick">
                                            <span>
                                                <i class="glyphicon glyphicon-repeat"></i> 초기화
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </section>
                        </fieldset>
                    </form>
                </div>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">계약 관리</p>
                </div>
                <div>
                    <!--리스트 영역-->
                    <section class="tbl-contents">
                        <div class="alert alert-info total">
                            <p>
                                <i class="glyphicon glyphicon-folder-close"></i>
                                전체: <span id="spTotCnt">{{DataCount}}</span>건
                            </p>
                        </div>
                        <div class="table-responsive">
                            <DxDataGrid ref="grdList"
                                        :data-source="DataSource"
                                        :hoverStateEnabled="true"
                                        :show-borders="true"
                                        :show-row-lines="true"
                                        @cell-prepared="onCellPrepared"
                                        @cell-click="onCellClick">
                                <DxColumn cell-template="detail" width="5%" alignment="center" />
                                <template #detail="{ data }">
                                    <DxButton icon="chevronnext" styling-mode="text" @click="onCollapseClick(data)" />
                                </template>
                                <DxColumn caption="발주업체" data-field="OrderCompNm" width="20%" />
                                <DxColumn caption="계약업체" data-field="ContractCompNm" width="20%" />
                                <DxColumn caption="계약명" data-field="ContractNm" width="45%"/>
                                <DxColumn cell-template="add" width="10%" alignment="center">
                                </DxColumn>
                                <template #add="{ data }">
                                    <DxButton text="계약상세등록" type="default" styling-mode="contained" @click="onAddContractDetailClick(data)"/>
                                </template>
                                <DxMasterDetail :enabled="false" template="masterDetailTemplate" />
                                <template #masterDetailTemplate="{ data }">
                                    <BZ1020R_Detail :template-data="data" />
                                </template>
                            </DxDataGrid>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <BZ1021E ref="BZ1021E"></BZ1021E>
        <BZ1022E ref="BZ1022E"></BZ1022E>
        <CP1030P ref="CP1030P"></CP1030P>
    </div>
</template>

<script>

    import ViewTitle from '@/components/ViewTitle'

    import BZ1021E from '@/components/bz/popup/BZ1021E'
    import BZ1022E from '@/components/bz/popup/BZ1022E'
    import BZ1020R_Detail from '@/components/bz/libs/BZ1020R_Detail.vue'

    import CP1030P from '@/components/libs/popup/CP1030P'

    import DxDataGrid, { DxColumn, DxMasterDetail } from 'devextreme-vue/data-grid'
    import { DxButton } from 'devextreme-vue'

    export default {
        name: 'BZ1020R'
        , components: {
            ViewTitle, DxDataGrid, DxColumn, DxMasterDetail, DxButton, BZ1021E, BZ1020R_Detail, CP1030P, BZ1022E
        }
        , data() {
            return {
                Condition: {
                    CorpCd: this.$parent.CompanyCode
                    , ContractNm: ''
                    , CompanyNm: ''
                    , CompanyCd: ''
                    , TargetYear: this.moment(new Date()).year()
                }
                , YearsList: new Array()
                , DataCount: 0
                , DataSource: new Array()
            }
        }
        , beforeMount() {
            var startYear = this.moment(new Date()).add(1, 'year').year()
            var endYear = this.moment(new Date(2013, 1, 1, 0, 0, 0)).year()

            // 대상년도 목록 값 설정
            for (var i = startYear; i >= endYear; i--) {
                this.YearsList.push(i)
            }
        }
        , mounted() {
            if (this.$parent.CanSearch) {
                this.getSearchContract()
			}
		}
        , methods: {
            //#region init
            //#endregion

            //#region event
            // datagrid header 가운데 정렬, column 중앙 정렬
            onCellPrepared(e) {
                if (e.rowType == 'header') {
                    e.cellElement.style['textAlign'] = 'center'
                }
                if (e.rowType == 'data') {
                    e.cellElement.style['verticalAlign'] = 'middle'
                }
            }
            //#endregion

            //#region button click
            // 마스터 그리드 선택 이벤트
            , onCellClick(e) {
                var that = this
                if (e.rowType === "data" && e.column.dataField === "ContractNm") {
                    var successCallback = function (result) {
                        that.getSearchContract()
                    }

                    this.$refs.BZ1021E.open(successCallback, this.Condition, e.data)
                }
            }
            , onCollapseClick(e) {
                this.clicked = !this.clicked;
                var expanded = e.component.isRowExpanded(e.row.key)
                if (expanded) {
                    e.component.collapseAll(-1);
                }
                else {
                    e.component.collapseAll(-1);
                    e.component.expandRow(e.row.key);
                }
            }
            , onSearchClick() {

                this.getSearchContract()

            }
            , onAddClick() {
                var that = this

                var successCallback = function () {
                    that.searchContract()
                }

                this.$refs.BZ1021E.open(successCallback, parent.Condition, null)
            }
            //마스터 그리드 계약상세등록 버튼 클릭
            , onAddContractDetailClick(e) {
                var that = this

                var callbackFuntion = function (result) {
                    that.getSearchContract()
                }

                this.$refs.BZ1022E.open(callbackFuntion, this.Condition, null, e.row.data)
            }
            , onCellClick(e) {
                var that = this

                if (e.rowType === "data" && e.column.dataField === "ContractNm") {
                    var successCallback = function (result) {
                        that.getSearchContract()
                    }

                    this.$refs.BZ1021E.open(successCallback, this.Condition, e.data)
                }
            }
            , onCompanyCodeClick(){
                var that = this
                var successCallback = function (result) {

                    // 결과값을 추출 후 돔객체에 바인딩
                    that.Condition.CompanyNm = result.CompanyNm
                    that.Condition.CompanyCd = result.CompanyCd
                }

                // 업체 검색 팝업 오픈
                this.$refs.CP1030P.open(successCallback)
            }
            , onResetCompnayClick() {
                this.Condition.CompanyNm = null
                this.Condition.CompanyCd = null
            }
            //#endregion

            //#region service
            // 계약 조회
            , getSearchContract() {
                var that = this

                // 조회
                var url = '/Mini.Service.IBS.BZ.Service/Business.svc/ajax/GetContractList'
                url = url.toRealServiceUrl()
                var obj = new Object()
                obj.jsonString = JSON.stringify(that.Condition)

                var ajaxSuccessHandler = function (data) {
                    var jsonObj = JSON.parse(data.d)

                    if (!jsonObj.Table) {
                        jsonObj.Table = new Array()
                    }

                    that.DataSource = jsonObj.Table
                    // 그리드 바인딩
                    that.DataCount = jsonObj.Table.length
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
            }
            //#endregion
        }
    }
</script>

