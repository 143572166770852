<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>
        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->

                <a href="#" id="btnListSrch" class="btn btn-primary btn-row2" v-show="this.$parent.CanSearch" @click.prevent="onSearchClick">
                    <span>
                        <i class="glyphicon glyphicon-search"></i> 조회
                    </span>
                </a>

                <a href="#" id="btnListInst" class="btn btn-default" @click.prevent="onRegisterContractFileClick()">
                    <span>
                        <i class="glyphicon glyphicon-plus"></i> 생성
                    </span>
                </a>

            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-4">
                                        <label for="selTrgtYY" class="control-label">
                                            <span>년도</span>
                                        </label>
                                        <!--<select class="form-control" ref="selTrgtYY" name="selTrgtYY" v-model="CollectMoneySelectTerm.TargetYear">-->
                                        <select class="form-control" ref="selTrgtYY" name="selTrgtYY" v-model="Condition.year">
                                            <option v-for="year in TargetYearList" v-bind:value="year">
                                                {{year}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-4">
                                        <label for="selTrgtFile" class="control-label">
                                            <span>파일 구분</span>
                                        </label>
                                        <!--<select class="form-control" ref="selTrgtYY" name="selTrgtYY" v-model="CollectMoneySelectTerm.TargetYear">-->
                                        <select class="form-control" ref="selTrgtFile" name="selTrgtFile" v-model="Condition.file">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="file in FileDivisionCodeList" v-bind:value="file.Code">
                                                {{file.Name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="txtCompanyNm" class="control-label">
                                            <span>프로젝트</span>
                                        </label>
                                        <div class="cols-btns-cont pad-r150">
                                            <input type="text" id="txtProjectNm" class="form-control" readonly="readonly" v-model="ProjectSelectTerm.ProjectName" />
                                        </div>
                                        <div class="cols-btns">
                                            <a href="#" id="btnListPop1" class="btn btn-default" @click.prevent="onProjectSerchClick()">
                                                <span>
                                                    <i class="glyphicon glyphicon-search"></i> 검색
                                                </span>
                                            </a>
                                            <a href="#" id="btnSrchInit" class="btn btn-default" @click.prevent="onProjectInitClick()">
                                                <span>
                                                    <i class="glyphicon glyphicon-repeat"></i> 초기화
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="cols col-1">
                                        <label for="txtProjectNm" class="control-label">
                                            <span>검색명</span>
                                        </label>
                                        <input type="text" id="txtProjectNm" class="form-control txt" v-model="Condition.text" @keypress.enter.prevent="getProjectFileList"/>
                                    </div>
                                </section>
                            </section>
                        </fieldset>
                    </form>
                </div>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">계약서 관리</p>
                </div>
                <div>
                    <!--리스트 영역-->
                    <section class="tbl-contents">
                        <div class="alert alert-info total">
                            <p>
                                <i class="glyphicon glyphicon-folder-close"></i>
                                전체: <span id="spTotCnt">{{ProjectFileListCount}}</span>건
                            </p>
                        </div>
                        <div class="table-responsive">
                            <DxDataGrid ref="grdList"
                                        :data-source="ProjectFileList"
                                        :hoverStateEnabled="true"
                                        :show-borders="true"
                                        :show-row-lines="true"
                                        @cell-prepared="onCellPrepared"
                                        @cell-click="onCellClick">
                                <DxColumn caption="파일구분" data-field="FileType" width="10%" alignment="center"/>
                                <DxColumn caption="거래처" data-field="CompanyNm" width="15%" />
                                <DxColumn caption="프로젝트명" data-field="ProjectNm" width="20%" />
                                <DxColumn caption="계약서명" data-field="ContractFileName" width="20%" />
                                <DxColumn caption="파일명" data-field="FileName" width="20%" />
                                <DxColumn caption="등록일" data-field="RegtDate" width="10%" alignment="center" />
                                <DxColumn caption="등록자" data-field="EmpNm" width="5%" alignment="center" />
                                <DxPaging :enabled="false" />
                            </DxDataGrid>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <CP1060P ref="CP1060P"></CP1060P>
        <PM1081E ref="PM1081E"></PM1081E>
    </div>
</template>

<script>
    import ViewTitle from '@/components/ViewTitle'

    // DevExtreme Component
    import {
        DxDataGrid, DxColumn, DxMasterDetail, DxButton, DxScrolling, DxSorting, DxLoadPanel, DxPaging } from 'devextreme-vue/data-grid'

    // Modal
    import CP1060P from '@/components/libs/popup/CP1060P'
    import PM1081E from '@/components/pm/popup/PM1081E'

    export default {
        name: 'PM1080R',
        components: {
            ViewTitle
            , DxDataGrid
            , DxColumn
            , DxMasterDetail
            , DxButton
            , DxScrolling
            , DxSorting
            , DxLoadPanel
            , DxPaging
            , CP1060P
            , PM1081E
        },
        data() {
            return {
                TargetYearList: new Array()                                 // 년도 목록
                , FileDivisionCodeList: new Array()                         // 파일구분 목록
                , SearchDivisionCodeList: new Array()                       // 검색구분 목록
                , ProjectSelectTerm: {                                      // 프로젝트 조회조건 객체
                    Arg: 'L'
                    , UserId: this.$parent.EmpId
                    , CompanyCode: this.$parent.CompanyCode
                    , ProjectCd: null
                    , ProjectName: null
                    , ProjectNo: null
                    , ProjectCompanyCode: null
                    , ProjectCompanyName: null
                    , TopSize: 10
                    , CurrentPage: 1
                    , TargetYear: this.moment(new Date()).year()
                    , StatusCode: '0701030'
                    , RoleSeq: '0'
                }
                , Condition: {
                    year: this.moment(new Date()).year()
                    , file: ''
                    , text: ''
                    , ProjectCd: ''
                    , ProjectNo: ''
                }
                , ProjectFileListCount: 0                                   // 파일 갯수
                , ProjectFileList: new Array()                              // 프로젝트 파일 리스트
                , Email: this.$parent.EmailAddress
                , PageActionType: null                                      // 페이지 액션 값(I: 신규, V: 상세)
            }
        },
        beforeMount() {
            //#region 공통코드 조회
            this.FileDivisionCodeList = $.scriptBase.getCommonCode('CODE000', '0737000', '', '')        // 파일구분 목록
            this.SearchDivisionCodeList = $.scriptBase.getCommonCode('CODE000', '0738000', '', '')      // 검색구분 목록
            //#endregion

            //#region 년도 조회 리스트 세팅
            var startYear = this.moment(new Date()).add(1, 'year').year()
            var endYear = this.moment(new Date(2013, 1, 1, 0, 0, 0)).year()
            
            for (var i = startYear; i >= endYear; i--) {        // 대상년도 목록 값 설정

                this.TargetYearList.push(i)
            }
            //#endregion
        },
        mounted() {
            this.getProjectFileList()
        },
        updated() {

        },
        methods: {
            //#region init
            initProject() {
                this.ProjectSelectTerm.ProjectCode = null
                this.ProjectSelectTerm.ProjectName = null
                this.ProjectSelectTerm.ProjectCompanyCode = null
                this.ProjectSelectTerm.ProjectCompanyName = null
                this.ProjectSelectTerm.CurrentPage = 1
            }
            //#endregion

            //#region Service
            // datagrid header 가운데 정렬, column 중앙 정렬
            , onCellPrepared(e) {
                if (e.rowType == 'header') {
                    e.cellElement.style['textAlign'] = 'center'
                }
                if (e.rowType == 'data') {
                    e.cellElement.style['verticalAlign'] = 'middle'
                }
            }
            , getProjectFileList() {
                if (this.Condition.text != '' && this.Condition.text.length < 2) {
                    alert('검색할 단어는 두 글자 이상 입력 해주세요.')
                    return false
                }

                var that = this

                var ContractSelectTerm = {
                    Arg: 'L'
                    , Year: this.Condition.year
                    , FileDivision: this.Condition.file
                    , Text: this.Condition.text
                    , ProjectCd: this.Condition.ProjectCd
                    , ProjectNo: this.Condition.ProjectNo == '' ? 0 : parseInt(this.Condition.ProjectNo)
                }

                var url = '/Mini.Service.IBS.PM.Service/ProjectMgt.svc/ajax/GetProjectFileList'
                url = url.toRealServiceUrl()
                var obj = new Object()

                obj.jsonString = JSON.stringify(ContractSelectTerm)

                var ajaxSuccessHandler = function (data) {
                    var jsonObj = JSON.parse(data.d)

                    that.ProjectFileList = jsonObj.Table
                    that.ProjectFileListCount = jsonObj.Table.length
                }

                $.scriptBase.executeAjaxAsync(url, obj, ajaxSuccessHandler)
            }
            //#endregion

            //#region ButtonClick
            , onProjectSerchClick() {               // 프로젝트 조회 클릭
                this.onProjectModalOpen()
            }
            , onProjectInitClick() {                // 프로젝트 초기화 클릭
                this.initProject()
            }
            , onRegisterContractFileClick() {       // 계약서 등록 클릭
                this.onContractFileModalOpen('new', null, null)
            }
            , onSearchClick() {                     // 계약서 조회 클릭
                this.getProjectFileList()
            }
            , onCellClick(e) {                      // 계약서 클릭
                this.onContractFileModalOpen('Detail', e.data.ProjectCd, e.data.ProjectNo)
            }
            //#endregion

            //#region Event
            //#endregion

            //#region Modal
            , onProjectModalOpen() {    // 프로젝트 조회 ModalOpen
                var that = this

                var successCallback = function (result) {

                    that.Condition.ProjectCd = result.ProjectCd
                    that.Condition.ProjectNo = result.ProjectNo
                    that.ProjectSelectTerm.ProjectCd = result.ProjectCd
                    that.ProjectSelectTerm.ProjectNo = result.ProjectNo
                    that.ProjectSelectTerm.ProjectName = result.ProjectNm

                    that.ProjectSelectTerm.CurrentPage = 1
                }

                this.$refs.CP1060P.open(successCallback, null, null, true)
            }
            , onContractFileModalOpen(type, projectcd, projectno) {
                var that = this

                var successCallback = function (result) {
                    that.getProjectFileList()
                }
                this.$refs.PM1081E.onContractFileDetailOpen(successCallback, type, this.ProjectSelectTerm.UserId, this.Email, projectcd, projectno)
                
            }
            //#endregion
        }
    }
</script>