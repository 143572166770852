<template>
    <div class="dashboard-wrap">
        <!-- 타이틀 영역 -->
        <ViewTitle></ViewTitle>

        <div class="content-box">
            <div class="btn-wrap">
                <!--공통 버튼영역-->
                <a v-show="this.$parent.CanSearch" href="#" id="btnListSrch" class="btn btn-primary btn-row2" @click.prevent="getBankAccount(false)">
                    <span>
                        <i class="glyphicon glyphicon-search"></i> 조회
                    </span>
                </a>
                <a v-show="this.$parent.CanNew" href="#" id="btnListInst" class="btn btn-default" @click.prevent="addBankAccount">
                    <span>
                        <i class="glyphicon glyphicon-plus"></i> 추가
                    </span>
                </a>
                <a v-show="this.$parent.CanSearch" href="#" id="btnBalanceModal" class="btn btn-default" @click.prevent="openBalanceModal">
                    <span>잔액관리</span>
                </a>
            </div>
            <div class="sch-box">
                <!--조건 선언부-->
                <div class="sch-form">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <legend>검색폼</legend>
                            <section class="sch-cont">
                                <section class="cols-wrap">
                                    <div class="cols col-2">
                                        <label for="selBankCd" class="control-label">
                                            <span>은행</span>
                                        </label>
                                        <select class="form-control" id="selBankCd" name="selBankCd" v-model="AccountListSelectTerm.BankCode">
                                            <option value="">== 전체 ==</option>
                                            <option v-for="item in BankCodeList" v-bind:value="item.Code">{{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="cols col-2">
                                        <label for="selUseYN" class="control-label">
                                            <span>사용여부</span>
                                        </label>
                                        <select id="selUseYN" class="form-control" v-model="AccountListSelectTerm.UseYn">
                                            <option value="">== 전체 ==</option>
                                            <option value="Y">Y</option>
                                            <option value="N">N</option>
                                        </select>
                                    </div>
                                </section>
                            </section>

                        </fieldset>
                    </form>
                </div>
            </div>
            <div class="grid-wrap">
                <div class="sub-title">
                    <p class="sub-title-txt">은행계좌 관리</p>
                </div>
                <div>
                    <!--리스트 영역-->
                    <div class="alert alert-info total">
                        <p>
                            <i class="glyphicon glyphicon-folder-close"></i>
                            전체: <span id="spTotCnt">{{AccountListTotalCount}}</span>건
                        </p>
                    </div>
                    <div class="table-responsive">
                        <table class="table table tbl-list tbl-center table-hover table-bordered" id="tb0">
                            <caption>테이블</caption>
                            <colgroup>
                                <col class="table-colwid10" />
                                <col class="table-colwid20" />
                                <col class="table-colwid20" />
                                <col class="table-colwid5" />
                                <col class="table-colwid5" />
                                <col class="table-colwid15" />
                                <col />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th scope="col">은행</th>
                                    <th scope="col">계좌번호</th>
                                    <th scope="col">계좌별칭</th>
                                    <th scope="col">사용여부</th>
                                    <th scope="col">사본등록여부</th>
                                    <th scope="col">최종수정자</th>
                                    <th scope="col">최종수정일</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="AccountList === null">
                                    <td colspan="7" class="text-center no-data">조회된 정보가 없습니다.</td>
                                </tr>
                                <tr v-else v-for="item in AccountList" style="cursor:pointer;" @click.prevent="rowClickEvent(item)">
                                    <td style="display:none">{{item.CorpCd}}</td>
                                    <td>{{item.BankNm}}</td>
                                    <td style="text-align:left">{{item.AcntNo}}</td>
                                    <td style="text-align:left">{{item.AcntName}}</td>
                                    <td>{{item.UseYN}}</td>
                                    <td v-if="item.FileName != null">Y</td>
                                    <td v-else>N</td>
                                    <td>{{item.UpdateEmpNm}}</td>
                                    <td>{{item.UpdateDate}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center">
                        <ul id="ulpager" class="pagination"></ul>
                    </div>
                </div>
            </div>
            <div class="modal fade" ref="CM1011E_Modal" tabindex="-1" role="dialog" aria-labelledby="SM1041E_Modal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">
                                <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                            </button>
                            <h3 class="modal-title">은행계좌 등록/수정</h3>
                        </div>
                        <div class="modal-body">
                            <section class="add-wrap">
                                <form>
                                    <fieldset>
                                        <legend>..</legend>
                                        <section class="cols-wrap">
                                            <div class="cols col-2">
                                                <label for="selBankCd2" class="control-label">
                                                    <span>
                                                        <i class="glyphicon glyphicon-asterisk"></i>은행명
                                                    </span>
                                                </label>
                                                <select class="form-control" ref="selBankCd2" name="selBankCd"
                                                        v-bind:disabled="PageActionType != 'I'"
                                                        v-model="AccountDetail.BankCd">
                                                    <option value="">== 선택 ==</option>
                                                    <option v-for="item in BankCodeList" v-bind:value="item.Code">{{item.Name}}</option>
                                                </select>
                                            </div>
                                            <div class="cols col-2">
                                                <label for="txtAcntNo2" class="control-label">
                                                    <span>
                                                        <i class="glyphicon glyphicon-asterisk"></i>계좌번호
                                                    </span>
                                                </label>
                                                <input type="text" ref="txtAcntNo2" class="form-control txt"
                                                       v-bind:readonly="PageActionType != 'I'"
                                                       v-bind:value="AccountDetail.AcntNo"
                                                       @input="function(e){
                                                        AccountDetail.AcntNo = e.target.value.toAccountNumberFoormat()
                                                        e.target.value = e.target.value.toAccountNumberFoormat()
                                                   }" />
                                            </div>
                                        </section>
                                        <section class="cols-wrap">
                                            <div class="cols col-2">
                                                <label for="selUseYN2" class="control-label asterisk">
                                                    <span>사용여부</span>
                                                </label>
                                                <select id="selUseYN2" class="form-control" v-model="AccountDetail.UseYN">
                                                    <option value="Y">Y</option>
                                                    <option value="N">N</option>
                                                </select>
                                            </div>
                                            <div class="cols col-2">
                                                <label for="txtTraderNm2" class="control-label">
                                                    <span>계좌별칭</span>
                                                </label>
                                                <input type="text" id="txtAcntName2" class="form-control txt"
                                                       v-model="AccountDetail.AcntName" />
                                            </div>
                                        </section>
                                        <section class="cols-wrap">
                                            <div class="btn-wrap border-bottom">
                                                <div v-if="AccountDetail.IsOwner === 'Y'" class="fr">
                                                    <a href="#" id="btnChldInst" class="btn btn-default" title="추가" @click.prevent="addAttachFile">
                                                        <span class="glyphicon glyphicon-plus"></span> 추가
                                                    </a>
                                                    <a href="#" id="btnChldRmve" class="btn btn-default" title="삭제" @click.prevent="removeAttachFile">
                                                        <span class="glyphicon glyphicon-minus"></span> 삭제
                                                    </a>
                                                </div>
                                            </div>
                                            <section class="tbl-contents">
                                                <div class="table-responsive">
                                                    <form id="form1" action="" name="form1" method="post" enctype="multipart/form-data">
                                                        <table class="table tbl-list table-hover table-bordered" id="tb1">
                                                            <colgroup>
                                                                <col class="chk" />
                                                                <col />
                                                            </colgroup>
                                                            <thead>
                                                                <tr>
                                                                    <th class="chk table-colwid5">선택</th>
                                                                    <th scope="col" class="text-center">첨부파일</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-if="AccountDetail.FileName === null">
                                                                    <td colspan="2" class="text-center no-data">조회된 정보가 없습니다.</td>
                                                                </tr>
                                                                <tr v-else>
                                                                    <td class="chk">
                                                                        <label>
                                                                            <input type="checkbox" name="cbItem"
                                                                                   @click="{
                                                                                    AccountDetail.IsRemoveFile = $event.target.checked
                                                                               }" />
                                                                        </label>
                                                                    </td>
                                                                    <td v-if="AccountDetail.IsNewFile" class="text-center">
                                                                        <input type="file" name="ctlAttachFile"
                                                                               @change="{
                                                                                AccountDetail.Files = $event.target.files
                                                                           }" />
                                                                    </td>
                                                                    <td v-else class="text-left">
                                                                        <a href="#" @click.prevent="fileHandler.download(AccountDetail.GUID)">{{AccountDetail.FileName}}</a>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </form>
                                                </div>
                                            </section>
                                        </section>
                                        <section class="btn-align">
                                            <div v-show="this.$parent.CanSave && AccountDetail.IsOwner === 'Y'" class="btn-group">
                                                <button type="button" id="btnItemSave" class="btn btn-primary" @click.prevent="saveBankAccount">
                                                    <span class="glyphicon glyphicon-save"></span> 저장
                                                </button>
                                            </div>
                                            <div v-show="this.$parent.CanDelete && PageActionType != 'I' && AccountDetail.IsOwner === 'Y'" class="btn-group">
                                                <button type="button" id="btnItemRmve" class="btn btn-warning" @click.prevent="removeBankAccount">
                                                    <span class="glyphicon glyphicon-edit"></span> 삭제
                                                </button>
                                            </div>
                                            <div class="btn-group">
                                                <button type="button" class="btn btn-danger" data-dismiss="modal">
                                                    <span class="glyphicon glyphicon-remove"></span> 취소
                                                </button>
                                            </div>
                                        </section>
                                    </fieldset>
                                </form>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <CM1011E ref="CM1011E"></CM1011E>
    </div>
</template>

<script>
    import CM1011E from '@/components/cm/popup/CM1011E';
    import ViewTitle from '@/components/ViewTitle'
    export default {
        name: 'CM1010R',
        components: {
            ViewTitle, CM1011E
        },
        data() {
            return {
                BankCodeList: null,         // 은행 공통코드 목록
                AccountList: null,          // 계좌 목록
                AccountListTotalCount: 0,   // 계좌 목록 전체 건수
                // 계좌 상세정보 객체
                AccountDetail: {
                    Arg: null,
                    UserId: this.$parent.EmpId,
                    OrderSeq: null,
                    CorpCd: this.$parent.CompanyCode,
                    AcntNo: null,
                    AcntName: null,
                    BankCd: null,
                    BankNm: null,
                    UseYN: null,
                    UpdateDate: null,
                    UpdateEmpNo: null,
                    UpdateEmpNm: null,
                    GUID: null,             // 파일 GUID
                    FileName: null,         // 파일명
                    IsOwner: null,
                    IsRemoveFile: false,    // 파일삭제여부
                    IsNewFile: false        // 신규파일여부
                },
                // 계좌 목록 조회 조건 객체
                AccountListSelectTerm: {
                    Arg: null,
                    UserId: this.$parent.EmpId,
                    CompanyCode: this.$parent.CompanyCode,
                    BankCode: '',
                    UseYn: '',
                    TopSize: 10,
                    CurrentPage: 1
                },
                PageActionType: 'V' // I: 등록, V: 저장
            }
        },
        beforeMount() {

            // 공통코드 조회
            this.BankCodeList = $.scriptBase.getCommonCode('CODE000', '0101000', '', '')
        },
        mounted() {

            if (this.$parent.CanSearch) {

                this.getBankAccount(false)
            }
        },
        methods: {
            // 은행계좌 목록 행 클릭 이벤트
            rowClickEvent: function (row) {

                // 페이지 엑션 값 설정(I: 신규, V: 상세화면)
                this.PageActionType = 'V'

                Object.assign(this.AccountDetail, row)

                this.AccountDetail.Arg = 'I'
                this.AccountDetail.UserId = this.$parent.EmpId
                this.AccountDetail.Files = null
                this.AccountDetail.IsRemoveFile = false
                this.AccountDetail.IsNewFile = false

                $(this.$refs.CM1011E_Modal).modal('show')
            },
            // 은행계좌 목록 조회
            getBankAccount: function (isOpenDetailPopup) {

                var parent = this
                var url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/GetBankAccount'.toRealServiceUrl()
                var obj = new Object()

                // 조회 Arg 값(L) 설정
                this.AccountListSelectTerm.Arg = 'L'

                obj.jsonString = JSON.stringify(this.AccountListSelectTerm)

                var successCallback = function (data) {

                    var table = JSON.parse(data.d).Table;

                    if (table.length > 0) {

                        parent.AccountList = table
                        parent.AccountListTotalCount = table.length
                    }
                    else {

                        parent.AccountList = null
                        parent.AccountListTotalCount = 0
                    }

                    // 상세팝업 오픈 플래그 값에 따라 상세 팝업 오픈
                    if (isOpenDetailPopup) {

                        for (var i = 0; i < table.length; i++) {

                            if (table[i].CorpCd === parent.AccountDetail.CorpCd
                                && table[i].AcntNo === parent.AccountDetail.AcntNo) {

                                parent.rowClickEvent(table[i])

                                break
                            }
                        }
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback);
            },
            // 은행계좌 신규 등록
            addBankAccount: function () {

                // 페이지 엑션 값 설정(I: 신규, V: 상세화면)
                this.PageActionType = 'I'

                // 상세 객체 초기화
                this.AccountDetail = {
                    Arg: 'I',
                    UserId: this.$parent.EmpId,
                    OrderSeq: null,
                    CorpCd: this.$parent.CompanyCode,
                    AcntNo: null,
                    AcntName: null,
                    BankCd: '',
                    BankNm: null,
                    UseYN: 'Y',
                    UpdateDate: null,
                    UpdateEmpNo: null,
                    UpdateEmpNm: null,
                    GUID: null,
                    FileName: null,
                    IsOwner: 'Y',
                    Files: null,
                    IsNewFile: false
                }

                $(this.$refs.CM1011E_Modal).modal('show');
            },
            // 은행계좌 저장
            saveBankAccount: function () {

                var parent = this

                // 파일업로드 완료 후 호출되는 콜백 함수
                var successUploadFileCallback = function (result) {

                    var jsonData = JSON.parse(result)

                    if (jsonData.files.length > 0) {

                        // 파일명, GUID 값 설정
                        parent.AccountDetail.FileName = jsonData.files[0].oFileName
                        parent.AccountDetail.GUID = jsonData.files[0].nFileName
                    }
                    else {

                        parent.AccountDetail.FileName = null
                        parent.AccountDetail.GUID = null
                    }

                    // 저장 실행
                    parent.doSave()
                }

                if (this.AccountDetail.BankCd === null || this.AccountDetail.BankCd === '') {

                    alert('은행명을 선택하세요.')
                    this.$refs.selBankCd2.focus()
                    return
                }

                if (this.AccountDetail.AcntNo === null || this.AccountDetail.AcntNo === '') {

                    alert('계좌번호를 입력하세요.')
                    this.$refs.txtAcntNo2.focus()
                    return
                }

                if (!this.AccountDetail.AcntNo.isValidAccountNumber()) {

                    alert('은행계좌 양식을 다시 확인해주세요.')
                    this.$refs.txtAcntNo2.focus()
                    return
                }

                // 신규일 경우에만 계좌번호, 파일 유효성 체크 및 파일 업로드 함
                if (this.PageActionType === 'I') {

                    var isExistAccountNo = this.AccountList.some(function (item) {

                        return (item.AcntNo === parent.AccountDetail.AcntNo)
                    })

                    if (isExistAccountNo) {

                        alert('이미 일치하는 계좌번호가 있습니다.')
                        this.$refstxtAcntNo2.focus()
                        this.$refs.focus()
                        return
                    }
                }

                // 신규파일이면 업로드 후 저장
                if (this.AccountDetail.IsNewFile) {

                    if (this.AccountDetail.FileName != null
                        && this.AccountDetail.FileName === ''
                        && this.AccountDetail.Files === null) {

                        alert('첨부파일을 선택하세요.')
                        return
                    }

                    // 업로드 할 파일이 존재하면 업로드 실행 후 저장
                    if (this.AccountDetail.Files != null) {

                        this.fileHandler.upload(this.AccountDetail.Files, successUploadFileCallback)
                    }
                }
                else {

                    // 파일이 없으면 저장 실행
                    this.doSave()
                }
            },
            // 실제 저장 실행
            doSave: function () {

                var parent = this
                var url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/SaveBankAccount'.toRealServiceUrl()
                var obj = new Object()

                obj.jsonString = JSON.stringify(this.AccountDetail)

                var successCallback = function (data) {

                    if (data.d > 0) {

                        if (parent.PageActionType === 'I') {

                            alert('등록 되었습니다.')
                            $(parent.$refs.CM1011E_Modal).modal('hide')
                            parent.getBankAccount(false)
                        }
                        else {

                            alert('저장 되었습니다.')
                            parent.getBankAccount(true)
                        }
                    }
                }

                $.scriptBase.executeAjaxAsync(url, obj, successCallback)
            },
            // 은행계좌 삭제
            removeBankAccount: function () {

                var parent = this

                if (confirm('삭제하시겠습니까?')) {

                    var url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/RemoveBankAccount'.toRealServiceUrl()
                    var obj = new Object()

                    obj.arg = 'D'
                    obj.companyCode = this.AccountDetail.CorpCd
                    obj.accountNumber = this.AccountDetail.AcntNo

                    var successCallback = function (data) {

                        if (data.d > 0) {

                            alert('삭제 되었습니다.')
                            $(parent.$refs.CM1011E_Modal).modal('hide')
                            parent.getBankAccount(false)
                        }
                    }

                    $.scriptBase.executeAjaxAsync(url, obj, successCallback)
                }
            },
            // 첨부파일 추가
            addAttachFile: function () {

                if (this.AccountDetail.FileName != null) {

                    alert('첨부파일은 1개만 등록 가능합니다')
                    return
                }
                else {

                    this.AccountDetail.FileName = ''
                    this.AccountDetail.IsNewFile = true
                }
            },
            // 첨부파일 삭제
            removeAttachFile: function () {

                var parent = this

                if (this.AccountDetail.IsRemoveFile) {

                    if (confirm('삭제하시겠습니까?')) {

                        if (this.AccountDetail.IsNewFile) {

                            this.AccountDetail.FileName = null
                            this.AccountDetail.Files = null
                            this.AccountDetail.IsNewFile = false
                        }
                        else {

                            var url = '/Mini.Service.IBS.CM.Service/Common.svc/ajax/RemoveBankAccountFile'.toRealServiceUrl()
                            var obj = new Object()

                            obj.arg = 'CD'
                            obj.companyCode = this.AccountDetail.CorpCd
                            obj.accountNumber = this.AccountDetail.AcntNo

                            var successCallback = function (data) {

                                if (data.d > 0) {

                                    alert('삭제 되었습니다.')
                                    $(parent.$refs.CM1011E_Modal).modal('hide')
                                    parent.getBankAccount(false)
                                }
                            }

                            $.scriptBase.executeAjaxAsync(url, obj, successCallback)
                        }
                    }
                }
                else {

                    alert('삭제할 파일이 존재 하지 않습니다. 삭제할 파일을 선택하여 주세요.')
                }
            },
            // 은행계좌잔액관리 모달 오픈
            openBalanceModal() {

                var callbackFunction = function () {

                }
                this.$refs.CM1011E.open(callbackFunction);
            },
        }
    }
</script>

<style scoped>
</style>